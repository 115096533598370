import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { flow } from 'lodash'
import { tryAcquireAccessToken } from 'shared/services/auth'
import { AppState } from 'store/shared'
import { getClientOnlineApiConfig } from 'store/system'
import { axiosBaseQuery } from './shared'

const getApiBaseUrl = (state: AppState) => {
  const base = flow(getClientOnlineApiConfig, (x) => x?.root)(state)
  const url = new URL('', base)
  return url.href
}

const getApiAuthToken = (state: AppState) => {
  const scopes = flow(getClientOnlineApiConfig, (x) => x?.scopes)(state)

  if (!scopes) {
    throw new Error('')
  }

  return tryAcquireAccessToken(scopes)
}

export const ClientOnlineSliceKey = 'api.clientOnline'

export const clientOnlineApi = createApi({
  baseQuery: axiosBaseQuery({
    getBaseUrl: (state) => getApiBaseUrl(state),
    getAuthToken: (state) => getApiAuthToken(state)
  }),
  endpoints: () => ({}),
  reducerPath: ClientOnlineSliceKey
})

export type ClientOnlineApiReducerState = {
  [ClientOnlineSliceKey]: ReturnType<typeof clientOnlineApi.reducer>
}
