import { lazy } from 'react'
import { IModuleDefinition } from '../types'

const TeamsContainer = lazy(() => import('./TeamsContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/teams/*',
    private: true,
    component: TeamsContainer
  },
  name: 'Team'
}

export default moduleDefinition
