import { call, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { IClient } from '../../../api/client.types'
import {
  IOdataCollectionFilter,
  OdataFilterCollectionOperatorEnum,
  OdataFilterOperatorEnum
} from '../../../api/odata'
import { AppState } from '../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../store/shared/asyncStore'
import { search } from '../../../store/shared/sagas'

export const clientFetchActions = createAsyncAction(
  '@features/@households/@householdDetail/@clients/REQUEST',
  '@features/@households/@householdDetail/@clients/SUCCESS',
  '@features/@households/@householdDetail/@clients/FAILURE'
)<string, IClient[], Error>()

export const householdClientsFetchReducer =
  createAsyncReducer(clientFetchActions)

const rootSelector = (state: AppState) =>
  state.features.households.householdClientsFetch

export const {
  getError: getClientsError,
  getIsLoading: getIsClientsLoading,
  getResult: getClientsResult
} = createAsyncSelectors(rootSelector)

const fetchClients = function* (
  action: ReturnType<typeof clientFetchActions.request>
) {
  const collectionFilter: IOdataCollectionFilter = {
    filter: {
      and: [
        {
          operator: OdataFilterOperatorEnum.eq,
          value: action.payload,
          path: 'householdId',
          type: 'string'
        }
      ]
    },
    operator: OdataFilterCollectionOperatorEnum.any,
    path: 'householdList'
  }

  try {
    const result = yield* call(search, 'client' as const, {
      orderBy: [{ dataPath: 'ClientKPI/AumTotal', direction: 'desc' as const }],
      select: [
        'ClientAdvisor',
        'ClientAdvisorID',
        'ClientKPI',
        'LegalEntityID',
        'LegalEntityName',
        'RelatedAccounts',
        'contactdetails',
        'dateofBirth',
        'householdId',
        'householdName',
        'householdList',
        'id',
        'legalEntityType',
        'loginDetails',
        'srcClientNumber',
        'Account',
        'revenueDet',
        'LegalEntityID',
        'investors'
      ],
      filters: [collectionFilter]
    })

    const clients = result?.value

    if (!clients) {
      throw new Error('Clients for household not found')
    }

    yield put(clientFetchActions.success(clients as IClient[]))
  } catch (e: any) {
    yield put(clientFetchActions.failure(e))
  }
}

export const householdClientsFetchSagas = [
  () => takeLatest(clientFetchActions.request, fetchClients)
]
