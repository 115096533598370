import createCache from '@emotion/cache'
import { ThemeProvider, CacheProvider } from '@emotion/react'
import { TableOptions } from '@tanstack/react-table'
import { IClosedLotExtended } from 'modules/Advisory/modules/Rdot360/store/types'
import { useCallback } from 'react'
import { createRoot } from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import { Provider, useStore } from 'react-redux'
import { Store } from 'redux'
import { whenIdle } from 'shared/async'
import { theme } from 'shared/theme'
import { PrintHTML } from './PrintHTML'

interface IGainLossPrintOptions {
  tableOptions: TableOptions<IClosedLotExtended>
  masked: boolean
  hideHousehold: boolean
  searchText: string
  netAmount: number
}

interface IGainLossPrintOptionsWithStore extends IGainLossPrintOptions {
  store: Store
}

const generateHTML = async (options: IGainLossPrintOptionsWithStore) => {
  // Emotion renders the style tags in this div with dynamic classnames for each css prop
  const emotionContainer = document.createElement('div')
  const cache = createCache({
    key: 'print',
    speedy: false,
    container: emotionContainer
  })
  const container = document.createElement('div')
  const root = createRoot(container)
  const { store, tableOptions, masked, hideHousehold, searchText, netAmount } =
    options
  root.render(
    <Provider store={store}>
      <IntlProvider locale="en">
        <ThemeProvider theme={theme}>
          <CacheProvider value={cache}>
            <PrintHTML
              tableOptions={tableOptions}
              masked={masked}
              hideHousehold={hideHousehold}
              searchText={searchText}
              netAmount={netAmount}
            />
          </CacheProvider>
        </ThemeProvider>
      </IntlProvider>
    </Provider>
  )
  await whenIdle()
  container.appendChild(emotionContainer)
  const html = container.innerHTML
  root.unmount()
  return html
}

const openGainLossPrintTab = async (
  options: IGainLossPrintOptions & { store: Store }
) => {
  const innerHtml = await generateHTML(options)
  const blob = new Blob([innerHtml], { type: 'text/html' })
  const url = URL.createObjectURL(blob)
  open(url, '_blank', 'noreferrer')
  setTimeout(() => URL.revokeObjectURL(url), 5000)
}

export const useGainLossPrint = () => {
  const store = useStore()

  const print = useCallback(
    (options: IGainLossPrintOptions) => {
      openGainLossPrintTab({ ...options, store })
    },
    [store]
  )

  return { print }
}
