import { IApiOptions } from 'shared/contracts/IApiOptions'
import { getRockefellerApiOptions } from 'store/shared'
import { call, put, takeLatest } from 'typed-redux-saga'
import {
  getRecurringSchedule,
  saveRecurringSchedule
} from '../../api/WireService'
import { getRecurringScheduledDaysActions } from './actions'

function* handleGetRecurringScheduledDays(
  action: ReturnType<typeof getRecurringScheduledDaysActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      getRecurringSchedule(
        action.payload.request,
        action.payload.preview,
        options
      )
    )
    if (response?.rspStatus?.statusCode === '0') {
      yield put(
        getRecurringScheduledDaysActions.success(
          response?.scheduledDayList ?? []
        )
      )
    } else {
      yield put(
        getRecurringScheduledDaysActions.failure(
          new Error(response?.rspStatus?.message)
        )
      )
    }
  } catch (e: any) {
    yield put(getRecurringScheduledDaysActions.failure(e))
  }
}

function* handleSaveRecurringScheduledDays(
  action: ReturnType<typeof getRecurringScheduledDaysActions.save>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      saveRecurringSchedule(action.payload.scheduledDays, options)
    )
    if (response?.statusCode === '0' && action.payload.refetchScheduleData) {
      console.log('refetch')
      yield put(
        //reFetchData
        getRecurringScheduledDaysActions.request(
          action.payload.refetchScheduleData
        )
      )
    }
  } catch (e: any) {
    yield put(getRecurringScheduledDaysActions.failure(e))
  }
}

export const sagas = [
  () =>
    takeLatest(
      getRecurringScheduledDaysActions.request,
      handleGetRecurringScheduledDays
    ),
  () =>
    takeLatest(
      getRecurringScheduledDaysActions.save,
      handleSaveRecurringScheduledDays
    )
]
