import { groupBy, has } from 'lodash'
import {
  SharedReportClientInfo,
  SharedReportErrorType,
  SharedReportResponseStates,
  SharedReportResponseType,
  SharedReportTypes
} from './types'

export const getSharedReportsResponsesGroupedByType = (
  responses: SharedReportResponseType[],
  selectedNames: string[],
  selectedLoginIds: string[]
) => {
  const responsesMapped = responses?.map(
    (response, i): SharedReportClientInfo => {
      const type: SharedReportTypes = has(response, 'data')
        ? 'success'
        : 'failure'
      const error = has(response, 'error')
        ? (response.error as SharedReportErrorType)
        : undefined
      const errorMessage = error?.response?.data?.message

      return {
        type,
        name: selectedNames[i],
        loginId: selectedLoginIds[i],
        errorMessage
      }
    }
  )

  return groupBy(responsesMapped, (x) => x.type) as SharedReportResponseStates
}
