import { partial } from 'lodash/fp'
import { IHousehold } from '../../../api/household.types'
import { AppState } from '../../../store'
import { createListsStore } from '../core/store'
import { BaseExportConfigurations } from '../shared/BaseExportConfigurations'
import {
  createDefaultColumnState,
  mapColumnsToFilterDefinitions
} from '../shared/lists'
import { HouseholdColumnDefinitions } from './ColumnDefinitions'

export const constructInitialHouseholdListColumnState = partial(
  createDefaultColumnState,
  [HouseholdColumnDefinitions]
)

export const householdListStore = createListsStore<IHousehold>(
  'household',
  '@features/@lists/@households',
  {
    columnDefinitions: HouseholdColumnDefinitions,
    filterDefinitions: mapColumnsToFilterDefinitions(HouseholdColumnDefinitions)
  },
  (state: AppState) => state.features.lists.household,
  BaseExportConfigurations
)

export const { reducer, sagas, actions, selectors } = householdListStore
