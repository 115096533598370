import {
  Dropdown,
  IDropdownOption,
  Label,
  Stack,
  TextField,
  Text,
  Icon,
  PrimaryButton,
  MessageBar
} from '@fluentui/react'
import { get } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch
} from 'react-hook-form'
import { IHurdleMetric } from '../../../../../../../../api/datahub'
import { FormattedNumberField } from '../../../../../../../../shared/components/FormattedNumberField'
import { Separator } from '../../../../../../../../shared/components/Separator'
import { isNotNullOrUndefined } from '../../../../../../../../shared/guards'
import { UserEntitySelector } from '../EntitySearch'
import { Accordion, AccordionSummary } from './Accordion'
import { IHurdleForm } from './HurdleEditPanel'

const metricOptions: IDropdownOption[] = [
  { key: 'T-12 From Hurdle', text: 'T-12 From Hurdle' },
  { key: '3 Months Annualized', text: '3 Months Annualized' },
  { key: 'AUS', text: 'AUS' }
]
const payoutTypeOptions: IDropdownOption[] = [
  { key: 'Cash', text: 'Cash' },
  { key: 'Note', text: 'Note' }
]
type index = number
type MetricsItemPath = `hurdle.measurements.${index}.metrics.${index}`
type PayoutsPath = `${MetricsItemPath}.payouts`

export const MetricAccordion: React.FC<{
  onMetricDelete?: () => void
  onMetricCopy: () => void
  expand: boolean
  title: string
  name: MetricsItemPath
  disabled?: boolean
}> = ({ onMetricDelete, onMetricCopy, expand, title, name, disabled }) => {
  const register = useFormContext<IHurdleForm>()
  const metricPath = name as MetricsItemPath
  const payoutsPath: PayoutsPath = `${metricPath}.payouts`
  const errors = get(register.formState.errors, name)

  const metric = useWatch<IHurdleForm>({
    name: metricPath
  }) as IHurdleMetric
  const isAUS = useMemo(
    () => metric?.metricType === 'AUS',
    [metric?.metricType]
  )
  const payoutFields = useFieldArray<IHurdleForm>({
    name: payoutsPath
  })
  const deleteMetric = useCallback(
    (ev: any) => {
      ev.stopPropagation()
      if (disabled) {
        return
      }
      onMetricDelete?.()
    },
    [disabled, onMetricDelete]
  )

  const addPayout = useCallback(() => {
    payoutFields.append({ payoutType: 'Cash' })
  }, [payoutFields])
  const deletePayout = useCallback(
    (payoutIndex: number) => {
      if (disabled) {
        return
      }
      payoutFields.remove(payoutIndex)
    },
    [disabled, payoutFields]
  )

  const headerText = useMemo(
    () =>
      [
        [
          metric?.metricType,
          metric?.metricValue
            ? metric?.metricType === 'AUS'
              ? `$${metric?.metricValue.toLocaleString()}`
              : `${metric?.metricValue}%`
            : undefined
        ]
          .filter(isNotNullOrUndefined)
          .join(' | ')
      ]
        .filter(isNotNullOrUndefined)
        .join(' | '),
    [metric]
  )

  const onCopyMetric = useCallback(
    (ev: any) => {
      ev.stopPropagation()
      if (disabled) {
        return
      }
      onMetricCopy()
    },
    [disabled, onMetricCopy]
  )

  return (
    <Accordion defaultExpanded={expand} expandAll={expand} invalid={!!errors}>
      <AccordionSummary>
        <Stack
          horizontal={true}
          verticalAlign="center"
          tokens={{ childrenGap: 5 }}
        >
          <Stack.Item styles={{ root: { width: '220px' } }}>
            <Text variant="medium" styles={{ root: { fontWeight: 'bold' } }}>
              {title}
            </Text>
          </Stack.Item>
          <Stack.Item grow={1}>
            <Text variant="small">{headerText}</Text>
          </Stack.Item>
          <Stack.Item>
            <Icon
              iconName="Copy"
              onClick={onCopyMetric}
              title="Copy Metric"
              style={{ opacity: disabled ? '0.5' : '1' }}
            />
          </Stack.Item>
          {onMetricDelete && (
            <Stack.Item>
              <Icon
                iconName="Delete"
                onClick={deleteMetric}
                title="Delete Metric"
                style={{ opacity: disabled ? '0.5' : '1' }}
              />
            </Stack.Item>
          )}
        </Stack>
      </AccordionSummary>

      <Stack tokens={{ childrenGap: 5 }}>
        <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
          <Stack grow={1} styles={{ root: { flexBasis: 0 } }}>
            <Label required>Hurdle Metric</Label>
            <Controller
              name={`${metricPath}.metricType`}
              control={register.control}
              rules={{ required: 'Select a Metric Type' }}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => {
                return (
                  <Dropdown
                    selectedKey={value || ''}
                    errorMessage={error && error.message}
                    onChange={(ev, option) => onChange(option?.key)}
                    placeholder="Select a Metric"
                    options={metricOptions}
                    disabled={disabled}
                  />
                )
              }}
            />
          </Stack>
          <Stack grow={1} styles={{ root: { flexBasis: 0 } }}>
            <Label required>{isAUS ? 'Hurdle Target' : 'Hurdle Percent'}</Label>
            <Controller
              name={`${metricPath}.metricValue`}
              control={register.control}
              rules={{ required: 'Enter Hurdle Percent' }}
              render={({
                field: { value, onChange },
                fieldState: { error }
              }) => {
                return isAUS ? (
                  <FormattedNumberField
                    placeholder="Target"
                    errorMessage={error && error.message}
                    value={value}
                    onChange={(x) => onChange(x != null ? x : null)}
                    prefix="$"
                    disabled={disabled}
                  />
                ) : (
                  <TextField
                    placeholder="Percent"
                    errorMessage={error && error.message}
                    value={value?.toString() || ''}
                    onChange={(ev, percent) =>
                      onChange(percent ? parseFloat(percent) : null)
                    }
                    type="number"
                    suffix="%"
                    disabled={disabled}
                  />
                )
              }}
            />
          </Stack>
        </Stack>
        <Stack>
          <Separator />
          <Stack
            horizontal={true}
            horizontalAlign="space-between"
            verticalAlign="start"
          >
            <h4 style={{ margin: '0px' }}>Payouts</h4>
            <PrimaryButton
              onClick={addPayout}
              iconProps={{ iconName: 'Add' }}
              disabled={disabled}
            >
              Payout
            </PrimaryButton>
          </Stack>
        </Stack>
        <Stack>
          <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
            <Stack.Item grow={1} styles={{ root: { flexBasis: 0 } }}>
              <Label required>Person</Label>
            </Stack.Item>
            <Stack.Item grow={1} styles={{ root: { flexBasis: 0 } }}>
              <Label required>Hurdle Payout $</Label>
            </Stack.Item>
            <Stack.Item grow={1} styles={{ root: { flexBasis: 0 } }}>
              <Label required>Hurdle Payout Type</Label>
            </Stack.Item>
            <Stack.Item styles={{ root: { minWidth: '35px' } }}>
              <Icon
                iconName="Delete"
                styles={{ root: { visibility: 'hidden' } }}
                style={{ opacity: disabled ? '0.5' : '1' }}
              />
            </Stack.Item>
          </Stack>

          <Stack tokens={{ childrenGap: 5 }}>
            {!payoutFields?.fields?.length && (
              <MessageBar>No payouts have been added.</MessageBar>
            )}
            {payoutFields?.fields?.map((payout, payoutIndex) => {
              return (
                <Stack
                  key={payout.id}
                  horizontal={true}
                  verticalAlign="start"
                  tokens={{ childrenGap: 10 }}
                >
                  <Stack
                    grow={1}
                    styles={{ root: { flexBasis: 0, minWidth: '80px' } }}
                  >
                    <Controller
                      name={`${payoutsPath}.${payoutIndex}.userFullName`}
                      control={register.control}
                      rules={{ required: 'Select a User' }}
                      render={({
                        field: { value, onChange },
                        fieldState: { error }
                      }) => {
                        return (
                          <UserEntitySelector
                            onSelectedEntityChanged={(entity) => {
                              onChange(entity?.fullname)
                              register.setValue(
                                `${payoutsPath}.${payoutIndex}.userDomainName`,
                                entity?.domainname,
                                { shouldValidate: true }
                              )
                            }}
                            selectedText={value}
                            errorMessage={error && error.message}
                            disabled={disabled}
                          />
                        )
                      }}
                    />
                  </Stack>
                  <Stack.Item grow={1} styles={{ root: { flexBasis: 0 } }}>
                    <Controller
                      name={`${payoutsPath}.${payoutIndex}.payoutValue`}
                      control={register.control}
                      rules={{ required: 'Enter Payout Value' }}
                      render={({
                        field: { value, onChange },
                        fieldState: { error }
                      }) => {
                        return (
                          <FormattedNumberField
                            errorMessage={error && error.message}
                            value={value}
                            onChange={(x) => onChange(x != null ? x : null)}
                            prefix="$"
                            disabled={disabled}
                          />
                        )
                      }}
                    />
                  </Stack.Item>
                  <Stack.Item grow={1} styles={{ root: { flexBasis: 0 } }}>
                    <Controller
                      name={`${payoutsPath}.${payoutIndex}.payoutType`}
                      control={register.control}
                      rules={{ required: 'Select a Payout Type' }}
                      render={({
                        field: { value, onChange },
                        fieldState: { error }
                      }) => {
                        return (
                          <Dropdown
                            selectedKey={value || null}
                            errorMessage={error && error.message}
                            onChange={(ev, option) => onChange(option?.key)}
                            placeholder="Select a Payout Type"
                            options={payoutTypeOptions}
                            disabled={disabled}
                          />
                        )
                      }}
                    />
                  </Stack.Item>
                  <Stack.Item styles={{ root: { minWidth: '35px' } }}>
                    <Icon
                      iconName="Delete"
                      styles={{
                        root: {
                          marginTop: '10px',
                          cursor: 'pointer',
                          opacity: disabled ? '0.5' : '1'
                        }
                      }}
                      onClick={() => deletePayout(payoutIndex)}
                    />
                  </Stack.Item>
                </Stack>
              )
            })}
          </Stack>
        </Stack>
      </Stack>
    </Accordion>
  )
}
