import axios from 'axios'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import {
  ICheck,
  ICheckReprintScreenActionsRequest,
  ICheckReprintScreenActionsResponse,
  IGetCheckListReq
} from '../store/types'

export const fetchCheckList = (
  reqPayload: IGetCheckListReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/bill/api/checks/get?userId=${reqPayload.userId}&${reqPayload.dateRange}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<ICheck[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
export const checkReprintScreenHandler = (
  reqPayload: ICheckReprintScreenActionsRequest,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/bill/api/checks/performaction`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<ICheckReprintScreenActionsResponse>(url, reqPayload, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
