import { combineReducers } from 'redux'
import {
  aiRevenueDashboardFeaturesReducer,
  aiRevenueDashboardFeaturesSagas
} from '../features'
import { aiRevenueReducer } from './dashboard'

export const aiRevenueDashboardReducer = combineReducers({
  features: aiRevenueDashboardFeaturesReducer,
  aiRevenueReducer
})

export const aiRevenueDashboardSagas = [...aiRevenueDashboardFeaturesSagas]
