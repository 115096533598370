import { Link, SearchBox, Stack, Text, useTheme } from '@fluentui/react'
import { debounce, orderBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { IBusinessUnit } from '../../../../api/dynamics'
import { Separator } from '../../../../shared/components/Separator'
import { isNotNullOrEmpty } from '../../../../shared/guards'
import { RepSelectorListItem } from './RepSelectorListItem'
import {
  IUsersAndTeamsAdvisorWithPersonalReps,
  useUsersAndTeamsStore
} from './store/usersAndTeams'

interface ISearchItem {
  key?: string
  value?: string
  type: 'team' | 'user'
  item?: IUsersAndTeamsAdvisorWithPersonalReps | IBusinessUnit
}

export interface IUsersAndTeamsProps {
  onTeamClicked: (businessunitid?: string) => void
  onAdvisorClicked: (domainname?: string) => void
  onSelectAll: () => void
}

export const UsersAndTeams: React.FC<IUsersAndTeamsProps> = ({
  onTeamClicked,
  onAdvisorClicked,
  onSelectAll
}) => {
  const {
    updateFilter,
    filter,
    filteredAdvisors,
    filteredTeams,
    userAdvisorInfo,
    userTeam
  } = useUsersAndTeamsStore()

  const onSearchChange = useCallback(
    (_: any, newValue?: string) => {
      updateFilter(newValue)
    },
    [updateFilter]
  )

  const debouncedOnSearchChange = useMemo(
    () => debounce(onSearchChange, 100),
    [onSearchChange]
  )

  const searchItems = useMemo(() => {
    return orderBy(
      [
        ...filteredAdvisors.map(
          (x): ISearchItem => ({
            key: x.advisor?.domainname,
            value: x.advisor?.fullname,
            type: 'user',
            item: x
          })
        ),
        ...filteredTeams.map(
          (x): ISearchItem => ({
            key: x.businessunitid,
            value: x.name,
            type: 'team',
            item: x
          })
        )
      ],
      ({ value }) => value
    )
  }, [filteredAdvisors, filteredTeams])

  const theme = useTheme()

  return (
    <Stack tokens={{ childrenGap: 3 }}>
      <Stack.Item
        styles={{
          root: {
            paddingBottom: '10px',
            position: 'sticky',
            top: '82px',
            zIndex: 2,
            backgroundColor: theme.semanticColors.bodyBackground
          }
        }}
      >
        <SearchBox
          placeholder="Filter Advisors and Teams"
          defaultValue={filter || ''}
          onChange={debouncedOnSearchChange}
          autoComplete="off"
        />
      </Stack.Item>
      <Stack tokens={{ childrenGap: 3 }}>
        {userAdvisorInfo && (
          <RepSelectorListItem
            text="Select Just My Contributions"
            subtext="Select only IDs where I'm the owner or a member of the pool"
            iconName="Contact"
            onClick={() => onAdvisorClicked()}
          />
        )}
        {userTeam && (
          <RepSelectorListItem
            text="Select Just My Team's Contributions"
            subtext="Select only IDs where a member of my team is the owner or a member of the pool"
            iconName="Teamwork"
            onClick={() => onTeamClicked()}
          />
        )}
        <RepSelectorListItem
          text="View Everything"
          subtext="Select all available IDs"
          iconName="BoxCheckmarkSolid"
          onClick={onSelectAll}
        />
        <Separator />
        {searchItems.map(({ key, type, item }) => {
          if (type === 'team') {
            const team = item as IBusinessUnit | undefined
            const officeBu = team?.parentbusinessunitid
            const office = officeBu?.name
            const division = officeBu?.parentbusinessunitid?.name
            return (
              <RepSelectorListItem
                key={key}
                text={team?.name}
                subtext={[division, office]
                  .filter(isNotNullOrEmpty)
                  .join(' - ')}
                iconName="Teamwork"
                onClick={() => onTeamClicked(team?.businessunitid)}
              />
            )
          }

          if (type === 'user') {
            const user = item as
              | IUsersAndTeamsAdvisorWithPersonalReps
              | undefined
            const officeBu =
              user?.reps?.[0]?.rcm_OwningTeam?.businessunitid
                ?.parentbusinessunitid
            const office = officeBu?.name
            const division = officeBu?.parentbusinessunitid?.name
            const reps = user?.reps?.map((x) => x.rcm_repid)
            return (
              <RepSelectorListItem
                key={key}
                text={() => (
                  <Stack
                    horizontal={true}
                    tokens={{ childrenGap: 5 }}
                    verticalAlign="center"
                  >
                    <Text>
                      <Link>{user?.advisor?.fullname}</Link>
                    </Text>
                    {!!reps?.length && (
                      <Text
                        variant="small"
                        styles={{ root: { fontWeight: 'bold' } }}
                      >
                        ({reps.join(', ')})
                      </Text>
                    )}
                  </Stack>
                )}
                subtext={[division, office]
                  .filter(isNotNullOrEmpty)
                  .join(' - ')}
                iconName="Contact"
                onClick={() => onAdvisorClicked(user?.advisor?.domainname)}
              />
            )
          }
        })}
      </Stack>
    </Stack>
  )
}
