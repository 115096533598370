import axios, { AxiosError } from 'axios'
import { isString } from 'lodash'
import { getStorageSettings } from './settings'
import { trackException } from './telemetry'

const registerExceptionInterceptor = () =>
  axios.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      if (axios.isCancel(error)) {
        return
      }

      let props = {}

      if (error?.isAxiosError !== undefined) {
        const errorResponse = error?.response?.data
        let errorDetail = 'unknown'
        if (errorResponse) {
          try {
            errorDetail = (
              isString(errorResponse)
                ? errorResponse
                : JSON.stringify(errorResponse)
            ).slice(0, 8190)
          } catch {
            /* empty */
          }
        }
        props = {
          ...props,
          url: error?.config ? axios.getUri(error.config) : '',
          method: error?.config?.method,
          error: errorDetail
        }
      }

      trackException(error, {
        source: 'axios-exception-interceptor',
        ...props
      })
      return Promise.reject(error)
    }
  )

const registerAddVersionHeaderForRockcoApisInterceptor = () => {
  return axios.interceptors.request.use((config) => {
    const addVersionHeaderForRockcoApis =
      getStorageSettings()?.addVersionHeaderForRockcoApis
    if (!config || !config.headers) {
      return config
    }

    const url = axios.getUri(config)
    const parsed = new URL(url)
    if (!parsed?.hostname?.toLowerCase()?.endsWith('rockco.com')) {
      return config
    }

    const [versionHeaderName, versionHeaderValue] =
      Object.entries(config.headers).find(
        ([key]) => key?.toLowerCase() === 'version'
      ) || []

    config.headers[versionHeaderName || 'version'] =
      addVersionHeaderForRockcoApis || versionHeaderValue || 'v2'

    return config
  })
}

export const registerInterceptors = () => {
  registerAddVersionHeaderForRockcoApisInterceptor()
  registerExceptionInterceptor()
}
