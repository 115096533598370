import { createReducer } from 'typesafe-actions'
import { grantorsInfoDataActions, GrantorInfoActionType } from './actions'
import { IGrantor } from './types'

export interface IGrantorState {
  grantorsData: IGrantor[]
  isLoading: boolean
  error?: Error
}

const initialState: IGrantorState = {
  grantorsData: [],
  isLoading: true,
  error: undefined
}

export const grantorReducer = createReducer<
  IGrantorState,
  GrantorInfoActionType
>(initialState)
  .handleAction(grantorsInfoDataActions.request, (state) => ({
    ...state,
    grantorsData: [],
    isLoading: true,
    error: undefined
  }))
  .handleAction(grantorsInfoDataActions.success, (state, action) => ({
    ...state,
    grantorsData: action.payload,
    isLoading: false,
    error: undefined
  }))
  .handleAction(grantorsInfoDataActions.failure, (state, action) => ({
    ...state,
    grantorsData: [],
    error: action.payload,
    isLoading: false
  }))
