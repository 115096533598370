import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { IAlert } from 'modules/Advisory/modules/Alerts/store/alerts.types'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

const { actions, reducer } = createSlice({
  name: 'alertDetailPanel',
  initialState: {} as IDetailPanelState,
  reducers: {
    openAlertDetails: (
      state,
      action: PayloadAction<{
        selectedAlert?: IAlert
        currentAlertIndex?: number
      }>
    ) => ({
      isAlertDetailsOpen: true,
      selectedAlert: action.payload.selectedAlert,
      currentAlertIndex: action.payload.currentAlertIndex || 0
    }),
    closeAlertDetails: () => ({
      isAlertDetailsOpen: false,
      errorAlertDetails: undefined,
      selectedAlert: undefined
    }),
    failure: (state, action: PayloadAction<Error>) => ({
      ...state,
      errorAlertDetails: action.payload
    })
  }
})

export { reducer as alertDetailReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.alerts.alertDetails

const getIsPanelOpen = flow(rootSelector, (x) => x.isAlertDetailsOpen)
const getPanelError = flow(rootSelector, (x) => x.errorAlertDetails)
const getSelectedAlert = flow(rootSelector, (x) => x.selectedAlert)
const getCurrentIndex = flow(rootSelector, (x) => x.currentAlertIndex)
export interface IDetailPanelState {
  isAlertDetailsOpen?: boolean
  errorAlertDetails?: Error
  selectedAlert?: IAlert
  currentAlertIndex?: number
}

export const useAlertDetailPanel = () => {
  const dispatch = useDispatch()

  const closeAlertDetails = useCallback(() => {
    dispatch(actions.closeAlertDetails())
  }, [dispatch])

  const openAlertDetails = useCallback(
    ({
      selectedAlert,
      currentAlertIndex
    }: {
      selectedAlert?: IAlert
      currentAlertIndex?: number
    }) => {
      dispatch(actions.openAlertDetails({ selectedAlert, currentAlertIndex }))
    },
    [dispatch]
  )

  const setErrorAlertDetails = useCallback(
    (e: Error) => {
      dispatch(actions.failure(e))
    },
    [dispatch]
  )

  const isAlertDetailsOpen = useSelector(getIsPanelOpen)
  const selectedAlertData = useSelector(getSelectedAlert)
  const errorAlertDetails = useSelector(getPanelError)
  const currentAlertIndex = useSelector(getCurrentIndex)

  return {
    closeAlertDetails,
    openAlertDetails,
    setErrorAlertDetails,
    isAlertDetailsOpen,
    errorAlertDetails,
    selectedAlertData,
    currentAlertIndex
  }
}
