import { MessageBarType } from '@fluentui/react'
import { flow } from 'lodash/fp'
import { createSelector } from 'reselect'
import { call, put, takeEvery, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import {
  IHouseholdFee,
  updateHouseholdFee
} from '../../../../../api/households'
import { AppState } from '../../../../../store'
import { getRockefellerApiOptions } from '../../../../../store/shared/sagas'
import { pushNotification } from '../../../../Notifications'
import { getHouseholdDepartmentFetchError } from '../../../store/householdDepartmentsFetch'
import {
  getHouseholdFeeFetchError,
  getHouseholdFeeFetchResult,
  getIsHouseholdFeeFetchLoading,
  householdFeeFetchActions
} from '../../../store/householdFeeFetch'
import {
  getIsHouseholdLoading,
  householdFetchActions
} from '../../../store/householdFetch'

const OPEN_PANEL =
  '@features/@households/@features/@householdFee/@householdFeePanel/OPEN_PANEL'
const CLOSE_PANEL =
  '@features/@households/@features/@householdFee/@householdFeePanel/CLOSE_PANEL'
const SUBMIT =
  '@features/@households/@features/@householdFee/@householdFeePanel/SUBMIT'
const SUCCESS =
  '@features/@households/@features/@householdFee/@householdFeePanel/SUCCESS'
const FAILURE =
  '@features/@households/@features/@householdFee/@householdFeePanel/FAILURE'

export const householdFeePanelActions = {
  openPanel: createAction(OPEN_PANEL)<string>(),
  closePanel: createAction(CLOSE_PANEL)(),
  submit: createAction(SUBMIT)<IHouseholdFee>(),
  success: createAction(SUCCESS)<IHouseholdFee>(),
  failure: createAction(FAILURE)<Error>()
}

type HouseholdFeePanelActionTypes = ActionType<typeof householdFeePanelActions>

export interface IHouseholdFeePanelState {
  currentHouseholdId?: string
  loading?: boolean
  error?: Error
}

const initialState: IHouseholdFeePanelState = {}

export const householdFeePanelReducer = createReducer<
  IHouseholdFeePanelState,
  HouseholdFeePanelActionTypes
>(initialState)
  .handleAction(householdFeePanelActions.openPanel, (state, action) => ({
    ...initialState,
    currentHouseholdId: action.payload
  }))
  .handleAction(householdFeePanelActions.closePanel, () => ({
    ...initialState,
    currentHouseholdId: undefined
  }))
  .handleAction(householdFeePanelActions.submit, (state) => ({
    ...state,
    loading: true
  }))
  .handleAction(householdFeePanelActions.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }))

const rootSelector = (state: AppState) =>
  state.features.households.features.householdFee.householdFeePanel

export const getHouseholdFeePanelCurrentHouseholdId = flow(
  rootSelector,
  ({ currentHouseholdId }) => currentHouseholdId
)

export const getIsHouseholdFeePanelOpen = flow(
  rootSelector,
  ({ currentHouseholdId }) => currentHouseholdId != null
)

export const getHouseholdFeeDetails = getHouseholdFeeFetchResult
export const getIsHouseholdFeePanelLoading = createSelector(
  [getIsHouseholdFeeFetchLoading, getIsHouseholdLoading],
  (fee, household) => fee || household
)
export const getHouseholdFeePanelError = createSelector(
  [
    getHouseholdFeeFetchError,
    getHouseholdDepartmentFetchError,
    flow(rootSelector, ({ error }) => error)
  ],
  (fetch, departmentFetch, panel) => fetch || departmentFetch || panel
)
export const getIsHouseholdFeePanelUpdating = flow(
  rootSelector,
  ({ loading }) => loading
)

const onOpenPanel = function* (
  action: ReturnType<typeof householdFeePanelActions.openPanel>
) {
  yield put(householdFeeFetchActions.request(action.payload))
  yield put(householdFetchActions.request(action.payload))
}

const onSubmit = function* (
  action: ReturnType<typeof householdFeePanelActions.submit>
) {
  try {
    const apiOptions = yield* call(getRockefellerApiOptions)
    const updated = yield* call(updateHouseholdFee, apiOptions, action.payload)
    yield put(householdFeePanelActions.success(updated))
    yield put(householdFeeFetchActions.success(updated))
  } catch (e: any) {
    yield put(householdFeePanelActions.failure(e))
    return
  }

  yield put(householdFeePanelActions.closePanel())

  yield call(pushNotification, {
    message: 'Successfully updated household fee details.',
    type: MessageBarType.success
  })
}

export const householdFeePanelSagas = [
  () => takeLatest(householdFeePanelActions.openPanel, onOpenPanel),
  () => takeEvery(householdFeePanelActions.submit, onSubmit)
]
