import axios, { CancelTokenSource } from 'axios'
import { call, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import {
  getAdvisorDomainFromDynamics,
  IDiscountShares
} from '../../../../../api/datahub'
import { IAdvisorRep } from '../../../../../api/dynamics'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import { AppState } from '../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../../../../store/shared/sagas'

export const discountSplitsFetchActions = createAsyncAction(
  '@modules/@advisory/@modules/@bda/@discountSplits/REQUEST',
  '@modules/@advisory/@modules/@bda/@discountSplits/SUCCESS',
  '@modules/@advisory/@modules/@bda/@discountSplits/FAILURE'
)<undefined, IAdvisorRep[] | undefined, Error>()

export const discountSplitsFetchReducer = createAsyncReducer(
  discountSplitsFetchActions
)

const getDatahubApiOptions = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)
  return [apiOptions, source] as [IApiOptions, CancelTokenSource]
}

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.bda.discountSplits

export const {
  getError: getDiscountSplitsFetchError,
  getIsLoading: getDiscountSplitsFetchLoading,
  getResult: getDiscountSplitsFetchResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* () {
  try {
    const [apiOptions] = yield* call(getDatahubApiOptions)
    const result = yield* call(getAdvisorDomainFromDynamics, apiOptions)
    if (!result) {
      throw new Error('No reps found')
    }
    yield put(discountSplitsFetchActions.success(result))
  } catch (e) {
    yield put(discountSplitsFetchActions.failure(e as Error))
  }
}

export interface IPools {
  rowlabel: string
  splits: ISplits[]
  poolname: string
  isPool: boolean
  repcodes: string[]
  monthlyDiscounts: number[]
  hub?: string
  division?: string
}

export interface ISplits extends IDiscountShares {
  repcode: string
  monthlyDiscounts: number[]
  percentage: number
  poolrep: string
  name?: string
}

export const discountSplitsFetchSagas = [
  () => takeLatest(discountSplitsFetchActions.request, onRequest)
]
