import { IRdot360Account, getIsSBL } from '../../../store/rdot360Context'

export const getRegistration = (account: IRdot360Account) => {
  const isSBL = getIsSBL(account)

  if (isSBL) {
    return 'SBL TriState'
  }

  return account?.preferredNickname && account?.registrationtype
    ? account.registrationtype
    : account?.registrationDesc ?? ''
}
