import { ICommandBarItemProps } from '@fluentui/react'
import { useMemo } from 'react'

interface IUseCommandBarProps {
  isExportLoading: boolean
  clickToExport: () => void
  openPanel: () => void
}

export const useCommandBar = (props: IUseCommandBarProps) => {
  const { isExportLoading, clickToExport, openPanel } = props
  const commandBarItems: ICommandBarItemProps[] = useMemo(
    () => [
      {
        key: 'editColumns',
        text: 'Edit Columns',
        cacheKey: 'myCacheKey',
        onClick: openPanel,
        iconProps: { iconName: 'Edit' }
      },
      {
        key: 'exportExcel',
        text: `${isExportLoading ? 'Exporting...' : 'Export'} to Excel`,
        cacheKey: 'myCacheKey',
        onClick: clickToExport,
        iconProps: { iconName: 'ExcelLogo' }
      }
    ],
    [clickToExport, isExportLoading, openPanel]
  )

  return { commandBarItems }
}
