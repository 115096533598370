import { call, put, takeLatest, select } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../store/shared/sagas'
import { getActiveClient } from '../../Dashboard/store/selectors'
import {
  fetchLedgerPaymentsService,
  getGwestransCodesService,
  reverseLedgerPaymentService,
  updateTransactionService,
  fetchEditedLedgerPaymentsService,
  postLedgerPaymentService
} from '../api'
import {
  editedGlTransactionDataActions,
  glTransactionDataActions,
  gwesTransCodesDataActions,
  postGlPaymentsActions,
  reverseTransactionActions,
  updateModifiedTransactionActions,
  updateTransactionActions
} from './actions'

function* handleFetchGlTransactions(
  action: ReturnType<typeof glTransactionDataActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchLedgerPaymentsService(action.payload, options)
    )
    yield put(glTransactionDataActions.success(data))
  } catch (e) {
    yield put(glTransactionDataActions.failure(e))
  }
}

function* handleReverseGlTransactions(
  action: ReturnType<typeof reverseTransactionActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    yield* call(() =>
      reverseLedgerPaymentService(action.payload?.transaction, options)
    )
    yield put(reverseTransactionActions.success())
    const activeClient = yield* select(getActiveClient)
    yield put(
      glTransactionDataActions.request({
        entityid: activeClient?.dynamicsLegalEntity || '',
        dates: action.payload?.dates
      })
    )
  } catch (e) {
    yield put(reverseTransactionActions.failure(e))
  }
}

function* handleUpdateGlTransaction(
  action: ReturnType<typeof updateTransactionActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    yield* call(() =>
      updateTransactionService(action.payload?.transactions, options)
    )
    yield put(updateTransactionActions.success())
    const activeClient = yield* select(getActiveClient)
    yield put(
      glTransactionDataActions.request({
        entityid: activeClient?.dynamicsLegalEntity || '',
        dates: action.payload?.dates
      })
    )
  } catch (e) {
    yield put(updateTransactionActions.failure(e))
  }
}

function* handleUpdateModifiedGlTransaction(
  action: ReturnType<typeof updateModifiedTransactionActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    yield* call(() =>
      updateTransactionService(action.payload?.transactions, options)
    )
    yield put(updateModifiedTransactionActions.success())
    const activeClient = yield* select(getActiveClient)
    yield put(
      editedGlTransactionDataActions.request({
        entityid: activeClient?.dynamicsLegalEntity || '',
        dates: action.payload?.dates
      })
    )
  } catch (e) {
    yield put(updateModifiedTransactionActions.failure(e))
  }
}

function* handleFetchGwesTransCodes() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() => getGwestransCodesService(options))
    yield put(gwesTransCodesDataActions.success(data))
  } catch (e) {
    yield put(gwesTransCodesDataActions.failure(e))
  }
}

function* handlePostGlTransactions(
  action: ReturnType<typeof postGlPaymentsActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const activeClient = yield* select(getActiveClient)
    yield* call(() =>
      postLedgerPaymentService(
        activeClient?.dynamicsLegalEntity || '',
        action.payload.payments,
        options
      )
    )
    yield put(postGlPaymentsActions.success())
    yield put(
      glTransactionDataActions.request({
        entityid: activeClient?.dynamicsLegalEntity || '',
        dates: action.payload?.dates
      })
    )
  } catch (e) {
    yield put(postGlPaymentsActions.failure(e))
  }
}

function* handleFetchEditedGlTransactions(
  action: ReturnType<typeof editedGlTransactionDataActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchEditedLedgerPaymentsService(action.payload, options)
    )
    yield put(editedGlTransactionDataActions.success(data))
  } catch (e) {
    yield put(editedGlTransactionDataActions.failure(e))
  }
}

export const sagas = [
  () => takeLatest(glTransactionDataActions.request, handleFetchGlTransactions),
  () =>
    takeLatest(reverseTransactionActions.request, handleReverseGlTransactions),
  () => takeLatest(updateTransactionActions.request, handleUpdateGlTransaction),
  () =>
    takeLatest(
      updateModifiedTransactionActions.request,
      handleUpdateModifiedGlTransaction
    ),
  () =>
    takeLatest(gwesTransCodesDataActions.request, handleFetchGwesTransCodes),
  () => takeLatest(postGlPaymentsActions.request, handlePostGlTransactions),
  () =>
    takeLatest(
      editedGlTransactionDataActions.request,
      handleFetchEditedGlTransactions
    )
]
