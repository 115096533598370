import { createAction, ActionType } from 'typesafe-actions'
import {
  ITrustDiscretionaryDistribution,
  ITrustDiscretionaryDistributionDoc,
  ITrustDiscretionaryDistributionByAccount,
  IUploadAdditionalDocRequest
} from '../api/types'
import { ITrustDistributionRequest, ITrustDistribution } from './types'

export const FETCH_DISCRETIONARY_DISTRIBUTION_REQUEST = `@mouldes/@trust/@newdistribution/FETCH_DISCRETIONARY_DISTRIBUTION_REQUEST`
export const FETCH_DISCRETIONARY_DISTRIBUTION_SUCCESS = `@mouldes/@trust/@newdistribution/FETCH_DISCRETIONARY_DISTRIBUTION_SUCCESS`
export const FETCH_DISCRETIONARY_DISTRIBUTION_FAILURE = `@mouldes/@trust/@newdistribution/FETCH_DISCRETIONARY_DISTRIBUTION_FAILURE`

export const FETCH_DISCRETIONARY_DISTRIBUTION_LEGACY_REQUEST = `@mouldes/@trust/@newdistribution/FETCH_DISCRETIONARY_DISTRIBUTION_LEGECY_REQUEST`
export const FETCH_DISCRETIONARY_DISTRIBUTION_LEGECY_SUCCESS = `@mouldes/@trust/@newdistribution/FETCH_DISCRETIONARY_DISTRIBUTION_LEGECY_SUCCESS`
export const FETCH_DISCRETIONARY_DISTRIBUTION_LEGECY_FAILURE = `@mouldes/@trust/@newdistribution/FETCH_DISCRETIONARY_DISTRIBUTION_LEGECY_FAILURE`

export const FETCH_DISCRETIONARY_DISTRIBUTION_BY_ACCOUNT_REQUEST = `@mouldes/@trust/@newdistribution/FETCH_DISCRETIONARY_DISTRIBUTION_BY_ACCOUNT_REQUEST`
export const FETCH_DISCRETIONARY_DISTRIBUTION_BY_ACCOUNT_SUCCESS = `@mouldes/@trust/@newdistribution/FETCH_DISCRETIONARY_DISTRIBUTION_BY_ACCOUNT_SUCCESS`
export const FETCH_DISCRETIONARY_DISTRIBUTION_BY_ACCOUNT_FAILURE = `@mouldes/@trust/@newdistribution/FETCH_DISCRETIONARY_DISTRIBUTION_BY_ACCOUNT_FAILURE`

export const FETCH_DISCRETIONARY_DISTRIBUTION_CRM_REQUEST = `@mouldes/@trust/@newdistribution/FETCH_DISCRETIONARY_DISTRIBUTION_CRM_REQUEST`
export const FETCH_DISCRETIONARY_DISTRIBUTION_CRM_SUCCESS = `@mouldes/@trust/@newdistribution/FETCH_DISCRETIONARY_DISTRIBUTION_CRM_SUCCESS`
export const FETCH_DISCRETIONARY_DISTRIBUTION_CRM_FAILURE = `@mouldes/@trust/@newdistribution/FETCH_DISCRETIONARY_DISTRIBUTION_CRM_FAILURE`

export const SUBMIT_DISCRETIONARY_DISTRIBUTION_REQUEST = `@modules/@trust/@newdistribution/SUBMIT_DISCRETIONARY_DISTRIBUTION_REQUEST`
export const SUBMIT_DISCRETIONARY_DISTRIBUTION_SUCCESS = `@modules/@trust/@newdistribution/SUBMIT_DISCRETIONARY_DISTRIBUTION_SUCCESS`
export const SUBMIT_DISCRETIONARY_DISTRIBUTION_FAILURE = `@modules/@trust/@newdistribution/SUBMIT_DISCRETIONARY_DISTRIBUTION_FAILURE`

export const CLEAR_NEW_TRUST_DISTRIBUTION = `@modules/@trust/@newdistribution/CLEAR_NEW_TRUST_DISTRIBUTION`
export const CLEAR_NEW_TRUST_DISTRIBUTION_BY_ACCOUNT = `@modules/@trust/@newdistribution/CLEAR_NEW_TRUST_DISTRIBUTION_BY_ACOOUNT`

export const FETCH_DISCRETIONARY_DISTRIBUTION_EDIT_REQUEST = `@modules/@trust/@newdistribution/FETCH_DISCRETIONARY_DISTRIBUTION_EDIT_REQUEST`
export const FETCH_DISCRETIONARY_DISTRIBUTION_EDIT_SUCCESS = `@modules/@trust/@newdistribution/FETCH_DISCRETIONARY_DISTRIBUTION_EDIT_SUCCESS`
export const FETCH_DISCRETIONARY_DISTRIBUTION_EDIT_FAILURE = `@modules/@trust/@newdistribution/FETCH_DISCRETIONARY_DISTRIBUTION_EDIT_FAILURE`

export const FETCH_DISCRETIONARY_DISTRIBUTION_DOC_REQUEST = `@modules/@trust/@newdistribution/FETCH_DISCRETIONARY_DISTRIBUTION_DOC_REQUEST`
export const FETCH_DISCRETIONARY_DISTRIBUTION_DOC_SUCCESS = `@modules/@trust/@newdistribution/FETCH_DISCRETIONARY_DISTRIBUTION_DOC_SUCCESS`
export const FETCH_DISCRETIONARY_DISTRIBUTION_DOC_FAILURE = `@modules/@trust/@newdistribution/FETCH_DISCRETIONARY_DISTRIBUTION_DOC_FAILURE`

export const DISCRETIONARY_DISTRIBUTION_SUBMIT_STATUS_CHANGE = `@modules/@trust/@newdistribution/DISCRETIONARY_DISTRIBUTION_SUBMIT_STATUS_CHANGE`

export const UPLOAD_ADDITIONAL_DOC_REQUEST = `@modules/@trust/@newdistribution/UPLOAD_ADDITIONAL_DOC_REQUEST`
export const UPLOAD_ADDITIONAL_DOC_SUCCESS = `@modules/@trust/@newdistribution/UPLOAD_ADDITIONAL_DOC_SUCCESS`
export const UPLOAD_ADDITIONAL_DOC_FAILURE = `@modules/@trust/@newdistribution/UPLOAD_ADDITIONAL_DOC_FAILURE`

export const DELETE_ADDITIONAL_DOC_REQUEST = `@modules/@trust/@newdistribution/DELETE_ADDITIONAL_DOC_REQUEST`
export const DELETE_ADDITIONAL_DOC_SUCCESS = `@modules/@trust/@newdistribution/DELETE_ADDITIONAL_DOC_SUCCESS`
export const DELETE_ADDITIONAL_DOC_FAILURE = `@modules/@trust/@newdistribution/DELETE_ADDITIONAL_DOC_FAILURE`

export const discretionaryDistributionInfoDataActions = {
  request: createAction(FETCH_DISCRETIONARY_DISTRIBUTION_REQUEST)<string>(),
  success: createAction(
    FETCH_DISCRETIONARY_DISTRIBUTION_SUCCESS
  )<ITrustDiscretionaryDistribution>(),
  failure: createAction(FETCH_DISCRETIONARY_DISTRIBUTION_FAILURE)<Error>(),
  clear: createAction(CLEAR_NEW_TRUST_DISTRIBUTION)()
}

export const discretionaryDistributionInfoLegacyDataActions = {
  request: createAction(
    FETCH_DISCRETIONARY_DISTRIBUTION_LEGACY_REQUEST
  )<string>(),
  success: createAction(
    FETCH_DISCRETIONARY_DISTRIBUTION_LEGECY_SUCCESS
  )<ITrustDiscretionaryDistribution>(),
  failure: createAction(
    FETCH_DISCRETIONARY_DISTRIBUTION_LEGECY_FAILURE
  )<Error>()
}

export const discretionaryDistributionInfoByAccountDataActions = {
  request: createAction(
    FETCH_DISCRETIONARY_DISTRIBUTION_BY_ACCOUNT_REQUEST
  )<string>(),
  success: createAction(
    FETCH_DISCRETIONARY_DISTRIBUTION_BY_ACCOUNT_SUCCESS
  )<ITrustDiscretionaryDistributionByAccount>(),
  failure: createAction(
    FETCH_DISCRETIONARY_DISTRIBUTION_BY_ACCOUNT_FAILURE
  )<Error>(),
  clear: createAction(CLEAR_NEW_TRUST_DISTRIBUTION_BY_ACCOUNT)()
}

export const discretionaryDistributionInfoCRMDataActions = {
  request: createAction(FETCH_DISCRETIONARY_DISTRIBUTION_CRM_REQUEST)<string>(),
  success: createAction(
    FETCH_DISCRETIONARY_DISTRIBUTION_CRM_SUCCESS
  )<ITrustDiscretionaryDistribution>(),
  failure: createAction(FETCH_DISCRETIONARY_DISTRIBUTION_CRM_FAILURE)<Error>()
}

export const discretionaryDistributionSubmitActions = {
  request: createAction(
    SUBMIT_DISCRETIONARY_DISTRIBUTION_REQUEST
  )<ITrustDistributionRequest>(),
  success: createAction(SUBMIT_DISCRETIONARY_DISTRIBUTION_SUCCESS)<string>(),
  failure: createAction(SUBMIT_DISCRETIONARY_DISTRIBUTION_FAILURE)<Error>(),
  statusChange: createAction(DISCRETIONARY_DISTRIBUTION_SUBMIT_STATUS_CHANGE)<
    number | undefined
  >()
}

export const discretionaryDistributionEditActions = {
  request: createAction(
    FETCH_DISCRETIONARY_DISTRIBUTION_EDIT_REQUEST
  )<string>(),
  success: createAction(
    FETCH_DISCRETIONARY_DISTRIBUTION_EDIT_SUCCESS
  )<ITrustDistribution>(),
  failure: createAction(FETCH_DISCRETIONARY_DISTRIBUTION_EDIT_FAILURE)<Error>()
}

export const discretionaryDistributionDocActions = {
  request: createAction(FETCH_DISCRETIONARY_DISTRIBUTION_DOC_REQUEST)<string>(),
  success: createAction(FETCH_DISCRETIONARY_DISTRIBUTION_DOC_SUCCESS)<
    ITrustDiscretionaryDistributionDoc[]
  >(),
  failure: createAction(FETCH_DISCRETIONARY_DISTRIBUTION_DOC_FAILURE)<Error>()
}

export const uploadAdditionalDocActions = {
  request: createAction(
    UPLOAD_ADDITIONAL_DOC_REQUEST
  )<IUploadAdditionalDocRequest>(),
  success: createAction(UPLOAD_ADDITIONAL_DOC_SUCCESS)(),
  failure: createAction(UPLOAD_ADDITIONAL_DOC_FAILURE)<Error>()
}

export const deleteAdditionalDocActions = {
  request: createAction(DELETE_ADDITIONAL_DOC_REQUEST)<string>(),
  success: createAction(DELETE_ADDITIONAL_DOC_SUCCESS)<string>(),
  failure: createAction(DELETE_ADDITIONAL_DOC_FAILURE)<Error>()
}

export type DiscretionaryDistrubutionInfoActionType = ActionType<
  typeof discretionaryDistributionInfoDataActions
>

export type DiscretionaryDistrubutionInfoLegacyActionType = ActionType<
  typeof discretionaryDistributionInfoLegacyDataActions
>

export type DiscretionaryDistrubutionInfoByAccountActionType = ActionType<
  typeof discretionaryDistributionInfoByAccountDataActions
>

export type DiscretionaryDistrubutionInfoCrmActionType = ActionType<
  typeof discretionaryDistributionInfoCRMDataActions
>

export type DiscretionaryDistrubutionSubmitActionType = ActionType<
  typeof discretionaryDistributionSubmitActions
>

export type DiscretionaryDistributionEditActionType = ActionType<
  typeof discretionaryDistributionEditActions
>
export type DiscretionaryDistributionDocsActionType = ActionType<
  typeof discretionaryDistributionDocActions
>

export type UploadAdditionalDocumentActionType = ActionType<
  typeof uploadAdditionalDocActions
>

export type DeleteAdditionalDocumentActionType = ActionType<
  typeof deleteAdditionalDocActions
>

export type NewDiscretionaryDistributionAction =
  | DiscretionaryDistrubutionInfoActionType
  | DiscretionaryDistrubutionInfoByAccountActionType
  | DiscretionaryDistrubutionSubmitActionType
  | DiscretionaryDistributionEditActionType
  | DiscretionaryDistributionDocsActionType
  | UploadAdditionalDocumentActionType
  | DeleteAdditionalDocumentActionType
