import { createSlice } from '@reduxjs/toolkit'
import { flow } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRootState } from 'store/shared'
import { ActionType } from 'typesafe-actions'

export type HistoricalValueDateRangeType =
  | 'mostRecent'
  | 'priorMonth'
  | 'prior6Months'
  | 'prior12Months'
  | 'currentYear'
  | 'priorYear'
  | 'inceptionToDate'
  | 'customRange'
export interface IBalanceFilterState {
  fromDate?: Date
  toDate?: Date
  selectedDateRange: HistoricalValueDateRangeType
}

const initialState: IBalanceFilterState = {
  selectedDateRange: 'prior12Months',
  toDate: new Date()
}

export const balanceFilterSlice = createSlice({
  name: '@features/@rdot360/@BalanceFilter',
  initialState,
  reducers: {
    setCustomFromDate: (state, action) => {
      state.fromDate = action.payload
    },
    setCustomToDate: (state, action) => {
      state.toDate = action.payload
    },
    setSelectedDateRange: (state, action) => {
      state.selectedDateRange = action.payload
    }
  }
})

export const getFilterState = flow(getRootState, (x) => {
  return x.modules.advisory.modules.rdot360.balanceFilter
})

export const getSelectedDateRange = flow(
  getFilterState,
  (x) => x.selectedDateRange
)
export const getFromDate = flow(getFilterState, (x) => x.fromDate)
export const getToDate = flow(getFilterState, (x) => x.toDate)

export const balanceFilterActions = balanceFilterSlice.actions
export const balanceFilterReducer = balanceFilterSlice.reducer

export type BalanceFilterActionTypes = ActionType<typeof balanceFilterActions>

export const useBalanceModuleStore = () => {
  const dispatch = useDispatch()
  const selectedDateRange = useSelector(getSelectedDateRange)
  const customFromDate = useSelector(getFromDate)
  const customToDate = useSelector(getToDate)

  const setCustomFromDate = useCallback(
    (fromDate?: Date) => {
      dispatch(balanceFilterActions.setCustomFromDate(fromDate))
    },
    [dispatch]
  )

  const setCustomToDate = useCallback(
    (toDate?: Date) => {
      dispatch(balanceFilterActions.setCustomToDate(toDate))
    },
    [dispatch]
  )

  const setSelectedDateRange = useCallback(
    (dateRange?: HistoricalValueDateRangeType) => {
      dispatch(balanceFilterActions.setSelectedDateRange(dateRange))
    },
    [dispatch]
  )

  return {
    customFromDate,
    setCustomFromDate,
    customToDate,
    setCustomToDate,
    selectedDateRange,
    setSelectedDateRange
  }
}
