import { MessageBarType } from '@fluentui/react'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import { pushNotification } from 'features/Notifications'
import { downloadUrlAsFile } from 'shared/downloads'
import { isNotNullOrUndefined } from 'shared/guards'
import { createAsyncReducer } from 'store/shared/asyncStore'
import { getAllPagedOdataApiResults } from 'store/shared/sagas/odata'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import {
  ExcelColumnConfigType,
  ExcelExportWorker
} from '../../shared/export/worker'
import {
  fetchUnderlyingHoldings,
  selectors as holdingsSelectors
} from './store'

export const holdingsExcelExportActions = createAsyncAction(
  '@modules/@advisory/@modules/@annuitiesandinsurance/@modules/@dashboard/@features/@holdingsExcelExport/EXPORT_REQUEST',
  '@modules/@advisory/@modules/@annuitiesandinsurance/@modules/@dashboard/@features/@holdingsExcelExport/EXPORT_SUCCESS',
  '@modules/@advisory/@modules/@annuitiesandinsurance/@modules/@dashboard/@features/@holdingsExcelExport/EXPORT_FAILURE'
)<undefined, undefined, Error>()

export const holdingsExcelExportReducer = createAsyncReducer(
  holdingsExcelExportActions
)

const onExport = function* () {
  try {
    const request = yield* select(holdingsSelectors.uiSelectors.getOdataRequest)

    const results = yield* call(() =>
      getAllPagedOdataApiResults(request, fetchUnderlyingHoldings)
    )

    const resultsMappedFiltered = results
      ?.flatMap((x) => x.result.value)
      ?.filter(isNotNullOrUndefined)

    const columnNames = yield* select(
      holdingsSelectors.uiSelectors.getSelectedColumns
    )

    const worker = new Worker(
      new URL('../../shared/export/worker.ts', import.meta.url)
    )

    const { createAndDownloadExcelWorksheet } = wrap<ExcelExportWorker>(worker)

    const excelColumnConfigType: ExcelColumnConfigType = 'ASSETS'

    const result = yield* call(createAndDownloadExcelWorksheet, [
      {
        data: resultsMappedFiltered,
        selectedColumnNames: columnNames || [],
        excelColumnConfigType,
        tabLabel: 'Underlying Holdings'
      }
    ])

    const filename = `Underlying Holdings ${format(
      new Date(),
      'MM-dd-yyyy'
    )}.xlsx`
    downloadUrlAsFile(result, filename)
    yield put(holdingsExcelExportActions.success())
  } catch (e: unknown) {
    yield put(holdingsExcelExportActions.failure(e as Error))
  }
}

const onFailure = function* (
  action: ReturnType<typeof holdingsExcelExportActions.failure>
) {
  yield call(pushNotification, {
    message: `Export failed: ${action.payload.message}`,
    type: MessageBarType.error
  })
}

export const holdingsExcelExportSagas = [
  () => takeLatest(holdingsExcelExportActions.request, onExport),
  () => takeLatest(holdingsExcelExportActions.failure, onFailure)
]
