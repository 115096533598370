import axios from 'axios'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { getRockefellerApiOptions } from 'store/shared'
import { call, cancelled, delay, put, takeLatest } from 'typed-redux-saga'
import {
  getDueDiligencesService,
  getIDDApprovalStatusService,
  getIDDAuditRequestService,
  updateDueDiligenceService
} from '../api/duediligence'
import {
  dueDiligenceFetchActions,
  fetchApprovalStatusActions,
  getAuditRequestActions,
  updateDueDiligenceActions
} from './actions'

function* handleFetchdueDiligence(
  action: ReturnType<typeof dueDiligenceFetchActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)

  try {
    const result = yield* call(
      getDueDiligencesService,
      apiOptions,
      action.payload
    )
    yield put(dueDiligenceFetchActions.success(result.data))
  } catch (e: any) {
    yield put(dueDiligenceFetchActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

function* handleUpdateDueDiligence(
  action: ReturnType<typeof updateDueDiligenceActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const apiResp = yield* call(() =>
      updateDueDiligenceService(action.payload, options)
    )
    if (apiResp.errorCode === 0) {
      yield put(updateDueDiligenceActions.success(apiResp))
      yield put(dueDiligenceFetchActions.request(''))
    } else {
      yield put(
        updateDueDiligenceActions.failure(
          new Error(
            apiResp?.errormessage ||
              'Unable to update Due Diligence , please try after sometime.'
          )
        )
      )
    }
  } catch (e: any) {
    yield put(
      updateDueDiligenceActions.failure(
        new Error('Unable to update Due Diligence, please try after sometime.')
      )
    )
  }
}

function* handleApprovalStatusActions() {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() => getIDDApprovalStatusService(options))
    if (response.success) {
      yield put(fetchApprovalStatusActions.success(response.data))
    } else {
      yield put(
        fetchApprovalStatusActions.failure(
          new Error('Unable to get Approval Status')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchApprovalStatusActions.failure(e))
  }
}

function* handleAuditRequestBySecId(
  action: ReturnType<typeof getAuditRequestActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      getIDDAuditRequestService(action.payload, options)
    )

    if (response.success) {
      yield put(getAuditRequestActions.success(response))
    } else {
      yield put(
        getAuditRequestActions.failure(
          new Error('Unable to get Audit Request, please try after sometime.')
        )
      )
    }
  } catch (e: any) {
    yield put(getAuditRequestActions.failure(e))
  }
}

export const dueDiligenceSagas = [
  () => takeLatest(dueDiligenceFetchActions.request, handleFetchdueDiligence),
  () => takeLatest(updateDueDiligenceActions.request, handleUpdateDueDiligence),
  () => takeLatest(getAuditRequestActions.request, handleAuditRequestBySecId),
  () =>
    takeLatest(fetchApprovalStatusActions.request, handleApprovalStatusActions)
]
