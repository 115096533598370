import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  endOfYear,
  format,
  startOfYear,
  subDays,
  subMonths,
  subYears
} from 'date-fns'
import { flow } from 'lodash/fp'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { AppState } from 'store'
import {
  rdot360ContextActions,
  useRdot360Context
} from '../../../store/rdot360Context'
import { DurationOptions } from './DateRangeFilter'

interface IDateRange {
  startDate?: string
  endDate?: string
}

const currentDate = new Date()
const formatDate = (date: Date) => format(date, 'yyyy-MM-dd')

const { actions, reducer } = createSlice({
  name: 'performanceReportsUiState',
  initialState: {
    dateSelection: 'Last 60 Days',
    selectedDateRange: {
      startDate: formatDate(subDays(currentDate, 59)),
      endDate: formatDate(currentDate)
    },
    searchText: '',
    isDownloading: false,
    isEncodedReportLoading: false
  } as IReportsUiState,
  reducers: {
    setStartDate: (state, action: PayloadAction<string | undefined>) => {
      state.startDate = action.payload
    },
    setEndDate: (state, action: PayloadAction<string | undefined>) => {
      state.endDate = action.payload
    },
    setDateSelection: (state, action: PayloadAction<DurationOptions>) => {
      state.dateSelection = action.payload
    },
    // ToDo: we might need to set multiple categories it in the future
    setCategories: (state, action: PayloadAction<string[] | undefined>) => {
      state.categories = action.payload
    },
    setCategory: (state, action: PayloadAction<string | undefined>) => {
      state.category = action.payload
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    },
    setIsDownloading: (state, action: PayloadAction<boolean | undefined>) => {
      state.isDownloading = action.payload
    },
    setSelectedDateRange: (
      state,
      action: PayloadAction<IDateRange | undefined>
    ) => {
      state.selectedDateRange = action.payload
    },
    setIsEncodedReportLoading: (state, action: PayloadAction<boolean>) => {
      state.isEncodedReportLoading = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rdot360ContextActions.setSelectedHouseholdId, (state) => {
      state.searchText = ''
    })
  }
})

export { reducer as performanceReportsUiStateReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.modules.reports
    .performanceReportsUiState

const getStartDate = flow(rootSelector, (x) => x.startDate)
const getEndDate = flow(rootSelector, (x) => x.endDate)
const getDateSelection = flow(rootSelector, (x) => x.dateSelection)
//const getCategories = flow(rootSelector, (x) => x.categories)
const getCategory = flow(rootSelector, (x) => x.category)
const getSearchText = flow(rootSelector, (x) => x.searchText)
const getIsDownloading = flow(rootSelector, (x) => x.isDownloading)
const getSelectedDateRange = flow(rootSelector, (x) => x.selectedDateRange)
const getIsEncodedReportLoading = flow(
  rootSelector,
  (x) => x.isEncodedReportLoading
)
export interface IReportsUiState {
  startDate?: string
  endDate?: string
  dateSelection: DurationOptions
  categories?: string[]
  category?: string
  searchText: string
  dateRangeString?: string
  isDownloading?: boolean
  selectedDateRange?: IDateRange
  isEncodedReportLoading?: boolean
}

const selectDateRangeString = createSelector(
  [getSelectedDateRange],
  (dateRange) => {
    if (!dateRange) {
      return undefined
    }
    const { startDate, endDate } = dateRange
    if (!startDate || !endDate) {
      return undefined
    }
    return `${startDate},${endDate}`
    // return dateRange
  }
)

export const usePerformanceReportsUiState = () => {
  const { asOfDate: asOfDateString } = useRdot360Context()
  const asOfDate = useMemo(
    () =>
      asOfDateString
        ? parseDateISOStringInLocalTimezone(asOfDateString)
        : new Date(),
    [asOfDateString]
  )
  const dispatch = useDispatch()

  const setStartDate = useCallback(
    (startDate?: string) => {
      dispatch(actions.setStartDate(startDate))
    },
    [dispatch]
  )

  const setEndDate = useCallback(
    (endDate?: string) => {
      dispatch(actions.setEndDate(endDate))
    },
    [dispatch]
  )

  const setSelectedDateRange = useCallback(
    (dateRange?: IDateRange) => {
      dispatch(actions.setSelectedDateRange(dateRange))
    },
    [dispatch]
  )

  const setDateSelection = useCallback(
    (selection?: DurationOptions) => {
      if (!selection) {
        return
      }
      dispatch(actions.setDateSelection(selection))
      switch (selection) {
        case 'Last 60 Days':
          setSelectedDateRange({
            startDate: formatDate(subDays(asOfDate, 59)),
            endDate: formatDate(asOfDate)
          })
          return

        case 'Prior Month':
          setSelectedDateRange({
            startDate: formatDate(subMonths(asOfDate, 1)),
            endDate: formatDate(asOfDate)
          })
          return

        case 'Prior 3 Months':
          setSelectedDateRange({
            startDate: formatDate(subMonths(asOfDate, 3)),
            endDate: formatDate(asOfDate)
          })
          return

        case 'Prior 6 Months':
          setSelectedDateRange({
            startDate: formatDate(subMonths(asOfDate, 6)),
            endDate: formatDate(asOfDate)
          })
          return

        case 'Prior 12 Months':
          setSelectedDateRange({
            startDate: formatDate(subMonths(asOfDate, 12)),
            endDate: formatDate(asOfDate)
          })
          return

        case 'Prior Year':
          setSelectedDateRange({
            startDate: formatDate(startOfYear(subYears(asOfDate, 1))),
            endDate: formatDate(endOfYear(subYears(asOfDate, 1)))
          })
          return

        case 'Current Year':
          setSelectedDateRange({
            startDate: formatDate(startOfYear(asOfDate)),
            endDate: formatDate(asOfDate)
          })
          return
      }
    },
    [asOfDate, dispatch, setSelectedDateRange]
  )

  const setCategory = useCallback(
    (category?: string) => {
      dispatch(actions.setCategory(category))
    },
    [dispatch]
  )

  const startDate = useSelector(getStartDate)

  const endDate = useSelector(getEndDate)

  const dateSelection = useSelector(getDateSelection)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setDateSelection(dateSelection), [asOfDate])

  //const categories = useSelector(getCategories)

  const category = useSelector(getCategory)

  const setCustomRange = useCallback(
    (startDate?: string, endDate?: string) => {
      if (!startDate || !endDate) {
        return
      }
      dispatch(
        actions.setSelectedDateRange({
          startDate,
          endDate
        })
      )
    },
    [dispatch]
  )

  const dateRangeString = useSelector(selectDateRangeString)

  const setIsDownloading = useCallback(
    (value?: boolean) => {
      dispatch(actions.setIsDownloading(value))
    },
    [dispatch]
  )

  const setRangeForDay = useCallback(
    async (date: string) => {
      setDateSelection('Custom Range')
      setStartDate(date)
      setEndDate(date)
      setCustomRange(date, date)
    },
    [setCustomRange, setDateSelection, setEndDate, setStartDate]
  )

  const setIsEncodedReportLoading = useCallback(
    (value: boolean) => {
      dispatch(actions.setIsEncodedReportLoading(value))
    },
    [dispatch]
  )

  const searchText = useSelector(getSearchText)
  const isDownloading = useSelector(getIsDownloading)
  const setSearchText = useCallback(
    (search?: string) => {
      dispatch(actions.setSearchText(search || ''))
    },
    [dispatch]
  )
  const selectedDateRange = useSelector(getSelectedDateRange)
  const isEncodedReportLoading = useSelector(getIsEncodedReportLoading)
  return {
    setStartDate,
    setEndDate,
    setDateSelection,
    setCategory,
    startDate,
    endDate,
    dateSelection,
    category,
    setCustomRange,
    dateRangeString,
    searchText,
    setSearchText,
    setRangeForDay,
    isDownloading,
    setIsDownloading,
    selectedDateRange,
    isEncodedReportLoading,
    setIsEncodedReportLoading
  }
}
