import { Link, Stack, Text } from '@fluentui/react'
import { differenceInYears } from 'date-fns/fp'
import { flow } from 'lodash/fp'
import { useRdot360Navigation } from 'modules/Advisory/modules/Rdot360/shared/useRdot360navigation'
import React, { memo, useMemo } from 'react'
import { useMasking } from 'store/shared/hooks/useMaskedText'
import { IClient } from '../../api/client.types'
import { parseDateISOStringInLocalTimezone } from '../../shared'
import { ClientMenuContainer, IClientMenuContainerProps } from '.'

interface ILegalEntityNameCellProps {
  client: IClient
  onNameClicked: (client: IClient) => void
  menu?: React.FC<IClientMenuContainerProps> | null
}

export const LegalEntityNameCell: React.FC<ILegalEntityNameCellProps> = memo(
  ({ client, menu }) => {
    const Menu = menu || ClientMenuContainer
    const {
      LegalEntityID,
      LegalEntityName,
      srcClientNumber,
      legalEntityType,
      dateofBirth,
      Account
    } = client
    const { mask } = useMasking()
    const maskedName = useMemo(
      () => mask(LegalEntityName, 'name'),
      [LegalEntityName, mask]
    )

    const { handleClientClicked } = useRdot360Navigation()

    let legalEntityTypeDisplay = legalEntityType
    switch (legalEntityType) {
      case 'P': {
        legalEntityTypeDisplay = 'Individual'
        break
      }
      case 'B': {
        legalEntityTypeDisplay = 'Organization'
        break
      }
    }

    const additionalInfo = [
      legalEntityTypeDisplay,
      Account && `${Account.length} acct${Account.length > 1 ? 's' : ''}`,
      srcClientNumber,
      dateofBirth &&
        `${Math.abs(
          flow(
            parseDateISOStringInLocalTimezone,
            differenceInYears(Date.now())
          )(dateofBirth)
        )} yo`
    ].filter((x) => x)

    return (
      <Stack
        horizontal={true}
        tokens={{ childrenGap: 5 }}
        styles={{ root: { alignItems: 'center', minWidth: 0 } }}
      >
        {menu !== null && <Menu client={client} />}
        <Stack styles={{ root: { minWidth: 0 } }}>
          <Stack
            horizontal={true}
            styles={{ root: { minWidth: 0 } }}
            tokens={{ childrenGap: 3 }}
          >
            {LegalEntityID ? (
              <Link
                styles={{ root: { minWidth: 0 } }}
                onClick={() => handleClientClicked(client)}
                target="_blank"
              >
                <Text nowrap={true} block={true} title={maskedName}>
                  {maskedName}
                </Text>
              </Link>
            ) : (
              <Text nowrap={true} block={true} title={maskedName}>
                {maskedName}
              </Text>
            )}
          </Stack>
          <Stack
            horizontal={true}
            styles={{ root: { minWidth: 0 } }}
            tokens={{ childrenGap: 3 }}
          >
            {additionalInfo.length > 0 &&
              additionalInfo
                .map((info, i) => (
                  <Text key={i} variant="small" nowrap={true}>
                    {info}
                  </Text>
                ))
                .reduce((a, x) =>
                  a === null ? (
                    x
                  ) : (
                    <>
                      {a}
                      <Text variant="small">|</Text>
                      {x}
                    </>
                  )
                )}
          </Stack>
        </Stack>
      </Stack>
    )
  }
)
