import axios from 'axios'
import { IApiOptions } from '../../../../../../../shared/contracts/IApiOptions'
import {
  IPaymentApprover,
  IPaymentApproverForm,
  IPaymentApproverResponse,
  ISearchUser,
  ISearchUserResponse
} from '../store/types'

export const fetchUsersService = (
  request: ISearchUser,
  options: IApiOptions
) => {
  const queryString = `$filter=((userType eq 'Member') and (startswith(displayName,'${request.search}') or startswith(mail,'${request.search}')))&$count=true&$top=${request.top}&$select=displayName,jobTitle,surname,givenName,id,userPrincipalName,accountEnabled,mail,userType,onPremisesExtensionAttributes,extension_7e3ca93bb8c14a58b6203b539f5ad15e_extensionAttribute3`
  const url = `${options.apiRoot}/v1.0/users?${queryString}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
    ConsistencyLevel: 'eventual'
  }
  return axios
    .get<ISearchUserResponse>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchPaymentApproversService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/api/AuthorizedApprovers/GetApprovers`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPaymentApproverResponse[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const deletePaymentApproversService = (
  req: IPaymentApprover,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/AuthorizedApprovers/DeleteApprover?approverid=${req.id}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<void>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const submitPaymentApproverService = (
  req: IPaymentApproverForm,
  options: IApiOptions
) => {
  const url = req.approverid
    ? `${options.apiRoot}/trustapi/api/AuthorizedApprovers/UpdateApprover`
    : `${options.apiRoot}/trustapi/api/AuthorizedApprovers/InsertApprover`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  const data = {
    approverid: req.approverid,
    emailid: req.emailid,
    firstname: req.firstname,
    lastname: req.lastname,
    wsportaluserid: req.wsportaluserid
  }
  return axios
    .post<void>(url, data, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
