import { combineReducers } from 'redux'
import { accountSelectorFeaturesReducer } from '../features'
import { accountSelectorReducer } from './store'

export * from './store'

export const accountSelectorFeatureReducer = combineReducers({
  accountSelector: accountSelectorReducer,
  features: accountSelectorFeaturesReducer
})
