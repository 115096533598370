import { Link, Stack, Text } from '@fluentui/react'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IHurdle } from '../../../../../../../../api/datahub'
import { getIsHurdleAdmin } from '../../store/selectors'
import { hurdleEditFormActions } from '../HurdleEdit/store/hurdleEditForm'
import { hurdleEditPanelActions } from '../HurdleEdit/store/hurdleEditPanel'
import { HurdleMenuContainer } from './HurdleMenuContainer'

export const HurdleNameCell: React.FC<{ hurdle: IHurdle }> = ({ hurdle }) => {
  const dispatch = useDispatch()
  const onHurdleClicked = useCallback(() => {
    dispatch(hurdleEditPanelActions.open())
    dispatch(hurdleEditFormActions.updateHurdle(hurdle))
  }, [dispatch, hurdle])
  const isHurdleAdmin = useSelector(getIsHurdleAdmin)

  return (
    <Stack
      horizontal={true}
      tokens={{ childrenGap: 5 }}
      styles={{ root: { minWidth: 0 } }}
    >
      {isHurdleAdmin && <HurdleMenuContainer hurdle={hurdle} />}
      <Link
        key={hurdle.name}
        onClick={onHurdleClicked}
        styles={{ root: { minWidth: 0 } }}
      >
        <Text nowrap={true} block={true}>
          {hurdle.name}
        </Text>
      </Link>
    </Stack>
  )
}
