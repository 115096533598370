import { createAction, ActionType } from 'typesafe-actions'
import { IUploadInvoiceRequest } from './types'

export const UPLOAD_INVOICE_REQUEST = `@modules/@payments/@invoice/UPLOAD_INVOICE_REQUEST`
export const UPLOAD_INVOICE_SUCCESS = `@modules/@payments/@invoice/UPLOAD_INVOICE_SUCCESS`
export const UPLOAD_INVOICE_FAILURE = `@modules/@payments/@invoice/UPLOAD_INVOICE_FAILURE`

export const UploadInvoiceActions = {
  request: createAction(UPLOAD_INVOICE_REQUEST)<IUploadInvoiceRequest>(),
  success: createAction(UPLOAD_INVOICE_SUCCESS)<null>(),
  failure: createAction(UPLOAD_INVOICE_FAILURE)<Error>()
}

export type UploadInvoiceActionType = ActionType<typeof UploadInvoiceActions>

export type InvoiceActionType = UploadInvoiceActionType
