import { getRockefellerApiOptions } from 'store/shared'
import { call, delay, put, takeLatest } from 'typed-redux-saga'
import { fetchFirmService, inserUpdateFirmService } from '../api/firmService'
import { IFirm, IFirmInsertUpdateRes } from '../api/types'
import { firmFetchActions, insertUpdateFirmActions } from './actions'

function* handleFetchFirm(action: ReturnType<typeof firmFetchActions.request>) {
  try {
    yield delay(300)
    const options = yield* call(getRockefellerApiOptions)
    const response: IFirm[] = yield call(() =>
      fetchFirmService(action.payload, options)
    )
    yield put(firmFetchActions.success(response))
  } catch (e: any) {
    yield put(firmFetchActions.failure(e))
  }
}

function* insertUpdateFirm(
  action: ReturnType<typeof insertUpdateFirmActions.request>
) {
  try {
    yield delay(300)
    const options = yield* call(getRockefellerApiOptions)
    const response: IFirmInsertUpdateRes | undefined = yield call(() =>
      inserUpdateFirmService(action.payload, options)
    )
    const msg =
      action.payload?.insertUpdateDelete === 'I'
        ? 'Firm Successfully Added!'
        : action.payload?.insertUpdateDelete === 'U'
        ? 'Firm Updated Successfully!'
        : 'Firm Deleted Successfully!'
    yield put(insertUpdateFirmActions.success({ ...response, msg: msg }))
    yield put(firmFetchActions.request(undefined))
  } catch (e: any) {
    yield put(insertUpdateFirmActions.failure(e))
  }
}
export const sagas = [
  () => takeLatest(firmFetchActions.request, handleFetchFirm),
  () => takeLatest(insertUpdateFirmActions.request, insertUpdateFirm)
]
