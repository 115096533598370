import { createReducer } from 'typesafe-actions'
import { IClient } from '../../../../../../../../api/client.types'
import {
  trustAumSummaryDataActions,
  TrustAumSummaryActionTypes
} from './actions'

export interface ITrustAumSummaryState {
  TrustsData?: IClient[]
  isLoading: boolean
  error?: Error
}

const initialState: ITrustAumSummaryState = {
  isLoading: true,
  error: undefined
}

export const trustAumSummaryReducer = createReducer<
  ITrustAumSummaryState,
  TrustAumSummaryActionTypes
>(initialState)
  .handleAction(trustAumSummaryDataActions.request, (state) => ({
    ...state,
    isLoading: true,
    error: undefined
  }))
  .handleAction(trustAumSummaryDataActions.success, (state, action) => ({
    ...state,
    TrustsData: action.payload,
    isLoading: false,
    error: undefined
  }))
  .handleAction(trustAumSummaryDataActions.failure, (state, action) => ({
    ...state,
    error: action.payload,
    isLoading: false,
    TrustsData: undefined
  }))
