import { lazy } from 'react'
import { IModuleDefinition } from '../types'

const TrustContainer = lazy(() => import('./TrustContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/trusts/*',
    private: true,
    component: TrustContainer
  },
  name: 'Trusts'
}

export default moduleDefinition
