import { ITableHeaderColumnSize } from 'modules/Advisory/modules/Rdot360/shared/ITableHeaderColumnSize'
import { rdot360TableStyles } from 'modules/Advisory/modules/Rdot360/shared/tableStyles'

const PerformanceTableHeaderSizesRow: React.FC<{
  sizes: ITableHeaderColumnSize[]
}> = ({ sizes }) => {
  return (
    <tr css={[rdot360TableStyles.sizesRow]}>
      {sizes.map(({ width, id }) => {
        return (
          <th
            key={id}
            style={{
              width,
              maxWidth: width
            }}
          />
        )
      })}
    </tr>
  )
}

export default PerformanceTableHeaderSizesRow
