import { IModuleDefinition } from 'modules/types'
import { lazy } from 'react'

const Rdot360Container = lazy(() => import('./Rdot360Container'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/client/*',
    private: true,
    component: Rdot360Container
  },
  name: 'Client Dashboard'
}

export * from './store'
export default moduleDefinition
