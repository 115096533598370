import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  subMonths
} from 'date-fns'

import { flow } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { getRevenueWidgetDateRangeOptions } from './DateSelector'

interface IRevenueChartDateStore {
  dateRange?: string
  customDates?: [Date?, Date?]
}

const { actions, reducer } = createSlice({
  name: 'revenueChartDateStore',
  initialState: {
    dateRange: 'Prior 12 Months'
  } as IRevenueChartDateStore,
  reducers: {
    setDateRange: (state, action: PayloadAction<string>) => {
      state.dateRange = action.payload
    },
    setCustomDateRange: (state, action: PayloadAction<[Date?, Date?]>) => {
      state.customDates = action.payload
    }
  }
})

export { reducer as revenueChartStore }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.modules.dashboard.revenueWidget

export const getDateRange = flow(rootSelector, (x) => x.dateRange)
const getCustomDateRange = flow(rootSelector, (x) => x.customDates)

export const useRevenueChartDateStore = () => {
  const dispatch = useDispatch()
  const setDateRange = useCallback(
    (dateRange: string) => {
      dispatch(actions.setDateRange(dateRange))
    },
    [dispatch]
  )
  const setCustomDateRange = useCallback(
    (customDates: [Date?, Date?]) => {
      dispatch(actions.setCustomDateRange(customDates))
    },
    [dispatch]
  )

  const dateRange = useSelector(getDateRange)
  const customDateRange = useSelector(getCustomDateRange)
  return {
    setDateRange,
    setCustomDateRange,
    dateRange,
    customDateRange
  }
}

export const getRevenueWidgetDateRangeDates = createSelector(
  [getDateRange, getCustomDateRange],
  (range, customDates): [Date, Date] | undefined => {
    const options = getRevenueWidgetDateRangeOptions()
    const [option] = options.filter(({ key }) => range === key)
    if (!option) {
      if (range === 'Custom') {
        const now = new Date()
        const startDate = customDates?.[0]
          ? startOfMonth(customDates?.[0])
          : subMonths(startOfMonth(now), 11)
        const endDate = customDates?.[1]
          ? endOfMonth(customDates?.[1])
          : endOfMonth(now)
        return [startDate, endDate]
      } else {
        return
      }
    }

    if (option.type === 'YEAR' && option.value) {
      const year = parseInt(option.value)
      const start = new Date(year, 0, 1)

      return [startOfYear(start), endOfYear(start)]
    }

    if (option.key === 'Prior 12 Months') {
      const now = new Date()
      const startTz = subMonths(startOfMonth(now), 11)
      const endTz = endOfMonth(now)

      return [startTz, endTz]
    }
  }
)
