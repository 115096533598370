import axios from 'axios'
import { IClient } from '../../../../../../../../api/client.types'
import { ISearchResult } from '../../../../../../../../api/common.types'
import { IApiOptions } from '../../../../../../../../shared/contracts/IApiOptions'

export const fetchTrustsAumSummary = (
  businessSegments: string,
  options: IApiOptions
) => {
  const select = `select=ausHistory,LegalEntityID,LegalEntityName,BusinessSegment`
  const filter = `$filter=clientServices/any(x: search.in(x/providercode, 'TDE,TES,TNS')) and search.in(BusinessSegment, '${businessSegments}')`
  const url = `${options.apiRoot}/search/clients?queryType=full&${select}&${filter}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<ISearchResult<IClient>>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data.value)
}
