import { combineReducers } from 'redux'
import {
  accountLinkingRequestListReducer,
  accountLinkingRequestListSagas
} from './AccountLinkingRequestList'

export const accountLinkingRequestsFeaturesReducer = combineReducers({
  accountLinkingRequestList: accountLinkingRequestListReducer
})

export const accountLinkingRequestsFeaturesSagas = [
  ...accountLinkingRequestListSagas
]
