import { addMonths } from 'date-fns'
import { orderBy, range } from 'lodash'
import { isNotNullOrEmpty } from 'shared/guards'
import {
  IIncomeSummaryResponse,
  IIncomeSummaryResponseItem
} from '../../store/holdingsApi/IIncomeSummaryResponse'
import { IncomeTableData, IncomeTableDataMonth } from './types'

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const getMonthlyData = (
  income?: Partial<IIncomeSummaryResponseItem> &
    Record<string, number | unknown>,
  month?: string,
  date?: string
): IncomeTableDataMonth => {
  const dividends = income?.[`${month}${'Div'}`]
  const interest = income?.[`${month}${'Int'}`]
  const capGain = income?.[`${month}${'Cap'}`]
  const total = income?.[`${month}${'Total'}`]
  const totalWithoutOptions =
    income?.[`${month}${'TotalExcludingOptionPremiums'}`]

  return {
    date,
    dividends,
    interest,
    capGain,
    total,
    totalWithoutOptions
  } as IncomeTableDataMonth
}

export const getIncomeDatesFromApiResponse = (data?: Record<string, unknown>) =>
  months.map((month) => data?.[`${month}${'PayDate'}`] as string | undefined)

export const convertIncomeApiResponseToMonthArrays = (
  data?: IIncomeSummaryResponse
) => {
  const dates = getIncomeDatesFromApiResponse(
    data as Record<string, string | unknown>
  ).filter(isNotNullOrEmpty)
  return (
    data?.IncomeSubTotal?.map(
      ({ CUSIPNUM, ACCT, SECNAME, SECDESCRIPTION, ...x }): IncomeTableData => {
        const monthTotals = months.map((month, i) =>
          getMonthlyData(x, month, dates[i])
        )
        return {
          CUSIPNUM,
          ACCT,
          SECNAME,
          SECDESCRIPTION,
          months: monthTotals
        }
      }
    ) ?? []
  )
}

export const getHistoricalIncomeDates = (
  financialYear?: number,
  isCurrentYear = false
) => {
  const year = financialYear ?? new Date().getFullYear()
  const now = new Date()
  const end = year === now.getFullYear() && !isCurrentYear ? now.getMonth() : 11

  const dates = range(-11, 1)
    .map((x) => x + end)
    .map((x) => ({
      year: x < 0 ? year - 1 : year,
      month: x < 0 ? 12 + x : x
    }))
    .map(({ year, month }) => new Date(year, month, 1))

  return orderBy(dates, (x) => x, 'asc')
}

export const getProjectedIncomeDates = (isCurrentYear = false) => {
  const startDate = isCurrentYear ? new Date() : addMonths(new Date(), 1)
  const year = startDate.getFullYear()
  const start = startDate.getMonth()
  return range(0, 12)
    .map((x) => x + start)
    .map((x) => ({
      year: x > 11 ? year + 1 : year,
      month: x > 11 ? x - 12 : x
    }))
    .map(({ year, month }) => new Date(year, month, 1))
}
