import { css } from '@emotion/react'
import { MessageBar, MessageBarType } from '@fluentui/react'
import { FC, useMemo } from 'react'
import { SharedReportClientInfo, SharedReportTypes } from '../types'

const getClasses = () => ({
  list: css({
    marginBottom: 0,
    paddingLeft: 20,
    fontSize: 12
  })
})

interface IMessageProps {
  message: string
  type: SharedReportTypes
  clientInfo: SharedReportClientInfo[]
}

export const Message: FC<IMessageProps> = ({ message, type, clientInfo }) => {
  const classes = useMemo(() => getClasses(), [])
  const isSuccess = type === 'success'
  const messageBarType = isSuccess
    ? MessageBarType.success
    : MessageBarType.error

  return (
    <>
      <MessageBar messageBarType={messageBarType}>{message}</MessageBar>
      {isSuccess ? (
        <p css={{ fontSize: 12 }}>
          {clientInfo.map((client) => client.name).join(', ')}
        </p>
      ) : (
        <ul css={classes.list}>
          {clientInfo.map((client, i) => (
            <li key={`${client.loginId}-${i}`}>
              {client.name} - <em>{client.errorMessage}</em>
            </li>
          ))}
        </ul>
      )}
    </>
  )
}
