import { lazy } from 'react'
import { IModuleDefinition } from '../types'

const ReportsContainer = lazy(() => import('./ReportsContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/reports/*',
    private: true,
    component: ReportsContainer
  },
  name: 'Reports'
}

export default moduleDefinition
