import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { AxiosError } from 'axios'
import pRetry, { AbortError } from 'p-retry'
import { datahubApi } from 'store/api/datahub'
import { AxiosBaseArgs } from 'store/api/shared'
import { AppState } from 'store/shared'

export type UnknownPreferences = Record<string, unknown>
export interface IPreferencesContainer<T = UnknownPreferences> {
  value?: T
  errorOnFetch?: boolean
}

type Response = QueryReturnValue<
  IPreferencesContainer<UnknownPreferences>,
  AxiosError
>

const getPreferenceApiPath = (containerName: string) =>
  `/preferences/${containerName}`

const enhancedDatahubApi = datahubApi.enhanceEndpoints({
  addTagTypes: ['Preferences']
})

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const preferencesApi = enhancedDatahubApi.injectEndpoints({
  endpoints: (builder) => ({
    getPreferencesContainer: builder.query<
      IPreferencesContainer<UnknownPreferences> | undefined,
      { containerId: string }
    >({
      keepUnusedDataFor: 60 * 60 * 24,
      queryFn: async ({ containerId }, _api, _extraOptions, baseQuery) => {
        const apiArgs: Partial<AxiosBaseArgs> = {
          url: getPreferenceApiPath(containerId),
          method: 'GET',
          timeout: 3000
        }

        const result = (await pRetry(
          async () => {
            const baseResult = (await baseQuery(apiArgs)) as Response

            if (baseResult.error?.response?.status === 404) {
              throw new AbortError(baseResult.error)
            }

            if (baseResult.error) {
              throw baseResult.error
            }

            return baseResult as any
          },
          { retries: 3 }
        ).catch((e: AxiosError) => ({ error: e } as Response))) as Response

        if (result?.error) {
          return {
            data: {
              errorOnFetch: result.error?.response?.status !== 404,
              value: {}
            }
          }
        }

        return {
          data: { ...result.data }
        }
      },
      providesTags: (_, _1, { containerId }) => [
        { type: 'Preferences', id: containerId }
      ]
    }),
    setPreferencesInContainer: builder.mutation<
      IPreferencesContainer<UnknownPreferences>,
      { containerId: string; value: UnknownPreferences }
    >({
      queryFn: async (
        { containerId, value },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const apiArgs = {
          url: getPreferenceApiPath(containerId),
          method: 'POST',
          data: value
        }

        const { getState } = _api
        const state = getState() as any
        const { data: cache } =
          preferencesApi.endpoints.getPreferencesContainer.select({
            containerId
          })(state)

        if (cache?.errorOnFetch) {
          await delay(0)
          return { data: { value: {} } }
        }

        const result = (await pRetry(() => baseQuery(apiArgs), {
          retries: 3
        })) as Response

        return result
      },
      onQueryStarted: async (
        request,
        { dispatch, queryFulfilled, getState }
      ) => {
        const { containerId, value } = request
        const state = getState()
        const { data: cache } =
          preferencesApi.endpoints.getPreferencesContainer.select({
            containerId
          })(state)

        // removed this because it causees render batching issues
        // await delay(0) // this improves rendering performance
        if (cache) {
          dispatch(
            preferencesApi.util.updateQueryData(
              'getPreferencesContainer',
              { containerId },
              (draft) => {
                Object.entries(value).forEach(([key, newValue]) => {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  draft!.value![key] = newValue
                })
              }
            )
          )
        } else {
          const newContainer = {
            value
          } as IPreferencesContainer<UnknownPreferences>

          dispatch(
            preferencesApi.util.upsertQueryData(
              'getPreferencesContainer',
              { containerId },
              newContainer
            )
          )
        }

        try {
          await queryFulfilled
        } catch {
          dispatch(
            preferencesApi.util.upsertQueryData(
              'getPreferencesContainer',
              { containerId },
              cache
            )
          )
        }
      }
    }),
    deleteContainer: builder.mutation<
      IPreferencesContainer<UnknownPreferences> | undefined,
      { containerId: string }
    >({
      query: ({ containerId }) => ({
        url: getPreferenceApiPath(containerId),
        method: 'DELETE'
      })
    })
  })
})

export const {
  useGetPreferencesContainerQuery,
  useLazyGetPreferencesContainerQuery,
  useSetPreferencesInContainerMutation,
  useDeleteContainerMutation
} = preferencesApi

export const createPreferencesContainerSelector = (containerId: string) =>
  preferencesApi.endpoints.getPreferencesContainer.select({
    containerId
  }) as (
    state: AppState
  ) => ReturnType<
    ReturnType<typeof preferencesApi.endpoints.getPreferencesContainer.select>
  >
