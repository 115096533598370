import { combineReducers } from 'redux'
import {
  advisorHurdleTableReducer,
  advisorHurdleTableSagas
} from './AdvisorHurdleTable'

export const hurdlesDashboardFeaturesReducer = combineReducers({
  advisorHurdleTable: advisorHurdleTableReducer
})

export const hurdlesDashboardFeaturesSagas = [...advisorHurdleTableSagas]
