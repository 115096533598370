import { lazy } from 'react'
import { IModuleDefinition } from '../types'

const FeesContainer = lazy(() => import('./FeesContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/fees/*',
    private: true,
    component: FeesContainer
  },
  name: 'Fees'
}

export default moduleDefinition
