import type { Path } from 'history'
import { createAction } from 'typesafe-actions'

type To = string | Partial<Path>

/**
 * @deprecated use useNavigate from react-router-dom
 */
export const push = createAction('@history/push')<To>()

/**
 * @deprecated use useNavigate from react-router-dom
 */
export const replace = createAction('@history/replace')<To>()

/**
 * @deprecated use useNavigate from react-router-dom
 */
export const goBack = createAction('@history/goBack')()
