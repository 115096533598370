import { createReducer } from 'typesafe-actions'
import {
  dynamicsExportActions,
  PaymentActionType,
  paymentsDataActions,
  paymentsMenuActions,
  pilotFetaureActions,
  setActiveClientAction,
  setActiveTrustSearchAction
} from './actions'
import { IMatrixInfo, IPaymentOption, IPilotFeaturesList } from './types'

export interface IPaymentsState {
  matrixdata: IMatrixInfo[]
  paymentOptions: IPaymentOption[]
  isMatrixInfoLoaded: boolean
  error?: Error
  activeClient?: IMatrixInfo
  pilotFeatureList?: IPilotFeaturesList
  isPilotFeatureLoading?: boolean
  errorPilotFeature?: Error
  dynamicsExportDetails?: {
    error?: Error
    isLoading?: boolean
    exportStatus?: boolean
  }
  trustSearchText?: string
}

const initialState: IPaymentsState = {
  matrixdata: [],
  paymentOptions: [],
  isMatrixInfoLoaded: false,
  activeClient: undefined,
  pilotFeatureList: undefined,
  isPilotFeatureLoading: false,
  errorPilotFeature: undefined,
  dynamicsExportDetails: undefined,
  trustSearchText: undefined
}

export const dashboardReducer = createReducer<
  IPaymentsState,
  PaymentActionType
>(initialState)
  .handleAction(paymentsDataActions.request, (state) => ({
    ...state,
    isMatrixInfoLoaded: false,
    dynamicsExportDetails: undefined
  }))
  .handleAction(paymentsDataActions.success, (state, action) => ({
    ...state,
    matrixdata: action.payload,
    isMatrixInfoLoaded: true
  }))
  .handleAction(paymentsDataActions.failure, (state, action) => ({
    ...state,
    error: action.payload
  }))
  .handleAction(paymentsMenuActions.success, (state, action) => ({
    ...state,
    paymentOptions: action.payload
  }))
  .handleAction(setActiveClientAction.setActiveClient, (state, action) => ({
    ...state,
    activeClient: action.payload
  }))
  .handleAction(setActiveClientAction.clearActiveClient, (state) => ({
    ...state,
    activeClient: undefined
  }))
  .handleAction(pilotFetaureActions.request, (state) => ({
    ...state,
    isPilotFeatureLoading: true,
    dynamicsExportDetails: undefined
  }))
  .handleAction(pilotFetaureActions.success, (state, action) => ({
    ...state,
    pilotFeatureList: action.payload,
    isPilotFeatureLoading: false
  }))
  .handleAction(pilotFetaureActions.failure, (state, action) => ({
    ...state,
    errorPilotFeature: action.payload,
    isPilotFeatureLoading: false
  }))
  .handleAction(dynamicsExportActions.request, (state) => ({
    ...state,
    dynamicsExportDetails: {
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(dynamicsExportActions.success, (state) => ({
    ...state,
    dynamicsExportDetails: {
      isLoading: false,
      error: undefined,
      exportStatus: true
    }
  }))
  .handleAction(dynamicsExportActions.failure, (state, action) => ({
    ...state,
    dynamicsExportDetails: {
      isLoading: false,
      error: action.payload,
      exportStatus: false
    }
  }))
  .handleAction(setActiveTrustSearchAction.setActiveTrust, (state, action) => ({
    ...state,
    trustSearchText: action.payload
  }))
  .handleAction(setActiveTrustSearchAction.clearActiveTrust, (state) => ({
    ...state,
    trustSearchText: undefined
  }))
