import { MessageBarType } from '@fluentui/react'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import { pushNotification } from 'features/Notifications'
import { downloadUrlAsFile } from 'shared/downloads'
import { isNotNullOrUndefined } from 'shared/guards'
import { createAsyncReducer } from 'store/shared/asyncStore'
import { getAllPagedOdataApiResults } from 'store/shared/sagas/odata'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import {
  fetchAltsAdvisorRevenueDetail,
  selectors as altsAdvisorRevenueDetailSelectors
} from '../store'
import { ExcelColumnConfigType, ExcelExportWorker } from './worker'

export const altsAdvRevDetExcelExportActions = createAsyncAction(
  '@modules/@airevenue/@modules/@dashboard/@features/@altsadvisorrevenuedetailExcelExport/EXPORT_REQUEST',
  '@modules/@airevenue/@modules/@dashboard/@features/@altsadvisorrevenuedetailExcelExport/EXPORT_SUCCESS',
  '@modules/@airevenue/@modules/@dashboard/@features/@altsadvisorrevenuedetailExcelExport/EXPORT_FAILURE'
)<IExportRevDetPayload, undefined, Error>()

export interface IExportRevDetPayload {
  dateRange: any
  feeType: any
  repCodes: any
}

export const altsAdvRevDetExcelExportReducer = createAsyncReducer(
  altsAdvRevDetExcelExportActions
)

const onExport = function* (
  action: ReturnType<typeof altsAdvRevDetExcelExportActions.request>
) {
  try {
    const request = yield* select(
      altsAdvisorRevenueDetailSelectors.uiSelectors.getOdataRequest
    )

    const results = yield* call(() =>
      getAllPagedOdataApiResults(request, fetchAltsAdvisorRevenueDetail)
    )

    const resultsMappedFiltered = results
      ?.flatMap((x) => x.result.value)
      ?.filter(isNotNullOrUndefined)

    const columnNames = yield* select(
      altsAdvisorRevenueDetailSelectors.uiSelectors.getSelectedColumns
    )

    const worker = new Worker(new URL('./worker.ts', import.meta.url))

    const { createAndDownloadExcelWorksheet } = wrap<ExcelExportWorker>(worker)

    const excelColumnConfigType: ExcelColumnConfigType =
      'ALTS_ADVISOR_REVENUE_DETAIL'

    const result = yield* call(createAndDownloadExcelWorksheet, [
      {
        data: resultsMappedFiltered,
        selectedColumnNames: columnNames || [],
        excelColumnConfigType,
        tabLabel: 'ALTS_ADVISOR_REVENUE_DETAIL',
        dateRange: action.payload?.dateRange ?? {},
        feeType: action.payload?.feeType ?? {},
        repCodes: action.payload?.repCodes ?? {}
      }
    ])

    const filename = `ALTS_ADVISOR_REVENUE_DETAIL ${format(
      new Date(),
      'MM-dd-yyyy'
    )}.xlsx`
    downloadUrlAsFile(result, filename)
    yield put(altsAdvRevDetExcelExportActions.success())
  } catch (e: unknown) {
    yield put(altsAdvRevDetExcelExportActions.failure(e as Error))
  }
}

const onFailure = function* (
  action: ReturnType<typeof altsAdvRevDetExcelExportActions.failure>
) {
  yield call(pushNotification, {
    message: `Export failed: ${action.payload.message}`,
    type: MessageBarType.error
  })
}

export const altsAdvRevDetExcelExportSagas = [
  () => takeLatest(altsAdvRevDetExcelExportActions.request, onExport),
  () => takeLatest(altsAdvRevDetExcelExportActions.failure, onFailure)
]
