import { ActionType, createAction } from 'typesafe-actions'
import { IMatrixInfo, IPaymentOption, IPilotFeaturesList } from './types'

export const FETCH_MATRIX_INFO = '@modules/@payments/FETCH_MATRIX_INFO'
export const FETCH_MATRIX_INFO_SUCCESS =
  '@modules/@payments/FETCH_MATRIX_INFO_SUCCESS'
export const FETCH_MATRIX_INFO_FAILURE =
  '@modules/@payments/FETCH_MATRIX_INFO_FAILURE'

export const PAYMENTS_MENU_REQ = '@modules/@payments/PAYMENTS_MENU_REQ'
export const PAYMENTS_MENU_SUCCESS = '@modules/@payments/PAYMENTS_MENU_SUCCESS'

export const NAVIGATE_TO_FEE = '@modules/@payments/NAVIGATE_TO_FEE'
export const NAVIGATE_TO_VENDORS = '@modules/@payments/NAVIGATE_TO_VENDORS'
export const NAVIGATE_TO_INVOICE = '@modules/@payments/NAVIGATE_TO_INVOICE'
export const NAVIGATE_TO_PAYMENTS = `@modules/@payments/NAVIGATE_TO_PAYMENTS`
export const NAVIGATE_TO_REPORTS = `@modules/@payments/NAVIGATE_TO_REPORTS`
export const NAVIGATE_TO_GL = `@modules/@payments/NAVIGATE_TO_GL`
export const NAVIGATE_TO_WIRE_INITIATION = `@modules/@payments/@wire/NAVIGATE_TO_WIRE_INITIATION`

export const FETCH_PILOT_FEATURE = '@modules/@payments/ FETCH_PILOT_FEATURE'
export const FETCH_PILOT_FEATURE_SUCCESS =
  '@modules/@payments/FETCH_PILOT_FEATURE_SUCCESS'
export const FETCH_PILOT_FEATURE_FAILURE =
  '@modules/@payments/FETCH_PILOT_FEATURE_FAILURE'

export const HANDLE_DYNAMICS_EXPORT =
  '@modules/@payments/HANDLE_DYNAMICS_EXPORT'
export const HANDLE_DYNAMICS_EXPORT_SUCCESS =
  '@modules/@payments/HANDLE_DYNAMICS_EXPORT_SUCCESS'
export const HANDLE_DYNAMICS_EXPORT_FAILURE =
  '@modules/@payments/HANDLE_DYNAMICS_EXPORT_FAILURE'

export const SET_ACTIVE_CLIENT = '@modules/@payments/SET_ACTIVE_CLIENT'
export const CLEAR_ACTIVE_CLIENT = '@modules/@payments/CLEAR_ACTIVE_CLIENT'

export const paymentsDataActions = {
  request: createAction(FETCH_MATRIX_INFO)(),
  success: createAction(FETCH_MATRIX_INFO_SUCCESS)<IMatrixInfo[]>(),
  failure: createAction(FETCH_MATRIX_INFO_FAILURE)<Error>()
}

export const paymentsNavigationActions = {
  navigateToFee: createAction(NAVIGATE_TO_FEE)(),
  navigateToVendors: createAction(NAVIGATE_TO_VENDORS)<IMatrixInfo>(),
  naviagteToInvoice: createAction(NAVIGATE_TO_INVOICE)<IMatrixInfo>(),
  navigateToPayments: createAction(NAVIGATE_TO_PAYMENTS)(),
  navigateToReports: createAction(NAVIGATE_TO_REPORTS)<IMatrixInfo>(),
  navigateToGl: createAction(NAVIGATE_TO_GL)<IMatrixInfo>(),
  naviagteToWireInitiation: createAction(NAVIGATE_TO_WIRE_INITIATION)()
}

export const paymentsMenuActions = {
  request: createAction(PAYMENTS_MENU_REQ)(),
  success: createAction(PAYMENTS_MENU_SUCCESS)<IPaymentOption[]>()
}

export const setActiveClientAction = {
  setActiveClient: createAction(SET_ACTIVE_CLIENT)<IMatrixInfo>(),
  clearActiveClient: createAction(CLEAR_ACTIVE_CLIENT)()
}
export const SET_ACTIVE_TRUST = '@modules/@payments/SET_ACTIVE_TRUST'
export const CLEAR_ACTIVE_TRUST = '@modules/@payments/CLEAR_ACTIVE_TRUST'
export const setActiveTrustSearchAction = {
  setActiveTrust: createAction(SET_ACTIVE_TRUST)<string>(),
  clearActiveTrust: createAction(CLEAR_ACTIVE_TRUST)()
}

export const pilotFetaureActions = {
  request: createAction(FETCH_PILOT_FEATURE)(),
  success: createAction(FETCH_PILOT_FEATURE_SUCCESS)<IPilotFeaturesList>(),
  failure: createAction(FETCH_PILOT_FEATURE_FAILURE)<Error>()
}

export const dynamicsExportActions = {
  request: createAction(HANDLE_DYNAMICS_EXPORT)(),
  success: createAction(HANDLE_DYNAMICS_EXPORT_SUCCESS)<boolean>(),
  failure: createAction(HANDLE_DYNAMICS_EXPORT_FAILURE)<Error>()
}
export type PaymentsDataActionTypes = ActionType<typeof paymentsDataActions>
export type PaymentsNavigationTypes = ActionType<
  typeof paymentsNavigationActions
>
export type PaymentsMenuActionTypes = ActionType<typeof paymentsMenuActions>
export type SetActiveClientActionType = ActionType<typeof setActiveClientAction>
export type SetActiveTrustActionType = ActionType<
  typeof setActiveTrustSearchAction
>
export type PilotFetureActionTypes = ActionType<typeof pilotFetaureActions>
export type DynamicsExportActionsTypes = ActionType<
  typeof dynamicsExportActions
>

export type PaymentActionType =
  | PaymentsDataActionTypes
  | PaymentsMenuActionTypes
  | PaymentsNavigationTypes
  | SetActiveClientActionType
  | PilotFetureActionTypes
  | DynamicsExportActionsTypes
  | SetActiveTrustActionType
