import { partial } from 'lodash/fp'
import { IClient } from '../../../api/client.types'
import { AppState } from '../../../store'
import { createListsStore } from '../core/store'
import { BaseExportConfigurations } from '../shared/BaseExportConfigurations'
import {
  createDefaultColumnState,
  mapColumnsToFilterDefinitions
} from '../shared/lists'
import { ClientColumnDefinitions } from './ColumnDefinitions'

export const constructInitialClientListColumnState = partial(
  createDefaultColumnState,
  [ClientColumnDefinitions]
)

export const clientListStore = createListsStore<IClient>(
  'client',
  '@features/@lists/@clients',
  {
    columnDefinitions: ClientColumnDefinitions,
    filterDefinitions: mapColumnsToFilterDefinitions(ClientColumnDefinitions)
  },
  (state: AppState) => state.features.lists.client,
  BaseExportConfigurations
)

export const { reducer, sagas, actions, selectors } = clientListStore
