import { ChoiceGroup, IChoiceGroupOption, Stack, Toggle } from '@fluentui/react'
import { ListsFilterType } from 'features/Lists/core/contracts/IListsFilterDefinition'
import React, { memo, useCallback, useMemo } from 'react'
import {
  IListsFacetFilter,
  IListsFilter
} from '../../../contracts/IListsFilter'
import { ListsDateRangeFilterComponent } from '../components/ListsDateRangeFilter'
import { ListsNumberRangeFilterComponent } from '../components/ListsNumberRangeFilter'
import { IListsFilterEditProps } from '../contracts/IListsFilterEditProps'
import { ListsFacetFilterComponent } from './ListsFacetFilter'
import { ListsSearchFilter } from './ListsSearchFilter'

const FacetFilterComponent: React.FC<
  IListsFilterEditProps & { filter: IListsFacetFilter }
> = ({ filter, onChange }) => {
  const { facets, values, mask } = filter
  return (
    <ListsFacetFilterComponent
      facets={facets}
      selectedValues={values}
      maskValues={mask}
      onChange={(selectedValues) =>
        onChange({
          ...filter,
          values: selectedValues,
          hasValue: selectedValues.length > 0
        } as IListsFacetFilter)
      }
    />
  )
}

export const getFilerComponent = (type: ListsFilterType) => {
  switch (type) {
    case 'date':
    case 'date-only':
      return memo(
        ListsDateRangeFilterComponent
      ) as React.FC<IListsFilterEditProps>
    case 'number':
      return memo(
        ListsNumberRangeFilterComponent
      ) as React.FC<IListsFilterEditProps>
    case 'search':
      return memo(ListsSearchFilter) as React.FC<IListsFilterEditProps>
    case 'boolean':
    case 'facet': {
      return memo(FacetFilterComponent) as React.FC<IListsFilterEditProps>
    }
    default: {
      return null
    }
  }
}

export const ListsFilterEdit: React.FC<IListsFilterEditProps> = ({
  onChange,
  filter,
  customFilter
}) => {
  const { type } = filter
  const FilterComponent: React.FC<IListsFilterEditProps> | null =
    useMemo(() => {
      if (customFilter) {
        return customFilter as React.FC<IListsFilterEditProps>
      }
      return getFilerComponent(type)
    }, [customFilter, type])

  const { blankIndicator } = filter

  const onBlankIndicatorChange = useCallback(
    (ev: any, checked?: boolean) => {
      if (checked == null) {
        return
      }
      const newFilter: IListsFilter = {
        ...filter,
        blankIndicator: checked ? 'include' : undefined,
        hasValue: checked
      }
      onChange(newFilter)
    },
    [filter, onChange]
  )

  const onBlankIndicatorChoiceChange = useCallback(
    (ev: any, option?: IChoiceGroupOption) => {
      if (!option) {
        return
      }

      const newFilter: IListsFilter = {
        ...filter,
        blankIndicator: option.key as any
      }
      onChange(newFilter)
    },
    [filter, onChange]
  )

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Toggle
        label="Filter for (Blank)"
        checked={!!blankIndicator}
        onChange={onBlankIndicatorChange}
      />
      {blankIndicator && (
        <ChoiceGroup
          selectedKey={blankIndicator}
          onChange={onBlankIndicatorChoiceChange}
          options={[
            { key: 'include', text: 'Include (Blank)' },
            { key: 'exclude', text: 'Exclude (Blank)' }
          ]}
        />
      )}
      {!blankIndicator && FilterComponent && (
        <FilterComponent onChange={onChange} filter={filter} />
      )}
    </Stack>
  )
}
