import { lazy } from 'react'
import { IModuleDefinition } from '../../../types'

const RevenueContainer = lazy(() => import('./RevenueContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/revenue/*',
    private: true,
    component: RevenueContainer
  },
  name: 'Revenue'
}

export default moduleDefinition
export * from './store'
