import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { call, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { getManagedAccountFeeBillTypes } from '../../../../../../../api/datahub'
import { AppState } from '../../../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../../../../../../store/shared/sagas'

export const managedAccountFeeHistoryBillTypesFetchActions = createAsyncAction(
  '@modules/@advisory/@modules/@accounts/@modules/@managedAccountFeeHistory/@billTypesFetch/REQUEST',
  '@modules/@advisory/@modules/@accounts/@modules/@managedAccountFeeHistory/@billTypesFetch/SUCCESS',
  '@modules/@advisory/@modules/@accounts/@modules/@managedAccountFeeHistory/@billTypesFetch/FAILURE'
)<undefined, string[], Error>()

export const managedAccountFeeHistoryBillTypesFetchReducer = createAsyncReducer(
  managedAccountFeeHistoryBillTypesFetchActions
)

const rootSeleector = (state: AppState) =>
  state.modules.advisory.modules.fees.modules.managedAccountFeeHistory
    .billTypesFetch
const selectors = createAsyncSelectors(rootSeleector)

const onRequest = function* () {
  try {
    const apiOptions = yield* call(getRockefellerApiOptions)
    const result = yield* call(getManagedAccountFeeBillTypes, apiOptions)

    if (!result?.value) {
      throw new Error('No result returned from api')
    }

    yield put(
      managedAccountFeeHistoryBillTypesFetchActions.success(result.value)
    )
  } catch (e: any) {
    yield put(managedAccountFeeHistoryBillTypesFetchActions.failure(e))
  }
}

export const managedAccountFeeHistoryBillTypesFetchSagas = [
  () =>
    takeLatest(managedAccountFeeHistoryBillTypesFetchActions.request, onRequest)
]

export const useManagedAccountFeeHistoryBillTypes = (autoRequest = false) => {
  const dispatch = useDispatch()
  const billTypes = useSelector(selectors.getResult)
  const error = useSelector(selectors.getError)
  const isLoading = useSelector(selectors.getIsLoading)

  const fetchBillTypes = useCallback(
    (force = false) => {
      if (!force && (isLoading || billTypes)) {
        return
      }

      dispatch(managedAccountFeeHistoryBillTypesFetchActions.request())
    },
    [billTypes, dispatch, isLoading]
  )

  useEffect(() => {
    if (!autoRequest || error) {
      return
    }

    fetchBillTypes()
  }, [autoRequest, error, fetchBillTypes])

  return {
    billTypes,
    error,
    isLoading,
    fetchBillTypes
  }
}
