import {
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable
} from '@tanstack/react-table'
import { map, sum } from 'lodash'
import { IActivity } from '../../../../store/types'
import { constructColumns } from '../../ActivityDetailsTable/ActivityDetailsTableColumns'
import { useActivityDetailUiState } from '../../activityDetailsUiState'
import { activityDetailColumns } from '../../shared'
import { PrintNoActivity } from './PrintNoActivity'
import { PrintTableBody } from './PrintTableBody'
import { PrintTableFooter } from './PrintTableFooter'
import { PrintTableHeader } from './PrintTableHeader'

export interface IActivityTableProps {
  data?: IActivity[]
  account: string
  maskedAccount: string
}

export const PrintTable: React.FC<IActivityTableProps> = ({
  data: activityData,
  account,
  maskedAccount
}) => {
  const showCol =
    activityData?.some(
      (activity) =>
        activity.cusipnumber ||
        activity.secid ||
        activity.unitprice ||
        activity.units
    ) ?? false
  const columnVisibility: VisibilityState = {
    [activityDetailColumns.price]: showCol,
    [activityDetailColumns.quantity]: showCol,
    [activityDetailColumns.symbolCusip]: showCol,
    [activityDetailColumns.cusip]: false,
    [activityDetailColumns.symbol]: false,
    [activityDetailColumns.account]: false,
    [activityDetailColumns.nickName]: false,
    [activityDetailColumns.entryDate]: false,
    [activityDetailColumns.settlementDate]: false,
    [activityDetailColumns.registrationType]: false
  }

  const columns = constructColumns()
  const data = activityData || []
  const { searchText } = useActivityDetailUiState()

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      globalFilter: searchText
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  })

  const allRows = table.getRowModel().rows
  const values = map(allRows, (row) => row.getValue('Amount') as number)
  const netAmount = sum(values)
  const headers = table.getFlatHeaders()
  const isData = allRows.length !== 0

  return (
    <>
      {isData ? (
        <table className="print-table">
          <PrintTableHeader
            headers={headers}
            account={account}
            maskedAccount={maskedAccount}
            netAmount={netAmount}
          />
          <PrintTableBody rows={allRows} />
          <PrintTableFooter colspan={headers.length} />
        </table>
      ) : (
        <div className="top-margin next-page">
          <PrintNoActivity />
        </div>
      )}
    </>
  )
}
