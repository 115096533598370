import type {
  AuthenticationResult,
  AuthError,
  Configuration,
  LogLevel,
  RedirectRequest,
  PopupRequest
} from '@azure/msal-browser'
import { getEnvironment } from '../environment'
import { LoginTypes } from './types'

const defaultConfig: Configuration = {
  auth: {
    clientId: '',
    authority: '',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    // TODO - if this is set, we do an extra navigation, but if it's not, the URL ends with a hash and it breaks msal
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  }
}

const log = (message: string, ...args: any[]) => {
  console.debug(message, ...args)
}

const init = async () => {
  const [{ PublicClientApplication, LogLevel }, { msal }] = await Promise.all([
    import('@azure/msal-browser'),
    getEnvironment()
  ])

  const config = {
    ...defaultConfig,
    system: {
      ...defaultConfig.system,
      loggerOptions: {
        loggerCallback: (logLevel: LogLevel, message: string) => {
          log(`MSAL: [${logLevel}] ${message}`)
        },
        logLevel: LogLevel.Warning,
        piiLoggingEnabled: true
      }
    },
    auth: {
      ...defaultConfig.auth,
      clientId: msal.clientId,
      authority: msal.authority
    }
  } as Configuration

  const publicClientApplication = new PublicClientApplication(config)
  await publicClientApplication.initialize()

  try {
    const response = await publicClientApplication.handleRedirectPromise()
    const account =
      response?.account || publicClientApplication.getAllAccounts()?.[0]
    if (account) {
      publicClientApplication.setActiveAccount(account)
    }

    return {
      publicClientApplication,
      redirect: {
        state: response?.state,
        accountId: response?.account?.homeAccountId
      }
    }
  } catch (e) {
    log('redirect failure', e)
    return { publicClientApplication, redirect: { error: e } }
  }
}

let initPromise = init()

export const ready = async () => {
  await initPromise
}

export const reset = async () => {
  const { publicClientApplication } = await initPromise
  const accountIds = publicClientApplication
    ?.getAllAccounts()
    .map((x) => x.homeAccountId)
  Object.keys(localStorage).forEach((key) => {
    const shouldRemoveKey =
      accountIds.some((x) => key.indexOf(x) >= 0) || key.indexOf('msal.') >= 0
    if (shouldRemoveKey) {
      localStorage.removeItem(key)
    }
  })
  initPromise = init()
}

export const login = async (
  type: LoginTypes = 'redirect',
  request: PopupRequest | RedirectRequest
): Promise<AuthenticationResult | void> => {
  const { publicClientApplication } = await initPromise

  if (type === 'popup') {
    return publicClientApplication?.loginPopup(request)
  } else {
    return publicClientApplication?.loginRedirect(request)
  }
}

export const getAccount = async () => {
  const { publicClientApplication } = await initPromise
  return publicClientApplication.getActiveAccount()
}

export const logout = async () => {
  const { publicClientApplication } = await initPromise
  const account = await getAccount()

  try {
    await publicClientApplication?.logoutRedirect({
      account: account || undefined
    })
  } catch (e) {
    await reset()
    await publicClientApplication?.logoutRedirect()
  }
}

export const getRedirectState = async () => {
  const { redirect } = await initPromise
  return redirect.state
}

export const getRedirectError = async () => {
  const { redirect } = await initPromise
  return redirect.error as AuthError | undefined
}

export const errorRequiresInteraction = (error?: Error) => {
  return error?.name === 'InteractionRequiredAuthError'
}

let currentInteraction: Promise<unknown> | null = null
export const acquireAccessToken = async (
  type: LoginTypes = 'silent',
  request: PopupRequest | RedirectRequest
): Promise<AuthenticationResult | void> => {
  const { publicClientApplication } = await initPromise

  if (currentInteraction) {
    try {
      await currentInteraction
    } finally {
      currentInteraction = null
    }
  }

  if (type === 'silent') {
    return publicClientApplication.acquireTokenSilent(request)
  }

  const interaction = (() => {
    switch (type) {
      case 'popup':
        return publicClientApplication.acquireTokenPopup(request)
      case 'redirect':
        return publicClientApplication.acquireTokenRedirect(request)
    }
  })()

  currentInteraction = Promise.race([
    interaction,
    new Promise((resolve) => setTimeout(resolve, 30000))
  ])

  return interaction
}
