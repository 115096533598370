import {
  call,
  put,
  takeLatest
  // tslint:disable-next-line: no-submodule-imports
} from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../store/shared/sagas'
import {
  fetchFailedPaymentList,
  retryFailedPayment
} from '../api/failedPaymentService'
import {
  failedPaymentsDataActions,
  retryFailedPaymentsActions
} from './actions'

function* handleFetchFailedPayments() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(fetchFailedPaymentList, options)
    yield put(failedPaymentsDataActions.success(data))
  } catch (e: any) {
    yield put(failedPaymentsDataActions.failure(e))
  }
}

function* handleRetryPayment(
  action: ReturnType<typeof retryFailedPaymentsActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    yield call(() =>
      retryFailedPayment(
        action.payload?.payment,
        action.payload?.action,
        options
      )
    )
    yield put(failedPaymentsDataActions.request())
  } catch (e: any) {
    yield put(retryFailedPaymentsActions.failure(e))
  }
}

export const sagas = [
  () =>
    takeLatest(failedPaymentsDataActions.request, handleFetchFailedPayments),
  () => takeLatest(retryFailedPaymentsActions.request, handleRetryPayment)
]
