import { Text } from '@fluentui/react'
import React from 'react'
import { IDynamicsTeam } from '../../../../../../../../api/dynamics'
import { createEntitySelector } from './EntitySelector'
import { useTeamSearchFetch } from './store/teamSearchFetch'

const TeamSearchAutoCompleteItem: React.FC<{
  item?: IDynamicsTeam
}> = ({ item }) => {
  return (
    <div style={{ padding: '6px 10px', minWidth: 0 }}>
      <Text nowrap>{item?.name}</Text>
    </div>
  )
}

export const TeamEntitySelector = createEntitySelector(
  useTeamSearchFetch,
  TeamSearchAutoCompleteItem
)
