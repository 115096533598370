import { combineReducers } from 'redux'
import { IModuleDefinition } from '../../types'
import Accounts, {
  accountsModuleReducer,
  accountsModuleSagas
} from './Accounts'
import AI, { aiModuleReducer, aiModuleSagas } from './AI'
import Alerts from './Alerts'
import { alertsModuleSagas, alertsReducer } from './Alerts/store'
import AnnuitiesAndInsuranceContainer, {
  annuitiesAndInsuranceModuleReducer,
  annuitiesAndInsuranceModuleSagas
} from './AnnuitiesAndInsurance'
import BDA, { bdaReducer, bdaSagas } from './BDA'
import Clients, { clientsModuleReducer, clientsModuleSagas } from './Clients'
import Dashboard, { dashboardReducer, dashboardSagas } from './Dashboard'
import {
  dashboardFeaturesReducer,
  dashboardFeaturesSagas
} from './Dashboard/features'
import Fees, { feesModuleReducer, feesModuleSagas } from './Fees'
import HomeOffice, {
  homeOfficeModuleReducer,
  homeOfficeModuleSagas
} from './HomeOffice'
import Lists from './Lists'
import Rdot360, { rdot360ModuleReducer, rdot360ModuleSagas } from './Rdot360'
import Reports, {
  advisoryReportsReducer,
  advisoryReportsSagas
} from './Reports'
import Requests from './Requests'
import Retirement, { retirementReducer, retirementSagas } from './Retirement'
import Revenue, { revenueModuleReducer, revenueModuleSagas } from './Revenue'
import RevenuePostSplit, { revenuePostSplitReducer } from './RevenuePostSplit'
import Teams, { teamsModuleReducer, teamsModuleSagas } from './Teams'

export default [
  AI,
  AnnuitiesAndInsuranceContainer,
  Dashboard,
  BDA,
  Reports,
  Clients,
  Retirement,
  Revenue,
  RevenuePostSplit,
  Accounts,
  Requests,
  Teams,
  Rdot360,
  HomeOffice,
  Alerts,
  Lists,
  Fees
] as IModuleDefinition[]

export const advisoryModulesReducer = combineReducers({
  dashboardFeatures: dashboardFeaturesReducer,
  dashboard: dashboardReducer,
  bda: bdaReducer,
  reports: advisoryReportsReducer,
  clients: clientsModuleReducer,
  retirement: retirementReducer,
  revenue: revenueModuleReducer,
  revenuePostSplit: revenuePostSplitReducer,
  accounts: accountsModuleReducer,
  teams: teamsModuleReducer,
  rdot360: rdot360ModuleReducer,
  ai: aiModuleReducer,
  homeOffice: homeOfficeModuleReducer,
  annuitiesAndInsurance: annuitiesAndInsuranceModuleReducer,
  alerts: alertsReducer,
  fees: feesModuleReducer
})

export const advisoryModulesSagas = [
  ...dashboardFeaturesSagas,
  ...dashboardSagas,
  ...bdaSagas,
  ...advisoryReportsSagas,
  ...clientsModuleSagas,
  ...retirementSagas,
  ...revenueModuleSagas,
  ...accountsModuleSagas,
  ...teamsModuleSagas,
  ...aiModuleSagas,
  ...homeOfficeModuleSagas,
  ...annuitiesAndInsuranceModuleSagas,
  ...rdot360ModuleSagas,
  ...alertsModuleSagas,
  ...feesModuleSagas
]
