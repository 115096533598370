import { IModuleDefinition } from 'modules/types'
import { combineReducers } from 'redux'
import { investmentsModuleFeatureReducer } from './features'
import { InvestmentsContainer } from './InvestmentsContainer'

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/investments/*',
    private: true,
    component: InvestmentsContainer
  },
  name: 'Investments'
}

export default moduleDefinition

export const investmentsModuleReducer = combineReducers({
  features: investmentsModuleFeatureReducer
})
