import { lazy } from 'react'
import { combineReducers } from 'redux'
import { IModuleDefinition } from '../../../../../types'
import {
  marginRateRequestsFeaturesReducer,
  marginRateRequestsFeaturesSagas
} from './features'

const ReportsContainer = lazy(() => import('./MarginRateRequestsContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/MarginRateRequests',
    private: true,
    component: ReportsContainer
  },
  name: 'Margin Rate Requests'
}

export default moduleDefinition

export const marginRateRequestsModuleReducer = combineReducers({
  features: marginRateRequestsFeaturesReducer
})

export const marginRateRequestsModuleSagas = [
  ...marginRateRequestsFeaturesSagas
]
