import { HTMLProps, PropsWithChildren } from 'react'

export const Link = ({
  children,
  ...props
}: HTMLProps<HTMLAnchorElement> & PropsWithChildren) => {
  return (
    <a
      css={{
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '13px',
        textDecorationLine: 'underline',
        color: '#2E536F',
        cursor: 'pointer'
      }}
      {...props}
    >
      {children}
    </a>
  )
}

export const SecondaryLink = ({
  children,
  ...props
}: HTMLProps<HTMLAnchorElement> & PropsWithChildren) => (
  <a
    css={{
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '11px',
      lineHeight: '14px',
      textDecorationLine: 'underline',
      color: '#1A75C9',
      cursor: 'pointer'
    }}
    {...props}
  >
    {children}
  </a>
)
