import { MessageBarType } from '@fluentui/react'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { pushNotification } from '../../../../../../../../../features/Notifications'
import { downloadUrlAsFile } from '../../../../../../../../../shared/downloads'
import { AppState } from '../../../../../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../../../store/shared/asyncStore'
import { getRevenueDashboardCategoryMode } from '../../../store/dashboard'
import { RevenueSummaryTableExportWorker } from '../export'
import { getMonthHeadings, getTableItems } from './revenueSummaryTable'

export const revenueSummaryTableExportActions = createAsyncAction(
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@revenueSummaryTable/EXPORT_REQUEST',
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@revenueSummaryTable/EXPORT_SUCCESS',
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@revenueSummaryTable/EXPORT_FAILURE'
)<undefined, undefined, Error>()

export const revenueSummaryTableExportReducer = createAsyncReducer(
  revenueSummaryTableExportActions
)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.revenue.modules.dashboard.features
    .revenueSummaryTable.revenueSummaryExport

export const {
  getError: getRevenueSummaryTableExportError,
  getIsLoading: getIsRevenueSummaryTableExportLoading
} = createAsyncSelectors(rootSelector)

const onExport = function* () {
  try {
    const tableItems = yield* select(getTableItems)
    const monthHeadings = yield* select(getMonthHeadings)
    const categoryMode = yield* select(getRevenueDashboardCategoryMode)

    const worker = new Worker(new URL('../export.ts', import.meta.url))
    const { createAndDownloadRevenueSummaryTableExport } =
      wrap<RevenueSummaryTableExportWorker>(worker)

    const result = yield* call(
      createAndDownloadRevenueSummaryTableExport,
      tableItems || [],
      monthHeadings || [],
      categoryMode
    )

    const filename = `Revenue Summary Table ${format(
      new Date(),
      'MM-dd-yyyy'
    )}.xlsx`
    downloadUrlAsFile(result, filename)
    yield put(revenueSummaryTableExportActions.success())
  } catch (e: unknown) {
    yield put(revenueSummaryTableExportActions.failure(e as Error))
  }
}

const onFailure = function* (
  action: ReturnType<typeof revenueSummaryTableExportActions.failure>
) {
  yield call(pushNotification, {
    message: `Export failed: ${action.payload.message}`,
    type: MessageBarType.error
  })
}

export const revenueSummaryTableExportSagas = [
  () => takeLatest(revenueSummaryTableExportActions.request, onExport),
  () => takeLatest(revenueSummaryTableExportActions.failure, onFailure)
]
