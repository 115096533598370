import { Dialog } from '@fluentui/react'
import { useCallback } from 'react'
import { SimpleOverlay } from 'shared/components/SimpleOverlay'
import { IndeterminateProgressIndicator } from '../../../../components/shared'
import { buttonStyles } from '../../../../components/shared/Buttons'
import { SnackBar } from '../../../../components/shared/Snackbar'
import { useRdot360_deleteCustomAccountGroupMutation } from '../../../../store/dynamics'
import { useDeleteCustomGroupStore } from './store'

export const DeleteCustomGroupModal: React.FC = () => {
  const { groupToDelete, setGroupToDelete } = useDeleteCustomGroupStore()
  const onDismiss = useCallback(() => {
    setGroupToDelete(undefined)
  }, [setGroupToDelete])
  const [deleteItem, deleteResult] =
    useRdot360_deleteCustomAccountGroupMutation()
  const { error, isLoading } = deleteResult

  const onDelete = useCallback(async () => {
    const response = await deleteItem(
      groupToDelete?.rcm_customaccountgroupid || ''
    )
    const { error } = response as { error: Error }

    if (!error) {
      setGroupToDelete(undefined)
    }
  }, [deleteItem, groupToDelete?.rcm_customaccountgroupid, setGroupToDelete])
  return (
    <Dialog
      hidden={!groupToDelete}
      onDismiss={onDismiss}
      minWidth={'400px'}
      dialogContentProps={{
        title: `Deleting '${groupToDelete?.rcm_name}' group`,
        styles: {
          title: {
            fontSize: '18px',
            fontWeight: 700,
            color: '#13272B',
            padding: '18px 30px',
            textAlign: 'center',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            minWidth: 0
          },
          inner: { padding: 0 }
        }
      }}
    >
      {isLoading && (
        <>
          <SimpleOverlay>
            <div css={{ position: 'absolute', width: '100%', top: 0, left: 0 }}>
              <IndeterminateProgressIndicator />
            </div>
          </SimpleOverlay>
        </>
      )}
      <div
        css={{ paddingTop: '5px', display: 'flex', justifyContent: 'center' }}
      >
        Are you sure you want to delete?
      </div>
      {error ? (
        <div css={{ padding: '0 24px' }}>
          <SnackBar
            type="Failure"
            message={(error as Error)?.message || 'An unknown error occurred'}
          />
        </div>
      ) : null}
      <div
        css={{
          padding: '40px 0 20px',
          display: 'flex',
          justifyContent: 'center',
          gridColumnGap: 5
        }}
      >
        <button
          onClick={onDismiss}
          css={[buttonStyles.secondary, { width: '100px' }]}
        >
          Cancel
        </button>
        <button
          onClick={onDelete}
          css={[
            buttonStyles.primary,
            {
              width: '100px'
            }
          ]}
        >
          Delete
        </button>
      </div>
    </Dialog>
  )
}
