import { call, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import {
  findContactsByEmailAddress,
  getActivity,
  getNotes,
  IActivityAndNotes
} from '../../../api/dynamics'
import { AppState } from '../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../store/shared/asyncStore'
import { getDynamicsApiOptions } from '../../../store/shared/sagas'

export interface NameAndActivity {
  activity: IActivityAndNotes[]
  name: string
}

export const clientActivityFetchActions = createAsyncAction(
  '@features/@clients/@clientActivityFetch/REQUEST',
  '@features/@clients/@clientActivityFetch/SUCCESS',
  '@features/@clients/@clientActivityFetch/FAILURE'
)<string[], NameAndActivity | undefined, Error>()

export const clientActivityFetchReducer = createAsyncReducer(
  clientActivityFetchActions
)

const rootSelector = (state: AppState) =>
  state.features.clients.clientActivityFetch

export const {
  getError: getClientActivityFetchError,
  getIsLoading: getIsClientActivityFetchLoading,
  getResult: getClientActivityFetchResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof clientActivityFetchActions.request>
) {
  try {
    const apiOptions = yield* call(getDynamicsApiOptions)
    const contacts = yield* call(
      findContactsByEmailAddress,
      apiOptions,
      action.payload
    )
    if (!contacts) {
      throw new Error('No contact found')
    }
    if (!contacts[0]) {
      throw new Error('No contact found')
    }
    const id = contacts[0].contactid
    if (!id) {
      throw new Error('No contact found')
    }
    const contactName = contacts[0].fullname
    const activity: IActivityAndNotes[] | undefined = yield* call(
      getActivity,
      apiOptions,
      id
    )
    const notes: IActivityAndNotes[] | undefined = yield* call(
      getNotes,
      apiOptions,
      id
    )
    notes?.forEach(
      (note) =>
        (note['activitytypecode@OData.Community.Display.V1.FormattedValue'] =
          'Note')
    )
    let activityAndNotes: IActivityAndNotes[] | undefined
    if (notes) {
      activityAndNotes = activity?.concat(notes)
    } else {
      activityAndNotes = activity
    }

    if (!activityAndNotes) {
      throw new Error('No activity found for contact')
    }
    const nameAndActivity = {
      activity: activityAndNotes,
      name: contactName
    }
    yield put(clientActivityFetchActions.success(nameAndActivity))
  } catch (e: any) {
    yield put(clientActivityFetchActions.failure(e))
  }
}

export const clientActivityFetchSagas = [
  () => takeLatest(clientActivityFetchActions.request, onRequest)
]
