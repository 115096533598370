import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

const { actions, reducer } = createSlice({
  name: 'notesPanel',
  initialState: {} as INotesPanelState,
  reducers: {
    open: () => ({
      isOpen: true
    }),
    close: () => ({
      isOpen: false,
      error: undefined
    }),
    failure: (state, action: PayloadAction<Error>) => ({
      ...state,
      error: action.payload
    })
  }
})

export { reducer as notesPanelReducer }

const rootSelector = (state: AppState) => state.features.notes.notesPanel

const getIsPanelOpen = flow(rootSelector, (x) => x.isOpen)
const getPanelError = flow(rootSelector, (x) => x.error)

export interface INotesPanelState {
  isOpen?: boolean
  error?: Error
}

export const useNotesPanel = () => {
  const dispatch = useDispatch()

  const close = useCallback(() => {
    dispatch(actions.close())
  }, [dispatch])

  const open = useCallback(() => {
    dispatch(actions.open())
  }, [dispatch])

  const setError = useCallback(
    (e: Error) => {
      dispatch(actions.failure(e))
    },
    [dispatch]
  )

  const isOpen = useSelector(getIsPanelOpen)

  const error = useSelector(getPanelError)

  return {
    close,
    open,
    setError,
    isOpen,
    error
  }
}
