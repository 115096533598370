import axios from 'axios'
import { IClient } from '../../../../../../../../api/client.types'
import { ISearchResult } from '../../../../../../../../api/common.types'
import { IApiOptions } from '../../../../../../../../shared/contracts/IApiOptions'

export const fetchClientSummaryService = (options: IApiOptions) => {
  const filter = `$filter=clientServices/any(x: search.in(x/providercode, 'TDE,TES,TNS')`
  const top = `$top=10`
  const orderby = `$orderby=ClientKPI/AumTotal%20desc`
  const select = `select=LegalEntityID,LegalEntityName,legalEntityType,Account,investors,srcClientNumber,ClientAdvisorID,contactdetails%2Femailid,clientServices,revenueDet%2Fttmrevenue,ClientKPI`
  const url = `${options.apiRoot}/search/clients?queryType=full&${select}&${orderby}&${top}&${filter})`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<ISearchResult<IClient>>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data.value)
}
