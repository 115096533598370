import axios from 'axios'
import { IApiOptions } from '../../../../../../../shared/contracts/IApiOptions'
import {
  IABAResponse,
  IAddEditSubAcctResponse,
  ICategoryResponse,
  ILinkAccountRequest,
  ISubAccount,
  ISubAccountRequest,
  ISubAccountResponse,
  IUnlikAccountResponse,
  IUnlinkAccountRequest,
  IMainAccount
} from '../store/types'

export const fetchSubAccountList = (
  options: IApiOptions,
  subAccountReq: ISubAccountRequest
) => {
  const url = `${options.apiRoot}/payment/api/billmgmt/retrieveOrgSubAccount?orgId=${subAccountReq.orgId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<ISubAccountResponse>(url, subAccountReq, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
export const fetchSubAccountCategories = (options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/api/Accounting/GetSubAccountCategories`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<ICategoryResponse>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const getABA = (options: IApiOptions, subAccountReq: ISubAccount) => {
  const url = `${options.apiRoot}/cashmanagement/mm/odata/GetABA('ATOCH,${subAccountReq.bankRoutingNumber}')`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<{ value: IABAResponse[] }>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data?.value)
}

export const addEditSubAccount = (
  options: IApiOptions,
  subAccountReq: ISubAccount | undefined
) => {
  const url = `${options.apiRoot}/payment/api/billmgmt/updateSubAccount`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  if (subAccountReq?.accounType === 'D365') {
    subAccountReq.dimension = ''
    subAccountReq.defaultGLAccount = ''
  } else if (subAccountReq?.accounType === 'GL') {
    subAccountReq.d365BankAccount = ''
  }
  return axios
    .post<IAddEditSubAcctResponse>(url, subAccountReq, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const linkAccount = (
  options: IApiOptions,
  accountReq: ILinkAccountRequest
) => {
  const url = `${options.apiRoot}/profileapi/odata/aggregatedaccount`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<{ value?: string }>(url, accountReq, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const unLinkAccount = (
  options: IApiOptions,
  accountReq: IUnlinkAccountRequest
) => {
  const url = `${options.apiRoot}/assetag/odata/ExtAssetAggregationSubmit`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IUnlikAccountResponse>(url, accountReq, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchMainAccountListService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/api/VendorPayeeRule/GetMainAccounts`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IMainAccount[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
