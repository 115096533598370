import { ActionType, createAction } from 'typesafe-actions'
import {
  DashboardData,
  IQBOReportActivity,
  IQBOReportHeader,
  IQBOReportHoldings,
  IQBOReportJournal,
  IQBOReportMarkToMarket,
  IQBOReportRequest,
  QBODashboardState
} from './types'

export const FETCH_ALL_QBOREPORT =
  '@modules/@payments/@pqboreport/FETCH_ALL_QBOREPORT'
export const FETCH_ACTIVITY_QBOREPORT =
  '@modules/@payments/@pqboreport/FETCH_ACTIVITY_QBOREPORT'
export const FETCH_ACTIVITY_QBOREPORT_SUCCESS =
  '@modules/@payments/@pqboreport/FETCH_ACTIVITY_QBOREPORT_SUCCESS'
export const FETCH_ACTIVITY_QBOREPORT_FAILURE =
  '@modules/@payments/@pqboreport/FETCH_ACTIVITY_QBOREPORT_FAILURE'

export const FETCH_HOLDINGS_QBOREPORT =
  '@modules/@payments/@pqboreport/FETCH_HOLDINGS_QBOREPORT'
export const FETCH_HOLDINGS_QBOREPORT_SUCCESS =
  '@modules/@payments/@pqboreport/FETCH_HOLDINGS_QBOREPORT_SUCCESS'
export const FETCH_HOLDINGS_QBOREPORT_FAILURE =
  '@modules/@payments/@pqboreport/FETCH_HOLDINGS_QBOREPORT_FAILURE'

export const FETCH_MARKTOMARKET_QBOREPORT =
  '@modules/@payments/@pqboreport/FETCH_MARKTOMARKET_QBOREPORT'
export const FETCH_MARKTOMARKET_QBOREPORT_SUCCESS =
  '@modules/@payments/@pqboreport/FETCH_MARKTOMARKET_QBOREPORT_SUCCESS'
export const FETCH_MARKTOMARKET_QBOREPORT_FAILURE =
  '@modules/@payments/@pqboreport/FETCH_MARKTOMARKET_QBOREPORT_FAILURE'

export const FETCH_MISSING_LOTS_QBOREPORT =
  '@modules/@payments/@pqboreport/FETCH_MISSING_LOTS_QBOREPORT'
export const FETCH_MISSING_LOTS_QBOREPORT_SUCCESS =
  '@modules/@payments/@pqboreport/FETCH_MISSING_LOTS_QBOREPORT_SUCCESS'
export const FETCH_MISSING_LOTS_QBOREPORT_FAILURE =
  '@modules/@payments/@pqboreport/FETCH_MISSING_LOTS_QBOREPORT_FAILURE'

export const FETCH_JOURNAL_ACTIVITY_QBOREPORT =
  '@modules/@payments/@pqboreport/FETCH_JOURNAL_ACTIVITY_QBOREPORT'
export const FETCH_JOURNAL_ACTIVITY_QBOREPORT_SUCCESS =
  '@modules/@payments/@pqboreport/FETCH_JOURNAL_ACTIVITY_QBOREPORT_SUCCESS'
export const FETCH_JOURNAL_ACTIVITY_QBOREPORT_FAILURE =
  '@modules/@payments/@pqboreport/FETCH_JOURNAL_ACTIVITY_QBOREPORT_FAILURE'

export const FETCH_JOURNAL_MTM_QBOREPORT_SUCCESS =
  '@modules/@payments/@pqboreport/FETCH_JOURNAL_MTM_QBOREPORT_SUCCESS'
export const FETCH_JOURNAL_MTM_QBOREPORT_FAILURE =
  '@modules/@payments/@pqboreport/FETCH_JOURNAL_MTM_QBOREPORT_FAILURE'

export const FETCH_JOURNAL_MARKTOMARKET_QBOREPORT =
  '@modules/@payments/@pqboreport/FETCH_JOURNAL_MARKTOMARKET_QBOREPORT'
export const FETCH_JOURNAL_MARKTOMARKET_QBOREPORT_SUCCESS =
  '@modules/@payments/@pqboreport/FETCH_JOURNAL_MARKTOMARKET_QBOREPORT_SUCCESS'
export const FETCH_JOURNAL_MARKTOMARKET_QBOREPORT_FAILURE =
  '@modules/@payments/@pqboreport/FETCH_JOURNAL_MARKTOMARKET_QBOREPORT_FAILURE'

export const QBOREPORT_SETDASHBOARDSTATE =
  '@modules/@payments/@pqboreport/QBOREPORT_SETDASHBOARDSTATE'
export const QBOREPORT_SETACCOUNTLIST =
  '@modules/@payments/@pqboreport/QBOREPORT_SETACCOUNTLIST'
export const QBOREPORT_SETHEADER =
  '@modules/@payments/@pqboreport/QBOREPORT_SETHEADER'

export const setDashboardStateActions = {
  setQBODashboardByType: createAction(QBOREPORT_SETDASHBOARDSTATE)<{
    type: keyof QBODashboardState
    data: DashboardData
  }>()
}
export const setAccoutListActions = {
  setAccountList: createAction(QBOREPORT_SETACCOUNTLIST)<string[]>()
}
export const setQBOReportHeaderActions = {
  updateQBOHeader: createAction(QBOREPORT_SETHEADER)<IQBOReportHeader>()
}
export const fetchQBOReportAllActions = {
  request: createAction(FETCH_ALL_QBOREPORT)<IQBOReportRequest>()
}
export const fetchQBOReportActivityActions = {
  request: createAction(FETCH_ACTIVITY_QBOREPORT)<IQBOReportRequest>(),
  success: createAction(FETCH_ACTIVITY_QBOREPORT_SUCCESS)<
    IQBOReportActivity[]
  >(),
  failure: createAction(FETCH_ACTIVITY_QBOREPORT_FAILURE)<Error>()
}
export const fetchQBOReportHoldingsActions = {
  request: createAction(FETCH_HOLDINGS_QBOREPORT)<IQBOReportRequest>(),
  success: createAction(FETCH_HOLDINGS_QBOREPORT_SUCCESS)<
    IQBOReportHoldings[]
  >(),
  failure: createAction(FETCH_HOLDINGS_QBOREPORT_FAILURE)<Error>()
}
export const fetchQBOReportMTMActions = {
  request: createAction(
    FETCH_JOURNAL_MARKTOMARKET_QBOREPORT
  )<IQBOReportRequest>(),
  success: createAction(FETCH_JOURNAL_MARKTOMARKET_QBOREPORT_SUCCESS)<
    IQBOReportMarkToMarket[]
  >(),
  failure: createAction(FETCH_JOURNAL_MARKTOMARKET_QBOREPORT_FAILURE)<Error>()
}

export const fetchQBOReportJournalActivityActions = {
  request: createAction(FETCH_JOURNAL_ACTIVITY_QBOREPORT)<IQBOReportRequest>(),
  success: createAction(FETCH_JOURNAL_ACTIVITY_QBOREPORT_SUCCESS)<
    IQBOReportJournal[]
  >(),
  failure: createAction(FETCH_JOURNAL_ACTIVITY_QBOREPORT_FAILURE)<Error>()
}
export const fetchQBOReportJournalMTMActions = {
  success: createAction(FETCH_JOURNAL_MTM_QBOREPORT_SUCCESS)<
    IQBOReportJournal[]
  >(),
  failure: createAction(FETCH_JOURNAL_MTM_QBOREPORT_FAILURE)<Error>()
}

export type QBOReportActions =
  | ActionType<typeof fetchQBOReportAllActions>
  | ActionType<typeof setAccoutListActions>
  | ActionType<typeof setQBOReportHeaderActions>
  | ActionType<typeof fetchQBOReportActivityActions>
  | ActionType<typeof fetchQBOReportHoldingsActions>
  | ActionType<typeof fetchQBOReportMTMActions>
  | ActionType<typeof fetchQBOReportJournalActivityActions>
  | ActionType<typeof fetchQBOReportJournalMTMActions>
  | ActionType<typeof setDashboardStateActions>
