import { addMonths, differenceInCalendarMonths, format } from 'date-fns'
import { groupBy, orderBy, range, sum, sumBy } from 'lodash'
import { createSelector } from 'reselect'
import {
  getRevenueDashboardDateRangeDates,
  getRevenueOrPayout,
  RevenueOrPayout
} from '../../../store/dashboard'
import {
  getRevenueSummaryFetchResult,
  IEnhancedRevenueSummaryItem
} from './revenueSummaryFetch'

export interface IRevenueTableItem {
  category: string
  total: number
  monthlyTotals: number[]
  parentCategory?: string
  heading?: boolean
}

export const getTableItems = createSelector(
  [
    getRevenueSummaryFetchResult,
    getRevenueDashboardDateRangeDates,
    getRevenueOrPayout
  ],
  (items, dateRangeDates, revOrPayout) => {
    const [startDateUtc, endDateUTC] = dateRangeDates || []

    if (!items?.length) {
      const blank = {
        category: '',
        total: 0,
        monthlyTotals: new Array(12).fill(0)
      }
      return new Array(8).fill(blank) as IRevenueTableItem[]
    }

    if (!startDateUtc) {
      throw new Error('No Date Range Found')
    }

    const startDateTz = new Date(
      startDateUtc.getUTCFullYear(),
      startDateUtc.getUTCMonth(),
      startDateUtc.getUTCDate()
    )
    const monthRange = endDateUTC
      ? differenceInCalendarMonths(endDateUTC, startDateUtc) + 1
      : 12

    return createTableItems(
      items,
      startDateTz,
      revOrPayout || 'revenue',
      monthRange
    )
  }
)

export const getTableTotals = createSelector([getTableItems], (items) => {
  const tableTotal = {
    category: 'Total',
    total: 0,
    monthlyTotals: new Array(items?.[0].monthlyTotals.length || 0).fill(0)
  }
  items?.map((item) => {
    tableTotal.total += item.total
    item.monthlyTotals.map((month, index) => {
      tableTotal.monthlyTotals[index] += month
    })
  })
  return tableTotal
})

export const getMonthHeadings = createSelector(
  [getRevenueDashboardDateRangeDates],
  (dateRangeDates) => {
    const [startDateUtc, endDateUTC] = dateRangeDates || []

    if (!startDateUtc) {
      return
    }

    const startDateTz = new Date(
      startDateUtc.getUTCFullYear(),
      startDateUtc.getUTCMonth(),
      startDateUtc.getUTCDate()
    )

    const monthRange = endDateUTC
      ? differenceInCalendarMonths(endDateUTC, startDateUtc) + 1
      : 12

    return range(0, monthRange).map((x) => addMonths(startDateTz, x))
  }
)

const createTableItems = (
  items: IEnhancedRevenueSummaryItem[],
  startDate: Date,
  revOrPayout: RevenueOrPayout,
  monthRange: number
) => {
  const isRevenueSelected = revOrPayout === 'revenue'
  const periodFormat = 'yyyy-MM'
  const otherCategory = 'Uncategorized'
  const chartPeriods = range(0, monthRange)
    .map((x) => addMonths(startDate, x))
    .map((x) => format(x, periodFormat))
  const categoryGroups = groupBy(
    items,
    ({ category = otherCategory }) => category
  )

  const orderedCategoryGroups = orderBy(
    Object.entries(categoryGroups).map(
      ([categoryKey, categoryValues]): IRevenueTableItem => {
        const periodGroups = groupBy(categoryValues, ({ payrollPeriodDate }) =>
          format(new Date(payrollPeriodDate), periodFormat)
        )
        const totals = chartPeriods.map((period) => {
          const currentPeriod = periodGroups[period]
          return sumBy(
            currentPeriod,
            ({ compRevenue, payout }) =>
              (isRevenueSelected ? compRevenue : payout) || 0
          )
        })

        return {
          category: categoryKey || otherCategory,
          monthlyTotals: totals,
          total: sum(totals),
          parentCategory: categoryValues[0].parentCategory
        }
      }
    ),
    ({ total }) => total,
    'desc'
  )
  return orderedCategoryGroups
}
