import { subAccountDataActions } from 'modules/Payments/features/Ledger/modules/SubAccount/store/actions'
import { base64toBlob } from 'modules/Reports/features/Dashboard/Utilities'
import { takeEvery } from 'redux-saga/effects'
import { takeLatest, call, put, select, delay } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../../../store/shared/sagas'
import { getRdotUsername } from '../../../../../../../store/user/selectors'
import {
  fetchClientListService,
  fetchClientSettingDocService,
  fetchClientSettingService,
  updateAccounts,
  updateClientSettings,
  updatePaymentAccountService,
  uploadLoaDocService
} from '../api/ClientSettingsService'
import {
  downloadClientSettingsDocActions,
  fetchClientListActions,
  fetchClientSettingsDocActions,
  fetchClientSettingsListActions,
  updateAccountsActions,
  updateClientSettingsActions,
  updatePaymentAccountActions,
  uploadLoaDocActions
} from './actions'
import {
  IClientSettings,
  IClientSettingsDocRes,
  IPaymentClient,
  IUpdateClientSettingResponse,
  IUploadLoaDocRequest
} from './types'

function* handleFetchClientSettings() {
  try {
    const email = yield* select(getRdotUsername)
    const options = yield* call(getRockefellerApiOptions)
    const data: IClientSettings[] = yield call(() =>
      fetchClientSettingService(email || '', options)
    )
    yield put(fetchClientSettingsListActions.success(data))
  } catch (e: any) {
    yield put(fetchClientSettingsListActions.failure(e))
  }
}

function* handleFetchClientSettingsDoc(
  action: ReturnType<typeof fetchClientSettingsDocActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: IClientSettingsDocRes = yield call(() =>
      fetchClientSettingDocService(action.payload, options)
    )

    if (data?.status?.code === '0') {
      yield put(
        fetchClientSettingsDocActions.success(data?.paymentloadoc || '')
      )
    } else {
      yield put(
        fetchClientSettingsDocActions.failure(new Error(data?.status?.text))
      )
    }
  } catch (e: any) {
    yield put(fetchClientSettingsDocActions.failure(e))
  }
}

function* handleDownloadClientSettingsDoc(
  action: ReturnType<typeof downloadClientSettingsDocActions.download>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: IClientSettingsDocRes = yield call(() =>
      fetchClientSettingDocService(action.payload, options)
    )

    if (data?.status?.code === '0' && data?.paymentloadoc) {
      const nbase64Data = data?.paymentloadoc.replace(
        'data:application/pdf;base64,',
        ''
      )
      const blob = base64toBlob(nbase64Data, 'application/pdf')
      const url = URL.createObjectURL(blob)
      window.open(url)
    }
  } catch (e: any) {
    console.log(e)
  }
}

function* handleFetchClientList(
  action: ReturnType<typeof fetchClientListActions.request>
) {
  try {
    yield delay(1000)
    const options = yield* call(getRockefellerApiOptions)
    const data: IPaymentClient[] = yield call(() =>
      fetchClientListService(action.payload, options)
    )
    yield put(fetchClientListActions.success(data))
  } catch (e: any) {
    yield put(fetchClientListActions.failure(e))
  }
}

function* handleUpdatePaymentAccount(
  action: ReturnType<typeof updatePaymentAccountActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    yield call(() => updatePaymentAccountService(action.payload, options))
    yield put(updatePaymentAccountActions.success())
    const email = yield* select(getRdotUsername)
    const data: IClientSettings[] = yield call(() =>
      fetchClientSettingService(email || '', options)
    )
    yield put(fetchClientSettingsListActions.success(data))
  } catch (e: any) {
    yield put(updatePaymentAccountActions.failure(e))
  }
}

function* handleUpdateClientSettings(
  action: ReturnType<typeof updateClientSettingsActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: IUpdateClientSettingResponse = yield call(() =>
      updateClientSettings(action.payload, options)
    )
    if (data?.status) {
      yield put(updateClientSettingsActions.success())
      yield put(fetchClientSettingsListActions.request())
    } else {
      yield put(
        updateClientSettingsActions.failure(
          new Error('unable to update client settings')
        )
      )
    }
  } catch (e: any) {
    yield put(updateClientSettingsActions.failure(e))
  }
}

function* handleUpdateAccounts(
  action: ReturnType<typeof updateAccountsActions.request>
) {
  const accountAction =
    action?.payload?.accounts?.[0]?.action === 'ADD' ? 'add' : 'remove'
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: IUpdateClientSettingResponse = yield call(() =>
      updateAccounts(action.payload.accounts || [], options)
    )

    if (data?.status) {
      yield put(updateAccountsActions.success(action.payload.accounts || []))
      yield put(
        subAccountDataActions.request({
          orgId: action.payload?.pkId?.toString()
        })
      )
    } else {
      yield put(
        updateAccountsActions.failure(`Unable to ${accountAction} account(s)!`)
      )
    }
  } catch (e: any) {
    yield put(
      updateAccountsActions.failure(`Unable to ${accountAction} account(s)!`)
    )
  }
}
function* handleUploadLoaDoc(
  action: ReturnType<typeof uploadLoaDocActions.request>
) {
  const { clientid, file, onUploadSuccess, onUploadFail, onUploadProgress } =
    action.payload as IUploadLoaDocRequest
  try {
    const options = yield* call(getRockefellerApiOptions)
    yield call(() =>
      uploadLoaDocService(file, clientid || 0, options, onUploadProgress)
    )

    onUploadSuccess(file)
  } catch (e) {
    onUploadFail(file)
  }
}

export const sagas = [
  () =>
    takeLatest(
      fetchClientSettingsListActions.request,
      handleFetchClientSettings
    ),
  () =>
    takeLatest(
      fetchClientSettingsDocActions.request,
      handleFetchClientSettingsDoc
    ),
  () =>
    takeLatest(
      downloadClientSettingsDocActions.download,
      handleDownloadClientSettingsDoc
    ),
  () => takeLatest(fetchClientListActions.request, handleFetchClientList),
  () =>
    takeLatest(updatePaymentAccountActions.request, handleUpdatePaymentAccount),
  () =>
    takeLatest(updateClientSettingsActions.request, handleUpdateClientSettings),
  () => takeEvery(uploadLoaDocActions.request, handleUploadLoaDoc),
  () => takeEvery(updateAccountsActions.request, handleUpdateAccounts)
]
