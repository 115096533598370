import {
  DirectionalHint,
  HoverCard,
  HoverCardType,
  Icon,
  IPlainCardProps,
  makeStyles,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  TooltipHost
} from '@fluentui/react'
import { addDays, endOfDay } from 'date-fns/fp'
import { flow, toLower, trim } from 'lodash/fp'
import React, { memo, PropsWithChildren, useCallback, useMemo } from 'react'
import { FormattedDate, FormattedNumber } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import {
  IManagedAccountFeeDetail,
  IManagedAccountFeeSummary
} from '../../api/account.types'
import { parseDateISOStringInLocalTimezone } from '../../shared'
import { USD } from '../../shared/components/Formatting'
import { LoadingComponent } from '../../shared/components/Loading'
import { ConnectedMaskedText } from '../../shared/components/MaskedText'
import { Separator } from '../../shared/components/Separator'
import { PercentCell } from '../Lists/core/components/PercentCell'
import { USDCell } from '../Lists/core/components/USDCell'
import {
  feeDetailsActions,
  getFeeDetailsResult,
  getIsFeeDetailsLoading
} from './store/feeDetails'

const useIconStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.themePrimary
  }
}))

export const TooltipDisclaimer: React.FC<{ content: string | JSX.Element }> = ({
  content
}) => {
  const classes = useIconStyles()
  return (
    <TooltipHost content={content}>
      <Icon
        className={classes.icon}
        iconName="info"
        styles={{ root: { cursor: 'pointer' } }}
      />
    </TooltipHost>
  )
}

interface IFeeDetailsProps {
  loading?: boolean
  feeSummary?: IManagedAccountFeeSummary
  feeDetails?: IManagedAccountFeeDetail[]
  accountNumber?: string
}

const useFeeDetailClasses = makeStyles((theme) => ({
  header: {
    borderBottom: `solid 2px ${theme.palette.black}`
  },
  warn: {
    fontWeight: 'bold',
    color: `${theme.semanticColors.errorText}`
  },
  feeTable: {
    tableLayout: 'fixed',
    '& thead th, & tbody td': {
      ...theme.fonts.small,
      textAlign: 'left',
      padding: '1px 3px'
    },
    '& thead th': {
      fontWeight: 'bold'
    },
    '& th:nth-child(2), & td:nth-child(2)': {
      textAlign: 'right'
    },
    '& th:nth-child(3), & td:nth-child(3)': {
      textAlign: 'right'
    },
    '& th:nth-child(4), & td:nth-child(4)': {
      textAlign: 'right'
    }
  }
}))
const FeeDetails: React.FC<IFeeDetailsProps> = memo(
  ({ loading, feeSummary, feeDetails, accountNumber }) => {
    const {
      billPeriodEnd,
      billPeriodStart,
      cashAfterFees,
      cashAvailable,
      clientFee,
      clientFeePercent,
      debitAccount,
      managerFeePercent,
      managerFee,
      billDate
    } = feeSummary || {}

    const classes = useFeeDetailClasses()
    const isEnoughCashAvailable = (cashAfterFees || 0) >= 0
    const trimToLower = flow(toLower, trim)
    const isDifferentDebitAccount =
      accountNumber &&
      debitAccount &&
      trimToLower(accountNumber) !== trimToLower(debitAccount)

    const nextBillDate = billDate && parseDateISOStringInLocalTimezone(billDate)
    const billDateIsInFuture =
      !!nextBillDate && flow(addDays(1), endOfDay)(nextBillDate) > new Date()

    const parts = {
      'Bill Period Start': billPeriodStart ? (
        <Text nowrap={true}>
          <FormattedDate
            day="2-digit"
            month="2-digit"
            year="numeric"
            value={parseDateISOStringInLocalTimezone(billPeriodStart)}
          />
        </Text>
      ) : (
        <Text>--</Text>
      ),
      'Bill Period End': billPeriodEnd ? (
        <Text nowrap={true}>
          <FormattedDate
            day="2-digit"
            month="2-digit"
            year="numeric"
            value={parseDateISOStringInLocalTimezone(billPeriodEnd)}
          />
        </Text>
      ) : (
        <Text>--</Text>
      ),
      'Debit Account': isDifferentDebitAccount && (
        <Stack
          horizontal={true}
          verticalAlign="center"
          tokens={{ childrenGap: 5 }}
        >
          <ConnectedMaskedText text={debitAccount} />
          {isDifferentDebitAccount && (
            <TooltipDisclaimer content="Account is using a separate debit account" />
          )}
        </Stack>
      ),
      'Manager Fee Percent': <PercentCell value={managerFeePercent} />,
      'Manager Fee': <USDCell value={managerFee} />,
      'Client Fee Percent': <PercentCell value={clientFeePercent} />,
      'Client Fee': <USDCell value={clientFee} />,
      [billDateIsInFuture ? 'Upcoming Bill Date' : 'Most Recent Bill Date']:
        nextBillDate ? (
          <Text nowrap={true}>
            <FormattedDate
              day="2-digit"
              month="2-digit"
              year="numeric"
              value={nextBillDate}
            />
          </Text>
        ) : (
          <Text>--</Text>
        ),
      'Cash Available to Withdraw': <USDCell value={cashAvailable} />,
      'Remaining Cash After Fee': (
        <div className={!isEnoughCashAvailable ? classes.warn : undefined}>
          <USDCell currencySign="accounting" value={cashAfterFees} />
        </div>
      )
    }

    return (
      <Stack tokens={{ childrenGap: 5, padding: 20 }}>
        {!loading && (
          <Stack.Item>
            <div style={{ textAlign: 'center' }}>
              <Text styles={{ root: { fontWeight: 'bold' } }}>
                Managed Account Fee Summary
              </Text>
            </div>
            <Separator styles={{ root: { padding: 0 } }} />
          </Stack.Item>
        )}
        {loading && <LoadingComponent />}
        {!loading && (
          <table>
            <tbody>
              {Object.entries(parts)
                .filter(([, value]) => value)
                .map(([key, value]) => (
                  <tr key={key}>
                    <td style={{ textAlign: 'right', paddingRight: '10px' }}>
                      <b>{key}:</b>
                    </td>
                    <td>{value}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {!!feeDetails?.length && !loading && (
          <>
            <Stack.Item>
              <Separator styles={{ root: { padding: 0 } }} />
            </Stack.Item>

            <table className={classes.feeTable}>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Billable Value</th>
                  <th>Fee</th>
                  <th>Fee Percent</th>
                </tr>
              </thead>
              <tbody>
                {feeDetails.map(
                  (
                    { billType, billableValue, clientFee, clientFeePercent },
                    i
                  ) => (
                    <tr key={i}>
                      <td>{billType}</td>
                      <td>
                        {billableValue != null ? (
                          <USD value={billableValue} />
                        ) : (
                          '--'
                        )}
                      </td>
                      <td>
                        {clientFee != null ? <USD value={clientFee} /> : '--'}
                      </td>
                      <td>
                        {clientFeePercent != null ? (
                          <>
                            <FormattedNumber
                              value={clientFeePercent}
                              maximumFractionDigits={2}
                              minimumFractionDigits={2}
                            />
                            %
                          </>
                        ) : (
                          '--'
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </>
        )}
        {!loading && !isEnoughCashAvailable && (
          <MessageBar messageBarType={MessageBarType.warning}>
            Not enough cash is available to pay the next fee.
          </MessageBar>
        )}
      </Stack>
    )
  }
)

export const ManagedAccountFeeHoverCard: React.FC<
  PropsWithChildren<{
    accountId: string
  }>
> = ({ accountId, children }) => {
  const dispatch = useDispatch()
  const details = useSelector(getFeeDetailsResult)
  const loading = useSelector(getIsFeeDetailsLoading)
  const plainCardProps = useMemo(
    (): IPlainCardProps => ({
      onRenderPlainCard: () => (
        <FeeDetails
          loading={loading}
          feeSummary={{
            ...details?.feeSummary,
            cashAvailable: details?.AccountKPIs?.cashAvlToWithDraw
          }}
          feeDetails={details?.feeDetails}
          accountNumber={details?.CustodyAccount}
        />
      ),
      directionalHint: DirectionalHint.rightCenter,
      calloutProps: {
        isBeakVisible: true
      }
    }),
    [details, loading]
  )

  const onCardVisible = useCallback(() => {
    dispatch(feeDetailsActions.request(accountId))
  }, [accountId, dispatch])

  return (
    <HoverCard
      plainCardProps={plainCardProps}
      instantOpenOnClick={true}
      type={HoverCardType.plain}
      onCardVisible={onCardVisible}
    >
      {children}
    </HoverCard>
  )
}
