import { combineReducers, Reducer } from 'redux'
import { ReportActionType } from '../features/Dashboard/store/actions'
import {
  reportsDashboardReducer,
  IReportsState
} from '../features/Dashboard/store/reducer'
import { GetScheduleReportListTypes } from '../features/ScheduleReport/store/actions'
import {
  IScheduleReportsState,
  scheduleReportsReducer
} from '../features/ScheduleReport/store/reducer'
import { ShareReportActionType } from '../features/ShareReport/store/action'
import {
  IShareReportState,
  shareReportReducer
} from '../features/ShareReport/store/reducer'

export interface IReportModuleState {
  dashboard: IReportsState
  scheduleReport: IScheduleReportsState
  shareReport: IShareReportState
}

export const reportreducer: Reducer<
  IReportModuleState,
  ReportActionType | GetScheduleReportListTypes | ShareReportActionType
> = combineReducers({
  dashboard: reportsDashboardReducer,
  scheduleReport: scheduleReportsReducer,
  shareReport: shareReportReducer
})
