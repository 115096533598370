import {
  FontIcon,
  IProcessedStyleSet,
  IStyle,
  Link,
  Stack,
  mergeStyleSets
} from '@fluentui/react'
import { TextCell } from 'features/Lists/core/components/TextCell'
import { sumBy, uniq } from 'lodash'
import { IColumnWithFilter } from 'shared/components/DataTableFilters/types'
import { IAIRevenueTypeByPlanid } from '../store/AIRevenueSummaryFetch'
import { PaymentFrequencyInfoHoverCard } from './utilities/PaymentFrequencyInfoHoverCard'
import { PlanTotalInfoHoverCard } from './utilities/PlanTotalInfoHoverCard'
import { USDCell } from './utilities/USDCell'

type AIRevenueColumns = {
  monthHeadings?: ImonthHeadings[]
  onColumnClick: (
    ev: React.MouseEvent<HTMLElement, MouseEvent>,
    column: IColumnWithFilter
  ) => void
  onplanTotalClick: (item: any) => void
  onMonthlyPlanTotalClick: (item: any, itemKey: any) => void
  aIRevenueTypeByPlanid?: IAIRevenueTypeByPlanid[]
}

interface ImonthHeadings {
  key?: string
  value?: string
}

interface IAIRevenueSummaryClasses {
  amountCell: IStyle
  amountHeader: IStyle
  bodyIcon: IStyle
  cashamount: IStyle
  multiamount: IStyle
}

const airevenuesummaryClasses: IProcessedStyleSet<IAIRevenueSummaryClasses> =
  mergeStyleSets({
    amountCell: {
      display: 'grid !important',
      justifyContent: 'end !important',
      paddingRight: '8px !important',
      textAlign: 'end !important'
    },
    bodyIcon: {
      width: '28px !important'
    },
    amountHeader: {
      display: 'grid !important',
      justifyContent: 'end !important',
      paddingRight: '8px !important',
      textAlign: 'end !important'
    },
    cashamount: {
      color: '#00C900'
    },
    multiamount: {
      color: '#D4702A'
    }
  })
const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    display: 'flex',
    float: 'right'
  },
  hideHeaderIcon: {
    display: 'none'
  },
  stickyBody: {
    zIndex: 1,
    backgroundColor: 'transparent'
  }
})

export const generateAIRevenueSummaryFooter = (
  airevenuedata: any[] | undefined,
  monthHeadings: any[] | undefined,
  aIRevenueTypeByPlanid: IAIRevenueTypeByPlanid[] | undefined
) => {
  const monthlyTotals: Record<string, number | null> = {}
  const monthlyTotalsRevenueType: Record<string, string | null> = {}

  for (const item of monthHeadings || []) {
    const monthKey = item.key as string
    const totalRevenue = sumBy(airevenuedata || [], (y) => y[monthKey])
    monthlyTotals[monthKey] = totalRevenue ? totalRevenue : null
  }
  for (const item of monthHeadings || []) {
    const monthKey = (item.key + 'revenueType') as string
    const revenueType = getRevenueTypeForTotal(
      airevenuedata,
      aIRevenueTypeByPlanid,
      item.key
    )
    monthlyTotalsRevenueType[monthKey] = revenueType
  }
  return {
    planid: 'total',
    planName: <b>Total</b>,
    planTotal: sumBy(airevenuedata || [], (y) => y.planTotal)
      ? sumBy(airevenuedata || [], (y) => y.planTotal)
      : 0,
    cash: sumBy(airevenuedata || [], (y) => y.cash)
      ? sumBy(airevenuedata || [], (y) => y.cash)
      : 0,
    accrual: sumBy(airevenuedata || [], (y) => y.accrual)
      ? sumBy(airevenuedata || [], (y) => y.accrual)
      : 0,
    ...monthlyTotals,
    ...monthlyTotalsRevenueType
  }
}

const getRevenueTypeForTotal = (
  airevenuedata: any[] | undefined,
  aIRevenueTypeByPlanid: IAIRevenueTypeByPlanid[] | undefined,
  itemkey: string | undefined
) => {
  const revenueTypesArray: any = uniq(
    airevenuedata
      ?.map((data) => {
        const revenueType = aIRevenueTypeByPlanid?.find(
          (x) => x.planid === data.planid && x.columnName === itemkey
        )?.revenueType
        return revenueType
      })
      .filter(Boolean)
  )
  if (revenueTypesArray?.length > 1) {
    return 'multi'
  } else if (revenueTypesArray?.length === 1) {
    return revenueTypesArray[0]
  } else {
    return null
  }
}

export const getAIRevenueSummaryTableColumns = ({
  monthHeadings,
  onColumnClick,
  onplanTotalClick,
  onMonthlyPlanTotalClick,
  aIRevenueTypeByPlanid
}: AIRevenueColumns): IColumnWithFilter[] => {
  const newColumns: any = [
    {
      key: 'paymentFrequency',
      name: '',
      fieldName: 'paymentFrequency',
      isRowHeader: true,
      isPadded: true,
      minWidth: 3,
      maxWidth: 3,
      iconClassName: classNames.hideHeaderIcon,
      className: airevenuesummaryClasses.bodyIcon,
      headerClassName: airevenuesummaryClasses.bodyIcon,
      onRender: (item: any) => {
        return (
          item?.paymentFrequency && (
            <PaymentFrequencyInfoHoverCard
              paymentFrequency={item?.paymentFrequency || ''}
            />
          )
        )
      }
    },
    {
      key: 'planName',
      name: 'Fund Name',
      fieldName: 'planName',
      isRowHeader: true,
      isPadded: true,
      minWidth: 80,
      maxWidth: 120,
      isResizable: true,
      allowFilter: true,
      allowSorting: true,
      showSearch: true,
      iconClassName: classNames.fileIconHeaderIcon,
      onColumnClick: onColumnClick,
      onRender: (item: any) => {
        return (
          <span
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            title={item?.planName}
          >
            <TextCell>{item?.planName || ''}</TextCell>
          </span>
        )
      }
    },
    {
      key: 'cusip',
      name: 'CUSIP',
      fieldName: 'cusip',
      isRowHeader: true,
      isPadded: true,
      minWidth: 60,
      maxWidth: 60,
      isResizable: true,
      allowFilter: true,
      allowSorting: true,
      showSearch: true,
      onColumnClick: onColumnClick,
      iconClassName: classNames.fileIconHeaderIcon,
      onRender: (item: any) => {
        return (
          <span title={item?.cusip}>
            <TextCell>{item?.cusip || ''}</TextCell>
          </span>
        )
      }
    },
    {
      key: 'planTotal',
      name: 'Total',
      fieldName: 'planTotal',
      isRowHeader: true,
      isPadded: true,
      minWidth: 50,
      maxWidth: 80,
      isResizable: true,
      allowFilter: false,
      allowSorting: true,
      iconClassName: classNames.fileIconHeaderIcon,
      onColumnClick: onColumnClick,
      onRender: (item: any) => (
        <PlanTotalInfoHoverCard
          item={item}
          onplanTotalClick={onplanTotalClick}
        />
      ),
      className: airevenuesummaryClasses.amountCell,
      headerClassName: airevenuesummaryClasses.amountHeader
    }
  ]
  if (monthHeadings && monthHeadings.length > 0) {
    monthHeadings.map((item) => {
      newColumns.push({
        key: item.key,
        name: item.value,
        fieldName: item.key,
        isRowHeader: true,
        isPadded: true,
        minWidth: 50,
        maxWidth: 70,
        isResizable: true,
        allowFilter: false,
        allowSorting: false,
        showSearch: true,
        iconClassName: classNames.hideHeaderIcon,
        classNames: classNames.stickyBody,
        onRender: (item1: any) => {
          const revenueType =
            item1?.planid?.toString() !== 'total'
              ? aIRevenueTypeByPlanid?.find(
                  (x) => x.planid === item1.planid && x.columnName === item.key
                )?.revenueType
              : null // set this if color coding required item1[item.key + 'revenueType']
          if (revenueType && revenueType?.toLocaleLowerCase() === 'multi') {
            return item1?.planid?.toString() !== 'total' ? (
              <Link
                style={{ color: '#3A3A3A' }}
                onClick={() =>
                  onMonthlyPlanTotalClick &&
                  onMonthlyPlanTotalClick(item1, item.key)
                }
              >
                <Stack
                  horizontal={true}
                  tokens={{ childrenGap: 5 }}
                  style={{ alignItems: 'center' }}
                >
                  <FontIcon iconName="Warning" style={{ color: '#D4702A' }} />
                  <div className={airevenuesummaryClasses.multiamount}>
                    <USDCell value={item1[item.key as string]} />
                  </div>
                </Stack>
              </Link>
            ) : (
              <Stack
                horizontal={true}
                tokens={{ childrenGap: 5 }}
                style={{ alignItems: 'center' }}
              >
                <FontIcon iconName="Warning" style={{ color: '#D4702A' }} />
                <div className={airevenuesummaryClasses.multiamount}>
                  <USDCell value={item1[item.key as string]} />
                </div>
              </Stack>
            )
          }
          return (
            <Link
              style={{ color: '#3A3A3A' }}
              onClick={() =>
                onMonthlyPlanTotalClick &&
                onMonthlyPlanTotalClick(item1, item.key)
              }
            >
              <div
                className={
                  revenueType && revenueType.toLocaleLowerCase() === 'cash'
                    ? airevenuesummaryClasses.cashamount
                    : ''
                }
              >
                <USDCell value={item1[item.key as string]} />
              </div>
            </Link>
          )
        },
        className: airevenuesummaryClasses.amountCell,
        headerClassName: airevenuesummaryClasses.amountHeader
      })
    })
  }
  return newColumns
}
