import { call, delay, put, takeLatest, takeEvery } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../../../store/shared/sagas'
import {
  addClients,
  fetchClientListService,
  fetchUserListService,
  manageClientOrgService
} from '../api/ClientVisibilityService'
import {
  addClientActions,
  fetchClientListActions,
  fetchUserListActions,
  manageRCMUserOrgActions
} from './actions'
import { IClient, IManageClientRes, IUser } from './types'

function* handleFetchUserList() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: IUser[] = yield call(() => fetchUserListService(options))
    yield put(fetchUserListActions.success(data))
  } catch (e: any) {
    yield put(fetchUserListActions.failure(e))
  }
}
function* handleAddClient(action: ReturnType<typeof addClientActions.request>) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: { pkId?: string } = yield call(() =>
      addClients(action.payload, options)
    )
    if (data?.pkId) {
      yield put(addClientActions.success(action?.payload?.clientName ?? ''))
      yield put(
        fetchClientListActions.request(action?.payload?.requestedBy ?? '')
      )
    } else {
      yield put(addClientActions.failure(new Error('Failed to add client')))
    }
  } catch (e: any) {
    yield put(addClientActions.failure(e))
  }
}

function* handleFetchClientClist(
  action: ReturnType<typeof fetchClientListActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: IClient[] = yield call(() =>
      fetchClientListService(action.payload, options)
    )
    yield delay(1000)
    yield put(fetchClientListActions.success(data))
  } catch (e: any) {
    yield put(fetchClientListActions.failure(e))
  }
}

function* handleRcmUserOrg(
  action: ReturnType<typeof manageRCMUserOrgActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: IManageClientRes = yield call(() =>
      manageClientOrgService(action.payload, options)
    )
    yield delay(1000)
    if (data?.response_status === 0) {
      yield put(manageRCMUserOrgActions.success(action.payload))
    } else {
      yield put(
        manageRCMUserOrgActions.failure({
          error: new Error(`${data?.error_code} - ${data?.error_message}`),
          req: action.payload
        })
      )
    }
  } catch (e: any) {
    yield put(
      manageRCMUserOrgActions.failure({ error: e, req: action.payload })
    )
  }
}

export const sagas = [
  () => takeLatest(fetchUserListActions.request, handleFetchUserList),
  () => takeLatest(fetchClientListActions.request, handleFetchClientClist),
  () => takeEvery(manageRCMUserOrgActions.request, handleRcmUserOrg),
  () => takeEvery(addClientActions.request, handleAddClient)
]
