import { IDropdownOption } from '@fluentui/react'
import { ActionType, createAction } from 'typesafe-actions'
import {
  IAdvisoryBusiness,
  IAdvisoryBusinessReq,
  ITPMFirmPDF
} from '../api/types/templateTypes'
import {
  IExternalManagerResp,
  IGenerateTPMPDFReq,
  ITPMDashboardExportReq
} from '../api/types/types'

export const SET_SELECTED_MONTH_YEAR = `@modules/@externalmanager1/@dashboard/SET_SELECTED_MONTH_YEAR`
export const SET_SELECTED_FIRM_CODE = `@modules/@externalmanager1/@dashboard/SET_SELECTED_FIRM_CODE`
export const SET_MONTH_YEAR_FIRM_CODES = `@modules/@externalmanager1/@dashboard/SET_MONTH_YEAR_FIRM_CODES`
export const SET_SELECTED_FIRM_REP_CODES = `@modules/@externalmanager1/@dashboard/SET_SELECTED_FIRM_REP_CODES`
export const SET_SELECTED_PDF_FIRM_CODE = `@modules/@externalmanager1/@dashboard/SET_SELECTED_PDF_FIRM_CODE`

export const setSelectedMonthYearActions = {
  selectedMonthYear: createAction(SET_SELECTED_MONTH_YEAR)<string>(),
  selectedFirmCode: createAction(SET_SELECTED_FIRM_CODE)<string | undefined>(),
  listOfFirmCodes: createAction(SET_MONTH_YEAR_FIRM_CODES)<IDropdownOption[]>(),
  selectedFirmRepCodes: createAction(SET_SELECTED_FIRM_REP_CODES)<string[]>(),
  selectedPdfFirmCode: createAction(SET_SELECTED_PDF_FIRM_CODE)<
    string | undefined
  >()
}
export const FETCH_ADVISORY_BUSINESS = `@modules/@externalmanager/FETCH_ADVISORY_BUSINESS`
export const FETCH_ADVISORY_BUSINESS_SUCCESS = `@modules/@externalmanager/FETCH_ADVISORY_BUSINESS_SUCCESS`
export const FETCH_ADVISORY_BUSINESS_FAILURE = `@modules/@externalmanager/FETCH_ADVISORY_BUSINESS_FAILURE`

export const fetchAdvisoryBusinessActions = {
  request: createAction(FETCH_ADVISORY_BUSINESS)<IAdvisoryBusinessReq>(),
  success: createAction(FETCH_ADVISORY_BUSINESS_SUCCESS)<
    IAdvisoryBusiness | undefined
  >(),
  failure: createAction(FETCH_ADVISORY_BUSINESS_FAILURE)<Error>()
}
export const EXPORT_TPM = `@modules/@externalmanager/EXPORT_TPM`
export const EXPORT_TPM_SUCCESS = `@modules/@externalmanager/EXPORT_TPM_SUCCESS`
export const EXPORT_TPM_FAILURE = `@modules/@externalmanager/EXPORT_TPM_FAILURE`

export const exportTPMToExcelActions = {
  request: createAction(EXPORT_TPM)<ITPMDashboardExportReq>(),
  success: createAction(EXPORT_TPM_SUCCESS)(),
  failure: createAction(EXPORT_TPM_FAILURE)<Error>()
}

export const GENERATE_TPM_PDF = `@modules/@externalmanager/GENERATE_TPM_PDF`
export const GENERATE_TPM_PDF_SUCCESS = `@modules/@externalmanager/GENERATE_TPM_PDF_SUCCESS`
export const GENERATE_TPM_PDF_FAILURE = `@modules/@externalmanager/GENERATE_TPM_PDF_FAILURE`

export const generateTPMPDFActions = {
  request: createAction(GENERATE_TPM_PDF)<IGenerateTPMPDFReq>(),
  success: createAction(GENERATE_TPM_PDF_SUCCESS)<IExternalManagerResp>(),
  failure: createAction(GENERATE_TPM_PDF_FAILURE)<Error>()
}

export const DOWNLOAD_TPM_PDF = `@modules/@externalmanager/DOWNLOAD_TPM_PDF`
export const DOWNLOAD_TPM_PDF_SUCCESS = `@modules/@externalmanager/DOWNLOAD_TPM_PDF_SUCCESS`
export const DOWNLOAD_TPM_PDF_FAILURE = `@modules/@externalmanager/DOWNLOAD_TPM_PDF_FAILURE`

export const downloadTPMPDFActions = {
  request: createAction(DOWNLOAD_TPM_PDF)<IGenerateTPMPDFReq>(),
  success: createAction(DOWNLOAD_TPM_PDF_SUCCESS)(),
  failure: createAction(DOWNLOAD_TPM_PDF_FAILURE)<Error>()
}

export const FETCH_TPM_FIRMPDF = `@modules/@externalmanager/FETCH_TPM_FIRMPDF`
export const FETCH_TPM_FIRMPDF_SUCCESS = `@modules/@externalmanager/FETCH_TPM_FIRMPDF_SUCCESS`
export const FETCH_TPM_FIRMPDF_FAILURE = `@modules/@externalmanager/FETCH_TPM_FIRMPDF_FAILURE`

export const fetchTPMFirmPDFActions = {
  request: createAction(FETCH_TPM_FIRMPDF)<string>(),
  success: createAction(FETCH_TPM_FIRMPDF_SUCCESS)<ITPMFirmPDF[] | undefined>(),
  failure: createAction(FETCH_TPM_FIRMPDF_FAILURE)<Error>()
}
export type setSelectedMonthYearActionType = ActionType<
  typeof setSelectedMonthYearActions
>
export type fetchAdvisoryBusinessActionsTypes = ActionType<
  typeof fetchAdvisoryBusinessActions
>
export type exportTPMToExcelActionType = ActionType<
  typeof exportTPMToExcelActions
>

export type generateTPMPDFActionsActionType = ActionType<
  typeof generateTPMPDFActions
>
export type downloadTPMPDFActionsActionType = ActionType<
  typeof downloadTPMPDFActions
>
export type fetchTPMFirmPDFActionsActionType = ActionType<
  typeof fetchTPMFirmPDFActions
>
export type ThirdPartyManagerFirmsActionTypes =
  | setSelectedMonthYearActionType
  | fetchAdvisoryBusinessActionsTypes
  | exportTPMToExcelActionType
  | generateTPMPDFActionsActionType
  | downloadTPMPDFActionsActionType
  | fetchTPMFirmPDFActionsActionType
