import { combineReducers, Reducer } from 'redux'
import { ThirdPartyManagerFirmsActionTypes } from '../features/store/actions'
import {
  IThirdPartyManagerState,
  thirdPartyManagerReducer
} from '../features/store/reducer'

export interface IThirdPartyManagerModuleState {
  thirdPartyManager: IThirdPartyManagerState
}
export const thirdPartyManagerFirmsReducer: Reducer<
  IThirdPartyManagerModuleState,
  ThirdPartyManagerFirmsActionTypes
> = combineReducers({
  thirdPartyManager: thirdPartyManagerReducer
})
