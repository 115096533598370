export enum ColumnIdsEnum {
  contractType = 'Contract Type',
  householdName = 'Household Name',
  accountNumber = 'Account Number',
  legalEntityName = 'Legal Entity Name',
  contractNumber = 'Contract Number',
  cusipNumber = 'CUSIP',
  productType = 'Product Type',
  carrierName = 'Carrier',
  securityDescription = 'Description',
  comdex = 'COMDEX',
  contractStatusDesc = 'Policy Status',
  issuedate = 'Issue Date',
  annualizedPremium = 'Annualized Premium',
  premiumAmount = 'Next Premium Amount',
  premiumDate = 'Next Premium Date',
  premiumPaidSinceInception = 'Premium',
  valuationDate = 'Last Updated date',
  cashValue = 'Cash Value',
  deathBenefitAmt = 'Death Benefit',
  surrenderValue = 'Surrender Value',
  maturityDate = 'Maturity Date',
  // edit view
  partyState = 'State',
  loanAmount = 'Policy Loan amount',
  insuredAnnuitants = 'Insured / Annuitants',
  productName = 'Product Name',
  address = 'Address', // partyaddressline1/2,
  agents = 'Agents',
  clientName = 'Owner / Client',
  beneficiaryName = 'Beneficiary Name',
  registeredRepName = 'Advisor Name',
  teamName = 'Advisor Team',
  regionName = 'Division Name',
  registeredRep = 'Advisor Id',
  assetClassL1 = 'Asset Class Level-1',
  assetClassL2 = 'Asset Class Level-2',
  assetClassL3 = 'Asset Class Level-3',
  assetClassL4 = 'Asset Class Level-4',
  assetType = 'Asset Type',
  assetSubType = 'Asset Sub Type',
  dollarAmountContributed = 'Basis',
  cleanCarrierName = 'Clean Carrier Name'
}
