import { IListsFilter } from 'features/Lists/core/contracts/IListsFilter'
import { IListsOrderBy } from 'features/Lists/core/contracts/IListsOrderBy'
import { IListsUiState } from 'features/Lists/core/contracts/IListsUIState'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IUseListHookDefinition } from './hookDefinitions'

export const useList = (definition: IUseListHookDefinition<string>) => {
  const dispatch = useDispatch()
  const { actions, selectors, columnDefinitions } = definition
  const { getUiState } = selectors.uiSelectors
  const listState = useSelector(getUiState)
  const { uiActions } = actions
  const { isReady } = listState

  const update = useCallback(
    (newState: Partial<IListsUiState>) => {
      dispatch(uiActions.update(newState))
    },
    [dispatch, uiActions]
  )

  const setFilters = useCallback(
    (updatedFilters: Record<string, IListsFilter>) => {
      dispatch(uiActions.setFilters(updatedFilters))
    },
    [dispatch, uiActions]
  )

  const removeFilters = useCallback(
    (filtersToRemove: string[]) => {
      dispatch(uiActions.removeFilters(filtersToRemove))
    },
    [dispatch, uiActions]
  )

  const moveColumn = useCallback(
    (id: string, position: number) => {
      dispatch(uiActions.moveColumn({ id, position }))
    },
    [dispatch, uiActions]
  )

  const sort = useCallback(
    (newSort: IListsOrderBy) => {
      dispatch(uiActions.sort(newSort))
    },
    [dispatch, uiActions]
  )

  const setIsReady = useCallback(() => {
    if (isReady) {
      return
    }
    dispatch(uiActions.update({ isReady: true }))
  }, [dispatch, uiActions, isReady])

  return {
    listState,
    columnDefinitions,
    update,
    setFilters,
    removeFilters,
    moveColumn,
    sort,
    setIsReady,
    isReady
  }
}
