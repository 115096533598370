export const PrintDisclaimer: React.FC = () => {
  return (
    <section role="article" className="disclaimer top-margin">
      <p>
        Please review your Balances in a timely manner and report any
        discrepancies or questions to your Rockefeller Capital Management Client
        Advisor.
      </p>
      <p>
        The attached report was generated at your request by your Client
        Advisory Team. The report is provided to you for informational purposes
        only and they are not intended, and should not be construed, as
        investment, tax, legal, or other professional advice. You should not
        rely upon nor use any tax-related information contained in these
        statements for tax reporting, filing or payment purposes. Please consult
        with your professional tax and/or legal advisor with respect to any
        tax-related information in these statements.
      </p>
      <p>
        In accordance with our regulatory obligations under the Investment
        Advisers Act of 1940, as amended, we are required to ask that you please
        compare the information in this Balances report with the statement(s)
        that you receive directly from your custodian to ensure that all account
        transactions are accurate. Please note that most custodians provide
        information on a settlement date basis while Rockefeller Capital
        Management account statement reflects data on a trade date basis. In
        addition, money market balances held at the custodian are reflected as
        part of your cash balances on the Rockefeller Capital Management account
        statements. Please review your statement in a timely manner and report
        any discrepancies or questions to your Rockefeller Capital Management
        Client Advisor.
      </p>
      <p>
        Security prices contained in this statement are unaudited and reflect
        the most current data available from various sources. Rockefeller
        Capital Management guarantees neither the accuracy of the data provided
        nor the ability to buy or sell assets at the reported market values,
        especially in the case of private, illiquid or unquoted securities and
        other assets for which there is no readily available public market.
      </p>
      <p>
        Valuations reported for investments in private equity funds, venture
        capital funds, hedge funds and real estate funds are based upon the most
        recent information provided to us by the third-party managers, which in
        many cases is as of a prior date. The final valuations for the current
        reporting period may differ materially from those included in this
        report.
      </p>
      <p>
        Estimated annual income is calculated based on the dividend or income
        expected to be received annually. Since interest and dividend rates may
        change at any time, it should not be relied upon for making investment
        decisions. In the case of private investment vehicles and other illiquid
        securities, it reflects the estimate of your share of the taxable income
        generated by the vehicle, not necessarily the amount of cash that you
        will receive annually.
      </p>
      <p>
        Past performance is no guarantee of future results. No investment
        strategy can guarantee profit or protection from losses. Investment
        products are not FDIC insured and are not bank guaranteed.
      </p>
      <p>
        Rockefeller Capital Management is the marketing name for Rockefeller
        Capital Management L.P. and its affiliates. Investment advisory, asset
        management and fiduciary activities are performed by the following
        affiliates of Rockefeller Capital Management: Rockefeller & Co. LLC,
        Rockefeller Trust Company, N.A. and The Rockefeller Trust Company
        (Delaware), as the case may be.
      </p>
    </section>
  )
}
