import { ITheme, ProgressIndicator, Stack } from '@fluentui/react'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useDomainStore } from 'features/Domain/store/domain'
import React, { useMemo } from 'react'
import { FormattedNumber } from 'react-intl'
import { USD } from 'shared/components/Formatting'
import { useClasses } from 'shared/hooks/useClasses'
import { useGetAnnuityAggregationQuery } from '../../shared/api/apiContracts'

const Header: React.FC<{
  isFetching: boolean
  headerValue?: number
  headerTitle?: string
  type?: string
}> = ({ isFetching, headerValue, headerTitle, type }) => {
  const getThemedClasses = (theme: ITheme) => ({
    listItemStyles: {
      boxShadow: theme.effects.elevation16,
      padding: '20px 10px 10px',
      backgroundColor: theme.palette.white,
      textAlign: 'center'
    },
    valueStyles: [theme.fonts.xxLarge],
    progressIndicator: {
      marginTop: '8px',
      height: '2px'
    }
  })

  const classes = useClasses(getThemedClasses)

  return (
    <Stack className={classes.listItemStyles}>
      <div className={classes.valueStyles}>
        {type === 'number' ? (
          <FormattedNumber value={headerValue || 0} />
        ) : (
          <USD value={headerValue || 0} fractionDigits={0} />
        )}
      </div>
      <div>{headerTitle}</div>
      <ProgressIndicator
        progressHidden={!isFetching}
        className={classes.progressIndicator}
        styles={{
          itemProgress: { padding: 0, margin: 0 }
        }}
      />
    </Stack>
  )
}

export const AggregatedHeaders: React.FC<{
  contractType?: string
  contractStatus?: string[]
}> = ({ contractType, contractStatus }) => {
  const { selectedAccountRepIds, isFullDomainSelected } = useDomainStore()

  const selectedReps = useMemo(
    () => (isFullDomainSelected ? undefined : selectedAccountRepIds),
    [isFullDomainSelected, selectedAccountRepIds]
  )

  const shouldMakeApiCall =
    isFullDomainSelected || selectedAccountRepIds?.length

  const queryProps = {
    registeredRepIds: selectedReps,
    contractType,
    contractStatus
  }

  const { data: aggregationData, isFetching: isAggregationFetching } =
    useGetAnnuityAggregationQuery(shouldMakeApiCall ? queryProps : skipToken)

  const { data: aggregationDataYTD, isFetching: isAggregationYTDFetching } =
    useGetAnnuityAggregationQuery(
      shouldMakeApiCall ? { ...queryProps, ytd: true } : skipToken
    )

  const { TotalCount, TotalCashValue, TotalDeathBenefit } =
    aggregationData?.[0] || {}

  const { TotalCount: totalCountYTD, TotalPremium: totalPremiumYTD } =
    aggregationDataYTD?.[0] || {}

  return (
    <Stack horizontal tokens={{ childrenGap: 15 }}>
      <Header
        key={20}
        isFetching={isAggregationFetching}
        headerValue={TotalCount}
        headerTitle="Total Contracts"
        type="number"
      />
      <Header
        key={30}
        isFetching={isAggregationFetching}
        headerValue={TotalCashValue}
        headerTitle="Total Cash Value"
      />
      {contractType === 'Insurance' && (
        <Header
          key={40}
          isFetching={isAggregationFetching}
          headerValue={TotalDeathBenefit}
          headerTitle="Total Death Benefit"
        />
      )}
      <Header
        key={50}
        isFetching={isAggregationYTDFetching}
        headerValue={totalCountYTD}
        headerTitle="YTD New Contracts"
        type="number"
      />
      <Header
        key={60}
        isFetching={isAggregationYTDFetching}
        headerValue={totalPremiumYTD}
        headerTitle="YTD New Premium"
      />
    </Stack>
  )
}
