import { combineReducers } from 'redux'
import {
  accountLinkingFeaturesReducer,
  accountLinkingFeaturesSagas
} from '../features'
import {
  accountLinkingPanelReducer,
  accountLinkingPanelSagas
} from './accountLinkingPanel'
import {
  accountLinkingPostAccountsUserSagas,
  accountLinkingPostReducer
} from './accountLinkingPost'
import {
  accountLinkingValidationReducer,
  accountLinkingValidationSagas
} from './accountLinkingValidation'
import {
  accountLinkingClientHouseholdFetchReducer,
  accountLinkingClientHouseholdFetchSagas
} from './clientHouseholdFetch'
import {
  accountLinkingClientSearchFetchSagas,
  accountLinkingClientSearchFetchReducer
} from './clientSearchFetch'
import {
  emailValidationFetchReducer,
  emailValidationFetchSagas
} from './emailValitdation'
import {
  accountLinkingNfsProfileFetchReducer,
  accountLinkingNfsProfileFetchSagas
} from './nfsProfileFetch'
import {
  accountLinkingValidationAccountsFetchReducer,
  accountLinkingValidationAccountsFetchSagas
} from './validationAccountsFetch'

export const accountLinkingReducer = combineReducers({
  accountLinkingPanel: accountLinkingPanelReducer,
  accountLinkingValidation: accountLinkingValidationReducer,
  validationAccountsFetch: accountLinkingValidationAccountsFetchReducer,
  clientSearchFetch: accountLinkingClientSearchFetchReducer,
  clientHouseholdFetch: accountLinkingClientHouseholdFetchReducer,
  accountLinkingPost: accountLinkingPostReducer,
  emailValidation: emailValidationFetchReducer,
  nfsProfileFetch: accountLinkingNfsProfileFetchReducer,
  features: accountLinkingFeaturesReducer
})

export const accountLinkingSagas = [
  ...accountLinkingValidationAccountsFetchSagas,
  ...accountLinkingPanelSagas,
  ...accountLinkingClientSearchFetchSagas,
  ...accountLinkingValidationSagas,
  ...accountLinkingClientHouseholdFetchSagas,
  ...accountLinkingPostAccountsUserSagas,
  ...emailValidationFetchSagas,
  ...accountLinkingNfsProfileFetchSagas,
  ...accountLinkingFeaturesSagas
]
