import { Panel, PanelType } from '@fluentui/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingComponent } from '../../shared/components/Loading'
import { RelatedPartyList } from './RelatedPartyList'
import {
  getCurrentRelatedPartyRequest,
  getIsRelatedPartiesLoading,
  getIsRelatedPartyPanelOpen,
  getRelatedParties,
  relatedPartyPanelActions
} from './store'

export const RelatedPartyPanelContainer: React.FC = () => {
  const dispatch = useDispatch()
  const onDismiss = () => dispatch(relatedPartyPanelActions.closePanel())
  const isPanelOpen = useSelector(getIsRelatedPartyPanelOpen)
  const currentRequest = useSelector(getCurrentRelatedPartyRequest)
  const relatedParties = useSelector(getRelatedParties)
  const isLoading = useSelector(getIsRelatedPartiesLoading)

  if (!isPanelOpen) {
    return null
  }

  return (
    <Panel
      headerText={`Related Parties for ${currentRequest?.displayName}`}
      isOpen={isPanelOpen}
      onDismiss={onDismiss}
      isLightDismiss={true}
      closeButtonAriaLabel="Close"
      type={PanelType.custom}
      customWidth="440px"
      onOuterClick={() => {
        return
      }}
    >
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <RelatedPartyList
          relatedParties={relatedParties}
          clientOnlineLaunchParameters={currentRequest?.launchParameters}
        />
      )}
    </Panel>
  )
}
