/* eslint-disable import/namespace */
import {
  call,
  put,
  takeLatest,
  select
  // tslint:disable-next-line: no-submodule-imports
} from 'typed-redux-saga'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import { IEnvironmentApiConfiguration } from '../../../../../shared/services/environment/IEnvironmentConfiguration'
import {
  getRockefellerApiOptions,
  tryAcquireAccessToken
} from '../../../../../store/shared/sagas'

import { getRockefellerApiConfig } from '../../../../../store/system'
import {
  fetchDashboardFeesService,
  RunReportJobService,
  viewReport
} from '../api/ReportService'
import {
  performanceDashboardActions,
  viewReportActions,
  runReportActions
} from './actions'
import { base64toBlob } from './utility'

function* handleFetchFeeDashboard() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() => fetchDashboardFeesService(options))
    yield put(performanceDashboardActions.success(data?.data || []))
  } catch (e: any) {
    yield put(performanceDashboardActions.failure(e))
  }
}

function* handleViewReport(
  action: ReturnType<typeof viewReportActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const data = yield* call(() => viewReport(action.payload, options))

    yield put(viewReportActions.success())
    // `base64String` is the given base 64 data
    const blob = base64toBlob('data:application/pdf;base64,' + data)
    const url = URL.createObjectURL(blob)
    window.open(url)
  } catch (e: any) {
    yield put(viewReportActions.failure(e))
  }
}

function* handleRunReport(action: ReturnType<typeof runReportActions.request>) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const data = yield* call(() => RunReportJobService(action.payload, options))

    yield put(runReportActions.success(data))
  } catch (e: any) {
    yield put(runReportActions.failure(e))
  }
}

export const sagas = [
  () =>
    takeLatest(performanceDashboardActions.request, handleFetchFeeDashboard),
  () => takeLatest(viewReportActions.request, handleViewReport),
  () => takeLatest(runReportActions.request, handleRunReport)
]
