import { Theme, css, useTheme } from '@emotion/react'
import React, { ReactNode, useMemo } from 'react'

export const getClasses = (theme: Theme) => ({
  positiveValue: css({
    color: theme.colors.extraGreen1
  }),
  negativeValue: css({
    color: theme.colors.primaryRed
  })
})

const useThemeClasses = () => {
  const theme = useTheme()
  return useMemo(() => getClasses(theme), [theme])
}

const useColorClass = (value?: number | null) => {
  const { positiveValue, negativeValue } = useThemeClasses()
  if (value && value > 0) {
    return positiveValue
  } else if (value && value < 0) {
    return negativeValue
  }
  return undefined
}

export const ValueColorWrapper: React.FC<{
  children?: ReactNode
  className?: string
  value?: number | null
}> = ({ children, value, className }) => {
  const colorClass = useColorClass(value)

  return (
    <span className={className} css={colorClass}>
      {children}
    </span>
  )
}
