import { lazy } from 'react'
import { IModuleDefinition } from '../types'

const SecurityContainer = lazy(() => import('./SecurityContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/security/*',
    private: true,
    component: SecurityContainer
  },
  name: 'Security'
}

export default moduleDefinition
