import { MessageBarType } from '@fluentui/react'
import axios, { CancelTokenSource } from 'axios'
import { find, keyBy, remove, some } from 'lodash'
import { flow } from 'lodash/fp'
import { Reducer, combineReducers } from 'redux'
import { createSelector } from 'reselect'
import { DynamicsUserRolesEnum } from 'store/user/dynamicsUser'
import { call, cancelled, put, select, takeEvery } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import {
  addRoleToUser,
  findRoleByName,
  getSystemUsers,
  ISystemUser,
  removeRoleFromUser
} from '../../../../../api/dynamics'
import {
  IOdataCollectionFilter,
  OdataFilterCollectionOperatorEnum,
  OdataFilterOperatorEnum
} from '../../../../../api/odata'
import { IOdataRequest } from '../../../../../api/odata.types'
import {
  IListsFacetFilter,
  IListsFilter
} from '../../../../../features/Lists/core/contracts/IListsFilter'
import { pushNotification } from '../../../../../features/Notifications'
import { IOdataListChunkPayload } from '../../../../../features/OdataList/common/IOdataListDataActions'
import { IOdataListDataState } from '../../../../../features/OdataList/common/IOdataListDataState'
import { IOdataListUiState } from '../../../../../features/OdataList/common/IOdataListUiState'
import { convertColumnTypeToFilterType } from '../../../../../features/OdataList/common/service'
import { IOdataListColumnDefinition } from '../../../../../features/OdataList/common/types'
import { createOdataListDataStore } from '../../../../../features/OdataList/store/odataListDataStore'
import { createOdataListUiStore } from '../../../../../features/OdataList/store/odataListUiStore'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import { IOdataResult } from '../../../../../shared/contracts/IOdataResult'
import { AppState } from '../../../../../store'
import { getDynamicsApiOptions } from '../../../../../store/shared/sagas'

export type BDAAdminSecurityUserListColumnName =
  | 'Name'
  | 'Business Unit'
  | 'Title'
  | 'Owner'
  | 'Transactions Only'
  | 'Discount Sharing Reviewer'

export interface IBDAAdminSecurityUserListColumnDefinition
  extends IOdataListColumnDefinition {
  name: BDAAdminSecurityUserListColumnName
  getValue?: (item: ISystemUser) => number | Date | string | boolean | undefined
}

const defaultColumn: Partial<IBDAAdminSecurityUserListColumnDefinition> = {
  filterable: true,
  sortable: true
}

export const bdaAdminSecurityUserListColumns: IBDAAdminSecurityUserListColumnDefinition[] =
  [
    {
      ...defaultColumn,
      name: 'Name',
      dataPath: 'fullname',
      type: 'string',
      width: 200,
      select: ['fullname'],
      searchFields: ['fullname', 'domainname']
    },
    {
      ...defaultColumn,
      name: 'Business Unit',
      dataPath: 'businessunitid',
      type: 'string',
      width: 200,
      searchFields: ['businessunitid/name'],
      expand: ['businessunitid($select=name,_parentbusinessunitid_value)']
    },
    {
      ...defaultColumn,
      name: 'Title',
      dataPath: 'title',
      type: 'string',
      width: 250,
      select: ['title'],
      searchFields: ['title']
    },
    {
      ...defaultColumn,
      name: 'Owner',
      dataPath: 'name',
      collectionPath: 'systemuserroles_association',
      type: 'string',
      width: 90,
      facetable: true,
      sortable: false,
      expand: ['systemuserroles_association($select=name)'],
      getValue: ({ systemuserroles_association }) =>
        some(
          systemuserroles_association,
          (x) => x.name === DynamicsUserRolesEnum.BDAOwner
        )
    },
    {
      ...defaultColumn,
      name: 'Transactions Only',
      dataPath: 'name',
      collectionPath: 'systemuserroles_association',
      type: 'string',
      width: 90,
      facetable: true,
      sortable: false,
      expand: ['systemuserroles_association($select=name)'],
      getValue: ({ systemuserroles_association }) =>
        some(
          systemuserroles_association,
          (x) => x.name === DynamicsUserRolesEnum.BDATransactionReviewer
        )
    },
    {
      ...defaultColumn,
      name: 'Discount Sharing Reviewer',
      dataPath: 'name',
      collectionPath: 'systemuserroles_association',
      type: 'string',
      width: 90,
      facetable: true,
      sortable: false,
      expand: ['systemuserroles_association($select=name)'],
      getValue: ({ systemuserroles_association }) =>
        some(
          systemuserroles_association,
          (x) => x.name === DynamicsUserRolesEnum.BDADiscountSharingReviewer
        )
    }
  ]

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.bda.bdaAdminSecurityUsersList

const getApiOptions = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getDynamicsApiOptions, source.token)
  return [apiOptions, source] as [IApiOptions, CancelTokenSource]
}

const fetchSystemUsers = function* (
  request: IOdataRequest,
  chunks?: IOdataResult<ISystemUser>[]
) {
  const [apiOptions, cancelTokenSource] = yield* call(getApiOptions)
  const [last] = chunks?.slice(-1) || []
  const next = last?.['@odata.nextLink']

  try {
    if (next) {
      const fetchUsers = () =>
        axios
          .get<IOdataResult<ISystemUser>>(next, {
            headers: {
              Authorization: `Bearer ${apiOptions.accessToken}`,
              ...(request.top && { Prefer: `odata.maxpagesize=${request.top}` })
            }
          })
          .then((x) => x.data)
      return yield* call(fetchUsers)
    }

    return yield* call(getSystemUsers, apiOptions, {
      ...request,
      filters: [
        ...(request.filters || []),
        'isdisabled eq false',
        'azureactivedirectoryobjectid ne null'
      ]
    })
  } finally {
    if (yield* cancelled()) {
      cancelTokenSource.cancel()
    }
  }
}

const dataStore = createOdataListDataStore(
  '@modules/@advisory/@modules/@bda/@bdaAdminSecurityList',
  fetchSystemUsers,
  flow(rootSelector, (x) => x.data)
)

export const {
  actions: bdaAdminSecurityUsersListDataActions,
  selectors: bdaAdminSecurityUsersListDataSelectors
} = dataStore

const uiFilters = keyBy(
  bdaAdminSecurityUserListColumns
    .filter((x) => x.filterable)
    .map((column) => {
      const base: IListsFilter = {
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        dataPath: column.dataPath,
        collectionPath: column.collectionPath,
        hasValue: false
      }

      if (column.filterable) {
        return {
          ...base,
          facets: [{ value: 'Yes' }, { value: 'No' }]
        } as IListsFacetFilter
      }

      return base
    }),
  ({ id }) => id
) as Record<BDAAdminSecurityUserListColumnName, IListsFilter>

const uiStore = createOdataListUiStore({
  prefix: '@modules/@advisory/@modules/@bda/@bdaAdminSecurityList',
  initialState: {
    columns: bdaAdminSecurityUserListColumns,
    filters: uiFilters
  },
  rootSelector: flow(rootSelector, (x) => x.ui),
  dataStore,
  onConvertToOdataFilter: (filter) => {
    const customFilters: [
      BDAAdminSecurityUserListColumnName,
      DynamicsUserRolesEnum
    ][] = [
      ['Owner', DynamicsUserRolesEnum.BDAOwner],
      ['Transactions Only', DynamicsUserRolesEnum.BDATransactionReviewer]
    ]

    const [id, role] = find(customFilters, ([id]) => filter.id === id) || []

    if (!id || !role) {
      return
    }

    return convertRoleColumnFilterToOdataFilter(filter, role)
  }
})

export const {
  selectors: bdaAdminSecurityUsersListUiSelectors,
  actions: bdaAdminSecurityUsersListUiActions
} = uiStore

const TOGGLE_ROLE =
  '@modules/@advisory/@modules/@bda/@bdaAdminSecurityList/TOGGLE_ROLE'
const TOGGLE_ROLE_SUCCESS =
  '@modules/@advisory/@modules/@bda/@bdaAdminSecurityList/TOGGLE_ROLE_SUCCESS'
const TOGGLE_ROLE_FAILURE =
  '@modules/@advisory/@modules/@bda/@bdaAdminSecurityList/TOGGLE_ROLE_FAILURE'

export interface IToggleRolePayload {
  user: ISystemUser
  role: DynamicsUserRolesEnum
  error?: Error
}

export const bdaAdminSecurityUserListToggleRoleActions = {
  toggleRole: createAction(TOGGLE_ROLE)<IToggleRolePayload>(),
  toggleRoleSuccess: createAction(TOGGLE_ROLE_SUCCESS)<IToggleRolePayload>(),
  toggleRoleFailure: createAction(TOGGLE_ROLE_FAILURE)<IToggleRolePayload>()
}

export type BdaAdminSecurityUserListToggleRoleActionTypes = ActionType<
  typeof bdaAdminSecurityUserListToggleRoleActions
>

export interface RoleUpdateStatus {
  systemuserid: string
  role: DynamicsUserRolesEnum
  loading?: boolean
  success?: boolean
  errorMessage?: string
}

type BdaAdminSecurityUserListToggleRoleUpdateState = Record<
  string,
  RoleUpdateStatus
>

const initialState: BdaAdminSecurityUserListToggleRoleUpdateState = {}

export const bdaAdminSecurityUserListRoleUpdateReducer = createReducer<
  BdaAdminSecurityUserListToggleRoleUpdateState,
  BdaAdminSecurityUserListToggleRoleActionTypes
>(initialState)
  .handleAction(
    bdaAdminSecurityUserListToggleRoleActions.toggleRole,
    (state, action) => {
      const { user, role } = action.payload
      const { systemuserid } = user
      if (!systemuserid) {
        return state
      }

      const key = `${systemuserid}${role}`

      return {
        ...state,
        [key]: {
          loading: true,
          systemuserid,
          role
        }
      }
    }
  )
  .handleAction(
    bdaAdminSecurityUserListToggleRoleActions.toggleRoleSuccess,
    (state, action) => {
      const { user, role } = action.payload
      const { systemuserid } = user
      if (!systemuserid) {
        return state
      }

      const key = `${systemuserid}${role}`

      return {
        ...state,
        [key]: {
          loading: false,
          systemuserid,
          role,
          success: true
        }
      }
    }
  )
  .handleAction(
    bdaAdminSecurityUserListToggleRoleActions.toggleRoleFailure,
    (state, action) => {
      const { user, role, error } = action.payload
      const { systemuserid } = user
      if (!systemuserid) {
        return state
      }

      const key = `${systemuserid}${role}`

      return {
        ...state,
        [key]: {
          loading: false,
          systemuserid,
          role,
          success: false,
          errorMessage: error?.message
        }
      }
    }
  )

export const bdaAdminSecurityUserListReducer: Reducer<{
  data: IOdataListDataState<ISystemUser>
  ui: IOdataListUiState
  roleUpdates: ReturnType<typeof bdaAdminSecurityUserListRoleUpdateReducer>
}> = combineReducers({
  data: dataStore.reducer,
  ui: uiStore.reducer,
  roleUpdates: bdaAdminSecurityUserListRoleUpdateReducer
})

export const getBdaAdminSecurityUserListRoleUpdates = flow(
  rootSelector,
  ({ roleUpdates }) => roleUpdates
)
export const getIsAnyBdaAdminSecurityUserListRoleUpdating = createSelector(
  [getBdaAdminSecurityUserListRoleUpdates],
  (updates) => Object.entries(updates).some(([, value]) => value.loading)
)

const convertRoleColumnFilterToOdataFilter = (
  filter: IListsFilter,
  roleName: string
): IOdataCollectionFilter | undefined => {
  const { dataPath, collectionPath, values } = filter as IListsFacetFilter
  const hasValue = values?.length === 1

  if (!hasValue || !dataPath || !collectionPath) {
    return
  }

  const operator =
    values?.[0] === 'Yes'
      ? OdataFilterOperatorEnum.eq
      : OdataFilterOperatorEnum.ne

  return {
    filter: {
      and: [
        {
          operator,
          value: roleName,
          path: dataPath,
          type: 'string'
        }
      ]
    },
    operator: OdataFilterCollectionOperatorEnum.any,
    path: collectionPath
  }
}

export const bdaAdminSecurityUserListSagas = [
  ...dataStore.sagas,
  ...uiStore.sagas,
  () =>
    takeEvery(
      bdaAdminSecurityUserListToggleRoleActions.toggleRole,
      function* (
        action: ReturnType<
          typeof bdaAdminSecurityUserListToggleRoleActions.toggleRole
        >
      ) {
        const { user, role } = action.payload
        const rolesCopy = [...(user.systemuserroles_association || [])]
        const userCopy = { ...user }
        const roleItem = rolesCopy?.find((x) => x.name === role)

        try {
          if (
            !userCopy.systemuserid ||
            !userCopy.businessunitid?.businessunitid
          ) {
            throw new Error('invalid user')
          }

          const apiOptions = yield* call(getDynamicsApiOptions)

          if (!roleItem) {
            const findRoleResult = yield* call(
              findRoleByName,
              apiOptions,
              role,
              userCopy.businessunitid.businessunitid
            )

            const newRole = findRoleResult.value?.[0]

            if (!newRole) {
              throw new Error(`Role ${role} not found`)
            }

            yield* call(
              addRoleToUser,
              apiOptions,
              userCopy.systemuserid,
              newRole.roleid
            )

            rolesCopy.push(newRole)
          }

          if (roleItem) {
            yield* call(
              removeRoleFromUser,
              apiOptions,
              userCopy.systemuserid,
              roleItem.roleid
            )

            remove(rolesCopy, (x) => x.roleid === roleItem.roleid)
          }

          userCopy.systemuserroles_association = rolesCopy

          yield put(
            bdaAdminSecurityUserListToggleRoleActions.toggleRoleSuccess({
              user: userCopy,
              role
            })
          )
        } catch (e: any) {
          yield put(
            bdaAdminSecurityUserListToggleRoleActions.toggleRoleFailure({
              user: userCopy,
              role,
              error: e
            })
          )
        }
      }
    ),
  () =>
    takeEvery(
      bdaAdminSecurityUserListToggleRoleActions.toggleRoleFailure,
      function* (
        action: ReturnType<
          typeof bdaAdminSecurityUserListToggleRoleActions.toggleRoleFailure
        >
      ) {
        yield call(pushNotification, {
          message: `Role failed to update: ${
            action.payload?.error?.message || 'Unknown error updating role'
          }`, // `
          type: MessageBarType.error
        })
      }
    ),
  () =>
    takeEvery(
      bdaAdminSecurityUserListToggleRoleActions.toggleRoleSuccess,
      function* (
        action: ReturnType<
          typeof bdaAdminSecurityUserListToggleRoleActions.toggleRoleSuccess
        >
      ) {
        const { user: updatedItem } = action.payload
        const chunks = yield* select(
          bdaAdminSecurityUsersListDataSelectors.getChunks
        )
        if (!chunks) {
          return
        }

        let updateChunkPayload: IOdataListChunkPayload<ISystemUser> | undefined
        chunks?.some((x, i) => {
          if (!x.value) {
            return
          }

          const itemIndex = x.value.findIndex(
            (x) => x.systemuserid === updatedItem.systemuserid
          )

          if (itemIndex < 0) {
            return
          }

          const itemsCopy = [...x.value]
          itemsCopy.splice(itemIndex, 1, updatedItem)
          updateChunkPayload = {
            index: i,
            result: { ...x, value: itemsCopy }
          }
        })

        if (!updateChunkPayload) {
          return
        }

        yield put(
          bdaAdminSecurityUsersListDataActions.updateChunk(updateChunkPayload)
        )
      }
    )
]
