import { Icon } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'

export const PrintNoActivity = () => {
  return (
    <div className="print-no-activity">
      <div>
        <Icon type="NoData" width={56} height={56} />
      </div>
      <div>No activity for selected filter choices</div>
    </div>
  )
}
