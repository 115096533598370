import { IComboBoxOption, SelectableOptionMenuItemType } from '@fluentui/react'
import { flow } from 'lodash'
import { createSelector } from 'reselect'
import { AppState } from 'store'
import { ActionType, createAction, createReducer } from 'typesafe-actions'

const SET_FEE_TYPE = '@modules/@airevenue/@modules/@dashboard/SET_FEE_TYPE'
const SET_DATE_RANGE = '@modules/@airevenue/@modules/@dashboard/SET_DATE_RANGE'

export const aiRevenueDashboardActions = {
  setFeeType: createAction(SET_FEE_TYPE)<string>(),
  setDateRange: createAction(SET_DATE_RANGE)<string>()
}

export interface IAIRevenueDashboardState {
  feeType?: string
  dateRange?: string
}

const initialState: IAIRevenueDashboardState = {
  feeType: 'TRF'
}

export const aiRevenueReducer = createReducer<
  IAIRevenueDashboardState,
  ActionType<typeof aiRevenueDashboardActions>
>(initialState)
  .handleAction(aiRevenueDashboardActions.setFeeType, (state, action) => ({
    ...state,
    feeType: action.payload
  }))
  .handleAction(aiRevenueDashboardActions.setDateRange, (state, action) => ({
    ...state,
    dateRange: action.payload
  }))

const rootSelector = (state: AppState) =>
  state.modules.airevenue.modules.dashboard.aiRevenueReducer

export const getRevenueDashboardFeeType = flow(
  rootSelector,
  (state) => state.feeType
)

export const getRevenueDashboardDateRange = flow(
  rootSelector,
  (state) => state.dateRange
)

export interface IAIRevenueDashboardDateRangeOption {
  key: string
  value?: string
  type?: 'MONTH'
  label: string
}

export const getRevenueDashboardDateRangeOptions = () => {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()
  const currentDay = currentDate.getDate()

  // Determine previousMonth based on the current date
  const previousMonth =
    currentDay > 7 ? (currentMonth + 12) % 12 : (currentMonth + 11) % 12

  const options = []
  options.push({
    key: '',
    text: 'Aging as of',
    itemType: SelectableOptionMenuItemType.Header
  })
  for (let i = 0; i < 12; i++) {
    const month = (previousMonth - i + 12) % 12
    const year = new Date().getFullYear() - (previousMonth < i ? 1 : 0)
    const day = new Date(year, month + 1, 0).getDate() // Get the last day of the month

    // Format month as MMMM YY (e.g., January 22, February 22, etc.)
    const formattedMonth = new Date(year, month, 1).toLocaleDateString(
      'en-US',
      { year: 'numeric', month: 'long' }
    )

    options.push({
      key: `${year}-${(month + 1).toString().padStart(2, '0')}-${day
        .toString()
        .padStart(2, '0')}`, // Include day
      text: `${formattedMonth}`
    })
  }
  return options
}

export const formatDate = (inputDate: string | undefined): string => {
  if (!inputDate) {
    return ''
  }
  const dateParts = inputDate.split('-')
  const year = dateParts[0]
  const month = new Date(`${dateParts[1]}-01-01`).toLocaleString('default', {
    month: 'long'
  })
  return `${month} ${year}`
}

export const getStartOfMonth = (dateStr: string): string => {
  const date = new Date(dateStr)
  // Set the date to the first day of the month
  const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1)
  // Format the date to YYYY-MM-DD
  return startOfMonth.toISOString().split('T')[0]
}

export const getRevenueDashboardDateRangeDates = createSelector(
  [getRevenueDashboardDateRange],
  (range): [Date, Date] | undefined => {
    const options = getRevenueDashboardDateRangeOptions()
    const [option] = options.filter(({ key }) => range === key)

    if (option && option.key) {
      const [year, month, day] = option.key.split('-').map(Number)
      const start = new Date(year, month - 1, day) // Subtract 1 from month to match JavaScript month indexing

      // Calculate the start date (exactly 12 months ago from the selected date)
      const twelveMonthsAgo = new Date(start)
      twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12) // Adjust to 12 months ago
      twelveMonthsAgo.setDate(twelveMonthsAgo.getDate() + 1) // Add one day

      // Handle February (leap year)
      if (month === 2 && day === 29) {
        twelveMonthsAgo.setFullYear(year - 1, 2, 1) // Set to March 1 of the previous year
      }

      // Ensure the start date is not after the end date
      const end = new Date(year, month, 0) // Get the last day of the selected month
      if (twelveMonthsAgo > end) {
        return [end, end] // Return the end date if start date exceeds end date
      }

      return [twelveMonthsAgo, start]
    }

    return undefined // Return undefined if no valid option is found
  }
)

export const convertToUTC = (
  dateString: string | undefined,
  type: string
): Date | undefined => {
  if (!dateString) {
    return undefined
  }
  const localDate = new Date(dateString)
  const utcDate =
    type.toLocaleLowerCase() === 'start'
      ? new Date(localDate.getTime() + 5 * 60 * 60 * 1000)
      : new Date(localDate.getTime())
  return utcDate
}

export const FeeTypeOptions: IComboBoxOption[] = [
  {
    key: 'header',
    text: 'Fee Type',
    itemType: SelectableOptionMenuItemType.Header
  },
  {
    key: 'TRF',
    text: 'AI Trail Fee'
  }
]
