import { Header } from '@tanstack/table-core'
import { Dictionary } from 'lodash'
import { IActivity } from '../../../../store/types'
import { activityDetailColumns } from '../../shared'
import { PrintHeaderAccount } from './PrintHeaderAccount'

interface IPrintTableHeader {
  headers: Header<IActivity, unknown>[]
  account: string
  maskedAccount: string
  netAmount: number
}

const classMap: Record<string, string> | Dictionary<string> = {
  [activityDetailColumns.symbolCusip]: 'left'
}

export const PrintTableHeader: React.FC<IPrintTableHeader> = ({
  headers,
  account,
  maskedAccount,
  netAmount
}) => {
  return (
    <>
      <colgroup>
        {headers.map((header) => (
          <col key={header.id} />
        ))}
      </colgroup>
      <thead>
        <tr>
          <th colSpan={headers.length}>
            <PrintHeaderAccount
              account={account}
              maskedAccount={maskedAccount}
              netAmount={netAmount}
            />
          </th>
        </tr>
        <tr className="header-table">
          {headers.map((header) => (
            <th key={header.id} className={classMap[header.column.id]}>
              {header['column'].id}
            </th>
          ))}
        </tr>
      </thead>
    </>
  )
}
