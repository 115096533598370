import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  Overlay,
  Panel,
  PanelType,
  PrimaryButton,
  Stack
} from '@fluentui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  IDatahubHousehold,
  IHouseholdChangeRequest,
  IHouseholdChangeRequestAccount
} from '../../../../api/households'
import { LoadingComponent } from '../../../../shared/components/Loading'
import { isNotNullOrEmpty } from '../../../../shared/guards'
import {
  HouseholdChangeRequestEdit,
  SelectedAccount
} from './HouseholdChangeRequestEdit'
import {
  changeRequestEditCreateUpdateActions,
  changeRequestEditUiActions,
  getChangeRequestEditUiRequest,
  getCreateOrUpdateHouseholdRequestError,
  getIsChangeRequestPanelOpen,
  getIsCreateOrUpdateHouseholdRequestLoading
} from './store'

export const HouseholdChangeRequestEditPanel: React.FC = () => {
  const dispatch = useDispatch()
  const isPanelOpen = useSelector(getIsChangeRequestPanelOpen)

  const closePanel = useCallback(
    () => dispatch(changeRequestEditUiActions.closePanel()),
    [dispatch]
  )

  const [accounts, setAccounts] = useState<SelectedAccount[]>()
  const [household, setHousehold] = useState<IDatahubHousehold>()

  const request = useSelector(getChangeRequestEditUiRequest)
  useEffect(() => {
    if (!request) {
      return
    }

    setHousehold({
      id: request.targetHouseholdId,
      name: request.targetHouseholdName
    })

    const requestAccounts = request.accounts
      ?.map(({ accountNumber, accountId }) => ({ accountNumber, accountId }))
      .filter((x) => isNotNullOrEmpty(x.accountNumber))

    setAccounts(requestAccounts)
  }, [request])

  useEffect(() => {
    if (isPanelOpen) {
      return
    }

    setAccounts(undefined)
    setHousehold(undefined)
  }, [isPanelOpen])

  const onSubmit = useCallback(() => {
    if (!household || !accounts?.length) {
      return
    }

    const newRequest: IHouseholdChangeRequest = {
      accounts: accounts.map(
        (x): IHouseholdChangeRequestAccount => ({
          accountNumber: x.accountNumber,
          accountId: x.accountId
        })
      ),
      targetHouseholdId: household.id,
      targetHouseholdName: household.name,
      id: request?.id
    }

    dispatch(changeRequestEditCreateUpdateActions.request(newRequest))
  }, [accounts, dispatch, household, request])

  const isLoading = useSelector(getIsCreateOrUpdateHouseholdRequestLoading)
  const error = useSelector(getCreateOrUpdateHouseholdRequestError)
  const onRenderFooterContent = useCallback(() => {
    const canSubmit = !!(household && accounts?.length)
    return (
      <>
        {isLoading && <Overlay styles={{ root: { zIndex: 1 } }} />}
        <Stack tokens={{ childrenGap: 10 }}>
          {error && (
            <MessageBar messageBarType={MessageBarType.error}>
              An error occurred while submitting the request
              {error.message ? `: ${error.message}` : ''}
            </MessageBar>
          )}
          <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
            <PrimaryButton
              onClick={onSubmit}
              disabled={!canSubmit || isLoading}
            >
              Submit
            </PrimaryButton>

            <DefaultButton onClick={closePanel}>Cancel</DefaultButton>
          </Stack>
        </Stack>
      </>
    )
  }, [accounts, closePanel, error, household, isLoading, onSubmit])

  const onAccountsChanged = useCallback(
    (updatedAccounts?: SelectedAccount[]) => {
      setAccounts(updatedAccounts)
    },
    []
  )

  const onHouseholdChanged = useCallback(
    (household?: { householdId?: string; householdName?: string }) => {
      setHousehold(
        household
          ? {
              id: household?.householdId,
              name: household?.householdName
            }
          : undefined
      )
      setAccounts(undefined)
    },
    []
  )

  return (
    <Panel
      headerText="Household Change Request"
      onOuterClick={() => {
        return
      }}
      isOpen={isPanelOpen}
      onDismiss={closePanel}
      closeButtonAriaLabel="Close"
      type={PanelType.custom}
      customWidth="780px"
      isFooterAtBottom={true}
      onRenderFooterContent={onRenderFooterContent}
      styles={{
        content: { flexGrow: 1, minHeight: 0 }
      }}
    >
      {isLoading && (
        <Overlay styles={{ root: { zIndex: 1 } }}>
          <LoadingComponent />
        </Overlay>
      )}
      {isPanelOpen && (
        <HouseholdChangeRequestEdit
          onAccountsChanged={onAccountsChanged}
          onHouseholdChanged={onHouseholdChanged}
          accounts={accounts}
          household={household}
        />
      )}
    </Panel>
  )
}
