import { css } from '@emotion/react'
import { openLotTableColumnNames } from './OpenLotsTableColumns'

const commonCellStyles = {
  left: css({
    textAlign: 'left'
  }),
  right: css({
    textAlign: 'right',
    justifyContent: 'flex-end'
  })
}

export const cellStyles = {
  [openLotTableColumnNames.acquisitionDate]: css([commonCellStyles.left]),
  [openLotTableColumnNames.term]: css([commonCellStyles.left]),
  [openLotTableColumnNames.costBasis]: css([commonCellStyles.right]),
  [openLotTableColumnNames.price]: css([commonCellStyles.right]),
  [openLotTableColumnNames.quantity]: css([commonCellStyles.right]),
  [openLotTableColumnNames.unitCost]: css([commonCellStyles.right]),
  [openLotTableColumnNames.unrealized]: css([commonCellStyles.right]),
  [openLotTableColumnNames.value]: css([commonCellStyles.right]),
  [openLotTableColumnNames.priceChange]: css([commonCellStyles.right]),
  [openLotTableColumnNames.dayChange]: css([commonCellStyles.right])
}
