import { IFontStyles, IRawStyle, Text } from '@fluentui/react'
import React from 'react'
import { USD } from '../../../../shared/components/Formatting'

export const USDCell: React.FC<{
  value?: number
  fractionDigits?: number
  currencySign?: 'standard' | 'accounting'
  addedRootStyles?: IRawStyle
  size?: keyof IFontStyles
}> = ({ value, fractionDigits, currencySign, addedRootStyles, size }) => {
  const decimals = fractionDigits != null ? fractionDigits : value === 0 ? 0 : 2
  const { root: rootStyles } = addedRootStyles || {}
  return value != null ? (
    <Text
      title={value.toFixed(decimals)}
      styles={{
        root: {
          ...rootStyles
        }
      }}
      variant={size}
      nowrap
    >
      <USD
        currencySign={currencySign}
        value={value}
        fractionDigits={decimals}
      />
    </Text>
  ) : (
    <Text variant={size}>--</Text>
  )
}
