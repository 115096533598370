import { sagas as dashboardSagas } from '../features/Dashboard/store/sagas'
import { sagas as siSagas } from '../features/MoneyMovement/store/EFT/sagas'
import { sagas as journalSagas } from '../features/MoneyMovement/store/Journal/sagas'
import { sagas as periodicpaymentSagas } from '../features/MoneyMovement/store/PeriodicPayment/sagas'
import { sagas as wireSagas } from '../features/MoneyMovement/store/sagas'

export const sagas = [
  ...dashboardSagas,
  ...wireSagas,
  ...journalSagas,
  ...periodicpaymentSagas,
  ...siSagas
]
