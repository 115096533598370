import { css } from '@emotion/react'
import { format } from 'date-fns'
import { sum, sumBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { FormattedNumber } from 'react-intl'
import { useRevenueDetailFilterStore } from '../../features/Revenue/store'
import { IRevenueHistoryItem } from '../../store/datahub'

const styles = {
  header: css({ paddingBottom: '10px', paddingTop: '10px' }),
  isDetailCell: css({
    color: 'transparent !important',
    cursor: 'default',
    pointerEvents: 'none'
  }),
  isDetailCategory: css({ fontWeight: 'bold' }),
  isTotal: css({ background: '#EAF6FC', fontWeight: 'bold' }),
  notEndCell: css({ paddingRight: '5px' }),
  leftSide: css({ paddingLeft: '15px' }),
  topSide: css({ paddingTop: '10px' }),
  rightSide: css({ paddingRight: '10px' }),
  bottomSide: css({ paddingBottom: '10px' }),
  catSticky: css({ position: 'sticky', left: '0', zIndex: 1 }),
  totalSticky: css({ position: 'sticky', left: '200px', zIndex: 1 }),
  actionable: css({
    'td.actionable:hover': {
      backgroundColor: `#2A769D !important`,
      cursor: 'pointer',
      color: 'white'
    }
  }),
  rowHover: css({
    'tr:hover td': {
      backgroundColor: `#ededed`,
      color: 'black'
    }
  }),
  numbers: css({
    textAlign: 'right',
    width: '130px'
  }),
  category: css({
    textAlign: 'left',
    width: '200px',
    background: 'white'
  }),
  totalCategory: css({
    textAlign: 'left',
    width: '200px',
    background: '#EAF6FC'
  }),
  cell: css({ td: { height: '25px' } })
}
interface ISubCategoryData {
  [key: string]: { [key: string]: IRevenueHistoryItem[] }
}
interface ICategoryData {
  [key: string]: {
    dateData: { [key: string]: IRevenueHistoryItem[] }
    subCategory: ISubCategoryData
  }
}

export const RevenueDetailTable: React.FC<{
  data: ICategoryData
  summaryOrDetail: string
  dateRange: Date[]
}> = ({ data, summaryOrDetail, dateRange }) => {
  const { setFilters } = useRevenueDetailFilterStore()

  const getCategoryTotals = useCallback(
    (categoryData: { [key: string]: IRevenueHistoryItem[] }) => {
      const total = sum(
        dateRange.map((date) => {
          return sumBy(
            categoryData[date.toDateString()],
            (x) => x.allocatedAmount
          )
        })
      )
      return total
    },
    [dateRange]
  )
  const getDateTotals = useCallback(
    (date: Date) => {
      const totals = Object.keys(data).map((category) => {
        return sumBy(
          data[category].dateData[date.toDateString()],
          (asset) => asset.allocatedAmount
        )
      })
      return sum(totals)
    },
    [data]
  )

  const getOrderTotals = useCallback(
    ({
      categoryData,
      subCategoryData
    }: {
      categoryData?: ICategoryData
      subCategoryData?: ISubCategoryData
    }) => {
      if (categoryData) {
        const ordered = Object.keys(categoryData).sort(
          (a, b) =>
            getCategoryTotals(categoryData[b].dateData) -
            getCategoryTotals(categoryData[a].dateData)
        )
        return ordered
      } else if (subCategoryData) {
        const ordered = Object.keys(subCategoryData).sort(
          (a, b) =>
            getCategoryTotals(subCategoryData[b]) -
            getCategoryTotals(subCategoryData[a])
        )
        return ordered
      }
      return categoryData ?? subCategoryData
    },
    [getCategoryTotals]
  )

  const handleCellClick = useCallback(
    ({
      periodStart,
      periodEnd,
      category,
      subCategory
    }: {
      periodStart: Date
      periodEnd: Date
      category?: string
      subCategory?: string
    }) => {
      setFilters({
        category: category,
        subCategory: subCategory,
        periodStart: periodStart,
        periodEnd: periodEnd
      })
    },
    [setFilters]
  )
  const categoryOrderTotals = useMemo(() => {
    return getOrderTotals({ categoryData: data })
  }, [data, getOrderTotals])

  const totalArray = useMemo(() => {
    return categoryOrderTotals?.map((category) => {
      return getCategoryTotals(data[category].dateData)
    })
  }, [categoryOrderTotals, data, getCategoryTotals])

  return (
    <div
      css={{
        overflow: 'auto'
      }}
    >
      <table
        css={[
          styles.numbers,
          styles.cell,
          styles.rowHover,
          styles.actionable,
          {
            width: '100%',
            borderSpacing: '0',
            background: 'white',
            tableLayout: 'fixed',
            padding: '5px 5px 5px 0px',
            position: 'relative'
          }
        ]}
      >
        <thead>
          <tr>
            <th
              css={[
                styles.header,
                styles.leftSide,
                styles.catSticky,
                styles.category,
                { background: 'white' }
              ]}
            >
              Category
            </th>
            <th
              css={[
                styles.numbers,
                styles.isTotal,
                styles.header,
                styles.notEndCell,
                styles.totalSticky
              ]}
            >
              Total
            </th>
            {dateRange.map((date, i) => {
              return (
                <th
                  key={i}
                  css={
                    i < dateRange.length - 1
                      ? [styles.notEndCell, styles.header, styles.numbers]
                      : [styles.rightSide, styles.header, styles.numbers]
                  }
                >
                  {format(date, "MMM'' yy")}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {categoryOrderTotals?.map((category, i) => {
            return (
              <>
                <tr key={i}>
                  <td
                    css={
                      summaryOrDetail === 'detail'
                        ? [
                            styles.catSticky,
                            styles.isDetailCategory,
                            styles.leftSide,
                            styles.category
                          ]
                        : [styles.catSticky, styles.leftSide, styles.category]
                    }
                  >
                    {category}
                  </td>
                  <td
                    className={'actionable'}
                    css={
                      summaryOrDetail === 'detail'
                        ? [
                            styles.isDetailCell,
                            styles.isTotal,
                            styles.notEndCell,
                            styles.numbers,
                            styles.totalSticky
                          ]
                        : [
                            styles.isTotal,
                            styles.notEndCell,
                            styles.numbers,
                            styles.totalSticky
                          ]
                    }
                    onClick={() =>
                      handleCellClick({
                        periodStart: dateRange[0],
                        periodEnd: dateRange[dateRange.length - 1],
                        category: category
                      })
                    }
                  >
                    <FormattedNumber
                      value={getCategoryTotals(data[category].dateData)}
                      style="currency"
                      currency="USD"
                    />
                  </td>
                  {dateRange.map((date, i) => {
                    return (
                      <td
                        key={i}
                        className={'actionable'}
                        css={
                          summaryOrDetail === 'detail'
                            ? [
                                styles.isDetailCell,
                                styles.notEndCell,
                                styles.numbers,
                                styles.actionable
                              ]
                            : [
                                styles.notEndCell,
                                styles.numbers,
                                styles.actionable
                              ]
                        }
                        onClick={() =>
                          handleCellClick({
                            periodStart: date,
                            periodEnd: date,
                            category: category
                          })
                        }
                      >
                        {sumBy(
                          data[category].dateData[date.toDateString()],
                          (x) => x.allocatedAmount
                        ) === 0 ? (
                          '-'
                        ) : (
                          <FormattedNumber
                            value={sumBy(
                              data[category].dateData[date.toDateString()],
                              (x) => x.allocatedAmount
                            )}
                            style="currency"
                            currency="USD"
                          />
                        )}
                      </td>
                    )
                  })}
                </tr>
                {summaryOrDetail === 'detail' && (
                  <>
                    {getOrderTotals({
                      subCategoryData: data[category].subCategory
                    })?.map((subCat, i) => {
                      return (
                        <tr key={i}>
                          <td
                            key={i}
                            css={[
                              styles.catSticky,
                              styles.category,
                              { paddingLeft: '20px' }
                            ]}
                          >
                            {subCat}
                          </td>
                          <td
                            className={'actionable'}
                            css={[
                              styles.isTotal,
                              styles.notEndCell,
                              styles.numbers,
                              styles.actionable,
                              styles.totalSticky
                            ]}
                            onClick={() =>
                              handleCellClick({
                                periodStart: dateRange[0],
                                periodEnd: dateRange[dateRange.length - 1],
                                subCategory: subCat
                              })
                            }
                          >
                            {getCategoryTotals(
                              data[category].subCategory[subCat]
                            ) === 0 ? (
                              '-'
                            ) : (
                              <FormattedNumber
                                value={getCategoryTotals(
                                  data[category].subCategory[subCat]
                                )}
                                style="currency"
                                currency="USD"
                              />
                            )}
                          </td>
                          {dateRange.map((date, i) => {
                            return (
                              <td
                                key={i}
                                className={'actionable'}
                                css={
                                  i < dateRange.length - 1
                                    ? [
                                        styles.notEndCell,
                                        styles.numbers,
                                        styles.actionable
                                      ]
                                    : [
                                        styles.rightSide,
                                        styles.numbers,
                                        styles.actionable
                                      ]
                                }
                                onClick={() =>
                                  handleCellClick({
                                    periodStart: date,
                                    periodEnd: date,
                                    subCategory: subCat
                                  })
                                }
                              >
                                {sumBy(
                                  data[category].subCategory[subCat][
                                    date.toDateString()
                                  ],
                                  (x) => x.allocatedAmount
                                ) === 0 ? (
                                  '-'
                                ) : (
                                  <FormattedNumber
                                    value={sumBy(
                                      data[category].subCategory[subCat][
                                        date.toDateString()
                                      ],
                                      (x) => x.allocatedAmount
                                    )}
                                    style="currency"
                                    currency="USD"
                                  />
                                )}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </>
                )}
              </>
            )
          })}
          <tr>
            <td
              css={[
                styles.isTotal,
                styles.bottomSide,
                styles.topSide,
                styles.catSticky,
                styles.totalCategory,
                {
                  borderLeft: '5px solid white',
                  paddingLeft: '10px'
                }
              ]}
            >
              Total
            </td>
            <td
              className={'actionable'}
              css={[
                styles.isTotal,
                styles.notEndCell,
                styles.bottomSide,
                styles.topSide,
                styles.numbers,
                styles.actionable,
                styles.totalSticky
              ]}
              onClick={() =>
                handleCellClick({
                  periodStart: dateRange[0],
                  periodEnd: dateRange[dateRange.length - 1]
                })
              }
            >
              {sum(totalArray) === 0 ? (
                '-'
              ) : (
                <FormattedNumber
                  value={sum(totalArray)}
                  style="currency"
                  currency="USD"
                />
              )}
            </td>
            {dateRange.map((date, i) => {
              return (
                <td
                  className={'actionable'}
                  key={i}
                  css={
                    i < dateRange.length - 1
                      ? [
                          styles.isTotal,
                          styles.notEndCell,
                          styles.bottomSide,
                          styles.topSide,
                          styles.numbers,
                          styles.actionable
                        ]
                      : [
                          styles.isTotal,
                          styles.rightSide,
                          styles.bottomSide,
                          styles.topSide,
                          styles.numbers,
                          styles.actionable
                        ]
                  }
                  onClick={() =>
                    handleCellClick({ periodStart: date, periodEnd: date })
                  }
                >
                  {getDateTotals(date) === 0 ? (
                    '-'
                  ) : (
                    <FormattedNumber
                      value={getDateTotals(date)}
                      style="currency"
                      currency="USD"
                    />
                  )}
                </td>
              )
            })}
          </tr>
        </tbody>
      </table>
    </div>
  )
}
