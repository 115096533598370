import { createReducer } from 'typesafe-actions'
import {
  fetchPaymentListDataActions,
  DashboardActionTypes,
  setUserRoleActions,
  setSelectedPaymentItemActions,
  setPaymentSearchSortFilterActions,
  setPaymentAutoRefreshFlagActions,
  paymentDashboardNavigationActions,
  fetchPaymentByIDActions,
  dashboardPreferenceActions,
  exportPaymentsToExcelActions,
  setPeriodicPlanSearchSortFilterActions,
  resendSMSLinkActions
} from './actions'
import {
  ColumnSetting,
  DashboardPreference,
  PaymentMM,
  PaymentRow,
  RefreshByRole,
  SearchSortFilter,
  Statistics
} from './types'

interface IDashboardState {
  paymentListState?: {
    isLoading: boolean
    error?: Error
    data?: PaymentRow[]
    statistics?: Statistics[]
  }
  userRole?: string
  paymentSearchSortFilterData?: SearchSortFilter
  periodicPlanSearchSortFilterData?: SearchSortFilter

  paymentAutoRefreshState?: RefreshByRole[]
  selectedPaymentItem?: PaymentMM
  columnPreferenceState?: ColumnSetting[]
  exportPayments?: IExportPaymentExcelState
  dashboardPreferenceState?: DashboardPreference
  paymentIDState?: {
    isLoading?: boolean
    error?: Error
    data?: PaymentMM
  }
  authrzLinkState?: {
    isLoading?: boolean
    error?: Error
    authzResp?: ISuccessCode
  }
}
export interface ISuccessCode {
  statusCode?: string
  statusMessage?: string
}
export interface IExportPaymentExcelState {
  paymentInstID?: string[]
  isLoading?: boolean
  error?: Error
}

const initialState: IDashboardState = {}

export const dashboardReducer = createReducer<
  IDashboardState,
  DashboardActionTypes
>(initialState)
  .handleAction(fetchPaymentListDataActions.request, (state) => ({
    ...state,
    paymentListState: {
      isLoading: true,
      error: undefined,
      data: undefined,
      statistics: undefined
    }
  }))
  .handleAction(fetchPaymentListDataActions.success, (state, action) => ({
    ...state,
    paymentListState: {
      isLoading: false,
      data: action.payload.paymentList,
      statistics: action.payload.statistics,
      error: undefined
    }
  }))
  .handleAction(fetchPaymentListDataActions.failure, (state, action) => ({
    ...state,
    paymentListState: {
      isLoading: false,
      data: undefined,
      statistics: undefined,
      error: action.payload
    }
  }))
  .handleAction(fetchPaymentByIDActions.request, (state) => ({
    ...state,
    paymentIDState: {
      isLoading: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(fetchPaymentByIDActions.success, (state, action) => ({
    ...state,
    paymentIDState: {
      isLoading: false,
      data: action.payload.paymentDetails,
      error: undefined
    }
  }))
  .handleAction(fetchPaymentByIDActions.clear, (state) => ({
    ...state,
    paymentIDState: undefined
  }))
  .handleAction(fetchPaymentByIDActions.failure, (state, action) => ({
    ...state,
    paymentIDState: {
      isLoading: false,
      data: undefined,
      error: action.payload
    }
  }))
  .handleAction(setUserRoleActions.setrole, (state, action) => ({
    ...state,
    userRole: action.payload
  }))
  .handleAction(
    setPaymentSearchSortFilterActions.setSearchSortFilter,
    (state, action) => ({
      ...state,
      paymentSearchSortFilterData: action.payload
    })
  )
  .handleAction(
    setPeriodicPlanSearchSortFilterActions.setSearchSortFilter,
    (state, action) => ({
      ...state,
      periodicPlanSearchSortFilterData: action.payload
    })
  )
  .handleAction(
    setPaymentAutoRefreshFlagActions.setAutoRefreshFlag,
    (state, action) => ({
      ...state,
      paymentAutoRefreshState: action.payload
    })
  )
  .handleAction(
    setSelectedPaymentItemActions.setPaymentItem,
    (state, action) => ({
      ...state,
      selectedPaymentItem: action.payload
    })
  )
  .handleAction(
    paymentDashboardNavigationActions.navigateToEdit,
    (state, action) => ({
      ...state,
      xferType: action.payload
    })
  )
  .handleAction(
    paymentDashboardNavigationActions.navigateToView,
    (state, action) => ({
      ...state,
      xferType: action.payload
    })
  )

  .handleAction(
    dashboardPreferenceActions.setColumnPreference,
    (state, action) => ({
      ...state,
      columnPreferenceState: action.payload
    })
  )

  .handleAction(dashboardPreferenceActions.setPreference, (state, action) => ({
    ...state,
    dashboardPreferenceState: action.payload
  }))
  .handleAction(
    dashboardPreferenceActions.getPreference_success,
    (state, action) => ({
      ...state,
      dashboardPreferenceState: action.payload
    })
  )
  .handleAction(exportPaymentsToExcelActions.request, (state) => ({
    ...state,
    exportPayments: {
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(exportPaymentsToExcelActions.success, (state) => ({
    ...state,
    exportPayments: {
      isLoading: false,
      error: undefined
    }
  }))
  .handleAction(exportPaymentsToExcelActions.failure, (state, action) => ({
    ...state,
    exportPayments: {
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(resendSMSLinkActions.request, (state) => ({
    ...state,
    authrzLinkState: {
      ...state?.authrzLinkState,
      authzResp: undefined,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(resendSMSLinkActions.success, (state, action) => ({
    ...state,
    authrzLinkState: {
      isLoading: false,
      authzResp: {
        statusCode: action?.payload?.statusCode,
        statusMessage: action?.payload?.message
      },
      error: undefined
    }
  }))
  .handleAction(resendSMSLinkActions.failure, (state, action) => ({
    ...state,
    authrzLinkState: {
      isLoading: false,
      authzResp: undefined,
      error: action.payload
    }
  }))
  .handleAction(resendSMSLinkActions.clear, (state) => ({
    ...state,
    authrzLinkState: undefined
  }))
