import { partial } from 'lodash/fp'
import { IOrder } from '../../../api/order.types'
import { createListsStore } from '../../../features/Lists/core/store'
import {
  createDefaultColumnState,
  mapColumnsToFilterDefinitions
} from '../../../features/Lists/shared/lists'
import { AppState } from '../../../store'
import { OrderColumnDefinitions } from './ColumnDefinitions'

export const constructInitialOrderListColumnState = partial(
  createDefaultColumnState,
  [OrderColumnDefinitions]
)

export const orderListStore = createListsStore<IOrder>(
  'order',
  '@features/@lists/@orders',
  {
    columnDefinitions: OrderColumnDefinitions,
    filterDefinitions: mapColumnsToFilterDefinitions(OrderColumnDefinitions)
  },
  (state: AppState) => state.features.lists.order
)

export const { reducer, sagas, actions, selectors } = orderListStore
