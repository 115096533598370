import {
  DefaultButton,
  PrimaryButton,
  ProgressIndicator,
  Stack
} from '@fluentui/react'

export const AccountLinkContentFooter: React.FC<{
  closePanel: () => void
  linkAccount: () => void
  isDisabled: boolean
  isLoading: boolean
}> = ({ closePanel, linkAccount, isDisabled, isLoading }) => (
  <>
    <Stack
      horizontal={true}
      horizontalAlign="center"
      tokens={{ childrenGap: 10 }}
      styles={{
        root: {
          margin: '20px 0'
        }
      }}
    >
      <PrimaryButton onClick={linkAccount} disabled={isDisabled}>
        Link Account
      </PrimaryButton>
      <DefaultButton onClick={closePanel}>Cancel</DefaultButton>
    </Stack>
    <ProgressIndicator
      progressHidden={!isLoading}
      styles={{
        itemProgress: { padding: 0, margin: 0 }
      }}
    />
  </>
)
