import axios from 'axios'
import { IApiOptions } from '../../../../../../../shared/contracts/IApiOptions'
import {
  IDynamicsApiResult,
  IPendingManagementReviewCount,
  ITrustDashboardInfo,
  ITrustOfficerReviewCount
} from './../store/types'

export const fetchTrustDashboardInfoListSvc = (options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/api/trusts`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IDynamicsApiResult<ITrustDashboardInfo>>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data.value)
}

export const fetchPendingManagementApprovalReviewCountSvc = (
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/trusts/pendMgmtReview/count`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IDynamicsApiResult<IPendingManagementReviewCount>>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data.value)
}

export const fetchTrustOfficerReviewCountSvc = (options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/api/trusts/trustofficerreview/count`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IDynamicsApiResult<ITrustOfficerReviewCount>>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data.value)
}
