import { ActionType, createAction } from 'typesafe-actions'

import {
  IReportSummaryListEntity,
  IReportDoc,
  IRunReport,
  IRunJob
} from './types'

export const PERFORMANCE_DASHBOARD_REQUEST = `@modules/@dashboard/PERFORMANCE_DASHBOARD_REQUEST`
export const PERFORMANCE_DASHBOARD_SUCCESS = `@modules/@dashboard/PERFORMANCE_DASHBOARD_SUCCESS`
export const PERFORMANCE_DASHBOARD_FAILURE = `@modules/@dashboard/PERFORMANCE_DASHBOARD_FAILURE`

export const SET_SELECTED_MONTH_YEAR = `@modules/@fees/SET_SELECTED_MONTH_YEAR`

export const performanceDashboardActions = {
  request: createAction(PERFORMANCE_DASHBOARD_REQUEST)(),
  success: createAction(PERFORMANCE_DASHBOARD_SUCCESS)<
    IReportSummaryListEntity[]
  >(),
  failure: createAction(PERFORMANCE_DASHBOARD_FAILURE)<Error>()
}
export const setSelectedMonthYearActions = {
  selectedMonthYear: createAction(SET_SELECTED_MONTH_YEAR)<string>()
}

export const VIEW_REPORT = `@modules/@fees/VIEW_REPORT`
export const VIEW_REPORT_SUCCESS = `@modules/@fees/VIEW_REPORT_SUCCESS`
export const VIEW_REPORT_FAILURE = `@modules/@fees/VIEW_REPORT_FAILURE`
export const viewReportActions = {
  request: createAction(VIEW_REPORT)<IReportDoc>(),
  success: createAction(VIEW_REPORT_SUCCESS)(),
  failure: createAction(VIEW_REPORT_FAILURE)<Error>()
}

export const RUN_REPORT = `@modules/@performance/RUN_REPORT`
export const RUN_REPORT_RESET = `@modules/@performance/RUN_REPORT_RESET`
export const RUN_REPORT_SUCCESS = `@modules/@performance/RUN_REPORT_SUCCESS`
export const RUN_REPORT_FAILURE = `@modules/@performance/RUN_REPORT_FAILURE`
export const runReportActions = {
  request: createAction(RUN_REPORT)<IRunReport>(),
  reset: createAction(RUN_REPORT_RESET)(),
  success: createAction(RUN_REPORT_SUCCESS)<IRunJob>(),
  failure: createAction(RUN_REPORT_FAILURE)<Error>()
}

export type ReportDataActionsTypes =
  | ActionType<typeof viewReportActions>
  | ActionType<typeof setSelectedMonthYearActions>
  | ActionType<typeof performanceDashboardActions>
  | ActionType<typeof runReportActions>
