import { IFacetResult } from '../../../../api/common.types'
import { DateRanges } from '../../../../shared'
import {
  IListsFilterDefinition,
  ListsFilterType
} from './IListsFilterDefinition'

export interface IListsFilterProps {
  hasValue?: boolean
  blankIndicator?: 'include' | 'exclude'
  type: ListsFilterType
  displayValue?: string
  disableModifications?: boolean
  hidden?: boolean
}

export enum NumberFilterTypesEnum {
  range = 'Range',
  eq = 'Equals',
  ne = 'Does Not Equal'
}
export const NumberFilterTypes = { ...NumberFilterTypesEnum }

export enum SearchFilterTypesEnum {
  eq = 'Equals',
  search = 'Search',
  regex = 'Regex'
}
export const SearchFilterTypes = { ...SearchFilterTypesEnum }

export interface IListsNumberRangeFilterProps {
  type: 'number'
  filterType?: keyof typeof NumberFilterTypesEnum
  value?: number
  min?: number
  max?: number
}

export interface IListsDateRangeFilterProps {
  type: 'date' | 'date-only'
  range?: DateRanges
  from?: Date
  to?: Date
}

export interface IListsFacetFilterProps {
  type: 'facet' | 'boolean'
  values?: string[]
  facets?: IFacetResult[]
}

export interface IListsSearchFilterProps {
  type: 'search'
  filterType?: keyof typeof SearchFilterTypesEnum
  value?: string
}

export type IListsFilterPropTypes =
  | IListsNumberRangeFilterProps
  | IListsDateRangeFilterProps
  | IListsFacetFilterProps
  | IListsSearchFilterProps

export type IListsFilter = IListsFilterProps & IListsFilterDefinition
export type IListsNumberRangeFilter = IListsNumberRangeFilterProps &
  IListsFilter
export type IListsDateRangeFilter = IListsDateRangeFilterProps & IListsFilter
export type IListsFacetFilter = IListsFacetFilterProps & IListsFilter
export type IListsSearchFilter = IListsSearchFilterProps & IListsFilter
