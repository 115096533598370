import { IColumn } from '@fluentui/react'
import { ActionType, createAction } from 'typesafe-actions'
import {
  IFilterDateRange,
  IOfacScreenRecord,
  IOfacTransactionStatusUpdate
} from './types'

export const FETCH_OFAC_SCREENING =
  '@modules/@compliance/@ofac/FETCH_OFAC_SCREENING'
export const FETCH_OFAC_SCREENING_SUCCESS =
  '@modules/@compliance/@ofac/FETCH_OFAC_SCREENING_SUCCESS'
export const FETCH_OFAC_SCREENING_FAILURE =
  '@modules/@compliance/@ofac/FETCH_OFAC_SCREENING_FAILURE'

export const UPDATE_OFAC_STATUS =
  '@modules/@compliance/@ofac/UPDATE_OFAC_STATUS'
export const UPDATE_OFAC_STATUS_SUCCESS =
  '@modules/@compliance/@ofac/UPDATE_OFAC_STATUS_SUCCESS'
export const UPDATE_OFAC_STATUS_FAILURE =
  '@modules/@compliance/@ofac/UPDATE_OFAC_STATUS_FAILURE'

export const UPDATE_OFAC_STATUS_RESET_UI =
  '@modules/@compliance/@ofac/UPDATE_OFAC_STATUS_RESET_UI'

export const EXPORT_OFAC_TRANS_TO_EXCEL = `@modules/@compliance/@ofac/EXPORT_OFAC_TRANS_TO_EXCEL`

export const ofacScreeningDataActions = {
  request: createAction(FETCH_OFAC_SCREENING)<IFilterDateRange>(),
  success: createAction(FETCH_OFAC_SCREENING_SUCCESS)<IOfacScreenRecord[]>(),
  failure: createAction(FETCH_OFAC_SCREENING_FAILURE)<Error>(),
  exportToexcel: createAction(EXPORT_OFAC_TRANS_TO_EXCEL)<{
    ofacTransactions: IOfacScreenRecord[]
    columns: IColumn[]
  }>()
}

export const updateOfacStatusActions = {
  request: createAction(UPDATE_OFAC_STATUS)<IOfacTransactionStatusUpdate>(),
  success: createAction(UPDATE_OFAC_STATUS_SUCCESS)(),
  failure: createAction(UPDATE_OFAC_STATUS_FAILURE)<Error>(),
  reset: createAction(UPDATE_OFAC_STATUS_RESET_UI)()
}

export type OfacScreeningDataActionTypes = ActionType<
  typeof ofacScreeningDataActions
>

export type UpdateOfacStatusActionTypes = ActionType<
  typeof updateOfacStatusActions
>

export type OfacScreeningActionType =
  | OfacScreeningDataActionTypes
  | UpdateOfacStatusActionTypes
