import {
  ChoiceGroup,
  DefaultButton,
  IChoiceGroupOption,
  IconButton,
  ITheme,
  Label,
  MessageBar,
  MessageBarType,
  ProgressIndicator,
  SearchBox,
  Stack,
  Text,
  TextField
} from '@fluentui/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IHousehold } from '../../../../api/household.types'
import { IDatahubHousehold } from '../../../../api/households'
import { AutoComplete } from '../../../../shared/components/AutoComplete'
import { USD } from '../../../../shared/components/Formatting'
import { ConnectedMasked } from '../../../../shared/components/MaskedText'
import { isNotNullOrFalse } from '../../../../shared/guards'
import { useClasses } from '../../../../shared/hooks/useClasses'
import { AccountNumberCell } from '../../../Lists/shared/AccountNumberCell'
import {
  changeRequestEditAccountSearchActions,
  changeRequestEditUiActions,
  getChangeRequestEditAccountSearchItems,
  getChangeRequestEditHouseholdsSearchItems,
  getChangeRequestEditUiHouseholdSearchText,
  getIsChangeRequestEditAccountSearchLoading,
  getIsChangeRequestEditHouseholdsSearchLoading
} from './store'

const getThemedClasses = (theme: ITheme) => ({
  selectedAccountsContainer: {
    backgroundColor: theme.palette.neutralLighterAlt,
    border: `solid 1px ${theme.palette.neutralLight}`,
    padding: '3px'
  },
  selectedAccounts: {
    maxHeight: '300px',
    overflow: 'auto',
    'flex-wrap': 'wrap'
  },
  accountButton: {
    backgroundColor: theme.palette.white,
    border: `solid 1px ${theme.palette.neutralQuaternary}`,
    paddingLeft: '10px',
    margin: '2px',
    '&:hover': {
      cursor: 'pointer'
    },
    '&:first-child': { marginLeft: 0 }
  },
  accountListTableContainer: {
    height: '100%',
    padding: '5px',
    overflow: 'auto'
  },
  accountListTable: {
    tableLayout: 'fixed',
    width: '100%',
    padding: 0,
    margin: 0,
    borderCollapse: 'collapse',
    '& th, & td': {
      textAlign: 'left',
      padding: '4px 4px',
      verticalAlign: 'top'
    },
    '& th:nth-child(1), & td:nth-child(1)': {
      width: '35%'
    },
    '& th:nth-child(2), & td:nth-child(2)': {
      width: '40%'
    },
    '& th:nth-child(3), & td:nth-child(3)': {
      textAlign: 'right',
      width: '25%'
    },
    '& tbody tr:hover': {
      backgroundColor: theme.palette.neutralLight,
      cursor: 'pointer'
    },
    '& tr:nth-child(even)': {
      backgroundColor: theme.palette.neutralLighterAlt
    }
  }
})

export interface SelectedAccount {
  accountNumber?: string
  accountId?: string
}

export interface IHouseholdChangeRequestEditProps {
  household?: IDatahubHousehold
  accounts?: SelectedAccount[]
  onHouseholdChanged: (household?: {
    householdId?: string
    householdName?: string
  }) => void
  onAccountsChanged: (accounts?: SelectedAccount[]) => void
}

export const HouseholdChangeRequestEdit: React.FC<
  IHouseholdChangeRequestEditProps
> = ({ household, accounts, onHouseholdChanged, onAccountsChanged }) => {
  const dispatch = useDispatch()
  const classes = useClasses(getThemedClasses)
  const accountLimitReached = accounts && accounts.length > 50

  useEffect(() => {
    dispatch(changeRequestEditUiActions.updateHouseholdSearchText(''))
  }, [dispatch])

  const { value: householdItems } =
    useSelector(getChangeRequestEditHouseholdsSearchItems) || {}

  const isHousholdsSearchLoading = useSelector(
    getIsChangeRequestEditHouseholdsSearchLoading
  )
  const handleHouseholdSearchTextChanged = useCallback(
    (text?: string) => {
      dispatch(changeRequestEditUiActions.updateHouseholdSearchText(text))
    },
    [dispatch]
  )

  const onHouseholdSelected = useCallback(
    (household?: IHousehold) => {
      if (!household) {
        return
      }

      if (!household?.householdId) {
        setNewOrExisting('new')
        onHouseholdChanged({ householdName: household?.householdName })
        return
      }

      if (onHouseholdChanged) {
        onHouseholdChanged(household)
      }
    },
    [onHouseholdChanged]
  )

  const { value: filteredAccountItems } =
    useSelector(getChangeRequestEditAccountSearchItems) || {}

  const isAccountsSearchLoading = useSelector(
    getIsChangeRequestEditAccountSearchLoading
  )

  const [searchText, setSearchText] = useState('')
  const updateAccountList = useCallback(
    (
      searchText?: string,
      accounts?: SelectedAccount[],
      householdId?: string
    ) => {
      dispatch(
        changeRequestEditAccountSearchActions.request({
          query: searchText,
          filterAccounts: accounts?.map((x) => x.accountId || ''),
          householdId: householdId
        })
      )
    },
    [dispatch]
  )

  useEffect(
    () => updateAccountList(searchText, accounts, household?.id),
    [accounts, household?.id, searchText, updateAccountList]
  )

  const handleAccountSearchTextChanged = useCallback(
    (_: unknown, text?: string) => {
      setSearchText(text || '')
    },
    []
  )

  const onAccountClicked = useCallback(
    (account?: SelectedAccount) => {
      if (
        !account ||
        accounts?.some((x) => x.accountId === account.accountId) ||
        accountLimitReached
      ) {
        return
      }

      onAccountsChanged([...(accounts || []), account])
    },
    [accountLimitReached, accounts, onAccountsChanged]
  )

  const onRemoveAccount = useCallback(
    (accountId?: string) => {
      if (!accountId) {
        return
      }

      const index = (accounts || []).findIndex((x) => x.accountId === accountId)

      if (index < 0) {
        return
      }

      const copy = [...(accounts || [])]
      copy.splice(index, 1)
      onAccountsChanged(copy)
    },
    [accounts, onAccountsChanged]
  )

  const householdSearchText = useSelector(
    getChangeRequestEditUiHouseholdSearchText
  )

  const householdAutoCompleteItems = useMemo(
    () =>
      [
        ...(householdItems || []),
        !!householdSearchText &&
          ({ householdName: householdSearchText } as IHousehold)
      ].filter(isNotNullOrFalse),
    [householdItems, householdSearchText]
  )

  const [newOrExisting, setNewOrExisting] = useState<'new' | 'existing'>()
  useEffect(() => {
    if (newOrExisting || !household) {
      return
    }
    setNewOrExisting(household?.id ? 'existing' : 'new')
  }, [household, newOrExisting])

  const onNewOrExistingChange = useCallback(
    (ev?: any, option?: IChoiceGroupOption) => {
      if (!option) {
        return
      }
      onHouseholdChanged(undefined)

      setNewOrExisting(option.key as typeof newOrExisting)
    },
    [onHouseholdChanged]
  )

  const options: IChoiceGroupOption[] = useMemo(
    () => [
      { key: 'new', text: 'Create a New Household' },
      { key: 'existing', text: 'Add to an Existing Household' }
    ],
    []
  )

  const onNewHouseholdNameChange = useCallback(
    (_ev?: unknown, value?: string) => {
      onHouseholdChanged({ householdName: value })
    },
    [onHouseholdChanged]
  )

  return (
    <Stack tokens={{ childrenGap: 20 }} verticalFill={true}>
      <ChoiceGroup
        options={options}
        selectedKey={newOrExisting || 'new'}
        label="Move Accounts into a New or Existing Household?"
        onChange={onNewOrExistingChange}
      />
      {newOrExisting === 'existing' && (
        <Stack.Item>
          <Label>Target Household</Label>
          {household ? (
            <Stack.Item styles={{ root: { display: 'inline-flex' } }}>
              <Stack
                horizontal={true}
                verticalAlign="center"
                className={classes.accountButton}
                tokens={{ childrenGap: 3 }}
              >
                <Text>{household.name}</Text>
                <IconButton
                  iconProps={{ iconName: 'Edit' }}
                  onClick={() => onHouseholdChanged?.(undefined)}
                />
              </Stack>
            </Stack.Item>
          ) : (
            <AutoComplete<IHousehold>
              placeholder="Select a Household"
              value={householdSearchText}
              onSearchTextChanged={handleHouseholdSearchTextChanged}
              items={householdAutoCompleteItems || []}
              onItemSelected={onHouseholdSelected}
              itemComponent={HouseholdAutoCompleteItem}
              showLoadingIndicator={isHousholdsSearchLoading}
            />
          )}
        </Stack.Item>
      )}
      {newOrExisting !== 'existing' && (
        <Stack.Item>
          <TextField
            value={household?.name}
            onChange={onNewHouseholdNameChange}
            label="Household Name"
            placeholder="Example Relationship"
            autoComplete="off"
          />
        </Stack.Item>
      )}
      <Stack.Item grow={1} styles={{ root: { minHeight: 0 } }}>
        <Stack verticalFill={true}>
          <Label>Select Accounts</Label>
          {accountLimitReached && (
            <MessageBar messageBarType={MessageBarType.warning}>
              A maximum of 50 accounts can be added per request
            </MessageBar>
          )}
          {!!accounts?.length && (
            <Stack.Item className={classes.selectedAccountsContainer}>
              <Stack horizontal={true} className={classes.selectedAccounts}>
                {accounts?.map((x) => {
                  return (
                    <Stack
                      horizontal={true}
                      key={x.accountId}
                      verticalAlign="center"
                      tokens={{ childrenGap: 3 }}
                      className={classes.accountButton}
                    >
                      <Text variant="smallPlus">
                        <ConnectedMasked text={x.accountNumber} />
                      </Text>
                      <IconButton
                        iconProps={{ iconName: 'Cancel' }}
                        onClick={() => onRemoveAccount(x.accountId)}
                      />
                    </Stack>
                  )
                })}
              </Stack>
            </Stack.Item>
          )}
          {!accounts?.length && (
            <MessageBar>Please select accounts from the list below</MessageBar>
          )}
          <Stack.Item
            styles={{
              root: { paddingTop: '10px' }
            }}
          >
            <Stack>
              <SearchBox
                styles={{
                  root: { width: '100%' }
                }}
                placeholder="Filter accounts by number, nickname, legal entity name, or current household"
                autoComplete="off"
                value={searchText}
                onChange={handleAccountSearchTextChanged}
              />
              <ProgressIndicator
                progressHidden={!isAccountsSearchLoading}
                styles={{
                  itemProgress: { padding: 0, margin: 0 }
                }}
              />
            </Stack>
          </Stack.Item>
          <Stack.Item
            grow={1}
            styles={{
              root: { paddingTop: '10px', minHeight: 0 }
            }}
          >
            <div className={classes.accountListTableContainer}>
              <table className={classes.accountListTable}>
                <thead>
                  <tr>
                    <th>Account</th>
                    <th>Legal Entity Name / Current Household</th>
                    <th>AUS</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAccountItems?.map((account) => {
                    const {
                      CustodyAccount,
                      id,
                      LegalEntityName,
                      householdName,
                      AccountKPIs,
                      accountId
                    } = account
                    return (
                      <tr
                        key={id}
                        onClick={() =>
                          onAccountClicked({
                            accountNumber: CustodyAccount,
                            accountId: accountId
                          })
                        }
                      >
                        <td>
                          <AccountNumberCell account={account} menu={null} />
                        </td>
                        <td>
                          <Stack>
                            <Text>{LegalEntityName}</Text>
                            <Text variant="small">{householdName}</Text>
                          </Stack>
                        </td>
                        <td>
                          {AccountKPIs?.AccountTotal != null ? (
                            <USD
                              value={AccountKPIs.AccountTotal}
                              fractionDigits={0}
                            />
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              {!filteredAccountItems?.length && (
                <MessageBar>
                  No accounts found, please refine your search criteria.
                </MessageBar>
              )}
            </div>
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  )
}

const HouseholdAutoCompleteItem: React.FC<{ item?: IHousehold }> = ({
  item
}) => {
  const partyLength = item?.Parties?.length || 0
  const accountLength = item?.Account?.length || 0
  const isNew = !item?.householdId

  if (isNew) {
    return (
      <Stack tokens={{ padding: '20px' }} horizontalAlign="center">
        <DefaultButton iconProps={{ iconName: 'Add' }}>
          <Text>Create a new household</Text>
        </DefaultButton>
      </Stack>
    )
  }
  return (
    <Stack
      horizontal={true}
      horizontalAlign="space-between"
      tokens={{ childrenGap: 10 }}
      styles={{ root: { padding: '5px 10px', minWidth: 0 } }}
    >
      <Stack styles={{ root: { minWidth: 0 } }}>
        <Text block={true} nowrap={true}>
          {item?.householdName}
        </Text>
        {(item?.Account || item?.Parties) && (
          <Text variant="small">
            {partyLength} client{partyLength !== 1 && <>s</>} | {accountLength}{' '}
            account{accountLength !== 1 && <>s</>}
          </Text>
        )}
      </Stack>
      {!!item?.householdKPI?.AumTotal && (
        <Text>
          <USD value={item?.householdKPI?.AumTotal || 0} />
        </Text>
      )}
    </Stack>
  )
}
