import {
  Icon,
  ITheme,
  makeStyles,
  MessageBar,
  Overlay,
  Stack,
  Text,
  TooltipHost
} from '@fluentui/react'
import { format, getMonth, startOfMonth, subMonths } from 'date-fns'
import { keyBy, range, uniq } from 'lodash'
import React, {
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo
} from 'react'
import { FormattedNumber } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import tinycolor from 'tinycolor2'
import {
  IHurdleProgressMeasurement,
  IRevenueDashboardHurdleSummaryItem
} from '../../../../../../../../api/datahub'
import { useDomainStore } from '../../../../../../../../features/Domain/store/domain'
import { parseDateISOStringInLocalTimezone } from '../../../../../../../../shared'
import { ErrorComponent } from '../../../../../../../../shared/components/Error'
import { USD } from '../../../../../../../../shared/components/Formatting'
import { LoadingComponent } from '../../../../../../../../shared/components/Loading'
import {
  IPaletteComponentOwnProps,
  PaletteComponent
} from '../../../../../../../../shared/components/Palette'
import { Separator } from '../../../../../../../../shared/components/Separator'
import { isNotNullOrEmpty } from '../../../../../../../../shared/guards'
import { chartColors } from '../../../../../../../../shared/services/theme'
import { useHurdleSummaryFetch } from './store/hurdleSummaryFetch'
import { useRevenueSummaryFetch } from './store/revenueSummaryFetch'

const useClassesForDivider = makeStyles((theme: ITheme) => ({
  divider: {
    borderRight: `solid 1px ${theme.palette.neutralQuaternary}`
  }
}))

const VerticalDivider: React.FC = () => {
  const classes = useClassesForDivider()
  return <div className={classes.divider} />
}

interface IRevenueSummaryProgress {
  title?: string
  value1?: number
  value2?: number
}

interface IRevenueSummaryStat {
  title?: string
  value?: number
}
interface IRevenueSummaryTileProps {
  stats?: IRevenueSummaryStat[]
  progressTitle?: string
  progressItems?: IRevenueSummaryProgress[]
}

const AbbreviatedUSD: React.FC<{ value?: number }> = ({ value }) =>
  value != null ? (
    <FormattedNumber
      notation="compact"
      maximumFractionDigits={1}
      style="currency"
      currency="USD"
      currencySign="accounting"
      value={value}
    />
  ) : (
    <>--</>
  )

const RevenueSummaryTile: React.FC<IRevenueSummaryTileProps> = ({
  stats,
  progressTitle,
  progressItems
}) => {
  return (
    <Stack tokens={{ childrenGap: 3 }}>
      <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
        {stats?.map(({ title, value }, i) => (
          <Fragment key={i}>
            <Stack>
              <Text variant="small">{title}</Text>
              <Text variant="large" style={{ fontWeight: 'bold' }}>
                <AbbreviatedUSD value={value} />
              </Text>
            </Stack>
            {i < stats.length - 1 && <VerticalDivider />}
          </Fragment>
        ))}
      </Stack>
      <Separator styles={{ root: { height: '1px' } }} />
      <Text variant="small">{progressTitle}</Text>
      <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
        {!progressItems?.length && <MessageBar>No data available</MessageBar>}
        {progressItems?.map(({ title, value1, value2 }, i) => (
          <Fragment key={i}>
            <Stack styles={{ root: { maxWidth: '100px' } }}>
              <Stack
                horizontal={true}
                horizontalAlign="space-between"
                verticalAlign="center"
                tokens={{ childrenGap: 10 }}
              >
                <Text nowrap={true} variant="small">
                  {title}
                </Text>
                <Text nowrap={true} style={{ fontWeight: 'bold' }}>
                  {value2 ? (
                    <>{(((value1 || 0) / value2) * 100).toFixed(0)}%</>
                  ) : (
                    <>--</>
                  )}
                </Text>
              </Stack>
              {value2 ? (
                <ProgressBar progress={(value1 || 0) / value2} height="20px" />
              ) : (
                <Text>--</Text>
              )}
            </Stack>
            {i < progressItems.length - 1 && <VerticalDivider />}
          </Fragment>
        ))}
      </Stack>
    </Stack>
  )
}

const usePaletteClasses = makeStyles((theme) => ({
  palette: {
    '&:hover': {
      backgroundColor: theme.palette.neutralLighterAlt,
      cursor: 'pointer'
    }
  }
}))

const PaletteComponentWithLoading: React.FC<
  IPaletteComponentOwnProps & { loading?: boolean }
> = ({ children, loading = false, isButton = false, ...rest }) => {
  const classes = usePaletteClasses()
  return (
    <PaletteComponent
      {...rest}
      className={isButton ? classes.palette : undefined}
    >
      {loading && (
        <Overlay styles={{ root: { zIndex: 1 } }}>
          <LoadingComponent />
        </Overlay>
      )}
      {children}
    </PaletteComponent>
  )
}

const maxTileWidth = '450px'
const dateFormat = `MMM ''yy`
const yearFormat = `yyyy`
const getFormattedDate = (date?: string) => {
  const parsed = date ? parseDateISOStringInLocalTimezone(date) : undefined
  const formatted = parsed && format(parsed, dateFormat)
  return formatted
}
const getFormattedYear = (date?: string) => {
  const parsed = date ? parseDateISOStringInLocalTimezone(date) : undefined
  const formatted = parsed && format(parsed, yearFormat)
  return formatted
}
const getMonthFromDateString = (date?: string) => {
  const parsed = date ? parseDateISOStringInLocalTimezone(date) : undefined
  return parsed && getMonth(parsed)
}

export const RevenueTiles: React.FC<{ showHurdleSummary: boolean }> = ({
  showHurdleSummary
}) => {
  const navigate = useNavigate()
  const {
    selectedAccountRepIds,
    selectedAdvisorRepIds,
    selectedTeams,
    isFullDomainSelected
  } = useDomainStore()

  const {
    sendRequest: requestRevenueSummary,
    result: revenueSummary,
    loading: isRevenueSummaryLoading,
    error: revenueSummaryError
  } = useRevenueSummaryFetch()

  const {
    sendRequest: requestHurdleSummary,
    result: hurdleSummary,
    loading: isHurdleSummaryLoading,
    error: hurdleSummaryError
  } = useHurdleSummaryFetch()

  useEffect(() => {
    if (!selectedAccountRepIds?.length) {
      return
    }

    requestRevenueSummary({
      tradeReps: selectedAccountRepIds || [],
      recipientReps: selectedAdvisorRepIds
    })
  }, [requestRevenueSummary, selectedAccountRepIds, selectedAdvisorRepIds])

  useEffect(() => {
    if (!selectedAccountRepIds?.length || !showHurdleSummary) {
      return
    }

    requestHurdleSummary(
      isFullDomainSelected
        ? undefined
        : {
            repIds: selectedAccountRepIds,
            teamIds: selectedTeams
          }
    )
  }, [
    isFullDomainSelected,
    requestHurdleSummary,
    selectedAccountRepIds,
    selectedTeams,
    showHurdleSummary
  ])

  const onHurdleClicked = useCallback(() => {
    navigate('../hurdles')
  }, [navigate])

  const yearOverYearTileData = useMemo((): IRevenueSummaryTileProps => {
    const { currentYear, mostRecentlyClosed, priorYear, prior3Months } =
      revenueSummary || {}

    const stats: IRevenueSummaryStat[] = [
      {
        title: `${getFormattedYear(currentYear?.period) || '--'} YTD`,
        value: currentYear?.ytdRevenue
      },
      {
        title: `T12 as of ${
          getFormattedDate(mostRecentlyClosed?.period) || '--'
        }`,
        value: mostRecentlyClosed?.t12Revenue
      },
      {
        title: `${getFormattedYear(priorYear?.period) || '--'} Total`,
        value: priorYear?.ytdRevenue
      }
    ]
    const monthsLookupByMonth = keyBy(prior3Months, ({ period }) => {
      const month = getMonthFromDateString(period)
      return month != null ? month : '--'
    })

    const dates = range(0, 3).map((x) => subMonths(startOfMonth(new Date()), x))

    const progressItems = dates?.map((date): IRevenueSummaryProgress => {
      const month = getMonth(date)
      return {
        title: format(date, dateFormat) || '--',
        value1:
          month != null
            ? monthsLookupByMonth[month]?.annualizedRevenue
            : undefined,
        value2: priorYear?.ytdRevenue
      }
    })

    return {
      stats,
      progressTitle: `Period Annualized vs ${
        getFormattedYear(priorYear?.period) || '--'
      } Total`,
      progressItems
    }
  }, [revenueSummary])

  const yearOverYearCurrentMonthTileData =
    useMemo((): IRevenueSummaryTileProps => {
      const { prior3Months, priorYearPrior3Months } = revenueSummary || {}
      const priorYearMonthsLookupByMonth = keyBy(
        priorYearPrior3Months,
        ({ period }) => {
          const month = getMonthFromDateString(period)
          return month != null ? month : '--'
        }
      )

      const monthsLookupByMonth = keyBy(prior3Months, ({ period }) => {
        const month = getMonthFromDateString(period)
        return month != null ? month : '--'
      })

      const dates = range(0, 3).map((x) =>
        subMonths(startOfMonth(new Date()), x)
      )

      const stats = dates?.map((date): IRevenueSummaryStat => {
        const month = getMonth(date)
        return {
          title: format(date, dateFormat) || '--',
          value:
            month != null
              ? monthsLookupByMonth[month]?.periodRevenue
              : undefined
        }
      })

      const progressItems = dates?.map((date): IRevenueSummaryProgress => {
        const month = getMonth(date)
        return {
          title: format(date, dateFormat) || '--',
          value1:
            month != null
              ? monthsLookupByMonth[month]?.periodRevenue
              : undefined,
          value2:
            month != null
              ? priorYearMonthsLookupByMonth[month]?.periodRevenue
              : undefined
        }
      })

      return {
        stats,
        progressTitle: 'Period vs Same Period 1 Year Prior',
        progressItems
      }
    }, [revenueSummary])

  return (
    <Stack
      horizontal={true}
      wrap={true}
      verticalFill={true}
      tokens={{ childrenGap: 10 }}
    >
      <Stack.Item
        styles={{ root: { minWidth: '150px', maxWidth: maxTileWidth } }}
      >
        <PaletteComponentWithLoading
          loading={
            (!revenueSummary && !revenueSummaryError) || isRevenueSummaryLoading
          }
        >
          <RevenueSummaryTile {...yearOverYearTileData} />
          {revenueSummaryError && (
            <ErrorComponent
              errorMessage={
                revenueSummaryError.message || 'An unknown error occurred'
              }
            />
          )}
        </PaletteComponentWithLoading>
      </Stack.Item>
      <Stack.Item
        styles={{ root: { minWidth: '150px', maxWidth: maxTileWidth } }}
      >
        <PaletteComponentWithLoading
          loading={
            (!revenueSummary && !revenueSummaryError) || isRevenueSummaryLoading
          }
        >
          <RevenueSummaryTile {...yearOverYearCurrentMonthTileData} />
          {revenueSummaryError && (
            <ErrorComponent
              errorMessage={
                revenueSummaryError.message || 'An unknown error occurred'
              }
            />
          )}
        </PaletteComponentWithLoading>
      </Stack.Item>
      {showHurdleSummary && (
        <Stack.Item
          styles={{ root: { minWidth: '150px', maxWidth: maxTileWidth } }}
        >
          <PaletteComponentWithLoading
            onClick={onHurdleClicked}
            isButton={true}
            loading={
              (!hurdleSummary && !hurdleSummaryError) || isHurdleSummaryLoading
            }
          >
            <Stack tokens={{ childrenGap: 5 }}>
              <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
                <Stack>
                  <Text variant="small">Total Hurdle(s)</Text>
                  <Text
                    variant="large"
                    styles={{ root: { fontWeight: 'bold' } }}
                  >
                    {hurdleSummary?.hurdleCount || 0}
                  </Text>
                </Stack>
                <VerticalDivider />
                <Stack>
                  <Text variant="small">Active Hurdle(s)</Text>
                  <Text
                    variant="large"
                    styles={{ root: { fontWeight: 'bold' } }}
                  >
                    {hurdleSummary?.activeHurdleCount || 0}
                  </Text>
                </Stack>
              </Stack>
              <Separator styles={{ root: { height: '1px' } }} />
              {!hurdleSummary?.futureMeasurement && (
                <MessageBar>No upcoming hurdles</MessageBar>
              )}
              {hurdleSummaryError && (
                <ErrorComponent
                  errorMessage={
                    hurdleSummaryError.message || 'An unknown error occurred'
                  }
                />
              )}
              {hurdleSummary?.futureMeasurement && (
                <>
                  <Text
                    variant="small"
                    nowrap={true}
                    title={hurdleSummary?.futureMeasurement?.name}
                  >
                    Upcoming | {hurdleSummary?.futureMeasurement?.name}
                  </Text>

                  <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
                    <Stack>
                      <Text variant="small">Target</Text>
                      <Text styles={{ root: { fontWeight: 'bold' } }}>
                        {hurdleSummary?.futureMeasurement?.targetValue ? (
                          <USD
                            value={hurdleSummary.futureMeasurement.targetValue}
                            fractionDigits={0}
                          />
                        ) : (
                          <>--</>
                        )}
                      </Text>
                    </Stack>
                    <VerticalDivider />
                    <Stack>
                      <Text variant="small">Target Date</Text>
                      <Text styles={{ root: { fontWeight: 'bold' } }}>
                        {hurdleSummary?.futureMeasurement?.targetDate
                          ? format(
                              parseDateISOStringInLocalTimezone(
                                hurdleSummary?.futureMeasurement?.targetDate
                              ),
                              dateFormat
                            )
                          : '--'}
                      </Text>
                    </Stack>
                  </Stack>
                </>
              )}
            </Stack>
          </PaletteComponentWithLoading>
        </Stack.Item>
      )}
      {hurdleSummary?.results?.map((hurdle, index) => (
        <Stack.Item
          key={index}
          styles={{ root: { minWidth: '150px', maxWidth: maxTileWidth } }}
        >
          <PaletteComponentWithLoading
            onClick={onHurdleClicked}
            isButton={true}
            loading={isHurdleSummaryLoading}
          >
            <HurdleTile hurdle={hurdle} />
          </PaletteComponentWithLoading>
        </Stack.Item>
      ))}
    </Stack>
  )
}

const blueLight = chartColors[1]
const blueDarkHex = tinycolor(blueLight).darken(25).toHex()
const useClassesForProgressBar = makeStyles((theme: ITheme) => ({
  container: {
    backgroundColor: theme.palette.neutralLight,
    position: 'relative',
    width: '100%',
    display: 'flex',
    minHeight: '1px'
  },
  bar: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: `#${blueDarkHex}`
  },
  children: {
    position: 'relative',
    width: '100%',
    zIndex: 5
  }
}))

const ProgressBar: React.FC<
  PropsWithChildren<{ progress: number; height?: string }>
> = ({ progress, children, height = 'auto' }) => {
  const classes = useClassesForProgressBar()
  return (
    <div className={classes.container} style={{ height }}>
      <div
        className={classes.bar}
        style={{
          width: `${progress ? (progress < 1 ? progress * 100 : 100) : 0}%`
        }}
      />
      {!!children && <div className={classes.children}>{children}</div>}
    </div>
  )
}

const HurdleTile: React.FC<{
  hurdle: IRevenueDashboardHurdleSummaryItem
}> = ({ hurdle }) => {
  const { measurements, dateAchieved } = hurdle || {}
  const [firstMeasurement] = measurements || []

  if (!firstMeasurement || !firstMeasurement.currentPeriodDate) {
    return null
  }

  const hurdleDates = uniq(
    dateAchieved
      ? [dateAchieved]
      : measurements
          ?.map(({ hurdleDate }) => hurdleDate)
          .filter(isNotNullOrEmpty)
  )
    .map(parseDateISOStringInLocalTimezone)
    .map((x) => format(x, dateFormat))

  return (
    <Stack
      tokens={{ childrenGap: 5 }}
      styles={{ root: { minWidth: '150px', maxWidth: '350px' } }}
    >
      {firstMeasurement.name && (
        <Stack.Item>
          <Stack horizontal={true} horizontalAlign="space-between">
            <Text
              variant="small"
              nowrap={true}
              block={true}
              title={firstMeasurement.name}
            >
              {firstMeasurement.name}
            </Text>
            <Stack.Item>
              <TooltipHost
                styles={{ root: { height: '100%', width: '100%' } }}
                tooltipProps={{
                  onRenderContent: () => {
                    return (
                      <div>
                        Revenue is updated daily and not considered final until
                        the month is closed
                      </div>
                    )
                  }
                }}
              >
                <Icon iconName="info" />
              </TooltipHost>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      )}
      <Stack.Item>
        <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
          <Stack>
            <Text variant="small">Target</Text>
            <Text styles={{ root: { fontWeight: 'bold' } }}>
              {firstMeasurement?.targetMetricValue ? (
                <USD
                  value={firstMeasurement?.targetMetricValue}
                  fractionDigits={0}
                />
              ) : (
                <>--</>
              )}
            </Text>
          </Stack>
          <VerticalDivider />
          <Stack style={{ whiteSpace: 'nowrap' }}>
            {!dateAchieved && <Text variant="small">Hurdle Date(s)</Text>}
            {dateAchieved && <Text variant="small">Achieved Date</Text>}
            <Text styles={{ root: { fontWeight: 'bold' } }}>
              {hurdleDates?.length ? <>{hurdleDates.join(', ')}</> : <>--</>}
            </Text>
          </Stack>
        </Stack>
      </Stack.Item>
      <Separator styles={{ root: { height: '1px' } }} />
      <Stack.Item>
        <HurdleTileMeasurements measurements={measurements} />
      </Stack.Item>
    </Stack>
  )
}

const HurdleTileMeasurements: React.FC<{
  measurements?: IHurdleProgressMeasurement[]
}> = ({ measurements }) => {
  const [firstMeasurement] = measurements || []

  if (!firstMeasurement || !firstMeasurement.currentPeriodDate) {
    return <MessageBar>No active measurements.</MessageBar>
  }

  const firstDate = parseDateISOStringInLocalTimezone(
    firstMeasurement.currentPeriodDate
  )

  const dates = range(0, 3).map((x) => subMonths(firstDate, x))

  return (
    <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
      {dates.map((x, i) => {
        const measurement = measurements?.[i]
        const date = measurement?.currentPeriodDate
          ? parseDateISOStringInLocalTimezone(measurement.currentPeriodDate)
          : x
        return (
          <Fragment key={i}>
            <Stack>
              <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
                <Text nowrap={true} variant="small">
                  {format(date, dateFormat)}
                </Text>
                {!!measurement?.metricProgress && (
                  <Text
                    variant="small"
                    styles={{ root: { fontWeight: 'bold' } }}
                  >
                    {((measurement.metricProgress || 0) * 100).toFixed(0)}%
                  </Text>
                )}
              </Stack>
              {measurement?.metricProgress ? (
                <ProgressBar
                  progress={measurement.metricProgress || 0}
                  height="20px"
                />
              ) : (
                <Text>--</Text>
              )}
            </Stack>
            {i < dates.length - 1 && <VerticalDivider />}
          </Fragment>
        )
      })}
    </Stack>
  )
}
