import { lazy } from 'react'
import { IModuleDefinition } from '../types'

const ComponentsContainer = lazy(() => import('./ComponentsContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/components/*',
    private: true,
    component: ComponentsContainer
  },
  name: 'Components'
}

export default moduleDefinition
