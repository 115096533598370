import { IColumnDefinition } from 'features/Lists/core/contracts/IColumnDefinition'
import { IColumnState } from 'features/Lists/core/contracts/IColumnState'
import {
  IListsDateRangeFilter,
  IListsFacetFilter,
  IListsFilter
} from 'features/Lists/core/contracts/IListsFilter'
import { IListsUiState } from 'features/Lists/core/contracts/IListsUIState'
import {
  createDefaultColumnState,
  createFilter
} from 'features/Lists/shared/lists'
import { flow, isUndefined, omitBy, keyBy } from 'lodash'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { IListPreferences } from './IListPreferences'

const dateFilterTypes = ['date', 'date-only']

const getFixedDateFilters = (filterProps?: IListsFilter[]) =>
  filterProps?.map((value) => {
    const isDateTypeFilter = dateFilterTypes.includes(value.type)
    if (!isDateTypeFilter) {
      return value
    }
    const valTo = 'to' in value && (value.to as Date | string)
    const valFrom = 'from' in value && (value.from as Date | string)

    const to =
      typeof valTo === 'string'
        ? parseDateISOStringInLocalTimezone(valTo)
        : valTo || undefined

    const from =
      typeof valFrom === 'string'
        ? parseDateISOStringInLocalTimezone(valFrom)
        : valFrom || undefined

    return { ...value, to, from }
  })

export const getPreSaveListState = (
  listState: IListsUiState
): IListPreferences => {
  const preSaveListState = structuredClone(listState)
  const { columnState, filters, orderBy, searchText } = preSaveListState
  const selectedColumns = columnState?.filter((x) => x.selected)
  const filterEntries = Object.entries(filters || {}).filter(
    ([, value]) => !value.hidden
  )
  const filterEntriesMapped = filterEntries.map(([, val]) => {
    const {
      id,
      type,
      blankIndicator,
      filterType,
      value,
      values,
      min,
      max,
      range,
      from,
      to
    } = val as IListsFilter & IListsDateRangeFilter & IListsFacetFilter
    const filterPropsToSave = omitBy(
      {
        id,
        type,
        blankIndicator,
        filterType,
        value,
        values,
        min,
        max,
        range,
        from,
        to
      },
      isUndefined
    ) as IListsFilter
    return filterPropsToSave
  })
  return {
    searchText,
    columnState: selectedColumns,
    filters: filterEntriesMapped,
    orderBy
  } as IListPreferences
}

export const getPreLoadListState = (
  preferences: IListPreferences,
  currentColumnDefinitions: Record<string, IColumnDefinition>,
  additionalFilters?: Record<string, IListsFilter>
): IListsUiState => {
  const {
    columnState: selectedColumns,
    filters: preferenceFiltersProps,
    orderBy,
    searchText
  } = preferences
  const getSelectedColumnsKeys = flow(keyBy, Object.keys)
  const selectedColumnsKeys = getSelectedColumnsKeys(
    selectedColumns,
    (x: IColumnState) => x.columnId
  ) as string[]
  const availableSelectedColumnsKeys = selectedColumnsKeys.filter(
    (key) => !!currentColumnDefinitions[key]
  )
  const availableFilterProps = preferenceFiltersProps?.filter(
    ({ id }) => !!currentColumnDefinitions[id]
  )
  const fixedDateFilters = getFixedDateFilters(availableFilterProps)

  const preferenceFiltersMapped = fixedDateFilters?.map((value) => {
    return createFilter(currentColumnDefinitions, value.id, value)
  })

  const preferenceFiltersKeyBy = keyBy(preferenceFiltersMapped, (x) => x.id)

  const defaultColumnState = createDefaultColumnState(
    currentColumnDefinitions,
    availableSelectedColumnsKeys
  )
  const rehydratedColumnState = defaultColumnState.map((x) => {
    const columnToUpdate = selectedColumns?.find(
      (y) => y.columnId === x.columnId
    )
    return columnToUpdate
      ? {
          ...x,
          ...columnToUpdate
        }
      : x
  })

  return {
    searchText,
    columnState: rehydratedColumnState,
    filters: {
      ...(additionalFilters || {}),
      ...preferenceFiltersKeyBy
    },
    orderBy
  } as IListsUiState
}
