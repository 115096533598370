import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack
} from '@fluentui/react'
import { IReportSearchRequest } from 'api/datahub'
import React, { useCallback } from 'react'
import {
  OptionsIncomeAndExpenseReportCriteria,
  useReportCriteriaEditCriteria
} from '../ReportCriteriaEdit'
import { useReportCriteriaPanel } from './store'

export const OptionsIncomeAndExpenseReportPanel: React.FC<{
  onSubmit: (criteria: IReportSearchRequest) => void
}> = ({ onSubmit }) => {
  const { criteria, isValidCriteria } = useReportCriteriaEditCriteria()
  const { isOpen, close } = useReportCriteriaPanel()

  const onDismiss = useCallback(() => close(), [close])

  const onSubmitClick = useCallback(() => {
    onSubmit(criteria)
    onDismiss()
  }, [onSubmit, criteria, onDismiss])

  const onRenderFooterContent = useCallback(() => {
    return (
      <Stack tokens={{ childrenGap: 10 }}>
        <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
          <PrimaryButton onClick={onSubmitClick} disabled={!isValidCriteria}>
            Apply
          </PrimaryButton>
          <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
        </Stack>
      </Stack>
    )
  }, [isValidCriteria, onDismiss, onSubmitClick])

  return (
    <>
      <Panel
        headerText={`Search Report`}
        onDismiss={onDismiss}
        type={PanelType.medium}
        isOpen={isOpen}
        isLightDismiss={true}
        isFooterAtBottom={true}
        onRenderFooterContent={onRenderFooterContent}
        styles={{ content: { flexGrow: 1 } }}
      >
        <OptionsIncomeAndExpenseReportCriteria />
      </Panel>
    </>
  )
}
