import { css, Theme, useTheme } from '@emotion/react'
import { IDropdownOption, Toggle } from '@fluentui/react'
import { Dropdown } from 'modules/Advisory/modules/Rdot360/components/shared'
import DetailsNavigator from 'modules/Advisory/modules/Rdot360/components/shared/DetailsNavigator/DetailsNavigator'
import { Searchbox } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/Searchbox'
import { Icon } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { useIncomeFeatureStore } from 'modules/Advisory/modules/Rdot360/features/Income'
import { IndeterminateCheckbox } from 'modules/Advisory/modules/Rdot360/shared/IndeterminateCheckbox'
import React, { FC, useCallback, useMemo } from 'react'
import { useMount } from 'react-use'
import {
  viewByOptions,
  yearRangeOptions,
  projectedDateRangeOptions,
  currentYear
} from '../../components/shared/filterOptions'
import { useHoldingsApiUtil } from '../../store/holdingsApi'
import { AccountExport } from './features/AccountView/AccountExport'
import { SummaryExport } from './features/SummaryView/SummaryExport'
import { useIncomeDetailsUiState, IncomeViewType } from './store'

export const getClasses = (theme: Theme) => ({
  container: css({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '24px',
    alignItems: 'flex-end',
    gap: 20,
    flexFlow: 'row wrap'
  }),
  tertiaryGray1: css({
    color: theme.colors.tertiaryGray1
  }),
  primaryDarkBlue: css({
    color: theme.colors.primaryDarkBlue
  }),
  margin2: css({
    margin: '2px 6px'
  }),
  dateRangeContainer: css({
    display: 'flex',
    flexDirection: 'column'
  }),
  dateRangeDropdown: css({
    width: 168,
    marginTop: '8px'
  }),
  iconContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }),
  iconStyle: css({
    width: 24,
    height: 24,
    cursor: 'pointer'
  }),
  checkboxContainer: css({
    display: 'flex',
    marginBottom: 9,
    '@media (max-width: 1380px)': {
      marginRight: 20
    }
  }),
  checkboxLabel: css({
    marginLeft: '4px',
    fontSize: '12px'
  }),
  searchBoxContainer: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '20px 15px',
    marginLeft: 'auto'
  }),
  incomeToggle: css({
    '.ms-Toggle': {
      margin: 'auto 0',
      '.ms-Toggle-background': {
        backgroundColor: theme.colors.primaryLightBlue1,
        width: 36,
        height: 14,
        border: 0,
        padding: 0,
        marginTop: 3,
        '.ms-Toggle-thumb': {
          background: theme.colors.extraBlue2,
          height: 16,
          width: 16
        }
      }
    }
  })
})

export const ProjectedIncomeDetailsHeader: FC = () => {
  const {
    financialYear,
    includeOptionPremiums,
    incomeMode,
    setFinancialYear,
    setIncludeOptionPremiums,
    setIncomeMode
  } = useIncomeFeatureStore()
  const theme = useTheme()
  const classes = useMemo(() => getClasses(theme), [theme])
  const { invalidateTags } = useHoldingsApiUtil()
  const { searchText, setSearchText, viewByKey, setViewByKey } =
    useIncomeDetailsUiState()
  useMount(() => {
    if (financialYear) {
      return
    }
    if (incomeMode === 'projected') {
      setFinancialYear(2)
    } else {
      setFinancialYear(currentYear)
    }
  })

  const isHistorical = useMemo(() => incomeMode === 'historical', [incomeMode])

  const handleIncomeTypeChangeClick = useCallback(() => {
    const mode = isHistorical ? 'projected' : 'historical'

    setIncomeMode(mode)
    setFinancialYear(!isHistorical ? currentYear : 2)
  }, [isHistorical, setFinancialYear, setIncomeMode])

  const handleDateRangeChange = (
    _event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption<string>
  ) => {
    if (!option?.key) {
      return
    }
    setSearchText('')
    setFinancialYear(+option.key)
  }

  const handleIncludeOptionPremiumsChange = useCallback(
    (event: any) => {
      setIncludeOptionPremiums(event.target.checked)
    },
    [setIncludeOptionPremiums]
  )

  const onViewTypeFilterChange = useCallback(
    (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option) {
        setViewByKey(option.key as IncomeViewType)
        setSearchText('')
      }
    },
    [setSearchText, setViewByKey]
  )

  const handleRefresh = useCallback(
    () =>
      invalidateTags([
        viewByKey === IncomeViewType.summary
          ? 'incomeDetailBySecurity'
          : 'incomeTaxSummary',
        `${incomeMode}IncomeChart`
      ]),
    [incomeMode, invalidateTags, viewByKey]
  )

  return (
    <div css={classes.container}>
      <DetailsNavigator />
      <div
        css={{
          width: 120,
          display: 'flex',
          flexDirection: 'column',
          rowGap: 6
        }}
      >
        <div>View by</div>
        <Dropdown
          options={viewByOptions}
          selectedKey={viewByKey}
          onChange={onViewTypeFilterChange}
        />
      </div>
      <div css={{ display: 'flex', marginBottom: 9 }}>
        <div
          css={[
            classes.margin2,
            isHistorical ? classes.primaryDarkBlue : classes.tertiaryGray1
          ]}
        >
          Historical
        </div>
        <div css={classes.incomeToggle}>
          <Toggle
            checked={!isHistorical}
            onChange={handleIncomeTypeChangeClick}
            role="checkbox"
          />
        </div>
        <div
          css={[
            classes.margin2,
            isHistorical ? classes.primaryDarkBlue : classes.tertiaryGray1
          ]}
        >
          Projected
        </div>
      </div>
      <div css={classes.dateRangeContainer}>
        <div>Date Range</div>
        <div css={classes.dateRangeDropdown}>
          <Dropdown
            options={
              isHistorical ? yearRangeOptions : projectedDateRangeOptions
            }
            selectedKey={financialYear?.toString()}
            onChange={handleDateRangeChange}
          />
        </div>
      </div>
      {isHistorical && (
        <div css={classes.checkboxContainer}>
          <IndeterminateCheckbox
            style={{ margin: '0 5px' }}
            id="optionPremiumsLabelCheckbox"
            className="checkbox"
            checked={includeOptionPremiums}
            onChange={handleIncludeOptionPremiumsChange}
          />
          <div>Include Option Premiums</div>
        </div>
      )}
      <div css={classes.searchBoxContainer}>
        <Searchbox searchText={searchText} onChange={setSearchText} />
        <div css={classes.iconContainer}>
          <Icon
            type="Refresh"
            width={24}
            height={24}
            onClick={handleRefresh}
            color={theme.colors.extraBlue2}
          />
        </div>
        <div css={classes.iconContainer}>
          {viewByKey === IncomeViewType.account ? (
            <AccountExport />
          ) : (
            <SummaryExport />
          )}
        </div>
      </div>
    </div>
  )
}
