import { useTheme } from '@emotion/react'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import { Icon } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { FC, useCallback } from 'react'
import { downloadUrlAsFile } from 'shared/downloads'
import type { IncomeSummaryExportWorker } from './export'
import { useIncomeSummaryContext } from './useIncomeSummaryContext.'

export const SummaryExport: FC = () => {
  const theme = useTheme()
  const { summaryData, dates } = useIncomeSummaryContext()

  const exportToExcelSummary = useCallback(async () => {
    if (!summaryData?.length) {
      return
    }

    const worker = new Worker(new URL('./export.ts', import.meta.url))
    const { exportIncomeSummary } = wrap<IncomeSummaryExportWorker>(worker)
    const result = await exportIncomeSummary(
      summaryData.filter((val) => val.Security !== 'Total'),
      dates
    )
    const filename = `Income Summary ${format(new Date(), 'MM-dd-yyyy')}.xlsx`
    downloadUrlAsFile(result, filename)
  }, [summaryData, dates])

  return (
    <>
      <Icon
        type="Download"
        width={24}
        height={24}
        onClick={exportToExcelSummary}
        color={theme.colors.extraBlue2}
      />
    </>
  )
}
