import { call, put, takeLatest } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../../../store/shared/sagas'
import { addEditProject, fetchProjectList } from '../api/projectService'
import {
  addEditProjectDataActions,
  projectDataActions,
  ProjectDataActionTypes
} from './actions'
import { IProjectRequest } from './types'

function* handleFetchProjectList(action: ProjectDataActionTypes) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const projectRequest = action.payload as IProjectRequest
    const data = yield* call(fetchProjectList, options, projectRequest)
    yield put(projectDataActions.success(data))
  } catch (e: any) {
    yield put(projectDataActions.failure(e))
  }
}
function* handleAddEditProject(
  action: ReturnType<typeof addEditProjectDataActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)

    const data = yield* call(addEditProject, options, action.payload)
    yield put(addEditProjectDataActions.success(data))
    yield put(projectDataActions.request({ pKid: action?.payload?.pKid }))
  } catch (e: any) {
    yield put(addEditProjectDataActions.failure(e))
  }
}

export const sagas = [
  () => takeLatest(projectDataActions.request, handleFetchProjectList),
  () => takeLatest(addEditProjectDataActions.request, handleAddEditProject)
]
