import { createStore } from '../../shared/annuityPPLIContracts'
import { ColumnIdsEnum } from './columnConfig'

const store = createStore({
  columnConfig: Object.values(ColumnIdsEnum),
  selectedColumns: 19,
  root: 'annuities'
})

export const { actions, selectors, reducer, sagas } = store
