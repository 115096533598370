import { lazy } from 'react'
import { IModuleDefinition } from '../types'

const GFOContainer = lazy(() => import('./GFOContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/homeoffice/*',
    private: true,
    component: GFOContainer
  },
  name: 'HomeOffice'
}

export default moduleDefinition
