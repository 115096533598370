import { IIconProps, Icon, IconButton, Stack } from '@fluentui/react'
import {
  GroupingState,
  Header,
  SortDirection,
  SortingState
} from '@tanstack/react-table'
import { AnnuityPPLICombined } from '../../shared/types/annuityPPLICombined'

const SortIndicator: React.FC<{ sortDirection?: SortDirection | false }> = ({
  sortDirection
}) => {
  return (
    <div style={{ width: '14px', minWidth: '14px', cursor: 'pointer' }}>
      {sortDirection ? (
        {
          asc: <Icon iconName="SortUp" />,
          desc: <Icon iconName="SortDown" />
        }[sortDirection]
      ) : (
        <Icon iconName="Sort" />
      )}
    </div>
  )
}

export const TableHead: React.FC<{
  headers: Header<AnnuityPPLICombined, unknown>[]
  grouping: GroupingState
  isAllRowsExpanded: boolean
  toggleAllRowsExpandedHandler: (event: unknown) => void
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>
}> = ({
  headers,
  grouping,
  isAllRowsExpanded,
  toggleAllRowsExpandedHandler,
  setSorting
}) => {
  const headerFirstInGrouping = headers.find(
    (header) => header.id === grouping[0]
  )
  const firstHeaderSortState = headerFirstInGrouping?.column.getIsSorted()

  const onFirstHeaderSort = () => {
    const newSorting = grouping.map((group) => {
      const header = headers.find((x) => x.id === group)
      const sortState = header?.column.getIsSorted()
      const asc = sortState === false || sortState === 'desc'
      return {
        id: group,
        desc: !asc
      }
    })
    setSorting(newSorting)
  }

  const iconProps: IIconProps = {
    iconName: isAllRowsExpanded ? 'CaretSolidDown' : 'CaretSolidRight',
    styles: {
      root: {
        opacity: '.75',
        height: 'auto',
        lineHeight: '1em',
        margin: '0'
      }
    }
  }

  return (
    <thead>
      <tr className="row">
        {grouping.length > 0 && (
          <>
            <th key="placeholder">
              <IconButton
                iconProps={iconProps}
                title="Expand / Collapse"
                ariaLabel="Expand / Collapse"
                onClick={toggleAllRowsExpandedHandler}
                styles={{
                  root: {
                    display: 'block',
                    width: '16px',
                    height: '16px',
                    borderRadius: 0,
                    padding: 0
                  },
                  rootHovered: { backgroundColor: 'transparent' },
                  rootPressed: { backgroundColor: 'transparent' }
                }}
              />
            </th>
            <th
              key="groupingLabel"
              onClick={onFirstHeaderSort}
              style={{
                cursor: 'pointer'
              }}
            >
              <Stack horizontal styles={{ root: { display: 'inline-flex' } }}>
                {grouping.join(' / ')}
                {firstHeaderSortState && (
                  <SortIndicator sortDirection={firstHeaderSortState} />
                )}
              </Stack>
            </th>
          </>
        )}
        {headers.map((header) => {
          const {
            column: {
              columnDef: {
                meta: {
                  className: metaClassName,
                  aggregatedClassName,
                  aggregatedHeader
                } = {}
              }
            }
          } = header

          const className = metaClassName
            ? metaClassName
            : aggregatedClassName || undefined

          return (
            !grouping.includes(header.id) && (
              <th
                key={header.id}
                className={className}
                onClick={header.column.getToggleSortingHandler()}
              >
                <Stack horizontal styles={{ root: { display: 'inline-flex' } }}>
                  {grouping.length && aggregatedHeader
                    ? aggregatedHeader
                    : header.id}
                  {header.column.getIsSorted() && (
                    <SortIndicator
                      sortDirection={header.column.getIsSorted()}
                    />
                  )}
                </Stack>
              </th>
            )
          )
        })}
      </tr>
    </thead>
  )
}
