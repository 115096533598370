import { Theme, css, useTheme } from '@emotion/react'
import { Icon, Label } from '@fluentui/react'
import { IndeterminateProgressIndicator } from 'modules/Advisory/modules/Rdot360/components/shared'
import { SnackBar } from 'modules/Advisory/modules/Rdot360/components/shared/Snackbar'
import {
  useGetPositionByCategoryQueryForSelectedAccounts,
  useRdot360Context
} from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { advisoryModuleStyles } from 'modules/Advisory/shared/styles'
import { FC, useMemo, useState } from 'react'
import { investmentsTableColumnNames } from '../InvestmentsTable/shared'
import { useInvestmentsTableStore } from '../InvestmentsTable/store'
import { InvestmentsChart } from './InvestmentsChart'
import InvestmentsChartLegendDetails from './InvestmentsChartLegendDetails'

const getAssetAllocationLabel = (grouping: string) => {
  let label = 'Asset Class'
  if (grouping === investmentsTableColumnNames.productType) {
    label = 'Product Type'
  } else if (grouping === investmentsTableColumnNames.sectorClass) {
    label = 'Sector Class'
  }
  return `Asset Allocation (${label})`
}

const getClasses = (theme: Theme) => ({
  progressIndicatorContainer: css({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  }),
  chartAndLegendContainer: css({
    display: 'flex',
    backgroundColor: theme.colors.primaryWhite,
    maxHeight: '350px',
    border: `solid 1px ${theme.colors.extraGray1}`,
    borderTop: 'none'
  }),
  chartContainer: css({
    height: '320px',
    marginTop: '15px',
    marginLeft: '50px'
  }),
  legendContainer: css({
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    alignContent: 'flex-start',
    columnGap: '100px',
    rowGap: '25px',
    flexWrap: 'wrap',
    margin: '7px',
    padding: '40px 10px 5px 0px',
    minWidth: 0
  }),
  iconStyle: css({
    display: 'inline-block',
    border: '1px solid #b8d5ed',
    borderRadius: 9,
    height: 18,
    width: 18,
    padding: '4px',
    fontSize: 8,
    textAlign: 'center',
    backgroundColor: 'white',
    marginRight: 12
  }),
  groupItem: css({
    backgroundColor: '#eff6fb',
    color: '#13272b',
    fontWeight: 700,
    fontSize: 13,
    padding: '10px 5px',
    border: '1px solid #6fa3c5',
    cursor: 'pointer',
    alignItems: 'center',
    boxShadow: '0px 2px 4px rgb(0 0 0 / 10%)',
    paddingLeft: 20,
    input: {
      width: 15,
      height: 15
    },
    "input[type='checkbox' i]": {
      backgroundColor: 'white'
    }
  })
})

export const InvestmentsChartContainer: FC = () => {
  const { asOfDate } = useRdot360Context()
  const { category, grouping } = useInvestmentsTableStore()
  const {
    data,
    isFetching,
    isError: L1error,
    error,
    refetch,
    isUninitialized
  } = useGetPositionByCategoryQueryForSelectedAccounts(category)
  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  const theme = useTheme()
  const classes = useMemo(() => getClasses(theme), [theme])
  const toggleAccount = () => {
    setIsExpanded(!isExpanded)
  }
  const chartErrorMessage =
    (error as Error)?.message || 'An unknown Error Occurred'

  const isChartError = useMemo(() => L1error || error, [L1error, error])

  const sortedData = useMemo(() => {
    return data?.map((x) => x).sort((a: any, b: any) => b?.mktval - a?.mktval)
  }, [data])

  return grouping &&
    (grouping[0] === investmentsTableColumnNames.assetClass ||
      grouping[0] === investmentsTableColumnNames.productType ||
      grouping[0] === investmentsTableColumnNames.sectorClass) ? (
    <div>
      {isFetching && <IndeterminateProgressIndicator />}
      <div css={classes.groupItem}>
        <Label
          onClick={(event) => {
            toggleAccount()
            event.preventDefault()
            return false
          }}
        >
          {isExpanded ? (
            <span css={[classes.iconStyle]}>
              <Icon iconName="ChevronUpSmall" />
            </span>
          ) : (
            <span css={[classes.iconStyle]}>
              <Icon iconName="ChevronDownSmall" />
            </span>
          )}
          <span>{getAssetAllocationLabel(grouping[0])}</span>
        </Label>
      </div>
      {!!isChartError && (
        <div css={{ paddingBottom: '5px' }}>
          <SnackBar
            message={chartErrorMessage}
            type="Failure"
            actionButtonLabel="click to retry"
            onActionButtonClicked={refetch}
          />
        </div>
      )}
      {!isUninitialized && !isFetching && isExpanded && (
        <div css={classes.chartAndLegendContainer}>
          {!data?.length ? (
            <div
              css={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                margin: 'auto 0'
              }}
            >
              No data available
            </div>
          ) : (
            <>
              <div css={classes.chartContainer}>
                <InvestmentsChart data={data} selectedType={category} />
              </div>
              <div
                css={[
                  classes.legendContainer,
                  advisoryModuleStyles.fancyScroll
                ]}
              >
                {sortedData?.map((item, index) => (
                  <InvestmentsChartLegendDetails
                    data={item}
                    key={index}
                    selectedType={category}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      )}
      {asOfDate && (
        <div css={{ fontSize: 10 }}>
          Please note the displayed asset mix for a past close of business
          report date might be based on asset classification that may have been
          revised. If you have further questions or need additional information,
          please connect with your Rockefeller Capital Management Advisor.
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}
