import {
  createAction,
  EmptyActionCreator,
  PayloadActionCreator
} from 'typesafe-actions'

export const createActionWithPrefix =
  <TType extends string>(prefix: string, type: TType) =>
  <TPayload>(): PayloadActionCreator<TType, TPayload> =>
    createAction<TType, TPayload, string>(
      `${prefix}/${type}` as TType,
      (payload: TPayload) => payload,
      () => prefix
    )<TPayload, string>()

export const createEmptyActionWithPrefix =
  <TType extends string>(prefix: string, type: TType) =>
  (): EmptyActionCreator<TType> =>
    createAction<TType, string>(
      `${prefix}/${type}` as TType,
      () => prefix
    )<string>()
