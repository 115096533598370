import { IPosition } from 'api/position.types'
import { fromPairs } from 'lodash'
import { parseDateISOStringInLocalTimezone } from 'shared'
import {
  IExportConfiguration,
  IExportDefinition
} from '../core/contracts/IExportDefinition'
import {
  PositionColumnDefinitions,
  PositionColumnIds
} from './ColumnDefinitions'

const drawownNaColumns = [
  PositionColumnIds.OriginalOrderAmount, // Original Subscription
  PositionColumnIds.RemainingOriginalAmount, // Total Investment Amount
  PositionColumnIds.netInvestmentGainLoss, // Cum Inv Return
  PositionColumnIds.ai_totalReturnPercentage // Return %
]
const nonDrawdownNaColumns = [
  PositionColumnIds.CapitalCall, // Capital Called
  PositionColumnIds.capitalCommitment, // Committed Capital
  PositionColumnIds.Unfunded_Value // Remaining Capital
]

export const positionExportDefinitions: Record<
  string,
  IExportConfiguration<IPosition>
> = {
  [PositionColumnIds.symbolCusipDesc]: {
    columnId: PositionColumnIds.symbolCusipDesc,
    getExportColumns: () =>
      [
        PositionColumnIds.description,
        PositionColumnIds.symbol,
        PositionColumnIds.cusip
      ].map((x) => PositionColumnDefinitions[x])
  },
  [PositionColumnIds.tvpi]: {
    columnId: PositionColumnIds.tvpi,
    getExportColumns: (_, column) =>
      [
        {
          columnId: column.id,
          columnName: column.name,
          getValue: (position) =>
            position.DrawdownNonDrawdown === 'YES' && position.CapitalCall
              ? ((position.TotalValue || 0) / position.CapitalCall).toFixed(1)
              : ''
        }
      ] as IExportDefinition<IPosition>[]
  },
  [PositionColumnIds.vintageYear]: {
    columnId: PositionColumnIds.vintageYear,
    getExportColumns: (_, column) =>
      [
        {
          columnId: column.id,
          columnName: column.name,
          getValue: (position) =>
            position.VintageYear ||
            (position.OriginalOrderDate &&
              parseDateISOStringInLocalTimezone(
                position.OriginalOrderDate
              )?.getFullYear())
        }
      ] as IExportDefinition<IPosition>[]
  },
  ...fromPairs(
    drawownNaColumns.map((x) => [
      x,
      {
        columnId: x,
        getExportColumns: (_, column) =>
          [
            {
              columnId: column.id,
              columnName: column.name,
              getValue: (position) =>
                position.DrawdownNonDrawdown === 'YES' ? '--' : undefined
            }
          ] as IExportDefinition<IPosition>[]
      }
    ])
  ),
  ...fromPairs(
    nonDrawdownNaColumns.map((x) => [
      x,
      {
        columnId: x,
        getExportColumns: (_, column) =>
          [
            {
              columnId: column.id,
              columnName: column.name,
              getValue: (position) =>
                position.DrawdownNonDrawdown === 'NO' ? '--' : undefined
            }
          ] as IExportDefinition<IPosition>[]
      }
    ])
  )
}
