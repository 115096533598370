import { useMemo } from 'react'
import { TileLoadingOverlay } from '../../../components/TileLoadingOverlay'
import { IncomeChart } from '../../../features/Income'
import { useGetProjectedIncomeChartQueryForSelectedAccounts } from '../../../store/rdot360Context'
import { IncomeDisclaimer } from './IncomeDisclaimer'
import { IncomeWidgetHeader } from './IncomeWidgetHeader'

export const IncomeWidgetChartProjected: React.FC = () => {
  const {
    data: incomeWidgetChartResponse,
    isFetching,
    isLoading,
    isError
  } = useGetProjectedIncomeChartQueryForSelectedAccounts(2)

  const data = useMemo(
    () =>
      isFetching || isError || !incomeWidgetChartResponse
        ? undefined
        : incomeWidgetChartResponse,
    [isFetching, isError, incomeWidgetChartResponse]
  )

  return (
    <>
      <IncomeWidgetHeader data={data} />
      <IncomeChart data={data?.incomeSubTotal} includeOptionPremiums={true} />
      <IncomeDisclaimer />
      {(isLoading || isFetching) && <TileLoadingOverlay />}
    </>
  )
}
