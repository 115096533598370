import {
  DirectionalHint,
  Stack,
  TooltipDelay,
  TooltipHost
} from '@fluentui/react'
import React from 'react'
import { useSelector } from 'react-redux'
import { IProductAllocationItem } from '../../../api/common.types'
import { chartColors } from '../../../shared/services/theme'
import { getAdvisorContextProductAllocations } from '../../../store/context/advisor'

interface IProductAllocationBarContainerProps {
  productAllocations: IProductAllocationItem[]
  selectedProductAllocations?: string[]
}

export const ProductAllocationBarContainer: React.FC<
  IProductAllocationBarContainerProps
> = ({ productAllocations, selectedProductAllocations }) => {
  const bookAllocations = useSelector(getAdvisorContextProductAllocations)
  const allocationMap: { [key: string]: number } = (
    bookAllocations || []
  ).reduce((a, x, i) => {
    if (!x.productType) {
      return a
    }
    a[x.productType] = i
    return a
  }, {} as any)

  const productAllocation = (productAllocations || []).filter(
    (x) => x.value && x.value > 0
  )
  const total = productAllocation.reduce((a, x) => a + (x.value || 0), 0)
  const allRows = productAllocation
    .map((x) => {
      return {
        name: x.productType || '',
        ratio: ((x.value || 0) / total) * 100
      }
    })
    .sort((a, b) => {
      return (allocationMap[a.name] || 0) - (allocationMap[b.name] || 0)
    })

  const otherRows = allRows.filter((x) => x.ratio <= 1)
  const totalOther = otherRows.reduce((a, x) => a + x.ratio, 0)
  const otherRow = {
    name: 'OTHER',
    ratio: totalOther
  }

  const rows = allRows.filter((x) => x.ratio > 1).concat([otherRow])
  const selectedMap = (selectedProductAllocations || []).reduce(
    (a, allocationName) => {
      a[allocationName] = allocationName
      return a
    },
    {} as any
  )
  const isAnyAllocationSelected = (selectedProductAllocations || []).length > 0
  const defaultColor = '#666'

  return (
    <Stack
      horizontal={true}
      tokens={{ childrenGap: 1 }}
      styles={{ root: { width: '100%', height: '100%', maxHeight: '20px' } }}
    >
      {rows.map((x, i) => {
        const backgroundColor =
          (!isAnyAllocationSelected ||
          (isAnyAllocationSelected && selectedMap[x.name])
            ? chartColors[allocationMap[x.name]]
            : defaultColor) || defaultColor
        return (
          <Stack
            key={x.name + i}
            style={{
              width: `${x.ratio}%`,
              backgroundColor
            }}
          >
            <TooltipHost
              styles={{ root: { height: '100%', width: '100%' } }}
              tooltipProps={{
                onRenderContent: () => {
                  return (
                    <div>
                      {x.name} - {x.ratio.toFixed(0)}%
                    </div>
                  )
                }
              }}
              delay={TooltipDelay.zero}
              directionalHint={DirectionalHint.bottomCenter}
            >
              <div style={{ height: '100%', width: '100%' }} />
            </TooltipHost>
          </Stack>
        )
      })}
    </Stack>
  )
}
