import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IHousehold } from 'api/household.types'
import { AppState } from 'store'

export interface IEditHouseholdTagsState {
  isPanelOpen: boolean
  household?: IHousehold
}

const initialState: IEditHouseholdTagsState = {
  isPanelOpen: false
}

export const {
  actions: editHouseholdTagsActions,
  reducer: editHouseholdTagsReducer
} = createSlice({
  name: '@features/@households/@features/@editHouseholdTags',
  initialState,
  reducers: {
    openPanel: (state, action: PayloadAction<IHousehold>) => {
      state.isPanelOpen = true
      state.household = action.payload
    },
    closePanel: (state) => {
      state.isPanelOpen = false
      state.household = undefined
    }
  }
})

const rootSelector = (state: AppState) =>
  state.features.households.features.editHouseholdTags

export const selectHousehold = createSelector(
  rootSelector,
  ({ household }) => household
)

export const selectIsPanelOpen = createSelector(
  rootSelector,
  ({ isPanelOpen }) => isPanelOpen
)
