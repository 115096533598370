import { IDropdownOption } from '@fluentui/react'

export const JournalTransferTypeList = {
  Partial: 'Partial',
  Full: 'Full'
}

export const YesNoOptions: IDropdownOption[] = [
  {
    key: 'Y',
    text: 'Yes'
  },
  {
    key: 'N',
    text: 'No'
  }
]

export const recipientOptions: IDropdownOption[] = [
  {
    key: 'First Party',
    text: 'First Party'
  },
  {
    key: 'Third Party',
    text: 'Third Party'
  }
]
