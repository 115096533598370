import { ActionType, createAction } from 'typesafe-actions'
import {
  IRelatedParty,
  relatedPartiesRequest
} from '../../ScheduleReport/store/types'
import { IUploadReportRequest, IUploadReportResponse } from './types'

export const FETCH_RELATED_PARTIES_INFO =
  '@modules/@reports/FETCH_RELATED_PARTIES_INFO'
export const FETCH_RELATED_PARTIES_SUCCESS =
  '@modules/@reports/FETCH_RELATED_PARTIES_SUCCESS'
export const FETCH_RELATED_PARTIES_FAILURE =
  '@modules/@reports/FETCH_RELATED_PARTIES_FAILURE'

export const UPLOAD_REPORT = '@modules/@reports/UPLOAD_REPORT'
export const UPLOAD_REPORT_SUCCESS = '@modules/@reports/UPLOAD_REPORT_SUCCESS'
export const UPLOAD_REPORT_FAILURE = '@modules/@reports/UPLOAD_REPORT_FAILURE'

export const relatedPartiesDetailsActionTypes = {
  request: createAction(FETCH_RELATED_PARTIES_INFO)<relatedPartiesRequest>(),
  success: createAction(FETCH_RELATED_PARTIES_SUCCESS)<IRelatedParty[]>(),
  failure: createAction(FETCH_RELATED_PARTIES_FAILURE)<Error>()
}

export const uploadReportActions = {
  request: createAction(UPLOAD_REPORT)<IUploadReportRequest>(),
  success: createAction(UPLOAD_REPORT_SUCCESS)<IUploadReportResponse>(),
  failure: createAction(UPLOAD_REPORT_FAILURE)<Error>()
}

export type RelatedPartiesDataActionTypes = ActionType<
  typeof relatedPartiesDetailsActionTypes
>
export type UploadReportActionTypes = ActionType<typeof uploadReportActions>

export type ShareReportActionType =
  | RelatedPartiesDataActionTypes
  | UploadReportActionTypes
