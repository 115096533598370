import { useDomainStore } from 'features/Domain/store/domain'
import type { IListsFacetFilter } from 'features/Lists/core/contracts/IListsFilter'
import type { IOdataListUiActions } from 'features/OdataList/common/IOdataListUiActions'
import type { IOdataListUiSelectors } from 'features/OdataList/common/IOdataListUiSelectors'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useAdvisorIdFilter = <T, U>(
  uiActions: IOdataListUiActions,
  uiSelectors: IOdataListUiSelectors<T, U>
) => {
  const { selectedAccountRepIds, isFullDomainSelected, domain } =
    useDomainStore()

  const filters = useSelector(uiSelectors.getFilters)

  const dispatch = useDispatch()

  useEffect(() => {
    if (selectedAccountRepIds !== null) {
      const advisorIdFilterId = 'Rep Id'
      const hasValue = !isFullDomainSelected && !!selectedAccountRepIds?.length

      dispatch(
        uiActions.updateFilters({
          [advisorIdFilterId]: {
            ...filters?.[advisorIdFilterId],
            values: hasValue ? selectedAccountRepIds : undefined,
            facets: domain?.map(({ rcm_repid }) => ({ value: rcm_repid })),
            hasValue,
            hidden: true
          } as IListsFacetFilter
        })
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountRepIds, isFullDomainSelected, domain])
}
