import { useCallback, useMemo } from 'react'
import { IRdot360Account, getAccountKey } from '../../store/rdot360Context'
import { useRdot360BalancesContext } from '../../store/rdot360Context/useRdot360BalancesContext'
import { AccountSelectorAccount } from '../AccountSelector/AccountSelectorAccount'
import { accountSelectorStyles } from '../AccountSelector/styles'

export const CreateEditAccountRow: React.FC<{
  account: IRdot360Account
  isSelected?: boolean
  onChange: (accountNumber: string) => void
}> = ({ account, isSelected, onChange }) => {
  const onCheckboxClicked = useCallback(() => {
    if (!account?.id) {
      return
    }
    onChange(account?.id)
  }, [account?.id, onChange])
  const { balanceLookupByKey } = useRdot360BalancesContext()
  const accountBalances = useMemo(
    () => balanceLookupByKey?.[getAccountKey(account)],
    [account, balanceLookupByKey]
  )
  const { netWorth, netWorthChange, todayschange } = accountBalances || {}
  return (
    <div css={accountSelectorStyles.accountItem}>
      <AccountSelectorAccount
        checked={isSelected}
        onChange={onCheckboxClicked}
        accountNumber={account?.id}
        balance={netWorth}
        change={netWorthChange}
        todaysChange={todayschange}
      />
    </div>
  )
}
