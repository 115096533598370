import { combineReducers } from 'redux'
import { createStoreInjectorHook } from '../../../store/shared'
import {
  householdAccountsDetailsReducer,
  householdAccountsDetailsSagas
} from './householdAccountsDetails'
import {
  householdAccountsFetchSagas,
  householdAccountsFetchReducer
} from './householdAccountsFetch'
import {
  householdDetailsFetchReducer,
  householdDetailsFetchSagas
} from './householdDetailsFetch'
import {
  householdSearchFetchReducer,
  householdSearchFetchSagas
} from './householdSearchFetch'
import { marginRateEmailReducer } from './marginRateEmailContents'
import { marginRatePanelReducer, marginRatePanelSagas } from './marginRatePanel'
import {
  marginRateRequestPostAccountsUserSagas,
  marginRateRequestPostReducer
} from './marginRateRequestsPost'
import { MarginRateAdjustmentFeatureSliceKey } from './shared'

export type MarginRateAdjustmentFeatureSlice = {
  [MarginRateAdjustmentFeatureSliceKey]?: ReturnType<
    typeof marginRateAdjustmentReducer
  >
}

export const marginRateAdjustmentReducer = combineReducers({
  marginRatePanel: marginRatePanelReducer,
  householdAum: householdDetailsFetchReducer,
  marginRateEmailContent: marginRateEmailReducer,
  householdSearchFetch: householdSearchFetchReducer,
  householdAccountsDetails: householdAccountsDetailsReducer,
  householdAccountsFetch: householdAccountsFetchReducer,
  householdDetailsFetch: householdDetailsFetchReducer,
  marginRateRequestPost: marginRateRequestPostReducer
})

export const marginRateSagas = [
  ...householdSearchFetchSagas,
  ...marginRatePanelSagas,
  ...householdAccountsFetchSagas,
  ...householdDetailsFetchSagas,
  ...householdAccountsDetailsSagas,
  ...marginRateRequestPostAccountsUserSagas
]

export const useMarginRateAdjustmentFeatureStore = createStoreInjectorHook(
  MarginRateAdjustmentFeatureSliceKey,
  marginRateAdjustmentReducer,
  marginRateSagas
)
