export * from './AltsAdvisorRevenueDetail'

import type { IAltsAdvisorRevenueDetail } from 'api/datahub'
import type { IODataListState } from 'features/OdataList/store/odataListWithFacetsStore'
import { Reducer, combineReducers } from 'redux'
import type { IAsyncReducerState } from 'store/shared'
import {
  altsAdvRevDetExcelExportReducer,
  altsAdvRevDetExcelExportSagas
} from './export/excelExportActionsAndSagas'
import {
  reducer as AltsAdvisorRevenuesReducer,
  sagas as AltsAdvisorRevenuesSagas
} from './store'

interface IAltsAdvisorRevenueDetailState {
  features: {
    AltsAdvisorRevenue: IODataListState<IAltsAdvisorRevenueDetail>
    AltsAdvisorRevenueExcelExport: IAsyncReducerState<any, unknown>
  }
}

const AltsAdvisorRevenueReducers = combineReducers({
  AltsAdvisorRevenue: AltsAdvisorRevenuesReducer,
  AltsAdvisorRevenueExcelExport: altsAdvRevDetExcelExportReducer
})

const AltsAdvisorRevenueReducer: Reducer<IAltsAdvisorRevenueDetailState> =
  combineReducers({
    features: AltsAdvisorRevenueReducers
  })

const AltsAdvisorRevenueSagas = [
  ...AltsAdvisorRevenuesSagas,
  ...altsAdvRevDetExcelExportSagas
]

export const AltsAdvisorRevenueModulesReducer = combineReducers({
  dashboard: AltsAdvisorRevenueReducer
})

export const AltsAdvisorRevenueModulesSagas = [...AltsAdvisorRevenueSagas]
