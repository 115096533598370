import axios from 'axios'
import { flow, isNil, omitBy } from 'lodash'
import { IApiOptions } from '../shared/contracts/IApiOptions'
import { IOdataResult } from '../shared/contracts/IOdataResult'
import { isNotNullOrEmpty } from '../shared/guards'
import { escapeFilterString } from './odata'
import { IOdataRequest } from './odata.types'
import { escapeAndEncodeQuery } from './search'

export interface IHouseholdAccount {
  accountNumber?: string
  accountTitle?: string
  registeredRep?: string
}

export interface IDatahubHousehold {
  id?: string
  name?: string
  accounts?: IHouseholdAccount[]
  createdOn?: string
  updatedOn?: string
}

const apiVersion = '1.0'
const defaultHeaders = {
  'api-version': apiVersion
}

export const searchHouseholds = (
  options: IApiOptions,
  request: IOdataRequest
) => {
  const { apiRoot, cancelToken, accessToken } = options
  const { search, filters, orderby, top, skip } = request
  const [firstOrderBy] = orderby || []

  const filter = [
    search &&
      `(${[
        `startswith(name, '${search}')`,
        `accounts/any(a: startswith(a/accountNumber, '${search}'))`
      ]
        .filter(isNotNullOrEmpty)
        .join(' or ')})`,
    ...(filters || [])
  ]
    .filter(isNotNullOrEmpty)
    .join(' and ')

  const qs = [
    filter && `$filter=${filter}`,
    firstOrderBy &&
      `$orderby=${firstOrderBy.dataPath} ${firstOrderBy.direction}`,
    `$top=${top ? top : 50}`,
    skip != null ? `$skip=${skip}` : '',
    '$count=true',
    '$expand=accounts'
  ]
    .filter(isNotNullOrEmpty)
    .join('&')

  return axios
    .get<IOdataResult<IDatahubHousehold>>(
      `${apiRoot}/datahub/households?${qs}`,
      {
        headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}` },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
}

export const updateHousehold = (
  options: IApiOptions,
  household: IDatahubHousehold
) => {
  if (!household.id) {
    throw new Error('Request must contain a household id')
  }
  const { apiRoot, cancelToken, accessToken } = options
  return axios
    .patch<IDatahubHousehold>(
      `${apiRoot}/datahub/households/${household.id}`,
      {
        ...household,
        id: undefined
      },
      {
        headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}` },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
}

export const getHousehold = (options: IApiOptions, id: string) => {
  const { apiRoot, cancelToken, accessToken } = options
  return axios
    .get<IDatahubHousehold>(`${apiRoot}/datahub/households/${id}`, {
      headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}` },
      cancelToken: cancelToken
    })
    .then((x) => x.data)
}

export interface IHouseholdChangeRequestAccount {
  requestId?: string
  accountNumber?: string
  accountId?: string
}

export type HouseholdChangeRequestStatus =
  | 'REQUESTED'
  | 'APPROVED'
  | 'REJECTED'
  | 'COMPLETED'
export interface IHouseholdChangeRequest {
  id?: number
  accounts?: IHouseholdChangeRequestAccount[]
  targetHouseholdId?: string
  targetHouseholdName?: string
  teamName?: string
  businessUnitId?: string
  createdByName?: string
  createdBy?: string
  createdOn?: string
  status?: HouseholdChangeRequestStatus
  modifiedByName?: string
  modifiedBy?: string
  modifiedOn?: string
}

export const constructHouseholdChangeRequestsQuery = (
  request?: IOdataRequest
) => {
  const { search, filters, orderby, top, skip } = request || {}
  const [firstOrderBy] = orderby || []
  const encodedSearch = flow(
    escapeFilterString,
    escapeAndEncodeQuery
  )(search || '')

  const filter = [
    search &&
      `(${[
        `contains(targetHouseholdName, '${encodedSearch}')`,
        `accounts/any(a: startswith(a/accountNumber, '${encodedSearch}'))`
      ]
        .filter(isNotNullOrEmpty)
        .join(' or ')})`,
    ...(filters || [])
  ]
    .filter(isNotNullOrEmpty)
    .join(' and ')

  return [
    filter && `$filter=${filter}`,
    firstOrderBy &&
      `$orderby=${firstOrderBy.dataPath} ${firstOrderBy.direction}`,
    `$top=${top ? top : 50}`,
    skip != null ? `$skip=${skip}` : '',
    '$count=true',
    '$expand=accounts'
  ]
    .filter(isNotNullOrEmpty)
    .join('&')
}

export const getHouseholdChangeRequests = (
  options: IApiOptions,
  request?: IOdataRequest
) => {
  const { apiRoot, cancelToken, accessToken } = options
  const qs = constructHouseholdChangeRequestsQuery(request)

  return axios
    .get<IOdataResult<IHouseholdChangeRequest>>(
      `${apiRoot}/datahub/householdchangerequests?${qs}`,
      {
        headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}` },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
}

export const getHouseholdChangeRequestById = (
  options: IApiOptions,
  id: number
) => {
  const { apiRoot, cancelToken, accessToken } = options

  return axios
    .get<IHouseholdChangeRequest>(
      `${apiRoot}/datahub/householdchangerequests/${id}?$expand=accounts`,
      {
        headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}` },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
}

export const createHouseholdChangeRequest = (
  options: IApiOptions,
  request: IHouseholdChangeRequest
) => {
  const { apiRoot, cancelToken, accessToken } = options
  return axios
    .post<IHouseholdChangeRequest>(
      `${apiRoot}/datahub/householdchangerequests`,
      { ...request, id: undefined },
      {
        headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}` },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
}

export type HouseholdFeeType = 'Client' | 'Production' | 'BDA'
export interface IHouseholdFee {
  householdId?: string
  chargeType?: HouseholdFeeType
  chargeAccount?: string
  householdStatus?: 1 | 0
  hasEmployeeAccount?: 1 | 0
  householdCreatedDate?: string
  lastEvaluatedDate?: string
  lastEvaluatedStatus?: string
  createdDate?: string
  updatedBy?: string
  updatedOn?: string
  primaryChargeToRep?: string
  primaryChargeToDept?: string
  annualHHFee?: number
  hhNextBillingDate?: string
}

export const getHouseholdFee = (options: IApiOptions, householdId: string) => {
  const { apiRoot, cancelToken, accessToken } = options
  return axios
    .get<IHouseholdFee>(
      `${apiRoot}/datahub/households/${householdId}/householdFee`,
      {
        headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}` },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
}

export interface IDatahubDepartment {
  id?: string
  number?: string
  name?: string
}

export const getHouseholdRelatedDepartments = (
  options: IApiOptions,
  householdId: string
) => {
  const { apiRoot, cancelToken, accessToken } = options
  return axios
    .get<IOdataResult<IDatahubDepartment>>(
      `${apiRoot}/datahub/households/${householdId}/relatedDepartments()`,
      {
        headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}` },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
}

export const updateHouseholdFee = (
  options: IApiOptions,
  householdFee: IHouseholdFee
) => {
  const { apiRoot, cancelToken, accessToken } = options
  const {
    chargeAccount,
    chargeType,
    primaryChargeToDept,
    primaryChargeToRep,
    annualHHFee,
    hhNextBillingDate
  } = householdFee
  return axios
    .patch<IHouseholdFee>(
      `${apiRoot}/datahub/householdFees/${householdFee.householdId}`,
      omitBy(
        {
          chargeAccount,
          chargeType,
          primaryChargeToDept,
          primaryChargeToRep,
          annualHHFee,
          hhNextBillingDate
        },
        isNil
      ),
      {
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${accessToken}`,
          Prefer: 'return=representation'
        },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
}
