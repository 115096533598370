import { lazy } from 'react'
import { IModuleDefinition } from '../types'

const ComplianceContainer = lazy(() => import('./ComplianceContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/compliance/*',
    private: true,
    component: ComplianceContainer
  },
  name: 'Compliance'
}

export default moduleDefinition
