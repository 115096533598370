import type { IAltsAdvisorRevenueDetail } from 'api/datahub'
import { TextCell } from 'features/Lists/core/components/TextCell'
import type { IOdataDataListCellRenderProps } from 'features/OdataList/containers/OdataList'
import { FeeTypeOptions, formatDate } from '../../../store/dashboard'
import { USDCell } from '../../AIRevenueSummaryTable/column/utilities/USDCell'
import type { AltsAdvisorRevenueDetailColumnName } from './columnNameTypes'

export const CellComponents: Record<
  AltsAdvisorRevenueDetailColumnName,
  React.FC<IOdataDataListCellRenderProps<IAltsAdvisorRevenueDetail>>
> = {
  'Aging Month': ({ item }) => (
    <TextCell>
      {formatDate(item.agingMonth?.toString().substring(0, 10))}
    </TextCell>
  ),

  'Revenue Month': ({ item }) => (
    <TextCell>
      {formatDate(item.revenueMonth?.toString().substring(0, 10))}
    </TextCell>
  ),

  'Account Number': ({ item }) => <TextCell>{item.accountNumber}</TextCell>,

  'Fund Name': ({ item }) => <TextCell>{item.fundName}</TextCell>,

  CUSIP: ({ item }) => <TextCell>{item.cusip}</TextCell>,

  'Rep Id': ({ item }) => <TextCell>{item.advisorRepNo}</TextCell>,

  'Advisor/Team Name': ({ item }) => <TextCell>{item.repName}</TextCell>,

  'Team Rep Id': ({ item }) => <TextCell>{item.custodianRepNo}</TextCell>,

  'Team Advisor Name': ({ item }) => <TextCell>{item.teamName}</TextCell>,

  'Allocated Amount': ({ item }) => <USDCell value={item.amount} />,

  'Allocation Type': ({ item }) => <TextCell>{item.revenueType}</TextCell>,

  'Fee Type': ({ item }) => (
    <TextCell>
      {FeeTypeOptions.find((x) => x.key === item.feeTypeCode)?.text ?? ''}
    </TextCell>
  )
}
