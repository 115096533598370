import {
  ActionButton,
  MessageBar,
  MessageBarType,
  ProgressIndicator
} from '@fluentui/react'
import {
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  GroupingState,
  SortingState,
  useReactTable,
  VisibilityState
} from '@tanstack/react-table'
import { constants } from 'modules/Advisory/modules/Rdot360/shared/theme'
import { FC, useMemo, useState } from 'react'
import { useDebounce } from 'shared/hooks/useDebounce'
import { rdot360TableStyles } from '../../../../shared/tableStyles'
import { usePerformanceReportsUiState } from '../reportsUiState'
import { usePerformanceReportsData } from '../usePerformanceReportsData'
import { colNames, getColumnDefs } from './columnDefinitions'
import { globalFilterFn } from './globalFilterFn'
import { TableBody } from './TableBody'
import { TableHead } from './TableHead'

const groupName = colNames.portfolioName

export const ReportsDetailTable: FC = () => {
  const { searchText, isEncodedReportLoading } = usePerformanceReportsUiState()
  const debouncedSearchText = useDebounce(searchText, 500)
  const { data, isFetching, isUninitialized } = usePerformanceReportsData()
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: colNames.reportDate,
      desc: true
    }
  ])
  const [expanded, setExpanded] = useState<ExpandedState>(true)
  const [grouping, setGrouping] = useState<GroupingState>([groupName])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    [groupName]: false
  })
  const columns = getColumnDefs(debouncedSearchText)

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      sorting,
      grouping,
      expanded,
      globalFilter: debouncedSearchText
    },
    autoResetExpanded: false,
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    onGroupingChange: setGrouping,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn
  })

  const headers = table.getFlatHeaders()
  const { rows } = table.getRowModel()
  const { getToggleAllRowsExpandedHandler } = table
  const isAllRowsExpanded = table.getIsAllRowsExpanded()

  const showNoData = useMemo(
    () => !isUninitialized && !data?.length && !isFetching,
    [data?.length, isFetching, isUninitialized]
  )
  const showLoadingProgress = isFetching || isEncodedReportLoading

  return (
    <div>
      <ActionButton
        label="Expand All Rows"
        onClick={getToggleAllRowsExpandedHandler()}
        styles={{
          root: {
            textDecoration: 'underline'
          }
        }}
        disabled={isFetching || showNoData}
      >
        {!isAllRowsExpanded ? 'Expand' : 'Collapse'} All Rows
      </ActionButton>
      <div
        css={{
          paddingTop: showLoadingProgress ? 0 : 2,
          position: 'sticky',
          top: constants.headerOffsetPx,
          zIndex: 3
        }}
      >
        {showLoadingProgress && (
          <ProgressIndicator styles={{ itemProgress: { padding: 0 } }} />
        )}
      </div>
      <table css={rdot360TableStyles.table}>
        <TableHead headers={headers} setSorting={setSorting} />
        <TableBody
          rows={rows}
          groupName={groupName}
          search={debouncedSearchText}
        />
      </table>
      {showNoData && (
        <div css={{ padding: 20 }}>
          <MessageBar messageBarType={MessageBarType.warning}>
            No Data Available
          </MessageBar>
        </div>
      )}
    </div>
  )
}
