import { combineReducers } from 'redux'
import { accountRepTreeReducer } from './accountRepTree'
import { advisorRepTreeReducer } from './advisorRepTree'
import {
  repSelectorPanelReducer,
  repSelectorPanelSagas
} from './repSelectorPanel'
import { usersAndTeamsReducer } from './usersAndTeams'

export const repSelectorFeatureReducer = combineReducers({
  repSelectorPanel: repSelectorPanelReducer,
  accountRepTree: accountRepTreeReducer,
  advisorRepTree: advisorRepTreeReducer,
  usersAndTeams: usersAndTeamsReducer
})

export const repSelectorFeatureSagas = [...repSelectorPanelSagas]
