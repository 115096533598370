import axios from 'axios'
import { IApiOptions } from '../../../../../../../shared/contracts/IApiOptions'
import { ITrustee } from '../store/types'

export const fetchTrusteesListSvc = (
  activityPartiesIds: string[],
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/trusts/parties`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<ITrustee[]>(url, activityPartiesIds, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
