import { ActionType, createAction } from 'typesafe-actions'
import { IClientSummary } from './types'

export const FETCH_CLIENT_SUMMARY =
  '@modules/@payments/@trust/FETCH_CLIENT_SUMMARY'
export const FETCH_CLIENT_SUMMARY_SUCCESS =
  '@modules/@payments/@trust/FETCH_CLIENT_SUMMARY_SUCCESS'
export const FETCH_CLIENT_SUMMARY_FAILURE =
  '@modules/@payments/@trust/FETCH_CLIENT_SUMMARY_FAILURE'

export const clientSummaryDataActions = {
  request: createAction(FETCH_CLIENT_SUMMARY)(),
  success: createAction(FETCH_CLIENT_SUMMARY_SUCCESS)<IClientSummary[]>(),
  failure: createAction(FETCH_CLIENT_SUMMARY_FAILURE)<any>()
}

export type ClientSummaryDataActions = ActionType<
  typeof clientSummaryDataActions
>

export type ClientSummaryActionTypes = ClientSummaryDataActions
