import { Theme, css, useTheme } from '@emotion/react'
import {
  ITheme,
  Icon,
  Label,
  MessageBar,
  MessageBarType,
  Panel,
  Stack
} from '@fluentui/react'
import { IClient } from 'api/client.types'
import { IAnnuityPPLIContract } from 'api/datahub'
import { AxiosError } from 'axios'
import { inputClasses } from 'modules/Advisory/modules/Rdot360/components/shared/Inputs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { AutoComplete } from 'shared/components/AutoComplete'
import { useClasses } from 'shared/hooks/useClasses'
import { useDebounce } from 'shared/hooks/useDebounce'
import {
  useGetClientSearchQuery,
  useUpdateInsuranceAccountLinkMutation
} from '../../../shared/annuityPPLIContracts'
import { AccountLinkContentFooter } from './AccountLinkContentFooter'

const getClasses = (theme: Theme) => ({
  selectedLegalName: css({
    position: 'absolute',
    top: 4,
    left: 6,
    display: 'flex',
    columnGap: '6px',
    backgroundColor: theme.colors.primaryLightBlue1,
    padding: '3px',
    borderRadius: '4px',
    alignItems: 'center',
    justifyContent: 'center'
  })
})

const AutoCompleteItem: React.FC<{ item?: IClient }> = ({ item }) => {
  if (!item) {
    return null
  }
  return <div css={{ padding: '5px 10px' }}>{item?.LegalEntityName}</div>
}

export const AccountLinkContent: React.FC<{
  contractProps: Partial<IAnnuityPPLIContract>
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>
  closePanel: () => void
}> = ({ contractProps, closePanel, setIsSuccess }) => {
  const getThemedClasses = (theme: ITheme) => ({
    clientDetailsLabel: {
      fontWeight: '300',
      color: theme.palette.neutralPrimary
    }
  })
  const classes = useClasses(getThemedClasses)

  const theme = useTheme()
  const emotionClasses = useMemo(() => getClasses(theme), [theme])

  const { contractNumber, contractType, clientName, insuredAnnuitants } =
    contractProps

  const [updateInsuranceAccountLink, updateInsuranceAccountLinkResult] =
    useUpdateInsuranceAccountLinkMutation()

  const { isLoading, isSuccess, error } = updateInsuranceAccountLinkResult

  const apiResponseStatus = useMemo(() => {
    const { response } = (error as AxiosError<{ error?: Error }>) ?? {}
    return response?.status
  }, [error])

  const isError403 = useMemo(
    () => apiResponseStatus === 403,
    [apiResponseStatus]
  )

  const [isAccountLinkingError, setIsAccountLinkingError] = useState(false)

  const [clientToLink, setClientToLink] = useState<Partial<IClient>>({})

  const [searchText, setSearchText] = useState('')
  const debouncedSearch = useDebounce(searchText, 300)

  const { data: clients, isFetching } = useGetClientSearchQuery(
    debouncedSearch,
    {
      skip: !debouncedSearch || debouncedSearch === ''
    }
  )

  const handleClientSearchTextChanged = useCallback(
    (text?: string) => setSearchText(text || ''),
    []
  )

  const onItemSelected = useCallback((item?: IClient) => {
    const {
      LegalEntityName,
      LegalEntityID,
      householdName,
      legalEntityType,
      contactdetails
    } = item ?? {}
    !!LegalEntityID &&
      setClientToLink({
        LegalEntityName,
        LegalEntityID,
        householdName,
        legalEntityType,
        contactdetails
      })
  }, [])

  const {
    LegalEntityName,
    LegalEntityID,
    legalEntityType,
    householdName,
    contactdetails
  } = useMemo(() => clientToLink ?? {}, [clientToLink])

  const onRenderFooterContent = useCallback(() => {
    const linkAccount = () => {
      updateInsuranceAccountLink({
        id: contractNumber || '',
        request: {
          LegalEntityID
        }
      })
    }
    const isLinkAccountButtonDisabled = !LegalEntityName || isLoading

    return (
      <AccountLinkContentFooter
        linkAccount={linkAccount}
        closePanel={closePanel}
        isLoading={isLoading}
        isDisabled={isLinkAccountButtonDisabled}
      />
    )
  }, [
    LegalEntityID,
    LegalEntityName,
    closePanel,
    contractNumber,
    isLoading,
    updateInsuranceAccountLink
  ])

  useEffect(() => {
    setIsAccountLinkingError(!!error)
  }, [error])

  useEffect(() => {
    setIsSuccess(isSuccess)
  }, [isSuccess, setIsSuccess])

  return (
    <Panel
      headerText={'Account Linking'}
      onDismiss={closePanel}
      isOpen={true}
      isLightDismiss={true}
      closeButtonAriaLabel="Close"
      onRenderFooterContent={onRenderFooterContent}
    >
      <Stack styles={{ root: { margin: '10px 0' } }}>
        <span>Contract Type</span>
        <strong>{contractType || '--'}</strong>
      </Stack>

      <Stack styles={{ root: { margin: '10px 0' } }}>
        <span>Contract Number</span>
        <strong>{contractNumber || '--'}</strong>
      </Stack>

      <Stack styles={{ root: { margin: '10px 0' } }}>
        <span>Policy Owner</span>
        <strong>{clientName || '--'}</strong>
      </Stack>

      <Stack styles={{ root: { margin: '10px 0' } }}>
        <span>Insured / Annuitants</span>
        <strong>{insuredAnnuitants || '--'}</strong>
      </Stack>

      {LegalEntityName ? (
        <>
          <Label>Link account to</Label>
          <div
            css={[inputClasses.input, { padding: '4px', position: 'relative' }]}
          >
            <div css={emotionClasses.selectedLegalName}>
              <div>{LegalEntityName}</div>
              <Icon
                iconName="Cancel"
                onClick={() => {
                  isAccountLinkingError && setIsAccountLinkingError(false)
                  setClientToLink({})
                }}
                styles={{ root: { cursor: 'pointer' } }}
              />
            </div>
          </div>
          <div style={{ marginTop: '10px' }}>
            <Stack
              horizontal
              tokens={{ childrenGap: 5 }}
              styles={{ root: { marginBottom: '10px' } }}
              wrap
            >
              <div className={classes.clientDetailsLabel}>Entity:</div>
              <div>{legalEntityType}</div>
            </Stack>
            <Stack
              horizontal
              tokens={{ childrenGap: 5 }}
              styles={{ root: { marginBottom: '10px' } }}
              wrap
            >
              <div className={classes.clientDetailsLabel}>Household:</div>
              <div>{householdName}</div>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 5 }} wrap>
              <div className={classes.clientDetailsLabel}>Email:</div>
              <div>{contactdetails?.emailid}</div>
            </Stack>
          </div>
          <MessageBar
            messageBarType={MessageBarType.warning}
            styles={{ root: { marginTop: '20px' } }}
          >
            <em>
              This linking request will generate a manual account number that
              will be used to associate this policy with the client. Please
              ensure you have selected the correct client before submitting this
              request.
            </em>
          </MessageBar>
          {isAccountLinkingError && (
            <MessageBar
              messageBarType={MessageBarType.error}
              styles={{ root: { marginTop: '10px' } }}
            >
              {isError403 ? (
                <span>You are not authorized to link accounts.</span>
              ) : (
                <span>
                  An error occurred. Account linking failed. Click{' '}
                  <b>Link Account</b> to try again.
                </span>
              )}
            </MessageBar>
          )}
        </>
      ) : (
        <>
          <Label>Search and select a client</Label>
          <AutoComplete<IClient>
            value={searchText}
            onSearchTextChanged={handleClientSearchTextChanged}
            items={clients?.value || []}
            itemComponent={AutoCompleteItem}
            showLoadingIndicator={isFetching}
            onItemSelected={(item) => {
              onItemSelected(item)
            }}
            css={{ borderRadius: '4px' }}
          />
        </>
      )}
    </Panel>
  )
}
