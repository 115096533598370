import { IModuleDefinition } from 'modules/types'
import { combineReducers } from 'redux'
import { IncomeContainer } from './IncomeContainer'
import { incomeDetailsUiStateReducer } from './store'

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/income/*',
    private: true,
    component: IncomeContainer
  },
  name: 'Income'
}

export const rdot360IncomeReducer = combineReducers({
  incomeDetailsUiState: incomeDetailsUiStateReducer
})

export default moduleDefinition
