import {
  CommandBar,
  Dropdown,
  IDropdownOption,
  SearchBox,
  Stack
} from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import {
  GroupingState,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { IAnnuityPPLIContract } from 'api/datahub'
import { uniq } from 'lodash'

import { useMemo, useState } from 'react'

import { ColumnEditSummaryPanel } from '../../features'
import { getGlobalFilterFn } from '../../features/TanStackReactTable/getGlobalFilterFn'
import { TableWrapper } from '../../features/TanStackReactTable/TableWrapper'
import {
  useSearchText,
  useClickToExport,
  useCommandBar
} from '../../shared/hooks'
import { useGetSummaryDetails } from '../../shared/hooks/useGetSummaryDetails'
import { columnNames } from './columnNames'
import { getColumnDefs } from './getColumnDefs'

const searchableColumnIds = [
  columnNames.cusipNumber,
  columnNames.securityDescription,
  columnNames.cleanCarrierName,
  columnNames.householdName
]

export const Summary: React.FC = () => {
  const { searchText, onSearchTextChanged, tokenizedGlobalFilter } =
    useSearchText()

  const [isPanelOpen, { setTrue: openPanel, setFalse: closePanel }] =
    useBoolean(false)

  const [
    isExportLoading,
    { setTrue: exportLoading, setFalse: exportDoneLoading }
  ] = useBoolean(false)

  const { data, isSummaryFetching } = useGetSummaryDetails()

  const tableData = useMemo(() => {
    const empty: IAnnuityPPLIContract[] = []
    return data || empty
  }, [data])

  const columnDefs = useMemo(getColumnDefs, [])

  const [sorting, setSorting] = useState<SortingState>([])

  const [grouping, setGrouping] = useState<GroupingState>([
    columnNames.contractType
  ])

  const [selectedKey, setSelectedKey] = useState<string | number | undefined>(
    columnNames.contractType
  )

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    [columnNames.valuationDate]: false,
    [columnNames.assetClassL1]: false,
    [columnNames.assetClassL3]: false,
    [columnNames.assetClassL4]: false,
    [columnNames.assetType]: false,
    [columnNames.assetSubType]: false,
    [columnNames.productType]: false,
    [columnNames.contractNumber]: false,
    [columnNames.regionName]: false
  })

  const table = useReactTable({
    data: tableData,
    columns: columnDefs,
    state: {
      columnVisibility,
      globalFilter: tokenizedGlobalFilter,
      grouping,
      sorting
    },
    onColumnVisibilityChange: setColumnVisibility,
    onGroupingChange: setGrouping,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: getGlobalFilterFn(searchableColumnIds),
    autoResetExpanded: false
  })

  const { getToggleAllColumnsVisibilityHandler, getIsAllColumnsVisible } = table

  const headers = table.getFlatHeaders()

  const { flatRows } = table.getRowModel()

  const allLeafColumns = table.getAllLeafColumns()

  const toggleAllColumnsVisibilityHandler =
    getToggleAllColumnsVisibilityHandler()

  const isAllColumnsVisible = getIsAllColumnsVisible()

  const groupOptions = useMemo(() => {
    const groupingIds = [
      {
        key: columnNames.cusipNumber,
        text: columnNames.cusipNumber
      },
      {
        key: columnNames.contractType,
        text: columnNames.contractType
      },
      {
        key: columnNames.assetClassL2,
        text: columnNames.assetClassL2
      },
      {
        key: `${columnNames.cleanCarrierName},${columnNames.cusipNumber}`,
        text: `${columnNames.cleanCarrierName} & ${columnNames.cusipNumber}`
      },
      {
        key: columnNames.productType,
        text: columnNames.productType
      }
    ]
    const options = groupingIds.map((groupId) => {
      const { key, text } = groupId
      const invisible = key
        .split(',')
        .find((item) => columnVisibility[item] === false)
      return {
        key,
        text,
        disabled: !!invisible
      }
    })
    options.push({ key: 'ungroup', text: 'All Contracts', disabled: false })

    return options
  }, [columnVisibility])

  const onGroupingChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption
  ) => {
    const optionKey = option?.key as string
    const newGrouping = optionKey === 'ungroup' ? [] : optionKey.split(',')
    setGrouping(uniq(newGrouping))
    setSelectedKey(optionKey)
  }

  const { clickToExport } = useClickToExport({
    headers,
    excelColumnConfigType: 'CONTRACTS',
    tabLabel: 'Summary',
    exportLoading,
    exportDoneLoading,
    flatRows
  })

  const { commandBarItems } = useCommandBar({
    isExportLoading,
    clickToExport,
    openPanel
  })

  return (
    <>
      <h2 css={{ marginTop: '0' }}>Summary</h2>
      <Stack
        horizontal
        tokens={{ childrenGap: 10 }}
        styles={{ root: { marginBottom: '10px' } }}
        verticalAlign="end"
      >
        <SearchBox
          placeholder="Search"
          onChange={onSearchTextChanged}
          autoComplete="off"
          value={searchText || ''}
          styles={{
            root: {
              width: 250,
              backgroundColor: searchText ? '#fbfb7366' : undefined
            }
          }}
        />

        <Dropdown
          label="Group By"
          placeholder="Group By"
          options={groupOptions}
          onChange={onGroupingChange}
          defaultSelectedKey={selectedKey}
          styles={{
            root: { width: 250 }
          }}
        />

        <CommandBar
          styles={{
            root: { margin: 0, padding: 0, height: '32px' }
          }}
          items={commandBarItems}
        />
      </Stack>

      <TableWrapper
        grouping={grouping}
        isSummaryFetching={isSummaryFetching}
        table={table}
        setSorting={setSorting}
      />

      {isPanelOpen && (
        <ColumnEditSummaryPanel
          isPanelOpen={isPanelOpen}
          closePanel={closePanel}
          columns={allLeafColumns}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          toggleAllColumnsVisibilityHandler={toggleAllColumnsVisibilityHandler}
          isAllColumnsVisible={isAllColumnsVisible}
        />
      )}
    </>
  )
}
