import { combineReducers, Reducer } from 'redux'
import {
  revenueSummaryTableFeatureReducer,
  revenueSummaryTableFeatureSagas
} from './AIRevenueSummaryTable'
import {
  AltsAdvisorRevenueModulesReducer,
  AltsAdvisorRevenueModulesSagas
} from './AltsAdvisorRevenueDetail'

export interface IRevenueDashboardFeaturesState {
  revenueSummaryTable: ReturnType<typeof revenueSummaryTableFeatureReducer>
  altsAdvisorRevenueDetail: ReturnType<typeof AltsAdvisorRevenueModulesReducer>
}

export const aiRevenueDashboardFeaturesReducer: Reducer<IRevenueDashboardFeaturesState> =
  combineReducers({
    revenueSummaryTable: revenueSummaryTableFeatureReducer,
    altsAdvisorRevenueDetail: AltsAdvisorRevenueModulesReducer
  })

export const aiRevenueDashboardFeaturesSagas = [
  ...revenueSummaryTableFeatureSagas,
  ...AltsAdvisorRevenueModulesSagas
]
