import { wrap } from 'comlink'
import type { XlsxWorker } from '../workers/xlsx'
import { saveBlobAsFile } from './downloads'

export interface IXlsxWorkbookOptions {
  sheets: IXlsxWorksheetOptions[]
}

export interface IXlsxWorksheetOptions {
  data: unknown[][]
  name: string
}

export const exportDataToExcel = async (
  options: IXlsxWorkbookOptions,
  filename: string
) => {
  const worker = new Worker(new URL('../workers/xlsx.ts', import.meta.url))
  const { createWorkbook } = wrap<XlsxWorker>(worker)

  const result = await createWorkbook(options)

  const blob = new Blob([result], { type: 'application/octet-stream' })

  saveBlobAsFile(blob, filename)
}
