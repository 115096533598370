import { ITransfer, IUpcomingCapitalCall } from 'api/datahub'
import { IPosition } from 'api/position.types'
import { IODataListState } from 'features/OdataList/store/odataListWithFacetsStore'
import { combineReducers, Reducer } from 'redux'
import { IAsyncReducerState } from 'store/shared'
import { IListsState } from '../../../../../../../features/Lists/core/store/reducers'
import {
  reducer as positionListReducer,
  sagas as positionListSagas
} from './AIPositionList'
import {
  transfersExportReducer,
  transfersExportSagas
} from './AiTransfers/exportExcel/sagas'
import {
  reducer as transfersReducer,
  sagas as transfersSagas
} from './AiTransfers/transfers'
import {
  upcomingCapitalCallsExportReducer,
  upcomingCapitalCallsExportSagas
} from './AiUpcomingCapitalCalls/exportExcel/sagas'
import {
  reducer as upcomingCapitalCallsReducer,
  sagas as upcomingCapitalCallsSagas
} from './AiUpcomingCapitalCalls/store/upcomingCapitalCalls'

export interface IAiDashboardFeaturesState {
  aiPositionList: IListsState<IPosition>
  aiUpcomingCapitalCalls: IODataListState<IUpcomingCapitalCall>
  aiUpcomingCapitalCallsExport: IAsyncReducerState<unknown, unknown>
  aiTransfers: IODataListState<ITransfer>
  aiTransfersExport: IAsyncReducerState<unknown, unknown>
}

export const aiDashboardFeaturesReducer: Reducer<IAiDashboardFeaturesState> =
  combineReducers({
    aiPositionList: positionListReducer,
    aiUpcomingCapitalCalls: upcomingCapitalCallsReducer,
    aiUpcomingCapitalCallsExport: upcomingCapitalCallsExportReducer,
    aiTransfers: transfersReducer,
    aiTransfersExport: transfersExportReducer
  })

export const aiDashboardFeaturesSagas = [
  ...positionListSagas,
  ...upcomingCapitalCallsSagas,
  ...upcomingCapitalCallsExportSagas,
  ...transfersSagas,
  ...transfersExportSagas
]
