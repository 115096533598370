import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import {
  IAccountLinkingRequest,
  IBusinessUnit,
  IDynamicsApiResult,
  IDynamicsCdmAdvisorRep
} from 'api/dynamics'
import { isNotNullOrEmpty, isNotNullOrUndefined } from 'shared/guards'
import { dynamicsApi } from 'store/api/dynamics'
import { AxiosBaseArgs } from 'store/api/shared'

export const activateProfileDynamicsApi = dynamicsApi.injectEndpoints({
  endpoints: (builder) => ({
    activateProfile_CreateRequest: builder.mutation<
      IAccountLinkingRequest,
      IAccountLinkingRequest
    >({
      query: (request) => ({
        url: `/rcm_accountlinkingrequests`,
        method: 'POST',
        data: request,
        headers: {
          Prefer: 'return=representation'
        }
      })
    }),
    getApproversAndEmails: builder.query<
      {
        businessUnitEmails?: string[]
        businessUnits?: IBusinessUnit[]
      },
      string[]
    >({
      queryFn: async (repCodes, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<
          IDynamicsApiResult<IDynamicsCdmAdvisorRep>,
          Error
        >
        const baseApiArgs: Partial<AxiosBaseArgs> = {
          method: 'GET',
          url: 'rcm_advisorreps',
          params: {
            $select: 'rcm_name,rcm_repid',
            $filter: `Microsoft.Dynamics.CRM.In(PropertyName='rcm_repid',PropertyValues=[${repCodes
              .map((x) => `'${x}'`)
              .join(',')}])`,
            $expand:
              'rcm_OwningTeam($select=name;$expand=businessunitid($select=name,emailaddress;$expand=parentbusinessunitid($select=name,emailaddress;$expand=parentbusinessunitid($select=name,emailaddress))))'
          }
        }
        const result = (await baseQuery({ ...baseApiArgs })) as Response
        const error = result.error
        if (error) {
          return { error }
        }
        const repsWithHierarchy = result?.data?.value
        const [firstRep] = repsWithHierarchy || []
        if (!firstRep || !firstRep.rcm_OwningTeam?.businessunitid) {
          return { error: Error('Reps not configured in cdm.rcm_advisorreps') }
        }
        const firstRepBusinessUnit = firstRep.rcm_OwningTeam?.businessunitid

        const businessUnits = [
          firstRepBusinessUnit.parentbusinessunitid,
          firstRepBusinessUnit.parentbusinessunitid?.parentbusinessunitid
        ]
          .filter(isNotNullOrUndefined)
          .filter((x) => x?.emailaddress)
        const businessUnitEmails = [
          firstRepBusinessUnit.parentbusinessunitid?.emailaddress,
          firstRepBusinessUnit.parentbusinessunitid?.parentbusinessunitid
            ?.emailaddress
        ].filter(isNotNullOrEmpty)

        if (!businessUnitEmails.length) {
          return { error: Error('Unable to determine business unit emails') }
        }
        return {
          data: { businessUnitEmails, businessUnits }
        }
      }
    }),
    checkForExistingActivateProfileRequest: builder.query<boolean, string>({
      query: (partyId) => ({
        url: '/rcm_accountlinkingrequests',
        params: {
          $filter: [
            `rcm_partyid eq '${partyId}'`,
            `rcm_status ne 798960001`,
            `rcm_status ne 798960002`,
            `rcm_status ne 798960005`
          ].join(' and '),
          $top: 1
        }
      }),
      transformResponse: (x: IDynamicsApiResult<IAccountLinkingRequest>) =>
        !!x?.value?.length
    })
  })
})

export const {
  useActivateProfile_CreateRequestMutation,
  useLazyGetApproversAndEmailsQuery,
  useCheckForExistingActivateProfileRequestQuery
} = activateProfileDynamicsApi
