import { CellContext } from '@tanstack/react-table'
import { PercentCellWithColor } from '../../../components/PercentCellWithColor'
import { USDCellWithColor } from '../../../components/USDCellWithColor'

export const ChangeCellWithPercent: React.FC<CellContext<any, unknown>> = ({
  getValue,
  row
}) => {
  const priceChange = getValue<number>() || 0
  const priceChangePercent = row.original.pricing?.pricechgpct

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <USDCellWithColor value={priceChange} fractionDigit={2} />
      {!!priceChangePercent && (
        <PercentCellWithColor value={priceChangePercent} />
      )}
    </div>
  )
}
