import axios from 'axios'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import { IVendorItem } from '../store/type'

export const fetchVendorList = (orgid: string, options: IApiOptions) => {
  const url = `${options.apiRoot}/payment/api/payment/getClientVendorList?orgId=${orgid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<{ response_status: number; response_data: IVendorItem[] }>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
