import { lazy } from 'react'
import { IModuleDefinition } from '../../../../../types'

const ClientOnboardingContainer = lazy(
  () => import('./BoardMemberAccountContainer')
)

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/boardmemberaccounts',
    private: true,
    component: ClientOnboardingContainer
  },
  name: 'Board Member Accounts'
}

export default moduleDefinition

export * from './store'
