import { IPosition } from 'api/position.types'
import {
  PositionColumnDefinitions,
  PositionColumnIds
} from 'features/Lists/positions/ColumnDefinitions'
import { positionExportDefinitions } from 'features/Lists/positions/ExportDefinitions'
import { PositionCell } from 'features/Lists/positions/PositionCell'
import { cloneDeep } from 'lodash'
import { partial } from 'lodash/fp'
import { createListsComponents } from '../../../../../../../../features/Lists/core/containers/service'
import { createListsStore } from '../../../../../../../../features/Lists/core/store'
import {
  createDefaultColumnState,
  createFilter,
  mapColumnsToFilterDefinitions
} from '../../../../../../../../features/Lists/shared/lists'
import { AppState } from '../../../../../../../../store'

const AIDashboardPositionListColumnDefinitions = cloneDeep(
  PositionColumnDefinitions
)
const notFilterable = [
  PositionColumnIds.householdId,
  PositionColumnIds.householdName,
  PositionColumnIds.assetType,
  PositionColumnIds.securitytype
]

notFilterable.forEach(
  (id) => (AIDashboardPositionListColumnDefinitions[id].filterable = false)
)

export const constructInitialAiPositionListColumnState = partial(
  createDefaultColumnState,
  [AIDashboardPositionListColumnDefinitions]
)

export const positionListFilterDefinitions = mapColumnsToFilterDefinitions(
  AIDashboardPositionListColumnDefinitions
)

export const aiPositionListStore = createListsStore<IPosition>(
  'position',
  '@features/@lists/@aipositions',
  {
    columnDefinitions: AIDashboardPositionListColumnDefinitions,
    filterDefinitions: positionListFilterDefinitions,
    isReady: false
  },
  (state: AppState) =>
    state.modules.advisory.modules.ai.modules.dashboard.features.aiPositionList,
  positionExportDefinitions
)

export const { reducer, sagas, actions, selectors } = aiPositionListStore

export const createAiPositionListFilter = partial(createFilter, [
  AIDashboardPositionListColumnDefinitions
])

export const AiPositionListComponents = createListsComponents(
  actions,
  selectors,
  PositionCell
)
