import { ActionType, createAction } from 'typesafe-actions'
import { IClient } from '../../../../../../../api/client.types'
import { ITrustDashboardInfo } from '../../DashBoard/store/types'
import { ITrustInfo } from './types'
export const NAVIGATE_TO_TRUSTINFO = '@modules/@trusts/NAVIGATE_TO_TRUSTINFO'
export const NAVIGATE_TO_NEWTRUST = '@modules/@trusts/NAVIGATE_TO_NEWTRUST'
export const FETCH_TRUST_INFO = '@modules/@trusts/FETCH_TRUST_INFO'
export const FETCH_TRUST_INFO_SUCCESS =
  '@modules/@trusts/FETCH_TRUST_INFO_SUCCESS'
export const FETCH_TRUST_INFO_FAILURE =
  '@modules/@trusts/FETCH_TRUST_INFO_FAILURE'
export const SET_ACTIVE_TRUST = '@modules/@trusts/SET_ACTIVE_TRUST'

export const FETCH_TRUST_FINANCE_INFO = `@modules/@trusts/@trustdetails/FETCH_TRUST_FINANCE_INFO`
export const FETCH_TRUST_FINANCE_INFO_SUCCESS = `@modules/@trusts/@trustdetails/FETCH_TRUST_FINANCE_INFO_SUCCESS`
export const FETCH_TRUST_FINANCE_INFO_FAILURE = `@modules/@trusts/@trustdetails/FETCH_TRUST_FINANCE_INFO_FAILURE`
export const CLEAR_TRUST_FINANCE_INFO = `@modules/@trusts/@trustdetails/CLEAR_TRUST_FINANCE_INFO`

export const trustNavigationActions = {
  navigateToTrustInfo: createAction(NAVIGATE_TO_TRUSTINFO)(),
  navigateToNewTrust: createAction(NAVIGATE_TO_NEWTRUST)()
}
export const trustInfoDataActions = {
  request: createAction(FETCH_TRUST_INFO)<string>(),
  success: createAction(FETCH_TRUST_INFO_SUCCESS)<ITrustInfo>(),
  failure: createAction(FETCH_TRUST_INFO_FAILURE)<any>()
}
export const setActiveTrustAction = {
  setActiveTrust: createAction(SET_ACTIVE_TRUST)<ITrustDashboardInfo>()
}

export const trustFinancialDataActions = {
  request: createAction(FETCH_TRUST_FINANCE_INFO)<string>(),
  success: createAction(FETCH_TRUST_FINANCE_INFO_SUCCESS)<IClient>(),
  failure: createAction(FETCH_TRUST_FINANCE_INFO_FAILURE)<any>(),
  clear: createAction(CLEAR_TRUST_FINANCE_INFO)()
}

export type TrustNavigationActions = ActionType<typeof trustNavigationActions>
export type TrustInfoDataActions = ActionType<typeof trustInfoDataActions>
export type SetActiveTrustActionType = ActionType<typeof setActiveTrustAction>
export type TrustFinancialDataActionType = ActionType<
  typeof trustFinancialDataActions
>

export type TrustInfoActionType =
  | TrustNavigationActions
  | TrustInfoDataActions
  | SetActiveTrustActionType
  | TrustFinancialDataActionType
