import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SortingState, Updater } from '@tanstack/react-table'
import { flow, isFunction } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { balancesDetailTableColumnNames } from '../../components/Balances/shared'
import { rdot360ContextActions } from '../../store/rdot360Context'

export interface IActivityDetailUiState {
  searchText: string
  isExpanded: boolean
  sorting: SortingState
}

const { actions, reducer } = createSlice({
  name: 'balanceSummaryDetailsUiState',
  initialState: {
    searchText: '',
    isExpanded: true,
    sorting: [
      {
        id: balancesDetailTableColumnNames.clientDashboardCategoryCode,
        desc: false
      },
      { id: balancesDetailTableColumnNames.netBalance, desc: true }
    ]
  } as IActivityDetailUiState,
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    },
    setIsExpanded: (state, action: PayloadAction<boolean>) => {
      state.isExpanded = action.payload
    },
    setSorting: (state, action: PayloadAction<SortingState>) => {
      state.sorting = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rdot360ContextActions.setSelectedHouseholdId, (state) => {
      state.searchText = ''
    })
  }
})

export { reducer as balanceSummaryDetailsUiStateReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.modules.balance
    .balanceSummaryDetailsUiState

const getSearchText = flow(rootSelector, (x) => x.searchText)
const getIsExpanded = flow(rootSelector, (x) => x.isExpanded)
const getSorting = flow(rootSelector, (x) => x.sorting)

export const useBalanceSummaryDetailsUiState = () => {
  const dispatch = useDispatch()
  const searchText = useSelector(getSearchText)
  const isExpanded = useSelector(getIsExpanded)
  const sorting = useSelector(getSorting)

  const setSearchText = useCallback(
    (search?: string) => {
      dispatch(actions.setSearchText(search || ''))
    },
    [dispatch]
  )

  const setIsExpanded = useCallback(
    (expanded: boolean) => {
      dispatch(actions.setIsExpanded(expanded))
    },
    [dispatch]
  )

  const setSorting = useCallback(
    (updater: Updater<SortingState>) => {
      const value = isFunction(updater) ? updater(sorting ?? []) : updater
      dispatch(actions.setSorting(value))
    },
    [dispatch, sorting]
  )

  return {
    searchText,
    setSearchText,
    isExpanded,
    setIsExpanded,
    sorting,
    setSorting
  }
}
