import {
  DefaultButton,
  FontSizes,
  Label,
  MessageBar,
  MessageBarType,
  Overlay,
  Panel,
  PanelType,
  PrimaryButton,
  Separator,
  Stack,
  Text,
  TextField
} from '@fluentui/react'
import { skipToken } from '@reduxjs/toolkit/query'
import {
  AccountLinkingRequestStatusEnum,
  IAccountLinkingRequest
} from 'api/dynamics'
import { IGraphApiSendMailRequest } from 'api/graph'
import { generateAccountLinkingEmail } from 'features/AccountLinking/AccountLinkingEmail'
import { useClientInvitePanel } from 'features/ClientInvite/store/clientInvitePanel'
import { usePushNotification } from 'features/Notifications'
import { uniq } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { isNotNullOrUndefined } from 'shared/guards'
import { useSendMailMutation } from 'store/api/graph_v1'
import { getIsEnvironmentProduction } from 'store/system'
import { getRdotUsername } from 'store/user/selectors'
import { ActivateProfileAccountsList } from './ActivateProfileAccountsList'
import {
  useCheckForProfileQuery,
  useGetAccountsForPartyQuery,
  useGetClientDetailsQuery
} from './store/activateProfileDatahubApi'
import {
  useActivateProfile_CreateRequestMutation,
  useCheckForExistingActivateProfileRequestQuery,
  useLazyGetApproversAndEmailsQuery
} from './store/activateProfileDynamicsApi'
import { useActivateProfilePanel } from './store/activateProfilePanel'

export interface IActivateProfileForm {
  name?: string
  email?: string
  phoneNumbers?: string[]
  repCode?: string
  role?: string
}

export const ActivateProfilePanel: React.FC = () => {
  const { isOpen, close, partyId, partyName } = useActivateProfilePanel()
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [submitError, setSubmitError] = useState<Error>()
  const setError = useCallback((error: Error) => {
    setSubmitError(error)
    setIsSubmitLoading(false)
  }, [])
  const {
    currentData: profile,
    isFetching: isCheckFetching,
    isError: isCheckError
  } = useCheckForProfileQuery(partyId || skipToken)
  const {
    currentData: requestExists,
    isFetching: isRequestExistsLoading,
    isError: isRequestExistsError
  } = useCheckForExistingActivateProfileRequestQuery(partyId || skipToken)
  const {
    currentData: accounts,
    isFetching: isAccountsFetching,
    isError: isAccountsError
  } = useGetAccountsForPartyQuery(partyId || skipToken)
  const { currentData: client, isFetching: isClientFetching } =
    useGetClientDetailsQuery(partyId || skipToken)
  const isLoading = useMemo(
    () =>
      isAccountsFetching ||
      isCheckFetching ||
      isClientFetching ||
      isSubmitLoading ||
      isRequestExistsLoading,
    [
      isAccountsFetching,
      isCheckFetching,
      isClientFetching,
      isRequestExistsLoading,
      isSubmitLoading
    ]
  )
  const [clientRole, setClientRole] = useState<string>()
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([])
  const disableSubmit = useMemo(
    () =>
      isCheckFetching ||
      isCheckError ||
      isAccountsError ||
      !selectedAccounts.length ||
      !partyId ||
      !partyName ||
      !clientRole ||
      requestExists ||
      !!profile ||
      isRequestExistsError,
    [
      clientRole,
      isAccountsError,
      isCheckError,
      isCheckFetching,
      isRequestExistsError,
      partyId,
      partyName,
      profile,
      requestExists,
      selectedAccounts.length
    ]
  )
  const phones = useMemo(() => client?.contactdetails?.phones || [], [client])
  const [submit] = useActivateProfile_CreateRequestMutation()
  const [getApproversAndEmails] = useLazyGetApproversAndEmailsQuery()
  const [sendEmail] = useSendMailMutation()
  const { pushNotification } = usePushNotification()
  const isProd = useSelector(getIsEnvironmentProduction)
  const currentUsername = useSelector(getRdotUsername)
  const onSubmit = useCallback(async () => {
    setIsSubmitLoading(true)
    const request: IAccountLinkingRequest = {
      rcm_requesttype: 'new',
      rcm_clientmethod: 'Existing Account Participant',
      rcm_newclient: partyName,
      rcm_newclientrole: clientRole,
      rcm_status: AccountLinkingRequestStatusEnum.REQUESTED,
      rcm_accountstoadd: selectedAccounts?.join(','),
      rcm_partyid: partyId,
      rcm_newclientemailid: client?.contactdetails?.emailid,
      rcm_mfaphone: phones?.[0]?.telNumber
    }
    if (!currentUsername) {
      setError(new Error('Unable to discover the logged in user'))
      return
    }
    const accountsToLink = accounts?.filter((x) =>
      selectedAccounts.includes(x.accountKey || '')
    )
    const repCodes = uniq(
      accountsToLink?.map((x) => x.registeredRep)?.filter(isNotNullOrUndefined)
    )
    if (!repCodes?.length) {
      setError(new Error('Rep ownership for accounts could not be determined'))
      return
    }
    const { data, error } = await getApproversAndEmails(repCodes)
    if (error) {
      setError(error as Error)
      return
    }
    try {
      const response = await submit(request)
      const { error } = response as { error?: Error }
      if (error) {
        throw error
      }
      const { businessUnitEmails, businessUnits } = data || {}
      const emailBody = await generateAccountLinkingEmail({
        selectedClients: [
          {
            fullname: partyName,
            emailprimary: client?.contactdetails?.emailid,
            role: clientRole,
            repcode: client?.ClientAdvisorID
          }
        ],
        validatedAccounts: accountsToLink?.map((x) => ({
          CustodyAccount: x.accountNumber,
          AdvisorAddedNickName: x.accountNicknames?.advisorAddedNickName,
          Shortname: x.shortName,
          LegalEntityName: partyName,
          householdName: x.household?.householdName,
          ClientAdvisorID: x.registeredRep
        })),
        verification: { method: 'Existing Account Participant' },
        approvers: businessUnits,
        requestType: 'new',
        origin: window.origin,
        id: (response as { data: IAccountLinkingRequest }).data
          ?.rcm_accountlinkingrequestid
      })
      const emailRequest: IGraphApiSendMailRequest = {
        message: {
          toRecipients: isProd
            ? businessUnitEmails?.map((domainname) => ({
                emailAddress: { address: domainname || '' }
              })) || []
            : [{ emailAddress: { address: currentUsername } }],
          ccRecipients: isProd
            ? [
                { emailAddress: { address: currentUsername } },
                { emailAddress: { address: 'RCM-Onboarding@rockco.com' } }
              ]
            : [{ emailAddress: { address: currentUsername } }],
          body: {
            contentType: 'HTML',
            content: emailBody
          },
          subject: `Account Linking Request | Activating profile for: ${partyName}`
        }
      }
      const emailResponse = await sendEmail(emailRequest)
      const { error: emailError } = emailResponse as { error?: Error }
      if (emailError) {
        throw emailError
      }
    } catch (error) {
      setError(error as Error)
      return
    }
    setIsSubmitLoading(false)
    pushNotification({
      message: 'Profile Activation Request Successfully Submitted',
      type: MessageBarType.success
    })
    close()
  }, [
    accounts,
    client?.ClientAdvisorID,
    client?.contactdetails?.emailid,
    clientRole,
    close,
    currentUsername,
    getApproversAndEmails,
    isProd,
    partyId,
    partyName,
    phones,
    pushNotification,
    selectedAccounts,
    sendEmail,
    setError,
    submit
  ])
  const { open } = useClientInvitePanel()
  const onInviteClicked = useCallback(() => {
    if (!profile?.wsportaluserid) {
      return
    }
    close()
    open(profile?.wsportaluserid || '', 'invite')
  }, [close, open, profile?.wsportaluserid])
  const onRenderFooterContent = useCallback(() => {
    return (
      <>
        {isLoading && <Overlay styles={{ root: { zIndex: 1 } }} />}
        <Stack tokens={{ childrenGap: 10 }}>
          {profile && (
            <MessageBar messageBarType={MessageBarType.error}>
              <Stack
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: 5 }}
              >
                <Text>Profile already exists</Text>
                {profile?.wsportaluserid && (
                  <DefaultButton
                    styles={{
                      root: {
                        padding: '3px 2px',
                        height: 'auto',
                        minWidth: 'auto'
                      },
                      label: { fontWeight: 'normal', fontSize: FontSizes.small }
                    }}
                    onClick={onInviteClicked}
                  >
                    Invite Now
                  </DefaultButton>
                )}
              </Stack>
            </MessageBar>
          )}
          {isCheckError && (
            <MessageBar messageBarType={MessageBarType.error}>
              Unable to verify if profile exists
            </MessageBar>
          )}
          {submitError && (
            <MessageBar messageBarType={MessageBarType.error}>
              {submitError.message}
            </MessageBar>
          )}
          {isAccountsError && (
            <MessageBar messageBarType={MessageBarType.error}>
              Unable to fetch accounts
            </MessageBar>
          )}
          {requestExists && (
            <MessageBar messageBarType={MessageBarType.error}>
              Profile Activation Request already exists for this profile
            </MessageBar>
          )}
          {isRequestExistsError && (
            <MessageBar messageBarType={MessageBarType.error}>
              Unable to verify if request exists
            </MessageBar>
          )}
          <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
            <PrimaryButton disabled={disableSubmit} onClick={onSubmit}>
              Activate Profile
            </PrimaryButton>
            <DefaultButton onClick={close}>Cancel</DefaultButton>
          </Stack>
        </Stack>
      </>
    )
  }, [
    isLoading,
    profile,
    onInviteClicked,
    isCheckError,
    submitError,
    isAccountsError,
    requestExists,
    isRequestExistsError,
    disableSubmit,
    onSubmit,
    close
  ])

  useEffect(() => {
    setSelectedAccounts(
      accounts?.map((x) => x.accountKey)?.filter(isNotNullOrUndefined) || []
    )
  }, [accounts])
  useEffect(() => {
    setClientRole('3rd Party')
    setSubmitError(undefined)
  }, [client])
  const onSelectionChanged = useCallback(
    (key: string) => {
      if (selectedAccounts.includes(key)) {
        setSelectedAccounts(selectedAccounts.filter((x) => x !== key))
      } else {
        setSelectedAccounts([...selectedAccounts, key])
      }
    },
    [selectedAccounts]
  )
  return (
    <Panel
      headerText="Activate Profile"
      isOpen={isOpen}
      onRenderFooterContent={onRenderFooterContent}
      onDismiss={close}
      onOuterClick={() => {
        return
      }}
      closeButtonAriaLabel="Close"
      type={PanelType.custom}
      customWidth="880px"
      isFooterAtBottom={true}
      styles={{ content: { flexGrow: 1 } }}
    >
      {isLoading && <Overlay styles={{ root: { zIndex: 1 } }} />}
      <Stack tokens={{ childrenGap: 10 }}>
        <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
          <div css={{ width: '240px' }}>
            <Label required>Name</Label>
            <TextField value={partyName} disabled={true} readOnly />
          </div>
          <div css={{ width: '240px' }}>
            <Label>Email</Label>
            <TextField
              value={client?.contactdetails?.emailid}
              disabled={true}
              readOnly
            />
          </div>
          <div css={{ width: '95px' }}>
            <Label>Rep Code</Label>
            <TextField value={client?.ClientAdvisorID} disabled readOnly />
          </div>
          <div css={{ width: '150px' }}>
            <Label>Profile Role</Label>
            <TextField
              value={clientRole}
              onChange={(_, newValue) => setClientRole(newValue)}
            />
          </div>
        </Stack>
        <Label>Phone Numbers</Label>
        <Stack
          horizontal={true}
          tokens={{ childrenGap: 10 }}
          disableShrink
          wrap
        >
          {!phones?.length && <MessageBar>No Phones Found</MessageBar>}
          {!!phones?.length &&
            phones?.map((phone, index) => (
              <TextField
                value={phone.telNumber}
                disabled={true}
                key={index}
                readOnly
              />
            ))}
        </Stack>

        <Separator />
        <Label>Validate Accounts</Label>
        <ActivateProfileAccountsList
          accounts={accounts}
          onSelectionChanged={onSelectionChanged}
          selectedAccounts={selectedAccounts}
          isLoading={isLoading}
        />
      </Stack>
    </Panel>
  )
}
