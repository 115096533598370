import { isDefined } from 'shared/guards'
import { call, put, select, takeEvery } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../../../store/shared/sagas'
import { getActiveTrust } from '../../TrustInfo/store/selectors'
import { fetchBeneficiariesListSvc } from '../api/beneficiaryService'
import { beneficiaryInfoDataActions } from './actions'

function* handleGetBeneficiariesList() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const activeTrust = yield* select(getActiveTrust)

    const activityIds = activeTrust?.account_rcm_trustbeneficiaries
      ?.map((x) => x.activityid || undefined)
      ?.filter(isDefined)
    const beneficiaries = yield* call(() =>
      fetchBeneficiariesListSvc(activityIds || [], options)
    )

    yield put(beneficiaryInfoDataActions.success(beneficiaries || []))
  } catch (e) {
    yield put(beneficiaryInfoDataActions.failure(e))
  }
}

export const sagas = [
  () =>
    takeEvery(beneficiaryInfoDataActions.request, handleGetBeneficiariesList)
]
