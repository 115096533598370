import { IColumnDefinition } from '../core/contracts/IColumnDefinition'

enum ColumnIdsEnum {
  symbol = 'symbol',
  accountNumber = 'accountNumber',
  legalEntityName = 'legalEntityName',
  tradeDate = 'tradeDate',
  orderEntryDate = 'orderEntryDate',
  action = 'action',
  description = 'description',
  cusip = 'cusip',
  quantity = 'quantity',
  timeInForce = 'timeInForce',
  orderRefNo = 'orderRefNo',
  settleDate = 'settleDate',
  productType = 'productType',
  productSubType = 'productSubType',
  price = 'price',
  priceType = 'priceType',
  tradeCount = 'tradeCount',
  executedQuantity = 'executedQuantity',
  executionStatCode = 'executionStatCode',
  orderCancelCode = 'orderCancelCode',
  advisorID = 'advisorID',
  orderValue = 'orderValue',
  solicitedInd = 'solicitedInd',
  concessionAmount = 'concessionAmount',
  commisionAmount = 'commisionAmount',
  managedaccInd = 'managedaccInd',
  assetType = 'assetType',
  assetTypeDetail = 'assetTypeDetail',
  orderRefNoAndOrderEntryDate = 'orderRefNoAndOrderEntryDate',
  accountNoAndLegalEntity = 'accountNoAndLegalEntity',
  symbolCusipDesc = 'symbolCusipDesc'
}

export const OrderColumnIds = { ...ColumnIdsEnum }
export type OrderColumnIds = typeof OrderColumnIds

export const OrderColumnDefinitions: Record<string, IColumnDefinition> = {
  [OrderColumnIds.orderEntryDate]: {
    id: OrderColumnIds.orderEntryDate,
    name: 'Order Entry Date',
    searchFields: [],
    type: 'date',
    width: 145,
    filterable: true,
    sortable: true,
    dataPath: 'orderEntryDate'
  },
  [OrderColumnIds.orderRefNoAndOrderEntryDate]: {
    id: OrderColumnIds.orderRefNoAndOrderEntryDate,
    name: 'Order Ref # / Order Entry Date',
    select: ['orderRefNo', 'tradecount', 'id', 'orderEntryDate'],
    searchFields: ['orderRefNo'],
    type: 'date',
    width: 145,
    filterable: true,
    sortable: true,
    dataPath: 'orderEntryDate',
    className: 'align-left'
  },
  [OrderColumnIds.accountNumber]: {
    id: OrderColumnIds.accountNumber,
    name: 'Account Number',
    type: 'string',
    width: 90,
    filterable: true,
    sortable: true,
    searchable: true,
    dataPath: 'accountNumber'
  },
  [OrderColumnIds.accountNoAndLegalEntity]: {
    id: OrderColumnIds.accountNoAndLegalEntity,
    name: 'Account Number / Legal Entity Name',
    select: ['accountNumber', 'LegalEntityName'],
    searchFields: ['accountNumber', 'LegalEntityName'],
    type: 'string',
    width: 140,
    filterable: true,
    sortable: true,
    searchable: true,
    dataPath: 'accountNumber'
  },
  [OrderColumnIds.legalEntityName]: {
    id: OrderColumnIds.legalEntityName,
    name: 'Legal Entity Name',
    type: 'string',
    width: 220,
    filterable: true,
    sortable: true,
    searchable: true,
    dataPath: 'LegalEntityName'
  },
  [OrderColumnIds.symbol]: {
    id: OrderColumnIds.symbol,
    name: 'Symbol',
    type: 'string',
    filterable: true,
    sortable: true,
    searchable: true,
    width: 75,
    dataPath: 'Symbol'
  },
  [OrderColumnIds.cusip]: {
    id: OrderColumnIds.cusip,
    name: 'CUSIP',
    type: 'string',
    filterable: true,
    sortable: true,
    searchable: true,
    width: 85,
    dataPath: 'CUSIP'
  },
  [OrderColumnIds.symbolCusipDesc]: {
    id: OrderColumnIds.symbolCusipDesc,
    name: 'Symbol / CUSIP / Description',
    select: ['Symbol', 'CUSIP', 'description'],
    searchFields: ['Symbol', 'CUSIP', 'description'],
    type: 'string',
    filterable: true,
    sortable: true,
    searchable: true,
    width: 250,
    dataPath: 'Symbol'
  },
  [OrderColumnIds.action]: {
    id: OrderColumnIds.action,
    name: 'Action',
    type: 'string',
    filterable: true,
    sortable: true,
    facetable: true,
    width: 75,
    dataPath: 'Action'
  },
  [OrderColumnIds.description]: {
    id: OrderColumnIds.description,
    name: 'Description',
    type: 'string',
    width: 200,
    filterable: true,
    sortable: true,
    searchable: true,
    dataPath: 'description'
  },
  [OrderColumnIds.quantity]: {
    id: OrderColumnIds.quantity,
    name: 'Order Quantity',
    type: 'number',
    width: 90,
    filterable: true,
    sortable: true,
    dataPath: 'Quantity'
  },
  [OrderColumnIds.executedQuantity]: {
    id: OrderColumnIds.executedQuantity,
    name: 'Executed Quantity',
    type: 'number',
    width: 90,
    filterable: true,
    sortable: true,
    dataPath: 'ExecQuantity'
  },
  [OrderColumnIds.timeInForce]: {
    id: OrderColumnIds.timeInForce,
    name: 'Time in Force',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: true,
    facetable: true,
    dataPath: 'TimeinForce'
  },
  [OrderColumnIds.orderRefNo]: {
    id: OrderColumnIds.orderRefNo,
    name: 'Order Ref #',
    select: ['orderRefNo', 'tradecount', 'id'],
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    dataPath: 'orderRefNo'
  },
  [OrderColumnIds.productSubType]: {
    id: OrderColumnIds.productSubType,
    name: 'Product Sub Type',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: true,
    facetable: true,
    dataPath: 'AssetSubType'
  },
  [OrderColumnIds.productType]: {
    id: OrderColumnIds.productType,
    name: 'Product Type',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: true,
    facetable: true,
    dataPath: 'AssetType'
  },
  [OrderColumnIds.assetType]: {
    id: OrderColumnIds.assetType,
    name: 'Asset Class',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: true,
    facetable: true,
    dataPath: 'assetClassLevel1'
  },
  [OrderColumnIds.assetTypeDetail]: {
    id: OrderColumnIds.assetTypeDetail,
    name: 'Asset Class Detail',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: true,
    facetable: true,
    dataPath: 'assetClassLevel3'
  },
  [OrderColumnIds.price]: {
    id: OrderColumnIds.price,
    name: 'Avg. Price',
    type: 'number',
    width: 90,
    filterable: true,
    sortable: true,
    dataPath: 'ExecutionPrice'
  },
  [OrderColumnIds.priceType]: {
    id: OrderColumnIds.priceType,
    name: 'Price Type',
    type: 'string',
    width: 110,
    filterable: true,
    sortable: true,
    facetable: true,
    dataPath: 'PriceType'
  },
  [OrderColumnIds.tradeCount]: {
    id: OrderColumnIds.tradeCount,
    name: 'Trade Count',
    type: 'number',
    width: 70,
    filterable: true,
    sortable: true,
    dataPath: 'tradecount'
  },
  [OrderColumnIds.executionStatCode]: {
    id: OrderColumnIds.executionStatCode,
    name: 'Execution Status',
    type: 'string',
    width: 110,
    filterable: true,
    sortable: true,
    facetable: true,
    dataPath: 'ExecutionStatCode'
  },
  [OrderColumnIds.orderCancelCode]: {
    id: OrderColumnIds.orderCancelCode,
    name: 'Order Status',
    type: 'string',
    width: 110,
    filterable: true,
    sortable: true,
    facetable: true,
    dataPath: 'OrderCancelCode'
  },
  [OrderColumnIds.advisorID]: {
    id: OrderColumnIds.advisorID,
    name: 'Advisor Id',
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    facetable: true,
    dataPath: 'AdvisorID',
    mask: true
  },
  [OrderColumnIds.solicitedInd]: {
    id: OrderColumnIds.solicitedInd,
    name: 'Solicited Indicator',
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    facetable: true,
    dataPath: 'solicitedInd'
  },
  [OrderColumnIds.orderValue]: {
    id: OrderColumnIds.orderValue,
    name: 'Value',
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true,
    dataPath: 'orderValue'
  },
  [OrderColumnIds.commisionAmount]: {
    id: OrderColumnIds.commisionAmount,
    name: 'Commission',
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true,
    dataPath: 'commisionAmount'
  },
  [OrderColumnIds.concessionAmount]: {
    id: OrderColumnIds.concessionAmount,
    name: 'Concession',
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true,
    dataPath: 'concessionAmount'
  },
  [OrderColumnIds.managedaccInd]: {
    id: OrderColumnIds.managedaccInd,
    name: 'AUS Class',
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    facetable: true,
    dataPath: 'managedaccInd'
  }
}
