import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { rdot360ContextActions } from '../../store/rdot360Context'

const currentYear = new Date().getFullYear()

const { actions, reducer } = createSlice({
  name: 'gainLossesStore',
  initialState: {
    selectedFinancialYear: currentYear,
    isTaxableAccounts: false,
    searchText: ''
  },
  reducers: {
    setSelectedFinancialYear: (state, action: PayloadAction<number>) => {
      state.selectedFinancialYear = action.payload
    },
    setIsTaxableAccounts: (state, action: PayloadAction<boolean>) => {
      state.isTaxableAccounts = action.payload
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rdot360ContextActions.setSelectedHouseholdId, (state) => {
      state.searchText = ''
      state.selectedFinancialYear = currentYear
    })
  }
})

export { reducer as gainLossesStore }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.modules.dashboard.gainLosses

export const getSelectedFinancialYear = flow(
  rootSelector,
  (x) => x.selectedFinancialYear
)

export const getIsTaxableAccounts = flow(
  rootSelector,
  (x) => x.isTaxableAccounts
)

const getSearchText = flow(rootSelector, (x) => x.searchText)

export const useGainLossesStore = () => {
  const dispatch = useDispatch()
  const setSelectedFinancialYear = useCallback(
    (selectedYear: number) => {
      dispatch(actions.setSelectedFinancialYear(selectedYear))
    },
    [dispatch]
  )
  const selectedFinancialYear = useSelector(getSelectedFinancialYear)
  const searchText = useSelector(getSearchText)

  const isTaxableAccounts = useSelector(getIsTaxableAccounts)
  const setIsTaxableAccounts = useCallback(
    (taxableState: boolean) => {
      dispatch(actions.setIsTaxableAccounts(taxableState))
    },
    [dispatch]
  )
  const setSearchText = useCallback(
    (search?: string) => {
      dispatch(actions.setSearchText(search || ''))
    },
    [dispatch]
  )

  return {
    selectedFinancialYear,
    setSelectedFinancialYear,
    isTaxableAccounts,
    setIsTaxableAccounts,
    searchText,
    setSearchText
  }
}
