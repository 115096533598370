import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { TagDescription } from '@reduxjs/toolkit/dist/query/react'
import { IPosition } from 'api/position.types'
import { orderBy, range } from 'lodash'
import pLimit from 'p-limit'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { isNotNullOrUndefined } from 'shared/guards'
import { datahubApi } from 'store/api/datahub'
import { arrayCommaParamsSerializer, AxiosBaseArgs } from 'store/api/shared'

type AiPositionApiTagType = 'aiPositionDetails'

const datahubApiTags: AiPositionApiTagType[] = ['aiPositionDetails']

const datahubWithAiPositionTags = datahubApi.enhanceEndpoints({
  addTagTypes: datahubApiTags
})

export const aiDashboardPositionsApi =
  datahubWithAiPositionTags.injectEndpoints({
    endpoints: (builder) => ({
      fetchAllAiPositions: builder.query<
        IPosition[] | undefined,
        string[] | undefined
      >({
        keepUnusedDataFor: 60 * 10,
        queryFn: async (reps, _api, _extraOptions, baseQuery) => {
          type Response = QueryReturnValue<IOdataResult<IPosition>, Error>

          const baseApiArgs: Partial<AxiosBaseArgs> = {
            method: 'POST',
            url: 'search/positions',
            paramsSerializer: arrayCommaParamsSerializer
          }

          const baseApiParams = {
            filter: [
              reps?.length
                ? `search.in(registeredrep, '${reps.join('|')}', '|')`
                : undefined,
              `search.in(assetClassLevel1, 'Private Investments|Hedge Funds', '|')`,
              `search.in(securitytype, 'AI', '|')`
            ]
              .filter(isNotNullOrUndefined)
              .join(' and '),
            orderby: 'id desc',
            select: [
              'accountnumber',
              'nickname',
              'shortName',
              'registrationType',
              'LegalEntityName',
              'symbol',
              'cusip',
              'description',
              'securitySubtype',
              'assetClassLevel3',
              'DrawdownNonDrawdown',
              'OriginalOrderDate',
              'capitalCommitment',
              'CapitalCall',
              'Unfunded_Value',
              'TotalValue',
              'AdjustedTotalValue',
              'OriginalOrderAmount',
              'costBasisAmount',
              'RemainingOriginalAmount',
              'Distributions',
              'marketvalue',
              'AdjustedMarketValue',
              'ENTRY_DATE',
              'UnrealizedGainOrloss',
              'householdId',
              'householdName',
              'CleanFundName',
              'DivCash',
              'VintageYear',
              'SponsorName',
              'OnPlatform_OffPlatform'
            ].join(',')
          }

          const peekTop = 50
          const chunkTop = 1000

          const peek = (await baseQuery({
            ...baseApiArgs,
            data: { ...baseApiParams, top: peekTop, count: true }
          })) as Response

          const count = peek.data?.['@odata.count'] || 0

          const numChunks = Math.ceil(Math.max(count - peekTop, 0) / chunkTop)
          const requests = range(0, numChunks).map(
            (i): [number, AxiosBaseArgs] => {
              const skip = peekTop + i * chunkTop
              return [
                i,
                {
                  ...baseApiArgs,
                  data: {
                    ...baseApiParams,
                    top: Math.min(chunkTop, count - skip),
                    skip
                  }
                }
              ]
            }
          )

          const limit = pLimit(7)
          const results = await Promise.all(
            requests.map(([i, x]) =>
              limit(async (): Promise<[number, Response]> => {
                const result = (await baseQuery(x)) as Response
                if (result.error) {
                  limit.clearQueue()
                }
                return [i, result]
              })
            )
          )

          const error = results.find(([, result]) => result.error)
          if (error) {
            return { error }
          }

          return {
            data: [
              peek.data?.value || [],
              ...orderBy(results, ([index]) => index, 'asc').map(
                ([, result]) => result.data?.value || []
              )
            ].flat()
          }
        }
      }),

      fetchAiPositionDetails: builder.query<
        IPosition[] | undefined,
        {
          accountKeys?: string[]
          cusip?: string
          selectFields?: (keyof IPosition)[]
        }
      >({
        query: ({ accountKeys, cusip, selectFields = [] }) => ({
          url: 'search/positions',
          method: 'POST',
          data: {
            count: true,
            filter: [
              `search.in(DrawdownNonDrawdown, 'YES|NO', '|')`,
              `search.in(accountKey, '${accountKeys?.join('|')}', '|')`,
              `cusip eq '${cusip}'`
            ].join(' and '),
            select: selectFields.join(','),
            queryType: 'full'
          }
        }),
        providesTags: ['aiPositionDetails'],
        transformResponse: (x: IOdataResult<IPosition>) => x.value
      })
    })
  })

export const { useFetchAllAiPositionsQuery, useFetchAiPositionDetailsQuery } =
  aiDashboardPositionsApi

export const useAiPositionApiUtil = () => {
  const dispatch = useDispatch()
  const invalidateTags = useCallback(
    (tags: TagDescription<AiPositionApiTagType>[]) =>
      dispatch(datahubWithAiPositionTags.util.invalidateTags(tags)),
    [dispatch]
  )

  return {
    invalidateTags
  }
}
