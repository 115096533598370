import { IFontStyles, Text } from '@fluentui/react'
import React from 'react'
import { FormattedDate } from 'react-intl'
import { parseDateISOStringInLocalTimezone } from 'shared'

export const DateOnlyCell: React.FC<{
  value?: string
  size?: keyof IFontStyles
}> = ({ value, size }) =>
  value ? (
    <Text nowrap={true} variant={size}>
      <FormattedDate
        day="2-digit"
        month="2-digit"
        year="numeric"
        value={parseDateISOStringInLocalTimezone(value)}
      />
    </Text>
  ) : (
    <Text>--</Text>
  )
