import { AppState, createAsyncSelectors } from 'store/shared'

const annuityExcelExportRootSelector = (state: AppState) =>
  state.modules.advisory.modules.annuitiesAndInsurance.modules.dashboard
    .features.annuitiesExcelExport

const insuranceExcelExportRootSelector = (state: AppState) =>
  state.modules.advisory.modules.annuitiesAndInsurance.modules.dashboard
    .features.insuranceExcelExport

const holdingsExcelExportRootSelector = (state: AppState) =>
  state.modules.advisory.modules.annuitiesAndInsurance.modules.dashboard
    .features.holdingsExcelExport

export const {
  getError: getAnnuityExcelExportError,
  getIsLoading: getAnnuityExcelExportIsLoading
} = createAsyncSelectors(annuityExcelExportRootSelector)

export const {
  getError: getInsuranceExcelExportError,
  getIsLoading: getInsuranceExcelExportIsLoading
} = createAsyncSelectors(insuranceExcelExportRootSelector)

export const {
  getError: getHoldingsExcelExportError,
  getIsLoading: getHoldingsExcelExportIsLoading
} = createAsyncSelectors(holdingsExcelExportRootSelector)
