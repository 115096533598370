import { IconButton, Modal } from '@fluentui/react'
import { FC } from 'react'
import { LoadingComponent } from 'shared/components/Loading'
import { buttonStyles } from '../../components/shared/Buttons'

export const OverrideModal: FC<{
  isModalOpen: boolean
  onHide: () => void
  onSave: () => void
  isUpdateAdvisorNicknameLoading: boolean
}> = ({ isModalOpen, onHide, onSave, isUpdateAdvisorNicknameLoading }) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={onHide}
      styles={{ main: { display: 'flex' } }}
      isBlocking={true}
    >
      <div css={{ width: 630 }}>
        <div
          css={{
            padding: '16px 0px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderBottom: '1px #CFCFCF solid'
          }}
        >
          <div css={{ marginLeft: 20, fontWeight: 500, fontSize: 18 }}>
            Confirm changes
          </div>
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel="Close popup modal"
            onClick={onHide}
            css={{
              marginRight: 20,
              height: 22,
              ':hover': {
                backgroundColor: 'transparent'
              }
            }}
          />
        </div>
        <div
          css={{
            marginLeft: 20,
            marginTop: 32,
            marginBottom: 40,
            marginRight: 20,
            fontWeight: 500,
            fontSize: 18
          }}
        >
          Are you sure you want to override the Rockefeller Digital Nickname?
        </div>
        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            padding: 16,
            boxShadow: ' 0px 4px 15px 0px rgba(0, 0, 0, 0.25)'
          }}
        >
          <button css={[buttonStyles.secondary]} onClick={onHide}>
            No
          </button>
          <button
            css={[buttonStyles.primary, { marginLeft: 16 }]}
            onClick={onSave}
          >
            {isUpdateAdvisorNicknameLoading ? <LoadingComponent /> : 'Yes'}
          </button>
        </div>
      </div>
    </Modal>
  )
}
