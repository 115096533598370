import { AppState, createAsyncSelectors } from 'store/shared'

const AltsAdvisorRevenueExcelExportRootSelector = (state: AppState) =>
  state.modules.airevenue.modules.dashboard.features.altsAdvisorRevenueDetail
    .dashboard.features.AltsAdvisorRevenueExcelExport

export const {
  getError: getAltsAdvisorRevenueExcelExportError,
  getIsLoading: getAltsAdvisorRevenueExcelExportIsLoading
} = createAsyncSelectors(AltsAdvisorRevenueExcelExportRootSelector)
