import axios from 'axios'
import { IApiOptions } from '../../../../../../../shared/contracts/IApiOptions'
import { IMatrixInfo } from '../../../../Dashboard/store/types'
import { fetchPaymentTransationsListMockResponse } from '../store/mockdata'
import {
  IFetchPaymentTransactionsRequest,
  IMainAccount,
  IPayee,
  ISubAccount
  //IFetchPaymentTransactionsResponse
} from '../store/types'

export const fetchPaymentTransactionsListService = (
  data: IFetchPaymentTransactionsRequest,
  options: IApiOptions
) => {
  const url = `${
    options.apiRoot
  }/trustapi/api/Transactions/GetGLTransactions?legalentityid=${
    data.entityid
  }&fromdate=${'03/01/2022'}&todate=${'03/31/2022'}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  console.log(url, headers)
  // return axios
  //   .get<IFetchPaymentTransactionsResponse[]>(url, {
  //     headers,
  //     cancelToken: options.cancelToken
  //   })
  //   .then((res) => res.data)

  return fetchPaymentTransationsListMockResponse.data
}

export const fetchPayeeListService = (
  options: IApiOptions,
  client: IMatrixInfo
) => {
  const url = `${options.apiRoot}/payment/api/d365/getUIVendorPayee?entityId=${client.pKid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPayee[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
export const fetchMainAccountListService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/api/VendorPayeeRule/GetMainAccounts`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IMainAccount[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
export const fetchSubAccountListService = (
  options: IApiOptions,
  client: IMatrixInfo
) => {
  const url = `${options.apiRoot}/trustapi/api/VendorPayeeRule/GetSubAccounts?clientid=${client?.pKid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<ISubAccount[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
