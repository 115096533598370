import { RdotUserRoleEnum } from 'store/user/rdotUser'
import CA from './assets/CA.png'
import Insights from './assets/insights.png'
import LifeInsurance from './assets/lifeInsurance.png'
import PaymentHub from './assets/PaymentHub.png'

export interface IAppConfig {
  name?: string
  path?: string
  icon?: string
  configApp?: string
  appId?: string
  imgSrc?: string
  roles?: RdotUserRoleEnum[]
}

export const appConfig: IAppConfig[] = [
  { name: 'Book Dashboard', path: '/advisory/dashboard', icon: 'Home' },
  { name: 'Client Dashboard', path: '/advisory/client', icon: 'ContactCard' },
  {
    name: 'Service Dashboard',
    path: '/advisory/alerts',
    imgSrc: CA
  },
  {
    name: 'Insights Dashboard',
    configApp: 'insightsDashboard',
    imgSrc: Insights,
    roles: [RdotUserRoleEnum.Advisory_Feature_ClientDashboardInsights]
  },
  { name: 'AI Dashboard', path: '/advisory/ai', icon: 'BIDashboard' },
  { name: 'Wealthscape', configApp: 'wealthscape' },
  { name: 'SIMON', configApp: 'simon' },
  { name: 'AI Order Entry', configApp: 'aiorderentry' },
  { name: 'iCapital', configApp: 'icapital' },
  { name: 'Tamarac', configApp: 'tamarac' },
  { name: 'Addepar', configApp: 'addepar' },
  { name: 'eMoney', configApp: 'emoney' },
  { name: 'Proposal Tool', configApp: 'proposaltool' },
  { name: 'Factset', configApp: 'factset' },
  { name: 'Revenue Dashboard', path: '/advisory/revenue', icon: 'Chart' },
  { name: 'BDA Dashboard', path: '/advisory/bda', icon: 'Bank' },
  { name: 'RPM', configApp: 'rpm' },
  { name: 'Payment Hub', path: '/transfers/dashboard', imgSrc: PaymentHub },
  {
    name: 'Annuities & Insurance',
    path: '/advisory/annuitiesAndInsurance',
    imgSrc: LifeInsurance
  },
  {
    name: 'Third Party Dashboard',
    path: '/externalmanager/dashboard',
    icon: 'ContactCard',
    roles: [
      RdotUserRoleEnum.Advisory_ThirdParty,
      RdotUserRoleEnum.HomeOffice_ThirdParty
    ]
  },
  {
    name: 'Retirement Dashboard',
    path: '/advisory/retirement',
    icon: 'PlanView',
    roles: [RdotUserRoleEnum.Advisory_Feature_RetirementDashboard]
  }
]
