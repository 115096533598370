const p_whenIdle = () => {
  return new Promise<void>((resolve) => {
    requestIdleCallback(() => {
      resolve()
    })
  })
}

const wait = (ms?: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

export const whenIdle = async () => {
  return Promise.race([p_whenIdle(), wait(3000)])
}
