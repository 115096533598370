import { Stack } from '@fluentui/react'
import { flexRender, Row } from '@tanstack/react-table'
import { HighlightSearchText } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/HighlightSearchText'
import { Icon } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import {
  rdot360TableStyles,
  useRdot360ThemedTableStyles
} from 'modules/Advisory/modules/Rdot360/shared/tableStyles'
import { IPerformanceReport } from 'modules/Advisory/modules/Rdot360/store/types'
import { FC, Fragment } from 'react'
import { reportCellStyles } from '../../styles'
import { colNames } from './columnDefinitions'
import { ShowActions } from './ShowActions'

export const TableBody: FC<{
  rows: Row<IPerformanceReport>[]
  groupName: string
  search: string
}> = ({ rows, groupName, search }) => {
  const themedStyles = useRdot360ThemedTableStyles()
  return (
    <tbody>
      {rows.map((row) => {
        const visibleCells = row.getVisibleCells()
        const isRowGrouped = row.getIsGrouped()
        const groupingValue = row.getGroupingValue(groupName) as string
        const isExpanded = row.getIsExpanded()
        const expandIconName = isExpanded ? 'Subtract' : 'Add'
        const rowCSS = isRowGrouped
          ? [
              rdot360TableStyles.bodyRow,
              themedStyles.bodyRow,
              themedStyles.groupRow,
              themedStyles.l1GroupRow,
              isExpanded && themedStyles.groupRowExpanded
            ]
          : [
              rdot360TableStyles.bodyRow,
              themedStyles.bodyRow,
              themedStyles.securityRow
            ]

        return (
          <Fragment key={row.id}>
            {isRowGrouped && (
              <>
                <tr>
                  <td colSpan={visibleCells.length + 2} css={{ height: 4 }} />
                </tr>
                <tr css={rowCSS}>
                  <td colSpan={visibleCells.length + 2}>
                    <Stack
                      horizontal
                      tokens={{ childrenGap: 8 }}
                      verticalAlign="center"
                    >
                      <div
                        css={{
                          cursor: 'pointer',
                          display: 'flex'
                        }}
                      >
                        <Icon
                          type={expandIconName}
                          width={12}
                          height={12}
                          onClick={row.getToggleExpandedHandler()}
                        />
                      </div>
                      <strong>
                        <HighlightSearchText
                          text={groupingValue}
                          search={search}
                        />
                      </strong>
                    </Stack>
                  </td>
                </tr>
              </>
            )}
            {!isRowGrouped && (
              <tr css={rowCSS}>
                <td css={{ width: '1%' }} />
                {visibleCells.map((cell) => {
                  return (
                    <td key={cell.id} css={[reportCellStyles[cell.column.id]]}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  )
                })}
                <td css={[reportCellStyles[colNames.action]]}>
                  <ShowActions report={row.original} />
                </td>
              </tr>
            )}
          </Fragment>
        )
      })}
    </tbody>
  )
}
