import { uniq } from 'lodash'
import { flow } from 'lodash/fp'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { getTeamsModuleState } from './shared'

export const SET_FILTER = '@modules/@teams/@AUM/SET_FILTER'
export const SET_DIVISION_FILTER = '@modules/@teams/@AUM/SET_DIVISION_FILTER'
export const SET_DIVISION_FILTER_SEARCH =
  '@modules/@teams/@AUM/SET_DIVISION_FILTER_SEARCH'
export const SET_PRIOR_FIRM = '@modules/@teams/@AUM/SET_PRIOR_FIRM'
export const SET_MIN_DATE = '@modules/@teams/@AUM/SET_MIN_DATE'
export const SET_MAX_DATE = '@modules/@teams/@AUM/SET_MAX_DATE'
export const SET_SELECTED_LENGTH_OF_SERVICE =
  '@modules/@teams/@AUM/SET_SELECTED_LENGTH_OF_SERVICE'
export const SET_VINTAGE = '@modules/@teams/@AUM/SET_VINTAGE'
export const ADD_TEAM = '@modules/@teams/@AUM/ADD_TEAM'
export const ADD_TEAMS = '@modules/@teams/@AUM/ADD_TEAMS'
export const REMOVE_TEAM = '@modules/@teams/@AUM/REMOVE_TEAM'
export const REMOVE_TEAMS = '@modules/@teams/@AUM/REMOVE_TEAMS'
export const SET_AS_OF_DATE = '@modules/@teams/@AUM/SET_AS_OF_DATE'

export const teamAnalyticsDashboardActions = {
  setFilter: createAction(SET_FILTER)<string>(),
  setDivisionFilter: createAction(SET_DIVISION_FILTER)<string>(),
  setDivisionFilterSearch: createAction(SET_DIVISION_FILTER_SEARCH)<string>(),
  setPriorFirm: createAction(SET_PRIOR_FIRM)<string>(),
  addTeam: createAction(ADD_TEAM)<string>(),
  addTeams: createAction(ADD_TEAMS)<string[]>(),
  removeTeam: createAction(REMOVE_TEAM)<string>(),
  removeTeams: createAction(REMOVE_TEAMS)<string[]>(),
  setMinDate: createAction(SET_MIN_DATE)<Date | undefined>(),
  setMaxDate: createAction(SET_MAX_DATE)<Date | undefined>(),
  setSelectedLengthOfService: createAction(
    SET_SELECTED_LENGTH_OF_SERVICE
  )<string>(),
  setSelectedVintage: createAction(SET_VINTAGE)<number | undefined>(),
  setAsOfDate: createAction(SET_AS_OF_DATE)<Date | undefined>()
}

export interface ITeamAnalyticsDashboardState {
  filter?: string
  divisionFilter?: string
  divisionFilterSearch?: string
  teamsFilter: string[]
  minDate?: Date
  maxDate?: Date
  selectedLengthOfService?: string
  vintage?: number
  asOfDate?: Date
  priorFirm?: string
}

export const teamAnalyticsDashboardReducer = createReducer<
  ITeamAnalyticsDashboardState,
  ActionType<typeof teamAnalyticsDashboardActions>
>({
  selectedLengthOfService: 'all',
  vintage: 0,
  teamsFilter: [],
  priorFirm: 'all'
})
  .handleAction(teamAnalyticsDashboardActions.setFilter, (state, action) => ({
    ...state,
    filter: action.payload
  }))
  .handleAction(
    teamAnalyticsDashboardActions.setDivisionFilter,
    (state, action) => ({
      ...state,
      divisionFilter: action.payload
    })
  )
  .handleAction(
    teamAnalyticsDashboardActions.setDivisionFilterSearch,
    (state, action) => ({
      ...state,
      divisionFilterSearch: action.payload
    })
  )
  .handleAction(teamAnalyticsDashboardActions.addTeam, (state, action) => ({
    ...state,
    teamsFilter: uniq([...(state?.teamsFilter || []), action.payload])
  }))
  .handleAction(teamAnalyticsDashboardActions.addTeams, (state, action) => ({
    ...state,
    teamsFilter: uniq([...(state?.teamsFilter || []), ...action.payload])
  }))
  .handleAction(teamAnalyticsDashboardActions.removeTeam, (state, action) => ({
    ...state,
    teamsFilter: state.teamsFilter.filter((team) => team !== action.payload)
  }))
  .handleAction(teamAnalyticsDashboardActions.removeTeams, (state, action) => ({
    ...state,
    teamsFilter: state.teamsFilter.filter(
      (team) => !action.payload.includes(team)
    )
  }))
  .handleAction(teamAnalyticsDashboardActions.setMinDate, (state, action) => ({
    ...state,
    minDate: action.payload
  }))
  .handleAction(teamAnalyticsDashboardActions.setMaxDate, (state, action) => ({
    ...state,
    maxDate: action.payload
  }))
  .handleAction(
    teamAnalyticsDashboardActions.setSelectedLengthOfService,
    (state, action) => ({
      ...state,
      selectedLengthOfService: action.payload
    })
  )
  .handleAction(
    teamAnalyticsDashboardActions.setSelectedVintage,
    (state, action) => ({
      ...state,
      vintage: action.payload
    })
  )
  .handleAction(teamAnalyticsDashboardActions.setAsOfDate, (state, action) => ({
    ...state,
    asOfDate: action.payload
  }))
  .handleAction(
    teamAnalyticsDashboardActions.setPriorFirm,
    (state, action) => ({
      ...state,
      priorFirm: action.payload
    })
  )

export const getTeamAnalyticsDashboardState = flow(
  getTeamsModuleState,
  (x) => x?.teamAnalyticsDashboard
)
