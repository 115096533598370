import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { flow } from 'lodash'
import { tryAcquireAccessToken } from 'shared/services/auth'
import { AppState } from 'store/shared'
import { getRockefellerApiConfig } from 'store/system'
import { axiosBaseQuery } from '../shared'

const cdmApiSliceKey = 'api.cdm'
export type CdmApiReducerState = {
  [cdmApiSliceKey]: ReturnType<typeof cdmApi.reducer>
}

const getCdmApiBaseUrl = (state: AppState) => {
  const base = flow(getRockefellerApiConfig, (x) => x?.root)(state)
  const v1 = new URL('cdm', base)
  return v1.href
}

const getCdmApiAuthToken = (state: AppState) => {
  const scopes = flow(getRockefellerApiConfig, (x) => x?.scopes)(state)

  if (!scopes) {
    throw new Error('')
  }

  return tryAcquireAccessToken(scopes)
}

export const cdmApi = createApi({
  reducerPath: cdmApiSliceKey,
  baseQuery: axiosBaseQuery({
    getBaseUrl: (state) => getCdmApiBaseUrl(state),
    getAuthToken: (state) => getCdmApiAuthToken(state)
  }),
  tagTypes: ['profile'],
  endpoints: (builder) => ({
    getReportBase64Code: builder.mutation<string | undefined, string>({
      query: (id) => ({
        url: `/Performance/viewReport/${id}`
      })
    })
  })
})

export const { useGetReportBase64CodeMutation } = cdmApi
