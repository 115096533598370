import { all, call, ForkEffect, fork } from 'redux-saga/effects'
import { trackException } from './telemetry'

export const createRootSaga = (
  key: string,
  sagas: (() => ForkEffect | Generator)[]
) => {
  return function* () {
    yield all(
      sagas.map((saga) => {
        const yieldable = saga()
        return fork(function* () {
          while (true) {
            try {
              yield call(function* () {
                yield yieldable
              })
              break
            } catch (e: any) {
              trackException(e, { source: key })
              console.error('saga root error', key, e)
            }
          }
        })
      })
    )
  }
}
