import { partial } from 'lodash/fp'
import { createListsComponents } from '../core/containers/service'
import { IUseListHookDefinition } from '../hooks/useList/hookDefinitions'
import { createFilter } from '../shared/lists'
import { HouseholdColumnDefinitions } from './ColumnDefinitions'
import { HouseholdCell } from './HouseholdCell'
import { actions, selectors } from './store'

export * from './store'

export const createHouseholdListFilter = partial(createFilter, [
  HouseholdColumnDefinitions
])

export const HouseholdListComponents = createListsComponents(
  actions,
  selectors,
  HouseholdCell
)

export const householdListHookDefinition: IUseListHookDefinition = {
  actions,
  selectors,
  columnDefinitions: HouseholdColumnDefinitions,
  listType: 'household'
}
