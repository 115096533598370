import { ActionType, createAction } from 'typesafe-actions'
import {
  GLTransactionRquest,
  IGLTransaction,
  IGWESTransCodes,
  PostGLTrasactionRequest,
  ReverseGLTransactionRequest,
  UpdateGLTransactionRequest
} from './types'

export const FETCH_GL_PAYMENTS = '@modules/@payments/@gl/FETCH_GL_PAYMENTS'
export const FETCH_GL_PAYMENTS_SUCCESS =
  '@modules/@payments/@gl/FETCH_GL_PAYMENTS_SUCCESS'
export const FETCH_GL_PAYMENTS_FAILURE =
  '@modules/@payments/@gl/FETCH_GL_PAYMENTS_FAILURE'

export const REVERSE_GL_PAYMENTS = '@modules/@payments/@gl/REVERSE_GL_PAYMENTS'
export const REVERSE_GL_PAYMENTS_SUCCESS =
  '@modules/@payments/@gl/REVERSE_GL_PAYMENTS_SUCCESS'
export const REVERSE_GL_PAYMENTS_FAILURE =
  '@modules/@payments/@gl/REVERSE_GL_PAYMENTS_FAILURE'
export const REVERSE_GL_PAYMENTS_CLEAR =
  '@modules/@payments/@gl/REVERSE_GL_PAYMENTS_CLEAR'

export const UPDATE_GL_PAYMENT = '@modules/@payments/@gl/UPDATE_GL_PAYMENT'
export const UPDATE_GL_PAYMENT_SUCCESS =
  '@modules/@payments/@gl/UPDATE_GL_PAYMENT_SUCCESS'
export const UPDATE_GL_PAYMENT_FAILURE =
  '@modules/@payments/@gl/UPDATE_GL_PAYMENT_FAILURE'
export const UPDATE_GL_PAYMENT_CLEAR =
  '@modules/@payments/@gl/UPDATE_GL_PAYMENT_CLEAR'

export const UPDATE_MODIFIED_GL_PAYMENT =
  '@modules/@payments/@gl/UPDATE_MODIFIED_GL_PAYMENT'
export const UPDATE_MODIFIED_GL_PAYMENT_SUCCESS =
  '@modules/@payments/@gl/UPDATE_MODIFIED_GL_PAYMENT_SUCCESS'
export const UPDATE_MODIFIED_GL_PAYMENT_FAILURE =
  '@modules/@payments/@gl/UPDATE_MODIFIED_GL_PAYMENT_FAILURE'

export const FETCH_GWES_TRANS_CODES =
  '@modules/@payments/@gl/FETCH_GWES_TRANS_CODES'
export const FETCH_GWES_TRANS_CODES_SUCCESS =
  '@modules/@payments/@gl/FETCH_GWES_TRANS_CODES_SUCCESS'
export const FETCH_GWES_TRANS_CODES_FAILURE =
  '@modules/@payments/@gl/FETCH_GWES_TRANS_CODES_FAILURE'

export const POST_GL_PAYMENTS = '@modules/@payments/@gl/POST_GL_PAYMENTS'
export const POST_GL_PAYMENTS_SUCCESS =
  '@modules/@payments/@gl/POST_GL_PAYMENTS_SUCCESS'
export const POST_GL_PAYMENTS_FAILURE =
  '@modules/@payments/@gl/POST_GL_PAYMENTS_FAILURE'
export const POST_GL_PAYMENTS_CLEAR =
  '@modules/@payments/@gl/POST_GL_PAYMENTS_CLEAR'

export const FETCH_EDITED_GL_PAYMENTS =
  '@modules/@payments/@gl/FETCH_EDITED_GL_PAYMENTS'
export const FETCH_EDITED_GL_PAYMENTS_SUCCESS =
  '@modules/@payments/@gl/FETCH_EDITED_GL_PAYMENTS_SUCCESS'
export const FETCH_EDITED_GL_PAYMENTS_FAILURE =
  '@modules/@payments/@gl/FETCH_EDITED_GL_PAYMENTS_FAILURE'

export const SET_INPUT_FOR_MODIFIED_PAYMENTS =
  '@modules/@payments/@gl/SET_INPUT_FOR_MODIFIED_PAYMENTS'

export const glTransactionDataActions = {
  request: createAction(FETCH_GL_PAYMENTS)<GLTransactionRquest>(),
  success: createAction(FETCH_GL_PAYMENTS_SUCCESS)<IGLTransaction[]>(),
  failure: createAction(FETCH_GL_PAYMENTS_FAILURE)<any>()
}

export const reverseTransactionActions = {
  request: createAction(REVERSE_GL_PAYMENTS)<ReverseGLTransactionRequest>(),
  success: createAction(REVERSE_GL_PAYMENTS_SUCCESS)(),
  failure: createAction(REVERSE_GL_PAYMENTS_FAILURE)<any>(),
  clear: createAction(REVERSE_GL_PAYMENTS_CLEAR)()
}

export const updateTransactionActions = {
  request: createAction(UPDATE_GL_PAYMENT)<UpdateGLTransactionRequest>(),
  success: createAction(UPDATE_GL_PAYMENT_SUCCESS)(),
  failure: createAction(UPDATE_GL_PAYMENT_FAILURE)<any>(),
  clear: createAction(UPDATE_GL_PAYMENT_CLEAR)()
}

export const gwesTransCodesDataActions = {
  request: createAction(FETCH_GWES_TRANS_CODES)(),
  success: createAction(FETCH_GWES_TRANS_CODES_SUCCESS)<IGWESTransCodes[]>(),
  failure: createAction(FETCH_GWES_TRANS_CODES_FAILURE)<any>()
}

export const postGlPaymentsActions = {
  request: createAction(POST_GL_PAYMENTS)<PostGLTrasactionRequest>(),
  success: createAction(POST_GL_PAYMENTS_SUCCESS)(),
  failure: createAction(POST_GL_PAYMENTS_FAILURE)<any>(),
  clear: createAction(POST_GL_PAYMENTS_CLEAR)()
}

export const editedGlTransactionDataActions = {
  request: createAction(FETCH_EDITED_GL_PAYMENTS)<GLTransactionRquest>(),
  success: createAction(FETCH_EDITED_GL_PAYMENTS_SUCCESS)<IGLTransaction[]>(),
  failure: createAction(FETCH_EDITED_GL_PAYMENTS_FAILURE)<any>(),
  setInputs: createAction(
    SET_INPUT_FOR_MODIFIED_PAYMENTS
  )<GLTransactionRquest>()
}

export const updateModifiedTransactionActions = {
  request: createAction(
    UPDATE_MODIFIED_GL_PAYMENT
  )<UpdateGLTransactionRequest>(),
  success: createAction(UPDATE_MODIFIED_GL_PAYMENT_SUCCESS)(),
  failure: createAction(UPDATE_MODIFIED_GL_PAYMENT_FAILURE)<any>()
}

export type GlTransactionDataActionTypes = ActionType<
  typeof glTransactionDataActions
>

export type ReverseGlTransactionActionTypes = ActionType<
  typeof reverseTransactionActions
>

export type UpdateGlTransactionActionTypes = ActionType<
  typeof updateTransactionActions
>

export type UpdateModifiedGLTransactionActionTypes = ActionType<
  typeof updateModifiedTransactionActions
>

export type GwesTransCodeActionTypes = ActionType<
  typeof gwesTransCodesDataActions
>

export type PostGLPaymentsActionTypes = ActionType<typeof postGlPaymentsActions>
export type EditGLPaymentsActionTypes = ActionType<
  typeof editedGlTransactionDataActions
>

export type LedgerPaymentsActionType =
  | GlTransactionDataActionTypes
  | ReverseGlTransactionActionTypes
  | UpdateGlTransactionActionTypes
  | GwesTransCodeActionTypes
  | PostGLPaymentsActionTypes
  | EditGLPaymentsActionTypes
  | UpdateModifiedGLTransactionActionTypes
