import { IModuleDefinition } from '../types'
import { LoginContainer } from './containers/Login'

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/login',
    private: false,
    component: LoginContainer
  },
  name: 'Login'
}

export default moduleDefinition
