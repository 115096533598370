import { INfsProfile } from 'api/datahub'
import React, { useCallback, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { getRdotName, getRdotUsername } from 'store/user/selectors'
import { ClientInviteForm } from './ClientInviteForm'
import {
  IClientInvitePanelType,
  useClientInvitePanel
} from './store/clientInvitePanel'

export interface IClientInviteFormFields {
  ssn?: string
  phones?: NfsPhone[]
  email?: string
  cc?: string
  from?: string
  invitedBy?: string
  firstName?: string
  lastName?: string
  invitee?: string
  onBehalfOf?: string
}
export interface IClientInviteForm {
  invite: IClientInviteFormFields
}
export interface NfsPhone {
  Phone?: string
  telType?: string
  smscapable?: string
}

const getInvite = (
  cc: string,
  invitedBy: string,
  profile?: INfsProfile
): IClientInviteFormFields => ({
  cc,
  from: 'RCM-Onboarding@rockco.com',
  invitedBy,
  email: profile?.loginid || profile?.emailprimary || '',
  firstName: profile?.firstname || '',
  lastName: profile?.lastname || '',
  ssn: profile?.ssn?.slice(-4) || '',
  phones: JSON.parse(profile?.phones || '[]'),
  invitee: profile?.fullname || ''
})

export const InviteContent: React.FC<{
  panelType?: IClientInvitePanelType
  onSubmit: (form?: IClientInviteForm) => Promise<void>
  error?: Error
  nfsProfile?: INfsProfile
  isThirdParty?: boolean
  isFetchLoading?: boolean
}> = ({ panelType, onSubmit, nfsProfile, isThirdParty, isFetchLoading }) => {
  const { isOpen, setShowValidationError } = useClientInvitePanel()
  const currentUsername = useSelector(getRdotUsername)
  const currentUser = useSelector(getRdotName)

  const invite = useMemo(
    () =>
      getInvite(
        currentUsername || '',
        currentUser || '',
        isFetchLoading ? undefined : nfsProfile
      ),
    [currentUser, currentUsername, nfsProfile, isFetchLoading]
  )

  const methods = useForm<IClientInviteForm>()

  const { handleSubmit, reset } = methods

  useEffect(() => {
    reset({ invite })
  }, [invite, reset])

  const onError = useCallback(() => {
    setShowValidationError(true)
  }, [setShowValidationError])
  useEffect(() => {
    if (!isOpen) {
      return
    }
    setShowValidationError(false)
  }, [isOpen, setShowValidationError])

  return (
    <FormProvider {...methods}>
      <form id="inviteForm" onSubmit={handleSubmit(onSubmit, onError)}>
        <ClientInviteForm mode={panelType} isThirdParty={isThirdParty} />
      </form>
    </FormProvider>
  )
}
