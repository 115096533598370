import { lazy } from 'react'
import { combineReducers } from 'redux'
import { IModuleDefinition } from '../types'
import { advisoryModulesReducer, advisoryModulesSagas } from './modules'

const AdvisoryContainer = lazy(() => import('./AdvisoryContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/advisory/*',
    private: true,
    component: AdvisoryContainer
  },
  name: 'Advisory'
}

export default moduleDefinition

export const advisoryModuleReducer = combineReducers({
  modules: advisoryModulesReducer
})

export const advisoryModuleSagas = [...advisoryModulesSagas]
