import {
  IDataTableColumnDefinition,
  IDataTableSortBy
} from 'features/DataList/common/types'
import { keyBy, orderBy } from 'lodash'
import { flow } from 'lodash/fp'
import { createSelector } from 'reselect'
import { put, select, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { IFacetResult } from '../../../../../api/common.types'
import {
  IListsDateRangeFilter,
  IListsFacetFilter,
  IListsFilter
} from '../../../../../features/Lists/core/contracts/IListsFilter'
import { convertColumnTypeToFilterType } from '../../../../../features/OdataList/common/service'
import { DateRanges } from '../../../../../shared'
import { AppState } from '../../../../../store'
import { evaluateFacets, evaluateFilter } from '../common/service'
import { BdaSummaryTransaction } from '../common/types'
import { bdaContainerActions } from './bdaContainer'
import { getBdaDepartmentAllowancesItems } from './bdaDepartmentAllowances'
import { getBdaTransactionsItems } from './bdaTransactions'

export type BDATransactionListColumnName =
  | 'Date'
  | 'Department'
  | 'Category'
  | 'Category Detail'
  | 'Description'
  | 'Amount'
  | 'Rep / Pool'
  | 'Published'
  | 'Owner'

export interface IBDATransactionColumnDefinition
  extends IDataTableColumnDefinition {
  getValue: (
    item: BdaSummaryTransactionListItem
  ) => number | Date | string | undefined
  name: BDATransactionListColumnName
}

const defaultColumn: Partial<IBDATransactionColumnDefinition> = {
  filterable: true,
  sortable: true
}

export const bdaTransactionListColumns: IBDATransactionColumnDefinition[] = [
  {
    ...defaultColumn,
    name: 'Date',
    type: 'date',
    width: 100,
    getValue: ({ date }) => date
  },
  {
    ...defaultColumn,
    name: 'Department',
    type: 'string',
    facetable: true,
    getValue: ({ rcm_Department }) => rcm_Department?.cdm_name,
    width: 200
  },
  {
    ...defaultColumn,
    name: 'Category',
    type: 'string',
    facetable: true,
    getValue: ({ rcm_trackercategory }) => rcm_trackercategory,
    width: 130
  },
  {
    ...defaultColumn,
    name: 'Category Detail',
    type: 'string',
    getValue: ({ rcm_trackerdetail }) => rcm_trackerdetail,
    width: 130
  },
  {
    ...defaultColumn,
    name: 'Rep / Pool',
    type: 'string',
    getValue: ({ rcm_advisor }) => rcm_advisor,
    width: 130
  },
  {
    ...defaultColumn,
    name: 'Description',
    type: 'string',
    width: 300,
    getValue: ({ rcm_description }) => rcm_description
  },
  {
    ...defaultColumn,
    name: 'Amount',
    type: 'number',
    width: 200,
    getValue: ({ rcm_totalamount }) => rcm_totalamount
  },
  {
    ...defaultColumn,
    name: 'Published',
    type: 'string',
    width: 100,
    facetable: true,
    getValue: ({ isPublished }) => (isPublished ? 'Yes' : 'No')
  },
  {
    ...defaultColumn,
    name: 'Owner',
    type: 'string',
    width: 100,
    getValue: ({
      '_ownerid_value@OData.Community.Display.V1.FormattedValue': ownername
    }) => ownername
  }
]

const UPDATE_FILTER =
  '@modules/@advisory/@modules/@bda/@bdaTranactionList/UPDATE_FILTER'
const RESET_FILTERS =
  '@modules/@advisory/@modules/@bda/@bdaTranactionList/RESET_FILTERS'
const UPDATE_SORT =
  '@modules/@advisory/@modules/@bda/@bdaTranactionList/UPDATE_SORT'
const SET_DATE_RANGE =
  '@modules/@advisory/@modules/@bda/@bdaTranactionList/SET_DATE_RANGE'
const SET_CATEGORIES =
  '@modules/@advisory/@modules/@bda/@bdaTranactionList/SET_CATEGORIES'
const SET_DEPARTMENT =
  '@modules/@advisory/@modules/@bda/@bdaTranactionList/SET_DEPARTMENT'
const UPDATE_FACET =
  '@modules/@advisory/@modules/@bda/@bdaTranactionList/UPDATE_FACET'

export const bdaTransactionListActions = {
  setDateRange: createAction(SET_DATE_RANGE)<
    { from: Date; to: Date } | undefined
  >(),
  setCategories: createAction(SET_CATEGORIES)<string[] | undefined>(),
  setDepartment: createAction(SET_DEPARTMENT)<string | undefined>(),
  updateFilters: createAction(UPDATE_FILTER)<Record<string, IListsFilter>>(),
  resetFilters: createAction(RESET_FILTERS)(),
  updateSort: createAction(UPDATE_SORT)<IDataTableSortBy>(),
  updateFacet: createAction(UPDATE_FACET)<BDATransactionListColumnName>()
}

export type BdaTransactionListActionTypes = ActionType<
  typeof bdaTransactionListActions
>

export interface IBdaTransactionListState {
  filters: Record<BDATransactionListColumnName, IListsFilter>
  sortBy: IDataTableSortBy
}

const defaultFilters = () =>
  keyBy(
    bdaTransactionListColumns
      .filter((x) => x.filterable)
      .map((column) => ({
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        hasValue: false
      })),
    ({ id }) => id
  ) as Record<BDATransactionListColumnName, IListsFilter>

const initialState: IBdaTransactionListState = {
  filters: defaultFilters(),
  sortBy: {
    name: 'Date',
    direction: 'desc'
  }
}

export const bdaTransactionListReducer = createReducer<
  IBdaTransactionListState,
  BdaTransactionListActionTypes
>(initialState)
  .handleAction(bdaTransactionListActions.updateFilters, (state, action) => ({
    ...state,
    filters: { ...state.filters, ...action.payload }
  }))
  .handleAction(bdaTransactionListActions.updateSort, (state, action) => ({
    ...state,
    sortBy: action.payload
  }))
  .handleAction(bdaTransactionListActions.resetFilters, (state) => ({
    ...state,
    filters: defaultFilters()
  }))

export const getBdaTransactionListState = (state: AppState) =>
  state.modules.advisory.modules.bda.bdaTransactionList
export const getBdaTransactionListSortBy = flow(
  getBdaTransactionListState,
  ({ sortBy }) => sortBy
)
export const getBdaTransactionListFilters = flow(
  getBdaTransactionListState,
  ({ filters }) => filters
)

export type BdaSummaryTransactionListItem = BdaSummaryTransaction & {
  isPublished?: boolean
}
export const getBdaTransactionListItems = createSelector(
  [getBdaTransactionsItems, getBdaDepartmentAllowancesItems],
  (items, departments) => {
    if (!departments || !items) {
      return
    }
    const departmentLookup = keyBy(
      departments,
      ({ rcm_Department }) => rcm_Department?.cdm_departmentnumber || ''
    )

    return items.map((item): BdaSummaryTransactionListItem => {
      const departmentNumber = item?.rcm_Department?.cdm_departmentnumber
      const department = departmentNumber && departmentLookup[departmentNumber]
      return {
        ...item,
        isPublished:
          !!department &&
          !!item?._ownerid_value &&
          department?.ownerid?.ownerid === item?._ownerid_value
      }
    })
  }
)

const valueFns = bdaTransactionListColumns.reduce(
  (a, x) => ({ ...a, [x.name]: x.getValue }),
  {} as Record<string, IBDATransactionColumnDefinition['getValue']>
)

// TODO - this reevaluates when clicking on the column header
export const getFilteredAndSortedTransactionItems = createSelector(
  [
    getBdaTransactionListItems,
    getBdaTransactionListSortBy,
    getBdaTransactionListFilters
  ],
  (items, sortBy, filters) => {
    const columnLookup = keyBy(bdaTransactionListColumns, ({ name }) => name)
    const filteredItems = items?.filter((item) =>
      evaluateFilter(item, valueFns, filters)
    )

    return sortBy
      ? orderBy(
          filteredItems,
          columnLookup[sortBy.name].getValue,
          sortBy.direction
        )
      : filteredItems
  }
)

export const bdaTransactionListSagas = [
  () =>
    takeLatest(bdaContainerActions.setSelectedDepartments, function* () {
      yield put(bdaTransactionListActions.resetFilters())
    }),
  () =>
    takeLatest(
      bdaTransactionListActions.setDateRange,
      function* (
        action: ReturnType<typeof bdaTransactionListActions.setDateRange>
      ) {
        const filters: Record<BDATransactionListColumnName, IListsFilter> =
          yield select(getBdaTransactionListFilters)
        const { from, to } = action.payload || {}
        yield put(
          bdaTransactionListActions.updateFilters({
            Date: {
              ...filters.Date,
              range: action.payload && DateRanges.Custom,
              from,
              to,
              hasValue: !!action.payload
            } as IListsDateRangeFilter
          })
        )
      }
    ),
  () =>
    takeLatest(
      bdaTransactionListActions.setCategories,
      function* (
        action: ReturnType<typeof bdaTransactionListActions.setCategories>
      ) {
        const filters: Record<BDATransactionListColumnName, IListsFilter> =
          yield select(getBdaTransactionListFilters)

        yield put(
          bdaTransactionListActions.updateFilters({
            Category: {
              ...filters.Category,
              values: action.payload,
              hasValue: !!action.payload
            } as IListsFacetFilter
          })
        )
      }
    ),
  () =>
    takeLatest(
      bdaTransactionListActions.setDepartment,
      function* (
        action: ReturnType<typeof bdaTransactionListActions.setDepartment>
      ) {
        const filters: Record<BDATransactionListColumnName, IListsFilter> =
          yield select(getBdaTransactionListFilters)

        yield put(
          bdaTransactionListActions.updateFilters({
            Department: {
              ...filters.Department,
              values: [action.payload],
              hasValue: !!action.payload
            } as IListsFacetFilter
          })
        )
      }
    ),
  () =>
    takeLatest(
      bdaTransactionListActions.updateFacet,
      function* (
        action: ReturnType<typeof bdaTransactionListActions.updateFacet>
      ) {
        const columnLookup = keyBy(bdaTransactionListColumns, (x) => x.name)
        const filters = yield* select(getBdaTransactionListFilters)
        const filterLookup = keyBy(filters, (x) => x.name)
        const items = yield* select(getBdaTransactionListItems)

        const filteredItems = items?.filter((x) =>
          evaluateFilter(x, valueFns, {
            ...filters,
            [action.payload]: { hasValue: false }
          })
        )

        const facets = evaluateFacets(filteredItems || [], [
          columnLookup[action.payload]
        ])
        const filter = filterLookup[action.payload]
        const columnFacet = Object.entries(facets[action.payload] || {})
          .map(([value, count]): IFacetResult => ({ value, count }))
          .sort((a, b) => b.count - a.count)

        yield put(
          bdaTransactionListActions.updateFilters({
            [action.payload]: {
              ...filter,
              facets: columnFacet
            } as IListsFacetFilter
          })
        )
      }
    )
]
