import { MessageBarType } from '@fluentui/react'
import { createReducer } from 'typesafe-actions'
import {
  IPlanByProvider,
  IFetchPlanToDistributeCash,
  IPlanProvider,
  IProductPlan,
  IRetirementProductCashReceipt,
  IGetMasterLookupResponse,
  IProjectedAmount,
  IPlanAllocations,
  IFetchDocType,
  IUploadFundres,
  IPlanProviderDocs,
  IPlanProviderForExcel,
  IAltsCashReceiptTrendReport,
  IAltsAccrualReport,
  IOpenReceivablesReport,
  IAccrualReportDrilldown,
  IClassification,
  IPlanApprove, ////approval dashboard
  IApiResponse,
  IUploadManualAccrual
} from './../store/types'

import {
  closeMonthActions,
  deleteStatementActions,
  downloadFundDocActions,
  exportAccrualReportToExcelActions,
  exportPlansToExcelActions,
  fetchAccrualReport,
  fetchFeeTypesActions,
  fetchPlanProvidersActions,
  fetchPlanProvidersDocsActions,
  fetchPlansByProviderActions,
  fetchPlansToDistributeCashActions,
  fetchProductPlanAllocationActions,
  fetchProductPlansAction,
  fetchProjectedAmountByPlansActions,
  fetchProvidersforExcelActions,
  fetchRetirementPlanTransactionsAction,
  fetchUploadDocListActions,
  fetchUploadDocTypeActions,
  ProductActionTypes,
  setSelectedFeeType,
  setSelectedMonthYearActions,
  setSelectedPlan,
  updateCashReceiptToPlanActions,
  updatePlanPlanByProviderActions,
  updatePlanProviderActions,
  updatePlanProviderDocActions,
  updateProjectedAmountByPlansActions,
  uploadFundDocActions,
  viewStatementActions,
  fetchCashReceiptTrendReportActions,
  exportExcelCashReceiptTrendReportActions,
  fetchOpenReceivablesReportActions,
  fetchAccrualReportDrilldownListActions,
  fetchExistingSponsorNamesActions,
  exportTrendReportDrilldownToExcelActions,
  fetchClassificationTypesActions,
  fetchAIPlansApproveDashboardActions, ////approval dashboard
  handleApproverActionApprovalDashboard,
  fetchComparisonApproveDashboardActions,
  fetchCommittedActions,
  fetchDraftPlansActions,
  fetchWorkflowPlanHistoryApproveDashboardActions,
  fetchManualUploadActions,
  downloadManualAccrualActions,
  deleteManualAccrualActions,
  uploadManualAccrualActions,
  deleteFundDocActions
} from './actions'
import { RootObject } from './approvalDashboardComparisonTypes'
import { IworkflowHistory } from './workflowHistoryApprovalDashboardTypes'

interface IPlanByProviderState {
  isLoading: boolean
  planproviderId?: number
  error?: Error
  plans?: IPlanByProvider[]
}

export interface IProductCommonState {
  selectedPlan?: IProductPlan
  selectedFeeType?: string
  productPlans: {
    isLoading: boolean
    plans?: IProductPlan[]
    error?: Error
  }
  plansToDistributeCash?: {
    isLoading: boolean
    error?: Error
    plans?: IFetchPlanToDistributeCash[]
  }
  selectedMonthYear?: string
  productTransactions?: {
    isLoading: boolean
    revenues?: IRetirementProductCashReceipt[]
    cashreceipts?: IRetirementProductCashReceipt[]
    error?: Error
  }
  planProviders?: {
    isLoading: boolean
    error?: Error
    planProviders?: IPlanProvider[]
  }

  ////approval dashboard
  aiFundsProvider: {
    isLoading: boolean
    error?: Error
    aiFunds?: IPlanApprove[]
  }

  approverDashboard: {
    isLoading: true
    draftData?: IPlanByProvider
    commitedData?: IPlanByProvider
  }

  approverActionResponse: {
    status?: IApiResponse
  }

  comparisonData: {
    isLoading: boolean
    error?: Error
    data?: RootObject
  }

  workflowHistoryData: {
    isLoading: boolean
    error?: Error
    data?: IworkflowHistory[]
  }

  committedFundData: {
    isLoading: boolean
    error?: Error
    data?: IPlanByProvider[]
  }

  fundPlandData: {
    isLoading: boolean
    error?: Error
    data?: IPlanByProvider[]
  }

  planProvidersForExcelState?: {
    isLoading: boolean
    error?: Error
    planProvidersForExcel?: IPlanProviderForExcel[]
  }
  plansByProviderState?: IPlanByProviderState[]
  updatePlanByProviderState?: {
    inProgress: boolean
    error?: Error
    successMsg?: string
    status?: string
    funddetails?: IPlanByProvider
    isDisableButtons: boolean
  }
  masterLookup?: {
    isLoading: boolean
    error?: Error
    masterLookup?: IGetMasterLookupResponse
  }
  updateCashReceiptToPlan?: {
    inProgress: boolean
    error?: Error
  }
  exportPlan?: {
    inProgress: boolean
    error?: Error
  }
  exportPlanExcel?: {
    inProgress: boolean
    error?: Error
  }
  closeMonthState?: {
    inProgress: boolean
    error?: Error
    successMsg?: string
  }
  viewStatement?: {
    inProgress: boolean
    error?: Error
  }
  projectAmountByPlan?: {
    projectedAmounts?: IProjectedAmount[]
    isLoading: boolean
    error?: Error
  }
  updateByPlanId?: {
    inProgress: boolean
    error?: Error
  }
  planAllocations?: {
    isLoading: boolean
    error?: Error
    allocations?: IPlanAllocations
  }
  updatePlanProvider?: {
    inProgress: boolean
    error?: Error
  }
  uploadDocTypes?: {
    isLoading: boolean
    error?: Error
    docTypeList?: IFetchDocType[]
  }

  uploadDocList?: {
    isLoading: boolean
    error?: Error
    docList?: IFetchDocType[]
  }
  FunDocUpload?: {
    isLoading: boolean
    error?: Error
    funddocsres?: IUploadFundres
  }

  FunDocDelete?: {
    isLoading: boolean
    error?: Error
    deletedocsres?: IUploadFundres
  }

  classificationTypesState?: {
    isLoading: boolean
    error?: Error
    classification?: IClassification[]
  }
  FunDocDownload?: {
    isLoading: boolean
    error?: Error
  }
  planProviderDocsState?: {
    isLoading: boolean
    error?: Error
    planProviderDocs?: IPlanProviderDocs[]
  }
  ManualAccrualUploadState?: {
    isLoading: boolean
    error?: Error
    docsres?: IUploadManualAccrual
  }

  manualUploadState?: {
    inProgress: boolean
    errorCode?: string
    msg?: string
    messageType?: MessageBarType
    data?: any[]
  }
  manualAccrualDelete?: {
    isLoading: boolean
    error?: Error
    msg?: string
    messageType?: MessageBarType
  }
  manualAccrualDownload?: {
    isLoading: boolean
    error?: Error
    messageType?: MessageBarType
  }

  deleteStatementState?: {
    isLoading: boolean
    error?: Error
  }
  updatePlanProviderDoc?: {
    inProgress: boolean
    error?: Error
  }
  openReceivablesReport?: {
    isLoading?: boolean
    error?: Error
    plans?: IOpenReceivablesReport
  }
  accrualReport: {
    isLoading?: boolean
    accrual?: IAltsAccrualReport
    error?: Error
  }
  exportAccrual?: {
    inProgress: boolean
    error?: Error
  }
  cashReceiptTrendReport?: {
    isLoading?: boolean
    cashReceiptTrend?: IAltsCashReceiptTrendReport
    error?: Error
  }
  exportCashReceiptTrendReport?: {
    inProgress: boolean
    error?: Error
  }
  accrualReportDrilldownState?: {
    inProgress: boolean
    error?: Error
    drilldown?: IAccrualReportDrilldown[]
  }
  existingSponsorNamesState?: {
    inProgress: boolean
    error?: Error
    data?: string[]
  }
  trendReportDrilldownExcelExportState?: {
    isLoading: boolean
    error?: Error
  }
}

const initialState: IProductCommonState = {
  productPlans: {
    isLoading: true
  },
  accrualReport: {
    isLoading: true
  },
  openReceivablesReport: {
    isLoading: false
  },
  cashReceiptTrendReport: {
    isLoading: true
  },
  aiFundsProvider: {
    isLoading: true,
    aiFunds: []
  },
  approverDashboard: {
    isLoading: true,
    draftData: {},
    commitedData: {}
  },
  approverActionResponse: {
    status: undefined
  },
  comparisonData: {
    isLoading: true,
    data: undefined
  },
  workflowHistoryData: {
    isLoading: true,
    data: undefined
  },
  committedFundData: {
    isLoading: true,
    data: undefined
  },
  fundPlandData: {
    isLoading: true,
    data: undefined
  }
}
export const productCommonReducer = createReducer<
  IProductCommonState,
  ProductActionTypes
>(initialState)
  .handleAction(setSelectedPlan.selectedPlan, (state, action) => ({
    ...state,
    selectedPlan: action.payload || undefined
  }))
  .handleAction(fetchPlansToDistributeCashActions.request, (state) => ({
    ...state,
    plansToDistributeCash: {
      ...state.plansToDistributeCash,
      isLoading: true,
      plans: undefined,
      error: undefined
    }
  }))
  .handleAction(fetchPlansToDistributeCashActions.success, (state, action) => ({
    ...state,
    plansToDistributeCash: {
      ...state.plansToDistributeCash,
      isLoading: false,
      error: undefined,
      plans: action.payload
    }
  }))
  .handleAction(fetchPlansToDistributeCashActions.failure, (state, action) => ({
    ...state,
    plansToDistributeCash: {
      ...state.plansToDistributeCash,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(fetchPlansToDistributeCashActions.clear, (state) => ({
    ...state,
    plansToDistributeCash: undefined
  }))
  .handleAction(
    setSelectedMonthYearActions.selectedMonthYear,
    (state, action) => ({
      ...state,
      selectedMonthYear: action.payload || undefined,
      closeMonthState: undefined
    })
  )
  .handleAction(setSelectedFeeType.selectedFeeType, (state, action) => ({
    ...state,
    selectedFeeType: action.payload || undefined
  }))
  .handleAction(fetchRetirementPlanTransactionsAction.request, (state) => ({
    ...state,
    productTransactions: {
      ...state.productTransactions,
      isLoading: true,
      cashreceipts: undefined,
      revenues: undefined,
      error: undefined
    },
    closeMonthState: undefined
  }))
  .handleAction(
    fetchRetirementPlanTransactionsAction.success,
    (state, action) => ({
      ...state,
      productTransactions: {
        ...state.productTransactions,
        isLoading: false,
        error: undefined,
        cashreceipts: action.payload?.cashreceipts || [],
        revenues: action.payload?.revenue || []
      }
    })
  )
  .handleAction(
    fetchRetirementPlanTransactionsAction.failure,
    (state, action) => ({
      ...state,
      productTransactions: {
        ...state.productTransactions,
        isLoading: false,
        error: action.payload
      }
    })
  )
  .handleAction(fetchRetirementPlanTransactionsAction.clear, (state) => ({
    ...state,
    productTransactions: undefined,
    closeMonthState: undefined
  }))
  .handleAction(fetchProductPlansAction.request, (state) => ({
    ...state,
    productPlans: {
      ...state.productPlans,
      error: undefined,
      isLoading: true
    },
    closeMonthState: undefined
  }))
  .handleAction(fetchProductPlansAction.success, (state, action) => ({
    ...state,
    productPlans: {
      ...state.productPlans,
      plans: action.payload || [],
      error: undefined,
      isLoading: false
    }
  }))
  .handleAction(fetchProductPlansAction.failure, (state, action) => ({
    ...state,
    productPlans: {
      ...state.productPlans,
      error: action.payload,
      isLoading: false
    }
  }))
  .handleAction(fetchPlanProvidersActions.request, (state) => ({
    ...state,
    planProviders: {
      isLoading: true,
      error: undefined,
      planProviders: undefined
    },
    updatePlanByProviderState: undefined,
    FunDocUpload: undefined,
    FunDocDelete: undefined
  }))
  .handleAction(fetchPlanProvidersActions.success, (state, action) => ({
    ...state,
    planProviders: {
      isLoading: false,
      error: undefined,
      planProviders: action.payload
    }
  }))

  .handleAction(fetchPlanProvidersActions.failure, (state, action) => ({
    ...state,
    planProviders: {
      ...state.planProviders,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(fetchPlanProvidersActions.clear, (state) => ({
    ...state,
    planProviders: undefined
  }))
  .handleAction(fetchPlansByProviderActions.request, (state, action) => {
    const clonedPlanProviderState = state?.plansByProviderState
      ? [...state.plansByProviderState]
      : []
    const matchedItemIdx = clonedPlanProviderState?.findIndex(
      (x) => x.planproviderId === action.payload?.planProvider?.planproviderid
    )
    clonedPlanProviderState.splice(matchedItemIdx, 1, {
      isLoading: true,
      error: undefined,
      plans: undefined,
      planproviderId: action.payload?.planProvider?.planproviderid
    })
    return {
      ...state,
      plansByProviderState: clonedPlanProviderState
      // updatePlanByProviderState: undefined
    }
  })
  .handleAction(fetchPlansByProviderActions.success, (state, action) => {
    const clonedPlanProviderState = state?.plansByProviderState
      ? [...state.plansByProviderState]
      : []
    const matchedItemIdx = clonedPlanProviderState?.findIndex(
      (x) => x.planproviderId === action.payload.planproviderId
    )
    if (matchedItemIdx === -1) {
      clonedPlanProviderState.push({
        isLoading: false,
        error: undefined,
        plans: action.payload.plans,
        planproviderId: action.payload.planproviderId
      })
      return {
        ...state,
        plansByProviderState: clonedPlanProviderState,
        updatePlanByProviderState: undefined,
        FunDocUpload: undefined,
        FunDocDelete: undefined
      }
    } else {
      clonedPlanProviderState.splice(matchedItemIdx, 1, {
        isLoading: false,
        error: undefined,
        plans: action.payload.plans,
        planproviderId: action.payload.planproviderId
      })
      return {
        ...state,
        plansByProviderState: clonedPlanProviderState
      }
    }
  })
  .handleAction(fetchPlansByProviderActions.failure, (state, action) => {
    const clonedPlanProviderState = state?.plansByProviderState
      ? [...state.plansByProviderState]
      : []
    const matchedItemIdx = clonedPlanProviderState?.findIndex(
      (x) => x.planproviderId === action.payload.planproviderId
    )
    clonedPlanProviderState.splice(matchedItemIdx, 1, {
      isLoading: false,
      error: action.payload.error,
      plans: undefined,
      planproviderId: action.payload.planproviderId
    })
    return {
      ...state,
      plansByProviderState: clonedPlanProviderState
      // updatePlanByProviderState: undefined
    }
  })
  .handleAction(updatePlanProviderActions.request, (state) => ({
    ...state,
    updatePlanProvider: {
      inProgress: true,
      error: undefined
    },
    closeMonthState: undefined,
    updatePlanByProviderState: undefined
  }))
  .handleAction(updatePlanProviderActions.success, (state) => ({
    ...state,
    updatePlanProvider: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(updatePlanProviderActions.failure, (state, action) => ({
    ...state,
    updatePlanProvider: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(updatePlanPlanByProviderActions.request, (state) => ({
    ...state,
    updatePlanByProviderState: {
      inProgress: true,
      error: undefined,
      successMsg: undefined,
      status: undefined,
      funddetails: undefined,
      isDisableButtons: false
    },
    FunDocUpload: undefined,
    FunDocDelete: undefined
  }))
  .handleAction(updatePlanPlanByProviderActions.failure, (state, action) => ({
    ...state,
    updatePlanByProviderState: {
      inProgress: false,
      error: action.payload,
      successMsg: undefined,
      status: undefined,
      funddetails: undefined,
      isDisableButtons: false
    }
  }))
  .handleAction(updatePlanPlanByProviderActions.success, (state, action) => ({
    ...state,
    updatePlanByProviderState: {
      inProgress: false,
      error: undefined,
      successMsg: action.payload.message,
      status: action.payload.status,
      funddetails: action.payload.funddetails,
      isDisableButtons: action.payload.isDisableButtons
    }
  }))
  .handleAction(fetchFeeTypesActions.request, (state) => ({
    ...state,
    masterLookup: {
      isLoading: true,
      error: undefined,
      masterLookup: {
        plantype: null,
        planfeetype: null,
        paymentsource: null,
        feetype: [],
        lookup: []
      }
    }
  }))
  .handleAction(fetchFeeTypesActions.success, (state, action) => ({
    ...state,
    masterLookup: {
      isLoading: false,
      error: undefined,
      masterLookup: action.payload
    }
  }))
  .handleAction(fetchFeeTypesActions.failure, (state, action) => ({
    ...state,
    masterLookup: {
      isLoading: false,
      error: action.payload,
      masterLookup: {
        plantype: null,
        planfeetype: null,
        paymentsource: null,
        feetype: []
      }
    }
  }))
  .handleAction(updateCashReceiptToPlanActions.request, (state) => ({
    ...state,
    updateCashReceiptToPlan: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(updateCashReceiptToPlanActions.failure, (state, action) => ({
    ...state,
    updateCashReceiptToPlan: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(updateCashReceiptToPlanActions.success, (state) => ({
    ...state,
    updateCashReceiptToPlan: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(exportPlansToExcelActions.request, (state) => ({
    ...state,
    exportPlan: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(exportPlansToExcelActions.success, (state) => ({
    ...state,
    exportPlan: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(exportPlansToExcelActions.failure, (state, action) => ({
    ...state,
    exportPlan: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(closeMonthActions.request, (state) => ({
    ...state,
    closeMonthState: {
      inProgress: true,
      error: undefined,
      successMsg: undefined
    }
  }))
  .handleAction(closeMonthActions.failure, (state, action) => ({
    ...state,
    closeMonthState: {
      inProgress: false,
      error: action.payload,
      successMsg: undefined
    }
  }))
  .handleAction(closeMonthActions.success, (state, action) => ({
    ...state,
    closeMonthState: {
      inProgress: false,
      error: undefined,
      successMsg: action.payload || undefined
    }
  }))
  .handleAction(viewStatementActions.request, (state) => ({
    ...state,
    viewStatement: {
      inProgress: true,
      error: undefined
    },
    updatePlanByProviderState: undefined
  }))
  .handleAction(viewStatementActions.success, (state) => ({
    ...state,
    viewStatement: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(viewStatementActions.failure, (state, action) => ({
    ...state,
    viewStatement: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(fetchProjectedAmountByPlansActions.request, (state) => ({
    ...state,
    projectAmountByPlan: {
      ...state?.projectAmountByPlan,
      isLoading: true,
      error: undefined
    },
    closeMonthState: undefined
  }))
  .handleAction(
    fetchProjectedAmountByPlansActions.success,
    (state, action) => ({
      ...state,
      projectAmountByPlan: {
        projectedAmounts: action.payload || [],
        isLoading: false,
        error: undefined
      }
    })
  )
  .handleAction(
    fetchProjectedAmountByPlansActions.failure,
    (state, action) => ({
      ...state,
      projectAmountByPlan: {
        ...state.projectAmountByPlan,
        isLoading: false,
        error: action.payload
      }
    })
  )
  .handleAction(fetchProjectedAmountByPlansActions.clear, (state) => ({
    ...state,
    projectAmountByPlan: undefined,
    updateByPlanId: undefined
  }))
  .handleAction(updateProjectedAmountByPlansActions.request, (state) => ({
    ...state,
    updateByPlanId: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(updateProjectedAmountByPlansActions.success, (state) => ({
    ...state,
    updateByPlanId: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(
    updateProjectedAmountByPlansActions.failure,
    (state, action) => ({
      ...state,
      updateByPlanId: {
        inProgress: false,
        error: action.payload
      }
    })
  )
  .handleAction(fetchProductPlanAllocationActions.request, (state) => ({
    ...state,
    planAllocations: {
      ...state.planAllocations,
      isLoading: true,
      error: undefined
    },
    closeMonthState: undefined
  }))
  .handleAction(fetchProductPlanAllocationActions.success, (state, action) => ({
    ...state,
    planAllocations: {
      isLoading: false,
      error: undefined,
      allocations: action.payload
    }
  }))
  .handleAction(fetchProductPlanAllocationActions.failure, (state, action) => ({
    ...state,
    planAllocations: {
      ...state.planAllocations,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(fetchProductPlanAllocationActions.clear, (state) => ({
    ...state,
    planAllocations: undefined,
    closeMonthState: undefined
  }))
  .handleAction(fetchUploadDocTypeActions.request, (state) => ({
    ...state,
    uploadDocTypes: {
      ...state.uploadDocTypes,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(fetchUploadDocTypeActions.success, (state, action) => ({
    ...state,
    uploadDocTypes: {
      isLoading: false,
      error: undefined,
      docTypeList: action.payload
    }
  }))
  .handleAction(fetchUploadDocTypeActions.failure, (state, action) => ({
    ...state,
    uploadDocTypes: {
      ...state.uploadDocTypes,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(fetchUploadDocListActions.request, (state) => ({
    ...state,
    uploadDocList: {
      ...state.uploadDocList,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(fetchUploadDocListActions.success, (state, action) => ({
    ...state,
    uploadDocList: {
      isLoading: false,
      error: undefined,
      doclist: action.payload
    }
  }))
  .handleAction(fetchUploadDocListActions.failure, (state, action) => ({
    ...state,
    uploadDocList: {
      ...state.uploadDocTypes,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(uploadFundDocActions.request, (state) => ({
    ...state,
    FunDocUpload: {
      ...state.FunDocUpload,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(uploadFundDocActions.success, (state, action) => ({
    ...state,
    FunDocUpload: {
      isLoading: false,
      error: undefined,
      funddocsres: action.payload
    }
  }))
  .handleAction(uploadFundDocActions.failure, (state, action) => ({
    ...state,
    FunDocUpload: {
      ...state.FunDocUpload,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(downloadFundDocActions.request, (state) => ({
    ...state,
    FunDocDownload: {
      ...state.FunDocDownload,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(downloadFundDocActions.success, (state) => ({
    ...state,
    FunDocDownload: {
      isLoading: false,
      error: undefined
    }
  }))
  .handleAction(downloadFundDocActions.failure, (state, action) => ({
    ...state,
    FunDocDownload: {
      ...state.FunDocDownload,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(fetchPlanProvidersDocsActions.request, (state) => ({
    ...state,
    planProviderDocsState: {
      isLoading: true,
      error: undefined,
      planProviderDocs: undefined
    }
  }))
  .handleAction(fetchPlanProvidersDocsActions.success, (state, action) => ({
    ...state,
    planProviderDocsState: {
      isLoading: false,
      error: undefined,
      planProviderDocs: action.payload
    }
  }))
  .handleAction(fetchPlanProvidersDocsActions.failure, (state, action) => ({
    ...state,
    planProviderDocsState: {
      ...state.planProviderDocsState,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(deleteStatementActions.request, (state) => ({
    ...state,
    deleteStatementState: {
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(deleteStatementActions.success, (state) => ({
    ...state,
    deleteStatementState: {
      isLoading: false,
      error: undefined
    }
  }))
  .handleAction(deleteStatementActions.failure, (state, action) => ({
    ...state,
    deleteStatementState: {
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(fetchProvidersforExcelActions.request, (state) => ({
    ...state,
    planProvidersForExcelState: {
      ...state.planProvidersForExcelState,
      error: undefined,
      isLoading: true
    }
  }))
  .handleAction(fetchProvidersforExcelActions.success, (state, action) => ({
    ...state,
    planProvidersForExcelState: {
      isLoading: false,
      error: undefined,
      planProvidersForExcel: action.payload
    }
  }))
  .handleAction(fetchProvidersforExcelActions.failure, (state, action) => ({
    ...state,
    planProvidersForExcelState: {
      ...state.planProvidersForExcelState,
      error: action.payload,
      isLoading: false
    }
  }))
  .handleAction(updatePlanProviderDocActions.request, (state) => ({
    ...state,
    updatePlanProviderDoc: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(updatePlanProviderDocActions.success, (state) => ({
    ...state,
    updatePlanProviderDoc: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(updatePlanProviderDocActions.failure, (state, action) => ({
    ...state,
    updatePlanProviderDoc: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(fetchAccrualReport.request, (state) => ({
    ...state,
    accrualReport: {
      ...state.accrualReport,
      error: undefined,
      isLoading: true
    },
    closeMonthState: undefined
  }))
  .handleAction(fetchAccrualReport.success, (state, action) => ({
    ...state,
    accrualReport: {
      ...state.accrualReport,
      accrual: action.payload || undefined,
      error: undefined,
      isLoading: false
    }
  }))
  .handleAction(fetchAccrualReport.failure, (state, action) => ({
    ...state,
    accrualReport: {
      ...state.accrualReport,
      error: action.payload,
      isLoading: false
    }
  }))
  .handleAction(exportAccrualReportToExcelActions.request, (state) => ({
    ...state,
    exportAccrual: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(exportAccrualReportToExcelActions.success, (state) => ({
    ...state,
    exportAccrual: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(exportAccrualReportToExcelActions.failure, (state, action) => ({
    ...state,
    exportAccrual: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(fetchCashReceiptTrendReportActions.request, (state) => ({
    ...state,
    cashReceiptTrendReport: {
      ...state.cashReceiptTrendReport,
      error: undefined,
      isLoading: true
    },
    closeMonthState: undefined
  }))
  .handleAction(
    fetchCashReceiptTrendReportActions.success,
    (state, action) => ({
      ...state,
      cashReceiptTrendReport: {
        ...state.cashReceiptTrendReport,
        cashReceiptTrend: action.payload || undefined,
        error: undefined,
        isLoading: false
      }
    })
  )
  .handleAction(
    fetchCashReceiptTrendReportActions.failure,
    (state, action) => ({
      ...state,
      cashReceiptTrendReport: {
        ...state.cashReceiptTrendReport,
        error: action.payload,
        isLoading: false
      }
    })
  )
  .handleAction(exportExcelCashReceiptTrendReportActions.request, (state) => ({
    ...state,
    exportCashReceiptTrendReport: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(exportExcelCashReceiptTrendReportActions.success, (state) => ({
    ...state,
    exportCashReceiptTrendReport: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(
    exportExcelCashReceiptTrendReportActions.failure,
    (state, action) => ({
      ...state,
      exportCashReceiptTrendReport: {
        inProgress: false,
        error: action.payload
      }
    })
  )
  .handleAction(fetchOpenReceivablesReportActions.request, (state) => ({
    ...state,
    openReceivablesReport: {
      ...state.openReceivablesReport,
      error: undefined,
      isLoading: true
    },
    closeMonthState: undefined
  }))
  .handleAction(fetchOpenReceivablesReportActions.success, (state, action) => ({
    ...state,
    openReceivablesReport: {
      ...state.openReceivablesReport,
      plans: action.payload || undefined,
      error: undefined,
      isLoading: false
    }
  }))
  .handleAction(fetchOpenReceivablesReportActions.failure, (state, action) => ({
    ...state,
    openReceivablesReport: {
      ...state.openReceivablesReport,
      error: action.payload,
      isLoading: false
    }
  }))
  .handleAction(fetchAccrualReportDrilldownListActions.request, (state) => ({
    ...state,
    accrualReportDrilldownState: {
      inProgress: true,
      drilldown: undefined,
      error: undefined
    }
  }))
  .handleAction(
    fetchAccrualReportDrilldownListActions.success,
    (state, action) => ({
      ...state,
      accrualReportDrilldownState: {
        inProgress: false,
        drilldown: action.payload,
        error: undefined
      }
    })
  )

  .handleAction(
    fetchAccrualReportDrilldownListActions.failure,
    (state, action) => ({
      ...state,
      accrualReportDrilldownState: {
        inProgress: false,
        drilldown: undefined,
        error: action.payload
      }
    })
  )
  .handleAction(fetchAccrualReportDrilldownListActions.clear, (state) => ({
    ...state,
    accrualReportDrilldownState: undefined
  }))
  .handleAction(fetchExistingSponsorNamesActions.request, (state) => ({
    ...state,
    existingSponsorNamesState: {
      inProgress: true,
      data: undefined,
      error: undefined
    }
  }))
  .handleAction(fetchExistingSponsorNamesActions.success, (state, action) => ({
    ...state,
    existingSponsorNamesState: {
      inProgress: false,
      data: action.payload,
      error: undefined
    }
  }))

  .handleAction(fetchExistingSponsorNamesActions.failure, (state, action) => ({
    ...state,
    existingSponsorNamesState: {
      inProgress: false,
      data: undefined,
      error: action.payload
    }
  }))
  .handleAction(fetchExistingSponsorNamesActions.clear, (state) => ({
    ...state,
    existingSponsorNamesState: undefined
  }))
  .handleAction(exportTrendReportDrilldownToExcelActions.request, (state) => ({
    ...state,
    trendReportDrilldownExcelExportState: {
      error: undefined,
      isLoading: true
    }
  }))
  .handleAction(exportTrendReportDrilldownToExcelActions.success, (state) => ({
    ...state,
    trendReportDrilldownExcelExportState: {
      isLoading: false,
      error: undefined
    }
  }))
  .handleAction(
    exportTrendReportDrilldownToExcelActions.failure,
    (state, action) => ({
      ...state,
      trendReportDrilldownExcelExportState: {
        error: action.payload,
        isLoading: false
      }
    })
  )
  .handleAction(exportTrendReportDrilldownToExcelActions.clear, (state) => ({
    ...state,
    trendReportDrilldownExcelExportState: undefined
  }))
  .handleAction(fetchClassificationTypesActions.request, (state) => ({
    ...state,
    classificationTypesState: {
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(fetchClassificationTypesActions.success, (state, action) => ({
    ...state,
    classificationTypesState: {
      isLoading: false,
      error: undefined,
      classification: action.payload
    }
  }))
  .handleAction(fetchClassificationTypesActions.failure, (state) => ({
    ...state,
    classificationTypesState: {
      ...state.classificationTypesState,
      isLoading: false,
      error: undefined
    }
  }))
  ////approval dashboard handle actions
  .handleAction(fetchAIPlansApproveDashboardActions.request, (state) => ({
    ...state,
    aiFundsProvider: {
      ...state.aiFundsProvider,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(
    fetchAIPlansApproveDashboardActions.success,
    (state, action) => ({
      ...state,
      aiFundsProvider: {
        isLoading: false,
        error: undefined,
        aiFunds: action.payload.aiFunds
      }
    })
  )
  .handleAction(fetchAIPlansApproveDashboardActions.failure, (state) => ({
    ...state,
    aiFundsProvider: {
      ...state.aiFundsProvider,
      isLoading: false
    }
  }))

  .handleAction(
    handleApproverActionApprovalDashboard.success,
    (state, action) => ({
      ...state,
      approverActionResponse: {
        ...state.approverActionResponse,
        status: action.payload
      }
    })
  )

  .handleAction(handleApproverActionApprovalDashboard.clear, (state) => ({
    ...state,
    approverActionResponse: {
      ...state.approverActionResponse,
      status: undefined
    }
  }))

  .handleAction(fetchComparisonApproveDashboardActions.request, (state) => ({
    ...state,
    comparisonData: {
      ...state.comparisonData,
      isLoading: true
    }
  }))

  .handleAction(
    fetchComparisonApproveDashboardActions.success,
    (state, action) => ({
      ...state,
      comparisonData: {
        ...state.comparisonData,
        isLoading: false,
        data: action.payload
      }
    })
  )

  .handleAction(fetchCommittedActions.reset, (state) => ({
    ...state,
    committedFundData: {
      ...state.committedFundData,
      isLoading: true,
      data: undefined
    }
  }))

  .handleAction(fetchCommittedActions.request, (state) => ({
    ...state,
    committedFundData: {
      ...state.committedFundData,
      isLoading: true,
      data: undefined
    }
  }))

  .handleAction(fetchCommittedActions.success, (state, action) => ({
    ...state,
    committedFundData: {
      ...state.committedFundData,
      isLoading: false,
      data: action.payload.plans
    }
  }))

  .handleAction(fetchCommittedActions.failure, (state, action) => ({
    ...state,
    committedFundData: {
      ...state.committedFundData,
      isLoading: false,
      error: action.payload.error
    }
  }))

  .handleAction(fetchDraftPlansActions.reset, (state) => ({
    ...state,
    fundPlandData: {
      ...state.fundPlandData,
      isLoading: true,
      data: undefined
    }
  }))

  .handleAction(fetchDraftPlansActions.request, (state) => ({
    ...state,
    fundPlandData: {
      ...state.fundPlandData,
      isLoading: true,
      data: undefined
    }
  }))

  .handleAction(fetchDraftPlansActions.success, (state, action) => ({
    ...state,
    fundPlandData: {
      ...state.fundPlandData,
      isLoading: false,
      data: action.payload?.plans
    }
  }))

  .handleAction(fetchDraftPlansActions.failure, (state, action) => ({
    ...state,
    fundPlandData: {
      ...state.fundPlandData,
      isLoading: false,
      error: action.payload.error
    }
  }))

  .handleAction(
    fetchWorkflowPlanHistoryApproveDashboardActions.request,
    (state) => ({
      ...state,
      workflowHistoryData: {
        ...state.workflowHistoryData,
        isLoading: true,
        data: undefined
      }
    })
  )

  .handleAction(
    fetchWorkflowPlanHistoryApproveDashboardActions.success,
    (state, action) => ({
      ...state,
      workflowHistoryData: {
        ...state.workflowHistoryData,
        isLoading: false,
        data: action.payload?.workflowHistory
      }
    })
  )

  .handleAction(
    fetchWorkflowPlanHistoryApproveDashboardActions.failure,
    (state, action) => ({
      ...state,
      workflowHistoryData: {
        ...state.workflowHistoryData,
        isLoading: false,
        error: action.payload.error
      }
    })
  )
  .handleAction(fetchManualUploadActions.request, (state) => ({
    ...state,
    manualUploadState: {
      inProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(fetchManualUploadActions.success, (state, action) => ({
    ...state,
    manualUploadState: {
      inProgress: false,
      error: undefined,
      data: action.payload,
      messageType: MessageBarType.success
    }
  }))
  .handleAction(fetchManualUploadActions.failure, (state, action) => ({
    ...state,
    manualUploadState: {
      inProgress: false,
      error: action.payload,
      data: undefined,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(fetchManualUploadActions.clear, (state) => ({
    ...state,
    manualUploadState: undefined
  }))

  .handleAction(downloadManualAccrualActions.request, (state) => ({
    ...state,
    SPADownload: {
      ...state.manualAccrualDownload,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(downloadManualAccrualActions.success, (state) => ({
    ...state,
    SPADownload: {
      isLoading: false,
      error: undefined,
      messageType: MessageBarType.success
    }
  }))
  .handleAction(downloadManualAccrualActions.failure, (state, action) => ({
    ...state,
    SPADownload: {
      ...state.manualAccrualDownload,
      isLoading: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(deleteManualAccrualActions.request, (state) => ({
    ...state,
    SPADelete: {
      ...state.manualAccrualDelete,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(deleteManualAccrualActions.success, (state) => ({
    ...state,
    SPADelete: {
      isLoading: false,
      error: undefined,
      messageType: MessageBarType.success,
      msg: 'Successfully deleted attachment'
    }
  }))
  .handleAction(deleteManualAccrualActions.failure, (state, action) => ({
    ...state,
    SPADelete: {
      ...state.manualAccrualDelete,
      isLoading: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(deleteManualAccrualActions.clear, (state) => ({
    ...state,
    SPADelete: undefined
  }))

  .handleAction(uploadManualAccrualActions.success, (state, action) => ({
    ...state,
    ManualAccrualUploadState: {
      isLoading: false,
      error: undefined,
      docsres: action.payload
    }
  }))
  .handleAction(uploadManualAccrualActions.failure, (state, action) => ({
    ...state,
    ManualAccrualUploadState: {
      ...state.ManualAccrualUploadState,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(deleteFundDocActions.request, (state) => ({
    ...state,
    FunDocDelete: {
      ...state.FunDocDelete,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(deleteFundDocActions.success, (state, action) => ({
    ...state,
    FunDocDelete: {
      isLoading: false,
      error: undefined,
      deletedocsres: action.payload
    }
  }))
  .handleAction(deleteFundDocActions.failure, (state, action) => ({
    ...state,
    FunDocDelete: {
      ...state.FunDocDelete,
      isLoading: false,
      error: action.payload
    }
  }))
