import axios from 'axios'
import { IDragDropFile } from 'shared/components/DragDrop'
import { IApiOptions } from '../../../shared/contracts/IApiOptions'
import { IUploadReportResponse } from '../features/ShareReport/store/types'
import {
  IReportInfo,
  IReportRequest,
  IReportListRequest,
  IClientsInfo,
  IShareReportResponse,
  IUploadReportList
} from './../features/Dashboard/store/types'

const GenerateReportService = (
  options: IApiOptions,
  reportRequest: IReportRequest
) => {
  const url = `${options.apiRoot}/documentsapi/api/reports/GenerateReport`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
    accept: 'application/json'
  }
  return axios
    .post<IReportInfo>(url, reportRequest, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchReportListService = (
  options: IApiOptions,
  reportRequest: IReportListRequest
) => {
  const url = `${options.apiRoot}/documentsapi/api/reports/GetReportsList?ClientId=${reportRequest.client}&reportType=${reportRequest.reportType}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IReportInfo[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const RefreshReportService = (
  options: IApiOptions,
  reportRequest: IReportRequest
) => {
  const url = `${options.apiRoot}/documentsapi/api/Reports/RefreshStatus`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
    accept: 'application/json'
  }
  return axios
    .post<IReportInfo>(url, reportRequest, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const ViewReportService = (
  options: IApiOptions,
  reportRequest: IReportRequest
) => {
  const url = `${options.apiRoot}/documentsapi/api/Reports/GetReport`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
    accept: 'application/json'
  }
  return axios
    .post<unknown>(url, reportRequest, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const ShareReportService = (
  options: IApiOptions,
  reportRequest: IReportRequest
) => {
  const url = `${options.apiRoot}/documentsapi/api/Reports/ShareReportWithUser `

  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
    accept: 'application/json'
  }
  return axios
    .post<IShareReportResponse>(url, reportRequest, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const fetchClientListService = (options: IApiOptions, email: string) => {
  const url = `${options.apiRoot}/documentsapi/api/reports/GetReportClientList?useremail=${email}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .get<IClientsInfo[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const uploadReport = (
  options: IApiOptions,
  reportkey: string,
  file: IDragDropFile,
  asofdate?: string,
  sharewithuser?: string,
  onUploadProgress?: (
    progressEvent: ProgressEvent<EventTarget>,
    file: IDragDropFile
  ) => void
) => {
  const url = `${options.apiRoot}/documentsapi/api/Reports/UploadShareReportWithUser`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  const data = new FormData()
  data.append('filecontents', file.file)
  data.append('asofdate', asofdate || '')
  data.append('sharewithuser', sharewithuser || '')
  data.append('reportkey', reportkey)

  return axios
    .post<IUploadReportResponse>(url, data, {
      headers,
      cancelToken: options.cancelToken,
      onUploadProgress: onUploadProgress
        ? (progressEvent) => {
            onUploadProgress(progressEvent, file)
          }
        : undefined
    })
    .then((res) => res.data)
}

const fetchUploadReportService = (options: IApiOptions, client: string) => {
  const url = `${options.apiRoot}/documentsapi/api/Reports/GetSharedReportList?loginid=${client}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .get<IUploadReportList[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const viewUploadReportService = (
  req: { storagedocid: string },
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/documentsapi/api/Reports/DownloadReport?fromrdot=true&storagedocid=${req?.storagedocid}`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<any>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const deleteUploadReportService = (
  req: { storagedocid: string },
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/documentsapi/api/Filedata/DeleteDocument`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<string>(
      url,
      { clientstoragedocid: req.storagedocid },
      {
        headers,
        cancelToken: options.cancelToken
      }
    )
    .then((res) => res.data)
}

export {
  GenerateReportService,
  fetchReportListService,
  RefreshReportService,
  ViewReportService,
  fetchClientListService,
  ShareReportService,
  uploadReport,
  fetchUploadReportService,
  viewUploadReportService,
  deleteUploadReportService
}
