export const Divider = () => (
  <div>
    <hr
      css={{
        backgroundColor: 'gray',
        width: '1px',
        height: '12px',
        marginTop: '2px'
      }}
    />
  </div>
)
