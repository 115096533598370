import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAccount, RdotAccountCategoryCode } from 'api/account.types'
import { format, subDays } from 'date-fns'
import { fromPairs, intersection } from 'lodash'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { isNotNullOrEmpty, isNotNullOrFalse } from 'shared/guards'
import { AppState, useSelector } from 'store/shared'
import {
  selectIncludeClosedAccountsPreference,
  useLazyClientDashboardCommonPreferences
} from '../../hooks/useClientDashboardPreferences'
import { useLazyGetAccountsFromHouseholdIdQuery } from '../datahub'
import {
  IProfileApiContextAccount,
  ProfileApiAccountSourceTypeCode
} from '../profileApi/IProfileApiContext'
import { useLazyGetStaticConfig } from '../templateApi/templateApi'
import {
  selectAsOfDate,
  useRdot360SelectedAccountsApiContext
} from './apiContext'

export interface IRdot360UiContextState {
  selectedHouseholdId?: string
  selectedAccounts: string[]
}

export interface ISetSelectedHouseholdPayload {
  householdId: string
  selectionType?: 'account' | 'client' | 'household'
  selectionIds?: string[]
}

const initialState: IRdot360UiContextState = {
  selectedAccounts: []
}

const uiContextSlice = createSlice({
  name: '@features/@rdot360/@contextSlice',
  initialState,
  reducers: {
    setSelectedHouseholdId: (state, action: PayloadAction<string>) => {
      state.selectedHouseholdId = action.payload
    },
    setSelectedAccounts: (state, action: PayloadAction<string[]>) => {
      state.selectedAccounts = action.payload
    }
  }
})

export const {
  actions: rdot360ContextActions,
  reducer: rdot360ContextReducer
} = uiContextSlice

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.context

export const getSelectedHouseholdId = createSelector(
  rootSelector,
  (x) => x.selectedHouseholdId
)
export const getUiSelectedAccounts = createSelector(
  rootSelector,
  (x) => x.selectedAccounts
)

export const getAccountKey = (account?: IAccount) =>
  account?.accountkey || account?.id || ''

const getIsInternal = ({ CustodianCode }: IAccount = {}) =>
  CustodianCode === 'NFS'

export const getIsSBL = ({ accountkey }: IAccount = {}) =>
  accountkey?.toUpperCase().startsWith('TRISTATE')

const getIsAggregated = ({ RDOTAccountCategoryCode }: IAccount = {}) =>
  RDOTAccountCategoryCode === '03'

const getApiContextAccountType = (
  account: IAccount
): ProfileApiAccountSourceTypeCode => {
  if (getIsInternal(account)) {
    return 'Rockefeller'
  }

  if (getIsAggregated(account)) {
    return 'Aggregated'
  }

  if (getIsSBL(account)) {
    return 'SBL'
  }

  return 'LOA'
}

const mapToApiContextAccounts = (accounts: IAccount[]) =>
  accounts
    .map((x): IProfileApiContextAccount | false => {
      const key = getAccountKey(x)
      const { ClientAdvisorID, CustodyAccount, id, taxstatus } = x
      const isInternal = getIsInternal(x)
      const accountsourcetypecd = getApiContextAccountType(x)

      return {
        key,
        number: CustodyAccount || id,
        repcode: ClientAdvisorID,
        accountsourcetypecd,
        accountsourcetypecode: accountsourcetypecd,
        isInternal,
        taxstatus: taxstatus,
        type: isInternal ? 'internal' : undefined
      }
    })
    .filter(isNotNullOrFalse)

const defaultSelectionBuckets: Record<RdotAccountCategoryCode, boolean> = {
  '01': true,
  '02': true,
  '03': false,
  '04': false
}

export const useRdot360Context = () => {
  const dispatch = useDispatch()
  const { setPreferences, getPreferences } =
    useLazyClientDashboardCommonPreferences()
  const includeClosedAccounts =
    useSelector(selectIncludeClosedAccountsPreference) || false
  const selectedHouseholdId = useSelector(getSelectedHouseholdId)
  const [fetchHouseholdAccounts] = useLazyGetAccountsFromHouseholdIdQuery()
  const {
    error: selectedAccountsApiContextError,
    setContext: setApiContextSelectedAccounts,
    refreshServerContext: refreshServerContextSelectedAccounts,
    updateAsOf
  } = useRdot360SelectedAccountsApiContext()

  const triggerRetrieveStaticConfig = useLazyGetStaticConfig()

  const asOfDate = useSelector(selectAsOfDate)
  const setAsOfDate = useCallback(
    async (asOf?: string) => {
      await updateAsOf(asOf)
    },
    [updateAsOf]
  )

  const setUseCob = useCallback(
    async (useCob: boolean) => {
      const { data } = await triggerRetrieveStaticConfig(!useCob)
      const asOfDate =
        data?.ActivityBusinessDate?.env?.previousbusinessdate ||
        format(subDays(new Date(), 1), 'yyyy-MM-dd')
      await setAsOfDate(useCob ? asOfDate : undefined)
    },
    [setAsOfDate, triggerRetrieveStaticConfig]
  )

  const setSelectedAccounts = useCallback(
    async (ids: string[], newHouseholdId?: string) => {
      const householdId = newHouseholdId || selectedHouseholdId
      if (!householdId) {
        return
      }

      const { data: householdAccounts } = await fetchHouseholdAccounts(
        householdId,
        true
      )

      const householdAccountsLookup = fromPairs(
        householdAccounts?.flatMap((x) => [
          [x.id, x],
          [x.accountkey, x],
          [x.accountId, x]
        ]) || []
      )

      const commonPreferences = await getPreferences().catch(() => {
        return
      })

      const filteredAccounts = ids
        .map((id) => householdAccountsLookup[id])
        .filter(
          (account) =>
            account?.id &&
            (commonPreferences?.includeClosedAccounts ||
              account?.accountStatus === 'Open')
        )

      const newIds = filteredAccounts
        .map(({ id }) => id)
        .filter(isNotNullOrEmpty)
      const newKeys = filteredAccounts
        .map(getAccountKey)
        .filter(isNotNullOrEmpty)

      await setApiContextSelectedAccounts(
        householdId,
        mapToApiContextAccounts(householdAccounts || []),
        newKeys,
        asOfDate
      )
      dispatch(rdot360ContextActions.setSelectedAccounts(newIds))
    },
    [
      asOfDate,
      dispatch,
      fetchHouseholdAccounts,
      getPreferences,
      selectedHouseholdId,
      setApiContextSelectedAccounts
    ]
  )

  const setHousehold = useCallback(
    async ({
      householdId,
      selectionType,
      selectionIds
    }: ISetSelectedHouseholdPayload) => {
      dispatch(rdot360ContextActions.setSelectedAccounts([]))
      dispatch(rdot360ContextActions.setSelectedHouseholdId(householdId))
      const { data } = await fetchHouseholdAccounts(householdId, true)
      const initialAccounts = data?.filter((x) => {
        if (
          !selectionType ||
          !selectionIds?.length ||
          selectionType === 'household'
        ) {
          return true
        }
        if (selectionType === 'client') {
          const relatedPartyIds = (x?.RelatedParties || [])
            .map((x) => x.LegalEntityId)
            .concat(x.LegalEntityID || '')
          const matches = intersection(relatedPartyIds, selectionIds)
          return matches.length > 0
        }

        if (selectionType === 'account') {
          return x.id && selectionIds?.includes(x.id)
        }
      })
      const newIds = initialAccounts
        ?.filter((account) => {
          const { RDOTAccountCategoryCode } = account
          return (
            selectionType === 'account' ||
            (RDOTAccountCategoryCode &&
              defaultSelectionBuckets[RDOTAccountCategoryCode]) ||
            getIsSBL(account)
          )
        })
        ?.map(({ id }) => id)
        .filter(isNotNullOrEmpty)

      const allIds = data?.map(({ id }) => id).filter(isNotNullOrEmpty)

      await setSelectedAccounts(
        (newIds?.length ? newIds : allIds) || [],
        householdId
      )
    },
    [dispatch, fetchHouseholdAccounts, setSelectedAccounts]
  )

  const setIncludeClosedAccounts = useCallback(
    (newIncludeClosedAccounts: boolean) => {
      setPreferences({
        includeClosedAccounts: newIncludeClosedAccounts
      })
    },
    [setPreferences]
  )

  const selectedAccounts = useSelector(getUiSelectedAccounts)

  const refreshServerContext = useCallback(() => {
    return refreshServerContextSelectedAccounts()
  }, [refreshServerContextSelectedAccounts])

  return {
    setHousehold,
    selectedHouseholdId,
    setSelectedAccounts,
    selectedAccountIds: selectedAccounts,
    setIncludeClosedAccounts,
    includeClosedAccounts,
    refreshServerContext,
    contextError: selectedAccountsApiContextError,
    setUseCob,
    asOfDate,
    setAsOfDate
  }
}
