import {
  DetailsListLayoutMode,
  IColumn,
  IconButton,
  Stack,
  Text
} from '@fluentui/react'
import { get } from 'lodash'
import React from 'react'
import { IOrder, IOrderTradeDetail } from '../../api/order.types'
import { DataTable } from '../../shared/components/DataTable'
import { ConnectedMaskedText } from '../../shared/components/MaskedText'
import { exportDataToExcel } from '../../shared/xlsx'
import { DateCell } from '../Lists/core/components/DateCell'
import { NumberCell } from '../Lists/core/components/NumberCell'
import { TextCell } from '../Lists/core/components/TextCell'
import { USDCell } from '../Lists/core/components/USDCell'

export interface ITradeDetailProps {
  orderWithTrades: IOrder
}

export const TradeDetail: React.FC<ITradeDetailProps> = ({
  orderWithTrades
}) => {
  const { traderev } = orderWithTrades

  const columns: IColumn[] = [
    {
      key: 'tradeReferenceNo',
      name: 'Trade Ref. #',
      fieldName: 'tradeReferenceNo',
      minWidth: 120,
      isResizable: true,
      onRender: (item: IOrderTradeDetail) => (
        <TextCell>{item.tradeReferenceNo}</TextCell>
      )
    },
    {
      key: 'tradeDate',
      name: 'Trade Date',
      fieldName: 'tradeDate',
      headerClassName: 'align-right',
      className: 'align-right',
      minWidth: 100,
      isResizable: true,
      onRender: (item: IOrderTradeDetail) => (
        <DateCell value={item.tradeDate?.slice(0, -1)} />
      )
    },
    {
      key: 'settlementDate',
      name: 'Settlement Date',
      fieldName: 'settlementDate',
      minWidth: 100,
      isResizable: true,
      headerClassName: 'align-right',
      className: 'align-right',
      onRender: (item: IOrderTradeDetail) => (
        <DateCell value={item.settlementDate?.slice(0, -1)} />
      )
    },
    {
      key: 'quantity',
      name: 'Quantity',
      fieldName: 'quantity',
      minWidth: 100,
      isResizable: true,
      headerClassName: 'align-right',
      className: 'align-right',
      onRender: (item: IOrderTradeDetail) => (
        <NumberCell value={item.quantity} />
      )
    },
    {
      key: 'price',
      name: 'Price',
      fieldName: 'price',
      minWidth: 80,
      isResizable: true,
      headerClassName: 'align-right',
      className: 'align-right',
      onRender: (item: IOrderTradeDetail) => (
        <USDCell value={item.price} fractionDigits={4} />
      )
    },
    {
      key: 'principal',
      name: 'Value',
      fieldName: 'principal',
      minWidth: 100,
      isResizable: true,
      headerClassName: 'align-right',
      className: 'align-right',
      onRender: (item: IOrderTradeDetail) => <USDCell value={item.principal} />
    },
    // amount charged to client, includes commission/fees
    // {
    //   key: 'NET',
    //   name: 'Client Paid',
    //   fieldName: 'NET',
    //   minWidth: 100,
    //   headerClassName: 'align-right',
    //   className: 'align-right',
    //   onRender: (item: IOrderTradeDetail) => <USDCell value={item.NET} />
    // },
    {
      key: 'revenueCommissionAmount',
      name: 'Commission',
      fieldName: 'revenueCommissionAmount',
      minWidth: 100,
      isResizable: true,
      headerClassName: 'align-right',
      className: 'align-right',
      onRender: (item: IOrderTradeDetail) => (
        <USDCell value={item.revenueCommissionAmount || 0} />
      )
    },
    {
      key: 'revenueConcessionAmount',
      name: 'Concession',
      fieldName: 'revenueConcessionAmount',
      minWidth: 100,
      headerClassName: 'align-right',
      className: 'align-right',
      onRender: (item: IOrderTradeDetail) => (
        <USDCell value={item.revenueConcessionAmount || 0} />
      )
    },
    {
      key: 'cancel',
      name: 'Cancel Code',
      fieldName: 'cancelcode',
      minWidth: 100,
      isResizable: true,
      onRender: (item: IOrderTradeDetail) => (
        <TextCell>{item.cancelcode}</TextCell>
      )
    }
  ]

  const exportToExcel = () => {
    const filename = 'Trade_Detail.xlsx'
    const wsName = 'Trade Detail'

    const data = traderev?.map((trade) => {
      return columns.map((column): unknown =>
        column.fieldName ? get(trade, column.fieldName) : ''
      )
    })

    data?.unshift(columns.map((x) => x.name))

    exportDataToExcel(
      { sheets: [{ name: wsName, data: data || [] }] },
      filename
    )
  }

  return (
    <Stack tokens={{ padding: 25, childrenGap: 10 }}>
      <h3>TRADE DETAIL</h3>
      <Stack horizontal={true} tokens={{ childrenGap: 20 }}>
        <Stack.Item>
          <IconButton
            iconProps={{ iconName: 'Download' }}
            title="Export to Excel"
            onClick={exportToExcel}
          />
        </Stack.Item>
        <Stack>
          <Text>
            <b>Order Ref #</b>
          </Text>
          <Text>{orderWithTrades.orderRefNo}</Text>
        </Stack>
        <Stack>
          <Text>
            <b>Account</b>
          </Text>
          <ConnectedMaskedText text={orderWithTrades.accountNumber} />
        </Stack>
        <Stack>
          <Text>
            <b>Symbol</b>
          </Text>
          <Text>{orderWithTrades.Symbol}</Text>
        </Stack>
        <Stack>
          <Text>
            <b>CUSIP</b>
          </Text>
          <Text>{orderWithTrades.CUSIP}</Text>
        </Stack>
        <Stack>
          <Text>
            <b>Description</b>
          </Text>
          <Text>{orderWithTrades.description}</Text>
        </Stack>
      </Stack>

      <DataTable
        items={traderev || []}
        columns={columns}
        layoutMode={DetailsListLayoutMode.justified}
        stickyHeaderOffset={0}
      />
    </Stack>
  )
}
