import { ActionType, createAction } from 'typesafe-actions'
import { ITrustee } from './types'

export const FETCH_TRUSTEES = '@modules/@payments/FETCH_TRUSTEES'
export const FETCH_TRUSTEES_SUCCESS =
  '@modules/@payments/FETCH_TRUSTEES_SUCCESS'
export const FETCH_TRUSTEES_FAILURE =
  '@modules/@payments/FETCH_TRUSTEES_FAILURE'

export const trusteesDataActions = {
  request: createAction(FETCH_TRUSTEES)(),
  success: createAction(FETCH_TRUSTEES_SUCCESS)<ITrustee[]>(),
  failure: createAction(FETCH_TRUSTEES_FAILURE)<Error>()
}

export type TrusteesDataActions = ActionType<typeof trusteesDataActions>

export type TrusteesActionType = TrusteesDataActions
