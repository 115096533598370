import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { EntityType } from 'api/performance.types'
import { intersectionWith, keyBy } from 'lodash'
import { useMemo } from 'react'
import { usePerformanceState } from '../../features/Performance/store'
import { useRdot360_getPerformanceQuery } from '../datahub'
import { useRdot360Context } from './rdot360Context'
import { useRdot360AccountContext } from './useRdot360AccountContext'

export const useRdot360PerformanceContext = () => {
  const { selectedHouseholdId } = useRdot360Context()
  const skip = !selectedHouseholdId
  const { isSingleAccount } = usePerformanceState()
  const {
    data: apiData,
    isUninitialized,
    isFetching,
    isError,
    error
  } = useRdot360_getPerformanceQuery(skip ? skipToken : selectedHouseholdId)
  const { selectedAccountKeys, accountLookupByAccountIdOrKey } =
    useRdot360AccountContext()

  const data = useMemo(
    () => (skip || isError || !apiData ? undefined : apiData),
    [skip, isError, apiData]
  )

  const isGroupDataAvailable = useMemo(
    () => data?.some((item) => item.EntityType === EntityType.group),
    [data]
  )

  const selectedEntityType = useMemo(
    () =>
      isSingleAccount || (data?.length && !isGroupDataAvailable)
        ? EntityType.singleAccount
        : EntityType.group,
    [data?.length, isGroupDataAvailable, isSingleAccount]
  )

  const selectedEntityTypeIsSingleAccount =
    selectedEntityType === EntityType.singleAccount

  const filteredData = useMemo(() => {
    const performanceDataLookupByName = keyBy(
      data,
      ({ Name, EntityType: entityType, Account }) =>
        entityType !== EntityType.singleAccount ? Name : Account[0]
    )

    return (
      data
        ?.filter((item) => item.EntityType === selectedEntityType)
        .map((item) => {
          const accountsData = item.Account.map((account) => {
            const nicknameAccount = accountLookupByAccountIdOrKey[account] || {}
            const performanceItem = performanceDataLookupByName[account]
            return {
              ...performanceItem,
              Account: performanceItem?.Account || [account],
              groupName: item.Name,
              Name: account,
              preferredNickname: nicknameAccount.preferredNickname,
              CustodyAccount: nicknameAccount.CustodyAccount,
              registrationtype: nicknameAccount.registrationtype,
              registrationDesc: nicknameAccount.registrationDesc
            }
          })

          const nicknameAccount = accountLookupByAccountIdOrKey[item.Id] ?? {}

          return {
            ...item,
            preferredNickname: nicknameAccount.preferredNickname,
            CustodyAccount: nicknameAccount.CustodyAccount,
            registrationtype: nicknameAccount.registrationtype,
            registrationDesc: nicknameAccount.registrationDesc,
            accountsData
          }
        }) ?? []
    )
  }, [accountLookupByAccountIdOrKey, data, selectedEntityType])

  const performanceData = useMemo(() => {
    if (skip || isError) {
      return []
    }

    if (!selectedEntityTypeIsSingleAccount || !selectedAccountKeys?.length) {
      return filteredData
    }

    return intersectionWith(
      filteredData,
      selectedAccountKeys,
      (a, b) => a.Account[0] === b
    )
  }, [
    filteredData,
    isError,
    selectedAccountKeys,
    selectedEntityTypeIsSingleAccount,
    skip
  ])

  return {
    isGroupDataAvailable,
    selectedEntityType,
    selectedEntityTypeIsSingleAccount,
    performanceData,
    isUninitialized,
    isFetching,
    isError,
    error
  }
}
