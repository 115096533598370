import { getEnvironment } from './environment'
import { history } from './history'

const init = async () => {
  const [environment, aiImport, aiReactImport] = await Promise.all([
    getEnvironment(),
    import('@microsoft/applicationinsights-web'),
    import('@microsoft/applicationinsights-react-js')
  ])

  const reactPlugin = new aiReactImport.ReactPlugin()

  const {
    instrumentationKey,
    maxBatchInterval,
    disableTelemetry,
    maxBatchSizeInBytes
  } = environment.telemetry

  const appInsights = new aiImport.ApplicationInsights({
    config: {
      instrumentationKey,
      maxBatchInterval: maxBatchInterval || 15000,
      maxBatchSizeInBytes: maxBatchSizeInBytes || 10000,
      disableFetchTracking: false,
      disableTelemetry: disableTelemetry || false,
      extensions: [reactPlugin as any],
      extensionConfig: {
        [reactPlugin.identifier]: {
          history: history
        }
      }
    }
  })

  appInsights.loadAppInsights()
  appInsights.addTelemetryInitializer((item) => {
    const base = item.baseData
    if (!base) {
      return
    }

    const {
      REACT_APP_BUILD_ID,
      REACT_APP_BUILD_SOURCE_BRANCH,
      REACT_APP_BUILD_SOURCE_VERSION
    } = process.env

    if (base.name && base.name.length > 512) {
      base.name = base.name.substr(0, 512)
    }

    const properties: { [key: string]: any } = {
      ...(base.properties || {}),
      ...(environment?.telemetry?.properties || {}),
      build: REACT_APP_BUILD_ID,
      sourceBranch: REACT_APP_BUILD_SOURCE_BRANCH,
      sourceVersion: REACT_APP_BUILD_SOURCE_VERSION
    }

    base.properties = properties
  })

  return { appInsights, aiImport, aiReactImport, reactPlugin }
}

const promise = init()

const getApplicationInsights = () =>
  promise.then(({ appInsights }) => appInsights)

export const setAuthenticatedUserContext = async (
  authenticatedUserId: string,
  accountId?: string
) =>
  (await getApplicationInsights()).setAuthenticatedUserContext(
    authenticatedUserId,
    accountId
  )

export const trackException = (
  error: Error,
  properties?: { [key: string]: any }
) =>
  promise.then(({ appInsights, aiImport }) =>
    appInsights.trackException({
      error,
      severityLevel: aiImport.SeverityLevel.Error,
      properties
    })
  )

export const trackTrace = (
  message: string,
  properties?: { [key: string]: any }
) =>
  promise.then(({ appInsights, aiImport }) =>
    appInsights.trackTrace({
      message,
      severityLevel: aiImport.SeverityLevel.Information,
      properties
    })
  )

export const trackEvent = async (
  name: string,
  properties?: { [key: string]: any }
) => (await getApplicationInsights()).trackEvent({ name, properties })
