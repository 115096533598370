import { DetailsListLayoutMode } from '@fluentui/react'
import type { IAltsAdvisorRevenueDetail } from 'api/datahub'
import { useDomainStore } from 'features/Domain/store/domain'
import { constants } from 'modules/Advisory/shared/theme'
import {
  IOdataDataListCellRenderProps,
  OdataList
} from 'modules/AIRevenue/components/tadbleWithMenu/OdataList'
import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getRevenueDashboardDateRange,
  getRevenueDashboardFeeType
} from '../../store/dashboard'
import { CellComponents } from './column/Cells'
import type { AltsAdvisorRevenueDetailColumnName } from './column/columnNameTypes'
import { altsAdvRevDetExcelExportActions } from './export/excelExportActionsAndSagas'
import { useAdvisorIdFilter, useFeeTypeFilter } from './hooks'
import {
  actions as altsAdvisorRevenueDetailActions,
  selectors as altsAdvisorRevenueDetailSelectors
} from './store'
import '../../../../style/index.css'

const altsAdvisorRevenueDetailCell: React.FC<
  IOdataDataListCellRenderProps<IAltsAdvisorRevenueDetail>
> = memo(({ item, column }) => {
  const Component =
    CellComponents[column.name as AltsAdvisorRevenueDetailColumnName]
  return Component ? <Component item={item} column={column} /> : null
})

export const AltsAdvisorRevenueDetail: React.FC = () => {
  const { facetActions, uiActions } = altsAdvisorRevenueDetailActions
  const { facetSelectors, uiSelectors } = altsAdvisorRevenueDetailSelectors
  const dispatch = useDispatch()
  const dateRange = useSelector(getRevenueDashboardDateRange)
  const feeType = useSelector(getRevenueDashboardFeeType)
  const { selectedAccountRepIds } = useDomainStore()

  const exportToExcel = useCallback(() => {
    dispatch(
      altsAdvRevDetExcelExportActions.request({
        dateRange: dateRange,
        feeType: feeType,
        repCodes: selectedAccountRepIds?.includes('000')
          ? 'All'
          : selectedAccountRepIds?.join('|') || ''
      })
    )
  }, [dateRange, dispatch, feeType, selectedAccountRepIds])

  useAdvisorIdFilter(uiActions, uiSelectors)

  useFeeTypeFilter({ uiActions })

  return (
    <>
      <div className="advisorRevenueDetails">
        <OdataList
          actions={uiActions}
          selectors={uiSelectors}
          facetActions={facetActions}
          facetSelectors={facetSelectors}
          onRenderCell={altsAdvisorRevenueDetailCell}
          layoutMode={DetailsListLayoutMode.justified}
          onExport={exportToExcel}
          stickyHeaderOffset={constants.headerHeight}
        />
      </div>
    </>
  )
}
