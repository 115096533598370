import { MessageBarType } from '@fluentui/react'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import { pushNotification } from 'features/Notifications'
import { downloadUrlAsFile } from 'shared/downloads'
import { isNotNullOrUndefined } from 'shared/guards'
import { AppState } from 'store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from 'store/shared/asyncStore'
import { getAllPagedOdataApiResults } from 'store/shared/sagas/odata'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { fetchActivity } from '../store/api'
import { selectors } from '../store/store'
import { ActivitiesListExportWorker } from './worker'

export const activitiesListExportActions = createAsyncAction(
  '@features/@lists/@activities/EXPORT_REQUEST',
  '@features/@lists/@activities/EXPORT_SUCCESS',
  '@features/@lists/@activities/EXPORT_FAILURE'
)<undefined, undefined, Error>()

export const activitiesListExportReducer = createAsyncReducer(
  activitiesListExportActions
)

const rootSelector = (state: AppState) => state.features.lists.activity.export

export const { getError, getIsLoading } = createAsyncSelectors(rootSelector)

const onExport = function* () {
  try {
    const request = yield* select(selectors.uiSelectors.getOdataRequest)
    const results = yield* call(() =>
      getAllPagedOdataApiResults(request, fetchActivity)
    )
    const resultsMappedFiltered = results
      ?.flatMap((x) => x.result.value)
      ?.filter(isNotNullOrUndefined)
    const columnNames = yield* select(selectors.uiSelectors.getSelectedColumns)

    const worker = new Worker(new URL('./worker.ts', import.meta.url))
    const { createAndDownloadActivitiesListExport } =
      wrap<ActivitiesListExportWorker>(worker)

    const result = yield* call(
      createAndDownloadActivitiesListExport,
      resultsMappedFiltered,
      columnNames || []
    )
    const filename = `Activities ${format(new Date(), 'MM-dd-yyyy')}.xlsx`

    downloadUrlAsFile(result, filename)

    yield put(activitiesListExportActions.success())
  } catch (e: unknown) {
    yield put(activitiesListExportActions.failure(e as Error))
  }
}

const onFailure = function* (
  action: ReturnType<typeof activitiesListExportActions.failure>
) {
  yield call(pushNotification, {
    message: `Activities Export failed: ${action.payload.message}`,
    type: MessageBarType.error
  })
}

export const activitiesListExportSagas = [
  () => takeLatest(activitiesListExportActions.request, onExport),
  () => takeLatest(activitiesListExportActions.failure, onFailure)
]
