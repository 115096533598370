import { keyBy } from 'lodash'
import { createSelector } from 'reselect'
import { IActivity } from '../../../api/dynamics'
import { AppState } from '../../../store'
import { IDataListColumnDefinition } from '../../DataList/contracts/IDataListColumnDefinition'
import { convertColumnTypeToFilterType } from '../../DataList/service'
import { createDataListStore } from '../../DataList/store'
import { IListsFilter } from '../../Lists/core/contracts/IListsFilter'
import {
  getClientActivityFetchResult,
  getIsClientActivityFetchLoading
} from './clientActivityFetch'

export type ActivityAndNotesListColumnName =
  | 'Type'
  | 'Subject/Title'
  | 'Description'
  | 'Owner'
  | 'Due Date'
  | 'Date Created'
  | 'Modified By'
  | 'Last Updated'
  | 'Activity Status'
  | 'Attachments'

interface IActivityAndNotesListColumnDefinition
  extends IDataListColumnDefinition<IActivity> {
  name: ActivityAndNotesListColumnName
}

const defaultColumn: Partial<IActivityAndNotesListColumnDefinition> = {
  filterable: true,
  sortable: true
}

const activityAndNotesListColumns: IActivityAndNotesListColumnDefinition[] = [
  {
    ...defaultColumn,
    name: 'Type',
    type: 'string',
    getValue: (activity) =>
      activity?.['activitytypecode@OData.Community.Display.V1.FormattedValue'],
    width: 75,
    facetable: true
  },
  {
    ...defaultColumn,
    name: 'Subject/Title',
    type: 'string',
    getValue: (activity) => activity.subject,
    width: 225
  },
  {
    ...defaultColumn,
    name: 'Description',
    type: 'string',
    getValue: (activity) => activity.description,
    width: 300
  },
  {
    ...defaultColumn,
    name: 'Owner',
    type: 'string',
    getValue: (activity) =>
      activity?.['_ownerid_value@OData.Community.Display.V1.FormattedValue'],
    width: 100
  },
  {
    ...defaultColumn,
    name: 'Due Date',
    type: 'date',
    getValue: (activity) =>
      activity?.['scheduledend@OData.Community.Display.V1.FormattedValue'] &&
      new Date(
        activity?.['scheduledend@OData.Community.Display.V1.FormattedValue']
      ),
    width: 75
  },
  {
    ...defaultColumn,
    name: 'Date Created',
    type: 'date',
    getValue: (activity) =>
      activity?.['createdon@OData.Community.Display.V1.FormattedValue'] &&
      new Date(
        activity?.['createdon@OData.Community.Display.V1.FormattedValue']
      ),
    width: 75
  },
  {
    ...defaultColumn,
    name: 'Modified By',
    type: 'string',
    getValue: (activity) =>
      `${activity?.['_modifiedby_value@OData.Community.Display.V1.FormattedValue']}`,
    width: 100
  },
  {
    ...defaultColumn,
    name: 'Last Updated',
    type: 'date',
    getValue: (activity) =>
      activity?.['modifiedon@OData.Community.Display.V1.FormattedValue'] &&
      new Date(
        activity?.['modifiedon@OData.Community.Display.V1.FormattedValue']
      ),
    width: 75
  },
  {
    ...defaultColumn,
    name: 'Activity Status',
    type: 'string',
    getValue: (activity) =>
      activity?.['statecode@OData.Community.Display.V1.FormattedValue'] || '',
    width: 75,
    facetable: true
  },
  {
    ...defaultColumn,
    name: 'Attachments',
    type: 'string',
    getValue: (activity) =>
      `${activity.activity_pointer_activity_mime_attachment}`,
    width: 150
  }
]

const rootSelector = (state: AppState) =>
  state.features.clients.activityAndNotesList

export const getActivityAndNotesListItems = createSelector(
  [getClientActivityFetchResult, getIsClientActivityFetchLoading],
  (nameAndActivity, loading) => {
    if (!nameAndActivity || loading) {
      return []
    }
    return nameAndActivity.activity
  }
)

export const getName = createSelector(
  [getClientActivityFetchResult, getIsClientActivityFetchLoading],
  (nameAndActivity, loading) => {
    if (!nameAndActivity || loading) {
      return []
    }
    return nameAndActivity.name
  }
)

const filters = keyBy(
  activityAndNotesListColumns
    .filter((x) => x.filterable)
    .map(
      (column): IListsFilter => ({
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        hasValue: false
      })
    ),
  ({ id }) => id
)

const {
  actions: activityAndNotesListActions,
  reducer: activityAndNotesListReducer,
  selectors: activityAndNotesListSelectors,
  sagas: activityAndNotesListSagas
} = createDataListStore({
  initialState: {
    columns: activityAndNotesListColumns,
    filters,
    sortBy: { name: 'Last Updated', direction: 'desc' }
  },
  itemsSelector: getActivityAndNotesListItems,
  prefix: '@features/@clients',
  rootSelector
})

export {
  activityAndNotesListActions,
  activityAndNotesListSelectors,
  activityAndNotesListReducer,
  activityAndNotesListSagas
}
