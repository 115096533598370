import { MessageBarType } from '@fluentui/react'
import axios from 'axios'
import { call, cancelled, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { IHurdleStatus, updateStatus } from '../../api/datahub'
import { AppState } from '../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../store/shared/sagas'
import { pushNotification } from '../Notifications'

export const updateStatusActions = createAsyncAction(
  '@features/@hurdles/@updateStatus/REQUEST',
  '@features/@hurdles/@updateStatus/SUCCESS',
  '@features/@hurdles/@updateStatus/FAILURE'
)<
  { id: number; status: IHurdleStatus },
  { id: number; status: IHurdleStatus },
  Error
>()

export const updateStatusReducer = createAsyncReducer(updateStatusActions)

const rootSelector = (state: AppState) => state.features.hurdles

export const {
  getError: getUpdateStatusError,
  getIsLoading: getIsUpdateStatusLoading,
  getResult: getUpdateStatusResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof updateStatusActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  try {
    const apiOptions = yield* call(getRockefellerApiOptions)

    if (!action.payload) {
      return
    }

    const newItem = yield* call(
      updateStatus,
      apiOptions,
      action.payload.id,
      action.payload.status
    )
    if (!newItem) {
      throw new Error('Failed to update status')
    }

    yield put(updateStatusActions.success(action.payload))
  } catch (e: unknown) {
    const error = e as Error
    yield call(pushNotification, {
      message: error?.message,
      type: MessageBarType.error
    })
    yield put(updateStatusActions.failure(e as Error))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const updateStatusSagas = [
  () => takeLatest(updateStatusActions.request, onRequest)
]
