import { AppState } from '../../../../../store'

export const getIsMatrixInfoLoaded = (state: AppState) =>
  state.modules.payments.dashboard.isMatrixInfoLoaded

export const getMatrixInfoData = (state: AppState) =>
  state.modules.payments.dashboard.matrixdata

export const getPaymentOptions = (state: AppState) =>
  state.modules.payments.dashboard.paymentOptions

export const getActiveClient = (state: AppState) =>
  state.modules.payments.dashboard.activeClient

export const getpilotFeature = (state: AppState) =>
  state.modules.payments.dashboard.pilotFeatureList?.pilotFeatures

export const getDynamicsExportDetails = (state: AppState) =>
  state.modules.payments.dashboard.dynamicsExportDetails

export const getTrustSearchText = (state: AppState) =>
  state.modules.payments.dashboard.trustSearchText
