import { get } from 'lodash'
import {
  call,
  put,
  takeLatest
  // tslint:disable-next-line: no-submodule-imports
} from 'typed-redux-saga'
import { exportDataToExcel } from '../../../../../shared/xlsx'
import { getRockefellerApiOptions } from '../../../../../store/shared/sagas'
import {
  fetchOfacScreeningService,
  updateOfacTransactionStatusService
} from '../api'
import { ofacScreeningDataActions, updateOfacStatusActions } from './actions'
import { getEnumKeyByEnumValue, ScanStateEnum } from './types'
import { getResultTypeText } from './utility'

function* handleFetchOfacScreeningRecords(
  action: ReturnType<typeof ofacScreeningDataActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchOfacScreeningService(action.payload, options)
    )
    yield put(ofacScreeningDataActions.success(data))
  } catch (e: any) {
    yield put(ofacScreeningDataActions.failure(e))
  }
}

function* handleUpdateOfacStatus(
  action: ReturnType<typeof updateOfacStatusActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    yield* call(() =>
      updateOfacTransactionStatusService(action.payload?.updateRequest, options)
    )
    yield put(ofacScreeningDataActions.request(action.payload?.filterDateRange))
    yield put(updateOfacStatusActions.success())
  } catch (e: any) {
    yield put(updateOfacStatusActions.failure(e))
  }
}

function* handleExportToExcel(
  action: ReturnType<typeof ofacScreeningDataActions.exportToexcel>
) {
  const filename = 'OfacScreeningTransactions.xlsx'
  const wsName = 'Ofac Screening'

  const { ofacTransactions, columns } = action.payload

  const updatedData = ofacTransactions.map((x) => ({
    ...x,
    CounterPartyName:
      `${x?.beneentityname}` ||
      `${x?.benenametitle || ''} ${x?.benefirstname || ''} ${
        x?.benemiddlename || ''
      } ${x?.benelastname || ''} ${x?.benenamesuffix || ''}`,
    bankname: `${x?.bankname || ''} ${x?.bankcode ? `(${x?.bankcode})` : ''}`,
    Results: getResultTypeText(
      getEnumKeyByEnumValue(ScanStateEnum, x?.scandstate || '')
    )
  }))

  const data = updatedData?.map((ofactran) => {
    return columns?.map((column): unknown =>
      column.fieldName ? get(ofactran, column.fieldName) : ''
    )
  })

  data?.unshift(
    columns
      .filter((y) => !['Action', 'AdditionalInfo'].includes(y.fieldName || ''))
      ?.map((x) => x.name)
  )

  yield call(() =>
    exportDataToExcel(
      {
        sheets: [{ name: wsName, data: data || [] }]
      },
      filename
    )
  )
}

export const sagas = [
  () =>
    takeLatest(
      ofacScreeningDataActions.request,
      handleFetchOfacScreeningRecords
    ),
  () => takeLatest(updateOfacStatusActions.request, handleUpdateOfacStatus),
  () => takeLatest(ofacScreeningDataActions.exportToexcel, handleExportToExcel)
]
