import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { IFacetResult } from 'api/common.types'
import { IPosition } from 'api/position.types'
import {
  selectIsFullDomainSelected,
  selectSelectedAccountRepIds
} from 'features/Domain/store/domain'
import { fromPairs, groupBy, keyBy } from 'lodash'
import { AppState } from 'store'
import { aiDashboardPositionsApi } from './aiDashboardPositionsApi'

export interface IAiDashboardFilterState {
  households?: string[]
  platforms?: string[]
}

interface IAiDashboardState {
  filters: IAiDashboardFilterState
  selectedMarketValuePiePivotIndex?: number
  selectedAssetClassPiePivotIndex?: number
}

const initialState = {
  filters: { platforms: ['On-Platform', 'Off-Platform', ''] }
} as IAiDashboardState

export const { actions: aiDashboardActions, reducer: aiDashboardReducer } =
  createSlice({
    name: '@modules/@advisory/@modules/@ai/',
    initialState,
    reducers: {
      updateFilters: (
        state,
        action: PayloadAction<Partial<IAiDashboardFilterState> | undefined>
      ) => ({
        ...state,
        filters: { ...state.filters, ...action.payload }
      }),
      setMarketValuePiePivotIndex: (state, action: PayloadAction<number>) => ({
        ...state,
        selectedMarketValuePiePivotIndex: action.payload
      }),
      setAssetClassPiePivotIndex: (state, action: PayloadAction<number>) => ({
        ...state,
        selectedAssetClassPiePivotIndex: action.payload
      }),
      resetFilters: (state) => ({
        ...state,
        filters: { ...initialState.filters }
      })
    }
  })

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.ai.modules.dashboard.dashboard

export const selectAiDashboardFilters = createSelector(
  rootSelector,
  ({ filters }) => filters
)

export const selectSelectedHouseholds = createSelector(
  selectAiDashboardFilters,
  ({ households }) => households
)

export const selectMarketValuePiePivotIndex = createSelector(
  rootSelector,
  ({ selectedMarketValuePiePivotIndex }) => selectedMarketValuePiePivotIndex
)

export const selectAssetClassPiePivotIndex = createSelector(
  rootSelector,
  ({ selectedAssetClassPiePivotIndex }) => selectedAssetClassPiePivotIndex
)

export interface IAiDashboardFilterDefinition {
  key: keyof IAiDashboardFilterState
  name: string
  getValue: (item: IPosition) => string | undefined
  getDisplayValue?: (item: IPosition) => string | undefined
}

export const aiDashboardFilterDefinitions: IAiDashboardFilterDefinition[] = [
  {
    key: 'households',
    name: 'Household',
    getValue: ({ householdId }: IPosition) => householdId || '',
    getDisplayValue: ({ householdName }: IPosition) =>
      householdName || 'Not Householded'
  },
  {
    key: 'platforms',
    name: 'AI RCM Platform',
    getValue: ({ OnPlatform_OffPlatform }: IPosition) =>
      OnPlatform_OffPlatform || ''
  }
]

export const aiDashboardFilterDefinitionsLookup = keyBy(
  aiDashboardFilterDefinitions,
  ({ key }) => key
)

export const selectAiDashboardRequest = createSelector(
  [selectSelectedAccountRepIds, selectIsFullDomainSelected],
  (selectedAccountRepIds, isFullDomainSelected) =>
    selectedAccountRepIds?.length
      ? isFullDomainSelected
        ? undefined
        : selectedAccountRepIds
      : skipToken
)

const selectAiPositionsQueryResult = createSelector(
  [
    (state) => {
      const request = selectAiDashboardRequest(state)
      return aiDashboardPositionsApi.endpoints.fetchAllAiPositions.select(
        request
      )(state)
    }
  ],
  (results) => results
)

const selectAiPositionsData = createSelector(
  [selectAiPositionsQueryResult],
  (result) => result.data
)

const applyFilters = (
  data: IPosition[],
  applicableFilterDefinitions: IAiDashboardFilterDefinition[],
  filterState: IAiDashboardFilterState
) => {
  if (
    !applicableFilterDefinitions
      .map((x) => filterState[x.key])
      ?.some((x) => x?.length)
  ) {
    return data
  }

  const enhancedFilters = applicableFilterDefinitions.map((x) => {
    const filterValues = filterState[x.key]
    const isActive = !!filterValues?.length
    const valuesLookup = keyBy(filterValues)
    return {
      ...x,
      isActive,
      valuesLookup
    }
  })

  const filteredHouseholdData = data?.filter((item) =>
    enhancedFilters
      .map((filter) => {
        if (!filter.isActive) {
          return true
        }
        const value = filter.getValue(item)
        return value != null && filter.valuesLookup[value] != null
      })
      .every(Boolean)
  )

  return filteredHouseholdData
}

export const selectAiDashboardFilteredPositionData = createSelector(
  [selectAiPositionsData, selectAiDashboardFilters],
  (data, filters) =>
    applyFilters(data || [], aiDashboardFilterDefinitions, filters)
)

export const selectAiDashboardFilterFacets = createSelector(
  [selectAiPositionsData, selectAiDashboardFilters],
  (data, filters) => {
    const facets = aiDashboardFilterDefinitions.map((filter) => {
      const filtersExceptCurrent = aiDashboardFilterDefinitions.filter(
        (x) => x !== filter
      )
      const filteredData = applyFilters(
        data || [],
        filtersExceptCurrent,
        filters
      )
      const options = Object.entries(
        groupBy(filteredData, filter.getValue)
      ).map(
        ([key, [first, ...rest]]): IFacetResult => ({
          value: key,
          displayValue:
            filter.getDisplayValue?.(first) || filter.getValue(first),
          count: (rest?.length || 0) + 1
        })
      )

      return [filter.key, options] as [
        keyof IAiDashboardFilterState,
        IFacetResult[]
      ]
    })

    return fromPairs(facets) as Record<
      keyof IAiDashboardFilterState,
      IFacetResult[]
    >
  }
)

export const selectAiDashboardFilterDisplayValues = createSelector(
  [selectAiDashboardFilters, selectAiDashboardFilterFacets],
  (filters, facets) => {
    return fromPairs(
      aiDashboardFilterDefinitions.map((filter) => {
        let value = 'All Selected'
        const filterValues = filters[filter.key]

        if (filterValues?.length === 1) {
          const optionLookup = keyBy(facets[filter.key], ({ value }) => value)
          value = optionLookup[filterValues[0]]?.displayValue || ''
        }

        if ((filterValues?.length || 0) > 1) {
          value = [filterValues?.length, 'Selected'].join(' ')
        }

        return [filter.key, value]
      })
    ) as Record<keyof IAiDashboardFilterState, string>
  }
)
