import { Theme, css, useTheme } from '@emotion/react'
import { Toggle } from '@fluentui/react'
import { IncomeReturnType } from 'api/performance.types'
import { useCallback, useMemo } from 'react'
import { usePerformanceState } from './store'

export const getClasses = (theme: Theme) => ({
  toggleContainer: css({
    display: 'flex',
    alignItems: 'center'
  }),
  margin2: css({
    margin: 2
  }),
  tertiaryGray1: css({
    color: theme.colors.tertiaryGray1
  }),
  primaryDarkBlue: css({
    color: theme.colors.primaryDarkBlue
  }),
  toggleWrapper: css({
    margin: 'auto 0',
    '.ms-Toggle': {
      margin: 'auto 0',
      '.ms-Toggle-background': {
        backgroundColor: theme.colors.primaryLightBlue1,
        width: 36,
        height: 13,
        border: 0,
        padding: 0,
        '.ms-Toggle-thumb': {
          background: theme.colors.extraBlue2,
          height: 16,
          width: 16
        }
      }
    }
  })
})

const IncomeReturnTypeToggle = () => {
  const theme = useTheme()
  const classes = useMemo(() => getClasses(theme), [theme])
  const { assetType, setAssetType } = usePerformanceState()

  const onReturnChange = useCallback(() => {
    const type: IncomeReturnType =
      assetType === IncomeReturnType.NetTWR
        ? IncomeReturnType.NetIrr
        : IncomeReturnType.NetTWR
    setAssetType(type)
  }, [assetType, setAssetType])

  const isTwr = assetType === IncomeReturnType.NetTWR

  return (
    <div css={classes.toggleContainer}>
      <div
        css={[
          classes.margin2,
          isTwr ? classes.primaryDarkBlue : classes.tertiaryGray1
        ]}
      >
        TWR
      </div>
      <div css={classes.toggleWrapper}>
        <Toggle
          checked={assetType === IncomeReturnType.NetIrr}
          onChange={onReturnChange}
          role="checkbox"
        />
      </div>
      <div
        css={[
          classes.margin2,
          !isTwr ? classes.primaryDarkBlue : classes.tertiaryGray1
        ]}
      >
        IRR
      </div>
    </div>
  )
}

export default IncomeReturnTypeToggle
