import { ISelectableOption } from '@fluentui/react'

export const activityDetailColumns = {
  activityDate: 'Activity Date',
  settlementDate: 'Settlement Date',
  entryDate: 'Entry Date',
  account: 'Account',
  description: 'Description',
  type: 'Type',
  symbol: 'Symbol',
  cusip: 'CUSIP',
  symbolCusip: 'Symbol / CUSIP',
  quantity: 'Quantity',
  price: 'Price',
  amount: 'Amount',
  nickName: 'Nickname',
  registrationType: 'Registration Type'
}

export const activityDropdownOptions: ISelectableOption[] = [
  { key: 'BY', text: 'Buys' },
  { key: 'SL', text: 'Sells' },
  { key: 'DV', text: 'Dividends & Interest' },
  { key: 'FE', text: 'Fees & Interest Charged' },
  { key: 'DP', text: 'Deposits / Contributions' },
  { key: 'WD', text: 'Withdrawals' },
  { key: 'JN', text: 'Journal Entries' },
  { key: 'CA', text: 'Corporate Actions' },
  { key: 'EX', text: 'Exchanges/Transfers' },
  { key: 'ZZ', text: 'Other' }
]
