import { lazy } from 'react'
import { IModuleDefinition } from '../../../../types'

export * from './store'

const OptionsIncomeAndExpenseReport = lazy(
  () => import('./OptionsIncomeAndExpenseReport')
)

export const OptionsIncomeAndExpenseReportModule: IModuleDefinition = {
  routeProps: {
    path: '/options-income-and-expense-report',
    private: true,
    component: OptionsIncomeAndExpenseReport
  },
  name: 'Options Income And Expense Report'
}
