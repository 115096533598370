import { MessageBarType } from '@fluentui/react'
import { flow } from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { delay, put, takeEvery } from 'typed-redux-saga'
import { ActionType, createReducer, createAction } from 'typesafe-actions'
import { v4 as uuid } from 'uuid'
import { createStoreInjectorHook, getRootState } from '../../store/shared'

const ADD_NOTIFICATION = '@features/@notifications/CREATE_NOTIFICATION'
const REMOVE_NOTIFICATION = '@features/@notifications/REMOVE_NOTIFICATION'

export interface IPushNotificationRequest {
  duration?: number
  message: string
  type: MessageBarType
  detailLink?: string
}

export interface INotificationPayload extends IPushNotificationRequest {
  id?: string
}

export const notificationActions = {
  push: createAction(
    ADD_NOTIFICATION,
    (request: IPushNotificationRequest) =>
      ({ ...request, id: uuid() } as INotificationPayload)
  )(),
  remove: createAction(REMOVE_NOTIFICATION)<string>()
}

export const NotificationFeatureSliceKey = 'features.notifications'
export type NotificationFeatureSlice = {
  [NotificationFeatureSliceKey]?: ReturnType<typeof notificationsReducer>
}

export type NotificationActionTypes = ActionType<typeof notificationActions>

export interface INotificationState {
  items: INotificationPayload[]
}

const initialState: INotificationState = {
  items: []
}

export const notificationsReducer = createReducer<
  INotificationState,
  NotificationActionTypes
>(initialState)
  .handleAction(notificationActions.push, (state, action) => ({
    ...state,
    items: [...state.items, action.payload]
  }))
  .handleAction(notificationActions.remove, (state, action) => {
    const { items } = state
    const copy = [...items]
    const index = copy.findIndex((x) => x.id === action.payload)
    if (index < 0) {
      return state
    }

    copy.splice(index, 1)

    return {
      ...state,
      items: copy
    }
  })

export const getNotificationState = flow(
  getRootState,
  (x) => x?.[NotificationFeatureSliceKey]
)
export const getNotifications = flow(getNotificationState, (x) => x?.items)

export const pushNotification = function* (request: IPushNotificationRequest) {
  const notification = notificationActions.push(request)
  yield put(notification)
  return notification
}

const onPushNotification = function* (
  action: ReturnType<typeof notificationActions.push>
) {
  const { duration, id } = action.payload
  if (!id) {
    return
  }

  yield delay(duration || 5000)
  yield put(notificationActions.remove(id))
}

export const notificationsSagas = [
  () => takeEvery(notificationActions.push, onPushNotification)
]

export const useNotificationFeatureStore = createStoreInjectorHook(
  NotificationFeatureSliceKey,
  notificationsReducer,
  notificationsSagas
)

export const usePushNotification = () => {
  const dispatch = useDispatch()

  const pushNotification = useCallback(
    (request: IPushNotificationRequest) => {
      dispatch(notificationActions.push(request))
    },
    [dispatch]
  )

  return {
    pushNotification
  }
}
