import { fetchPreference } from 'modules/Transfers/features/Dashboard/modules/Dashboard/api/DashboardService'
import { getDateMinMax } from 'modules/Transfers/features/Dashboard/modules/Dashboard/utilities/Utilities'
import { RangeType } from 'shared/components/DataTableFilters/DataTableFilters'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { AppState } from 'store'
import { createAsyncSelectors, getRockefellerApiOptions } from 'store/shared'
import { call, put, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { SecOverrideDashboardPreference, ColumnSetting } from './types'

export const SET_COLUMN_PREFERENCE =
  '@modules/@security/@overrides/SET_COLUMN_PREFERENCE'
export const SET_PREFERENCE = '@modules/@security/@overrides/SET_PREFERENCE'
export const GET_PREFERENCE = '@modules/@security/@overrides/GET_PREFERENCE'

export const overridePreferenceActions = {
  setColumnPreference: createAction(SET_COLUMN_PREFERENCE)<ColumnSetting[]>(),
  setPreference: createAction(SET_PREFERENCE)<SecOverrideDashboardPreference>(),
  getPreference_request: createAction(GET_PREFERENCE)(),
  getPreference_success:
    createAction(SET_PREFERENCE)<SecOverrideDashboardPreference>()
}
interface IDashboardState {
  columnPreferenceState?: ColumnSetting[]
  dashboardPreferenceState?: SecOverrideDashboardPreference
}

const initialState: IDashboardState = {}

export const overridePreferenceReducer = createReducer<
  IDashboardState,
  DashboardActionTypes
>(initialState)
  .handleAction(
    overridePreferenceActions.setColumnPreference,
    (state, action) => ({
      ...state,
      columnPreferenceState: action.payload
    })
  )
  .handleAction(overridePreferenceActions.setPreference, (state, action) => ({
    ...state,
    dashboardPreferenceState: action.payload
  }))

const rootSelector = (state: AppState) => state.modules.overrides.overridesFetch

export const {
  getError: getOverridesFetchError,
  getIsLoading: getOverridesFetchLoading,
  getResult: getOverridesFetchResult
} = createAsyncSelectors(rootSelector)

function* handleGetPreference() {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: SecOverrideDashboardPreference = yield* call(() =>
      fetchPreference(options)
    )
    if (data?.SecOverridesDashboardFilters?.creationdate?.value) {
      const value = data?.SecOverridesDashboardFilters?.creationdate
        ?.value as RangeType
      // refetch from and to dates as dates saved in preference may not be relevant
      const updatedDates = getDateMinMax(value?.rangeKey || '')
      data.SecOverridesDashboardFilters.creationdate.value = {
        minValue: updatedDates.min,
        maxValue: updatedDates.max,
        rangeKey: value?.rangeKey
      }
    }
    if (data?.SecOverridesDashboardFilters?.processdate?.value) {
      const value = data?.SecOverridesDashboardFilters?.processdate
        ?.value as RangeType
      // refetch from and to dates as dates saved in preference may not be relevant
      const updatedDates = getDateMinMax(value?.rangeKey || '')
      data.SecOverridesDashboardFilters.processdate.value = {
        minValue: updatedDates.min,
        maxValue: updatedDates.max,
        rangeKey: value?.rangeKey
      }
    }

    yield put(overridePreferenceActions.getPreference_success(data || {}))
  } catch (e: any) {
    yield put(overridePreferenceActions.getPreference_success({}))
    console.log('unable to fetch preference')
  }
}

export const overridePreferenceSagas = [
  () =>
    takeLatest(
      overridePreferenceActions.getPreference_request,
      handleGetPreference
    )
]

export type DashboardActionTypes = ActionType<typeof overridePreferenceActions>

export const getOverridePreferenceState = (state: AppState) =>
  state.modules.overrides.overridePreference.dashboardPreferenceState
