import { Callout, ITheme } from '@fluentui/react'
import { FC } from 'react'
import { useClasses } from 'shared/hooks/useClasses'
import { useShareReportLogic } from './useShareReportLogic'

const getThemedClasses = (theme: ITheme) => {
  return {
    shareReportCallout: {
      boxShadow: theme.effects.elevation16,
      padding: 10,
      width: 360
    },
    scrollBox: {
      minHeight: 100,
      maxHeight: 250,
      overflow: 'auto'
    },
    textLight: {
      color: theme.palette.neutralSecondary
    }
  }
}

interface ISharedCalloutProps {
  fileId: string
  buttonId: string
  hideCallout: () => void
}

export const SharedCallout: FC<ISharedCalloutProps> = ({
  fileId,
  buttonId,
  hideCallout
}) => {
  const { calloutPlaceholder, logsGroupedSorted } = useShareReportLogic(fileId)
  const placeHolder = calloutPlaceholder ? <p>{calloutPlaceholder}</p> : null
  const classes = useClasses(getThemedClasses)
  return (
    <Callout
      target={`#${buttonId}`}
      className={classes.shareReportCallout}
      onDismiss={hideCallout}
      setInitialFocus
    >
      <div className={classes.scrollBox}>
        {placeHolder ||
          logsGroupedSorted.map((log) => {
            const { createdOn, requestedBy } = log[1][0]
            const shareWithUser = log[1]
              .map((log) => log.shareWithUser)
              .join(', ')

            return (
              <div key={log[0]} css={{ marginBottom: 10 }}>
                <strong>{createdOn}</strong>
                <div>
                  Shared by:{' '}
                  <span className={classes.textLight}>{requestedBy}</span>
                </div>
                <div>
                  Shared with:{' '}
                  <span className={classes.textLight}>{shareWithUser}</span>
                </div>
              </div>
            )
          })}
      </div>
    </Callout>
  )
}
