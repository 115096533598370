import { createRoot } from 'react-dom/client'
import { whenIdle } from 'shared/async'

export interface IReportErrorEmailProps {
  location?: string
  currentUsername?: string
  error?: Error
  errorInfo?: React.ErrorInfo
  environment?: string
}

export const ReportErrorEmail: React.FC<IReportErrorEmailProps> = ({
  location,
  currentUsername,
  error,
  errorInfo,
  environment
}) => {
  return (
    <ul style={{ marginTop: '10px' }}>
      <li>
        <b>Environment: </b> {environment}
      </li>
      <li>
        <b>Error Message: </b> {error?.message}
      </li>
      <li>
        <b>Location: </b> {location}
      </li>
      <li>
        <b>Username: </b> {currentUsername}
      </li>
      <li>
        <b>Error Stack: </b> {error?.stack}
      </li>
      <li>
        <b>Component Stack: </b> {errorInfo?.componentStack}
      </li>
    </ul>
  )
}

export const generateReportErrorEmail = async (
  props: IReportErrorEmailProps
) => {
  const container = document.createElement('div')
  const root = createRoot(container)

  root.render(<ReportErrorEmail {...props} />)
  await whenIdle()
  const html = container.innerHTML
  root.unmount()
  return html
}
