import { getAnnuityPPLIContracts } from 'api/datahub'
import type { IOdataRequest } from 'api/odata.types'
import axios, { CancelTokenSource } from 'axios'
import { IListsFilter } from 'features/Lists/core/contracts/IListsFilter'
import { convertColumnTypeToFilterType } from 'features/OdataList/common/service'
import { createOdataListWithFacetsStore } from 'features/OdataList/store/odataListWithFacetsStore'
import { keyBy } from 'lodash'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { isNotNullOrUndefined } from 'shared/guards'
import { AppState, getRockefellerApiOptions } from 'store/shared'
import { call, cancelled } from 'typed-redux-saga'
import { annuityPPLIColumns } from './columnConfigOdataList'
import type { AnnuityPPLIName } from './columnNameTypes'

type RootSelector = 'annuities' | 'insurance' | 'holdings'

interface IStoreParams {
  columnConfig: string[]
  selectedColumns: number
  root: RootSelector
}

const getDatahubApiOptions = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)
  return [apiOptions, source] as [IApiOptions, CancelTokenSource]
}

export const fetchAnnuitiesAndInsurance = function* (request: IOdataRequest) {
  const [apiOptions, cancelTokenSource] = yield* call(getDatahubApiOptions)
  try {
    return yield* call(getAnnuityPPLIContracts, apiOptions, request)
  } finally {
    if (yield* cancelled()) {
      cancelTokenSource.cancel()
    }
  }
}

export const createStore = (params: IStoreParams) => {
  const { columnConfig, selectedColumns, root } = params

  const annuityPPLIColumnsKeyedBy = keyBy(
    annuityPPLIColumns,
    (item) => item.name
  )

  const annuityPPLIColumnsMapped = columnConfig
    .map((val) => annuityPPLIColumnsKeyedBy[val])
    ?.filter(isNotNullOrUndefined)

  const defaultColumnsIds: AnnuityPPLIName[] = annuityPPLIColumnsMapped.map(
    (x) => x.name
  )

  const rootSelector = (state: AppState) =>
    state.modules.advisory.modules.annuitiesAndInsurance.modules.dashboard
      .features[root]

  const uiFilters = keyBy(
    annuityPPLIColumnsMapped
      .filter((x) => x.filterable)
      .map((column): IListsFilter => {
        const base = {
          id: column.name,
          name: column.name,
          type: convertColumnTypeToFilterType(column),
          dataPath: column.dataPath,
          hasValue: false
        }

        return base
      }),
    ({ id }) => id
  )

  const selectedColumnIds = [...defaultColumnsIds].splice(0, selectedColumns)

  return createOdataListWithFacetsStore({
    prefix: `@modules/@advisory/@modules/@annuitiesandinsurance/@modules/@dashboard/@features/@${root}`,
    getOdataResults: fetchAnnuitiesAndInsurance,
    initialState: {
      data: {},
      ui: {
        columns: annuityPPLIColumnsMapped,
        selectedColumns: selectedColumnIds,
        filters: uiFilters,
        sortBy: {
          direction: 'desc',
          name: 'Issue Date'
        }
      },
      facets: {}
    },
    rootSelector
  })
}
