import { createSelector } from 'reselect'
import { dynamicsApi } from 'store/api/dynamics'
import { call, put } from 'typed-redux-saga'

// DYNAMICS
export enum DynamicsUserRolesEnum {
  SystemAdministrator = 'System Administrator',
  BDAAdmin = 'BDA Admin',
  BDAOwner = 'BDA Owner',
  BDATransactionReviewer = 'BDA Transaction Reviewer',
  BDADiscountSharingReviewer = 'BDA Discount Sharing Reviewer',
  MarginRateRequestApprover = 'Margin Rate Request Approver',
  PWMSupplementalComp = 'PWM Supplemental Comp',
  PWMSupplementalCompAdmin = 'PWM Supplemental Comp Admin',
  PWMSupplementalCompHomeOffice = 'PWM Supplemental Comp Home Office'
}

export function* saga_getCurrentSystemuser() {
  const action = dynamicsApi.endpoints.getCurrentSystemuser.initiate()
  const promise = yield* put(action)
  promise.unsubscribe()
  return yield* call(promise.unwrap)
}

export function* saga_getCurrentSystemuserRoles() {
  const systemuser = yield* call(saga_getCurrentSystemuser)
  return systemuser?.systemuserroles_association
}

const selectCurrentSystemUserResult =
  dynamicsApi.endpoints.getCurrentSystemuser.select()

/**
 * @deprecated use useGetCurrentSystemuserQuery hook
 */
export const getDynamicsUserBusinessunitId = createSelector(
  selectCurrentSystemUserResult,
  (result) =>
    result?.isLoading ? undefined : result?.data?._businessunitid_value || 'na'
)
