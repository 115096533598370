import { combineReducers } from 'redux'
import {
  accountLinkingReducer,
  accountLinkingSagas
} from './AccountLinking/store'
import { accountFeatureReducer, accountFeatureSagas } from './Accounts/store'
import { activateProfileReducer } from './ActivateProfile/store'
import { clientInviteReducer } from './ClientInvite/store'
import {
  clientsFeatureSagas,
  clientsFeatureReducer
} from './Clients/store/index'
import { contactReducer, contactSagas } from './Contact/store'
import { domainFeatureReducer, domainFeatureSagas } from './Domain'
import { errorPageReducer, errorPageSagas } from './ErrorPage'
import {
  householdFeatureReducer,
  householdFeatureSagas
} from './Households/store'
import { updateStatusReducer, updateStatusSagas } from './Hurdles'
import { reducer as listsReducer, sagas as listsSagas } from './Lists'
import type { MarginRateAdjustmentFeatureSlice } from './MarginRateAdjustment/store'
import { notesReducer } from './Notes/store'
import type { NotificationFeatureSlice } from './Notifications/store'
import { relatedPartyPanelSagas } from './RelatedPartyPanel/sagas'
import { relatedPartyPanelReducer } from './RelatedPartyPanel/store'
import { searchReducer, searchSagas } from './Search'
import { taskReducer } from './Tasks/store'
import { tradeDetailReducer, sagas as tradeDetailSagas } from './TradeDetail'

export const featureReducer = combineReducers({
  relatedPartyPanel: relatedPartyPanelReducer,
  search: searchReducer,
  lists: listsReducer,
  tradeDetail: tradeDetailReducer,
  contact: contactReducer,
  clients: clientsFeatureReducer,
  households: householdFeatureReducer,
  accounts: accountFeatureReducer,
  accountLinking: accountLinkingReducer,
  domain: domainFeatureReducer,
  errorPage: errorPageReducer,
  hurdles: updateStatusReducer,
  clientInvite: clientInviteReducer,
  notes: notesReducer,
  tasks: taskReducer,
  activateProfile: activateProfileReducer
})

export type DynamicFeaturesState = NotificationFeatureSlice &
  MarginRateAdjustmentFeatureSlice
export type StaticFeaturesState = ReturnType<typeof featureReducer>

export const featureSagas = [
  ...relatedPartyPanelSagas,
  ...searchSagas,
  ...listsSagas,
  ...tradeDetailSagas,
  ...contactSagas,
  ...householdFeatureSagas,
  ...accountFeatureSagas,
  ...clientsFeatureSagas,
  ...accountLinkingSagas,
  ...domainFeatureSagas,
  ...errorPageSagas,
  ...updateStatusSagas
]
