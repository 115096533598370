import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

export interface IAlertSummaryState {
  expanded: boolean
  applications: string[]
  statuses: string[]
  repCodes: string[]
  selectedApplications: string[]
  selectedAlertStatuses: string[]
  selectedAlertType: string[]
  enabledArchiveFilter: boolean
  selection: string[]
}

const initialState = {
  expanded: true,
  applications: [],
  statuses: [],
  repCodes: [],
  selectedApplications: [],
  selectedAlertStatuses: [],
  selectedAlertType: [],
  enabledArchiveFilter: false,
  selection: []
} as IAlertSummaryState

export const { actions: alertsSummaryActions, reducer: alertsSummaryReducer } =
  createSlice({
    name: '@modules/@advisory/@modules/@alerts/@features/@alertsTable',
    initialState,
    reducers: {
      setExpanded: (state, action: PayloadAction<boolean>) => ({
        ...state,
        expanded: action.payload
      }),
      setApplications: (state, action: PayloadAction<string[]>) => ({
        ...state,
        applications: action.payload
      }),
      setStatuses: (state, action: PayloadAction<string[]>) => ({
        ...state,
        statuses: action.payload
      }),
      setSelectedApplications: (state, action: PayloadAction<string[]>) => ({
        ...state,
        selectedApplications: action.payload
      }),
      setSelectedAlertStatuses: (state, action: PayloadAction<string[]>) => ({
        ...state,
        selectedAlertStatuses: action.payload
      }),
      setSelectedAlertType: (state, action: PayloadAction<string[]>) => ({
        ...state,
        selectedAlertType: action.payload
      }),
      setRepCodes: (state, action: PayloadAction<string[]>) => ({
        ...state,
        repCodes: action.payload
      }),
      setEnabledArchiveFilter: (state, action: PayloadAction<boolean>) => ({
        ...state,
        enabledArchiveFilter: action.payload
      }),
      setSelection: (state, action: PayloadAction<string[]>) => ({
        ...state,
        selection: action.payload
      })
    }
  })

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.alerts.alertsSummary

export const getEnabledArchiveFilter = flow(
  rootSelector,
  (x) =>
    `isArchived ${
      x?.enabledArchiveFilter ? 'eq true' : 'ne true'
    } and isDeleted ne true`
)

export const useAlertStore = () => {
  const dispatch = useDispatch()
  const {
    expanded,
    applications,
    statuses,
    repCodes,
    enabledArchiveFilter,
    selectedAlertStatuses,
    selectedApplications,
    selectedAlertType,
    selection
  } = useSelector(rootSelector)

  const hasSelectedFilters = useMemo(
    () => selectedApplications.length > 0 && selectedAlertStatuses.length > 0,
    [selectedAlertStatuses.length, selectedApplications.length]
  )

  const setExpanded = useCallback(
    (payload: boolean) => {
      dispatch(alertsSummaryActions.setExpanded(payload))
    },
    [dispatch]
  )

  const setStatuses = useCallback(
    (payload: string[]) => {
      dispatch(alertsSummaryActions.setStatuses(payload))
    },
    [dispatch]
  )

  const setApplications = useCallback(
    (payload: string[]) => {
      dispatch(alertsSummaryActions.setApplications(payload))
    },
    [dispatch]
  )

  const setSelectedAlertStatuses = useCallback(
    (payload: string[]) => {
      dispatch(alertsSummaryActions.setSelectedAlertStatuses(payload))
    },
    [dispatch]
  )

  const setSelectedApplications = useCallback(
    (payload: string[]) => {
      dispatch(alertsSummaryActions.setSelectedApplications(payload))
    },
    [dispatch]
  )

  const setSelectedAlertType = useCallback(
    (payload: string[]) => {
      dispatch(alertsSummaryActions.setSelectedAlertType(payload))
    },
    [dispatch]
  )

  const setRepCodes = useCallback(
    (payload: string[]) => {
      dispatch(alertsSummaryActions.setRepCodes(payload))
    },
    [dispatch]
  )

  const setEnabledArchiveFilter = useCallback(
    (payload: boolean) => {
      dispatch(alertsSummaryActions.setEnabledArchiveFilter(payload))
    },
    [dispatch]
  )

  const setSelection = useCallback(
    (payload: string[]) => {
      dispatch(alertsSummaryActions.setSelection(payload))
    },
    [dispatch]
  )

  const archiveFilter = useSelector(getEnabledArchiveFilter)

  return {
    expanded,
    setExpanded,
    applications,
    setApplications,
    statuses,
    setStatuses,
    selectedAlertStatuses,
    setSelectedAlertStatuses,
    selectedApplications,
    setSelectedApplications,
    selectedAlertType,
    setSelectedAlertType,
    hasSelectedFilters,
    repCodes,
    setRepCodes,
    enabledArchiveFilter,
    setEnabledArchiveFilter,
    archiveFilter,
    selection,
    setSelection
  }
}
