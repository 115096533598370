import { useDomainStore } from 'features/Domain/store/domain'
import { IListsUiState } from 'features/Lists/core/contracts/IListsUIState'
import { IListPreferences } from 'features/Lists/hooks/useListPreferences'
import { useListSessionState } from 'features/Lists/hooks/useListSessionState'
import {
  IListWithPreferencesHookArgs,
  useListWithPreferences
} from 'features/Lists/hooks/useListWithPreferences'
import { useCallback, useLayoutEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useMount, useUpdateEffect } from 'react-use'
import { DateRanges } from 'shared'
import { getRevenueDashboardDateRangeDates } from '../../../store/dashboard'
import { CreditEventColumnIds } from '../ColumnDefinitions'
import { createCreditEventListFilter } from '../store'
import { CreditEventListTypesEnum } from '../types'
import { creditEventHookDefinitions } from './hookDefinitions'
import { systemViewsMap } from './systemViews'
import { useAdvisorIdFilter } from './useAdvisorIdFilter'
import { useRevenueDashboardList } from './useList'

const useListWithPreferencesByType = (
  type: CreditEventListTypesEnum,
  args: IListWithPreferencesHookArgs
) => {
  const definition = creditEventHookDefinitions[type]
  return useListWithPreferences(definition, args)
}

const useDateRangeFilter = () => {
  const dateRange = useSelector(getRevenueDashboardDateRangeDates)

  const dateRangefilter = useMemo(() => {
    if (!dateRange) {
      return
    }
    return createCreditEventListFilter(CreditEventColumnIds.periodTimestamp, {
      type: 'date-only',
      range: 'Custom' as DateRanges,
      from: dateRange[0],
      to: dateRange[1]
    })
  }, [dateRange])

  return { dateRangefilter }
}

const preferenceKey = 'revenue_dashboard/lists'

const useListConfig = (listType: CreditEventListTypesEnum) => {
  const { advisorIdFilter } = useAdvisorIdFilter()
  const { dateRangefilter } = useDateRangeFilter()

  const onBeforeApply = useCallback(
    (
      newState: Partial<IListsUiState>,
      currentState: Partial<IListsUiState>
    ) => {
      return {
        ...newState,
        filters: {
          ...(dateRangefilter?.id && { [dateRangefilter.id]: dateRangefilter }),
          ...currentState.filters,
          ...(advisorIdFilter?.id && { [advisorIdFilter.id]: advisorIdFilter })
        }
      }
    },
    [advisorIdFilter, dateRangefilter]
  )

  const onBeforeSave = useCallback((preferences: IListPreferences) => {
    const copy = { ...preferences }
    copy.filters = undefined
    return copy
  }, [])

  const systemViews = systemViewsMap[listType]

  return {
    preferenceKey,
    listType,
    systemViews,
    onBeforeApply,
    onBeforeSave
  }
}

const useSessionPreferences = (listType: CreditEventListTypesEnum) => {
  return useListSessionState(
    `${preferenceKey}/${listType}/creditEventListSessionPreferences`
  )
}

export const useCreditEventListWithPreferences = (
  listType: CreditEventListTypesEnum
) => {
  const { preferenceKey, systemViews, onBeforeApply, onBeforeSave } =
    useListConfig(listType)

  const listWithPreferences = useListWithPreferencesByType(listType, {
    preferenceKey,
    systemViews,
    onBeforeApply,
    onBeforeSave
  })

  return listWithPreferences
}

export const useInitializeCreditEventList = (
  listType: CreditEventListTypesEnum
) => {
  const {
    listPreferences: sessionListPreferences,
    setListPreferences: setSessionListPreferences
  } = useSessionPreferences(listType)
  const { isFetching } = useDomainStore()
  const { setIsReady } = useRevenueDashboardList(listType)
  const { listPreferences } = useCreditEventListWithPreferences(listType)
  const {
    applyManualPreferences,
    defaultViewId,
    selectedViewId,
    applyView,
    preSaveListPreferences
  } = listPreferences

  useMount(() => {
    if (!sessionListPreferences) {
      return
    }

    applyManualPreferences(sessionListPreferences)
  })

  useUpdateEffect(() => {
    // if rep codes aren't ready, or we haven't updated the list state yet, don't set ready
    if (isFetching || !preSaveListPreferences) {
      return
    }
    setIsReady()
  }, [isFetching, preSaveListPreferences, setIsReady])

  // need useLayoutEffect here or we cause an infinite loop
  // https://github.com/reduxjs/react-redux/issues/1912
  // set the initial view
  useLayoutEffect(() => {
    if (!defaultViewId || selectedViewId) {
      return
    }

    applyView(defaultViewId)
  }, [applyView, defaultViewId, selectedViewId])

  useUpdateEffect(() => {
    if (!preSaveListPreferences) {
      return
    }

    setSessionListPreferences(preSaveListPreferences)
  }, [preSaveListPreferences, setSessionListPreferences])
}
