import { ActionType, createAction } from 'typesafe-actions'
import { IAddClientReq, IClient, IManageClientReq, IUser } from './types'

export const FETCH_USER_LIST = '@modules/@payments/@admin/FETCH_USER_LIST'
export const FETCH_USER_LIST_SUCCESS =
  '@modules/@payments/@admin/FETCH_USER_LIST_SUCCESS'
export const FETCH_USER_LIST_FAILURE =
  '@modules/@payments/@admin/FETCH_USER_LIST_FAILURE'

export const FETCH_CLIENT_LIST = '@modules/@payments/@admin/FETCH_CLIENT_LIST'
export const FETCH_CLIENT_LIST_SUCCESS =
  '@modules/@payments/@admin/FETCH_CLIENT_LIST_SUCCESS'
export const FETCH_CLIENT_LIST_FAILURE =
  '@modules/@payments/@admin/FETCH_CLIENT_LIST_FAILURE'

export const SET_SELECTED_USER = '@modules/@payments/@admin/SET_SELECTED_USER'

export const MANAGE_RCM_USER_ORG =
  '@modules/@payments/@admin/MANAGE_RCM_USER_ORG'
export const MANAGE_RCM_USER_ORG_SUCCESS =
  '@modules/@payments/@admin/MANAGE_RCM_USER_ORG_SUCCESS'
export const MANAGE_RCM_USER_ORG_FAILURE =
  '@modules/@payments/@admin/MANAGE_RCM_USER_ORG_FAILURE'

export const ADD_CLIENT = '@modules/@payments/@admin/ADD_CLIENT'
export const ADD_CLIENT_SUCCESS = '@modules/@payments/@admin/ADD_CLIENT_SUCCESS'
export const ADD_CLIENT_FAILURE = '@modules/@payments/@admin/ADD_CLIENT_FAILURE'
export const ADD_CLIENT_CLEAR = '@modules/@payments/@admin/ADD_CLIENT_CLEAR'

export const fetchUserListActions = {
  request: createAction(FETCH_USER_LIST)(),
  success: createAction(FETCH_USER_LIST_SUCCESS)<IUser[]>(),
  failure: createAction(FETCH_USER_LIST_FAILURE)<Error>()
}

export const fetchClientListActions = {
  request: createAction(FETCH_CLIENT_LIST)<string>(),
  success: createAction(FETCH_CLIENT_LIST_SUCCESS)<IClient[]>(),
  failure: createAction(FETCH_CLIENT_LIST_FAILURE)<Error>()
}

export const addClientActions = {
  request: createAction(ADD_CLIENT)<IAddClientReq>(),
  success: createAction(ADD_CLIENT_SUCCESS)<string>(),
  failure: createAction(ADD_CLIENT_FAILURE)<Error>(),
  clear: createAction(ADD_CLIENT_CLEAR)()
}

export const setSelectedUserActions = {
  setSelectedUser: createAction(SET_SELECTED_USER)<IUser>()
}

export const manageRCMUserOrgActions = {
  request: createAction(MANAGE_RCM_USER_ORG)<IManageClientReq>(),
  success: createAction(MANAGE_RCM_USER_ORG_SUCCESS)<IManageClientReq>(),
  failure: createAction(MANAGE_RCM_USER_ORG_FAILURE)<{
    error: Error
    req: IManageClientReq
  }>()
}

export type FetchUserListActionType = ActionType<typeof fetchUserListActions>
export type FetchClientListActionType = ActionType<
  typeof fetchClientListActions
>
export type SetSelectedUserActionType = ActionType<
  typeof setSelectedUserActions
>

export type ManageRcmUserOrgActionType = ActionType<
  typeof manageRCMUserOrgActions
>
export type AddClientActionType = ActionType<typeof addClientActions>

export type ClientVisibilityActions =
  | FetchUserListActionType
  | FetchClientListActionType
  | SetSelectedUserActionType
  | ManageRcmUserOrgActionType
  | AddClientActionType
