import { IColumnDefinition } from '../core/contracts/IColumnDefinition'
import { CommonColumnIdsEnum } from './CommonColumnIdsEnum'

export const CommonColumnDefinitions: Record<string, IColumnDefinition> = {
  [CommonColumnIdsEnum.sectorAllocation]: {
    id: CommonColumnIdsEnum.sectorAllocation,
    name: 'Sector Allocation',
    dataPath: 'sectorAllocation',
    type: 'custom',
    width: 150,
    filters: [
      {
        id: 'sector',
        name: 'Sector',
        type: 'facet',
        collectionPath: 'sectorAllocation',
        dataPath: 'Sector'
      }
    ]
  },
  [CommonColumnIdsEnum.productAllocation]: {
    id: CommonColumnIdsEnum.productAllocation,
    name: 'Product Allocation',
    dataPath: 'productAllocation',
    type: 'custom',
    width: 150,
    filters: [
      {
        id: 'productType',
        name: 'Product Type',
        type: 'facet',
        collectionPath: 'productAllocation',
        dataPath: 'productType'
      }
    ]
  },
  [CommonColumnIdsEnum.assetAllocation]: {
    id: CommonColumnIdsEnum.assetAllocation,
    name: 'Asset Allocation',
    dataPath: 'assetAllocationLvl1',
    type: 'custom',
    width: 150,
    filters: [
      {
        id: 'assetType',
        name: 'Asset Type',
        type: 'facet',
        collectionPath: 'assetAllocationLvl1',
        dataPath: 'assetType'
      }
    ]
  },
  [CommonColumnIdsEnum.team]: {
    id: CommonColumnIdsEnum.team,
    name: 'Team',
    dataPath: 'ClientAdvisorTeam',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    facetable: true,
    mask: true
  },
  [CommonColumnIdsEnum.clientAdvisor]: {
    id: CommonColumnIdsEnum.clientAdvisor,
    name: 'Advisor',
    dataPath: 'ClientAdvisor',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    facetable: true,
    mask: true
  },
  [CommonColumnIdsEnum.clientAdvisorId]: {
    id: CommonColumnIdsEnum.clientAdvisorId,
    name: 'Advisor Id',
    dataPath: 'ClientAdvisorID',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    facetable: true,
    mask: true
  },
  [CommonColumnIdsEnum.householdId]: {
    id: CommonColumnIdsEnum.householdId,
    name: 'Household Id',
    dataPath: 'householdId',
    type: 'string',
    width: 120,
    filterable: true,
    sortable: true,
    facetable: false,
    searchable: true
  },
  [CommonColumnIdsEnum.householdName]: {
    id: CommonColumnIdsEnum.householdName,
    name: 'Household Name',
    dataPath: 'householdName',
    type: 'string',
    width: 180,
    filterable: true,
    sortable: true,
    facetable: false,
    searchable: true
  }
}
