import { createReducer } from 'typesafe-actions'
import {
  trustDashboardInfoDataActions,
  TrustDashboardInfoActionType,
  pendingMgmtReviewCountActions,
  trustOfficerReviewCountActions
} from './actions'
import {
  IPendingManagementReviewCount,
  ITrustDashboardInfo,
  ITrustOfficerReviewCount
} from './types'

export interface ITrustDashboardInfoState {
  trustDashboardInfoData: ITrustDashboardInfo[]
  isLoading: boolean
  error?: Error
  pendingMgmtRevCountState?: {
    isLoading: boolean
    error?: Error
    data?: IPendingManagementReviewCount
  }
  pendingTrustOfficerRevCountState?: {
    isLoading: boolean
    error?: Error
    data?: ITrustOfficerReviewCount
  }
}

const initialState: ITrustDashboardInfoState = {
  trustDashboardInfoData: [],
  isLoading: true,
  error: undefined
}

export const trustDashboardInfoReducer = createReducer<
  ITrustDashboardInfoState,
  TrustDashboardInfoActionType
>(initialState)
  .handleAction(trustDashboardInfoDataActions.request, (state) => ({
    ...state,
    isLoading: true,
    error: undefined
  }))
  .handleAction(trustDashboardInfoDataActions.success, (state, action) => ({
    ...state,
    trustDashboardInfoData: action.payload,
    isLoading: false,
    error: undefined
  }))
  .handleAction(trustDashboardInfoDataActions.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload
  }))
  .handleAction(pendingMgmtReviewCountActions.request, (state) => ({
    ...state,
    pendingMgmtRevCountState: {
      isLoading: true,
      data: undefined,
      error: undefined
    }
  }))
  .handleAction(pendingMgmtReviewCountActions.success, (state, action) => ({
    ...state,
    pendingMgmtRevCountState: {
      isLoading: false,
      data: action.payload,
      error: undefined
    }
  }))
  .handleAction(pendingMgmtReviewCountActions.failure, (state, action) => ({
    ...state,
    pendingMgmtRevCountState: {
      isLoading: false,
      data: undefined,
      error: action.payload
    }
  }))
  .handleAction(trustOfficerReviewCountActions.request, (state) => ({
    ...state,
    pendingTrustOfficerRevCountState: {
      isLoading: true,
      data: undefined,
      error: undefined
    }
  }))
  .handleAction(trustOfficerReviewCountActions.success, (state, action) => ({
    ...state,
    pendingTrustOfficerRevCountState: {
      isLoading: false,
      data: action.payload,
      error: undefined
    }
  }))
  .handleAction(trustOfficerReviewCountActions.failure, (state, action) => ({
    ...state,
    pendingTrustOfficerRevCountState: {
      isLoading: false,
      data: undefined,
      error: action.payload
    }
  }))
