import { Theme, css, useTheme } from '@emotion/react'
import { Toggle } from '@fluentui/react'
import { useCallback, useMemo } from 'react'
import { usePerformanceState } from './store'

export const getClasses = (theme: Theme) => ({
  toggleContainer: css({
    display: 'flex',
    alignItems: 'center'
  }),
  margin2: css({
    margin: 2
  }),
  tertiaryGray1: css({
    color: theme.colors.tertiaryGray1
  }),
  primaryDarkBlue: css({
    color: theme.colors.primaryDarkBlue
  }),
  toggleWrapper: css({
    margin: 'auto 0',
    '.ms-Toggle': {
      margin: 'auto 0',
      '.ms-Toggle-background': {
        backgroundColor: theme.colors.primaryLightBlue1,
        width: 36,
        height: 13,
        border: 0,
        padding: 0,
        '.ms-Toggle-thumb': {
          background: theme.colors.extraBlue2,
          height: 16,
          width: 16
        }
      }
    }
  })
})

const AccountToggle = () => {
  const theme = useTheme()
  const classes = useMemo(() => getClasses(theme), [theme])
  const { isSingleAccount, setIsSingleAccount } = usePerformanceState()

  const handleGroupingChange = useCallback(() => {
    setIsSingleAccount(!isSingleAccount)
  }, [isSingleAccount, setIsSingleAccount])

  return (
    <div css={classes.toggleContainer}>
      <div
        css={[
          classes.margin2,
          isSingleAccount ? classes.primaryDarkBlue : classes.tertiaryGray1
        ]}
      >
        Group
      </div>
      <div css={classes.toggleWrapper}>
        <Toggle
          checked={isSingleAccount}
          onChange={handleGroupingChange}
          role="checkbox"
        />
      </div>
      <div
        css={[
          classes.margin2,
          !isSingleAccount ? classes.primaryDarkBlue : classes.tertiaryGray1
        ]}
      >
        Account
      </div>
    </div>
  )
}

export default AccountToggle
