import { DateRangeType, Label, Stack } from '@fluentui/react'
import { format } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { buttonStyles } from '../../components/shared/Buttons'
import DatePicker from '../../components/shared/DatePicker'
import { useRevenueDetailFilterStore } from '../../features/Revenue/store'

const formatDate = (date: Date | undefined) => {
  return date ? format(date, `MMM yyyy`) : ''
}

export const RevenueDetailListDatePicker: React.FC<{
  defaultStartDate: Date
  defaultEndDate: Date
  minDate: Date
  maxDate: Date
}> = ({ defaultStartDate, defaultEndDate, minDate, maxDate }) => {
  const { filters, setFilters } = useRevenueDetailFilterStore()

  const [[startDate, endDate], setDate] = useState([
    defaultStartDate,
    defaultEndDate
  ])
  useEffect(() => {
    setDate([defaultStartDate, defaultEndDate])
  }, [defaultStartDate, defaultEndDate])

  const [chosenStartDate, setChosenStartDate] = useState<Date | undefined>(
    startDate
  )
  const [chosenEndDate, setChosenEndDate] = useState<Date | undefined>(endDate)

  const onCustomSubmit = useCallback(() => {
    if (!startDate || !endDate) {
      return
    }
    setDate([startDate, endDate])
    setChosenStartDate(startDate)
    setChosenEndDate(endDate)
    setFilters({
      ...filters,
      periodStart: startDate,
      periodEnd: endDate
    })
  }, [endDate, filters, setFilters, startDate])
  const onStartDateChange = useCallback(
    (date?: Date | null) => {
      if (!date) {
        return
      }
      setChosenStartDate(date)
      setDate([date, endDate])
    },
    [endDate]
  )
  const onEndDateChange = useCallback(
    (date?: Date | null) => {
      if (!date) {
        return
      }
      setChosenEndDate(date)
      setDate([startDate, date])
    },
    [startDate]
  )
  return (
    <div>
      <Label>Date Range</Label>
      <Stack horizontal={true} tokens={{ childrenGap: '5px' }}>
        <DatePicker
          placeholder="Start Date"
          value={startDate}
          onSelectDate={onStartDateChange}
          calendarProps={{
            dateRangeType: DateRangeType.Month,
            isDayPickerVisible: false
          }}
          formatDate={formatDate}
          minDate={minDate}
          maxDate={
            format(endDate, `MMM yyyy`) !== format(startDate, `MMM yyyy`)
              ? endDate
              : maxDate
          }
          style={{ width: '110px' }}
        />
        <DatePicker
          placeholder="End Date"
          value={endDate}
          onSelectDate={onEndDateChange}
          calendarProps={{
            dateRangeType: DateRangeType.Month,
            isDayPickerVisible: false
          }}
          formatDate={formatDate}
          minDate={
            format(endDate, `MMM yyyy`) !== format(startDate, `MMM yyyy`)
              ? startDate
              : minDate
          }
          maxDate={maxDate}
          style={{ width: '110px' }}
        />
        <button
          css={[buttonStyles.primary]}
          disabled={!chosenStartDate || !chosenEndDate}
          onClick={onCustomSubmit}
        >
          Apply
        </button>
      </Stack>
    </div>
  )
}
