import { css } from '@emotion/react'

export const detailTable = {
  detailTable: css({
    borderCollapse: 'collapse',
    width: '100%',
    fontSize: '13px',
    fontWeight: 500,
    tableLayout: 'fixed',
    ['th']: {
      padding: '20px 10px',
      backgroundColor: '#EDF1F4',
      textAlign: 'left',
      verticalAlign: 'top'
    },
    ['tr']: {
      '&:nth-of-type(even)': {
        backgroundColor: '#FAFAFA'
      },
      '&:nth-of-type(odd)': {
        backgroundColor: '#FFFFFF'
      },
      border: '1px solid #EBEBEB'
    },
    ['td']: {
      padding: '10px',
      overflow: 'hidden'
    }
  }),
  resizerContainer: css({
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    width: '6px',
    opacity: 0,
    cursor: 'ew-resize',
    ['&:hover']: { opacity: 0.5 }
  }),
  resizer: css({
    position: 'absolute',
    top: 5,
    bottom: 5,
    right: 3,
    background: 'black',
    width: '1px',
    opacity: 1
  }),
  isResizing: css({
    opacity: 1,
    boxShadow: 'black 0px 0px 10px'
  }),
  ellipsis: css({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }),
  gainLossesWrapper: css({
    table: {
      ['tfoot']: {
        ['tr']: {
          minHeight: '260px !important',
          ['td']: {
            background: 'none'
          }
        }
      }
    }
  }),
  rightAlign: css({
    textAlign: 'end'
  }),
  leftRightPadding: css({
    ['tr']: {
      ['td']: {
        '&:first-child': {
          paddingLeft: '25px',
          paddingRight: 0
        },
        '&:last-child': {
          paddingRight: '25px',
          paddingLeft: 0
        }
      },
      ['th']: {
        '&:first-child': {
          paddingLeft: '25px',
          paddingRight: 0
        },
        '&:last-child': {
          paddingRight: '25px',
          paddingLeft: 0
        }
      }
    }
  })
}
