import { getRMDDetails, IRMDDetailsResult } from 'api/datahub'
import { IOdataRequest } from 'api/odata.types'
import axios, { CancelTokenSource } from 'axios'
import { format } from 'date-fns'
import type exceljs from 'exceljs'
import { groupBy, keyBy, partition, sumBy, uniq } from 'lodash'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { saveBlobAsFile } from 'shared/downloads'
import {
  boldDarkFont,
  fillWhite,
  normalDarkFont,
  thinBorder,
  totalBorder,
  totalRowBorder
} from 'shared/export'
import { isNotNullOrEmpty, isNotNullOrUndefined } from 'shared/guards'
import { getSelectedDomainContextRepCodes } from 'store/context/domain'
import { getRockefellerApiOptions } from 'store/shared'
import { getAllPagedOdataApiResults } from 'store/shared/sagas/odata'
import { call, cancelled, select } from 'typed-redux-saga'

const getDatahubApiOptions = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)
  return [apiOptions, source] as [IApiOptions, CancelTokenSource]
}
export const fetchRMDDetails = function* (request: IOdataRequest) {
  const [apiOptions, cancelTokenSource] = yield* call(getDatahubApiOptions)
  try {
    return yield* call(getRMDDetails, apiOptions, request)
  } finally {
    if (yield* cancelled()) {
      cancelTokenSource.cancel()
    }
  }
}

const loadExceljs = () => import('exceljs')
const formats = {
  ACCOUNTING_NO_DECIMAL: '$#,##0_);($#,##0)',
  ACCOUNTING: '$#,##0.00_);($#,##0.00)',
  PERCENT: '0.00\\%',
  PERCENT_NO_DECIMAL: '0\\%',
  DATE: 'mm/dd/yyyy'
}

type StyleKeys =
  | 'ACCOUNTING'
  | 'PERCENT'
  | 'DATE'
  | 'ACCOUNTING_NO_DECIMAL'
  | 'PERCENT_NO_DECIMAL'

const rowStyle = {
  fill: fillWhite,
  border: thinBorder,
  font: normalDarkFont
}

const totalStyle = {
  font: boldDarkFont,
  border: totalRowBorder,
  fill: fillWhite
}

const styles: Record<StyleKeys, Partial<exceljs.Style>> = {
  ACCOUNTING: { numFmt: formats.ACCOUNTING },
  ACCOUNTING_NO_DECIMAL: { numFmt: formats.ACCOUNTING_NO_DECIMAL },
  PERCENT_NO_DECIMAL: { numFmt: formats.PERCENT_NO_DECIMAL },
  PERCENT: { numFmt: formats.PERCENT },
  DATE: { numFmt: formats.DATE }
}
interface IExcelColumnProps {
  name: string
  type?: string
  width?: number
  value?:
    | string
    | number
    | boolean
    | Date
    | undefined
    | Partial<exceljs.CellFormulaValue>
  style?: Partial<exceljs.Style>
  alignment?: Partial<exceljs.Alignment>
}

interface IExcelColumnConfig<T> extends Exclude<IExcelColumnProps, 'value'> {
  getFooter?: (range: string) => Partial<IExcelColumnProps>
  getValue?: (
    item: T
  ) =>
    | string
    | number
    | boolean
    | Date
    | undefined
    | Partial<exceljs.CellFormulaValue>
    | Partial<exceljs.RichText[]>
    | exceljs.CellRichTextValue
}

export const buildRMDReport = function* () {
  const reps = yield* select(getSelectedDomainContextRepCodes)
  const rmdDetails = yield* call(() =>
    getAllPagedOdataApiResults(
      {
        filters: reps?.length
          ? [`rr1 in (${reps.map((x) => `'${x}'`).join(',')})`]
          : undefined,
        orderby: [{ dataPath: 'accountNumber' }],
        expand: ['account($expand=party)']
      },
      fetchRMDDetails
    )
  )

  const [allDetails, closed] = partition(
    rmdDetails.flatMap((x) => x?.result?.value || []),
    (x) => x.activeFlag === '1' || !!x.priorYrFairMktVal || !!x.totalDistAmt
  )

  const items = Object.entries(
    groupBy(allDetails, (x) =>
      [
        x?.account?.partyID,
        x?.lifeExpectancyValue,
        ['IRAB', 'RTHB'].includes(x?.account?.registrationType || '')
          ? x?.account?.registrationType
          : undefined
      ].filter(isNotNullOrUndefined)
    )
  )
    .map(([, rmds]): (IRMDDetailsResult & { type?: 'total' })[] => {
      const estRMDAmount = sumBy(rmds, (x) => x.estRMDAmount || 0)
      const totalDistAmt = sumBy(rmds, (x) => x.totalDistAmt || 0)
      const estRMDAmountRemain = estRMDAmount - totalDistAmt
      const [first] = rmds
      const {
        ageOfPrimAccntHolder,
        dobOfPrimAcctHolder,
        lifeExpectancyValue,
        headerDate
      } = first

      const registrationTypes = uniq(
        rmds.map((x) => x.account?.registrationType).filter(isNotNullOrEmpty)
      )

      const account = {
        ...first.account,
        registrationType:
          registrationTypes.length === 1 ? registrationTypes[0] : ''
      }

      return [
        ...rmds,
        {
          type: 'total',
          account,
          ageOfPrimAccntHolder,
          dobOfPrimAcctHolder,
          totalDistAmt,
          estRMDAmount,
          estRMDAmountRemain,
          lifeExpectancyValue,
          headerDate,
          teamName: first.teamName,
          primaryCA: first.primaryCA
        }
      ]
    })
    .flat()

  const exceljs = yield* call(loadExceljs)
  // eslint-disable-next-line import/no-named-as-default-member
  const workbook = new exceljs.Workbook()
  const summarySheet = workbook.addWorksheet('RMD Summary', {
    views: [{ state: 'frozen', xSplit: 2, ySplit: 2 }]
  })

  const columns: IExcelColumnConfig<(typeof items)[0]>[] = [
    {
      name: 'Primary Account Holder',
      width: 25,
      style: rowStyle,
      getValue: (item) => item.account?.party?.partyName
    },
    {
      name: 'Account #',
      width: 15,
      style: rowStyle,
      getValue: (item) => item.accountNumber
    },
    {
      name: 'First Name',
      width: 15,
      style: rowStyle,
      getValue: (item) => item.account?.party?.firstName
    },
    {
      name: 'Last Name',
      width: 15,
      style: rowStyle,
      getValue: (item) => item.account?.party?.lastName
    },
    {
      name: 'Suffix',
      width: 10,
      style: rowStyle,
      getValue: (item) => item.account?.party?.suffix
    },
    {
      name: 'Registration and Address',
      width: 35,
      style: rowStyle,
      getValue: (item) =>
        item.type !== 'total'
          ? [
              item.address?.addressLine1,
              item.address?.addressLine2,
              item.address?.city,
              item.address?.state,
              item.address?.postalCode
            ]
              .filter(isNotNullOrEmpty)
              .join(' ')
          : ''
    },
    {
      name: 'FBSI Short Name',
      width: 18,
      style: rowStyle,
      getValue: (item) => (item.type !== 'total' ? item.account?.shortName : '')
    },
    {
      name: 'Reg Type Code',
      width: 16,
      style: rowStyle,
      getValue: (item) => item.account?.registrationType
    },
    {
      name: 'Cash Available to Withdraw',
      width: 25,
      style: { ...rowStyle, ...styles.ACCOUNTING },
      getValue: (item) => item.availToWithdrawCash
    },
    {
      name: 'Age of Primary Account Holder',
      width: 15,
      style: rowStyle,
      getValue: (item) => item.ageOfPrimAccntHolder
    },
    {
      name: 'DOB of Primary Account Holder',
      width: 20,
      style: rowStyle,
      getValue: (item) => item.dobOfPrimAcctHolder
    },
    {
      name: 'Current Distribution Year',
      width: 15,
      style: rowStyle,
      getValue: (item) => item.currentDistYear
    },
    {
      name: 'Account Status',
      width: 15,
      style: rowStyle,
      getValue: (item) =>
        item.type === 'total'
          ? undefined
          : item.activeFlag === '1'
          ? 'Open'
          : 'Closed'
    },
    {
      name: 'Estimated RMD Amount',
      width: 25,
      style: {
        ...rowStyle,
        ...styles.ACCOUNTING,
        alignment: { horizontal: 'right' }
      },
      getValue: (item) =>
        // TODO - check account open date = this year - show not calculated?  show 0.. check if account balance > 0 -- show not calculated
        item.account?.registrationType &&
        ['IRAB', 'RTHB'].includes(item.account.registrationType) &&
        !item.estRMDAmount
          ? 'NOT CALCULATED'
          : item.estRMDAmount
    },
    {
      name: 'YTD Total Distribution Amount',
      width: 30,
      style: { ...rowStyle, ...styles.ACCOUNTING },
      getValue: (item) => item.totalDistAmt
    },
    {
      name: 'Remaining Amount',
      width: 20,
      style: { ...rowStyle, ...styles.ACCOUNTING },
      getValue: (item) => (item.type === 'total' ? item.estRMDAmountRemain : '')
    },
    {
      name: 'Life Expectancy Type Code',
      width: 20,
      style: rowStyle,
      getValue: (item) => item.lifeExpectancyCode
    },
    {
      name: 'Life Expectancy Value',
      width: 20,
      style: rowStyle,
      getValue: (item) => item.lifeExpectancyValue
    },
    {
      name: 'Periodic Distribution Plan Indicator',
      width: 20,
      style: rowStyle,
      getValue: (item) => item.prdicDistPlanIndicator
    },
    {
      name: 'Expected Remaining Periodic Pymt Amnt',
      width: 20,
      style: { ...rowStyle, ...styles.ACCOUNTING },
      getValue: (item) => item.expctedRemainPrdicPymtAmt
    },
    {
      name: 'Prior Fair Market Value Year',
      width: 20,
      style: rowStyle,
      getValue: (item) => item.priorFairMktValYr
    },
    {
      name: 'Prior Year Fair Market Value',
      width: 20,
      style: { ...rowStyle, ...styles.ACCOUNTING },
      getValue: (item) => item.priorYrFairMktVal
    },
    {
      name: 'Total Account Value',
      width: 20,
      style: { ...rowStyle, ...styles.ACCOUNTING },
      getValue: (item) => item.totalAcctValue
    },
    {
      name: 'RMD Mandatory Withdrawal Date',
      width: 20,
      style: rowStyle,
      getValue: (item) => item.rmdMandWithDate
    },
    {
      name: 'Prior Year Total Distribution Amount',
      width: 20,
      style: { ...rowStyle, ...styles.ACCOUNTING },
      getValue: (item) => item.priorYrTotalDistAmt
    },
    {
      name: 'Date of Death (Primary)',
      width: 20,
      style: rowStyle,
      getValue: (item) => item.dateOfDeathPrimary
    },
    {
      name: 'RR1',
      width: 10,
      style: rowStyle,
      getValue: (item) => item.rr1
    },
    {
      name: 'As of Date',
      width: 11,
      style: rowStyle,
      getValue: (item) =>
        item?.headerDate &&
        format(parseDateISOStringInLocalTimezone(item.headerDate), 'MM/dd/yyyy')
    },
    {
      name: 'Account Opened Date',
      width: 11,
      style: rowStyle,
      getValue: (item) =>
        item.account?.establishDate && item.type !== 'total'
          ? format(
              parseDateISOStringInLocalTimezone(item.account?.establishDate),
              'MM/dd/yyyy'
            )
          : ''
    },
    {
      name: 'Decedent Name',
      width: 20,
      style: rowStyle,
      getValue: (item) => item?.originalOwner?.partyName
    },
    {
      name: 'Decedent Date of Birth',
      width: 20,
      style: rowStyle,
      getValue: (item) =>
        item?.originalOwner?.dateOfBirth &&
        format(
          parseDateISOStringInLocalTimezone(item.originalOwner.dateOfBirth),
          'MM/dd/yyyy'
        )
    },
    {
      name: 'Decedent Date of Death',
      width: 20,
      style: rowStyle,
      getValue: (item) =>
        item?.originalOwner?.dateOfDeath &&
        format(
          parseDateISOStringInLocalTimezone(item.originalOwner.dateOfDeath),
          'MM/dd/yyyy'
        )
    },
    {
      name: 'Team Name',
      width: 15,
      style: rowStyle,
      getValue: (item) => item?.teamName
    },
    {
      name: 'Primary CA',
      width: 15,
      style: rowStyle,
      getValue: (item) => item?.primaryCA
    }
  ]

  const columnLookup = keyBy(columns, (x) => x.name)
  const summaryColumns = [
    'Primary Account Holder',
    'First Name',
    'Last Name',
    'Suffix',
    'Age of Primary Account Holder',
    'DOB of Primary Account Holder',
    'Life Expectancy Value',
    'Reg Type Code',
    'Estimated RMD Amount',
    'YTD Total Distribution Amount',
    'Remaining Amount',
    'As of Date',
    'Team Name',
    'Primary CA'
  ].map((x) => columnLookup[x])

  summarySheet.columns = summaryColumns.map(
    ({ name, width }): Partial<exceljs.Column> => ({
      header: name,
      key: name,
      width
    })
  )

  summarySheet.autoFilter = {
    from: {
      row: 2,
      column: 1
    },
    to: {
      row: 2,
      column: summarySheet.columns.length
    }
  }
  const allTotals = items.filter((x) => x.type === 'total')

  const summaryDataRows = allTotals?.map((item) =>
    summaryColumns.map(
      (column): Partial<exceljs.Cell> => ({
        value: column.getValue?.(item) as any,
        style: column.style,
        alignment: column.style?.alignment
      })
    )
  )

  summaryDataRows?.forEach((dataRow, j) => {
    const sheetRow = summarySheet.getRow(j + 2)
    dataRow.forEach((cell, i) => {
      const sheetCell = sheetRow.getCell(i + 1)
      sheetCell.value = cell.value
      sheetCell.style = cell.style || {}
      sheetCell.alignment = cell.alignment || {}
    })
  })

  summarySheet.getRow(1).eachCell((cell) => {
    cell.font = boldDarkFont
    cell.border = totalBorder
  })

  const disclaimerText = 'For internal and advisor use only'
  const summaryDisclaimer = summarySheet.insertRow(1, []).getCell(1)
  summaryDisclaimer.value = disclaimerText
  summaryDisclaimer.font = boldDarkFont

  const worksheet = workbook.addWorksheet('RMD Detail', {
    views: [{ state: 'frozen', xSplit: 2, ySplit: 2 }]
  })

  worksheet.columns = columns.map(
    ({ name, width }): Partial<exceljs.Column> => ({
      header: name,
      key: name,
      width
    })
  )

  worksheet.autoFilter = {
    from: {
      row: 2,
      column: 1
    },
    to: {
      row: 2,
      column: worksheet.columns.length
    }
  }

  const dataRows = items?.map((item) =>
    columns.map((column): Partial<exceljs.Cell> => {
      const style = {
        ...column.style,
        ...(item.type === 'total' ? totalStyle : {})
      }
      return {
        value: column.getValue?.(item) as any,
        style,
        alignment: column.style?.alignment
      }
    })
  )

  dataRows?.forEach((dataRow, i) => {
    const sheetRow = worksheet.getRow(i + 2)
    dataRow.forEach((cell, i) => {
      const sheetCell = sheetRow.getCell(i + 1)
      sheetCell.value = cell.value
      sheetCell.style = cell.style || {}
      sheetCell.alignment = cell.alignment || {}
    })
  })

  worksheet.getRow(1).eachCell((cell) => {
    cell.font = boldDarkFont
    cell.border = totalBorder
  })

  const detailDisclaimer = worksheet.insertRow(1, []).getCell(1)
  detailDisclaimer.value = disclaimerText
  detailDisclaimer.font = boldDarkFont

  if (closed.length) {
    const closedWs = workbook.addWorksheet('Closed Accounts')
    closedWs.columns = columns.map(
      ({ name, width }): Partial<exceljs.Column> => ({
        header: name,
        key: name,
        width
      })
    )

    closedWs.autoFilter = {
      from: {
        row: 2,
        column: 1
      },
      to: {
        row: 2,
        column: closedWs.columns.length
      }
    }

    const closedRows = closed?.map((item) =>
      columns.map((column): Partial<exceljs.Cell> => {
        const style = column.style
        return {
          value: column.getValue?.(item) as any,
          style,
          alignment: column.style?.alignment
        }
      })
    )

    closedRows?.forEach((dataRow, i) => {
      const sheetRow = closedWs.getRow(i + 2)
      dataRow.forEach((cell, i) => {
        const sheetCell = sheetRow.getCell(i + 1)
        sheetCell.value = cell.value
        sheetCell.style = cell.style || {}
        sheetCell.alignment = cell.alignment || {}
      })
    })

    closedWs.getRow(1).eachCell((cell) => {
      cell.font = boldDarkFont
      cell.border = totalBorder
    })

    const closedDisclaimer = closedWs.insertRow(1, []).getCell(1)
    closedDisclaimer.value =
      'The following are closed accounts with no Prior Year Fair Market Value or YTD Total Distribution Amount.  Closed accounts with a Prior Year Fair Market Value or a YTD Total Distribution Amount are still shown in the RMD Detail tab.'
    closedDisclaimer.font = boldDarkFont
  }

  const download = async () => {
    const base64 = await workbook.xlsx.writeBuffer({
      base64: true
    } as any)

    const blob = new Blob([base64], { type: 'application/octet-stream' })

    saveBlobAsFile(blob, `RMD ${format(new Date(), 'MM-dd-yyyy')}.xlsx`)
  }

  yield* call(download)
}
