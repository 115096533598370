import { flow } from 'lodash/fp'
import { createSelector } from 'reselect'
import { darkTheme, lightTheme } from 'shared/services/theme'
import { getPreferenceUserPreferences } from '../user/selectors'

const themes = {
  light: lightTheme,
  dark: darkTheme
}

export const getThemeType = createSelector(
  getPreferenceUserPreferences,
  (x) => x?.themeType || 'light'
)

export const getTheme = createSelector(
  [getThemeType],
  (themeType = 'light') => themes[themeType]
)

export const getThemePreference = flow(
  getPreferenceUserPreferences,
  (x) => x?.themeType
)
