import axios from 'axios'
import { IClient } from '../../../../../../../api/client.types'
import { ISearchResult } from '../../../../../../../api/common.types'
import { IApiOptions } from '../../../../../../../shared/contracts/IApiOptions'
import { ITrustInfo } from '../store/types'

export const fetchTrustInfoListSvc = (
  accountid: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/trusts/${accountid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<ITrustInfo>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchClientFinanceDetailsSvc = (
  legalEntityId: string,
  options: IApiOptions
) => {
  const select = `select=ausHistory,ClientKPI,LegalEntityID,LegalEntityName,BusinessSegment`
  const filter = `$filter=LegalEntityID eq '${legalEntityId}'`
  const url = `${options.apiRoot}/search/clients?queryType=full&${select}&${filter}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<ISearchResult<IClient>>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
