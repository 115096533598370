import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAppConfig } from 'shared/config/useAppConfig'
import { RdotUserRoleEnum } from '../../store/user/rdotUser'
import { getRdotUserRoles } from '../../store/user/selectors'

export const HomeContainer: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userRoles = useSelector(getRdotUserRoles)
  const { navItems } = useAppConfig()
  const [firstRole] = userRoles || []

  useEffect(() => {
    if (firstRole === RdotUserRoleEnum.Accounting_Clients) {
      navigate('/payments', { replace: true })
      return
    }

    if (firstRole === RdotUserRoleEnum.Accounting_Trusts) {
      navigate('/trusts', { replace: true })
      return
    }

    if (firstRole === RdotUserRoleEnum.Finance_FeeAdmin) {
      navigate('/fees', { replace: true })
      return
    }

    const navItemsWithPath = navItems.filter((x) => !!x.path)
    if (!navItemsWithPath.length) {
      return
    }

    navigate(navItemsWithPath[0].path || '/advisory/dashboard', {
      replace: true
    })
  }, [dispatch, firstRole, navItems, navigate])

  return <div />
}
