export interface PerformanceData {
  '@search.score': number
  GroupAccountId: string
  Id: string
  HouseholdId: string
  HouseholdName: string
  EntityType: string
  Name: string
  softdelete: string
  Type: string
  Source: string
  createdDate: string
  lastUpdatedDate: string
  LastUpdatedAt: string
  Account: string[]
  Parties: Parties
  Advisors: Advisor[]
  override99: boolean
  PreDayCPerformance: Performance
  PreDayMPerformance: Performance
  PreDayUMPerformance: Performance
  MTDCPerformance: Performance
  MTDMPerformance: Performance
  MTDUMPerformance: Performance
  YTDCPerformance: Performance
  YTDMPerformance: Performance
  YTDUMPerformance: Performance
  QTDCPerformance: Performance
  QTDMPerformance: Performance
  QTDUMPerformance: Performance
  ITDCPerformance: Performance
  ITDMPerformance: Performance
  ITDUMPerformance: Performance
  [key: string]: any
}

export interface Parties {
  LegalEntityId: string[]
  LegalEntityName: string[]
}

export interface Advisor {
  ClientAdvisorID: string
  ClientAdvisor: string
  ClientAdvisorTeam: string
  HubName: string
}

export interface Performance {
  StartDate: string
  EndDate: string
  StartValue: number
  EndValue: number
  Inflows: number
  Outflows: number
  Netflows: number
  NetInvestmentGain: number
  NetTWR: number
  NetIrr: number
  InterestAndDividends: number
  grossTWR: number
  grossIRR: number
  [key: string]: any
}

export enum IncomeReturnType {
  NetTWR = 'NetTWR',
  NetIrr = 'NetIrr'
}

export enum EntityType {
  singleAccount = 'Single Account',
  group = 'Group'
}

export enum PerformanceDateRange {
  monthToDate = 'MTD',
  quarterToDate = 'QTD',
  yearToDate = 'YTD',
  inceptionToDate = 'ITD'
}

export enum PerformanceType {
  combined = 'C',
  managed = 'M',
  unmanaged = 'UM'
}
