import { Link } from '@fluentui/react'
import { intersectionWith, sumBy } from 'lodash'
import { useDomainStore } from '../../../../../../../../features/Domain/store/domain'
import { USDCell } from '../../../../../../../../features/Lists/core/components/USDCell'
import { IEnrichedCreditEvent } from './CreditEvent'
import { RecipientHoverCard } from './RecipientHoverCard'

export const AllocatedAmountCell: React.FC<{
  creditEvent: IEnrichedCreditEvent
}> = ({ creditEvent }) => {
  const { rep, recipients, event } = creditEvent
  const {
    selectedAdvisorRepIds,
    accountRepsWithSplitVisibilityLookup,
    selectedAdvisorRepIdsLookup
  } = useDomainStore()

  const accountRepWithSplitVisibility =
    accountRepsWithSplitVisibilityLookup[rep?.repId || '']

  const { personalRepsInSplit, userHasAccessToSplits } =
    accountRepWithSplitVisibility || {}
  const selectedRecipients = recipients?.filter((recipient) =>
    selectedAdvisorRepIds?.includes(recipient.recipientId || '')
  )

  const total = selectedRecipients?.length
    ? sumBy(selectedRecipients, ({ allocatedAmount = 0 }) => allocatedAmount)
    : selectedAdvisorRepIds?.length
    ? undefined
    : event?.allocatedAmount

  const hoverRecipients = userHasAccessToSplits
    ? recipients
    : intersectionWith(
        recipients,
        personalRepsInSplit || [],
        (a, b) => a.recipientId === b.id
      )

  return hoverRecipients?.length && total !== undefined ? (
    <RecipientHoverCard
      recipients={hoverRecipients}
      type={'allocatedAmount'}
      selectedLookup={selectedAdvisorRepIdsLookup}
    >
      <Link>
        <USDCell value={total} />
      </Link>
    </RecipientHoverCard>
  ) : (
    <USDCell value={total} />
  )
}
