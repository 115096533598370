import axios from 'axios'
import { flow } from 'lodash/fp'
import { call, cancelled, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../../store/shared/sagas'
import { getTeamsModuleState } from './shared'
import { getTeamAnalytics, ITeamAnalyticsResponse } from './teamAnalyticsApi'

export const teamAnalyticsFetchActions = createAsyncAction(
  '@modules/@teams/@teamAnalyticsFetch/REQUEST',
  '@modules/@teams/@teamAnalyticsFetch/SUCCESS',
  '@modules/@teams/@teamAnalyticsFetch/FAILURE'
)<Date | undefined, ITeamAnalyticsResponse, Error>()

export const teamAnalyticsFetchReducer = createAsyncReducer(
  teamAnalyticsFetchActions
)

const getRootSelector = flow(getTeamsModuleState, (x) => x?.teamAnalyticsFetch)

export const {
  getError: getTeamAnalayticsFetchError,
  getIsLoading: getIsTeamAnalayticsFetchLoading,
  getResult: getTeamAnalayticsFetchResult
} = createAsyncSelectors(getRootSelector)

export const getTeamAnalyticsTeams = flow(
  getTeamAnalayticsFetchResult,
  (x) => x?.teams
)
export const getTeamAnalyticsAsOfDate = flow(
  getTeamAnalayticsFetchResult,
  (x) => x?.asOfDate
)
export const getTeamAnalyticsTotal = flow(
  getTeamAnalayticsFetchResult,
  (x) => x?.total
)

export const onRequest = function* (
  action: ReturnType<typeof teamAnalyticsFetchActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  try {
    const apiOptions = yield* call(getRockefellerApiOptions)

    const result = action.payload
      ? yield* call(getTeamAnalytics, apiOptions, action.payload)
      : yield* call(getTeamAnalytics, apiOptions)

    yield put(teamAnalyticsFetchActions.success(result))
  } catch (e: unknown) {
    yield put(teamAnalyticsFetchActions.failure(e as Error))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const teamAnalyticsFetchSagas = [
  () => takeLatest(teamAnalyticsFetchActions.request, onRequest)
]
