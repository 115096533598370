import { cloneDeep, flow } from 'lodash'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { IHurdle } from '../../../../../../../../../api/datahub'
import { AppState } from '../../../../../../../../../store'

const UPDATE_HURDLE = '@modules/@teams/@hurdleEditForm/UPDATE_HURDLE'
const RESET = '@modules/@teams/@hurdleEditForm/RESET'

export const hurdleEditFormActions = {
  updateHurdle: createAction(UPDATE_HURDLE)<IHurdle>(),
  reset: createAction(RESET)()
}

export type HurdleEditFormActionTypes = ActionType<typeof hurdleEditFormActions>

export interface IHurdleEditFormState {
  hurdle: IHurdle
}

const defaultHurdle: IHurdle = {
  measurements: [
    {
      intervalOfMeasurement: 'Annual',
      metrics: [
        {
          metricType: 'T-12 From Hurdle',
          payouts: []
        }
      ]
    }
  ],
  entityType: 'Team',
  completionType: 'OneAndDone'
}

const initialState: IHurdleEditFormState = {
  hurdle: cloneDeep(defaultHurdle)
}

export const hurdleEditFormReducer = createReducer<
  IHurdleEditFormState,
  HurdleEditFormActionTypes
>(initialState)
  .handleAction(hurdleEditFormActions.updateHurdle, (state, action) => ({
    ...state,
    hurdle: action.payload
  }))
  .handleAction(hurdleEditFormActions.reset, () => ({
    ...initialState,
    hurdle: cloneDeep(defaultHurdle)
  }))

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.teams.modules.hurdles.features.hurdleEdit
    .hurdleEditForm

export const getHurdleGroup = flow(rootSelector, ({ hurdle }) => hurdle)
