import { ActionType, createAction } from 'typesafe-actions'
import {
  IAllCashReceipt,
  IAllocatedAmount,
  ICashReceiptExportReq,
  IDownloadFileRequest,
  IProductType,
  IUploadCheckImageRequest,
  IUploadedFiles
} from './types'

export const FETCH_ALL_CASH_RECEIPTS =
  '@modules/@products/FETCH_ALL_CASH_RECEIPTS'
export const FETCH_ALL_CASH_RECEIPTS_SUCCESS =
  '@modules/@products/FETCH_ALL_CASH_RECEIPTS_SUCCESS'
export const FETCH_ALL_CASH_RECEIPTS_FAILURE =
  '@modules/@products/FETCH_ALL_CASH_RECEIPTS_FAILURE'

export const FETCH_ALL_PRODUCT_TYPE =
  '@modules/@products/FETCH_ALL_PRODUCT_TYPE'
export const FETCH_ALL_PRODUCT_TYPE_SUCCESS =
  '@modules/@products/FETCH_ALL_PRODUCT_TYPE_SUCCESS'
export const FETCH_ALL_PRODUCT_TYPE_FAILURE =
  '@modules/@products/FETCH_ALL_PRODUCT_TYPE_FAILURE'

export const UPDATE_ALL_CASH_RECEIPT =
  '@modules/@products/UPDATE_ALL_CASH_RECEIPT'
export const UPDATE_ALL_CASH_RECEIPT_SUCCESS =
  '@modules/@products/UPDATE_ALL_CASH_RECEIPT_SUCCESS'
export const UPDATE_ALL_CASH_RECEIPT_FAILURE =
  '@modules/@products/UPDATE_ALL_CASH_RECEIPT_FAILURE'

export const UPLOAD_CHECK_IMAGE = `@modules/@products/UPLOAD_CHECK_IMAGE`

export const VIEW_CASHRECEIPT_CHECKIMAGE = `@modules/@products/VIEW_CASHRECEIPT_CHECKIMAGE`
export const VIEW_CASHRECEIPT_CHECKIMAGE_SUCCESS = `@modules/@products/VIEW_CASHRECEIPT_CHECKIMAGE_SUCCESS`
export const VIEW_CASHRECEIPT_CHECKIMAGE_FAILURE = `@modules/@products/VIEW_CASHRECEIPT_CHECKIMAGE_FAILURE`

export const DELETE_DOCUMENT = `@modules/@products/DELETE_DOCUMENT`
export const DELETE_DOCUMENT_SUCCESS = `@modules/@products/DELETE_DOCUMENT_SUCCESS`
export const DELETE_DOCUMENT_FAILURE = `@modules/@products/DELETE_DOCUMENT_FAILURE`

export const SET_SELECTED_MONTH_YEAR_CASHRECEIPT_SETTING = `@modules/@products/SET_SELECTED_MONTH_YEAR_CASHRECEIPT_SETTING`
export const SET_SELECTED_CASH_RECEIPT = `@modules/@products/SET_SELECTED_CASH_RECEIPT`
export const SET_UPDATED_CASH_RECEIPT_FLAG = `@modules/@products/SET_UPDATED_CASH_RECEIPT_FLAG`

export const RESET_UPDATE_CASH_RECEIPT_BY_PROVIDER_STATE = `@modules/@products/RESET_UPDATE_CASH_RECEIPT_BY_PROVIDER_STATE`

export const EXPORT_CASHRECEIPT = `@modules/@products/EXPORT_CASHRECEIPT`
export const EXPORT_CASHRECEIPT_SUCCESS = `@modules/@products/EXPORT_CASHRECEIPT_SUCCESS`
export const EXPORT_CASHRECEIPT_FAILURE = `@modules/@products/EXPORT_CASHRECEIPT_FAILURE`

export const UPDATE_CASH_RECEIPT_BY_PROVIDER = `@modules/@products/UPDATE_CASH_RECEIPT_BY_PROVIDER`
export const UPDATE_CASH_RECEIPT_BY_PROVIDER_SUCCESS = `@modules/@products/UPDATE_CASH_RECEIPT_BY_PROVIDER_SUCCESS`
export const UPDATE_CASH_RECEIPT_BY_PROVIDER_FAILURE = `@modules/@products/UPDATE_CASH_RECEIPT_BY_PROVIDER_FAILURE`

export const fetchAllCashReceiptsActions = {
  request: createAction(FETCH_ALL_CASH_RECEIPTS)<string>(),
  success: createAction(FETCH_ALL_CASH_RECEIPTS_SUCCESS)<IAllCashReceipt[]>(),
  failure: createAction(FETCH_ALL_CASH_RECEIPTS_FAILURE)<Error>()
}

export const fetchAllProductTypeActions = {
  request: createAction(FETCH_ALL_PRODUCT_TYPE)(),
  success: createAction(FETCH_ALL_PRODUCT_TYPE_SUCCESS)<IProductType[]>(),
  failure: createAction(FETCH_ALL_PRODUCT_TYPE_FAILURE)<Error>()
}

export const updateAllCashReceiptTypeActions = {
  request: createAction(UPDATE_ALL_CASH_RECEIPT)<{
    cashReceipts: IAllCashReceipt[]
    selectedMonthYear: string
  }>(),
  success: createAction(UPDATE_ALL_CASH_RECEIPT_SUCCESS)(),
  failure: createAction(UPDATE_ALL_CASH_RECEIPT_FAILURE)<Error>()
}

export const uploadCheckImageActions = {
  uploadDoc: createAction(UPLOAD_CHECK_IMAGE)<IUploadCheckImageRequest>()
}

export const viewCashReceiptCheckImageActions = {
  request: createAction(VIEW_CASHRECEIPT_CHECKIMAGE)<IDownloadFileRequest>(),
  success: createAction(VIEW_CASHRECEIPT_CHECKIMAGE_SUCCESS)(),
  failure: createAction(VIEW_CASHRECEIPT_CHECKIMAGE_FAILURE)<Error>()
}
export const deleteDocumentActions = {
  request: createAction(DELETE_DOCUMENT)<{
    cashreceiptdocid: string
    productTypeid: string
    cashReceiptid: string
  }>(),
  success: createAction(DELETE_DOCUMENT_SUCCESS)(),
  failure: createAction(DELETE_DOCUMENT_FAILURE)<Error>()
}

export const setSelectedMonthYearCashReceiptSettingActions = {
  selectedMonthYear: createAction(
    SET_SELECTED_MONTH_YEAR_CASHRECEIPT_SETTING
  )<string>()
}
export const setSelectedCashReceiptActions = {
  selectedCashReceipt: createAction(
    SET_SELECTED_CASH_RECEIPT
  )<IAllCashReceipt>(),
  updatedCashReceiptFlag: createAction(SET_UPDATED_CASH_RECEIPT_FLAG)<boolean>()
}
export const resetupdateCashReceiptByProviderStateActions = {
  reset: createAction(RESET_UPDATE_CASH_RECEIPT_BY_PROVIDER_STATE)()
}
export const FETCH_UPLOADED_FILES = `@modules/@products/FETCH_UPLOADED_FILES`
export const FETCH_UPLOADED_FILES_SUCCESS = `@modules/@products/FETCH_UPLOADED_FILES_SUCCESS`
export const FETCH_UPLOADED_FILES_FAILURE = `@modules/@products/FETCH_UPLOADED_FILES_FAILURE`

export const fetchUploadedFilesAction = {
  request: createAction(FETCH_UPLOADED_FILES)<{
    cashReceiptid: string
    productTypeid: string
  }>(),
  success: createAction(FETCH_UPLOADED_FILES_SUCCESS)<IUploadedFiles[]>(),
  failure: createAction(FETCH_UPLOADED_FILES_FAILURE)<Error>()
}
export const exportCashReceiptToExcelActions = {
  request: createAction(EXPORT_CASHRECEIPT)<ICashReceiptExportReq>(),
  success: createAction(EXPORT_CASHRECEIPT_SUCCESS)(),
  failure: createAction(EXPORT_CASHRECEIPT_FAILURE)<Error>()
}

export const updateCashReceiptByProviderActions = {
  request: createAction(UPDATE_CASH_RECEIPT_BY_PROVIDER)<{
    cashReceipt: IAllCashReceipt
  }>(),
  success: createAction(UPDATE_CASH_RECEIPT_BY_PROVIDER_SUCCESS)(),
  failure: createAction(UPDATE_CASH_RECEIPT_BY_PROVIDER_FAILURE)<Error>()
}

export const FETCH_ALLOCATED_AMOUNT = `@modules/@products/FETCH_ALLOCATED_AMOUNT`
export const FETCH_ALLOCATED_AMOUNT_SUCCESS = `@modules/@products/FETCH_ALLOCATED_AMOUNT_SUCCESS`
export const FETCH_ALLOCATED_AMOUNT_FAILURE = `@modules/@products/FETCH_ALLOCATED_AMOUNT_FAILURE`
export const FETCH_ALLOCATED_AMOUNT_CLEAR = `@modules/@products/FETCH_ALLOCATED_AMOUNT_CLEAR`

export const fetchAllocatedAmountActions = {
  request: createAction(FETCH_ALLOCATED_AMOUNT)<{
    cashReceipt: IAllCashReceipt
    selectedMonth: string | undefined
  }>(),
  success: createAction(FETCH_ALLOCATED_AMOUNT_SUCCESS)<IAllocatedAmount[]>(),
  failure: createAction(FETCH_ALLOCATED_AMOUNT_FAILURE)<Error>(),
  clear: createAction(FETCH_ALLOCATED_AMOUNT_CLEAR)()
}

export type fetchAllCashReceiptsActionTypes = ActionType<
  typeof fetchAllCashReceiptsActions
>

export type fetchAllProductTypeActionTypes = ActionType<
  typeof fetchAllProductTypeActions
>

export type updateAllCashReceiptActionTypes = ActionType<
  typeof updateAllCashReceiptTypeActions
>

export type uploadCheckImageActionTypes = ActionType<
  typeof uploadCheckImageActions
>

export type viewCashReceiptCheckImageActionTypes = ActionType<
  typeof viewCashReceiptCheckImageActions
>
export type deleteDocumentActionTypes = ActionType<typeof deleteDocumentActions>
export type setSelectedMonthYearCashReceiptSettingActionTypes = ActionType<
  typeof setSelectedMonthYearCashReceiptSettingActions
>
export type setSelectedCashReceiptActionTypes = ActionType<
  typeof setSelectedCashReceiptActions
>
export type FetchUploadedFilesAction = ActionType<
  typeof fetchUploadedFilesAction
>
export type exportCashReceiptToExcelActionTypes = ActionType<
  typeof exportCashReceiptToExcelActions
>
export type updateCashReceiptByProviderActionTypes = ActionType<
  typeof updateCashReceiptByProviderActions
>
export type resetupdateCashReceiptByProviderStateActionTypes = ActionType<
  typeof resetupdateCashReceiptByProviderStateActions
>

export type fetchAllocatedAmountActionTypes = ActionType<
  typeof fetchAllocatedAmountActions
>

export type AllCashReceiptActionTypes =
  | fetchAllCashReceiptsActionTypes
  | fetchAllProductTypeActionTypes
  | updateAllCashReceiptActionTypes
  | uploadCheckImageActionTypes
  | viewCashReceiptCheckImageActionTypes
  | setSelectedMonthYearCashReceiptSettingActionTypes
  | FetchUploadedFilesAction
  | deleteDocumentActionTypes
  | exportCashReceiptToExcelActionTypes
  | setSelectedCashReceiptActionTypes
  | updateCashReceiptByProviderActionTypes
  | resetupdateCashReceiptByProviderStateActionTypes
  | fetchAllocatedAmountActionTypes
