import { ITheme } from '@fluentui/react'
import { constants } from 'modules/Advisory/shared/theme'
import React, { PropsWithChildren } from 'react'
import { useClasses } from 'shared/hooks/useClasses'

const getThemedClasses = (theme: ITheme) => ({
  header: {
    backgroundColor: theme.semanticColors.bodyBackground,
    borderBottom: `solid 1px ${theme.semanticColors.bodyFrameDivider}`,
    position: 'sticky',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 4,
    height: constants.headerHeightPx,
    maxHeight: constants.headerHeightPx
  }
})

export const HeaderWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const classes = useClasses(getThemedClasses)
  return <header className={classes.header}>{children}</header>
}
