import { Link } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { Row } from '@tanstack/react-table'
import { useRef } from 'react'
import { isNotNullOrUndefined } from 'shared/guards'
import { HighlightSearchText } from '../../../../components/shared/DetailTables/HighlightSearchText'
import { rdot360TableStyles } from '../../../../shared/tableStyles'
import { ICategoryPosition } from '../../../../store/holdingsApi/ICategoryPositionResponse'
import { useInvestmentsDetailsUIState } from '../../InvestmentsDetailsUIState'
import { CalloutWrapper } from './CalloutWrapper'
import { AssetClassEnum } from './ColumnDefinitions'

export const SymbolDescriptionWithCallout: React.FC<{
  row: Row<ICategoryPosition>
  isPrint?: boolean
}> = ({ row, isPrint }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false)
  const { searchText } = useInvestmentsDetailsUIState()
  const { cusip } = row.original
  const { assetcat: assetClass, secname: description } =
    row.original.secinfo || {}

  const hasCallout =
    !isPrint &&
    cusip &&
    assetClass &&
    Object.values(AssetClassEnum).includes(assetClass as AssetClassEnum)

  if (!hasCallout) {
    return (
      <div title={description} css={rdot360TableStyles.nowrap}>
        <HighlightSearchText text={description} search={searchText} />
      </div>
    )
  }

  const leafRows = row.getLeafRows().filter((leaf) => !leaf.getIsGrouped())
  const accountKeys = leafRows
    .map((leaf) => leaf.original.acctkey)
    .filter(isNotNullOrUndefined)

  return (
    <Link
      ref={containerRef}
      onClick={toggleIsCalloutVisible}
      title={description}
      underline
      styles={{
        root: {
          ...rdot360TableStyles.nowrap,
          overflowX: 'hidden'
        }
      }}
    >
      <HighlightSearchText text={description} search={searchText} />
      {isCalloutVisible && (
        <CalloutWrapper
          accountKeys={accountKeys}
          cusip={cusip}
          onDismiss={toggleIsCalloutVisible}
          target={containerRef}
        />
      )}
    </Link>
  )
}
