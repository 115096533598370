import { call, put, takeLatest } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../../../store/shared/sagas'
import { addEditPerson, fetchPersonList } from '../api/personService'
import {
  addEditPersonDataActions,
  personDataActions,
  PersonDataActionTypes
} from './actions'
import { IPersonRequest } from './types'

function* handleFetchPersonList(action: PersonDataActionTypes) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const personRequest = action.payload as IPersonRequest
    const data = yield* call(fetchPersonList, options, personRequest)
    yield put(personDataActions.success(data))
  } catch (e: any) {
    yield put(personDataActions.failure(e))
  }
}
function* handleAddEditPerson(
  action: ReturnType<typeof addEditPersonDataActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(addEditPerson, options, action.payload)
    yield put(addEditPersonDataActions.success(data))
    yield put(personDataActions.request({ pKid: action?.payload?.pKid }))
  } catch (e: any) {
    yield put(addEditPersonDataActions.failure(e))
  }
}
export const sagas = [
  () => takeLatest(personDataActions.request, handleFetchPersonList),
  () => takeLatest(addEditPersonDataActions.request, handleAddEditPerson)
]
