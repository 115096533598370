import { lazy } from 'react'
import { IModuleDefinition } from '../types'

const ExternalManagerContainer = lazy(
  () => import('./ExternalManagerContainer')
)

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/externalmanager/*',
    private: true,
    component: ExternalManagerContainer
  },
  name: 'THIRD PARTY MANAGER FIRMS'
}

export default moduleDefinition
