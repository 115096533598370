import axios from 'axios'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import { IUploadReportRequest, IUploadReportResponse } from '../store/types'

const UploadReportService = (
  options: IApiOptions,
  uploadReportRequest: IUploadReportRequest
) => {
  const url = `${options.apiRoot}/documentsapi/api/Reports/UploadReport`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
    accept: 'application/json'
  }
  const formdata = new FormData()
  formdata.append('filecontents', uploadReportRequest.filecontents)
  formdata.append('email', uploadReportRequest.email)
  formdata.append('foldername', uploadReportRequest.foldername)

  return axios
    .post<IUploadReportResponse>(url, formdata, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
export { UploadReportService }
