import { IColumnDefinition } from 'features/Lists/core/contracts/IColumnDefinition'
import { IListsUiState } from 'features/Lists/core/contracts/IListsUIState'
import { keyBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useSessionStateContainer } from 'store/session'
import { v4 as uuidv4 } from 'uuid'
import {
  IListMetaPreferenceDetails,
  IListPreferences
} from './IListPreferences'
import { getPreLoadListState, getPreSaveListState } from './preferencesService'
import {
  useLazyListPreferenceContainer,
  useListMetaPreferenceContainer
} from './useListPreferenceContainer'

export type ISystemViewDefinition = IListMetaPreferenceDetails & {
  config: Partial<IListsUiState>
}

export interface IListPreferenceHookArgs {
  preferenceKey: string
  systemViews?: ISystemViewDefinition[]
  listState: IListsUiState
  onBeforeSave?: (preferences: IListPreferences) => IListPreferences
  onBeforeApply?: (preferences: IListPreferences) => IListPreferences
  onApply: (listState: Partial<IListsUiState>) => void
}

interface IListSessionState {
  selectedViewId: string
}

const useSessionViewId = (sessionKey: string) => {
  const { updateContainer, value = {} } =
    useSessionStateContainer<IListSessionState>(
      `${sessionKey}/useSessionViewId`
    )

  const { selectedViewId } = value
  const setSelectedViewId = useCallback(
    (newSelectedViewId: string) =>
      updateContainer({ selectedViewId: newSelectedViewId }),
    [updateContainer]
  )

  return {
    selectedViewId,
    setSelectedViewId
  }
}

export interface IUseLazyListPreferencesHookArgs {
  preferenceKey: string
  systemViews?: ISystemViewDefinition[]
  columnDefinitions: Record<string, IColumnDefinition>
  onBeforeSave?: (preferences: IListPreferences) => IListPreferences
  onBeforeApply?: (preferences: IListPreferences) => IListPreferences
  onApply: (listState: Partial<IListsUiState>) => void
}

export const useLazyListPreferences = ({
  preferenceKey,
  systemViews,
  columnDefinitions,
  onBeforeSave,
  onBeforeApply,
  onApply
}: IUseLazyListPreferencesHookArgs) => {
  const key = preferenceKey
  const { selectedViewId, setSelectedViewId } = useSessionViewId(key)

  const { getPreferences, setPreferences, deletePreferences } =
    useLazyListPreferenceContainer(key)

  const systemViewLookup = useMemo(
    () => keyBy(systemViews, ({ key }) => key || ''),
    [systemViews]
  )

  const applyConfig = useCallback(
    (config: IListPreferences) => {
      const updatedConfig = onBeforeApply?.(config) || config
      const preLoadState = getPreLoadListState(
        updatedConfig,
        columnDefinitions || {}
      )

      onApply(preLoadState)
    },
    [columnDefinitions, onApply, onBeforeApply]
  )

  const applySavedView = useCallback(
    async (id: string) => {
      const preferences = await getPreferences(id)
      if (!preferences) {
        return
      }

      applyConfig(preferences)
    },
    [applyConfig, getPreferences]
  )

  const applyManualPreferences = useCallback(
    (preferences: IListPreferences, setDefaultSystemViewId = false) => {
      const defaultSystemView = systemViews?.[0].key
      if (setDefaultSystemViewId && defaultSystemView) {
        setSelectedViewId(defaultSystemView)
      }

      applyConfig(preferences)
    },
    [applyConfig, setSelectedViewId, systemViews]
  )

  const applySystemView = useCallback(
    (id?: string) => {
      const { config } = (id && systemViewLookup[id]) || systemViews?.[0] || {}

      if (!config) {
        return
      }

      const systemViewAsPreferences = getPreSaveListState(config)
      applyConfig(systemViewAsPreferences)
    },
    [applyConfig, systemViewLookup, systemViews]
  )

  const applyView = useCallback(
    async (id?: string) => {
      const newId = id || systemViews?.[0].key
      if (!newId) {
        return
      }

      setSelectedViewId(newId)
      const isSystemView = !!systemViewLookup[newId]
      if (!isSystemView) {
        await applySavedView(newId)
      } else {
        applySystemView(newId)
      }
    },
    [
      applySavedView,
      applySystemView,
      setSelectedViewId,
      systemViewLookup,
      systemViews
    ]
  )

  const deleteView = useCallback(
    async (id: string) => {
      return await deletePreferences(id)
    },
    [deletePreferences]
  )

  const saveView = useCallback(
    async (id: string, preferences: IListPreferences) => {
      const beforeSavePreferences = onBeforeSave?.(preferences) || preferences
      beforeSavePreferences.searchText = undefined
      return await setPreferences(id, beforeSavePreferences)
    },
    [onBeforeSave, setPreferences]
  )

  return {
    sessionKey: key,
    systemViews,
    selectedViewId,
    setSelectedViewId,
    applyManualPreferences,
    applyView,
    deleteView,
    saveView
  }
}

export const useListPreferences = ({
  preferenceKey,
  systemViews,
  listState,
  onBeforeSave,
  onBeforeApply,
  onApply
}: IListPreferenceHookArgs) => {
  const {
    applyManualPreferences,
    applyView,
    deleteView,
    saveView,
    selectedViewId,
    sessionKey,
    setSelectedViewId
  } = useLazyListPreferences({
    preferenceKey,
    systemViews,
    columnDefinitions: listState.columnDefinitions || {},
    onBeforeSave,
    onBeforeApply,
    onApply
  })

  const {
    isFetchingComplete: isLoadedMetaPreferences,
    preferences: metaPreferences,
    setPreferences: setMetaPreferences
  } = useListMetaPreferenceContainer(sessionKey)

  const defaultViewId = useMemo(() => {
    return isLoadedMetaPreferences
      ? metaPreferences?.defaultViewId || systemViews?.[0].key
      : undefined
  }, [isLoadedMetaPreferences, metaPreferences, systemViews])

  const systemViewLookup = useMemo(
    () => keyBy(systemViews, ({ key }) => key || ''),
    [systemViews]
  )

  const savedViewMetaLookup = useMemo(
    () => keyBy(metaPreferences?.savedContainerMeta, ({ key }) => key || ''),
    [metaPreferences?.savedContainerMeta]
  )

  const selectedViewMetadata = useMemo(() => {
    return selectedViewId
      ? savedViewMetaLookup[selectedViewId] || systemViewLookup[selectedViewId]
      : undefined
  }, [savedViewMetaLookup, selectedViewId, systemViewLookup])

  const isSystemViewSelected = useMemo(() => {
    return !!selectedViewId && !!systemViewLookup[selectedViewId]
  }, [selectedViewId, systemViewLookup])

  const isDefaultViewSelected = useMemo(
    () => selectedViewId === defaultViewId,
    [selectedViewId, defaultViewId]
  )

  const preSaveListPreferences = useMemo(() => {
    if (!listState?.columnState?.length) {
      return
    }
    return getPreSaveListState(listState)
  }, [listState])

  const deleteViewAndUpdateMetaPreferences = useCallback(
    async (id: string) => {
      const clone = structuredClone(metaPreferences?.savedContainerMeta || [])
      const meta = clone.filter((x) => x.key !== id)
      const newDefaultViewId =
        id === defaultViewId ? systemViews?.[0].key : defaultViewId
      applyView(newDefaultViewId)
      await Promise.all([
        setMetaPreferences({
          savedContainerMeta: meta,
          defaultViewId: newDefaultViewId
        }),
        deleteView(id)
      ])
    },
    [
      applyView,
      defaultViewId,
      deleteView,
      metaPreferences?.savedContainerMeta,
      setMetaPreferences,
      systemViews
    ]
  )

  const setDefaultViewId = useCallback(
    async (id: string) => {
      return await setMetaPreferences({ defaultViewId: id })
    },
    [setMetaPreferences]
  )

  const saveCurrentView = useCallback(async () => {
    if (!selectedViewId || !preSaveListPreferences) {
      return
    }

    return await saveView(selectedViewId, preSaveListPreferences)
  }, [preSaveListPreferences, saveView, selectedViewId])

  const saveCurrentViewAs = useCallback(
    async (name: string, saveAsDefault: boolean) => {
      if (!preSaveListPreferences) {
        return
      }
      const id = uuidv4()
      setSelectedViewId(id)
      await Promise.all([
        saveView(id, preSaveListPreferences),
        setMetaPreferences({
          savedContainerMeta: [
            ...(metaPreferences?.savedContainerMeta || []),
            { key: id, label: name }
          ],
          ...(saveAsDefault ? { defaultViewId: id } : {})
        })
      ])
      return id
    },
    [
      metaPreferences?.savedContainerMeta,
      preSaveListPreferences,
      saveView,
      setMetaPreferences,
      setSelectedViewId
    ]
  )

  const renameSavedView = useCallback(
    async (id: string, newName: string) => {
      const clone = structuredClone([
        ...(metaPreferences?.savedContainerMeta || [])
      ])
      const index = clone.findIndex((x) => x.key === id)
      const newItem = { key: id, label: newName } as IListMetaPreferenceDetails
      clone.splice(index, 1, newItem)
      return await setMetaPreferences({ savedContainerMeta: clone })
    },
    [metaPreferences?.savedContainerMeta, setMetaPreferences]
  )

  const savedViews = metaPreferences?.savedContainerMeta

  return {
    sessionKey,
    savedViews,
    systemViews,
    defaultViewId,
    selectedViewId,
    setSelectedViewId,
    selectedViewMetadata,
    isSystemViewSelected,
    isDefaultViewSelected,
    applyView,
    deleteView,
    deleteViewAndUpdateMetaPreferences,
    setDefaultViewId,
    preSaveListPreferences,
    applyManualPreferences,
    renameSavedView,
    saveCurrentView,
    saveCurrentViewAs,
    setMetaPreferences,
    metaPreferences,
    isLoadedMetaPreferences
  }
}
