import { ActionType, createAction } from 'typesafe-actions'
import { IFetchDocumentRequest } from '../api/types'
import { IDistributionInfo } from './types'

export const FETCH_PENDING_DISTRIBUTION_INFO =
  '@modules/@trust/@DiscretionaryDistribution/FETCH_PENDING_DISTRIBUTION_INFO'
export const FETCH_PENDING_DISTRIBUTION_INFO_SUCCESS =
  '@modules/@trust/@DiscretionaryDistribution/FETCH_PENDING_DISTRIBUTION_INFO_SUCCESS'
export const FETCH_PENDING_DISTRIBUTION_INFO_FAILURE =
  '@modules/@trust/@DiscretionaryDistribution/FETCH_PENDING_DISTRIBUTION_INFO_FAILURE'

export const FETCH_COMPLETED_DISTRIBUTION_INFO =
  '@modules/@trust/@DiscretionaryDistribution/FETCH_COMPLETED_DISTRIBUTION_INFO'
export const FETCH_COMPLETED_DISTRIBUTION_INFO_SUCCESS =
  '@modules/@trust/@DiscretionaryDistribution/FETCH_COMPLETED_DISTRIBUTION_INFO_SUCCESS'
export const FETCH_COMPLETED_DISTRIBUTION_INFO_FAILURE =
  '@modules/@trust/@DiscretionaryDistribution/FETCH_COMPLETED_DISTRIBUTION_INFO_FAILURE'

export const NAVIAGTE_TO_NEW_DISTRIBUTION = `@modules/@trust/@DiscretionaryDistribution/NAVIGATE_TO_NEW_DISTRIBUTION`
export const NAVIGATE_TO_DISTRIBUTIONS = `@modules/@trust/@DiscretionaryDistribution/NAVIGATE_TO_DISTRIBUTIONS`

export const FETCH_DOCUMENT_REQUEST = `@modules/@trust/@DiscretionaryDistribution/FETCH_DOCUMENT_REQUEST`
export const FETCH_DOCUMENT_SUCCESS = `@modules/@trust/@DiscretionaryDistribution/FETCH_DOCUMENT_SUCCESS`
export const FETCH_DOCUMENT_FAILURE = `@modules/@trust/@DiscretionaryDistribution/FETCH_DOCUMENT_FAILURE`

export const DELETE_DISTRIBUTION_REQUEST = `@modules/@trust/@DiscretionaryDistribution/DELETE_DISTRIBUTION_REQUEST`
export const DELETE_DISTRIBUTION_SUCCESS = `@modules/@trust/@DiscretionaryDistribution/DELETE_DISTRIBUTION_SUCCESS`
export const DELETE_DISTRIBUTION_FAILURE = `@modules/@trust/@DiscretionaryDistribution/DELETE_DISTRIBUTION_FAILURE`

export const pendingDistributionDataActions = {
  request: createAction(FETCH_PENDING_DISTRIBUTION_INFO)(),
  success: createAction(FETCH_PENDING_DISTRIBUTION_INFO_SUCCESS)<
    IDistributionInfo[]
  >(),
  failure: createAction(FETCH_PENDING_DISTRIBUTION_INFO_FAILURE)<Error>()
}

export const completedDistributionDataActions = {
  request: createAction(FETCH_COMPLETED_DISTRIBUTION_INFO)(),
  success: createAction(FETCH_COMPLETED_DISTRIBUTION_INFO_SUCCESS)<
    IDistributionInfo[]
  >(),
  failure: createAction(FETCH_COMPLETED_DISTRIBUTION_INFO_FAILURE)<Error>()
}

export const discretionaryDistributionNavigationActions = {
  naviagteToNewDistribution: createAction(NAVIAGTE_TO_NEW_DISTRIBUTION)<
    string | undefined
  >(),
  naviagteToDistribution: createAction(NAVIGATE_TO_DISTRIBUTIONS)()
}

export const documentDataActions = {
  request: createAction(FETCH_DOCUMENT_REQUEST)<IFetchDocumentRequest>(),
  success: createAction(FETCH_DOCUMENT_SUCCESS)<string>(),
  failure: createAction(FETCH_DOCUMENT_FAILURE)<Error>()
}

export const deleteDistributionActions = {
  request: createAction(DELETE_DISTRIBUTION_REQUEST)<string>(),
  success: createAction(DELETE_DISTRIBUTION_SUCCESS)<string>(),
  failure: createAction(DELETE_DISTRIBUTION_FAILURE)<Error>()
}

export type PendingDistributionDataActionTypes = ActionType<
  typeof pendingDistributionDataActions
>
export type CompletedDistributionDataActionTypes = ActionType<
  typeof completedDistributionDataActions
>
export type DiscretionaryDistributionNaviagtionActionType = ActionType<
  typeof discretionaryDistributionNavigationActions
>

export type DocumentDataActionTypes = ActionType<typeof documentDataActions>
export type DeleteDistributionActionTypes = ActionType<
  typeof deleteDistributionActions
>

export type DistributionActionType =
  | PendingDistributionDataActionTypes
  | CompletedDistributionDataActionTypes
  | DiscretionaryDistributionNaviagtionActionType
  | DocumentDataActionTypes
  | DeleteDistributionActionTypes
