import TileHeading from '../TileHeading'

const ComingSoon = ({ title }: { title: string }) => {
  return (
    <div css={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <TileHeading title={title} showIcons={false} />
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '14px',
          height: '100%'
        }}
      >
        COMING SOON
      </div>
    </div>
  )
}

export default ComingSoon
