import { ActionType, createAction } from 'typesafe-actions'
import { IDurationOptions } from '../shared/DateSelector'
import {
  ICheck,
  ICheckReprintScreenActionsRequest,
  ICheckReprintScreenActionsResponse,
  IGetCheckListReq
} from './types'

export const FETCH_CHECKS = '@modules/@payments/@checkprint/FETCH_CHECKS'
export const FETCH_CHECKS_SUCCESS =
  '@modules/@payments/@checkprint/FETCH_CHECKS_SUCCESS'
export const FETCH_CHECKS_FAILURE =
  '@modules/@payments/@checkprint/FETCH_CHECKS_FAILURE'

export const CHECK_REPRINT_SCREEN =
  '@modules/@payments/@checkprint/CHECK_REPRINT_SCREEN'
export const CHECK_REPRINT_SCREEN_SUCCESS =
  '@modules/@payments/@checkprint/CHECK_REPRINT_SCREEN_SUCCESS'
export const CHECK_REPRINT_SCREEN_FAILURE =
  '@modules/@payments/@checkprint/CHECK_REPRINT_SCREEN_FAILURE'
export const SET_SELECTED_ACTION_TYPE =
  '@modules/@payments/@checkprint/SET_SELECTED_ACTION_TYPE'

export const SET_START_DATE = '@modules/@payments/@checkprint/SET_START_DATE'
export const SET_END_DATE = '@modules/@payments/@checkprint/SET_END_DATE'
export const SET_CUSTOM_RANGE =
  '@modules/@payments/@checkprint/SET_CUSTOM_RANGE'
export const SET_DATE_SELECTION =
  '@modules/@payments/@checkprint/SET_DATE_SELECTION'

export const checksDataActions = {
  request: createAction(FETCH_CHECKS)<IGetCheckListReq>(),
  success: createAction(FETCH_CHECKS_SUCCESS)<ICheck[]>(),
  failure: createAction(FETCH_CHECKS_FAILURE)<Error>()
}
export const dateSelectorActions = {
  setStartDate: createAction(SET_START_DATE)<string | undefined>(),
  setEndDate: createAction(SET_END_DATE)<string | undefined>(),
  setDateSelection: createAction(SET_CUSTOM_RANGE)<IDurationOptions>(),
  setCustomRange: createAction(SET_DATE_SELECTION)<string>()
}

export const checkReprintScreenActions = {
  request:
    createAction(CHECK_REPRINT_SCREEN)<ICheckReprintScreenActionsRequest>(),
  success: createAction(
    CHECK_REPRINT_SCREEN_SUCCESS
  )<ICheckReprintScreenActionsResponse>(),
  failure: createAction(CHECK_REPRINT_SCREEN_FAILURE)<Error>(),
  setSelectedActionType: createAction(SET_SELECTED_ACTION_TYPE)<string>()
}

export type ChecksDataActionTypes = ActionType<typeof checksDataActions>
export type DateSelectorActionType = ActionType<typeof dateSelectorActions>
export type CheckReprintScreenActionType = ActionType<
  typeof checkReprintScreenActions
>

export type ChecksActionType =
  | ChecksDataActionTypes
  | DateSelectorActionType
  | CheckReprintScreenActionType
