import { Dialog } from '@fluentui/react'
import { useCallback, useMemo } from 'react'
import { buttonStyles } from '../../components/shared/Buttons'
import { SnackBar } from '../../components/shared/Snackbar'
import { useDeleteItemMutation } from '../../store/graph'
import { useCollaborationUiState } from './CollaborationUIState'

export const DeleteItemDialog: React.FC = () => {
  const {
    showDeleteWarning,
    setShowDeleteWarning,
    setMenuItem,
    menuItem,
    driveId
  } = useCollaborationUiState()
  const type = useMemo(
    () => (menuItem?.folder ? 'folder' : 'file'),
    [menuItem?.folder]
  )
  const [deleteItem, deleteResult] = useDeleteItemMutation()
  const { isSuccess, error } = deleteResult
  useMemo(() => {
    if (isSuccess) {
      setShowDeleteWarning(false)
      setMenuItem(undefined)
    }
  }, [isSuccess, setMenuItem, setShowDeleteWarning])
  const onDelete = useCallback(() => {
    deleteItem({ driveId, itemId: menuItem?.id })
  }, [deleteItem, driveId, menuItem?.id])
  return (
    <Dialog
      hidden={!showDeleteWarning}
      dialogContentProps={{
        title:
          type === 'folder'
            ? 'Are you certain that you want to delete the selected folder, including all of its contents?'
            : 'Are you certain that you want to delete the selected file?',
        styles: {
          title: {
            fontSize: '18px',
            fontWeight: 700,
            color: '#13272B',
            padding: '18px 30px'
          },
          content: { width: '550px' }
        }
      }}
      minWidth={'550px'}
      modalProps={{ isBlocking: true }}
      onDismiss={() => setShowDeleteWarning(false)}
    >
      {error ? (
        <SnackBar
          type="Failure"
          message={(error as Error)?.message || 'An unknown error occurred'}
        />
      ) : null}
      <div
        css={{
          padding: '40px 0 20px',
          display: 'flex',
          justifyContent: 'center',
          gridColumnGap: 5
        }}
      >
        <button
          onClick={() => setShowDeleteWarning(false)}
          css={[buttonStyles.secondary, { width: '100px' }]}
        >
          Cancel
        </button>
        <button
          onClick={onDelete}
          css={[buttonStyles.primary, { width: '100px' }]}
        >
          Yes, delete
        </button>
      </div>
    </Dialog>
  )
}
