import { createSlice } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

const { actions, reducer } = createSlice({
  name: 'notesUi',
  initialState: { activeIndex: 0 } as INotesUiState,
  reducers: {
    nextNote(state) {
      state.activeIndex++
    },
    previousNote(state) {
      state.activeIndex--
    },
    resetActiveNote(state) {
      state.activeIndex = 0
    }
  }
})

export { reducer as notesUiReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.secondaryHeader.notes.notesUi

const getActiveNote = flow(rootSelector, (x) => x.activeIndex)

export interface INotesUiState {
  activeIndex: number
}

export const useNotesUi = () => {
  const dispatch = useDispatch()

  const nextNote = useCallback(() => {
    dispatch(actions.nextNote())
  }, [dispatch])

  const previousNote = useCallback(() => {
    dispatch(actions.previousNote())
  }, [dispatch])

  const resetActiveIndex = useCallback(() => {
    dispatch(actions.resetActiveNote())
  }, [dispatch])

  const activeIndex = useSelector(getActiveNote)

  return {
    nextNote,
    previousNote,
    resetActiveIndex,
    activeIndex
  }
}
