import axios from 'axios'
import { call, cancelled, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import {
  IMarginRateRequest,
  updateMarginRateRequest
} from '../../../../../../../../../api/dynamics'
import { AppState } from '../../../../../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../../../store/shared/asyncStore'
import { getDynamicsApiOptions } from '../../../../../../../../../store/shared/sagas'
import { marginRateRequestListUpdateActions } from '../../MarginRateRequestList'

export const marginRateRequestUpdateActions = createAsyncAction(
  '@modules/@advisory/@modules/@marginRateRequest/@marginRateRequestViewPanel/@MarginRateRequestUpdate/REQUEST',
  '@modules/@advisory/@modules/@marginRateRequest/@marginRateRequestViewPanel/@MarginRateRequestUpdate/SUCCESS',
  '@modules/@advisory/@modules/@marginRateRequest/@marginRateRequestViewPanel/@MarginRateRequestUpdate/FAILURE'
)<IMarginRateRequest, boolean, Error>()

export const marginRateRequestUpdateReducer = createAsyncReducer(
  marginRateRequestUpdateActions
)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.accounts.modules.marginRateRequests.features
    .marginRateRequestUpdate

export const {
  getError: getMarginRateRequestUpdateError,
  getIsLoading: getIsMarginRateRequestUpdateLoading,
  getResult: getMarginRateRequestUpdateResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof marginRateRequestUpdateActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  try {
    const apiOptions = yield* call(getDynamicsApiOptions)

    const result = yield* call(
      updateMarginRateRequest,
      apiOptions,
      action.payload
    )

    yield put(marginRateRequestUpdateActions.success(true))
    yield put(marginRateRequestListUpdateActions.update(result))
  } catch (e: unknown) {
    yield put(marginRateRequestUpdateActions.failure(e as Error))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const marginRateRequestUpdateSagas = [
  () => takeLatest(marginRateRequestUpdateActions.request, onRequest)
]
