import { ActionType, createAction } from 'typesafe-actions'
import { IPaymentApprover, IPaymentApproverForm, ISearchUser } from './types'

export const FETCH_USERS =
  '@modules/@payments/@admin/@paymentapprovers/FETCH_USERS'
export const FETCH_USERS_SUCCESS =
  '@modules/@payments/@admin/@paymentapprovers/FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE =
  '@modules/@payments/@admin/@paymentapprovers/FETCH_USERS_FAILURE'

export const FETCH_PAYMENT_APPROVERS =
  '@modules/@payments/@admin/@paymentapprovers/FETCH_PAYMENT_APPROVERS'
export const FETCH_PAYMENT_APPROVERS_SUCCESS =
  '@modules/@payments/@admin/@paymentapprovers/FETCH_PAYMENT_APPROVERS_SUCCESS'
export const FETCH_PAYMENT_APPROVERS_FAILURE =
  '@modules/@payments/@admin/@paymentapprovers/FETCH_PAYMENT_APPROVERS_FAILURE'

export const DELETE_APPROVER =
  '@modules/@payments/@admin/@paymentapprovers/DELETE_APPROVER'
export const DELETE_APPROVER_SUCCESS =
  '@modules/@payments/@admin/@paymentapprovers/DELETE_APPROVER_SUCCESS'
export const DELETE_APPROVER_FAILURE =
  '@modules/@payments/@admin/@paymentapprovers/DELETE_APPROVER_FAILURE'

export const SET_SELECTED_APPROVER =
  '@modules/@payments/@admin/@paymentapprovers/SET_SELECTED_APPROVER'

export const SUBMIT_APPROVER =
  '@modules/@payments/@admin/@paymentapprovers/SUBMIT_APPROVER'
export const SUBMIT_APPROVER_SUCCESS =
  '@modules/@payments/@admin/@paymentapprovers/SUBMIT_APPROVER_SUCCESS'
export const SUBMIT_APPROVER_FAILURE =
  '@modules/@payments/@admin/@paymentapprovers/SUBMIT_APPROVER_FAILURE'

export const fetchUserActions = {
  request: createAction(FETCH_USERS)<ISearchUser>(),
  success: createAction(FETCH_USERS_SUCCESS)<IPaymentApprover[]>(),
  failure: createAction(FETCH_USERS_FAILURE)<Error>()
}

export const fetchPaymentApproversActions = {
  request: createAction(FETCH_PAYMENT_APPROVERS)(),
  success: createAction(FETCH_PAYMENT_APPROVERS_SUCCESS)<IPaymentApprover[]>(),
  failure: createAction(FETCH_PAYMENT_APPROVERS_FAILURE)<Error>()
}

export const deletePaymentApproverActions = {
  request: createAction(DELETE_APPROVER)<IPaymentApprover>(),
  success: createAction(DELETE_APPROVER_SUCCESS)(),
  failure: createAction(DELETE_APPROVER_FAILURE)<Error>()
}

export const submitPaymentApproverActions = {
  request: createAction(SUBMIT_APPROVER)<IPaymentApproverForm>(),
  success: createAction(SUBMIT_APPROVER_SUCCESS)(),
  failure: createAction(SUBMIT_APPROVER_FAILURE)<Error>()
}

export const setSelectedApproverActions = {
  setApprover: createAction(SET_SELECTED_APPROVER)<IPaymentApprover>()
}

export type FetchUsersActionType = ActionType<typeof fetchUserActions>
export type FetchPaymentApproverActionType = ActionType<
  typeof fetchPaymentApproversActions
>
export type DeletePaymentApproverActionType = ActionType<
  typeof deletePaymentApproverActions
>

export type SetSelectedApproverActionType = ActionType<
  typeof setSelectedApproverActions
>

export type SubmitPaymentApproverActionType = ActionType<
  typeof submitPaymentApproverActions
>

export type PaymentApproversActions =
  | FetchUsersActionType
  | FetchPaymentApproverActionType
  | DeletePaymentApproverActionType
  | SetSelectedApproverActionType
  | SubmitPaymentApproverActionType
