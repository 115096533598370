import { get } from 'lodash'
import { useCallback, useState } from 'react'

function usePreferences<T>(appName: string): {
  getPreference: (key: string) => any
  setPreference: (value: T) => void
} {
  const [, setUpdatedOn] = useState<Date>(new Date())

  const getPreference = (key: string): any => {
    return get(JSON.parse(window.localStorage.getItem(appName) || '{}'), key)
  }

  const setPreference = useCallback(
    (value: T) => {
      const updatedPreference = {
        ...JSON.parse(window.localStorage.getItem(appName) || '{}'),
        ...value
      }

      window.localStorage.setItem(appName, JSON.stringify(updatedPreference))
      setUpdatedOn(new Date())
    },
    [appName]
  )

  return { getPreference, setPreference }
}

export default usePreferences
