import { IModuleDefinition } from 'modules/types'
import { combineReducers } from 'redux'
import { RevenueContainer } from './RevenueContainer'
import { revenueDetailUiStateReducer } from './revenueDetailUIState'

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/revenue/*',
    private: true,
    component: RevenueContainer
  },
  name: 'Revenue'
}

export default moduleDefinition

export const rdot360RevenueReducer = combineReducers({
  revenueDetailUI: revenueDetailUiStateReducer
})
