import { createRoot } from 'react-dom/client'
import { whenIdle } from 'shared/async'
import { IAccount } from '../../api/account.types'
import { INfsProfile } from '../../api/datahub'
import {
  AccountLinkingValidationRequestType,
  IBusinessUnit
} from '../../api/dynamics'
import { fidelityCharitable } from './store/accountLinkingPanel'
import { IAccountLinkingVerification } from './store/accountLinkingValidation'

export interface IAccountLinkingEmailProps {
  validatedAccounts?: IAccount[]
  selectedClients?: INfsProfile[]
  verification?: IAccountLinkingVerification
  validations?: string[]
  checksum?: string
  approvers?: IBusinessUnit[]
  requestType?: AccountLinkingValidationRequestType
  origin?: string
  id?: string
  delinkAccounts?: string[]
}

export const AccountLinkingEmail: React.FC<IAccountLinkingEmailProps> = ({
  validatedAccounts,
  selectedClients,
  verification,
  validations,
  checksum,
  approvers,
  requestType,
  origin,
  id
}) => {
  return (
    <div>
      <div>
        <div>
          To view the status of your request, see{' '}
          <a
            href={`${origin}/advisory/requests/AccountLinkingRequests${
              id ? `?id=${id}` : ''
            }`}
          >
            Account Linking Requests
          </a>{' '}
        </div>
      </div>

      {!!validations?.length && (
        <div style={{ marginTop: '10px' }}>
          <b>Reasons for non-standard request</b>
          <ul style={{ padding: 0, margin: 0 }}>
            {validations?.map((x, i) => (
              <li style={{ padding: 0, margin: 0 }} key={i}>
                {x}
              </li>
            ))}
          </ul>
        </div>
      )}

      {verification && (
        <div style={{ marginTop: '10px' }}>
          {verification?.method !== fidelityCharitable && (
            <>
              <div>
                <b>Verification Date</b>: {verification?.date?.toDateString()}
              </div>
              <div>
                <b>Verification Time</b>: {verification?.time}
              </div>
              <div>
                <b>Verification Client</b>: {verification?.client}
              </div>
            </>
          )}
          <div>
            <b>Verification Method</b>: {verification?.method || ''}
          </div>
        </div>
      )}

      <AccountLinkingEmailClientsSection
        selectedClients={selectedClients}
        requestType={requestType}
      />

      <div style={{ marginTop: '10px' }}>
        <b>Accounts</b>
        <table>
          <thead>
            <tr>
              {[
                'Number',
                'Nickname',
                'Legal Entity Name',
                'Household',
                'Rep Code'
              ].map((x, i) => (
                <th
                  style={{ padding: '0 5px', margin: 0, textAlign: 'left' }}
                  key={i}
                >
                  {x}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {validatedAccounts?.map(
              (
                {
                  CustodyAccount,
                  AdvisorAddedNickName,
                  Shortname,
                  LegalEntityName,
                  householdName,
                  ClientAdvisorID
                },
                i
              ) => (
                <tr key={i}>
                  {[
                    CustodyAccount,
                    AdvisorAddedNickName || Shortname,
                    LegalEntityName,
                    householdName,
                    ClientAdvisorID
                  ].map((value, i) => (
                    <td style={{ padding: '0 5px' }} key={i}>
                      {value || '--'}
                    </td>
                  ))}
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>

      <div>
        <div style={{ marginTop: '10px' }}>
          <b>Approvers</b>
          <table>
            <thead>
              <tr>
                {['Region', 'Email'].map((x, i) => (
                  <th
                    style={{ padding: '0 5px', margin: 0, textAlign: 'left' }}
                    key={i}
                  >
                    {x}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {approvers?.map(({ name, emailaddress }, i) => (
                <tr key={i}>
                  {[name, emailaddress].map((value, i) => (
                    <td style={{ padding: '0 5px' }} key={i}>
                      {value || '--'}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div style={{ fontSize: '10px', marginTop: '10px' }}>
          <div>
            <b>Account List</b>:{' '}
            {validatedAccounts
              ?.map(({ CustodyAccount }) => CustodyAccount)
              .join(',')}
          </div>
          <div>
            <b>Request Id</b>: {checksum}
          </div>
        </div>
      </div>
    </div>
  )
}

export const generateAccountLinkingEmail = async (
  props: IAccountLinkingEmailProps
) => {
  const container = document.createElement('div')
  const root = createRoot(container)

  root.render(<AccountLinkingEmail {...props} />)
  await whenIdle()
  const html = container.innerHTML
  root.unmount()
  return html
}

export const AccountLinkingEmailClientsSection: React.FC<{
  selectedClients?: INfsProfile[]
  requestType?: AccountLinkingValidationRequestType
}> = ({ selectedClients, requestType }) => {
  return (
    <div style={{ marginTop: '10px' }}>
      <b>
        {requestType === 'delink'
          ? 'Clients to delink the below accounts from'
          : 'Clients to link the below accounts onto'}
      </b>
      <table>
        <thead>
          <tr>
            {['Name', 'Email', 'Id', 'Role', 'Rep Code'].map((x, i) => (
              <th
                style={{ padding: '0 5px', margin: 0, textAlign: 'left' }}
                key={i}
              >
                {x}
              </th>
            ))}
            {requestType === 'new' && (
              <th style={{ padding: '0 5px', margin: 0, textAlign: 'left' }}>
                MFA Phone
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {selectedClients?.map(
            (
              {
                fullname,
                loginid,
                emailprimary,
                wsportaluserid,
                role,
                repcode,
                mfaPhones
              },
              i
            ) => (
              <tr key={i}>
                {[
                  fullname,
                  loginid || emailprimary,
                  wsportaluserid,
                  role,
                  repcode
                ].map((value, i) => (
                  <td style={{ padding: '0 5px' }} key={i}>
                    {value || '--'}
                  </td>
                ))}
                {requestType === 'new' && (
                  <td style={{ padding: '0 5px' }}>{mfaPhones || '--'}</td>
                )}
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  )
}
