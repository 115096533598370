import {
  Dropdown,
  IconButton,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  Stack
} from '@fluentui/react'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PaletteComponent } from '../../../../../../../../shared/components/Palette'
import { PayoutChart } from './PayoutChart'
import { PayoutList } from './PayoutList'
import { PayoutTable } from './PayoutTable'
import { getHurdlesFetchError, hurdlesFetchActions } from './store/hurdleFetch'
import { mostRecentClosedMonthFetchActions } from './store/mostRecentClosedMonthFetch'
import {
  ChartorTable,
  getChartOrTable,
  getGroupByType,
  GroupByTypes,
  payoutsDashboardUiActions
} from './store/payoutsDashboardUi'

const groupOptions = [
  { key: 'hurdle', text: 'Hurdle' },
  { key: 'team', text: 'Team / Individual' }
]

export const PayoutsDashboard: React.FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(hurdlesFetchActions.request())
    dispatch(mostRecentClosedMonthFetchActions.request())
  }, [dispatch])

  const chartOrTable = useSelector(getChartOrTable)
  const setChartOrTable = (selected: ChartorTable) => {
    dispatch(payoutsDashboardUiActions.setChartOrTable(selected))
  }
  const groupByType = useSelector(getGroupByType)
  const onGroupByChange = useCallback(
    (ev: any, option?: IDropdownOption) => {
      if (!option?.key) {
        return
      }
      dispatch(
        payoutsDashboardUiActions.setGroupByType(option?.key as GroupByTypes)
      )
    },
    [dispatch]
  )
  const error = useSelector(getHurdlesFetchError)

  return (
    <div>
      <PaletteComponent>
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
            <Dropdown
              options={groupOptions}
              style={{ width: '200px' }}
              selectedKey={groupByType}
              onChange={onGroupByChange}
            />
            <Stack horizontal={true}>
              <IconButton
                ariaLabel="Table"
                iconProps={{ iconName: 'Table' }}
                onClick={() => setChartOrTable('Table')}
                checked={chartOrTable === 'Table'}
              />
              <IconButton
                ariaLabel="Chart"
                iconProps={{ iconName: 'BarChartVertical' }}
                onClick={() => setChartOrTable('Chart')}
                checked={chartOrTable === 'Chart'}
              />
            </Stack>
          </Stack>
          {chartOrTable === 'Chart' ? <PayoutChart /> : <PayoutTable />}
          {error && (
            <MessageBar messageBarType={MessageBarType.error}>
              {error?.message}
            </MessageBar>
          )}
        </Stack>
      </PaletteComponent>
      <PaletteComponent>
        <PayoutList />
      </PaletteComponent>
    </div>
  )
}
