import { IColumn } from '@fluentui/react'
import { ActionType, createAction } from 'typesafe-actions'
import {
  FetchRetirementPlanTransactionType,
  IExportPlanProviderToExcel,
  IFetchPlanToDistributeCash,
  ILookupFieldsResponse,
  IPlanAllocations,
  IPlanByProvider,
  IPlanExcelExportReq,
  IPlanProvider,
  IProjectedAmount,
  IRetirementPlanExcelExportReq,
  IRetirementPlanExportReq,
  IRetirementPlanReport,
  IRetirementPlanTransactionsResponse,
  IRetirementProductCashReceipt,
  IRetirementProductPlan,
  IUploadProductDocumentRequest
} from './types'

export const FETCH_RETIREMENT_PRODUCT_PLANS =
  '@modules/@products/@retirement/FETCH_RETIREMENT_PRODUCT_PLANS'
export const FETCH_RETIREMENT_PRODUCT_PLANS_SUCCESS =
  '@modules/@products/@retirement/FETCH_RETIREMENT_PRODUCT_PLANS_SUCCESS'
export const FETCH_RETIREMENT_PRODUCT_PLANS_FAILURE =
  '@modules/@products/@retirement/FETCH_RETIREMENT_PRODUCT_PLANS_FAILURE'

export const NAVIGATE_TO_RETIREMENT_PLAN_TRANSACTIONS = `@modules/@products/@retirement/NAVIGATE_TO_RETIREMENT_PLAN_TRANSACTIONS`
export const NAVIGATE_TO_PROJECTED_AMOUNT_BY_PLAN = `@modules/@products/@retirement/NAVIGATE_TO_PROJECTED_AMOUNT_BY_PLAN`
export const NAVIGATE_TO_RETIREMENT_PLAN = `@modules/@products/@retirement/NAVIAGTE_TO_RETIREMENT_PLAN`
export const NAVIGATE_TO_RETIREMENT_PLAN_TRANSACTIONS_BY_CLIENT = `@modules/@products/@retirement/NAVIGATE_TO_RETIREMENT_PLAN_TRANSACTIONS_BY_CLIENT`

export const FETCH_RETIREMENT_PLAN_TRANSACTIONS =
  '@modules/@products/@retirement/FETCH_RETIREMENT_PLAN_TRANSACTIONS'
export const FETCH_RETIREMENT_PLAN_TRANSACTIONS_SUCCESS =
  '@modules/@products/@retirement/FETCH_RETIREMENT_PLAN_TRANSACTIONS_SUCCESS'
export const FETCH_RETIREMENT_PLAN_TRANSACTIONS_FAILURE =
  '@modules/@products/@retirement/FETCH_RETIREMENT_PLAN_TRANSACTIONS_FAILURE'

export const CLEAR_RETIREMENT_PLAN_TRANSACTIONS =
  '@modules/@products/@retirement/CLEAR_RETIREMENT_PLAN_TRANSACTIONS'

export const SET_SELECTED_RETIREMENT_PLAN = `@modules/@products/@retirement/SET_SELECTED_RETIREMENT_PLAN`

export const UPDATE_CASH_RECEIPTS_REQUEST = `@modules/@products/@retirement/UPDATE_CASH_RECEIPTS_REQUEST`
export const UPDATE_CASH_RECEIPTS_SUCCESS = `@modules/@products/@retirement/UPDATE_CASH_RECEIPTS_SUCCESS`
export const UPDATE_CASH_RECEIPTS_FAILURE = `@modules/@products/@retirement/UPDATE_CASH_RECEIPTS_FAILURE`

export const FETCH_RETIREMENT_PLAN_TRANSACTIONS_BY_CLIENT = `@modules/@products/@retirement/FETCH_RETIREMENT_PLAN_TRANSACTIONS_BY_CLIENT`
export const FETCH_RETIREMENT_PLAN_TRANSACTIONS_BY_CLIENT_SUCCESS = `@modules/@products/@retirement/FETCH_RETIREMENT_PLAN_TRANSACTIONS_BY_CLIENT_SUCCESS`
export const FETCH_RETIREMENT_PLAN_TRANSACTIONS_BY_CLIENT_FAILURE = `@modules/@products/@retirement/FETCH_RETIREMENT_PLAN_TRANSACTIONS_BY_FAILURE`

export const SET_SELECTED_MONTH_YEAR = `@modules/@products/@retirement/SET_SELECTED_MONTH_YEAR`
export const SET_SELECTED_RETIREMENT_PLAN_TYPE = `@modules/@products/@retirement/SET_SELECTED_RETIREMENT_PLAN_TYPE`

export const FETCH_PROJECTED_AMOUNT_BY_PLAN = `@modules/@products/@retirement/FETCH_PROJECTED_AMOUNT_BY_PLAN`
export const FETCH_PROJECTED_AMOUNT_BY_PLAN_SUCCESS = `@modules/@products/@retirement/FETCH_PROJECTED_AMOUNT_BY_PLAN_SUCCESS`
export const FETCH_PROJECTED_AMOUNT_BY_PLAN_FAILURE = `@modules/@products/@retirement/FETCH_PROJECTED_AMOUNT_BY_PLAN_FAILURE`
export const CLEAR_PROJECTED_AMOUNT_BY_PLAN = `@modules/@products/@retirement/CLEAR_PROJECTED_AMOUNT_BY_PLAN`

export const UPDATE_PROJECTED_AMOUNT_BY_PLAN = `@modules/@products/@retirement/UPDATE_PROJECTED_AMOUNT_BY_PLAN`
export const UPDATE_PROJECTED_AMOUNT_BY_PLAN_SUCCESS = `@modules/@products/@retirement/UPDATE_PROJECTED_AMOUNT_BY_PLAN_SUCCESS`
export const UPDATE_PROJECTED_AMOUNT_BY_PLAN_FAILURE = `@modules/@products/@retirement/UPDATE_PROJECTED_AMOUNT_BY_PLAN_FAILURE`

export const DOWNLOAD_D365_EXCEL =
  '@modules/@products/@retirement/DOWNLOAD_D365_EXCEL'
export const DOWNLOAD_D365_EXCEL_SUCCESS =
  '@modules/@products/@retirement/DOWNLOAD_D365_EXCEL_SUCCESS'
export const DOWNLOAD_D365_EXCEL_FAILURE =
  '@modules/@products/@retirement/DOWNLOAD_D365_EXCEL_FAILURE'

export const FETCH_PLAN_PROVIDERS = `@modules/@products/@retirement/@planproviders/FETCH_PLAN_PROVIDERS`
export const FETCH_PLAN_PROVIDERS_SUCCESS = `@modules/@products/@retirement/@planproviders/FETCH_PLAN_PROVIDERS_SUCCESS`
export const FETCH_PLAN_PROVIDERS_FAILURE = `@modules/@products/@retirement/@planproviders/FETCH_PLAN_PROVIDERS_FAILURE`
export const CLEAR_PLAN_PROVIDERS = `@modules/@products/@retirement/@planproviders/CLEAR_PLAN_PROVIDERS`

export const EXPORT_FUND_PROVIDERS_EXCEL = `@modules/@products/@retirement/@planproviders/EXPORT_FUND_PROVIDERS_EXCEL`
export const EXPORT_FUND_PROVIDERS_EXCEL_SUCCESS = `@modules/@products/@retirement/@planproviders/EXPORT_FUND_PROVIDERS_EXCEL_SUCCESS`
export const EXPORT_FUND_PROVIDERS_EXCEL_FAILURE = `@modules/@products/@retirement/@planproviders/EXPORT_FUND_PROVIDERS_EXCEL_FAILURE`

export const EXPORT_FUND_PLANS = `@modules/@products/@retirement/@planproviders/FETCH_PLAN_PROVIDERS`
export const EXPORT_FUND_PLANS_SUCCESS = `@modules/@products/@retirement/@planproviders/FETCH_PLAN_PROVIDERS_SUCCESS`
export const EXPORT_FUND_PLANS_FAILURE = `@modules/@products/@retirement/@planproviders/FETCH_PLAN_PROVIDERS_FAILURE`

export const UPDATE_PLAN_PROVIDER = `@modules/@products/@retirement/UPDATE_PLAN_PROVIDER`
export const UPDATE_PLAN_PROVIDER_SUCCESS = `@modules/@products/@retirement/UPDATE_PLAN_PROVIDER_SUCCESS`
export const UPDATE_PLAN_PROVIDER_FAILURE = `@modules/@products/@retirement/UPDATE_PLAN_PROVIDER_FAILURE`

export const VIEW_STATEMENT = `@modules/@products/@retirement/VIEW_STATEMENT`
export const VIEW_STATEMENT_SUCCESS = `@modules/@products/@retirement/VIEW_STATEMENT_SUCCESS`
export const VIEW_STATEMENT_FAILURE = `@modules/@products/@retirement/VIEW_STATEMENT_FAILURE`

export const EXPORT_RETIREMENT_PLANS = `@modules/@products/@retirement/EXPORT_RETIREMENT_PLANS`
export const EXPORT_RETIREMENT_PLANS_SUCCESS = `@modules/@products/@retirement/EXPORT_RETIREMENT_PLANS_SUCCESS`
export const EXPORT_RETIREMENT_PLANS_FAILURE = `@modules/@products/@retirement/EXPORT_RETIREMENT_PLANS_FAILURE`

export const SET_SELECTED_PERIOD = `@modules/@products/@retirement/SET_SELECTED_PERIOD`

export const FETCH_PLANS_TO_DISTRIBUTE_CASH = `@modules/@products/@retirement/FETCH_PLANS_TO_DISTRIBUTE_CASH`
export const FETCH_PLANS_TO_DISTRIBUTE_CASH_SUCCESS = `@modules/@products/@retirement/FETCH_PLANS_TO_DISTRIBUTE_CASH_SUCCESS`
export const FETCH_PLANS_TO_DISTRIBUTE_CASH_FAILURE = `@modules/@products/@retirement/FETCH_PLANS_TO_DISTRIBUTE_CASH_FAILURE`
export const CLEAR_PLANS_TO_DISTRIBUTE_CASH = `@modules/@products/@retirement/CLEAR_PLANS_TO_DISTRIBUTE_CASH`

export const UPDATE_CASH_RECEIPT_TO_PLAN = `@modules/@products/@retirement/UPDATE_CASH_RECEIPT_TO_PLAN`
export const UPDATE_CASH_RECEIPT_TO_PLAN_SUCCESS = `@modules/@products/@retirement/UPDATE_CASH_RECEIPT_TO_PLAN_SUCCESS`
export const UPDATE_CASH_RECEIPT_TO_PLAN_FAILURE = `@modules/@products/@retirement/UPDATE_CASH_RECEIPT_TO_PLAN_FAILURE`

export const SET_SELECTED_ACCRUAL_PERIOD = `@modules/@products/@retirement/SET_SELECTED_ACCRUAL_PERIOD`

export const UPLOAD_PLAN_DOCUMENTS = `@modules/@products/@retirement/UPLOAD_PLAN_DOCUMENT`

export const FETCH_PLANS_BY_PROVIDER = `@modules/@products/@retirement/FETCH_PLANS_BY_PROVIDER`
export const FETCH_PLANS_BY_PROVIDER_SUCCESS = `@modules/@products/@retirement/FETCH_PLANS_BY_PROVIDER_SUCCESS`
export const FETCH_PLANS_BY_PROVIDER_FAILURE = `@modules/@products/@retirement/FETCH_PLANS_BY_PROVIDER_FAILURE`

export const CLOSE_MONTH = `@modules/@products/@retirement/CLOSE_MONTH`
export const CLOSE_MONTH_SUCCESS = `@modules/@products/@retirement/CLOSE_MONTH_SUCCESS`
export const CLOSE_MONTH_FAILURE = `@modules/@products/@retirement/CLOSE_MONTH_SUCCESS`

export const FETCH_RETIREMENT_PLAN_RPT = `@modules/@products/@retirement/FETCH_RETIREMENT_PLAN_RPT`
export const FETCH_RETIREMENT_PLAN_RPT_SUCCESS = `@modules/@products/@retirement/FETCH_RETIREMENT_PLAN_RPT_SUCCESS`
export const FETCH_RETIREMENT_PLAN_RPT_FAILURE = `@modules/@products/@retirement/FETCH_RETIREMENT_PLAN_RPT_FAILURE`

export const UPDATE_PLAN_BY_PROVIDER = `@modules/@products/@retirement/UPDATE_PLAN_BY_PROVIDER`
export const UPDATE_PLAN_BY_PROVIDER_SUCCESS = `@modules/@products/@retirement/UPDATE_PLAN_BY_PROVIDER_SUCCESS`
export const UPDATE_PLAN_BY_PROVIDER_FAILURE = `@modules/@products/@retirement/UPDATE_PLAN_BY_PROVIDER_FAILURE`

export const EXPORT_RPT_RETIREMENT_PLANS = `@modules/@products/@retirement/EXPORT_RPT_RETIREMENT_PLANS`
export const EXPORT_RPT_RETIREMENT_PLANS_SUCCESS = `@modules/@products/@retirement/EXPORT_RPT_RETIREMENT_PLANS_SUCCESS`
export const EXPORT_RPT_RETIREMENT_PLANS_FAILURE = `@modules/@products/@retirement/EXPORT_RPT_RETIREMENT_PLANS_FAILURE`

export const FETCH_RETIREMENT_PLAN_ALLOCATIONS = `@modules/@products/@retirement/FETCH_RETIREMENT_PLAN_ALLOCATIONS`
export const FETCH_RETIREMENT_PLAN_ALLOCATIONS_SUCCESS = `@modules/@products/@retirement/FETCH_RETIREMENT_PLAN_ALLOCATIONS_SUCCESS`
export const FETCH_RETIREMENT_PLAN_ALLOCATIONS_FAILURE = `@modules/@products/@retirement/FETCH_RETIREMENT_PLAN_ALLOCATIONS_FAILURE`
export const CLEAR_RETIREMENT_PLAN_ALLOCATIONS = `@modules/@products/@retirement/CLEAR_RETIREMENT_PLAN_ALLOCATIONS`

export const FETCH_LOOKUP_FIELDS = `@modules/@products/@retirement/FETCH_LOOKUP_FIELDS`
export const FETCH_LOOKUP_FIELDS_SUCCESS = `@modules/@products/@retirement/FETCH_LOOKUP_FIELDS_SUCCESS`
export const FETCH_LOOKUP_FIELDS_FAILURE = `@modules/@products/@retirement/FETCH_LOOKUP_FIELDS_FAILURE`

export const fetchRetirementProductPlans = {
  request: createAction(FETCH_RETIREMENT_PRODUCT_PLANS)(),
  success: createAction(FETCH_RETIREMENT_PRODUCT_PLANS_SUCCESS)<
    IRetirementProductPlan[]
  >(),
  failure: createAction(FETCH_RETIREMENT_PRODUCT_PLANS_FAILURE)<Error>()
}

export const retirementProductNavigationActions = {
  navigatetoRetirementTransaction: createAction(
    NAVIGATE_TO_RETIREMENT_PLAN_TRANSACTIONS
  )<IRetirementProductPlan>(),
  navigateToProjectedAmountByPlan: createAction(
    NAVIGATE_TO_PROJECTED_AMOUNT_BY_PLAN
  )<IRetirementProductPlan>(),
  navigateToRetirementPlan: createAction(NAVIGATE_TO_RETIREMENT_PLAN)(),
  naviagteToRetirementTransactionByClient: createAction(
    NAVIGATE_TO_RETIREMENT_PLAN_TRANSACTIONS_BY_CLIENT
  )<IRetirementProductPlan>()
}

export const fetchRetirementPlanTransactionsAction = {
  request: createAction(
    FETCH_RETIREMENT_PLAN_TRANSACTIONS
  )<FetchRetirementPlanTransactionType>(),
  success: createAction(
    FETCH_RETIREMENT_PLAN_TRANSACTIONS_SUCCESS
  )<IRetirementPlanTransactionsResponse>(),
  failure: createAction(FETCH_RETIREMENT_PLAN_TRANSACTIONS_FAILURE)<Error>(),
  clear: createAction(CLEAR_RETIREMENT_PLAN_TRANSACTIONS)()
}

export const fetchRetirementPlanAllocationActions = {
  request: createAction(
    FETCH_RETIREMENT_PLAN_ALLOCATIONS
  )<FetchRetirementPlanTransactionType>(),
  success: createAction(
    FETCH_RETIREMENT_PLAN_ALLOCATIONS_SUCCESS
  )<IPlanAllocations>(),
  failure: createAction(FETCH_RETIREMENT_PLAN_ALLOCATIONS_FAILURE)<Error>(),
  clear: createAction(CLEAR_RETIREMENT_PLAN_ALLOCATIONS)()
}

export const setSelectedRetirementPlan = {
  selectedPlan: createAction(SET_SELECTED_RETIREMENT_PLAN)<
    IRetirementProductPlan | undefined | null
  >()
}

export const setSelectedMonthYearActions = {
  selectedMonthYear: createAction(SET_SELECTED_MONTH_YEAR)<string>()
}

export const setSelectedRetirementPlanTypeActions = {
  selectedRetirementPlanType: createAction(
    SET_SELECTED_RETIREMENT_PLAN_TYPE
  )<string>()
}

export const updateRetirementCashReceiptActions = {
  request: createAction(UPDATE_CASH_RECEIPTS_REQUEST)<
    IRetirementProductCashReceipt[]
  >(),
  success: createAction(UPDATE_CASH_RECEIPTS_SUCCESS)(),
  failure: createAction(UPDATE_CASH_RECEIPTS_FAILURE)<Error>()
}

export const fetchRetirementPlanTransactionsByClientActions = {
  request: createAction(FETCH_RETIREMENT_PLAN_TRANSACTIONS_BY_CLIENT)<string>(),
  success: createAction(FETCH_RETIREMENT_PLAN_TRANSACTIONS_BY_CLIENT_SUCCESS)<
    IRetirementProductCashReceipt[]
  >(),
  failure: createAction(
    FETCH_RETIREMENT_PLAN_TRANSACTIONS_BY_CLIENT_FAILURE
  )<Error>()
}

export const fetchProjectedAmountByPlansActions = {
  request: createAction(FETCH_PROJECTED_AMOUNT_BY_PLAN)<string>(),
  success: createAction(FETCH_PROJECTED_AMOUNT_BY_PLAN_SUCCESS)<
    IProjectedAmount[]
  >(),
  failure: createAction(FETCH_PROJECTED_AMOUNT_BY_PLAN_FAILURE)<Error>(),
  clear: createAction(CLEAR_PROJECTED_AMOUNT_BY_PLAN)()
}

export const updateProjectedAmountByPlansActions = {
  request: createAction(UPDATE_PROJECTED_AMOUNT_BY_PLAN)<IProjectedAmount[]>(),
  success: createAction(UPDATE_PROJECTED_AMOUNT_BY_PLAN_SUCCESS)(),
  failure: createAction(UPDATE_PROJECTED_AMOUNT_BY_PLAN_FAILURE)<Error>()
}

export const downloadD365ExcelActions = {
  request: createAction(DOWNLOAD_D365_EXCEL)(),
  success: createAction(DOWNLOAD_D365_EXCEL_SUCCESS)(),
  failure: createAction(DOWNLOAD_D365_EXCEL_FAILURE)<Error>()
}

export const fetchPlanProvidersActions = {
  request: createAction(FETCH_PLAN_PROVIDERS)(),
  success: createAction(FETCH_PLAN_PROVIDERS_SUCCESS)<IPlanProvider[]>(),
  failure: createAction(FETCH_PLAN_PROVIDERS_FAILURE)<Error>(),
  clear: createAction(CLEAR_PLAN_PROVIDERS)()
}

export const exportPlanProvidersActions = {
  request: createAction(
    EXPORT_FUND_PROVIDERS_EXCEL
  )<IRetirementPlanExcelExportReq>(),
  success: createAction(EXPORT_FUND_PROVIDERS_EXCEL_SUCCESS)<
    IExportPlanProviderToExcel[]
  >(),
  failure: createAction(EXPORT_FUND_PROVIDERS_EXCEL_FAILURE)<Error>()
}

export const exportToExcelActions = {
  request: createAction(EXPORT_FUND_PLANS)<IPlanExcelExportReq>(),
  success: createAction(EXPORT_FUND_PLANS_SUCCESS)(),
  failure: createAction(EXPORT_FUND_PLANS_FAILURE)<Error>()
}

export const updatePlanProviderActions = {
  request: createAction(UPDATE_PLAN_PROVIDER)<IPlanProvider>(),
  success: createAction(UPDATE_PLAN_PROVIDER_SUCCESS)(),
  failure: createAction(UPDATE_PLAN_PROVIDER_FAILURE)<Error>()
}

export const viewStatementActions = {
  request: createAction(VIEW_STATEMENT)<{
    plan: IRetirementProductPlan
    period: string
  }>(),
  success: createAction(VIEW_STATEMENT_SUCCESS)(),
  failure: createAction(VIEW_STATEMENT_FAILURE)<Error>()
}

export const exportRetirementPlansToExcelActions = {
  request: createAction(EXPORT_RETIREMENT_PLANS)<IRetirementPlanExportReq>(),
  success: createAction(EXPORT_RETIREMENT_PLANS_SUCCESS)(),
  failure: createAction(EXPORT_RETIREMENT_PLANS_FAILURE)<Error>()
}

export const setSelectedPeriodActions = {
  setPeriod: createAction(SET_SELECTED_PERIOD)<string>()
}

export const fetchPlansToDistributeCashActions = {
  request: createAction(
    FETCH_PLANS_TO_DISTRIBUTE_CASH
  )<IRetirementProductCashReceipt>(),
  success: createAction(FETCH_PLANS_TO_DISTRIBUTE_CASH_SUCCESS)<
    IFetchPlanToDistributeCash[]
  >(),
  failure: createAction(FETCH_PLANS_TO_DISTRIBUTE_CASH_FAILURE)<Error>(),
  clear: createAction(CLEAR_PLANS_TO_DISTRIBUTE_CASH)()
}

export const updateCashReceiptToPlanActions = {
  request: createAction(UPDATE_CASH_RECEIPT_TO_PLAN)<{
    plans: IFetchPlanToDistributeCash[]
    cashReceipt: IRetirementProductCashReceipt
  }>(),
  success: createAction(UPDATE_CASH_RECEIPT_TO_PLAN_SUCCESS)(),
  failure: createAction(UPDATE_CASH_RECEIPT_TO_PLAN_FAILURE)<Error>()
}

export const setSelectedAccrualPeriodActions = {
  setPeriod: createAction(SET_SELECTED_ACCRUAL_PERIOD)<string>()
}

export const uploadProductDocumentActions = {
  uploadDoc: createAction(
    UPLOAD_PLAN_DOCUMENTS
  )<IUploadProductDocumentRequest>()
}

export const fetchPlansByProviderActions = {
  request: createAction(FETCH_PLANS_BY_PROVIDER)<IPlanProvider>(),
  success: createAction(FETCH_PLANS_BY_PROVIDER_SUCCESS)<{
    planproviderId: number
    plans: IPlanByProvider[]
  }>(),
  failure: createAction(FETCH_PLANS_BY_PROVIDER_FAILURE)<{
    planproviderId: number
    error: Error
  }>()
}

export const closeMonthActions = {
  request: createAction(CLOSE_MONTH)<string>(),
  success: createAction(CLOSE_MONTH_SUCCESS)<string>(),
  failure: createAction(CLOSE_MONTH_FAILURE)<Error>()
}

export const fetchRetirementPlanReportActions = {
  request: createAction(FETCH_RETIREMENT_PLAN_RPT)<{
    period: string
    producttype: number
  }>(),
  success: createAction(FETCH_RETIREMENT_PLAN_RPT_SUCCESS)<
    IRetirementPlanReport[]
  >(),
  failure: createAction(FETCH_RETIREMENT_PLAN_RPT_FAILURE)<Error>()
}

export const updatePlanPlanByProviderActions = {
  request: createAction(UPDATE_PLAN_BY_PROVIDER)<{
    provider: IPlanProvider
    plan: IPlanByProvider
  }>(),
  success: createAction(UPDATE_PLAN_BY_PROVIDER_SUCCESS)<string>(),
  failure: createAction(UPDATE_PLAN_BY_PROVIDER_FAILURE)<Error>()
}

export const exportReportRetirementPlansToExcelActions = {
  request: createAction(EXPORT_RPT_RETIREMENT_PLANS)<{
    plans: IRetirementPlanReport[]
    columns: IColumn[]
  }>(),
  success: createAction(EXPORT_RPT_RETIREMENT_PLANS_SUCCESS)(),
  failure: createAction(EXPORT_RPT_RETIREMENT_PLANS_FAILURE)<Error>()
}

export const getLookUPFieldsActions = {
  request: createAction(FETCH_LOOKUP_FIELDS)(),
  success: createAction(FETCH_LOOKUP_FIELDS_SUCCESS)<ILookupFieldsResponse>(),
  failure: createAction(FETCH_LOOKUP_FIELDS_FAILURE)<Error>()
}
export type GetLookUPFieldsActionTypes = ActionType<
  typeof getLookUPFieldsActions
>
export type RetirementProductPaymentsDataActionTypes = ActionType<
  typeof fetchRetirementProductPlans
>

export type RetirementProductNavigationActionTypes = ActionType<
  typeof retirementProductNavigationActions
>

export type RetirementProductPlanTransactionActionTypes = ActionType<
  typeof fetchRetirementPlanTransactionsAction
>

export type SetSelectedRetirementPlanActionTypes = ActionType<
  typeof setSelectedRetirementPlan
>

export type SetSelectedMonthYearActionTypes = ActionType<
  typeof setSelectedMonthYearActions
>

export type setSelectedRetirementPlanTypeActionsTypes = ActionType<
  typeof setSelectedRetirementPlanTypeActions
>

export type UpdateCashReceiptsActionTypes = ActionType<
  typeof updateRetirementCashReceiptActions
>

export type fetchRetirementPlanTransactionsByClientActionTypes = ActionType<
  typeof fetchRetirementPlanTransactionsByClientActions
>

export type fetchProjectedAmountByPlansActionTypes = ActionType<
  typeof fetchProjectedAmountByPlansActions
>

export type updateProjectedAmountByPlansActionTypes = ActionType<
  typeof updateProjectedAmountByPlansActions
>

export type downloadD365ExcelActionTypes = ActionType<
  typeof downloadD365ExcelActions
>

export type fetchPlanProvidersActionTypes = ActionType<
  typeof fetchPlanProvidersActions
>

export type updatePlanProviderActionTypes = ActionType<
  typeof updatePlanProviderActions
>

export type viewStatementActionTypes = ActionType<typeof viewStatementActions>

export type exportRetirementPlansToExcelActionTypes = ActionType<
  typeof exportRetirementPlansToExcelActions
>

export type setSelectedPeriodActionTypes = ActionType<
  typeof setSelectedPeriodActions
>

export type fetchPlansToDistributeCashActionTypes = ActionType<
  typeof fetchPlansToDistributeCashActions
>

export type updateCashReceiptToPlanActionTypes = ActionType<
  typeof updateCashReceiptToPlanActions
>

export type setSelectedAccrualPeriodActionTypes = ActionType<
  typeof setSelectedAccrualPeriodActions
>

export type uploadProductDocumentsActionTypes = ActionType<
  typeof uploadProductDocumentActions
>

export type fetchPlansByProviderActionTypes = ActionType<
  typeof fetchPlansByProviderActions
>

export type CloseMonthActionTypes = ActionType<typeof closeMonthActions>
export type FetchRetirementPlanReportActionTypes = ActionType<
  typeof fetchRetirementPlanReportActions
>

export type UpdatePlanByProviderActionTypes = ActionType<
  typeof updatePlanPlanByProviderActions
>

export type ExportReportRetirementPlansToExcelActionType = ActionType<
  typeof exportReportRetirementPlansToExcelActions
>

export type FetchRetirementPlanAllocationActionType = ActionType<
  typeof fetchRetirementPlanAllocationActions
>
export type exportToExcelPlanProvidersActionsType = ActionType<
  typeof exportPlanProvidersActions
>

export type RetirementProductActionTypes =
  | RetirementProductPaymentsDataActionTypes
  | RetirementProductNavigationActionTypes
  | RetirementProductPlanTransactionActionTypes
  | SetSelectedRetirementPlanActionTypes
  | UpdateCashReceiptsActionTypes
  | fetchRetirementPlanTransactionsByClientActionTypes
  | SetSelectedMonthYearActionTypes
  | setSelectedRetirementPlanTypeActionsTypes
  | fetchProjectedAmountByPlansActionTypes
  | updateProjectedAmountByPlansActionTypes
  | downloadD365ExcelActionTypes
  | fetchPlanProvidersActionTypes
  | updatePlanProviderActionTypes
  | viewStatementActionTypes
  | exportRetirementPlansToExcelActionTypes
  | setSelectedPeriodActionTypes
  | fetchPlansToDistributeCashActionTypes
  | updateCashReceiptToPlanActionTypes
  | setSelectedAccrualPeriodActionTypes
  | uploadProductDocumentsActionTypes
  | fetchPlansByProviderActionTypes
  | CloseMonthActionTypes
  | FetchRetirementPlanReportActionTypes
  | UpdatePlanByProviderActionTypes
  | ExportReportRetirementPlansToExcelActionType
  | FetchRetirementPlanAllocationActionType
  | GetLookUPFieldsActionTypes
  | exportToExcelPlanProvidersActionsType
