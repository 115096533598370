import { IModuleDefinition } from 'modules/types'
import { lazy } from 'react'

const FirmContainer = lazy(() => import('./FirmContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/firm/*',
    private: true,
    component: FirmContainer
  },
  name: 'Firm'
}

export default moduleDefinition
