import axios, { CancelTokenSource } from 'axios'
import { keyBy } from 'lodash'
import { flow } from 'lodash/fp'
import { combineReducers, Reducer } from 'redux'
import { call, cancelled } from 'typed-redux-saga'
import {
  getManagedAccountFeeHistory,
  IManagedAccountFeeHistory
} from '../../../../../../../api/datahub'
import { IOdataRequest } from '../../../../../../../api/odata.types'
import { IListsFilter } from '../../../../../../../features/Lists/core/contracts/IListsFilter'
import { IOdataListDataState } from '../../../../../../../features/OdataList/common/IOdataListDataState'
import { IOdataListUiState } from '../../../../../../../features/OdataList/common/IOdataListUiState'
import { convertColumnTypeToFilterType } from '../../../../../../../features/OdataList/common/service'
import {
  IOdataListColumnDefinition,
  IWithGetValue
} from '../../../../../../../features/OdataList/common/types'
import { createOdataListDataStore } from '../../../../../../../features/OdataList/store/odataListDataStore'
import { createOdataListUiStore } from '../../../../../../../features/OdataList/store/odataListUiStore'
import { IApiOptions } from '../../../../../../../shared/contracts/IApiOptions'
import { AppState } from '../../../../../../../store'
import { getRockefellerApiOptions } from '../../../../../../../store/shared/sagas'

export type ManagedAccountFeeHistoryListColumnName =
  | 'Account'
  | 'Client'
  | 'Bill Date'
  | 'Bill Period Start'
  | 'Bill Period End'
  | 'Days'
  | 'Bill Type'
  | 'Billable Value'
  | 'Manager Fee'
  | 'Manager Fee Percent'
  | 'Rockefeller Fee'
  | 'Rockefeller Fee Percent'
  | 'Client Fee'
  | 'Client Fee Percent'
  | 'Advisor Name'
  | 'Product Type'
  | 'Rep Code'
  | 'Product Name'
  | 'Debit Account'
  | 'Branch'
  | 'Billing Group Name'
  | 'Billable Household Value'

export interface IManagedAccountFeeHistoryListColumnDefinition
  extends IOdataListColumnDefinition,
    IWithGetValue<IManagedAccountFeeHistory> {
  name: ManagedAccountFeeHistoryListColumnName
}

const defaultColumn: Partial<IManagedAccountFeeHistoryListColumnDefinition> = {
  filterable: true,
  sortable: true
}

export const managedAccountFeeHistoryListColumns: IManagedAccountFeeHistoryListColumnDefinition[] =
  [
    {
      ...defaultColumn,
      name: 'Account',
      dataPath: 'Account',
      type: 'string',
      searchFields: ['Account'],
      width: 100,
      getValue: ({ account }) => account
    },
    {
      ...defaultColumn,
      name: 'Client',
      dataPath: 'Client',
      type: 'string',
      searchFields: ['Client'],
      width: 100,
      getValue: ({ client }) => client
    },
    {
      ...defaultColumn,
      name: 'Bill Date',
      dataPath: 'BillDate',
      type: 'date-only',
      width: 90,
      getValue: ({ billDate }) => billDate
    },
    {
      ...defaultColumn,
      name: 'Bill Period Start',
      dataPath: 'BillPeriodStart',
      type: 'date-only',
      width: 90,
      getValue: ({ billPeriodStart }) => billPeriodStart
    },
    {
      ...defaultColumn,
      name: 'Bill Period End',
      dataPath: 'BillPeriodEnd',
      type: 'date-only',
      width: 90,
      getValue: ({ billPeriodEnd }) => billPeriodEnd
    },
    {
      ...defaultColumn,
      name: 'Days',
      dataPath: 'Days',
      type: 'number',
      width: 40,
      getValue: ({ days }) => days
    },
    {
      ...defaultColumn,
      name: 'Bill Type',
      dataPath: 'BillType',
      type: 'string',
      facetable: true,
      width: 90,
      getValue: ({ billType }) => billType
    },
    {
      ...defaultColumn,
      name: 'Billable Value',
      dataPath: 'BillableValue',
      type: 'number',
      width: 120,
      getValue: ({ billableValue }) => billableValue
    },
    {
      ...defaultColumn,
      name: 'Manager Fee',
      dataPath: 'ManagerFee',
      type: 'number',
      width: 100,
      getValue: ({ managerFee }) => managerFee
    },
    {
      ...defaultColumn,
      name: 'Manager Fee Percent',
      dataPath: 'ManagerFeePercent',
      type: 'number',
      width: 100,
      getValue: ({ managerFeePercent }) => managerFeePercent
    },
    {
      ...defaultColumn,
      name: 'Rockefeller Fee',
      dataPath: 'RockefellerFee',
      type: 'number',
      width: 100,
      getValue: ({ rockefellerFee }) => rockefellerFee
    },
    {
      ...defaultColumn,
      name: 'Rockefeller Fee Percent',
      dataPath: 'RockefellerFeePercent',
      type: 'number',
      width: 100,
      getValue: ({ rockefellerFeePercent }) => rockefellerFeePercent
    },
    {
      ...defaultColumn,
      name: 'Client Fee',
      dataPath: 'ClientFee',
      type: 'number',
      width: 100,
      getValue: ({ clientFee }) => clientFee
    },
    {
      ...defaultColumn,
      name: 'Client Fee Percent',
      dataPath: 'ClientFeePercent',
      type: 'number',
      width: 100,
      getValue: ({ clientFeePercent }) => clientFeePercent
    },
    {
      ...defaultColumn,
      name: 'Billable Household Value',
      dataPath: 'billableHouseholdValue',
      type: 'number',
      width: 130,
      getValue: ({ billableHouseholdValue }) => billableHouseholdValue
    },
    {
      ...defaultColumn,
      name: 'Debit Account',
      dataPath: 'DebitAccount',
      type: 'string',
      width: 100,
      getValue: ({ debitAccount }) => debitAccount
    },
    {
      ...defaultColumn,
      name: 'Product Type',
      dataPath: 'ProductType',
      type: 'string',
      width: 120,
      getValue: ({ productType }) => productType
    },
    {
      ...defaultColumn,
      name: 'Product Name',
      dataPath: 'ProductName',
      type: 'string',
      width: 120,
      getValue: ({ productName }) => productName
    },
    {
      ...defaultColumn,
      name: 'Advisor Name',
      dataPath: 'AdvisorName',
      type: 'string',
      width: 120,
      getValue: ({ advisorName }) => advisorName
    },
    {
      ...defaultColumn,
      name: 'Rep Code',
      dataPath: 'RepCode',
      type: 'string',
      width: 60,
      getValue: ({ repCode }) => repCode
    },

    {
      ...defaultColumn,
      name: 'Branch',
      dataPath: 'Branch',
      type: 'string',
      width: 100,
      getValue: ({ branch }) => branch
    },
    {
      ...defaultColumn,
      name: 'Billing Group Name',
      dataPath: 'BillingGroupName',
      type: 'string',
      width: 100,
      getValue: ({ billingGroupName }) => billingGroupName
    }
  ]

const getDatahubApiOptions = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)
  return [apiOptions, source] as [IApiOptions, CancelTokenSource]
}
export const fetchManagedAccountFeeHistory = function* (
  request: IOdataRequest
) {
  const [apiOptions, cancelTokenSource] = yield* call(getDatahubApiOptions)
  try {
    return yield* call(getManagedAccountFeeHistory, apiOptions, request)
  } finally {
    if (yield* cancelled()) {
      cancelTokenSource.cancel()
    }
  }
}

const rootListSelector = (state: AppState) =>
  state.modules.advisory.modules.fees.modules.managedAccountFeeHistory
    .managedAccountFeeHistoryList
const dataStore = createOdataListDataStore(
  '@modules/@advisory/@modules/@managedAccountFeeHistoryList',
  fetchManagedAccountFeeHistory,
  flow(rootListSelector, (x) => x.data)
)
export const {
  actions: managedAccountFeeHistoryListDataActions,
  selectors: managedAccountFeeHistoryListDataSelectors
} = dataStore
const uiFilters = keyBy(
  managedAccountFeeHistoryListColumns
    .filter((x) => x.filterable)
    .map((column): IListsFilter => {
      const base = {
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        dataPath: column.dataPath,
        hasValue: false
      }

      return base
    }),
  ({ id }) => id
)

const uiStore = createOdataListUiStore({
  prefix:
    '@modules/@advisory/@modules/@accounts/@modules/@managedAccountFeeHistory',
  initialState: {
    columns: managedAccountFeeHistoryListColumns,
    filters: uiFilters,
    sortBy: { direction: 'desc', name: 'Bill Date' }
  },
  rootSelector: flow(rootListSelector, (x) => x.ui),
  dataStore
})

export const {
  selectors: managedAccountFeeHistoryListUiSelectors,
  actions: managedAccountFeeHistoryListUiActions
} = uiStore

export const managedAccountFeeHistoryListReducer: Reducer<{
  data: IOdataListDataState<IManagedAccountFeeHistory>
  ui: IOdataListUiState
}> = combineReducers({
  data: dataStore.reducer,
  ui: uiStore.reducer
})

export const managedAccountFeeHistoryListSagas = [
  ...dataStore.sagas,
  ...uiStore.sagas
]
