import { createStore } from '../../shared/annuityPPLIContracts'
import { ColumnIdsEnum } from './columnConfig'

const store = createStore({
  columnConfig: Object.values(ColumnIdsEnum),
  selectedColumns: 21,
  root: 'insurance'
})

export const { actions, selectors, reducer, sagas } = store
