import { css } from '@emotion/react'
import {
  DirectionalHint,
  HoverCard,
  HoverCardType,
  IPlainCardProps
} from '@fluentui/react'
import { USDCellWithColor } from 'modules/Advisory/modules/Rdot360/components/USDCellWithColor'
import { ITodaysGainLossActivities } from 'modules/Advisory/modules/Rdot360/store/holdingsApi/ICategoryPositionResponse'
import React, { useMemo } from 'react'
import { TodaysChangePlainCard } from './TodaysChangePlainCard'

const textDecorationLink = css({
  cursor: 'pointer',
  textDecoration: 'underline'
})

export const TodaysChange: React.FC<{
  todaysGainLoss: number
  rcntmktval?: number
  clsmktval?: number
  todaysGainLossActivities?: ITodaysGainLossActivities[]
}> = ({ todaysGainLoss, rcntmktval, clsmktval, todaysGainLossActivities }) => {
  const isActivities = todaysGainLossActivities?.length

  const plainCardProps = useMemo(
    (): IPlainCardProps => ({
      onRenderPlainCard: () => (
        <TodaysChangePlainCard
          todaysGainLossActivities={todaysGainLossActivities}
          rcntmktval={rcntmktval}
          clsmktval={clsmktval}
        />
      ),
      directionalHint: DirectionalHint.bottomCenter,
      directionalHintFixed: false,
      calloutProps: {
        isBeakVisible: true
      }
    }),
    [clsmktval, rcntmktval, todaysGainLossActivities]
  )

  if (!isActivities) {
    return <USDCellWithColor value={todaysGainLoss} fractionDigit={2} />
  }
  return (
    <HoverCard
      styles={{ host: { display: 'inline-flex' } }}
      plainCardProps={plainCardProps}
      type={HoverCardType.plain}
      cardDismissDelay={400}
      instantOpenOnClick={true}
    >
      <USDCellWithColor
        value={todaysGainLoss}
        fractionDigit={2}
        css={textDecorationLink}
      />
    </HoverCard>
  )
}
