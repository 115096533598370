import axios from 'axios'
import { call, cancelled, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { getMostRecentClosedMonth } from '../../../../../../../../../api/datahub'
import { AppState } from '../../../../../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../../../../../../../../store/shared/sagas'

export const mostRecentClosedMonthFetchActions = createAsyncAction(
  '@modules/@teams/@potentialPayout/mostRecentClosedMonthFetch/REQUEST',
  '@modules/@teams/@potentialPayout/mostRecentClosedMonthFetch/SUCCESS',
  '@modules/@teams/@potentialPayout/mostRecentClosedMonthFetch/FAILURE'
)<undefined, string | undefined, Error>()

export const mostRecentClosedMonthFetchReducer = createAsyncReducer(
  mostRecentClosedMonthFetchActions
)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.teams.modules.hurdles.features.potentialPayouts
    .mostRecentClosedMonthFetch

export const {
  getError: getMostRecentClosedMonthFetchError,
  getIsLoading: getIsMostRecentClosedMonthFetchLoading,
  getResult: getMostRecentClosedMonthFetchResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  try {
    const apiOptions = yield* call(getRockefellerApiOptions)
    const result = yield* call(getMostRecentClosedMonth, apiOptions)

    if (!result) {
      throw new Error('An error occurred while fetching teams')
    }
    yield put(mostRecentClosedMonthFetchActions.success(result))
  } catch (e: any) {
    yield put(mostRecentClosedMonthFetchActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const mostRecentClosedMonthFetchSagas = [
  () => takeLatest(mostRecentClosedMonthFetchActions.request, onRequest)
]
