import { flow } from 'lodash/fp'
import { call, delay, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { IAccount } from '../../../api/account.types'
import { OdataFilterOperatorEnum } from '../../../api/odata'
import { AppState } from '../../../store'
import { createAsyncReducer } from '../../../store/shared/asyncStore'
import { search } from '../../../store/shared/sagas'

export const alternateFeesActions = createAsyncAction(
  '@features/@accounts/@alternateFees/REQUEST',
  '@features/@accounts/@alternateFees/SUCCESS',
  '@features/@accounts/@alternateFees/FAILURE'
)<string, IAccount[], Error>()

export const alternateFeesReducer = createAsyncReducer(alternateFeesActions)

export const getAlternateFeesState = (state: AppState) =>
  state.features.accounts.alternateFees
export const getAlternateFeesResult = flow(
  getAlternateFeesState,
  (x) => x.result
)
export const getIsAlternateFeesLoading = flow(
  getAlternateFeesState,
  (x) => x.loading
)

const fetchFees = function* (
  action: ReturnType<typeof alternateFeesActions.request>
) {
  if (!action.payload) {
    return
  }

  yield delay(100)

  try {
    const result = yield* call(search, 'account' as const, {
      select: ['feeSummary', 'id', 'AccountKPIs'],
      filters: [
        {
          and: [
            {
              operator: OdataFilterOperatorEnum.eq,
              value: action.payload,
              path: 'feeSummary/debitAccount',
              type: 'string' as const
            }
          ]
        }
      ]
    })
    const accounts = result?.value as IAccount[]

    if (!accounts || !accounts.length) {
      throw new Error('Accounts not found')
    }

    yield put(alternateFeesActions.success(accounts))
  } catch (e) {
    yield put(alternateFeesActions.failure(e as Error))
  }
}

export const alternateFeesSagas = [
  () => takeLatest(alternateFeesActions.request, fetchFees)
]
