export const incomeAccountsColumnNames = {
  accounts: 'Accounts',
  symbolCUSIP: 'Symbol / CUSIP',
  secDescription: 'Description',
  taxExemptDividends: 'Tax Exempt Dividends',
  qualifiedDividends: 'Qualified Dividends',
  nonQualifiedDividends: 'Non-Qualified Dividends',
  totalDividends: 'Total Dividends',
  taxExemptInterest: 'Tax Exempt Interest',
  taxableInterest: 'Taxable Interest',
  optionPremiumsPaidReceived: 'Option Premiums Paid (-) / Received',
  totalInterest: 'Total Interest',
  capitalGainsDistribution: 'Capital Gains Distribution',
  totalIncome: 'Total Income',
  totalExcludingOptionPremiums: 'TotalExcludingOptionPremiums',
  principalPayments: 'Principal Payments'
}
