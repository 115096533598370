import { createReducer } from 'typesafe-actions'
import {
  fetchRelatedPartieActions,
  fetchStatementsActions,
  StatementListActions,
  uploadReportActions
} from './actions'
import { IRelatedParty, IStatement, IStatementRequest } from './types'

export interface IFetchStatementListState {
  error?: Error
  isLoading?: boolean
  statementList?: IStatement[]
  request?: IStatementRequest
}
export interface IRelatedPartyState {
  error?: Error
  isLoading?: boolean
  relatedParties?: IRelatedParty[]
}
export interface IUploadReportState {
  loading: boolean
  error?: Error
  status?: string
}
export interface IDownloaddStatementState {
  loading: boolean
  error?: Error
  status?: string
}
export interface IStatementListState {
  fetchStatementListState?: IFetchStatementListState
  relatedPartiesState?: IRelatedPartyState
  uploadReportState?: IUploadReportState
  downloadStatementState?: IDownloaddStatementState
}

export const initialState: IStatementListState = {}

export const statementListReducer = createReducer<
  IStatementListState,
  StatementListActions
>(initialState)
  .handleAction(fetchStatementsActions.request, (state, action) => ({
    ...state,
    fetchStatementListState: {
      isLoading: true,
      error: undefined,
      statementList: undefined,
      request: action.payload
    }
  }))
  .handleAction(fetchStatementsActions.success, (state, action) => ({
    ...state,
    fetchStatementListState: {
      ...state.fetchStatementListState,
      isLoading: false,
      error: undefined,
      statementList: action.payload
    }
  }))
  .handleAction(fetchStatementsActions.failure, (state, action) => ({
    ...state,
    fetchStatementListState: {
      ...state.fetchStatementListState,
      isLoading: false,
      error: action.payload,
      statementList: undefined
    }
  }))
  .handleAction(fetchStatementsActions.clear, (state) => ({
    ...state,
    fetchStatementListState: undefined
  }))
  .handleAction(fetchRelatedPartieActions.request, (state) => ({
    ...state,
    relatedPartiesState: {
      isLoading: true,
      error: undefined,
      relatedParties: undefined
    }
  }))
  .handleAction(fetchRelatedPartieActions.success, (state, action) => ({
    ...state,
    relatedPartiesState: {
      relatedParties: action.payload,
      isLoading: false,
      error: undefined
    }
  }))
  .handleAction(fetchRelatedPartieActions.failure, (state, action) => ({
    ...state,
    relatedPartiesState: {
      error: action.payload,
      isLoading: false,
      relatedParties: undefined
    }
  }))
  .handleAction(uploadReportActions.clear, (state) => ({
    ...state,
    uploadReportState: undefined
  }))
  .handleAction(uploadReportActions.request, (state) => ({
    ...state,
    uploadReportState: {
      loading: true,
      error: undefined,
      status: undefined
    }
  }))
  .handleAction(uploadReportActions.success, (state, action) => ({
    ...state,
    uploadReportState: {
      loading: false,
      error: undefined,
      status: action.payload.status
    }
  }))
  .handleAction(uploadReportActions.failure, (state, action) => ({
    ...state,
    uploadReportState: {
      loading: false,
      error: action.payload,
      status: undefined
    }
  }))
