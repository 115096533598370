import axios from 'axios'
import { format } from 'date-fns'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import {
  GLTransactionRquest,
  IGLTransaction,
  IGWESTransCodes
} from '../store/types'

export const fetchLedgerPaymentsService = (
  data: GLTransactionRquest,
  options: IApiOptions
) => {
  const { fromDate, toDate } = data?.dates || {}
  const fromDateString = format(fromDate, 'MM/dd/yyyy')
  const toDateString = format(toDate, 'MM/dd/yyyy')
  const url = `${options.apiRoot}/trustapi/api/Transactions/GetGLTransactions?legalentityid=${data.entityid}&fromdate=${fromDateString}&todate=${toDateString}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IGLTransaction[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const reverseLedgerPaymentService = (
  data: IGLTransaction,
  options: IApiOptions
) => {
  const url = `${
    options.apiRoot
  }/trustapi/api/Transactions/ReverseGLTransaction?legalentityid=${
    data?.entityid || ''
  }&transid=${data?.transid || ''}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<void>(url, null, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const updateTransactionService = (
  data: IGLTransaction[],
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/Transactions/UpdateGLTransactions`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<void>(url, data, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const getGwestransCodesService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/api/Transactions/GetGWESTranCodes`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IGWESTransCodes[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const postLedgerPaymentService = (
  entityid: string,
  payments: IGLTransaction[],
  options: IApiOptions
) => {
  const url = `${
    options.apiRoot
  }/trustapi/api/Transactions/PostGLAdjustments?legalentityid=${entityid || ''}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<void>(url, payments, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchEditedLedgerPaymentsService = (
  data: GLTransactionRquest,
  options: IApiOptions
) => {
  const { fromDate, toDate } = data?.dates || {}
  const fromDateString = format(fromDate, 'MM/dd/yyyy')
  const toDateString = format(toDate, 'MM/dd/yyyy')
  const url = `${options.apiRoot}/trustapi/api/Transactions/GetGLTransactions?legalentityid=${data.entityid}&fromdate=${fromDateString}&todate=${toDateString}&filter=edited`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IGLTransaction[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
