import axios from 'axios'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import {
  IFilterDateRange,
  IOfacScreenRecord,
  IOfacTransactionStatusUpdateReq
} from '../store/types'
import { onFormatDate } from '../store/utility'

export const fetchOfacScreeningService = (
  dateRange: IFilterDateRange,
  options: IApiOptions
) => {
  const url = `${
    options.apiRoot
  }/homeoffice/api/compliance/GetOFACTransactions?FromDate=${onFormatDate(
    dateRange.FromDate
  )}&ToDate=${onFormatDate(dateRange.ToDate)}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IOfacScreenRecord[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const updateOfacTransactionStatusService = (
  data: IOfacTransactionStatusUpdateReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/mm/odata/OFACScanStatus`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios.post<void>(url, data, {
    headers,
    cancelToken: options.cancelToken
  })
}
