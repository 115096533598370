import {
  Stack,
  ColumnActionsMode,
  DetailsListLayoutMode,
  TextField,
  Dropdown,
  IComboBoxOption,
  CommandBarButton,
  IDetailsFooterProps,
  DetailsRow,
  ConstrainMode
} from '@fluentui/react'
import { useDomainStore } from 'features/Domain/store/domain'
import {
  IListsDateRangeFilter,
  IListsFacetFilter
} from 'features/Lists/core/contracts/IListsFilter'
import { Dictionary, keyBy } from 'lodash'
import { DataTable } from 'modules/AIRevenue/components/DataTable'
import { SortColumn } from 'modules/shared/utilities'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataTableFilters, {
  IDataFilter
} from 'shared/components/DataTableFilters/DataTableFilters'
import { IColumnWithFilter } from 'shared/components/DataTableFilters/types'
import { PaletteComponent } from 'shared/components/Palette'
import { RdotUserRoleEnum } from 'store/user/rdotUser'
import { getRdotUserRoles } from 'store/user/selectors'
import {
  FeeTypeOptions,
  aiRevenueDashboardActions,
  convertToUTC,
  getRevenueDashboardDateRange,
  getRevenueDashboardDateRangeOptions,
  getRevenueDashboardFeeType,
  getStartOfMonth
} from '../../store/dashboard'
import { actions as altsAdvisorRevenueDetailActions } from '../AltsAdvisorRevenueDetail/store'
import {
  generateAIRevenueSummaryFooter,
  getAIRevenueSummaryTableColumns
} from './column/AIRevenueSummaryTableColumns'
import {
  filterDashboardByFilterData,
  filterDashboardBySearchtext
} from './column/utilities/FilterAndSearchUtility'
import {
  getAIRevenueSummaryFetchResult,
  getIsAIRevenueSummaryFetchLoading
} from './store/AIRevenueSummaryFetch'
import { convertToDate, getMonthHeadings } from './store/AIRevenueSummaryTable'
import {
  AIRevenueSummaryTableExportActions,
  getIsAIRevenueSummaryTableExportLoading
} from './store/AIRevenueSummaryTableExport'

export const AIRevenueSummaryTable: React.FC = () => {
  const dispatch = useDispatch()
  const roles = useSelector(getRdotUserRoles)
  const aiRevenuePartialUser = roles?.includes(
    RdotUserRoleEnum.Advisory_AIRevenue_Partial
  )
  const dateRangeOptions = useMemo(
    () => getRevenueDashboardDateRangeOptions(),
    []
  )
  const dateRange = useSelector(getRevenueDashboardDateRange)
  const feeType = useSelector(getRevenueDashboardFeeType)
  const { selectedAccountRepIds } = useDomainStore()
  const { uiActions } = altsAdvisorRevenueDetailActions
  const [sortedBy, setSortBy] = useState<{
    columnkey: string
    sortOrder: boolean
  }>()
  const isFetchLoading = useSelector(getIsAIRevenueSummaryFetchLoading)
  const isExportLoading = useSelector(getIsAIRevenueSummaryTableExportLoading)
  const monthHeadings = useSelector(getMonthHeadings)
  const airevenueState = useSelector(
    getAIRevenueSummaryFetchResult
  )?.aIRevenueData
  const aIRevenueTypeByPlanid = useSelector(
    getAIRevenueSummaryFetchResult
  )?.aIRevenueTypeByPlanid
  const [airevenuedata, setairevenuedata] = useState<typeof airevenueState>()
  useEffect(() => {
    setairevenuedata(airevenueState)
  }, [airevenueState])
  const [searchText, setSearchText] = useState<string>()
  const [dataFilters, setDataFilters] = useState<Dictionary<IDataFilter>>({})
  const [contextualMenuData, setContextualMenuData] = useState<
    | {
        ev: React.MouseEvent<HTMLElement>
        column: IColumnWithFilter
      }
    | undefined
  >(undefined)
  const onColumnClick = useCallback(
    (
      ev: React.MouseEvent<HTMLElement, MouseEvent>,
      column: IColumnWithFilter
    ) => {
      if (column.columnActionsMode !== ColumnActionsMode.disabled) {
        setContextualMenuData({ ev: ev, column: column })
      }
    },
    []
  )
  const onFilterDismiss = () => {
    setContextualMenuData(undefined)
  }
  const onColumnSort = useCallback(
    (column: IColumnWithFilter, sortOrder: boolean) => {
      setSortBy({
        columnkey: column.key,
        sortOrder: sortOrder
      })
      onFilterDismiss()
    },
    []
  )
  const handleOnSearchTextChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchText((event?.target as HTMLInputElement)?.value)
  }

  const onplanTotalClick = useCallback((item: any) => {
    const fundNameFilterId = 'CUSIP'
    const dateRangeFilterId = 'Revenue Month'
    if (
      item &&
      item.planTotal &&
      item.planid?.toString() !== 'total' &&
      item.cusip &&
      !aiRevenuePartialUser
    ) {
      dispatch(
        uiActions.updateFilters({
          [fundNameFilterId]: {
            id: fundNameFilterId,
            name: fundNameFilterId,
            type: 'facet',
            dataPath: 'cusip',
            hasValue: true,
            values: [item.cusip]
          } as IListsFacetFilter,
          [dateRangeFilterId]: {
            id: dateRangeFilterId,
            name: dateRangeFilterId,
            dataPath: 'revenueMonth',
            type: 'date',
            hasValue: false
          } as IListsDateRangeFilter
        })
      )
    } else if (
      item &&
      item.planTotal &&
      item.planid?.toString() === 'total' &&
      !aiRevenuePartialUser
    ) {
      dispatch(
        uiActions.updateFilters({
          [fundNameFilterId]: {
            id: fundNameFilterId,
            name: fundNameFilterId,
            type: 'facet',
            dataPath: 'cusip',
            hasValue: false,
            values: []
          } as IListsFacetFilter,
          [dateRangeFilterId]: {
            id: dateRangeFilterId,
            name: dateRangeFilterId,
            dataPath: 'revenueMonth',
            type: 'date',
            hasValue: false
          } as IListsDateRangeFilter
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onMonthlyPlanTotalClick = useCallback((item: any, itemKey: any) => {
    if (
      item &&
      itemKey &&
      item.planid?.toString() !== 'total' &&
      item.cusip &&
      !aiRevenuePartialUser
    ) {
      const fundNameFilterId = 'CUSIP'
      const dateRangeFilterId = 'Revenue Month'
      dispatch(
        uiActions.updateFilters({
          [fundNameFilterId]: {
            id: fundNameFilterId,
            name: fundNameFilterId,
            type: 'facet',
            dataPath: 'cusip',
            hasValue: true,
            values: [item.cusip]
          } as IListsFacetFilter,
          [dateRangeFilterId]: {
            id: dateRangeFilterId,
            name: dateRangeFilterId,
            dataPath: 'revenueMonth',
            type: 'date',
            range: 'Custom',
            from: convertToUTC(
              getStartOfMonth(convertToDate(itemKey)),
              'start'
            ),
            to: convertToUTC(convertToDate(itemKey), 'end'),
            hasValue: true
          } as IListsDateRangeFilter
        })
      )
    } else if (
      item &&
      itemKey &&
      item.planid?.toString() === 'total' &&
      !aiRevenuePartialUser
    ) {
      const fundNameFilterId = 'CUSIP'
      const dateRangeFilterId = 'Revenue Month'
      dispatch(
        uiActions.updateFilters({
          [fundNameFilterId]: {
            id: fundNameFilterId,
            name: fundNameFilterId,
            type: 'facet',
            dataPath: 'cusip',
            hasValue: false,
            values: []
          } as IListsFacetFilter,
          [dateRangeFilterId]: {
            id: dateRangeFilterId,
            name: dateRangeFilterId,
            dataPath: 'revenueMonth',
            type: 'date',
            range: 'Custom',
            from: convertToUTC(
              getStartOfMonth(convertToDate(itemKey)),
              'start'
            ),
            to: convertToUTC(convertToDate(itemKey), 'end'),
            hasValue: true
          } as IListsDateRangeFilter
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onRenderAIRevenueSummaryFooter = useCallback(
    (footerProps: IDetailsFooterProps | undefined) => {
      const footerRowObject = generateAIRevenueSummaryFooter(
        airevenuedata || [],
        monthHeadings || [],
        aIRevenueTypeByPlanid || []
      )
      if (footerProps) {
        return (
          <DetailsRow
            {...footerProps}
            id="airevenuetableFooter"
            itemIndex={-1}
            columns={footerProps.columns}
            item={footerRowObject}
            styles={{
              root: {
                position: 'sticky',
                bottom: 0,
                color: '#3A3A3A'
              }
            }}
          />
        )
      }
      return null
    },
    [aIRevenueTypeByPlanid, airevenuedata, monthHeadings]
  )

  const columns: IColumnWithFilter[] = useMemo(() => {
    let sortedColums: IColumnWithFilter[] = [
      ...getAIRevenueSummaryTableColumns({
        monthHeadings: monthHeadings,
        onColumnClick,
        onplanTotalClick,
        onMonthlyPlanTotalClick,
        aIRevenueTypeByPlanid
      })
    ].map((x) => ({
      ...x,
      isSorted: x.key === sortedBy?.columnkey,
      isSortedDescending: sortedBy?.sortOrder,
      iconName: x.key === sortedBy?.columnkey ? '' : 'ChevronDown'
    }))
    //put add isFiltered to show filtered icon
    if (dataFilters) {
      Object.entries(dataFilters).map(([key, data]) => {
        const shallowCopy = [...sortedColums]
        const shallowObject = shallowCopy.filter((x) => x.key === key)?.[0]
        const index = shallowCopy.findIndex((x) => x.key === key)
        shallowCopy.splice(index, 1, {
          ...shallowObject,
          isFiltered: data?.value ? true : false
        })
        sortedColums = shallowCopy
      })
    } else {
      sortedColums = sortedColums.map((x) => ({ ...x, isFiltered: false }))
    }
    return sortedColums
  }, [
    monthHeadings,
    onColumnClick,
    onplanTotalClick,
    onMonthlyPlanTotalClick,
    aIRevenueTypeByPlanid,
    dataFilters,
    sortedBy?.columnkey,
    sortedBy?.sortOrder
  ])
  const columnLookup = useMemo(() => keyBy(columns, (x) => x.key), [columns])
  useMemo(() => {
    const filterByFilterDataDictionary = filterDashboardByFilterData(
      airevenueState || [],
      dataFilters
    )
    const filteredData = filterDashboardBySearchtext(
      filterByFilterDataDictionary || [],
      searchText || ''
    )
    const result = sortedBy?.columnkey
      ? SortColumn(
          columnLookup[sortedBy?.columnkey],
          filteredData || [],
          columns
        )
      : { items: filteredData, columns: columns }
    setairevenuedata(result.items)
  }, [
    airevenueState,
    dataFilters,
    searchText,
    sortedBy?.columnkey,
    columnLookup,
    columns
  ])

  const onFilterApply = useCallback(
    (key: string, data: IDataFilter) => {
      const shallowCopy = { ...dataFilters }
      shallowCopy[key] = data
      setDataFilters(shallowCopy)
    },
    [dataFilters]
  )

  const onFeeTypeChange = useCallback(
    (_?: any, option?: IComboBoxOption) => {
      if (!option?.key) {
        return
      }
      dispatch(aiRevenueDashboardActions.setFeeType(option.key as string))
    },
    [dispatch]
  )

  const onDateRangeChange = useCallback(
    (_?: any, option?: IComboBoxOption) => {
      if (!option?.key) {
        return
      }
      dispatch(aiRevenueDashboardActions.setDateRange(option.key as string))
    },
    [dispatch]
  )
  //Export to Excel
  const exportToExcel = useCallback(() => {
    dispatch(
      AIRevenueSummaryTableExportActions.request({
        airevenuedata: airevenuedata,
        aIRevenueTypeByPlanid: aIRevenueTypeByPlanid,
        dateRange: dateRange,
        feeType: feeType,
        repCodes: selectedAccountRepIds?.includes('000')
          ? 'All'
          : selectedAccountRepIds?.join('|') || ''
      })
    )
  }, [
    aIRevenueTypeByPlanid,
    airevenuedata,
    dateRange,
    dispatch,
    feeType,
    selectedAccountRepIds
  ])

  return (
    <div>
      <section>
        <h2 style={{ color: '#002A3A', fontSize: 18, fontWeight: 500 }}>
          Expected Production Revenue Estimates{' '}
          <span style={{ fontSize: 14, fontWeight: 400 }}>
            - Displaying Records ({airevenuedata?.length ?? 0})
          </span>
        </h2>
        <Stack horizontal={true} horizontalAlign="space-between">
          <Stack horizontal={true} horizontalAlign="space-between">
            <Stack
              horizontal={true}
              tokens={{ childrenGap: 10, padding: '10px 0px' }}
              verticalAlign="end"
            >
              <Dropdown
                label="Date Range"
                options={dateRangeOptions}
                selectedKey={dateRange}
                onChange={onDateRangeChange}
                style={{ width: '200px' }}
              />
              <Dropdown
                label="Fee Type"
                options={FeeTypeOptions}
                selectedKey={feeType}
                onChange={onFeeTypeChange}
                style={{ width: '200px' }}
              />
            </Stack>
          </Stack>
          <Stack horizontal={true} horizontalAlign="space-between">
            <Stack
              horizontal={true}
              tokens={{ childrenGap: 10, padding: 10 }}
              verticalAlign="end"
            >
              <TextField
                placeholder="Search"
                style={{ minWidth: '400px' }}
                onChange={handleOnSearchTextChange}
              />
              <CommandBarButton
                key="export"
                name={isExportLoading ? 'Exporting...' : 'Export to Excel'}
                text={isExportLoading ? 'Exporting...' : 'Export to Excel'}
                iconProps={{ iconName: 'ExcelLogo' }}
                onClick={exportToExcel}
                style={{
                  padding: '8px',
                  backgroundColor: '#F3F1EC'
                }}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack tokens={{ childrenGap: 10, padding: 0 }}>
          <PaletteComponent
            css={{ padding: '0px', backgroundColor: '#f3f1ec' }}
            className="aiRevenueDashboard"
          >
            <div id="content">
              <DataTable
                columns={columns}
                enableShimmer={isFetchLoading}
                shimmerLines={3}
                items={airevenuedata}
                isHeaderVisible={true}
                stickyHeaderOffset={0}
                numStickyColumns={0}
                stickyColumnOffset={0}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                constrainMode={ConstrainMode.unconstrained}
                selectionPreservedOnEmptyClick
                onRenderDetailsFooter={onRenderAIRevenueSummaryFooter}
              />
              {contextualMenuData && (
                <DataTableFilters
                  data={airevenuedata || []}
                  filters={dataFilters}
                  column={contextualMenuData.column}
                  target={contextualMenuData.ev?.target as HTMLElement}
                  onColumnSort={onColumnSort}
                  onDismiss={onFilterDismiss}
                  onApply={onFilterApply}
                />
              )}
            </div>
          </PaletteComponent>
        </Stack>
      </section>
    </div>
  )
}
