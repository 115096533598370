import { ActionType, createAction } from 'typesafe-actions'
import { IGrantor } from './types'
export const NAVIGATE_TO_TRUSTINFO = '@modules/@trusts/NAVIGATE_TO_TRUSTINFO'
export const NAVIGATE_TO_NEWTRUST = '@modules/@trusts/NAVIGATE_TO_NEWTRUST'
export const FETCH_GRANTOR_INFO = '@modules/@trusts/FETCH_GRANTOR_INFO'
export const FETCH_GRANTOR_INFO_SUCCESS =
  '@modules/@trusts/FETCH_GRANTOR_INFO_SUCCESS'
export const FETCH_GRANTOR_INFO_FAILURE =
  '@modules/@trusts/FETCH_GRANTOR_INFO_FAILURE'

export const trustNavigationActions = {
  navigateToTrustInfo: createAction(NAVIGATE_TO_TRUSTINFO)(),
  navigateToNewTrust: createAction(NAVIGATE_TO_NEWTRUST)()
}
export const grantorsInfoDataActions = {
  request: createAction(FETCH_GRANTOR_INFO)(),
  success: createAction(FETCH_GRANTOR_INFO_SUCCESS)<IGrantor[]>(),
  failure: createAction(FETCH_GRANTOR_INFO_FAILURE)<any>()
}
export type TrustNavigationActions = ActionType<typeof trustNavigationActions>

export type GrantorInfoDataActions = ActionType<typeof grantorsInfoDataActions>

export type GrantorInfoActionType =
  | TrustNavigationActions
  | GrantorInfoDataActions
