import {
  call,
  put,
  takeLatest
  // tslint:disable-next-line: no-submodule-imports
} from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../../../store/shared/sagas'
import {
  fetchPostingRulesList,
  fetchInstitutionList,
  fetchTransactionList,
  fetchPayeeList,
  fetchMainAccountList,
  fetchSubAccountList,
  addEditRule,
  fetchProjectList,
  fetchPersonList
} from '../api/postingRulesService'
import {
  postingRulesDataActions,
  institutionsDataActions,
  transactionsDataActions,
  payeesDataActions,
  mainAccountsDataActions,
  subAccountsDataActions,
  addEditRuleDataActions,
  AddEditRuleDataActionType,
  projectDataActions,
  ProjectDataActionTypes,
  personDataActions,
  PersonDataActionTypes
} from './actions'
import { IPostingRulesRequest, IAddEditRulesRequest } from './types'

function* handleFetchPostingRules(
  action: ReturnType<typeof postingRulesDataActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const postingRulesRequest = action.payload as IPostingRulesRequest
    const data = yield* call(
      fetchPostingRulesList,
      options,
      postingRulesRequest
    )

    yield put(postingRulesDataActions.success(data))
  } catch (e: any) {
    yield put(postingRulesDataActions.failure(e))
  }
}
function* handleFetchInstitutions(
  action: ReturnType<typeof institutionsDataActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const institutiosRequest = action.payload as IPostingRulesRequest
    const data = yield* call(fetchInstitutionList, options, institutiosRequest)
    yield put(institutionsDataActions.success(data))
  } catch (e: any) {
    yield put(institutionsDataActions.failure(e))
  }
}
function* handleFetchTransactions() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(fetchTransactionList, options)
    yield put(transactionsDataActions.success(data))
  } catch (e: any) {
    yield put(transactionsDataActions.failure(e))
  }
}
function* handleFetchPayees(
  action: ReturnType<typeof payeesDataActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const payeesRequest = action.payload as IPostingRulesRequest
    const data = yield* call(fetchPayeeList, options, payeesRequest)
    yield put(payeesDataActions.success(data))
  } catch (e: any) {
    yield put(payeesDataActions.failure(e))
  }
}
function* handleFetchMainAccounts() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(fetchMainAccountList, options)
    yield put(mainAccountsDataActions.success(data))
  } catch (e: any) {
    yield put(mainAccountsDataActions.failure(e))
  }
}
function* handleFetchSubAccounts(
  action: ReturnType<typeof subAccountsDataActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const subAccountsRequest = action.payload
    const data = yield* call(fetchSubAccountList, options, subAccountsRequest)

    yield put(subAccountsDataActions.success(data))
  } catch (e: any) {
    yield put(subAccountsDataActions.failure(e))
  }
}
function* handleAddEditRule(action: AddEditRuleDataActionType) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const addEditRequest = action.payload
    const data = yield* call(
      addEditRule,
      options,
      addEditRequest as IAddEditRulesRequest
    )
    yield put(addEditRuleDataActions.success(data))
  } catch (e: any) {
    yield put(addEditRuleDataActions.failure(e))
  }
}
function* handleFetchProjectList(action: ProjectDataActionTypes) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const projectRequest = action.payload as IPostingRulesRequest
    const data = yield* call(fetchProjectList, options, projectRequest)
    yield put(projectDataActions.success(data))
  } catch (e: any) {
    yield put(projectDataActions.failure(e))
  }
}
function* handleFetchPersonList(action: PersonDataActionTypes) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const personRequest = action.payload as IPostingRulesRequest
    const data = yield* call(fetchPersonList, options, personRequest)
    yield put(personDataActions.success(data))
  } catch (e: any) {
    yield put(personDataActions.failure(e))
  }
}
export const sagas = [
  () => takeLatest(postingRulesDataActions.request, handleFetchPostingRules),
  () => takeLatest(institutionsDataActions.request, handleFetchInstitutions),
  () => takeLatest(transactionsDataActions.request, handleFetchTransactions),
  () => takeLatest(payeesDataActions.request, handleFetchPayees),
  () => takeLatest(mainAccountsDataActions.request, handleFetchMainAccounts),
  () => takeLatest(subAccountsDataActions.request, handleFetchSubAccounts),
  () => takeLatest(addEditRuleDataActions.request, handleAddEditRule),
  () => takeLatest(projectDataActions.request, handleFetchProjectList),
  () => takeLatest(personDataActions.request, handleFetchPersonList)
]
