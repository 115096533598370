import { push } from 'connected-react-router'
import { downloadAttachment } from 'modules/Advisory/modules/Rdot360/hooks/useDownloadAttachment'
import { TransactionType } from 'modules/Transfers/features/MoneyMovement/EFT/Constants'
import { setFormDataActions } from 'modules/Transfers/features/MoneyMovement/store/actions'
import {
  prepareDisbursmentApprovalForReview,
  prepareWireDataForEdit
} from 'modules/Transfers/features/MoneyMovement/utilities/Utilities'
import { RangeType } from 'shared/components/DataTableFilters/DataTableFilters'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { call, put, takeLatest } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../../../store/shared/sagas'
import {
  fetchDetailsByID,
  fetchPaymentList,
  fetchPreference,
  savePreference,
  fetchPaymentExcelService,
  resendSMSService
} from '../api/DashboardService'
import { getDefaultColumns } from '../Constants'
import { getDateMinMax } from '../utilities/Utilities'
import {
  dashboardPreferenceActions,
  exportPaymentsToExcelActions,
  fetchPaymentByIDActions,
  fetchPaymentListDataActions,
  paymentDashboardNavigationActions,
  resendSMSLinkActions
} from './actions'
import { DashboardPreference, IAuthzLinkRsp, PaymentRow } from './types'

function* handleFetchPaymentList(
  action: ReturnType<typeof fetchPaymentListDataActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const response = yield* call(fetchPaymentList, action.payload, options)

    if (response?.rspStatus?.statusCode === '0') {
      const data = response?.rspn ?? undefined
      const paymentRows: PaymentRow[] | undefined = data?.map(
        (x) =>
          (({
            refnum: x?.paymentMM?.pmmID,
            fedRefNumber: x?.paymentMM?.fedRefNumber,
            status: x?.paymentMM?.displayStatus,
            statusdescription: x?.paymentMM?.mmStatusDescription,
            account: x?.paymentMM?.paymentAccount,
            confirmNo: x?.paymentMM.confirmNo,
            payee:
              x?.paymentMM?.furtherCredit2_NameLine1 ??
              x?.paymentMM?.furtherCredit1_NameLine1 ??
              x?.paymentMM?.beneficiaryName,
            transtype: x?.paymentMM?.tranType,
            clientname: x?.paymentMM?.clientName,
            amount: x?.paymentMM?.tranAmt,
            processdate: x?.paymentMM?.tranDate,
            creationdate: x?.paymentMM?.createdOn,
            assignToId: x?.paymentMM?.assignToId,
            actions: x?.paymentMM?.nextAction,
            createdBy: x?.paymentMM?.createdBy,
            repId: x?.paymentMM?.repId,
            executingCustodian: x?.paymentMM?.executingCustodian,
            futureDated: x?.paymentMM?.futureDated,
            beneficiaryAcct: x?.paymentMM?.beneficiaryAcct,
            cashavailable: '',
            isRecurringPaymentPlan: x?.paymentMM?.isRecurringPaymentPlan,
            isRecurringPayment: x?.paymentMM?.isRecurringPayment,
            fromRecurringPlanPMTId: x?.paymentMM?.fromRecurringPlanPMTId,
            recurringFrequency: x?.paymentMM?.recurringFrequency,
            accountShortName: x?.paymentMM?.accountShortName,
            rowData: x
          } as PaymentRow) ?? undefined)
      )
      yield put(
        fetchPaymentListDataActions.success({
          paymentList: paymentRows || [],
          statistics: response?.statistics || []
        })
      )
    } else {
      yield put(
        fetchPaymentListDataActions.failure(
          new Error(response?.rspStatus?.message)
        )
      )
    }
  } catch (e: any) {
    yield put(fetchPaymentListDataActions.failure(e))
  }
}

function* handleFetchPaymentbyID(
  action: ReturnType<typeof fetchPaymentByIDActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const response = yield* call(fetchDetailsByID, action.payload.ID, options)

    if (response?.rspStatus?.statusCode === '0') {
      const data = response?.rspn
      if (action.payload.reset) {
        yield put(
          setFormDataActions.setWireFormData(
            prepareWireDataForEdit(data?.[0].paymentMM)
          )
        )
        yield put(
          setFormDataActions.setDisbursmentApprovalViewData(
            prepareDisbursmentApprovalForReview(data?.[0].paymentMM)
          )
        )
      }
      yield put(
        fetchPaymentByIDActions.success({
          paymentDetails: data?.[0].paymentMM
        })
      )
    } else {
      yield put(
        fetchPaymentListDataActions.failure(
          new Error(response?.rspStatus?.message)
        )
      )
    }
  } catch (e: any) {
    yield put(fetchPaymentListDataActions.failure(e))
  }
}

function* handleNavigateToEdit(
  action: ReturnType<typeof paymentDashboardNavigationActions.navigateToEdit>
) {
  if (action.payload === TransactionType.Journal) {
    yield put(push('/transfers/journal/new'))
  } else if (action.payload === TransactionType.EFT) {
    yield put(push('/transfers/eft/new'))
  } else {
    yield put(push('/transfers/wire/new'))
  }
}

function* handleNavigateToView(
  action: ReturnType<typeof paymentDashboardNavigationActions.navigateToView>
) {
  if (action.payload === TransactionType.EFT) {
    yield put(push('/transfers/eft/view'))
  } else {
    yield put(push('/transfers/wire/view'))
  }
}

function* handleNavigateToUpdateFedRefNo() {
  yield put(push('/transfers/wire/updateFedRefNo'))
}
function* handleNavigateToUpdateDFDisclosure() {
  yield put(push('/transfers/wire/updateDFDisclosure'))
}
function* handleNavigateToUpdateDFAcknowledge() {
  yield put(push('/transfers/wire/updateDFAcknowledge'))
}

function* handleNavigateToReview() {
  yield put(push('/transfers/approval'))
}

function* handleGetPreference() {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: DashboardPreference = yield* call(() =>
      fetchPreference(options)
    )
    const columnSettings = [
      data?.TransfersColumnSettings || [],
      data?.TransfersRecurringPlanColumnSettings || []
    ]
    // add new columns to the list which are not in pref
    columnSettings.forEach((colSetting, index) => {
      getDefaultColumns(index === 2).forEach((colItem) => {
        const col = colSetting?.find((x) => x.key === colItem.key)
        if (!col) {
          colSetting?.push(colItem)
        }
      })
    })
    const filterList = [
      data?.TransfersDashboardFilters || {},
      data?.TransfersRecurringPlanFilters || {}
    ]
    filterList.forEach((filter) => {
      if (filter?.creationdate?.value) {
        const value = filter?.creationdate?.value as RangeType
        // refetch from and to dates as dates saved in preference may not be relevant
        const updatedDates = getDateMinMax(value?.rangeKey || '')
        filter.creationdate.value = {
          minValue: updatedDates.min,
          maxValue: updatedDates.max,
          rangeKey: value?.rangeKey
        }
      }
      if (filter?.processdate?.value) {
        const value = filter?.processdate?.value as RangeType
        // refetch from and to dates as dates saved in preference may not be relevant
        const updatedDates = getDateMinMax(value?.rangeKey || '')
        filter.processdate.value = {
          minValue: updatedDates.min,
          maxValue: updatedDates.max,
          rangeKey: value?.rangeKey
        }
      }
    })

    yield put(dashboardPreferenceActions.getPreference_success(data || {}))
  } catch (e: any) {
    yield put(dashboardPreferenceActions.getPreference_success({}))
    console.log('unable to fetch preference')
  }
}

function* handleSavePreference(
  action: ReturnType<typeof dashboardPreferenceActions.setPreference>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    yield* call(() => savePreference(options, action.payload))
  } catch (e: any) {
    console.log('unable to save preference')
  }
}
function* handleExportPaymentsToExcel() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const response = yield* call(() => fetchPaymentExcelService(options))

    yield put(exportPaymentsToExcelActions.success())

    if (response) {
      if (response?.data?.message === 'Success') {
        downloadAttachment(
          response?.data?.content || '',
          'application/zip',
          'PaymentTransactionsReport.zip'
        )
      } else {
        yield put(
          exportPaymentsToExcelActions.failure(
            new Error(
              'Someting went wrong. Unable to downoad excel report file. Please try again in later'
            )
          )
        )
      }
    }
  } catch (e: any) {
    yield put(exportPaymentsToExcelActions.failure(e))
  }
}
function* handleResendSMSAuthorization(
  action: ReturnType<typeof resendSMSLinkActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const response: IAuthzLinkRsp = yield* call(() =>
      resendSMSService(options, action.payload)
    )
    if (response?.statusCode === '0' && response?.message === 'Success') {
      yield put(resendSMSLinkActions.success(response))
    } else {
      yield put(resendSMSLinkActions.failure(new Error(response?.message)))
    }
  } catch (e: any) {
    yield put(
      resendSMSLinkActions.failure(
        new Error(
          'Something went wrong. Please resend SMS link again in a few minutes'
        )
      )
    )
  }
}

export const sagas = [
  () => takeLatest(fetchPaymentListDataActions.request, handleFetchPaymentList),
  () =>
    takeLatest(
      paymentDashboardNavigationActions.navigateToEdit,
      handleNavigateToEdit
    ),
  () =>
    takeLatest(
      paymentDashboardNavigationActions.navigateToView,
      handleNavigateToView
    ),
  () =>
    takeLatest(
      paymentDashboardNavigationActions.navigateToUpdateFedRef,
      handleNavigateToUpdateFedRefNo
    ),
  () =>
    takeLatest(
      paymentDashboardNavigationActions.navigateToUpdateDFAcknowledge,
      handleNavigateToUpdateDFAcknowledge
    ),
  () =>
    takeLatest(
      paymentDashboardNavigationActions.navigateToUpdateDFDisclosure,
      handleNavigateToUpdateDFDisclosure
    ),
  () =>
    takeLatest(
      paymentDashboardNavigationActions.navigateToReview,
      handleNavigateToReview
    ),
  () => takeLatest(fetchPaymentByIDActions.request, handleFetchPaymentbyID),
  () =>
    takeLatest(
      dashboardPreferenceActions.getPreference_request,
      handleGetPreference
    ),
  () =>
    takeLatest(dashboardPreferenceActions.setPreference, handleSavePreference),
  () =>
    takeLatest(
      exportPaymentsToExcelActions.request,
      handleExportPaymentsToExcel
    ),
  () => takeLatest(resendSMSLinkActions.request, handleResendSMSAuthorization)
]
