import { ITheme } from '@fluentui/react'

export const getThemedClasses = (theme: ITheme) => {
  const activeBgColor = theme.palette.neutralLighter
  return {
    rowItem: [
      {
        ':hover': {
          backgroundColor: activeBgColor,
          cursor: 'pointer'
        },
        padding: '4px 5px'
      }
    ]
  }
}
