import axios from 'axios'
import { IEnvironmentConfiguration } from './IEnvironmentConfiguration'

const init = () => {
  const fileName =
    process.env.NODE_ENV === 'development'
      ? 'environment.dev.json'
      : 'environment.json'

  return axios
    .get<IEnvironmentConfiguration>(
      `/${fileName}?build=${process.env.REACT_APP_BUILD_ID}`
    )
    .then((x) => x.data)
}

const promise = init()

export const getEnvironment = () => promise
