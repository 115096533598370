import { MessageBar, MessageBarType } from '@fluentui/react'
import React, { PureComponent } from 'react'

export interface IErrorComponentProps {
  errorMessage: string
  style?: React.CSSProperties
}

export class ErrorComponent extends PureComponent<IErrorComponentProps> {
  public render() {
    const { errorMessage, style } = this.props
    if (!errorMessage) {
      return null
    }
    return (
      <div style={style}>
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
          dismissButtonAriaLabel="Close"
        >
          {errorMessage}
        </MessageBar>
      </div>
    )
  }
}
