import { ISecurity } from 'api/security.types'
import { createListsStore } from 'features/Lists/core/store'
import {
  createDefaultColumnState,
  mapColumnsToFilterDefinitions
} from 'features/Lists/shared/lists'
import { partial } from 'lodash/fp'
import { AppState } from '../../../store'
import { SecurityColumnDefinitions } from '../ColumnDefinitions'

export const constructInitialSecuritiesColumnState = partial(
  createDefaultColumnState,
  [SecurityColumnDefinitions]
)

export const securityListStore = createListsStore<ISecurity>(
  'security',
  '@features/@lists/@securities',
  {
    columnDefinitions: SecurityColumnDefinitions,
    filterDefinitions: mapColumnsToFilterDefinitions(SecurityColumnDefinitions)
  },
  (state: AppState) => state.features.lists.security
)

export const { reducer, sagas, actions, selectors } = securityListStore
