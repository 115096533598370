import { Dialog } from '@fluentui/react'
import { useCallback, useState } from 'react'
import { SimpleOverlay } from 'shared/components/SimpleOverlay'
import { IndeterminateProgressIndicator } from '../../components/shared'
import { buttonStyles } from '../../components/shared/Buttons'
import { inputClasses } from '../../components/shared/Inputs'
import { SnackBar } from '../../components/shared/Snackbar'
import { useUpdateCagNameMutation } from '../../store/dynamics'

export const EditGroupNameDialog: React.FC<{
  hidden?: boolean
  selectedId: string
  hide: () => void
  originalName: string
}> = ({ hidden, selectedId, originalName, hide }) => {
  const [name, setName] = useState(originalName)
  const onDismiss = useCallback(() => {
    hide()
  }, [hide])
  const [editName, editNameResult] = useUpdateCagNameMutation()
  const onApply = useCallback(async () => {
    try {
      await editName({
        cagName: name,
        id: selectedId
      }).unwrap()
      hide()
    } catch {
      return
    }
  }, [editName, hide, name, selectedId])
  const { error, isLoading } = editNameResult

  return (
    <Dialog
      hidden={hidden}
      onDismiss={onDismiss}
      minWidth={'450px'}
      modalProps={{
        isBlocking: true
      }}
      dialogContentProps={{
        styles: {
          header: {
            borderBottom: '1px solid rgba(0,0,0,0.1)',
            height: 0,
            padding: 0,
            zIndex: 1
          },
          content: {
            backgroundColor: '#F5F5F5'
          },
          inner: { padding: 0 }
        }
      }}
    >
      {isLoading && <SimpleOverlay />}
      <div
        css={(theme) => ({
          fontSize: theme.size.lg1,
          fontWeight: theme.fontWeights.demi,
          color: theme.colors.primaryDarkBlue,
          height: '60px',
          borderBottom: '1px solid rgba(0,0,0,0.1)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0 10px'
        })}
      >
        Edit Custom Account Group Name
      </div>
      {isLoading && <IndeterminateProgressIndicator />}
      <div css={{ padding: '0 24px 24px' }}>
        <>
          <div css={{ padding: '12px 0' }}>
            <input
              css={inputClasses.input}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          {error ? (
            <SnackBar
              type="Failure"
              message={(error as Error)?.message || 'An unknown error occurred'}
            />
          ) : null}
          <div
            css={{
              padding: '20px 0 20px',
              display: 'flex',
              justifyContent: 'end',
              gridColumnGap: 5
            }}
          >
            <button
              onClick={onDismiss}
              css={[buttonStyles.secondary, { width: '100px' }]}
            >
              Cancel
            </button>
            <button
              onClick={onApply}
              css={[
                buttonStyles.primary,
                {
                  width: '100px'
                }
              ]}
              disabled={!name.length || name === originalName}
            >
              Save
            </button>
          </div>
        </>
      </div>
    </Dialog>
  )
}
