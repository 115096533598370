import { css } from '@emotion/react'
import { FC, useMemo } from 'react'
import TileHeading, { BaseHeaderProps } from '../../../components/TileHeading'
import { TileLoadingOverlay } from '../../../components/TileLoadingOverlay'
import { useRdot360PerformanceContext } from '../../../store/rdot360Context/useRdot360PerformanceContext'
import PerformanceTable from './PerformanceTable'

const getClasses = () => ({
  performanceContainer: css({
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 5
  })
})

const Performance: FC<BaseHeaderProps> = ({ disableExpand, onExpand }) => {
  const classes = useMemo(() => getClasses(), [])
  const { isFetching } = useRdot360PerformanceContext()

  return (
    <>
      <div css={classes.performanceContainer}>
        <TileHeading disableExpand={disableExpand} onExpand={onExpand}>
          Performance
        </TileHeading>
        <PerformanceTable />
      </div>
      {isFetching && <TileLoadingOverlay />}
    </>
  )
}

export default Performance
