import { ActionType, createAction } from 'typesafe-actions'
import { IClient } from '../../../../../../../../api/client.types'

export const FETCH_TRUST_AUM_SUMMARY =
  '@modules/@payments/@trust/FETCH_TRUST_AUM_SUMMARY'
export const FETCH_TRUST_AUM_SUMMARY_SUCCESS =
  '@modules/@payments/@trust/FETCH_TRUST_AUM_SUMMARY_SUCCESS'
export const FETCH_TRUST_AUM_SUMMARY_FAILURE =
  '@modules/@payments/@trust/FETCH_TRUST_AUM_SUMMARY_FAILURE'

export const trustAumSummaryDataActions = {
  request: createAction(FETCH_TRUST_AUM_SUMMARY)<string>(),
  success: createAction(FETCH_TRUST_AUM_SUMMARY_SUCCESS)<IClient[]>(),
  failure: createAction(FETCH_TRUST_AUM_SUMMARY_FAILURE)<any>()
}

export type TrustsAumSummaryDataActions = ActionType<
  typeof trustAumSummaryDataActions
>

export type TrustAumSummaryActionTypes = TrustsAumSummaryDataActions
