import axios from 'axios'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import {
  IPerformanceReport,
  IReportDoc,
  IRunJob,
  IRunReport
} from '../store/types'

const fetchDashboardFeesService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/performance/getreportsummary`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPerformanceReport | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const viewReport = (req: IReportDoc, options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/Performance/viewReport/${req.reqId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<string>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const RunReportJobService = (req: IRunReport, options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/Performance/downloadReports?date=${req.date}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IRunJob>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export { fetchDashboardFeesService, viewReport, RunReportJobService }
