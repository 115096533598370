import {
  ContextualMenu,
  ContextualMenuItemType,
  DirectionalHint,
  IColumn,
  IContextualMenuItem,
  IContextualMenuListProps,
  IRenderFunction
} from '@fluentui/react'
import { debounce, keyBy } from 'lodash'
import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react'
import {
  DataTable,
  IDataTableProps
} from '../../../shared/components/DataTable'
import { IListsFilter } from '../../Lists/core/contracts/IListsFilter'
import {
  IListsFilterEditWithApplyCancel,
  ListsFilterEditWithApplyCancel
} from '../../Lists/core/features/filter/components/ListsFilterEditWithApplyCancel'
import { convertColumnTypeToFilterType } from '../../OdataList/common/service'
import { IDataTableColumnDefinition, IDataTableSortBy } from '../common/types'

export interface IDataTableCellRenderProps<T> {
  item: T
  column: IDataTableColumnDefinition
}

export interface IDataTableWithMenuProps<T>
  extends Omit<IDataTableProps, 'columns' | 'items'> {
  columns: IDataTableColumnDefinition[]
  items: T[]
  cellComponent: React.FC<IDataTableCellRenderProps<T>>
  sortBy?: IDataTableSortBy
  onSortByChange?: (sortBy: IDataTableSortBy) => void
  filters?: Record<string, IListsFilter>
  onFilterChange?: (filter: IListsFilter) => void
  onColumnClick?: (column: IDataTableColumnDefinition) => void
  customListsFilterEditWithApplyCancel?: React.FC<IListsFilterEditWithApplyCancel>
  onRemoveColumn?: (name: string) => void
}

const rightAlignTypes = ['number', 'date', 'date-only']

export const DataTableWithMenu = <T,>({
  columns = [],
  filters,
  onFilterChange,
  sortBy,
  onSortByChange,
  onColumnClick,
  cellComponent: CellComponent,
  onColumnResize,
  customListsFilterEditWithApplyCancel,
  onRemoveColumn,
  ...rest
}: PropsWithChildren<
  IDataTableWithMenuProps<T>
>): React.ReactElement | null => {
  const CustomListsFilterEditWithApplyCancel =
    customListsFilterEditWithApplyCancel
  const menuTargetRef = useRef<Element | null>(null)
  const [activeColumn, setActiveColumn] = useState<IDataTableColumnDefinition>()

  const columnLookkup = useMemo(
    () => keyBy(columns, ({ name }) => name),
    [columns]
  )

  const activeFilter = useMemo(
    (): IListsFilter | undefined =>
      activeColumn?.filterable && filters
        ? filters[activeColumn.name] || {
            id: activeColumn.name,
            name: activeColumn.displayName || activeColumn.name,
            type: convertColumnTypeToFilterType(activeColumn),
            hasValue: false
          }
        : undefined,
    [activeColumn, filters]
  )

  const removeFilter = useCallback(
    (filter: IListsFilter) => {
      const { id, name, type } = filter
      onFilterChange?.({ id, name, type })
      setActiveColumn(undefined)
    },
    [onFilterChange]
  )

  const onRenderMenuList = useCallback(
    (
      menuListProps?: IContextualMenuListProps,
      defaultRender?: IRenderFunction<IContextualMenuListProps>
    ) => {
      if (!activeColumn) {
        return null
      }

      const onApply = (filter: IListsFilter) => {
        onFilterChange?.(filter)
        setActiveColumn(undefined)
      }

      return (
        <>
          {defaultRender?.(menuListProps)}
          {onFilterChange && activeFilter && (
            <div
              style={{
                padding: '10px'
              }}
            >
              {CustomListsFilterEditWithApplyCancel ? (
                <CustomListsFilterEditWithApplyCancel
                  filter={activeFilter}
                  onApply={onApply}
                  onCancel={() => setActiveColumn(undefined)}
                />
              ) : (
                <ListsFilterEditWithApplyCancel
                  filter={activeFilter}
                  onApply={onApply}
                  onCancel={() => setActiveColumn(undefined)}
                />
              )}
            </div>
          )}
        </>
      )
    },
    [
      CustomListsFilterEditWithApplyCancel,
      activeColumn,
      activeFilter,
      onFilterChange
    ]
  )

  const menuItems = useMemo((): IContextualMenuItem[] => {
    if (!activeColumn) {
      return []
    }

    const { name, filterable, sortable, removable } = activeColumn
    const showRemoveOption = removable && !!onRemoveColumn
    const showSortOptions = sortable && !!onSortByChange
    const sortItems = [
      showSortOptions && {
        key: 'aToZ',
        name: 'Sort Ascending',
        iconProps: { iconName: 'SortUp' },
        onClick: () => onSortByChange?.({ name, direction: 'asc' })
      },
      showSortOptions && {
        key: 'zToA',
        name: 'Sort Descending',
        iconProps: { iconName: 'SortDown' },
        onClick: () => onSortByChange?.({ name, direction: 'desc' })
      },
      showRemoveOption && {
        key: 'remove',
        name: 'Hide This Column',
        iconProps: { iconName: 'Hide3' },
        onClick: () => onRemoveColumn(name)
      }
    ].filter(Boolean) as IContextualMenuItem[]

    const filterItems =
      filterable && onFilterChange
        ? [
            {
              key: 'filter-divider',
              text: 'Filter',
              iconProps: { iconName: 'Filter' },
              itemType: ContextualMenuItemType.Header
            },
            {
              key: 'filter',
              name: `Clear filter from "${name}"`,
              iconProps: { iconName: 'ClearFilter' },
              disabled: !activeFilter?.hasValue,
              onClick: () => activeFilter && removeFilter(activeFilter)
            }
          ]
        : []

    return [...sortItems, ...filterItems]
  }, [
    activeColumn,
    activeFilter,
    onFilterChange,
    onRemoveColumn,
    onSortByChange,
    removeFilter
  ])

  const onColumnHeaderClick = useCallback(
    (ev?: React.MouseEvent<HTMLElement>, column?: IColumn) => {
      const dataListColumn = columnLookkup[column?.key || '']
      if (!dataListColumn || !dataListColumn.name || !ev?.currentTarget) {
        return
      }

      onColumnClick?.(dataListColumn)

      menuTargetRef.current = ev.currentTarget
      setActiveColumn(dataListColumn)
    },
    [columnLookkup, onColumnClick]
  )

  const pendingResizeMap = useRef<Record<string, number>>({})
  const [columnSizeMap, setColumnSizeMap] = useState<Record<string, number>>({})
  const dataTableColumns = useMemo(
    () =>
      columns
        .filter(({ hidden }) => !hidden)
        .map((column): IColumn => {
          const { name, displayName, width } = column
          return {
            key: name,
            name: displayName || name,
            minWidth: 50,
            maxWidth: columnSizeMap[name] || width,
            isResizable: true,
            isCollapsible: true,
            isSorted: sortBy?.name === name,
            isSortedDescending: sortBy?.direction === 'desc',
            className: rightAlignTypes.includes(column.type)
              ? 'align-right'
              : '',
            headerClassName: rightAlignTypes.includes(column.type)
              ? 'align-right'
              : ''
          }
        }),
    [columnSizeMap, columns, sortBy]
  )

  const onRenderItemColumn = useCallback(
    (item?: any, index?: number, column?: IColumn) => (
      <CellComponent item={item} column={columnLookkup[column?.key || '']} />
    ),
    [CellComponent, columnLookkup]
  )

  const fireOnResizeDebounced = useMemo(
    () =>
      debounce(() => {
        setColumnSizeMap({ ...pendingResizeMap.current })
      }, 200),
    []
  )

  const onColumnResizeLocal = useCallback(
    (column?: IColumn, newWidth?: number) => {
      if (!column?.key || !newWidth) {
        return
      }

      pendingResizeMap.current[column.key] = newWidth
      fireOnResizeDebounced()
    },
    [fireOnResizeDebounced]
  )

  return (
    <>
      <DataTable
        {...rest}
        onRenderItemColumn={onRenderItemColumn}
        onColumnHeaderClick={onColumnHeaderClick}
        onColumnResize={onColumnResize || onColumnResizeLocal}
        columns={dataTableColumns}
      />
      {activeColumn && (
        <ContextualMenu
          target={menuTargetRef}
          items={menuItems}
          onRenderMenuList={onRenderMenuList}
          directionalHint={DirectionalHint.bottomLeftEdge}
          isBeakVisible={true}
          shouldFocusOnMount={false}
          onDismiss={() => setActiveColumn(undefined)}
        />
      )}
    </>
  )
}
