import {
  Checkbox,
  ChoiceGroup,
  Dropdown,
  IChoiceGroupOption,
  IDropdownOption,
  Stack,
  Text
} from '@fluentui/react'
import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useUserSettings } from 'shared/hooks/useUserSettings'
import {
  getStorageSettings,
  setStorageSettings
} from 'shared/services/settings'
import { ThemeType } from 'store/ui/types'
import { getIsEnvironmentProduction } from '../../../store/system'

const apiVersionOptions = ['', 'v1', 'v2', 'r2'].map(
  (x): IDropdownOption => ({ key: x, text: x })
)

export const SettingsContainer: React.FC = () => {
  const isProd = useSelector(getIsEnvironmentProduction)

  const {
    setUserPreferences,
    themeType = 'light',
    enableDataMasking,
    enablePreviewFeatures,
    navigateInNewWindow,
    isUserSettingsLoaded,
    isUserSettingsError
  } = useUserSettings()

  const isUserSettingsAllGood = useMemo(
    () => isUserSettingsLoaded && !isUserSettingsError,
    [isUserSettingsError, isUserSettingsLoaded]
  )

  const [addVersionHeaderForRockcoApis, setAddVersionHeaderForRockcoApis] =
    useState(() => {
      const storageValue = getStorageSettings().addVersionHeaderForRockcoApis
      if (storageValue === true) {
        return 'v1'
      }

      return storageValue || undefined
    })

  const onNavigateInNewWindowChanged = useCallback(() => {
    setUserPreferences({
      navigateInNewWindow: !navigateInNewWindow
    })
  }, [navigateInNewWindow, setUserPreferences])

  const onEnablePreviewFeaturesChanged = useCallback(() => {
    setUserPreferences({
      enablePreviewFeatures: !enablePreviewFeatures
    })
  }, [enablePreviewFeatures, setUserPreferences])

  const onEnableDataMaskingChanged = useCallback(() => {
    setUserPreferences({
      enableDataMasking: !enableDataMasking
    })
  }, [enableDataMasking, setUserPreferences])

  const onThemeChanged = useCallback(
    (_: any, option?: IChoiceGroupOption) => {
      if (!option) {
        return
      }
      setUserPreferences({
        themeType: option.key as ThemeType
      })
    },
    [setUserPreferences]
  )

  const onAddApiVersionHeaderChanged = useCallback(
    (ev?: unknown, option?: IDropdownOption) => {
      const value = option?.key as string | undefined
      setStorageSettings({
        addVersionHeaderForRockcoApis: value || undefined
      })
      setAddVersionHeaderForRockcoApis(value || undefined)
    },
    []
  )

  return (
    <Stack
      styles={{ root: { padding: '10px', minWidth: '220px' } }}
      tokens={{ childrenGap: 20 }}
    >
      <header>
        <h3>Settings</h3>
      </header>
      <div>
        <ChoiceGroup
          label="Choose a theme"
          selectedKey={themeType}
          options={[
            {
              key: 'light',
              text: 'light'
            },
            {
              key: 'dark',
              text: 'dark'
            }
          ]}
          onChange={onThemeChanged}
          disabled={!isUserSettingsAllGood}
        />
      </div>
      <Checkbox
        label="Navigate in new window"
        onChange={onNavigateInNewWindowChanged}
        checked={navigateInNewWindow}
        disabled={!isUserSettingsAllGood}
      />
      <Checkbox
        onRenderLabel={maskingLabel}
        onChange={onEnableDataMaskingChanged}
        checked={enableDataMasking}
        disabled={!isUserSettingsAllGood}
      />
      {!isProd && (
        <>
          <Checkbox
            label="Enable Preview Features"
            onChange={onEnablePreviewFeaturesChanged}
            checked={enablePreviewFeatures}
            disabled={!isUserSettingsAllGood}
          />

          <div style={{ display: 'flex', columnGap: 5, alignItems: 'center' }}>
            <Text styles={{ root: { fontWeight: 'normal', flexGrow: 1 } }}>
              API Version Header
            </Text>
            <Dropdown
              styles={{
                root: { width: 'auto', minWidth: 'auto', display: 'inline' }
              }}
              options={apiVersionOptions}
              selectedKey={addVersionHeaderForRockcoApis}
              onChange={onAddApiVersionHeaderChanged}
            />
          </div>
        </>
      )}
    </Stack>
  )
}

const maskingLabel = () => (
  <Stack>
    <Text>Enable Data Masking / Obfuscation</Text>
    <Text variant="small">requires refresh</Text>
  </Stack>
)
