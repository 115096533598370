import { createRef, useEffect } from 'react'
import { inputClasses } from '../../components/shared/Inputs'

export const ShareItemCallout: React.FC<{
  targetId: string
  link?: string
  showCallout?: boolean
}> = ({ link, showCallout }) => {
  const inputRef = createRef<HTMLInputElement>()
  useEffect(() => {
    inputRef.current?.focus()
    inputRef.current?.select()
  }, [inputRef, showCallout])
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gridRowGap: 5
      }}
    >
      <div css={(theme) => ({ fontWeight: theme.fontWeights.bolder })}>
        Share a link to this File/Folder
      </div>
      <div css={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
        <input
          value={link}
          readOnly={true}
          ref={inputRef}
          css={[inputClasses.input, { width: '350px' }]}
        />
      </div>
      <div
        css={(theme) => ({
          display: 'flex',
          flexDirection: 'row',
          marginTop: '10px',
          color: theme.colors.tertiaryBlue1,
          cursor: 'pointer'
        })}
      >
        <a onClick={() => navigator.clipboard.writeText(link || '')}>
          Copy Link
        </a>
      </div>
    </div>
  )
}
