import axios from 'axios'
import { all, call, cancelled, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import {
  createAccountLinkingRequest,
  IAccountLinkingRequest,
  updateAccountLinkingRequest
} from '../../../api/dynamics'
import { AppState } from '../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../store/shared/asyncStore'
import { getDynamicsApiOptions } from '../../../store/shared/sagas'
import { accountLinkingRequestViewPanelActions } from '../features/AccountLinkingRequestView/store/accountLinkingRequestViewPanel'

export const accountLinkingPostActions = createAsyncAction(
  '@features/@accountLinking/@accountLinkingPost/REQUEST',
  '@features/@accountLinking/@accountLinkingPost/SUCCESS',
  '@features/@accountLinking/@accountLinkingPost/FAILURE'
)<IAccountLinkingRequest[], IAccountLinkingRequest, Error>()

export const accountLinkingPostReducer = createAsyncReducer(
  accountLinkingPostActions
)

const rootSelector = (state: AppState) =>
  state.features.accountLinking.accountLinkingPost

export const {
  getError: getAccountLinkingPostError,
  getIsLoading: getIsAccountLinkingPostLoading,
  getResult: getAccountLinkingPostResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof accountLinkingPostActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  try {
    const apiOptions = yield* call(getDynamicsApiOptions)
    if (!action.payload.length) {
      return
    }

    if (
      action.payload.length === 1 &&
      action.payload[0].rcm_accountlinkingrequestid
    ) {
      const request = action.payload[0]

      const res = yield* call(updateAccountLinkingRequest, apiOptions, request)

      yield put(accountLinkingPostActions.success(res))

      yield put(accountLinkingRequestViewPanelActions.updateStatusSuccess(res))
    } else {
      const results = yield* all(
        action.payload.map((x) =>
          call(createAccountLinkingRequest, apiOptions, x)
        )
      )

      yield put(accountLinkingPostActions.success(results?.[0].data))
    }
  } catch (e: unknown) {
    yield put(accountLinkingPostActions.failure(e as Error))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const accountLinkingPostAccountsUserSagas = [
  () => takeLatest(accountLinkingPostActions.request, onRequest)
]
