import { createReducer } from 'typesafe-actions'
import {
  QBOReportActions,
  fetchQBOReportActivityActions,
  fetchQBOReportHoldingsActions,
  fetchQBOReportJournalActivityActions,
  fetchQBOReportJournalMTMActions,
  fetchQBOReportMTMActions,
  setAccoutListActions,
  setDashboardStateActions,
  setQBOReportHeaderActions
} from './actions'
import {
  IQBOReportActivity,
  IQBOReportHeader,
  IQBOReportHoldings,
  IQBOReportJournal,
  IQBOReportMarkToMarket,
  QBODashboardState
} from './types'

export interface IQBOReportState {
  activity?: {
    isLoading: boolean
    error?: Error
    list?: IQBOReportActivity[]
  }
  holdings?: {
    isLoading: boolean
    error?: Error
    list?: IQBOReportHoldings[]
  }
  MTM?: {
    isLoading: boolean
    error?: Error
    list?: IQBOReportMarkToMarket[]
  }
  journalMTM?: {
    isLoading: boolean
    error?: Error
    list?: IQBOReportJournal[]
  }
  journalActivity?: {
    isLoading: boolean
    error?: Error
    list?: IQBOReportJournal[]
  }
  dashboardState?: QBODashboardState
  accountList?: string[]
  qboHeader?: IQBOReportHeader
}
const initialState: IQBOReportState = {}
export const QBOReportReducer = createReducer<
  IQBOReportState,
  QBOReportActions
>(initialState)
  .handleAction(fetchQBOReportActivityActions.request, (state) => ({
    ...state,
    activity: {
      isLoading: true,
      error: undefined,
      list: undefined
    }
  }))
  .handleAction(fetchQBOReportActivityActions.success, (state, action) => ({
    ...state,
    activity: {
      isLoading: false,
      error: undefined,
      list: action.payload
    }
  }))
  .handleAction(fetchQBOReportActivityActions.failure, (state, action) => ({
    ...state,
    activity: {
      isLoading: false,
      error: action.payload,
      list: undefined
    }
  }))
  .handleAction(fetchQBOReportHoldingsActions.request, (state) => ({
    ...state,
    holdings: {
      isLoading: true,
      error: undefined,
      list: undefined
    }
  }))
  .handleAction(fetchQBOReportHoldingsActions.success, (state, action) => ({
    ...state,
    holdings: {
      isLoading: false,
      error: undefined,
      list: action.payload
    }
  }))
  .handleAction(fetchQBOReportHoldingsActions.failure, (state, action) => ({
    ...state,
    holdings: {
      isLoading: false,
      error: action.payload,
      list: undefined
    }
  }))

  .handleAction(fetchQBOReportMTMActions.request, (state) => ({
    ...state,
    MTM: {
      isLoading: true,
      error: undefined,
      list: undefined
    }
  }))
  .handleAction(fetchQBOReportMTMActions.success, (state, action) => ({
    ...state,
    MTM: {
      isLoading: false,
      error: undefined,
      list: action.payload
    }
  }))
  .handleAction(fetchQBOReportMTMActions.failure, (state, action) => ({
    ...state,
    MTM: {
      isLoading: false,
      error: action.payload,
      list: undefined
    }
  }))
  .handleAction(fetchQBOReportJournalActivityActions.request, (state) => ({
    ...state,
    journalActivity: {
      isLoading: true,
      error: undefined,
      list: undefined
    }
  }))
  .handleAction(
    fetchQBOReportJournalActivityActions.success,
    (state, action) => ({
      ...state,
      journalActivity: {
        isLoading: false,
        error: undefined,
        list: action.payload
      }
    })
  )
  .handleAction(
    fetchQBOReportJournalActivityActions.failure,
    (state, action) => ({
      ...state,
      journalActivity: {
        isLoading: false,
        error: action.payload,
        list: undefined
      }
    })
  )
  .handleAction(fetchQBOReportJournalMTMActions.success, (state, action) => ({
    ...state,
    journalMTM: {
      isLoading: false,
      error: undefined,
      list: action.payload
    }
  }))
  .handleAction(fetchQBOReportJournalMTMActions.failure, (state, action) => ({
    ...state,
    journalMTM: {
      isLoading: false,
      error: action.payload,
      list: undefined
    }
  }))
  .handleAction(
    setDashboardStateActions.setQBODashboardByType,
    (state, action) => ({
      ...state,
      dashboardState: {
        ...state.dashboardState,
        [action.payload.type]: action.payload.data
      }
    })
  )
  .handleAction(setAccoutListActions.setAccountList, (state, action) => ({
    ...state,
    accountList: action.payload
  }))
  .handleAction(setQBOReportHeaderActions.updateQBOHeader, (state, action) => ({
    ...state,
    qboHeader: { ...state.qboHeader, ...action.payload }
  }))
