import { MessageBarType } from '@fluentui/react'
import { createReducer } from 'typesafe-actions'
import { IAuditRequestByRcmSecIdApiRes, IGetDueDiligence } from '../api/types'
import {
  dueDiligenceFetchActions,
  DueDiligencesActionsTypes,
  fetchApprovalStatusActions,
  getAuditRequestActions,
  updateDueDiligenceActions
} from './actions'

export interface IDueDiligenceState {
  getDueDiligencesState?: {
    inProgress: boolean
    errorCode?: string
    msg?: string
    messageType?: MessageBarType
    data?: IGetDueDiligence[]
  }
  updateDueDiligencesState?: {
    inProgress: boolean
    error?: Error
    msg?: string
    messageType?: MessageBarType
    outputResponse?: string
    data?: any
  }
  fetchApprovalStatusState?: {
    inProgress: boolean
    error?: Error
    messageType?: MessageBarType
    msg?: string
    data?: any
  }
  fetchAuditRequestByRcmSecIdState?: {
    inProgress: boolean
    error?: Error
    messageType?: MessageBarType
    msg?: string
    data?: IAuditRequestByRcmSecIdApiRes
  }
}
const initialState: IDueDiligenceState = {}
export const dueDiligencesReducer = createReducer<
  IDueDiligenceState,
  DueDiligencesActionsTypes
>(initialState)
  .handleAction(dueDiligenceFetchActions.request, (state) => ({
    ...state,
    getDueDiligencesState: {
      inProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(dueDiligenceFetchActions.success, (state, action) => ({
    ...state,
    getDueDiligencesState: {
      inProgress: false,
      error: undefined,
      data: action.payload,
      messageType: MessageBarType.success
    }
  }))
  .handleAction(dueDiligenceFetchActions.failure, (state, action) => ({
    ...state,
    getDueDiligencesState: {
      inProgress: false,
      error: action.payload,
      data: undefined,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(dueDiligenceFetchActions.clear, (state) => ({
    ...state,
    getDueDiligencesState: undefined
  }))

  .handleAction(updateDueDiligenceActions.request, (state) => ({
    ...state,
    updateDueDiligencesState: {
      inProgress: true,
      error: undefined,
      msg: undefined,
      messageType: undefined
    }
  }))
  .handleAction(updateDueDiligenceActions.success, (state, action) => ({
    ...state,
    updateDueDiligencesState: {
      inProgress: false,
      error: undefined,
      msg: 'Successfully saved.',
      messageType: MessageBarType.success,
      data: action.payload,
      outputResponse: action.payload?.outputResponse
    }
  }))
  .handleAction(updateDueDiligenceActions.failure, (state, action) => ({
    ...state,
    updateDueDiligencesState: {
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(updateDueDiligenceActions.clear, (state) => ({
    ...state,
    updateDueDiligencesState: undefined
  }))

  .handleAction(fetchApprovalStatusActions.request, (state) => ({
    ...state,
    fetchApprovalStatusState: {
      ...state.fetchApprovalStatusState,
      inProgress: true,
      error: undefined,
      messageType: MessageBarType.info,
      msg: 'fetching Approval Status'
    }
  }))
  .handleAction(fetchApprovalStatusActions.success, (state, action) => ({
    ...state,
    fetchApprovalStatusState: {
      inProgress: false,
      error: undefined,
      messageType: MessageBarType.success,
      msg: 'Successfully fetch Approval Status',
      data: action.payload
    }
  }))
  .handleAction(fetchApprovalStatusActions.failure, (state, action) => ({
    ...state,
    fetchApprovalStatusState: {
      ...state.fetchApprovalStatusState,
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(fetchApprovalStatusActions.clear, (state) => ({
    ...state,
    fetchApprovalStatusState: undefined
  }))

  .handleAction(getAuditRequestActions.request, (state) => ({
    ...state,
    fetchAdvisorAccountsByClientIdState: {
      ...state.fetchAuditRequestByRcmSecIdState,
      inProgress: true,
      error: undefined,
      messageType: MessageBarType.info,
      msg: 'Get the Audit by RCM Sec ID'
    }
  }))
  .handleAction(getAuditRequestActions.success, (state, action) => ({
    ...state,
    fetchAuditRequestByRcmSecIdState: {
      inProgress: false,
      error: undefined,
      messageType: MessageBarType.success,
      msg: 'Successfully fetched Audit by RCM Sec ID',
      data: action.payload
    }
  }))
  .handleAction(getAuditRequestActions.failure, (state, action) => ({
    ...state,
    fetchAdvisorAccountsByClientIdState: {
      ...state.fetchAuditRequestByRcmSecIdState,
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(getAuditRequestActions.clear, (state) => ({
    ...state,
    fetchAuditRequestByRcmSecIdState: undefined
  }))
