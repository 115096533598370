import { saveBlobAsFile } from 'shared/downloads'

export const getBlob = (base64Data: string, contentType?: string) => {
  contentType = contentType || ''
  const sliceSize = 1024
  const byteCharacters = atob(base64Data)
  const bytesLength = byteCharacters.length
  const slicesCount = Math.ceil(bytesLength / sliceSize)
  const byteArrays = new Array(slicesCount)
  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize
    const end = Math.min(begin + sliceSize, bytesLength)

    const bytes = new Array(end - begin)
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0)
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes)
  }
  return new Blob(byteArrays, { type: contentType })
}

export const downloadAttachment = (
  base64Data: string,
  contentType: string,
  filename: string
) => {
  const blob = getBlob(base64Data, contentType)
  saveBlobAsFile(blob, filename)
}

export const openPreview = (base64Data: string, contentType: string) => {
  const blob = getBlob(base64Data, contentType)
  const blobUrl = URL.createObjectURL(blob)
  window.open(blobUrl, '_blank')
}
