import { createApi } from '@reduxjs/toolkit/dist/query/react'
import {
  IGetDirectoryObjectsByIdResponse,
  IServicePrincipal
} from '../../api/graph'
import {
  axiosBaseQuery,
  getGraphApiAuthToken,
  getGraphApiBaseUrl
} from './shared'

export const GraphBetaApiSliceKey = 'api.graph_beta'
export type GraphBetaApiReducerState = {
  [GraphBetaApiSliceKey]: ReturnType<typeof graph_beta.reducer>
}

export const graph_beta = createApi({
  reducerPath: GraphBetaApiSliceKey,
  baseQuery: axiosBaseQuery({
    getBaseUrl: (state) => getGraphApiBaseUrl(state, 'beta'),
    getAuthToken: (state) => getGraphApiAuthToken(state)
  }),
  endpoints: (builder) => ({
    getServicePrincipalsById: builder.query<
      IGetDirectoryObjectsByIdResponse<IServicePrincipal>,
      string[]
    >({
      query: (ids) => ({
        url: `/directoryObjects/getByIds`,
        method: 'post',
        data: { ids, types: ['servicePrincipal'] }
      })
    })
  })
})

export const { useGetServicePrincipalsByIdQuery } = graph_beta
