import { combineReducers } from 'redux'
import {
  accountLinkingRequestFetchReducer,
  accountLinkingRequestFetchSagas
} from './accountLinkingRequestFetch'
import {
  accountLinkingRequestViewPanelReducer,
  accountLinkingRequestViewPanelSagas
} from './accountLinkingRequestViewPanel'
import {
  requestOwnerFetchReducer,
  requestOwnerFetchSagas
} from './requestOwnerFetch'

export const accountLinkingRequestViewFeatureReducer = combineReducers({
  accountLinkingRequestFetch: accountLinkingRequestFetchReducer,
  accountLinkingRequestViewPanel: accountLinkingRequestViewPanelReducer,
  requestOwnerFetch: requestOwnerFetchReducer
})

export const accountLinkingRequestViewFeatureSagas = [
  ...accountLinkingRequestFetchSagas,
  ...accountLinkingRequestViewPanelSagas,
  ...requestOwnerFetchSagas
]
