import { flow } from 'lodash/fp'
import { call, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { IHousehold } from '../../../api/household.types'
import { OdataFilterOperatorEnum } from '../../../api/odata'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../store/shared/asyncStore'
import { search } from '../../../store/shared/sagas'
import { marginAccountFetchActions } from './householdAccountsFetch'
import { getMarginRateAdjustmentFeatureState } from './shared'

export const householdDetailsFetchActions = createAsyncAction(
  '@features/@marginRateAdjustment/@householdDetailsFetch/@households/REQUEST',
  '@features/@marginRateAdjustment/@householdDetailsFetch/@households/SUCCESS',
  '@features/@marginRateAdjustment/@householdDetailsFetch/@households/FAILURE'
)<string | undefined, IHousehold | undefined, Error>()

export const householdDetailsFetchReducer = createAsyncReducer(
  householdDetailsFetchActions
)

const rootSelector = flow(
  getMarginRateAdjustmentFeatureState,
  (x) => x?.householdDetailsFetch
)

export const {
  getError: getHouseholdsError,
  getIsLoading: getIsHouseholdsLoading,
  getResult: getHouseholdsResult
} = createAsyncSelectors(rootSelector)

const fetchHouseholdDetails = function* (
  action: ReturnType<typeof householdDetailsFetchActions.request>
) {
  if (!action.payload) {
    yield put(householdDetailsFetchActions.success(undefined))
    return
  }
  try {
    const result = yield* call(search, 'household' as const, {
      orderBy: [
        { dataPath: 'householdKPI/AumTotal', direction: 'desc' as const }
      ],
      filters: [
        {
          and: [
            {
              operator: OdataFilterOperatorEnum.eq,
              value: action.payload,
              path: 'householdId',
              type: 'string' as const
            }
          ]
        }
      ]
    })
    const household = result?.value as IHousehold[]

    if (!household[0]) {
      throw new Error('Household not found')
    }
    yield put(marginAccountFetchActions.request(household[0].householdId))
    yield put(householdDetailsFetchActions.success(household[0]))
  } catch (e: any) {
    yield put(householdDetailsFetchActions.failure(e))
  }
}

export const householdDetailsFetchSagas = [
  () => takeLatest(householdDetailsFetchActions.request, fetchHouseholdDetails)
]
