import { Row } from '@tanstack/react-table'
import { IClosedLotSummaryItem } from 'modules/Advisory/modules/Rdot360/store/types'

export const globalFilterFn = (
  row: Row<IClosedLotSummaryItem>,
  id: string,
  filterValue: string
): boolean => {
  const searchString = (filterValue || '').toLowerCase()
  const { SECNAME, SECDESCRIPTION, ACCTKEY } = row.original

  return [SECNAME, SECDESCRIPTION, ACCTKEY].some((value) =>
    value?.toLowerCase().includes(searchString)
  )
}
