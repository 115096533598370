import { createReducer } from 'typesafe-actions'
import {
  FaiedPaymentActionType,
  failedPaymentsDataActions,
  retryFailedPaymentsActions
} from './actions'
import { IFailedPayment } from './types'

export interface IFailedPaymentState {
  failedPayments: {
    payments?: IFailedPayment[]
    isLoading: boolean
    error?: Error
  }
  retryPayment?: {
    isLoading: boolean
    error?: Error
  }
}

const initialState: IFailedPaymentState = {
  failedPayments: {
    isLoading: true
  },
  retryPayment: undefined
}

//create reducer for failed payment

export const failedPaymentReducer = createReducer<
  IFailedPaymentState,
  FaiedPaymentActionType
>(initialState)
  .handleAction(failedPaymentsDataActions.request, (state) => ({
    ...state,
    failedPayments: {
      isLoading: true,
      error: undefined
    },
    retryPayment: undefined
  }))
  .handleAction(failedPaymentsDataActions.success, (state, action) => ({
    ...state,
    failedPayments: {
      isLoading: false,
      error: undefined,
      payments: action.payload
    }
  }))
  .handleAction(failedPaymentsDataActions.failure, (state, action) => ({
    ...state,
    failedPayments: {
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(retryFailedPaymentsActions.request, (state) => ({
    ...state,
    retryPayment: {
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(retryFailedPaymentsActions.success, (state) => ({
    ...state,
    retryPayment: {
      isLoading: false,
      error: undefined
    }
  }))
  .handleAction(retryFailedPaymentsActions.failure, (state, action) => ({
    ...state,
    retryPayment: {
      isLoading: false,
      error: action.payload
    }
  }))
