import { flow } from 'lodash/fp'
import { call, delay, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { IAccount } from '../../../api/account.types'
import { OdataFilterOperatorEnum } from '../../../api/odata'
import { AppState } from '../../../store'
import { createAsyncReducer } from '../../../store/shared/asyncStore'
import { search } from '../../../store/shared/sagas'

export const feeDetailsActions = createAsyncAction(
  '@features/@accounts/@feeDetails/REQUEST',
  '@features/@accounts/@feeDetails/SUCCESS',
  '@features/@accounts/@feeDetails/FAILURE'
)<string, IAccount, Error>()

export const feeDetailsReducer = createAsyncReducer(feeDetailsActions)

export const getFeeDetailsState = (state: AppState) =>
  state.features.accounts.feeDetails
export const getFeeDetailsResult = flow(getFeeDetailsState, (x) => x.result)
export const getIsFeeDetailsLoading = flow(getFeeDetailsState, (x) => x.loading)

const fetchFees = function* (
  action: ReturnType<typeof feeDetailsActions.request>
) {
  if (!action.payload) {
    return
  }

  yield delay(100)

  try {
    const result = yield* call(search, 'account' as const, {
      top: 1,
      select: [
        'feeSummary',
        'feeDetails',
        'id',
        'CustodyAccount',
        'AccountKPIs/cashAvlToWithDraw'
      ],
      filters: [
        {
          and: [
            {
              operator: OdataFilterOperatorEnum.eq,
              value: action.payload,
              path: 'id',
              type: 'string' as const
            }
          ]
        }
      ]
    })
    const accounts = result?.value as IAccount[]

    if (!accounts || !accounts.length) {
      throw new Error('Accounts not found')
    }

    const [first] = accounts
    if (!first || !first.feeSummary) {
      throw new Error('No Fee information found for account')
    }

    yield put(feeDetailsActions.success(first))
  } catch (e: any) {
    yield put(feeDetailsActions.failure(e))
  }
}

export const feeDetailsSagas = [
  () => takeLatest(feeDetailsActions.request, fetchFees)
]
