import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { skipToken } from '@reduxjs/toolkit/query/react'
import { IReportSearchRequest } from 'api/datahub'
import { flow } from 'lodash/fp'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useGetAccountsByHouseholdIdQuery,
  useGetHouseholdQuery
} from '../../api/datahub'
import { getOptionsIncomeAndExpenseReportRootState } from '../../shared'

interface IOptionsIncomeAndExpenseReportCriteriaEditState {
  fromDate?: Date
  toDate?: Date
  selectedHouseholdId?: string
  selectedAccounts?: string[]
}

const initialState = {} as IOptionsIncomeAndExpenseReportCriteriaEditState

const { actions, reducer } = createSlice({
  name: '@modules/@advisory/@modules/@reports/@options-income-and-expense-report/@features/@reportCriteria',
  initialState,
  reducers: {
    setFromDate: (state, action: PayloadAction<Date | undefined>) => ({
      ...state,
      fromDate: action.payload
    }),
    setToDate: (state, action: PayloadAction<Date | undefined>) => ({
      ...state,
      toDate: action.payload
    }),
    setSelectedHouseholdId: (
      state,
      action: PayloadAction<string | undefined>
    ) => ({
      ...state,
      selectedHouseholdId: action.payload,
      selectedAccounts: undefined
    }),
    setSelectedAccounts: (
      state,
      action: PayloadAction<string[] | undefined>
    ) => ({
      ...state,
      selectedAccounts: action.payload
    }),
    reset: () => initialState
  }
})

const rootSelector = flow(
  getOptionsIncomeAndExpenseReportRootState,
  (x) => x.features.reportCriteria
)

const getOptionsIncomeAndExpenseReportCriteriaEditCriteria = createSelector(
  rootSelector,
  ({ fromDate, toDate, selectedAccounts }): IReportSearchRequest => ({
    startDate: fromDate,
    endDate: toDate,
    accounts: selectedAccounts
  })
)
const getFromDate = createSelector(rootSelector, (x) => x.fromDate)
const getToDate = createSelector(rootSelector, (x) => x.toDate)
const getSelectedHouseholdId = createSelector(
  rootSelector,
  (x) => x.selectedHouseholdId
)
const getSelectedAccounts = createSelector(
  rootSelector,
  (x) => x.selectedAccounts
)

export { reducer as optionsIncomeAndExpenseReportCriteriaEditReducer }

export const useReportCriteriaEditCriteria = () => {
  const dispatch = useDispatch()
  const selectedHouseholdId = useSelector(getSelectedHouseholdId)
  const fromDate = useSelector(getFromDate)
  const toDate = useSelector(getToDate)
  const selectedAccounts = useSelector(getSelectedAccounts)
  const criteria = useSelector(
    getOptionsIncomeAndExpenseReportCriteriaEditCriteria
  )
  const setSelectedHouseholdId = useCallback(
    (householdId?: string) =>
      dispatch(actions.setSelectedHouseholdId(householdId)),
    [dispatch]
  )

  const setFromDate = useCallback(
    (fromDate?: Date) => dispatch(actions.setFromDate(fromDate)),
    [dispatch]
  )

  const setToDate = useCallback(
    (toDate?: Date) => dispatch(actions.setToDate(toDate)),
    [dispatch]
  )

  const setSelectedAccounts = useCallback(
    (accounts?: string[]) => dispatch(actions.setSelectedAccounts(accounts)),
    [dispatch]
  )

  const reset = useCallback(() => dispatch(actions.reset()), [dispatch])

  const isValidCriteria = useMemo(
    () => fromDate && toDate && fromDate <= toDate && selectedAccounts?.length,
    [fromDate, selectedAccounts?.length, toDate]
  )

  return {
    reset,
    selectedHouseholdId,
    criteria,
    setSelectedHouseholdId,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    selectedAccounts,
    setSelectedAccounts,
    isValidCriteria
  }
}

export const useReportCriteriaEditSelectedHousehold = () => {
  const selectedHouseholdId = useSelector(getSelectedHouseholdId)
  const household = useGetHouseholdQuery(selectedHouseholdId ?? skipToken)
  const accounts = useGetAccountsByHouseholdIdQuery(
    selectedHouseholdId ?? skipToken
  )

  return {
    household,
    accounts,
    selectedHouseholdId
  }
}
