import { combineReducers } from 'redux'
import {
  boardMemberAccountModuleReducer,
  boardMemberAccountModuleSagas
} from './BoardMemberAccounts'

export const homeOfficeModulesReducer = combineReducers({
  boardMemberAccounts: boardMemberAccountModuleReducer
})

export const homeOfficeModulesSagas = [...boardMemberAccountModuleSagas]
