import { AccountView } from './features/AccountView/AccountView'
import { SummaryView } from './features/SummaryView/SummaryView'
import { ProjectedIncomeDetailsHeader } from './IncomeHeader'
import { useIncomeDetailsUiState, IncomeViewType } from './store'

export const IncomeContainer: React.FC = () => {
  const { viewByKey } = useIncomeDetailsUiState()

  return (
    <div>
      <ProjectedIncomeDetailsHeader />

      <div>
        {viewByKey === IncomeViewType.account ? (
          <AccountView />
        ) : (
          <SummaryView />
        )}
      </div>
    </div>
  )
}
