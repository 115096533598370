import { MessageBarType } from '@fluentui/react'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import { pushNotification } from 'features/Notifications'
import { downloadUrlAsFile } from 'shared/downloads'
import { isNotNullOrUndefined } from 'shared/guards'
import { createAsyncReducer } from 'store/shared/asyncStore'
import { getAllPagedOdataApiResults } from 'store/shared/sagas/odata'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { fetchAnnuitiesAndInsurance } from '../../shared/annuityPPLIContracts'
import type {
  ExcelColumnConfigType,
  ExcelExportWorker
} from '../../shared/export/worker'
import { selectors as annuitySelectors } from './store'

export const annuitiesExcelExportActions = createAsyncAction(
  '@modules/@advisory/@modules/@annuitiesandinsurance/@modules/@dashboard/@features/@annuitiesExcelExport/EXPORT_REQUEST',
  '@modules/@advisory/@modules/@annuitiesandinsurance/@modules/@dashboard/@features/@annuitiesExcelExport/EXPORT_SUCCESS',
  '@modules/@advisory/@modules/@annuitiesandinsurance/@modules/@dashboard/@features/@annuitiesExcelExport/EXPORT_FAILURE'
)<undefined, undefined, Error>()

export const annuitiesExcelExportReducer = createAsyncReducer(
  annuitiesExcelExportActions
)

const onExport = function* () {
  try {
    const request = yield* select(annuitySelectors.uiSelectors.getOdataRequest)

    const results = yield* call(() =>
      getAllPagedOdataApiResults(request, fetchAnnuitiesAndInsurance)
    )

    const resultsMappedFiltered = results
      ?.flatMap((x) => x.result.value)
      ?.filter(isNotNullOrUndefined)

    const columnNames = yield* select(
      annuitySelectors.uiSelectors.getSelectedColumns
    )

    const worker = new Worker(
      new URL('../../shared/export/worker.ts', import.meta.url)
    )

    const { createAndDownloadExcelWorksheet } = wrap<ExcelExportWorker>(worker)

    const excelColumnConfigType: ExcelColumnConfigType = 'CONTRACTS'

    const result = yield* call(createAndDownloadExcelWorksheet, [
      {
        data: resultsMappedFiltered,
        selectedColumnNames: columnNames || [],
        excelColumnConfigType,
        tabLabel: 'Annuities'
      }
    ])

    const filename = `Annuities ${format(new Date(), 'MM-dd-yyyy')}.xlsx`
    downloadUrlAsFile(result, filename)
    yield put(annuitiesExcelExportActions.success())
  } catch (e: unknown) {
    yield put(annuitiesExcelExportActions.failure(e as Error))
  }
}

const onFailure = function* (
  action: ReturnType<typeof annuitiesExcelExportActions.failure>
) {
  yield call(pushNotification, {
    message: `Export failed: ${action.payload.message}`,
    type: MessageBarType.error
  })
}

export const annuitiesExcelExportSagas = [
  () => takeLatest(annuitiesExcelExportActions.request, onExport),
  () => takeLatest(annuitiesExcelExportActions.failure, onFailure)
]
