import { combineReducers } from 'redux'
import Hurdles, { hurdlesModuleReducer, hurdlesModuleSagas } from './Hurdles'
import PeerReview from './PeerReview'

export const teamsModulesReducer = combineReducers({
  hurdles: hurdlesModuleReducer
})

export const teamsModulesSagas = [...hurdlesModuleSagas]

export default [Hurdles, PeerReview]
