import { TagDescription } from '@reduxjs/toolkit/dist/query'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import {
  IActivity,
  ICdsBatchRequestItem,
  IContact,
  IDynamicsApiResult,
  IDynamicsConsolidation,
  ISystemUser
} from 'api/dynamics'
import { escapeFilterString } from 'api/odata'
import { escapeAndEncodeQuery, tokenizeQuery } from 'api/search'
import { AxiosResponseHeaders } from 'axios'
import { IActivityTask } from 'features/Tasks/api/dynamics'
import { chunk, sum, trim } from 'lodash'
import { stringify } from 'query-string'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { isNotNullOrEmpty, isNotNullOrUndefined } from 'shared/guards'
import {
  BatchResponse,
  createCdsBatchPayload,
  dynamicsApi,
  executeBatchRequest
} from 'store/api/dynamics'
import { AxiosBaseArgs, arrayCommaParamsSerializer } from 'store/api/shared'
import { apiConstants } from './apis'
import {
  ActivityPointerActivityMimeAttachment,
  InteractionAttachment
} from './types'

export interface ICustomAccountGroup {
  rcm_customaccountgroupid?: string
  rcm_name?: string
  _rcm_household_value?: string
  rcm_cag_rcm_financialaccount?: IFinancialAccount[]
}

export interface IFinancialAccount {
  rcm_accountnumber?: string
  rcm_cdmaccountid?: string
}

export interface ICreateInteractionPayload {
  aka_type: number
  subject: string
  scheduledend: string
  ram_sentiment?: number
  'regardingobjectid_contact_aka_meetingnote@odata.bind'?: string
  'regardingobjectid_account_aka_meetingnote@odata.bind'?: string
  rpm_annualreview?: boolean
  rcm_city?: string
  rcm_stateprovince?: string
  rcm_countryregion?: string
  description?: string
  mimeType?: string
  filename?: string
  documentbody?: string
  isdocument?: boolean
}

export interface IInteraction {
  'aka_type@OData.Community.Display.V1.FormattedValue': string
  'scheduledend@OData.Community.Display.V1.FormattedValue': string
  '_regardingobjectid_value@OData.Community.Display.V1.FormattedValue': string
  _regardingobjectid_value: string
  subject: string
  description: string
  'modifiedon@OData.Community.Display.V1.FormattedValue'?: string
  '_modifiedby_value@OData.Community.Display.V1.FormattedValue'?: string
  activityid?: string
  '_ownerid_value@OData.Community.Display.V1.FormattedValue'?: string
  rpm_annualreview: boolean
  rcm_city: string
  rcm_stateprovince: string
  rcm_countryregion: string
  'ram_sentiment@OData.Community.Display.V1.FormattedValue'?: string
  ram_sentiment?: number
  rcm_attachment_name?: string
}

export interface IAdvisorTeamPayload {
  'rcm_primaryadvisor@odata.bind'?: string | null
  'rcm_primaryclientassociate@odata.bind'?: string | null
}

export const InteractionTypeOptions = [
  'Email',
  'Phone Call',
  'Meeting - Onsite',
  'Meeting - Offsite',
  'Meal / Social',
  'Conference / Event',
  'Encounter',
  'Conference Call',
  'Finals Pitch',
  'Video Call'
] as const

export type IInteractionType = (typeof InteractionTypeOptions)[number]

export interface ICreatePhoneCallPayload {
  subject: string
  description?: string
  directioncode: boolean
  actualdurationminutes: number
  'regardingobjectid_contact_phonecall@odata.bind'?: string
  phonenumber?: string
  phonecall_activity_parties: {
    'partyid_contact@odata.bind'?: string
    'partyid_systemuser@odata.bind'?: string
    participationtypemask: number
  }[]
  scheduledend: string
  prioritycode: number
  statecode: number
  statuscode: number
}

export function convertInteractionTypeToFilter(type: IInteractionType) {
  switch (type) {
    case 'Email':
      return 964110005
    case 'Phone Call':
      return 964110003
    case 'Meeting - Onsite':
      return 964110000
    case 'Meeting - Offsite':
      return 964110001
    case 'Meal / Social':
      return 964110002
    case 'Conference / Event':
      return 804820001
    case 'Encounter':
      return 964110004
    case 'Conference Call':
      return 804820000
    case 'Finals Pitch':
      return 804820002
    case 'Video Call':
      return 412290001
  }
}

export function convertInteractionTypeToActivityFilter(
  type?: IInteractionType
) {
  switch (type) {
    case 'Email':
      return 'email'
    case 'Phone Call':
      return 'phonecall'
    default:
      return undefined
  }
}

const { cacheTime } = apiConstants

type DynamicsApiTagType =
  | 'rdot360'
  | 'Notes'
  | 'Tasks'
  | 'Interactions'
  | 'CAGs'
  | 'AdvisorTeam'
  | 'contacts'
const datahubApiTags: DynamicsApiTagType[] = [
  'rdot360',
  'Notes',
  'Tasks',
  'Interactions',
  'CAGs',
  'AdvisorTeam',
  'contacts'
]

const paramsSerializer = (params: any) => {
  return stringify(params, { arrayFormat: 'comma' })
}

const dynamicsApiWithRdot360Tags = dynamicsApi.enhanceEndpoints({
  addTagTypes: datahubApiTags
})

const fullNameTokenSearchFilter = (search: string): string =>
  tokenizeQuery(search)
    .map(escapeFilterString)
    .map(escapeAndEncodeQuery)
    .map(trim)
    .filter(isNotNullOrEmpty)
    .map((token) => `contains(fullname, '${token}')`)
    .join(' and ')
const enhancedEndpoints = dynamicsApiWithRdot360Tags.enhanceEndpoints({
  endpoints: {
    getContactsAndOrgsByPartyIds: {
      providesTags: ['rdot360', 'contacts']
    }
  }
})

export const rdot360DynamicsApi = enhancedEndpoints.injectEndpoints({
  endpoints: (builder) => ({
    getSystemUsers: builder.query<
      ISystemUser[] | undefined,
      string | undefined
    >({
      query: (search) => ({
        url: '/systemusers',
        params: {
          $select: 'systemuserid, fullname',
          $filter: [
            search && fullNameTokenSearchFilter(search),
            'systemuserid ne null',
            'fullname ne null'
          ]
            .filter(Boolean)
            .join(' and '),
          $top: 10
        },
        paramsSerializer,
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        }
      }),
      transformResponse: (x: IDynamicsApiResult<IContact>) => x.value,
      providesTags: ['rdot360'],
      keepUnusedDataFor: cacheTime
    }),
    getTaskCounts: builder.query<
      number | undefined,
      { contactIds: string[]; startDate?: string; endDate?: string }
    >({
      queryFn: async (
        { contactIds, startDate, endDate },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const chunkedIds = chunk(contactIds, 20)
        const requests = chunkedIds.map(
          (ids) =>
            ({
              method: 'GET',
              url: `/api/data/v9.2/activitypointers?$filter=${[
                `Microsoft.Dynamics.CRM.In(PropertyName='regardingobjectid',PropertyValues=[${ids
                  .map((x) => `'${x}'`)
                  .join(',')}]) and activitytypecode eq 'task'`,
                startDate && `scheduledend ge '${startDate}'`,
                endDate && `scheduledend le '${endDate}'`
              ]
                .filter(Boolean)
                .join(' and ')}&$count=true&$top=1&$select=activityid`,
              headers: {
                Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`,
                Accept: 'application/json, text/plain, */*'
              }
            } as ICdsBatchRequestItem)
        )
        const responses = await executeBatchRequest(requests, baseQuery)
        const response = responses?.[0] as BatchResponse<IActivityTask>
        return {
          data: sum(
            response?.data
              ?.map((x) => x['@odata.count'])
              .filter(isNotNullOrUndefined)
          )
        }
      },
      providesTags: ['Tasks', 'rdot360'],
      keepUnusedDataFor: cacheTime
    }),
    getActivitiesByContacts: builder.query<
      IActivity[] | undefined,
      {
        contactIds: string[]
        type?: IInteractionType
        search?: string
        order?: 'asc' | 'desc'
      }
    >({
      queryFn: async (
        { contactIds, type, search, order },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const request = {
          method: 'GET',
          url: [
            `/api/data/v9.2/activitypointers?$filter=Microsoft.Dynamics.CRM.In(PropertyName='regardingobjectid',PropertyValues=[${contactIds
              .map((x) => `'${x}'`)
              .join(',')}])${
              convertInteractionTypeToActivityFilter(type)
                ? ` and activitytypecode eq '${convertInteractionTypeToActivityFilter(
                    type
                  )}'`
                : ''
            } ${
              search
                ? `and (contains(description, '${search}') or contains(subject, '${search}'))`
                : ''
            } &$expand=regardingobjectid_contact($select=fullname),activity_pointer_activity_mime_attachment($select=activitymimeattachmentid,attachmentcontentid,filename,mimetype)&$orderby=modifiedon ${
              order || 'desc'
            } &$select=${[
              'activitytypecode',
              'modifiedon',
              'description',
              'subject',
              '_regardingobjectid_value',
              '_modifiedby_value',
              '_ownerid_value'
            ].join(',')}`
          ]
            .filter(Boolean)
            .join(''),
          headers: {
            Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",odata.continue-on-error`,
            Accept: 'application/json, text/plain, */*'
          }
        } as ICdsBatchRequestItem
        const responses = await executeBatchRequest([request], baseQuery)
        const response = responses?.[0] as BatchResponse<IActivity>
        return {
          data: response?.data?.[0]?.value
        }
      },
      providesTags: ['rdot360', 'Interactions'],
      keepUnusedDataFor: cacheTime
    }),
    getEmailAttachment: builder.query<
      ActivityPointerActivityMimeAttachment | undefined,
      string
    >({
      queryFn: async (attachmentId, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<
          ActivityPointerActivityMimeAttachment,
          Error
        >

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: `activitymimeattachments(${attachmentId})`,
          paramsSerializer: arrayCommaParamsSerializer
        }

        const result = (await baseQuery({
          ...baseApiArgs
        })) as Response
        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data
        }
      },
      keepUnusedDataFor: cacheTime
    }),
    getInteractionAttachment: builder.query<
      InteractionAttachment | undefined,
      string
    >({
      queryFn: async (interactionId, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<InteractionAttachment, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: `aka_meetingnotes(${interactionId})/rcm_attachment`,
          paramsSerializer: arrayCommaParamsSerializer
        }

        const result = (await baseQuery({
          ...baseApiArgs
        })) as Response
        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data
        }
      },
      keepUnusedDataFor: cacheTime
    }),
    getEmailAttachments: builder.query<
      ActivityPointerActivityMimeAttachment[] | undefined,
      string[]
    >({
      queryFn: async (ids, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<
          IDynamicsApiResult<ActivityPointerActivityMimeAttachment>,
          Error
        >

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: `activitymimeattachments`,
          params: {
            $filter: ids
              .map((id) => `activitymimeattachmentid eq '${id}'`)
              .join(' or ')
          },
          paramsSerializer: arrayCommaParamsSerializer
        }

        const result = (await baseQuery({
          ...baseApiArgs
        })) as Response
        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data?.value
        }
      },
      keepUnusedDataFor: cacheTime
    }),
    getInteractionsByContacts: builder.query<
      IInteraction[] | undefined,
      {
        contactIds: string[]
        type?: IInteractionType
        search?: string
        order?: 'asc' | 'desc'
      }
    >({
      queryFn: async (
        { contactIds, type, search, order },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const request = {
          method: 'GET',
          url: [
            `/api/data/v9.2/aka_meetingnotes?$filter=Microsoft.Dynamics.CRM.In(PropertyName='regardingobjectid',PropertyValues=[${contactIds
              .map((x) => `'${x}'`)
              .join(',')}]) ${
              type && convertInteractionTypeToFilter(type)
                ? ` and aka_type eq ${convertInteractionTypeToFilter(type)}`
                : ''
            } ${
              search
                ? `and (contains(description, '${search}') or contains(subject, '${search}'))`
                : ''
            } &$expand=regardingobjectid_contact($select=fullname)&$orderby=modifiedon ${
              order || 'desc'
            }`
          ]
            .filter(Boolean)
            .join(''),
          headers: {
            Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`,
            Accept: 'application/json, text/plain, */*'
          }
        } as ICdsBatchRequestItem
        const responses = await executeBatchRequest([request], baseQuery)
        const response = responses?.[0] as BatchResponse<IInteraction>
        return { data: response?.data?.[0]?.value }
      },
      providesTags: ['rdot360', 'Interactions'],
      keepUnusedDataFor: cacheTime
    }),
    getMostRecentInteractionByContacts: builder.query<
      IInteraction | undefined,
      string[]
    >({
      queryFn: async (contactIds: string[], _api, _extraOptions, baseQuery) => {
        const request = {
          method: 'GET',
          url: [
            `/api/data/v9.2/aka_meetingnotes?$filter=Microsoft.Dynamics.CRM.In(PropertyName='regardingobjectid',PropertyValues=[${contactIds
              .map((x) => `'${x}'`)
              .join(',')}])
            &$expand=regardingobjectid_contact&$orderby=createdon desc&$top=1`
          ]
            .filter(Boolean)
            .join(''),
          headers: {
            Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`,
            Accept: 'application/json, text/plain, */*'
          }
        } as ICdsBatchRequestItem
        const responses = await executeBatchRequest([request], baseQuery)
        const response = responses?.[0] as BatchResponse<IInteraction>
        return { data: response?.data?.[0]?.value?.[0] }
      },
      providesTags: ['rdot360', 'Interactions'],
      keepUnusedDataFor: cacheTime
    }),
    rdot360_createInteraction: builder.mutation<
      IInteraction,
      ICreateInteractionPayload
    >({
      query: (payload) => ({
        url: `/aka_meetingnotes`,
        method: 'POST',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
        },
        data: payload
      }),
      invalidatesTags: ['Interactions']
    }),
    rdot360_editInteraction: builder.mutation<
      IInteraction,
      { id: string; payload: ICreateInteractionPayload }
    >({
      query: ({ id, payload }) => ({
        url: `/aka_meetingnotes(${id})`,
        method: 'Patch',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
        },
        data: payload
      }),
      invalidatesTags: ['Interactions']
    }),
    rdot360_interactionAttachment: builder.mutation<
      string | undefined,
      { id: string; name: string; payload?: File }
    >({
      query: ({ id, name, payload }) => ({
        url: `/aka_meetingnotes(${id})/rcm_attachment?x-ms-file-name=${name}`,
        method: 'Patch',
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        data: payload
      }),
      invalidatesTags: ['Interactions']
    }),
    rdot360_updateKeyContact: builder.mutation<
      IContact,
      {
        keyContact: boolean
        id: string
        householdId: string
      }
    >({
      query: ({ keyContact, id }) => ({
        url: `/contacts(${id})`,
        method: 'PATCH',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
        },
        data: { rpm_headofhousehold: keyContact }
      }),
      invalidatesTags: ['contacts']
    }),
    rdot360_getCustomAccountGroups: builder.query<
      ICustomAccountGroup[] | undefined,
      string
    >({
      query: (householdId) => ({
        url: [
          `/rcm_customaccountgroups?$filter=rcm_household/rcm_householdid eq '${householdId}'`,
          '&$expand=rcm_cag_rcm_financialaccount'
        ].join(''),
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        }
      }),
      transformResponse: (x: IDynamicsApiResult<ICustomAccountGroup>) =>
        x.value,
      providesTags: ['rdot360', 'CAGs'],
      keepUnusedDataFor: cacheTime
    }),
    rdot360_deleteCustomAccountGroup: builder.mutation<undefined, string>({
      queryFn: async (id, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<undefined, Error>
        const response = (await baseQuery({
          url: `/rcm_customaccountgroups(${id})`,
          method: 'DELETE'
        })) as Response
        if (response.error) {
          return { error: response.error }
        }
        return { ...response, error: undefined }
      },
      invalidatesTags: ['CAGs']
    }),
    rdot360_createCustomAccountGroup: builder.mutation<
      any,
      {
        name: string
        householdId: string
        accountIds: string[]
      }
    >({
      queryFn: async (
        { name, householdId, accountIds },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        type Response = QueryReturnValue<string, Error>
        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: '/rcm_customaccountgroups',
          method: 'POST',
          data: {
            rcm_name: name,
            'rcm_household@odata.bind': `/aka_rollups(rcm_householdid='${householdId}')`,
            statecode: 0,
            statuscode: 1
          },
          headers: {
            Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
          }
        }

        const createdGroup = (await baseQuery({
          ...baseApiArgs
        })) as { data?: ICustomAccountGroup }
        const createdGroupId = createdGroup?.data?.rcm_customaccountgroupid

        if (!createdGroupId) {
          return { error: new Error('Failed to create group') }
        }

        const requests = accountIds?.map(
          (x) =>
            ({
              method: 'POST',
              url: `/api/data/v9.0/rcm_customaccountgroups(${createdGroupId})/rcm_cag_rcm_financialaccount/$ref`,
              payload: {
                '@odata.context': '/api/data/v9.0/$metadata#$ref',
                '@odata.id': `rcm_financialaccounts(rcm_cdmaccountid='${x}')`
              }
            } as ICdsBatchRequestItem)
        )

        const { batchRequest, boundary } = createCdsBatchPayload(requests)

        const batchResponse = (await baseQuery({
          url: '/$batch',
          method: 'POST',
          headers: {
            'Content-Type': `multipart/mixed;boundary=${boundary}`,
            Accept: 'application/json',
            'OData-MaxVersion': '4.0',
            'OData-Version': '4.0'
          },
          data: batchRequest
        })) as Response

        if (batchResponse.error) {
          await baseQuery({
            url: `/rcm_customaccountgroups(${createdGroupId})`,
            method: 'DELETE'
          })
        }

        return batchResponse
      },
      invalidatesTags: ['CAGs']
    }),
    getAdvisorTeam: builder.query<IDynamicsConsolidation | undefined, string>({
      query: (id) => ({
        url: '/aka_rollups',
        params: {
          $filter: `rcm_householdid eq '${id}'`,
          $top: 1,
          $select: [
            '_rcm_primaryadvisor_value',
            '_rcm_primaryclientassociate_value',
            '_owningbusinessunit_value'
          ],
          $expand: [
            'rcm_primaryadvisor($select=azureactivedirectoryobjectid)',
            'rcm_primaryclientassociate($select=azureactivedirectoryobjectid)'
          ]
        },
        paramsSerializer,
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        }
      }),
      transformResponse: (x: IDynamicsApiResult<IDynamicsConsolidation>) =>
        x?.value?.[0],
      providesTags: ['AdvisorTeam', 'rdot360'],
      keepUnusedDataFor: cacheTime
    }),
    updateAdvisorTeam: builder.mutation<
      undefined,
      {
        id: string
        payload: IAdvisorTeamPayload
      }
    >({
      query: ({ id, payload }) => ({
        url: `/aka_rollups(${id})`,
        method: 'PATCH',
        data: payload
      }),
      invalidatesTags: ['AdvisorTeam']
    }),
    updateAdvisorTeamForContacts: builder.mutation<
      undefined,
      {
        id: string
        payload: IAdvisorTeamPayload
      }
    >({
      query: ({ id, payload }) => ({
        url: `/contacts(${id})`,
        method: 'PATCH',
        data: payload
      }),
      invalidatesTags: ['contacts']
    }),
    updateCagName: builder.mutation<any, { id: string; cagName: string }>({
      query: ({ id, cagName }) => ({
        url: `rcm_customaccountgroups(${id})`,
        method: 'PATCH',
        data: { rcm_name: cagName }
      }),
      invalidatesTags: ['CAGs']
    }),
    updateCagAccounts: builder.mutation<
      any,
      { id: string; accountsToAdd?: string[]; accountsToRemove?: string[] }
    >({
      queryFn: async (
        { id, accountsToAdd, accountsToRemove },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        type Response = QueryReturnValue<any, Error>
        const add =
          accountsToAdd?.map(
            (x) =>
              ({
                method: 'POST',
                url: `/api/data/v9.0/rcm_customaccountgroups(${id})/rcm_cag_rcm_financialaccount/$ref`,
                payload: {
                  '@odata.context': '/api/data/v9.0/$metadata#$ref',
                  '@odata.id': `rcm_financialaccounts(rcm_cdmaccountid='${x}')`
                }
              } as ICdsBatchRequestItem)
          ) || []
        const remove =
          accountsToRemove?.map(
            (x) =>
              ({
                method: 'DELETE',
                url: `/api/data/v9.0/rcm_customaccountgroups(${id})/rcm_cag_rcm_financialaccount(rcm_cdmaccountid='${x}')/$ref`
              } as ICdsBatchRequestItem)
          ) || []
        const requests = [...add, ...remove]
        const { batchRequest, boundary } = createCdsBatchPayload(requests)
        const response = (await baseQuery({
          url: '/$batch',
          method: 'POST',
          headers: {
            'Content-Type': `multipart/mixed;boundary=${boundary}`,
            Accept: 'application/json',
            'OData-MaxVersion': '4.0',
            'OData-Version': '4.0'
          },
          data: batchRequest
        })) as Response
        return response
      },
      invalidatesTags: ['CAGs']
    }),
    R360_createPhoneCall: builder.mutation<void, ICreatePhoneCallPayload>({
      queryFn: async (_args, _api, _extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `/phonecalls`,
          method: 'POST',
          data: _args
        })
        if (result.error) {
          return { error: { status: 500, data: false } }
        }

        //check response headers
        const odataEntityId = (result.meta as AxiosResponseHeaders)?.[
          'odata-entityid'
        ]
        if (odataEntityId) {
          baseQuery({
            url: odataEntityId,
            method: 'PATCH',
            data: {
              statecode: _args.statecode,
              statuscode: _args.statuscode
            }
          })
        }

        return { data: undefined }
      },
      invalidatesTags: ['Tasks']
    })
  })
})

export const {
  useGetInteractionsByContactsQuery,
  useGetTaskCountsQuery,
  useGetMostRecentInteractionByContactsQuery,
  useGetActivitiesByContactsQuery,
  useLazyGetEmailAttachmentQuery,
  useLazyGetInteractionAttachmentQuery,
  useRdot360_createInteractionMutation,
  useRdot360_editInteractionMutation,
  useRdot360_interactionAttachmentMutation,
  useRdot360_updateKeyContactMutation,
  useRdot360_getCustomAccountGroupsQuery,
  useRdot360_deleteCustomAccountGroupMutation,
  useRdot360_createCustomAccountGroupMutation,
  useGetSystemUsersQuery,
  useLazyGetEmailAttachmentsQuery,
  useGetAdvisorTeamQuery,
  useUpdateAdvisorTeamMutation,
  useUpdateAdvisorTeamForContactsMutation,
  useUpdateCagNameMutation,
  useUpdateCagAccountsMutation,
  useR360_createPhoneCallMutation
} = rdot360DynamicsApi

export const useDynamicsApiUtil = () => {
  const dispatch = useDispatch()
  const invalidateTags = useCallback(
    (tags: TagDescription<DynamicsApiTagType>[]) =>
      dispatch(rdot360DynamicsApi.util.invalidateTags(tags)),
    [dispatch]
  )

  return {
    invalidateTags
  }
}
