import { combineReducers } from 'redux'
import { domainFeaturesReducer, domainFeaturesSagas } from '../features'
import { selectedDomainReducer } from './domain'

export const domainFeatureReducer = combineReducers({
  features: domainFeaturesReducer,
  selectedDomain: selectedDomainReducer
})

export const domainFeatureSagas = [...domainFeaturesSagas]
