import { combineReducers } from 'redux'
import {
  revenueSummaryFetchReducer,
  revenueSummaryFetchSagas
} from './revenueSummaryFetch'
import {
  revenueSummaryTableExportReducer,
  revenueSummaryTableExportSagas
} from './revenueSummaryTableExport'

export const revenueSummaryTableFeatureReducer = combineReducers({
  revenueSummaryFetch: revenueSummaryFetchReducer,
  revenueSummaryExport: revenueSummaryTableExportReducer
})

export const revenueSummaryTableFeatureSagas = [
  ...revenueSummaryFetchSagas,
  ...revenueSummaryTableExportSagas
]
