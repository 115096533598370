import { createSlice } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { getOptionsIncomeAndExpenseReportRootState } from '../../shared'

interface IReportSearchPanelState {
  isOpen?: boolean
}

const initialState: IReportSearchPanelState = {
  isOpen: false
}

const { actions, reducer } = createSlice({
  name: '@features/@reportSearch/@reportSearchPanel',
  initialState,
  reducers: {
    open(state) {
      state.isOpen = true
    },
    close(state) {
      state.isOpen = false
    }
  }
})

const rootSelector = flow(
  getOptionsIncomeAndExpenseReportRootState,
  (x) => x.features.reportCriteriaPanel
)
const getIsOptionsIncomeAndExpensePanelOpen = createSelector(
  rootSelector,
  (x) => x.isOpen
)

export { reducer as optionsIncomeAndExpenseReportCriteriaPanelReducer }

export const useReportCriteriaPanel = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(getIsOptionsIncomeAndExpensePanelOpen)
  const open = useCallback(() => dispatch(actions.open()), [dispatch])
  const close = useCallback(() => dispatch(actions.close()), [dispatch])

  return {
    isOpen,
    open,
    close
  }
}
