import { IModuleDefinition } from 'modules/types'
import { combineReducers } from 'redux'
import { gainLossesStore } from '../../features/GainLosses/gainLossesStore'
import { revenueChartStore } from '../../features/Revenue/revenueChartDateStore'
import { DashboardContainer } from './DashboardContainer'
import { performanceTileCalloutReducer } from './Performance/performanceTile'

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/',
    private: true,
    component: DashboardContainer
  },
  name: 'Dashboard'
}

export const rdot360DashboardReducer = combineReducers({
  performanceTileCallout: performanceTileCalloutReducer,
  revenueWidget: revenueChartStore,
  gainLosses: gainLossesStore
})

export default moduleDefinition
