import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import { ColumnSort } from '@tanstack/react-table'
import { addMonths } from 'date-fns'
import { AppState } from 'store'
import { IExportData } from '../types/export'

export interface IRevenuePostSplitState {
  categories?: string[]
  endDate: Date
  exportData: IExportData
  isProduction: boolean
  searchText: string
  sort: ColumnSort
  startDate: Date
}

const now = new Date()
const first = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0)
const endDate = addMonths(first, -1)
const startDate = addMonths(endDate, -4)
const initialState: IRevenuePostSplitState = {
  endDate,
  exportData: { headers: [], items: [] },
  isProduction: false,
  searchText: '',
  sort: { id: 'Total', desc: true },
  startDate
}

export const {
  actions: revenuePostSplitActions,
  reducer: revenuePostSplitReducer
} = createSlice({
  name: '@modules/@advisory/@modules/@revenuePostSplit/',
  initialState,
  reducers: {
    updateCategories: (state, action: PayloadAction<string[]>) => {
      state.categories = action.payload
    },
    updateSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    },
    updateStartDate: (state, action: PayloadAction<Date>) => {
      state.startDate = action.payload
    },
    updateEndDate: (state, action: PayloadAction<Date>) => {
      state.endDate = action.payload
    },
    updateExportData: (state, action: PayloadAction<IExportData>) => {
      state.exportData = action.payload
    },
    updateIsProduction: (state, action: PayloadAction<boolean>) => {
      state.isProduction = action.payload
    },
    updateSort: (state, action: PayloadAction<ColumnSort>) => {
      state.sort = action.payload
    }
  }
})

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.revenuePostSplit

export const selectCategories = createSelector(
  rootSelector,
  ({ categories }) => categories
)

export const selectDateRange = createSelector(
  rootSelector,
  ({ startDate, endDate }) => ({ startDate, endDate })
)

export const selectExportData = createSelector(
  rootSelector,
  ({ exportData }) => exportData
)

export const selectIsProduction = createSelector(
  rootSelector,
  ({ isProduction }) => isProduction
)

export const selectSearchText = createSelector(
  rootSelector,
  ({ searchText }) => searchText
)

export const selectSort = createSelector(rootSelector, ({ sort }) => sort)
