import { Header, Row } from '@tanstack/react-table'
import { uniq } from 'lodash'
import { useCallback } from 'react'
import { isNotNullOrUndefined } from 'shared/guards'
import { exportToExcel } from '../export/exportExcel'
import { ExcelColumnConfigType } from '../export/worker'
import { AnnuityPPLICombined } from '../types/annuityPPLICombined'

interface IClickToExportProps {
  headers: Header<AnnuityPPLICombined, unknown>[]
  excelColumnConfigType: ExcelColumnConfigType
  tabLabel: string
  exportLoading: () => void
  exportDoneLoading: () => void
  flatRows: Row<AnnuityPPLICombined>[]
}

export const useClickToExport = (props: IClickToExportProps) => {
  const {
    headers,
    excelColumnConfigType,
    tabLabel,
    exportLoading,
    exportDoneLoading,
    flatRows
  } = props
  const clickToExport = useCallback(() => {
    const visibleColumns = headers
      .map((header) => (header.column.getIsVisible() ? header.id : undefined))
      .filter(isNotNullOrUndefined)

    const updatedRows = uniq(flatRows.map(({ original }) => original))

    exportToExcel(
      [
        {
          data: updatedRows,
          selectedColumnNames: visibleColumns,
          excelColumnConfigType,
          tabLabel
        }
      ],
      exportLoading,
      exportDoneLoading,
      tabLabel
    )
  }, [
    excelColumnConfigType,
    exportDoneLoading,
    exportLoading,
    flatRows,
    headers,
    tabLabel
  ])

  return { clickToExport }
}
