import {
  ISeparatorStyles,
  makeStyles,
  Separator as FabricSeparator
} from '@fluentui/react'
import React, { PropsWithChildren } from 'react'

const useSeparatorClasses = makeStyles((theme) => ({
  separator: {
    '&:before': {
      backgroundColor: theme.palette.neutralTertiary
    }
  }
}))

export const Separator: React.FC<
  PropsWithChildren<{ styles?: Partial<ISeparatorStyles> }>
> = ({ children, styles }) => {
  const classes = useSeparatorClasses()
  return (
    <FabricSeparator
      styles={styles}
      className={classes.separator}
      alignContent="start"
    >
      {children}
    </FabricSeparator>
  )
}
