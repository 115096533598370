import { ITheme, ProgressIndicator } from '@fluentui/react'
import {
  CommandBar,
  ICommandBarItemProps
} from '@fluentui/react/lib/CommandBar'
import React from 'react'
import { useClasses } from 'shared/hooks/useClasses'

const getThemedClasses = (theme: ITheme) => ({
  commandBar: {
    borderBottom: `solid 2px ${theme.palette.neutralLight}`
  }
})

export const SecondaryHeader: React.FC<{
  exportToExcel?: () => void
  isExportError?: Error
  isExportLoading?: boolean
  openPanel?: () => void
}> = ({ exportToExcel, isExportError, isExportLoading, openPanel }) => {
  const classes = useClasses(getThemedClasses)

  const getExportButtonText = (): string => {
    if (isExportLoading) {
      return 'Exporting...'
    }
    if (isExportError) {
      return 'Error!'
    }
    return 'Export to Excel'
  }

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: 'editColumns',
      text: 'Edit Columns',
      cacheKey: 'myCacheKey',
      onClick: openPanel,
      iconProps: { iconName: 'Edit' }
    },
    {
      key: 'exportExcel',
      text: getExportButtonText(),
      cacheKey: 'myCacheKey',
      onClick: exportToExcel,
      disabled: isExportLoading,
      iconProps: { iconName: 'ExcelLogo' }
    }
  ]

  return (
    <>
      <CommandBar
        className={classes.commandBar}
        styles={{
          root: { margin: 0, padding: 0 }
        }}
        items={commandBarItems}
      />
      <div style={{ height: '2px', marginTop: '-2px' }}>
        <ProgressIndicator
          progressHidden={!isExportLoading}
          styles={{
            itemProgress: { padding: 0, margin: 0 }
          }}
        />
      </div>
    </>
  )
}
