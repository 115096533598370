import { call, put, takeEvery } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../store/shared/sagas'
import { GetRelatedLoginsByPartyIdService } from '../../ScheduleReport/api/scheduleReportService'
import { UploadReportService } from './../api/shareReportService'
import { relatedPartiesDetailsActionTypes, uploadReportActions } from './action'

/**
 * Generator function saga to fetch related parties and call api
 */

function* handleGetRelatedPartiesDetails(
  action: ReturnType<typeof relatedPartiesDetailsActionTypes.request>
) {
  // Call fetch related logins api here....
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      GetRelatedLoginsByPartyIdService(options, action.payload)
    )
    if (data && data.length > 0) {
      yield put(relatedPartiesDetailsActionTypes.success(data))
    } else {
      yield put(
        relatedPartiesDetailsActionTypes.failure(
          new Error('No logins are associated with this client')
        )
      )
    }
  } catch (e) {
    yield put(
      relatedPartiesDetailsActionTypes.failure(
        new Error(
          'Unable to fetch logins associated with this client. Please try again later.'
        )
      )
    )
  }
}

function* handleUploadReport(
  action: ReturnType<typeof uploadReportActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() => UploadReportService(options, action.payload))
    yield put(uploadReportActions.success(data))
  } catch (e: any) {
    yield put(uploadReportActions.failure(e))
  }
}
export const sagas = [
  () =>
    takeEvery(
      relatedPartiesDetailsActionTypes.request,
      handleGetRelatedPartiesDetails
    ),
  () => takeEvery(uploadReportActions.request, handleUploadReport)
]
