import { useMemo } from 'react'
import { Drawdown, NonDrawdown } from '../types'
import { getAiDashboardDefaulViewHiddenFilters } from './getListFilters'

export const useHiddenFilters = <T extends string>(listType: T) => {
  const aiPositionListHiddenFilters = useMemo(() => {
    const DdOrNonDd: { [key: string]: string[] } = {
      [Drawdown]: ['YES'],
      [NonDrawdown]: ['NO']
    }

    const hiddenFilters = getAiDashboardDefaulViewHiddenFilters({
      drawdownNonDrawdownValues: DdOrNonDd[listType]
    })

    return hiddenFilters
  }, [listType])

  return { aiPositionListHiddenFilters }
}
