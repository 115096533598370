import { Theme, css, useTheme } from '@emotion/react'
import { Icon, Label } from '@fluentui/react'
import { useMemo } from 'react'
import { useBalanceSummaryDetailsUiState } from '../../modules/Balances/balanceSummaryDetailsUIState'
import { HistoricalBalancesChart } from '../../modules/Dashboard/BalanceSummary/HistoricalBalancesChart'

export const getClasses = (theme: Theme) => ({
  progressIndicatorContainer: css({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  }),
  iconStyle: css({
    display: 'inline-block',
    cursor: 'pointer',
    border: `solid 1px ${theme.colors.primaryLightBlue3}`,
    borderRadius: 9,
    height: 18,
    width: 18,
    fontSize: 8,
    textAlign: 'center',
    backgroundColor: theme.colors.primaryWhite,
    marginRight: 12
  }),
  groupItem: css({
    backgroundColor: theme.colors.primaryLightBlue4,
    color: theme.colors.extraBlue3,
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '19px',
    padding: '10px 5px',
    border: `solid 1px ${theme.colors.tertiaryBlue4}`,
    cursor: 'pointer',
    alignItems: 'center',
    boxShadow: '0px 2px 4px rgb(0 0 0 / 10%)',
    paddingLeft: 20,
    display: 'flex',
    input: {
      width: 15,
      height: 15
    },
    "input[type='checkbox' i]": {
      backgroundColor: theme.colors.primaryWhite
    }
  }),
  chartContainer: css({
    backgroundColor: theme.colors.primaryWhite,
    border: `solid 1px ${theme.colors.extraGray1}`,
    padding: 20,
    height: 320,
    display: 'flex',
    flexDirection: 'column'
  })
})

export const BalanceSummaryChartContainer: React.FC = () => {
  const theme = useTheme()
  const classes = useMemo(() => getClasses(theme), [theme])
  const { isExpanded, setIsExpanded } = useBalanceSummaryDetailsUiState()

  return (
    <div style={{ position: 'relative' }}>
      <div css={classes.groupItem} onClick={() => setIsExpanded(!isExpanded)}>
        {!isExpanded ? (
          <span css={[classes.iconStyle]}>
            <Icon iconName="ChevronDownSmall" />
          </span>
        ) : (
          <span css={[classes.iconStyle]}>
            <Icon iconName="ChevronUpSmall" />
          </span>
        )}
        <Label>Historical Value</Label>
      </div>
      <div css={{ display: isExpanded ? 'block' : 'none' }}>
        <div css={classes.chartContainer}>
          <HistoricalBalancesChart loader="progress" />
        </div>
      </div>
    </div>
  )
}
