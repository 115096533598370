import { IAnnuityPPLIAssets } from 'api/datahub'
import { stringify } from 'query-string'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { isNotNullOrEmpty, isNotNullOrUndefined } from 'shared/guards'
import { datahubApi } from 'store/api/datahub'

type QueryPropsType = {
  registeredRepIds?: string[]
  contractType?: string
  contractStatus?: string[]
}

const paramsSerializer = (params: any) =>
  stringify(params, { arrayFormat: 'comma' })

const api = datahubApi.injectEndpoints({
  endpoints: (builder) => ({
    getPrivatePlacementSummary: builder.query<
      IAnnuityPPLIAssets[],
      QueryPropsType
    >({
      query: ({ registeredRepIds, contractType, contractStatus }) => {
        const repIdFilter =
          registeredRepIds &&
          `registeredRep in ('${registeredRepIds
            ?.filter(isNotNullOrEmpty)
            .join("','")}')`

        const contractStatusFilter =
          contractStatus &&
          `contract/contractStatusDesc in ('${contractStatus
            ?.filter(isNotNullOrEmpty)
            .join("','")}')`

        const contractTypeFilter =
          contractType && `contractType in ('${contractType}')`

        const allFilters = [
          contractTypeFilter,
          contractStatusFilter,
          repIdFilter
        ]

        return {
          url: 'AnnuityPPLIAssets',
          params: {
            $filter: allFilters.filter(isNotNullOrUndefined).join(' and '),
            $expand: 'contract, contractSecurity'
          },
          paramsSerializer
        }
      },
      keepUnusedDataFor: 60 * 60 * 2,
      transformResponse: (x: IOdataResult<IAnnuityPPLIAssets>) => x.value || []
    })
  })
})

export const { useGetPrivatePlacementSummaryQuery } = api
