import { makeStyles } from '@fluentui/react'
import { debounce } from 'lodash'
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

const useClasses = makeStyles(() => ({
  container: {
    '@media screen': {
      overflow: 'clip'
    }
  },
  scrollbar: {
    '@media screen': {
      minHeight: '10px',
      position: 'sticky',
      bottom: 0,
      overflowX: 'auto',
      overflowY: 'hidden',
      zIndex: 10
    }
  }
}))

export const HorizontalScrollContainer: React.FC<PropsWithChildren> = ({
  children
}) => {
  const innerContainerRef = useRef<HTMLDivElement>(null)
  const outerContainerRef = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)

  const [scrollWidth, setScrollWidth] = useState<number>()
  const onResize = useCallback(() => {
    const width = innerContainerRef.current?.clientWidth
    if (!width) {
      return
    }
    setScrollWidth(width)
  }, [])

  const classes = useClasses()

  const debouncedOnResize = useMemo(() => debounce(onResize, 200), [onResize])

  useEffect(() => {
    if (!innerContainerRef.current) {
      return
    }

    const observer = new ResizeObserver(debouncedOnResize)
    observer.observe(innerContainerRef.current)

    return () => observer.disconnect()
  }, [debouncedOnResize])

  const onScroll = useCallback(() => {
    if (!innerContainerRef.current) {
      return
    }
    innerContainerRef.current.style.marginLeft = `-${scrollRef.current?.scrollLeft}px`
  }, [])

  return (
    <div ref={outerContainerRef} className={classes.container}>
      <div ref={innerContainerRef} style={{ width: 'fit-content' }}>
        {children}
      </div>
      <div className={classes.scrollbar} onScroll={onScroll} ref={scrollRef}>
        <div style={{ width: `${scrollWidth}px`, height: '1px' }} />
      </div>
    </div>
  )
}
