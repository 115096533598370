import { createAction, ActionType } from 'typesafe-actions'
import {
  ILookUpValueResponse,
  IAbaNumber,
  IEligibleAccount,
  IWirePayment
} from '../api/types'
import { IInstruction, IWireInstructionForSecurity } from './types'

export const FETCH_LOOKUP_VALUES_REQUEST =
  '@modules/@payments/@wire/FETCH_LOOKUP_VALUES_REQUEST'
export const FETCH_LOOKUP_VALUES_SUCCESS =
  '@modules/@payments/@wire/FETCH_LOOKUP_VALUES_SUCCESS'
export const FETCH_LOOKUP_VALUES_FAILURE =
  '@modules/@payments/@wire/FETCH_LOOKUP_VALUES_FAILURE'
export const CLEAR_LOOKUP_VALUES =
  '@modules/@payments/@wire/CLEAR_LOOKUP_VALUES'

export const CREATE_WIRE_INSTRUCTION_REQUEST = `@modules/@payments/@wire/CREATE_WIRE_INSTRUCTION_REQUEST`
export const CREATE_WIRE_INSTRUCTION_SUCCESS = `@modules/@payments/@wire/CREATE_WIRE_INSTRUCTION_SUCCESS`
export const CREATE_WIRE_INSTRUCTION_FAILURE = `@modules/@payments/@wire/CREATE_WIRE_INSTRUCTION_FAILURE`
export const CREATE_WIRE_INSTRUCTION_VALIDATION_ERROR = `@modules/@payments/@wire/CREATE_WIRE_INSTRUCTION_VALIDATION_ERROR`
export const NAVIAGTE_TO_VENDOR = `@modules/@payments/@wire/NAVIGATE_TO_VENDOR`

export const FETCH_ABA_DETAIL_REQUEST = `@modules/@payments/@wire/FETCH_ABA_DETAIL_REQUEST`
export const FETCH_ABA_DETAIL_SUCCESS = `@modules/@payments/@wire/FETCH_ABA_DETAIL_SUCCESS`
export const FETCH_ABA_DETAIL_FAILURE = `@modules/@payments/@wire/FETCH_ABA_DETAIL_FAILURE`
export const CLEAR_ABA_DETAIL = `@modules/@payments/@wire/CLEAR_ABA_DETAIL`

export const FETCH_ELIGIBLE_ACCT_REQUEST = `@modules/@payments/@wire/FETCH_ELIGIBLE_ACCT_REQUEST`
export const FETCH_ELIGIBLE_ACCT_SUCCESS = `@modules/@payments/@wire/FETCH_ELIGIBLE_ACCT_SUCCESS`
export const FETCH_ELIGIBLE_ACCT_FAILURE = `@modules/@payments/@wire/FETCH_ELIGIBLE_ACCT_FAILURE`
export const CLEAR_FETCH_ELIGIBLE_ACCT = `@modules/@payments/@wire/CLEAR_FETCH_ELIGIBLE_ACCT`

export const FETCH_WIRE_INSTRUCTION_REQUEST = `@modules/@payments/@wire/FETCH_WIRE_INSTRUCTION_REQUEST`
export const FETCH_WIRE_INSTRUCTION_SUCCESS = `@modules/@payments/@wire/FETCH_WIRE_INSTRUCTION_SUCCESS`
export const FETCH_WIRE_INSTRUCTION_FAILURE = `@modules/@payments/@wire/FETCH_WIRE_INSTRUCTION_FAILURE`
export const CLEAR_FETCH_WIRE_INSTRUCTION = `@modules/@payments/@wire/CLEAR_FETCH_WIRE_INSTRUCTION`

export const FETCH_WIRE_PAYMENTS_REQUEST = `@modules/@payments/@wire/FETCH_WIRE_PAYMENTS_REQUEST`
export const FETCH_WIRE_PAYMENTS_SUCCESS = `@modules/@payments/@wire/FETCH_WIRE_PAYMENTS_SUCCESS`
export const FETCH_WIRE_PAYMENTS_FAILURE = `@modules/@payments/@wire/FETCH_WIRE_PAYMENTS_FAILURE`

export const CREATE_WIRE_INSTRUCTION_FOR_SECURITY_REQUEST = `@modules/@payments/@wire/CREATE_WIRE_INSTRUCTION_FOR_SECURITY_REQUEST`
export const CREATE_WIRE_INSTRUCTION_FOR_SECURITY_SUCCESS = `@modules/@payments/@wire/CREATE_WIRE_INSTRUCTION_FOR_SECURITY_SUCCESS`
export const CREATE_WIRE_INSTRUCTION_FOR_SECURITY_FAILURE = `@modules/@payments/@wire/CREATE_WIRE_INSTRUCTION_FOR_SECURITY_FAILURE`
export const CLEAR_CREATE_WIRE_INSTRUCTION_FOR_SECURITY = `@modules/@payments/@wire/CLEAR_CREATE_WIRE_INSTRUCTION_FOR_SECURITY`

export const LookUpValueActions = {
  request: createAction(FETCH_LOOKUP_VALUES_REQUEST)<string>(),
  success: createAction(FETCH_LOOKUP_VALUES_SUCCESS)<ILookUpValueResponse[]>(),
  failure: createAction(FETCH_LOOKUP_VALUES_FAILURE)<Error>(),
  clear: createAction(CLEAR_LOOKUP_VALUES)()
}

export const CreateWireInstructionActions = {
  request: createAction(CREATE_WIRE_INSTRUCTION_REQUEST)<IInstruction>(),
  success: createAction(CREATE_WIRE_INSTRUCTION_SUCCESS)<null | undefined>(),
  failure: createAction(CREATE_WIRE_INSTRUCTION_FAILURE)<Error>(),
  validationError: createAction(
    CREATE_WIRE_INSTRUCTION_VALIDATION_ERROR
  )<string>()
}

export const FetchAbaDetailActions = {
  request: createAction(FETCH_ABA_DETAIL_REQUEST)<{
    routingNumber: string
    country?: string
  }>(),
  success: createAction(FETCH_ABA_DETAIL_SUCCESS)<IAbaNumber>(),
  failure: createAction(FETCH_ABA_DETAIL_FAILURE)<Error>(),
  clear: createAction(CLEAR_ABA_DETAIL)()
}

export const FetchEligibleAcctsActions = {
  request: createAction(FETCH_ELIGIBLE_ACCT_REQUEST)<string>(),
  success: createAction(FETCH_ELIGIBLE_ACCT_SUCCESS)<IEligibleAccount[]>(),
  failure: createAction(FETCH_ELIGIBLE_ACCT_FAILURE)<Error>(),
  clear: createAction(CLEAR_FETCH_ELIGIBLE_ACCT)()
}

export const FetchWireInstructionActions = {
  request: createAction(FETCH_WIRE_INSTRUCTION_REQUEST)<{
    id: string
    orgid: string
  }>(),
  success: createAction(FETCH_WIRE_INSTRUCTION_SUCCESS)<IInstruction>(),
  failure: createAction(FETCH_WIRE_INSTRUCTION_FAILURE)<Error>(),
  clear: createAction(CLEAR_FETCH_WIRE_INSTRUCTION)()
}

export const WireInstructionNavigationActions = {
  navigateToVendor: createAction(NAVIAGTE_TO_VENDOR)<string>()
}

export const FetchWirePaymentActions = {
  request: createAction(FETCH_WIRE_PAYMENTS_REQUEST)<{
    fromDate: string
    toDate: string
  }>(),
  success: createAction(FETCH_WIRE_PAYMENTS_SUCCESS)<IWirePayment[]>(),
  failure: createAction(FETCH_WIRE_PAYMENTS_FAILURE)<Error>()
}

export const CreateWireInstructionForSecurityActions = {
  request: createAction(
    CREATE_WIRE_INSTRUCTION_FOR_SECURITY_REQUEST
  )<IWireInstructionForSecurity>(),
  success: createAction(CREATE_WIRE_INSTRUCTION_FOR_SECURITY_SUCCESS)(),
  failure: createAction(CREATE_WIRE_INSTRUCTION_FOR_SECURITY_FAILURE)<Error>(),
  clear: createAction(CLEAR_CREATE_WIRE_INSTRUCTION_FOR_SECURITY)()
}

export type LookUpValueActionType = ActionType<typeof LookUpValueActions>
export type CreateWireInstructionActionType = ActionType<
  typeof CreateWireInstructionActions
>
export type WireInstructionNavigationActionType = ActionType<
  typeof WireInstructionNavigationActions
>

export type FetchAbaDetailActionType = ActionType<typeof FetchAbaDetailActions>
export type FetchEligibleAcctsActionType = ActionType<
  typeof FetchEligibleAcctsActions
>

export type FetchWireInstructionActionType = ActionType<
  typeof FetchWireInstructionActions
>

export type FetchWirePaymentActionType = ActionType<
  typeof FetchWirePaymentActions
>

export type CreateWireInstructionForSecurityActionType = ActionType<
  typeof CreateWireInstructionForSecurityActions
>

export type WireActions =
  | LookUpValueActionType
  | CreateWireInstructionActionType
  | CreateWireInstructionForSecurityActionType
  | WireInstructionNavigationActionType
  | FetchAbaDetailActionType
  | FetchEligibleAcctsActionType
  | FetchWireInstructionActionType
  | FetchWirePaymentActionType
