import { call, delay, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { getHouseholdFee, IHouseholdFee } from '../../../api/households'
import { AppState } from '../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../../store/shared/sagas'

export const householdFeeFetchActions = createAsyncAction(
  '@features/@households/@features/@householdFee/@householdFee/REQUEST',
  '@features/@households/@features/@householdFee/@householdFee/SUCCESS',
  '@features/@households/@features/@householdFee/@householdFee/FAILURE'
)<string, IHouseholdFee | undefined, Error>()

export const householdFeeFetchReducer = createAsyncReducer(
  householdFeeFetchActions
)

const rootSelector = (state: AppState) =>
  state.features.households.householdFeeFetch

export const {
  getError: getHouseholdFeeFetchError,
  getIsLoading: getIsHouseholdFeeFetchLoading,
  getResult: getHouseholdFeeFetchResult,
  getRequest: getHouseholdFeeFetchRequest
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof householdFeeFetchActions.request>
) {
  if (!action.payload) {
    yield put(householdFeeFetchActions.success(undefined))
    return
  }
  yield delay(300)

  try {
    const apiOptions = yield* call(getRockefellerApiOptions)
    const result = yield* call(getHouseholdFee, apiOptions, action.payload)

    if (!result) {
      throw new Error('No result returned from datahub API')
    }

    yield put(householdFeeFetchActions.success(result))
  } catch (e: any) {
    yield put(householdFeeFetchActions.failure(e))
  }
}

export const householdFeesFetchSagas = [
  () => takeLatest(householdFeeFetchActions.request, onRequest)
]
