import { ActionButton, Callout, DirectionalHint, ITheme } from '@fluentui/react'
import React, { useCallback, useRef, useState } from 'react'
import { useClasses } from '../../../shared/hooks/useClasses'
import { FeedbackContainer } from './Feedback'

const getThemedClasses = (theme: ITheme) => {
  return {
    feedbackCallout: {
      boxShadow: theme.effects.elevation16,
      minWidth: '200px'
    }
  }
}

export const FeedbackButtonContainer: React.FC = () => {
  const [isCalloutOpen, setIsCalloutOpen] = useState(false)
  const toggleFeedbackCallout = useCallback(
    () => setIsCalloutOpen(!isCalloutOpen),
    [isCalloutOpen]
  )
  const closeFeedbackCallout = useCallback(() => setIsCalloutOpen(false), [])
  const FeedbackButton = useRef<HTMLSpanElement>(null)
  const classes = useClasses(getThemedClasses)
  return (
    <>
      <span ref={FeedbackButton} onClick={toggleFeedbackCallout}>
        <ActionButton
          styles={{ root: { height: '32px' } }}
          iconProps={{ iconName: 'Feedback' }}
          title="Feedback"
          ariaLabel="Feedback"
          disabled={false}
          checked={isCalloutOpen}
        >
          Help and Support
        </ActionButton>
      </span>
      {isCalloutOpen ? (
        <Callout
          className={classes.feedbackCallout}
          styles={{ root: { zIndex: 3 } }}
          target={FeedbackButton}
          isBeakVisible={false}
          setInitialFocus={true}
          directionalHint={DirectionalHint.bottomLeftEdge}
          doNotLayer={true}
          onDismiss={closeFeedbackCallout}
        >
          <FeedbackContainer onLinkClick={closeFeedbackCallout} />
        </Callout>
      ) : null}
    </>
  )
}
