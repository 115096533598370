import { AnnuityPPLIName } from '../../shared/annuityPPLIContracts'

export type AnnuityPPLINameExtended =
  | AnnuityPPLIName
  | 'Households'
  | 'Advisor Teams'

export const columnNames: Record<any, AnnuityPPLINameExtended> = {
  cusipNumber: 'CUSIP',
  securityDescription: 'Description',
  contractType: 'Contract Type',
  contractNumber: 'Contract Number',
  assetClassL1: 'Asset Class Level-1',
  assetClassL2: 'Asset Class Level-2',
  assetClassL3: 'Asset Class Level-3',
  assetClassL4: 'Asset Class Level-4',
  cleanCarrierName: 'Clean Carrier Name',
  householdName: 'Household Name',
  householdsTotal: 'Households',
  premiumPaidSinceInception: 'Premium',
  cashValue: 'Cash Value',
  deathBenefitAmt: 'Death Benefit',
  valuationDate: 'Last Updated',
  teamName: 'Advisor Team',
  teamsTotal: 'Advisor Teams',
  assetType: 'Asset Type',
  assetSubType: 'Asset Sub Type',
  productType: 'Product Type',
  regionName: 'Division Name'
}
