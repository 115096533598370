import { Icon, Link, makeStyles, Stack, Text } from '@fluentui/react'
import { isString } from 'lodash'

const useClasses = makeStyles((theme) => ({
  searchItem: {
    padding: '6px 5px',
    border: `solid 1px ${theme.palette.neutralLight}`,
    backgroundColor: theme.palette.neutralLighterAlt,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: theme.palette.neutralLight
    },
    '.action-arrow': {
      visibility: 'hidden'
    },
    ':hover .action-arrow': {
      visibility: 'visible'
    }
  }
}))

export interface IRepSelectorListItemProps {
  onClick?: () => void
  iconName?: string
  text?: string | (() => React.ReactElement)
  subtext?: string | (() => React.ReactElement)
}

export const RepSelectorListItem: React.FC<IRepSelectorListItemProps> = ({
  text: TextComponent,
  subtext: SubtextComponent,
  iconName,
  onClick
}) => {
  const classes = useClasses()
  const textIsString = isString(TextComponent)
  const subtextIsString = isString(SubtextComponent)
  return (
    <Stack
      horizontal={true}
      tokens={{ childrenGap: 10 }}
      verticalAlign="center"
      className={classes.searchItem}
      onClick={onClick}
    >
      <Icon iconName={iconName} />
      <Stack grow={1}>
        {TextComponent && textIsString && (
          <Text>
            <Link>{TextComponent}</Link>
          </Text>
        )}
        {TextComponent && !textIsString && <TextComponent />}
        {SubtextComponent && subtextIsString && (
          <Text variant="small">{SubtextComponent}</Text>
        )}
        {SubtextComponent && !subtextIsString && <SubtextComponent />}
      </Stack>
      <Icon className="action-arrow" iconName="ChevronRightMed" />
    </Stack>
  )
}
