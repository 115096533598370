export const SecureMessageContainer: React.FC = () => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        height: '200px'
      }}
    >
      COMING SOON
    </div>
  )
}
