import { IDropdownOption } from '@fluentui/react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ExpandedState,
  SortingState,
  Updater,
  VisibilityState
} from '@tanstack/react-table'
import { isFunction } from 'lodash'
import { useClientDashboardTilePreferences } from 'modules/Advisory/modules/Rdot360/hooks/useClientDashboardPreferences'
import { ClientDashboardTiles } from 'modules/Advisory/modules/Rdot360/shared/types'
import {
  AssetClassLevel,
  PositionCategoryType
} from 'modules/Advisory/modules/Rdot360/store/holdingsApi'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { investmentsTableColumnNames } from './shared'

export interface IInvestmentsTableState {
  assetClassLevel?: AssetClassLevel
  sorting?: SortingState
  expanded?: ExpandedState
  defaultExpanded?: ExpandedState
  visibility?: VisibilityState
}

const initialState = {
  assetClassLevel: 'L1',
  sorting: [{ id: investmentsTableColumnNames.value, desc: true }],
  expanded: {},
  defaultExpanded: undefined,
  visibility: {
    [investmentsTableColumnNames.accountNumber]: false,
    [investmentsTableColumnNames.nickname]: false,
    [investmentsTableColumnNames.assetClass]: false,
    [investmentsTableColumnNames.productType]: false,
    [investmentsTableColumnNames.sectorClass]: false,
    [investmentsTableColumnNames.secid]: false,
    [investmentsTableColumnNames.symbol]: false,
    [investmentsTableColumnNames.desc]: false
  }
} as IInvestmentsTableState

export const {
  actions: investmentsTableActions,
  reducer: investmentsTableReducer
} = createSlice({
  name: '@modules/@rdot360/@modules/@investments/@features/@investmentsTable',
  initialState,
  reducers: {
    setSorting: (state, action: PayloadAction<SortingState | undefined>) => ({
      ...state,
      sorting: action.payload
    }),
    setExpanded: (state, action: PayloadAction<ExpandedState | undefined>) => ({
      ...state,
      expanded: action.payload
    }),
    setDefaultExpanded: (
      state,
      action: PayloadAction<ExpandedState | undefined>
    ) => ({
      ...state,
      defaultExpanded: action.payload
    }),
    setVisibility: (
      state,
      action: PayloadAction<VisibilityState | undefined>
    ) => ({
      ...state,
      visibility: action.payload
    }),
    setAssetClassLevel: (
      state,
      action: PayloadAction<AssetClassLevel | undefined>
    ) => ({
      ...state,
      assetClassLevel: action.payload
    })
  }
})

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.modules.investments.features
    .investmentsTable

const tileName = ClientDashboardTiles.holdingsTile

const { assetClass, productType, sectorClass, secid, accountNumber } =
  investmentsTableColumnNames

const groupingLookup = {
  [assetClass]: [assetClass, secid],
  [sectorClass]: [sectorClass, secid],
  [productType]: [productType, secid],
  [secid]: [secid],
  [accountNumber]: [accountNumber, productType]
}

const viewByOptions: IDropdownOption[] = [
  { key: assetClass, text: 'Asset Class' },
  { key: sectorClass, text: 'Sector Class' },
  { key: productType, text: 'Product Type' },
  { key: secid, text: 'Investment' },
  { key: accountNumber, text: 'Accounts' }
]

const assetClassLevelOptions: IDropdownOption[] = [
  { key: 'L1', text: 'Level 1' },
  { key: 'L2', text: 'Level 2' },
  { key: 'L3', text: 'Level 3' },
  { key: 'L4', text: 'Level 4' }
]

export const useInvestmentsTableStore = () => {
  const dispatch = useDispatch()
  const { sorting, visibility, expanded, defaultExpanded, assetClassLevel } =
    useSelector(rootSelector)
  const { setTilePreferences, tilePreferences } =
    useClientDashboardTilePreferences(tileName)
  const assetClassLevelSelected =
    assetClassLevel || (assetClassLevelOptions[0].key as AssetClassLevel)
  const excludeUnclassifiedSectors =
    tilePreferences?.excludeUnclassifiedSectors ?? true
  const viewByKeySelected = tilePreferences?.viewByKey || viewByOptions[0].key
  const grouping = groupingLookup[viewByKeySelected]

  const category: PositionCategoryType = useMemo(() => {
    if (grouping?.length) {
      if (grouping.includes(productType)) {
        return 'P'
      }

      if (grouping[0] === sectorClass) {
        return excludeUnclassifiedSectors ? 'S:E' : 'S'
      }
    }

    return assetClassLevelSelected
  }, [assetClassLevelSelected, excludeUnclassifiedSectors, grouping])

  const setAssetClassLevelSelected = useCallback(
    (selected?: AssetClassLevel) =>
      dispatch(investmentsTableActions.setAssetClassLevel(selected)),
    [dispatch]
  )
  const setInvestmentsViewByKey = useCallback(
    (viewByKey: string | undefined = secid) => {
      setTilePreferences({ viewByKey })
      setAssetClassLevelSelected('L1')
    },
    [setTilePreferences, setAssetClassLevelSelected]
  )
  const setInvestmentsViewByCategory = useCallback(
    (category: PositionCategoryType) => {
      switch (category) {
        case 'P':
          setInvestmentsViewByKey(productType)
          break
        case 'S':
          setInvestmentsViewByKey(sectorClass)
          break
        default:
          setInvestmentsViewByKey(assetClass)
          break
      }
    },
    [setInvestmentsViewByKey]
  )
  const setSorting = useCallback(
    (updater: Updater<SortingState>) => {
      const value = isFunction(updater) ? updater(sorting || []) : updater
      dispatch(investmentsTableActions.setSorting(value))
    },
    [dispatch, sorting]
  )
  const setVisibility = useCallback(
    (updater: Updater<VisibilityState>) => {
      const value = isFunction(updater) ? updater(visibility || {}) : updater
      dispatch(investmentsTableActions.setVisibility(value))
    },
    [dispatch, visibility]
  )
  const setExpanded = useCallback(
    (updater: Updater<ExpandedState>) => {
      const value = isFunction(updater) ? updater(expanded || {}) : updater
      dispatch(investmentsTableActions.setExpanded(value))
    },
    [dispatch, expanded]
  )
  const setDefaultExpanded = useCallback(
    (value?: ExpandedState) => {
      dispatch(investmentsTableActions.setDefaultExpanded(value))
    },
    [dispatch]
  )
  const setExcludeUnclassifiedSectors = useCallback(
    (exclude?: boolean) => {
      setTilePreferences({ excludeUnclassifiedSectors: exclude })
    },
    [setTilePreferences]
  )

  return {
    sorting,
    setSorting,
    grouping,
    setInvestmentsViewByCategory,
    setInvestmentsViewByKey,
    visibility,
    setVisibility,
    expanded,
    setExpanded,
    defaultExpanded,
    setDefaultExpanded,
    viewByOptions,
    assetClassLevelOptions,
    assetClassLevelSelected,
    setAssetClassLevelSelected,
    category,
    excludeUnclassifiedSectors,
    setExcludeUnclassifiedSectors
  }
}
