import { combineReducers } from 'redux'
import {
  platformFeeHistoryListReducer,
  platformFeeHistoryListSagas
} from './platformFeeHistoryList'

export const platformFeeHistoryModuleReducer = combineReducers({
  platformFeeHistoryList: platformFeeHistoryListReducer
})

export const platformFeeHistoryModuleSagas = [...platformFeeHistoryListSagas]
