import { createReducer } from 'typesafe-actions'
import { accountsDataActions, AccountsActionType } from './actions'
import { IAccount } from './types'

export interface IAccountsState {
  accountsData: IAccount[]
  isLoading: boolean
  error?: Error
}

const initialState: IAccountsState = {
  accountsData: [],
  isLoading: true,
  error: undefined
}

export const accountsReducer = createReducer<
  IAccountsState,
  AccountsActionType
>(initialState)
  .handleAction(accountsDataActions.request, (state) => ({
    ...state,
    isLoading: true,
    error: undefined,
    accountsData: []
  }))
  .handleAction(accountsDataActions.success, (state, action) => ({
    ...state,
    accountsData: action.payload,
    isLoading: false,
    error: undefined
  }))
  .handleAction(accountsDataActions.failure, (state, action) => ({
    ...state,
    accountsData: [],
    error: action.payload,
    isLoading: false
  }))
