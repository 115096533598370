import {
  DefaultButton,
  Link,
  Panel,
  PanelType,
  PrimaryButton,
  SearchBox,
  Stack
} from '@fluentui/react'
import { keyBy, orderBy, sortBy, sum } from 'lodash'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SearchResponseType } from '../../../../api/common.types'
import { getEnablePreviewPreference } from '../../../../store/user/selectors'
import { SortableListsDataTableColumnEditorComponent } from '../components/ListsDataTableColumnEditor'
import { IColumnState } from '../contracts/IColumnState'
import { IListsActions } from '../store/actions'
import { IListsSelectors } from '../store/selectors'

const MemoListsDataTableColumnEditorComponent = memo(
  SortableListsDataTableColumnEditorComponent
)

export interface IConnectedListsDataTableColumnEditorPanelComponentProps {
  isOpen: boolean
  onDismiss: () => void
}

export const createConnectedListsDataTableColumnEditorPanelComponent = <
  T extends SearchResponseType
>(
  actions: IListsActions<T>,
  selectors: IListsSelectors<T>
) => {
  const ConnectedListsDataTableColumnEditorPanelComponent: React.FC<
    IConnectedListsDataTableColumnEditorPanelComponentProps
  > = ({ isOpen, onDismiss }) => {
    const { uiActions } = actions
    const { uiSelectors } = selectors
    const columnDefinitions = useSelector(uiSelectors.getColumnDefinitions)
    const columnDefinitionList = useMemo(
      () => Object.entries(columnDefinitions || {}).map(([, value]) => value),
      [columnDefinitions]
    )
    const columnState = useSelector(uiSelectors.getColumnState)

    const dispatch = useDispatch()

    const [modifiedColumns, setModifiedColumns] = useState<IColumnState[]>([])

    const getOrderedColumns = useCallback(
      (columns: IColumnState[]) => {
        const copy = [...columns]
        const length = copy.length
        const forSorting = copy.map((x, i) => ({
          column: x,
          index: i
        }))

        const sorted = orderBy(
          forSorting,
          [
            ({ column: { sticky, selected }, index }) =>
              sum([
                sticky ? 0 : length,
                selected ? 0 : length,
                sticky || selected ? index : 0
              ]),
            ({ column: { columnId } }) => columnDefinitions?.[columnId]?.name
          ],
          ['asc', 'asc']
        ).map(({ column }) => column)

        return sorted
      },
      [columnDefinitions]
    )

    useEffect(() => {
      const sorted = getOrderedColumns(columnState)
      setModifiedColumns(sorted)
    }, [columnState, getOrderedColumns, isOpen])

    const onColumnChanged = useCallback(
      (newColumn: IColumnState) => {
        const newColumns = [...modifiedColumns]
        const columnIndex = newColumns.findIndex(
          (x) => x.columnId === newColumn.columnId
        )
        newColumns.splice(columnIndex, 1, newColumn)
        setModifiedColumns(newColumns)
        const sorted = getOrderedColumns(newColumns)

        setTimeout(() => setModifiedColumns(sorted), 250)
      },
      [getOrderedColumns, modifiedColumns]
    )

    const onColumnMoved = useCallback(
      (column: IColumnState, oldPosition: number, newPosition: number) => {
        const newColumns = [...modifiedColumns]
        newColumns.splice(oldPosition, 1)
        newColumns.splice(newPosition, 0, { ...column })
        setModifiedColumns(newColumns)
      },
      [modifiedColumns]
    )

    const onApply = useCallback(() => {
      dispatch(uiActions.updateColumnState(modifiedColumns))
      onDismiss()
    }, [dispatch, modifiedColumns, onDismiss, uiActions])

    const onRenderFooterContent = useCallback(() => {
      const isAnySelected = modifiedColumns.some((x) => x.selected)
      return (
        <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
          <PrimaryButton disabled={!isAnySelected} onClick={onApply}>
            Apply
          </PrimaryButton>
          <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
        </Stack>
      )
    }, [modifiedColumns, onApply, onDismiss])

    const enablePreviewFeatures = useSelector(getEnablePreviewPreference)

    const [searchText, setSearchText] = useState<string | undefined>()
    const onSearchChange = useCallback((_: unknown, newValue?: string) => {
      setSearchText(newValue)
    }, [])

    useEffect(() => {
      setSearchText(undefined)
    }, [isOpen])

    const visibleColumns = useMemo(() => {
      const lowerCaseSearchText = (searchText || '').trim().toLowerCase()
      const filtered = lowerCaseSearchText
        ? columnDefinitionList?.filter(
            (x) =>
              [x.name]
                .map((x) => (x || '').trim().toLowerCase())
                .join(' | ')
                .indexOf(lowerCaseSearchText) >= 0
          )
        : columnDefinitionList

      return sortBy(filtered, (x) => x.name)
    }, [columnDefinitionList, searchText])

    const onSelectAll = useCallback(
      (selected = true) => {
        const visibleColumnLookup = keyBy(visibleColumns, ({ id }) => id)

        setModifiedColumns(
          modifiedColumns.map((x) => ({
            ...x,
            selected: selected && !!visibleColumnLookup[x.columnId],
            sticky: selected && !!visibleColumnLookup[x.columnId] && x.sticky
          }))
        )
      },
      [modifiedColumns, visibleColumns]
    )

    return (
      <Panel
        isOpen={isOpen}
        type={PanelType.medium}
        onDismiss={onDismiss}
        headerText="Edit Columns"
        hasCloseButton={true}
        closeButtonAriaLabel="close"
        isBlocking={true}
        layerProps={{ eventBubblingEnabled: true }}
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}
        styles={{ content: { flexGrow: 1 } }}
      >
        <Stack tokens={{ childrenGap: 10 }}>
          <SearchBox
            placeholder="Filter Columns"
            value={searchText}
            onChange={onSearchChange}
            autoComplete="off"
          />
          <Stack tokens={{ childrenGap: 2 }}>
            <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
              <Link onClick={() => onSelectAll(true)}>Select All</Link>
              <span>|</span>
              <Link onClick={() => onSelectAll(false)}>Deselect All</Link>
            </Stack>
            <MemoListsDataTableColumnEditorComponent
              columnDefinitions={visibleColumns}
              columnState={modifiedColumns}
              onColumnChanged={onColumnChanged}
              onColumnMoved={onColumnMoved}
              enablePreviewColumns={enablePreviewFeatures}
            />
          </Stack>
        </Stack>
      </Panel>
    )
  }

  return ConnectedListsDataTableColumnEditorPanelComponent
}
