import {
  Callout,
  DirectionalHint,
  ProgressIndicator,
  Text
} from '@fluentui/react'
import { useFetchAiPositionDetailsQuery } from 'modules/Advisory/modules/AI/modules/Dashboard/store/aiDashboardPositionsApi'
import { FC, useCallback, useMemo } from 'react'
import { Icon } from 'shared/uiSharedComponents/Icon'
import { SnackBar } from 'shared/uiSharedComponents/Snackbar'
import { CalloutDetailsTable } from './CalloutDetailsTable'
import { positionSelectFields } from './ColumnDefinitions'

export const CalloutWrapper: FC<{
  accountKeys?: string[]
  cusip?: string
  onDismiss: () => void
  target: React.RefObject<HTMLDivElement>
}> = ({ accountKeys, cusip, onDismiss, target }) => {
  const {
    data = [],
    isError,
    isFetching
  } = useFetchAiPositionDetailsQuery({
    accountKeys,
    cusip,
    selectFields: positionSelectFields
  })

  const showNoData = isError || !data.length

  const isDrawdown = useMemo(
    () => data.every((d) => d.DrawdownNonDrawdown === 'YES'),
    [data]
  )

  const CalloutHeader = useCallback(
    () => (
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 10
        }}
      >
        <Text variant="medium">Details</Text>
        <Icon
          title="Dismiss"
          type="CancelClose"
          onClick={onDismiss}
          width={18}
          height={18}
        />
      </div>
    ),
    [onDismiss]
  )

  const calloutProps = useMemo(
    () => ({
      calloutMaxWidth: isDrawdown ? 1200 : 960,
      calloutMinWidth: 300,
      directionalHint: DirectionalHint.topLeftEdge,
      onDismiss,
      target,
      styles: {
        root: {
          padding: 20,
          minHeight: 120
        }
      }
    }),
    [isDrawdown, onDismiss, target]
  )

  return (
    <Callout {...calloutProps}>
      <CalloutHeader />

      {isFetching && (
        <ProgressIndicator
          styles={{
            itemProgress: { padding: 0, marginTop: 0 }
          }}
        />
      )}

      {!isFetching && showNoData && (
        <div css={{ margin: '30px 0' }}>
          {isError ? (
            <SnackBar type="Failure" message="An unknown error occurred" />
          ) : (
            <SnackBar type="Info" message="No data available" />
          )}
        </div>
      )}

      {!isFetching && !showNoData && (
        <CalloutDetailsTable data={data} isDrawdown={isDrawdown} />
      )}
    </Callout>
  )
}
