export const ready = () => {
  if (
    document.readyState === 'interactive' ||
    document.readyState === 'complete'
  ) {
    return Promise.resolve()
  } else {
    return new Promise((resolve) => {
      document.addEventListener('DOMContentLoaded', resolve)
    })
  }
}

/**
 * @deprecated use hooks and useLocation from react-router-dom wherever possible.
 * This may not work correctly within a selector.
 */
export const getLocation = () => document.location
