import { MessageBarType } from '@fluentui/react'
import { pushNotification } from 'features/Notifications'
import { flow } from 'lodash/fp'
import { createSelector } from 'reselect'
import { AppState } from 'store'
import { call, put, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { v4 as uuidv4 } from 'uuid'
import { buildAusRevenueCombinedReport } from './ausRevenueCombined'
import { buildRMDReport } from './rmdReport'

export const reports = {
  ausRevenueCombined: buildAusRevenueCombinedReport,
  rmdReport: buildRMDReport
}

export interface IAdvisoryReportActionPayload {
  name: keyof typeof reports
}

export interface IAdvisoryReportStartActionPayload
  extends IAdvisoryReportActionPayload {
  id: string
}

const REQUEST = '@modules/@advisory/@reports/@REQUEST'
const START = '@modules/@advisory/@reports/@START'
const SUCCESS = '@modules/@advisory/@reports/@SUCCESS'
const FAILURE = '@modules/@advisory/@reports/@FAILURE'
export const excelReportsActions = {
  request: createAction(REQUEST)<IAdvisoryReportActionPayload>(),
  start: createAction(START)<IAdvisoryReportStartActionPayload>(),
  success: createAction(SUCCESS)<string>(),
  failure: createAction(FAILURE)<{ id: string; error: Error }>()
}

export interface IAdvisoryReportBuildState {
  name: string
  id: string
  loading: boolean
  complete?: boolean
  error?: Error
}

export interface IAdvisorReportState {
  reports: IAdvisoryReportBuildState[]
}

const initialState: IAdvisorReportState = { reports: [] }

export const excelReportsReducer = createReducer<
  IAdvisorReportState,
  ActionType<typeof excelReportsActions>
>(initialState)
  .handleAction(
    excelReportsActions.start,
    (state, { payload: { id, name } }) => ({
      reports: [...state.reports, { id, name, loading: true }]
    })
  )
  .handleAction(excelReportsActions.success, (state, action) => {
    const { reports } = state
    const index = reports.findIndex(({ id }) => id === action.payload)
    if (index < 0) {
      return state
    }

    const copy = [...reports]
    const item = copy[index]
    copy.splice(index, 1, { ...item, loading: false, complete: true })
    return { reports: copy }
  })
  .handleAction(excelReportsActions.failure, (state, action) => {
    const { reports } = state
    const { id, error } = action.payload
    const index = reports.findIndex(({ id: reportId }) => reportId === id)
    if (index < 0) {
      return state
    }

    const copy = [...reports]
    const item = copy[index]
    copy.splice(index, 1, { ...item, loading: false, complete: true, error })
    return { reports: copy }
  })

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.reports.excelReports
export const getAdvisoryReports = flow(rootSelector, ({ reports }) => reports)
export const getLoadingAdvisoryReports = createSelector(
  [getAdvisoryReports],
  (reports) => reports.filter(({ loading }) => loading)
)

const onRequest = function* (
  action: ReturnType<typeof excelReportsActions.request>
) {
  const { name } = action.payload
  const id = uuidv4()
  yield put(excelReportsActions.start({ id, name }))
}

const onStart = function* (
  action: ReturnType<typeof excelReportsActions.start>
) {
  const { id, name } = action.payload
  const report = reports[name]
  try {
    yield* call(report)
    yield put(excelReportsActions.success(id))
  } catch (e: any) {
    yield put(excelReportsActions.failure({ id, error: e }))
  }
}

const onFailure = function* (
  action: ReturnType<typeof excelReportsActions.failure>
) {
  const { error } = action.payload
  yield call(pushNotification, {
    message: `Failed to generate report: ${error?.message}`,
    type: MessageBarType.error
  })
}

export const excelReportsSagas = [
  () => takeLatest(excelReportsActions.request, onRequest),
  () => takeLatest(excelReportsActions.start, onStart),
  () => takeLatest(excelReportsActions.failure, onFailure)
]
