import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useActivityDetailUiState } from '../modules/Activity/activityDetailsUiState'
import {
  financialsApi,
  useGetDetailActivitySummaryQuery
} from '../store/rdot360AnalyticsApi'
import { useRdot360SelectedAccountsApiContext } from '../store/rdot360Context'
import { IActivity } from '../store/types'

export const useActivityDetails = () => {
  const { apiContextAccounts } = useRdot360SelectedAccountsApiContext()
  const { dateRangeString, categories } = useActivityDetailUiState()

  const skip =
    !apiContextAccounts?.length || !dateRangeString || !categories?.length

  const request = useMemo(
    () =>
      skip
        ? skipToken
        : {
            contextAccounts: apiContextAccounts,
            categories: categories,
            dateRange: dateRangeString
          },
    [apiContextAccounts, categories, dateRangeString, skip]
  )
  const { data, isFetching, error, isUninitialized, isError } =
    useGetDetailActivitySummaryQuery(request)

  const activityData = skip || isError ? undefined : data

  const dispatch = useDispatch()
  const updateDescriptionInCache = useCallback(
    (seqNo: bigint | string, newDescription: string) => {
      const activities = activityData?.activities
      if (!activities?.length || request === skipToken) {
        return
      }
      const index = activities?.findIndex(
        ({ SequenceNo }) => SequenceNo === seqNo.toString()
      )

      if (index < 0) {
        return
      }

      const updated = {
        ...activities[index],
        trndesc: newDescription
      } as IActivity

      const updatedActivities = [...activities]
      updatedActivities.splice(index, 1, updated)

      dispatch(
        financialsApi.util.upsertQueryData(
          'getDetailActivitySummary',
          request,
          {
            ...activityData,
            activities: updatedActivities
          }
        ) as any
      )
    },
    [activityData, dispatch, request]
  )

  return {
    activityData,
    isFetching,
    error,
    isUninitialized,
    updateDescriptionInCache
  }
}
