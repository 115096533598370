import { IAccount } from 'api/account.types'
import { IHousehold } from 'api/household.types'
import { chunk } from 'lodash'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { isNotNullOrUndefined } from 'shared/guards'
import { AppState } from 'store'
import { datahubApi } from 'store/api/datahub'
import { arrayCommaParamsSerializer, AxiosBaseArgs } from 'store/api/shared'

const nextGenCouncilHouseholdApi = datahubApi.injectEndpoints({
  endpoints: (builder) => ({
    getNextGenCouncilHouseholds: builder.query<
      IHousehold[] | undefined,
      string[]
    >({
      queryFn: async (householdIds, _api, _extraOptions, baseQuery) => {
        const promises = chunk(householdIds, 50)
          .map(
            (x): AxiosBaseArgs => ({
              url: 'search/households',
              params: {
                $filter: [`search.in(householdId, '${x.join('|')}', '|')`],
                $top: 1000,
                $orderby: 'id desc',
                $count: true,
                select: [
                  'householdKPI',
                  'id',
                  'householdId',
                  'householdName',
                  'revenue'
                ]
              },
              paramsSerializer: arrayCommaParamsSerializer
            })
          )
          .map(baseQuery)

        const results = await Promise.all(promises)
        const errorResponse = results.find((x) => x.error)
        if (errorResponse) {
          return { error: errorResponse.error }
        }

        const accounts = results
          .map((x) => x.data as IOdataResult<IAccount>)
          .map((x) => x.value)
          .filter(isNotNullOrUndefined)
          .flat()

        return { data: accounts }
      }
    })
  })
})

const emptyArray: [] = []
export const { useGetNextGenCouncilHouseholdsQuery } =
  nextGenCouncilHouseholdApi
export const selectNexGenCouncilHouseholds = (
  state: AppState,
  householdIds: string[]
) =>
  nextGenCouncilHouseholdApi.endpoints.getNextGenCouncilHouseholds.select(
    householdIds
  )(state)?.data ?? emptyArray
