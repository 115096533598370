import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useTheme } from '@emotion/react'
import React, { PropsWithChildren } from 'react'
import { Icon } from '../../../features/Icons/Icon'

export const SortableItem: React.FC<
  PropsWithChildren<{ id: string; className?: string }>
> = ({ id, className, children }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id })

  const style = {
    opacity: isDragging ? 0.8 : undefined,
    zIndex: isDragging ? 99 : undefined,
    transform: CSS.Transform.toString(transform),
    transition
  }

  const theme = useTheme()

  return (
    <div ref={setNodeRef} className={className} style={style}>
      <div
        {...attributes}
        {...listeners}
        style={{
          position: 'absolute',
          width: '24px',
          top: '5px',
          left: 'calc(50% - 9px)',
          cursor: 'grab',
          zIndex: 2
        }}
      >
        <Icon
          viewBox="0 0 18 8"
          type="DragHandle"
          width={18}
          height={8}
          color={theme.colors.tertiaryGray2}
          title="Drag and Drop icon"
        />
      </div>
      {children}
    </div>
  )
}
