import { CellContext, HeaderContext } from '@tanstack/react-table'
import { IAnnuityPPLIContract } from 'api/datahub'
import { findKey, uniq } from 'lodash'
import { FormattedNumber } from 'react-intl'
import { isNotNullOrUndefined } from 'shared/guards'
import { AnnuityPPLINameExtended, columnNames } from './columnNames'

export const BlankAggregatedCell: React.FC<
  CellContext<IAnnuityPPLIContract, unknown>
> = ({ getValue, row, column }) => {
  const topLevelGroupings = [
    columnNames.cleanCarrierName,
    columnNames.contractType,
    columnNames.assetClassL2,
    columnNames.productType
  ]

  return (
    (!row.groupingColumnId ||
      !topLevelGroupings.includes(
        row.groupingColumnId as AnnuityPPLINameExtended
      ) ||
      row.groupingColumnId === column.id) &&
    getValue<string>()
  )
}

export const SumUniqValueFooter: React.FC<
  HeaderContext<IAnnuityPPLIContract, unknown>
> = ({ table, column }) => {
  const propName = findKey(
    columnNames,
    (prop) => prop === column.id
  ) as keyof IAnnuityPPLIContract

  const total = uniq(
    table
      .getRowModel()
      .flatRows.map(({ original }) => original[propName])
      .filter(isNotNullOrUndefined)
  )

  return total.length > 0 ? (
    <FormattedNumber
      value={total.length}
      maximumFractionDigits={0}
      minimumFractionDigits={0}
    />
  ) : (
    <>0</>
  )
}
