import { skipToken } from '@reduxjs/toolkit/query'
import { actions as householdListActions } from 'features/Lists/households'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RdotUserRoleEnum } from 'store/user/rdotUser'
import { getRdotUserRoles } from 'store/user/selectors'
import {
  IHouseholdTagName,
  useAddHouseholdTagNameMutation,
  useEditHouseholdTagNameMutation,
  useGetHouseholdTagNamesQuery,
  useGetHouseholdTagsQuery,
  useUpdateHouseholdTagsMutation
} from '../store/editHouseholdTagsApi'
import {
  editHouseholdTagsActions,
  selectHousehold,
  selectIsPanelOpen
} from '../store/editHouseholdTagsSlice'

const isErrorWithMessage = (error: unknown): error is { message: string } => {
  return error instanceof Object && 'message' in error
}

export const useEditHouseholdTags = () => {
  const dispatch = useDispatch()
  const household = useSelector(selectHousehold)
  const isPanelOpen = useSelector(selectIsPanelOpen)
  const userRoles = useSelector(getRdotUserRoles)

  const canEditSystemTags = useMemo(
    () => !!userRoles?.includes(RdotUserRoleEnum.IEX_User),
    [userRoles]
  )

  const [errorMessage, setErrorMessage] = useState<string>()

  const [
    addHouseholdTagName,
    {
      error: addTagNameError,
      isLoading: addTagNameLoading,
      isSuccess: addTagNameSuccess
    }
  ] = useAddHouseholdTagNameMutation()

  const addTagName = useCallback(
    (tagName?: string) => {
      setErrorMessage(undefined)

      if (!tagName) {
        return
      }

      addHouseholdTagName(tagName)
    },
    [addHouseholdTagName]
  )

  useEffect(() => {
    if (isErrorWithMessage(addTagNameError)) {
      setErrorMessage(addTagNameError.message)
    }
  }, [addTagNameError])

  const [
    editHouseholdTagName,
    {
      error: editTagNameError,
      isLoading: editTagNameLoading,
      isSuccess: editTagNameSuccess
    }
  ] = useEditHouseholdTagNameMutation()

  const editTagName = useCallback(
    (tagName: IHouseholdTagName) => {
      setErrorMessage(undefined)

      if (!tagName) {
        return
      }

      editHouseholdTagName(tagName)
    },
    [editHouseholdTagName]
  )

  useEffect(() => {
    if (isErrorWithMessage(editTagNameError)) {
      setErrorMessage(editTagNameError.message)
    }
  }, [editTagNameError])

  const [updateHouseholdTags, { isLoading: updateTagsLoading }] =
    useUpdateHouseholdTagsMutation()

  const updateTags = useCallback(
    async (tagsAdded: number[], tagsRemoved: number[]) => {
      setErrorMessage(undefined)

      if (!household?.id || (!tagsAdded.length && !tagsRemoved.length)) {
        return false
      }

      try {
        const result = await updateHouseholdTags({
          householdId: household.id,
          tagsAdded,
          tagsRemoved
        }).unwrap()
        const errorData = result?.responses?.filter(
          (item) => item.status < 200 || item.status > 299
        )
        if (errorData?.length) {
          throw new Error('Status Code Not Successful')
        }

        return true
      } catch (error) {
        if (error instanceof Error) {
          setErrorMessage(error.message)
        } else {
          setErrorMessage(String(error))
        }
      }

      return false
    },
    [household, updateHouseholdTags]
  )

  const {
    data: tagNames,
    error: tagNamesError,
    isFetching: tagNamesFetching,
    refetch: refetchTagNames
  } = useGetHouseholdTagNamesQuery()

  useEffect(() => {
    if (isErrorWithMessage(tagNamesError)) {
      setErrorMessage(tagNamesError.message)
    }
  }, [tagNamesError])

  const {
    data: tags,
    error: tagsError,
    isFetching: tagsFetching
  } = useGetHouseholdTagsQuery(household?.id ?? skipToken)

  useEffect(() => {
    if (isErrorWithMessage(tagsError)) {
      setErrorMessage(tagsError.message)
    }
  }, [tagsError])

  const closePanel = useCallback(() => {
    dispatch(editHouseholdTagsActions.closePanel())
    setErrorMessage(undefined)
  }, [dispatch])

  const refreshHouseholdList = useCallback(() => {
    dispatch(householdListActions.dataActions.request())
  }, [dispatch])

  return {
    addTagName,
    editTagName,
    closePanel,
    refetchTagNames,
    refreshHouseholdList,
    updateTags,
    addTagNameLoading,
    addTagNameSuccess,
    canEditSystemTags,
    editTagNameLoading,
    editTagNameSuccess,
    errorMessage,
    household,
    isLoading: tagNamesFetching || tagsFetching,
    isPanelOpen,
    tagNames,
    tags,
    updateTagsLoading
  }
}
