import { FC, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { isNotNullOrUndefined } from 'shared/guards'
import {
  HighchartsComponent,
  HighchartsImport,
  highcharts
} from 'shared/highcharts'
import { useInvestmentsTableStore } from '../../modules/Investments/features/InvestmentsTable'
import { useInvestmentsDetailsUIState } from '../../modules/Investments/InvestmentsDetailsUIState'
import { IHoldings } from '../../store/types'

const getHoldingOptions = (
  onClick: (value?: string) => void,
  highcharts?: HighchartsImport,
  data: IHoldings[] = []
) => ({
  chart: {
    type: 'bar'
  },
  title: {
    text: ''
  },
  accessibility: {
    announceNewData: {
      enabled: true
    }
  },
  xAxis: {
    title: {
      text: ''
    },
    categories: data.map((item) => item.symbol)
  },
  yAxis: {
    tickLength: 0,
    gridLineColor: '#e6e6e6',
    title: {
      text: ''
    },
    softMin: 0,
    max: data?.length ? undefined : 10000
  },
  legend: {
    enabled: false
  },
  plotOptions: {
    bar: {
      events: {
        click: (ev: any) => {
          ev?.preventDefault && ev.preventDefault()
          onClick(ev?.point?.category)
        }
      },
      pointWidth: 10,
      cursor: 'pointer'
    },
    series: {
      borderWidth: 0
    }
  },

  tooltip: {
    pointFormat: '$<b>{point.y:,.0f}</b>',
    formatter: function formatTooltip(this: any) {
      if (this.y >= 0) {
        return [
          `${data[this.point.x].SECNAME}`,
          `<b>$${highcharts?.numberFormat(this.y, 0, '.', ',')}</b>`
        ]
          .filter(isNotNullOrUndefined)
          .join('</br>')
      } else {
        return [
          `${data[this.point.x].SECNAME}`,
          `<b>-$${highcharts?.numberFormat(this.y * -1, 0, '.', ',')}</b>`
        ]
          .filter(isNotNullOrUndefined)
          .join('</br>')
      }
    }
  },

  series: [
    {
      type: 'bar',
      name: 'Browsers',
      colorByPoint: false,
      color: {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1
        },
        stops: [
          [0, '#45a5ed'],
          [1, '#859daf']
        ]
      },
      data: data.map((item) => item.recentmktval)
    }
  ],
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 300
        }
      }
    ]
  }
})

const containerProps: React.HTMLAttributes<unknown> = {
  style: { height: '100%', width: '100%' }
}

export const HoldingsChart: FC<{ data: IHoldings[] }> = ({ data = [] }) => {
  const navigate = useNavigate()
  const { setSearchText } = useInvestmentsDetailsUIState()
  const { setInvestmentsViewByKey } = useInvestmentsTableStore()

  const onClick = useCallback(
    (value?: string) => {
      navigate('../investments')
      setInvestmentsViewByKey()
      setSearchText(value)
    },
    [navigate, setInvestmentsViewByKey, setSearchText]
  )
  const holdingOptions = useMemo(
    () => getHoldingOptions(onClick, highcharts, data),
    [data, onClick]
  )
  return (
    <HighchartsComponent
      options={holdingOptions}
      containerProps={containerProps}
    />
  )
}
