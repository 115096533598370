import { createReducer } from 'typesafe-actions'
import {
  ReportActionType,
  generateReportsActions,
  getReportListActions,
  refreshReportActions,
  viewReportActions,
  setActiveReportAction,
  getClientListActions,
  shareReportActions,
  uploadReportActions,
  fetchUploadReportActions,
  viewUploadReportActions,
  deleteUploadReportActions
} from './actions'
import { IClientsInfo, IReportInfo, IUploadReportList } from './types'

export interface IReportsState {
  isReportGenerated: boolean
  loading: boolean
  error?: Error
  reportList: IReportInfo[]
  activeReport?: IReportInfo
  viewReportDetails?: {
    loading: boolean
    error?: Error
    reportString?: string
  }
  clientDetails?: {
    loading: boolean
    error?: Error
    clients?: IClientsInfo[]
  }
  shareReortDetails?: {
    loading: boolean
    error?: Error
    status?: string
  }
  uploadReportDetails?: {
    loading: boolean
    error?: Error
    status?: string
  }
  uploadReportList?: {
    loading: boolean
    error?: Error
    data?: IUploadReportList[]
  }
  viewUploadReport?: {
    inProgress: boolean
    error?: Error
  }
  deleteUploadReport?: {
    inProgress: boolean
    error?: Error
  }
}

const initialState: IReportsState = {
  isReportGenerated: false,
  loading: false,
  reportList: [],
  activeReport: undefined,
  viewReportDetails: {
    loading: false,
    error: undefined,
    reportString: undefined
  },
  clientDetails: undefined,
  shareReortDetails: undefined,
  uploadReportDetails: undefined,
  uploadReportList: undefined
}

export const reportsDashboardReducer = createReducer<
  IReportsState,
  ReportActionType
>(initialState)
  .handleAction(getReportListActions.request, (state) => ({
    ...state,
    loading: true,
    error: undefined,
    reportList: []
  }))
  .handleAction(getReportListActions.success, (state, action) => ({
    ...state,
    loading: false,
    reportList: action.payload,
    error: undefined
  }))
  .handleAction(getReportListActions.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
    reportList: []
  }))
  .handleAction(getClientListActions.request, (state) => ({
    ...state,
    loading: false,
    error: undefined,
    clientDetails: {
      ...state.clientDetails,
      loading: true,
      error: undefined,
      clients: undefined
    }
  }))
  .handleAction(getClientListActions.success, (state, action) => ({
    ...state,
    loading: false,
    error: undefined,
    clientDetails: {
      ...state.clientDetails,
      loading: false,
      error: undefined,
      clients: action.payload
    }
  }))
  .handleAction(getClientListActions.failure, (state, action) => ({
    ...state,
    loading: false,
    error: undefined,
    clientDetails: {
      ...state.clientDetails,
      loading: false,
      error: action.payload
    }
  }))
  .handleAction(generateReportsActions.request, (state, action) => {
    const copyShallowLocal = state?.reportList ? [...state.reportList] : []
    const matchedItemIdx = copyShallowLocal?.findIndex(
      (x) => x.reportKey === action.payload.reportKey
    )
    const newItem: IReportInfo = {
      ...copyShallowLocal[matchedItemIdx],
      loading: true,
      loadingMsg: 'Generating Report',
      error: undefined
    }
    copyShallowLocal.splice(matchedItemIdx, 1, newItem)

    return {
      ...state,
      reportList: copyShallowLocal,
      loading: false,
      error: undefined
    }
  })
  .handleAction(generateReportsActions.success, (state) => ({
    ...state,
    loading: false,
    error: undefined
  }))
  .handleAction(generateReportsActions.failure, (state, action) => {
    const copyShallowLocal = state?.reportList ? [...state.reportList] : []
    const matchedItemIdx = copyShallowLocal?.findIndex(
      (x) => x.reportKey === action.payload.request?.reportKey
    )
    const newItem: IReportInfo = {
      ...copyShallowLocal[matchedItemIdx],
      loading: false,
      loadingMsg: undefined,
      error: action.payload.error
    }
    copyShallowLocal.splice(matchedItemIdx, 1, newItem)
    return {
      ...state,
      loading: false,
      error: undefined,
      reportList: copyShallowLocal
    }
  })
  .handleAction(refreshReportActions.request, (state, action) => {
    const copyShallowLocal = state?.reportList ? [...state.reportList] : []
    const matchedItemIdx = copyShallowLocal?.findIndex(
      (x) => x.reportKey === action.payload.reportKey
    )
    const newItem: IReportInfo = {
      ...copyShallowLocal[matchedItemIdx],
      loading: true,
      loadingMsg: 'Refreshing Status',
      error: undefined
    }
    copyShallowLocal.splice(matchedItemIdx, 1, newItem)
    return {
      ...state,
      reportList: copyShallowLocal,
      loading: false,
      error: undefined
    }
  })
  .handleAction(refreshReportActions.success, (state) => ({
    ...state,
    loading: false,
    error: undefined
  }))
  .handleAction(refreshReportActions.failure, (state, action) => {
    const copyShallowLocal = state?.reportList ? [...state.reportList] : []
    const matchedItemIdx = copyShallowLocal?.findIndex(
      (x) => x.reportKey === action.payload.request?.reportKey
    )
    const newItem: IReportInfo = {
      ...copyShallowLocal[matchedItemIdx],
      loading: false,
      loadingMsg: undefined,
      error: action.payload.error
    }
    copyShallowLocal.splice(matchedItemIdx, 1, newItem)
    return {
      ...state,
      loading: false,
      error: undefined,
      reportList: copyShallowLocal
    }
  })
  .handleAction(viewReportActions.request, (state) => ({
    ...state,
    loading: false,
    error: undefined,
    viewReportDetails: {
      ...state.viewReportDetails,
      loading: true,
      reportString: undefined
    }
  }))
  .handleAction(viewReportActions.success, (state, action) => ({
    ...state,
    loading: false,
    error: undefined,
    viewReportDetails: {
      ...state.viewReportDetails,
      loading: false,
      reportString: action.payload
    }
  }))
  .handleAction(viewReportActions.failure, (state, action) => ({
    ...state,
    loading: false,
    error: undefined,

    viewReportDetails: {
      ...state.viewReportDetails,
      loading: false,
      error: action.payload
    }
  }))
  .handleAction(shareReportActions.request, (state) => ({
    ...state,
    loading: false,
    error: undefined,
    shareReortDetails: {
      ...state.viewReportDetails,
      loading: true,
      status: undefined
    }
  }))
  .handleAction(shareReportActions.success, (state, action) => ({
    ...state,
    loading: false,
    error: undefined,
    shareReortDetails: {
      ...state.viewReportDetails,
      loading: false,
      status: action.payload.status
    }
  }))
  .handleAction(shareReportActions.failure, (state, action) => ({
    ...state,
    loading: false,
    error: undefined,

    shareReortDetails: {
      ...state.shareReortDetails,
      loading: false,
      error: action.payload
    }
  }))
  .handleAction(setActiveReportAction.setActiveReport, (state, action) => ({
    ...state,
    activeReport: action.payload
  }))
  .handleAction(uploadReportActions.request, (state) => ({
    ...state,
    uploadReportDetails: {
      ...state.uploadReportDetails,
      loading: true,
      status: undefined
    }
  }))
  .handleAction(uploadReportActions.success, (state, action) => ({
    ...state,
    uploadReportDetails: {
      ...state.uploadReportDetails,
      loading: false,
      status: action.payload.status
    }
  }))
  .handleAction(uploadReportActions.failure, (state, action) => ({
    ...state,
    uploadReportDetails: {
      ...state.uploadReportDetails,
      loading: false,
      error: action.payload
    }
  }))
  .handleAction(uploadReportActions.clear, (state) => ({
    ...state,
    uploadReportDetails: undefined
  }))
  .handleAction(fetchUploadReportActions.request, (state) => ({
    ...state,
    uploadReportList: { loading: true, error: undefined }
  }))
  .handleAction(fetchUploadReportActions.success, (state, action) => ({
    ...state,
    uploadReportList: { loading: false, error: undefined, data: action.payload }
  }))
  .handleAction(fetchUploadReportActions.failure, (state, action) => ({
    ...state,
    uploadReportList: { loading: false, error: action.payload }
  }))
  .handleAction(viewUploadReportActions.request, (state) => ({
    ...state,
    viewUploadReport: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(viewUploadReportActions.success, (state) => ({
    ...state,
    viewUploadReport: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(viewUploadReportActions.failure, (state, action) => ({
    ...state,
    viewUploadReport: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(viewUploadReportActions.clear, (state) => ({
    ...state,
    viewUploadReport: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(deleteUploadReportActions.request, (state) => ({
    ...state,
    deleteUploadReport: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(deleteUploadReportActions.success, (state) => ({
    ...state,
    deleteUploadReport: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(deleteUploadReportActions.failure, (state, action) => ({
    ...state,
    deleteUploadReport: {
      inProgress: false,
      error: action.payload
    }
  }))
