import axios from 'axios'
import { createSelector } from 'reselect'
import { call, cancelled, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { getNfsProfiles, INfsProfile } from '../../../api/datahub'
import { AppState } from '../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../../store/shared/sagas'

export interface emailValidationResponse {
  loginid: boolean
  emailprimary: boolean
  accounts: INfsProfile[]
}

export const emailValidationFetchActions = createAsyncAction(
  '@features/@accountLinking/@emailValidation/REQUEST',
  '@features/@accountLinking/@emailValidation/SUCCESS',
  '@features/@accountLinking/@emailValidation/FAILURE'
)<string | undefined, emailValidationResponse | undefined, Error>()

export const emailValidationFetchReducer = createAsyncReducer(
  emailValidationFetchActions
)

const rootSelector = (state: AppState) =>
  state.features.accountLinking.emailValidation

export const {
  getError: getEmailValidationFetchError,
  getIsLoading: getEmailValidationFetchLoading,
  getResult: getEmailValidationFetchResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof emailValidationFetchActions.request>
) {
  if (!action.payload || !action.payload.length) {
    yield put(emailValidationFetchActions.success(undefined))
    return
  }
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)
  const lowercaseEmail = action.payload?.toLowerCase()
  try {
    const result = yield* call(getNfsProfiles, apiOptions, {
      filters: [
        `loginid eq '${lowercaseEmail}' or emailprimary eq '${lowercaseEmail}'`
      ],
      select: [
        'fullname',
        'loginid',
        'emailprimary',
        'role',
        'wsportaluserid',
        'id',
        'profilejson',
        'repcode'
      ]
    })
    if (!result || !result.value) {
      throw new Error(
        'An error occurred while fetching the profile information'
      )
    }
    const loginid = result.value.some(
      (x) => x.loginid?.toLowerCase() === lowercaseEmail
    )
    const emailprimary = result.value.some(
      (x) => x.emailprimary?.toLowerCase() === lowercaseEmail
    )
    yield put(
      emailValidationFetchActions.success({
        loginid: loginid,
        emailprimary: emailprimary,
        accounts: result.value
      })
    )
  } catch (e: any) {
    yield put(emailValidationFetchActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const isInvalidEmail = createSelector(
  [getEmailValidationFetchResult],
  (validations) => {
    if (validations?.loginid || !validations) {
      return true
    }
    return false
  }
)

export const showInvalidEmailMessage = createSelector(
  [getEmailValidationFetchResult],
  (validations) => {
    if (validations?.loginid) {
      return true
    }
    return false
  }
)

export const showValidEmailMessage = createSelector(
  [getEmailValidationFetchResult],
  (validations) => {
    if (validations && !validations?.loginid) {
      return true
    }
    return false
  }
)

export const getPrimaryEmailWarning = createSelector(
  [getEmailValidationFetchResult],
  (validations) => {
    if (!validations?.loginid && validations?.emailprimary) {
      return true
    }
    return false
  }
)

export const emailValidationFetchSagas = [
  () => takeLatest(emailValidationFetchActions.request, onRequest)
]
