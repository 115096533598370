import { Callout, DirectionalHint, Icon, Stack } from '@fluentui/react'
import { useId } from '@fluentui/react-hooks'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { IHurdleStatus } from '../../api/datahub'
import { chartColors } from '../../shared/services/theme'
import { updateStatusActions } from './updateStatus'

export const HurdleStatusIndicator: React.FC<{
  disableEdit: boolean
  id?: number
  status?: IHurdleStatus
  style?: React.CSSProperties | undefined
}> = ({ disableEdit, id, status, style }) => {
  const dispatch = useDispatch()
  const [isCalloutVisible, setIsCalloutVisible] = useState(false)
  const indicatorId = useId('indicatorId')
  const updateStatus = useCallback(
    (status: IHurdleStatus) => {
      if (!id) {
        return
      }
      dispatch(updateStatusActions.request({ id, status }))
    },
    [dispatch, id]
  )
  const icon = useMemo(() => {
    if (status === 'Achieved') {
      return 'SkypeCircleCheck'
    } else if (status === 'Missed') {
      return 'SkypeCircleMinus'
    } else if (status === 'Not Applicable') {
      return 'StatusCircleBlock'
    } else {
      return 'SkypeCircleClock'
    }
  }, [status])

  const color = useMemo(() => {
    if (status === 'Missed') {
      return `${chartColors[4]}55`
    } else if (status === 'Achieved') {
      return `${chartColors[3]}aa`
    } else if (status === 'Not Applicable') {
      return '#808080'
    } else {
      return `${chartColors[9]}55`
    }
  }, [status])

  return (
    <>
      <div
        onClick={() => {
          if (!disableEdit) {
            setIsCalloutVisible(!isCalloutVisible)
          }
        }}
        style={style ? style : { fontSize: 15, textAlign: 'center' }}
      >
        <Icon
          title={status || 'Pending'}
          id={indicatorId}
          iconName={icon}
          style={{
            color,
            cursor: !disableEdit ? 'pointer' : 'default'
          }}
        />
      </div>
      {isCalloutVisible && (
        <Callout
          target={`#${indicatorId}`}
          onDismiss={() => setIsCalloutVisible(false)}
          setInitialFocus
          directionalHint={DirectionalHint.rightCenter}
        >
          <Stack horizontal={true}>
            <Icon
              title="Achieved"
              iconName="SkypeCircleCheck"
              style={{
                padding: '10px',
                color: `${chartColors[3]}aa`,
                cursor: 'pointer'
              }}
              onClick={() => {
                updateStatus('Achieved')
                setIsCalloutVisible(false)
              }}
            />
            <Icon
              title="Pending"
              iconName="SkypeCircleClock"
              style={{
                padding: '10px',
                color: `${chartColors[9]}55`,
                cursor: 'pointer'
              }}
              onClick={() => {
                updateStatus('Pending')
                setIsCalloutVisible(false)
              }}
            />
            <Icon
              title="Missed"
              iconName="SkypeCircleMinus"
              style={{
                padding: '10px',
                color: `${chartColors[4]}55`,
                cursor: 'pointer'
              }}
              onClick={() => {
                updateStatus('Missed')
                setIsCalloutVisible(false)
              }}
            />
            <Icon
              title="Not Applicable"
              iconName="StatusCircleBlock"
              style={{
                padding: '10px',
                color: `#808080`,
                cursor: 'pointer'
              }}
              onClick={() => {
                updateStatus('Not Applicable')
                setIsCalloutVisible(false)
              }}
            />
          </Stack>
        </Callout>
      )}
    </>
  )
}
