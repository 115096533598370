import { createReducer } from 'typesafe-actions'
import { IDurationOptions } from '../shared/DateSelector'
import {
  ChecksActionType,
  checkReprintScreenActions,
  checksDataActions,
  dateSelectorActions
} from './actions'
import { ICheck } from './types'

export interface IChecksState {
  checks: {
    checkList?: ICheck[]
    isLoading: boolean
    error?: Error
  }
  dateSelection?: IDurationOptions
  startDate?: string
  endDate?: string
  customRange?: string
  dateRangeString?: string
  checkActionStatus: {
    isLoading: boolean
    error?: Error
    fileContent?: string
  }
  selectedActionType?: string
}

const initialState: IChecksState = {
  checks: {
    isLoading: true
  },
  dateSelection: 'Last 10 Days',
  checkActionStatus: {
    isLoading: true
  }
}

export const checksReducer = createReducer<IChecksState, ChecksActionType>(
  initialState
)
  .handleAction(checksDataActions.request, (state) => ({
    ...state,
    checks: {
      isLoading: true,
      error: undefined,
      checkList: undefined
    }
  }))
  .handleAction(checksDataActions.success, (state, action) => ({
    ...state,
    checks: {
      isLoading: false,
      error: undefined,
      checkList: action.payload
    }
  }))
  .handleAction(checksDataActions.failure, (state, action) => ({
    ...state,
    checks: {
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(dateSelectorActions.setStartDate, (state, action) => ({
    ...state,
    startDate: action.payload
  }))
  .handleAction(dateSelectorActions.setCustomRange, (state, action) => ({
    ...state,
    customRange: action.payload
  }))
  .handleAction(dateSelectorActions.setEndDate, (state, action) => ({
    ...state,
    endDate: action.payload
  }))
  .handleAction(dateSelectorActions.setDateSelection, (state, action) => ({
    ...state,
    dateSelection: action.payload
  }))
  .handleAction(checkReprintScreenActions.request, (state) => ({
    ...state,
    checkActionStatus: {
      isLoading: false,
      error: undefined,
      fileContent: undefined
    }
  }))
  .handleAction(checkReprintScreenActions.success, (state, action) => ({
    ...state,
    checkActionStatus: {
      isLoading: true,
      error: undefined,
      fileContent: action.payload.fileContent
    }
  }))
  .handleAction(checkReprintScreenActions.failure, (state, action) => ({
    ...state,
    checkActionStatus: {
      isLoading: false,
      error: action.payload,
      fileContent: undefined
    }
  }))
  .handleAction(
    checkReprintScreenActions.setSelectedActionType,
    (state, action) => ({
      ...state,
      selectedActionType: action.payload
    })
  )
