import {
  Checkbox,
  DefaultButton,
  Panel,
  PrimaryButton,
  Stack
} from '@fluentui/react'
import { IAnnuityPPLIContract, IAnnuityPPLIAssets } from 'api/datahub'
import { IOdataListUiActions } from 'features/OdataList/common/IOdataListUiActions'
import { IOdataListUiSelectors } from 'features/OdataList/common/IOdataListUiSelectors'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store/shared/contracts'

interface IColumnEditPanel<T = unknown, U = unknown> {
  isPanelOpen: boolean
  closePanel: React.Dispatch<React.SetStateAction<boolean>>
  actions: IOdataListUiActions
  selectors: IOdataListUiSelectors<T, U>
}

export const ColumnEditPanel: React.FC<
  IColumnEditPanel<IAnnuityPPLIContract | IAnnuityPPLIAssets, AppState>
> = ({ isPanelOpen, closePanel, actions, selectors }) => {
  const dispatch = useDispatch()

  const collumns = useSelector(selectors.getColumns) || []

  const defaultColumnsIds = collumns?.map((x) => x.name)

  const initialColumnIds = useSelector(selectors.getSelectedColumns)

  const [selectedColumnIds, setSelectedColumnIds] = useState(
    initialColumnIds || []
  )

  const stackTokens = { childrenGap: 10 }

  const selectAllColumns = useCallback(
    (checked: boolean) =>
      setSelectedColumnIds(checked ? defaultColumnsIds : []),
    [defaultColumnsIds]
  )

  const isIndeterminate =
    !!selectedColumnIds?.length &&
    selectedColumnIds.length < defaultColumnsIds.length

  const onDismiss = useCallback(() => closePanel(false), [closePanel])

  const handleCheckboxChange = useCallback(
    (checked: boolean, colName: string) => {
      const selected = [...selectedColumnIds]
      const updated = checked
        ? [...selected, colName]
        : (() => {
            selected.splice(selected.indexOf(colName), 1)
            return selected
          })()
      setSelectedColumnIds(updated)
    },
    [selectedColumnIds]
  )

  const onApply = useCallback(() => {
    closePanel(false)

    dispatch(actions.updateSelectedColumns(selectedColumnIds))
  }, [closePanel, dispatch, actions, selectedColumnIds])

  const onRenderFooterContent = useCallback(() => {
    return (
      <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
        <PrimaryButton onClick={onApply}>Apply</PrimaryButton>
        <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
      </Stack>
    )
  }, [onApply, onDismiss])

  return (
    <>
      <Panel
        headerText={'Edit Columns'}
        isOpen={isPanelOpen}
        onDismiss={onDismiss}
        isLightDismiss={true}
        closeButtonAriaLabel="Close"
        onRenderFooterContent={onRenderFooterContent}
      >
        <Stack tokens={stackTokens} style={{ marginBottom: '20px' }}>
          <Checkbox
            label="Select/Unselect All"
            onChange={(_ev, checked) => selectAllColumns(checked || false)}
            indeterminate={isIndeterminate}
          />
        </Stack>
        <Stack tokens={stackTokens}>
          {defaultColumnsIds.map((colId, index) => (
            <Checkbox
              key={index}
              checked={selectedColumnIds.includes(colId)}
              label={colId}
              onChange={(_ev, checked) =>
                handleCheckboxChange(checked || false, colId)
              }
            />
          ))}
        </Stack>
      </Panel>
    </>
  )
}
