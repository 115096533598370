import { lazy } from 'react'
import { IModuleDefinition } from '../../../types'
import { dashboardReducer } from './store/reducers'
import { dashboardSagas } from './store/sagas'

const DashboardContainer = lazy(() => import('./containers/Dashboard'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/dashboard',
    private: true,
    component: DashboardContainer
  },
  name: 'Dashboard'
}

export default moduleDefinition
export { dashboardReducer, dashboardSagas }
