import { MessageBarType } from '@fluentui/react'
import { call, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { deleteHurdle } from '../../../../../../../api/datahub'
import { pushNotification } from '../../../../../../../features/Notifications'
import { AppState } from '../../../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../../../../../../store/shared/sagas'
import { hurdleDefinitionListUpdateActions } from '../features/HurdleDefinitionList/store'

export const hurdleDeleteActions = createAsyncAction(
  '@modules/@teams/@hurdleDelete/REQUEST',
  '@modules/@teams/@hurdleDelete/SUCCESS',
  '@modules/@teams/@hurdleDelete/FAILURE'
)<number | undefined, number | undefined, Error>()

export const hurdleDeleteReducer = createAsyncReducer(hurdleDeleteActions)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.teams.modules.hurdles.hurdleDelete

export const {
  getError: getHurdleDeleteError,
  getIsLoading: getHurdleDeleteLoading,
  getResult: getHurdleDeleteResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof hurdleDeleteActions.request>
) {
  try {
    if (!action.payload) {
      yield put(hurdleDeleteActions.failure(new Error('No ID found to delete')))
      return
    }
    const apiOptions = yield* call(getRockefellerApiOptions)
    yield* call(deleteHurdle, apiOptions, action.payload)

    yield put(hurdleDeleteActions.success(action.payload))
  } catch (e: any) {
    yield put(hurdleDeleteActions.failure(e))
  }
}

const onSuccess = function* (
  action: ReturnType<typeof hurdleDeleteActions.success>
) {
  try {
    if (!action.payload) {
      return
    }
    yield put(hurdleDefinitionListUpdateActions.delete(action.payload))
  } catch (e: any) {
    yield put(hurdleDeleteActions.failure(e))
  }
}

const onFailure = function* (
  action: ReturnType<typeof hurdleDeleteActions.failure>
) {
  yield call(pushNotification, {
    message: `Delete failed: ${action.payload.message}`,
    type: MessageBarType.error
  })
}

export const hurdleDeleteSagas = [
  () => takeLatest(hurdleDeleteActions.request, onRequest),
  () => takeLatest(hurdleDeleteActions.success, onSuccess),
  () => takeLatest(hurdleDeleteActions.failure, onFailure)
]
