import { ActionType, createAction } from 'typesafe-actions'
import {
  IScheduleReportRequest,
  IScheduleReport,
  relatedPartiesRequest,
  IRelatedParty,
  IScheduleReportResponse
} from './types'

export const GET_SCHEDULES_LIST = '@modules/@reports/GET_SCHEDULES_LIST'
export const GET_SCHEDULE_LIST_SUCCESS =
  '@modules/@reports/GET_SCHEDULE_LIST_SUCCESS'
export const GET_SCHEDULE_LIST_FAILURE =
  '@modules/@reports/GET_SCHEDULE_LIST_FAILURE'

export const getScheduleListActions = {
  request: createAction(GET_SCHEDULES_LIST)<IScheduleReportRequest>(),
  success: createAction(GET_SCHEDULE_LIST_SUCCESS)<IScheduleReport[]>(),
  failure: createAction(GET_SCHEDULE_LIST_FAILURE)<Error>()
}
export const FETCH_RELATED_PARTIES_INFO =
  '@modules/@payments/FETCH_RELATED_PARTIES_INFO'
export const FETCH_RELATED_PARTIES_SUCCESS =
  '@modules/@payments/FETCH_RELATED_PARTIES_SUCCESS'
export const FETCH_RELATED_PARTIES_FAILURE =
  '@modules/@payments/FETCH_RELATED_PARTIES_FAILURE'

export const relatedPartiesDetailsActionTypes = {
  request: createAction(FETCH_RELATED_PARTIES_INFO)<relatedPartiesRequest>(),
  success: createAction(FETCH_RELATED_PARTIES_SUCCESS)<IRelatedParty[]>(),
  failure: createAction(FETCH_RELATED_PARTIES_FAILURE)<Error>()
}

export const CREATE_UPDATE_SCHEDULE =
  '@modules/@payments/CREATE_UPDATE_SCHEDULE'
export const CREATE_UPDATE_SCHEDULE_SUCCESS =
  '@modules/@payments/CREATE_UPDATE_SCHEDULE_SUCCESS'
export const CREATE_UPDATE_SCHEDULE_FAILURE =
  '@modules/@payments/CREATE_UPDATE_SCHEDULE_FAILURE'

export const createUpdateScheduleActionTypes = {
  request: createAction(CREATE_UPDATE_SCHEDULE)<IScheduleReport>(),
  success: createAction(
    CREATE_UPDATE_SCHEDULE_SUCCESS
  )<IScheduleReportResponse>(),
  failure: createAction(CREATE_UPDATE_SCHEDULE_FAILURE)<Error>()
}

export type GetScheduleReportListTypes = ActionType<
  typeof getScheduleListActions
>
export type RelatedPartiesDataActionTypes = ActionType<
  typeof relatedPartiesDetailsActionTypes
>

export type CreateUpdateScheduleActionTypes = ActionType<
  typeof createUpdateScheduleActionTypes
>
export type ScheduleReportActionType =
  | GetScheduleReportListTypes
  | RelatedPartiesDataActionTypes
  | CreateUpdateScheduleActionTypes
