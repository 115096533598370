import { Text } from '@fluentui/react'
import { CellContext, ColumnDef, HeaderContext } from '@tanstack/react-table'
import { IPosition } from 'api/position.types'
import { DateCell } from 'features/Lists/core/components/DateCell'
import { NumberCell } from 'features/Lists/core/components/NumberCell'
import { PercentCell } from 'features/Lists/core/components/PercentCell'
import { USDCell } from 'features/Lists/core/components/USDCell'
import { sum } from 'lodash'
import { FC } from 'react'
import { ConnectedMaskedText } from 'shared/components/MaskedText'

export enum AssetClassEnum {
  privateInvestments = 'Private Investments',
  hedgeFunds = 'Hedge Funds'
}

const AccountCell: FC<CellContext<IPosition, unknown>> = ({ getValue }) => (
  <ConnectedMaskedText nowrap text={getValue<string>()} variant="small" />
)

const AdjustedTVPICell: FC<CellContext<IPosition, unknown>> = ({
  getValue
}) => {
  const value = getValue<number>()
  const decimals = value === 0 ? 0 : 2

  return (
    <>
      <NumberCell fractionDigits={decimals} size="small" value={value} />
      {value != null && value !== 0 && <Text variant="small">{'x'}</Text>}
    </>
  )
}

const PercentCellWrapper: FC<CellContext<IPosition, unknown>> = ({
  getValue
}) => <PercentCell size="small" value={getValue<number>()} />

const USDCellWrapper: FC<CellContext<IPosition, unknown>> = ({ getValue }) => (
  <USDCell fractionDigits={0} size="small" value={getValue<number>()} />
)

const DateCellWrapper: FC<CellContext<IPosition, unknown>> = ({ getValue }) => (
  <DateCell size="small" value={getValue<string>()} />
)

const SumUSDFooter: FC<HeaderContext<IPosition, unknown>> = ({
  table,
  column
}) => {
  const total = sum(
    table
      .getPreExpandedRowModel()
      .rows.map(({ getValue }) => getValue<number>(column.id))
  )

  return <USDCell fractionDigits={0} size="small" value={total} />
}

const BlankFooter = () => <Text variant="small">--</Text>

export const positionSelectFields: (keyof IPosition)[] = [
  // Common fields
  'accountKey',
  'DrawdownNonDrawdown',
  'accountnumber',
  'ENTRY_DATE',
  'LastValuationValue',

  // Non Drawdown fields
  'OriginalOrderDate',
  'OriginalOrderAmount',
  'RemainingOriginalAmount',
  'TotalIncome',
  'TotalReturnPercentage',

  // Drawdown fields
  'capitalCommitment',
  'CapitalCall',
  'Unfunded_Value',
  'Distributions',
  'AdjustedMarketValue',
  'AdjustedTotalValue',
  'AdjustedTVPI'
]

const nonDrawdownColumnDefinitions: ColumnDef<IPosition>[] = [
  {
    header: 'Account Number',
    accessorFn: ({ accountnumber }) => accountnumber,
    cell: AccountCell,
    footer: BlankFooter
  },
  {
    header: 'Original Order Date',
    accessorFn: ({ OriginalOrderDate }) => OriginalOrderDate,
    cell: DateCellWrapper,
    footer: BlankFooter
  },
  {
    header: 'Total Subscription',
    accessorFn: ({ OriginalOrderAmount }) => OriginalOrderAmount,
    cell: USDCellWrapper,
    footer: SumUSDFooter
  },
  {
    header: 'Net Investment Amount',
    accessorFn: ({ RemainingOriginalAmount }) => RemainingOriginalAmount,
    cell: USDCellWrapper,
    footer: SumUSDFooter
  },
  {
    header: 'Last Valuation Value',
    accessorFn: ({ LastValuationValue }) => LastValuationValue,
    cell: USDCellWrapper,
    footer: SumUSDFooter
  },
  {
    header: 'Valuation Date',
    accessorFn: ({ ENTRY_DATE }) => ENTRY_DATE,
    cell: DateCellWrapper,
    footer: BlankFooter
  },
  {
    header: 'Total Income',
    accessorFn: ({ TotalIncome }) => TotalIncome,
    cell: USDCellWrapper,
    footer: SumUSDFooter
  },
  {
    header: 'Cumulative Return (%)',
    accessorFn: ({ TotalReturnPercentage }) => TotalReturnPercentage,
    cell: PercentCellWrapper,
    footer: BlankFooter
  }
]

const drawdownColumnDefinitions: ColumnDef<IPosition>[] = [
  {
    header: 'Account Number',
    accessorFn: ({ accountnumber }) => accountnumber,
    cell: AccountCell,
    footer: BlankFooter
  },
  {
    header: 'Committed Capital',
    accessorFn: ({ capitalCommitment }) => capitalCommitment,
    cell: USDCellWrapper,
    footer: SumUSDFooter
  },
  {
    header: 'Capital Called',
    accessorFn: ({ CapitalCall }) => CapitalCall,
    cell: USDCellWrapper,
    footer: SumUSDFooter
  },
  {
    header: 'Remaining Capital',
    accessorFn: ({ Unfunded_Value }) => Unfunded_Value,
    cell: USDCellWrapper,
    footer: SumUSDFooter
  },
  {
    header: 'Distribution to Date',
    accessorFn: ({ Distributions }) => Distributions,
    cell: USDCellWrapper,
    footer: SumUSDFooter
  },
  {
    header: 'Last Valuation Value',
    accessorFn: ({ LastValuationValue }) => LastValuationValue,
    cell: USDCellWrapper,
    footer: SumUSDFooter
  },
  {
    header: 'Valuation Date',
    accessorFn: ({ ENTRY_DATE }) => ENTRY_DATE,
    cell: DateCellWrapper,
    footer: BlankFooter
  },
  {
    header: 'Adjusted Market Value',
    accessorFn: ({ AdjustedMarketValue }) => AdjustedMarketValue,
    cell: USDCellWrapper,
    footer: SumUSDFooter
  },
  {
    header: 'Adjusted Total Value',
    accessorFn: ({ AdjustedTotalValue }) => AdjustedTotalValue,
    cell: USDCellWrapper,
    footer: SumUSDFooter
  },
  {
    header: 'Adjusted TVPI',
    accessorFn: ({ AdjustedTVPI }) => AdjustedTVPI,
    cell: AdjustedTVPICell,
    footer: BlankFooter
  }
]

export const getColumnDefinitions = (isDrawdown?: boolean) =>
  isDrawdown
    ? {
        columnDefinitions: drawdownColumnDefinitions
      }
    : {
        columnDefinitions: nonDrawdownColumnDefinitions
      }
