import { addMonths, differenceInCalendarMonths, format } from 'date-fns'
import { createSelector } from 'reselect'
import { getRevenueDashboardDateRangeDates } from '../../../store/dashboard'

export interface IRevenueTableItem {
  category: string
  total: number
  monthlyTotals: number[]
  parentCategory?: string
  heading?: boolean
}

export const getMonthHeadings = createSelector(
  [getRevenueDashboardDateRangeDates],
  (dateRangeDates) => {
    const [startDateUtc, endDateUTC] = dateRangeDates || []

    if (!startDateUtc) {
      return []
    }

    const startDateTz = new Date(
      startDateUtc.getUTCFullYear(),
      startDateUtc.getUTCMonth(),
      startDateUtc.getUTCDate()
    )

    const monthRange = endDateUTC
      ? differenceInCalendarMonths(endDateUTC, startDateUtc) + 1
      : 12
    const monthHeadings: { key: string; value: string }[] = []
    for (let i = 0; i < monthRange; i++) {
      const currentMonth = addMonths(startDateTz, i)
      const formattedKey = format(currentMonth, 'yyyyMM')
      const formattedValue = format(currentMonth, "MMM ''yy")
      monthHeadings.push({ key: formattedKey, value: formattedValue })
    }
    return monthHeadings
  }
)

export const convertToDate = (input: string): string => {
  const year = input.substring(0, 4)
  const month = input.substring(4, 6)

  // Determine the last day of the month
  let lastDay: string
  switch (month) {
    case '01': // January
    case '03': // March
    case '05': // May
    case '07': // July
    case '08': // August
    case '10': // October
    case '12': // December
      lastDay = '31'
      break
    case '04': // April
    case '06': // June
    case '09': // September
    case '11': // November
      lastDay = '30'
      break
    case '02': // February
      // eslint-disable-next-line no-case-declarations
      const yearNumber = parseInt(year, 10)
      lastDay =
        yearNumber % 4 === 0 &&
        (yearNumber % 100 !== 0 || yearNumber % 400 === 0)
          ? '29'
          : '28'
      break
    default:
      throw new Error('Invalid month')
  }

  return `${year}-${month}-${lastDay}`
}
