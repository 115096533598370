import { AssetClassEnum } from './ColumnDefinitions'
import { NavigateToAIDashboard } from './NavigateToAIDashboard'

export const AssetClassCell: React.FC<{
  assetClass?: string
  subRowsLength?: number
  isPrint?: boolean
}> = ({ assetClass, subRowsLength, isPrint }) => {
  const isLink =
    assetClass &&
    Object.values(AssetClassEnum).includes(assetClass as AssetClassEnum)
  const numberOfSubRows =
    subRowsLength != null && !isNaN(subRowsLength)
      ? ` (${subRowsLength})`
      : null
  const assetClassWithSubRowsCount = `${assetClass}${numberOfSubRows}`

  if (isPrint || !isLink) {
    return <span>{assetClassWithSubRowsCount}</span>
  }

  return <NavigateToAIDashboard linkText={assetClassWithSubRowsCount} />
}
