import { call, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'

import {
  getDashboardHurdleSummary,
  IDashboardHurdleSummaryRequest,
  IDashboardHurdleSummaryResponse
} from '../../../../../../../../../api/datahub'
import { AppState } from '../../../../../../../../../store'
import {
  createAsyncHook,
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../../../../../../../../store/shared/sagas'

export const hurdleSummaryFetchActions = createAsyncAction(
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@hurdleSummary/REQUEST',
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@hurdleSummary/SUCCESS',
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@hurdleSummary/FAILURE'
)<
  IDashboardHurdleSummaryRequest | undefined,
  IDashboardHurdleSummaryResponse | undefined,
  Error
>()

export const hurdleSummaryFetchReducer = createAsyncReducer(
  hurdleSummaryFetchActions
)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.revenue.modules.dashboard.features.revenueTiles
    .hurdleSummaryFetch

const selectors = createAsyncSelectors(rootSelector)
export const useHurdleSummaryFetch = createAsyncHook(
  hurdleSummaryFetchActions.request,
  selectors
)

const onRequest = function* (
  action: ReturnType<typeof hurdleSummaryFetchActions.request>
) {
  try {
    const apiOptions = yield* call(getRockefellerApiOptions)
    const result = yield* call(
      getDashboardHurdleSummary,
      apiOptions,
      action.payload || {}
    )
    if (!result) {
      throw new Error('No result returned from datahub API')
    }
    yield put(hurdleSummaryFetchActions.success(result))
  } catch (e: any) {
    yield put(hurdleSummaryFetchActions.failure(e))
  }
}

export const hurdleSummaryFetchSagas = [
  () => takeLatest(hurdleSummaryFetchActions.request, onRequest)
]
