import { IModuleDefinition } from 'modules/types'
import { PerformanceContainer } from '../../Performance/PerformanceContainer'

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/performance/*',
    private: true,
    component: PerformanceContainer
  },
  name: 'Performance'
}

export default moduleDefinition
