import { Header, flexRender } from '@tanstack/react-table'
import { PerformanceData } from 'api/performance.types'
import { rdot360TableStyles } from 'modules/Advisory/modules/Rdot360/shared/tableStyles'
import { cellStyles } from './styles'

const PerformanceTableHeaderRow: React.FC<{
  headers: Header<PerformanceData, unknown>[]
}> = ({ headers }) => {
  return (
    <tr css={[rdot360TableStyles.headerRow]}>
      {headers.map((header) => {
        return (
          <th
            key={header.id}
            css={[cellStyles[header.column.id]]}
            onClick={header.column.getToggleSortingHandler()}
          >
            <div css={{ cursor: 'pointer' }}>
              {flexRender(header.column.columnDef.header, header.getContext())}
            </div>
          </th>
        )
      })}
    </tr>
  )
}

export default PerformanceTableHeaderRow
