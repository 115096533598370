import { usePreferencesContainer } from 'store/preferences'
import { ThemeType } from 'store/ui/types'
import { userSettingsContainerId } from 'store/user/selectors'

export interface IUserSettings {
  appsOrder?: string[]
  enableDataMasking?: boolean
  enablePreviewFeatures?: boolean
  navigateInNewWindow?: boolean
  themeType?: ThemeType
}

export const useUserSettings = () => {
  const {
    isFetchingComplete: isUserSettingsLoaded,
    isError: isUserSettingsError,
    preferences: {
      themeType,
      enableDataMasking,
      enablePreviewFeatures,
      navigateInNewWindow,
      appsOrder
    } = {},
    setPreferences: setUserPreferences
  } = usePreferencesContainer<IUserSettings>(userSettingsContainerId)

  return {
    setUserPreferences,
    themeType,
    enableDataMasking,
    enablePreviewFeatures,
    navigateInNewWindow,
    isUserSettingsLoaded,
    isUserSettingsError,
    appsOrder
  }
}
