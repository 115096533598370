import { combineReducers } from 'redux'
import { IModuleDefinition } from '../../../../types'
import AccountLinkingRequests, {
  accountLinkingRequestsModuleReducer,
  accountLinkingRequestsModuleSagas
} from './AccountLinkingRequests'

export default [AccountLinkingRequests] as IModuleDefinition[]

export const clientsModulesReducer = combineReducers({
  accountLinkingRequests: accountLinkingRequestsModuleReducer
})

export const clientsModulesSagas = [...accountLinkingRequestsModuleSagas]
