import { base64toBlob } from 'modules/Reports/features/Dashboard/Utilities'
import { DragDropFileStatus } from 'shared/components/DragDrop'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { saveBlobAsFile } from 'shared/downloads'
import { getRockefellerApiOptions } from 'store/shared'
import { call, put, takeLatest } from 'typed-redux-saga'
import {
  DownloadManifestFileService,
  DownloadManifestStatementService,
  fetchManifestListService,
  fetchStatementDetailsService,
  UploadManifestFileSevice
} from '../api/ManifestsServices'
import {
  downloadManifestFileActions,
  downloadManifestStatementActions,
  fetchManifestsActions,
  fetchStatementDetailActions,
  uploadManifestFileActions
} from './actions'

function* hanleFetchManifests() {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data = yield* call(() => fetchManifestListService(options))
    if (data) {
      yield put(fetchManifestsActions.success(data))
    } else {
      yield put(
        fetchManifestsActions.failure(new Error('Unable to fetch Manifests'))
      )
    }
  } catch (err: any) {
    yield put(fetchManifestsActions.failure(err))
  }
}
function* handleManifestFileUpload(
  action: ReturnType<typeof uploadManifestFileActions.request>
) {
  const options = yield* call(getRockefellerApiOptions)
  const { onUploadSuccess, onUploadFail, onUploadProgress, file, asOfDate } =
    action.payload
  try {
    const data = yield* call(() =>
      UploadManifestFileSevice(options, file, asOfDate, onUploadProgress)
    )
    onUploadSuccess(file)
    yield put(fetchManifestsActions.request())

    if (file.fileStatus === DragDropFileStatus.SUCCESS) {
      yield put(uploadManifestFileActions.success(data))
    } else {
      yield put(
        uploadManifestFileActions.failure(
          new Error('Unable to upload the file.Please try again later')
        )
      )
    }
  } catch (e: any) {
    onUploadFail(file)
    yield put(uploadManifestFileActions.failure(e))
  }
}

function* handleDownloadManifestFile(
  action: ReturnType<typeof downloadManifestFileActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      DownloadManifestFileService(options, action.payload)
    )
    if (response) {
      const blob = base64toBlob(response, 'text/csv')
      saveBlobAsFile(blob, action.payload)
    }
  } catch (e: any) {
    console.log(e)
  }
}

function* handleDownloadStatementFile(
  action: ReturnType<typeof downloadManifestStatementActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      DownloadManifestStatementService(options, action.payload)
    )
    if (response) {
      const nbase64Data = response.replace('data:application/pdf;base64,', '')
      const blob = base64toBlob(nbase64Data, 'application/pdf')
      const url = URL.createObjectURL(blob)
      window.open(url)
    }
  } catch (e: any) {
    console.log(e)
  }
}

function* hanleFetchStatementDetails(
  action: ReturnType<typeof fetchStatementDetailActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchStatementDetailsService(options, action.payload)
    )
    if (data) {
      yield put(fetchStatementDetailActions.success(data))
    } else {
      yield put(
        fetchStatementDetailActions.failure(
          new Error('Unable to fetch Statement Details')
        )
      )
    }
  } catch (err: any) {
    yield put(fetchStatementDetailActions.failure(err))
  }
}

export const sagas = [
  () => takeLatest(fetchManifestsActions.request, hanleFetchManifests),
  () => takeLatest(uploadManifestFileActions.request, handleManifestFileUpload),
  () =>
    takeLatest(downloadManifestFileActions.request, handleDownloadManifestFile),
  () =>
    takeLatest(fetchStatementDetailActions.request, hanleFetchStatementDetails),
  () =>
    takeLatest(
      downloadManifestStatementActions.request,
      handleDownloadStatementFile
    )
]
