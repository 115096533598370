import { format } from 'date-fns'
import type { SeriesAreaOptions, SeriesColumnOptions } from 'highcharts'
import { merge } from 'lodash'
import React from 'react'
import {
  HighchartsComponent,
  HighchartsImport,
  useHighchartsOptions
} from 'shared/highcharts'

const getChartOptions = (highcharts: HighchartsImport): Highcharts.Options => ({
  title: {
    text: ''
  },
  yAxis: {
    title: {
      text: ''
    },
    softMin: 0
  },
  legend: {
    enabled: true,
    layout: 'vertical',
    symbolRadius: 0,
    align: 'right',
    itemWidth: 75,
    verticalAlign: 'middle',
    backgroundColor: 'transparent',
    floating: false,
    shadow: false
  },
  tooltip: {
    formatter: function formatTooltip(
      this: Highcharts.TooltipFormatterContextObject
    ) {
      return `<b>${format(this.x as number, `MMM ''yy`)}</b><br/>${
        this.series.name
      }: $${highcharts.numberFormat(this.y as number, 0, '.', ',')}
      <br/>TOTAL: $${highcharts.numberFormat(this.total || 0, 0, '.', ',')}`
    }
  },
  plotOptions: {
    column: {
      borderWidth: 0,
      pointPadding: 0,
      groupPadding: 0.1,
      // pointRange: 3600 * 24 * 28 * 1000,
      shadow: false,
      stacking: 'normal',
      dataLabels: {
        enabled: false
      }
    },
    area: {
      shadow: false,
      stacking: 'normal',
      dataLabels: {
        enabled: false
      }
    }
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 400
        }
      }
    ]
  }
})

export interface ITimeSeriesChartSeries {
  name: string
  data: ITimeSeriesChartData[]
  stack?: number
  color?: string
}

export interface ITimeSeriesChartData {
  date?: Date
  value?: number
}

export interface ITimeSeriesChartProps {
  series: ITimeSeriesChartSeries[]
  chartType: ITimeSeriesChartType
  options?: Highcharts.Options
  desc?: boolean
}

export type ITimeSeriesChartType = 'area' | 'column'

const containerProps = {
  style: { height: '100%', width: '100%' }
}

export const TimeSeriesChart: React.FC<ITimeSeriesChartProps> = ({
  series,
  chartType,
  options,
  desc
}) => {
  const weightedSeries = series.map((componentSeries) => {
    const { name, data, stack, color } = componentSeries

    const highChartsSeries: SeriesColumnOptions | SeriesAreaOptions = {
      name,
      data: data
        .filter(({ date }) => date != null)
        .map((item) => [item?.date?.getTime(), item.value]),
      stack: stack || 0,
      type: chartType || 'area',
      color
    }

    return {
      series: highChartsSeries,
      total: data.reduce((a, x) => a + (x?.value || 0), 0)
    }
  })

  const seriesOptions: Highcharts.Options = {
    series: desc
      ? weightedSeries.sort((a, b) => b.total - a.total).map((x) => x.series)
      : weightedSeries.sort((a, b) => a.total - b.total).map((x) => x.series),
    xAxis: {
      type: 'datetime',
      tickInterval: 30 * 24 * 3600 * 1000,
      labels: {
        format: `{value:%b '%y}`
      }
    }
  }

  const defaultChartOptions = useHighchartsOptions(getChartOptions)
  const mergedChartOptions = merge(
    {},
    defaultChartOptions,
    options,
    seriesOptions
  )

  return (
    <HighchartsComponent
      options={mergedChartOptions}
      containerProps={containerProps}
    />
  )
}
