import {
  useReactTable,
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  flexRender
} from '@tanstack/react-table'
import { IndeterminateProgressIndicator } from 'modules/Advisory/modules/Rdot360/components/shared'
import { SnackBar } from 'modules/Advisory/modules/Rdot360/components/shared/Snackbar'
import { SortIndicator } from 'modules/Advisory/modules/Rdot360/shared/SortIndicator'
import { IOpenTaxLotDetail } from 'modules/Advisory/modules/Rdot360/store/tasApi/IOpenTaxLotResponse'
import { useMemo, useState } from 'react'
import { ITableHeaderColumnSize } from '../../../../shared/ITableHeaderColumnSize'
import { rdot360TableStyles } from '../../../../shared/tableStyles'
import {
  getOpenLotsTableColumns,
  OpenLotTableColumnNames
} from './OpenLotsTableColumns'
import { cellStyles } from './styles'
import { useGetOpenLots } from './useGetOpenLots'

export interface IOpenLotsTableProps {
  accountId?: string
  secId?: string
  sizes: ITableHeaderColumnSize[]
}

type OpenLotTableRow = Row<IOpenTaxLotDetail>
export const OpenLotsTable: React.FC<IOpenLotsTableProps> = ({
  accountId,
  secId,
  sizes
}) => {
  const {
    data: openTaxLotResponse,
    isFetching,
    isUninitialized,
    error
  } = useGetOpenLots(accountId, secId)
  const [sorting, setSorting] = useState<SortingState>([])
  const data = useMemo(
    () => openTaxLotResponse?.AcctSecTaxLotDetails || [],
    [openTaxLotResponse?.AcctSecTaxLotDetails]
  )

  const columns = useMemo(getOpenLotsTableColumns, [])
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })

  const rows = table.getRowModel().rows
  const headers = table.getFlatHeaders()

  const newSizes = useMemo((): ITableHeaderColumnSize[] => {
    const [first, ...rest] = sizes

    return [
      ...[first, first].map((x, i) => ({
        id: `${x.id}${i}`,
        width: x.width / 2
      })),
      ...rest
    ]
  }, [sizes])

  const emptyColCount = useMemo(
    () => (sizes?.length ?? 0) - (headers?.length ?? 0) + 1,
    [headers.length, sizes.length]
  )

  return (
    <div>
      {isFetching && <IndeterminateProgressIndicator />}
      <table css={[rdot360TableStyles.table, { fontSize: '12px' }]}>
        <thead>
          <OpenLotsTableHeaderSizesRow sizes={newSizes} />
          <tr css={[rdot360TableStyles.subHeaderRow]}>
            {headers.map((header) => (
              <th
                key={header.id}
                style={{ cursor: 'pointer' }}
                onClick={header.column.getToggleSortingHandler()}
              >
                <div style={{ display: 'flex' }} css={[cellStyles[header.id]]}>
                  <span>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </span>
                  {header.column.getCanSort() && (
                    <SortIndicator direction={header.column.getIsSorted()} />
                  )}
                </div>
              </th>
            ))}
            <th colSpan={emptyColCount} />
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <OpenLotTableRow key={row.id} row={row} />
          ))}
        </tbody>
      </table>
      {!isFetching && !isUninitialized && !data?.length && !error && (
        <SnackBar type="Info" message="No data available" />
      )}
      {!!error && (
        <SnackBar
          type="Failure"
          message={(error as Error)?.message || 'An unknown error occurred'}
        />
      )}
    </div>
  )
}

const OpenLotTableRow: React.FC<{ row: OpenLotTableRow }> = ({ row }) => {
  const cells = row.getVisibleCells()

  return (
    <tr css={[rdot360TableStyles.bodyRow, rdot360TableStyles.subRow]}>
      {cells.map((cell) => {
        return (
          <td
            key={cell.id}
            css={[cellStyles[cell.column.id as OpenLotTableColumnNames]]}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        )
      })}
      <td />
    </tr>
  )
}

const OpenLotsTableHeaderSizesRow: React.FC<{
  sizes: ITableHeaderColumnSize[]
}> = ({ sizes }) => {
  return (
    <tr css={[rdot360TableStyles.sizesRow]}>
      {sizes.map(({ width, id }) => {
        return (
          <th
            key={id}
            style={{
              width,
              maxWidth: width
            }}
          />
        )
      })}
    </tr>
  )
}
