import { call, put, takeLatest } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../../../store/shared/sagas'
import { addEditPayee, fetchPayeeList } from '../api/payeeService'
import {
  AddEditActionTypes,
  addEditPayeesDataActions,
  payeesDataActions,
  PayeesDataActionTypes
} from './actions'
import { IPayees, IPayeesRequest } from './types'

function* handleFetchPayeeList(action: PayeesDataActionTypes) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const payeeRequest = action.payload as IPayeesRequest
    const data = yield* call(fetchPayeeList, options, payeeRequest)
    yield put(payeesDataActions.success(data))
  } catch (e: any) {
    yield put(payeesDataActions.failure(e))
  }
}
function* handleAddEditPayments(action: AddEditActionTypes) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const payeeRequest = action.payload as IPayees
    const data = yield* call(addEditPayee, options, payeeRequest)
    yield put(addEditPayeesDataActions.success(data))
  } catch (e: any) {
    yield put(addEditPayeesDataActions.failure(e))
  }
}
export const sagas = [
  () => takeLatest(payeesDataActions.request, handleFetchPayeeList),
  () => takeLatest(addEditPayeesDataActions.request, handleAddEditPayments)
]
