import { IColumn } from '@fluentui/react'
import React, { useMemo } from 'react'
import { IClient } from '../../api/client.types'
import { DataTable } from '../../shared/components/DataTable'
import { USDCell } from '../Lists/core/components/USDCell'
import { InvitedEmailCell } from './InvitedEmailCell'
import { LegalEntityNameCellContainer } from './LegalEntityNameCellContainer'

const defaultColumn: IColumn = {
  key: '',
  name: '',
  isResizable: true,
  minWidth: 80,
  maxWidth: 130
}

export const SmallClientList: React.FC<{
  clients?: IClient[]
  loading?: boolean
}> = ({ clients = [], loading = false }) => {
  const columns: IColumn[] = useMemo(
    () => [
      {
        ...defaultColumn,
        key: 'name',
        name: 'Legal Entity Name',
        maxWidth: 220,
        onRender: (client: IClient) => (
          <LegalEntityNameCellContainer client={client} />
        )
      },
      {
        ...defaultColumn,
        key: 'lastLogin',
        maxWidth: 175,
        name: 'Online Id / Last Login',
        onRender: (client: IClient) => {
          return <InvitedEmailCell client={client} />
        }
      },
      {
        ...defaultColumn,
        key: 'rev',
        className: 'align-right',
        headerClassName: 'align-right',
        maxWidth: 110,
        name: 'T12 Revenue',
        onRender: ({ revenueDet }: IClient) => (
          <USDCell value={revenueDet?.ttmrevenue} fractionDigits={0} />
        )
      },
      {
        ...defaultColumn,
        key: 'aus',
        className: 'align-right',
        headerClassName: 'align-right',
        name: 'AUS',
        onRender: ({ ClientKPI }: IClient) => (
          <USDCell value={ClientKPI?.AumTotal} fractionDigits={0} />
        )
      }
    ],
    []
  )
  return (
    <DataTable
      columns={columns}
      items={clients}
      enableShimmer={loading}
      shimmerLines={5}
    />
  )
}
