import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

export interface IFilters {
  category?: string
  subCategory?: string
  periodStart: Date
  periodEnd: Date
}

interface IRevenueDetailFilter {
  filters: IFilters | undefined
}

const initialState = {
  filters: undefined
} as IRevenueDetailFilter

export const { actions, reducer: revenueDetailFilterReducer } = createSlice({
  name: 'revenueDetailFilterReducer',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<IFilters | undefined>) => ({
      ...state,
      filters: action.payload
    })
  }
})

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.features.revenueDetailFilter

export const getFilters = flow(rootSelector, (x) => x.filters)

export const useRevenueDetailFilterStore = () => {
  const dispatch = useDispatch()
  const setFilters = useCallback(
    (filters: IFilters) => {
      dispatch(actions.setFilter(filters))
    },
    [dispatch]
  )

  const filters = useSelector(getFilters)
  return {
    setFilters,
    filters
  }
}
