import axios from 'axios'
import { call, cancelled, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { getSystemUserDetail, ISystemUser } from '../../../../../api/dynamics'
import { AppState } from '../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../store/shared/asyncStore'
import { getDynamicsApiOptions } from '../../../../../store/shared/sagas'

export const requestOwnerFetchActions = createAsyncAction(
  '@features/@accountLinking/@features/@accountLinkingRequestView/@requestOwnerFetch/REQUEST',
  '@features/@accountLinking/@features/@accountLinkingRequestView/@requestOwnerFetch/SUCCESS',
  '@features/@accountLinking/@features/@accountLinkingRequestView/@requestOwnerRequestFetch/FAILURE'
)<string, ISystemUser, Error>()

export const requestOwnerFetchReducer = createAsyncReducer(
  requestOwnerFetchActions
)

const rootSelector = (state: AppState) =>
  state.features.accountLinking.features.accountLinkingRequestView
    .requestOwnerFetch

export const {
  getError: getRequestOwnerFetchError,
  getIsLoading: getIsRequestOwnerLoading,
  getResult: getRequestOwnerFetchResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof requestOwnerFetchActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  try {
    const apiOptions = yield* call(getDynamicsApiOptions)

    const result = yield* call(getSystemUserDetail, apiOptions, action.payload)

    yield put(requestOwnerFetchActions.success(result))
  } catch (e: unknown) {
    yield put(requestOwnerFetchActions.failure(e as Error))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const requestOwnerFetchSagas = [
  () => takeLatest(requestOwnerFetchActions.request, onRequest)
]
