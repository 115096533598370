import { CommandBar, SearchBox, Stack } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import {
  GroupingState,
  SortingState,
  VisibilityState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { IAnnuityPPLIContract } from 'api/datahub'

import { useMemo, useState } from 'react'
import { ColumnEditSummaryPanel } from '../../features'
import { getGlobalFilterFn } from '../../features/TanStackReactTable/getGlobalFilterFn'
import { TableWrapper } from '../../features/TanStackReactTable/TableWrapper'
import { useGetPrivatePlacementSummaryQuery } from '../../shared/api/apiAssets'
import {
  useSelectedReps,
  useSearchText,
  useClickToExport,
  useCommandBar
} from '../../shared/hooks'
import { columnNames as cn } from './columnNames'
import { getColumnDefs } from './getColumnDefs'

const searchableColumnIds = [
  cn.contractNumber,
  cn.cusip,
  cn.underlyingSecurityCUSIP,
  cn.underlyingSecurityDescription,
  cn.householdName
]

export const PrivatePlacement: React.FC = () => {
  const { selectedReps, shouldMakeApiCall } = useSelectedReps()

  const { searchText, onSearchTextChanged, tokenizedGlobalFilter } =
    useSearchText()

  const [isPanelOpen, { setTrue: openPanel, setFalse: closePanel }] =
    useBoolean(false)

  const [
    isExportLoading,
    { setTrue: exportLoading, setFalse: exportDoneLoading }
  ] = useBoolean(false)

  const queryProps = useMemo(
    () => ({
      registeredRepIds: selectedReps,
      contractStatus: ['Active'],
      contractType: 'Private Placement'
    }),
    [selectedReps]
  )

  const { data, isFetching: isSummaryFetching } =
    useGetPrivatePlacementSummaryQuery(
      shouldMakeApiCall ? queryProps : skipToken
    )

  const tableData = useMemo(() => {
    const empty: IAnnuityPPLIContract[] = []
    return data || empty
  }, [data])

  const columnDefs = useMemo(getColumnDefs, [])

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'Issue Date',
      desc: true
    }
  ])

  const [grouping, setGrouping] = useState<GroupingState>([cn.contractNumber])

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    [cn.cusip]: false,
    [cn.productType]: false,
    [cn.underlyingAssetClassL2]: false,
    [cn.underlyingAssetClassL3]: false,
    [cn.underlyingAssetClassL4]: false,
    [cn.state]: false,
    [cn.holdingPercent]: false,
    [cn.underlyingAssetSubType]: false,
    [cn.underlyingAssetType]: false,
    [cn.policyStatus]: false,
    [cn.lastUpdated]: false,
    [cn.cashValue]: false,
    [cn.deathBenefit]: false,
    [cn.surrenderValue]: false,
    [cn.surrenderCharge]: false,
    [cn.surrenderChargeExpiration]: false,
    [cn.rmds]: false,
    [cn.maturityDate]: false,
    [cn.policyLoanAmount]: false,
    [cn.insuredAnnuitants]: false,
    [cn.productName]: false,
    [cn.address]: false,
    [cn.highBenefitName]: false,
    [cn.maxBenefit]: false,
    [cn.incomeBenefitGMIB]: false,
    [cn.feeType]: false,
    [cn.tbfPercentage]: false,
    [cn.agents]: false,
    [cn.ownerClient]: false,
    [cn.advisorId]: false,
    [cn.advisorName]: false,
    [cn.advisorTeam]: false,
    [cn.regionName]: false,
    [cn.assetClassL1]: false,
    [cn.assetClassL2]: false,
    [cn.assetClassL3]: false,
    [cn.assetClassL4]: false,
    [cn.assetType]: false,
    [cn.assetSubType]: false
  })

  const table = useReactTable({
    data: tableData,
    columns: columnDefs,
    state: {
      columnVisibility,
      globalFilter: tokenizedGlobalFilter,
      grouping,
      sorting
    },
    onColumnVisibilityChange: setColumnVisibility,
    onGroupingChange: setGrouping,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: getGlobalFilterFn(searchableColumnIds),
    autoResetExpanded: false
  })

  const { getToggleAllColumnsVisibilityHandler, getIsAllColumnsVisible } = table

  const headers = table.getFlatHeaders()

  const { flatRows } = table.getRowModel()

  const allLeafColumns = table.getAllLeafColumns()

  const toggleAllColumnsVisibilityHandler =
    getToggleAllColumnsVisibilityHandler()

  const isAllColumnsVisible = getIsAllColumnsVisible()

  const { clickToExport } = useClickToExport({
    headers,
    excelColumnConfigType: 'ASSETS_EXPANDED',
    tabLabel: 'New Private Placement',
    exportLoading,
    exportDoneLoading,
    flatRows
  })

  const { commandBarItems } = useCommandBar({
    isExportLoading,
    clickToExport,
    openPanel
  })

  return (
    <>
      <h2 css={{ marginTop: '0' }}>
        Private Placement Life Insurance and Annuities
      </h2>
      <Stack
        horizontal
        tokens={{ childrenGap: 10 }}
        styles={{ root: { marginBottom: '10px' } }}
        verticalAlign="end"
      >
        <SearchBox
          placeholder="Search"
          onChange={onSearchTextChanged}
          autoComplete="off"
          value={searchText || ''}
          styles={{
            root: { width: 250 }
          }}
        />

        <CommandBar
          styles={{
            root: { margin: 0, padding: 0, height: '32px' }
          }}
          items={commandBarItems}
        />
      </Stack>

      <TableWrapper
        grouping={grouping}
        isSummaryFetching={isSummaryFetching}
        table={table}
        setSorting={setSorting}
      />

      {isPanelOpen && (
        <ColumnEditSummaryPanel
          isPanelOpen={isPanelOpen}
          closePanel={closePanel}
          columns={allLeafColumns}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          toggleAllColumnsVisibilityHandler={toggleAllColumnsVisibilityHandler}
          isAllColumnsVisible={isAllColumnsVisible}
        />
      )}
    </>
  )
}
