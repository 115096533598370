import { combineReducers } from 'redux'
import { createNoteDialogReducer } from './createNoteDialog'
import { notesDialogReducer } from './notesDialog'
import { notesUiReducer } from './notesUi'

export const notes360Reducer = combineReducers({
  notesDialog: notesDialogReducer,
  notesUi: notesUiReducer,
  createNoteDialog: createNoteDialogReducer
})
