import { AppState } from '../../../../../store'

export const getIsLoading = (state: AppState) =>
  state.modules.reports.dashboard.loading
export const getReportError = (state: AppState) =>
  state.modules.reports.dashboard.error
export const getReportList = (state: AppState) =>
  state.modules.reports.dashboard.reportList
export const getViewReportDetails = (state: AppState) =>
  state.modules.reports.dashboard.viewReportDetails
export const getClientContext = (state: AppState) => state.context.client?.items
export const getActiveReport = (state: AppState) =>
  state.modules.reports.dashboard.activeReport
export const getClientLoading = (state: AppState) =>
  state.modules.reports.dashboard.clientDetails?.loading || false
export const getClientListError = (state: AppState) =>
  state.modules.reports.dashboard.clientDetails?.error
export const getClientList = (state: AppState) =>
  state.modules.reports.dashboard.clientDetails?.clients || []
export const getShareReportDetails = (state: AppState) =>
  state.modules.reports.dashboard.shareReortDetails
export const getUploadReportDetails = (state: AppState) =>
  state.modules.reports.dashboard.uploadReportDetails
export const getUploadReportList = (state: AppState) =>
  state.modules.reports.dashboard.uploadReportList
export const getViewUploadReport = (state: AppState) =>
  state.modules.reports.dashboard.viewUploadReport
export const getDeleteUploadReport = (state: AppState) =>
  state.modules.reports.dashboard.deleteUploadReport
