import axios from 'axios'
import { call, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import {
  getRevenueSummary,
  IGetRevenueSummaryRequest,
  IRevenueSummaryItem
} from '../../../../../../../../../api/datahub'
import { AppState } from '../../../../../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../../../../../../../../store/shared/sagas'

export interface IEnhancedRevenueSummaryItem extends IRevenueSummaryItem {
  parentCategory?: string
}

export const revenueSummaryFetchActions = createAsyncAction(
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@revenueSummaryTable/REQUEST',
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@revenueSummaryTable/SUCCESS',
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@revenueSummaryTable/FAILURE'
)<IGetRevenueSummaryRequest, IEnhancedRevenueSummaryItem[], Error>()

export const revenueSummaryFetchReducer = createAsyncReducer(
  revenueSummaryFetchActions
)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.revenue.modules.dashboard.features
    .revenueSummaryTable.revenueSummaryFetch

export const {
  getError: getRevenueSummaryFetchError,
  getIsLoading: getIsRevenueSummaryFetchLoading,
  getResult: getRevenueSummaryFetchResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof revenueSummaryFetchActions.request>
) {
  if (!action?.payload?.tradeReps?.length) {
    yield put(revenueSummaryFetchActions.success([]))
    return
  }
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)

  try {
    const result = yield* call(getRevenueSummary, action.payload, apiOptions)

    if (!result) {
      throw new Error('No result returned from datahub api')
    }
    const categoryMode = action.payload.catMode
    const enhancedResult =
      categoryMode === 'L2'
        ? result?.map((item): IEnhancedRevenueSummaryItem => {
            const split = item.category?.split(' - ')
            const category = split?.slice(1).join(' - ')
            return {
              category: category,
              parentCategory: split?.[0],
              compRevenue: item.compRevenue,
              payout: item.payout,
              payrollPeriodDate: item.payrollPeriodDate
            }
          })
        : result
    yield put(revenueSummaryFetchActions.success(enhancedResult))
  } catch (e) {
    yield put(revenueSummaryFetchActions.failure(e as Error))
  }
}

export const revenueSummaryFetchSagas = [
  () => takeLatest(revenueSummaryFetchActions.request, onRequest)
]
