import { Icon, Stack, ProgressIndicator } from '@fluentui/react'
import React, {
  useRef,
  useState,
  memo,
  useCallback,
  PropsWithChildren
} from 'react'
import { PaletteComponent } from './Palette'

export interface IDragDropProps {
  WrapperStyle?: React.CSSProperties
  onDropFile: (
    file: IDragDropFile,
    onUploadSuccess: (file: IDragDropFile) => void,
    onUploadFail: (file: IDragDropFile) => void,
    onUploadProgress?: (
      progressEvent: ProgressEvent<EventTarget>,
      file: IDragDropFile
    ) => void
  ) => void
  allowedFileType?: string[]
}

const DragDropWrapperStyle: React.CSSProperties = {
  minHeight: 200,
  border: '3px dashed #CCC',
  display: 'grid',
  alignItems: 'center',
  justifyItems: 'center'
}

export enum DragDropFileStatus {
  'PROCESSING' = 'PROCESSING',
  'SUCCESS' = 'SUCCESS',
  'FAILURE' = 'FAILURE'
}

export interface IDragDropFile {
  id: string
  file: File
  fileStatus?: DragDropFileStatus
  uploadPercentage?: number
}

interface IDragDropState {
  isDragging: boolean
  fileList: IDragDropFile[]
}

const DragDrop: React.FC<PropsWithChildren<IDragDropProps>> = (props) => {
  const refDragWrapper = useRef<HTMLDivElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const { WrapperStyle, onDropFile, allowedFileType }: IDragDropProps = props
  const [dragDropState, setDragDropState] = useState<IDragDropState>({
    isDragging: false,
    fileList: []
  })
  const { isDragging, fileList } = dragDropState
  const refFileList = useRef<IDragDropFile[]>([])
  refFileList.current = fileList
  const handleDragEnter = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()
      setDragDropState((prevState) => ({
        ...prevState,
        isDragging: true
      }))
    },
    []
  )

  const handleDragLeave = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()
      setDragDropState((prevState) => ({
        ...prevState,
        isDragging: false
      }))
    },
    []
  )

  const fileInputClicked = () => {
    if (null !== fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()
      if (event.dataTransfer) {
        event.dataTransfer.dropEffect = 'copy'
      }
      setDragDropState((prevState) => ({
        ...prevState,
        isDragging: true
      }))
    },
    []
  )

  const onUploadSuccess = useCallback((file: IDragDropFile) => {
    const referencedFiles = [...refFileList.current]
    const updatedFileList: IDragDropFile[] = referencedFiles.map(
      (statefile) => {
        if (statefile.id === file.id) {
          statefile.fileStatus = DragDropFileStatus.SUCCESS
        }
        return statefile
      }
    )

    setDragDropState((prevState) => ({
      ...prevState,
      fileList: [...updatedFileList]
    }))
  }, [])

  const onUploadFail = useCallback((file: IDragDropFile) => {
    const referencedFiles = [...refFileList.current]
    const updatedFileList: IDragDropFile[] = referencedFiles?.map(
      (statefile) => {
        if (statefile.id === file.id) {
          statefile.fileStatus = DragDropFileStatus.FAILURE
        }
        return statefile
      }
    )
    setDragDropState((prevState) => ({
      ...prevState,
      fileList: [...updatedFileList]
    }))
  }, [])

  const onUploadProgress = useCallback(
    (progressEvent: ProgressEvent<EventTarget>, file: IDragDropFile) => {
      const referencedFiles = [...refFileList.current]
      const updatedFileList: IDragDropFile[] = referencedFiles?.map(
        (statefile) => {
          if (statefile.id === file.id) {
            statefile.uploadPercentage = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            )
          }
          return statefile
        }
      )
      setDragDropState((prevState) => ({
        ...prevState,
        fileList: [...updatedFileList]
      }))
    },
    []
  )

  const onFileDrop = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const target = e.target as HTMLInputElement
    const files: IDragDropFile[] = [...(target?.files || [])]
      .filter((file) => {
        if (allowedFileType) {
          return allowedFileType.includes(file?.type)
        } else {
          return true
        }
      })
      .map(
        (file) =>
          ({
            id: `_${Math.random().toString(36)}`,
            file
          } as IDragDropFile)
      )

    setDragDropState((prevState) => ({
      ...prevState,
      isDragging: false,
      fileList: [...prevState.fileList, ...files]
    }))

    //call onDropFile
    files.forEach((file) => {
      onDropFile(file, onUploadSuccess, onUploadFail, onUploadProgress)
    })
  }

  const handleDragDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()
      const files: IDragDropFile[] = [...(event.dataTransfer?.files || [])]
        .filter((file) => {
          if (allowedFileType) {
            return allowedFileType.includes(file?.type)
          } else {
            return true
          }
        })
        .map(
          (file) =>
            ({
              id: `_${Math.random().toString(36)}`,
              file
            } as IDragDropFile)
        )

      setDragDropState((prevState) => ({
        ...prevState,
        isDragging: false,
        fileList: [...prevState.fileList, ...files]
      }))

      //call onDropFile
      files.forEach((file) => {
        onDropFile(file, onUploadSuccess, onUploadFail, onUploadProgress)
      })
    },
    [
      allowedFileType,
      onDropFile,
      onUploadFail,
      onUploadProgress,
      onUploadSuccess
    ]
  )

  const getProgressColor = (fileStatus?: DragDropFileStatus) => {
    if (fileStatus) {
      if (fileStatus === DragDropFileStatus.SUCCESS) {
        return 'green'
      }
      if (fileStatus === DragDropFileStatus.FAILURE) {
        return 'red'
      }
    } else {
      return 'rgb(0, 120, 212)'
    }
  }

  return (
    <>
      <PaletteComponent>
        <div
          style={WrapperStyle ? WrapperStyle : DragDropWrapperStyle}
          ref={refDragWrapper}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDragDrop}
          onClick={fileInputClicked}
        >
          <input
            ref={fileInputRef}
            className="file-input"
            type="file"
            onChange={onFileDrop}
            style={{ display: 'none' }}
          />
          <Icon
            iconName="BulkUpload"
            style={{
              fontSize: '64px',
              color: isDragging ? 'rgb(0, 120, 212)' : '#ccc'
            }}
          />
          {props.children}
        </div>
      </PaletteComponent>
      <Stack>
        {fileList &&
          fileList.length > 0 &&
          fileList.map((fileItem, index) => {
            return (
              <PaletteComponent key={index}>
                <Stack.Item>
                  <div
                    style={{
                      color: getProgressColor(fileItem?.fileStatus)
                    }}
                  >
                    {fileItem?.file?.name}
                  </div>
                  {!fileItem.fileStatus && (
                    <ProgressIndicator
                      percentComplete={fileItem.uploadPercentage}
                    />
                  )}
                </Stack.Item>
              </PaletteComponent>
            )
          })}
      </Stack>
    </>
  )
}

export default memo(DragDrop)
