import React from 'react'
import { ValueColorWrapper } from './ValueColorWrapper'

export const PercentCellWithColor: React.FC<{
  value?: number
  showTwoDecimalPrecisionForZero?: true
}> = ({ value, showTwoDecimalPrecisionForZero }) => {
  const zeroPercentToDisplay = showTwoDecimalPrecisionForZero ? '0.00%' : '0'
  const nullValueToDisplay = value == null ? '--' : null
  const percent = value === 0 ? zeroPercentToDisplay : `${value?.toFixed(2)}%`

  return (
    <ValueColorWrapper value={value}>
      {!!value && value > 0 && '+'}
      {nullValueToDisplay ?? percent}
    </ValueColorWrapper>
  )
}
