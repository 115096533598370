import { call, put, takeLatest } from 'typed-redux-saga'
import { IClient } from '../../../../../../../../api/client.types'
import { getRockefellerApiOptions } from '../../../../../../../../store/shared/sagas'
import { fetchClientSummaryService } from '../api/clientService'
import { clientSummaryDataActions } from './actions'
import { IClientSummary } from './types'

function* handleGetClientSummaryList() {
  try {
    const apiOptions = yield* call(getRockefellerApiOptions)
    const data = yield* call(() => fetchClientSummaryService(apiOptions))
    const clientSummary =
      data?.map(
        (x: IClient) =>
          ({
            aum: x?.ClientKPI?.AumTotal,
            aumBrokerage: x?.ClientKPI?.AumBrokerage,
            aumManaged: x?.ClientKPI?.AumManaged,
            name: x?.LegalEntityName
          } as IClientSummary)
      ) ?? []
    yield put(clientSummaryDataActions.success(clientSummary))
  } catch (e) {
    yield put(clientSummaryDataActions.failure(e))
  }
}

export const sagas = [
  () => takeLatest(clientSummaryDataActions.request, handleGetClientSummaryList)
]
