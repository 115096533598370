import { useTheme } from '@emotion/react'
import { USDCell } from 'features/Lists/core/components/USDCell'
import { getCroppedAccountList } from 'modules/Advisory/modules/Rdot360/shared/utilities'
import { FormattedDate } from 'react-intl'
import {
  useRdot360AccountContext,
  useRdot360Context,
  useRdot360HouseholdContext
} from '../../../../store/rdot360Context'

interface IPrintHeaderClientProps {
  skipCag: boolean
  masked: boolean
  hideHousehold: boolean
  total: number
  daysChange: number
  searchText: string
}

export const PrintHeaderClient: React.FC<IPrintHeaderClientProps> = ({
  skipCag,
  masked,
  hideHousehold,
  total,
  daysChange,
  searchText
}) => {
  const context = useRdot360HouseholdContext()
  const { selectedAccounts } = useRdot360AccountContext()
  const accountNumbers = selectedAccounts.map(
    (account) => account.CustodyAccount || ''
  )
  const accounts = getCroppedAccountList(accountNumbers, masked, 320, 4)
  const theme = useTheme()
  let textColor = theme.colors.primaryBlack
  if (daysChange > 0) {
    textColor = theme.colors.extraGreen1
  } else if (daysChange < 0) {
    textColor = theme.colors.primaryRed
  }
  const { asOfDate } = useRdot360Context()
  return (
    <>
      <div className="header-column header-left">
        {!hideHousehold && (
          <div>
            <strong>{context.household?.householdName}</strong>
          </div>
        )}
        <div>
          <label>Selected Account(s):</label> {accounts}
        </div>
      </div>
      <div className="header-column header-right">
        <div>
          <label>Report As Of:</label>{' '}
          <FormattedDate
            value={asOfDate}
            day="numeric"
            month="numeric"
            year="numeric"
          />
        </div>
        {skipCag && (
          <div>
            <label>Total:</label>{' '}
            <USDCell currencySign="standard" value={total || 0} />
            {' | '}
            <label>Day&apos;s Change:</label>{' '}
            <span style={{ color: textColor }}>
              <USDCell currencySign="standard" value={daysChange || 0} />
            </span>
          </div>
        )}
        {searchText && (
          <div>
            <label>Filtered by:</label> {searchText}
          </div>
        )}
      </div>
    </>
  )
}
