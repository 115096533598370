export const convertTaxStatus = (value?: string) => {
  switch (value) {
    case 'Taxable':
      return 'Taxable'
    case 'taxable':
      return 'Taxable'
    case 'tax-deferred':
      return 'Tax-Deferred'
    case 'tax-exempt':
      return 'Tax-Exempt'
    default:
      return 'Uncategorized'
  }
}
