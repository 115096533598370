import { createRoot } from 'react-dom/client'
import { whenIdle } from 'shared/async'
import { IAccountLinkingRequest } from '../../../../api/dynamics'

export interface IAccountLinkingRejectEmailProps {
  request?: IAccountLinkingRequest
  origin?: string
}

export const AccountLinkingRejectEmail: React.FC<
  IAccountLinkingRejectEmailProps
> = ({ request, origin }) => {
  const notes = (request?.rcm_notes?.split('\n\n') || []).map((note) =>
    note.split('\n')
  )
  return (
    <div>
      <div style={{ marginTop: '10px' }}>
        <br />
        This message is to notify you that the status of your Account Linking
        Request for client <b>{request?.rcm_newclient}</b> has been updated to{' '}
        <b>
          {request?.['rcm_status@OData.Community.Display.V1.FormattedValue']}
        </b>
        .
        <br />
      </div>

      <div style={{ marginTop: '10px' }}>
        <div>
          <b>Notes</b>:<br />
          <ul>
            {notes.map((note, index) => (
              <li key={index}>
                {note.map((text, textIndex) =>
                  textIndex % 2 === 0 ? (
                    <>
                      <b>{text}</b>
                      <br />
                    </>
                  ) : (
                    <>
                      {text}
                      <br />
                    </>
                  )
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div style={{ marginTop: '10px' }}>
        <div>
          To view the status of your request, see{' '}
          <a
            href={`${origin}/advisory/requests/AccountLinkingRequests${
              request?.rcm_accountlinkingrequestid
                ? `?id=${request?.rcm_accountlinkingrequestid}`
                : ''
            }`}
          >
            Account Linking Requests
          </a>{' '}
        </div>
      </div>
    </div>
  )
}

export const generateAccountLinkingRejectEmail = async (
  props: IAccountLinkingRejectEmailProps
) => {
  const container = document.createElement('div')
  const root = createRoot(container)

  root.render(<AccountLinkingRejectEmail {...props} />)
  await whenIdle()

  const html = container.innerHTML
  root.unmount()
  return html
}
