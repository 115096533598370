import { css } from '@emotion/react'
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { IndeterminateProgressIndicator } from '../../components/shared'
import { useUploadFileMutation } from '../../store/graph'

const dragoverClass = css({
  outline: '1px dashed blue',
  '& *': { pointerEvents: 'none' }
})

export const FileDropWrapper: React.FC<
  PropsWithChildren<{
    disabled?: boolean
    driveId?: string
    folderId?: string
    groupId?: string
  }>
> = ({ disabled, driveId, folderId, groupId, children }) => {
  const dropRef = useRef<HTMLDivElement>(null)
  const [uploadFile, uploadResult] = useUploadFileMutation()
  const allowUpload = useMemo(
    () => !disabled && driveId && groupId,
    [disabled, driveId, groupId]
  )
  const handleDrop = useCallback(
    (ev: DragEvent) => {
      ev.preventDefault()
      ev.stopPropagation()
      setIsDragging(false)
      if (!allowUpload) {
        return
      }
      const files = ev?.dataTransfer ? [...(ev?.dataTransfer.files || [])] : []
      files?.map((x) =>
        uploadFile({
          driveId: driveId,
          folderId: folderId,
          groupId: groupId,
          file: x
        })
      )
    },
    [allowUpload, driveId, folderId, groupId, uploadFile]
  )
  const handleDragover = useCallback((ev: DragEvent) => {
    ev.preventDefault()
    ev.stopPropagation()
  }, [])
  useEffect(() => {
    const dropArea = dropRef?.current
    dropArea?.addEventListener('drop', handleDrop)
    dropArea?.addEventListener('dragover', handleDragover)
    return () => {
      dropArea?.removeEventListener('drop', handleDrop)
      dropArea?.removeEventListener('dragover', handleDragover)
    }
  }, [handleDrop, handleDragover])
  const isLoading = useMemo(
    () => uploadResult?.isLoading,
    [uploadResult?.isLoading]
  )
  const [isDragging, setIsDragging] = useState(false)
  const onDragLeave = useCallback((ev: React.DragEvent<HTMLDivElement>) => {
    if (ev.target === dropRef.current) {
      setIsDragging(false)
    }
  }, [])
  return (
    <div
      ref={dropRef}
      css={[{ minHeight: '400px' }, isDragging && !disabled && dragoverClass]}
      onDragEnter={() => setIsDragging(true)}
      onDragLeave={onDragLeave}
    >
      {isLoading && <IndeterminateProgressIndicator />}
      {children}
    </div>
  )
}
