import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { AccountNumberCell } from '../../../components/shared/DetailTables/AccountNumberCell'
import { HeaderContainer } from '../../../components/shared/DetailTables/HeaderContainer'
import { IndeterminateCheckbox } from '../../../shared/IndeterminateCheckbox'
import { IRetrieveDocumentListDocumentItem } from '../../../store/documentsApi/IRetrieveDocumentListResponse'
import { DownloadAttachment } from '../shared/DownloadAttachment'

const columnHelper = createColumnHelper<IRetrieveDocumentListDocumentItem>()

export const constructColumns = () => [
  columnHelper.accessor((row) => row.DisplayName, {
    id: 'DisplayName',
    cell: ({ row }) => {
      const checked = row.getIsSelected()
      return (
        <div
          style={{ display: 'flex', columnGap: '10px', alignItems: 'center' }}
        >
          <input
            style={{ cursor: 'pointer' }}
            type="checkbox"
            checked={checked}
            onChange={row.getToggleSelectedHandler()}
          />
          <DownloadAttachment
            data={row.original}
            virtualTable="NF_STATEMENTS"
          />
        </div>
      )
    },
    header: ({ table, column }) => {
      const isAllSelected = table.getIsAllRowsSelected()
      const isSomeSelected = table.getIsSomeRowsSelected()
      return (
        <div
          style={{ display: 'flex', columnGap: '10px', alignItems: 'center' }}
        >
          <IndeterminateCheckbox
            style={{ cursor: 'pointer' }}
            type="checkbox"
            indeterminate={isSomeSelected}
            checked={isAllSelected}
            onClick={(e) => e.stopPropagation()}
            onChange={() => table.toggleAllRowsSelected(!isAllSelected)}
          />
          <HeaderContainer
            title="Description"
            isSorted={column.getIsSorted()}
          />
        </div>
      )
    },
    size: 200
  }),
  columnHelper.accessor((row) => row.PrimaryValue, {
    id: 'PrimaryValue',
    header: (props) => (
      <HeaderContainer title="Accounts" isSorted={props.column.getIsSorted()} />
    ),
    cell: (props) => <AccountNumberCell accountIdOrKey={props.getValue()} />,
    size: 200
  }),
  columnHelper.accessor((row) => row.CorrespondenceDate, {
    id: 'CorrespondenceDate',
    cell: (props) =>
      props.getValue() &&
      format(parseDateISOStringInLocalTimezone(props.getValue()), 'yyyy-MM-dd'),
    header: (props) => (
      <HeaderContainer title="Date" isSorted={props.column.getIsSorted()} />
    ),
    size: 100
  })
]
