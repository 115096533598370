import { IListsUiState } from 'features/Lists/core/contracts/IListsUIState'
import { ISystemViewDefinition } from 'features/Lists/hooks/useListPreferences'
import { CreditEventColumnIds } from '../../ColumnDefinitions'
import { constructInitialCreditEventListColumnState } from '../../store'

const initialColumns: (keyof CreditEventColumnIds)[] = [
  'periodTimestamp',
  'tradeOrRevenueDate',
  'accountNumber',
  'partyName',
  'householdName',
  'description',
  'feeAndCommission',
  'allocatedAmount',
  'grossPayout',
  'revenueCategoryLvl1',
  'revenueCategoryLvl2',
  'repId'
]

const defaultUiState: IListsUiState = {
  searchText: '',
  columnState: constructInitialCreditEventListColumnState(initialColumns),
  orderBy: {
    columnId: CreditEventColumnIds.periodTimestamp,
    direction: 'desc'
  }
}

export const creditEventSystemViews = [
  {
    key: 'CREDIT_EVENT_DEFAULT_SYSTEM_VIEW',
    label: 'Default System View',
    config: defaultUiState
  }
] as ISystemViewDefinition[]
