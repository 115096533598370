import { css } from '@emotion/react'
import { investmentsTableColumnNames } from './shared'

const commonCellStyles = {
  left: css({
    textAlign: 'left'
  }),
  right: css({
    textAlign: 'right',
    justifyContent: 'flex-end'
  })
}

export const cellStyles = {
  [investmentsTableColumnNames.accountNumber]: css([commonCellStyles.left]),
  [investmentsTableColumnNames.assetClass]: css([commonCellStyles.left]),
  [investmentsTableColumnNames.costBasis]: css([commonCellStyles.right]),
  [investmentsTableColumnNames.dayChange]: css([commonCellStyles.right]),
  [investmentsTableColumnNames.description]: css([commonCellStyles.left]),
  [investmentsTableColumnNames.price]: css([commonCellStyles.right]),
  [investmentsTableColumnNames.priceChange]: css([commonCellStyles.right]),
  [investmentsTableColumnNames.productType]: css([commonCellStyles.left]),
  [investmentsTableColumnNames.quantity]: css([commonCellStyles.right]),
  [investmentsTableColumnNames.secid]: css([commonCellStyles.left]),
  [investmentsTableColumnNames.unitCost]: css([commonCellStyles.right]),
  [investmentsTableColumnNames.unrealized]: css([commonCellStyles.right]),
  [investmentsTableColumnNames.value]: css([commonCellStyles.right]),
  [investmentsTableColumnNames.yield]: css([commonCellStyles.right]),
  [investmentsTableColumnNames.accruedIncome]: css([commonCellStyles.right]),
  [investmentsTableColumnNames.asOf]: css([commonCellStyles.right])
}
