import { css } from '@emotion/react'
import { useMemo } from 'react'
import { USD } from 'shared/components/Formatting'

export const getClasses = () => ({
  totalHeader: css({
    display: 'flex',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    margin: '4px 8px 4px 0',
    textOverflow: 'ellipsis',
    columnGap: '4px'
  })
})

type TotalInterestDividendProps = {
  interest: number
  dividend?: number
  capGain?: number | false
}

export const TotalInterestDividend: React.FC<TotalInterestDividendProps> = ({
  interest,
  dividend = 0,
  capGain
}) => {
  const classes = useMemo(() => getClasses(), [])
  return (
    <>
      <span css={classes.totalHeader}>
        Total Interest:
        <div css={{ fontWeight: 'bold' }}>
          <USD value={interest} currencySign="standard" fractionDigits={0} />
        </div>
      </span>
      <span css={classes.totalHeader}>
        Total Dividends:
        <div css={{ fontWeight: 'bold' }}>
          <USD value={dividend} currencySign="standard" fractionDigits={0} />
        </div>
      </span>
      {!!capGain && (
        <span css={classes.totalHeader}>
          Total Capital Gains Distribution:
          <div css={{ fontWeight: 'bold' }}>
            <USD value={capGain} currencySign="standard" fractionDigits={0} />
          </div>
        </span>
      )}
    </>
  )
}
