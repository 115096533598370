import { AppState } from '../../../../../store'

export const getVendorsList = (state: AppState) =>
  state.modules.payments.vendor.vendorlist

export const getIsVendorListLoading = (state: AppState) =>
  state.modules.payments.vendor.isLoading

export const getOtherPaymentsUrl = (state: AppState) =>
  state.system.environment?.apps?.otherPayments?.url
