import { combineReducers } from 'redux'
import { accountContextReducer, accountSagas } from './account'
import { advisorContextReducer, advisorSagas } from './advisor'
import { clientContextReducer, clientSagas } from './client'
import { domainContextReducer, domainSagas } from './domain'

export const contextReducer = combineReducers({
  advisor: advisorContextReducer,
  client: clientContextReducer,
  account: accountContextReducer,
  domain: domainContextReducer
})

export const contextSagas = [
  ...advisorSagas,
  ...clientSagas,
  ...accountSagas,
  ...domainSagas
]
