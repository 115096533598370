import { call, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { IHousehold } from '../../../api/household.types'
import { OdataFilterOperatorEnum } from '../../../api/odata'
import { AppState } from '../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../store/shared/asyncStore'
import { search } from '../../../store/shared/sagas'

export const householdFetchActions = createAsyncAction(
  '@features/@households/@householdDetail/@household/REQUEST',
  '@features/@households/@householdDetail/@household/SUCCESS',
  '@features/@households/@householdDetail/@household/FAILURE'
)<string, IHousehold, Error>()

export const householdFetchReducer = createAsyncReducer(householdFetchActions)

const rootSelector = (state: AppState) =>
  state.features.households.householdFetch

export const {
  getError: getHouseholdError,
  getIsLoading: getIsHouseholdLoading,
  getResult: getHouseholdResult
} = createAsyncSelectors(rootSelector)

const fetchHousehold = function* (
  action: ReturnType<typeof householdFetchActions.request>
) {
  try {
    const result = yield* call(search, 'household' as const, {
      top: 1,
      select: [
        'Account',
        'Advisors',
        'Parties',
        'ausHistory',
        'householdKPI',
        'householdId',
        'householdName',
        'id',
        'noOfAccounts',
        'revenue',
        'revenueDetHistory',
        'householdFeeIndicator'
      ],
      filters: [
        {
          and: [
            {
              operator: OdataFilterOperatorEnum.eq,
              value: action.payload,
              path: 'id',
              type: 'string' as const
            }
          ]
        }
      ]
    })
    const household = result?.value?.[0]

    if (!household) {
      throw new Error('Household not found')
    }

    yield put(householdFetchActions.success(household as IHousehold))
  } catch (e: any) {
    yield put(householdFetchActions.failure(e))
  }
}

export const householdFetchSags = [
  () => takeLatest(householdFetchActions.request, fetchHousehold)
]
