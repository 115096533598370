import { get } from 'lodash'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import { IEnvironmentApiConfiguration } from '../../../../../shared/services/environment/IEnvironmentConfiguration'
import { exportDataToExcel } from '../../../../../shared/xlsx'
import { tryAcquireAccessToken } from '../../../../../store/shared/sagas'
import { getRockefellerApiConfig } from '../../../../../store/system'
import { fetchUploadedFilesService } from '../../api/productServices'
import {
  deleteDocumentService,
  getAllCashReceiptsService,
  getAllocatedAmount,
  getAllProductTypesService,
  updateAllCashReceiptService,
  updateCashReceiptByProviderService,
  uploadCheckImageDocumentService,
  viewCashReceiptCheckService
} from '../api/ProductSettingService'
import {
  deleteDocumentActions,
  exportCashReceiptToExcelActions,
  fetchAllCashReceiptsActions,
  fetchAllocatedAmountActions,
  fetchAllProductTypeActions,
  fetchUploadedFilesAction,
  setSelectedCashReceiptActions,
  updateAllCashReceiptTypeActions,
  updateCashReceiptByProviderActions,
  uploadCheckImageActions,
  viewCashReceiptCheckImageActions
} from './actions'
import { getAllCashReceiptState } from './selectors'
import { IAllCashReceipt } from './types'
import { getAsOfDate, getPlanProviderName, getProductTypeName } from './utility'

function* handleFetchAllCashReceipts(
  action: ReturnType<typeof fetchAllCashReceiptsActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const data = yield* call(() =>
      getAllCashReceiptsService(action.payload, options)
    )

    yield put(fetchAllCashReceiptsActions.success(data || []))
  } catch (e: any) {
    yield put(fetchAllCashReceiptsActions.failure(e))
  }
}

function* handleFetchAllProducts() {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const data = yield* call(() => getAllProductTypesService(options))

    yield put(fetchAllProductTypeActions.success(data || []))
  } catch (e: any) {
    yield put(fetchAllProductTypeActions.failure(e))
  }
}

function* handleUpdateAllCashReceipts(
  action: ReturnType<typeof updateAllCashReceiptTypeActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    //filter only updated onces
    const { receipts } = (yield* select(getAllCashReceiptState)) || {}
    const filteredData = action.payload?.cashReceipts?.filter(
      (x) =>
        !receipts?.some(
          (y) =>
            y.producttypeid === x.producttypeid &&
            y.planproviderid === x.planproviderid &&
            y.offsetaccount === x.offsetaccount &&
            y.cashreceiptid === x.cashreceiptid &&
            y.selectedproducttypeid === x.selectedproducttypeid
        )
    )

    yield* call(() => updateAllCashReceiptService(filteredData, options))
    yield put(
      fetchAllCashReceiptsActions.request(action?.payload?.selectedMonthYear)
    )
  } catch (e: any) {
    yield put(updateAllCashReceiptTypeActions.failure(e))
  }
}

function* handleUploadCheckImageDoc(
  action: ReturnType<typeof uploadCheckImageActions.uploadDoc>
) {
  const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
    getRockefellerApiConfig
  )
  const token = yield* call(tryAcquireAccessToken, rockefellerApiConfig.scopes)
  const options: IApiOptions = {
    apiRoot: rockefellerApiConfig.root,
    accessToken: token
  }

  const { onUploadSuccess, onUploadFail, onUploadProgress, imageDoc } =
    action.payload
  try {
    yield call(
      uploadCheckImageDocumentService,
      imageDoc,
      options,
      onUploadProgress
    )
    onUploadSuccess(imageDoc.file)
    const data = yield* call(() =>
      fetchUploadedFilesService(
        imageDoc.producttypeid?.toString(),
        imageDoc.cashReceiptId?.toString(),
        options
      )
    )

    yield put(fetchUploadedFilesAction.success(data || []))
  } catch (e: any) {
    onUploadFail(imageDoc.file)
  }
}

function* handleViewCashReceiptCheckImage(
  action: ReturnType<typeof viewCashReceiptCheckImageActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const data = yield* call(() =>
      viewCashReceiptCheckService(action.payload, options)
    )

    yield put(viewCashReceiptCheckImageActions.success())
    const pdfWindow = window.open('', 'statementWindow')
    pdfWindow?.document.write(
      `<iframe width='100%' height='100%' src=${encodeURI(data)}
        ></iframe>`
    )
  } catch (e: any) {
    yield put(viewCashReceiptCheckImageActions.failure(e))
  }
}
function* handleFetchUploadedFiles(
  action: ReturnType<typeof fetchUploadedFilesAction.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const data = yield* call(() =>
      fetchUploadedFilesService(
        action.payload.productTypeid,
        action.payload.cashReceiptid,
        options
      )
    )

    yield put(fetchUploadedFilesAction.success(data || []))
  } catch (e: any) {
    yield put(fetchUploadedFilesAction.failure(e))
  }
}
function* handleDeleteDoc(
  action: ReturnType<typeof deleteDocumentActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    yield* call(() =>
      deleteDocumentService(options, action.payload.cashreceiptdocid)
    )

    yield put(deleteDocumentActions.success())
    const data = yield* call(() =>
      fetchUploadedFilesService(
        action.payload.productTypeid,
        action.payload.cashReceiptid,
        options
      )
    )

    yield put(fetchUploadedFilesAction.success(data || []))
  } catch (e: any) {
    yield put(deleteDocumentActions.failure(e))
  }
}

function* handleCashReceiptToExcel(
  action: ReturnType<typeof exportCashReceiptToExcelActions.request>
) {
  try {
    const { cashReceipts, columns, productTypes } = action.payload

    const filename = 'Cash_Receipt.xlsx'
    const wsName = 'Cash Receipt'
    const filteredcolumns = columns?.filter(
      (x) => x.key !== 'action' && x.key !== 'action1'
    )
    const data = cashReceipts?.map((cashReceipt) => {
      const excelCashReceipt = {
        ...cashReceipt,
        cashreceiptid: cashReceipt?.cashreceiptid,
        asofdate: getAsOfDate(cashReceipt?.asofdate),
        receivedfrom: cashReceipt?.accountname || '',
        account: cashReceipt?.accountnumber,
        transactiontype: cashReceipt?.trantype,
        cashreceivedamount: cashReceipt?.amount,
        totalsplitamount:
          cashReceipt?.selectedproducttypeid &&
          cashReceipt?.selectedproducttypeid !== null &&
          cashReceipt?.selectedproducttypeid.length > 1
            ? cashReceipt?.totalsplitamount
            : 0,
        offsetaccount: cashReceipt?.offsetaccount,
        producttype: getProductTypeName(
          productTypes,
          cashReceipt?.selectedproducttypeid || []
        ),
        planprovider: getPlanProviderName(
          cashReceipt.planProvidersOptions || [],
          cashReceipt?.selectedproducttypeid || [],
          cashReceipt.planproviderid?.toString() || ''
        )
      }
      return filteredcolumns?.map((column): unknown =>
        column.fieldName ? get(excelCashReceipt, column.fieldName) : ''
      )
    })

    data?.unshift(filteredcolumns?.map((x) => x.name))

    yield call(() =>
      exportDataToExcel(
        {
          sheets: [{ name: wsName, data: data || [] }]
        },
        filename
      )
    )
    yield put(exportCashReceiptToExcelActions.success())
  } catch (e: any) {
    yield put(exportCashReceiptToExcelActions.failure(e))
  }
}

function* handleUpdateCashReceiptByProvider(
  action: ReturnType<typeof updateCashReceiptByProviderActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )
    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )
    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }

    const data = yield* call(() =>
      updateCashReceiptByProviderService(action.payload.cashReceipt, options)
    )
    if (data.status === 'Failed') {
      yield put(updateCashReceiptByProviderActions.failure(data.message))
      yield put(setSelectedCashReceiptActions.updatedCashReceiptFlag(false))
    } else {
      yield put(updateCashReceiptByProviderActions.success())
      yield put(
        setSelectedCashReceiptActions.selectedCashReceipt(
          data.updatedcashreceipt
        )
      )
      yield put(setSelectedCashReceiptActions.updatedCashReceiptFlag(true))
    }
  } catch (e: any) {
    yield put(updateCashReceiptByProviderActions.failure(e))
  }
}

function* handleFetchAllocatedAmount(
  action: ReturnType<typeof fetchAllocatedAmountActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const {
      cashReceipt,
      selectedMonth
    }: { cashReceipt: IAllCashReceipt; selectedMonth: string | undefined } =
      action.payload
    const data = yield* call(() =>
      getAllocatedAmount(cashReceipt, selectedMonth, options)
    )

    yield put(fetchAllocatedAmountActions.success(data || []))
  } catch (e: any) {
    yield put(fetchAllocatedAmountActions.failure(e))
  }
}

export const sagas = [
  () =>
    takeLatest(fetchAllCashReceiptsActions.request, handleFetchAllCashReceipts),
  () => takeLatest(fetchAllProductTypeActions.request, handleFetchAllProducts),
  () =>
    takeLatest(
      updateAllCashReceiptTypeActions.request,
      handleUpdateAllCashReceipts
    ),
  () =>
    takeLatest(uploadCheckImageActions.uploadDoc, handleUploadCheckImageDoc),
  () =>
    takeLatest(
      viewCashReceiptCheckImageActions.request,
      handleViewCashReceiptCheckImage
    ),
  () => takeLatest(deleteDocumentActions.request, handleDeleteDoc),
  () => takeLatest(fetchUploadedFilesAction.request, handleFetchUploadedFiles),
  () =>
    takeLatest(
      exportCashReceiptToExcelActions.request,
      handleCashReceiptToExcel
    ),
  () =>
    takeLatest(
      updateCashReceiptByProviderActions.request,
      handleUpdateCashReceiptByProvider
    ),
  () =>
    takeLatest(fetchAllocatedAmountActions.request, handleFetchAllocatedAmount)
]
