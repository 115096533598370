import {
  BaseQueryFn,
  TypedUseQueryHookResult
} from '@reduxjs/toolkit/dist/query/react'

const defaultResult = {
  isUninitialized: true,
  isError: false,
  isFetching: false,
  isLoading: false,
  isSuccess: false,
  data: undefined,
  error: undefined,
  currentData: undefined,
  refetch: (() => Promise.resolve(undefined)) as any
} as TypedUseQueryHookResult<unknown, unknown, BaseQueryFn>

export const getFixedResult = <T, U, V>(
  request: false | undefined | V,
  result: TypedUseQueryHookResult<T, U, BaseQueryFn>
) => {
  if (!request) {
    return defaultResult as TypedUseQueryHookResult<T, U, BaseQueryFn>
  }

  if (result?.isError) {
    return {
      ...result,
      data: undefined
    }
  }

  return result
}
