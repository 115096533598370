import { css, useTheme } from '@emotion/react'
import { Callout, DirectionalHint } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { useCallback, useRef } from 'react'
import { Icon } from '../../../features/Icons/Icon'

const MenuItem = css({
  padding: '10px',
  display: 'flex',
  cursor: 'pointer',
  justifyContent: 'space-between',
  alignItems: 'center',
  ':hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  }
})

export const DownloadMenuCallout: React.FC<{
  onIndividualDownloadClick: () => void
  onCombinedDownloadClick: () => void
  onPrintClick: () => void
  selection: string[]
}> = ({
  onIndividualDownloadClick,
  onCombinedDownloadClick,
  onPrintClick,
  selection
}) => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false)
  const downloadMenuId = useRef<HTMLDivElement>(null)
  const onIndividualDownload = useCallback(() => {
    onIndividualDownloadClick()
    toggleIsCalloutVisible()
  }, [onIndividualDownloadClick, toggleIsCalloutVisible])

  const onCombinedDownload = useCallback(() => {
    onCombinedDownloadClick()
    toggleIsCalloutVisible()
  }, [onCombinedDownloadClick, toggleIsCalloutVisible])

  const theme = useTheme()
  return (
    <>
      <div>
        <Icon
          height={24}
          width={24}
          type={'Print'}
          onClick={onPrintClick}
          color={theme.colors.extraBlue2}
        />
      </div>
      <div ref={downloadMenuId}>
        <Icon
          type="Download"
          height={24}
          width={24}
          onClick={toggleIsCalloutVisible}
          color={theme.colors.extraBlue2}
        />
      </div>
      {isCalloutVisible && (
        <Callout
          target={downloadMenuId}
          onDismiss={toggleIsCalloutVisible}
          directionalHint={DirectionalHint.bottomRightEdge}
          styles={{
            calloutMain: {
              borderRadius: '8px',
              border: `1px solid ${theme.colors.panelCalloutBorder}`,
              background: 'white',
              boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.2)',
              width: '170px'
            }
          }}
          isBeakVisible={false}
        >
          <div>
            <div
              css={{
                padding: '10px',
                fontWeight: 'bold',
                backgroundColor: '#EDF1F4',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <div>Download</div>
              <Icon
                type={'CancelClose'}
                height={12}
                width={12}
                onClick={toggleIsCalloutVisible}
                color={theme.colors.primaryBlack}
              />
            </div>
            <div css={MenuItem} onClick={onIndividualDownload}>
              Individual PDFs ({selection?.length})
            </div>
            <div css={MenuItem} onClick={onCombinedDownload}>
              Combined PDF
            </div>
          </div>
        </Callout>
      )}
    </>
  )
}
