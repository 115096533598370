import { Theme, css, useTheme } from '@emotion/react'
import {
  flexRender,
  getCoreRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  Row
} from '@tanstack/react-table'
import { sum } from 'lodash'
import { SnackBar } from 'modules/Advisory/modules/Rdot360/components/shared/Snackbar'
import { useIncomeFeatureStore } from 'modules/Advisory/modules/Rdot360/features/Income'
import { ITableHeaderColumnSize } from 'modules/Advisory/modules/Rdot360/shared/ITableHeaderColumnSize'
import { SortIndicator } from 'modules/Advisory/modules/Rdot360/shared/SortIndicator'
import {
  rdot360TableStyles,
  useRdot360ThemedTableStyles
} from 'modules/Advisory/modules/Rdot360/shared/tableStyles'
import { constants } from 'modules/Advisory/modules/Rdot360/shared/theme'
import { IIncomeSummaryResponseSubTotal } from 'modules/Advisory/modules/Rdot360/store/holdingsApi/IIncomeSummaryResponse'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { HorizontalScrollContainer } from 'shared/components/HorizontalScrollContainer'
import { useDebounce } from 'shared/hooks/useDebounce'
import { useWidthObserver } from 'shared/hooks/useResizeObserver'
import { IndeterminateProgressIndicator } from '../../../../components/shared/ProgressIndicator/IndeterminateProgressIndicator'
import { useIncomeDetailsUiState } from '../../store'
import { DetailsRow } from './DetailsRow'
import { getColumns } from './IncomeColumns'

export const getClasses = (theme: Theme) => ({
  expandAllLabel: css({
    color: '#4C9DA8',
    cursor: 'pointer'
  }),
  noDataAvailableCell: css({
    height: '100%',
    textAlign: 'center',
    background: theme.colors.secondaryWhite,
    border: `1px solid ${theme.colors.extraGray1}`
  })
})

const getAdjustedHeaderSizes = (
  sizes: ITableHeaderColumnSize[],
  tableWidth: number,
  depth = 0,
  firstColumnOffset = 5,
  lastColumnOffset = 5
) => {
  const totalSize = sum(sizes.map(({ width }) => width))
  const ratio = tableWidth ? tableWidth / totalSize : 1
  const getColumnOffset = (isFirst: boolean) =>
    isFirst ? firstColumnOffset : lastColumnOffset

  return sizes.map(({ width, id }, i) => {
    const isFirst = i === 0
    const isLast = i === sizes.length - 1
    const depthAdjustment =
      isFirst || isLast ? depth * getColumnOffset(isFirst) : 0

    return { id, width: width * ratio - depthAdjustment }
  })
}

const TableHeaderSizesRow: React.FC<{
  sizes: ITableHeaderColumnSize[]
}> = ({ sizes }) => {
  return (
    <tr css={[rdot360TableStyles.sizesRow]}>
      {sizes.map(({ width, id }) => {
        return (
          <th
            key={id}
            css={{
              width,
              maxWidth: width
            }}
          />
        )
      })}
    </tr>
  )
}

const TableBody: React.FC<{
  rows: Row<any>[]
  getHeaderSizes: (depth?: number) => ITableHeaderColumnSize[]
  depth?: number
}> = ({ rows, getHeaderSizes, depth = 0 }) => {
  const sizes = getHeaderSizes(depth)

  return (
    <table css={[rdot360TableStyles.table]}>
      <thead>
        <TableHeaderSizesRow sizes={sizes} />
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr
            key={index}
            css={{
              paddingRight: '4px'
            }}
          >
            <td
              colSpan={row.getVisibleCells().length + 1}
              style={{
                padding: 0,
                paddingBottom: 6
              }}
            >
              <DetailsRow row={row} rowIndex={index} sizes={sizes} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export const AccountDataTable: FC<{
  data?: IIncomeSummaryResponseSubTotal[]
  isLoading?: boolean
  isUninitialized?: boolean
}> = ({ data, isLoading = false, isUninitialized }) => {
  const { searchText, setSearchText } = useIncomeDetailsUiState()
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'TotalInt', desc: true }
  ])
  const { incomeMode, includeOptionPremiums } = useIncomeFeatureStore()
  const isHistorical = incomeMode === 'historical'
  const debouncedSearchText = useDebounce(searchText, 100)
  const columns = useMemo(
    () => getColumns(debouncedSearchText, isHistorical, includeOptionPremiums),
    [debouncedSearchText, isHistorical, includeOptionPremiums]
  )
  const accountData = useMemo(() => data || [], [data])

  const table = useReactTable({
    data: accountData,
    columns,
    state: {
      sorting,
      globalFilter: debouncedSearchText
    },
    enableColumnResizing: true,
    globalFilterFn: (
      row: Row<any>,
      id: string,
      filterValue: string
    ): boolean => {
      const searchString = (filterValue || '').toLowerCase()

      return (
        row.original.ACCT?.toLowerCase().includes(searchString) ||
        row.original.SECNAME?.toLowerCase().includes(searchString) ||
        row.original.CUSIPNUM?.toLowerCase().includes(searchString) ||
        row.original.SECDESCRIPTION?.toLowerCase().includes(searchString) ||
        row.original.IncomeDetail?.some(
          (item: any) =>
            item.SECNAME?.toLowerCase().includes(searchString) ||
            item.CUSIPNUM?.toLowerCase().includes(searchString) ||
            item.SECDESCRIPTION?.toLowerCase().includes(searchString)
        )
      )
    },
    columnResizeMode: 'onChange',
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    autoResetExpanded: false,
    onGlobalFilterChange: setSearchText,
    getSubRows: (row: any): any => {
      const subRows = row.IncomeDetail
      return subRows
    }
  })

  const rows = table.getPreExpandedRowModel().rows
  const headers = table.getFlatHeaders()

  const tableRefContainer = useRef<HTMLDivElement>(null)
  const containerWidth = useWidthObserver(tableRefContainer)
  const tableWidth = Math.max(1450, containerWidth || 0)

  const getHeaderSizes = useCallback(
    (depth?: number) => {
      const sizes = getAdjustedHeaderSizes(
        headers.map((x) => ({ id: x.id || '', width: x.getSize() || 0 })),
        // -2 for the border around the table
        tableWidth - 2,
        depth
      )
      return sizes
    },
    [headers, tableWidth]
  )

  const themedStyles = useRdot360ThemedTableStyles()

  const renderTableHeaderTow = () => {
    return table.getHeaderGroups().map((headerGroup) => (
      <tr
        key={headerGroup.id}
        css={{ position: 'sticky', top: constants.headerOffsetPx }}
      >
        {headerGroup.headers.map((header, index) => {
          return (
            <th
              key={header.id}
              colSpan={header.colSpan}
              css={{
                cursor: header.column.getCanSort() ? 'pointer' : 'default',
                backgroundColor: '#EDF1F4',
                color: '#06262D',
                padding: '20px 8px',
                position: 'sticky',
                top: constants.headerOffsetPx,
                textAlign: index > 2 ? 'right' : 'left',
                verticalAlign: 'top',
                '&:first-of-type': {
                  paddingLeft: '20px'
                },
                '&:last-of-type': {
                  paddingRight: '20px'
                }
              }}
            >
              {header.isPlaceholder ? null : (
                <div
                  {...{
                    className: header.column.getCanSort()
                      ? 'cursor-pointer select-none'
                      : '',
                    onClick: header.column.getToggleSortingHandler()
                  }}
                  css={{
                    display: 'inline-flex',
                    justifyContent: index > 2 ? 'right' : 'left'
                  }}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                  {header.column.getCanSort() && (
                    <SortIndicator direction={header.column.getIsSorted()} />
                  )}
                </div>
              )}
            </th>
          )
        })}
      </tr>
    ))
  }

  const theme = useTheme()
  const classes = useMemo(() => getClasses(theme), [theme])

  return (
    <HorizontalScrollContainer>
      <div css={{ minWidth: '1720px' }}>
        <div css={{ marginBottom: 16 }}>
          <a
            onClick={(e) => {
              table.toggleAllRowsExpanded()
              e.preventDefault()
            }}
            href="#"
            css={classes.expandAllLabel}
          >
            {table.getIsAllRowsExpanded() ? 'Collapse All' : 'Expand All'}
          </a>
        </div>
        {isLoading && <IndeterminateProgressIndicator />}
        <div
          css={[
            rdot360TableStyles.headerContainer,
            themedStyles.headerContainer
          ]}
        >
          <table css={rdot360TableStyles.table}>
            <thead>
              <TableHeaderSizesRow sizes={getHeaderSizes()} />
              {renderTableHeaderTow()}
            </thead>
          </table>
        </div>
        <div css={[themedStyles.bodyContainer]}>
          <TableBody rows={rows} getHeaderSizes={getHeaderSizes} />
          <div
            css={[
              rdot360TableStyles.headerContainer,
              themedStyles.headerContainer,
              { bottom: 0 }
            ]}
          >
            <table css={[rdot360TableStyles.table]}>
              <thead>
                <TableHeaderSizesRow sizes={getHeaderSizes()} />
              </thead>
              {!(isUninitialized || isLoading) && data?.length === 0 ? (
                <tfoot css={{ height: '100%' }}>
                  <tr css={{ height: '100%' }}>
                    <td
                      colSpan={columns.length}
                      css={classes.noDataAvailableCell}
                    >
                      <SnackBar message={'No data available'} type="Info" />
                    </td>
                  </tr>
                </tfoot>
              ) : (
                <tfoot>
                  <tr
                    css={[
                      rdot360TableStyles.bodyRow,
                      rdot360TableStyles.l2GroupRow,
                      themedStyles.totalRow,
                      {
                        'td:last-of-type': {
                          paddingRight: 20
                        }
                      }
                    ]}
                  >
                    {headers.map((header) => (
                      <td key={header.id}>
                        {header.column.columnDef.footer
                          ? flexRender(
                              header.column.columnDef.footer,
                              header.getContext()
                            )
                          : null}
                      </td>
                    ))}
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
        </div>
      </div>
    </HorizontalScrollContainer>
  )
}
