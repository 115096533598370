import { combineReducers, Reducer } from 'redux'
import {
  distributionReducer,
  IDistributionsState
} from '../features/DiscretionaryDistribution/store/reducer'
import {
  INewDistributionState,
  newdistributionreducer
} from '../features/NewDistribution/store/reducers'

import {
  IStatementModuleState,
  statementsReducer
} from '../features/Statements/store/reducer'
import {
  IAccountsState,
  accountsReducer
} from '../features/TrustDetail/modules/Accounts/store/reducers'
import {
  IBeneficiaryState,
  beneficiaryReducer
} from '../features/TrustDetail/modules/Beneficiaries/store/reducers'
import {
  ITrustAumSummaryState,
  trustAumSummaryReducer
} from '../features/TrustDetail/modules/DashBoard/AUM/store/reducers'
import {
  IClientSummaryState,
  clientsummaryReducer
} from '../features/TrustDetail/modules/DashBoard/Clients/store/reducers'
import {
  ITrustDashboardInfoState,
  trustDashboardInfoReducer
} from '../features/TrustDetail/modules/DashBoard/store/reducers'
import {
  IGrantorState,
  grantorReducer
} from '../features/TrustDetail/modules/Grantors/store/reducers'
import {
  ITrusteesState,
  trusteesReducer
} from '../features/TrustDetail/modules/Trustee/store/reducers'
import {
  ITrustInfoState,
  trustInfoReducer
} from '../features/TrustDetail/modules/TrustInfo/store/reducers'

export interface ITrustModuleState {
  distributions: IDistributionsState
  newDistribution: INewDistributionState
  trustInfo: ITrustInfoState
  trustees: ITrusteesState
  grantor: IGrantorState
  beneficiary: IBeneficiaryState
  accounts: IAccountsState
  dashboard: ITrustDashboardInfoState
  dashboardClientSummary: IClientSummaryState
  dashboardTrustsAumSummary: ITrustAumSummaryState
  statements: IStatementModuleState
}

export const trustreducer: Reducer<ITrustModuleState> = combineReducers({
  distributions: distributionReducer,
  newDistribution: newdistributionreducer,
  trustInfo: trustInfoReducer,
  trustees: trusteesReducer,
  grantor: grantorReducer,
  beneficiary: beneficiaryReducer,
  accounts: accountsReducer,
  dashboard: trustDashboardInfoReducer,
  dashboardClientSummary: clientsummaryReducer,
  dashboardTrustsAumSummary: trustAumSummaryReducer,
  statements: statementsReducer
})
