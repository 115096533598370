import { ChoiceGroup, IChoiceGroupOption, Stack, Toggle } from '@fluentui/react'
import React, { memo, useCallback, useMemo } from 'react'
import { IListsFilter } from '../../../contracts/IListsFilter'
import { ListsFacetActions } from '../../../store/actions'
import { ListsFacetSelectors } from '../../../store/selectors'
import { ListsDateRangeFilterComponent } from '../components/ListsDateRangeFilter'
import { ListsNumberRangeFilterComponent } from '../components/ListsNumberRangeFilter'
import { ListsSearchFilterV2 } from '../components/ListsSearchFilterV2'
import { IListsFilterEditProps } from '../contracts/IListsFilterEditProps'
import { createConnectedListsFacetFilter } from './ListsFacetFilter'

export const createListsFilterEditContainer = (
  facetSelectors: ListsFacetSelectors,
  facetActions: ListsFacetActions
) => {
  const ListsFilterEditContainer: React.FC<IListsFilterEditProps> = ({
    onChange,
    filter
  }) => {
    const { type } = filter
    const FilterComponent: React.FC<IListsFilterEditProps> = useMemo(() => {
      switch (type) {
        case 'date':
        case 'date-only':
          return memo(
            ListsDateRangeFilterComponent
          ) as React.FC<IListsFilterEditProps>
        case 'number':
          return memo(
            ListsNumberRangeFilterComponent
          ) as React.FC<IListsFilterEditProps>
        case 'search':
          return memo(ListsSearchFilterV2) as React.FC<IListsFilterEditProps>
        case 'facet':
          return memo(
            createConnectedListsFacetFilter(facetActions, facetSelectors)
          ) as React.FC<IListsFilterEditProps>
        case 'boolean':
          return () => null
      }
    }, [type])

    const { blankIndicator } = filter

    const onBlankIndicatorChange = useCallback(
      (ev: any, checked?: boolean) => {
        if (checked == null) {
          return
        }
        const newFilter: IListsFilter = {
          ...filter,
          blankIndicator: checked ? 'include' : undefined,
          hasValue: checked
        }
        onChange(newFilter)
      },
      [filter, onChange]
    )

    const onBlankIndicatorChoiceChange = useCallback(
      (ev: any, option?: IChoiceGroupOption) => {
        if (!option) {
          return
        }

        const newFilter: IListsFilter = {
          ...filter,
          blankIndicator: option.key as any
        }
        onChange(newFilter)
      },
      [filter, onChange]
    )

    return (
      <Stack tokens={{ childrenGap: 10 }}>
        <Toggle
          label="Filter for (Blank)"
          checked={!!blankIndicator}
          onChange={onBlankIndicatorChange}
        />
        {blankIndicator && (
          <ChoiceGroup
            selectedKey={blankIndicator}
            onChange={onBlankIndicatorChoiceChange}
            options={[
              { key: 'include', text: 'Include (Blank)' },
              { key: 'exclude', text: 'Exclude (Blank)' }
            ]}
          />
        )}
        {!blankIndicator && (
          <FilterComponent onChange={onChange} filter={filter} />
        )}
      </Stack>
    )
  }

  return ListsFilterEditContainer
}
