import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  Overlay,
  PrimaryButton,
  Stack
} from '@fluentui/react'
import { INfsProfile } from 'api/datahub'
import { ErrorComponent } from 'shared/components/Error'
import {
  IClientInvitePanelType,
  useClientInvitePanel
} from './store/clientInvitePanel'

export const InviteFooter: React.FC<{
  isFetchLoading?: boolean
  isSendInviteLoading?: boolean
  error?: Error
  isAlreadyInvited?: boolean
  panelType?: IClientInvitePanelType
  data?: INfsProfile
  hasPhones?: boolean
  onDismiss?: () => void
}> = ({
  isFetchLoading,
  isSendInviteLoading,
  error,
  isAlreadyInvited,
  panelType,
  data,
  hasPhones,
  onDismiss
}) => {
  const { showValidationError } = useClientInvitePanel()
  return (
    <>
      {(isFetchLoading || isSendInviteLoading) && (
        <Overlay styles={{ root: { zIndex: 1 } }} />
      )}
      <Stack tokens={{ childrenGap: 10 }}>
        {error && (
          <MessageBar messageBarType={MessageBarType.error}>
            {error?.message}
          </MessageBar>
        )}
        {isAlreadyInvited && (
          <MessageBar messageBarType={MessageBarType.error}>
            The profile was already invited
          </MessageBar>
        )}
        {showValidationError && (
          <ErrorComponent errorMessage="Please fill in all required fields." />
        )}
        {panelType === 'reinvite' && (
          <MessageBar messageBarType={MessageBarType.severeWarning}>
            Are you sure you want to delete and reinvite client. All
            Collaboration folder contents will be deleted.
          </MessageBar>
        )}
        {!isFetchLoading && !data?.emailprimary && (
          <MessageBar messageBarType={MessageBarType.warning}>
            An email for this client is not configured in Wealthscape
          </MessageBar>
        )}
        {!isFetchLoading && !hasPhones && (
          <MessageBar messageBarType={MessageBarType.warning}>
            A phone number for this client is not configured in Wealthscape
          </MessageBar>
        )}
        <Stack
          horizontal={true}
          tokens={{ childrenGap: 10 }}
          horizontalAlign="space-between"
        >
          <Stack
            horizontal={true}
            tokens={{ childrenGap: 10 }}
            verticalAlign="center"
          >
            <PrimaryButton
              type="submit"
              form="inviteForm"
              disabled={
                isAlreadyInvited || !data || isFetchLoading || !hasPhones
              }
            >
              Submit
            </PrimaryButton>

            {onDismiss && (
              <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}
