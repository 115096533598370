import {
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  subMonths
} from 'date-fns'
import { flow, range } from 'lodash'
import { createSelector } from 'reselect'
import { put, select, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { RevenueSummaryCategoryMode } from '../../../../../../../api/datahub'
import { selectUserHasAccessToPayout } from '../../../../../../../features/Domain/store/domain'
import { DateRanges } from '../../../../../../../shared'
import { AppState } from '../../../../../../../store'
import { CreditEventColumnIds } from '../features/CreditEventList/ColumnDefinitions'
import {
  actions,
  createCreditEventListFilter
} from '../features/CreditEventList/store'

const SET_CATEGORY_MODE =
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@shared/@dashboard/SET_CATEGORY_MODE'
const SET_DATE_RANGE =
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@shared/@dashboard/SET_DATE_RANGE'
const SET_SELECTED_CATEGORY =
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@shared/@dashboard/SET_SELECTED_CATEGORY'
const SET_SELECTED_MONTH =
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@shared/@dashboard/SET_SELECTED_MONTH'
const SET_REVENUE_OR_PAYOUT =
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@shared/@dashboard/SET_REVENUE_OR_PAYOUT'
const SET_CUSTOM_DATES =
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@shared/@dashboard/SET_CUSTOM_DATES'

export interface IRevenueSummaryCategorySelection {
  type: RevenueSummaryCategoryMode
  category: string
}

export type RevenueOrPayout = 'revenue' | 'payout'

export const revenueDashboardActions = {
  setCategoryMode:
    createAction(SET_CATEGORY_MODE)<RevenueSummaryCategoryMode>(),
  setDateRange: createAction(SET_DATE_RANGE)<string>(),
  setSelectedCategory: createAction(SET_SELECTED_CATEGORY)<
    IRevenueSummaryCategorySelection | undefined
  >(),
  setSelectedMonth: createAction(SET_SELECTED_MONTH)<Date | undefined>(),
  setRevenueOrPayout: createAction(SET_REVENUE_OR_PAYOUT)<RevenueOrPayout>(),
  setCustomDates: createAction(SET_CUSTOM_DATES)<[Date, Date]>()
}

export interface IRevenueDashboardState {
  categoryMode?: RevenueSummaryCategoryMode
  selectedCategory?: IRevenueSummaryCategorySelection
  dateRange?: string
  selectedMonth?: Date
  revenueOrPayout?: RevenueOrPayout
  customDates?: [Date, Date]
}

const initialState: IRevenueDashboardState = {
  categoryMode: 'L1',
  dateRange: 'Prior 12 Months',
  revenueOrPayout: 'revenue'
}

export const revenueDashboardReducer = createReducer<
  IRevenueDashboardState,
  ActionType<typeof revenueDashboardActions>
>(initialState)
  .handleAction(revenueDashboardActions.setCategoryMode, (state, action) => ({
    ...state,
    categoryMode: action.payload
  }))
  .handleAction(revenueDashboardActions.setDateRange, (state, action) => ({
    ...state,
    dateRange: action.payload
  }))
  .handleAction(
    revenueDashboardActions.setSelectedCategory,
    (state, action) => ({ ...state, selectedCategory: action.payload })
  )
  .handleAction(revenueDashboardActions.setSelectedMonth, (state, action) => ({
    ...state,
    selectedMonth: action.payload
  }))
  .handleAction(
    revenueDashboardActions.setRevenueOrPayout,
    (state, action) => ({
      ...state,
      revenueOrPayout: action.payload
    })
  )
  .handleAction(revenueDashboardActions.setCustomDates, (state, action) => ({
    ...state,
    customDates: action.payload
  }))

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.revenue.modules.dashboard.dashboard

export const getRevenueDashboardCategoryMode = flow(
  rootSelector,
  (state) => state.categoryMode
)

export const getRevenueDashboardDateRange = flow(
  rootSelector,
  (state) => state.dateRange
)

export const getRevenueOrPayout = createSelector(
  [
    selectUserHasAccessToPayout,
    flow(rootSelector, ({ revenueOrPayout }) => revenueOrPayout)
  ],
  (userHasAccessToPayout, revenueOrPayout) =>
    userHasAccessToPayout ? revenueOrPayout : 'revenue'
)

export const getCustomDates = flow(rootSelector, (state) => state.customDates)

export interface IRevenueDashboardDateRangeOption {
  key: string
  value?: string
  type?: 'YEAR'
  label: string
}

export const getRevenueDashboardDateRangeOptions = () => {
  const currentYear = new Date().getFullYear()
  const options: IRevenueDashboardDateRangeOption[] = [
    {
      key: 'Prior 12 Months',
      label: 'Prior 12 Months'
    },
    ...range(currentYear, 2018, -1).map(
      (x): IRevenueDashboardDateRangeOption => ({
        key: x.toString(),
        type: 'YEAR',
        value: x.toString(),
        label: x.toString()
      })
    )
  ]

  return options
}

export const getRevenueDashboardDateRangeDates = createSelector(
  [getRevenueDashboardDateRange, getCustomDates],
  (range, customDates): [Date, Date] | undefined => {
    const options = getRevenueDashboardDateRangeOptions()
    const [option] = options.filter(({ key }) => range === key)
    if (!option) {
      if (range === 'Custom') {
        const now = new Date()
        const startDate = customDates?.[0]
          ? startOfMonth(customDates?.[0])
          : subMonths(startOfMonth(now), 11)
        const endDate = customDates?.[1]
          ? endOfMonth(customDates?.[1])
          : endOfMonth(now)
        return [startDate, endDate]
      } else {
        return
      }
    }

    if (option.type === 'YEAR' && option.value) {
      const year = parseInt(option.value)
      const start = new Date(year, 0, 1)

      return [startOfYear(start), endOfYear(start)]
    }

    if (option.key === 'Prior 12 Months') {
      const now = new Date()
      const startTz = subMonths(startOfMonth(now), 11)
      const endTz = endOfMonth(now)

      return [startTz, endTz]
    }
  }
)

export const revenueDashboardSharedSagas = [
  () =>
    takeLatest(revenueDashboardActions.setDateRange, function* () {
      const dateRange: [Date, Date] | undefined = yield* select(
        getRevenueDashboardDateRangeDates
      )
      if (!dateRange) {
        return
      }
      const filter = createCreditEventListFilter(
        CreditEventColumnIds.periodTimestamp,
        {
          type: 'date-only',
          range: 'Custom' as DateRanges,
          from: dateRange[0],
          to: dateRange[1]
        }
      )

      yield put(actions.uiActions.setFilter(filter))
      return
    }),
  () =>
    takeLatest(
      revenueDashboardActions.setSelectedMonth,
      function* (
        action: ReturnType<typeof revenueDashboardActions.setSelectedMonth>
      ) {
        if (!action.payload) {
          const [from, to] =
            (yield* select(getRevenueDashboardDateRangeDates)) || []

          const filter = createCreditEventListFilter(
            CreditEventColumnIds.periodTimestamp,
            {
              type: 'date-only',
              range: 'Custom' as DateRanges,
              from,
              to
            }
          )

          yield put(actions.uiActions.setFilter(filter))
          return
        }

        const monthDateTz = action.payload
        const end = endOfMonth(monthDateTz)
        const start = startOfMonth(monthDateTz)
        const filter = createCreditEventListFilter(
          CreditEventColumnIds.periodTimestamp,
          {
            type: 'date-only',
            range: 'Custom' as DateRanges,
            from: start,
            to: end
          }
        )

        yield put(actions.uiActions.setFilter(filter))
      }
    ),
  () =>
    takeLatest(
      revenueDashboardActions.setSelectedCategory,
      function* (
        action: ReturnType<typeof revenueDashboardActions.setSelectedCategory>
      ) {
        yield put(
          actions.uiActions.removeFilters([
            CreditEventColumnIds.revenueCategoryLvl1,
            CreditEventColumnIds.revenueCategoryLvl2
          ])
        )
        if (!action.payload) {
          return
        }
        const { type, category } = action.payload
        const addColumnId =
          type === 'L1'
            ? CreditEventColumnIds.revenueCategoryLvl1
            : CreditEventColumnIds.revenueCategoryLvl2
        const filter = createCreditEventListFilter(addColumnId, {
          type: 'facet',
          values: [category]
        })
        yield put(actions.uiActions.setFilter(filter))
      }
    )
]
