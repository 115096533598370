import { useTheme, css } from '@emotion/react'
import {
  Icon as Icon1,
  Label,
  MessageBar,
  MessageBarType,
  ProgressIndicator
} from '@fluentui/react'
import { IDropdownOption } from '@fluentui/react/lib/Dropdown'
import {
  format,
  setDate,
  endOfMonth,
  differenceInCalendarMonths,
  subMonths,
  startOfMonth,
  addMonths
} from 'date-fns'
import { groupBy } from 'lodash'
import { Icon as Icon2 } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { useMemo, useState } from 'react'
import DetailsNavigator from '../../components/shared/DetailsNavigator/DetailsNavigator'
import Toggle from '../../components/shared/Toggle'
import { ChartDropDown } from '../../features/Revenue/ChartDropDown'
import { useRevenueChartDateStore } from '../../features/Revenue/revenueChartDateStore'
import { useRevenueDetailFilterStore } from '../../features/Revenue/store'
import { useGetRevenueSummaryChartData } from '../../features/Revenue/useRevenueContext'
import { IRevenueHistoryItem } from '../../store/datahub'
import { RevenueDetailList } from './RevenueDetailList'
import { RevenueDetailTable } from './RevenueDetailTable'

const getClasses = () => ({
  iconStyle: css({
    display: 'inline-block',
    cursor: 'pointer',
    border: '1px solid #b8d5ed',
    borderRadius: 9,
    height: 18,
    width: 18,
    fontSize: 8,
    textAlign: 'center',
    backgroundColor: 'white',
    marginRight: 12
  }),
  groupItem: css({
    backgroundColor: '#eff6fb',
    color: '#13272b',
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '19px',
    padding: '10px 5px',
    border: '1px solid #6fa3c5',
    cursor: 'pointer',
    alignItems: 'center',
    boxShadow: '0px 2px 4px rgb(0 0 0 / 10%)',
    paddingLeft: 20,
    display: 'flex',
    input: {
      width: 15,
      height: 15
    },
    "input[type='checkbox' i]": {
      backgroundColor: 'white'
    }
  })
})

const getDates = (months: number) => {
  const today = new Date()
  const startDate = setDate(addMonths(today, -months), 1)
  return { startDate: startDate, endDate: today }
}

export const RevenueDetailContainer: React.FC = () => {
  const classes = getClasses()
  const theme = useTheme()
  const { setFilters } = useRevenueDetailFilterStore()

  const options: IDropdownOption[] = [
    { key: 'summary', text: 'Summary' },
    { key: 'detail', text: 'Detailed' }
  ]
  const { customDateRange, dateRange: dateRangeStore } =
    useRevenueChartDateStore()
  const [revenueTableStartDate, revenueTableEndDate] = useMemo(() => {
    if (dateRangeStore === 'Prior 12 Months') {
      setFilters({
        periodStart: getDates(11).startDate,
        periodEnd: new Date(),
        category: undefined,
        subCategory: undefined
      })
      return [getDates(11).startDate, new Date()]
    } else if (
      dateRangeStore === 'Custom' &&
      customDateRange !== undefined &&
      customDateRange[0] !== undefined &&
      customDateRange[1] !== undefined
    ) {
      setFilters({
        periodStart: setDate(customDateRange[0], 1),
        periodEnd: setDate(
          customDateRange[1],
          endOfMonth(customDateRange[1]).getDate()
        ),
        category: undefined,
        subCategory: undefined
      })
      return [
        setDate(customDateRange[0], 1),
        setDate(customDateRange[1], endOfMonth(customDateRange[1]).getDate())
      ]
    } else if (
      dateRangeStore !== 'Custom' &&
      dateRangeStore !== 'Prior 12 Months'
    ) {
      setFilters({
        periodStart: new Date(`${dateRangeStore}/01/01`),
        periodEnd: new Date(`${dateRangeStore}/12/31`),
        category: undefined,
        subCategory: undefined
      })
      return [
        new Date(`${dateRangeStore}/01/01`),
        new Date(`${dateRangeStore}/12/31`)
      ]
    } else {
      setFilters({
        periodStart: getDates(12).startDate,
        periodEnd: getDates(12).endDate,
        category: undefined,
        subCategory: undefined
      })
      return [getDates(12).startDate, getDates(12).endDate]
    }
  }, [customDateRange, dateRangeStore, setFilters])
  const { data, isFetching, invalidateCache, error } =
    useGetRevenueSummaryChartData(
      format(revenueTableStartDate ?? getDates(12).startDate, 'yyyy-MM-dd'),
      format(revenueTableEndDate ?? new Date(), 'yyyy-MM-dd'),
      'detail'
    )

  const dateRange: Date[] = useMemo(() => {
    const temp = []
    const difference = differenceInCalendarMonths(
      revenueTableEndDate,
      revenueTableStartDate
    )
    for (let i = difference || 12; i > 0; i--) {
      temp.push(startOfMonth(subMonths(revenueTableEndDate ?? new Date(), i)))
    }
    temp.push(startOfMonth(revenueTableEndDate ?? new Date()))
    return temp
  }, [revenueTableEndDate, revenueTableStartDate])

  const categories = useMemo(() => {
    return groupBy(data, (item) => item.assetType1)
  }, [data])

  const categoryDateData = useMemo(() => {
    const tempCategoryDateData: {
      [key: string]: {
        [key: string]: IRevenueHistoryItem[]
      }
    } = {}
    Object.keys(categories).forEach((category) => {
      tempCategoryDateData[category] = groupBy(categories[category], (asset) =>
        new Date(asset.payrollPeriodDate).toDateString()
      )
    })
    return tempCategoryDateData
  }, [categories])

  const detailedCategoryDateData = useMemo(() => {
    const tempDetailedCategoryDateData: {
      [key: string]: {
        dateData: { [key: string]: IRevenueHistoryItem[] }
        subCategory: { [Key: string]: IRevenueHistoryItem[] }
      }
    } = {}
    Object.keys(categoryDateData).forEach((category) => {
      tempDetailedCategoryDateData[category] = {
        dateData: categoryDateData[category],
        subCategory: groupBy(categories[category], (asset) => asset.assetType)
      }
    })
    return tempDetailedCategoryDateData
  }, [categories, categoryDateData])

  const detailedData = useMemo(() => {
    const tempDetailedData: {
      [key: string]: {
        dateData: { [key: string]: IRevenueHistoryItem[] }
        subCategory: {
          [key: string]: { [key: string]: IRevenueHistoryItem[] }
        }
      }
    } = {}
    Object.keys(detailedCategoryDateData).forEach((category) => {
      Object.keys(detailedCategoryDateData[category].subCategory).forEach(
        (subCat) => {
          tempDetailedData[category] = {
            dateData: detailedCategoryDateData[category].dateData,
            subCategory: {
              ...tempDetailedData[category]?.subCategory,
              [subCat]: groupBy(
                detailedCategoryDateData[category].subCategory[subCat],
                (asset) => new Date(asset.payrollPeriodDate).toDateString()
              )
            }
          }
        }
      )
    })
    return tempDetailedData
  }, [detailedCategoryDateData])

  const [isExpanded, setIsExpanded] = useState(true)
  const [isSubCategory, setIsSubCategory] = useState(false)
  return (
    <div>
      <DetailsNavigator />
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'end',
          width: '100%'
        }}
      >
        <Icon2
          type="Refresh"
          width={24}
          height={24}
          onClick={invalidateCache}
          color={theme.colors.extraBlue2}
        />
      </div>
      <div>
        <div
          css={[
            classes.groupItem,
            { marginTop: '20px', justifyContent: 'space-between' }
          ]}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <div
            css={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {!isExpanded ? (
              <span css={[classes.iconStyle]}>
                <Icon1 iconName="ChevronUpSmall" />
              </span>
            ) : (
              <span css={[classes.iconStyle]}>
                <Icon1 iconName="ChevronDownSmall" />
              </span>
            )}
            <Label>Revenue Table</Label>
          </div>

          <div css={{ display: 'flex', alignItems: 'center' }}>
            <div
              css={{
                display: 'flex',
                columnGap: 5,
                alignItems: 'center'
              }}
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <div>Summary</div>
              <Toggle
                checked={isSubCategory}
                onChange={() => {
                  setIsSubCategory(!isSubCategory)
                }}
                styles={{ root: { marginBottom: 0 } }}
              />
              <div>Detailed</div>
            </div>

            <div
              css={{ paddingLeft: '30px' }}
              onClick={(event) => {
                event.stopPropagation()
              }}
            >
              <ChartDropDown showLabel={false} />
            </div>
          </div>
        </div>

        <div
          css={{
            display: isExpanded ? 'block' : 'none',
            background: 'white',
            border: '1px solid #D9D9D9'
          }}
        >
          <ProgressIndicator
            progressHidden={!isFetching}
            styles={{
              itemProgress: { padding: 0, margin: 0 }
            }}
          />
          <div>
            {data === undefined ||
            data?.length === 0 ||
            Object.keys(detailedData).length === 0 ? (
              <div css={{ padding: '5px' }}>
                <MessageBar
                  messageBarType={
                    error ? MessageBarType.error : MessageBarType.info
                  }
                >
                  {error
                    ? (error as Error)?.message || 'An unknown error occurred'
                    : 'No Data Available'}
                </MessageBar>
              </div>
            ) : (
              <RevenueDetailTable
                data={detailedData}
                summaryOrDetail={
                  isSubCategory
                    ? (options[1].key as string)
                    : (options[0].key as string)
                }
                dateRange={dateRange}
              />
            )}
          </div>
        </div>

        <div css={{ marginTop: '20px' }}>
          <RevenueDetailList
            revenueTableStartDate={revenueTableStartDate}
            revenueTableEndDate={revenueTableEndDate}
          />
        </div>
      </div>
    </div>
  )
}
