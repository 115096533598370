import { css } from '@emotion/react'
import {
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table'
import { IPosition } from 'api/position.types'
import { FC } from 'react'
import { getColumnDefinitions } from './ColumnDefinitions'
import { NavigateToAIDashboard } from './NavigateToAIDashboard'

const classes = {
  tableBase: css({
    borderSpacing: 0,
    borderCollapse: 'collapse',
    borderLeft: '2px solid #dcdcdc',
    borderRight: '2px solid #dcdcdc',
    tableLayout: 'fixed',
    width: '100%',
    td: {
      color: '#5C666F',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    th: {
      fontWeight: 500
    },
    'th, td': {
      fontSize: 12,
      padding: 8,
      textAlign: 'right',
      verticalAlign: 'top'
    },
    'th:first-of-type, td:first-of-type': {
      textAlign: 'left',
      paddingLeft: 16
    },
    'th:last-of-type, td:last-of-type': {
      paddingRight: 16
    }
  }),
  tableHeader: css({
    backgroundColor: '#eff3f6'
  }),
  tableFooter: css({
    backgroundColor: '#cde2f2',
    'td:first-of-type': {
      color: '#000000',
      fontWeight: 700
    }
  })
}

const markedHeaders = ['Adjusted Market Value', 'Adjusted Total Value']

const ReferenceMark: FC<{ value?: number | string }> = ({ value }) => {
  return value != null ? (
    <span
      css={{
        position: 'absolute',
        top: 0,
        right: 0,
        fontSize: 11,
        fontWeight: 400
      }}
    >
      {value}
    </span>
  ) : null
}

const FootNote: FC<{ isDrawdown?: boolean }> = ({ isDrawdown }) => {
  return (
    <section css={{ color: 'gray', fontSize: 11, paddingTop: 10 }}>
      <div>
        Note: Go to{' '}
        <NavigateToAIDashboard linkText="AI Dashboard" iconSize={14} /> for
        additional details.
      </div>
      {isDrawdown && (
        <ol css={{ paddingInlineStart: 16 }}>
          <li>Last Valuation Value + New Capital Calls - New Distributions</li>
          <li>
            Adjusted Market Value + Distributions To Date + Total Income{' '}
            <span css={{ whiteSpace: 'nowrap' }}>Paid-In-Cash</span>
          </li>
        </ol>
      )}
    </section>
  )
}

export const CalloutDetailsTable: FC<{
  data: IPosition[]
  isDrawdown: boolean
}> = ({ data, isDrawdown }) => {
  const { columnDefinitions } = getColumnDefinitions(isDrawdown)

  const table = useReactTable({
    data,
    columns: columnDefinitions,
    getCoreRowModel: getCoreRowModel()
  })

  const headers = table.getFlatHeaders()
  const { rows } = table.getRowModel()

  return (
    <>
      <table css={classes.tableBase}>
        <thead css={classes.tableHeader}>
          <tr>
            {headers.map((header) => {
              const markIndex = isDrawdown
                ? markedHeaders.indexOf(header.id)
                : -1
              const isMarked = markIndex > -1
              return (
                <th
                  key={header.id}
                  css={{
                    position: isMarked ? 'relative' : undefined
                  }}
                >
                  {isMarked && <ReferenceMark value={markIndex + 1} />}
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot css={classes.tableFooter}>
          <tr>
            {headers.map((header, index) =>
              index === 0 ? (
                <td key="x">Total</td>
              ) : (
                <td key={header.id}>
                  {header.column.columnDef.footer
                    ? flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )
                    : null}
                </td>
              )
            )}
          </tr>
        </tfoot>
      </table>
      <FootNote isDrawdown={isDrawdown} />
    </>
  )
}
