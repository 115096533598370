import { push } from 'connected-react-router'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../store/shared/sagas'
import { navigationActions } from '../../../../../store/ui/actions'
import { fetchVendorList } from '../api'
import {
  VendorListActions,
  VendorListActionType,
  VendorNavigationActions,
  VendorNavigationActionType
} from './actions'
import { getOtherPaymentsUrl } from './selector'

function* requestVendorsList(action: VendorListActionType) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const orgid = action.payload as string
    const data = yield* call(fetchVendorList, orgid, options)
    if (data && data.response_status === 0) {
      yield put(
        VendorListActions.success(data.response_data ? data.response_data : [])
      )
    } else {
      yield put(
        VendorListActions.failure(new Error('Unable to pull vendors list'))
      )
    }
  } catch (e: any) {
    yield put(VendorListActions.failure(e))
  }
}

function* navigateToWireInstruction(action: VendorNavigationActionType) {
  yield put(push(`/payments/wire/instruction/${action.payload}/ADD`))
}

function* navigateToEditWireInstruction(
  action: ReturnType<
    typeof VendorNavigationActions.naviagteToEditWireInstruction
  >
) {
  yield put(
    push(
      `/payments/wire/instruction/${action.payload.orgid}/UPDATE/${action.payload.id}`
    )
  )
}

function* navigateToPayments() {
  yield put(push('/Payments'))
}

function* naviagteToOtherPayments() {
  const url = yield* select(getOtherPaymentsUrl)

  if (!url) {
    throw new Error('url is undefined')
  }

  yield put(navigationActions.launchBillDotCom(url))
}

export const sagas = [
  () => takeLatest(VendorListActions.request, requestVendorsList),
  () =>
    takeLatest(
      VendorNavigationActions.naviagateToWireInstruction,
      navigateToWireInstruction
    ),
  () =>
    takeLatest(VendorNavigationActions.navigateToPayments, navigateToPayments),
  () =>
    takeLatest(
      VendorNavigationActions.naviagteToEditWireInstruction,
      navigateToEditWireInstruction
    ),
  () =>
    takeLatest(
      VendorNavigationActions.naviagteToOtherPayments,
      naviagteToOtherPayments
    )
]
