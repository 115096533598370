import { DetailsListLayoutMode, Stack, Text } from '@fluentui/react'
import { ListCommandBarWithPreferences } from 'modules/Advisory/modules/Revenue/components/ListCommandBarWithPreferences'
import { constants } from 'modules/Advisory/shared/theme'
import React from 'react'
import { createListsComponents } from '../../../../../../../../features/Lists/core/containers/service'
import { AllocatedAmountCell } from './AllocatedAmountCell'
import { CreditEventColumnIds } from './ColumnDefinitions'
import {
  createCreditEventCell,
  CreditEventCellComponents
} from './CreditEventCell'
import { PayoutCell } from './PayoutCell'
import { useInitializeCreditEventList } from './preferences'
import { useSyncRevenueDashboardWithDomainContext } from './preferences/useAdvisorIdFilter'
import { actions, selectors } from './store'
import { CreditEvent } from './types'

const CreditEventListComponents = createListsComponents(
  actions,
  selectors,
  createCreditEventCell({
    ...CreditEventCellComponents,
    [CreditEventColumnIds.allocatedAmount]: ({ creditEvent }) => (
      <AllocatedAmountCell creditEvent={creditEvent} />
    ),
    [CreditEventColumnIds.grossPayout]: ({ creditEvent }) => (
      <PayoutCell creditEvent={creditEvent} />
    )
  })
)

export const CreditEventList: React.FC = () => {
  useInitializeCreditEventList(CreditEvent)
  useSyncRevenueDashboardWithDomainContext(CreditEvent)

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
        <CreditEventListComponents.ListsSearch />
        <Stack.Item grow={1}>
          <CreditEventListComponents.ListsFilterStatusList />
        </Stack.Item>

        <Text variant="medium" styles={{ root: { fontWeight: 'bold' } }}>
          <CreditEventListComponents.ListsResultsCount /> Results
        </Text>
      </Stack>
      <Stack.Item>
        <Stack>
          <ListCommandBarWithPreferences
            listType={CreditEvent}
            ListsCommandBar={CreditEventListComponents.ListsCommandBar}
          />
          <CreditEventListComponents.ListsProgress />
          <CreditEventListComponents.ListsInfiniteScrollDataTable
            layoutMode={DetailsListLayoutMode.fixedColumns}
            stickyHeaderOffset={constants.headerHeight}
          />
        </Stack>
      </Stack.Item>
    </Stack>
  )
}
