import { flexRender, Row } from '@tanstack/react-table'
import { PerformanceData } from 'api/performance.types'
import {
  useRdot360ThemedTableStyles,
  rdot360TableStyles
} from 'modules/Advisory/modules/Rdot360/shared/tableStyles'
import { cellStyles } from './styles'

const PerformanceTableRow: React.FC<{ row: Row<PerformanceData> }> = ({
  row
}) => {
  const cells = row.getVisibleCells()
  const themedStyles = useRdot360ThemedTableStyles()

  return (
    <tr
      css={[
        rdot360TableStyles.bodyRow,
        themedStyles.bodyRow,
        themedStyles.securityRow
      ]}
    >
      {cells.map((cell) => {
        return (
          <td key={cell.id} css={[cellStyles[cell.column.id]]}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        )
      })}
    </tr>
  )
}

export default PerformanceTableRow
