import { combineReducers } from 'redux'
import {
  managedAccountFeeHistoryBillTypesFetchReducer,
  managedAccountFeeHistoryBillTypesFetchSagas
} from './billTypesFetch'
import {
  managedAccountFeeHistoryExportReducer,
  managedAccountFeeHistoryExportSagas
} from './managedAccountFeeHistoryExport'
import {
  managedAccountFeeHistoryListReducer,
  managedAccountFeeHistoryListSagas
} from './managedAccountFeeHistoryList'

export const managedAccountFeeHistoryModuleReducer = combineReducers({
  billTypesFetch: managedAccountFeeHistoryBillTypesFetchReducer,
  managedAccountFeeHistoryExport: managedAccountFeeHistoryExportReducer,
  managedAccountFeeHistoryList: managedAccountFeeHistoryListReducer
})

export const managedAccountFeeHistoryModuleSagas = [
  ...managedAccountFeeHistoryBillTypesFetchSagas,
  ...managedAccountFeeHistoryListSagas,
  ...managedAccountFeeHistoryExportSagas
]
