import { TagDescription } from '@reduxjs/toolkit/dist/query/react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { rdot360Api, Rdot360ApiTagType } from 'store/api/rdot360'
import { apiConstants } from '../apis'
import { IOpenTaxLotResponseValue } from './IOpenTaxLotResponse'

export interface ITasApiBaseValueResponse<T> {
  value: T[]
}

export interface ITasApiBaseRequest {
  contextId: string
}

const { cacheTime } = apiConstants

type TasApiTagType = 'openTaxLotsByAccountAndSecurity'
const tasApiTags: TasApiTagType[] = ['openTaxLotsByAccountAndSecurity']
const tasApiWithTags = rdot360Api.enhanceEndpoints({
  addTagTypes: tasApiTags
})

const getDefaultTags = (
  type: TasApiTagType,
  req: ITasApiBaseRequest
): TagDescription<TasApiTagType | Rdot360ApiTagType>[] => [
  'rdot360',
  { type: 'rdot360', id: req.contextId },
  type,
  { type, id: req.contextId }
]

const baseTransformResponse = <T>(response: ITasApiBaseValueResponse<T>) =>
  response?.value?.[0]

const providesTags =
  (type: TasApiTagType) => (_: unknown, _1: unknown, req: ITasApiBaseRequest) =>
    getDefaultTags(type, req)

const tasApi = tasApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getOpenTaxLotsByAccountAndSecurity: builder.query<
      IOpenTaxLotResponseValue | undefined,
      ITasApiBaseRequest & { secId: string; accountId: string }
    >({
      query: ({ secId, accountId }) => {
        return {
          url: `/tas/odata/TAS/TaxLotOpenByAcctSec(secId='${secId}',acct='${accountId}',rid='null')`
        }
      },
      keepUnusedDataFor: cacheTime,
      transformResponse: baseTransformResponse,
      providesTags: providesTags('openTaxLotsByAccountAndSecurity')
    })
  })
})

export const { useGetOpenTaxLotsByAccountAndSecurityQuery } = tasApi

export const useTasApiUtil = () => {
  const dispatch = useDispatch()
  const invalidateTags = useCallback(
    (tags: TagDescription<TasApiTagType>[]) =>
      dispatch(tasApi.util.invalidateTags(tags)),
    [dispatch]
  )

  return {
    invalidateTags
  }
}
