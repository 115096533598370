import {
  Modal,
  IconButton,
  Text,
  ProgressIndicator,
  Stack,
  makeStyles,
  FontWeights,
  Link
} from '@fluentui/react'
import { sanitize } from 'dompurify'
import { memo, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAttachment, IActivity, IActivityAndNotes } from '../../api/dynamics'
import { base64toBlob } from '../../modules/Reports/features/Dashboard/Utilities'
import { IApiOptions } from '../../shared/contracts/IApiOptions'
import { tryAcquireAccessToken } from '../../shared/services/auth'
import { IEnvironmentApiConfiguration } from '../../shared/services/environment/IEnvironmentConfiguration'
import { getDynamicsCrmApiConfig } from '../../store/system'
import { IDataListColumnDefinition } from '../DataList/contracts/IDataListColumnDefinition'
import { DataList } from '../DataList/DataList'
import { AttachmentComponent } from './AttachmentComponent'
import {
  activityAndNotesListActions,
  ActivityAndNotesListColumnName,
  activityAndNotesListSelectors,
  getName
} from './store/activityAndNotesList'
import {
  clientActivityFetchActions,
  getIsClientActivityFetchLoading
} from './store/clientActivityFetch'

const CellComponents: Record<
  ActivityAndNotesListColumnName,
  React.FC<{
    item: IActivityAndNotes
    column: IDataListColumnDefinition<IActivityAndNotes>
  }>
> = {
  Type: ({ item }) => (
    <Text
      nowrap={true}
      block={true}
      key={item['activitytypecode@OData.Community.Display.V1.FormattedValue']}
    >
      {item['activitytypecode@OData.Community.Display.V1.FormattedValue']}
    </Text>
  ),
  'Subject/Title': function SubjectTitleComponent({ item }) {
    const apiConfig = useSelector(getDynamicsCrmApiConfig)
    return (
      <>
        {item.description || item.notetext ? (
          <Link
            onClick={(e) => (e.preventDefault(), openEmail(item, apiConfig))}
          >
            {item.subject}
          </Link>
        ) : (
          <Text nowrap={true} block={true}>
            {item.subject}
          </Text>
        )}
      </>
    )
  },
  Description: ({ item }) => (
    <Text nowrap={true} block={true}>
      {item.description ? getDescription(item.description) : ''}
      {item.notetext ? getNoteDescription(item.notetext) : ''}
    </Text>
  ),
  Owner: ({ item }) => (
    <Text nowrap={true} block={true}>
      {item['_ownerid_value@OData.Community.Display.V1.FormattedValue']}
    </Text>
  ),
  'Due Date': ({ item }) => (
    <Text nowrap={true} block={true}>
      {item['scheduledend@OData.Community.Display.V1.FormattedValue']
        ? new Date(
            item['scheduledend@OData.Community.Display.V1.FormattedValue']
          ).toLocaleDateString()
        : ''}
    </Text>
  ),
  'Date Created': ({ item }) => (
    <Text nowrap={true} block={true}>
      {item['createdon@OData.Community.Display.V1.FormattedValue']
        ? new Date(
            item['createdon@OData.Community.Display.V1.FormattedValue']
          ).toLocaleDateString()
        : ''}
    </Text>
  ),
  'Modified By': ({ item }) => (
    <Text nowrap={true} block={true}>
      {item['_modifiedby_value@OData.Community.Display.V1.FormattedValue']}
    </Text>
  ),
  'Last Updated': ({ item }) => (
    <Text nowrap={true} block={true}>
      {item['modifiedon@OData.Community.Display.V1.FormattedValue']
        ? new Date(
            item['modifiedon@OData.Community.Display.V1.FormattedValue']
          ).toLocaleDateString()
        : ''}
    </Text>
  ),
  'Activity Status': ({ item }) => (
    <Text nowrap={true} block={true}>
      {item['statecode@OData.Community.Display.V1.FormattedValue']}
    </Text>
  ),
  Attachments: ({ item }) => (
    <Stack>
      {item.activity_pointer_activity_mime_attachment
        ?.filter(
          (attach) =>
            !(attach.filename.startsWith('image00') && attach.filesize === 4013)
        )
        .map((attach) => (
          <AttachmentComponent
            filename={attach.filename}
            id={attach.activitymimeattachmentid}
            key={attach.filename}
            type={'Email'}
          />
        ))}
      {item.filename && item.annotationid ? (
        <AttachmentComponent
          filename={item.filename}
          id={item.annotationid}
          key={item.filename}
          type={'Note'}
        />
      ) : (
        ''
      )}
    </Stack>
  )
}

function openEmail(
  item: IActivityAndNotes,
  apiConfig?: IEnvironmentApiConfiguration
) {
  if (item.description) {
    openWindow(item.subject || '', item.description, apiConfig)
  } else if (item.notetext) {
    openWindow(item.subject || '', item.notetext, apiConfig)
  }
}

async function openWindow(
  title: string,
  body: string,
  apiConfig?: IEnvironmentApiConfiguration
) {
  const wnd = window.open()
  wnd?.document.write(sanitize(body))
  if (!wnd) {
    return
  }
  wnd.document.title = title
  const images = wnd.document.querySelectorAll('img')
  const allSources = [...images].map((x) =>
    x.getAttribute('data-attachment-id')
  )

  const getImages = async () => {
    return Promise.all(allSources.map((id) => downloadAttach(id, apiConfig)))
  }
  getImages().then((data) =>
    images.forEach((x, i) => x.setAttribute('src', `${data[i]}`))
  )
  wnd?.document.close()
}

async function downloadAttach(
  id: string | null,
  apiConfig?: IEnvironmentApiConfiguration
) {
  if (!id || !apiConfig) {
    return
  }
  const token = await tryAcquireAccessToken(apiConfig.scopes)
  const options: IApiOptions = {
    apiRoot: apiConfig.root,
    accessToken: token
  }
  const attachment = await getAttachment(options, id)
  if (!attachment) {
    throw Error('Attachment not Found')
  }
  const blob = base64toBlob(attachment.body, attachment.mimetype)
  const url = window.URL.createObjectURL(blob)
  return url
}

function getDescription(html: string) {
  const div = document.createElement('div')
  div.innerHTML = sanitize(html)
  return div.childElementCount > 1
    ? div.lastChild?.textContent
    : div.firstChild?.textContent
}

function getNoteDescription(html: string) {
  const div = document.createElement('div')
  div.innerHTML = sanitize(html)
  return div.innerText
}

const CellComponent: React.FC<{
  item: IActivity
  column: IDataListColumnDefinition<IActivity>
}> = memo(({ item, column }) => {
  const Component =
    CellComponents[column.name as ActivityAndNotesListColumnName]
  return Component ? <Component item={item} column={column} /> : null
})

const useModalClasses = makeStyles((theme) => ({
  header: [
    theme.fonts.xLarge,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px'
    }
  ],
  closeButton: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    '&:hover': {
      color: theme.palette.neutralDark
    }
  }
}))

export interface IActivitiesModalComponentProps {
  email: string[] | undefined
  isModalOpen: boolean
  onDismiss: () => void
}

export const ActivitiesModalComponent: React.FC<
  IActivitiesModalComponentProps
> = ({ email, isModalOpen, onDismiss }) => {
  const dispatch = useDispatch()
  const classes = useModalClasses()
  const isFetchLoading = useSelector(getIsClientActivityFetchLoading)
  const name = useSelector(getName)
  useEffect(() => {
    if (!email) {
      return
    }
    if (!isModalOpen) {
      return
    }
    dispatch(clientActivityFetchActions.request(email))
  }, [dispatch, email, isModalOpen])

  const header = useMemo(
    () => (
      <ProgressIndicator
        progressHidden={!isFetchLoading}
        styles={{
          itemProgress: { padding: 0, margin: 0 }
        }}
      />
    ),
    [isFetchLoading]
  )

  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={onDismiss}
      isBlocking={false}
      styles={{
        main: { display: 'flex' }
      }}
    >
      <Stack styles={{ root: { height: '100%' } }}>
        <div className={classes.header}>
          <span>{name}</span>
          <IconButton
            className={classes.closeButton}
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel="Close popup modal"
            onClick={onDismiss}
          />
        </div>
        <Stack.Item grow={1} styles={{ root: { minHeight: 0 } }}>
          <div
            data-is-scrollable={true}
            style={{
              minWidth: '1600px',
              height: '100%',
              overflow: 'auto',
              padding: '0 20px 5px 20px'
            }}
          >
            <DataList
              actions={activityAndNotesListActions}
              selectors={activityAndNotesListSelectors}
              enableShimmer={isFetchLoading}
              stickyHeaderOffset={0}
              cell={CellComponent}
              secondaryHeader={header}
            />
          </div>
        </Stack.Item>
      </Stack>
    </Modal>
  )
}
