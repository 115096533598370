import { Text } from '@fluentui/react'
import React from 'react'
import { ISystemUser } from '../../../../../../../../api/dynamics'
import { createEntitySelector } from './EntitySelector'
import { usePayoutUserSearchFetch } from './store/payoutUserSearchFetch'

const UserSearchAutoCompleteItem: React.FC<{
  item?: ISystemUser
}> = ({ item }) => {
  return (
    <div style={{ padding: '5px 10px', minWidth: 0 }}>
      <Text>{item?.fullname}</Text>
    </div>
  )
}

export const UserEntitySelector = createEntitySelector(
  usePayoutUserSearchFetch,
  UserSearchAutoCompleteItem
)
