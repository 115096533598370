import { IColumnDefinition } from 'features/Lists/core/contracts/IColumnDefinition'

enum ColumnIdsEnum {
  id = 'id',
  cusip = 'cusip',
  ticker = 'ticker',
  isin = 'isin',
  sedol = 'sedol',
  securitydescription = 'securitydescription',
  l1description = 'l1description',
  l2description = 'l2description',
  l3description = 'l3description',
  l4description = 'l4description',
  sector = 'sector',
  assettype = 'assettype',
  assetsubtype = 'assetsubtype',
  nfsSecurityTypeDescription = 'nfsSecurityTypeDescription',
  rockitSecurityTypeDescription = 'rockitSecurityTypeDescription',
  nwSecurityType = 'nwSecurityType',
  saSecurityType = 'saSecurityType',
  source = 'source',
  isSpecialtyAsset = 'isSpecialtyAsset',
  minorAssetType = 'minorAssetType',
  // Fixed Income Fields
  interestRate = 'interestRate',
  incomeFrequency = 'incomeFrequency',
  maturityDate = 'maturityDate',
  effectiveDuration = 'effectiveDuration',
  stateTaxable = 'stateTaxable',
  federalTaxable = 'federalTaxable',
  issueState = 'issueState',
  displayAccrued = 'displayAccrued',
  accrualMethod = 'accrualMethod',
  issueDate = 'issueDate',
  callDate = 'callDate',
  callPrice = 'callPrice',
  preRefundDate = 'preRefundDate',
  preRefundPrice = 'preRefundPrice',
  extendedDescription = 'extendedDescription',
  amortizeOrAccreteTo = 'amortizeOrAccreteTo',
  currentYieldToWorst = 'currentYieldToWorst',
  firstCouponDate = 'firstCouponDate',
  finalCouponDate = 'finalCouponDate',
  // Equities Fields
  annualDividend = 'annualDividend',
  //IncomeFrequency = 'IncomeFrequency',
  //EffectiveDuration = 'EffectiveDuration',
  nextExDividendDate = 'nextExDividendDate',
  nextDividendDate = 'nextDividendDate',
  nextDividendAmount = 'nextDividendAmount',
  qualifiedStatus = 'qualifiedStatus',
  marketCap = 'marketCap',
  allowUpdatesFromTransactionInformation = 'allowUpdatesFromTransactionInformation',
  //Flag
  fixedIncomeOrEquityFlag = 'fixedIncomeOrEquityFlag'
}

export const SecurityColumnIds = { ...ColumnIdsEnum }
export type SecurityColumnIds = typeof SecurityColumnIds

export const SecurityColumnDefinitions: Record<string, IColumnDefinition> = {
  [SecurityColumnIds.cusip]: {
    id: SecurityColumnIds.cusip,
    name: 'CUSIP',
    type: 'string',
    width: 110,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'cusip'
  },
  [SecurityColumnIds.ticker]: {
    id: SecurityColumnIds.ticker,
    name: 'TICKER',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'ticker'
  },
  [SecurityColumnIds.isin]: {
    id: SecurityColumnIds.isin,
    name: 'ISIN',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'isin'
  },
  [SecurityColumnIds.sedol]: {
    id: SecurityColumnIds.sedol,
    name: 'SEDOL',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'sedol'
  },
  [SecurityColumnIds.securitydescription]: {
    id: SecurityColumnIds.securitydescription,
    name: 'SECURITY DESC',
    type: 'string',
    width: 400,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'securitydescription'
  },
  [SecurityColumnIds.l1description]: {
    id: SecurityColumnIds.l1description,
    name: 'LEVEL1',
    type: 'string',
    width: 150,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'l1description'
  },
  [SecurityColumnIds.l2description]: {
    id: SecurityColumnIds.l2description,
    name: 'LEVEL2',
    type: 'string',
    width: 150,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'l2description'
  },
  [SecurityColumnIds.l3description]: {
    id: SecurityColumnIds.l3description,
    name: 'LEVEL3',
    type: 'string',
    width: 150,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'l3description'
  },
  [SecurityColumnIds.l4description]: {
    id: SecurityColumnIds.l4description,
    name: 'LEVEL4',
    type: 'string',
    width: 150,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'l4description'
  },
  [SecurityColumnIds.sector]: {
    id: SecurityColumnIds.sector,
    name: 'SECTOR',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'sector'
  },
  [SecurityColumnIds.assettype]: {
    id: SecurityColumnIds.assettype,
    name: 'ASSET TYPE',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'assettype'
  },
  [SecurityColumnIds.assetsubtype]: {
    id: SecurityColumnIds.assetsubtype,
    name: 'ASSET SUB TYPE',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'assetsubtype'
  },
  [SecurityColumnIds.nfsSecurityTypeDescription]: {
    id: SecurityColumnIds.nfsSecurityTypeDescription,
    name: 'NFS SECURITY TYPE',
    type: 'string',
    width: 200,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'nfsSecurityTypeDescription'
  },
  [SecurityColumnIds.rockitSecurityTypeDescription]: {
    id: SecurityColumnIds.rockitSecurityTypeDescription,
    name: 'ROCKIT SECURITY TYPE',
    type: 'string',
    width: 200,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'rockitSecurityTypeDescription'
  },
  [SecurityColumnIds.nwSecurityType]: {
    id: SecurityColumnIds.nwSecurityType,
    name: 'NW SECURITY TYPE',
    type: 'string',
    width: 200,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'nwSecurityType'
  },
  [SecurityColumnIds.saSecurityType]: {
    id: SecurityColumnIds.saSecurityType,
    name: 'SA SECURITY TYPE',
    type: 'string',
    width: 200,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'saSecurityType'
  },
  [SecurityColumnIds.source]: {
    id: SecurityColumnIds.source,
    name: 'SOURCE',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'source'
  },
  [SecurityColumnIds.isSpecialtyAsset]: {
    id: SecurityColumnIds.isSpecialtyAsset,
    name: 'MANUAL SECURITY IND',
    type: 'custom',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'isSpecialtyAsset'
  },
  [SecurityColumnIds.minorAssetType]: {
    id: SecurityColumnIds.minorAssetType,
    name: 'GWES MINOR ASSET TYPE',
    type: 'string',
    width: 150,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'minorAssetType'
  },
  // FI or Equities Flag
  [SecurityColumnIds.fixedIncomeOrEquityFlag]: {
    id: SecurityColumnIds.fixedIncomeOrEquityFlag,
    name: 'FI OR EQUITIES',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'fixedIncomeOrEquityFlag'
  },
  // Fixed Income Fields
  [SecurityColumnIds.interestRate]: {
    id: SecurityColumnIds.interestRate,
    name: 'INTEREST RATE',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'interestRate'
  },
  [SecurityColumnIds.incomeFrequency]: {
    id: SecurityColumnIds.incomeFrequency,
    name: 'INCOME FREQUENCY',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'incomeFrequency'
  },
  [SecurityColumnIds.maturityDate]: {
    id: SecurityColumnIds.maturityDate,
    name: 'MATURITY DATE',
    type: 'date',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'maturityDate'
  },
  [SecurityColumnIds.effectiveDuration]: {
    id: SecurityColumnIds.effectiveDuration,
    name: 'EFFECTIVE DURATION',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'effectiveDuration'
  },
  [SecurityColumnIds.stateTaxable]: {
    id: SecurityColumnIds.stateTaxable,
    name: 'STATE TAXABLE',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'stateTaxable'
  },
  [SecurityColumnIds.federalTaxable]: {
    id: SecurityColumnIds.federalTaxable,
    name: 'FEDERAL TAXABLE',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'federalTaxable'
  },
  [SecurityColumnIds.issueState]: {
    id: SecurityColumnIds.issueState,
    name: 'ISSUE STATE',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'issueState'
  },
  [SecurityColumnIds.displayAccrued]: {
    id: SecurityColumnIds.displayAccrued,
    name: 'DISPLAY ACCRUED',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'displayAccrued'
  },
  [SecurityColumnIds.accrualMethod]: {
    id: SecurityColumnIds.accrualMethod,
    name: 'ACCRUAL METHOD',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'accrualMethod'
  },
  [SecurityColumnIds.issueDate]: {
    id: SecurityColumnIds.issueDate,
    name: 'ISSUE DATE',
    type: 'date',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'issueDate'
  },
  [SecurityColumnIds.callDate]: {
    id: SecurityColumnIds.callDate,
    name: 'CALL DATE',
    type: 'date',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'callDate'
  },
  [SecurityColumnIds.callPrice]: {
    id: SecurityColumnIds.callPrice,
    name: 'CALL PRICE',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'callPrice'
  },
  [SecurityColumnIds.preRefundDate]: {
    id: SecurityColumnIds.preRefundDate,
    name: 'PRE-REFUND DATE',
    type: 'date',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'preRefundDate'
  },
  [SecurityColumnIds.preRefundPrice]: {
    id: SecurityColumnIds.preRefundPrice,
    name: 'PRE-REFUND PRICE',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'preRefundPrice'
  },
  [SecurityColumnIds.extendedDescription]: {
    id: SecurityColumnIds.extendedDescription,
    name: 'EXTENDED DESCRIPTION',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'extendedDescription'
  },
  [SecurityColumnIds.amortizeOrAccreteTo]: {
    id: SecurityColumnIds.amortizeOrAccreteTo,
    name: 'AMORTIZE OR ACCRETE TO',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'amortizeOrAccreteTo'
  },
  [SecurityColumnIds.currentYieldToWorst]: {
    id: SecurityColumnIds.currentYieldToWorst,
    name: 'CURRENT YIELD TO WORST (MARKET)',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'currentYieldToWorst'
  },
  [SecurityColumnIds.firstCouponDate]: {
    id: SecurityColumnIds.firstCouponDate,
    name: 'FIRST COUPON DATE',
    type: 'date',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'firstCouponDate'
  },
  [SecurityColumnIds.finalCouponDate]: {
    id: SecurityColumnIds.finalCouponDate,
    name: 'FINAL COUPON DATE',
    type: 'date',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'finalCouponDate'
  },
  // Equities Fields
  [SecurityColumnIds.annualDividend]: {
    id: SecurityColumnIds.annualDividend,
    name: 'ANNUAL DIVIDEND',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'annualDividend'
  },
  [SecurityColumnIds.nextExDividendDate]: {
    id: SecurityColumnIds.nextExDividendDate,
    name: 'NEXT EX-DIVIDEND DATE',
    type: 'date',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'nextExDividendDate'
  },
  [SecurityColumnIds.nextDividendDate]: {
    id: SecurityColumnIds.nextDividendDate,
    name: 'NEXT DIVIDEND DATE',
    type: 'date',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'nextDividendDate'
  },
  [SecurityColumnIds.nextDividendAmount]: {
    id: SecurityColumnIds.nextDividendAmount,
    name: 'NEXT DIVIDEND AMOUNT',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'nextDividendAmount'
  },
  [SecurityColumnIds.qualifiedStatus]: {
    id: SecurityColumnIds.qualifiedStatus,
    name: 'QUALIFIED STATUS',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'qualifiedStatus'
  },
  [SecurityColumnIds.marketCap]: {
    id: SecurityColumnIds.marketCap,
    name: 'MARKET CAP (BILLIONS)',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'marketCap'
  },
  [SecurityColumnIds.allowUpdatesFromTransactionInformation]: {
    id: SecurityColumnIds.allowUpdatesFromTransactionInformation,
    name: 'ALLOW UPDATES FROM TRANSACTION INFORMATION',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'allowUpdatesFromTransactionInformation'
  }
}
