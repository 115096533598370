import { cloneDeep } from 'lodash'
import { ColumnSetting } from './store/types'

export const DashboardLocation = {
  PeriodicPayment: 'periodicpayments'
}

const DefaultCommonColumnList: ColumnSetting[] = [
  {
    key: 'clientname',
    name: 'CLIENT NAME',
    isVisible: true
  },
  {
    key: 'accountShortName',
    name: 'SHORT NAME',
    isVisible: true
  },
  {
    key: 'account',
    name: 'ACCOUNT',
    isVisible: true
  },
  {
    key: 'fedRefNumber',
    name: 'FED REF #',
    isVisible: true
  },
  {
    key: 'refnum',
    name: 'REF #',
    isVisible: true
  },
  { key: 'fromRecurringPlanPMTId', name: 'PLAN REF#', isVisible: true },
  {
    key: 'creationdate',
    name: 'CREATION DATE',
    isVisible: true
  },
  {
    key: 'processdate',
    name: 'PROCESS DATE',
    isVisible: true
  },
  {
    key: 'payee',
    name: 'TO PAYEE',
    isVisible: true
  },
  {
    key: 'transtype',
    name: 'TRANS TYPE',
    isVisible: true
  },
  {
    key: 'isRecurringPayment',
    name: 'FROM RECURRING PLAN',
    isVisible: true
  },
  {
    key: 'amount',
    name: 'AMOUNT',
    isVisible: true
  },

  {
    key: 'status',
    name: 'STATUS',
    isVisible: true
  },
  {
    key: 'statusdescription',
    name: 'STATUS DESCRIPTION',
    isVisible: true
  },
  {
    key: 'assignToId',
    name: 'ASSIGNED TO',
    isVisible: true
  },

  {
    key: 'action',
    name: 'ACTION',
    isVisible: true
  },
  {
    key: 'createdBy',
    name: 'CREATED BY',
    isVisible: false
  },
  {
    key: 'repId',
    name: 'REP ID',
    isVisible: false
  },
  {
    key: 'executingCustodian',
    name: 'EXECUTING CUSTODIAN',
    isVisible: false
  },
  {
    key: 'futureDated',
    name: 'FUTURE DATED',
    isVisible: false
  },

  {
    key: 'beneficiaryAcct',
    name: 'TO ACCOUNT',
    isVisible: false
  }
]

export const getDefaultColumns = (isRecurringPayment?: boolean) => {
  const DefaultColumnList = cloneDeep(DefaultCommonColumnList)
  if (isRecurringPayment) {
    const index = DefaultColumnList.findIndex(
      (x) => x.key === 'recurringFrequency'
    )
    if (index !== -1) {
      DefaultColumnList[index].isVisible = true
    }
    const updatedList: ColumnSetting[] = DefaultColumnList.filter(
      (x) =>
        x.key !== 'fedRefNumber' &&
        x.key !== 'isRecurringPayment' &&
        x.key !== 'fromRecurringPlanPMTId'
    ).map((column) => {
      if (column.key === 'refnum') {
        return {
          ...getDefaultColumns,
          key: 'refnum',
          name: 'PLAN REF #',
          isVisible: column.isVisible
        }
      } else if (column.key === 'processdate') {
        return {
          ...getDefaultColumns,
          key: 'processdate',
          name: 'PROCESS DATE',
          isVisible: false
        }
      }
      return column // Add this line to return the updated column object
    })

    const statusIndex = updatedList.findIndex(
      (column) => column.key === 'status'
    )

    updatedList.splice(statusIndex, 0, {
      key: 'recurringFrequency',
      name: 'FREQUENCY',
      isVisible: true
    })
    const visiblecolumns = updatedList.filter((x) => x?.isVisible)
    const hiddencolumns = updatedList.filter((x) => !x?.isVisible)
    const preference = [...visiblecolumns, ...hiddencolumns]
    return preference ?? []
  }

  return DefaultColumnList
}
