import { ActionType, createAction } from 'typesafe-actions'
import { IProject, IProjectRequest } from './types'

export const FETCH_PROJECT = '@modules/@payments/@project/FETCH_PROJECT'
export const FETCH_PROJECT_SUCCESS =
  '@modules/@payments/@project/FETCH_PROJECT_SUCCESS'
export const FETCH_PROJECT_FAILURE =
  '@modules/@payments/@project/FETCH_PROJECT_FAILURE'

export const ADD_EDIT_PROJECT = '@modules/@payments/@project/ADD_EDIT_PROJECT'
export const ADD_EDIT_PROJECT_SUCCESS =
  '@modules/@payments/@project/ADD_EDIT_PROJECT_SUCCESS'
export const ADD_EDIT_PROJECT_FAILURE =
  '@modules/@payments/@project/ADD_EDIT_PROJECT_FAILURE'

export const projectDataActions = {
  request: createAction(FETCH_PROJECT)<IProjectRequest>(),
  success: createAction(FETCH_PROJECT_SUCCESS)<IProject[]>(),
  failure: createAction(FETCH_PROJECT_FAILURE)<Error>()
}

export const addEditProjectDataActions = {
  request: createAction(ADD_EDIT_PROJECT)<IProject | undefined>(),
  success: createAction(ADD_EDIT_PROJECT_SUCCESS)<IProject>(),
  failure: createAction(ADD_EDIT_PROJECT_FAILURE)<Error>()
}

export type ProjectDataActionTypes = ActionType<typeof projectDataActions>
export type AddEditActionTypes = ActionType<typeof addEditProjectDataActions>

export type ProjectActionTypes = ProjectDataActionTypes | AddEditActionTypes
