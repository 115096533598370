import { IFontStyles, Text } from '@fluentui/react'
import React from 'react'
import { FormattedNumber } from 'react-intl'

export const PercentCell: React.FC<{
  value?: number
  size?: keyof IFontStyles
}> = ({ size, value }) => {
  const fractionDigits = value === 0 ? 0 : 2
  return value != null ? (
    <Text nowrap variant={size}>
      <FormattedNumber
        value={value}
        maximumFractionDigits={fractionDigits}
        minimumFractionDigits={fractionDigits}
      />
      %
    </Text>
  ) : (
    <Text variant={size}>--</Text>
  )
}
