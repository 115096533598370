import { ColumnDef } from '@tanstack/react-table'
import { IAnnuityPPLIAssets } from 'api/datahub'
import { isNotNullOrUndefined } from 'shared/guards'
import {
  AmountCellUSD,
  DateCell,
  PercentCell,
  SumAmountFooter
} from '../../features/TanStackReactTable/cellDefinitions'
import { columnNames as cn } from './columnNames'

export const getColumnDefs = (): ColumnDef<IAnnuityPPLIAssets>[] => [
  {
    header: cn.contractNumber,
    accessorFn: ({ contract }) => contract?.contractNumber
  },
  {
    header: cn.cusip,
    accessorFn: ({ contract }) => contract?.cusipNumber,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.productType,
    accessorFn: ({ contract }) => contract?.productType,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.description,
    accessorFn: ({ contractSecurity }) => contractSecurity?.securityDescription,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.premium,
    accessorFn: ({ contract }) => contract?.premiumPaidSinceInception,
    cell: () => null,
    aggregationFn: 'max',
    aggregatedCell: AmountCellUSD,
    meta: { className: 'right' }
  },
  {
    header: cn.accountNumber,
    accessorFn: ({ contract }) => contract?.accountNumber,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.legalEntityName,
    accessorFn: ({ legalEntityName }) => legalEntityName,
    cell: () => null,
    aggregationFn: 'max'
  },
  // BEGIN >>> ...Underlying Assets props
  {
    header: cn.underlyingSecurityCUSIP,
    accessorFn: ({ assetCusip }) => assetCusip,
    aggregationFn: 'count',
    meta: { className: 'assets', aggregatedClassName: 'right assets' }
  },
  {
    header: cn.underlyingSecurityDescription,
    accessorFn: ({ assetDescription }) => assetDescription,
    aggregationFn: 'count',
    meta: { className: 'assets', aggregatedClassName: 'right assets' }
  },
  {
    header: cn.underlyingAssetClassL1,
    accessorFn: ({ assetClassL1 }) => assetClassL1,
    aggregationFn: 'count',
    meta: { className: 'assets', aggregatedClassName: 'right assets' }
  },
  {
    header: cn.underlyingAssetClassL2,
    accessorFn: ({ assetClassL2 }) => assetClassL2,
    aggregationFn: 'count',
    meta: { className: 'assets', aggregatedClassName: 'right assets' }
  },
  {
    header: cn.marketValue,
    accessorFn: ({ marketValue }) => marketValue,
    cell: AmountCellUSD,
    aggregatedCell: AmountCellUSD,
    footer: SumAmountFooter,
    meta: { className: 'right assets' }
  },
  {
    header: cn.holdingPercent,
    accessorFn: ({ fundPercent }) => fundPercent,
    cell: PercentCell,
    aggregatedCell: PercentCell,
    meta: { className: 'right assets' }
  },
  {
    header: cn.underlyingAssetClassL3,
    accessorFn: ({ assetClassL3 }) => assetClassL3,
    aggregationFn: 'count',
    meta: { className: 'assets', aggregatedClassName: 'right assets' }
  },
  {
    header: cn.underlyingAssetClassL4,
    accessorFn: ({ assetClassL4 }) => assetClassL4,
    aggregationFn: 'count',
    meta: { className: 'assets', aggregatedClassName: 'right assets' }
  },
  {
    header: cn.underlyingAssetType,
    accessorFn: ({ assetType }) => assetType,
    aggregationFn: 'count',
    meta: { className: 'assets', aggregatedClassName: 'right assets' }
  },
  {
    header: cn.underlyingAssetSubType,
    accessorFn: ({ assetSubType }) => assetSubType,
    aggregationFn: 'count',
    meta: { className: 'assets', aggregatedClassName: 'right assets' }
  },
  // END >>> ...Underlying Assets props
  {
    header: cn.householdName,
    accessorFn: ({ householdName }) => householdName,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.issueDate,
    accessorFn: ({ contract }) => contract?.issueDate,
    cell: () => null,
    aggregationFn: 'max',
    aggregatedCell: DateCell,
    sortingFn: 'datetime',
    meta: { className: 'right' }
  },
  {
    header: cn.carrier,
    accessorFn: ({ contract }) => contract?.carrierName,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.state,
    accessorFn: ({ contract }) => contract?.partyState,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.policyStatus,
    accessorFn: ({ contract }) => contract?.contractStatusDesc,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.lastUpdated,
    accessorFn: ({ contract }) => contract?.valuationDate,
    cell: () => null,
    aggregationFn: 'max',
    aggregatedCell: DateCell,
    sortingFn: 'datetime',
    meta: { className: 'right' }
  },
  {
    header: cn.cashValue,
    accessorFn: ({ contract }) => contract?.cashValue,
    cell: () => null,
    aggregationFn: 'max',
    aggregatedCell: AmountCellUSD,
    meta: { className: 'right' }
  },
  {
    header: cn.deathBenefit,
    accessorFn: ({ contract }) => contract?.deathBenefitAmt,
    cell: () => null,
    aggregationFn: 'max',
    aggregatedCell: AmountCellUSD,
    meta: { className: 'right' }
  },
  {
    header: cn.surrenderValue,
    accessorFn: ({ contract }) => contract?.surrenderValue,
    cell: () => null,
    aggregationFn: 'max',
    aggregatedCell: AmountCellUSD,
    meta: { className: 'right' }
  },
  {
    header: cn.surrenderCharge,
    accessorFn: ({ contract }) => contract?.surrenderCharge,
    cell: () => null,
    aggregationFn: 'max',
    aggregatedCell: AmountCellUSD,
    meta: { className: 'right' }
  },
  {
    header: cn.surrenderChargeExpiration,
    accessorFn: ({ contract }) => contract?.surrenderDate,
    cell: () => null,
    aggregationFn: 'max',
    aggregatedCell: DateCell,
    sortingFn: 'datetime',
    meta: { className: 'right' }
  },
  {
    header: cn.rmds,
    accessorFn: ({ contract }) => contract?.rmdAmount,
    cell: () => null,
    aggregationFn: 'max',
    aggregatedCell: AmountCellUSD,
    meta: { className: 'right' }
  },
  {
    header: cn.maturityDate,
    accessorFn: ({ contract }) => contract?.maturityDate,
    cell: () => null,
    aggregationFn: 'max',
    aggregatedCell: DateCell,
    sortingFn: 'datetime',
    meta: { className: 'right' }
  },
  {
    header: cn.policyLoanAmount,
    accessorFn: ({ contract }) => contract?.loanAmount,
    cell: () => null,
    aggregationFn: 'max',
    aggregatedCell: AmountCellUSD,
    meta: { className: 'right' }
  },
  {
    header: cn.insuredAnnuitants,
    accessorFn: ({ contract }) => contract?.insuredAnnuitants,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.productName,
    accessorFn: ({ contract }) => contract?.productName,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.address,
    accessorFn: ({ contract }) => {
      const address = [contract?.partyAddressLine1, contract?.partyAddressLine2]
      return address.filter(isNotNullOrUndefined).join(', ')
    },
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.highBenefitName,
    accessorFn: ({ contract }) => contract?.highBenefitName,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.maxBenefit,
    accessorFn: ({ contract }) => contract?.maxBenefit,
    cell: () => null,
    aggregationFn: 'max',
    aggregatedCell: AmountCellUSD,
    meta: { className: 'right' }
  },
  {
    header: cn.incomeBenefitGMIB,
    accessorFn: ({ contract }) => contract?.incomeBenefitAmt,
    cell: () => null,
    aggregationFn: 'max',
    aggregatedCell: AmountCellUSD,
    meta: { className: 'right' }
  },
  {
    header: cn.feeType,
    accessorFn: ({ contract }) => contract?.feeType,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.tbfPercentage,
    accessorFn: ({ contract }) => contract?.tbfPercentAmt,
    cell: () => null,
    aggregatedCell: PercentCell,
    meta: { className: 'right' }
  },
  {
    header: cn.agents,
    accessorFn: ({ contract }) => contract?.agents,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.ownerClient,
    accessorFn: ({ contract }) => contract?.clientName,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.advisorId,
    accessorFn: ({ registeredRep }) => registeredRep,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.advisorName,
    accessorFn: ({ registeredRepName }) => registeredRepName,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.advisorTeam,
    accessorFn: ({ teamName }) => teamName,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.regionName,
    accessorFn: ({ regionName }) => regionName,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.assetClassL1,
    accessorFn: ({ contractSecurity }) => contractSecurity?.l1Description,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.assetClassL2,
    accessorFn: ({ contractSecurity }) => contractSecurity?.l2Description,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.assetClassL3,
    accessorFn: ({ contractSecurity }) => contractSecurity?.l3Description,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.assetClassL4,
    accessorFn: ({ contractSecurity }) => contractSecurity?.l4Description,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.assetType,
    accessorFn: ({ contractSecurity }) => contractSecurity?.assetType,
    cell: () => null,
    aggregationFn: 'max'
  },
  {
    header: cn.assetSubType,
    accessorFn: ({ contractSecurity }) => contractSecurity?.assetSubType,
    cell: () => null,
    aggregationFn: 'max'
  }
]
