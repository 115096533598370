import {
  createApi,
  FetchBaseQueryError
} from '@reduxjs/toolkit/dist/query/react'
import {
  IBaseGraphValueResponse,
  IGetAppRollAssignmentsForUserResponse,
  IGraphApiSendMailRequest,
  IGraphUser,
  IServicePrincipal
} from '../../api/graph'
import { isNotNullOrEmpty } from '../../shared/guards'
import {
  axiosBaseQuery,
  getGraphApiAuthToken,
  getGraphApiBaseUrl
} from './shared'

export const GraphV1ApiSliceKey = 'api.graph_v1'
export type GraphV1ApiReducerState = {
  [GraphV1ApiSliceKey]: ReturnType<typeof graph_v1.reducer>
}

export const graph_v1 = createApi({
  reducerPath: GraphV1ApiSliceKey,
  baseQuery: axiosBaseQuery({
    getBaseUrl: (state) => getGraphApiBaseUrl(state, 'v1.0'),
    getAuthToken: (state) => getGraphApiAuthToken(state)
  }),
  endpoints: (builder) => ({
    getCurrentUser: builder.query<IGraphUser, void>({
      query: () => `/me`,
      keepUnusedDataFor: 60 * 60 * 24
    }),
    searchApplications: builder.query<
      IBaseGraphValueResponse<IServicePrincipal[]>,
      { $search: string; $top?: number }
    >({
      query: ({ $search, $top = 20 }) => ({
        url: `/applications`,
        params: {
          $search,
          $top,
          $select: 'id,appId,appDisplayName,displayName,homepage,info',
          $count: true
        },
        headers: { ConsistencyLevel: 'eventual' }
      })
    }),
    getApplicationsForUser: builder.query<IServicePrincipal[], void>({
      queryFn: async function queryFn(arg, api, extraOptions, baseQuery) {
        const appRoleAssignmentsApiResult = await baseQuery({
          url: `/me/appRoleAssignments`,
          params: {
            $top: 999,
            $count: true
          },
          headers: { ConsistencyLevel: 'eventual' }
        })

        if (appRoleAssignmentsApiResult.error) {
          return {
            error: appRoleAssignmentsApiResult.error as FetchBaseQueryError
          }
        }

        const ids = (
          appRoleAssignmentsApiResult.data as
            | IGetAppRollAssignmentsForUserResponse
            | undefined
        )?.value
          ?.map(({ resourceId }) => resourceId)
          .filter(isNotNullOrEmpty)

        if (!ids?.length) {
          return { data: [] }
        }

        const servicePrincipalsApiResult = await baseQuery({
          url: `/directoryObjects/getByIds`,
          method: 'post',
          data: { ids, types: ['servicePrincipal'] }
        })

        if (servicePrincipalsApiResult.error) {
          return {
            error: servicePrincipalsApiResult.error as FetchBaseQueryError
          }
        }

        const servicePrincipals =
          (
            servicePrincipalsApiResult.data as IBaseGraphValueResponse<
              IServicePrincipal[]
            >
          )?.value || []

        return { data: servicePrincipals }
      },
      keepUnusedDataFor: 60 * 60 * 24
    }),
    sendMail: builder.mutation<void, IGraphApiSendMailRequest>({
      query: (request) => ({
        url: '/me/sendMail',
        method: 'POST',
        data: request
      })
    })
  })
})

export const {
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useSearchApplicationsQuery,
  useGetApplicationsForUserQuery,
  usePrefetch: useGraph_v1Prefetch,
  useSendMailMutation
} = graph_v1
