import { put, takeLatest } from '@redux-saga/core/effects'
import { flow } from 'lodash'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { IAccount } from '../../../api/account.types'
import { marginAccountFetchActions } from './householdAccountsFetch'
import { householdDetailsFetchActions } from './householdDetailsFetch'
import { getMarginRateAdjustmentFeatureState } from './shared'

const RESET = '@features/@marginRateAdjustment/@marginRateMoreAccounts/RESET'
const SET_ALL_SELECTED_ACCOUNTS =
  '@features/@marginRateAdjustment/@marginRateMoreAccounts/SET_MORE_ACCOUNTS'
const SET_REQUEST_TYPE =
  '@features/@marginRateAdjustment/@marginRateMoreAccounts/SET_REQUEST_TYPE'

export const marginRateMoreAccountsActions = {
  setAllSelectedAccounts: createAction(SET_ALL_SELECTED_ACCOUNTS)<
    IAccount[] | undefined
  >(),
  setRequestType: createAction(SET_REQUEST_TYPE)<MarginRateRequestType>(),
  reset: createAction(RESET)()
}

export type MarginRateRequestType = 'Suggested' | 'Existing' | 'Custom'

export type MarginRateMoreAccountsActionTypes = ActionType<
  typeof marginRateMoreAccountsActions
>

export interface IMarginRateMoreAccountsState {
  setAllSelectedAccounts?: IAccount[]
  requestType?: MarginRateRequestType
}

const initialState: IMarginRateMoreAccountsState = {
  requestType: 'Suggested'
}

export const householdAccountsDetailsReducer = createReducer<
  IMarginRateMoreAccountsState,
  MarginRateMoreAccountsActionTypes
>(initialState)
  .handleAction(
    marginRateMoreAccountsActions.setAllSelectedAccounts,
    (state, action) => ({
      ...state,
      setAllSelectedAccounts: action.payload
    })
  )
  .handleAction(
    marginRateMoreAccountsActions.setRequestType,
    (state, action) => ({
      ...state,
      requestType: action.payload
    })
  )
  .handleAction(marginRateMoreAccountsActions.reset, () => ({
    ...initialState
  }))

const rootSelector = flow(
  getMarginRateAdjustmentFeatureState,
  (x) => x?.householdAccountsDetails
)

export const getMoreAccountsFromHouseholdId = flow(
  rootSelector,
  (x) => x?.setAllSelectedAccounts
)

export const getMarginRateRequestType = flow(
  rootSelector,
  (x) => x?.requestType
)

export const householdAccountsDetailsSagas = [
  () =>
    takeLatest(marginRateMoreAccountsActions.reset, function* () {
      yield put(marginAccountFetchActions.request(undefined))
      yield put(householdDetailsFetchActions.request(undefined))
    })
]
