import { push } from 'connected-react-router'
import { getLocation } from 'shared/services/dom'
import { domainContextActions } from 'store/context/domain'
import { put, select, takeLatest } from 'typed-redux-saga'
import { dashboardNavigationActions } from './actions'

export const dashboardSagas = [
  () =>
    takeLatest(
      dashboardNavigationActions.navigateToDashboard,
      function* (
        action: ReturnType<
          typeof dashboardNavigationActions.navigateToDashboard
        >
      ) {
        const { reps } = action.payload || {}
        const { pathname } = yield* select(getLocation)

        if (reps?.length) {
          yield put(domainContextActions.setSelectedDomainReps(reps))
        }

        if (pathname === '/advisory/dashboard') {
          return
        }

        yield put(push({ pathname: '/advisory/dashboard' }))
      }
    )
]
