import { createReducer } from 'typesafe-actions'
import { SIActions, siValidatePaymentActions } from './action'
import { ISIValidateResponse } from './types'

export interface ISIState {
  siValidateState?: ISIValidateState
}

export interface ISIValidateState {
  isLoading?: boolean
  validateResponse?: ISIValidateResponse
  error?: Error
}
const initialState: ISIState = {}
export const SIReducer = createReducer<ISIState, SIActions>(initialState)
  .handleAction(siValidatePaymentActions.request, (state) => ({
    ...state,
    siValidateState: {
      isLoading: true,
      error: undefined,
      validateResponse: undefined
    }
  }))
  .handleAction(siValidatePaymentActions.success, (state, action) => ({
    ...state,
    siValidateState: {
      isLoading: false,
      error: undefined,
      validateResponse: action.payload
    }
  }))
  .handleAction(siValidatePaymentActions.failure, (state, action) => ({
    ...state,
    siValidateState: {
      isLoading: false,
      error: action.payload.Error,
      validateResponse: action.payload.validateResponse
    }
  }))
  .handleAction(siValidatePaymentActions.clear, (state) => ({
    ...state,
    siValidateState: {
      isLoading: false,
      error: undefined,
      validateResponse: undefined
    }
  }))
