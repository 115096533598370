import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { FC, useMemo } from 'react'
import { useGainLossesStore } from '../../features/GainLosses/gainLossesStore'
import { useYearRangeOptions } from '../../features/GainLosses/YearRangeOptionsHook'
import { useGetRealizedGainLossSummaryQuery } from '../../store/rdot360AnalyticsApi'
import { useRdot360SelectedAccountsApiContext } from '../../store/rdot360Context'
import { useGetStaticConfig } from '../../store/templateApi/templateApi'
import { Dropdown, ISelectableOption } from '../shared'
import { SnackBar } from '../shared/Snackbar'
import Toggle from '../shared/Toggle'
import TileHeading, { BaseHeaderProps } from '../TileHeading'
import { TileLoadingOverlay } from '../TileLoadingOverlay'
import { getGainLossesIncomeClasses } from './GainLossesIncome.styles'
import { GainLossSummarytable } from './GainLossSummaryTable'

const GainLossesIncome: FC<BaseHeaderProps> = ({ onExpand }) => {
  const classes = useMemo(() => getGainLossesIncomeClasses(), [])
  const { apiContextAccounts } = useRdot360SelectedAccountsApiContext()

  const skip = !apiContextAccounts?.length

  const { yearRangeOptions } = useYearRangeOptions(apiContextAccounts)

  const {
    selectedFinancialYear,
    setSelectedFinancialYear,
    isTaxableAccounts,
    setIsTaxableAccounts
  } = useGainLossesStore()

  const {
    data,
    isLoading: isRealizedLoading,
    isFetching: isRealizedFetching,
    error: realizedError
  } = useGetRealizedGainLossSummaryQuery(
    skip
      ? skipToken
      : {
          accounts: apiContextAccounts,
          financialYear: selectedFinancialYear,
          taxable: isTaxableAccounts
        }
  )

  const gainLossesIncomeData = skip || !!realizedError ? undefined : data
  const isError = useMemo(() => !!realizedError, [realizedError])
  const errorMessage =
    (realizedError as Error)?.message || 'An unknown Error Occurred'

  const onDateRangeChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: ISelectableOption<any>
  ) => {
    if (option) {
      setSelectedFinancialYear(option.key as number)
    }
  }
  const { previousBusinessDate } = useGetStaticConfig()

  return (
    <>
      <div css={classes.container}>
        <TileHeading onExpand={onExpand}>Realized Gains/Losses</TileHeading>
        <div
          css={{ width: '100%', display: 'flex', flexDirection: 'row-reverse' }}
        >
          <div
            css={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingBottom: '10px'
            }}
          >
            <div
              css={{
                display: 'flex',
                columnGap: 5,
                alignItems: 'center'
              }}
            >
              <div>All Accounts</div>
              <Toggle
                defaultChecked={isTaxableAccounts}
                onChange={() => {
                  setIsTaxableAccounts(!isTaxableAccounts)
                }}
              />
              <div>Taxable Accounts</div>
            </div>
            <div css={{ width: '150px' }}>
              <Dropdown
                options={yearRangeOptions}
                selectedKey={selectedFinancialYear}
                onChange={onDateRangeChange}
              />
            </div>
          </div>
        </div>
        {isError && (
          <div css={{ paddingBottom: '5px' }}>
            <SnackBar message={errorMessage} type="Failure" />
          </div>
        )}
        <>
          <GainLossSummarytable
            data={gainLossesIncomeData}
            financialYear={selectedFinancialYear}
            isLoading={isRealizedLoading}
          />
        </>
        <div css={classes.note}>
          Rockefeller NFS and other Custodian Accounts Only. | Values are as of{' '}
          {previousBusinessDate || '--'}
        </div>
      </div>
      {(isRealizedLoading || isRealizedFetching) && <TileLoadingOverlay />}
    </>
  )
}

export default GainLossesIncome
