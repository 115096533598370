import { Callout, DirectionalHint } from '@fluentui/react'
import { IFacetResult } from 'api/common.types'
import { ListsFacetFilterComponent } from 'features/Lists/core/features/filter/components/ListsFacetFilter'
import { ListsFilterStatusButton } from 'features/Lists/core/features/filter/components/ListsFilterStatusButton'
import { useCallback, useRef, useState } from 'react'
import { IAiDashboardFilterDefinition } from '../../store/aiDashboard'

export const AiDashboardFilter: React.FC<{
  filterDefinition: IAiDashboardFilterDefinition
  facets?: IFacetResult[]
  selectedValues?: string[]
  displayValue?: string
  onUpdate: (values: string[]) => void
}> = ({ filterDefinition, facets, selectedValues, displayValue, onUpdate }) => {
  const [showFilter, setShowFilter] = useState(false)
  const onDismiss = useCallback(() => setShowFilter(false), [])
  const editFilterRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <div ref={editFilterRef} onClick={() => setShowFilter(true)}>
        <ListsFilterStatusButton>
          <span>{filterDefinition.name}: </span>
          <b>{displayValue}</b>
        </ListsFilterStatusButton>
      </div>
      {showFilter && (
        <Callout
          styles={{ root: { zIndex: 3 } }}
          target={editFilterRef}
          isBeakVisible={true}
          directionalHint={DirectionalHint.bottomAutoEdge}
          onDismiss={onDismiss}
        >
          <div style={{ padding: '10px', width: '400px', minWidth: '400px' }}>
            <ListsFacetFilterComponent
              facets={facets}
              selectedValues={selectedValues}
              onChange={onUpdate}
            />
          </div>
        </Callout>
      )}
    </>
  )
}
