import { IListsFacetFilter } from 'features/Lists/core/contracts/IListsFilter'
import { IOdataListUiActions } from 'features/OdataList/common/IOdataListUiActions'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  FeeTypeOptions,
  getRevenueDashboardFeeType
} from '../../../store/dashboard'

export const useFeeTypeFilter = (props: { uiActions: IOdataListUiActions }) => {
  const { uiActions } = props
  const dispatch = useDispatch()
  const feeType = useSelector(getRevenueDashboardFeeType)

  useEffect(() => {
    const FeeTypeFilterId = 'Fee Type'

    dispatch(
      uiActions.updateFilters({
        [FeeTypeFilterId]: {
          id: FeeTypeFilterId,
          name: FeeTypeFilterId,
          type: 'facet',
          dataPath: 'feeTypeCode',
          hasValue: true,
          values: [feeType],
          displayValue:
            FeeTypeOptions.find((x) => x.key === feeType)?.text ?? ''
        } as IListsFacetFilter
      })
    )
  }, [dispatch, feeType, uiActions])
}
