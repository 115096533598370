import { intersectionWith, xor } from 'lodash'
import { flow } from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { put, select, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { AppState } from '../../../../../store'
import {
  domainActions,
  selectSelectedAccountRepIds,
  selectSelectedAdvisorRepIds,
  selectAccountRepsWithSplitVisibility,
  selectAdvisorRepsForTeam
} from '../../../store/domain'
import {
  accountRepTreeActions,
  getUserHasAccessToAllAccountRepTreeSelectedAccountRepSplits
} from './accountRepTree'
import {
  advisorRepTreeActions,
  getAdvisorRepTreeSelectedReps,
  getAreAllAdvisorRepsSelected
} from './advisorRepTree'
import { usersAndTeamsActions } from './usersAndTeams'

const OPEN_PANEL =
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@repSelectorPanel/OPEN_PANEL'
const CLOSE_PANEL =
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@repSelectorPanel/CLOSE_PANEL'

export const repSelectorPanelActions = {
  openPanel: createAction(OPEN_PANEL)(),
  closePanel: createAction(CLOSE_PANEL)()
}

export interface IRepSelectorPanelFeatureState {
  isOpen: boolean
}

const initialState: IRepSelectorPanelFeatureState = { isOpen: false }

export const repSelectorPanelReducer = createReducer<
  IRepSelectorPanelFeatureState,
  ActionType<typeof repSelectorPanelActions>
>(initialState)
  .handleAction(repSelectorPanelActions.openPanel, (state) => ({
    ...state,
    isOpen: true
  }))
  .handleAction(repSelectorPanelActions.closePanel, () => ({
    ...initialState
  }))

const rootSelector = (state: AppState) =>
  state.features.domain.features.repSelector.repSelectorPanel

export const getIsRepSelectorPanelOpen = flow(
  rootSelector,
  ({ isOpen }) => isOpen
)

const getUserHasAccessToAllAdvisorRepTreeReps = createSelector(
  [getAdvisorRepTreeSelectedReps, selectAccountRepsWithSplitVisibility],
  (selected, splits) => {
    const selectedRepSplitVisibility = intersectionWith(
      splits,
      selected || [],
      (a, b) => a.rep.rcm_repid === b
    )

    return selectedRepSplitVisibility.every(
      ({ userHasAccessToSplits }) => userHasAccessToSplits
    )
  }
)

const getAreAllAdvisorRepTreeUserTeamRepsSelected = createSelector(
  [selectAdvisorRepsForTeam, getAdvisorRepTreeSelectedReps],
  (teamReps, selectedReps) =>
    xor(
      teamReps?.map(({ rcm_repid }) => rcm_repid),
      selectedReps
    ).length === 0
)

const getIsAdvisorRepTreeSelectionValid = createSelector(
  [
    getUserHasAccessToAllAccountRepTreeSelectedAccountRepSplits,
    getUserHasAccessToAllAdvisorRepTreeReps,
    getAreAllAdvisorRepTreeUserTeamRepsSelected,
    getAreAllAdvisorRepsSelected
  ],
  (accountRepsValid, advisorRepsValid, allTeamReps, allAdvisorReps) =>
    accountRepsValid || advisorRepsValid || allTeamReps || allAdvisorReps
)

export const useRepSelectorPanelStore = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(getIsRepSelectorPanelOpen)
  const isAdvisorRepTreeSelectionValid = useSelector(
    getIsAdvisorRepTreeSelectionValid
  )
  const open = useCallback(() => {
    dispatch(repSelectorPanelActions.openPanel())
  }, [dispatch])
  const close = useCallback(() => {
    dispatch(repSelectorPanelActions.closePanel())
  }, [dispatch])

  return { isOpen, open, close, isAdvisorRepTreeSelectionValid }
}

const onAccountRepTreeSetSelected = function* () {
  const isAdvisorRepTreeSelectionValid = yield* select(
    getIsAdvisorRepTreeSelectionValid
  )
  if (isAdvisorRepTreeSelectionValid) {
    return
  }

  yield put(advisorRepTreeActions.setSelected(undefined))
}

const onOpen = function* () {
  const selectedAccountReps = yield* select(selectSelectedAccountRepIds)
  const selectedAdvisorReps = yield* select(selectSelectedAdvisorRepIds)

  yield put(accountRepTreeActions.setSelected(selectedAccountReps))
  yield put(advisorRepTreeActions.setSelected(selectedAdvisorReps))
}

const onClose = function* () {
  yield put(accountRepTreeActions.reset())
  yield put(advisorRepTreeActions.reset())
  yield put(usersAndTeamsActions.reset())
}

export const repSelectorPanelSagas = [
  () => takeLatest(repSelectorPanelActions.openPanel, onOpen),
  () => takeLatest(domainActions.setSelectedDomain, onOpen),
  () => takeLatest(repSelectorPanelActions.closePanel, onClose),
  () =>
    takeLatest(accountRepTreeActions.setSelected, onAccountRepTreeSetSelected)
]
