import { Callout, DirectionalHint, IconButton, ITheme } from '@fluentui/react'
import React, { useCallback, useRef, useState } from 'react'
import { useClasses } from '../../../shared/hooks/useClasses'
import { SettingsContainer } from './Settings'

const getThemedClasses = (theme: ITheme) => {
  return {
    settingsCallout: {
      boxShadow: theme.effects.elevation16,
      minWidth: '200px'
    }
  }
}

export const SettingsButtonContainer: React.FC = () => {
  const [isCalloutOpen, setIsCalloutOpen] = useState(false)
  const toggleSettingsCallout = useCallback(
    () => setIsCalloutOpen(!isCalloutOpen),
    [isCalloutOpen]
  )
  const closeSettingsCallout = useCallback(() => setIsCalloutOpen(false), [])
  const settingsButton = useRef<HTMLSpanElement>(null)
  const classes = useClasses(getThemedClasses)
  return (
    <>
      <span ref={settingsButton} onClick={toggleSettingsCallout}>
        <IconButton
          iconProps={{ iconName: 'Settings' }}
          title="Settings"
          ariaLabel="Settings"
          disabled={false}
          checked={isCalloutOpen}
        />
      </span>
      {isCalloutOpen ? (
        <Callout
          className={classes.settingsCallout}
          styles={{ root: { zIndex: 3 } }}
          target={settingsButton}
          isBeakVisible={false}
          setInitialFocus={true}
          directionalHint={DirectionalHint.bottomLeftEdge}
          doNotLayer={true}
          onDismiss={closeSettingsCallout}
        >
          <SettingsContainer />
        </Callout>
      ) : null}
    </>
  )
}
