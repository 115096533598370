import { addMonths } from '@fluentui/react'
import { format } from 'date-fns'
import { sumBy } from 'lodash'
import {
  Dictionary,
  IDataFilter,
  NoData
} from 'shared/components/DataTableFilters/DataTableFilters'

import { formatDateString } from '../Retirement/store/utility'
import {
  IAccrualData,
  ICashAllocation,
  IFetchPlanToDistributeCash,
  IOpenReceivablesData,
  IPlanByProvider,
  IPlanProvider,
  IProductPlan,
  IRetirementProductCashReceipt,
  ITieredFee,
  ITrendDetails
} from './types'

export const showPeriodFromDate = (date: string): string => {
  if (date) {
    try {
      const inputDate = new Date(date)
      return format(inputDate, 'MMM-yyyy')
    } catch (e) {
      return '-'
    }
  }
  return '-'
}

export const convertToUSD = (data: number | undefined): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  return data ? formatter.format(data) : '0'
}

export const getRemainingAmount = (
  localPlans: IFetchPlanToDistributeCash[] | undefined,
  cashReceipt: IRetirementProductCashReceipt | undefined
): number => {
  const allocatedAmt = sumBy(
    localPlans?.map((x) => ({
      totalCash: sumBy(x.cashallocations || [], (y) => y.cashallocatedamt || 0)
    })),
    (z) => z.totalCash || 0
  )

  const remainingAmt = (cashReceipt?.amount || 0) - allocatedAmt

  return parseFloat(remainingAmt.toFixed(2))
}
export const filterProductPlanBySearchtext = (
  items: IProductPlan[],
  searchText: string
) => {
  let filtered: IProductPlan[] = items || []
  if (items && searchText && Array.isArray(items)) {
    const lowerCaseText = searchText.toLowerCase()
    filtered = items.filter((p) => {
      if (
        p?.planprovider?.toLowerCase()?.includes(lowerCaseText) ||
        p?.planname?.toLowerCase()?.includes(lowerCaseText) ||
        p?.clientname?.toLowerCase()?.includes(lowerCaseText) ||
        p?.teamid?.toLowerCase()?.includes(lowerCaseText) ||
        p?.teamname?.toLowerCase()?.includes(lowerCaseText) ||
        p?.plantype?.toLowerCase()?.includes(lowerCaseText) ||
        p?.feepaymentsource?.toLowerCase()?.includes(lowerCaseText) ||
        p?.plansponser?.state?.toLowerCase()?.includes(lowerCaseText) ||
        formatDateString(p?.asof)?.startsWith(searchText) ||
        formatDateString(p?.committeddate)?.startsWith(searchText) ||
        p?.aum?.startsWith(searchText) ||
        p?.projectedamount?.toFixed(2)?.toString()?.startsWith(searchText) ||
        p?.receivedamount?.toFixed(2)?.toString()?.startsWith(searchText) ||
        p?.accountnumber?.toLowerCase()?.includes(lowerCaseText) ||
        p?.cusip?.toLowerCase()?.includes(lowerCaseText) ||
        p?.committedamount?.toString().toLowerCase()?.includes(lowerCaseText) ||
        p?.marketvalue?.toString().toLowerCase()?.includes(lowerCaseText) ||
        p?.transactiontypecode?.toLowerCase()?.includes(lowerCaseText) ||
        p?.frequency?.toLowerCase()?.includes(lowerCaseText) ||
        p?.feetype?.toLowerCase()?.includes(lowerCaseText) ||
        p?.state?.toLowerCase()?.includes(lowerCaseText) ||
        p?.zipcode?.toLowerCase()?.includes(lowerCaseText) ||
        p?.entity?.toLowerCase()?.includes(lowerCaseText) ||
        p?.segment?.toLowerCase()?.includes(lowerCaseText)
      ) {
        return true
      } else {
        return false
      }
    })
  }
  return filtered
}

export const filterProductPlanByFilterData = (
  items: IProductPlan[],
  filterBy: Dictionary<IDataFilter>
) => {
  let filteredData: IProductPlan[] = items || []
  if (filterBy) {
    Object.entries(filterBy)?.map(([key, data]) => {
      filteredData = filteredData.filter((x) => {
        if (data?.value) {
          return (data.value as (string | number)[]).includes(
            (x[key as keyof IProductPlan] as string | number) || NoData
          )
        } else {
          return true
        }
      })
    })
  }
  return filteredData
}

export const getChangedCashAllocations = (
  newCashAllocation: ICashAllocation[],
  originalCashAllocation: ICashAllocation[]
): ICashAllocation[] => {
  return newCashAllocation.filter(
    (x) =>
      !originalCashAllocation?.some(
        (y) =>
          y.cashallocatedamt === x.cashallocatedamt && y.uniqueid === x.uniqueid
      )
  )
}
export const showNumericPeriodFromDate = (date: string): string => {
  if (date) {
    try {
      const inputDate = new Date(date)
      return format(inputDate, 'MM-yyyy')
    } catch (e) {
      return '-'
    }
  }

  return '-'
}
export const filterCashReceiptsSearchtext = (
  items: IFetchPlanToDistributeCash[],
  searchText: string
) => {
  let filtered: IFetchPlanToDistributeCash[] = items || []
  if (items && searchText && Array.isArray(items)) {
    const lowerCaseText = searchText.toLowerCase()
    filtered = items.filter((p) => {
      if (
        p?.planname?.toLowerCase()?.includes(lowerCaseText) ||
        p?.cusip?.toLowerCase()?.includes(lowerCaseText) ||
        p?.accountnumber?.toLowerCase()?.includes(lowerCaseText) ||
        filterCashallocations(p?.cashallocations || [], lowerCaseText)
      ) {
        return true
      } else {
        return false
      }
    })
  }
  return filtered
}
export const filterPlanproviderSearchtext = (
  items: IPlanProvider[],
  searchText: string
) => {
  let filtered: IPlanProvider[] = items || []
  if (items && searchText && Array.isArray(items)) {
    const lowerCaseText = searchText.toLowerCase()
    filtered = items.filter((p) => {
      if (
        p?.providername?.toLowerCase()?.includes(lowerCaseText) ||
        p?.frequency?.toLowerCase()?.includes(lowerCaseText) ||
        p?.paymenttype?.toLowerCase()?.includes(lowerCaseText) ||
        p?.revenuebps?.toLowerCase()?.includes(lowerCaseText)
      ) {
        return true
      } else {
        return false
      }
    })
  }

  return filtered
}
export const filterPlanByproviderSearchtext = (
  items: IPlanByProvider[],
  searchText: string
) => {
  let filtered: IPlanByProvider[] = items || []
  if (items && searchText && Array.isArray(items)) {
    const lowerCaseText = searchText.toLowerCase()
    filtered = items.filter((p) => {
      if (
        p?.fundname?.toLowerCase().trim()?.includes(lowerCaseText.trim()) ||
        p?.assettype?.toLowerCase().trim()?.includes(lowerCaseText.trim()) ||
        p?.cusip?.toLowerCase().trim()?.includes(lowerCaseText.trim())
      ) {
        return true
      } else {
        return false
      }
    })
  }

  return filtered
}

export const filterAIFundsSearchtext = (
  items: IPlanByProvider[],
  searchText: string
) => {
  let filtered: IPlanByProvider[] = items || []
  if (items && searchText && Array.isArray(items)) {
    const lowerCaseText = searchText.toLowerCase()
    filtered = items.filter((p) => {
      if (
        p?.fundname?.toLowerCase().trim()?.includes(lowerCaseText.trim()) ||
        p?.assettype?.toLowerCase().trim()?.includes(lowerCaseText.trim()) ||
        p?.cusip?.toLowerCase().trim()?.includes(lowerCaseText.trim())
      ) {
        return true
      } else {
        return false
      }
    })
  }

  return filtered
}

export const filterAIFundsByFilterData = (
  feePlans: IPlanByProvider[],
  filterBy: Dictionary<IDataFilter>
) => {
  let filteredData = [...feePlans]
  if (filterBy) {
    Object.entries(filterBy)?.map(([key, data]) => {
      filteredData = filteredData.filter((x) => {
        if (data?.value) {
          return (data.value as (string | number)[]).includes(
            (x[key as keyof IPlanByProvider] as string | number) || NoData
          )
        } else {
          return true
        }
      })
    })
  }

  return filteredData
}

export const getMonthsArray = (cashAllocation: ICashAllocation[]) => {
  const monthYear = cashAllocation.map(function (item) {
    const monthdate = format(new Date(item.accrueddate || ''), 'MMM-yyyy')
    return {
      key: monthdate,
      displayname: monthdate.replace('-', ' ').toUpperCase()
    }
  })
  return monthYear
}
export const getPlanFee = (item: IProductPlan) => {
  if (
    item?.planfee &&
    Array.isArray(item?.planfee) &&
    item?.planfee.length > 0
  ) {
    const planFee = item?.planfee[0]
    return planFee.type + ' : ' + parseFloat(planFee?.feepercent || '0') + '%'
  }
}
export const filterCashallocations = (
  item: ICashAllocation[],
  lowerCaseText: string
) => {
  let filtered: ICashAllocation[] = item || []
  filtered = item?.filter((cash) => {
    if (
      cash?.cashallocatedamt
        ?.toString()
        .toLowerCase()
        ?.includes(lowerCaseText) ||
      cash?.accruedamount?.toString().toLowerCase()?.includes(lowerCaseText)
    ) {
      return true
    } else {
      return false
    }
  })
  if (filtered.length > 0) {
    return true
  } else {
    return false
  }
}
export const checkIfModified = (feeTiered: ITieredFee[]) => {
  let isModified = false
  if (feeTiered.length > 0) {
    feeTiered.forEach((item) => {
      if (item.marketvalue || item.percentage) {
        isModified = true
      }
    })
  }
  return isModified
}

export const addMonthsInSelectedMonthYear = (
  selectedMonthYear: string,
  value: number
): string => {
  try {
    const splitString = selectedMonthYear?.split('-')
    const currentMonthDate = new Date(
      `${splitString?.[0]}/01/${splitString?.[1]}`
    )
    const updatedMonthYear = addMonths(currentMonthDate, value)
    return format(updatedMonthYear, 'MM-yyyy')
  } catch {
    return ''
  }
}

export const b64toBlob = (b64Data: string, sliceSize = 512) => {
  const dataParts = b64Data.split(',')
  const contentType = dataParts?.[0]
    ?.replace('data:', '')
    ?.replace(';base64', '')
  const byteCharacters: string = atob(dataParts?.[1])
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}
export const allowedUplaodFileTypes: string[] = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
]
export const filterAccrualReportSearchtext = (
  items: IAccrualData[],
  searchText: string
) => {
  let filtered: IAccrualData[] = items || []
  if (items && searchText && Array.isArray(items)) {
    const lowerCaseText = searchText.toLowerCase()
    filtered = items.filter((p) => {
      if (
        p?.providername?.toLowerCase()?.includes(lowerCaseText) ||
        p?.planname?.toLowerCase()?.includes(lowerCaseText) ||
        filterTrendDetails(p?.trenddetails || [], lowerCaseText)
      ) {
        return true
      } else {
        return false
      }
    })
  }
  return filtered
}
export const filterTrendDetails = (
  item: ITrendDetails[],
  lowerCaseText: string
) => {
  let filtered: ITrendDetails[] = item || []
  filtered = item?.filter((trend) => {
    if (
      trend?.amount?.toString().toLowerCase()?.includes(lowerCaseText) ||
      trend?.percentage?.toString().toLowerCase()?.includes(lowerCaseText) ||
      trend?.incdecvalue?.toString().toLowerCase()?.includes(lowerCaseText) ||
      trend?.trenddate?.toString().toLowerCase()?.includes(lowerCaseText)
    ) {
      return true
    } else {
      return false
    }
  })
  if (filtered.length > 0) {
    return true
  } else {
    return false
  }
}

export const filterOpenReceivablesSearchtext = (
  items: IOpenReceivablesData[],
  searchText: string
) => {
  let filtered: IOpenReceivablesData[] = items || []
  if (items && searchText && Array.isArray(items)) {
    const lowerCaseText = searchText.toLowerCase()
    filtered = items.filter((p) => {
      if (
        p?.providername?.toLowerCase()?.includes(lowerCaseText) ||
        p?.planname?.toLowerCase()?.includes(lowerCaseText)
      ) {
        return true
      } else {
        return false
      }
    })
  }
  return filtered
}
