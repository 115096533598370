import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { useNotesUi } from './notesUi'

const { actions, reducer } = createSlice({
  name: 'notesDialog',
  initialState: {} as INotesDialogState,
  reducers: {
    show: () => ({
      showDialog: true
    }),
    hide: () => ({
      showDialog: false,
      error: undefined
    }),
    failure: (state, action: PayloadAction<Error>) => ({
      ...state,
      error: action.payload
    })
  }
})

export { reducer as notesDialogReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.secondaryHeader.notes.notesDialog

const getShouldShowDialog = flow(rootSelector, (x) => x.showDialog)
const getPanelError = flow(rootSelector, (x) => x.error)

export interface INotesDialogState {
  showDialog?: boolean
  error?: Error
}

export const useNotesDialog = () => {
  const { resetActiveIndex } = useNotesUi()
  const dispatch = useDispatch()

  const hide = useCallback(() => {
    dispatch(actions.hide())
    resetActiveIndex()
  }, [dispatch, resetActiveIndex])

  const show = useCallback(() => {
    dispatch(actions.show())
  }, [dispatch])

  const setError = useCallback(
    (e: Error) => {
      dispatch(actions.failure(e))
    },
    [dispatch]
  )

  const showDialog = useSelector(getShouldShowDialog)

  const error = useSelector(getPanelError)

  return {
    hide,
    show,
    setError,
    showDialog,
    error
  }
}
