import { ITheme, ProgressIndicator, mergeStyleSets } from '@fluentui/react'
import React from 'react'
import { useSelector } from 'react-redux'
import { useClasses } from 'shared/hooks/useClasses'
import { SearchResponseType } from '../../../../api/common.types'
import { IListsSelectors } from '../store/selectors'

const progressBarHeight = 2

const getThemedClasses = (theme: ITheme) => {
  return mergeStyleSets({
    progressBarPlaceholder: {
      backgroundColor: theme.palette.neutralLight,
      height: progressBarHeight
    }
  })
}

export const createConnectedListsProgess = <T extends SearchResponseType>(
  listSelectors: IListsSelectors<T>
) => {
  const ListsProgress: React.FC = () => {
    const classes = useClasses(getThemedClasses)
    const { dataSelectors, uiSelectors } = listSelectors
    const loading = useSelector(dataSelectors.getIsLoading)
    const exporting = useSelector(
      listSelectors.exportSelectors.getIsExportLoading
    )
    const progress = useSelector(dataSelectors.getProgress)
    const listSize = useSelector(uiSelectors.getListSize)
    return loading || exporting ? (
      <ProgressIndicator
        barHeight={progressBarHeight}
        percentComplete={
          listSize && listSize > 50
            ? (progress || 0) / (listSize || 50) || 0.01
            : undefined
        }
        styles={{
          root: {
            width: '100%'
          },
          itemProgress: { padding: 0, margin: 0 }
        }}
      />
    ) : (
      <div className={classes.progressBarPlaceholder} />
    )
  }
  return ListsProgress
}
