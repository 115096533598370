import { getAltsAdvisorRevenueDetail } from 'api/datahub'
import type { IOdataRequest } from 'api/odata.types'
import axios, { CancelTokenSource } from 'axios'
import {
  selectIsFullDomainSelected,
  selectSelectedAccountRepIds
} from 'features/Domain/store/domain'
import type { IListsFilter } from 'features/Lists/core/contracts/IListsFilter'
import { convertColumnTypeToFilterType } from 'features/OdataList/common/service'
import { createOdataListWithFacetsStore } from 'features/OdataList/store/odataListWithFacetsStore'
import { keyBy } from 'lodash'
import type { IApiOptions } from 'shared/contracts/IApiOptions'
import { isNotNullOrEmpty } from 'shared/guards'
import { AppState, getRockefellerApiOptions } from 'store/shared'
import { call, cancelled, select } from 'typed-redux-saga'

import { altsAdvisorRevenueDetailColumns } from './column/columnConfigOdataList'
import { AltsAdvisorRevenueDetailColumnName } from './column/columnNameTypes'

export const defaultColumnsIds: AltsAdvisorRevenueDetailColumnName[] =
  altsAdvisorRevenueDetailColumns.map((x) => x.name)

const getDatahubApiOptions = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)
  return [apiOptions, source] as [IApiOptions, CancelTokenSource]
}

export const fetchAltsAdvisorRevenueDetail = function* (
  request: IOdataRequest
) {
  const selectedAccountRepIds = yield* select(selectSelectedAccountRepIds)
  const isFullDomainSelected = yield* select(selectIsFullDomainSelected)

  let domainFilter = ''
  if (!isFullDomainSelected && selectedAccountRepIds?.length) {
    const reps = selectedAccountRepIds.map((rep) => `'${rep}'`).join(',')
    domainFilter = `(advisorRepNo in (${reps}) or custodianRepNo in (${reps}))`
  }

  request.filters = [...(request.filters || []), domainFilter].filter(
    isNotNullOrEmpty
  )

  const [apiOptions, cancelTokenSource] = yield* call(getDatahubApiOptions)
  try {
    return yield* call(getAltsAdvisorRevenueDetail, apiOptions, request)
  } finally {
    if (yield* cancelled()) {
      cancelTokenSource.cancel()
    }
  }
}

const rootSelector = (state: AppState) =>
  state.modules.airevenue.modules.dashboard.features.altsAdvisorRevenueDetail
    .dashboard.features.AltsAdvisorRevenue

const uiFilters = keyBy(
  altsAdvisorRevenueDetailColumns
    .filter((x) => x.filterable)
    .map((column): IListsFilter => {
      const base = {
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        dataPath: column.dataPath,
        hasValue: false
      }

      return base
    }),
  ({ id }) => id
)

const selectedColumnIds = [...defaultColumnsIds].splice(0, 14)

const store = createOdataListWithFacetsStore({
  prefix:
    '@modules/@airevenue/@modules/@dashboard/@features/@altsadvisorrevenuedetail',
  getOdataResults: fetchAltsAdvisorRevenueDetail,
  initialState: {
    data: {},
    ui: {
      columns: altsAdvisorRevenueDetailColumns,
      selectedColumns: selectedColumnIds,
      filters: uiFilters
    },
    facets: {}
  },
  rootSelector
})

export const { actions, selectors, reducer, sagas } = store
