import axios from 'axios'
import { flow } from 'lodash/fp'
import { call, cancelled, put, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import {
  getBdaDepartmentAllowances,
  IDynamicsApiResult,
  IDynamicsBdaDepartmentAllowance
} from '../../../../../api/dynamics'
import { isNotNullOrUndefined } from '../../../../../shared/guards'
import { AppState } from '../../../../../store'
import { getDynamicsApiOptions } from '../../../../../store/shared/sagas'

const REQUEST =
  '@modules/@advisory/@modules/@bda/@bdaDepartmentAllowances/REQUEST'
const SUCCESS =
  '@modules/@advisory/@modules/@bda/@bdaDepartmentAllowances/SUCCESS'
const FAILURE =
  '@modules/@advisory/@modules/@bda/@bdaDepartmentAllowances/FAILURE'

export const bdaDepartmentAllowancesActions = {
  request: createAction(REQUEST)<number>(),
  success: createAction(SUCCESS)<IDynamicsBdaDepartmentAllowance[]>(),
  failure: createAction(FAILURE)<Error>()
}

export type bdaDepartmentAllowancesActionTypes = ActionType<
  typeof bdaDepartmentAllowancesActions
>

export interface IbdaDepartmentAllowancesState {
  items?: IDynamicsBdaDepartmentAllowance[]
  loading?: boolean
  error?: Error
}

const initialState: IbdaDepartmentAllowancesState = {
  loading: true
}

export const bdaDepartmentAllowancesReducer = createReducer<
  IbdaDepartmentAllowancesState,
  bdaDepartmentAllowancesActionTypes
>(initialState)
  .handleAction(bdaDepartmentAllowancesActions.request, () => ({
    ...initialState
  }))
  .handleAction(bdaDepartmentAllowancesActions.success, (state, action) => ({
    ...state,
    items: action.payload,
    loading: false
  }))
  .handleAction(bdaDepartmentAllowancesActions.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }))

export const getBdaDepartmentAllowancesState = (state: AppState) =>
  state.modules.advisory.modules.bda.departmentAllowances
export const getBdaDepartmentAllowancesItems = flow(
  getBdaDepartmentAllowancesState,
  (x) => x.items
)

export const getBdaDepartmentAllowancesLoading = flow(
  getBdaDepartmentAllowancesState,
  (x) => x.loading
)
export const getBdaDepartmentAllowancesError = flow(
  getBdaDepartmentAllowancesState,
  (x) => x.error
)

export const fetchbdaDepartmentAllowances = function* (
  action: ReturnType<typeof bdaDepartmentAllowancesActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getDynamicsApiOptions, source.token)
  const pageSize = 200

  try {
    const bdaDepartmentAllowances = yield* call(
      getBdaDepartmentAllowances,
      apiOptions,
      new Date(Date.UTC(action.payload, 0, 1, 0, 0, 0, 0)),
      pageSize
    )

    const pages = [bdaDepartmentAllowances]
    let nextLink = bdaDepartmentAllowances['@odata.nextLink']

    const fetchNext = (nextLink: string) =>
      axios
        .get<IDynamicsApiResult<IDynamicsBdaDepartmentAllowance>>(nextLink, {
          headers: {
            Authorization: `Bearer ${apiOptions.accessToken}`,
            Prefer: `odata.maxpagesize=${pageSize}`
          }
        })
        .then((x) => x.data)

    while (nextLink) {
      const next = yield* call(fetchNext, nextLink)
      pages.push(next)
      nextLink = next['@odata.nextLink']
    }

    const results =
      pages
        .map(({ value }) => value)
        .filter(isNotNullOrUndefined)
        .flat() || []

    yield put(bdaDepartmentAllowancesActions.success(results))
  } catch (e: any) {
    yield put(bdaDepartmentAllowancesActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const bdaDepartmentAllowancesSagas = [
  () =>
    takeLatest(
      bdaDepartmentAllowancesActions.request,
      fetchbdaDepartmentAllowances
    )
]
