import axios from 'axios'
import { call, cancelled, put, select, takeLatest } from 'typed-redux-saga'
import { IApiOptions } from '../../shared/contracts/IApiOptions'
import { IEnvironmentApiConfiguration } from '../../shared/services/environment/IEnvironmentConfiguration'
import { tryAcquireAccessToken } from '../../store/shared/sagas'
import { getRockefellerApiConfig } from '../../store/system'
import {
  getRelatedLoginsByAccountId,
  getRelatedLoginsByPartyId,
  IRelatedParty
} from './api'
import { relatedPartyDataActions, relatedPartyPanelActions } from './store'

export const relatedPartyPanelSagas = [
  () =>
    takeLatest(
      relatedPartyPanelActions.openPanel,
      function* (
        action: ReturnType<typeof relatedPartyPanelActions.openPanel>
      ) {
        yield put(relatedPartyDataActions.request(action.payload))
      }
    ),
  () =>
    takeLatest(
      relatedPartyDataActions.request,
      function* (action: ReturnType<typeof relatedPartyDataActions.request>) {
        const apiConfig: IEnvironmentApiConfiguration = yield select(
          getRockefellerApiConfig
        )
        const accessToken: string = yield call(
          tryAcquireAccessToken,
          apiConfig.scopes
        )

        // eslint-disable-next-line import/no-named-as-default-member
        const source = axios.CancelToken.source()

        const apiOptions: IApiOptions = {
          accessToken,
          apiRoot: apiConfig.root,
          cancelToken: source.token
        }

        try {
          const relatedLogins: IRelatedParty[] = yield call(
            action.payload.idType === 'partyId'
              ? getRelatedLoginsByPartyId
              : getRelatedLoginsByAccountId,
            action.payload.id,
            apiOptions
          )

          yield put(relatedPartyDataActions.success(relatedLogins))
        } catch (e: any) {
          yield put(relatedPartyDataActions.failure(e))
        } finally {
          if (yield* cancelled()) {
            source.cancel()
          }
        }
      }
    )
]
