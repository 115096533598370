import { Reducer } from 'redux'
import { useInjectReducer, useInjectSaga } from 'redux-injectors'
import { ForkEffect } from 'redux-saga/effects'
import { createRootSaga } from '../../shared/services/sagas'
import type { AppState } from './contracts'

export const createStoreInjectorHook = (
  key: keyof AppState,
  reducer?: Reducer<any, any>,
  sagas?: (() => ForkEffect | Generator)[]
) => {
  const rootSaga = sagas && createRootSaga(key, sagas)
  return () => {
    const isReducerInjected = reducer
      ? useInjectReducer({ key, reducer })
      : true
    const isSagaInjected = rootSaga
      ? useInjectSaga({ key, saga: rootSaga })
      : true
    return isReducerInjected && isSagaInjected
  }
}
