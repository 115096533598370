import { MessageBarType } from '@fluentui/react'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { pushNotification } from '../../../../../../../../../features/Notifications'
import { downloadUrlAsFile } from '../../../../../../../../../shared/downloads'
import { AppState } from '../../../../../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../../../store/shared/asyncStore'
import { PotentialPayoutExportWorker } from './export.worker'
import { payoutsListSelectors } from './payoutsList'

export const potentialPayoutExportActions = createAsyncAction(
  '@modules/@advisory/@modules/@teams/@modules/@hurdles/@features/@potentialPayout/@export/REQUEST',
  '@modules/@advisory/@modules/@teams/@modules/@hurdles/@features/@potentialPayout/@export/SUCCESS',
  '@modules/@advisory/@modules/@teams/@modules/@hurdles/@features/@potentialPayout/@export/FAILURE'
)<undefined, undefined, Error>()

export const potentialPayoutExportReducer = createAsyncReducer(
  potentialPayoutExportActions
)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.teams.modules.hurdles.features.potentialPayouts
    .potentialPayoutExport
export const {
  getError: getPotentialPayoutExportError,
  getIsLoading: getIsPotentialPayoutExportLoading
} = createAsyncSelectors(rootSelector)

const onExport = function* () {
  try {
    const results = yield* select(
      payoutsListSelectors.getFilteredAndSortedItems
    )
    const worker = new Worker(new URL('./export.worker.ts', import.meta.url))
    const { createAndDownloadPotentialPayoutExport } =
      wrap<PotentialPayoutExportWorker>(worker)

    const result = yield* call(createAndDownloadPotentialPayoutExport, results)

    const filename = `Potential Payouts ${format(
      new Date(),
      'MM-dd-yyyy'
    )}.xlsx`
    downloadUrlAsFile(result, filename)
    yield put(potentialPayoutExportActions.success())
  } catch (e: unknown) {
    yield put(potentialPayoutExportActions.failure(e as Error))
  }
}

const onFailure = function* (
  action: ReturnType<typeof potentialPayoutExportActions.failure>
) {
  yield call(pushNotification, {
    message: `Export failed: ${action.payload.message}`,
    type: MessageBarType.error
  })
}

export const potentialPayoutExportSagas = [
  () => takeLatest(potentialPayoutExportActions.request, onExport),
  () => takeLatest(potentialPayoutExportActions.failure, onFailure)
]
