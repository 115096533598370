import { getRandomColor } from 'api/random'
import { sumBy } from 'lodash'
import { getTaxonomyColorLookupByType } from 'modules/Advisory/modules/Rdot360/shared/taxonomy'
import { ICategoryPositionResponseValue } from 'modules/Advisory/modules/Rdot360/store/holdingsApi/ICategoryPositionResponse'
import { FC, useMemo } from 'react'
import { HighchartsComponent } from 'shared/highcharts'

const getAssetsAllocationOptions = (
  data: ICategoryPositionResponseValue[] = [],
  totalValue = 0,
  colorMapping: Record<string, string> = {}
): Highcharts.Options => ({
  title: {
    text:
      totalValue === 0
        ? ''
        : totalValue > 0
        ? 'Total<br> $' + Math.round(totalValue).toLocaleString('en-US')
        : 'Total<br> -$' +
          Math.round(Math.abs(totalValue)).toLocaleString('en-US'),
    align: 'center',
    verticalAlign: 'middle',
    x: 0,
    y: 10
  },
  chart: {
    plotShadow: false,
    type: 'pie'
  },
  tooltip: {
    pointFormat:
      '<p>{point.isNegative}${point.y}</p>' +
      '<br>{point.isNegative}{point.percentage:.1f}%</br>'
  },
  accessibility: {
    point: {
      valueSuffix: '%'
    }
  },

  plotOptions: {
    series: {
      states: {
        hover: {
          enabled: true
        }
      }
    },
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      point: {
        events: {
          legendItemClick: () => false
        }
      },
      showInLegend: false,
      borderWidth: 0,
      dataLabels: {
        enabled: false
      }
    }
  },
  series: [
    {
      type: 'pie',
      innerSize: '80%',
      data: data
        .map((item) => ({
          name: item.assetcat === 'Cash' ? 'Cash & Equivalents' : item.assetcat,
          y: Math.round(Math.abs(item.mktval || 0) || 0),
          color: `#${
            colorMapping[item.assetcat || 'Others'] ?? getRandomColor()
          }`,
          value: item.mktval,
          isNegative: item.mktval && item.mktval < 0 ? '-' : ''
        }))
        .sort((a, b) => (b.value || 0) - (a.value || 0))
    }
  ],
  credits: {
    enabled: false
  },
  legend: {
    backgroundColor: undefined,
    itemStyle: {
      color: 'black'
    },
    enabled: true,
    symbolRadius: 0,
    layout: 'vertical',
    align: 'right',
    itemWidth: 150,
    verticalAlign: 'middle'
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500
        }
      }
    ]
  }
})
const containerProps: React.HTMLAttributes<unknown> = {
  style: { height: '100%', width: 350, flex: '0.3' }
}
export const InvestmentsChart: FC<{
  data?: ICategoryPositionResponseValue[]
  selectedType: string
}> = ({ data = [], selectedType }) => {
  const totalValue = useMemo(
    () => sumBy(data, ({ mktval = 0 }) => mktval),
    [data]
  )
  const colorLookup = getTaxonomyColorLookupByType(selectedType)
  const assetsAllocationOptions = useMemo(
    () => getAssetsAllocationOptions(data, totalValue, colorLookup),
    [data, colorLookup, totalValue]
  )
  return (
    <>
      <HighchartsComponent
        containerProps={containerProps}
        options={assetsAllocationOptions}
      />
    </>
  )
}
