import {
  CommandBar,
  ICommandBarItemProps,
  ITheme,
  ProgressIndicator,
  Stack,
  Text
} from '@fluentui/react'
import { format } from 'date-fns'
import { constants } from 'modules/Advisory/shared/theme'
import React, { memo, useCallback, useMemo } from 'react'
import { FormattedNumber } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { PaletteComponent } from 'shared/components/Palette'
import { IHurdle, IHurdleMetric } from '../../../../../../../../api/datahub'
import {
  IOdataDataListCellRenderProps,
  OdataList
} from '../../../../../../../../features/OdataList/containers/OdataList'
import { parseDateISOStringInLocalTimezone } from '../../../../../../../../shared'
import { USD } from '../../../../../../../../shared/components/Formatting'
import { isNotNullOrUndefined } from '../../../../../../../../shared/guards'
import { useClasses } from '../../../../../../../../shared/hooks/useClasses'
import { DateCell } from '../../../../../Accounts/modules/MarginRateRequests/features/MarginRateRequestList'
import { getIsHurdleAdmin } from '../../store/selectors'
import { hurdleEditFormActions } from '../HurdleEdit/store/hurdleEditForm'
import { hurdleEditPanelActions } from '../HurdleEdit/store/hurdleEditPanel'
import {
  getIsHurdleDefinitionExportLoading,
  hurdleDefinitionExportActions
} from './export'
import { HurdleNameCell } from './HurdleNameCell'
import {
  getIsHurdlesLoading,
  HurdleDefinitionListColumnName,
  hurdleDefinitionListUiActions,
  hurdleDefinitionListUiSelectors
} from './store'

const getThemedClasses = (theme: ITheme) => ({
  commandBar: {
    borderBottom: `solid 2px ${theme.palette.neutralLight}`
  }
})
const getDescription = (hurdle: IHurdle) => {
  if (!hurdle.measurements) {
    return ''
  }
  return hurdle.measurements.length > 1
    ? `${hurdle.measurements.length} Measurements`
    : [
        hurdle.measurements?.[0]?.targetDate &&
          formatDate(
            parseDateISOStringInLocalTimezone(
              hurdle.measurements?.[0]?.targetDate
            )
          ),
        hurdle?.measurements?.[0]?.intervalOfMeasurement,
        (hurdle?.measurements?.[0]?.metrics?.length || 0) > 1
          ? `${hurdle?.measurements?.[0]?.metrics?.length} Metrics`
          : [
              hurdle.measurements[0]?.metrics?.[0]?.metricType,
              hurdle.measurements[0]?.metrics?.[0]?.metricValue
                ? getMetricValueString(hurdle.measurements?.[0]?.metrics?.[0])
                : undefined
            ]
              .filter(isNotNullOrUndefined)
              .join(' | ')
      ]
        .filter(isNotNullOrUndefined)
        .join(' | ')
}
const getMetricValueString = (metric: IHurdleMetric) => {
  const { metricType, metricValue } = metric
  return metricType === 'AUS'
    ? `$${metricValue?.toLocaleString()}`
    : `${metricValue}%`
}

const formatDate = (date: Date | undefined) => {
  return date ? format(new Date(date), `MMM yyyy`) : ''
}

const CellComponents: Record<
  HurdleDefinitionListColumnName,
  React.FC<IOdataDataListCellRenderProps<IHurdle>>
> = {
  'Hurdle Name': ({ item }) => <HurdleNameCell hurdle={item} />,
  'Team / Individual': ({ item }) => (
    <Text nowrap={true} block={true} key={item.entityName}>
      {item.entityName}
    </Text>
  ),
  'Advertised Production': ({ item }) => (
    <Text nowrap={true} block={true}>
      <USD value={item.advertisedT12Revenue || 0} fractionDigits={0} />
    </Text>
  ),
  Description: ({ item }) => (
    <Text nowrap={true} block={true}>
      {getDescription(item)}
    </Text>
  ),
  Probability: ({ item }) =>
    item.probability ? (
      <Text nowrap={true} block={true}>
        <FormattedNumber
          value={item.probability}
          maximumFractionDigits={0}
          minimumFractionDigits={0}
        />
        %
      </Text>
    ) : (
      <Text nowrap={true} block={true}>
        --
      </Text>
    ),
  Created: ({ item }) => (
    <Stack styles={{ root: { minWidth: 0 } }}>
      <DateCell value={item.createdDate} />
      <Text variant="small" nowrap={true} block={true}>
        {item.createdBy}
      </Text>
    </Stack>
  )
}
const HurdleDefinitionListCell: React.FC<
  IOdataDataListCellRenderProps<IHurdle>
> = memo(({ item, column }) => {
  const Component =
    CellComponents[column.name as HurdleDefinitionListColumnName]
  return Component ? <Component item={item} column={column} /> : null
})

export const HurdleDefinitionList: React.FC = () => {
  const dispatch = useDispatch()
  const onExport = useCallback(() => {
    dispatch(hurdleDefinitionExportActions.request())
  }, [dispatch])
  const isExportLoading = useSelector(getIsHurdleDefinitionExportLoading)
  const isHurdleAdmin = useSelector(getIsHurdleAdmin)
  const commandBarItems = useMemo(() => {
    const items: ICommandBarItemProps[] = [
      ...(isHurdleAdmin
        ? [
            {
              key: 'newHurdle',
              name: 'New Hurdle',
              iconProps: { iconName: 'Add' },
              onClick: () => {
                dispatch(hurdleEditPanelActions.open())
                dispatch(hurdleEditFormActions.reset())
              }
            }
          ]
        : []),
      {
        key: 'Export',
        text: isExportLoading ? 'Exporting' : 'Export To Excel',
        disabled: isExportLoading,
        iconProps: { iconName: 'ExcelDocument' },
        onClick: onExport
      }
    ]
    return items
  }, [dispatch, isExportLoading, isHurdleAdmin, onExport])
  const classes = useClasses(getThemedClasses)
  const isLoading = useSelector(getIsHurdlesLoading)
  const header = useMemo(
    () => (
      <Stack>
        <CommandBar
          className={classes.commandBar}
          items={commandBarItems}
          styles={{ root: { paddingLeft: '0px' } }}
        />
        <ProgressIndicator
          progressHidden={!isLoading}
          styles={{
            itemProgress: { padding: 0, margin: 0 }
          }}
        />
      </Stack>
    ),
    [classes.commandBar, commandBarItems, isLoading]
  )

  return (
    <div>
      <PaletteComponent>
        <OdataList
          actions={hurdleDefinitionListUiActions}
          selectors={hurdleDefinitionListUiSelectors}
          onRenderCell={HurdleDefinitionListCell}
          stickyHeaderOffset={constants.headerHeight}
          secondaryHeader={header}
        />
      </PaletteComponent>
    </div>
  )
}
