import { DragEndEvent } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  Checkbox,
  Icon,
  IconButton,
  Link,
  mergeStyleSets,
  Stack,
  Text,
  useTheme
} from '@fluentui/react'
import { keyBy } from 'lodash'
import React, { useCallback, useMemo, PropsWithChildren } from 'react'
import { BasicDndContext } from 'shared/components/BasicDndContext'
import { IColumnDefinition } from '../contracts/IColumnDefinition'
import { IColumnState } from '../contracts/IColumnState'

export interface IListsDataTableColumnEditorComponentProps {
  columnDefinitions: IColumnDefinition[]
  columnState: IColumnState[]
  onColumnChanged: (newState: IColumnState) => void
  enablePreviewColumns: boolean
}

export const SortableItem: React.FC<
  PropsWithChildren<{ id: string; className?: string }>
> = ({ id, className, children }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id })

  const style = {
    opacity: isDragging ? 0.8 : undefined,
    zIndex: isDragging ? 99 : undefined,
    transform: CSS.Transform.toString(transform),
    transition,
    overflowAnchor: 'none' as const
  }

  return (
    <div
      ref={setNodeRef}
      className={className}
      style={style}
      {...attributes}
      {...listeners}
    >
      {children}
    </div>
  )
}

interface IColumnEditorItemProps {
  columnDefinition: IColumnDefinition
  columnState: IColumnState
  onColumnChanged: (newState: IColumnState) => void
}

const useClasses = () => {
  const theme = useTheme()
  const classes = useMemo(
    () =>
      mergeStyleSets({
        column: {
          backgroundColor: theme.palette.white,
          border: `solid 1px ${theme.palette.neutralQuaternary}`,
          padding: '0 10px'
        }
      }),
    [theme]
  )
  return classes
}

const ColumnEditorItem: React.FC<IColumnEditorItemProps> = ({
  columnDefinition,
  columnState,
  onColumnChanged
}) => {
  const classes = useClasses()
  const onSearchClick = () =>
    onColumnChanged({
      ...columnState,
      includeInSearch: !columnState.includeInSearch
    })
  const onStickyClick = () =>
    onColumnChanged({
      ...columnState,
      sticky: !columnState.sticky,
      selected: true
    })
  const onAddOrRemove = () =>
    onColumnChanged({
      ...columnState,
      selected: !columnState.selected,
      sticky: false
    })

  return (
    <Stack horizontal={true} verticalAlign="center" className={classes.column}>
      <Stack horizontal={true} tokens={{ childrenGap: 10 }} grow={1}>
        <Checkbox
          title={
            columnState.selected ? 'Remove this column' : 'Add this column'
          }
          checked={columnState.selected}
          onChange={onAddOrRemove}
        />
        <Stack.Item grow={1}>
          <Text block={true}>{columnDefinition.name}</Text>
          {columnDefinition.preview && (
            <Text
              variant="small"
              title="The information in this column may not be accurate"
            >
              <Link>Preview</Link>
            </Text>
          )}
        </Stack.Item>
      </Stack>
      {columnDefinition.searchable && (
        <IconButton
          title="Include results from this column when searching the list"
          checked={columnState.includeInSearch}
          iconProps={{
            iconName: 'Search'
          }}
          onClick={onSearchClick}
        />
      )}
      <IconButton
        title="Pin this column to the left"
        checked={columnState.sticky}
        iconProps={{
          iconName: columnState.sticky ? 'PinnedSolid' : 'Pinned'
        }}
        onClick={onStickyClick}
      />
      <div
        title="Drag to reorder columns"
        style={{
          marginLeft: '8px',
          width: '16px',
          height: '16px',
          cursor: 'grab'
        }}
      >
        <Icon
          iconName="GripperDotsVertical"
          styles={{ root: { cursor: 'inherit !important' } }}
        />
      </div>
    </Stack>
  )
}

export const SortableListsDataTableColumnEditorComponent: React.FC<
  IListsDataTableColumnEditorComponentProps & {
    onColumnMoved: (
      column: IColumnState,
      oldIndex: number,
      newIndex: number
    ) => void
  }
> = (props) => {
  const {
    onColumnMoved,
    columnState,
    onColumnChanged,
    columnDefinitions,
    enablePreviewColumns
  } = props

  const columnDefinitionMap = useMemo(
    () => keyBy(columnDefinitions, (x) => x.id),
    [columnDefinitions]
  )

  const columnsWithIds = useMemo(
    () => columnState.map((x) => ({ ...x, id: x.columnId })),
    [columnState]
  )

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event

      if (!over) {
        return
      }

      const oldIndex = columnsWithIds.findIndex(({ id }) => id === active.id)
      const newIndex = columnsWithIds.findIndex(({ id }) => id === over.id)

      onColumnMoved(columnState[oldIndex], oldIndex, newIndex)
    },
    [columnState, columnsWithIds, onColumnMoved]
  )

  return (
    <div style={{ position: 'relative' }}>
      <Stack tokens={{ childrenGap: 4 }}>
        <BasicDndContext
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext items={columnsWithIds} id="columnId">
            {columnsWithIds.map((column) => {
              const columnDefinition = columnDefinitionMap[column.columnId]
              if (!columnDefinition) {
                return null
              }

              if (!enablePreviewColumns && columnDefinition.preview) {
                return null
              }

              return (
                <SortableItem id={column.columnId} key={column.columnId}>
                  <ColumnEditorItem
                    columnDefinition={columnDefinition}
                    columnState={column}
                    onColumnChanged={onColumnChanged}
                  />
                </SortableItem>
              )
            })}
          </SortableContext>
        </BasicDndContext>
      </Stack>
    </div>
  )
}
