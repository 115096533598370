import { useDomainStore } from 'features/Domain/store/domain'
import { useMemo } from 'react'

export const useSelectedReps = () => {
  const { selectedAccountRepIds, isFullDomainSelected } = useDomainStore()

  const shouldMakeApiCall =
    isFullDomainSelected || selectedAccountRepIds?.length

  const selectedReps = useMemo(
    () => (isFullDomainSelected ? undefined : selectedAccountRepIds),
    [isFullDomainSelected, selectedAccountRepIds]
  )

  return { selectedReps, shouldMakeApiCall }
}
