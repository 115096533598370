import { createReducer } from 'typesafe-actions'
import {
  PeriodicPaymentActions,
  getRecurringScheduledDaysActions
} from './actions'
import { IRecurringScheduledDay } from './types'

export interface IPeriodicPaymentState {
  recurringScheduledDaysState?: IRecurringScheduledDaysState
}
interface IRecurringScheduledDaysState {
  isLoading: boolean
  recurringScheduledDays?: IRecurringScheduledDay[]
  error?: Error
}
const initialState: IPeriodicPaymentState = {}
export const periodicPaymentReducer = createReducer<
  IPeriodicPaymentState,
  PeriodicPaymentActions
>(initialState)
  .handleAction(getRecurringScheduledDaysActions.request, (state) => ({
    ...state,
    recurringScheduledDaysState: {
      ...state.recurringScheduledDaysState,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(getRecurringScheduledDaysActions.success, (state, action) => ({
    ...state,
    recurringScheduledDaysState: {
      isLoading: false,
      recurringScheduledDays: action.payload,
      error: undefined
    }
  }))
  .handleAction(getRecurringScheduledDaysActions.failure, (state, action) => ({
    ...state,
    recurringScheduledDaysState: {
      isLoading: false,
      recurringScheduledDays: undefined,
      error: action.payload
    }
  }))
  .handleAction(getRecurringScheduledDaysActions.save, (state) => ({
    ...state,
    recurringScheduledDaysState: {
      ...state.recurringScheduledDaysState,
      isLoading: false,
      error: undefined
    }
  }))
  .handleAction(getRecurringScheduledDaysActions.clear, (state) => ({
    ...state,
    recurringScheduledDaysState: undefined
  }))
