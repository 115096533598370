//export const overridesFetchReducer = createAsyncReducer(OverridesFetchActions)

import { MessageBarType } from '@fluentui/react'
import { createReducer } from 'typesafe-actions'
import { IFirm } from '../api/types'
import {
  FirmActionsTypes,
  firmFetchActions,
  insertUpdateFirmActions
} from './actions'

export interface IFirmState {
  fetchFirmState?: {
    inProgress: boolean
    error?: Error
    data?: IFirm[]
  }
  insertUpdateFirmtate?: {
    inProgress: boolean
    error?: Error
    msg?: string
    messageType?: MessageBarType
    outputResponse?: string
    data?: any
  }
}

const initialState: IFirmState = {}

export const firmReducer = createReducer<IFirmState, FirmActionsTypes>(
  initialState
)
  .handleAction(firmFetchActions.request, (state) => ({
    ...state,
    fetchFirmState: {
      inProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(firmFetchActions.success, (state, action) => ({
    ...state,
    fetchFirmState: {
      inProgress: false,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(firmFetchActions.failure, (state, action) => ({
    ...state,
    fetchFirmState: {
      inProgress: false,
      error: action.payload,
      data: undefined
    }
  }))
  .handleAction(insertUpdateFirmActions.request, (state) => ({
    ...state,
    insertUpdateFirmtate: {
      inProgress: true,
      error: undefined,
      msg: undefined,
      messageType: undefined
    }
  }))
  .handleAction(insertUpdateFirmActions.success, (state, action) => ({
    ...state,
    insertUpdateFirmtate: {
      inProgress: false,
      error: undefined,
      msg: action.payload?.msg,
      messageType: MessageBarType.success,
      data: action.payload,
      outputResponse: action.payload?.outputResponse
    }
  }))
  .handleAction(insertUpdateFirmActions.failure, (state, action) => ({
    ...state,
    insertUpdateFirmtate: {
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(insertUpdateFirmActions.cancel, (state) => ({
    ...state,
    insertUpdateFirmtate: undefined
  }))
