import { ProgressIndicator } from '@fluentui/react'
import { GroupingState, SortingState, Table } from '@tanstack/react-table'
import { HorizontalScrollContainer } from 'shared/components/HorizontalScrollContainer'
import { useClasses } from 'shared/hooks/useClasses'
import { AnnuityPPLICombined } from '../../shared/types/annuityPPLICombined'
import { getThemedClasses } from './getThemedClasses'
import { TableBody } from './TableBody'
import { TableFooter } from './TableFooter'
import { TableHead } from './TableHead'

export const TableWrapper: React.FC<{
  table: Table<AnnuityPPLICombined>
  grouping: GroupingState
  isSummaryFetching: boolean
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>
}> = ({ grouping, isSummaryFetching, table, setSorting }) => {
  const isAllRowsExpanded = table.getIsAllRowsExpanded()

  const toggleAllRowsExpandedHandler = table.getToggleAllRowsExpandedHandler()

  const headers = table.getFlatHeaders()

  const { rows } = table.getRowModel()

  const classes = useClasses(getThemedClasses)

  return (
    <>
      <HorizontalScrollContainer>
        <table
          className={[
            classes.summaryTable,
            classes.tableHeader,
            classes.tableFooter
          ].join(' ')}
        >
          <caption>
            <ProgressIndicator
              progressHidden={!isSummaryFetching}
              styles={{
                itemProgress: { padding: 0, margin: 0 }
              }}
            />
          </caption>
          <TableHead
            headers={headers}
            grouping={grouping}
            isAllRowsExpanded={isAllRowsExpanded}
            toggleAllRowsExpandedHandler={toggleAllRowsExpandedHandler}
            setSorting={setSorting}
          />
          <TableBody rows={rows} groupingLength={grouping.length} />
          <TableFooter headers={headers} groupingLength={grouping.length} />
        </table>
      </HorizontalScrollContainer>

      <p>
        Rows: <em>{rows.length}</em>
        {grouping.length > 0 && (
          <>
            {', '}
            grouped by: <em>{grouping?.join(', ')}</em>
          </>
        )}
      </p>
    </>
  )
}
