import { getTransfers, ITransfer } from 'api/datahub'
import { IOdataRequest } from 'api/odata.types'
import axios, { CancelTokenSource } from 'axios'
import { IListsFilter } from 'features/Lists/core/contracts/IListsFilter'
import { convertColumnTypeToFilterType } from 'features/OdataList/common/service'
import {
  IOdataListColumnDefinition,
  IWithGetValue
} from 'features/OdataList/common/types'
import { createOdataListWithFacetsStore } from 'features/OdataList/store/odataListWithFacetsStore'
import { keyBy } from 'lodash'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { AppState, getRockefellerApiOptions } from 'store/shared'
import { call, cancelled } from 'typed-redux-saga'

export type TransfersColumnName =
  | 'Account Number'
  | 'CUSIP'
  | 'Description'
  | 'Quantity'
  | 'Settle Date'
  | 'Transaction Type'
  | 'Created Date'
  | 'Transfer ID'
  | 'Transfer Status'
  | 'Legal Entity Name'
  | 'Advisor'
  | 'Advisor ID'
  | 'Is Full Transfer'
  | 'Comments'
  | 'Amount'
  | 'Contra Broker Name'
  | 'Contra Broker Number'
  | 'Asset Class'
  | 'Sponsor Name'
  | 'Transfer Agent Name'
  | 'Detail Updated On'
  | 'Transfer Type'

export interface ITransfersColumnDefinition
  extends IOdataListColumnDefinition,
    IWithGetValue<ITransfer> {
  name: TransfersColumnName
}

const commonColumnProps: Partial<ITransfersColumnDefinition> = {
  filterable: true,
  sortable: true
}

export const transfersColumns: ITransfersColumnDefinition[] = [
  {
    ...commonColumnProps,
    name: 'Account Number',
    dataPath: 'accountNumber',
    type: 'string',
    width: 135,
    getValue: ({ accountNumber }) => accountNumber,
    searchFields: ['accountNumber']
  },
  {
    ...commonColumnProps,
    name: 'Legal Entity Name',
    dataPath: 'legalEntityName',
    type: 'string',
    width: 150,
    getValue: ({ legalEntityName }) => legalEntityName
  },
  {
    ...commonColumnProps,
    name: 'Transfer ID',
    dataPath: 'transferId',
    type: 'number',
    width: 125,
    getValue: ({ transferId }) => transferId
  },
  {
    ...commonColumnProps,
    name: 'Transaction Type',
    dataPath: 'transactionType',
    type: 'string',
    width: 200,
    getValue: ({ transactionType }) => transactionType,
    facetable: true
  },
  {
    ...commonColumnProps,
    name: 'Transfer Status',
    dataPath: 'transferStatus',
    type: 'string',
    width: 150,
    getValue: ({ transferStatus }) => transferStatus,
    facetable: true
  },
  {
    ...commonColumnProps,
    name: 'CUSIP',
    dataPath: 'cusipNumber',
    type: 'string',
    width: 125,
    getValue: ({ cusipNumber }) => cusipNumber,
    searchFields: ['cusipNumber']
  },
  {
    ...commonColumnProps,
    name: 'Description',
    dataPath: 'productName',
    type: 'string',
    width: 300,
    getValue: ({ productName }) => productName,
    searchFields: ['productName']
  },
  {
    ...commonColumnProps,
    name: 'Quantity',
    dataPath: 'quantity',
    type: 'number',
    width: 100,
    getValue: ({ quantity }) => quantity ?? 0
  },
  {
    ...commonColumnProps,
    name: 'Amount',
    type: 'number',
    dataPath: 'amount',
    width: 75,
    getValue: ({ amount }) => amount
  },
  {
    ...commonColumnProps,
    name: 'Created Date',
    dataPath: 'transferDate',
    type: 'date-only',
    width: 135,
    getValue: ({ transferDate }) => transferDate
  },
  {
    ...commonColumnProps,
    name: 'Settle Date',
    dataPath: 'settleDate',
    type: 'date-only',
    width: 135,
    getValue: ({ settleDate }) => settleDate
  },
  {
    ...commonColumnProps,
    name: 'Is Full Transfer',
    dataPath: 'isFullTransfer',
    type: 'boolean',
    width: 135,
    getValue: ({ isFullTransfer }) => isFullTransfer
  },
  {
    ...commonColumnProps,
    name: 'Comments',
    dataPath: 'comments',
    type: 'string',
    width: 250,
    getValue: ({ comments }) => comments
  },
  {
    ...commonColumnProps,
    name: 'Advisor ID',
    dataPath: 'advisorId',
    type: 'string',
    width: 130,
    getValue: ({ advisorId }) => advisorId
  },
  {
    ...commonColumnProps,
    name: 'Advisor',
    dataPath: 'advisorName',
    type: 'string',
    width: 170,
    getValue: ({ advisorName }) => advisorName
  },
  {
    ...commonColumnProps,
    name: 'Contra Broker Name',
    dataPath: 'contraBrokerName',
    type: 'string',
    width: 300,
    getValue: ({ contraBrokerName }) => contraBrokerName
  },
  {
    ...commonColumnProps,
    name: 'Contra Broker Number',
    dataPath: 'contraBrokerNumber',
    type: 'number',
    width: 160,
    getValue: ({ contraBrokerNumber }) => contraBrokerNumber
  },
  {
    ...commonColumnProps,
    name: 'Asset Class',
    dataPath: 'assetClass',
    type: 'string',
    width: 100,
    getValue: ({ assetClass }) => assetClass,
    facetable: true
  },
  {
    ...commonColumnProps,
    name: 'Sponsor Name',
    dataPath: 'sponsorName',
    type: 'string',
    width: 250,
    getValue: ({ sponsorName }) => sponsorName
  },
  {
    ...commonColumnProps,
    name: 'Transfer Agent Name',
    dataPath: 'transferAgentName',
    type: 'string',
    width: 300,
    getValue: ({ transferAgentName }) => transferAgentName
  },
  {
    ...commonColumnProps,
    name: 'Detail Updated On',
    dataPath: 'detailUpdatedOn',
    type: 'string',
    width: 135,
    getValue: ({ detailUpdatedOn }) => detailUpdatedOn
  },
  {
    ...commonColumnProps,
    name: 'Transfer Type',
    dataPath: 'transferType',
    type: 'string',
    width: 130,
    getValue: ({ transferType }) => transferType,
    facetable: true
  }
]

export const defaultColumnsIds: TransfersColumnName[] = transfersColumns.map(
  (x) => x.name
)

const getDatahubApiOptions = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)
  return [apiOptions, source] as [IApiOptions, CancelTokenSource]
}

export const fetchTransfers = function* (request: IOdataRequest) {
  const [apiOptions, cancelTokenSource] = yield* call(getDatahubApiOptions)
  try {
    return yield* call(getTransfers, apiOptions, request)
  } finally {
    if (yield* cancelled()) {
      cancelTokenSource.cancel()
    }
  }
}

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.ai.modules.dashboard.features.aiTransfers

const uiFilters = keyBy(
  transfersColumns
    .filter((x) => x.filterable)
    .map((column): IListsFilter => {
      const base = {
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        dataPath: column.dataPath,
        hasValue: false
      }

      return base
    }),
  ({ id }) => id
)

const selectedColumnIds = [...defaultColumnsIds].splice(0, 22)

const store = createOdataListWithFacetsStore({
  prefix:
    '@modules/@advisory/@modules/@ai/@modules/@dashboard/@features/@aiTransfers',
  getOdataResults: fetchTransfers,
  initialState: {
    data: {},
    ui: {
      columns: transfersColumns,
      selectedColumns: selectedColumnIds,
      filters: uiFilters,
      sortBy: {
        direction: 'desc',
        name: 'Created Date'
      }
    },
    facets: {}
  },
  rootSelector
})

export const { actions, selectors, reducer, sagas } = store
