import { createReducer } from 'typesafe-actions'
import {
  payeesDataActions,
  PayeesActionTypes,
  addEditPayeesDataActions
} from './actions'

import { IPayees, IPayeesRequest } from './types'

export interface IPayeesState {
  PayeesList: {
    payees?: IPayees[]
    isLoading: boolean
    error?: Error
    pkid?: IPayeesRequest
  }
  AddEditVendor?: {
    isLoading: boolean
    error?: Error
    payee?: IPayees
  }
}

const initialState: IPayeesState = {
  PayeesList: {
    isLoading: true
  },
  AddEditVendor: undefined
}

//create reducer for failed payment

export const payeesReducer = createReducer<IPayeesState, PayeesActionTypes>(
  initialState
)
  .handleAction(payeesDataActions.request, (state, action) => ({
    ...state,
    PayeesList: {
      isLoading: true,
      error: undefined,
      pkid: action.payload
    }
  }))
  .handleAction(payeesDataActions.success, (state, action) => ({
    ...state,
    PayeesList: {
      isLoading: false,
      error: undefined,
      payees: action.payload
    }
  }))
  .handleAction(payeesDataActions.failure, (state, action) => ({
    ...state,
    PayeesList: {
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(addEditPayeesDataActions.request, (state) => ({
    ...state,
    AddEditVendor: {
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(addEditPayeesDataActions.success, (state, action) => ({
    ...state,
    AddEditVendor: {
      isLoading: false,
      error: undefined,
      payee: action.payload
    }
  }))
  .handleAction(addEditPayeesDataActions.failure, (state, action) => ({
    ...state,
    AddEditVendor: {
      isLoading: false,
      error: action.payload
    }
  }))
