export interface IRdotStorageSettings {
  addVersionHeaderForRockcoApis?: string | boolean
}

const storageSettingsKey = 'rdot-storage-settings'

let currentSettings: IRdotStorageSettings | undefined

export const getStorageSettings = (): IRdotStorageSettings => {
  return (
    currentSettings ||
    (JSON.parse(
      localStorage.getItem(storageSettingsKey) || '{}'
    ) as IRdotStorageSettings)
  )
}

export const setStorageSettings = (settings: Partial<IRdotStorageSettings>) => {
  currentSettings = { ...currentSettings, ...settings }
  localStorage.setItem(storageSettingsKey, JSON.stringify(currentSettings))
}

currentSettings = getStorageSettings()
