import { lazy } from 'react'
import { RdotUserRoleEnum } from 'store/user/rdotUser'
import { IModuleDefinition } from '../../../types'

const RevenuePostSplitContainer = lazy(
  () => import('./RevenuePostSplitContainer')
)

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/revenuePostSplit/*',
    private: true,
    component: RevenuePostSplitContainer
  },
  name: 'Revenue Post Split',
  roles: [RdotUserRoleEnum.Advisory_Feature_PostSplitRevenue]
}

export default moduleDefinition

export * from './shared/store/revenuePostSplit'
