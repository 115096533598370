import { ColumnDef } from '@tanstack/react-table'
import { format } from 'date-fns'
import { IPerformanceReport } from 'modules/Advisory/modules/Rdot360/store/types'
import { HighlightSearchText } from '../../../../components/shared/DetailTables/HighlightSearchText'
import { SharedIndicator } from './SharedIndicator'
import { ShowDetails } from './ShowDetails'

export const colNames = {
  reportName: 'Report Name',
  reportDate: 'Report Created Date',
  accountNumber: 'Account Number(s)',
  clientName: 'Client Name(s)',
  shareReport: 'Report Shared',
  portfolioName: 'Portfolio Name',
  action: 'Action'
}

export const getColumnDefs = (
  searchText: string
): ColumnDef<IPerformanceReport>[] => [
  {
    header: colNames.reportName,
    accessorFn: ({ reportName }) => reportName,
    cell: (props) => (
      <HighlightSearchText
        text={props.getValue() as string}
        search={searchText}
      />
    )
  },
  {
    header: colNames.reportDate,
    accessorFn: ({ reportDate }) =>
      format(new Date(reportDate || ''), 'MM-dd-yyyy'),
    sortingFn: 'datetime',
    cell: (props) => (
      <HighlightSearchText
        text={props.getValue() as string}
        search={searchText}
      />
    )
  },
  {
    header: colNames.accountNumber,
    accessorFn: ({ accountList }) =>
      accountList?.map((account) => account.accountNumber),
    enableSorting: false,
    cell: ({ getValue, row }) => {
      if (row.getIsGrouped()) {
        return null
      }
      const accounts = getValue<string[]>()
      return <ShowDetails value={accounts} masked />
    }
  },
  {
    header: colNames.clientName,
    accessorFn: ({ clientName }) => clientName,
    enableSorting: false,
    cell: ({ getValue, row }) => {
      if (row.getIsGrouped()) {
        return null
      }
      const clients = getValue<string[]>()
      return <ShowDetails value={[...clients].sort()} />
    }
  },
  {
    header: colNames.shareReport,
    accessorFn: ({ sharedIndicator }) => sharedIndicator,
    cell: ({ getValue, row }) => {
      const fileId = row.original.fileId
      if (!fileId) {
        return '--'
      }
      const sharedIndicator = getValue<string>() || 'No'
      return (
        <SharedIndicator fileId={fileId} sharedIndicator={sharedIndicator} />
      )
    }
  },
  {
    header: colNames.portfolioName,
    accessorFn: ({ portfolioName }) => portfolioName
  }
]
