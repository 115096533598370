import { PrintFooter } from '../../../../shared/PrintFooter'

interface IPrintTableFooter {
  colspan: number
}

export const PrintTableFooter: React.FC<IPrintTableFooter> = ({ colspan }) => {
  return (
    <tfoot>
      <tr className="footer-table">
        <td colSpan={colspan}>
          <PrintFooter />
        </td>
      </tr>
    </tfoot>
  )
}
