import { css } from '@emotion/react'
import { useBoolean, useId } from '@fluentui/react-hooks'
import { FC } from 'react'
import { SharedCallout } from './SharedCallout'

const getClasses = () => {
  return {
    calloutLabel: css({
      cursor: 'pointer',
      color: 'rgb(0, 120, 212)'
    })
  }
}

interface IShareReportCalloutProps {
  fileId: string
  sharedIndicator: string
}

export const SharedIndicator: FC<IShareReportCalloutProps> = ({
  fileId,
  sharedIndicator
}) => {
  const classes = getClasses()
  const [isCalloutVisible, { setTrue: showCallout, setFalse: hideCallout }] =
    useBoolean(false)

  const buttonId = useId(`callout-button-${fileId}`)

  return (
    <>
      <span onClick={showCallout} id={buttonId} css={classes.calloutLabel}>
        {sharedIndicator}
      </span>
      {isCalloutVisible && (
        <SharedCallout
          fileId={fileId}
          buttonId={buttonId}
          hideCallout={hideCallout}
        />
      )}
    </>
  )
}
