import {
  call,
  put,
  select,
  takeEvery
  // tslint:disable-next-line: no-submodule-imports
} from 'typed-redux-saga'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import { IEnvironmentApiConfiguration } from '../../../../../shared/services/environment/IEnvironmentConfiguration'
import { tryAcquireAccessToken } from '../../../../../store/shared/sagas'
import { getRockefellerApiConfig } from '../../../../../store/system'
import {
  fetchAnticipatedBalance,
  fetchAnticipatedPIBalance
} from '../api/anticipatedBalanceServices'
import {
  anticipatedBalanceDetailsActionTypes,
  AnticipatedBalanceDataActionTypes,
  anticipatedBalancePIDataActionTypes,
  AnticipatedBalancePIDataActionTypes
} from './action'
import {
  anticipatedBalancePiDetailsRequest,
  anticipatedBalanceRequest,
  IAnticipatedBalance,
  PIDetails
} from './types'

/**
 * Generator function saga to fetch matrix info and call api
 */

function* handleAnticipatedBalanceDetails(
  action: AnticipatedBalanceDataActionTypes
) {
  // Call fetch anticipated balance api here....

  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token: string = yield call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const request = action.payload as anticipatedBalanceRequest
    const data: IAnticipatedBalance = yield call(() =>
      fetchAnticipatedBalance(request, options)
    )
    yield put(anticipatedBalanceDetailsActionTypes.success(data))
  } catch (e: any) {
    yield put(anticipatedBalanceDetailsActionTypes.failure(e))
  }
}
function* handleAnticipatedBalancePIDetails(
  action: AnticipatedBalancePIDataActionTypes
) {
  // Call fetch anticipated balance api here....

  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token: string = yield call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const request = action.payload as anticipatedBalancePiDetailsRequest
    const data: PIDetails = yield call(() =>
      fetchAnticipatedPIBalance(request, options)
    )
    yield put(anticipatedBalancePIDataActionTypes.success(data))
  } catch (e: any) {
    yield put(anticipatedBalancePIDataActionTypes.failure(e))
  }
}
export const sagas = [
  () =>
    takeEvery(
      anticipatedBalanceDetailsActionTypes.request,
      handleAnticipatedBalanceDetails
    ),
  () =>
    takeEvery(
      anticipatedBalancePIDataActionTypes.request,
      handleAnticipatedBalancePIDetails
    )
]
