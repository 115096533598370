import { call, put, takeEvery } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../store/shared/sagas'
import {
  createScheduleService,
  fetchScheduleReportListService,
  GetRelatedLoginsByPartyIdService
} from '../api/scheduleReportService'
import {
  getScheduleListActions,
  relatedPartiesDetailsActionTypes,
  createUpdateScheduleActionTypes
} from './actions'

function* handleGetScheduleList(
  action: ReturnType<typeof getScheduleListActions.request>
) {
  // Call fetch schedule list api here....

  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchScheduleReportListService(options, action.payload)
    )
    yield put(getScheduleListActions.success(data))
  } catch (e: any) {
    yield put(getScheduleListActions.failure(e))
  }
}

function* handleGetRelatedPartiesDetails(
  action: ReturnType<typeof relatedPartiesDetailsActionTypes.request>
) {
  // Call fetch related logins api here....
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      GetRelatedLoginsByPartyIdService(options, action.payload)
    )
    if (data && data.length > 0) {
      yield put(relatedPartiesDetailsActionTypes.success(data))
    } else {
      yield put(
        relatedPartiesDetailsActionTypes.failure(
          new Error('No logins are associated with this client')
        )
      )
    }
  } catch (e) {
    yield put(
      relatedPartiesDetailsActionTypes.failure(
        new Error(
          'Unable to fetch logins associated with this client. Please try again later.'
        )
      )
    )
  }
}

function* handleSaveSchedule(
  action: ReturnType<typeof createUpdateScheduleActionTypes.request>
) {
  // Call fetch Save schedule api here....

  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      createScheduleService(options, action.payload)
    )
    yield put(createUpdateScheduleActionTypes.success(data))
  } catch (e: any) {
    yield put(createUpdateScheduleActionTypes.failure(e))
  }
}

export const sagas = [
  () => takeEvery(getScheduleListActions.request, handleGetScheduleList),
  () =>
    takeEvery(
      relatedPartiesDetailsActionTypes.request,
      handleGetRelatedPartiesDetails
    ),
  () => takeEvery(createUpdateScheduleActionTypes.request, handleSaveSchedule)
]
