import { Row, flexRender } from '@tanstack/react-table'
import { ITableHeaderColumnSize } from 'modules/Advisory/modules/Rdot360/shared/ITableHeaderColumnSize'
import { rdot360TableStyles } from 'modules/Advisory/modules/Rdot360/shared/tableStyles'
import { FC, useCallback } from 'react'
import { incomeAccountsColumnNames } from './ColumnNames'

const TableHeaderSizesRow: React.FC<{
  sizes: ITableHeaderColumnSize[]
}> = ({ sizes }) => {
  return (
    <tr css={[rdot360TableStyles.sizesRow]}>
      {sizes.map(({ width, id }) => {
        return (
          <th
            key={id}
            css={{
              width,
              maxWidth: width
            }}
          />
        )
      })}
    </tr>
  )
}

export const DetailsRow: FC<{
  row: Row<any>
  rowIndex: number
  sizes: ITableHeaderColumnSize[]
}> = ({ row, rowIndex, sizes }) => {
  const hasSubRows = useCallback((row: any) => !!row.subRows, [])
  const isTotalRow = useCallback((value: any) => value === 'Total', [])
  const expanded = row.getIsExpanded()

  const getBackgroundColor = (row: Row<any>) => {
    const color = hasSubRows(row) ? '#ebf6fc' : '#ffffff'

    return isTotalRow(row.getVisibleCells()[0].getValue()) ? '#e3edf5' : color
  }

  const getHeight = (row: Row<any>) => {
    return isTotalRow(row.getVisibleCells()[0].getValue()) ? '50px' : '60px'
  }

  const geFontWeight = (row: Row<any>) => {
    return isTotalRow(row.getVisibleCells()[0].getValue()) || hasSubRows(row)
      ? 600
      : 500
  }

  return (
    <table
      css={{
        border: isTotalRow(row.getVisibleCells()[0].getValue())
          ? '1px solid #E6E6E6'
          : 'solid 1px #dddddd',
        borderCollapse: 'collapse',
        tableLayout: 'fixed',
        width: '100%'
      }}
    >
      <thead>
        <TableHeaderSizesRow sizes={sizes} />
        <tr>
          {row.getVisibleCells().map((cell: any, colIndex: number) => {
            const isDescription = cell.id.includes('description')

            return (
              <td
                key={cell.id}
                css={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: 14,
                  textAlign: colIndex > 2 ? 'right' : 'left',
                  fontWeight: geFontWeight(row),
                  height: getHeight(row),
                  backgroundColor: getBackgroundColor(row),
                  cursor:
                    hasSubRows(row) && colIndex === 0 ? 'pointer' : 'default',
                  padding: hasSubRows(row)
                    ? '8px 10px 8px 8px'
                    : '8px 10px 8px 20px',
                  '&:last-of-type': {
                    paddingRight: '20px'
                  }
                }}
                colSpan={isDescription ? 2 : 1}
                title={cell.getValue() as string}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {expanded &&
          hasSubRows(row) &&
          row.subRows.map((subRow: any, subIndex: number) => (
            <tr
              key={`${rowIndex}${subIndex}`}
              css={{
                border: 'solid 1px #dddddd',
                height: 40,
                background: subIndex % 2 === 0 ? '#ffffff' : '#FAFAFA'
              }}
            >
              {subRow.getVisibleCells().map((subCell: any, index: number) =>
                subCell.id.includes(incomeAccountsColumnNames.accounts) ? (
                  <td key={subCell.id}>{''}</td>
                ) : (
                  <td
                    key={subCell.id}
                    className={subCell.id}
                    css={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      padding: '8px 10px',
                      '&:last-of-type': {
                        paddingRight: '20px'
                      },
                      fontSize: 14
                    }}
                    align={index <= 2 ? 'left' : 'right'}
                    valign={'top'}
                    title={subCell.getValue()}
                  >
                    {flexRender(
                      subCell.column.columnDef.cell,
                      subCell.getContext()
                    )}
                  </td>
                )
              )}
            </tr>
          ))}
      </tbody>
    </table>
  )
}
