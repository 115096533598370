import { createReducer } from 'typesafe-actions'
import {
  AnticipatedBalanceActionType,
  anticipatedBalanceDetailsActionTypes,
  anticipatedBalancePIDataActionTypes
} from './action'
import { IAnticipatedBalance, PIDetails } from './types'

export interface IAnticipatedBalanceState {
  anticipatedBalanceDetails: IAnticipatedBalance
  isAnticipatedBalanceLoading: boolean
  error?: Error
  PIDetails?: {
    loading: boolean
    error?: Error
    PIData?: PIDetails
  }
}

const initialState: IAnticipatedBalanceState = {
  anticipatedBalanceDetails: {
    accounts: '',
    availableCash: '',
    anticipatedCash: '',
    upcomingPayments: '',
    paymentDetails: []
  },
  isAnticipatedBalanceLoading: true,
  error: undefined,
  PIDetails: undefined
}

export const anticipatedBalanceReducer = createReducer<
  IAnticipatedBalanceState,
  AnticipatedBalanceActionType
>(initialState)
  .handleAction(anticipatedBalanceDetailsActionTypes.request, (state) => ({
    ...state,
    isAnticipatedBalanceLoading: true,
    error: undefined,
    anticipatedBalanceDetails: {
      accounts: '',
      availableCash: '',
      anticipatedCash: '',
      upcomingPayments: '',
      paymentDetails: []
    }
  }))
  .handleAction(
    anticipatedBalanceDetailsActionTypes.success,
    (state, action) => ({
      ...state,
      anticipatedBalanceDetails: action.payload,
      isAnticipatedBalanceLoading: false,
      error: undefined
    })
  )
  .handleAction(
    anticipatedBalanceDetailsActionTypes.failure,
    (state, action) => ({
      ...state,
      error: action.payload,
      isAnticipatedBalanceLoading: false,
      anticipatedBalanceDetails: {
        accounts: '',
        availableCash: '',
        anticipatedCash: '',
        upcomingPayments: '',
        paymentDetails: []
      }
    })
  )
  .handleAction(anticipatedBalancePIDataActionTypes.request, (state) => ({
    ...state,
    isAnticipatedBalanceLoaded: true,
    error: undefined,
    PIDetails: {
      loading: true,
      error: undefined
    }
  }))
  .handleAction(
    anticipatedBalancePIDataActionTypes.success,
    (state, action) => ({
      ...state,
      isAnticipatedBalanceLoaded: true,
      error: undefined,
      PIDetails: {
        loading: false,
        error: undefined,
        PIData: action.payload
      }
    })
  )
  .handleAction(
    anticipatedBalancePIDataActionTypes.failure,
    (state, action) => ({
      ...state,
      error: action.payload,
      isAnticipatedBalanceLoaded: true,
      PIDetails: {
        loading: false,
        error: action.payload
      }
    })
  )
