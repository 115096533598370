import { CellContext } from '@tanstack/react-table'
import { CDMv2Account } from 'api/nickname.types'
import { InputTableCell } from './InputTableCell'
import { useManageAccountNicknamesStore } from './store'

export const AdvisorNicknameCell: React.FC<{
  context: CellContext<CDMv2Account, unknown>
}> = ({ context }) => {
  const { setUpdatedNickname, advisorNicknames } =
    useManageAccountNicknamesStore()

  return (
    <InputTableCell
      context={context}
      setUpdatedNickname={(a, n) => setUpdatedNickname(a, n, 'advisor')}
      updatedNicknames={advisorNicknames}
    />
  )
}
