import md5 from 'crypto-js/md5'
import { differenceBy, flow, keyBy, uniq, uniqBy } from 'lodash'
import { put } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { select, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { IAccount } from '../../../api/account.types'
import { INfsProfile, INfsProfileJson } from '../../../api/datahub'
import { AccountLinkingValidationRequestType } from '../../../api/dynamics'
import {
  isNotNullOrEmpty,
  isNotNullOrFalse,
  isNotNullOrUndefined
} from '../../../shared/guards'
import { AppState } from '../../../store'
import {
  accountLinkingClientHouseholdFetchActions,
  getAccountLinkingClientHouseholdFetchResult
} from './clientHouseholdFetch'
import { getPrimaryEmailWarning } from './emailValitdation'
import {
  accountLinkingValidationAccountsFetchActions,
  getAccountLinkingValidationAccountsFetchResult
} from './validationAccountsFetch'

const SET_REQUEST_TYPE =
  '@features/@accountLinking/@accountLinkingValidation/SET_REQUEST_TYPE'
const SET_INPUT_ACCOUNTS =
  '@features/@accountLinking/@accountLinkingValidation/SET_INPUT_ACCOUNTS'
const SET_VALIDATED_ACCOUNTS =
  '@features/@accountLinking/@accountLinkingValidation/SET_VALIDATED_ACCOUNTS'
const SET_SELECTED_CLIENTS =
  '@features/@accountLinking/@accountLinkingValidation/SET_SELECTED_CLIENTS'
const SET_VERIFICATION =
  '@features/@accountLinking/@accountLinkingValidation/SET_VERIFICATION'
const RESET = '@features/@accountLinking/@accountLinkingValidation/RESET'

export const accountLinkingValidationActions = {
  setRequestType:
    createAction(SET_REQUEST_TYPE)<AccountLinkingValidationRequestType>(),
  setInputAccounts: createAction(SET_INPUT_ACCOUNTS)<string[] | undefined>(),
  setValidatedAccounts: createAction(SET_VALIDATED_ACCOUNTS)<
    string[] | undefined
  >(),
  setSelectedClients: createAction(SET_SELECTED_CLIENTS)<
    INfsProfile[] | undefined
  >(),
  setVerification:
    createAction(SET_VERIFICATION)<IAccountLinkingVerification>(),
  reset: createAction(RESET)()
}

export type AccountLinkingValidationActionTypes = ActionType<
  typeof accountLinkingValidationActions
>
export interface IAccountLinkingVerification {
  date?: Date
  time?: string
  client?: string
  clientFirstName?: string
  clientLastName?: string
  method?: string
}

export interface IAccountLinkingValidationState {
  requestType?: AccountLinkingValidationRequestType
  inputAccounts?: string[]
  validatedAccounts?: string[]
  selectedClients?: INfsProfile[]
  verification?: IAccountLinkingVerification
}

const initialState: IAccountLinkingValidationState = {
  requestType: 'existing'
}

export const accountLinkingValidationReducer = createReducer<
  IAccountLinkingValidationState,
  AccountLinkingValidationActionTypes
>(initialState)
  .handleAction(
    accountLinkingValidationActions.setValidatedAccounts,
    (state, action) => ({
      ...state,
      validatedAccounts: action.payload
    })
  )
  .handleAction(
    accountLinkingValidationActions.setSelectedClients,
    (state, action) => ({
      ...state,
      selectedClients: action.payload
    })
  )
  .handleAction(
    accountLinkingValidationActions.setInputAccounts,
    (state, action) => ({
      ...state,
      inputAccounts: action.payload
    })
  )
  .handleAction(
    accountLinkingValidationActions.setRequestType,
    (state, action) => ({
      ...state,
      requestType: action.payload
    })
  )
  .handleAction(
    accountLinkingValidationActions.setVerification,
    (state, action) => ({
      ...state,
      verification: action.payload
    })
  )
  .handleAction(accountLinkingValidationActions.reset, () => ({
    ...initialState
  }))

const rootSelector = (state: AppState) =>
  state.features.accountLinking.accountLinkingValidation

export const getAccountLinkingValidationValidatedAccounts = flow(
  rootSelector,
  ({ validatedAccounts }) => validatedAccounts
)

export const getAccountLinkingValidationValidatedAccountDetails =
  createSelector(
    [
      getAccountLinkingValidationValidatedAccounts,
      getAccountLinkingValidationAccountsFetchResult
    ],
    (accountNumbers, accounts) => {
      const accountsLookup = keyBy(accounts, ({ id = '' }) => id)
      return accountNumbers
        ?.map((number) => accountsLookup[number])
        .filter(isNotNullOrUndefined)
    }
  )

export const getAccountLinkingValidationSelectedClients = flow(
  rootSelector,
  ({ selectedClients }) => selectedClients
)

export const getAccountLinkingValidationInputAccounts = flow(
  rootSelector,
  ({ inputAccounts }) => inputAccounts
)

export const getAccountLinkingValidationRequestType = flow(
  rootSelector,
  ({ requestType }) => requestType
)

export const getAccountLinkingValidationVerification = flow(
  rootSelector,
  ({ verification }) => verification
)

export const getIsRockcoEmail = flow(rootSelector, ({ selectedClients }) =>
  selectedClients?.[0]?.loginid?.trim().toLowerCase()?.endsWith('rockco.com')
)

export const getAccountLinkingValidationValidatedAccountsChecksum =
  createSelector([getAccountLinkingValidationValidatedAccounts], (accounts) => {
    if (!accounts || !accounts.length) {
      return
    }
    const sorted = [...accounts].sort()

    return md5(sorted.join('')).toString()
  })

export const getAccountLinkingValidationClientHouseholds = createSelector(
  [getAccountLinkingClientHouseholdFetchResult],
  (households) => households
)

export const getAccountLinkingValidations = createSelector(
  [
    getAccountLinkingValidationSelectedClients,
    getAccountLinkingClientHouseholdFetchResult,
    getAccountLinkingValidationValidatedAccountDetails,
    getPrimaryEmailWarning
  ],
  (clients, households, validatedAccounts, primaryEmailWarning) => {
    const validatedAccountsUniqByHousehold = uniqBy(
      validatedAccounts,
      ({ householdId }) => householdId
    )

    const validatedAccountsUniqByAdvisor = uniqBy(
      validatedAccounts,
      ({ ClientAdvisorID }) => ClientAdvisorID
    )

    const selectedClientsUniqByAdvisor = uniqBy(
      clients,
      ({ repcode }) => repcode
    )

    const validatedAccountsDifferenceBySelectedClientReps = differenceBy(
      validatedAccounts,
      selectedClientsUniqByAdvisor,
      (item) =>
        (item as IAccount)?.ClientAdvisorID || (item as INfsProfile)?.repcode
    )

    const validatedAccountsDifferenceBySelectedClientHouseholds = differenceBy(
      validatedAccounts,
      households || [],
      (item) => item.householdId
    )

    const validations = [
      validatedAccountsUniqByHousehold.length > 1 &&
        `Validated accounts contain more than 1 household: ${validatedAccountsUniqByHousehold
          .map(({ householdName }) => householdName)
          .join(', ')}`,
      validatedAccountsUniqByAdvisor.length > 1 &&
        `Validated accounts contain more than 1 rep: ${validatedAccountsUniqByAdvisor
          .map(
            ({ ClientAdvisorID, ClientAdvisor }) =>
              `${ClientAdvisorID} - ${ClientAdvisor}`
          )
          .join(', ')}`,
      !!clients?.length &&
        clients.some(({ repcode }) => repcode) &&
        !!validatedAccountsDifferenceBySelectedClientReps?.length &&
        `Validated accounts belong to reps outside of the requested client(s) to link accounts to: ${uniqBy(
          validatedAccountsDifferenceBySelectedClientReps,
          ({ ClientAdvisorID }) => ClientAdvisorID
        )
          .map(
            ({ ClientAdvisorID, ClientAdvisor }) =>
              `${ClientAdvisorID} - ${ClientAdvisor}`
          )
          .join(', ')}`,
      !!clients?.length &&
        !!validatedAccountsDifferenceBySelectedClientHouseholds?.length &&
        !!households?.length &&
        `Validated accounts belong to households outside of the requested client(s) to link accounts to: ${uniqBy(
          validatedAccountsDifferenceBySelectedClientHouseholds,
          ({ householdId }) => householdId
        )
          .map(({ householdName }) => householdName)
          .join(', ')}`,
      primaryEmailWarning &&
        'Email address is found on an Account Holder or previous 3rd Party request.'
    ].filter(isNotNullOrFalse)

    return validations
  }
)

export const getAccountLinkingValidationSubject = createSelector(
  [
    getAccountLinkingValidations,
    getAccountLinkingValidationRequestType,
    getAccountLinkingValidationSelectedClients
  ],
  (validations, requestType, selectedClients) =>
    [
      'Account Linking Request',
      !!validations?.length && 'NON-STANDARD',
      `${
        requestType === 'existing'
          ? 'Adding accounts to'
          : requestType === 'delink'
          ? 'Delink Accounts From'
          : 'Create new 3rd Party for'
      }: ${
        selectedClients?.map(({ fullname }) => fullname || '').join(', ') || ''
      }`
    ]
      .filter(isNotNullOrFalse)
      .join(' | ')
)

export const getAccountLinkingLinkedAccounts = createSelector(
  [getAccountLinkingValidationSelectedClients],
  (clients) =>
    uniq(
      clients
        ?.map(({ profilejson }) => {
          if (!profilejson) {
            return
          }
          try {
            return JSON.parse(profilejson) as INfsProfileJson
          } catch (e) {
            return undefined
          }
        })
        .filter(isNotNullOrUndefined)
        .flatMap((profile) => profile.profile?.accounts)
        .filter(isNotNullOrUndefined)
        ?.flatMap(({ number }) => number)
        .filter(isNotNullOrEmpty)
    )
)

export const accountLinkingValidationSagas = [
  () =>
    takeLatest(
      accountLinkingValidationActions.setInputAccounts,
      function* (
        action: ReturnType<
          typeof accountLinkingValidationActions.setInputAccounts
        >
      ) {
        yield put(
          accountLinkingValidationAccountsFetchActions.request(action.payload)
        )
      }
    ),
  () =>
    takeLatest(
      accountLinkingValidationActions.setSelectedClients,
      function* () {
        const accounts = yield* select(getAccountLinkingLinkedAccounts)
        yield put(
          accountLinkingClientHouseholdFetchActions.request(uniq(accounts))
        )
      }
    )
]
