import {
  DirectionalHint,
  HoverCard,
  HoverCardType,
  IPlainCardProps,
  Link
} from '@fluentui/react'
import React, { useMemo } from 'react'
import { USDCell } from './USDCell'

export const PlanTotalInfoHoverCard: React.FC<{
  item?: any
  onplanTotalClick?: (item: any) => void
}> = ({ item, onplanTotalClick }) => {
  const plainCardProps = useMemo(
    (): IPlainCardProps => ({
      onRenderPlainCard: () => (
        <div
          style={{
            padding: '15px',
            maxWidth: '300px',
            border: '1px solid #B49D77'
          }}
        >
          <table className="planTotal">
            <tbody>
              <tr
                style={{
                  color: '#3a3a3a',
                  gap: 10,
                  backgroundColor: '#F3F1EC'
                }}
              >
                <td style={{ textAlign: 'left', padding: '10px 5px' }}>
                  Cash Received
                </td>
                <td
                  style={{
                    textAlign: 'right',
                    padding: '10px 5px',
                    color: '#00C900'
                  }}
                >
                  {' '}
                  <USDCell value={item?.cash} />
                </td>
              </tr>
              <tr style={{ color: '#3a3a3a', gap: 10 }}>
                <td style={{ textAlign: 'left', padding: '10px 5px' }}>
                  Estimated Payment
                </td>
                <td style={{ textAlign: 'right', padding: '10px 5px' }}>
                  <USDCell value={item?.accrual} />
                </td>
              </tr>
              <tr
                style={{
                  color: '#3a3a3a',
                  gap: 10,
                  backgroundColor: '#B49D77'
                }}
              >
                <td style={{ textAlign: 'left', padding: '10px 5px' }}>
                  <strong>Plan Total</strong>
                </td>
                <td style={{ textAlign: 'right', padding: '10px 5px' }}>
                  <strong>
                    <USDCell value={item?.planTotal} />
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ),
      directionalHint: DirectionalHint.bottomRightEdge,
      calloutProps: {
        isBeakVisible: true
      }
    }),
    [item]
  )
  return (
    <HoverCard
      plainCardProps={plainCardProps}
      instantOpenOnClick={true}
      type={HoverCardType.plain}
    >
      <Link
        style={{ color: '#3A3A3A' }}
        underline={true}
        onClick={() => onplanTotalClick && onplanTotalClick(item)}
      >
        <USDCell value={item?.planTotal} />
      </Link>
    </HoverCard>
  )
}
