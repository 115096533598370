import { takeLatest } from '@redux-saga/core/effects'
import { push } from 'connected-react-router'
import { getLocation } from 'shared/services/dom'
import { call, put, takeEvery } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../../../store/shared/sagas'
import {
  fetchPendingManagementApprovalReviewCountSvc,
  fetchTrustDashboardInfoListSvc,
  fetchTrustOfficerReviewCountSvc
} from '../api/dashboardService'
import {
  pendingMgmtReviewCountActions,
  trustDashboardInfoDataActions,
  trustDashboardNavigationActions,
  trustOfficerReviewCountActions
} from './actions'

function* handleGetTrustDashboardList() {
  try {
    const apiOptions = yield* call(getRockefellerApiOptions)
    const data = yield* call(() => fetchTrustDashboardInfoListSvc(apiOptions))
    yield put(trustDashboardInfoDataActions.success(data || []))
  } catch (e) {
    yield put(trustDashboardInfoDataActions.failure(e as Error))
  }
}

function* handleNavigateToTrustInfo() {
  const { pathname } = yield* call(getLocation)
  const path = pathname.replace('/dashboard', '/trustinfo')
  yield put(push({ pathname: path }))
}
function* handleNavigateToTrustees() {
  const { pathname } = yield* call(getLocation)
  const path = pathname.replace('/dashboard', '/trustees')
  yield put(push({ pathname: path }))
}

function* handleNavigateToGrantors() {
  const { pathname } = yield* call(getLocation)
  const path = pathname.replace('/dashboard', '/grantors')
  yield put(push({ pathname: path }))
}

function* handleNavigateToAccounts() {
  const { pathname } = yield* call(getLocation)
  const path = pathname.replace('/dashboard', '/accounts')
  yield put(push({ pathname: path }))
}

function* handleNavigateToBeneficiaries() {
  const { pathname } = yield* call(getLocation)
  const path = pathname.replace('/dashboard', '/Beneficiaries')
  yield put(push({ pathname: path }))
}

function* handleGetPendingMgmtReviewCount() {
  try {
    const apiOptions = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchPendingManagementApprovalReviewCountSvc(apiOptions)
    )
    yield put(pendingMgmtReviewCountActions.success(data?.[0] || undefined))
  } catch (e) {
    yield put(pendingMgmtReviewCountActions.failure(e))
  }
}

function* handleGetTrustOfficerReviewCount() {
  try {
    const apiOptions = yield* call(getRockefellerApiOptions)
    const data = yield* call(() => fetchTrustOfficerReviewCountSvc(apiOptions))
    yield put(trustOfficerReviewCountActions.success(data?.[0] || undefined))
  } catch (e) {
    yield put(trustOfficerReviewCountActions.failure(e))
  }
}

export const sagas = [
  () =>
    takeLatest(
      trustDashboardInfoDataActions.request,
      handleGetTrustDashboardList
    ),
  () =>
    takeEvery(
      trustDashboardNavigationActions.navigateToTrustInfo,
      handleNavigateToTrustInfo
    ),
  () =>
    takeEvery(
      trustDashboardNavigationActions.navigateToTrustees,
      handleNavigateToTrustees
    ),
  () =>
    takeEvery(
      trustDashboardNavigationActions.naviagteToGrantors,
      handleNavigateToGrantors
    ),
  () =>
    takeEvery(
      trustDashboardNavigationActions.naviagteToAccounts,
      handleNavigateToAccounts
    ),
  () =>
    takeEvery(
      trustDashboardNavigationActions.naviagteToBeneficiaries,
      handleNavigateToBeneficiaries
    ),
  () =>
    takeLatest(
      pendingMgmtReviewCountActions.request,
      handleGetPendingMgmtReviewCount
    ),
  () =>
    takeLatest(
      trustOfficerReviewCountActions.request,
      handleGetTrustOfficerReviewCount
    )
]
