import { ChoiceGroup, IChoiceGroupOption, IColumn } from '@fluentui/react'
import React, { useCallback, useMemo } from 'react'
import { IAccount } from '../../../../api/account.types'
import { DataTable } from '../../../../shared/components/DataTable'
import { TextCell } from '../../../Lists/core/components/TextCell'
import { USDCell } from '../../../Lists/core/components/USDCell'
import { AccountNumberCell } from '../../../Lists/shared/AccountNumberCell'

const defaultColumn: IColumn = {
  key: '',
  name: '',
  isResizable: true,
  minWidth: 80,
  maxWidth: 130
}

export const HouseholdFeeAccountList: React.FC<{
  accounts?: IAccount[]
  selectedAccount?: string
  onSelectionChanged?: (account: string) => void
  loading?: boolean
}> = ({
  accounts = [],
  loading = false,
  selectedAccount,
  onSelectionChanged
}) => {
  const onAccountChanged = useCallback(
    (ev?: any, option?: IChoiceGroupOption) => {
      if (!option) {
        return
      }

      onSelectionChanged?.(option.key)
    },
    [onSelectionChanged]
  )
  const columns: IColumn[] = useMemo(
    () => [
      {
        ...defaultColumn,
        name: '',
        key: 'choice',
        isResizable: false,
        minWidth: 15,
        maxWidth: 15,
        onRender: (account: IAccount) => (
          <ChoiceGroup
            styles={{ root: { margin: 0, '.ms-ChoiceField': { margin: 0 } } }}
            selectedKey={selectedAccount}
            options={[{ key: account.id || '', text: '' }]}
            onChange={onAccountChanged}
          />
        )
      },
      {
        ...defaultColumn,
        name: 'Account',
        key: 'account',
        maxWidth: 120,
        onRender: (account: IAccount) => (
          <AccountNumberCell account={account} menu={null} />
        )
      },
      {
        ...defaultColumn,
        key: 'name',
        name: 'Legal Entity Name',
        maxWidth: 130,
        onRender: ({ LegalEntityName }: IAccount) => (
          <TextCell>{LegalEntityName}</TextCell>
        )
      },
      {
        ...defaultColumn,
        key: 'cash',
        maxWidth: 130,
        className: 'align-right',
        headerClassName: 'align-right',
        name: 'Cash Available to Withdraw',
        onRender: ({ AccountKPIs }: IAccount) => (
          <USDCell value={AccountKPIs?.cashAvlToWithDraw} />
        )
      }
    ],
    [onAccountChanged, selectedAccount]
  )
  return (
    <DataTable
      columns={columns}
      items={accounts}
      enableShimmer={loading}
      shimmerLines={5}
    />
  )
}
