import { css, useTheme } from '@emotion/react'
import { Callout, PrimaryButton, Stack, Toggle } from '@fluentui/react'
import { useState } from 'react'
import { Icon } from '../features/Icons/Icon'

const printDropdownClasses = () => ({
  heading: css({
    backgroundColor: '#F9FBFC',
    padding: '10px 0 10px 12px',
    borderBottom: '1px solid #EBEBEB',
    fontWeight: 500,
    fontSize: 16
  }),
  body: css({
    padding: '0 10px 10px 0'
  }),
  row: css({
    padding: 15,
    width: 300
  }),
  label: css({
    paddingBottom: 15,
    fontSize: 14
  }),
  option: css({
    fontSize: 16
  }),
  selected: css({
    color: '#1D679D',
    fontWeight: 'bold'
  }),
  unselected: css({
    color: '#AFB1B6'
  }),
  instructions: css({
    fontSize: 12,
    paddingTop: 5
  }),
  dark: css({
    color: '#61646B'
  }),
  light: css({
    color: '#CCC'
  }),
  toggle: css({
    padding: '0 5px',
    '& .ms-Toggle-thumb': css({
      backgroundColor: '#3C6F80 !important'
    }),
    '& .ms-Toggle-background': css({
      backgroundColor: '#ADD2E4 !important'
    })
  }),
  right: css({
    display: 'flex',
    justifyContent: 'flex-end'
  }),
  button: css({
    borderRadius: 8,
    backgroundColor: 'black',
    border: '1px solid black',
    padding: 20,
    fontSize: 14,
    '&:hover': css({
      backgroundColor: 'black',
      border: '1px solid black'
    })
  })
})

interface IPrintOptionProps {
  label: string
  stateVal: boolean
  setStateVal: React.Dispatch<React.SetStateAction<boolean>>
  option1: string
  option2: string
  disableInstructions: boolean
  instructions: string
}

const PrintOption: React.FC<IPrintOptionProps> = ({
  label,
  stateVal,
  setStateVal,
  option1,
  option2,
  disableInstructions,
  instructions
}) => {
  const classes = printDropdownClasses()
  return (
    <div css={classes.row}>
      <div css={classes.label}>
        <strong>{label}</strong>
      </div>
      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <span
          css={[
            stateVal ? classes.selected : classes.unselected,
            classes.option
          ]}
        >
          {option1}
        </span>
        <Toggle
          checked={!stateVal}
          onChange={() => setStateVal(!stateVal)}
          css={classes.toggle}
        />
        <span
          css={[
            stateVal ? classes.unselected : classes.selected,
            classes.option
          ]}
        >
          {option2}
        </span>
      </Stack>
      {instructions && (
        <div
          css={[
            classes.instructions,
            disableInstructions ? classes.light : classes.dark
          ]}
        >
          {instructions}
        </div>
      )}
    </div>
  )
}

interface IPrintDropdownMenuProps {
  print: (
    masked: boolean,
    hideHousehold: boolean,
    includeAltInvestmentDetails?: boolean // For Investment Print
  ) => void
  displayDisclaimer: boolean
  displayAltInvestmentDetails?: boolean
  isDisabled: boolean
}

export const PrintDropdownMenu: React.FC<IPrintDropdownMenuProps> = ({
  print,
  displayDisclaimer,
  displayAltInvestmentDetails,
  isDisabled
}) => {
  const [showPrinterDropdown, setShowPrinterDropdown] = useState(false)
  // NOTE: The default (left) value must be true for
  // * unmasked account numbers
  // * show relationship name
  // * include alt investment details
  // so the left option is highlighted by default
  const [unmaskAccountNumbers, setUnmaskAccountNumbers] = useState(true)
  const [showRelationshipName, seShowRelationshipName] = useState(true)
  const [includeAltInvestmentDetails, setIncludeAltInvestmentDetails] =
    useState(true)
  const theme = useTheme()
  const showPrinterDropdownMenu = () => setShowPrinterDropdown(true)

  const printPDF = () => {
    setShowPrinterDropdown(false)
    setUnmaskAccountNumbers(true)
    seShowRelationshipName(true)
    setIncludeAltInvestmentDetails(true)
    print(
      !unmaskAccountNumbers, // toggle so true means mask account numbers
      !showRelationshipName, // toggle so true means hide relationship names
      includeAltInvestmentDetails
    )
  }

  const classes = printDropdownClasses()
  return (
    <>
      <div id="PrintButton">
        <Icon
          type="Print"
          width={24}
          height={24}
          color={theme.colors.extraBlue2}
          onClick={isDisabled ? undefined : showPrinterDropdownMenu}
          isDisabled={isDisabled}
        />
      </div>
      {showPrinterDropdown && (
        <Callout
          target="#PrintButton"
          role="dialog"
          onDismiss={() => setShowPrinterDropdown(false)}
          styles={{
            beak: {
              display: 'none'
            }
          }}
        >
          <div css={classes.heading}>Print PDF</div>
          <div css={classes.body}>
            <PrintOption
              label="Account Numbers"
              stateVal={unmaskAccountNumbers}
              setStateVal={setUnmaskAccountNumbers}
              option1="Unmasked"
              option2="Masked"
              disableInstructions={unmaskAccountNumbers}
              instructions={
                displayDisclaimer
                  ? 'Unmasked account numbers may show in the Description column.'
                  : ''
              }
            />
            <PrintOption
              label="Relationship Name"
              stateVal={showRelationshipName}
              setStateVal={seShowRelationshipName}
              option1="Display"
              option2="Hide"
              disableInstructions={!showRelationshipName}
              instructions="Household name will be displayed at the top of the report by default."
            />
            {displayAltInvestmentDetails && (
              <PrintOption
                label="Alternative Investment Details"
                stateVal={includeAltInvestmentDetails}
                setStateVal={setIncludeAltInvestmentDetails}
                option1="Include"
                option2="Exclude"
                disableInstructions={!includeAltInvestmentDetails}
                instructions="Alternative Investment Details will be included in the report by default."
              />
            )}
            <div css={[classes.row, classes.right]}>
              <PrimaryButton
                text="Continue"
                onClick={() => printPDF()}
                css={classes.button}
              />
            </div>
          </div>
        </Callout>
      )}
    </>
  )
}
