import { Theme, css, useTheme } from '@emotion/react'
import { IconButton } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import {
  Row,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import {
  CDMv2Account,
  UpdateAccountPreferenceRequest,
  UpdateNicknameResponse
} from 'api/nickname.types'
import { isEmpty, range, uniq } from 'lodash'
import { FC, useCallback, useMemo, useState } from 'react'
import { LoadingComponent } from 'shared/components/Loading'
import { isNotNullOrEmpty, isNotNullOrFalse } from 'shared/guards'
import { useDebounce } from 'shared/hooks/useDebounce'
import { buttonStyles } from '../../components/shared/Buttons'
import { Searchbox } from '../../components/shared/DetailTables/Searchbox'
import { IndeterminateProgressIndicator } from '../../components/shared/ProgressIndicator/IndeterminateProgressIndicator'
import { SnackBar } from '../../components/shared/Snackbar'
import { useClientDashboardCommonPreferences } from '../../hooks/useClientDashboardPreferences'
import { useRdot360_updateAdvisorNicknameMutation } from '../../store/datahub'
import {
  useRdot360AccountContext,
  useRdot360HouseholdContext
} from '../../store/rdot360Context'
import { WidgetTableStyles } from '../WidgetTable/WidgetTableStyles'
import { ConformationModal } from './ConfirmationModal'
import { getNickNameTableColumns } from './ManageNicknameColumns'
import { OverrideModal } from './OverrideModal'
import { defaultNickname, useManageAccountNicknamesStore } from './store'

type ManageAccountNickNamesProps = {
  onDismissModal: () => void
}

export const getNickNamesClasses = (theme: Theme, index: number) => ({
  modalContainer: css({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '600px'
  }),
  householdLabelContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '28px 20px 8px 20px',
    flexDirection: 'row',
    position: 'sticky',
    top: 55,
    backgroundColor: theme.colors.primaryWhite,
    height: '82px',
    zIndex: 1
  }),
  summaryHeader: css({
    td: {
      borderBottom: 'solid 1px #E6E6E6'
    },
    '&:last-of-type td': {
      borderBottom: 'none'
    },
    '&:nth-of-type(odd) td': {
      backgroundColor: theme.colors.primaryWhite
    },
    '&:nth-of-type(even) td': {
      backgroundColor: theme.colors.secondaryWhite3
    }
  }),
  saveContainer: css({
    position: 'sticky',
    bottom: '0',
    backgroundColor: theme.colors.primaryWhite
  }),
  handleSaveContainer: css({
    display: 'flex',
    justifyContent: 'center',
    padding: '8px 20px',
    flexDirection: 'row'
  }),
  modalHeader: css({
    padding: '16px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px #CFCFCF solid',
    position: 'sticky',
    top: 0,
    backgroundColor: theme.colors.primaryWhite,
    zIndex: 1
  }),
  modalCloseButton: css({
    marginRight: 20,
    height: 22,
    ':hover': {
      backgroundColor: 'transparent'
    }
  }),
  noDataNote: css({
    height: '100%',
    textAlign: 'center',
    background: theme.colors.secondaryWhite,
    border: `1px solid ${theme.colors.secondaryWhite}`
  }),
  tableOverrides: css({
    ['thead']: {
      top: '137px'
    },
    ['th:first-of-type, td:first-of-type']: {
      position: 'static'
    },
    [`th:nth-child(${index})`]: {
      backgroundColor: '#DAE5F0'
    },
    [`& tr:nth-of-type(odd) td:nth-child(${index})`]: {
      backgroundColor: '#EBF2FA'
    },
    [`& tr:nth-of-type(even) td:nth-child(${index})`]: {
      backgroundColor: '#E6EDF5'
    }
  })
})

const noData: any[] = []
const emptyRowCount = 10
const conflictErrorMessage =
  'An error occurred on Save. Client Online nickname not in sync.'
const genericErrorMessage =
  'An error occurred on Save. Please click on Save to retry.'

export const ManageAccountNicknames: FC<ManageAccountNickNamesProps> = ({
  onDismissModal
}) => {
  const [updateError, setUpdateError] = useState<string | undefined>()
  const [sorting, setSorting] = useState<SortingState>([])
  const {
    advisorNicknames,
    clientNicknames,
    checkedAccounts,
    hasChangedClientNickname,
    searchText,
    setSearchText
  } = useManageAccountNicknamesStore()
  const debouncedSearchText = useDebounce(searchText, 100) || ''

  const { preferences, setPreferences } = useClientDashboardCommonPreferences()
  const preferenceValue = preferences?.preferredNickName || defaultNickname

  const [initialPreferenceValue] = useState(preferenceValue)
  const { cdmv2HouseholdAccounts, isFetching, error } =
    useRdot360AccountContext()

  const [updateAdvisorNickname, updateAdvisorNicknameResult] =
    useRdot360_updateAdvisorNicknameMutation()

  const { isLoading: isUpdateAdvisorNicknameLoading } =
    updateAdvisorNicknameResult

  const handleSave = useCallback(async () => {
    setUpdateError(undefined)
    const changedAccountIds = uniq([
      ...Object.keys(advisorNicknames || {}),
      ...Object.keys(clientNicknames || {}),
      ...Object.keys(checkedAccounts)
    ])
    const requestPayload = changedAccountIds?.map((id) => ({
      id,
      advisorNickname: advisorNicknames ? advisorNicknames[id] : undefined,
      clientNickname: clientNicknames ? clientNicknames[id] : undefined,
      sendToPerformance:
        checkedAccounts[id] === undefined
          ? undefined
          : checkedAccounts[id]
          ? 'T'
          : null
    })) as UpdateAccountPreferenceRequest[]

    if (!requestPayload?.length) {
      onDismissModal()
      return
    }

    try {
      const result = await updateAdvisorNickname(requestPayload).unwrap()
      const errorData = result?.responses?.filter(
        (item: UpdateNicknameResponse) => item.status !== 204
      )
      if (errorData?.length) {
        const conflicts = errorData.filter((x) => x.status === 409)
        if (conflicts?.length) {
          setUpdateError(conflictErrorMessage)
        } else {
          setUpdateError(genericErrorMessage)
        }
        return
      }
      onDismissModal()
    } catch (e: unknown) {
      setUpdateError(genericErrorMessage)
    }
  }, [
    advisorNicknames,
    clientNicknames,
    checkedAccounts,
    onDismissModal,
    updateAdvisorNickname
  ])

  const onDismissConfirmationModal = useCallback(() => {
    setPreferences({ preferredNickName: initialPreferenceValue })
    onDismissModal()
  }, [initialPreferenceValue, onDismissModal, setPreferences])

  const columns = useMemo(() => {
    return getNickNameTableColumns(debouncedSearchText)
  }, [debouncedSearchText])

  const table = useReactTable({
    data: cdmv2HouseholdAccounts || noData,
    columns,
    state: {
      sorting,
      globalFilter: debouncedSearchText
    },
    globalFilterFn: (
      row: Row<CDMv2Account>,
      id: string,
      filterValue: string
    ): boolean => {
      const searchString = (filterValue || '').toLowerCase()

      if (id === 'accountNumber') {
        const {
          accountNumber = '',
          registeredRep = '',
          registrationType = ''
        } = row.original
        return [accountNumber, registeredRep, registrationType].some((value) =>
          value?.toLowerCase().includes(searchString)
        )
      }

      if (id === 'addressMappings') {
        const address = row.original.addressMappings?.[0]?.address
        return [
          address?.addressLine1,
          address?.addressLine2,
          address?.addressLine3,
          address?.addressLine4,
          address?.addressLine5,
          address?.addressLine6
        ]
          .filter(isNotNullOrEmpty)
          .some((line) => line?.toLowerCase().includes(searchString))
      }

      const { shortName = '', accountNicknames } = row.original
      const { advisorAddedNickName = '', clientAddedNickName = '' } =
        accountNicknames || {}

      return [
        id === 'advisorAddedNickName' && advisorAddedNickName,
        id === 'clientAddedNickName' && clientAddedNickName,
        id === 'shortName' && shortName
      ]
        .filter(isNotNullOrFalse)
        .some((nickname) => nickname?.toLowerCase().includes(searchString))
    },
    onGlobalFilterChange: setSearchText,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  })

  const isFormValid = !(
    isEmpty(advisorNicknames) &&
    isEmpty(clientNicknames) &&
    isEmpty(checkedAccounts)
  )
  const headers = table.getFlatHeaders()
  const headerIndex = headers.findIndex((h) => preferenceValue.endsWith(h.id))
  const showNoData = useMemo(
    () => !isFetching && !cdmv2HouseholdAccounts?.length,
    [cdmv2HouseholdAccounts?.length, isFetching]
  )
  const theme = useTheme()
  const classes = useMemo(
    () => getNickNamesClasses(theme, headerIndex + 1),
    [headerIndex, theme]
  )
  const [isModalOpen, { setTrue: showModal, setFalse: hideConfirmationModal }] =
    useBoolean(false)
  const [
    isOverrideModalOpen,
    { setTrue: showOverrideModal, setFalse: hideOverrideModal }
  ] = useBoolean(false)
  const isSaveDisabled = useMemo(() => {
    return (
      (!isFormValid || isUpdateAdvisorNicknameLoading) &&
      preferenceValue === initialPreferenceValue
    )
  }, [
    isFormValid,
    isUpdateAdvisorNicknameLoading,
    preferenceValue,
    initialPreferenceValue
  ])

  const handleCancel = useCallback(() => {
    if (!isSaveDisabled) {
      showModal()
    } else {
      setPreferences({ preferredNickName: initialPreferenceValue })
      onDismissModal()
    }
  }, [
    isSaveDisabled,
    showModal,
    setPreferences,
    initialPreferenceValue,
    onDismissModal
  ])

  const handleOverride = useCallback(() => {
    hideOverrideModal()
    handleSave()
  }, [handleSave, hideOverrideModal])

  const beforeHandleSave = useCallback(() => {
    if (hasChangedClientNickname) {
      showOverrideModal()
    } else {
      handleSave()
    }
  }, [hasChangedClientNickname, showOverrideModal, handleSave])

  const { household: householdDetails } = useRdot360HouseholdContext()

  const householdName = useMemo(
    () => householdDetails?.householdName,
    [householdDetails?.householdName]
  )

  const isError = useMemo(() => !!error, [error])
  const errorMessage = (error as Error)?.message || 'An unknown Error Occurred'

  return (
    <div css={classes.modalContainer}>
      <div css={classes.modalHeader}>
        <div css={{ marginLeft: 20, fontWeight: 500 }}>
          Manage Account Nicknames
        </div>
        <IconButton
          iconProps={{ iconName: 'Cancel' }}
          ariaLabel="Close popup modal"
          onClick={handleCancel}
          css={classes.modalCloseButton}
        />
      </div>
      <ConformationModal
        isModalOpen={isModalOpen}
        onHide={hideConfirmationModal}
        onDismissModal={onDismissConfirmationModal}
        onSave={beforeHandleSave}
        isUpdateAdvisorNicknameLoading={isUpdateAdvisorNicknameLoading}
      />
      <OverrideModal
        isModalOpen={isOverrideModalOpen}
        onHide={hideOverrideModal}
        onSave={handleOverride}
        isUpdateAdvisorNicknameLoading={isUpdateAdvisorNicknameLoading}
      />
      <div css={classes.householdLabelContainer}>
        <div>
          <div css={{ marginBottom: 8, fontWeight: 700, fontSize: 18 }}>
            {householdName}
          </div>
          <div>{cdmv2HouseholdAccounts?.length} Accounts</div>
        </div>
        <div>
          <Searchbox searchText={searchText} onChange={setSearchText} />
        </div>
      </div>
      {isError && (
        <div css={{ paddingBottom: '2px' }}>
          <SnackBar message={errorMessage} type="Failure" />
        </div>
      )}
      <div css={{ margin: '0px 20px 8px 20px' }}>
        {(isFetching || isUpdateAdvisorNicknameLoading) && (
          <IndeterminateProgressIndicator />
        )}
        <table
          css={[
            WidgetTableStyles.widgetTable,
            classes.tableOverrides,
            { borderBottom: '1px solid #E6E6E6' }
          ]}
        >
          <thead>
            <tr>
              {headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      width: header.getSize(),
                      height: 0,
                      padding: 0,
                      margin: 0
                    }}
                  />
                )
              })}
            </tr>
            <tr>
              {headers.map((header) => {
                return (
                  <th key={header.id}>
                    <div
                      {...{
                        onClick: header.column.getToggleSortingHandler()
                      }}
                      css={{
                        cursor: 'pointer'
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {isFetching &&
              !cdmv2HouseholdAccounts?.length &&
              range(emptyRowCount ? emptyRowCount : 10)?.map((x) => (
                <tr css={{ height: '30px', width: '100%' }} key={x} />
              ))}
            {!isFetching &&
              table?.getRowModel()?.rows.map((row) => (
                <tr key={row.id} css={classes.summaryHeader}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      css={{ borderRight: '1px solid #AFB1B6' }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
          {showNoData && (
            <tfoot>
              {showNoData && (
                <tr css={{ height: '100%' }}>
                  <td css={classes.noDataNote} colSpan={columns.length}>
                    No data available
                  </td>
                </tr>
              )}
            </tfoot>
          )}
        </table>
      </div>
      <div css={classes.saveContainer}>
        <div css={classes.handleSaveContainer}>
          <button css={[buttonStyles.secondary]} onClick={handleCancel}>
            Cancel
          </button>
          <button
            disabled={isSaveDisabled}
            css={[buttonStyles.primary, { marginLeft: 16, display: 'flex' }]}
            onClick={beforeHandleSave}
          >
            {isUpdateAdvisorNicknameLoading ? <LoadingComponent /> : 'Save'}
          </button>
        </div>
        {updateError && (
          <div css={{ padding: '8px 20px' }}>
            <SnackBar message={updateError} type="Failure" />
          </div>
        )}
      </div>
    </div>
  )
}
