import { ColumnDef, Row } from '@tanstack/react-table'
import { IAnnuityPPLIContract } from 'api/datahub'
import { uniq } from 'lodash'
import { isNotNullOrEmpty } from 'shared/guards'
import {
  AggregatedUniqueTotal,
  AmountCellUSD,
  DateCell,
  SumAmountFooter
} from '../../features/TanStackReactTable/cellDefinitions'
import { BlankAggregatedCell, SumUniqValueFooter } from './cellDefs'
import { columnNames } from './columnNames'

const getFirstUniqueValue = (
  columnId: string,
  leafRows: Row<IAnnuityPPLIContract>[]
): string | number => {
  const items = uniq(
    leafRows
      .map((x) => {
        return x.getValue<string>(columnId)
      })
      .filter(isNotNullOrEmpty)
  )
  switch (items.length) {
    case 0:
      return ''
    case 1:
      return items[0]
    default:
      return `Multiple (${items.length})`
  }
}

export const getColumnDefs = (): ColumnDef<IAnnuityPPLIContract>[] => [
  {
    header: columnNames.cusipNumber,
    accessorFn: ({ cusipNumber }) => cusipNumber,
    enableGrouping: true
  },
  {
    header: columnNames.securityDescription,
    enableGrouping: true,
    enableHiding: true,
    accessorFn: ({ securityDescription }) => securityDescription,
    aggregationFn: getFirstUniqueValue,
    aggregatedCell: BlankAggregatedCell
  },
  {
    header: columnNames.contractType,
    aggregationFn: getFirstUniqueValue,
    accessorFn: ({ contractType }) => contractType,
    aggregatedCell: BlankAggregatedCell,
    enableGrouping: true
  },
  {
    header: columnNames.contractNumber,
    aggregationFn: 'unique',
    accessorFn: ({ contractNumber }) => contractNumber,
    aggregatedCell: AggregatedUniqueTotal
  },
  {
    header: columnNames.assetClassL1,
    aggregationFn: getFirstUniqueValue,
    aggregatedCell: BlankAggregatedCell,
    accessorFn: ({ assetClassL1 }) => assetClassL1
  },
  {
    header: columnNames.assetClassL2,
    aggregationFn: getFirstUniqueValue,
    aggregatedCell: BlankAggregatedCell,
    accessorFn: ({ assetClassL2 }) => assetClassL2
  },
  {
    header: columnNames.assetClassL3,
    aggregationFn: getFirstUniqueValue,
    aggregatedCell: BlankAggregatedCell,
    accessorFn: ({ assetClassL3 }) => assetClassL3
  },
  {
    header: columnNames.assetClassL4,
    aggregationFn: getFirstUniqueValue,
    aggregatedCell: BlankAggregatedCell,
    accessorFn: ({ assetClassL4 }) => assetClassL4
  },
  {
    header: columnNames.cleanCarrierName,
    meta: {
      className: '',
      aggregatedClassName: 'right'
    },
    aggregationFn: 'unique',
    aggregatedCell: AggregatedUniqueTotal,
    accessorFn: ({ cleanCarrierName }) => cleanCarrierName,
    sortingFn: 'alphanumeric',
    sortUndefined: 1,
    enableGrouping: true,
    footer: SumUniqValueFooter
  },
  {
    header: columnNames.householdName,
    meta: {
      className: '',
      aggregatedClassName: 'right',
      aggregatedHeader: columnNames.householdsTotal
    },
    aggregationFn: 'unique',
    aggregatedCell: AggregatedUniqueTotal,
    accessorFn: ({ householdName }) => householdName,
    sortingFn: 'alphanumeric',
    sortUndefined: 1,
    enableSorting: true,
    footer: SumUniqValueFooter
  },
  {
    header: columnNames.teamName,
    meta: {
      className: '',
      aggregatedClassName: 'right',
      aggregatedHeader: columnNames.teamsTotal
    },
    aggregationFn: 'unique',
    aggregatedCell: AggregatedUniqueTotal,
    accessorFn: ({ teamName }) => teamName,
    sortingFn: 'alphanumeric',
    sortUndefined: 1,
    enableSorting: true,
    footer: SumUniqValueFooter
  },
  {
    header: columnNames.regionName,
    meta: {
      className: '',
      aggregatedClassName: 'right'
    },
    aggregationFn: 'unique',
    aggregatedCell: AggregatedUniqueTotal,
    accessorFn: ({ regionName }) => regionName,
    footer: SumUniqValueFooter
  },
  {
    header: columnNames.premiumPaidSinceInception,
    meta: { className: 'right' },
    cell: AmountCellUSD,
    aggregatedCell: undefined,
    accessorFn: ({ premiumPaidSinceInception }) => premiumPaidSinceInception,
    footer: SumAmountFooter
  },
  {
    header: columnNames.cashValue,
    meta: { className: 'right' },
    cell: AmountCellUSD,
    aggregatedCell: undefined,
    accessorFn: ({ cashValue }) => cashValue,
    footer: SumAmountFooter
  },
  {
    header: columnNames.deathBenefitAmt,
    meta: { className: 'right' },
    cell: AmountCellUSD,
    aggregatedCell: undefined,
    accessorFn: ({ deathBenefitAmt }) => deathBenefitAmt,
    footer: SumAmountFooter
  },
  {
    header: columnNames.valuationDate,
    meta: { className: 'right' },
    aggregationFn: getFirstUniqueValue,
    cell: DateCell,
    aggregatedCell: undefined,
    accessorFn: ({ valuationDate }) => valuationDate
  },
  {
    header: columnNames.assetType,
    aggregationFn: getFirstUniqueValue,
    aggregatedCell: BlankAggregatedCell,
    accessorFn: ({ assetType }) => assetType
  },
  {
    header: columnNames.assetSubType,
    aggregationFn: getFirstUniqueValue,
    aggregatedCell: BlankAggregatedCell,
    accessorFn: ({ assetSubType }) => assetSubType
  },
  {
    header: columnNames.productType,
    aggregationFn: getFirstUniqueValue,
    aggregatedCell: BlankAggregatedCell,
    accessorFn: ({ productType }) => productType,
    enableGrouping: true
  }
]
