import { endOfMonth, isWithinInterval, startOfMonth, subMonths } from 'date-fns'
import { range, sortBy } from 'lodash'
import { createSelector } from 'reselect'
import {
  IHurdle,
  IHurdleMetric,
  IHurdleProgressMeasurement,
  IHurdleProgressStatus,
  IMetricProgress,
  IMetricWithProgress
} from '../../../../../../../../../api/datahub'
import { parseDateISOStringInLocalTimezone } from '../../../../../../../../../shared'
import { getHurdlesWithProgressFetchResult } from './hurdlesWithProgressFetch'

export const getTableDates = () =>
  range(12).map((x) => subMonths(new Date(), x))

export const getHurdlesWithProgress = createSelector(
  [getHurdlesWithProgressFetchResult],
  (hurdles) => {
    const dates = getTableDates()
    const hurdlesWithProgress = hurdles?.map((hurdle) => {
      const isAchieved = isHurdleAchieved(hurdle)
      return {
        ...hurdle,
        measurements: sortBy(
          hurdle?.measurements?.map((measurement) => {
            return {
              ...measurement,
              metrics: measurement?.metrics?.map((metric) => {
                return {
                  ...metric,
                  totalProgress: getTotalProgress(metric),
                  trailing6MonthProgress: dates.map((date) =>
                    getProgressForMonth(metric.progressMeasurements, date)
                  ),
                  status: metric.progressMeasurementStatuses?.some(
                    (x) => x.status === 'Achieved'
                  )
                    ? 'Achieved'
                    : isHurdleMissed(
                        measurement.targetDate,
                        metric.progressMeasurementStatuses || []
                      )
                    ? 'Missed'
                    : isAchieved
                    ? 'Not Applicable'
                    : 'Pending',
                  targetValue:
                    metric.metricType === 'AUS'
                      ? metric.metricValue
                      : ((metric.metricValue || 0) *
                          (hurdle.advertisedT12Revenue || 0)) /
                        100,
                  advertisedT12: hurdle.advertisedT12Revenue
                } as IMetricWithProgress
              })
            }
          }),
          (x) => x.targetDate
        )
      } as IHurdle
    })
    return sortBy(
      hurdlesWithProgress,
      (x) => x?.measurements?.[0]?.targetDate,
      (x) => x.name
    )
  }
)

const isHurdleMissed = (
  targetDate: string | undefined,
  progressStatuses: IHurdleProgressStatus[]
) =>
  progressStatuses.filter(
    (status) => status.currentPeriodDate === targetDate
  )?.[0]?.status === 'Missed'

const getProgressForMonth = (
  progress: IHurdleProgressMeasurement[] | undefined,
  date: Date
) => {
  const metric = progress
    ?.filter((x) =>
      isWithinInterval(
        parseDateISOStringInLocalTimezone(x.currentPeriodDate || ''),
        {
          start: startOfMonth(date),
          end: endOfMonth(date)
        }
      )
    )
    .pop()
  return {
    progress: metric?.metricProgress,
    total: metric?.metricMeasurementValue,
    advertisedT12Revenue: metric?.advertisedT12Revenue,
    metricTarget:
      metric?.metricType === 'AUS'
        ? metric?.perNumber
        : ((metric?.advertisedT12Revenue || 0) * (metric?.perNumber || 0)) /
          100,
    currentMonthAnnualized: metric?.currentMonthAnnualized,
    currentT12: metric?.currentT12,
    periodDate:
      metric?.currentPeriodDate &&
      parseDateISOStringInLocalTimezone(metric.currentPeriodDate),
    periodStartDate:
      metric?.currentPeriodDate &&
      metric.measuredMonths &&
      subMonths(
        parseDateISOStringInLocalTimezone(metric.currentPeriodDate),
        metric?.measuredMonths - 1
      )
  } as IMetricProgress
}

const getTotalProgress = (metric: IHurdleMetric) => {
  const achievedProgress = metric.progressMeasurementStatuses?.filter(
    (x) => x.status === 'Achieved'
  )
  const achievedDate = achievedProgress?.length
    ? achievedProgress?.[0]?.currentPeriodDate
    : undefined
  const achievedIndex =
    achievedDate &&
    metric.progressMeasurements
      ?.map((x) => x.currentPeriodDate)
      .indexOf(achievedDate)
  const totalMetric =
    metric?.progressMeasurements?.[
      achievedIndex !== undefined
        ? (achievedIndex as number)
        : metric?.progressMeasurements?.length - 1 || 0
    ]

  return {
    progress: totalMetric?.metricProgress,
    total: totalMetric?.metricMeasurementValue,
    advertisedT12Revenue: totalMetric?.advertisedT12Revenue,
    metricTarget:
      metric.metricType === 'AUS'
        ? totalMetric?.perNumber
        : ((totalMetric?.advertisedT12Revenue || 0) *
            (totalMetric?.perNumber || 0)) /
          100,
    currentMonthAnnualized: totalMetric?.currentMonthAnnualized,
    currentT12: totalMetric?.currentT12,
    periodDate:
      totalMetric?.currentPeriodDate &&
      parseDateISOStringInLocalTimezone(totalMetric.currentPeriodDate),
    periodStartDate:
      totalMetric?.currentPeriodDate &&
      totalMetric.measuredMonths &&
      subMonths(
        parseDateISOStringInLocalTimezone(totalMetric.currentPeriodDate),
        totalMetric?.measuredMonths - 1
      ),
    metricType: totalMetric?.metricType
  } as IMetricProgress
}

const isHurdleAchieved = (hurdle: IHurdle) =>
  hurdle?.measurements
    ?.map((measurement) =>
      measurement?.metrics?.map((metric) =>
        metric.progressMeasurementStatuses?.map((status) => status.status)
      )
    )
    .flat(3)
    .some((x) => x === 'Achieved')
