import axios from 'axios'
import { IApiOptions } from '../../../../../../../shared/contracts/IApiOptions'
import { IProject, IProjectRequest } from '../store/types'

export const fetchProjectList = (
  options: IApiOptions,
  projectReq: IProjectRequest
) => {
  const url = `${options.apiRoot}/trustapi/api/VendorPayeeRule/GetDimensionValues?dimname=project&clientid=${projectReq.pKid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IProject[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const addEditProject = (
  options: IApiOptions,
  projectReq: IProject | undefined
) => {
  const url = `${options.apiRoot}/trustapi/api/VendorPayeeRule/InsertUpdateDimensionValue`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IProject>(url, projectReq, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
