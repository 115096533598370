import axios from 'axios'
import { IApiOptions } from '../../../shared/contracts/IApiOptions'
import {
  IMatrixInfo,
  IPilotFeatureRequest
} from '../features/Dashboard/store/types'

const fetchMatrixInfoService = (email: string, options: IApiOptions) => {
  const url = `${options.apiRoot}/payment/api/billmgmt/getMatrixinfo?rcmUser=${email}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IMatrixInfo[]>(url, null, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchPaymentsMenuOptions = (options: IApiOptions) => {
  const url = `${options.apiRoot}/payment/api/payment/getDynamicsFeatures?isAdminUser=true`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .get<unknown>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const billDotcomSsoLog = (options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/api/PaymentAccount/SSOLog`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const getPilotFeature = (req: IPilotFeatureRequest, options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/api/PilotFeature/Get`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .post(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const handleDynamicsExportService = (url: string, options: IApiOptions) => {
  return axios
    .post(url, null, {
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export {
  fetchMatrixInfoService,
  fetchPaymentsMenuOptions,
  billDotcomSsoLog,
  getPilotFeature,
  handleDynamicsExportService
}
