import { combineReducers, Reducer } from 'redux'
import { IListsState } from '../../../../../../../features/Lists/core/store/reducers'
import {
  reducer as creditEventListReducer,
  sagas as creditEventListSagas
} from './CreditEventList'
import { IEnrichedCreditEvent } from './CreditEventList/CreditEvent'
import {
  revenueSummaryTableFeatureReducer,
  revenueSummaryTableFeatureSagas
} from './RevenueSummaryTable'
import { revenueTilesReducer, revenueTilesSagas } from './RevenueTiles'

export interface IRevenueDashboardFeaturesState {
  revenueSummaryTable: ReturnType<typeof revenueSummaryTableFeatureReducer>
  creditEventsList: IListsState<IEnrichedCreditEvent>
  revenueTiles: ReturnType<typeof revenueTilesReducer>
}

export const revenueDashboardFeaturesReducer: Reducer<IRevenueDashboardFeaturesState> =
  combineReducers({
    revenueSummaryTable: revenueSummaryTableFeatureReducer,
    creditEventsList: creditEventListReducer,
    revenueTiles: revenueTilesReducer
  })

export const revenueDashboardFeaturesSagas = [
  ...revenueSummaryTableFeatureSagas,
  ...creditEventListSagas,
  ...revenueTilesSagas
]
