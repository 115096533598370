import { css } from '@emotion/react'

export const WidgetTableStyles = {
  widgetTable: css({
    width: '100%',
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    padding: '0px 10px',
    borderLeft: '2px solid #f0f0f0',
    borderRight: '2px solid #f0f0f0',
    ['thead']: {
      position: 'sticky',
      top: 0,
      zIndex: 2
    },
    ['th']: {
      backgroundColor: '#eff3f6',
      padding: '12px 3px',
      fontSize: '12px'
    },
    ['td']: {
      borderBottom: '1px solid #f0f0f0',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: '8px 3px'
    },
    ['th:first-of-type, td:first-of-type']: {
      paddingLeft: '7px',
      position: 'sticky',
      left: '1px'
    },
    ['tbody td:first-of-type']: {
      backgroundColor: '#fff'
    },
    ['th:last-of-type, td:last-of-type']: {
      paddingRight: '7px'
    },
    ['tfoot']: { position: 'sticky', bottom: 0, fontWeight: 'bold', zIndex: 2 }
  }),
  ellipsis: css({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }),
  rightAlign: css({
    textAlign: 'end'
  }),
  footer: css({
    backgroundColor: '#eff3f6',
    fontSize: '12px',
    td: { height: '30px' }
  })
}
