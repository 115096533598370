import {
  DetailsListLayoutMode,
  IColumnReorderOptions,
  IconButton,
  ProgressIndicator,
  SearchBox,
  Stack,
  Text
} from '@fluentui/react'
import {
  IDataTableColumnDefinition,
  IDataTableSortBy
} from 'features/DataList/common/types'
import { DataTableWithMenu } from 'features/DataList/components/DataTableWithMenu'
import {
  IListsFilterEditWithApplyCancel,
  ListsFilterEditWithApplyCancel
} from 'features/Lists/core/features/filter/components/ListsFilterEditWithApplyCancel'
import { IListsFilterEditProps } from 'features/Lists/core/features/filter/contracts/IListsFilterEditProps'
import { keyBy, throttle } from 'lodash'
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react'
import { FormattedNumber } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { HorizontalScrollContainerWrapper } from 'shared/components/HorizontalScrollContainerWrapper'
import { isNotNullOrUndefined } from 'shared/guards'
import { ErrorComponent } from '../../../shared/components/Error'
import { LoadingComponent } from '../../../shared/components/Loading'
import { IListsFilter } from '../../Lists/core/contracts/IListsFilter'
import { ListsFilterStatusList } from '../../Lists/core/features/filter/components/ListsFilterStatusList'
import { IOdataListFacetActions } from '../common/IODataListFacetActions'
import { IOdataListFacetSelectors } from '../common/IODataListFacetSelectors'
import { IOdataListUiActions } from '../common/IOdataListUiActions'
import { IOdataListUiSelectors } from '../common/IOdataListUiSelectors'
import { ODataListsFilterEdit } from '../components/ODataListFilerEdit'

export interface IOdataDataListCellRenderProps<T = unknown> {
  item: T
  column: IDataTableColumnDefinition
}

export interface IOdataListProps<T = unknown, U = unknown> {
  actions: IOdataListUiActions
  selectors: IOdataListUiSelectors<T, U>
  facetActions?: IOdataListFacetActions
  facetSelectors?: IOdataListFacetSelectors<U>
  onRenderCell: React.FC<IOdataDataListCellRenderProps<T>>
  onExport?: () => void
  stickyHeaderOffset?: number
  stickyColumnOffset?: number
  layoutMode?: DetailsListLayoutMode
  secondaryHeader?: React.ReactElement<any, any>
  numStickyColumns?: number
  autoLoad?: boolean
  onColumnClick?: (column?: IDataTableColumnDefinition) => void
}

export const OdataList = <T, U>({
  actions,
  selectors,
  facetActions,
  facetSelectors,
  onRenderCell,
  onExport,
  stickyHeaderOffset,
  layoutMode,
  secondaryHeader,
  numStickyColumns,
  stickyColumnOffset,
  autoLoad = true,
  onColumnClick
}: PropsWithChildren<IOdataListProps<T, U>>): React.ReactElement<
  any,
  any
> | null => {
  const {
    resetFilters,
    updateFilters,
    updateSearchText,
    updateSelectedColumns,
    updateSort,
    loadMore
  } = actions
  const {
    getIsLoading,
    getItems,
    getItemsCount,
    getFilters,
    getSortBy,
    getSearchText,
    getError,
    getColumns,
    getSelectedColumns
  } = selectors
  const dispatch = useDispatch()
  const items = useSelector(getItems)
  const totalItems = useSelector(getItemsCount)
  const isLoading = useSelector(getIsLoading)
  const filters = useSelector(getFilters)
  const sortBy = useSelector(getSortBy)
  const searchText = useSelector(getSearchText)
  const error = useSelector(getError)
  const columns = useSelector(getColumns)
  const selectedColumns = useSelector(getSelectedColumns)

  useEffect(() => {
    if (!autoLoad) {
      return
    }
    dispatch(updateSearchText(''))
  }, [autoLoad, dispatch, updateSearchText])

  const onSearchTextChange = useCallback(
    (ev?: any, text?: string) => {
      dispatch(updateSearchText(text || ''))
    },
    [dispatch, updateSearchText]
  )

  const onFilterChange = useCallback(
    (filter: IListsFilter) => {
      if (!filter.hasValue) {
        dispatch(resetFilters([filter.id]))
        return
      }
      dispatch(updateFilters({ [filter.id]: filter }))
    },
    [dispatch, resetFilters, updateFilters]
  )

  const onRemoveColumn = useCallback(
    (name: string) => {
      if (!selectedColumns) {
        return
      }
      const updatedColumns = selectedColumns.toSpliced(
        selectedColumns.indexOf(name),
        1
      )
      dispatch(updateSelectedColumns(updatedColumns))
    },
    [dispatch, selectedColumns, updateSelectedColumns]
  )

  const onSortByChange = useCallback(
    (sortBy: IDataTableSortBy) => {
      dispatch(updateSort(sortBy))
    },
    [dispatch, updateSort]
  )

  const onFilterRemove = useCallback(
    ({ id }: IListsFilter) => {
      dispatch(resetFilters([id]))
    },
    [dispatch, resetFilters]
  )

  useEffect(() => {
    if (isLoading || (items?.length || 0) >= (totalItems || 0)) {
      return
    }

    const scrollContainer = ref?.current?.closest(
      '[data-is-scrollable]'
    ) as HTMLElement

    if (scrollContainer) {
      const handleElementScroll = throttle(() => {
        if (!scrollContainer) {
          return
        }
        const scrollPosition = scrollContainer.scrollTop
        const elementHeight =
          scrollContainer.scrollHeight - scrollContainer.offsetHeight

        if (scrollPosition >= elementHeight - 300) {
          dispatch(loadMore())
        }
      }, 200)

      scrollContainer.addEventListener('scroll', handleElementScroll)
      return () => {
        scrollContainer.removeEventListener('scroll', handleElementScroll)
        handleElementScroll.cancel()
      }
    }

    const handleWindowScroll = throttle(() => {
      const scrollPosition = window.innerHeight + window.pageYOffset
      const pageHeight = document.body.offsetHeight
      if (scrollPosition >= pageHeight - 300) {
        dispatch(loadMore())
      }
    }, 200)

    window.addEventListener('scroll', handleWindowScroll)

    return () => {
      window.removeEventListener('scroll', handleWindowScroll)
      handleWindowScroll.cancel()
    }
  }, [dispatch, isLoading, items, loadMore, totalItems])

  const ref = useRef<HTMLDivElement>(null)

  const columnLookup = useMemo(
    () => keyBy(columns, (x) => x.name || ''),
    [columns]
  )

  const validSelectedColumnNames = useMemo(
    () =>
      (
        selectedColumns ||
        columns?.map((x) => x.name).filter(isNotNullOrUndefined) ||
        []
      ).filter(
        (x) =>
          isNotNullOrUndefined(x) &&
          columnLookup[x] &&
          columnLookup[x].hidden !== true
      ),
    [columnLookup, columns, selectedColumns]
  )

  const columnReorderOptions = useMemo(
    (): IColumnReorderOptions => ({
      frozenColumnCountFromEnd: 0,
      frozenColumnCountFromStart: 0,
      onColumnDrop: ({ draggedIndex, targetIndex }) => {
        const newColumns = [...validSelectedColumnNames]
        const dragged = newColumns.splice(draggedIndex, 1)
        newColumns.splice(targetIndex, 0, ...dragged)
        dispatch(actions.updateSelectedColumns(newColumns))
      }
    }),
    [actions, dispatch, validSelectedColumnNames]
  )

  const visibleColumns = useMemo(() => {
    const visible = validSelectedColumnNames
      .map((x) => columnLookup[x])
      .filter(isNotNullOrUndefined)
    return visible
  }, [columnLookup, validSelectedColumnNames])

  const ListFilterEdit: React.FC<IListsFilterEditProps> = useCallback(
    (props) => (
      <ODataListsFilterEdit
        facetActions={facetActions}
        facetSelectors={facetSelectors}
        {...props}
      />
    ),
    [facetActions, facetSelectors]
  )
  const ListFilterEditWithApplyCancel: React.FC<IListsFilterEditWithApplyCancel> =
    useCallback(
      (props) => (
        <ListsFilterEditWithApplyCancel
          {...props}
          customListFilterEdit={ListFilterEdit}
        />
      ),
      [ListFilterEdit]
    )

  return (
    <div ref={ref} style={{ position: 'relative' }}>
      <Stack tokens={{ childrenGap: 5 }}>
        <Stack
          horizontal={true}
          verticalAlign="center"
          tokens={{ childrenGap: 10 }}
        >
          <SearchBox
            value={searchText}
            autoComplete="off"
            onChange={onSearchTextChange}
            styles={{ root: { width: '250px' } }}
            placeholder="Search"
          />
          <Stack.Item grow={1}>
            <ListsFilterStatusList
              filters={filters || {}}
              onFilterChange={onFilterChange}
              onRemove={onFilterRemove}
              customListsFilterEditWithApplyCancel={
                ListFilterEditWithApplyCancel
              }
            />
          </Stack.Item>
          <Text styles={{ root: { fontWeight: 'bold' } }}>
            <FormattedNumber value={items?.length || 0} style="decimal" />
            <span> of </span>
            <FormattedNumber value={totalItems || 0} style="decimal" />
          </Text>
          {onExport && (
            <IconButton
              iconProps={{ iconName: 'Download' }}
              title="Export to Excel"
              onClick={onExport}
            />
          )}
        </Stack>
        <Stack.Item>
          {secondaryHeader}

          <div style={{ height: '2px' }}>
            <ProgressIndicator
              progressHidden={!isLoading}
              styles={{
                itemProgress: { padding: 0, margin: 0 }
              }}
            />
          </div>
        </Stack.Item>
        <HorizontalScrollContainerWrapper layoutMode={layoutMode}>
          <DataTableWithMenu
            columns={visibleColumns as IDataTableColumnDefinition[]}
            items={items || []}
            enableShimmer={isLoading && !items?.length}
            stickyHeaderOffset={stickyHeaderOffset}
            cellComponent={onRenderCell}
            filters={filters}
            onFilterChange={onFilterChange}
            sortBy={sortBy}
            onSortByChange={onSortByChange}
            layoutMode={layoutMode}
            numStickyColumns={numStickyColumns}
            stickyColumnOffset={stickyColumnOffset}
            onColumnClick={onColumnClick}
            columnReorderOptions={columnReorderOptions}
            customListsFilterEditWithApplyCancel={ListFilterEditWithApplyCancel}
            onRemoveColumn={onRemoveColumn}
          />
        </HorizontalScrollContainerWrapper>
        <div
          style={{
            position: 'absolute',
            bottom: '5px',
            width: '100%',
            zIndex: 3
          }}
        >
          {isLoading && <LoadingComponent />}
          {error && (
            <ErrorComponent
              errorMessage={`An error occurred while loading: ${
                error.message || 'No detail available'
              }`}
            />
          )}
        </div>
      </Stack>
    </div>
  )
}
