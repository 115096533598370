import { IHurdle, IHurdleMeasurement } from 'api/datahub'
import { max, min } from 'lodash'

export type IExtendedHurdle = IHurdle & {
  hurdleTargetDate: string | undefined
  measurements: IHurdleMeasurement[] | undefined
}

export type HurdleCategory = 'CarryForward' | 'EarlyOpportunity'
export const hurdleCategories: Record<HurdleCategory, string> = {
  CarryForward: 'Carry Forward',
  EarlyOpportunity: 'Early Opportunity'
}

export type HurdleMeasurementCatgory = HurdleCategory | 'Target'

const toLowerAlphaNumeric = (val?: string) =>
  val?.replace(/\W/g, '').toLowerCase()
const getHurdleCategory = (hurdleName?: string): HurdleCategory | undefined =>
  categoryMatch.find(
    ([, match]) => match && toLowerAlphaNumeric(hurdleName)?.includes(match)
  )?.[0]

const categoryMatch = Object.entries(hurdleCategories).map(
  ([category, match]): [HurdleCategory, string | undefined] => [
    category as HurdleCategory,
    toLowerAlphaNumeric(match)
  ]
)

export const getHurdleTargetDate = (hurdle: IHurdle) => {
  const category = getHurdleCategory(hurdle.name)

  if (category === 'CarryForward') {
    return min(hurdle.measurements?.map(({ targetDate }) => targetDate))
  }

  if (category === 'EarlyOpportunity') {
    return max(hurdle.measurements?.map(({ targetDate }) => targetDate))
  }

  if (hurdle.measurements?.length === 1) {
    return hurdle.measurements[0].targetDate
  }
}

export const getHurdleMeasurementType = (
  hurdleTargetDate?: string,
  targetDate?: string
): HurdleMeasurementCatgory | undefined => {
  if (!hurdleTargetDate) {
    return 'Target'
  }

  if (!targetDate) {
    return
  }

  if (hurdleTargetDate > targetDate) {
    return 'EarlyOpportunity'
  }

  if (hurdleTargetDate < targetDate) {
    return 'CarryForward'
  }

  return 'Target'
}
