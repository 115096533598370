import {
  DatePicker,
  IDatePickerProps,
  IDatePickerStyles
} from '@fluentui/react'
import { FC } from 'react'

const DatePickerWrapper: FC<IDatePickerProps> = ({ ...props }) => {
  const datePickerStyles: Partial<IDatePickerStyles> = {
    textField: {
      color: '#000000',
      width: '100%',
      height: 34,
      '& .ms-TextField-fieldGroup': {
        height: 34,
        borderColor: '#AFB1B6',
        borderRadius: 4
      }
    },
    readOnlyTextField: {
      lineHeight: 31
    }
  }

  return <DatePicker {...props} styles={datePickerStyles} />
}

export default DatePickerWrapper
