import { convertColumnTypeToFilterType } from 'features/OdataList/common/service'
import { createOdataListWithFacetsStore } from 'features/OdataList/store/odataListWithFacetsStore'
import { keyBy } from 'lodash'
import { AppState } from '../../../../store'
import { IListsFilter } from '../../core/contracts/IListsFilter'
import {
  ActivityColumnDefinitions,
  ActivityColumnName
} from '../ColumnDefinitions'
import { fetchActivity } from './api'

export const defaultColumnsIds: ActivityColumnName[] =
  ActivityColumnDefinitions.map((x) => x.name)

const hiddenFilters: ActivityColumnName[] = ['Advisor ID']

const uiFilters = keyBy(
  ActivityColumnDefinitions.filter((x) => x.filterable).map(
    (column): IListsFilter => {
      const base = {
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        dataPath: column.dataPath,
        hasValue: false,
        hidden: hiddenFilters.includes(column.name)
      }

      return base
    }
  ),
  ({ id }) => id
)

const activityListStore = createOdataListWithFacetsStore({
  getOdataResults: fetchActivity,
  prefix: '@features/@lists/@activities',
  rootSelector: (state: AppState) => state.features.lists.activity.list,
  initialState: {
    data: {},
    facets: {},
    ui: {
      columns: ActivityColumnDefinitions,
      selectedColumns: [
        'Activity Date',
        'Account Number',
        'Description',
        'Type',
        'Symbol / CUSIP',
        'Quantity',
        'Price',
        'Amount'
      ],
      filters: uiFilters,
      sortBy: {
        direction: 'desc',
        name: 'Activity Date'
      }
    }
  }
})

export const { reducer, sagas, actions, selectors } = activityListStore
