import { css } from '@emotion/react'
import { Icon, Label } from '@fluentui/react'
import type { PlotColumnOptions } from 'highcharts'
import {
  useGetHistoricalIncomeChartQueryForSelectedAccounts,
  useGetProjectedIncomeChartQueryForSelectedAccounts
} from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useMemo, useState } from 'react'
import { useIncomeFeatureStore } from '../../../../features/Income/store'
import { IncomeTotalLabel } from '../../../Dashboard/Income/IncomeTotalLabel'
import { HistoricalIncomeTotals } from './HistoricalIncomeTotals'
import { IncomeSummaryChartHistorical } from './IncomeSummaryChartHistorical'
import { IncomeSummaryChartProjected } from './IncomeSummaryChartProjected'
import { ProjectedIncomeTotals } from './ProjectedIncomeTotals'

export const getClasses = () => ({
  progressIndicatorContainer: css({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  }),
  tableContainer: css({
    minWidth: '1140px',
    marginTop: '34px'
  }),
  iconStyle: css({
    display: 'inline-block',
    cursor: 'pointer',
    border: '1px solid #b8d5ed',
    borderRadius: 9,
    height: 18,
    width: 18,
    fontSize: 8,
    textAlign: 'center',
    backgroundColor: 'white',
    marginRight: 12
  }),
  groupItem: css({
    backgroundColor: '#eff6fb',
    color: '#13272b',
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '19px',
    padding: '10px 5px',
    border: '1px solid #6fa3c5',
    cursor: 'pointer',
    alignItems: 'center',
    boxShadow: '0px 2px 4px rgb(0 0 0 / 10%)',
    paddingLeft: 20,
    display: 'flex',
    input: {
      width: 15,
      height: 15
    },
    "input[type='checkbox' i]": {
      backgroundColor: 'white'
    }
  })
})

const chartColumnOptions: PlotColumnOptions = {
  pointWidth: 40
}

export const IncomeSummaryChartContainer: React.FC = () => {
  const { incomeMode } = useIncomeFeatureStore()
  const classes = useMemo(() => getClasses(), [])
  const [isExpanded, setIsExpanded] = useState(true)
  const isHistorical = incomeMode === 'historical'

  return (
    <div>
      {isHistorical ? <HistoricalIncomeTotals /> : <ProjectedIncomeTotals />}
      <div css={classes.groupItem} onClick={() => setIsExpanded(!isExpanded)}>
        {!isExpanded ? (
          <span css={[classes.iconStyle]}>
            <Icon iconName="ChevronUpSmall" />
          </span>
        ) : (
          <span css={[classes.iconStyle]}>
            <Icon iconName="ChevronDownSmall" />
          </span>
        )}
        <Label>{isHistorical ? <HistoricalTotal /> : <ProjectedTotal />}</Label>
      </div>
      <div css={{ display: isExpanded ? 'block' : 'none' }}>
        {isHistorical ? (
          <IncomeSummaryChartHistorical columnOptions={chartColumnOptions} />
        ) : (
          <IncomeSummaryChartProjected columnOptions={chartColumnOptions} />
        )}
      </div>
    </div>
  )
}

const HistoricalTotal: React.FC = () => {
  const { financialYear, includeOptionPremiums, incomeMode } =
    useIncomeFeatureStore()

  const { data } =
    useGetHistoricalIncomeChartQueryForSelectedAccounts(financialYear)

  const total = useMemo(
    () =>
      includeOptionPremiums ? data?.total : data?.totalExcludingOptionPremiums,
    [data, includeOptionPremiums]
  )

  return (
    <IncomeTotalLabel
      financialYearLabel={`${financialYear} ${incomeMode}`}
      incomeTotal={total || 0}
    />
  )
}

const ProjectedTotal: React.FC = () => {
  const { financialYear, incomeMode } = useIncomeFeatureStore()

  const { data } =
    useGetProjectedIncomeChartQueryForSelectedAccounts(financialYear)

  const financialYearLabel =
    financialYear === 1 ? 'Current year' : 'Next 12 months'

  return (
    <IncomeTotalLabel
      financialYearLabel={`${financialYearLabel} ${incomeMode}`}
      incomeTotal={data?.total || 0}
    />
  )
}
