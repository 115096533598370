import axios from 'axios'
import { call, cancelled, delay, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import {
  getDynamicsReps,
  IAdvisorRep
} from '../../../../../../../../../api/dynamics'
import { AppState } from '../../../../../../../../../store'
import {
  createAsyncHook,
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../../../store/shared/asyncStore'
import { getDynamicsApiOptions } from '../../../../../../../../../store/shared/sagas'

export const repSearchFetchActions = createAsyncAction(
  '@modules/@teams/@repSearchFetch/REQUEST',
  '@modules/@teams/@repSearchFetch/SUCCESS',
  '@modules/@teams/@repSearchFetch/FAILURE'
)<string | undefined, IAdvisorRep[], Error>()

export const repSearchFetchReducer = createAsyncReducer(repSearchFetchActions)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.teams.modules.hurdles.features.entitySearch
    .repSearchFetch

const selectors = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof repSearchFetchActions.request>
) {
  if (!action.payload || !action.payload.length) {
    yield put(repSearchFetchActions.success([]))
    return
  }
  yield delay(500)

  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  try {
    const apiOptions = yield* call(getDynamicsApiOptions)
    const result = yield* call(getDynamicsReps, apiOptions, action.payload)

    if (!result) {
      throw new Error('An error occurred while fetching teams')
    }
    yield put(repSearchFetchActions.success(result || []))
  } catch (e: any) {
    yield put(repSearchFetchActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const useRepSearchFetch = createAsyncHook(
  repSearchFetchActions.request,
  selectors
)

export const repSearchFetchSagas = [
  () => takeLatest(repSearchFetchActions.request, onRequest)
]
