import { IAltsAdvisorRevenueDetail } from 'api/datahub'
import type exceljs from 'exceljs'
import { IOdataListColumnDefinition } from 'features/OdataList/common/types'
import { FeeTypeOptions, formatDate } from '../../../store/dashboard'
import { excelStyle } from '../export/exportFormat'
import { AltsAdvisorRevenueDetailColumnName } from './columnNameTypes'

export interface IAltsAdvisorRevenueDetailColumnDefinition
  extends IOdataListColumnDefinition {
  name: AltsAdvisorRevenueDetailColumnName
  getExportValue?: (
    item: IAltsAdvisorRevenueDetail
  ) => Partial<exceljs.CellValue>
  exportFormat?: Partial<exceljs.Style>
}

const commonCollumnProps: Partial<IAltsAdvisorRevenueDetailColumnDefinition> = {
  filterable: true,
  sortable: true
}

export const altsAdvisorRevenueDetailColumns: IAltsAdvisorRevenueDetailColumnDefinition[] =
  [
    {
      ...commonCollumnProps,
      name: 'Aging Month',
      dataPath: 'agingMonth',
      type: 'date',
      width: 100,
      hidden: false,
      getExportValue: ({ agingMonth }) =>
        formatDate(agingMonth?.toString().substring(0, 10)),
      searchFields: [
        'accountNumber',
        'fundName',
        'cusip',
        'repName',
        'teamName',
        'revenueType'
      ]
    },
    {
      ...commonCollumnProps,
      name: 'Revenue Month',
      dataPath: 'revenueMonth',
      type: 'date',
      width: 120,
      getExportValue: ({ revenueMonth }) =>
        formatDate(revenueMonth?.toString().substring(0, 10))
    },
    {
      ...commonCollumnProps,
      name: 'Account Number',
      dataPath: 'accountNumber',
      type: 'string',
      width: 135,
      getExportValue: ({ accountNumber }) => accountNumber
    },
    {
      ...commonCollumnProps,
      name: 'Fund Name',
      dataPath: 'fundName',
      type: 'string',
      width: 320,
      getExportValue: ({ fundName }) => fundName,
      facetable: true
    },
    {
      ...commonCollumnProps,
      name: 'CUSIP',
      dataPath: 'cusip',
      type: 'string',
      width: 100,
      getExportValue: ({ cusip }) => cusip
    },
    {
      ...commonCollumnProps,
      name: 'Rep Id',
      dataPath: 'advisorRepNo',
      type: 'string',
      filterable: false,
      width: 100,
      getExportValue: ({ advisorRepNo }) => advisorRepNo
    },
    {
      ...commonCollumnProps,
      name: 'Advisor/Team Name',
      dataPath: 'repName',
      type: 'string',
      width: 200,
      getExportValue: ({ repName }) => repName,
      facetable: true
    },
    {
      ...commonCollumnProps,
      name: 'Team Rep Id',
      dataPath: 'custodianRepNo',
      type: 'string',
      filterable: false,
      width: 100,
      getExportValue: ({ custodianRepNo }) => custodianRepNo
    },
    {
      ...commonCollumnProps,
      name: 'Team Advisor Name',
      dataPath: 'teamName',
      type: 'string',
      width: 200,
      getExportValue: ({ teamName }) => teamName,
      facetable: true
    },
    {
      ...commonCollumnProps,
      name: 'Allocated Amount',
      dataPath: 'amount',
      type: 'number',
      width: 150,
      exportFormat: excelStyle.ACCOUNTING,
      getExportValue: ({ amount }) => amount
    },
    {
      ...commonCollumnProps,
      name: 'Allocation Type',
      dataPath: 'revenueType',
      type: 'string',
      width: 125,
      getExportValue: ({ revenueType }) => revenueType,
      facetable: true
    },
    {
      ...commonCollumnProps,
      name: 'Fee Type',
      dataPath: 'feeTypeCode',
      type: 'string',
      width: 100,
      hidden: true,
      getExportValue: ({ feeTypeCode }) => {
        return FeeTypeOptions.find((x) => x.key === feeTypeCode)?.text ?? ''
      },
      facetable: false
    }
  ]
