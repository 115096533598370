import {
  Stack,
  Label,
  Text,
  IChoiceGroupOption,
  ChoiceGroup,
  MessageBar,
  MessageBarType,
  Icon,
  StackItem,
  makeStyles,
  TextField,
  Dropdown,
  IDropdownOption
} from '@fluentui/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IHousehold } from '../../api/household.types'
import { AutoComplete } from '../../shared/components/AutoComplete'
import { USD } from '../../shared/components/Formatting'
import { Separator } from '../../shared/components/Separator'
import { chartColors } from '../../shared/services/theme'
import { MarginRateAccountList } from './MarginRateAccountList'
import { useMarginRateAdjustmentFeatureStore } from './store'
import {
  getMarginRateRequestType,
  marginRateMoreAccountsActions,
  MarginRateRequestType
} from './store/householdAccountsDetails'
import {
  getAccountsResult,
  getIsAccountsLoading
} from './store/householdAccountsFetch'
import { householdDetailsFetchActions } from './store/householdDetailsFetch'
import {
  getMarginRateHouseholdSearchFetchResult,
  getMarginRateHouseholdSearchFetchLoading,
  marginRateHouseholdSearchFetchActions
} from './store/householdSearchFetch'

import {
  getMarginRate,
  getRateJustification,
  marginRateEmailActions
} from './store/marginRateEmailContents'
import { getExistingRates, getSelectedHousehold } from './store/marginRatePanel'

const useClasses = makeStyles((theme) => ({
  selectedAccountContainer: {
    backgroundColor: theme.palette.neutralLighterAlt,
    border: `solid 1px ${theme.palette.neutralLighter}`,
    padding: '5px'
  },
  householdNameContainer: {
    backgroundColor: theme.palette.neutralLighterAlt,
    border: `solid 1px ${theme.palette.neutralLighter}`,
    padding: '5px',
    borderBottom: `solid 3px ${chartColors[1]}`
  }
}))

export const MarginRequestContent: React.FC = () => {
  useMarginRateAdjustmentFeatureStore()
  const dispatch = useDispatch()
  const classes = useClasses()
  const existingRates = useSelector(getExistingRates)
  const marginRate = useSelector(getMarginRate)
  const marginRateRequestType = useSelector(getMarginRateRequestType)
  const selectedHousehold = useSelector(getSelectedHousehold)
  const onMarginRateRequestTypeChanged = useCallback(
    (ev?: any, option?: IChoiceGroupOption) => {
      if (!option?.key) {
        return
      }
      dispatch(
        marginRateMoreAccountsActions.setRequestType(
          option.key as MarginRateRequestType
        )
      )
      dispatch(marginRateEmailActions.setJustification(''))
    },
    [dispatch]
  )
  useEffect(() => {
    if (marginRateRequestType === 'Existing') {
      dispatch(marginRateEmailActions.setMarginRate(existingRates?.[0]))
    } else if (marginRateRequestType === 'Suggested') {
      if (selectedHousehold?.employeeCode === 'Yes') {
        dispatch(
          marginRateEmailActions.setMarginRate(
            selectedHousehold.suggestedRate
              ? selectedHousehold.suggestedRate - 0.5
              : undefined
          )
        )
      } else {
        dispatch(
          marginRateEmailActions.setMarginRate(selectedHousehold?.suggestedRate)
        )
      }
    } else if (marginRateRequestType === 'Custom') {
      dispatch(
        marginRateEmailActions.setMarginRate(selectedHousehold?.suggestedRate)
      )
    }
  }, [
    dispatch,
    existingRates,
    marginRateRequestType,
    selectedHousehold?.employeeCode,
    selectedHousehold?.suggestedRate
  ])
  const accountSearchItems = useSelector(
    getMarginRateHouseholdSearchFetchResult
  )
  const isHouseholdSearchLoading = useSelector(
    getMarginRateHouseholdSearchFetchLoading
  )
  const [householdSearchText, setHouseholdSearchText] = useState<string>()
  const onHouseholdSearchTextChanged = useCallback((text?: string) => {
    setHouseholdSearchText(text)
  }, [])

  const onHouseholdSearchItemSelected = useCallback(
    (item?: IHousehold) => {
      setHouseholdSearchText('')
      dispatch(householdDetailsFetchActions.request(item?.householdId))
      dispatch(marginRateMoreAccountsActions.setRequestType('Suggested'))
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(marginRateHouseholdSearchFetchActions.request(householdSearchText))
  }, [householdSearchText, dispatch])

  const onAccountRemoved = useCallback(() => {
    dispatch(marginRateMoreAccountsActions.reset())
    dispatch(householdDetailsFetchActions.request(undefined))
  }, [dispatch])

  const moreAccounts = useSelector(getAccountsResult)

  const moreAccountsLoading = useSelector(getIsAccountsLoading)
  const customRateChange = useCallback(
    (rate?: string) => {
      dispatch(
        marginRateEmailActions.setMarginRate(
          rate ? parseFloat(rate) : undefined
        )
      )
    },
    [dispatch]
  )

  const justification = useSelector(getRateJustification)
  const justificationChange = useCallback(
    (ev: any, text?: string) => {
      dispatch(marginRateEmailActions.setJustification(text))
    },
    [dispatch]
  )

  const requestTypeOptions: IChoiceGroupOption[] = useMemo(
    () => [
      {
        key: 'Suggested',
        text: 'Apply Suggested Rate'
      },
      {
        key: 'Existing',
        text: 'Existing Margin Rate',
        disabled: !existingRates || !existingRates.length
      },
      { key: 'Custom', text: 'Custom Rate' }
    ],
    [existingRates]
  )
  const existingRateoptions = useMemo(
    () =>
      existingRates
        ?.map(
          (rate): IDropdownOption => ({
            key: rate || '',
            text: `OBFR + ${rate?.toFixed(2)}%`
          })
        )
        .filter(({ key }) => key),
    [existingRates]
  )
  const onExistingRateChange = useCallback(
    (ev: any, option?: IDropdownOption) => {
      if (isNaN(option?.key as number)) {
        return
      }
      dispatch(marginRateEmailActions.setMarginRate(option?.key as number))
    },
    [dispatch]
  )

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Label required>1. Search for Household, Client, or Account</Label>
      <Stack>
        <Stack>
          <AutoComplete<IHousehold>
            value={householdSearchText}
            onSearchTextChanged={onHouseholdSearchTextChanged}
            items={accountSearchItems || []}
            onItemSelected={onHouseholdSearchItemSelected}
            itemComponent={HouseholdSearchAutoCompleteItem}
            showLoadingIndicator={isHouseholdSearchLoading}
          />
        </Stack>
        <Stack styles={{ root: { paddingTop: '8px' } }} />
        {!!selectedHousehold && (
          <Stack className={classes.selectedAccountContainer}>
            <Stack
              key={selectedHousehold.id}
              horizontal={true}
              horizontalAlign="space-between"
              verticalAlign="center"
              tokens={{ childrenGap: 10 }}
            >
              <Stack>
                <Text
                  nowrap={true}
                  block={true}
                  title={selectedHousehold?.householdName}
                >
                  {selectedHousehold?.householdName || '(No Name)'}
                </Text>
                <Stack
                  horizontal={true}
                  tokens={{ childrenGap: 3 }}
                  verticalAlign="center"
                >
                  <Text variant="small" nowrap={true} block={true}>
                    {selectedHousehold?.Parties?.length} client
                    {selectedHousehold?.Parties?.length !== 1 && <>s</>}
                  </Text>
                  <Text variant="small" nowrap={true} block={true}>
                    |
                  </Text>
                  <Text variant="small" nowrap={true} block={true}>
                    {selectedHousehold?.Account?.length} account
                    {selectedHousehold?.Account?.length !== 1 && <>s</>}
                  </Text>
                </Stack>
              </Stack>
              <StackItem>
                <Stack>
                  <Text>
                    AUM Total:{' '}
                    <USD
                      value={selectedHousehold?.householdKPI?.AumTotal || 0}
                      fractionDigits={0}
                    />
                  </Text>
                  Suggested Rate: OBFR +{' '}
                  {selectedHousehold.suggestedRate?.toFixed(2)}%
                </Stack>
              </StackItem>
              <div style={{ width: '14px' }}>
                <Icon
                  title="Remove"
                  iconName="Cancel"
                  onClick={() => onAccountRemoved()}
                  styles={{ root: { cursor: 'pointer' } }}
                />
              </div>
            </Stack>
          </Stack>
        )}
        {!selectedHousehold && <MessageBar>No Household selected.</MessageBar>}
      </Stack>
      <Separator />
      <Stack tokens={{ childrenGap: 5 }}>
        <Label required>2. Select Rate Type</Label>
        <ChoiceGroup
          options={requestTypeOptions}
          selectedKey={marginRateRequestType}
          onChange={onMarginRateRequestTypeChanged}
        />
        <Separator />

        {marginRateRequestType === 'Suggested' &&
          selectedHousehold?.employeeCode !== 'Yes' && (
            <Stack>
              <Label>3. Suggested Rate</Label>
              {marginRate ? (
                <Text>OBFR + {marginRate?.toFixed(2)}%</Text>
              ) : (
                <MessageBar isMultiline={true} dismissButtonAriaLabel="Close">
                  Please select a household to display suggested rate.
                </MessageBar>
              )}
            </Stack>
          )}

        {!!(
          marginRateRequestType === 'Suggested' &&
          selectedHousehold?.employeeCode === 'Yes' &&
          selectedHousehold.suggestedRate
        ) && (
          <Stack tokens={{ childrenGap: 10 }}>
            <Label required>3. Suggested Rate</Label>

            <Text>OBFR + {marginRate?.toFixed(2)}%</Text>
            <MessageBar messageBarType={MessageBarType.info} isMultiline={true}>
              Employee discount of 50 bps applied.
            </MessageBar>
          </Stack>
        )}
        {marginRateRequestType === 'Existing' && (
          <Stack>
            <Label required>3. Select Existing Rate</Label>
            <Dropdown
              selectedKey={marginRate}
              options={existingRateoptions || []}
              onChange={onExistingRateChange}
            />
          </Stack>
        )}
        {marginRateRequestType === 'Custom' && (
          <Stack tokens={{ childrenGap: 10 }}>
            <Label required>3. Rate Details</Label>

            <TextField
              placeholder="Custom Rate"
              value={marginRate?.toString()}
              onChange={(ev, rate) => customRateChange(rate)}
              type="number"
              prefix="OBFR +"
              suffix="%"
              styles={{ root: { width: '200px' } }}
              min={0}
            />

            <TextField
              placeholder="Justification"
              multiline={true}
              value={justification}
              onChange={justificationChange}
            />
          </Stack>
        )}
        <Separator />
      </Stack>
      <Label required>4. Select Accounts</Label>
      <MessageBar isMultiline={true}>
        Select the accounts in the list below by clicking the checkbox to the
        left of each account that you would like to apply the rate to. Once you
        have selected all accounts, <b>Submit For Approval</b> button to submit
        the request. Rates shown in list do not include OBFR. Only eligible
        accounts can be selected.
      </MessageBar>
      <Stack>
        <MarginRateAccountList
          accounts={moreAccounts}
          loading={moreAccountsLoading}
        />
        {!moreAccounts && <MessageBar>No Accounts found.</MessageBar>}
      </Stack>
      <Separator />
    </Stack>
  )
}

const HouseholdSearchAutoCompleteItem: React.FC<{
  item?: IHousehold
}> = ({ item }) => {
  const { householdName, Parties, Account, householdKPI } = item || {}
  const numClients = Parties?.length || 0
  const numAccounts = Account?.length || 0
  return (
    <Stack styles={{ root: { padding: '5px 10px', minWidth: 0 } }}>
      <Stack horizontal={true} horizontalAlign="space-between">
        <Stack>
          <Text nowrap={true} block={true} title={householdName}>
            {householdName || '(No Name)'}
          </Text>
          <Stack
            horizontal={true}
            tokens={{ childrenGap: 3 }}
            verticalAlign="center"
          >
            <Text variant="small" nowrap={true} block={true}>
              {numClients} client{numClients !== 1 && <>s</>}
            </Text>
            <Text variant="small" nowrap={true} block={true}>
              |
            </Text>
            <Text variant="small" nowrap={true} block={true}>
              {numAccounts} account{numAccounts !== 1 && <>s</>}
            </Text>
          </Stack>
        </Stack>
        <Text>${householdKPI?.AumTotal?.toLocaleString()}</Text>
      </Stack>
    </Stack>
  )
}
