import { IColumnDefinition } from '../core/contracts/IColumnDefinition'
import { CommonColumnIdsEnum } from './CommonColumnIdsEnum'

export const BaseColumnDefinitions: Record<string, IColumnDefinition> = {
  [CommonColumnIdsEnum.aus]: {
    id: CommonColumnIdsEnum.aus,
    name: 'Assets Under Supervision',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.aum]: {
    id: CommonColumnIdsEnum.aum,
    name: 'Managed Assets',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.annuity]: {
    id: CommonColumnIdsEnum.annuity,
    name: 'Annuities & Insurance',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.brokerageAssets]: {
    id: CommonColumnIdsEnum.brokerageAssets,
    name: 'Brokerage Assets',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.loansOutstanding]: {
    id: CommonColumnIdsEnum.loansOutstanding,
    name: 'Loans Outstanding',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.purchasingPower]: {
    id: CommonColumnIdsEnum.purchasingPower,
    name: 'Cash Available To Trade',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.netNewAssets]: {
    id: CommonColumnIdsEnum.netNewAssets,
    name: 'Prior Day New Assets',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.netNewMoney]: {
    id: CommonColumnIdsEnum.netNewMoney,
    name: 'Prior Day New Money',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    dataPath: ''
  },
  [CommonColumnIdsEnum.legalEntityName]: {
    id: CommonColumnIdsEnum.legalEntityName,
    name: 'Legal Entity Name',
    dataPath: 'LegalEntityName',
    type: 'string',
    width: 220,
    searchable: true,
    filterable: true,
    sortable: true
  }
}
