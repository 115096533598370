import {
  Callout,
  DirectionalHint,
  Dropdown,
  IDropdownOption,
  RefObject,
  Stack,
  Text
} from '@fluentui/react'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SearchResponseType } from '../../../../../../api/common.types'
import { getEnableDataMaskingPreference } from '../../../../../../store/user/selectors'
import { IListsFilter } from '../../../contracts/IListsFilter'
import { IListsActions } from '../../../store/actions'
import { IListsSelectors } from '../../../store/selectors'
import { ListsFilterStatus } from '../components/ListsFilterStatus'
import { ListsFilterStatusButton } from '../components/ListsFilterStatusButton'
import { createListsFilterEditStandaloneContainer } from './ListsFilterEditStandalone'

export const createConnectedListsFilterStatusList = <
  T extends SearchResponseType
>(
  actions: IListsActions<T>,
  selectors: IListsSelectors<T>
) => {
  const { facetActions, uiActions } = actions
  const { facetSelectors, uiSelectors } = selectors
  const FilterEditStandalone = createListsFilterEditStandaloneContainer(
    facetSelectors,
    facetActions
  )
  const ConnectedListsFilterStatusList: React.FC = () => {
    const filters = useSelector(uiSelectors.getFilters)

    const filterDefinitions = useSelector(uiSelectors.getFilterDefinitions)
    const shouldMask = useSelector(getEnableDataMaskingPreference)

    const filterOptions = useMemo(() => {
      return Object.entries(filterDefinitions || {})
        .map(([key, value]) => {
          if (filters?.[key]) {
            return null
          }
          const options: IDropdownOption = {
            key,
            text: value.name
          }
          return options
        })
        .filter(Boolean)
        .sort((a, b) => ('' + a?.text).localeCompare(b?.text || ''))
    }, [filterDefinitions, filters]) as IDropdownOption[]

    const [selectedFilter, setSelectedFilter] = useState<
      IListsFilter | undefined
    >()

    const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false)

    const onFilterChange = useCallback(
      (_: any, option?: IDropdownOption) => {
        if (!option) {
          return
        }

        const filterId = option.key
        const filter = filters?.[filterId] || filterDefinitions?.[filterId]

        if (!filter) {
          return
        }

        setSelectedFilter({ ...filter })
      },
      [filters, filterDefinitions]
    )

    const dispatch = useDispatch()

    const onApply = useCallback(
      (newFilter: IListsFilter) => {
        if (!newFilter) {
          return
        }

        const { hasValue } = newFilter

        dispatch(
          hasValue
            ? uiActions.setFilter(newFilter)
            : uiActions.removeFilters([newFilter.id])
        )
        setSelectedFilter(undefined)
      },
      [dispatch]
    )

    const onCancel = useCallback(() => setSelectedFilter(undefined), [])

    const editFilterRef = useRef<HTMLElement>() as RefObject<HTMLElement>

    const toggleNewFilterMenu = useCallback(
      (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
        editFilterRef.current = ev.target as HTMLElement
        setShowFilterDropdown(true)
        const firstOption = filterOptions[0]
        setSelectedFilter({ ...(filterDefinitions || {})[firstOption.key] })
      },
      [filterDefinitions, editFilterRef, filterOptions]
    )

    const onCallbackDismiss = useCallback(
      () => setSelectedFilter(undefined),
      []
    )

    return (
      <>
        <Stack horizontal={true} tokens={{ childrenGap: 10 }} wrap={true}>
          {Object.entries(filters || {})
            .filter(
              ([key, value]) => !value?.hidden && (filterDefinitions || {})[key]
            )
            .map(([key, value]) => {
              const filterDefinition = (filterDefinitions || {})[key]
              const onRemove = () =>
                dispatch(uiActions.removeFilters([value.id]))
              const onClick = (
                ev: React.MouseEvent<HTMLElement, MouseEvent>
              ) => {
                if (value.disableModifications) {
                  return
                }
                editFilterRef.current = ev.target as HTMLElement
                setShowFilterDropdown(false)
                setSelectedFilter(value)
              }
              return (
                <Stack.Item key={key}>
                  <ListsFilterStatusButton
                    onClick={onClick}
                    onRemove={onRemove}
                  >
                    <Text>
                      {filterDefinition.name}:{' '}
                      <b>
                        <ListsFilterStatus
                          filter={value}
                          shouldMask={shouldMask}
                        />
                      </b>
                    </Text>
                  </ListsFilterStatusButton>
                </Stack.Item>
              )
            })}

          <Stack.Item>
            <ListsFilterStatusButton
              onClick={toggleNewFilterMenu}
              primary={true}
              iconProps={{ iconName: 'Filter' }}
            >
              <Text>New Filter</Text>
            </ListsFilterStatusButton>
          </Stack.Item>
        </Stack>
        {selectedFilter && (
          <Callout
            styles={{ root: { zIndex: 3 } }}
            target={editFilterRef}
            isBeakVisible={true}
            directionalHint={DirectionalHint.bottomAutoEdge}
            onDismiss={onCallbackDismiss}
          >
            <Stack
              styles={{
                root: { minWidth: '200px', padding: '10px' }
              }}
              tokens={{ childrenGap: 10 }}
            >
              {showFilterDropdown && (
                <Dropdown
                  styles={{ dropdown: { width: '100%' } }}
                  selectedKey={selectedFilter.id}
                  onChange={onFilterChange}
                  placeholder="Select a column or facet to filter"
                  options={filterOptions}
                />
              )}
              {selectedFilter && (
                <FilterEditStandalone
                  filter={selectedFilter}
                  onApply={onApply}
                  onCancel={onCancel}
                />
              )}
            </Stack>
          </Callout>
        )}
      </>
    )
  }

  return ConnectedListsFilterStatusList
}
