import {
  Checkbox,
  List,
  MessageBar,
  SearchBox,
  Stack,
  Text
} from '@fluentui/react'
import { fromPairs, groupBy, orderBy } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { FormattedNumber } from 'react-intl'
import { useDebounce } from 'shared/hooks/useDebounce'
import { IFacetResult } from '../../../../../../api/common.types'
import { Masked } from '../../../../../../shared/components/MaskedText'

export interface IListsFacetFilterComponentProps {
  selectedValues?: string[]
  facets?: IFacetResult[]
  onChange: (selectedValues: string[]) => void
  maskValues?: boolean
}

interface IFacetListItem {
  id: string
  key: string
  label?: string
  count?: number
  checked: boolean
}

export const ListsFacetFilterComponent: React.FC<
  IListsFacetFilterComponentProps
> = ({ selectedValues, facets, onChange, maskValues = false }) => {
  const selectedMap = useMemo(
    () => fromPairs((selectedValues || []).map((x) => [x || '', true])),
    [selectedValues]
  )

  const [searchText, setSearchText] = useState<string | undefined>()

  const onSearchChange = useCallback((_: unknown, newValue?: string) => {
    setSearchText(newValue)
  }, [])

  const debouncedSearchText = useDebounce(searchText, 400)

  const visibleFacets = useMemo(() => {
    const lowerCaseSearchText = (debouncedSearchText || '').trim().toLowerCase()

    const filtered = lowerCaseSearchText
      ? facets?.filter(
          (x) =>
            [x.value, x.displayValue]
              .map((x) => (x || '').trim().toLowerCase())
              .join(' | ')
              .indexOf(lowerCaseSearchText) >= 0
        )
      : facets

    const groupedFacets = groupBy(
      filtered,
      (item) => !!(item.displayValue || item.value)
    )

    const { true: goodFacets, false: badFacets } = groupedFacets

    const totalCountBlankLabels = badFacets?.reduce(
      (acc, item) => acc + item.count,
      0
    )

    return orderBy(
      totalCountBlankLabels
        ? [
            ...(goodFacets || []),
            { value: '', count: totalCountBlankLabels } as IFacetResult
          ]
        : goodFacets || [],
      ({ count }) => count,
      'desc'
    )
  }, [debouncedSearchText, facets])

  const allAreSelected = useMemo(
    () => visibleFacets.every((x) => selectedMap[x.value]),
    [selectedMap, visibleFacets]
  )

  const someAreSelected = useMemo(
    () => !allAreSelected && visibleFacets.some((x) => selectedMap[x.value]),
    [allAreSelected, selectedMap, visibleFacets]
  )

  const items = useMemo(
    () =>
      visibleFacets.map(
        (facet): IFacetListItem => ({
          id: facet.value,
          key: facet.value + (selectedMap[facet.value] || false),
          checked: selectedMap[facet.value || ''] || false,
          label: facet.displayValue || facet.value,
          count: facet.count
        })
      ),
    [selectedMap, visibleFacets]
  )

  const onCheckboxChanged = useCallback(
    (id: string, checked: boolean) => {
      selectedMap[id] = checked ?? false
      const selected = visibleFacets
        .filter((x) => selectedMap[x.value])
        .map(({ value }) => value)
        .map((x) => ({
          columnId: x,
          selected: selectedMap[x]
        }))
        .filter((x) => x.selected)
        .map((x) => x.columnId)
      onChange(selected)
    },
    [onChange, selectedMap, visibleFacets]
  )

  const onRenderCell = useCallback(
    (item?: IFacetListItem) => {
      if (!item) {
        return null
      }
      const onItemCheckboxChanged = (_: any, checked?: boolean) =>
        onCheckboxChanged(item.id, checked || false)

      return (
        <Stack
          key={item.key}
          horizontal={true}
          tokens={{ childrenGap: 5 }}
          verticalAlign="center"
          styles={{ root: { minWidth: 0, padding: '3px 3px 3px 0' } }}
        >
          <Checkbox checked={item.checked} onChange={onItemCheckboxChanged} />
          <Text
            nowrap={true}
            title={maskValues ? '' : item.label}
            styles={{ root: { fontWeight: 'bold' } }}
          >
            {item.label ? (
              <Masked
                text={item.label}
                shouldMask={maskValues || false}
                unmaskedCount={1}
              />
            ) : (
              <>(Blank)</>
            )}{' '}
          </Text>

          {!!item?.count && (
            <span>
              (<FormattedNumber value={item.count} maximumFractionDigits={0} />)
            </span>
          )}
        </Stack>
      )
    },
    [maskValues, onCheckboxChanged]
  )

  return (
    <Stack tokens={{ childrenGap: 5, padding: 5 }}>
      <Stack
        horizontal={true}
        verticalAlign="center"
        tokens={{ childrenGap: 5 }}
      >
        <Checkbox
          indeterminate={someAreSelected}
          checked={allAreSelected}
          onChange={() => {
            !allAreSelected
              ? onChange(visibleFacets.map(({ value }) => value))
              : onChange([])
          }}
        />
        <Stack.Item grow={1}>
          <SearchBox
            placeholder="Filter"
            value={searchText}
            onChange={onSearchChange}
            autoComplete="off"
          />
        </Stack.Item>
      </Stack>

      <div style={{ maxHeight: '300px', overflow: 'auto' }}>
        <List items={items} onRenderCell={onRenderCell} />
      </div>

      {(!facets || !facets.length) && (
        <MessageBar>No facets available</MessageBar>
      )}
    </Stack>
  )
}
