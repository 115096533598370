import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

export interface IConfigState {
  accessToken?: string
}

const { actions, reducer } = createSlice({
  name: 'alertConfig',
  initialState: { accessToken: undefined } as IConfigState,
  reducers: {
    accessToken: (state, action: PayloadAction<string>) => ({
      ...state,
      accessToken: action.payload
    })
  }
})

export { reducer as alertConfigReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.alerts.alertConfig

const getAccessToken = flow(rootSelector, (x) => x.accessToken)

export const useAlertConfig = () => {
  const dispatch = useDispatch()

  const setToken = useCallback(
    (token: string) => {
      dispatch(actions.accessToken(token))
    },
    [dispatch]
  )
  const accessToken = useSelector(getAccessToken)

  return {
    accessToken,
    setToken
  }
}
