import { ActionType, createAction } from 'typesafe-actions'
import {
  IReportInfo,
  IReportRequest,
  IReportListRequest,
  IClientsInfo,
  IShareReportResponse,
  IUploadReportRequest,
  IUploadReportResponse,
  IUploadReportList
} from './types'

export const GENERATE_REPORTS = '@modules/@reports/GENERATE_REPORTS'
export const GENERATE_REPORTS_SUCCESS =
  '@modules/@reports/GENERATE_REPORTS_SUCCESS'
export const GENERATE_REPORTS_FAILURE =
  '@modules/@reports/GENERATE_REPORTS_FAILURE'

export const GET_REPORTS_LIST = '@modules/@reports/GET_REPORTS_LIST'
export const GET_REPORTS_LIST_SUCCESS = '@modules/@reports/GET_REPORTS_SUCCESS'
export const GET_REPORTS_LIST_FAILURE =
  '@modules/@reports/GET_REPORTS_LIST_FAILURE'

export const REFRESH_REPORT = '@modules/@reports/REFRESH_REPORT'
export const REFRESH_REPORT_SUCCESS = '@modules/@reports/REFRESH_REPORT_SUCCESS'
export const REFRESH_REPORT_FAILURE = '@modules/@reports/REFRESH_REPORT_FAILURE'

export const VIEW_REPORT = '@modules/@reports/VIEW_REPORT'
export const VIEW_REPORT_SUCCESS = '@modules/@reports/VIEW_REPORT_SUCCESS'
export const VIEW_REPORT_FAILURE = '@modules/@reports/VIEW_REPORT_FAILURE'

export const SET_ACTIVE_REPORT = '@modules/@reports/SET_ACTIVE_REPORT'

export const GET_CLIENTS = '@modules/@reports/GET_CLIENTS'
export const GET_CLIENTS_SUCCESS = '@modules/@reports/GET_CLIENTS_SUCCESS'
export const GET_CLIENTS_FAILURE = '@modules/@reports/GET_CLIENTS_FAILURE'
export const SHARE_REPORT = '@modules/@reports/SHARE_REPORT'
export const SHARE_REPORT_SUCCESS = '@modules/@reports/SHARE_REPORT_SUCCESS'
export const SHARE_REPORT_FAILURE = '@modules/@reports/SHARE_REPORT_FAILURE'

export const getClientListActions = {
  request: createAction(GET_CLIENTS)(),
  success: createAction(GET_CLIENTS_SUCCESS)<IClientsInfo[]>(),
  failure: createAction(GET_CLIENTS_FAILURE)<Error>()
}

export const generateReportsActions = {
  request: createAction(GENERATE_REPORTS)<IReportRequest>(),
  success: createAction(GENERATE_REPORTS_SUCCESS)<IReportInfo>(),
  failure: createAction(GENERATE_REPORTS_FAILURE)<{
    error: Error
    request: IReportRequest
  }>()
}
export const getReportListActions = {
  request: createAction(GET_REPORTS_LIST)<IReportListRequest>(),
  success: createAction(GET_REPORTS_LIST_SUCCESS)<IReportInfo[]>(),
  failure: createAction(GET_REPORTS_LIST_FAILURE)<Error>()
}
export const refreshReportActions = {
  request: createAction(REFRESH_REPORT)<IReportRequest>(),
  success: createAction(REFRESH_REPORT_SUCCESS)<IReportInfo>(),
  failure: createAction(REFRESH_REPORT_FAILURE)<{
    error: Error
    request: IReportRequest
  }>()
}

export const viewReportActions = {
  request: createAction(VIEW_REPORT)<IReportRequest>(),
  success: createAction(VIEW_REPORT_SUCCESS)<string>(),
  failure: createAction(VIEW_REPORT_FAILURE)<Error>()
}

export const shareReportActions = {
  request: createAction(SHARE_REPORT)<IReportRequest>(),
  success: createAction(SHARE_REPORT_SUCCESS)<IShareReportResponse>(),
  failure: createAction(SHARE_REPORT_FAILURE)<Error>()
}

export const UPLOAD_REPORT_REQUEST = `@modules/@reports/UPLOAD_REPORT_REQUEST`
export const UPLOAD_REPORT_SUCCESS = `@modules/@reports/UPLOAD_REPORT_SUCCESS`
export const UPLOAD_REPORT_FAILURE = `@modules/@reports/UPLOAD_REPORT_FAILURE`
export const UPLOAD_REPORT_CLEAR = `@modules/@reports/UPLOAD_REPORT_CLEAR`

export const uploadReportActions = {
  request: createAction(UPLOAD_REPORT_REQUEST)<IUploadReportRequest>(),
  success: createAction(UPLOAD_REPORT_SUCCESS)<IUploadReportResponse>(),
  failure: createAction(UPLOAD_REPORT_FAILURE)<Error>(),
  clear: createAction(UPLOAD_REPORT_CLEAR)()
}

export const FETCH_UPLOAD_REPORT_REQUEST = `@modules/@reports/FETCH_UPLOAD_REPORT_REQUEST`
export const FETCH_UPLOAD_REPORT_SUCCESS = `@modules/@reports/FETCH_UPLOAD_REPORT_SUCCESS`
export const FETCH_REPORT_FAILURE = `@modules/@reports/FETCH_REPORT_FAILURE`

export const fetchUploadReportActions = {
  request: createAction(FETCH_UPLOAD_REPORT_REQUEST)<{ client: string }>(),
  success: createAction(FETCH_UPLOAD_REPORT_SUCCESS)<IUploadReportList[]>(),
  failure: createAction(FETCH_REPORT_FAILURE)<Error>()
}

export const VIEW_UPLOAD_REPORT_REQUEST = `@modules/@reports/VIEW_UPLOAD_REPORT_REQUEST`
export const VIEW_UPLOAD_REPORT_SUCCESS = `@modules/@reports/VIEW_UPLOAD_REPORT_SUCCESS`
export const VIEW_UPLOAD_REPORT_FAILURE = `@modules/@reports/VIEW_UPLOAD_REPORT_FAILURE`
export const VIEW_UPLOAD_REPORT_CLEAR = `@modules/@reports/VIEW_UPLOAD_REPORT_CLEAR`

export const viewUploadReportActions = {
  request: createAction(VIEW_UPLOAD_REPORT_REQUEST)<{ storagedocid: string }>(),
  success: createAction(VIEW_UPLOAD_REPORT_SUCCESS)(),
  failure: createAction(VIEW_UPLOAD_REPORT_FAILURE)<Error>(),
  clear: createAction(VIEW_UPLOAD_REPORT_CLEAR)()
}

export const DELETE_UPLOAD_REPORT_REQUEST = `@modules/@reports/DELETE_UPLOAD_REPORT_REQUEST`
export const DELETE_UPLOAD_REPORT_SUCCESS = `@modules/@reports/DELETE_UPLOAD_REPORT_SUCCESS`
export const DELETE_UPLOAD_REPORT_FAILURE = `@modules/@reports/DELETE_UPLOAD_REPORT_FAILURE`

export const deleteUploadReportActions = {
  request: createAction(DELETE_UPLOAD_REPORT_REQUEST)<{
    storagedocid: string
    client: string
  }>(),
  success: createAction(DELETE_UPLOAD_REPORT_SUCCESS)(),
  failure: createAction(DELETE_UPLOAD_REPORT_FAILURE)<Error>()
}

export type GenerateReportsActionTypes = ActionType<
  typeof generateReportsActions
>
export type GetReportListActionTypes = ActionType<typeof getReportListActions>
export type RefreshReportActionsTypes = ActionType<typeof refreshReportActions>
export type ViewReportActionsTypes = ActionType<typeof viewReportActions>
export type ShareReportActionsTypes = ActionType<typeof shareReportActions>
export const setActiveReportAction = {
  setActiveReport: createAction(SET_ACTIVE_REPORT)<IReportInfo>()
}
export type GetClientListActionsTypes = ActionType<typeof getClientListActions>
export type SetActiveReportActionType = ActionType<typeof setActiveReportAction>
export type UploadReportActionType = ActionType<typeof uploadReportActions>
export type fetchUploadReportActionsType = ActionType<
  typeof fetchUploadReportActions
>
export type viewUploadReportActionsType = ActionType<
  typeof viewUploadReportActions
>
export type deleteUploadReportActionsType = ActionType<
  typeof deleteUploadReportActions
>
export type ReportActionType =
  | GenerateReportsActionTypes
  | GetReportListActionTypes
  | RefreshReportActionsTypes
  | ViewReportActionsTypes
  | SetActiveReportActionType
  | GetClientListActionsTypes
  | ShareReportActionsTypes
  | UploadReportActionType
  | fetchUploadReportActionsType
  | viewUploadReportActionsType
  | deleteUploadReportActionsType
