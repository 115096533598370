import { MessageBarType } from '@fluentui/react'
import { flow } from 'lodash/fp'
import { combineReducers } from 'redux'
import {
  getDomainContextItems,
  getSelectedDomainContextRepCodes
} from 'store/context/domain'
import {
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest
} from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { IAccount } from '../../../../api/account.types'
import { ISearchResult } from '../../../../api/common.types'
import { IHousehold } from '../../../../api/household.types'
import { IHouseholdChangeRequest } from '../../../../api/households'
import {
  IOdataCollectionFilter,
  OdataFilterCollectionOperatorEnum,
  OdataFilterOperatorEnum,
  OdataFilterType,
  OdataPropertyFilterGroup
} from '../../../../api/odata'
import { IOdataResult } from '../../../../shared/contracts/IOdataResult'
import { isNotNullOrEmpty, isNotNullOrFalse } from '../../../../shared/guards'
import { AppState } from '../../../../store'
import { search } from '../../../../store/shared/sagas'
import { pushNotification } from '../../../Notifications'
import {
  checkForAccountsInExistingRequest,
  createOrUpdateHouseholdChangeRequestFromApi
} from '../../store/sagas'

const SEARCH_REQUEST = '@features/@households/@changeRequestEdit/SEARCH_REQUEST'
const SEARCH_SUCCESS = '@features/@households/@changeRequestEdit/SEARCH_SUCCESS'
const SEARCH_FAILURE = '@features/@households/@changeRequestEdit/SEARCH_FAILURE'

export const changeRequestEditHouseholdSearchActions = {
  request: createAction(SEARCH_REQUEST)<string | undefined>(),
  success: createAction(SEARCH_SUCCESS)<
    ISearchResult<IHousehold> | undefined
  >(),
  failure: createAction(SEARCH_FAILURE)<Error>()
}

const OPEN_PANEL = '@features/@households/@changeRequestEdit/OPEN_PANEL'
const CLOSE_PANEL = '@features/@households/@changeRequestEdit/CLOSE_PANEL'
const NEW_REQUEST = '@features/@households/@changeRequestEdit/NEW_REQUEST'
const UPDATE_HOUSEHOLD_SEARCH_TEXT =
  '@features/@households/@changeRequestEdit/UPDATE_HOUSEHOLD_SEARCH_TEXT'

export const changeRequestEditUiActions = {
  openPanel: createAction(OPEN_PANEL)<IHouseholdChangeRequest | undefined>(),
  closePanel: createAction(CLOSE_PANEL)(),
  createNewChangeRequest: createAction(NEW_REQUEST)<
    IHouseholdChangeRequest | undefined
  >(),
  updateHouseholdSearchText: createAction(UPDATE_HOUSEHOLD_SEARCH_TEXT)<
    string | undefined
  >()
}

const ACCOUNT_SEARCH_REQUEST =
  '@features/@households/@changeRequestEdit/ACCOUNT_SEARCH_REQUEST'
const ACCOUNT_SEARCH_SUCCESS =
  '@features/@households/@changeRequestEdit/ACCOUNT_SEARCH_SUCCESS'
const ACCOUNT_SEARCH_FAILURE =
  '@features/@households/@changeRequestEdit/ACCOUNT_SEARCH_FAILURE'

export interface IChangeRequestEditAccountSearchPayload {
  query?: string
  filterAccounts?: string[]
  householdId?: string
}
export const changeRequestEditAccountSearchActions = {
  request: createAction(ACCOUNT_SEARCH_REQUEST)<
    IChangeRequestEditAccountSearchPayload | undefined
  >(),
  success: createAction(ACCOUNT_SEARCH_SUCCESS)<
    ISearchResult<IAccount> | undefined
  >(),
  failure: createAction(ACCOUNT_SEARCH_FAILURE)<Error>()
}

const CREATE_UPDATE_REQUEST =
  '@features/@households/@changeRequestEdit/CREATE_UPDATE_REQUEST'
const CREATE_UPDATE_SUCCESS =
  '@features/@households/@changeRequestEdit/CREATE_UPDATE_SUCCESS'
const CREATE_UPDATE_FAILURE =
  '@features/@households/@changeRequestEdit/CREATE_UPDATE_FAILURE'

export const changeRequestEditCreateUpdateActions = {
  request: createAction(CREATE_UPDATE_REQUEST)<IHouseholdChangeRequest>(),
  success: createAction(CREATE_UPDATE_SUCCESS)(),
  failure: createAction(CREATE_UPDATE_FAILURE)<Error>()
}

export type ChangeRequestEditActionTypes =
  | ActionType<typeof changeRequestEditHouseholdSearchActions>
  | ActionType<typeof changeRequestEditUiActions>
  | ActionType<typeof changeRequestEditAccountSearchActions>
  | ActionType<typeof changeRequestEditCreateUpdateActions>

export interface IChangeRequestEditHouseholdSearchState {
  items?: ISearchResult<IHousehold>
  loading?: boolean
  error?: Error
}

const initialChangeRequestEditHouseholdSearchState: IChangeRequestEditHouseholdSearchState =
  {
    loading: false
  }

const changeRequestEditHouseholdSearchReducer = createReducer<
  IChangeRequestEditHouseholdSearchState,
  ChangeRequestEditActionTypes
>(initialChangeRequestEditHouseholdSearchState)
  .handleAction(changeRequestEditHouseholdSearchActions.request, (state) => ({
    ...state,
    loading: true
  }))
  .handleAction(
    changeRequestEditHouseholdSearchActions.success,
    (state, action) => ({
      ...state,
      items: action.payload,
      loading: false
    })
  )
  .handleAction(
    changeRequestEditHouseholdSearchActions.failure,
    (state, action) => ({
      ...state,
      error: action.payload,
      loading: false
    })
  )

export const getChangeRequestEditState = (state: AppState) =>
  state.features.households.features.changeRequestEdit
export const getChangeRequestEditHouseholdsSearchItems = flow(
  getChangeRequestEditState,
  (x) => x.householdsSearch.items
)
export const getIsChangeRequestEditHouseholdsSearchLoading = flow(
  getChangeRequestEditState,
  (x) => x.householdsSearch.loading
)

export interface IChangeRequestEditUiState {
  isPanelOpen: boolean
  householdSearchText?: string
  request?: IHouseholdChangeRequest
}

const initialChangeRequestEditUiState: IChangeRequestEditUiState = {
  isPanelOpen: false
}

export const changeRequestEditUiReducer = createReducer<
  IChangeRequestEditUiState,
  ChangeRequestEditActionTypes
>(initialChangeRequestEditUiState)
  .handleAction(changeRequestEditUiActions.openPanel, (state, action) => ({
    ...state,
    isPanelOpen: true,
    request: action.payload
  }))
  .handleAction(changeRequestEditUiActions.closePanel, (state) => ({
    ...state,
    isPanelOpen: false,
    request: undefined,
    householdSearchText: undefined
  }))
  .handleAction(
    changeRequestEditUiActions.updateHouseholdSearchText,
    (state, action) => ({
      ...state,
      householdSearchText: action.payload
    })
  )

export const getIsChangeRequestPanelOpen = flow(
  getChangeRequestEditState,
  (x) => x.ui.isPanelOpen
)

export const getChangeRequestEditUiRequest = flow(
  getChangeRequestEditState,
  (x) => x.ui.request
)

export const getChangeRequestEditUiHouseholdSearchText = flow(
  getChangeRequestEditState,
  (x) => x.ui.householdSearchText
)

export interface IChangeRequestEdiAccountSearchState {
  items?: ISearchResult<IAccount>
  loading?: boolean
  error?: Error
}

const initialChangeRequestEditAccountSearch: IChangeRequestEdiAccountSearchState =
  {
    loading: false
  }

const changeRequestEditAccountSearchReducer = createReducer<
  IChangeRequestEdiAccountSearchState,
  ChangeRequestEditActionTypes
>(initialChangeRequestEditAccountSearch)
  .handleAction(changeRequestEditAccountSearchActions.request, (state) => ({
    ...state,
    loading: true
  }))
  .handleAction(
    changeRequestEditAccountSearchActions.success,
    (state, action) => ({
      ...state,
      items: action.payload,
      loading: false
    })
  )
  .handleAction(
    changeRequestEditAccountSearchActions.failure,
    (state, action) => ({
      ...state,
      error: action.payload,
      loading: false
    })
  )

export const getChangeRequestEditAccountSearchItems = flow(
  getChangeRequestEditState,
  (x) => x.accountSearch.items
)

export const getIsChangeRequestEditAccountSearchLoading = flow(
  getChangeRequestEditState,
  (x) => x.accountSearch.loading
)

export interface ICreateOrUpdateHouseholdState {
  item?: IHouseholdChangeRequest
  success?: boolean
  loading?: boolean
  error?: Error
}

const initialCreateOrUpdateHouseholdState: ICreateOrUpdateHouseholdState = {
  loading: false
}

const createOrUpdateHouseholdReducer = createReducer<
  ICreateOrUpdateHouseholdState,
  ChangeRequestEditActionTypes
>(initialCreateOrUpdateHouseholdState)
  .handleAction(
    changeRequestEditCreateUpdateActions.request,
    (state, action) => ({
      ...state,
      item: action.payload,
      success: undefined,
      error: undefined,
      loading: true
    })
  )
  .handleAction(changeRequestEditCreateUpdateActions.success, (state) => ({
    ...state,
    success: true,
    loading: false
  }))
  .handleAction(
    changeRequestEditCreateUpdateActions.failure,
    (state, action) => ({
      ...state,
      success: false,
      error: action.payload,
      loading: false
    })
  )
  .handleAction(changeRequestEditUiActions.closePanel, () => ({
    ...initialCreateOrUpdateHouseholdState
  }))

export const getIsCreateOrUpdateHouseholdRequestLoading = flow(
  getChangeRequestEditState,
  (x) => x.createOrUpdate.loading
)
export const getCreateOrUpdateHouseholdRequestItem = flow(
  getChangeRequestEditState,
  (x) => x.createOrUpdate.item
)
export const getCreateOrUpdateHouseholdRequestSuccess = flow(
  getChangeRequestEditState,
  (x) => x.createOrUpdate.success
)
export const getCreateOrUpdateHouseholdRequestError = flow(
  getChangeRequestEditState,
  (x) => x.createOrUpdate.error
)

export const changeRequestEditReducer = combineReducers({
  householdsSearch: changeRequestEditHouseholdSearchReducer,
  accountSearch: changeRequestEditAccountSearchReducer,
  createOrUpdate: createOrUpdateHouseholdReducer,
  ui: changeRequestEditUiReducer
})

const getSelectedDomainFilter = function* (path = 'ClientAdvisorID') {
  const selectedReps = yield* select(getSelectedDomainContextRepCodes)
  const allReps = yield* select(getDomainContextItems)

  const isAllRepsSelected =
    allReps && selectedReps && selectedReps.length === allReps.length

  if (isAllRepsSelected || !selectedReps?.length) {
    return undefined
  }

  const filter: OdataPropertyFilterGroup = {
    and: [
      {
        type: 'string' as OdataFilterType,
        operator: OdataFilterOperatorEnum.searchin,
        value: selectedReps,
        path
      }
    ]
  }

  return filter
}

const getSelectedDomainCollectionFilter = function* (
  collectionPath = 'Advisors',
  propertyPath = 'ClientAdvisorID'
) {
  const propFilter = yield* call(getSelectedDomainFilter, propertyPath)

  if (!propFilter) {
    return
  }

  const collectionFilter: IOdataCollectionFilter = {
    operator: OdataFilterCollectionOperatorEnum.any,
    path: collectionPath,
    filter: propFilter
  }

  return collectionFilter
}

const fetchHouseholdSearchResults = function* (
  action: ReturnType<typeof changeRequestEditHouseholdSearchActions.request>
) {
  yield delay(300)
  const domainFilter = yield* call(getSelectedDomainCollectionFilter)

  try {
    const households = yield* call(search, 'household' as const, {
      top: 20,
      searchFields: ['householdName', 'Parties/LegalEntityName'],
      filters: [domainFilter].filter(isNotNullOrFalse),
      select: [
        'id',
        'householdId',
        'householdName',
        'Parties',
        'Account',
        'householdKPI/AumTotal'
      ],
      query: action.payload
    })

    yield put(
      changeRequestEditHouseholdSearchActions.success(
        households as ISearchResult<IHousehold>
      )
    )
  } catch (e: any) {
    yield put(changeRequestEditHouseholdSearchActions.failure(e))
  }
}

const createOrUpdateHouseholdEditRequest = function* (
  action: ReturnType<typeof changeRequestEditCreateUpdateActions.request>
) {
  try {
    const existingRequestsWithAccounts:
      | IOdataResult<IHouseholdChangeRequest>
      | undefined = yield call(
      checkForAccountsInExistingRequest,
      action.payload.accounts
        ?.map((x) => x.accountId)
        .filter(isNotNullOrEmpty) || []
    )

    const nonCurrentExistingRequests =
      existingRequestsWithAccounts?.value?.filter(
        (x) => x.id !== action.payload.id
      ) || []

    if (nonCurrentExistingRequests.length) {
      yield put(
        changeRequestEditCreateUpdateActions.failure(
          new Error(
            'Some requested accounts exist in a currently pending request'
          )
        )
      )
      return
    }

    yield call(createOrUpdateHouseholdChangeRequestFromApi, action.payload)

    yield put(changeRequestEditCreateUpdateActions.success())
  } catch (e: any) {
    yield put(changeRequestEditCreateUpdateActions.failure(e))
  }
}

const fetchAccountSearchResults = function* (
  action: ReturnType<typeof changeRequestEditAccountSearchActions.request>
) {
  yield delay(300)
  const { query, filterAccounts, householdId } = action.payload || {}
  const domainFilter = yield* call(getSelectedDomainFilter)

  const filters: (OdataPropertyFilterGroup | IOdataCollectionFilter)[] = [
    !!filterAccounts?.length && {
      not: {
        and: [
          {
            type: 'string' as OdataFilterType,
            operator: OdataFilterOperatorEnum.searchin,
            value: filterAccounts,
            path: 'accountId'
          }
        ]
      }
    },
    !!householdId && {
      and: [
        {
          type: 'string' as OdataFilterType,
          operator: OdataFilterOperatorEnum.ne,
          value: householdId,
          path: 'householdId'
        }
      ].filter(isNotNullOrFalse)
    },
    domainFilter
  ].filter(isNotNullOrFalse)

  try {
    const accounts: ISearchResult<IAccount> = yield call(
      search,
      'account' as const,
      {
        count: true,
        query,
        top: 30,
        orderBy: [
          {
            dataPath: 'search.score()',
            direction: 'desc' as const
          },
          {
            dataPath: 'AccountKPIs/AccountTotal',
            direction: 'desc' as const
          }
        ],
        searchFields: [
          'CustodyAccount',
          'LegalEntityName',
          'AdvisorAddedNickName',
          'Shortname',
          'gfoCustodyAccount',
          'householdName'
        ],
        filters,
        select: [
          'ClientAdvisorID',
          'ClientAdvisor',
          'ClientAdvisorTeam',
          'CustodyAccount',
          'LegalEntityName',
          'LegalEntityID',
          'AccountKPIs/AccountTotal',
          'AdvisorAddedNickName',
          'accountStatus',
          'Shortname',
          'CustodianType',
          'CustodianName',
          'registrationtype',
          'gfoCustodyAccount',
          'id',
          'accountId',
          'householdName'
        ]
      }
    )

    yield put(changeRequestEditAccountSearchActions.success(accounts))
  } catch (e: any) {
    yield put(changeRequestEditAccountSearchActions.failure(e))
  }
}

export const changeRequestEditSagas = [
  () =>
    takeLatest(
      changeRequestEditHouseholdSearchActions.request,
      fetchHouseholdSearchResults
    ),
  () =>
    takeLatest(
      changeRequestEditAccountSearchActions.request,
      fetchAccountSearchResults
    ),
  () =>
    takeLatest(
      changeRequestEditCreateUpdateActions.request,
      createOrUpdateHouseholdEditRequest
    ),
  () =>
    takeLatest(changeRequestEditCreateUpdateActions.success, function* () {
      yield put(changeRequestEditUiActions.closePanel())
    }),
  () =>
    takeLatest(
      changeRequestEditUiActions.updateHouseholdSearchText,
      function* (
        action: ReturnType<
          typeof changeRequestEditUiActions.updateHouseholdSearchText
        >
      ) {
        yield put(
          changeRequestEditHouseholdSearchActions.request(action.payload)
        )
      }
    ),
  () =>
    takeLatest(
      changeRequestEditUiActions.createNewChangeRequest,
      function* (
        action: ReturnType<
          typeof changeRequestEditUiActions.createNewChangeRequest
        >
      ) {
        yield put(changeRequestEditUiActions.openPanel(action.payload))
      }
    ),
  () =>
    takeEvery(changeRequestEditCreateUpdateActions.success, function* () {
      yield call(pushNotification, {
        message: 'Successfully created/updated request.',
        type: MessageBarType.success,
        detailLink: '/households/changerequests'
      })
    })
]
