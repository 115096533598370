import { IListsState } from 'features/Lists/core/store/reducers'
import { Reducer, combineReducers } from 'redux'
import { reducer as listReducer } from '../features/OdataList/alerts/store'
import { IODataListState } from '../features/OdataList/store/odataListWithFacetsStore'
import { IConfigState, alertConfigReducer } from './alertConfig'
import { IDetailPanelState, alertDetailReducer } from './alertDetailPanel'
import { IAlert } from './alerts.types'
import { reducer as alertsListReducer, sagas } from './alertsList'
import { INotesPanelState, notesPanelReducer } from './alertsNotesStore'
import { IAlertSummaryState, alertsSummaryReducer } from './alertsSummary'
import { IDialogState, archiveAlertReducer } from './archiveAlert'

export interface IAlertsState {
  alertDetails: IDetailPanelState
  archiveAlert: IDialogState
  notes: INotesPanelState
  alertsList: IODataListState<IAlert>
  alertsSummary: IAlertSummaryState
  alertConfig: IConfigState
  list: IListsState<IAlert>
}

export const alertsReducer: Reducer<IAlertsState> = combineReducers({
  alertDetails: alertDetailReducer,
  archiveAlert: archiveAlertReducer,
  notes: notesPanelReducer,
  alertsList: alertsListReducer,
  alertsSummary: alertsSummaryReducer,
  alertConfig: alertConfigReducer,
  list: listReducer
})

export const alertsModuleSagas = [...sagas]
