import { IBusinessUnit } from '../../../../../api/dynamics'
import { IWithSplitVisibility } from '../../../store/domain'

export interface IAdvisoryBusinessUnit {
  id: string
  name?: string
  parentId?: string
}

export interface IAdvisoryAccountRep extends IWithSplitVisibility {
  id: string
  name?: string
  team?: IAdvisoryBusinessUnit
  office?: IAdvisoryBusinessUnit
  division?: IAdvisoryBusinessUnit
}

export const mapToContextBusinessUnit = (
  businessunit?: IBusinessUnit
): IAdvisoryBusinessUnit | undefined =>
  businessunit && {
    id: businessunit.businessunitid,
    name: businessunit.name,
    parentId: businessunit?.parentbusinessunitid?.businessunitid
  }
