import { combineReducers } from 'redux'

import {
  marginRateRequestListReducer,
  marginRateRequestListSagas
} from './MarginRateRequestList/store'
import {
  householdAccountsFetchReducer,
  householdAccountsFetchSagas
} from './MarginRateRequestView/store/HouseholdAccountsFetch'
import {
  householdDetailsFetchReducer,
  householdDetailsFetchSagas
} from './MarginRateRequestView/store/HouseholdDetailsFetch'
import {
  marginRateRequestFetchReducer,
  marginRateRequestFetchSagas
} from './MarginRateRequestView/store/MarginRateRequestFetch'
import {
  marginRateRequestUpdateReducer,
  marginRateRequestUpdateSagas
} from './MarginRateRequestView/store/MarginRateRequestUpdate'
import {
  marginRateRequestViewPanelReducer,
  marginRateRequestViewPanelSagas
} from './MarginRateRequestView/store/MarginRateRequestViewPanel'

export const marginRateRequestsFeaturesReducer = combineReducers({
  marginRateRequestList: marginRateRequestListReducer,
  marginRateRequestViewPanel: marginRateRequestViewPanelReducer,
  householdAccountsFetch: householdAccountsFetchReducer,
  householdDetailFetch: householdDetailsFetchReducer,
  marginRateRequestFetch: marginRateRequestFetchReducer,
  marginRateRequestUpdate: marginRateRequestUpdateReducer
})

export const marginRateRequestsFeaturesSagas = [
  ...marginRateRequestListSagas,
  ...marginRateRequestViewPanelSagas,
  ...householdAccountsFetchSagas,
  ...householdDetailsFetchSagas,
  ...marginRateRequestFetchSagas,
  ...marginRateRequestUpdateSagas
]
