import { createReducer } from 'typesafe-actions'
import {
  ITrustDiscretionaryDistribution,
  ITrustDiscretionaryDistributionDoc,
  ITrustDiscretionaryDistributionByAccount
} from '../api/types'
import {
  NewDiscretionaryDistributionAction,
  discretionaryDistributionInfoDataActions,
  discretionaryDistributionSubmitActions,
  discretionaryDistributionEditActions,
  discretionaryDistributionDocActions,
  uploadAdditionalDocActions,
  deleteAdditionalDocActions,
  discretionaryDistributionInfoByAccountDataActions
} from './actions'
import { ITrustDistribution } from './types'

export interface IDistributionInfoState {
  isLoading: boolean
  error?: Error
  distribution?: ITrustDiscretionaryDistribution
}
export interface IDistributionInfoByAccountState {
  isLoading: boolean
  error?: Error
  distribution?: ITrustDiscretionaryDistributionByAccount
}

export interface ISubmitDistributionState {
  isLoading: boolean
  error?: Error
  distributionId?: string
  status?: number
}

export interface IDistributionInfoEditState {
  isLoading: boolean
  error?: Error
  distribution?: ITrustDistribution
}

export interface IDistributionDocsState {
  isLoading: boolean
  error?: Error
  documents?: ITrustDiscretionaryDistributionDoc[]
}

export interface IUploadAdditionalDocsState {
  error?: Error
}
export interface INewDistributionState {
  distributionInfoState?: IDistributionInfoState
  distributionInfoByAccountState?: IDistributionInfoByAccountState
  submitDistributionState?: ISubmitDistributionState
  distributionInfoEditState?: IDistributionInfoEditState
  distributionDocsState?: IDistributionDocsState
  uploadAdditionalDoc?: IUploadAdditionalDocsState
}

export const initialState: INewDistributionState = {}

export const newdistributionreducer = createReducer<
  INewDistributionState,
  NewDiscretionaryDistributionAction
>(initialState)
  .handleAction(discretionaryDistributionInfoDataActions.request, (state) => ({
    ...state,
    distributionInfoState: {
      ...state.distributionInfoState,
      isLoading: true,
      error: undefined,
      distribution: undefined
    },
    distributionInfoEditState: undefined,
    distributionDocsState: undefined
  }))
  .handleAction(
    discretionaryDistributionInfoDataActions.success,
    (state, action) => ({
      ...state,
      distributionInfoState: {
        ...state.distributionInfoState,
        isLoading: false,
        error: undefined,
        distribution: action.payload
      }
    })
  )
  .handleAction(
    discretionaryDistributionInfoDataActions.failure,
    (state, action) => ({
      ...state,
      distributionInfoState: {
        ...state.distributionInfoState,
        isLoading: false,
        error: action.payload,
        distribution: undefined
      }
    })
  )
  .handleAction(discretionaryDistributionInfoDataActions.clear, (state) => ({
    ...state,
    distributionInfoState: undefined,
    submitDistributionState: undefined,
    distributionInfoEditState: undefined,
    distributionDocsState: undefined,
    uploadAdditionalDoc: undefined
  }))
  .handleAction(
    discretionaryDistributionInfoByAccountDataActions.request,
    (state) => ({
      ...state,
      distributionInfoByAccountState: {
        isLoading: true,
        error: undefined,
        distribution: undefined
      },
      distributionInfoEditState: undefined,
      distributionDocsState: undefined
    })
  )
  .handleAction(
    discretionaryDistributionInfoByAccountDataActions.success,
    (state, action) => ({
      ...state,
      distributionInfoByAccountState: {
        isLoading: false,
        error: undefined,
        distribution: action.payload
      }
    })
  )
  .handleAction(
    discretionaryDistributionInfoByAccountDataActions.failure,
    (state, action) => ({
      ...state,
      distributionInfoByAccountState: {
        isLoading: false,
        error: action.payload,
        distribution: undefined
      }
    })
  )
  .handleAction(
    discretionaryDistributionInfoByAccountDataActions.clear,
    (state) => ({
      ...state,
      distributionInfoByAccountState: undefined,
      submitDistributionState: undefined,
      distributionInfoEditState: undefined,
      distributionDocsState: undefined,
      uploadAdditionalDoc: undefined
    })
  )
  .handleAction(discretionaryDistributionSubmitActions.request, (state) => ({
    ...state,
    submitDistributionState: {
      isLoading: true,
      error: undefined,
      distributionId: undefined
    }
  }))
  .handleAction(
    discretionaryDistributionSubmitActions.success,
    (state, action) => ({
      ...state,
      submitDistributionState: {
        isLoading: false,
        error: undefined,
        distributionId: action.payload
      }
    })
  )
  .handleAction(
    discretionaryDistributionSubmitActions.failure,
    (state, action) => ({
      ...state,
      submitDistributionState: {
        isLoading: true,
        error: action.payload,
        distributionId: undefined
      }
    })
  )
  .handleAction(discretionaryDistributionEditActions.request, (state) => ({
    ...state,
    distributionInfoEditState: {
      isLoading: true,
      error: undefined,
      distribution: undefined
    }
  }))
  .handleAction(
    discretionaryDistributionEditActions.success,
    (state, action) => ({
      ...state,
      distributionInfoEditState: {
        isLoading: false,
        error: undefined,
        distribution: action.payload ? action.payload : undefined
      }
    })
  )
  .handleAction(
    discretionaryDistributionEditActions.failure,
    (state, action) => ({
      ...state,
      distributionInfoEditState: {
        isLoading: false,
        error: action.payload,
        distribution: undefined
      }
    })
  )
  .handleAction(discretionaryDistributionDocActions.request, (state) => ({
    ...state,
    distributionDocsState: {
      isLoading: true,
      error: undefined,
      documents: undefined
    }
  }))
  .handleAction(
    discretionaryDistributionDocActions.success,
    (state, action) => ({
      ...state,
      distributionDocsState: {
        isLoading: false,
        error: undefined,
        documents: action.payload
      }
    })
  )
  .handleAction(
    discretionaryDistributionDocActions.failure,
    (state, action) => ({
      ...state,
      distributionDocsState: {
        isLoading: false,
        error: action.payload,
        documents: undefined
      }
    })
  )
  .handleAction(
    discretionaryDistributionSubmitActions.statusChange,
    (state, action) => ({
      ...state,
      submitDistributionState: {
        ...state.submitDistributionState,
        isLoading: action.payload === 4 ? false : true,
        status: action.payload
      }
    })
  )
  .handleAction(uploadAdditionalDocActions.request, (state) => ({
    ...state,
    uploadAdditionalDoc: {
      ...state.uploadAdditionalDoc,
      error: undefined
    }
  }))
  .handleAction(uploadAdditionalDocActions.failure, (state, action) => ({
    ...state,
    uploadAdditionalDoc: {
      ...state.uploadAdditionalDoc,
      error: action.payload
    }
  }))
  .handleAction(deleteAdditionalDocActions.request, (state) => ({
    ...state,
    distributionDocsState: {
      ...state.distributionDocsState,
      isLoading: true,
      documents: state.distributionDocsState?.documents
        ? [...state.distributionDocsState.documents]
        : undefined,
      error: undefined
    }
  }))
  .handleAction(deleteAdditionalDocActions.success, (state, action) => ({
    ...state,
    distributionDocsState: {
      ...state.distributionDocsState,
      isLoading: false,
      documents: state.distributionDocsState?.documents?.filter(
        (d) => d.documentId !== action.payload
      ),
      error: undefined
    }
  }))
  .handleAction(deleteAdditionalDocActions.failure, (state, action) => ({
    ...state,
    distributionDocsState: {
      ...state.distributionDocsState,
      isLoading: false,
      documents: state.distributionDocsState?.documents
        ? [...state.distributionDocsState.documents]
        : undefined,
      error: action.payload
    }
  }))
