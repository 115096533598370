import { IPerformanceReport } from 'api/performancereport.types'
import { ISecurity } from 'api/security.types'
import { IThirdPartyProduct } from 'api/thirdpartyproduct.type'
import { combineReducers, Reducer } from 'redux'
import { IAccount } from '../../api/account.types'
import { IClient } from '../../api/client.types'
import { IHousehold } from '../../api/household.types'
import { IOrder } from '../../api/order.types'
import { IPosition } from '../../api/position.types'
import {
  reducer as thirdPartyProductReducer,
  sagas as thirdPartyProductSagas
} from '../../modules/ExternalManagers/features/ProductsView/store' ///features/DashBoard/store'
import {
  reducer as performanceReportReducer,
  sagas as performanceReportSagas
} from '../../modules/Operations/modules/Performance/features/DashBoard/store'
import {
  reducer as dueDiligenceReducer,
  sagas as dueDiligenceSagas
} from '../../modules/Security/features/DueDiligence/store'
import {
  reducer as securityReducer,
  sagas as securitySagas
} from '../../modules/Security/store'
import {
  reducer as accountListReducer,
  sagas as accountListSagas
} from './accounts/store'
import {
  activityListReducer,
  activityListSagas,
  IActivityListState
} from './activities'
import {
  reducer as clientListReducer,
  sagas as clientListSagas
} from './clients/store'
import { IListsState } from './core/store/reducers'
import {
  reducer as householdListReducer,
  sagas as householdListSagas
} from './households/store'
import {
  reducer as orderListRecuder,
  sagas as orderListSagas
} from './orders/store'
import {
  reducer as postionListReducer,
  sagas as postionListSagas
} from './positions/store'

export interface IListsFeatureState {
  account: IListsState<IAccount>
  client: IListsState<IClient>
  position: IListsState<IPosition>
  order: IListsState<IOrder>
  household: IListsState<IHousehold>
  security: IListsState<ISecurity>
  duediligences: IListsState<ISecurity>
  performanceReport: IListsState<IPerformanceReport>
  thirdPartyProduct: IListsState<IThirdPartyProduct>
  activity: IActivityListState
}

export const reducer: Reducer<IListsFeatureState> = combineReducers({
  account: accountListReducer,
  client: clientListReducer,
  position: postionListReducer,
  order: orderListRecuder,
  household: householdListReducer,
  security: securityReducer,
  duediligences: dueDiligenceReducer,
  performanceReport: performanceReportReducer,
  thirdPartyProduct: thirdPartyProductReducer,
  activity: activityListReducer
})

export const sagas = [
  ...accountListSagas,
  ...clientListSagas,
  ...postionListSagas,
  ...orderListSagas,
  ...householdListSagas,
  ...securitySagas,
  ...dueDiligenceSagas,
  ...performanceReportSagas,
  ...thirdPartyProductSagas,
  ...activityListSagas
]
