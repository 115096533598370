import { flow } from 'lodash/fp'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import { call, delay, put, select, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { IDatahubHousehold } from '../../../api/households'
import { IOrderBy } from '../../../api/odata.types'
import {
  fetchHouseholdFromApi,
  fetchHouseholdSearchResultsFromApi
} from '../../../features/Households/store/sagas'
import { IOdataResult } from '../../../shared/contracts/IOdataResult'
import { AppState } from '../../../store'

const SEARCH_REQUEST = '@modules/@households/SEARCH_REQUEST'
const SEARCH_LOAD_MORE = '@modules/@households/SEARCH_LOAD_MORE'
const SEARCH_CHUNK = '@modules/@households/SEARCH_CHUNK'
const SEARCH_FAILURE = '@modules/@households/SEARCH_FAILURE'

interface IChunkPayload {
  page: number
  chunk: IOdataResult<IDatahubHousehold>
}

export const householdSearchActions = {
  request: createAction(SEARCH_REQUEST)(),
  loadMore: createAction(SEARCH_LOAD_MORE)(),
  chunk: createAction(SEARCH_CHUNK)<IChunkPayload>(),
  failure: createAction(SEARCH_FAILURE)<Error>()
}

const FETCH_REQUEST = '@modules/@households/FETCH_REQUEST'
const FETCH_SUCCESS = '@modules/@households/FETCH_SUCCESS'
const FETCH_FAILURE = '@modules/@households/FETCH_FAILURE'

export const householdFetchActions = {
  request: createAction(FETCH_REQUEST)<string>(),
  success: createAction(FETCH_SUCCESS)<IDatahubHousehold | undefined>(),
  failure: createAction(FETCH_FAILURE)<Error>()
}

const UPDATE_SEARCH_TEXT = '@modules/@households/UPDATE_SEARCH_TEXT'
const SORT = '@modules/@households/SORT'

export const householdsListUiActions = {
  updateSearchText: createAction(UPDATE_SEARCH_TEXT)<string | undefined>(),
  sort: createAction(SORT)<IOrderBy>()
}

export type HouseholdActionTypes =
  | ActionType<typeof householdSearchActions>
  | ActionType<typeof householdFetchActions>
  | ActionType<typeof householdsListUiActions>

export interface IHouseholdSearchState {
  chunks?: IOdataResult<IDatahubHousehold>[]
  loading?: boolean
  error?: Error
}

const initialSearchState: IHouseholdSearchState = {
  loading: false
}

export const householdSearchReducer = createReducer<
  IHouseholdSearchState,
  HouseholdActionTypes
>(initialSearchState)
  .handleAction(householdSearchActions.request, (state) => ({
    ...state,
    error: undefined,
    loading: true
  }))
  .handleAction(householdSearchActions.loadMore, (state) => ({
    ...state,
    error: undefined,
    loading: true
  }))
  .handleAction(householdSearchActions.chunk, (state, action) => {
    const { page, chunk } = action.payload
    const isFirstPage = page === 0
    const newChunks = isFirstPage ? [chunk] : [...(state.chunks || [])]
    if (!isFirstPage) {
      newChunks[page] = chunk
    }
    return {
      ...state,
      error: undefined,
      chunks: newChunks,
      loading: false
    }
  })
  .handleAction(householdSearchActions.failure, (state, action) => ({
    ...state,
    error: action.payload,
    chunks: undefined,
    loading: false
  }))

export interface IHouseholdFetchState {
  item?: IDatahubHousehold
  loading?: boolean
  error?: Error
}

const initialFetchState: IHouseholdFetchState = {
  loading: false
}

export const householdFetchReducer = createReducer<
  IHouseholdFetchState,
  HouseholdActionTypes
>(initialFetchState)
  .handleAction(householdFetchActions.request, () => ({
    ...initialFetchState,
    loading: true
  }))
  .handleAction(householdFetchActions.success, (state, action) => ({
    ...state,
    items: action.payload
  }))
  .handleAction(householdFetchActions.failure, (state, action) => ({
    ...state,
    error: action.payload
  }))

export const getHouseholdsState = (state: AppState) =>
  state.modules.households.householdsList
export const getHouseholdsSearchState = flow(
  getHouseholdsState,
  (x) => x.search
)
export const getIsHouseholdSearchLoading = flow(
  getHouseholdsSearchState,
  (x) => x.loading
)
export const getHouseholdsSearchError = flow(
  getHouseholdsSearchState,
  (x) => x.error
)

export const getHouseholdSearchChunks = flow(
  getHouseholdsSearchState,
  (x) => x.chunks
)
export const getHouseholdsSearchItems = createSelector(
  [getHouseholdSearchChunks],
  (chunks) => {
    return chunks?.flatMap((x) => x.value || [])
  }
)
export const getHouseholdsSearchCount = createSelector(
  [getHouseholdSearchChunks],
  (chunks) => {
    return chunks?.[0]?.['@odata.count'] as number | undefined
  }
)

export interface IHouseholdsListUiState {
  searchText?: string
  orderBy?: IOrderBy
}

const initialHouseholdsListUiState: IHouseholdsListUiState = {
  orderBy: {
    dataPath: 'updatedOn',
    direction: 'desc'
  }
}

export const changeRequestEditListUiReducer = createReducer<
  IHouseholdsListUiState,
  HouseholdActionTypes
>(initialHouseholdsListUiState)
  .handleAction(householdsListUiActions.updateSearchText, (state, action) => ({
    ...state,
    searchText: action.payload
  }))
  .handleAction(householdsListUiActions.sort, (state, action) => ({
    ...state,
    orderBy: action.payload
  }))

export const getHouseholdsListUiSearchText = flow(
  getHouseholdsState,
  (x) => x.ui.searchText
)

export const getHouseholdsListUiOrderBy = flow(
  getHouseholdsState,
  (x) => x.ui.orderBy
)

export const householdsListReducer = combineReducers({
  search: householdSearchReducer,
  detail: householdFetchReducer,
  ui: changeRequestEditListUiReducer
})

const pageSize = 50
const fetchHouseholdSearchResults = function* () {
  yield delay(300)
  const searchText = yield* select(getHouseholdsListUiSearchText)
  const orderBy = yield* select(getHouseholdsListUiOrderBy)
  try {
    const households: IOdataResult<IDatahubHousehold> = yield call(
      fetchHouseholdSearchResultsFromApi,
      {
        search: searchText,
        orderby: orderBy ? [orderBy] : [],
        top: pageSize
      }
    )

    yield put(householdSearchActions.chunk({ page: 0, chunk: households }))
  } catch (e: any) {
    yield put(householdSearchActions.failure(e))
  }
}

const loadMore = function* () {
  yield delay(300)
  const searchText = yield* select(getHouseholdsListUiSearchText)
  const orderBy = yield* select(getHouseholdsListUiOrderBy)
  const chunks = yield* select(getHouseholdSearchChunks)
  const totalChunks = chunks?.length || 0
  const max = chunks?.[0]?.['@odata.count'] || 0
  const skip = totalChunks * pageSize
  if (!totalChunks || max <= skip) {
    return
  }

  try {
    const households: IOdataResult<IDatahubHousehold> = yield call(
      fetchHouseholdSearchResultsFromApi,
      {
        search: searchText,
        orderby: orderBy ? [orderBy] : [],
        skip,
        top: pageSize
      }
    )

    yield put(
      householdSearchActions.chunk({ page: totalChunks, chunk: households })
    )
  } catch (e: any) {
    yield put(householdSearchActions.failure(e))
  }
}

const fetchHousehold = function* (
  action: ReturnType<typeof householdFetchActions.request>
) {
  yield delay(300)

  try {
    const household: IDatahubHousehold = yield call(
      fetchHouseholdFromApi,
      action.payload
    )

    yield put(householdFetchActions.success(household))
  } catch (e: any) {
    yield put(householdFetchActions.failure(e))
  }
}

export const householdsListSagas = [
  () =>
    takeLatest(
      [householdsListUiActions.updateSearchText, householdsListUiActions.sort],
      function* () {
        yield put(householdSearchActions.request())
      }
    ),
  () => takeLatest(householdSearchActions.request, fetchHouseholdSearchResults),
  () => takeLatest(householdSearchActions.loadMore, loadMore),
  () => takeLatest(householdFetchActions.request, fetchHousehold)
]
