import { format, setMonth } from 'date-fns/fp'
import { keyBy } from 'lodash'
import { flow } from 'lodash/fp'
import { combineReducers, Reducer } from 'redux'
import { createSelector } from 'reselect'
import { select, takeLatest, put } from 'typed-redux-saga'
import {
  ActionType,
  createAction,
  createReducer,
  StateType
} from 'typesafe-actions'
import { IDataListColumnDefinition } from '../../../../../features/DataList/contracts/IDataListColumnDefinition'
import { IDataListState } from '../../../../../features/DataList/contracts/IDataListState'
import { convertColumnTypeToFilterType } from '../../../../../features/DataList/service'
import { createDataListStore } from '../../../../../features/DataList/store'
import { IListsFilter } from '../../../../../features/Lists/core/contracts/IListsFilter'
import { AppState } from '../../../../../store'
import { IBdaYearlySummary } from '../common/types'
import {
  getBdaSummaryForAllDepartments,
  getMostRecentPayrollMonth
} from './bdaContainer'

export type BDADepartmentListColumnName =
  | 'Department'
  | 'Department Number'
  | 'Allowance'
  | 'YTD Spend'
  | 'YTD Spend Difference To Allowance'
  | 'Current Projection'
  | 'Current Projection Difference To Allowance'
  | 'Overage'
  | 'Last Month Operating Expenses'
  | 'Last Month Deductions'
  | 'Progress'
  | 'Office'
  | 'Division'

interface IBDADepartmentListColumnDefinition
  extends IDataListColumnDefinition<IBdaYearlySummary> {
  name: BDADepartmentListColumnName
  displayName?: string
}

const defaultColumn: Partial<IBDADepartmentListColumnDefinition> = {
  filterable: true,
  sortable: true
}

export const bdaDepartmentListColumns: IBDADepartmentListColumnDefinition[] = [
  {
    ...defaultColumn,
    name: 'Department Number',
    type: 'string',
    hidden: true,
    getValue: ({ department }) => department?.cdm_departmentnumber,
    width: 100
  },
  {
    ...defaultColumn,
    name: 'Department',
    type: 'string',
    getValue: ({ department }) => department?.cdm_name,
    width: 185
  },
  {
    ...defaultColumn,
    name: 'Allowance',
    type: 'number',
    width: 130,
    getValue: ({ allowance }) => allowance
  },
  {
    ...defaultColumn,
    name: 'YTD Spend',
    type: 'number',
    getValue: ({ ytdAllowanceTotal }) => ytdAllowanceTotal,
    width: 130
  },
  {
    ...defaultColumn,
    name: 'YTD Spend Difference To Allowance',
    type: 'number',
    width: 130,
    getValue: ({ ytdTotalDifferenceToAllowance }) =>
      ytdTotalDifferenceToAllowance
  },
  {
    ...defaultColumn,
    name: 'Current Projection',
    type: 'number',
    width: 130,
    getValue: ({ projection }) => projection
  },
  {
    ...defaultColumn,
    name: 'Current Projection Difference To Allowance',
    type: 'number',
    width: 130,
    getValue: ({ projectionDifferenceToAllowance }) =>
      projectionDifferenceToAllowance
  },
  {
    ...defaultColumn,
    name: 'Overage',
    type: 'number',
    width: 130,
    getValue: ({ overage }) => overage
  },
  {
    ...defaultColumn,
    name: 'Progress',
    type: 'number',
    width: 130,
    getValue: ({ allowanceProgress }) => allowanceProgress
  },
  {
    ...defaultColumn,
    name: 'Office',
    type: 'string',
    width: 130,
    facetable: true,
    hidden: true,
    getValue: ({ departmentAllowance }) =>
      (departmentAllowance?.owninguser || departmentAllowance?.owningteam)
        ?.businessunitid?.parentbusinessunitid?.name
  },
  {
    ...defaultColumn,
    name: 'Division',
    type: 'string',
    width: 130,
    facetable: true,
    hidden: true,
    getValue: ({ departmentAllowance }) =>
      (departmentAllowance?.owninguser || departmentAllowance?.owningteam)
        ?.businessunitid?.parentbusinessunitid?.parentbusinessunitid?.name
  }
]

const SEARCH = '@modules/@advisory/@modules/@bda/@bdaDepartmentsList/SEARCH'
const SHOW_ADMIN_COLUMNS =
  '@modules/@advisory/@modules/@bda/@bdaDepartmentsList/SHOW_ADMIN_COLUMNS'
export const bdaDepartmentListUiActions = {
  search: createAction(SEARCH)<string | undefined>(),
  showAdminColumns: createAction(SHOW_ADMIN_COLUMNS)()
}

export interface IBdaDepartmentListUiState {
  searchText?: string
}

const initialState: IBdaDepartmentListUiState = {}

const bdaDepartmentListUiReducer = createReducer<
  IBdaDepartmentListUiState,
  ActionType<typeof bdaDepartmentListUiActions>
>(initialState).handleAction(
  bdaDepartmentListUiActions.search,
  (state, action) => ({
    ...state,
    searchText: action.payload
  })
)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.bda.bdaDepartmentList

export const getBdaDepartmentListSearchText = flow(
  rootSelector,
  ({ ui }) => ui.searchText
)

export const getBdaSummaryItems = createSelector(
  [getBdaSummaryForAllDepartments, getBdaDepartmentListSearchText],
  (items, searchText) => {
    if (!items) {
      return []
    }

    const results = searchText
      ? items?.filter(({ department }) =>
          [department?.cdm_departmentnumber, department?.cdm_name].some(
            (x) => x && x.toLowerCase().indexOf(searchText?.toLowerCase()) >= 0
          )
        )
      : items

    return results
  }
)

const filters = keyBy(
  bdaDepartmentListColumns
    .filter((x) => x.filterable)
    .map(
      (column): IListsFilter => ({
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        hasValue: false
      })
    ),
  ({ id }) => id
)

const {
  actions: bdaDepartmentDataListActions,
  reducer: bdaDepartmentDataListReducer,
  sagas: bdaDepartmentDataListSagas,
  selectors: bdaDepartmentDataListSelectors
} = createDataListStore({
  initialState: {
    columns: bdaDepartmentListColumns,
    filters,
    sortBy: { name: 'Progress', direction: 'desc' }
  },
  itemsSelector: getBdaSummaryItems,
  prefix: '@modules/@advisory/@modules/@bda/@bdaDepartmentsList',
  rootSelector: flow(rootSelector, ({ list }) => list)
})

export { bdaDepartmentDataListActions, bdaDepartmentDataListSelectors }

export interface IBDAModuleState {
  ui: StateType<typeof bdaDepartmentListUiReducer>
  list: IDataListState<IBdaYearlySummary>
}

export const bdaDepartmentListReducer: Reducer<IBDAModuleState> =
  combineReducers({
    list: bdaDepartmentDataListReducer,
    ui: bdaDepartmentListUiReducer
  })

const constructOperatingExpenseColumn = (
  mostRecentPayrollMonth?: number
): IBDADepartmentListColumnDefinition => ({
  ...defaultColumn,
  name: 'Last Month Operating Expenses',
  displayName: `${
    mostRecentPayrollMonth != null
      ? flow(setMonth(mostRecentPayrollMonth), format('MMMM'))(new Date())
      : ''
  } Operating Expenses`,
  type: 'number',
  width: 130,
  getValue: (summary) => {
    return (
      summary?.months?.[mostRecentPayrollMonth ?? -1]?.operatingExpenses || 0
    )
  }
})

const constructDeductionsColumn = (
  mostRecentPayrollMonth?: number
): IBDADepartmentListColumnDefinition => ({
  ...defaultColumn,
  name: 'Last Month Deductions',
  displayName: `${
    mostRecentPayrollMonth != null
      ? flow(setMonth(mostRecentPayrollMonth), format('MMMM'))(new Date())
      : ''
  } Deductions`,
  type: 'number',
  width: 130,
  getValue: (summary) => {
    return summary?.months?.[mostRecentPayrollMonth ?? -1]?.newDeductions || 0
  }
})

const onShowAdminColumns = function* () {
  const columns = yield* select(bdaDepartmentDataListSelectors.getColumns)

  if (!columns?.length) {
    return
  }
  const mostRecentPayrollMonth = yield* select(getMostRecentPayrollMonth)

  const adminColumnsIndex = columns?.findIndex(
    ({ name }) => name === 'Last Month Operating Expenses'
  )

  const adminColumns = [
    constructOperatingExpenseColumn(mostRecentPayrollMonth),
    constructDeductionsColumn(mostRecentPayrollMonth)
  ]

  const columnsCopy = [...columns]

  adminColumnsIndex >= 0
    ? columnsCopy.splice(adminColumnsIndex, 2, ...adminColumns)
    : columnsCopy.splice(8, 0, ...adminColumns)

  yield put(bdaDepartmentDataListActions.updateColumns(columnsCopy))
}

export const bdaDepartmentListSagas = [
  ...bdaDepartmentDataListSagas,
  () =>
    takeLatest(bdaDepartmentListUiActions.showAdminColumns, onShowAdminColumns)
]
