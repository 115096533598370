import { format } from 'date-fns'
import { isEmpty } from 'lodash'
import { PaymentMM } from '../../Dashboard/modules/Dashboard/store/types'
import {
  DayOptions,
  FrequencyOptions,
  FundingOptions,
  MonthOptions,
  QuarterlyOptions,
  WeekendHolidayProcessOptions
} from '../PeriodicPayment/Constants'
import {
  IFrequencyInfo,
  IPeriodicPayment,
  IRecurringScheduledDay
} from '../store/PeriodicPayment/types'
import { IRecurringRequst } from '../store/types'

export const getMonths = (
  frequencyInfo: IFrequencyInfo
): number[] | undefined => {
  if (frequencyInfo.FrequencyType === 'W') {
    return undefined
  }
  if (frequencyInfo.FrequencyType === 'Q') {
    return frequencyInfo.SelectedQuarter?.split('/').map((i) => Number(i)) ?? []
  }
  return frequencyInfo.MonthOfYear
}

export const getSelectedQarterFromMonths = (
  selectedMonths: number[],
  FrequencyType: string
): string | undefined => {
  if (FrequencyType !== 'Q') {
    return undefined
  }
  if (selectedMonths.includes(1)) {
    return '1/4/7/10'
  }
  if (selectedMonths.includes(2)) {
    return '2/5/8/11'
  }
  if (selectedMonths.includes(3)) {
    return '3/6/9/12'
  }
  return undefined
}

export const getPeriodicPayment = (
  periodicPayment?: IPeriodicPayment
): IRecurringRequst => {
  const MonthOfYear =
    getMonths(periodicPayment?.frequencyInfo ?? {}) ?? undefined
  return {
    FrequencyType: periodicPayment?.frequencyInfo?.FrequencyType ?? undefined,
    RecurringReqId: periodicPayment?.RecurringReqId ?? undefined,
    HolidayHandling:
      periodicPayment?.frequencyInfo?.HolidayHandling ?? undefined,
    RecurringPlanTranId: periodicPayment?.RecurringPlanTranId ?? undefined,
    StartDate: periodicPayment?.dateInfo?.StartDate
      ? format(periodicPayment?.dateInfo?.StartDate, 'MM/dd/yyyy')
      : undefined,
    EndDate: periodicPayment?.dateInfo?.EndDate
      ? format(periodicPayment?.dateInfo?.EndDate, 'MM/dd/yyyy')
      : undefined,
    DayOfWeek: periodicPayment?.frequencyInfo?.DayOfWeek ?? undefined,
    DayOfMonth: periodicPayment?.frequencyInfo?.DayOfMonth ?? undefined,
    MonthOfYear: MonthOfYear ?? undefined,
    SkipCurrentDayPayment: periodicPayment?.SkipCurrentDayPayment ?? undefined
  }
}

export const getPeriodicPaymentDataForEdit = (paymentMM?: PaymentMM) => {
  const recurringReq = paymentMM?.apprDetail?.recurringReq
  const startDate = recurringReq?.startDate
  const endDate = recurringReq?.endDate
  const monthOfYear = getSelectedQarterFromMonths(
    recurringReq?.monthOfYear ?? [],
    recurringReq?.frequencyType ?? ''
  )
  const isStartDateValid = startDate && startDate.toString() !== '1900-1-1'
  const isEndDateValid =
    endDate &&
    (endDate.toString() === '9999-12-31' || endDate.toString() === '12/31/9999')

  const periodicPaymentData: IPeriodicPayment = {
    isRecurringPaymentPlan:
      paymentMM?.tranInfo?.isRecurringPaymentPlan ?? undefined,
    RecurringReqId:
      paymentMM?.apprDetail?.recurringReq?.recurringReqId ?? undefined,
    RecurringReqName:
      paymentMM?.apprDetail?.recurringReq?.recurringReqName ?? undefined,
    RecurringPlanTranId:
      paymentMM?.apprDetail?.recurringReq?.recurringPlanTranId ?? undefined,
    frequencyInfo: {
      DayOfMonth: paymentMM?.apprDetail?.recurringReq?.dayOfMonth ?? undefined,
      DayOfWeek: paymentMM?.apprDetail?.recurringReq?.dayOfWeek ?? undefined,
      FrequencyType:
        paymentMM?.apprDetail?.recurringReq?.frequencyType ?? undefined,
      HolidayHandling:
        paymentMM?.apprDetail?.recurringReq?.holidayHandling ?? undefined,
      MonthOfYear:
        paymentMM?.apprDetail?.recurringReq?.monthOfYear ?? undefined,
      SelectedQuarter: monthOfYear
    },
    SkipCurrentDayPayment:
      paymentMM?.apprDetail?.recurringReq?.skipCurrentDayPayment,
    dateInfo: {
      StartDate: isStartDateValid ? new Date(startDate) : undefined,
      EndDate: isEndDateValid ? undefined : new Date(endDate || ''),
      noEndDate: !!isEndDateValid
    },
    hasRecurringPaymentToday: paymentMM?.hasRecurringPaymentToday
  }
  return periodicPaymentData
}

export function getOptions<T extends object>(options: T) {
  return Object.keys(options).map((optionKey) => {
    const columnkey = optionKey as keyof T
    return {
      key: columnkey,
      text: options[columnkey]
    }
  })
}

export const getFreqencyType = (type: string) => {
  if (isEmpty(type)) {
    return '--'
  }
  const value = FrequencyOptions[type as keyof typeof FrequencyOptions]
  return value
}

export const getQuarterlyOptions = (option: string) => {
  if (isEmpty(option)) {
    return '--'
  }

  const value = QuarterlyOptions[option as keyof typeof QuarterlyOptions]

  return value
}

export const getDayOptions = (day: number[]) => {
  if (!day.length) {
    return '--'
  }
  const sortedDay = day.slice().sort((a, b) => Number(a) - Number(b))
  const value = sortedDay.map((x) => DayOptions[x as keyof typeof DayOptions])
  return value.filter((x) => x !== undefined).join(',')
}

export const getMonthOptions = (month: number[]) => {
  if (!month.length) {
    return '--'
  }
  const sortedMonth = month.slice().sort((a, b) => Number(a) - Number(b))
  const value = sortedMonth.map(
    (x) => MonthOptions[x as keyof typeof MonthOptions]
  )

  return value.join(', ')
}

export const getFundingOptions = (option?: any) => {
  if (!option) {
    return '--'
  }
  const value = FundingOptions[option as keyof typeof FundingOptions]
  return value
}

export const getHolidayHandling = (option?: any) => {
  if (!option) {
    return '--'
  }
  const value =
    WeekendHolidayProcessOptions[
      option as keyof typeof WeekendHolidayProcessOptions
    ]
  return value
}

export const isScheduledForToday = (
  scheduledDays: IRecurringScheduledDay[] | undefined
) => {
  const today = new Date()
  if (scheduledDays) {
    const hasTransactionToday = (scheduledDays ?? []).some(
      (x: IRecurringScheduledDay) =>
        x?.dateScheduled &&
        new Date(x?.dateScheduled).toISOString().split('T')[0] ===
          today.toISOString().split('T')[0]
    )
    return hasTransactionToday ? 'Y' : 'N'
  }
  return undefined
}
