import { ActionType, createAction } from 'typesafe-actions'
import { IPeriodicPayment, IRecurringScheduledDay } from './types'

export const GET_RECURRING_SCHEDULED_DAYS_REQUEST = `@modules/@transfers/@moneymovement/@periodicpayment/GET_RECURRING_SCHEDULED_DAYS_REQUEST`
export const GET_RECURRING_SCHEDULED_DAYS_SUCCESS = `@modules/@transfers/@moneymovement/@periodicpayment/GET_RECURRING_SCHEDULED_DAYS_SUCCESS`
export const GET_RECURRING_SCHEDULED_DAYS_FAILURE = `@modules/@transfers/@moneymovement/@periodicpayment/GET_RECURRING_SCHEDULED_DAYS_FAILURE`
export const GET_RECURRING_SCHEDULED_DAYS_CLEAR = `@modules/@transfers/@moneymovement/@periodicpayment/GET_RECURRING_SCHEDULED_DAYS_CLEAR`
export const SAVE_RECURRING_SCHEDULED_DAYS_REQUEST = `@modules/@transfers/@moneymovement/@periodicpayment/SAVE_RECURRING_SCHEDULED_DAYS_REQUEST`

export const getRecurringScheduledDaysActions = {
  request: createAction(GET_RECURRING_SCHEDULED_DAYS_REQUEST)<{
    request: IPeriodicPayment
    preview: string
  }>(),
  success: createAction(GET_RECURRING_SCHEDULED_DAYS_SUCCESS)<
    IRecurringScheduledDay[]
  >(),
  failure: createAction(GET_RECURRING_SCHEDULED_DAYS_FAILURE)<Error>(),
  clear: createAction(GET_RECURRING_SCHEDULED_DAYS_CLEAR)(),
  save: createAction(SAVE_RECURRING_SCHEDULED_DAYS_REQUEST)<{
    scheduledDays: IRecurringScheduledDay[]
    refetchScheduleData?: { request: IPeriodicPayment; preview: string }
  }>()
}

// export const SAVE_RECURRING_SCHEDULED_DAYS_REQUEST = `@modules/@transfers/@moneymovement/@periodicpayment/SAVE_RECURRING_SCHEDULED_DAYS_REQUEST`
// export const SAVE_RECURRING_SCHEDULED_DAYS_SUCCESS = `@modules/@transfers/@moneymovement/@periodicpayment/SAVE_RECURRING_SCHEDULED_DAYS_SUCCESS`
// export const SAVE_RECURRING_SCHEDULED_DAYS_FAILURE = `@modules/@transfers/@moneymovement/@periodicpayment/SAVE_RECURRING_SCHEDULED_DAYS_FAILURE`
// export const SAVE_RECURRING_SCHEDULED_DAYS_CLEAR = `@modules/@transfers/@moneymovement/@periodicpayment/SAVE_RECURRING_SCHEDULED_DAYS_CLEAR`

// export const saveRecurringScheduledDaysActions = {
//   request: createAction(SAVE_RECURRING_SCHEDULED_DAYS_REQUEST)<{
//     request: IPeriodicPayment
//     preview: string
//   }>(),
//   success: createAction(SAVE_RECURRING_SCHEDULED_DAYS_SUCCESS)<
//     IRecurringScheduledDay[]
//   >(),
//   failure: createAction(SAVE_RECURRING_SCHEDULED_DAYS_FAILURE)<Error>(),
//   clear: createAction(SAVE_RECURRING_SCHEDULED_DAYS_CLEAR)()
// }

export type PeriodicPaymentActions = ActionType<
  typeof getRecurringScheduledDaysActions
  // | typeof saveRecurringScheduledDaysActions
>
