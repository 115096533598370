import { clientOnlineApi } from 'store/api/clientOnline'
import { apiConstants } from '../apis'
import { IProfileApiContext } from './IProfileApiContext'

const { cacheTime } = apiConstants

type ProfileApiTagType = 'profileApi'
const profileApiTags: ProfileApiTagType[] = ['profileApi']
const profileApiWithTags = clientOnlineApi.enhanceEndpoints({
  addTagTypes: profileApiTags
})

export const profileApi = profileApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    setContext: builder.mutation<
      unknown,
      { key: string; context: IProfileApiContext }
    >({
      query: ({ key, context }) => ({
        url: `profileapi/odata/context?key=${key}`,
        method: 'POST',
        data: [context]
      }),
      invalidatesTags: (_, _1, { key }) => [{ type: 'profileApi', id: key }]
    }),
    getContext: builder.query<IProfileApiContext, { key: string }>({
      query: ({ key }) => {
        return {
          url: `profileapi/odata/context('${key}')`,
          headers: { contextjson: key, profilejson: key }
        }
      },
      providesTags: (_, _1, { key }) => [{ type: 'profileApi', id: key }],
      keepUnusedDataFor: cacheTime
    }),
    invalidateCache: builder.mutation<void, { key: string }>({
      query: ({ key }) => {
        return {
          url: `profileapi/odata/InvalidateCache`,
          headers: { contextjson: key, profilejson: key }
        }
      },
      invalidatesTags: (_, _1, { key }) => [{ type: 'profileApi', id: key }]
    })
  })
})

export const { useSetContextMutation, useInvalidateCacheMutation } = profileApi
