import {
  Checkbox,
  DefaultButton,
  FontWeights,
  IButtonStyles,
  IIconProps,
  IconButton,
  MessageBar,
  MessageBarType,
  Modal,
  PrimaryButton,
  Stack,
  TextField,
  getTheme,
  mergeStyleSets
} from '@fluentui/react'
import { IListMetaPreferenceDetails } from 'features/Lists/hooks/useListPreferences/IListPreferences'
import { useCallback, useState } from 'react'
import { stringTrim } from 'shared'

const theme = getTheme()
const contentStyles = mergeStyleSets({
  container: {
    width: '420px',
    border: '2px solid lightgray',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch'
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px'
    }
  ],
  heading: {
    color: theme.palette.neutralPrimary,
    fontWeight: FontWeights.semibold,
    fontSize: 'inherit',
    margin: '0'
  },
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 }
    }
  },
  label: {
    whiteSpace: 'nowrap'
  },
  input: {
    paddingBottom: '10px'
  },
  error: {
    marginBottom: '10px'
  }
})

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
  },
  rootHovered: {
    color: theme.palette.neutralDark
  }
}

const cancelIcon: IIconProps = { iconName: 'Cancel' }

interface IModalSaveViewProps {
  item: IListMetaPreferenceDetails
  onClose: () => void
  onSubmit: (item: IListMetaPreferenceDetails) => void
}

export const ModalSaveView: React.FC<IModalSaveViewProps> = ({
  item,
  onClose,
  onSubmit
}) => {
  const [disableSaveButton, setDisableSaveButton] = useState(true)
  const [newName, setNewName] = useState('')
  const [showError, setShowError] = useState('')
  const [isDefault, setIsDefault] = useState(false)

  const onSave = useCallback(() => {
    const name = stringTrim(newName)
    if (name.length < 3 || name.length > 250) {
      setShowError('Name must be between 3 and 250 characters')
      return
    }
    setDisableSaveButton(true)
    setNewName('')
    onClose()
    onSubmit({ ...item, label: name, isDefault })
  }, [newName, isDefault, item, onSubmit, onClose])

  const onNameChange = (ev: any, text?: string) => {
    if (text && (text.length < 3 || text.length > 250)) {
      setDisableSaveButton(true)
      if (text.length > 250) {
        setShowError('Name must be between 3 and 250 characters')
      }
      return
    }
    if (text === '') {
      setDisableSaveButton(true)
      return
    }
    setDisableSaveButton(false)
    setShowError('')
    setNewName(text || '')
  }

  const hideError = () => {
    setShowError('')
  }

  return (
    <Modal
      isOpen={!!item}
      isBlocking={false}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <h2 className={contentStyles.heading}>Save View</h2>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          onClick={() => {
            onClose()
            setDisableSaveButton(true)
          }}
        />
      </div>
      <div className={contentStyles.body}>
        <TextField
          placeholder="Enter name of view"
          className={contentStyles.input}
          onChange={onNameChange}
          autoFocus={true}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSave()
            }
            if (e.key === 'Escape') {
              onClose()
            }
          }}
        />
        {showError && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
            onDismiss={hideError}
            dismissButtonAriaLabel="Close"
            className={contentStyles.error}
          >
            {showError}
          </MessageBar>
        )}
        <Stack
          horizontal={true}
          verticalAlign="center"
          tokens={{ childrenGap: 20 }}
        >
          <DefaultButton text="Cancel" onClick={onClose} />
          <PrimaryButton
            text="Save View"
            onClick={() => onSave()}
            disabled={disableSaveButton}
            allowDisabledFocus
          />
          <Checkbox
            label="Set as default"
            checked={isDefault}
            onChange={() => setIsDefault(!isDefault)}
          />
        </Stack>
      </div>
    </Modal>
  )
}
