import { combineReducers } from 'redux'
import {
  AIRevenueSummaryFetchReducer,
  AIRevenueSummaryFetchSagas
} from './AIRevenueSummaryFetch'
import {
  AIRevenueSummaryTableExportReducer,
  AIRevenueSummaryTableExportSagas
} from './AIRevenueSummaryTableExport'

export const revenueSummaryTableFeatureReducer = combineReducers({
  revenueSummaryFetch: AIRevenueSummaryFetchReducer,
  revenueSummaryExport: AIRevenueSummaryTableExportReducer
})

export const revenueSummaryTableFeatureSagas = [
  ...AIRevenueSummaryFetchSagas,
  ...AIRevenueSummaryTableExportSagas
]
