import { ISecurity } from 'api/security.types'
import { createListsStore } from 'features/Lists/core/store'
import {
  createDefaultColumnState,
  mapColumnsToFilterDefinitions
} from 'features/Lists/shared/lists'
import { partial } from 'lodash/fp'
import { combineReducers } from 'redux'
import { AppState } from 'store'
import { DueDiligenceColumnDefinitions } from '../components/ColumnDefinitions'
import { dueDiligencesReducer } from './reducer'
import { dueDiligenceSagas } from './sagas'

export const constructInitialSecuritiesColumnState = partial(
  createDefaultColumnState,
  [DueDiligenceColumnDefinitions]
)

export const dueDiligenceListStore = createListsStore<ISecurity>(
  'security',
  '@features/@lists/@duediligences',
  {
    columnDefinitions: DueDiligenceColumnDefinitions,
    filterDefinitions: mapColumnsToFilterDefinitions(
      DueDiligenceColumnDefinitions
    )
  },
  (state: AppState) => state.features.lists.duediligences
)

export const { reducer, sagas, actions, selectors } = dueDiligenceListStore

export const dueDiligenceReducer = combineReducers({
  dueDiligencesFetch: dueDiligencesReducer
})

export const dueDiligencesSagas = [...dueDiligenceSagas]
