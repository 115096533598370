import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { useCallback, useMemo } from 'react'
import {
  useLazyCombinedRetrieveDocumentQuery,
  useLazyIndividualRetrieveDocumentQuery,
  useLazyRetrieveDocumentQuery,
  useRetrieveDocumentListQuery
} from '../documentsApi'
import { IRetrieveDocumentListRequest } from '../documentsApi/IRetrieveDocumentListRequest'
import { IDocumentApiRetrieveDocumentRequest } from '../documentsApi/types'
import { useRdot360SelectedAccountsApiContext } from './apiContext'
import { getFixedResult } from './shared'

const useDocumentsApiSelectedAccountsBaseRequest = (
  showAllAccounts: boolean
) => {
  const { apiContextAccounts, contextId, allAccountKeys } =
    useRdot360SelectedAccountsApiContext()

  return allAccountKeys?.length
    ? {
        accounts: showAllAccounts ? allAccountKeys : apiContextAccounts,
        contextId
      }
    : false
}

export const useRetrieveDocumentListQueryForSelectedAccounts = (
  request:
    | Omit<IRetrieveDocumentListRequest, 'PrimaryType' | 'PrimaryValue'>
    | false,
  showAllAccounts: boolean
) => {
  const base = useDocumentsApiSelectedAccountsBaseRequest(showAllAccounts)
  const apiRequest = useMemo(
    () =>
      !!base &&
      !!request && {
        ...base,
        payload: {
          ...request,
          PrimaryType: 'Account',
          PrimaryValue: base.accounts,
          ShowAllAccounts: showAllAccounts ? true : undefined
        }
      },
    [base, request, showAllAccounts]
  )
  const result = useRetrieveDocumentListQuery(
    apiRequest ? apiRequest : skipToken
  )

  const fixedResult = useMemo(
    () => getFixedResult(apiRequest, result),
    [apiRequest, result]
  )
  return fixedResult
}

export const useLazyRetrieveDocumentQueryForSelectedAccounts = () => {
  const [triggerRetrieveDocument] = useLazyRetrieveDocumentQuery()
  const { apiContextAccounts, contextId } =
    useRdot360SelectedAccountsApiContext()

  const trigger = useCallback(
    (payload: IDocumentApiRetrieveDocumentRequest) => {
      const result = triggerRetrieveDocument(
        {
          accounts: apiContextAccounts,
          contextId,
          payload
        },
        false
      )
      result.unsubscribe()
      return result
    },
    [apiContextAccounts, contextId, triggerRetrieveDocument]
  )

  return [trigger]
}

export const useLazyRetrieveCombinedDocumentQueryForSelectedAccounts = () => {
  const [triggerRetrieveCombinedDocument] =
    useLazyCombinedRetrieveDocumentQuery()
  const { apiContextAccounts, contextId } =
    useRdot360SelectedAccountsApiContext()

  const trigger = useCallback(
    async (payload: IDocumentApiRetrieveDocumentRequest) => {
      const result = triggerRetrieveCombinedDocument(
        {
          accounts: apiContextAccounts,
          contextId,
          payload
        },
        false
      )

      result.unsubscribe()
      return result
    },
    [apiContextAccounts, contextId, triggerRetrieveCombinedDocument]
  )

  return [trigger]
}

export const useLazyRetrieveIndividualDocumentQueryForSelectedAccounts = () => {
  const [triggerRetrieveIndividualDocument] =
    useLazyIndividualRetrieveDocumentQuery()
  const { apiContextAccounts, contextId } =
    useRdot360SelectedAccountsApiContext()

  const trigger = useCallback(
    async (payload: IDocumentApiRetrieveDocumentRequest) => {
      const result = triggerRetrieveIndividualDocument(
        {
          accounts: apiContextAccounts,
          contextId,
          payload
        },
        false
      )

      result.unsubscribe()
      return result
    },
    [apiContextAccounts, contextId, triggerRetrieveIndividualDocument]
  )

  return [trigger]
}
