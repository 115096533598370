import axios from 'axios'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import {
  AppState,
  createAsyncReducer,
  createAsyncSelectors,
  getRockefellerApiOptions
} from 'store/shared'
import { call, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'

export interface IGetAIRevenueSummaryRequest {
  feeTypeCode?: string
  AgingMonth?: string
  repCodes?: string
}

export interface IAIRevenue {
  aIRevenueColumnNames?: IAIRevenueColumnNames[]
  aIRevenueData?: any[]
  aIRevenueTypeByPlanid?: IAIRevenueTypeByPlanid[]
}
export interface IAIRevenueColumnNames {
  colOrder?: number
  columnName?: string
}

export interface IAIRevenueTypeByPlanid {
  planid?: number
  columnName?: string
  revenueType?: string
}

export interface IAIRevenueResp {
  success?: boolean
  responseMessage?: string
  data?: IAIRevenue[]
}

export const AIRevenueSummaryFetchActions = createAsyncAction(
  '@modules/@airevenue/@modules/@dashboard/@features/@airevenueSummaryTable/REQUEST',
  '@modules/@airevenue/@modules/@dashboard/@features/@airevenueSummaryTable/SUCCESS',
  '@modules/@airevenue/@modules/@dashboard/@features/@airevenueSummaryTable/FAILURE'
)<IGetAIRevenueSummaryRequest, IAIRevenue | undefined, Error>()

export const AIRevenueSummaryFetchReducer = createAsyncReducer(
  AIRevenueSummaryFetchActions
)

const rootSelector = (state: AppState) =>
  state.modules.airevenue.modules.dashboard.features.revenueSummaryTable
    .revenueSummaryFetch

export const {
  getError: getAIRevenueSummaryFetchError,
  getIsLoading: getIsAIRevenueSummaryFetchLoading,
  getResult: getAIRevenueSummaryFetchResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof AIRevenueSummaryFetchActions.request>
) {
  if (!action?.payload?.repCodes?.length) {
    yield put(AIRevenueSummaryFetchActions.success(undefined))
    return
  }
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)

  try {
    const result = yield* call(GetAIRevenues, action.payload, apiOptions)

    if (!result) {
      throw new Error('No result returned from cdm api')
    }
    yield put(AIRevenueSummaryFetchActions.success(result.data?.[0]))
  } catch (e) {
    yield put(AIRevenueSummaryFetchActions.failure(e as Error))
  }
}

export const GetAIRevenues = (
  request: IGetAIRevenueSummaryRequest,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/AIRevenue/GetAIRevenues`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IAIRevenueResp | undefined>(url, request, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      throw e
    })
}

export const AIRevenueSummaryFetchSagas = [
  () => takeLatest(AIRevenueSummaryFetchActions.request, onRequest)
]
