import { DropdownMenuItemType, IDropdownOption } from '@fluentui/react'
import { INfsProfile } from 'api/datahub'
import { partition, uniqBy } from 'lodash'
import { IconType } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'

export const getRelatedPartyDropdownOptions = (
  data: INfsProfile[] = [],
  getMenuItem: (
    id?: string,
    name?: string,
    iconType?: IconType
  ) => IDropdownOption
): IDropdownOption[] => {
  if (!data.length) {
    return []
  }
  // filter out falsy loginId or partyName
  const dataFiltered = data.filter((x) => x.loginid && x.fullname)
  // remove duplicate loginId
  const dataUniq = uniqBy(dataFiltered, (x) => x.loginid)
  // split data into two groups: Clients and 3rd parties
  const [clients, thirdPartyClients] = partition(
    dataUniq,
    (x) => x.role === 'Client'
  )

  const clientsOptions = [
    {
      key: 'other',
      text: clients.length ? 'Clients' : 'No Clients',
      itemType: DropdownMenuItemType.Header
    },
    // DEBUG: random icon type can be used for testing
    // DEBUG: ........... getMenuItem(x.loginId, x.partyName, getRandomIconType())),
    ...clients.map((x) => getMenuItem(x.loginid, x.fullname, 'Profile')),
    { key: 'divider_2', text: '-', itemType: DropdownMenuItemType.Divider }
  ]
  const thirdPartyClientsOptions = [
    {
      key: 'interested',
      text: thirdPartyClients.length
        ? 'Interested 3rd parties'
        : 'No Interested 3rd parties',
      itemType: DropdownMenuItemType.Header
    },
    ...thirdPartyClients.map((x) =>
      getMenuItem(x.loginid, x.fullname, 'InterestedParties')
    )
  ]
  const options: IDropdownOption[] = [
    ...clientsOptions,
    ...thirdPartyClientsOptions
  ]

  return options
}
