import { IClient } from '../../../api/client.types'
import { numberFormat, amountFormat } from './Constants'

export function _copyAndSort<T>(
  items: T[],
  columnKey: string,
  isSortedDescending?: boolean
): T[] {
  const key = columnKey as keyof T
  return items
    .slice(0)
    .sort((a: T, b: T) =>
      (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
    )
}

export const isValidLegalEntityId = (legalId: string) => {
  let isValid = true
  const regexp = new RegExp(numberFormat)
  isValid = regexp.test(legalId)
  return isValid
}

export const formattedClientNameEntity = (client: IClient) => {
  return client?.LegalEntityID && isValidLegalEntityId(client?.LegalEntityID)
    ? `${client?.LegalEntityName} (${client?.LegalEntityID})`
    : client?.LegalEntityName
    ? `${client?.LegalEntityName}`
    : ''
}

export const isAmountFormat = (value: string) => {
  let isValid = false
  if (value) {
    const regexp = new RegExp(amountFormat)
    isValid = regexp.test(value)
  }

  return isValid
}

export const numberToCurrency = (value: number, currency?: string): string => {
  if (value) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency || 'USD'
    })

    return formatter.format(value)
  } else {
    return '0'
  }
}
