import { amountFormatter } from 'modules/Advisory/modules/Rdot360/components/shared/Formatters'
import { getTaxonomyColor } from 'modules/Advisory/modules/Rdot360/shared/taxonomy'
import { ICategoryPositionResponseValue } from 'modules/Advisory/modules/Rdot360/store/holdingsApi/ICategoryPositionResponse'
import { FC } from 'react'
import { FormattedNumber } from 'react-intl'

const InvestmentsChartLegendDetails: FC<{
  data: ICategoryPositionResponseValue
  selectedType: string
}> = ({ data, selectedType }) => {
  const category =
    data?.assetcat === 'Cash' ? 'Cash & Equivalents' : data?.assetcat
  return (
    <div
      css={{
        flexBasis: 0,
        maxWidth: '300px',
        minWidth: '180px',
        pageBreakInside: 'avoid'
      }}
    >
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <div
          css={{
            maxWidth: '10px',
            minWidth: '10px',
            width: '10px',
            height: '10px',
            backgroundColor: `#${getTaxonomyColor(
              selectedType,
              data?.assetcat || 'Others'
            )}`
          }}
        />
        <div
          css={{
            minWidth: 0,
            marginLeft: '10px',
            fontSize: '14px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: '2'
          }}
          title={category}
        >
          {category}
        </div>
      </div>
      <div
        css={{
          display: 'flex',
          marginTop: '5px',
          marginLeft: '20px',
          gap: '5px'
        }}
      >
        <div css={{ fontWeight: 'bold', flexGrow: 1 }}>
          {amountFormatter(data?.mktval || 0)}
        </div>
        <div
          css={{
            minWidth: '30px'
          }}
        >
          <FormattedNumber
            value={data?.mktvalpct || 0}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
          />
          %
        </div>
      </div>
    </div>
  )
}

export default InvestmentsChartLegendDetails
