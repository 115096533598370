import { ELIGIBLE_ACCOUNT_TYPES } from 'features/MarginRateAdjustment/constants'
import { call, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { IAccount } from '../../../../../../../../../api/account.types'
import { OdataFilterOperatorEnum } from '../../../../../../../../../api/odata'
import { AppState } from '../../../../../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../../../store/shared/asyncStore'
import { search } from '../../../../../../../../../store/shared/sagas'

export const marginAccountFetchActions = createAsyncAction(
  '@modules/@advisory/@modules/@marginRateRequest/@marginRateRequestViewPanel/@householdAccountsFetch/REQUEST',
  '@modules/@advisory/@modules/@marginRateRequest/@marginRateRequestViewPanel/@householdAccountsFetch/SUCCESS',
  '@modules/@advisory/@modules/@marginRateRequest/@marginRateRequestViewPanel/@householdAccountsFetch/FAILURE'
)<string | undefined, IAccount[] | undefined, Error>()

export const householdAccountsFetchReducer = createAsyncReducer(
  marginAccountFetchActions
)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.accounts.modules.marginRateRequests.features
    .householdAccountsFetch

export const {
  getError: getAccountsError,
  getIsLoading: getIsAccountsLoading,
  getResult: getAccountsResult
} = createAsyncSelectors(rootSelector)

const fetchAccounts = function* (
  action: ReturnType<typeof marginAccountFetchActions.request>
) {
  if (!action.payload) {
    yield put(marginAccountFetchActions.success([]))
    return
  }
  try {
    const result = yield* call(search, 'account' as const, {
      orderBy: [
        { dataPath: 'AccountKPIs/AccountTotal', direction: 'desc' as const }
      ],
      filters: [
        {
          and: [
            {
              operator: OdataFilterOperatorEnum.searchin,
              value: [action.payload],
              path: 'householdId',
              type: 'string' as const
            },

            {
              operator: OdataFilterOperatorEnum.ne,
              value: 'Closed',
              path: 'accountStatus',
              type: 'string' as const
            }
          ]
        }
      ]
    })
    let accounts = result?.value as IAccount[]
    accounts = accounts.filter((account) =>
      ELIGIBLE_ACCOUNT_TYPES.includes(account.registrationtype || '')
    )

    if (!accounts) {
      throw new Error('Accounts for household not found')
    }

    yield put(marginAccountFetchActions.success(accounts as IAccount[]))
  } catch (e: any) {
    yield put(marginAccountFetchActions.failure(e))
  }
}

export const householdAccountsFetchSagas = [
  () => takeLatest(marginAccountFetchActions.request, fetchAccounts)
]
