import { Pivot, PivotItem } from '@fluentui/react'
import { HeaderContainer } from 'modules/Advisory/features/Header'
import { useCallback } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { useRouteMatch } from 'shared/hooks/useRouteMatch'
import { BoardMemberAccountContainer } from './modules/BoardMemberAccounts/BoardMemberAccountContainer'
import { NextGenCouncilDashboard } from './modules/NextGenCouncil'

export const HomeOfficeContainer: React.FC = () => {
  const navigate = useNavigate()
  const { firstSubSegment } = useRouteMatch()

  const onPivotChange = useCallback(
    (item?: PivotItem) => {
      if (!item?.props?.itemKey) {
        return
      }

      navigate(item.props.itemKey as string)
    },
    [navigate]
  )
  return (
    <>
      <HeaderContainer title="HOME OFFICE" />
      <div style={{ padding: '20px' }}>
        <div style={{ marginBottom: '15px' }}>
          <Pivot
            headersOnly={true}
            selectedKey={firstSubSegment}
            onLinkClick={onPivotChange}
          >
            <PivotItem headerText="Board Members" itemKey="boardmembers" />
            <PivotItem headerText="Next Gen Council" itemKey="nextgen" />
          </Pivot>
        </div>
        <Routes>
          <Route path={'/'} element={<Navigate to="nextgen" replace />} />
          <Route path={`/nextgen`} element={<NextGenCouncilDashboard />} />
          <Route
            path={`/boardmembers`}
            element={<BoardMemberAccountContainer />}
          />
        </Routes>
      </div>
    </>
  )
}

export default HomeOfficeContainer
