import { CommandBar, ICommandBarItemProps } from '@fluentui/react'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { SearchResponseType } from '../../../../api/common.types'
import { IListsActions } from '../store/actions'
import { IListsSelectors } from '../store/selectors'
import { createConnectedListsDataTableColumnEditorPanelComponent } from './ListsDataTableColumnEditorPanel'

export const createConnectedListsCommandBar = <T extends SearchResponseType>(
  listActions: IListsActions<T>,
  listSelectors: IListsSelectors<T>
) => {
  const { exportActions } = listActions
  const { exportSelectors, dataSelectors } = listSelectors
  const ListsDataTableColumnEditorPanel =
    createConnectedListsDataTableColumnEditorPanelComponent(
      listActions,
      listSelectors
    )

  const ListsCommandBar: React.FC<{
    firstItems?: ICommandBarItemProps[]
    lastItems?: ICommandBarItemProps[]
  }> = ({ firstItems, lastItems }) => {
    const dispatch = useDispatch()
    const [isColumnPanelOpen, setIsColumnPanelOpen] = useState(false)
    const toggleColumnPanel = useCallback(() => {
      setIsColumnPanelOpen(!isColumnPanelOpen)
    }, [isColumnPanelOpen])
    const exporting = useSelector(exportSelectors.getIsExportLoading)
    const count = useSelector(dataSelectors.getTotalCount)

    const commandBarItems = useMemo(() => {
      const items: ICommandBarItemProps[] = [
        ...(firstItems || []),
        {
          key: 'columns',
          text: 'Edit Columns',
          iconProps: { iconName: 'DoubleColumnEdit' },
          onClick: () => toggleColumnPanel()
        },
        {
          key: 'export',
          text: exporting ? 'Exporting' : 'Export to Excel',
          iconProps: { iconName: exporting ? 'Download' : 'ExcelLogo' },
          disabled: exporting || count > 100000 || count <= 0,
          onClick: () => {
            dispatch(exportActions.request())
          }
        },
        ...(lastItems || [])
      ].map((item) => ({ ...item, cacheKey: uuidv4() }))
      return items
    }, [firstItems, exporting, count, lastItems, toggleColumnPanel, dispatch])

    return (
      <div style={{ minHeight: 44 }}>
        <CommandBar
          items={commandBarItems}
          styles={{
            root: {
              paddingLeft: 0
            }
          }}
        />
        <ListsDataTableColumnEditorPanel
          isOpen={isColumnPanelOpen}
          onDismiss={toggleColumnPanel}
        />
      </div>
    )
  }

  return ListsCommandBar
}
