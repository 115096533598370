import {
  CommandBar,
  DirectionalHint,
  ICommandBarItemProps,
  ITheme,
  Link,
  Stack,
  Text,
  TooltipDelay,
  TooltipHost
} from '@fluentui/react'
// eslint-disable-next-line import/no-duplicates
import { formatDistance } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns/fp'
import { flow } from 'lodash/fp'
import { constants } from 'modules/Advisory/shared/theme'
import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
  AccountLinkingRequestStatusEnum,
  IAccountLinkingRequest
} from '../../../../../../../../api/dynamics'
import { accountLinkingRequestViewPanelActions } from '../../../../../../../../features/AccountLinking/features/AccountLinkingRequestView/store/accountLinkingRequestViewPanel'
import {
  accountLinkingPanelActions,
  fidelityCharitable
} from '../../../../../../../../features/AccountLinking/store/accountLinkingPanel'
import { TextCell } from '../../../../../../../../features/Lists/core/components/TextCell'
import { IListsSearchFilter } from '../../../../../../../../features/Lists/core/contracts/IListsFilter'
import {
  IOdataDataListCellRenderProps,
  OdataList
} from '../../../../../../../../features/OdataList/containers/OdataList'
import { parseDateISOStringInLocalTimezone } from '../../../../../../../../shared'
import { ConnectedMasked } from '../../../../../../../../shared/components/MaskedText'
import { useClasses } from '../../../../../../../../shared/hooks/useClasses'

import {
  AccountLinkingRequestListColumnName,
  accountLinkingRequestListUiActions,
  accountLinkingRequestListUiSelectors
} from './store'

const ClientNameCell: React.FC<{ request: IAccountLinkingRequest }> = ({
  request
}) => {
  const dispatch = useDispatch()
  const onLinkClick = useCallback(() => {
    if (!request.rcm_accountlinkingrequestid) {
      return
    }
    dispatch(
      accountLinkingRequestViewPanelActions.open(
        request.rcm_accountlinkingrequestid
      )
    )
  }, [dispatch, request])

  return (
    <Link title="Client Info" onClick={onLinkClick}>
      {request.rcm_newclient}
    </Link>
  )
}

export const DateCell: React.FC<{ value?: string; showTime?: boolean }> = ({
  value
}) =>
  value ? (
    <Text nowrap={true}>
      {formatDistance(new Date(value), Date.now(), { includeSeconds: false })}{' '}
      ago
    </Text>
  ) : (
    <Text>--</Text>
  )

const getThemedClasses = (theme: ITheme) => ({
  commandBar: {
    borderBottom: `solid 2px ${theme.palette.neutralLight}`
  }
})

export const AccountLinkingRequestList: React.FC = () => {
  const CellComponents: Record<
    AccountLinkingRequestListColumnName,
    React.FC<IOdataDataListCellRenderProps<IAccountLinkingRequest>>
  > = {
    'Client Name': ({ item }) => (
      <Stack styles={{ root: { minWidth: 0 } }}>
        <ClientNameCell request={item} />
        <Text variant="small" nowrap={true}>
          {item.rcm_newclientemailid}
        </Text>
      </Stack>
    ),
    'Client Role': ({ item }) => <TextCell>{item?.rcm_newclientrole}</TextCell>,

    Status: ({ item }) => {
      const [[key]] = Object.entries(AccountLinkingRequestStatusEnum).filter(
        ([, value]) => value === item.rcm_status
      )

      return <TextCell>{key}</TextCell>
    },
    'Wealthscape Id': ({ item }) => (
      <TextCell>{item.rcm_cliendid || '--'}</TextCell>
    ),
    'Request Type': ({ item }) => (
      <TextCell>{item.rcm_requesttype || '--'}</TextCell>
    ),
    Accounts: ({ item }) => {
      const accounts = item?.rcm_accountstoadd?.split(',') || []

      return (
        <>
          {!!accounts.length && (
            <TooltipHost
              tooltipProps={{
                onRenderContent: () => {
                  return (
                    <Stack tokens={{ childrenGap: 2 }}>
                      {accounts?.map((x) => (
                        <Text key={x} variant="small">
                          <ConnectedMasked text={x} />
                        </Text>
                      ))}
                    </Stack>
                  )
                }
              }}
              delay={TooltipDelay.zero}
              directionalHint={DirectionalHint.rightCenter}
            >
              <Link>
                <Text variant="smallPlus">{accounts.length} accounts</Text>
              </Link>
            </TooltipHost>
          )}
        </>
      )
    },
    Verification: ({ item }) =>
      item.rcm_clientmethod ? (
        <Stack styles={{ root: { minWidth: 0 } }}>
          {item?.rcm_clientmethod !== fidelityCharitable ? (
            <Stack
              horizontal={true}
              tokens={{ childrenGap: 3 }}
              styles={{ root: { minWidth: 0 } }}
            >
              <Text block={true} nowrap={true}>
                {item.rcm_client}
              </Text>
              <Text block={true} nowrap={true}>
                {item.rcm_clientmethod}
              </Text>
            </Stack>
          ) : (
            <Text block={true} nowrap={true}>
              {item.rcm_clientmethod}
            </Text>
          )}
          <Stack
            horizontal={true}
            tokens={{ childrenGap: 3 }}
            styles={{ root: { minWidth: 0 } }}
          >
            {item.rcm_verificationdate && (
              <Text variant="small" block={true} nowrap={true}>
                {item.rcm_verificationdate &&
                  flow(
                    parseDateISOStringInLocalTimezone,
                    format('MM/dd/yyyy')
                  )(item.rcm_verificationdate)}
              </Text>
            )}

            {item.rcm_verificationtime && (
              <Text variant="small" block={true} nowrap={true}>
                @ {item.rcm_verificationtime}
              </Text>
            )}
          </Stack>
        </Stack>
      ) : (
        <>--</>
      ),
    Created: ({ item }) => (
      <Stack styles={{ root: { minWidth: 0 } }}>
        <DateCell value={item.createdon} />
        <Text variant="small" nowrap={true}>
          {item['_createdby_value@OData.Community.Display.V1.FormattedValue']}
        </Text>
      </Stack>
    ),
    'Last Modified': ({ item }) => (
      <Stack styles={{ root: { minWidth: 0 } }}>
        <DateCell value={item.modifiedon} />
        <Text variant="small" nowrap={true}>
          {item[`_modifiedby_value@OData.Community.Display.V1.FormattedValue`]}
        </Text>
      </Stack>
    ),
    Team: ({ item }) => (
      <Stack styles={{ root: { minWidth: 0 } }}>
        <Text nowrap={true}>{item.owningbusinessunit?.name}</Text>
        <Text nowrap={true} variant="small">
          {
            item.owninguser?.businessunitid?.parentbusinessunitid
              ?.parentbusinessunitid?.name
          }
        </Text>
      </Stack>
    ),
    ID: () => <></>
  }
  const AccountLinkingRequestListCell: React.FC<
    IOdataDataListCellRenderProps<IAccountLinkingRequest>
  > = memo(({ item, column }) => {
    const Component =
      CellComponents[column.name as AccountLinkingRequestListColumnName]
    return Component ? <Component item={item} column={column} /> : null
  })
  const dispatch = useDispatch()
  const commandBarItems = useMemo(() => {
    const items: ICommandBarItemProps[] = [
      {
        key: 'month',
        name: 'New Request',
        iconProps: { iconName: 'Add' },
        onClick: () => {
          dispatch(accountLinkingPanelActions.open())
        }
      }
    ]
    return items
  }, [dispatch])
  const classes = useClasses(getThemedClasses)
  const header = useMemo(
    () => (
      <CommandBar
        className={classes.commandBar}
        items={commandBarItems}
        styles={{ root: { paddingLeft: '0px' } }}
      />
    ),
    [classes, commandBarItems]
  )
  const [searchParams] = useSearchParams()
  const id = useMemo(() => searchParams.get('id'), [searchParams])
  useEffect(() => {
    if (!id) {
      return
    }
    dispatch(
      accountLinkingRequestListUiActions.updateFilters({
        ID: {
          id: 'ID',
          name: 'ID',
          type: 'search',
          filterType: 'eq',
          value: id,
          hasValue: id ? true : false,
          dataPath: 'rcm_accountlinkingrequestid'
        } as IListsSearchFilter
      })
    )
  }, [dispatch, id])

  return (
    <OdataList
      actions={accountLinkingRequestListUiActions}
      selectors={accountLinkingRequestListUiSelectors}
      onRenderCell={AccountLinkingRequestListCell}
      stickyHeaderOffset={constants.headerHeight}
      secondaryHeader={header}
    />
  )
}
