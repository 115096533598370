import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

interface IActivateProfilePanelState {
  isOpen?: boolean
  partyId?: string
  partyName?: string
}

const { actions, reducer } = createSlice({
  name: 'activateProfilePanel',
  initialState: {} as IActivateProfilePanelState,
  reducers: {
    open: (
      state,
      action: PayloadAction<{ partyId?: string; partyName?: string }>
    ) => ({
      ...state,
      isOpen: true,
      partyId: action.payload.partyId,
      partyName: action.payload.partyName
    }),
    close: () => ({
      isOpen: false
    })
  }
})

export { reducer as activateProfilePanelReducer }

const rootSelector = (state: AppState) =>
  state.features.activateProfile.activateProfilePanel

const getIsOpen = (state: AppState) => rootSelector(state).isOpen
const getPartyId = (state: AppState) => rootSelector(state).partyId
const getPartyName = (state: AppState) => rootSelector(state).partyName

export const useActivateProfilePanel = () => {
  const dispatch = useDispatch()

  const open = useCallback(
    (partyId?: string, partyName?: string) => {
      dispatch(actions.open({ partyId, partyName }))
    },
    [dispatch]
  )

  const close = useCallback(() => {
    dispatch(actions.close())
  }, [dispatch])

  return {
    isOpen: useSelector(getIsOpen),
    partyId: useSelector(getPartyId),
    partyName: useSelector(getPartyName),
    open,
    close
  }
}
