import axios from 'axios'
import { call, cancelled, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import {
  getMarginRateRequest,
  IMarginRateRequest
} from '../../../../../../../../../api/dynamics'
import { AppState } from '../../../../../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../../../store/shared/asyncStore'
import { getDynamicsApiOptions } from '../../../../../../../../../store/shared/sagas'
import { marginAccountFetchActions } from './HouseholdAccountsFetch'
import { householdDetailsFetchActions } from './HouseholdDetailsFetch'

export const marginRateRequestFetchActions = createAsyncAction(
  '@modules/@advisory/@modules/@marginRateRequest/@marginRateRequestViewPanel/@MarginRateRequestFetch/REQUEST',
  '@modules/@advisory/@modules/@marginRateRequest/@marginRateRequestViewPanel/@MarginRateRequestFetch/SUCCESS',
  '@modules/@advisory/@modules/@marginRateRequest/@marginRateRequestViewPanel/@MarginRateRequestFetch/FAILURE'
)<string, IMarginRateRequest, Error>()

export const marginRateRequestFetchReducer = createAsyncReducer(
  marginRateRequestFetchActions
)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.accounts.modules.marginRateRequests.features
    .marginRateRequestFetch

export const {
  getError: getMarginRateRequestFetchError,
  getIsLoading: getIsMarginRateRequestFetchLoading,
  getResult: getMarginRateRequestFetchResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof marginRateRequestFetchActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  try {
    const apiOptions = yield* call(getDynamicsApiOptions)

    const result = yield* call(getMarginRateRequest, apiOptions, action.payload)
    yield put(marginAccountFetchActions.request(result.rcm_householdid))
    yield put(householdDetailsFetchActions.request(result.rcm_householdid))
    yield put(marginRateRequestFetchActions.success(result))
  } catch (e: unknown) {
    yield put(marginRateRequestFetchActions.failure(e as Error))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const marginRateRequestFetchSagas = [
  () => takeLatest(marginRateRequestFetchActions.request, onRequest)
]
