import { flexRender, Row } from '@tanstack/react-table'
import { PerformanceData } from 'api/performance.types'
import {
  useRdot360ThemedTableStyles,
  rdot360TableStyles
} from 'modules/Advisory/modules/Rdot360/shared/tableStyles'
import { Icon } from '../../features/Icons/Icon'
import { cellStyles } from './styles'

const PerformanceTableGroupRow: React.FC<{ row: Row<PerformanceData> }> = ({
  row
}) => {
  const cells = row.getVisibleCells()
  const themedStyles = useRdot360ThemedTableStyles()
  const isL1GroupRow = row.depth === 0
  const isL2GroupRow = row.depth > 0
  const isGroupRow = isL1GroupRow || isL2GroupRow
  const isExpanded = row.getIsExpanded()

  return (
    <tr
      css={[
        rdot360TableStyles.bodyRow,
        themedStyles.bodyRow,
        isGroupRow && themedStyles.groupRow,
        isL1GroupRow && themedStyles.l1GroupRow,
        isL1GroupRow && rdot360TableStyles.l1GroupRow,
        isL2GroupRow && themedStyles.l2GroupRow,
        isL2GroupRow && rdot360TableStyles.l2GroupRow,
        isExpanded && themedStyles.groupRowExpanded
      ]}
    >
      {cells.map((cell, i) => {
        return (
          <td key={cell.id} css={[cellStyles[cell.column.id]]}>
            {!!row.subRows?.length && i === 0 ? (
              <div
                key={row.id}
                css={{
                  cursor: 'pointer',
                  display: 'flex'
                }}
                onClick={row.getToggleExpandedHandler()}
              >
                <div css={{ width: '12px', height: '12px' }}>
                  <Icon
                    type={row.getIsExpanded() ? 'Subtract' : 'Add'}
                    width={12}
                    height={12}
                  />
                </div>

                <span
                  style={{
                    marginLeft: '10px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    width: '100%'
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </span>
              </div>
            ) : (
              <span
                css={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: '100%'
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </span>
            )}
          </td>
        )
      })}
    </tr>
  )
}

export default PerformanceTableGroupRow
