import { ActionType, createAction } from 'typesafe-actions'
import {
  IPostingRules,
  IPostingRulesRequest,
  IInstitution,
  ITransaction,
  IPayee,
  IMainAccount,
  ISubAccount,
  IAddEditResponse,
  IAddEditRulesRequest,
  IProject,
  IPerson
} from './types'

export const FETCH_POSTING_RULES =
  '@modules/@payments/@postingrules/FETCH_POSTING_RULES'
export const FETCH_POSTING_RULES_SUCCESS =
  '@modules/@payments/@postingrules/FFETCH_POSTING_RULES_SUCCESS'
export const FETCH_POSTING_RULES_FAILURE =
  '@modules/@payments/@postingrules/FETCH_POSTING_RULES_FAILURE'

export const postingRulesDataActions = {
  request: createAction(FETCH_POSTING_RULES)<IPostingRulesRequest>(),
  success: createAction(FETCH_POSTING_RULES_SUCCESS)<IPostingRules[]>(),
  failure: createAction(FETCH_POSTING_RULES_FAILURE)<Error>()
}

export const FETCH_INSTITUTIONS =
  '@modules/@payments/@postingrules/FETCH_INSTITUTIONS'
export const FETCH_INSTITUTIONS_SUCCESS =
  '@modules/@payments/@postingrules/FETCH_INSTITUTIONS_SUCCESS'
export const FETCH_INSTITUTIONS_FAILURE =
  '@modules/@payments/@postingrules/FETCH_INSTITUTIONS_FAILURE'

export const institutionsDataActions = {
  request: createAction(FETCH_INSTITUTIONS)<IPostingRulesRequest>(),
  success: createAction(FETCH_INSTITUTIONS_SUCCESS)<IInstitution[]>(),
  failure: createAction(FETCH_INSTITUTIONS_FAILURE)<Error>()
}
export const FETCH_TRANSACTIONS =
  '@modules/@payments/@postingrules/FETCH_TRANSACTIONS'
export const FETCH_TRANSACTIONS_SUCCESS =
  '@modules/@payments/@postingrules/FETCH_TRANSACTIONS_SUCCESS'
export const FETCH_TRANSACTIONS_FAILURE =
  '@modules/@payments/@postingrules/FETCH_TRANSACTIONS_FAILURE'

export const transactionsDataActions = {
  request: createAction(FETCH_TRANSACTIONS)(),
  success: createAction(FETCH_TRANSACTIONS_SUCCESS)<ITransaction[]>(),
  failure: createAction(FETCH_TRANSACTIONS_FAILURE)<Error>()
}
export const FETCH_PAYEES = '@modules/@payments/@postingrules/FETCH_PAYEES'
export const FETCH_PAYEES_SUCCESS =
  '@modules/@payments/@postingrules/FETCH_PAYEES_SUCCESS'
export const FETCH_PAYEES_FAILURE =
  '@modules/@payments/@postingrules/FETCH_PAYEES_FAILURE'

export const payeesDataActions = {
  request: createAction(FETCH_PAYEES)<IPostingRulesRequest>(),
  success: createAction(FETCH_PAYEES_SUCCESS)<IPayee[]>(),
  failure: createAction(FETCH_PAYEES_FAILURE)<Error>()
}

export const FETCH_MAIN_ACCOUNTS =
  '@modules/@payments/@postingrules/FETCH_MAIN_ACCOUNTS'
export const FETCH_MAIN_ACCOUNTS_SUCCESS =
  '@modules/@payments/@postingrules/FETCH_MAIN_ACCOUNTS_SUCCESS'
export const FETCH_MAIN_ACCOUNTS_FAILURE =
  '@modules/@payments/@postingrules/FETCH_MAIN_ACCOUNTS_FAILURE'

export const mainAccountsDataActions = {
  request: createAction(FETCH_MAIN_ACCOUNTS)(),
  success: createAction(FETCH_MAIN_ACCOUNTS_SUCCESS)<IMainAccount[]>(),
  failure: createAction(FETCH_MAIN_ACCOUNTS_FAILURE)<Error>()
}

export const FETCH_SUB_ACCOUNTS =
  '@modules/@payments/@postingrules/FETCH_SUB_ACCOUNTS'
export const FETCH_SUB_ACCOUNTS_SUCCESS =
  '@modules/@payments/@postingrules/FETCH_SUB_ACCOUNTS_SUCCESS'
export const FETCH_SUB_ACCOUNTS_FAILURE =
  '@modules/@payments/@postingrules/FETCH_SUB_ACCOUNTS_FAILURE'

export const subAccountsDataActions = {
  request: createAction(FETCH_SUB_ACCOUNTS)<IPostingRulesRequest>(),
  success: createAction(FETCH_SUB_ACCOUNTS_SUCCESS)<ISubAccount[]>(),
  failure: createAction(FETCH_SUB_ACCOUNTS_FAILURE)<Error>()
}

export const ADD_EDIT_RULES = '@modules/@payments/@postingrules/ADD_EDIT_RULES'
export const ADD_EDIT_RULES_SUCCESS =
  '@modules/@payments/@postingrules/ADD_EDIT_RULES_SUCCESS'
export const ADD_EDIT_RULES_FAILURE =
  '@modules/@payments/@postingrules/ADD_EDIT_RULES_FAILURE'

export const addEditRuleDataActions = {
  request: createAction(ADD_EDIT_RULES)<IAddEditRulesRequest | undefined>(),
  success: createAction(ADD_EDIT_RULES_SUCCESS)<IAddEditResponse>(),
  failure: createAction(ADD_EDIT_RULES_FAILURE)<Error>()
}

export const FETCH_PROJECT =
  '@modules/@payments/@postingrules/FETCH_PROJECT_PAYMENTS'
export const FETCH_PROJECT_SUCCESS =
  '@modules/@payments/@postingrules/FETCH_PROJECT_SUCCESS'
export const FETCH_PROJECT_FAILURE =
  '@modules/@payments/@postingrules/FETCH_PROJECT_FAILURE'

export const projectDataActions = {
  request: createAction(FETCH_PROJECT)<IPostingRulesRequest>(),
  success: createAction(FETCH_PROJECT_SUCCESS)<IProject[]>(),
  failure: createAction(FETCH_PROJECT_FAILURE)<Error>()
}

export const FETCH_PERSON =
  '@modules/@payments/@postingrules/FETCH_PERSON_PAYMENTS'
export const FETCH_PERSON_SUCCESS =
  '@modules/@payments/@postingrules/FETCH_PERSON_SUCCESS'
export const FETCH_PERSON_FAILURE =
  '@modules/@payments/@postingrules/FETCH_PERSON_FAILURE'

export const personDataActions = {
  request: createAction(FETCH_PERSON)<IPostingRulesRequest>(),
  success: createAction(FETCH_PERSON_SUCCESS)<IPerson[]>(),
  failure: createAction(FETCH_PERSON_FAILURE)<Error>()
}

export type PostingRulesDataActionType = ActionType<
  typeof postingRulesDataActions
>
export type InstitutionsDataActionType = ActionType<
  typeof institutionsDataActions
>
export type TransactionsDataActionType = ActionType<
  typeof transactionsDataActions
>
export type PayeesDataActionType = ActionType<typeof payeesDataActions>
export type MainAccountsDataActionType = ActionType<
  typeof mainAccountsDataActions
>
export type SubAccountsDataActionType = ActionType<
  typeof subAccountsDataActions
>
export type AddEditRuleDataActionType = ActionType<
  typeof addEditRuleDataActions
>
export type ProjectDataActionTypes = ActionType<typeof projectDataActions>
export type PersonDataActionTypes = ActionType<typeof personDataActions>

export type PostingRulesActions =
  | PostingRulesDataActionType
  | InstitutionsDataActionType
  | TransactionsDataActionType
  | PayeesDataActionType
  | MainAccountsDataActionType
  | SubAccountsDataActionType
  | AddEditRuleDataActionType
  | ProjectDataActionTypes
  | PersonDataActionTypes
