import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

const { actions, reducer } = createSlice({
  name: 'noteFilterCallout',
  initialState: {} as INoteFilterCalloutState,
  reducers: {
    show: (state) => ({
      ...state,
      showCallout: true
    }),
    hide: (state) => ({
      ...state,
      showCallout: false,
      error: undefined
    }),
    failure: (state, action: PayloadAction<Error>) => ({
      ...state,
      error: action.payload
    }),
    setDisplayLast30: (state, action: PayloadAction<boolean>) => ({
      ...state,
      displayLast30: action.payload
    })
  }
})

export { reducer as noteFilterCalloutReducer }

const rootSelector = (state: AppState) => state.features.notes.noteFilterCallout

const getShouldShowCallout = flow(rootSelector, (x) => x.showCallout)
const getPanelError = flow(rootSelector, (x) => x.error)
const getDisplayLast30 = flow(rootSelector, (x) => x.displayLast30)

export interface INoteFilterCalloutState {
  showCallout?: boolean
  error?: Error
  displayLast30?: boolean
}

export const useNoteFilterCallout = () => {
  const dispatch = useDispatch()

  const hide = useCallback(() => {
    dispatch(actions.hide())
  }, [dispatch])

  const show = useCallback(() => {
    dispatch(actions.show())
  }, [dispatch])

  const setError = useCallback(
    (e: Error) => {
      dispatch(actions.failure(e))
    },
    [dispatch]
  )

  const setDisplayLast30 = useCallback(
    (bool: boolean) => {
      dispatch(actions.setDisplayLast30(bool))
    },
    [dispatch]
  )

  const showCallout = useSelector(getShouldShowCallout)

  const error = useSelector(getPanelError)

  const shouldDisplayLast30 = useSelector(getDisplayLast30)

  return {
    hide,
    show,
    setError,
    setDisplayLast30,
    showCallout,
    error,
    shouldDisplayLast30
  }
}
