import { call, put, takeLatest } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../../../store/shared/sagas'
import {
  addEditSubAccount,
  fetchSubAccountCategories,
  fetchSubAccountList,
  getABA,
  linkAccount,
  unLinkAccount,
  fetchMainAccountListService
} from '../api/subAccountService'
import {
  addEditSubAccountDataActions,
  SubAccountAddEditActionTypes,
  subAccountDataActions,
  categoryDataActions,
  SubAccountDataActionTypes,
  aBADataActions,
  ABADataActionTypes,
  getNinthwaveConfig,
  linkAccountActions,
  unLinkAccountActions,
  UnlinkAccountActionTypes,
  fetchGLMainAccountsActions
} from './actions'
import {
  ILinkAccountRequest,
  ISubAccount,
  ISubAccountRequest,
  IUnlinkAccountRequest
} from './types'

function* handleFetchSubAccountList(action: SubAccountDataActionTypes) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const payeeRequest = action.payload as ISubAccountRequest
    const data = yield* call(fetchSubAccountList, options, payeeRequest)
    yield put(subAccountDataActions.success(data))
  } catch (e: any) {
    yield put(subAccountDataActions.failure(e))
  }
}

function* handleFetchSubAccountCategories() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(fetchSubAccountCategories, options)
    yield put(categoryDataActions.success(data))
  } catch (e: any) {
    yield put(categoryDataActions.failure(e))
  }
}

function* handleGetABANumbers(action: ABADataActionTypes) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const subAcctReq = action.payload as ISubAccount
    const data = yield* call(getABA, options, subAcctReq)
    if (data && data.length > 0) {
      yield put(aBADataActions.success(data[0]))
    } else {
      yield put(
        aBADataActions.failure(
          new Error('Please provide valid ABA Routing number')
        )
      )
    }
  } catch (e) {
    yield put(
      aBADataActions.failure(
        new Error('Please provide valid ABA Routing number')
      )
    )
  }
}

function* handleAddEditSubAccount(action: SubAccountAddEditActionTypes) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const payeeRequest = action.payload as ISubAccount
    const data = yield* call(addEditSubAccount, options, payeeRequest)
    if (data && data.status && data.status?.code !== '000000') {
      yield put(
        addEditSubAccountDataActions.failure(
          new Error('We are unable save account, you can try again.')
        )
      )
    } else {
      yield put(addEditSubAccountDataActions.success(data))
    }
  } catch (e) {
    yield put(
      addEditSubAccountDataActions.failure(
        new Error('Please provide valid ABA Routing number')
      )
    )
  }
}

function* getNinthWaveConfig() {
  try {
    const options = yield* call(getRockefellerApiOptions)

    yield put(
      getNinthwaveConfig.success({
        token: options.accessToken,
        baseURL: options.apiRoot
      })
    )
  } catch (e: any) {
    yield put(getNinthwaveConfig.failure(e))
  }
}

function* handleLinkAccount(
  action: ReturnType<typeof linkAccountActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const accountRequest = action.payload as ILinkAccountRequest
    yield* call(linkAccount, options, accountRequest)
    yield put(linkAccountActions.success())
  } catch (e: any) {
    yield put(linkAccountActions.failure(e))
  }
}

function* handleUnLinkAccount(action: UnlinkAccountActionTypes) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const accountRequest = action.payload as IUnlinkAccountRequest
    const data = yield* call(unLinkAccount, options, accountRequest)
    if (data?.value?.[0]?.status?.Code === '0') {
      yield put(unLinkAccountActions.success(data?.value?.[0]?.status))
    } else {
      yield put(
        unLinkAccountActions.failure(
          new Error('We are unable save account, you can try again.')
        )
      )
    }
  } catch (e: any) {
    yield put(unLinkAccountActions.failure(e))
  }
}

function* handleFetchGLMainAccountsList() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(fetchMainAccountListService, options)
    yield put(fetchGLMainAccountsActions.success(data))
  } catch (e: any) {
    yield put(fetchGLMainAccountsActions.failure(e))
  }
}

export const sagas = [
  () => takeLatest(subAccountDataActions.request, handleFetchSubAccountList),
  () =>
    takeLatest(categoryDataActions.request, handleFetchSubAccountCategories),
  () =>
    takeLatest(addEditSubAccountDataActions.request, handleAddEditSubAccount),
  () => takeLatest(aBADataActions.request, handleGetABANumbers),
  () => takeLatest(getNinthwaveConfig.request, getNinthWaveConfig),
  () => takeLatest(linkAccountActions.request, handleLinkAccount),
  () => takeLatest(unLinkAccountActions.request, handleUnLinkAccount),
  () =>
    takeLatest(
      fetchGLMainAccountsActions.request,
      handleFetchGLMainAccountsList
    )
]
