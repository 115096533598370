import {
  ActionType,
  createAction,
  EmptyActionCreator,
  PayloadActionCreator
} from 'typesafe-actions'
import {
  IFacetResult,
  ISearchParams,
  ISearchResult,
  SearchResponseType
} from '../../../../api/common.types'
import { IColumnState } from '../contracts/IColumnState'
import { IListsFilter } from '../contracts/IListsFilter'
import { IListsOrderBy } from '../contracts/IListsOrderBy'
import { IListsUiState } from '../contracts/IListsUIState'

const createActionWithPrefix =
  <TType extends string>(prefix: string, type: TType) =>
  <TPayload>(): PayloadActionCreator<TType, TPayload> =>
    createAction<TType, TPayload, string>(
      `${prefix}/${type}` as TType,
      (payload: TPayload) => payload,
      () => prefix
    )<TPayload, string>()

const createEmptyActionWithPrefix =
  <TType extends string>(prefix: string, type: TType) =>
  (): EmptyActionCreator<TType> =>
    createAction<TType, string>(
      `${prefix}/${type}` as TType,
      () => prefix
    )<string>()

const UPDATE_SEARCH_TEXT = '@features/@lists/UPDATE_SEARCH_TEXT'
const ADD_FILTER = '@features/@lists/UPDATE_FILTERS'
const REMOVE_FILTER = '@features/@lists/REMOVE_FILTER'
const SET_FILTERS = '@features/@lists/SET_FILTERS'
const SORT = '@features/@lists/SORT'
const UPDATE_COLUMN_STATE = '@features/@lists/UPDATE_COLUMN_STATE'
const MOVE_COLUMN = '@features/@lists/MOVE_COLUMN'
const HIDE_COLUMN = '@features/@lists/HIDE_COLUMN'
const UPDATE_LIST_STATE = '@features/@lists/UPDATE_LIST_STATE'
const REPLACE_COLUMN = '@features/@lists/REPLACE_COLUMN'
const SET_CONFIG = '@features/@lists/SET_CONFIG'

export type ListsUiActions = ReturnType<typeof createListsUiActionsWithPrefix>
export type ListsUiActionTypes = ActionType<ListsUiActions>
export const createListsUiActionsWithPrefix = (prefix: string) => {
  const updateSearchText = createActionWithPrefix(
    prefix,
    UPDATE_SEARCH_TEXT
  )<string>()

  const removeFilters = createActionWithPrefix(prefix, REMOVE_FILTER)<
    string[]
  >()

  const setFilter = createActionWithPrefix(prefix, ADD_FILTER)<IListsFilter>()

  const setFilters = createActionWithPrefix(prefix, SET_FILTERS)<
    Record<string, IListsFilter>
  >()

  const sort = createActionWithPrefix(prefix, SORT)<IListsOrderBy>()

  const updateColumnState = createActionWithPrefix(prefix, UPDATE_COLUMN_STATE)<
    IColumnState[]
  >()

  const moveColumn = createActionWithPrefix(prefix, MOVE_COLUMN)<{
    id: string
    position: number
  }>()

  const hideColumn = createActionWithPrefix(prefix, HIDE_COLUMN)<string>()

  const update = createActionWithPrefix(prefix, UPDATE_LIST_STATE)<
    Partial<IListsUiState>
  >()
  const replaceColumn = createActionWithPrefix(prefix, REPLACE_COLUMN)<{
    columnToReplaceId: string
    newColumnId: string
  }>()

  const setConfig = createActionWithPrefix(prefix, SET_CONFIG)<
    Pick<
      IListsUiState,
      | 'filters'
      | 'filterDefinitions'
      | 'columnDefinitions'
      | 'orderBy'
      | 'columnState'
    >
  >()

  return {
    updateSearchText,
    removeFilters,
    setFilter,
    setFilters,
    sort,
    updateColumnState,
    update,
    moveColumn,
    hideColumn,
    replaceColumn,
    setConfig
  }
}

const SEARCH_REQUESTED = '@feature/@lists/REQUESTED'
const SEARCH_COMPLETE = '@feature/@lists/COMPLETE'
const SEARCH_CHUNK = '@feature/@lists/CHUNK'
const SEARCH_LOAD_MORE = '@feature/@lists/LOAD_MORE'
const SEARCH_ERROR = '@feature/@lists/ERROR'

export interface IListsDataChunkPayload<T extends SearchResponseType> {
  index: number
  result: ISearchResult<T>
}

const request = (prefix: string) =>
  createEmptyActionWithPrefix(prefix, SEARCH_REQUESTED)()
const complete = (prefix: string) =>
  createActionWithPrefix(prefix, SEARCH_COMPLETE)<ISearchParams | undefined>()
const chunk = <T extends SearchResponseType>(prefix: string) =>
  createActionWithPrefix(prefix, SEARCH_CHUNK)<IListsDataChunkPayload<T>>()
const loadMore = (prefix: string) =>
  createEmptyActionWithPrefix(prefix, SEARCH_LOAD_MORE)()
const error = (prefix: string) =>
  createActionWithPrefix(prefix, SEARCH_ERROR)<Error>()

export interface IListsDataActions<T extends SearchResponseType> {
  request: ReturnType<typeof request>
  complete: ReturnType<typeof complete>
  chunk: PayloadActionCreator<typeof SEARCH_CHUNK, IListsDataChunkPayload<T>>
  loadMore: ReturnType<typeof loadMore>
  error: ReturnType<typeof error>
}

export type ListsDataActionTypes<T extends SearchResponseType> = ActionType<
  IListsDataActions<T>
>
export const createListsDataActionsWithPrefix = <T extends SearchResponseType>(
  prefix: string
): IListsDataActions<T> => {
  return {
    request: request(prefix),
    complete: complete(prefix),
    chunk: chunk(prefix),
    loadMore: loadMore(prefix),
    error: error(prefix)
  }
}

const EXPORT_REQUESTED = '@feature/@lists/EXPORT_REQUESTED'
const EXPORT_ERROR = '@feature/@lists/EXPORT_ERROR'
const EXPORT_COMPLETE = '@feature/@lists/EXPORT_COMPLETE'
export type ListsExportActions = ReturnType<
  typeof createListsExportActionsWithPrefix
>
export type ListsExportActionTypes = ActionType<ListsExportActions>
export const createListsExportActionsWithPrefix = (prefix: string) => {
  const exportRequested = createEmptyActionWithPrefix(
    prefix,
    EXPORT_REQUESTED
  )()
  const exportError = createActionWithPrefix(prefix, EXPORT_ERROR)<Error>()
  const exportComplete = createEmptyActionWithPrefix(prefix, EXPORT_COMPLETE)()

  return {
    request: exportRequested,
    error: exportError,
    complete: exportComplete
  }
}

const FACET_REQUESTED = '@feature/@lists/FACET_REQUESTED'
const FACET_ERROR = '@feature/@lists/FACET_ERROR'
const FACET_COMPLETE = '@feature/@lists/FACET_COMPLETE'
const FACET_RESET = '@feature/@lists/FACET_RESET'

export interface IListsFacetRequestPayload {
  id: string
  searchText?: string
}

export type ListsFacetActions = ReturnType<
  typeof createListsFacetActionsWithPrefix
>
export type ListsFacetActionTypes = ActionType<ListsFacetActions>
export const createListsFacetActionsWithPrefix = (prefix: string) => {
  const facetRequested = createActionWithPrefix(
    prefix,
    FACET_REQUESTED
  )<IListsFacetRequestPayload>()
  const facetComplete = createActionWithPrefix(prefix, FACET_COMPLETE)<
    Record<string, IFacetResult[]>
  >()
  const facetError = createActionWithPrefix(prefix, FACET_ERROR)<Error>()
  const facetReset = createEmptyActionWithPrefix(prefix, FACET_RESET)()
  return {
    request: facetRequested,
    complete: facetComplete,
    error: facetError,
    reset: facetReset
  }
}

export interface IListsActions<T extends SearchResponseType> {
  dataActions: IListsDataActions<T>
  uiActions: ListsUiActions
  exportActions: ListsExportActions
  facetActions: ListsFacetActions
}

export const createListsActionsWithPrefix = <T extends SearchResponseType>(
  prefix: string
): IListsActions<T> => {
  const dataActions = createListsDataActionsWithPrefix<T>(prefix)
  const uiActions = createListsUiActionsWithPrefix(prefix)
  const exportActions = createListsExportActionsWithPrefix(prefix)
  const facetActions = createListsFacetActionsWithPrefix(prefix)

  return {
    dataActions,
    uiActions,
    exportActions,
    facetActions
  }
}
