import { MessageBar, MessageBarType } from '@fluentui/react'
import { groupBy } from 'lodash'
import React from 'react'
import { IRevenueHistory } from '../../store/context/advisor'
import {
  ITimeSeriesChartData,
  ITimeSeriesChartSeries,
  ITimeSeriesChartType,
  TimeSeriesChart
} from './TimeSeriesChart'

interface IRevenueSummaryComponentProps {
  revenueHistory?: IRevenueHistory[]
  chartType?: ITimeSeriesChartType
  pivotProperty?: RevenueSummaryPivotOptions
  chartValueType?: RevenueSummaryChartValueType
}

export type RevenueSummaryPivotOptions = 'advisorName' | 'category' | 'repId'
export type RevenueSummaryChartValueType = 'payout' | 'revenue'

export const RevenueSummaryComponent: React.FC<
  IRevenueSummaryComponentProps
> = ({ revenueHistory, pivotProperty, chartType, chartValueType }) => {
  const pivotGroups = groupBy(revenueHistory, pivotProperty || 'category')
  const pivotKeys = Object.keys(pivotGroups)

  const series: ITimeSeriesChartSeries[] = pivotKeys.map((pivotKey) => {
    const pivotGroup = pivotGroups[pivotKey]
    const pivotDateGroups = groupBy(pivotGroup, (x) => x.timestamp)
    const dateData: ITimeSeriesChartData[] = Object.entries(pivotDateGroups)
      .map(([timestamp, items]) => {
        const value = items.reduce(
          (a, x) =>
            a + ((chartValueType === 'payout' ? x.payout : x.revenue) || 0),
          0
        )
        return {
          date: new Date(parseInt(timestamp, 10)),
          value
        }
      })
      .filter((x) => x.value !== 0)

    return {
      data: dateData,
      name: pivotKey
    }
  })

  return series.length ? (
    <TimeSeriesChart series={series} chartType={chartType || 'column'} />
  ) : (
    <MessageBar messageBarType={MessageBarType.info}>
      No data available
    </MessageBar>
  )
}
