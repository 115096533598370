import {
  ITheme,
  MessageBar,
  MessageBarType,
  ProgressIndicator
} from '@fluentui/react'
import { skipToken } from '@reduxjs/toolkit/query'
import { usePushNotification } from 'features/Notifications'
import { useActivityDetails } from 'modules/Advisory/modules/Rdot360/hooks/useActivityDetails'
import {
  useRdot360_updateActivityDescriptionMutation,
  useGetActivitySummaryOverrideDescQuery,
  UpdateAppendedDescriptionRequest
} from 'modules/Advisory/modules/Rdot360/store/rdot360AnalyticsApi'

import { useRdot360SelectedAccountsApiContext } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useClasses } from 'shared/hooks/useClasses'
import { Icon } from '../../../../features/Icons/Icon'

const getThemedClasses = (theme: ITheme) => ({
  links: {
    color: theme.palette.themePrimary
  },
  inputLength: {
    color: theme.palette.blackTranslucent40
  },
  disabled: {
    color: theme.palette.blackTranslucent40
  }
})

export interface ActivityEditTooltipComponentProps {
  sequenceNo?: string
  dismissCard: () => void
  accountNumber?: string
  origDescription: string
}

export const ActivityEditTooltipContent: React.FC<
  ActivityEditTooltipComponentProps
> = ({ accountNumber, dismissCard, sequenceNo, origDescription = '' }) => {
  const classes = useClasses(getThemedClasses)

  const [updateActivityDescription] =
    useRdot360_updateActivityDescriptionMutation()

  const { apiContextAccounts } = useRdot360SelectedAccountsApiContext()
  const { updateDescriptionInCache } = useActivityDetails()

  const skip =
    !apiContextAccounts?.length ||
    sequenceNo === undefined ||
    sequenceNo === null

  const {
    data: appendedDescription,
    isLoading: isAppendedDescLoading,
    error: getAppendedDescError
  } = useGetActivitySummaryOverrideDescQuery(
    skip ? skipToken : { contextAccounts: apiContextAccounts, id: sequenceNo }
  )
  const [newDescription, setNewDescription] = useState(
    appendedDescription?.description || ''
  )
  useEffect(
    () => setNewDescription(appendedDescription?.description || ''),
    [appendedDescription?.description]
  )
  const containsAppendedDesc = useMemo(
    () =>
      (appendedDescription?.description || '').length > 0 &&
      origDescription.endsWith(appendedDescription?.description || ''),
    [appendedDescription, origDescription]
  )
  const charactersUsed = useMemo(
    () =>
      containsAppendedDesc
        ? origDescription.length -
          (appendedDescription?.description || '').length +
          newDescription.length
        : origDescription.length + newDescription.length,
    [
      appendedDescription?.description,
      containsAppendedDesc,
      newDescription.length,
      origDescription.length
    ]
  )
  const characterLimit = 99
  const remainingCharacters = useMemo(
    () => characterLimit - charactersUsed,
    [charactersUsed]
  )

  const origDescTooLong = useMemo(
    () =>
      origDescription.length - (appendedDescription?.description || '').length >
      characterLimit,
    [appendedDescription?.description, origDescription.length]
  )

  const inputHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const enteredName = event.target.value
    setNewDescription(enteredName)
  }
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const { pushNotification } = usePushNotification()

  const showLoadingError = useMemo(() => {
    if (
      (!containsAppendedDesc &&
        (appendedDescription?.description || '').length > 0) ||
      getAppendedDescError
    ) {
      return true
    }
    return false
  }, [
    appendedDescription?.description,
    containsAppendedDesc,
    getAppendedDescError
  ])
  const disableSave = useMemo(
    () => origDescTooLong || showLoadingError || isAppendedDescLoading,
    [origDescTooLong, showLoadingError, isAppendedDescLoading]
  )

  const handleSave = useCallback(async () => {
    if (disableSave) {
      return
    }
    setErrorMessage(undefined)
    const trimmedNewDesc = newDescription?.trim() || ''
    if (!sequenceNo) {
      return
    }

    const requestPayload = {
      AccountNumber: accountNumber,
      Description: trimmedNewDesc,
      SequenceNo: BigInt(sequenceNo)
    } as UpdateAppendedDescriptionRequest

    try {
      await updateActivityDescription(requestPayload).unwrap()
      const oldAppendedDescription = appendedDescription?.description || ''
      const index = origDescription.lastIndexOf(oldAppendedDescription)
      const withoutAppended = (
        index < 0 ? origDescription : origDescription.slice(0, index)
      ).trim()
      updateDescriptionInCache(
        sequenceNo,
        [withoutAppended, trimmedNewDesc].join(' ')
      )

      pushNotification({
        message: 'Successfully added description',
        type: MessageBarType.success
      })
      dismissCard()
    } catch (e: unknown) {
      setErrorMessage('An error occurred on save.')
    }
  }, [
    disableSave,
    newDescription,
    sequenceNo,
    accountNumber,
    updateActivityDescription,
    appendedDescription?.description,
    origDescription,
    updateDescriptionInCache,
    pushNotification,
    dismissCard
  ])

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        margin: 10,
        width: '510px',
        alignItems: 'center'
      }}
    >
      <ProgressIndicator
        progressHidden={!isAppendedDescLoading}
        styles={{
          root: {
            width: '100%'
          },
          itemProgress: { padding: 0, margin: 0 }
        }}
      />
      <textarea
        value={newDescription}
        onChange={inputHandler}
        placeholder="Type here..."
        maxLength={
          containsAppendedDesc
            ? characterLimit -
              origDescription.length +
              (appendedDescription?.description || '').length
            : characterLimit - origDescription.length
        }
        css={{
          width: '510px',
          height: 50,
          marginBottom: 10,
          resize: 'none'
        }}
      />
      <div
        className={classes.inputLength}
        css={{
          fontSize: '12px',
          width: '100%',
          textAlign: 'end',
          marginBottom: 5
        }}
      >
        {remainingCharacters} characters remaining
      </div>
      <div
        css={{
          width: '100%',
          fontSize: '12px',
          marginBottom: '10px',
          textAlign: 'start'
        }}
      >
        * This information will be visible to the client on Rockefeller Digital.
      </div>
      {(errorMessage || showLoadingError) && (
        <div css={{ marginBottom: '10px', width: '100%' }}>
          <MessageBar messageBarType={MessageBarType.error}>
            {errorMessage || 'An error occurred while fetching data'}
          </MessageBar>
        </div>
      )}
      <div css={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
        <div
          className={classes.links}
          css={{
            display: 'flex',
            alignItems: 'center',
            marginRight: 20,
            cursor: 'pointer'
          }}
          onClick={dismissCard}
        >
          <Icon type="CancelClose" width={12} height={12} />
          <div css={{ textDecoration: 'underline', marginLeft: 5 }}>Cancel</div>
        </div>
        <div
          className={disableSave ? classes.disabled : classes.links}
          css={[
            disableSave ? { cursor: 'not-allowed' } : { cursor: 'pointer' },
            { display: 'flex', alignItems: 'center' }
          ]}
        >
          <Icon type="SaveOkDone" width={15} height={15} />
          <div
            css={{ textDecoration: 'underline', marginLeft: 5 }}
            onClick={() => handleSave()}
          >
            Save
          </div>
        </div>
      </div>
    </div>
  )
}
