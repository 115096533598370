import {
  DirectionalHint,
  ITooltipProps,
  MessageBar,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from '@fluentui/react'
import { useDomainStore, IPoolSplitRep } from 'features/Domain/store/domain'
import { groupBy, keyBy, orderBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { FormattedNumber } from 'react-intl'
import { isNotNullOrUndefined } from '../../../../shared/guards'
import { AccountRepTreeHeader } from './AccountRepTreeHeader'
import { ITreeLeaf, ITreeNode, SelectableTree } from './SelectableTree'
import { useAccountRepTreeStore } from './store/accountRepTree'
import { IAdvisoryAccountRep } from './store/service'

const TextLabel: React.FC<{ rep: IAdvisoryAccountRep }> = ({
  rep: { id, name, userIsMemberOfRep }
}) => (
  <Text
    styles={{
      root: {
        maxWidth: '250px',
        ...(userIsMemberOfRep ? { fontWeight: 'bold' } : {})
      }
    }}
    nowrap={true}
  >
    {id} - {name}
  </Text>
)

const SplitList: React.FC<{ splits: IPoolSplitRep[] }> = ({ splits: data }) => {
  const oderedData = orderBy(data, ({ percentage }) => percentage, 'desc')
  const splitItems = oderedData.map((split, i) => (
    <Stack
      key={i}
      enableScopedSelectors
      horizontal
      horizontalAlign="space-between"
    >
      <Stack.Item>{split.name}</Stack.Item>
      <Stack.Item styles={{ root: { paddingLeft: '10px' } }}>
        <FormattedNumber
          value={split.percentage}
          maximumFractionDigits={2}
          minimumFractionDigits={2}
        />
        %
      </Stack.Item>
    </Stack>
  ))

  return (
    <Stack tokens={{ childrenGap: 5 }} styles={{ root: { minWidth: '85px' } }}>
      {splitItems}
    </Stack>
  )
}

export const AccountRepTree: React.FC = () => {
  const {
    selected,
    updateSelected,
    collapsed,
    updateCollapsed,
    filteredBusinessUnits,
    filteredAccountReps
  } = useAccountRepTreeStore()

  const { rootBusinessunitIds } = useDomainStore()

  const onTreeSelectedChanged = useCallback(
    (newSelected: string[]) => {
      updateSelected(newSelected)
    },
    [updateSelected]
  )

  const onTreeCollapsedChanged = useCallback(
    (newCollapsed: string[]) => {
      updateCollapsed(newCollapsed)
    },
    [updateCollapsed]
  )

  const filteredLeafs = useMemo(
    () =>
      filteredAccountReps?.map(
        ({ id, team }): ITreeLeaf => ({
          id,
          parentId: team?.id || rootBusinessunitIds?.[0]
        })
      ),
    [filteredAccountReps, rootBusinessunitIds]
  )

  const filteredNodes = useMemo(() => {
    const buParentGroups = groupBy(
      filteredBusinessUnits,
      ({ parentId }) => parentId
    )

    const mapChildren = (businessUnitId?: string): ITreeNode[] => {
      const buChildren =
        (businessUnitId &&
          buParentGroups[businessUnitId]
            ?.map((x) => ({
              id: x.id || '',
              children: mapChildren(x.id)
            }))
            .filter(isNotNullOrUndefined)) ||
        []

      return buChildren
    }

    return [
      ...rootBusinessunitIds.flatMap((id) => mapChildren(id)),
      ...rootBusinessunitIds.map((id) => ({ id }))
    ]
  }, [filteredBusinessUnits, rootBusinessunitIds])

  const contextRepsLookup = useMemo(
    () => keyBy(filteredAccountReps, ({ id }) => id),
    [filteredAccountReps]
  )

  const contextBusinessUnitsLookup = useMemo(
    () => keyBy(filteredBusinessUnits, ({ id }) => id),
    [filteredBusinessUnits]
  )

  const TreeNodeComponent = useMemo(
    (): React.FC<{ id: string }> =>
      ({ id }) => {
        const businessUnit = contextBusinessUnitsLookup[id]
        if (businessUnit) {
          return <Text>{businessUnit.name}</Text>
        }

        if (rootBusinessunitIds.includes(id)) {
          return <Text>Other Account Reps</Text>
        }

        const rep = contextRepsLookup[id]
        if (rep) {
          const { splits = [], userHasAccessToSplits = false } = rep

          const tooltipProps: ITooltipProps = {
            onRenderContent: () => <SplitList splits={splits} />
          }

          return userHasAccessToSplits && splits.length ? (
            <TooltipHost
              directionalHint={DirectionalHint.topCenter}
              tooltipProps={tooltipProps}
            >
              <TextLabel rep={rep} />
            </TooltipHost>
          ) : (
            <TextLabel rep={rep} />
          )
        }

        return null
      },
    [contextBusinessUnitsLookup, contextRepsLookup, rootBusinessunitIds]
  )

  const theme = useTheme()

  return (
    <Stack>
      <Stack.Item
        styles={{
          root: {
            paddingBottom: '10px',
            position: 'sticky',
            top: '82px',
            zIndex: 2,
            backgroundColor: theme.semanticColors.bodyBackground
          }
        }}
      >
        <AccountRepTreeHeader />
      </Stack.Item>
      <SelectableTree
        leafs={filteredLeafs}
        nodes={filteredNodes}
        selected={selected}
        TreeNodeComponent={TreeNodeComponent}
        collapsed={collapsed}
        onCollapsedChanged={onTreeCollapsedChanged}
        onSelectedChanged={onTreeSelectedChanged}
      />
      {!filteredAccountReps.length && <MessageBar>No results found</MessageBar>}
    </Stack>
  )
}
