import { Link } from '@fluentui/react'

export const AccountStatus: React.FC<{
  contractType?: string
  manuallyLinkedPartyId?: string
  openPanel: () => void
  isInProgress: boolean
}> = ({ openPanel, contractType, manuallyLinkedPartyId, isInProgress }) => {
  const linkingInProgress = 'Linking in progress'
  const accountNotLinked = 'Account not linked'

  if (contractType === 'Insurance') {
    if (manuallyLinkedPartyId !== null || isInProgress) {
      return linkingInProgress
    }
    return (
      <Link
        styles={{
          root: {
            width: '100%'
          }
        }}
        onClick={openPanel}
      >
        {accountNotLinked}
      </Link>
    )
  }
  return accountNotLinked
}
