import { MessageBarType } from '@fluentui/react'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import { pushNotification } from 'features/Notifications'
import { downloadUrlAsFile } from 'shared/downloads'
import { AppState } from 'store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from 'store/shared/asyncStore'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { IAIRevenueTypeByPlanid } from './AIRevenueSummaryFetch'
import { getMonthHeadings } from './AIRevenueSummaryTable'
import { ExcelExportWorker } from './ExportWorker'

export const AIRevenueSummaryTableExportActions = createAsyncAction(
  '@modules/@airevenue/@modules/@dashboard/@features/@altsadvisorrevenuesummaryExcelExport/EXPORT_REQUEST',
  '@modules/@airevenue/@modules/@dashboard/@features/@altsadvisorrevenuesummaryExcelExport/EXPORT_SUCCESS',
  '@modules/@airevenue/@modules/@dashboard/@features/@altsadvisorrevenuesummaryExcelExport/EXPORT_FAILURE'
)<IExportRevSumPayload, undefined, Error>()

export interface IExportRevSumPayload {
  airevenuedata: any
  aIRevenueTypeByPlanid: IAIRevenueTypeByPlanid[] | undefined
  dateRange: any
  feeType: any
  repCodes: any
}

export const AIRevenueSummaryTableExportReducer = createAsyncReducer(
  AIRevenueSummaryTableExportActions
)

const rootSelector = (state: AppState) =>
  state.modules.airevenue.modules.dashboard.features.revenueSummaryTable
    .revenueSummaryExport

export const {
  getError: getAIRevenueSummaryTableExportError,
  getIsLoading: getIsAIRevenueSummaryTableExportLoading
} = createAsyncSelectors(rootSelector)

const onExport = function* (
  action: ReturnType<typeof AIRevenueSummaryTableExportActions.request>
) {
  try {
    const monthHeadings = yield* select(getMonthHeadings)
    const worker = new Worker(new URL('./ExportWorker.ts', import.meta.url))

    const { createAndDownloadExcelWorksheet } = wrap<ExcelExportWorker>(worker)

    const result = yield* call(
      createAndDownloadExcelWorksheet,
      action.payload?.airevenuedata ?? [],
      action.payload?.aIRevenueTypeByPlanid ?? [],
      action.payload?.dateRange ?? {},
      action.payload?.feeType ?? {},
      action.payload?.repCodes ?? {},
      monthHeadings || []
    )

    const filename = `ALTS_ADVISOR_REVENUE_SUMMARY ${format(
      new Date(),
      'MM-dd-yyyy'
    )}.xlsx`
    downloadUrlAsFile(result, filename)
    yield put(AIRevenueSummaryTableExportActions.success())
  } catch (e: unknown) {
    yield put(AIRevenueSummaryTableExportActions.failure(e as Error))
  }
}

const onFailure = function* (
  action: ReturnType<typeof AIRevenueSummaryTableExportActions.failure>
) {
  yield call(pushNotification, {
    message: `Export failed: ${action.payload.message}`,
    type: MessageBarType.error
  })
}

export const AIRevenueSummaryTableExportSagas = [
  () => takeLatest(AIRevenueSummaryTableExportActions.request, onExport),
  () => takeLatest(AIRevenueSummaryTableExportActions.failure, onFailure)
]
