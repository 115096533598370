import { Stack } from '@fluentui/react'

const error404Styles = {
  mainPage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10%'
  },
  h2Header: {
    fontSize: '18vw',
    lineHeight: '1em'
  },
  h4Header: {
    fontSize: '1.5em',
    marginBottom: '20px',
    marginTop: '2px'
  }
}

export const NotFound = () => {
  return (
    <Stack
      style={{
        ...error404Styles.mainPage,
        position: 'inherit'
      }}
      tokens={{ childrenGap: 10 }}
      verticalAlign="center"
    >
      <Stack style={{ maxWidth: '600px', textAlign: 'center' }}>
        <div style={{ ...error404Styles.h2Header, position: 'relative' }}>
          404
        </div>
        <h4 style={{ ...error404Styles.h4Header, position: 'relative' }}>
          Page Not Found
        </h4>
        <div>The resource requested could not be found</div>
      </Stack>
    </Stack>
  )
}
