import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { IAccount } from 'api/account.types'
import { CDMv2Account } from 'api/nickname.types'
import { keyBy, uniq } from 'lodash'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { isNotNullOrEmpty, isNotNullOrUndefined } from 'shared/guards'
import { AppState } from 'store'
import {
  selectIncludeClosedAccountsPreference,
  selectPreferredNickNamePreference
} from '../../hooks/useClientDashboardPreferences'
import {
  rdot360DatahubApi,
  useGetAccountsFromHouseholdIdQuery,
  useRdot360_getAccountNicknamesQuery
} from '../datahub'
import {
  getSelectedHouseholdId,
  getUiSelectedAccounts,
  useRdot360Context
} from './rdot360Context'

export interface IRdot360Account extends IAccount {
  preferredNickname?: string
}

const mapToPreferredNickname = (
  cdmV2Account?: CDMv2Account,
  preferenceValue?: string
) => {
  if (!cdmV2Account) {
    return
  }
  let preferredNickname: string | undefined

  switch (preferenceValue) {
    case 'accountNicknames.clientAddedNickName': {
      preferredNickname = cdmV2Account?.accountNicknames?.clientAddedNickName
      break
    }
    case 'shortName': {
      preferredNickname = cdmV2Account?.shortName
      break
    }
    case 'accountNicknames.advisorAddedNickName':
    default: {
      preferredNickname = cdmV2Account?.accountNicknames?.advisorAddedNickName
      break
    }
  }

  return preferredNickname
}

export const isMultiMargin = (account?: IAccount) =>
  account?.marginAgreement === 'Yes' && isNotNullOrEmpty(account.multipleMargin)

const selectHouseholdApiRequest = createSelector(
  [getSelectedHouseholdId],
  (householdId) => householdId || skipToken
)

const selectSearchAccountsApiResultSelector = createSelector(
  [selectHouseholdApiRequest],
  (request) =>
    rdot360DatahubApi.endpoints.getAccountsFromHouseholdId.select(request)
)

const selectSearchAccountsApiResult = (state: AppState) =>
  selectSearchAccountsApiResultSelector(state)(state as any)

const selectSearchAccounts = createSelector(
  [selectSearchAccountsApiResult],
  (result) => result.data
)

const selectSearchAccountsLookupById = createSelector(
  [selectSearchAccounts],
  (accounts) => keyBy(accounts, ({ id }) => id || '')
)

const selectCdmv2AccountsApiResultSelector = createSelector(
  [selectHouseholdApiRequest],
  (request) =>
    rdot360DatahubApi.endpoints.rdot360_getAccountNicknames.select(request)
)

const selectCdmv2AccountsApiResult = (state: AppState) =>
  selectCdmv2AccountsApiResultSelector(state)(state as any)

const selectCdmv2Accounts = createSelector(
  [selectCdmv2AccountsApiResult],
  (result) => result.data
)

const selectCdmv2AccountsLookupByAccountId = createSelector(
  [selectCdmv2Accounts],
  (accounts) => keyBy(accounts, ({ accountID }) => accountID || '')
)

const selectCdmv2SelectedAccounts = createSelector(
  [
    getUiSelectedAccounts,
    selectSearchAccountsLookupById,
    selectCdmv2AccountsLookupByAccountId
  ],
  (selectedAccountIds, searchLookup, cdmv2Lookup) =>
    selectedAccountIds && searchLookup && cdmv2Lookup
      ? selectedAccountIds
          .map((x) => searchLookup[x])
          .map(({ accountId = '' } = {}) => cdmv2Lookup[accountId])
          .filter(isNotNullOrUndefined)
      : []
)

const selectPreferredNicknameLookupByAccountId = createSelector(
  [
    selectSearchAccounts,
    selectCdmv2AccountsLookupByAccountId,
    selectPreferredNickNamePreference
  ],
  (searchAccounts, cdmv2AccountsLookup, preferredNicknamePreference) =>
    searchAccounts?.reduce((acc, item) => {
      if (!item.accountId) {
        return acc
      }

      const cdmV2Account = cdmv2AccountsLookup[item.accountId]
      const preferredNickname = mapToPreferredNickname(
        cdmV2Account,
        preferredNicknamePreference
      )

      return {
        ...acc,
        [item.accountId]: preferredNickname || ''
      }
    }, {} as Record<string, string>) || {}
)

const selectClientDashboardAccounts = createSelector(
  [selectSearchAccounts, selectPreferredNicknameLookupByAccountId],
  (accounts, preferredNicknameLookup) =>
    accounts?.map((apiAccount) => {
      return {
        ...apiAccount,
        preferredNickname: preferredNicknameLookup[apiAccount.accountId || '']
      } as IRdot360Account
    })
)

export const selectAccountLookupByAccountIdOrKey = createSelector(
  [selectClientDashboardAccounts],
  (accounts) => {
    const byId = keyBy(accounts, ({ id }) => id || '')
    const byAccountId = keyBy(accounts, ({ accountId }) => accountId || '')
    const byKey = keyBy(
      accounts,
      ({ accountkey, id }) => accountkey || id || ''
    )

    return { ...byId, ...byAccountId, ...byKey } as Record<
      string,
      IRdot360Account | undefined
    >
  }
)

const selectSelectedAccounts = createSelector(
  [getUiSelectedAccounts, selectAccountLookupByAccountIdOrKey],
  (selectedAccountIds, lookup) =>
    selectedAccountIds.map((x) => lookup[x]).filter(isNotNullOrUndefined)
)

export const selectSelectedAccountKeys = createSelector(
  [selectSelectedAccounts],
  (accounts) =>
    accounts
      .map(({ accountkey, id }) => accountkey || id)
      .filter(isNotNullOrEmpty)
)

export const selectPreferenceAccounts = createSelector(
  [selectClientDashboardAccounts, selectIncludeClosedAccountsPreference],
  (accounts, includeClosedAccounts) =>
    accounts?.filter((x) => includeClosedAccounts || x.accountStatus === 'Open')
)

const selectIsMultiMarginInSelectedAccounts = createSelector(
  [selectSelectedAccounts],
  (selectedAccounts) => selectedAccounts.some(isMultiMargin)
)

const selectNonIndividualPartyIds = createSelector(
  [selectCdmv2Accounts],
  (cdmV2Accounts) =>
    uniq(
      cdmV2Accounts
        ?.map((acct) =>
          acct.stakeholders
            ?.filter((x) => x?.party?.partyType !== 'Individual')
            ?.map((x) => x?.partyID)
        )
        ?.flat()
        ?.filter(isNotNullOrUndefined)
    )
)
const selectIndividualPartyIds = createSelector(
  [selectCdmv2Accounts],
  (cdmV2Accounts) =>
    uniq(
      cdmV2Accounts
        ?.map((acct) =>
          acct.stakeholders
            ?.filter((x) => x?.party?.partyType === 'Individual')
            ?.map((x) => x?.partyID)
        )
        ?.flat()
        ?.filter(isNotNullOrUndefined)
    )
)

const selectIsFetching = createSelector(
  [selectSearchAccountsApiResult, selectCdmv2AccountsApiResult],
  (searchResult, cdmv2Result) => searchResult.isLoading || cdmv2Result.isLoading
)

const selectError = createSelector(
  [selectSearchAccountsApiResult, selectCdmv2AccountsApiResult],
  (searchResult, cdmv2Result) => searchResult.error || cdmv2Result.error
)

const emptyResultCdmv2 = { static: 'useRdot360_getAccountNicknamesQuery' }
const emptyResultSearch = { static: 'useGetAccountsFromHouseholdIdQuery' }
export const useRdot360AccountContext = () => {
  const {
    selectedAccountIds,
    setSelectedAccounts,
    includeClosedAccounts,
    setIncludeClosedAccounts
  } = useRdot360Context()

  const setSelectedAccountIds = useCallback(
    (ids: string[]) => setSelectedAccounts(ids),
    [setSelectedAccounts]
  )

  const request = useSelector(selectHouseholdApiRequest)
  useRdot360_getAccountNicknamesQuery(request, {
    selectFromResult: () => emptyResultCdmv2
  })
  useGetAccountsFromHouseholdIdQuery(request, {
    selectFromResult: () => emptyResultSearch
  })

  const cdmv2Accounts = useSelector(selectCdmv2Accounts)
  const cdmv2AccountsLookupByAccountId = useSelector(
    selectCdmv2AccountsLookupByAccountId
  )
  const cdmv2SelectedAccounts = useSelector(selectCdmv2SelectedAccounts)
  const clientDashboardAccounts = useSelector(selectClientDashboardAccounts)
  const accountLookupByAccountIdOrKey = useSelector(
    selectAccountLookupByAccountIdOrKey
  )
  const selectedAccounts = useSelector(selectSelectedAccounts)
  const selectedAccountKeys = useSelector(selectSelectedAccountKeys)
  const accounts = useSelector(selectPreferenceAccounts)
  const isMultiMarginInSelectedAccounts = useSelector(
    selectIsMultiMarginInSelectedAccounts
  )
  const nonIndividualPartyIds = useSelector(selectNonIndividualPartyIds)
  const individualPartyIds = useSelector(selectIndividualPartyIds)
  const isFetching = useSelector(selectIsFetching)
  const error = useSelector(selectError)

  return {
    householdAccounts: clientDashboardAccounts, // all accounts
    accounts, // depends on closed indicator
    selectedAccountIds,
    selectedAccountKeys,
    selectedAccounts,
    setSelectedAccountIds,
    includeClosedAccounts,
    setIncludeClosedAccounts,
    accountLookupByAccountIdOrKey,
    cdmv2HouseholdAccounts: cdmv2Accounts,
    cdmv2SelectedAccounts,
    cdmv2HouseholdAccountLookupByAccountId: cdmv2AccountsLookupByAccountId,
    isMultiMarginInSelectedAccounts,
    nonIndividualPartyIds,
    individualPartyIds,
    isFetching,
    error
  }
}
