import axios from 'axios'
import { IDragDropFile } from 'shared/components/DragDrop'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { IManifest, IStatementDetail } from '../store/types'

export const UploadManifestFileSevice = (
  options: IApiOptions,
  doc: IDragDropFile,
  asOfDate: string,
  onUploadProgress?: (
    progressEvent: ProgressEvent<EventTarget>,
    file: IDragDropFile
  ) => void
) => {
  const url = `${options.apiRoot}/trustapi/api/GWES/UploadManifestFile`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  const data = new FormData()
  data.append('filecontents', doc.file)
  data.append('asOfDate', asOfDate)

  return axios
    .post(url, data, {
      headers,
      cancelToken: options.cancelToken,
      onUploadProgress: onUploadProgress
        ? (progressEvent) => {
            onUploadProgress(progressEvent, doc)
          }
        : undefined
    })
    .then((res) => res.data)
}

export const DownloadManifestFileService = (
  options: IApiOptions,
  fileName: string
) => {
  const url = `${options.apiRoot}/trustapi/api/GWES/DownloadManifest?fileName=${fileName}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const DownloadManifestStatementService = (
  options: IApiOptions,
  fileName: string
) => {
  const url = `${options.apiRoot}/trustapi/api/GWES/DownloadStatement?fileName=${fileName}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchManifestListService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/api/GWES/GetManifestList`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IManifest[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
export const fetchStatementDetailsService = (
  options: IApiOptions,
  manifestName: string
) => {
  const url = `${options.apiRoot}/trustapi/api/GWES/GetStatementDetails?manifestName=${manifestName}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IStatementDetail[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
