import axios from 'axios'
import { push } from 'connected-react-router'
import { get } from 'lodash'
import { DragDropFileStatus } from 'shared/components/DragDrop'
import { getLocation } from 'shared/services/dom'
import { IEnvironmentApiConfiguration } from 'shared/services/environment/IEnvironmentConfiguration'
import {
  call,
  cancelled,
  delay,
  put,
  select,
  takeEvery,
  takeLatest
} from 'typed-redux-saga'
import { IApiOptions } from '../../../../shared/contracts/IApiOptions'
import { exportDataToExcel } from '../../../../shared/xlsx'
import {
  getRockefellerApiOptions,
  navigate,
  tryAcquireAccessToken
} from '../../../../store/shared/sagas'
import { getRockefellerApiConfig } from '../../../../store/system'
import { downloadFile } from '../../../Reports/features/Dashboard/Utilities'
import {
  fetchDraftPlansForApprovalDashboard,
  handleApproverActionApprovalDashboardService,
  fetchComparisionDataApprovalDashboardService
} from '../api/approvalDashboardServices'
import {
  closeMonthService,
  deleteStatementService,
  downloadD365ExcelService,
  downloadFundDocService,
  fetchAccrualReportService,
  fetchDocListService,
  fetchDocTypeService,
  fetchFundProvidersExcelService,
  fetchMasterLookupService,
  fetchPlanProviderDocsService,
  fetchPlanProvidersService,
  fetchPlansByPlanProviderService,
  fetchPlansToDistributeCashService,
  fetchProductPlanAllocationsService,
  fetchProductPlansService,
  fetchProductPlanTransactionsService,
  fetchProjectedAmountByPlanIdService,
  insertUpdatePlanProviderService,
  updateCashReceiptToPlansService,
  updatePlanByproviderService,
  updatePlanProviderDocService,
  updateProjectedAmountByPlanService,
  deleteFundDocService,
  uploadFundDocService,
  uploadProductDocumentService,
  viewStatementService,
  fetchCashReceiptTrendReportService,
  fetchOpenReceivablesReportService,
  fetchAccrualReportDrilldownService,
  fetchSponsorNamesService,
  fetchClassificationTypesService,
  fetchDraftPlansByProviderService,
  fetchAIPlansApprovalDashboardService, ////approval dashboard
  fetchCommittedPlanService,
  fetchWorkflowHistoryApprovalDashboardService,
  fetchManualUploadService,
  downloadManualAccrualService,
  deleteManualAccrualService,
  uploadManualAccrualDocService
} from '../api/productServices'

import { getMonthYearStringFromSelectedMonth } from '../Retirement/store/utility'

import {
  closeMonthActions,
  deleteStatementActions,
  downloadD365ExcelActions,
  downloadFundDocActions,
  exportPlansToExcelActions,
  exportToExcelActions,
  fetchFeeTypesActions,
  fetchPlanProvidersActions,
  fetchPlanProvidersDocsActions,
  fetchAIPlansApproveDashboardActions, ////TODO: approval dashboard
  fetchPlansByProviderActions,
  fetchPlansToDistributeCashActions,
  fetchProductPlanAllocationActions,
  fetchProductPlansAction,
  fetchProjectedAmountByPlansActions,
  fetchProvidersforExcelActions,
  fetchRetirementPlanTransactionsAction,
  fetchUploadDocListActions,
  fetchUploadDocTypeActions,
  navigateactions,
  productNavigationActions,
  setSelectedPlan,
  updateCashReceiptToPlanActions,
  updatePlanPlanByProviderActions,
  updatePlanProviderActions,
  updatePlanProviderDocActions,
  updateProjectedAmountByPlansActions,
  uploadFundDocActions,
  uploadProductDocumentActions,
  viewStatementActions,
  fetchCashReceiptTrendReportActions,
  exportExcelCashReceiptTrendReportActions,
  fetchAccrualReport,
  exportAccrualReportToExcelActions,
  fetchOpenReceivablesReportActions,
  exportOpenReceivablesReportActions,
  fetchAccrualReportDrilldownListActions,
  fetchExistingSponsorNamesActions,
  exportTrendReportDrilldownToExcelActions,
  fetchClassificationTypesActions,
  fetchDraftPlansByProviderServiceTypesActions,
  fetchAIDRAFTPlansApproveDashboardActions,
  fetchComparisonApproveDashboardActions,
  handleApproverActionApprovalDashboard,
  fetchCommittedActions,
  fetchDraftPlansActions,
  fetchWorkflowPlanHistoryApproveDashboardActions,
  fetchManualUploadActions,
  downloadManualAccrualActions,
  deleteManualAccrualActions,
  uploadManualActions,
  uploadManualAccrualActions,
  deleteFundDocActions
} from './actions'
import {
  getPlansToDistributeCashState,
  getProjectedAmountByPlanState,
  getSelectedFeeType,
  getSelectedMonthYear,
  getSelectedPlan
} from './selectors'
import { ICashAllocationWithIds, IUploadFundres } from './types'
import { b64toBlob, getChangedCashAllocations, getPlanFee } from './utility'

function* handleFetchRetirementProductPlanTransactions(
  action: ReturnType<typeof fetchRetirementPlanTransactionsAction.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchProductPlanTransactionsService(action.payload, options)
    )

    yield put(fetchRetirementPlanTransactionsAction.success(data?.[0] || {}))
  } catch (e: any) {
    yield put(fetchRetirementPlanTransactionsAction.failure(e))
  }
}

function* handleFetchPlansToDistributeCash(
  action: ReturnType<typeof fetchPlansToDistributeCashActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const selectedPlan = yield* select(getSelectedPlan)
    const monthYear: string = yield select(getSelectedMonthYear)
    const data = yield* call(() =>
      fetchPlansToDistributeCashService(
        action.payload.planToDistributeCash,
        selectedPlan,
        options,
        action.payload.productType,
        action.payload.feetype,
        monthYear
      )
    )
    yield put(fetchPlansToDistributeCashActions.success(data || []))
  } catch (e: any) {
    yield put(fetchPlansToDistributeCashActions.failure(e))
  }
}

function* handleFetchProductPlans(
  action: ReturnType<typeof fetchProductPlansAction.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const monthYear: string = yield select(getSelectedMonthYear)
    const feetype: string = yield select(getSelectedFeeType)
    const source = action.payload as string
    const data = yield* call(() =>
      fetchProductPlansService(monthYear, options, source, feetype)
    )
    yield put(fetchProductPlansAction.success(data || []))
  } catch (e: any) {
    yield put(fetchProductPlansAction.failure(e))
  }
}
function* handleFetchPlanProviders(
  action: ReturnType<typeof fetchPlanProvidersActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const producttype = action.payload
    const data = yield* call(() =>
      fetchPlanProvidersService(options, producttype)
    )
    yield put(fetchPlanProvidersActions.success(data || []))
  } catch (e: any) {
    yield put(fetchPlanProvidersActions.failure(e))
  }
}
function* handleFetchProvidersforExcel(
  action: ReturnType<typeof fetchProvidersforExcelActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const producttype = action.payload.producttype
    const data = yield* call(() =>
      fetchFundProvidersExcelService(options, producttype)
    )

    yield put(fetchProvidersforExcelActions.success(data || []))
    yield put(
      exportToExcelActions.request({
        plans: data || [],
        columns: action.payload.columns,
        producttype: producttype
      })
    )
  } catch (e: any) {
    yield put(fetchProvidersforExcelActions.failure(e))
  }
}

//TODO: approval dashboard
function* handleFetchAIFundsApprovalDashboardService() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchAIPlansApprovalDashboardService(options)
    )
    // data[0].workflowstate = 'REJECTED'
    yield put(
      fetchAIPlansApproveDashboardActions.success({ aiFunds: data || [] })
    )
  } catch (e: any) {
    yield put(
      fetchAIPlansApproveDashboardActions.failure({
        error: e
      })
    )
  }
}

//TODO: approval dashboard
function* handleFetchWorkflowHistoryApprovalDashboardService(
  action: ReturnType<
    typeof fetchWorkflowPlanHistoryApproveDashboardActions.request
  >
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchWorkflowHistoryApprovalDashboardService(
        options,
        action.payload.draftId
      )
    )

    yield put(
      fetchWorkflowPlanHistoryApproveDashboardActions.success({
        workflowHistory: data || []
      })
    )
  } catch (e: any) {
    yield put(
      fetchWorkflowPlanHistoryApproveDashboardActions.failure({
        error: e
      })
    )
  }
}

function* handleFetchAIDraftApprovalDashboardService(
  action: ReturnType<typeof fetchAIDRAFTPlansApproveDashboardActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchDraftPlansForApprovalDashboard(
        options,
        action.payload.providerId,
        action.payload.planId,
        action.payload.draftId
      )
    )
    yield put(fetchAIDRAFTPlansApproveDashboardActions.success(data))
  } catch (e: any) {
    yield put(
      fetchAIDRAFTPlansApproveDashboardActions.failure({
        error: e
      })
    )
  }
}

function* fetchComparisionData(
  action: ReturnType<typeof fetchComparisonApproveDashboardActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchComparisionDataApprovalDashboardService(
        options,
        action.payload.providerId,
        action.payload.planId,
        action.payload.draftId
      )
    )
    if (data) {
      data.providerid = action.payload?.providerId
    }
    yield put(fetchComparisonApproveDashboardActions.success(data))
  } catch (e: any) {
    yield put(
      fetchComparisonApproveDashboardActions.failure({
        error: e
      })
    )
  }
}

function* handleApproverAction(
  action: ReturnType<typeof handleApproverActionApprovalDashboard.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      handleApproverActionApprovalDashboardService(options, action.payload)
    )
    yield put(handleApproverActionApprovalDashboard.success(data))
  } catch (e: any) {
    yield put(
      handleApproverActionApprovalDashboard.failure({
        error: e
      })
    )
  }
}

function* handleFetchPlansByProvider(
  action: ReturnType<typeof fetchPlansByProviderActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchPlansByPlanProviderService(
        action.payload?.planProvider,
        action.payload.source,
        options,
        action.payload.selectedOption,
        action.payload.planId
      )
    )
    yield put(
      fetchPlansByProviderActions.success({
        planproviderId: action.payload?.planProvider?.planproviderid || 0,
        plans: data || []
      })
    )
  } catch (e: any) {
    yield put(
      fetchPlansByProviderActions.failure({
        planproviderId: action.payload?.planProvider?.planproviderid || 0,
        error: e
      })
    )
  }
}

function* handleFetchCommittedPlanAction(
  action: ReturnType<typeof fetchCommittedActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchCommittedPlanService(
        action.payload?.planProviderId,
        action.payload?.productType,
        action.payload?.PlanId,
        options
      )
    )
    yield put(
      fetchCommittedActions.success({
        plans: data
      })
    )
    yield put(
      fetchDraftPlansActions.success({
        plans: data
      })
    )
  } catch (e: any) {
    yield put(
      fetchCommittedActions.failure({
        error: e
      })
    )
  }
}

function* handleUpdatePlanProvider(
  action: ReturnType<typeof updatePlanProviderActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    yield* call(() =>
      insertUpdatePlanProviderService(
        action.payload.planProvider,
        action.payload.source,
        options
      )
    )
    yield put(updatePlanProviderActions.success())
    yield put(fetchPlanProvidersActions.request(action.payload.source))
  } catch (e: any) {
    yield put(updatePlanProviderActions.failure(e))
  }
}

function* handleUpdatePlanByProvider(
  action: ReturnType<typeof updatePlanPlanByProviderActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)

    const data = yield* call(() =>
      updatePlanByproviderService(
        action.payload.plan,
        action.payload.provider,
        options,
        action.payload.source
      )
    )

    if (data.status.toString() === 'Success') {
      if (action?.payload?.source === 'alts') {
        if (action.payload.plan.actionindicator === 'Draft') {
          data.message = `Fund ${
            action.payload.plan?.fundname || ''
          } draft has been saved successfully`
        } else {
          data.message = `Fund ${
            action.payload.plan?.fundname || ''
          } submitted successfully`

          data.isDisableButtons = true
        }
      } else {
        data.message = `Plan ${
          action.payload.plan?.planname || ''
        } has been saved successfully`
      }
    }
    yield put(updatePlanPlanByProviderActions.success(data))
  } catch (e: any) {
    yield put(updatePlanPlanByProviderActions.failure(e))
  }
}
function* handleFetchFeeTypes(
  action: ReturnType<typeof fetchFeeTypesActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchMasterLookupService(options, action.payload.type)
    )
    yield put(
      fetchFeeTypesActions.success(
        data || {
          plantype: null,
          planfeetype: null,
          paymentsource: null,
          feetype: [],
          lookup: []
        }
      )
    )
  } catch (e: any) {
    yield put(fetchFeeTypesActions.failure(e))
  }
}

function* handleFetchUploadDocTypes(
  action: ReturnType<typeof fetchUploadDocTypeActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() => fetchDocTypeService(options, action.payload))
    yield put(fetchUploadDocTypeActions.success(data))
  } catch (e: any) {
    yield put(fetchUploadDocTypeActions.failure(e))
  }
}
function* handleFetchUploadDocList(
  action: ReturnType<typeof fetchUploadDocListActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() => fetchDocListService(options, action.payload))
    yield put(fetchUploadDocListActions.success(data))
  } catch (e: any) {
    yield put(fetchUploadDocListActions.failure(e))
  }
}
function* handleUpdateCashReceiptToPlan(
  action: ReturnType<typeof updateCashReceiptToPlanActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const selectedPlan = yield* select(getSelectedPlan)

    //filter only updated onces
    const { plans } = (yield* select(getPlansToDistributeCashState)) || {}

    // updated code to identify the changed cash allocations
    let changedCashAllocations: ICashAllocationWithIds[] = []
    action.payload?.plans?.map((plan) => {
      const originalPlanRecord = plans?.filter(
        (x) => x.retirementplanid === plan.retirementplanid
      )?.[0]
      const changedAllocationsByPlan =
        getChangedCashAllocations(
          plan?.cashallocations || [],
          originalPlanRecord?.cashallocations || []
        ) || []

      const changedAllocationsByPlanWithId = changedAllocationsByPlan.map(
        (x) =>
          ({
            ...x,
            retirementplanid: originalPlanRecord?.retirementplanid,
            accountnumber: plan?.accountnumber,
            cusip: plan?.cusip
          } as ICashAllocationWithIds)
      )

      changedCashAllocations = changedCashAllocations.concat(
        changedAllocationsByPlanWithId
      )
    })

    yield* call(() =>
      updateCashReceiptToPlansService(
        action.payload.cashReceipt,
        selectedPlan,
        changedCashAllocations,
        options,
        action.payload.feetype
      )
    )

    yield put(updateCashReceiptToPlanActions.success())
    yield put(
      fetchPlansToDistributeCashActions.request({
        planToDistributeCash: action.payload.cashReceipt,
        productType: action.payload.productType,
        feetype: action.payload.feetype
      })
    )
  } catch (e: any) {
    yield put(updateCashReceiptToPlanActions.failure(e))
  }
}
function* handleNavigateToTransaction(
  action: ReturnType<typeof productNavigationActions.navigatetoTransaction>
) {
  const { plan, producttype } = action.payload
  yield put(setSelectedPlan.selectedPlan(plan))

  let { pathname } = yield* call(getLocation)
  const indexofSlash = pathname.lastIndexOf(`/`)
  if (indexofSlash === pathname.length - 1) {
    pathname = pathname.slice(0, -1)
  }
  const path = `${pathname}/transactions/id/${producttype}/${plan?.planid}`
  yield put(push({ pathname: path }))
}

function* handleExportPlansToExcel(
  action: ReturnType<typeof exportPlansToExcelActions.request>
) {
  try {
    const { plans, columns, producttype } = action.payload
    const filename =
      producttype === 'AI' ? 'Alts_Plans.xlsx' : 'Retirement_Plans.xlsx'
    const wsName = producttype === 'AI' ? 'Funds' : 'Plans'

    const data = plans?.map((plan) => {
      const excelPlan = {
        ...plan,
        accruedamount: plan.accrualamount,
        projectedamount: plan.projectedamount || '',
        plansponcerstate: plan?.plansponser?.state || '',
        planfee: getPlanFee(plan)
      }
      return columns?.map((column): unknown =>
        column.fieldName ? get(excelPlan, column.fieldName) : ''
      )
    })

    data?.unshift(
      columns?.filter((x) => x.key !== 'details')?.map((x) => x.name)
    )

    yield call(() =>
      exportDataToExcel(
        {
          sheets: [{ name: wsName, data: data || [] }]
        },
        filename
      )
    )
    yield put(exportPlansToExcelActions.success())
  } catch (e: any) {
    yield put(exportPlansToExcelActions.failure(e))
  }
}
function* handleExportToExcel(
  action: ReturnType<typeof exportToExcelActions.request>
) {
  try {
    const { plans, columns, producttype } = action.payload

    const filename =
      producttype === producttype ? 'Alts_Funds.xlsx' : 'Retirement_Plans.xlsx'
    const wsName = producttype === 'AI' ? 'Funds' : 'Plans'

    const data = plans?.map((plan) => {
      const excelPlan = {
        ...plan,
        PlanProviderID: plan.PlanProviderID,
        providername: plan.providername || '',
        planname: plan.planname
      }
      return columns?.map((column): unknown =>
        column.fieldName ? get(excelPlan, column.fieldName) : ''
      )
    })

    data?.unshift(
      columns?.filter((x) => x.key !== 'details')?.map((x) => x.name)
    )

    yield call(() =>
      exportDataToExcel(
        {
          sheets: [{ name: wsName, data: data || [] }]
        },
        filename
      )
    )
    yield put(exportPlansToExcelActions.success())
  } catch (e: any) {
    yield put(exportPlansToExcelActions.failure(e))
  }
}

function* handleDownloadD365Excel(
  action: ReturnType<typeof downloadD365ExcelActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const producttype = action.payload
    const monthYear: string = yield select(getSelectedMonthYear)
    const data = yield* call(() =>
      downloadD365ExcelService(monthYear, options, producttype)
    )
    const filename = producttype === 'AI' ? 'D356-Alts' : 'D356-Retirement'
    downloadFile(data || '', filename, '.xlsx')
    yield put(downloadD365ExcelActions.success())
  } catch (e: any) {
    yield put(downloadD365ExcelActions.failure(e))
  }
}
function* handleCloseMonth(
  action: ReturnType<typeof closeMonthActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    yield* call(() =>
      closeMonthService(
        action.payload.selectedMonthYear,
        action.payload.producttype,
        options
      )
    )
    yield put(
      closeMonthActions.success(
        `Month ${getMonthYearStringFromSelectedMonth(
          action.payload.selectedMonthYear
        )} has been closed successfully`
      )
    )
  } catch (e: any) {
    yield put(closeMonthActions.failure(e))
  }
}
function* handleViewStatement(
  action: ReturnType<typeof viewStatementActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      viewStatementService(action.payload, options)
    )

    yield put(viewStatementActions.success())
    const blob = b64toBlob(data)
    const fileURL = URL.createObjectURL(blob)
    window.open(fileURL, 'statementWindow')
  } catch (e: any) {
    yield put(viewStatementActions.failure(e))
  }
}
function* handleNavigateToProjectedAmountbyPlan(
  action: ReturnType<
    typeof productNavigationActions.navigateToProjectedAmountByPlan
  >
) {
  const { plan, producttype } = action.payload
  yield put(setSelectedPlan.selectedPlan(plan))
  let { pathname } = yield* call(getLocation)
  const indexofSlash = pathname.lastIndexOf(`/`)
  if (indexofSlash === pathname.length - 1) {
    pathname = pathname.slice(0, -1)
  }
  const path = `${pathname}/projectedamount/plan/${producttype}/${plan?.planid}`
  yield put(push({ pathname: path }))
}
function* handleUploadProductDoc(
  action: ReturnType<typeof uploadProductDocumentActions.uploadDoc>
) {
  const options = yield* call(getRockefellerApiOptions)
  const { onUploadSuccess, onUploadFail, onUploadProgress, productDoc } =
    action.payload
  try {
    yield call(
      uploadProductDocumentService,
      productDoc,
      options,
      onUploadProgress
    )
    onUploadSuccess(productDoc.file)
    if (productDoc.source && productDoc.source === 'multiupload') {
      yield put(
        fetchPlanProvidersDocsActions.request({
          planproviderid:
            productDoc.planproviderid && productDoc.planproviderid !== null
              ? parseInt(productDoc.planproviderid)
              : null,
          producttype: productDoc.producttype,
          planid: productDoc?.isPlanSpecific ? Number(productDoc.planid) : null
        })
      )
    }
  } catch (e: any) {
    onUploadFail(productDoc.file)
  }
}

function* handleFetchProjectedAmountByPlan(
  action: ReturnType<typeof fetchProjectedAmountByPlansActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const selectedfeetype: string = yield select(getSelectedFeeType)
    const requestJson = {
      planid: action.payload.selectedPlan?.planid || '',
      cusip: action.payload.selectedPlan?.cusip || '',
      accountnumber: action.payload.selectedPlan?.accountnumber || '',
      feetype: selectedfeetype || '',
      producttype: action.payload.producttype
    }
    const data = yield* call(() =>
      fetchProjectedAmountByPlanIdService(requestJson, options)
    )
    yield put(fetchProjectedAmountByPlansActions.success(data || []))
  } catch (e: any) {
    yield put(fetchProjectedAmountByPlansActions.failure(e))
  }
}
function* handleUpdateProjectedAmountByPlan(
  action: ReturnType<typeof updateProjectedAmountByPlansActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const { projectedAmounts } =
      (yield* select(getProjectedAmountByPlanState)) || {}
    const filteredData = action.payload?.filter(
      (x) =>
        !projectedAmounts?.some(
          (y) =>
            y.amount === x.amount &&
            y.description === x.description &&
            x.projecteddate === y.projecteddate
        )
    )

    yield* call(() => updateProjectedAmountByPlanService(filteredData, options))
    yield put(updateProjectedAmountByPlansActions.success())
  } catch (e: any) {
    yield put(updateProjectedAmountByPlansActions.failure(e))
  }
}
function* handleNavigateToPlan(
  action: ReturnType<typeof productNavigationActions.navigateToPlan>
) {
  const path = `/products/${action.payload}`
  yield put(push({ pathname: path }))
}
function* handleFetchProductPlanAllocations(
  action: ReturnType<typeof fetchProductPlanAllocationActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchProductPlanAllocationsService(action.payload, options)
    )

    yield put(fetchProductPlanAllocationActions.success(data?.[0] || {}))
  } catch (e: any) {
    yield put(fetchProductPlanAllocationActions.failure(e))
  }
}
function* LaunchFundDocs(
  action: ReturnType<typeof navigateactions.launchFundDoc>
) {
  const url: string = action.payload
  // yield put(viewStatementActions.success())
  yield call(navigate, url, 'fund Doc window')
}

function* handleDeleteFundDoc(
  action: ReturnType<typeof deleteFundDocActions.request>
) {
  const options = yield* call(getRockefellerApiOptions)

  try {
    const data: IUploadFundres = yield* call(() =>
      deleteFundDocService(
        action.payload.storagedocdraftid,
        action.payload.draftid,
        options
      )
    )
    if (data && data.status === 'Success') {
      yield put(deleteFundDocActions.success(data))
    } else {
      yield put(
        deleteFundDocActions.failure(
          new Error('Unable to Delete Fund Doc.Please try again later')
        )
      )
    }
  } catch (e: any) {
    yield put(deleteFundDocActions.failure(e))
  }
}

function* handleUploadFundDoc(
  action: ReturnType<typeof uploadFundDocActions.request>
) {
  const options = yield* call(getRockefellerApiOptions)

  try {
    const data: IUploadFundres = yield* call(() =>
      uploadFundDocService(action.payload, options)
    )
    if (data && data.status === 'Success') {
      yield put(uploadFundDocActions.success(data))
    } else {
      yield put(
        uploadFundDocActions.failure(
          new Error('Unable to upload Fund Doc.Please try again later')
        )
      )
    }
  } catch (e: any) {
    yield put(uploadFundDocActions.failure(e))
  }
}

function* handleFetchClassificationTypes() {
  const options = yield* call(getRockefellerApiOptions)

  try {
    const data = yield* call(() => fetchClassificationTypesService(options))

    if (data) {
      yield put(fetchClassificationTypesActions.success(data))
    } else {
      yield put(
        fetchClassificationTypesActions.failure(
          new Error(
            'Unable to fetch classification types. Please try again later'
          )
        )
      )
    }
  } catch (e: any) {
    yield put(fetchClassificationTypesActions.failure(e))
  }
}

function* handleFetchDraftPlansByProviderTypes(
  action: ReturnType<
    typeof fetchDraftPlansByProviderServiceTypesActions.request
  >
) {
  const options = yield* call(getRockefellerApiOptions)
  try {
    const data = yield* call(() =>
      fetchDraftPlansByProviderService(
        options,
        action.payload.draftId,
        action.payload.planproviderid
      )
    )

    if (data) {
      yield put(
        fetchPlansByProviderActions.success({
          planproviderId: action.payload.planproviderid || 0,
          plans: data || []
        })
      )
    } else {
      yield put(
        fetchDraftPlansByProviderServiceTypesActions.failure(
          new Error(
            'Unable to fetch DraftPlansByProviderService types. Please try again later'
          )
        )
      )
    }
  } catch (e: any) {
    yield put(fetchDraftPlansByProviderServiceTypesActions.failure(e))
  }
}

function* handleFetchDraftPlanDetailsTypes(
  action: ReturnType<typeof fetchDraftPlansActions.request>
) {
  const options = yield* call(getRockefellerApiOptions)
  try {
    const data = yield* call(() =>
      fetchDraftPlansByProviderService(
        options,
        action.payload.draftId,
        action.payload.planproviderid
      )
    )

    if (data) {
      yield put(
        fetchDraftPlansActions.success({
          plans: data || []
        })
      )
    } else {
      yield put(
        fetchDraftPlansActions.failure({
          error: new Error(
            'Unable to fetch DraftPlansByProviderService types. Please try again later'
          )
        })
      )
    }
  } catch (e: any) {
    yield put(fetchDraftPlansActions.failure(e))
  }
}

function* handleDownloadFundDoc(
  action: ReturnType<typeof downloadFundDocActions.request>
) {
  const options = yield* call(getRockefellerApiOptions)

  try {
    const data: string = yield* call(() =>
      downloadFundDocService(
        action.payload.storagedocdraftid,
        action.payload.storagedocid,
        options,
        action.payload.draftid
      )
    )
    yield put(downloadFundDocActions.success())
    const blob = b64toBlob(data)
    const fileURL = URL.createObjectURL(blob)
    window.open(fileURL, 'DocumentWindow')
  } catch (e: any) {
    yield put(downloadFundDocActions.failure(e))
  }
}

function* handleFetchPlanProviderDocs(
  action: ReturnType<typeof fetchPlanProvidersDocsActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)

    const data = yield* call(() =>
      fetchPlanProviderDocsService(options, action.payload)
    )
    yield put(fetchPlanProvidersDocsActions.success(data || []))
  } catch (e: any) {
    yield put(fetchPlanProvidersDocsActions.failure(e))
  }
}
function* handleDeleteStatement(
  action: ReturnType<typeof deleteStatementActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)

    yield* call(() =>
      deleteStatementService(options, action.payload.plandocumentid)
    )
    yield put(deleteStatementActions.success())
    yield put(
      fetchPlanProvidersDocsActions.request({
        planproviderid:
          action.payload.planproviderid &&
          action.payload.planproviderid !== null
            ? parseInt(action.payload.planproviderid)
            : null,
        producttype: action.payload.producttype,
        planid: action.payload.isPlanSpecific ? action.payload.planid : null
      })
    )
  } catch (e: any) {
    yield put(deleteStatementActions.failure(e))
  }
}
function* handleUpdatePlanProviderDoc(
  action: ReturnType<typeof updatePlanProviderDocActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)

    yield* call(() => updatePlanProviderDocService(action.payload, options))
    yield put(updatePlanProviderDocActions.success())
    yield put(
      fetchPlanProvidersDocsActions.request({
        planproviderid: action.payload.planProviderDoc.planproviderid
          ? parseInt(action.payload.planProviderDoc.planproviderid)
          : null,
        producttype: action.payload.productType
      })
    )
  } catch (e: any) {
    yield put(updatePlanProviderDocActions.failure(e))
  }
}
function* handleFetchAccrualReport(
  action: ReturnType<typeof fetchAccrualReport.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const monthYear: string = action.payload.monthyear || ''
    const feetype: string = action.payload.feetypecode || ''

    const data = yield* call(() =>
      fetchAccrualReportService(monthYear, options, feetype)
    )
    if (data) {
      yield put(fetchAccrualReport.success(data))
    }
  } catch (e: any) {
    yield put(fetchAccrualReport.failure(e))
  }
}
function* handleExportOpenReceivablesReportToExcel(
  action: ReturnType<typeof exportOpenReceivablesReportActions.export>
) {
  const { openReceivables, columns } = action.payload

  const filename = 'OpenReceivablesReport.xlsx'
  const wsName = 'OpenReceivables'
  const data = openReceivables?.map((a) => {
    const excelopenReceivables: any = {
      ...a
    }

    const trendeLength = a.trenddetails?.length || 0
    a.trenddetails?.map((t, key) => {
      excelopenReceivables[`amount${key}`] = t?.amount
      if (key <= trendeLength) {
        excelopenReceivables[`change${key}`] = t?.incdecvalue
        excelopenReceivables[`perc${key}`] = t?.percentage
      }
    })

    return columns?.map((column) =>
      column ? get(excelopenReceivables, column.fieldName || '') : ''
    )
  })
  data?.unshift(columns?.map((x) => x.name))

  yield call(() =>
    exportDataToExcel(
      {
        sheets: [{ name: wsName, data: data || [] }]
      },
      filename
    )
  )
}
function* handleExportAccrualReportToExcel(
  action: ReturnType<typeof exportAccrualReportToExcelActions.request>
) {
  try {
    const { accural, columns } = action.payload

    const filename = 'AccrualReport.xlsx'
    const wsName = 'Accrual'
    const data = accural?.map((a) => {
      const excelAccural: any = {
        ...a
      }

      const trendeLength = a.trenddetails?.length || 0
      a.trenddetails?.map((t, key) => {
        excelAccural[`amount${key}`] = t?.amount
        if (key <= trendeLength) {
          excelAccural[`change${key}`] = t?.incdecvalue
          excelAccural[`perc${key}`] = t?.percentage
        }
      })

      return columns?.map((column) =>
        column ? get(excelAccural, column.fieldName || '') : ''
      )
    })
    data?.unshift(columns?.map((x) => x.name))

    yield call(() =>
      exportDataToExcel(
        {
          sheets: [{ name: wsName, data: data || [] }]
        },
        filename
      )
    )
    yield put(exportAccrualReportToExcelActions.success())
  } catch (e: any) {
    yield put(exportAccrualReportToExcelActions.failure(e))
  }
}

function* handleFetchOpenReceivablesTrendReport(
  action: ReturnType<typeof fetchOpenReceivablesReportActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const monthYear: string = action.payload.monthyear || ''
    const feetype: string = action.payload.feetypecode || ''

    const data = yield* call(() =>
      fetchOpenReceivablesReportService(monthYear, options, feetype)
    )

    yield put(fetchOpenReceivablesReportActions.success(data || []))
  } catch (e: any) {
    yield put(fetchOpenReceivablesReportActions.failure(e))
  }
}

function* handleFetchCashReceiptTrendReport(
  action: ReturnType<typeof fetchCashReceiptTrendReportActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const monthYear: string = action.payload.monthyear || ''
    const feetype: string = action.payload.feetypecode || ''
    const data = yield* call(() =>
      fetchCashReceiptTrendReportService(monthYear, options, feetype)
    )

    if (data) {
      yield put(fetchCashReceiptTrendReportActions.success(data))
    }
  } catch (e: any) {
    yield put(fetchCashReceiptTrendReportActions.failure(e))
  }
}

function* handleExportExcelCashReceiptTrendReport(
  action: ReturnType<typeof exportExcelCashReceiptTrendReportActions.request>
) {
  try {
    const filename = 'CashReceiptTrend_Report.xlsx'
    const wsName = 'Report'

    const { cashReceiptTrendExportExcelData, columns } = action.payload

    const data = cashReceiptTrendExportExcelData?.map((a) => {
      const cashReceiptTrend: any = {
        ...a
      }

      const trendeLength = a.trenddetails?.length || 0
      a.trenddetails?.map((t, key) => {
        cashReceiptTrend[`amount${key}`] = t?.amount
        if (key <= trendeLength) {
          cashReceiptTrend[`change${key}`] = t?.incdecvalue
          cashReceiptTrend[`perc${key}`] =
            t?.percentage == null ? '' : t?.percentage + '%'
        }
      })

      return columns?.map((column) =>
        column ? get(cashReceiptTrend, column.fieldName || '') : ''
      )
    })

    data?.unshift(columns.map((x) => x.name))

    yield call(() =>
      exportDataToExcel(
        {
          sheets: [{ name: wsName, data: data || [] }]
        },
        filename
      )
    )
    yield put(exportExcelCashReceiptTrendReportActions.success())
  } catch (e: any) {
    yield put(exportExcelCashReceiptTrendReportActions.failure(e))
  }
}

function* handleFetchAccrualReportDrilldownList(
  action: ReturnType<typeof fetchAccrualReportDrilldownListActions.request>
) {
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token
    }
    const data = yield* call(() =>
      fetchAccrualReportDrilldownService(action.payload, options)
    )

    if (data) {
      yield put(fetchAccrualReportDrilldownListActions.success(data))
    }
  } catch (e: any) {
    yield put(fetchAccrualReportDrilldownListActions.failure(e))
  }
}

function* handleFetchSponsorNames(
  action: ReturnType<typeof fetchExistingSponsorNamesActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  try {
    const rockefellerApiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      rockefellerApiConfig.scopes
    )

    const options: IApiOptions = {
      apiRoot: rockefellerApiConfig.root,
      accessToken: token,
      cancelToken: source.token
    }
    const data = yield* call(() =>
      fetchSponsorNamesService(action.payload, options)
    )

    if (data) {
      yield put(fetchExistingSponsorNamesActions.success(data))
    }
  } catch (e: any) {
    yield put(fetchExistingSponsorNamesActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

function* handleTrendReportDrilldownExportToExcel(
  action: ReturnType<typeof exportTrendReportDrilldownToExcelActions.request>
) {
  try {
    const { columns, drilldown: drilldowndata } = action.payload
    const filename = 'Accrual Trend Report Drilldown.xlsx'
    const wsName = 'Accruals'

    const data = drilldowndata?.map((drilldown) => {
      return columns?.map((column): unknown =>
        column.fieldName ? get(drilldown, column.fieldName) : ''
      )
    })
    data?.unshift(columns?.map((x) => x.name))

    yield call(() =>
      exportDataToExcel(
        {
          sheets: [{ name: wsName, data: data || [] }]
        },
        filename
      )
    )
    yield put(exportTrendReportDrilldownToExcelActions.success())
  } catch (e: any) {
    yield put(exportTrendReportDrilldownToExcelActions.failure(e))
  }
}

function* handleManualAccrual(
  action: ReturnType<typeof uploadManualActions.uploadDoc>
) {
  const options = yield* call(getRockefellerApiOptions)
  const { onUploadSuccess, onUploadFail, onUploadProgress, fileDoc } =
    action.payload
  try {
    const data = yield* call(
      uploadManualAccrualDocService,
      fileDoc,
      options,
      onUploadProgress
    )
    onUploadSuccess(fileDoc.docFile)

    if (fileDoc.docFile.fileStatus === DragDropFileStatus.SUCCESS) {
      yield put(uploadManualAccrualActions.success(data))
    } else {
      yield put(
        uploadManualAccrualActions.failure(
          new Error('Unable to upload the file.Please try again later')
        )
      )
    }
    yield put(fetchManualUploadActions.request({}))
  } catch (e: any) {
    onUploadFail(fileDoc.docFile)
    yield put(uploadManualAccrualActions.failure(e))
  }
}

function* handleFetchManual(
  action: ReturnType<typeof fetchManualUploadActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      fetchManualUploadService(action.payload, options)
    )
    if (response && response) {
      yield put(fetchManualUploadActions.success(response))
    } else {
      yield put(
        fetchManualUploadActions.failure(
          new Error('unable to fetch attachments list')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchManualUploadActions.failure(e))
  }
}

function* handleDownloadManualAccrual(
  action: ReturnType<typeof downloadManualAccrualActions.request>
) {
  const options = yield* call(getRockefellerApiOptions)

  try {
    const data: string = yield* call(() =>
      downloadManualAccrualService(
        action.payload.attachmentsURL,
        action.payload.fileName,
        options
      )
    )
    yield put(downloadManualAccrualActions.success())
    const blob = b64toBlob(data)
    const fileURL = URL.createObjectURL(blob)
    window.open(fileURL, 'DocumentWindow')
  } catch (e: any) {
    yield put(downloadManualAccrualActions.failure(e))
  }
}
function* handleDeleteManualAccrual(
  action: ReturnType<typeof deleteManualAccrualActions.request>
) {
  const options = yield* call(getRockefellerApiOptions)

  try {
    const data: any = yield* call(() =>
      deleteManualAccrualService(action.payload.fileName, options)
    )
    if (data) {
      yield put(deleteManualAccrualActions.success())
      yield put(fetchManualUploadActions.request({}))
    } else {
      yield put(
        deleteManualAccrualActions.failure(
          new Error('unable to delete attachment')
        )
      )
    }
  } catch (e: any) {
    yield put(
      deleteManualAccrualActions.failure(
        new Error('Unable to delete attachment, please try after sometime.')
      )
    )
  }
}
export const sagas = [
  () =>
    takeLatest(
      fetchRetirementPlanTransactionsAction.request,
      handleFetchRetirementProductPlanTransactions
    ),
  () =>
    takeLatest(
      fetchPlansToDistributeCashActions.request,
      handleFetchPlansToDistributeCash
    ),
  () => takeLatest(fetchProductPlansAction.request, handleFetchProductPlans),
  () => takeLatest(fetchPlanProvidersActions.request, handleFetchPlanProviders),
  () =>
    takeLatest(
      fetchProvidersforExcelActions.request,
      handleFetchProvidersforExcel
    ),
  () =>
    takeEvery(fetchPlansByProviderActions.request, handleFetchPlansByProvider),
  () => takeLatest(updatePlanProviderActions.request, handleUpdatePlanProvider),
  () =>
    takeLatest(fetchCommittedActions.request, handleFetchCommittedPlanAction),
  () =>
    takeLatest(
      updatePlanPlanByProviderActions.request,
      handleUpdatePlanByProvider
    ),
  () => takeLatest(fetchFeeTypesActions.request, handleFetchFeeTypes),
  () =>
    takeLatest(
      updateCashReceiptToPlanActions.request,
      handleUpdateCashReceiptToPlan
    ),
  () =>
    takeLatest(
      productNavigationActions.navigatetoTransaction,
      handleNavigateToTransaction
    ),
  () => takeLatest(exportPlansToExcelActions.request, handleExportPlansToExcel),
  () => takeLatest(exportToExcelActions.request, handleExportToExcel),
  () => takeLatest(downloadD365ExcelActions.request, handleDownloadD365Excel),
  () => takeLatest(closeMonthActions.request, handleCloseMonth),
  () => takeLatest(viewStatementActions.request, handleViewStatement),
  () =>
    takeLatest(
      productNavigationActions.navigateToProjectedAmountByPlan,
      handleNavigateToProjectedAmountbyPlan
    ),
  () =>
    takeEvery(uploadProductDocumentActions.uploadDoc, handleUploadProductDoc),
  () =>
    takeLatest(
      fetchProjectedAmountByPlansActions.request,
      handleFetchProjectedAmountByPlan
    ),
  () =>
    takeLatest(
      updateProjectedAmountByPlansActions.request,
      handleUpdateProjectedAmountByPlan
    ),
  () =>
    takeLatest(productNavigationActions.navigateToPlan, handleNavigateToPlan),
  () =>
    takeLatest(
      fetchProductPlanAllocationActions.request,
      handleFetchProductPlanAllocations
    ),
  () =>
    takeLatest(fetchUploadDocTypeActions.request, handleFetchUploadDocTypes),
  () => takeLatest(fetchUploadDocListActions.request, handleFetchUploadDocList),
  () => takeLatest(navigateactions.launchFundDoc, LaunchFundDocs),
  () => takeLatest(deleteFundDocActions.request, handleDeleteFundDoc),
  () => takeLatest(uploadFundDocActions.request, handleUploadFundDoc),
  () => takeLatest(downloadFundDocActions.request, handleDownloadFundDoc),
  () =>
    takeLatest(
      fetchPlanProvidersDocsActions.request,
      handleFetchPlanProviderDocs
    ),
  () => takeLatest(deleteStatementActions.request, handleDeleteStatement),
  () =>
    takeLatest(
      updatePlanProviderDocActions.request,
      handleUpdatePlanProviderDoc
    ),
  () => takeLatest(fetchAccrualReport.request, handleFetchAccrualReport),
  () =>
    takeLatest(
      fetchOpenReceivablesReportActions.request,
      handleFetchOpenReceivablesTrendReport
    ),
  () =>
    takeLatest(
      exportAccrualReportToExcelActions.request,
      handleExportAccrualReportToExcel
    ),
  () =>
    takeLatest(
      exportOpenReceivablesReportActions.export,
      handleExportOpenReceivablesReportToExcel
    ),
  () =>
    takeLatest(
      fetchCashReceiptTrendReportActions.request,
      handleFetchCashReceiptTrendReport
    ),
  () =>
    takeLatest(
      exportExcelCashReceiptTrendReportActions.request,
      handleExportExcelCashReceiptTrendReport
    ),
  () =>
    takeLatest(
      fetchAccrualReportDrilldownListActions.request,
      handleFetchAccrualReportDrilldownList
    ),
  () =>
    takeLatest(
      fetchExistingSponsorNamesActions.request,
      handleFetchSponsorNames
    ),
  () =>
    takeLatest(
      exportTrendReportDrilldownToExcelActions.request,
      handleTrendReportDrilldownExportToExcel
    ),
  () =>
    takeLatest(
      fetchClassificationTypesActions.request,
      handleFetchClassificationTypes
    ),
  () =>
    takeLatest(
      fetchDraftPlansByProviderServiceTypesActions.request,
      handleFetchDraftPlansByProviderTypes
    ),
  () =>
    takeLatest(
      fetchDraftPlansActions.request,
      handleFetchDraftPlanDetailsTypes
    ),
  () =>
    takeLatest(
      fetchAIPlansApproveDashboardActions.request,
      handleFetchAIFundsApprovalDashboardService
    ),
  () =>
    takeLatest(
      fetchWorkflowPlanHistoryApproveDashboardActions.request,
      handleFetchWorkflowHistoryApprovalDashboardService
    ),
  () =>
    takeLatest(
      fetchAIDRAFTPlansApproveDashboardActions.request,
      handleFetchAIDraftApprovalDashboardService
    ),
  () =>
    takeLatest(
      fetchComparisonApproveDashboardActions.request,
      fetchComparisionData
    ),
  () =>
    takeLatest(
      handleApproverActionApprovalDashboard.request,
      handleApproverAction
    ),

  () => takeLatest(fetchManualUploadActions.request, handleFetchManual),
  () => takeLatest(uploadManualActions.uploadDoc, handleManualAccrual),

  () =>
    takeLatest(
      downloadManualAccrualActions.request,
      handleDownloadManualAccrual
    ),
  () =>
    takeLatest(deleteManualAccrualActions.request, handleDeleteManualAccrual)
]
