import { DetailsListLayoutMode } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import type { IAnnuityPPLIContract } from 'api/datahub'
import {
  IOdataDataListCellRenderProps,
  OdataList
} from 'features/OdataList/containers/OdataList'
import { constants } from 'modules/Advisory/shared/theme'
import { memo, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SecondaryHeader, ColumnEditPanel } from '../../features'
import {
  AnnuityPPLIName,
  CellComponents
} from '../../shared/annuityPPLIContracts'
import {
  getAnnuityExcelExportError,
  getAnnuityExcelExportIsLoading
} from '../../shared/export/rootSelectors'
import {
  useAdvisorIdFilter,
  usePolicyStatusFilter,
  useContractTypeFilter
} from '../../shared/hooks'
import { annuitiesExcelExportActions } from './excelExportActionsAndSagas'
import {
  actions as annuitiesAndInsuranceActions,
  selectors as annuitiesAndInsuranceSelectors
} from './store'

export const AnnuitiesAndInsuranceCell: React.FC<
  IOdataDataListCellRenderProps<IAnnuityPPLIContract>
> = memo(({ item, column }) => {
  const Component = CellComponents[column.name as AnnuityPPLIName]
  return Component ? <Component item={item} column={column} /> : null
})

export const Annuities: React.FC = () => {
  const { facetActions, uiActions } = annuitiesAndInsuranceActions
  const { facetSelectors, uiSelectors } = annuitiesAndInsuranceSelectors

  const [isPanelOpen, { setTrue: openPanel, setFalse: closePanel }] =
    useBoolean(false)

  const dispatch = useDispatch()

  const isExportLoading = useSelector(getAnnuityExcelExportIsLoading)

  const isExportError = useSelector(getAnnuityExcelExportError)

  const exportToExcel = useCallback(() => {
    dispatch(annuitiesExcelExportActions.request())
  }, [dispatch])

  const secondaryHeader = useMemo(
    () => (
      <SecondaryHeader
        exportToExcel={exportToExcel}
        isExportError={isExportError}
        isExportLoading={isExportLoading}
        openPanel={openPanel}
      />
    ),
    [exportToExcel, isExportError, isExportLoading, openPanel]
  )

  useAdvisorIdFilter(uiActions, uiSelectors)

  usePolicyStatusFilter(uiActions, uiSelectors, [
    'Active',
    'Active Annuity Payout'
  ])

  useContractTypeFilter({
    uiActions,
    contractType: 'Annuity',
    hidden: true
  })

  return (
    <>
      <h2 css={{ marginTop: '0' }}>Annuities</h2>
      <OdataList
        actions={uiActions}
        selectors={uiSelectors}
        facetActions={facetActions}
        facetSelectors={facetSelectors}
        onRenderCell={AnnuitiesAndInsuranceCell}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        secondaryHeader={secondaryHeader}
        stickyHeaderOffset={constants.headerHeight}
      />
      {isPanelOpen && (
        <ColumnEditPanel
          isPanelOpen={isPanelOpen}
          closePanel={closePanel}
          actions={uiActions}
          selectors={uiSelectors}
        />
      )}
    </>
  )
}
