import {
  ContextualMenu,
  ContextualMenuItemType,
  DetailsListLayoutMode,
  DirectionalHint,
  IContextualMenuItem,
  IContextualMenuListProps,
  IContextualMenuProps,
  IRenderFunction,
  MessageBar,
  MessageBarType,
  SelectionMode,
  Selection,
  IColumnReorderOptions
} from '@fluentui/react'
import { keyBy } from 'lodash'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SearchResponseType } from '../../../../api/common.types'
import { OrderByDirection } from '../../../../api/odata.types'
import { getEnablePreviewPreference } from '../../../../store/user/selectors'
import {
  IListsCellComponent,
  ListsDataTableComponent
} from '../components/ListsDataTable'
import { IColumnDefinition } from '../contracts/IColumnDefinition'
import { IColumnState } from '../contracts/IColumnState'
import { IListsFilter } from '../contracts/IListsFilter'
import { createListsFilterEditStandaloneContainer } from '../features/filter/containers/ListsFilterEditStandalone'
import { IListsActions } from '../store/actions'
import { IListsSelectors } from '../store/selectors'

export interface IListsDataTableContainerProps {
  stickyHeaderOffset?: number
  numStickyColumns?: number
  layoutMode?: DetailsListLayoutMode
  selectionMode?: SelectionMode
  selection?: Selection
}

const MemoizedListsDataTableComponent = memo(
  ListsDataTableComponent
) as typeof ListsDataTableComponent

export const createConnectedListsDataTable = <T extends SearchResponseType>(
  listActions: IListsActions<T>,
  listSelectors: IListsSelectors<T>,
  cell: IListsCellComponent<T>
) => {
  const { facetActions, uiActions } = listActions
  const { facetSelectors, uiSelectors, dataSelectors } = listSelectors
  const FilterEditStandalone = createListsFilterEditStandaloneContainer(
    facetSelectors,
    facetActions
  )

  const ConnectedListsDataTable: React.FC<IListsDataTableContainerProps> = ({
    stickyHeaderOffset,
    layoutMode = DetailsListLayoutMode.fixedColumns,
    selectionMode,
    selection
  }) => {
    const dispatch = useDispatch()

    const orderBy = useSelector(uiSelectors.getOrderBy)
    const [contextualMenuProps, setContextualMenuProps] = useState<
      IContextualMenuProps | undefined
    >()

    const onFilterApply = useCallback(
      (newFilter?: IListsFilter) => {
        if (newFilter) {
          dispatch(uiActions.setFilter(newFilter))
        }
        setContextualMenuProps(undefined)
      },
      [dispatch]
    )

    const onFilterCancel = useCallback(
      () => setContextualMenuProps(undefined),
      []
    )

    const filters = useSelector(uiSelectors.getFilters)
    const filterDefinitions = useSelector(uiSelectors.getFilterDefinitions)

    const onColumnClick = useCallback(
      (ev: any, column?: IColumnDefinition) => {
        if (!column) {
          return
        }

        const { id, sortable, filterable, name } = column
        const hasFilter = Boolean(filters?.[id])
        const filter = filters?.[id] || filterDefinitions?.[id]

        // TODO - make this it's own component, too much happening in this callback
        const sort = (direction: OrderByDirection) => {
          dispatch(
            uiActions.sort({
              columnId: id,
              direction
            })
          )
        }

        const hideColumn = () => {
          dispatch(uiActions.hideColumn(id))
        }

        const removeFilter = () => {
          if (filter) {
            dispatch(uiActions.removeFilters([filter.id]))
          }
        }

        const sortItems = [
          sortable && {
            key: 'aToZ',
            name: 'Sort Ascending',
            iconProps: { iconName: 'SortUp' },
            onClick: () => sort('asc')
          },
          sortable && {
            key: 'zToA',
            name: 'Sort Descending',
            iconProps: { iconName: 'SortDown' },
            onClick: () => sort('desc')
          },
          {
            key: 'remove',
            name: 'Hide This Column',
            iconProps: { iconName: 'Hide3' },
            onClick: hideColumn
          }
        ].filter(Boolean) as IContextualMenuItem[]

        const filterItems = filterable
          ? [
              {
                key: 'filter-divider',
                text: 'Filter',
                iconProps: { iconName: 'Filter' },
                itemType: ContextualMenuItemType.Header
              },
              {
                key: 'filter',
                name: `Clear filter from "${name}"`,
                iconProps: { iconName: 'ClearFilter' },
                disabled: !hasFilter,
                onClick: removeFilter
              }
            ]
          : []

        const onRenderMenuList: IRenderFunction<IContextualMenuListProps> = (
          menuListProps?: IContextualMenuListProps,
          defaultRender?: IRenderFunction<IContextualMenuListProps>
        ) => {
          return (
            <>
              {defaultRender?.(menuListProps)}
              {filterable && filter && (
                <div
                  style={{
                    padding: '10px'
                  }}
                >
                  <FilterEditStandalone
                    filter={filter}
                    onApply={onFilterApply}
                    onCancel={onFilterCancel}
                  />
                </div>
              )}
            </>
          )
        }

        setContextualMenuProps({
          items: [...sortItems, ...filterItems],
          target: ev.currentTarget as HTMLElement,
          directionalHint: DirectionalHint.bottomLeftEdge,
          isBeakVisible: true,
          onDismiss: () => setContextualMenuProps(undefined),
          onRenderMenuList
        })
      },
      [dispatch, filters, filterDefinitions, onFilterApply, onFilterCancel]
    )

    const columnState = useSelector(uiSelectors.getColumnState)
    const onResize = useCallback(
      (newWidths?: Record<string, number>) => {
        if (!newWidths) {
          return
        }

        if (Object.entries(newWidths).length === 0) {
          return
        }

        const updatedColumnStates = [...columnState]

        Object.entries(newWidths).forEach(([columnId, size]) => {
          const columnStateIndex = columnState.findIndex(
            (x) => x.columnId === columnId
          )

          if (columnStateIndex < 0) {
            return
          }

          const updatedColumnState = columnState[columnStateIndex]

          const newColumnState: IColumnState = {
            ...updatedColumnState,
            width: size
          }

          updatedColumnStates.splice(columnStateIndex, 1, newColumnState)
        })

        dispatch(uiActions.updateColumnState(updatedColumnStates))
      },
      [dispatch, columnState]
    )

    const chunks = useSelector(dataSelectors.getChunks)
    const isLoading = useSelector(dataSelectors.getIsLoading)

    const items = useMemo(
      () =>
        chunks?.filter((x) => x).reduce((a, x) => a.concat(x.value), [] as T[]),
      [chunks]
    )

    const columnDefinitions = useSelector(uiSelectors.getColumnDefinitions)

    const columns = useMemo(() => {
      const columnDefinitionMap = keyBy(columnDefinitions, (item) => item.id)
      return columnState
        .filter((x) => x.selected && columnDefinitionMap[x.columnId])
        .map(
          (x): IColumnDefinition => ({
            ...columnDefinitionMap[x.columnId],
            width: x.width
          })
        )
        .filter((x) => x != null)
    }, [columnDefinitions, columnState])

    const error = useSelector(dataSelectors.getError)

    const numStickyColumns = useMemo(() => {
      return columnState?.filter((x) => x.sticky).length || 0
    }, [columnState])

    const enablePreviewFeatures = useSelector(getEnablePreviewPreference)

    const columnReorderOptions = useMemo(
      (): IColumnReorderOptions => ({
        frozenColumnCountFromEnd: 0,
        frozenColumnCountFromStart: numStickyColumns || 0,
        onColumnDrop: ({ draggedIndex, targetIndex }) => {
          const id = columns?.[draggedIndex]?.id
          if (!id) {
            return
          }

          dispatch(
            uiActions.moveColumn({
              id,
              position: targetIndex
            })
          )
        }
      }),
      [columns, dispatch, numStickyColumns]
    )

    return (
      <>
        <MemoizedListsDataTableComponent<T>
          items={items}
          columns={columns}
          cell={cell}
          orderBy={orderBy}
          stickyHeaderOffset={stickyHeaderOffset}
          numStickyColumns={numStickyColumns}
          onColumnClick={onColumnClick}
          onResize={onResize}
          enableShimmer={isLoading && !items?.length}
          enablePreviewColumns={enablePreviewFeatures}
          layoutMode={layoutMode}
          selectionMode={selectionMode}
          selection={selection}
          columnReorderOptions={columnReorderOptions}
        />
        {error && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            An error occurred while loading: {error.message}
          </MessageBar>
        )}
        {contextualMenuProps && (
          <ContextualMenu shouldFocusOnMount={false} {...contextualMenuProps} />
        )}
      </>
    )
  }

  return ConnectedListsDataTable
}
