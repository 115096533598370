import { createReducer } from 'typesafe-actions'
import {
  AllCashReceiptActionTypes,
  deleteDocumentActions,
  exportCashReceiptToExcelActions,
  fetchAllCashReceiptsActions,
  fetchAllProductTypeActions,
  fetchUploadedFilesAction,
  setSelectedMonthYearCashReceiptSettingActions,
  setSelectedCashReceiptActions,
  updateAllCashReceiptTypeActions,
  viewCashReceiptCheckImageActions,
  updateCashReceiptByProviderActions,
  resetupdateCashReceiptByProviderStateActions,
  fetchAllocatedAmountActions
} from './actions'
import {
  IAllCashReceipt,
  IAllocatedAmount,
  IProductType,
  IUploadedFiles
} from './types'

export interface IAllCashReceiptState {
  cashReceiptsState: {
    isLoading: boolean
    receipts?: IAllCashReceipt[]
    error?: Error
  }
  productTypesState?: {
    isLoading: boolean
    products?: IProductType[]
    error?: Error
  }
  updateAllCashReceiptState?: {
    inProgress: boolean
    error?: Error
  }
  viewCashReceiptCheckImage?: {
    inProgress: boolean
    error?: Error
  }
  selectedMonthYear?: string
  uploadedFilesRes?: {
    uplodedFiles: IUploadedFiles[]
    isLoading: boolean
    error?: Error
  }
  deleteDocumentRes?: {
    isLoading: boolean
    error?: Error
  }
  exportCashReceipts?: {
    inProgress: boolean
    error?: Error
  }
  selectedCashReceipt?: IAllCashReceipt
  updateCashReceiptByProviderState?: {
    inProgress: boolean
    error?: Error
  }
  updatedCashReceiptFlag?: boolean
  fetchAllocatedAmountState?: {
    inProgress: boolean
    error?: Error
    data?: IAllocatedAmount[]
  }
}

const initialState: IAllCashReceiptState = {
  cashReceiptsState: {
    isLoading: false
  },
  updatedCashReceiptFlag: false
}

export const allCashReceiptsReducer = createReducer<
  IAllCashReceiptState,
  AllCashReceiptActionTypes
>(initialState)
  .handleAction(fetchAllCashReceiptsActions.request, (state) => ({
    ...state,
    cashReceiptsState: {
      ...state.cashReceiptsState,
      error: undefined,
      isLoading: true
    }
  }))
  .handleAction(fetchAllCashReceiptsActions.success, (state, action) => ({
    ...state,
    cashReceiptsState: {
      ...state.cashReceiptsState,
      receipts: action.payload,
      error: undefined,
      isLoading: false
    }
  }))
  .handleAction(fetchAllCashReceiptsActions.failure, (state, action) => ({
    ...state,
    cashReceiptsState: {
      ...state.cashReceiptsState,
      error: action.payload,
      isLoading: false
    }
  }))
  .handleAction(fetchAllProductTypeActions.request, (state) => ({
    ...state,
    productTypesState: {
      ...state.productTypesState,
      error: undefined,
      isLoading: true
    }
  }))
  .handleAction(fetchAllProductTypeActions.success, (state, action) => ({
    ...state,
    productTypesState: {
      ...state.productTypesState,
      products: action.payload,
      error: undefined,
      isLoading: false
    }
  }))
  .handleAction(fetchAllProductTypeActions.failure, (state, action) => ({
    ...state,
    productTypesState: {
      ...state.productTypesState,
      error: action.payload,
      isLoading: false
    }
  }))
  .handleAction(updateAllCashReceiptTypeActions.request, (state) => ({
    ...state,
    updateAllCashReceiptState: {
      error: undefined,
      inProgress: true
    }
  }))
  .handleAction(updateAllCashReceiptTypeActions.success, (state) => ({
    ...state,
    updateAllCashReceiptTypeActions: {
      ...state.productTypesState,
      error: undefined,
      isLoading: false
    }
  }))
  .handleAction(updateAllCashReceiptTypeActions.failure, (state, action) => ({
    ...state,
    updateAllCashReceiptTypeActions: {
      error: action.payload,
      isLoading: false
    }
  }))
  .handleAction(viewCashReceiptCheckImageActions.request, (state) => ({
    ...state,
    viewCashReceiptCheckImage: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(viewCashReceiptCheckImageActions.success, (state) => ({
    ...state,
    viewCashReceiptCheckImage: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(viewCashReceiptCheckImageActions.failure, (state, action) => ({
    ...state,
    viewCashReceiptCheckImage: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(deleteDocumentActions.request, (state) => ({
    ...state,
    deleteDocumentRes: {
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(deleteDocumentActions.success, (state) => ({
    ...state,
    deleteDocumentRes: {
      isLoading: false,
      error: undefined
    }
  }))
  .handleAction(deleteDocumentActions.failure, (state, action) => ({
    ...state,
    deleteDocumentRes: {
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(
    setSelectedMonthYearCashReceiptSettingActions.selectedMonthYear,
    (state, action) => ({
      ...state,
      selectedMonthYear: action.payload || undefined
    })
  )
  .handleAction(fetchUploadedFilesAction.request, (state) => ({
    ...state,
    uploadedFilesRes: {
      isLoading: true,
      error: undefined,
      uplodedFiles: []
    }
  }))
  .handleAction(fetchUploadedFilesAction.success, (state, action) => ({
    ...state,
    uploadedFilesRes: {
      isLoading: false,
      error: undefined,
      uplodedFiles: action.payload
    }
  }))
  .handleAction(fetchUploadedFilesAction.failure, (state, action) => ({
    ...state,
    uploadedFilesRes: {
      isLoading: false,
      error: action.payload,
      uplodedFiles: []
    }
  }))
  .handleAction(exportCashReceiptToExcelActions.request, (state) => ({
    ...state,
    exportCashReceipts: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(exportCashReceiptToExcelActions.success, (state) => ({
    ...state,
    exportCashReceipts: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(exportCashReceiptToExcelActions.failure, (state, action) => ({
    ...state,
    exportCashReceipts: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(
    setSelectedCashReceiptActions.selectedCashReceipt,
    (state, action) => ({
      ...state,
      selectedCashReceipt: action.payload || undefined
    })
  )
  .handleAction(
    setSelectedCashReceiptActions.updatedCashReceiptFlag,
    (state, action) => ({
      ...state,
      updatedCashReceiptFlag: action.payload || undefined
    })
  )
  .handleAction(updateCashReceiptByProviderActions.request, (state) => ({
    ...state,
    updateCashReceiptByProviderState: {
      error: undefined,
      inProgress: true
    }
  }))
  .handleAction(updateCashReceiptByProviderActions.success, (state) => ({
    ...state,
    updateCashReceiptByProviderState: {
      ...state.updateCashReceiptByProviderState,
      error: undefined,
      inProgress: false
    }
  }))
  .handleAction(
    updateCashReceiptByProviderActions.failure,
    (state, action) => ({
      ...state,
      updateCashReceiptByProviderState: {
        error: action.payload,
        inProgress: false
      }
    })
  )
  .handleAction(
    resetupdateCashReceiptByProviderStateActions.reset,
    (state) => ({
      ...state,
      updateCashReceiptByProviderState: {
        error: undefined,
        inProgress: false
      }
    })
  )
  .handleAction(fetchAllocatedAmountActions.request, (state) => ({
    ...state,
    fetchAllocatedAmountState: {
      ...state.fetchAllocatedAmountState,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(fetchAllocatedAmountActions.success, (state, action) => ({
    ...state,
    fetchAllocatedAmountState: {
      inProgress: false,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchAllocatedAmountActions.failure, (state, action) => ({
    ...state,
    fetchAllocatedAmountState: {
      ...state.fetchAllocatedAmountState,
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(fetchAllocatedAmountActions.clear, (state) => ({
    ...state,
    fetchAllocatedAmountState: undefined
  }))
