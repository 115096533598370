import type { IAnnuityPPLIContract } from 'api/datahub'
import { PercentCell } from 'features/Lists/core/components/PercentCell'
import { TextCell } from 'features/Lists/core/components/TextCell'
import { USDCell } from 'features/Lists/core/components/USDCell'
import { IOdataDataListCellRenderProps } from 'features/OdataList/containers/OdataList'
import { FormattedDate } from 'react-intl'
import { parseDateISOStringInLocalTimezone } from 'shared/dates'
import { AccountLink } from '../../modules/Insurance/AccountLink'
import { AnnuityPPLIName } from './columnNameTypes'

export const CellComponents: Record<
  AnnuityPPLIName,
  React.FC<IOdataDataListCellRenderProps<IAnnuityPPLIContract>>
> = {
  'Contract Type': ({ item }) => <TextCell>{item.contractType}</TextCell>,

  'Household Name': ({ item }) => <TextCell>{item.householdName}</TextCell>,

  'Account Number': ({ item }) => {
    const {
      contractType,
      contractNumber,
      manuallyLinkedPartyId,
      clientName,
      insuredAnnuitants
    } = item
    return (
      <TextCell>
        {item.accountNumber ?? (
          <AccountLink
            props={{
              contractType,
              contractNumber,
              manuallyLinkedPartyId,
              clientName,
              insuredAnnuitants
            }}
          />
        )}
      </TextCell>
    )
  },

  'Contract Number': ({ item }) => <TextCell>{item.contractNumber}</TextCell>,

  'Legal Entity Name': ({ item }) => (
    <TextCell>{item.legalEntityName}</TextCell>
  ),

  CUSIP: ({ item }) => <TextCell>{item.cusipNumber}</TextCell>,

  Description: ({ item }) => <TextCell>{item.securityDescription}</TextCell>,

  State: ({ item }) => <TextCell>{item.partyState}</TextCell>,

  'Product Type': ({ item }) => <TextCell>{item.productType}</TextCell>,

  Carrier: ({ item }) => <TextCell>{item.carrierName}</TextCell>,

  'Clean Carrier Name': ({ item }) => (
    <TextCell>{item.cleanCarrierName}</TextCell>
  ),

  'Issue Date': ({ item }) => (
    <TextCell>
      {item.issueDate && (
        <FormattedDate
          day="2-digit"
          month="2-digit"
          year="numeric"
          value={parseDateISOStringInLocalTimezone(item.issueDate)}
        />
      )}
    </TextCell>
  ),

  'Policy Status': ({ item }) => <TextCell>{item.contractStatusDesc}</TextCell>,

  Premium: ({ item }) => (
    <USDCell value={item.premiumPaidSinceInception} fractionDigits={2} />
  ),

  'Last Updated': ({ item }) => (
    <TextCell>
      {item.valuationDate && (
        <FormattedDate
          day="2-digit"
          month="2-digit"
          year="numeric"
          value={parseDateISOStringInLocalTimezone(item.valuationDate)}
        />
      )}
    </TextCell>
  ),

  'Cash Value': ({ item }) => (
    <USDCell value={item.cashValue} fractionDigits={2} />
  ),

  'Death Benefit': ({ item }) => (
    <USDCell value={item.deathBenefitAmt} fractionDigits={2} />
  ),

  'Surrender Value': ({ item }) => (
    <USDCell value={item.surrenderValue} fractionDigits={2} />
  ),

  'Surrender Charge': ({ item }) => (
    <USDCell value={item.surrenderCharge} fractionDigits={2} />
  ),

  'Surrender Charge Expiration': ({ item }) => (
    <TextCell>
      {item.surrenderDate && (
        <FormattedDate
          day="2-digit"
          month="2-digit"
          year="numeric"
          value={parseDateISOStringInLocalTimezone(item.surrenderDate)}
        />
      )}
    </TextCell>
  ),

  RMDs: ({ item }) => <USDCell value={item.rmdAmount} fractionDigits={2} />,

  'Maturity Date': ({ item }) => (
    <TextCell>
      {item.maturityDate && (
        <FormattedDate
          day="2-digit"
          month="2-digit"
          year="numeric"
          value={parseDateISOStringInLocalTimezone(item.maturityDate)}
        />
      )}
    </TextCell>
  ),

  'Policy Loan amount': ({ item }) => (
    <USDCell value={item.loanAmount} fractionDigits={2} />
  ),

  'Insured / Annuitants': ({ item }) => (
    <TextCell>{item.insuredAnnuitants}</TextCell>
  ),

  'Product Name': ({ item }) => <TextCell>{item.productName}</TextCell>,

  Address: ({ item }) => (
    <TextCell>
      <div>{item.partyAddressLine1}</div>
      <div>{item.partyAddressLine2}</div>
    </TextCell>
  ),

  'High Benefit Name': ({ item }) => (
    <TextCell>{item.highBenefitName}</TextCell>
  ),

  'Max Benefit': ({ item }) => (
    <USDCell value={item.maxBenefit} fractionDigits={2} />
  ),

  'Income Benefit (GMIB)': ({ item }) => (
    <USDCell value={item.incomeBenefitAmt} fractionDigits={2} />
  ),

  'Fee Type': ({ item }) => <TextCell>{item.feeType}</TextCell>,

  'TBF Percentage': ({ item }) => <PercentCell value={item.tbfPercentAmt} />,

  Agents: ({ item }) => <TextCell>{item.agents}</TextCell>,

  'Owner / Client': ({ item }) => <TextCell>{item.clientName}</TextCell>,

  'Advisor Team': ({ item }) => <TextCell>{item.teamName}</TextCell>,

  'Beneficiary Name': ({ item }) => <TextCell>{item.beneficiaryName}</TextCell>,

  'Asset Class Level-1': ({ item }) => <TextCell>{item.assetClassL1}</TextCell>,

  'Asset Class Level-2': ({ item }) => <TextCell>{item.assetClassL2}</TextCell>,

  'Asset Class Level-3': ({ item }) => <TextCell>{item.assetClassL3}</TextCell>,

  'Asset Class Level-4': ({ item }) => <TextCell>{item.assetClassL4}</TextCell>,

  'Asset Type': ({ item }) => <TextCell>{item.assetType}</TextCell>,

  'Asset Sub Type': ({ item }) => <TextCell>{item.assetSubType}</TextCell>,

  'Advisor Id': ({ item }) => <TextCell>{item.registeredRep}</TextCell>,

  'Advisor Name': ({ item }) => <TextCell>{item.registeredRepName}</TextCell>,

  'Division Name': ({ item }) => <TextCell>{item.regionName}</TextCell>,

  COMDEX: ({ item }) => <TextCell>{item.comdex}</TextCell>,

  'Annualized Premium': ({ item }) => (
    <USDCell value={item.annualizedPremium} fractionDigits={2} />
  ),

  'Next Premium Amount': ({ item }) => (
    <USDCell value={item.premiumAmount} fractionDigits={2} />
  ),

  'Next Premium Date': ({ item }) => (
    <TextCell>
      {item.premiumDate && (
        <FormattedDate
          day="2-digit"
          month="2-digit"
          year="numeric"
          value={parseDateISOStringInLocalTimezone(item.premiumDate)}
        />
      )}
    </TextCell>
  ),

  Basis: ({ item }) => (
    <USDCell value={item.dollarAmountContributed} fractionDigits={2} />
  )
}
