import type {
  AuthError,
  PopupRequest,
  RedirectRequest
} from '@azure/msal-browser'
import { makeArrayFromString } from '../../strings'
import * as msalWrapper from './msal'
import { LoginTypes } from './types'

export const {
  login,
  logout,
  getAccount,
  getRedirectError,
  errorRequiresInteraction,
  reset,
  ready,
  getRedirectState: getState
} = msalWrapper

export const acquireAccessToken = async (
  type: LoginTypes,
  request: PopupRequest | RedirectRequest
) => {
  const response = await msalWrapper.acquireAccessToken(type, request)
  if (!response) {
    throw new Error('unable to retrieve access token')
  }
  return response?.accessToken
}

export const tryAcquireAccessToken = async (
  scopes: string[] | string,
  destinationAfterRedirect?: string,
  loginHint?: string,
  fallbackLoginType: LoginTypes = 'popup'
) => {
  const arrayScopes = makeArrayFromString(scopes)
  let currentError: AuthError | undefined
  try {
    console.debug('auth service: before acquire access token silent', scopes)
    return await acquireAccessToken('silent', {
      scopes: arrayScopes,
      loginHint
    })
  } catch (error: any) {
    currentError = error
  }

  const requiresInteraction = await msalWrapper.errorRequiresInteraction(
    currentError
  )

  console.debug(
    'auth service: requires interaction? ',
    requiresInteraction,
    scopes
  )
  if (requiresInteraction) {
    try {
      console.debug('auth service: befeore acquire token popup', scopes)
      return await acquireAccessToken(fallbackLoginType, {
        scopes: arrayScopes
      })
    } catch (error: any) {
      currentError = error
    }
  }

  if (currentError?.errorCode === 'popup_window_error') {
    try {
      console.debug('auth service: before acquire token redirect', scopes)
      return await acquireAccessToken('redirect', {
        scopes: arrayScopes,
        state: destinationAfterRedirect
      })
    } catch (error: any) {
      currentError = error
    }
  }

  console.debug(
    'auth service: get access token failed',
    currentError,
    currentError && currentError.message
      ? currentError.message
      : 'no error message',
    scopes
  )
  throw currentError
}
