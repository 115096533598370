import { format } from 'date-fns'
import { IColumn } from 'modules/Advisory/modules/Rdot360/components/shared/DataTable/DataTable'
import { HighlightSearchText } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/HighlightSearchText'
import { IncomeTableData } from 'modules/Advisory/modules/Rdot360/features/Income'
import { FC, useMemo } from 'react'
import { USD } from 'shared/components/Formatting'
import { useIncomeDetailsUiState } from '../../store'
import { DataTable } from './DataTable'

export interface ProjectedIncomeDetailSummaryTableProps {
  data?: IncomeTableData[]
  dates?: Date[]
  includeOptionPremiums?: boolean
}

const commonColumnStyle = {
  height: 60,
  fontSize: 13,
  lineHeight: '15px'
}

const commonHeaderStyle = {
  height: 80,
  fontWeight: 500,
  fontSize: 13
}

export const ProjectedIncomeDetailSummaryTable: FC<
  ProjectedIncomeDetailSummaryTableProps
> = ({ data, dates }) => {
  const { searchText } = useIncomeDetailsUiState()

  const columns = useMemo(() => {
    const columnDefs: IColumn[] = [
      {
        sortable: true,
        field: 'Security',
        headerName: 'Symbol/CUSIP Description',
        format: (value, row) => {
          return (
            <div>
              <div css={{ color: '#1A75C9' }}>
                <HighlightSearchText text={row?.SECNAME} search={searchText} />
              </div>
              <div
                css={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                <HighlightSearchText text={value} search={searchText} />
              </div>
            </div>
          )
        },
        headerStyle: {
          ...commonHeaderStyle,
          width: 250,
          paddingLeft: 8
        },
        columnStyle: {
          ...commonColumnStyle,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          paddingLeft: 8
        }
      },
      ...(dates || []).map(
        (date) =>
          ({
            field: format(date, `MMM`),
            align: 'right',
            headerName: format(date, `MMM ''yy`),
            headerStyle: commonHeaderStyle,
            columnStyle: commonColumnStyle,
            format: (value: number) =>
              value ? (
                <USD value={value} fractionDigits={0} currencySign="standard" />
              ) : (
                '-'
              )
          } as IColumn)
      ),
      {
        field: 'Total',
        headerName: 'Total Income',
        align: 'right',
        headerStyle: {
          ...commonHeaderStyle,
          paddingRight: 8
        },
        columnStyle: {
          ...commonColumnStyle,
          paddingRight: 8
        },
        format: (value: number) =>
          value ? (
            <USD value={value} fractionDigits={0} currencySign="standard" />
          ) : (
            '-'
          )
      }
    ]

    return columnDefs
  }, [dates, searchText])

  return <DataTable columns={columns} data={data} />
}
