import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable
} from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { detailTable } from '../../../components/shared/DataTable/DetailTable'
import { constants } from '../../../shared/theme'
import { IDriveChild } from '../../../store/graph'
import { constructColumns } from './CollaborationTableColumns'

export {}

export const CollaborationTable: React.FC<{
  filesToDisplay: IDriveChild[]
}> = ({ filesToDisplay }) => {
  const columns = useMemo(() => constructColumns(), [])
  const [sorting, setSorting] = useState<SortingState>([])
  const table = useReactTable({
    data: filesToDisplay,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })
  return (
    <table css={detailTable.detailTable}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr
            key={headerGroup.id}
            css={{ position: 'sticky', top: constants.headerOffsetPx }}
          >
            {headerGroup.headers.map((header) => {
              return (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width: header.getSize()
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        onClick: header.column.getToggleSortingHandler()
                      }}
                      css={{
                        cursor: 'pointer'
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {header.column.getCanResize() && (
                        <div
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          css={[
                            detailTable.resizerContainer,
                            header.column.getIsResizing()
                              ? { opacity: 1 }
                              : undefined
                          ]}
                        >
                          <div
                            css={[
                              detailTable.resizer,
                              header.column.getIsResizing()
                                ? detailTable.isResizing
                                : undefined
                            ]}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </th>
              )
            })}
          </tr>
        ))}
      </thead>

      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} style={{ height: '50px' }}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
