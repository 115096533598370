import { useTheme } from '@emotion/react'
import { Callout } from '@fluentui/react'
import { useId } from '@fluentui/react-hooks'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import { fromPairs, sortBy } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { downloadUrlAsFile } from 'shared/downloads'
import { Dropdown, ISelectableOption } from '../../components/shared'
import { buttonStyles } from '../../components/shared/Buttons'
import DatePicker from '../../components/shared/DatePicker'
import DetailsNavigator from '../../components/shared/DetailsNavigator/DetailsNavigator'
import { Searchbox } from '../../components/shared/DetailTables/Searchbox'
import { Icon } from '../../features/Icons/Icon'
import { useActivityDetails } from '../../hooks/useActivityDetails'
import { IndeterminateCheckbox } from '../../shared/IndeterminateCheckbox'
import { PrintDropdownMenu } from '../../shared/PrintDropdownMenu'
import { useFinancialsApiUtil } from '../../store/rdot360AnalyticsApi'
import {
  useRdot360Context,
  useRdot360SelectedAccountsApiContext
} from '../../store/rdot360Context'
import { useRdot360AccountContext } from '../../store/rdot360Context/useRdot360AccountContext'
import ActivityDetailsTable from './ActivityDetailsTable/ActivityDetailsTable'
import { constructColumns } from './ActivityDetailsTable/ActivityDetailsTableColumns'
import { useActivityDetailUiState } from './activityDetailsUiState'
import type { ActivityExportWorker } from './export'
import { useActivityPrint } from './features/PrintView/PrintView'
import { activityDropdownOptions } from './shared'

const parseDate = (date?: string) =>
  date ? parseDateISOStringInLocalTimezone(date) : undefined

export type IActivityDurationOptions =
  | 'Today'
  | 'Prior Day'
  | 'Last 10 Days'
  | 'Last 30 Days'
  | 'Current Year'
  | 'Previous Year'
  | 'Custom Range'

type ActivitySelectableOption = { key: IActivityDurationOptions; text: string }
export const durationDropdownOptions: ActivitySelectableOption[] = [
  { key: 'Today', text: 'Today' },
  { key: 'Prior Day', text: 'Prior Day' },
  { key: 'Last 10 Days', text: 'Last 10 Days' },
  { key: 'Last 30 Days', text: 'Last 30 Days' },
  { key: 'Current Year', text: 'Current Year' },
  { key: 'Previous Year', text: 'Previous Year' },
  { key: 'Custom Range', text: 'Custom Range' }
]

const ActivityDetailView = () => {
  const { asOfDate } = useRdot360Context()
  const { invalidateTags } = useFinancialsApiUtil()
  const {
    dateSelection,
    setDateSelection,
    setCategories,
    categories,
    endDate,
    startDate,
    setStartDate,
    setEndDate,
    setCustomRange,
    searchText,
    setSearchText
  } = useActivityDetailUiState()
  useEffect(() => {
    setDateSelection(dateSelection)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asOfDate])
  useEffect(() => {
    if (!categories || categories?.length === 0) {
      setCategories(
        sortBy(activityDropdownOptions, (x) => x.key).map(
          (x) => x.key as string
        )
      )
    }
  }, [categories, setCategories])
  const onApplyCategories = useCallback(
    (categories: ISelectableOption[]) => {
      setCategories(
        sortBy(categories, (x) => x.key).map((x) => x.key as string)
      )
    },
    [setCategories]
  )
  const { apiContextAccounts } = useRdot360SelectedAccountsApiContext()
  const { accounts, accountLookupByAccountIdOrKey } = useRdot360AccountContext()
  const { activityData: data } = useActivityDetails()
  const columns = useMemo(
    () => constructColumns('', accountLookupByAccountIdOrKey),
    [accountLookupByAccountIdOrKey]
  )

  const exportToExcel = useCallback(async () => {
    if (!data?.activities) {
      return
    }
    const columnDef = columns.map((x) => ({
      id: x.id || '',
      header: x.id
    }))
    const mappedExportData = data?.activities?.map((x, i) =>
      fromPairs<any>(
        columns?.map((column) => [column.id || '', column.accessorFn(x, i)])
      )
    )

    const worker = new Worker(new URL('./export.ts', import.meta.url))
    const { exportActivity } = wrap<ActivityExportWorker>(worker)
    const result = await exportActivity(mappedExportData, columnDef)
    const filename = `Activity Summary ${format(new Date(), 'MM-dd-yyyy')}.xlsx`
    downloadUrlAsFile(result, filename)
  }, [columns, data])

  const selectedCategoryOptions = useMemo(
    () =>
      activityDropdownOptions.filter((x) =>
        categories?.includes(x.key as string)
      ),
    [categories]
  )
  const theme = useTheme()
  const { print } = useActivityPrint()
  const { isFetching } = useActivityDetails()
  const { activities = [] } = data || {}
  const isData = !!activities.length
  const isDisabled = isFetching || !isData

  return (
    <div css={{ height: '100%' }}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 20,
          alignItems: 'flex-end'
        }}
      >
        <DetailsNavigator />
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 3
          }}
        >
          <div css={{ fontSize: '14px', fontWeight: 500 }}>Activity Type</div>
          <div
            css={{
              fontSize: '14px',
              lineHeight: '1.5',
              width: '205px'
            }}
          >
            <ActivityTypeSelector
              options={activityDropdownOptions}
              initialSelectedOptions={selectedCategoryOptions}
              onApplySelection={onApplyCategories}
            />
          </div>
        </div>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 3
          }}
        >
          <div css={{ fontSize: '14px', fontWeight: 500 }}>Date Range</div>
          <div
            css={{
              width: '185px'
            }}
          >
            <Dropdown
              options={durationDropdownOptions}
              selectedKey={dateSelection}
              onChange={(_, option) => {
                setDateSelection(option?.key as IActivityDurationOptions)
              }}
            />
          </div>
        </div>

        {dateSelection === 'Custom Range' && (
          <div
            css={{
              display: 'flex',
              flexWrap: 'nowrap',
              columnGap: 20,
              flexGrow: 1
            }}
          >
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 3
              }}
            >
              <div css={{ fontSize: '14px', fontWeight: 500 }}>From Date</div>
              <div
                css={{
                  fontSize: '14px',
                  lineHeight: '1.5',
                  width: '125px'
                }}
              >
                <DatePicker
                  value={startDate ? parseDate(startDate) : undefined}
                  onSelectDate={(date) =>
                    date && setStartDate(format(date, 'yyyy-MM-dd'))
                  }
                  formatDate={(date) =>
                    date ? format(date, 'yyyy-MM-dd') : ''
                  }
                  maxDate={endDate ? parseDate(endDate) : new Date()}
                  placeholder="YYYY-MM-DD"
                  style={{ width: '135px' }}
                />
              </div>
            </div>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 3
              }}
            >
              <div css={{ fontSize: '14px', fontWeight: 500 }}>To Date</div>
              <div
                css={{
                  fontSize: '14px',
                  lineHeight: '1.5',
                  width: '125px'
                }}
              >
                <DatePicker
                  value={endDate ? parseDate(endDate) : undefined}
                  onSelectDate={(date) =>
                    date && setEndDate(format(date, 'yyyy-MM-dd'))
                  }
                  formatDate={(date) =>
                    date ? format(date, 'yyyy-MM-dd') : ''
                  }
                  minDate={startDate ? parseDate(startDate) : undefined}
                  maxDate={new Date()}
                  placeholder="YYYY-MM-DD"
                  style={{ width: '135px' }}
                />
              </div>
            </div>
            <button
              css={[buttonStyles.primary, { width: '80px', marginTop: 20 }]}
              onClick={() => setCustomRange(startDate, endDate)}
              disabled={!startDate || !endDate}
            >
              Apply
            </button>
          </div>
        )}
        <div
          css={{
            display: 'flex',
            flexWrap: 'nowrap',
            columnGap: 10,
            marginLeft: 'auto',
            alignItems: 'flex-end'
          }}
        >
          <Searchbox
            searchText={searchText}
            onChange={setSearchText}
            title="Find within Description, Symbol/Cusip and Amount"
          />

          <div
            css={{
              display: 'flex',
              marginBottom: 5
            }}
          >
            <div css={{ marginRight: 20 }}>
              <Icon
                type="Refresh"
                width={24}
                height={24}
                onClick={() => invalidateTags(['activity'])}
                color={theme.colors.extraBlue2}
              />
            </div>
            <PrintDropdownMenu
              print={print}
              displayDisclaimer={true}
              isDisabled={isDisabled}
            />
            <div
              css={{
                width: 24,
                height: 24,
                marginLeft: 20,
                opacity: isDisabled ? '0.5' : '1'
              }}
            >
              <Icon
                type="Download"
                width={24}
                height={24}
                onClick={isDisabled ? undefined : exportToExcel}
                color={theme.colors.extraBlue2}
                isDisabled={isDisabled}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        css={{
          padding: '5px',
          paddingBottom: '10px'
        }}
      >
        {`${apiContextAccounts?.length || 0} / ${
          accounts?.length || 0
        } Accounts Selected`}
      </div>
      <ActivityDetailsTable />
    </div>
  )
}

export default ActivityDetailView

const ActivityTypeSelector: React.FC<{
  options: ISelectableOption[]
  initialSelectedOptions: ISelectableOption[]
  onApplySelection: (selectedOptions: ISelectableOption[]) => void
}> = ({ options, initialSelectedOptions, onApplySelection }) => {
  const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions)
  useMemo(() => {
    setSelectedOptions(initialSelectedOptions)
  }, [initialSelectedOptions])
  const onSelectAll = useCallback(() => {
    setSelectedOptions(options)
  }, [options])
  const onDeselectAll = useCallback(() => {
    setSelectedOptions([])
  }, [])
  const onDeselect = useCallback(
    (option: ISelectableOption) => {
      setSelectedOptions(selectedOptions.filter((x) => x.key !== option.key))
    },
    [selectedOptions]
  )
  const onSelect = useCallback(
    (option: ISelectableOption) => {
      setSelectedOptions(sortBy([...selectedOptions, option], (x) => x.key))
    },
    [selectedOptions]
  )
  const dropdownId = useId('Callout')
  const dropdownRef: any = useRef()
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const onApply = useCallback(() => {
    onApplySelection(selectedOptions)
    setShowDropdown(false)
  }, [onApplySelection, selectedOptions])
  const onDismiss = useCallback(() => {
    setSelectedOptions(initialSelectedOptions)
    setShowDropdown(false)
  }, [initialSelectedOptions])
  return (
    <>
      <div
        tabIndex={0}
        ref={dropdownRef}
        id={dropdownId}
        css={(theme) => ({
          border: '1px solid #AFB1B6',
          borderRadius: 4,
          padding: '0px 12px',
          height: 34,
          color: '#000000',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          background: theme.colors.primaryWhite
        })}
        onClick={() => setShowDropdown(true)}
      >
        <div
          css={{
            maxWidth: '75%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            height: 22,
            ':hover': {
              color: '#5e6470'
            }
          }}
        >
          {selectedOptions.length === options.length
            ? 'All'
            : selectedOptions.length === 1
            ? selectedOptions?.[0]?.text
            : `${selectedOptions.length} Selected`}
        </div>
        <div
          css={{
            color: '#5e6470',
            ':hover': {
              color: '#000000'
            }
          }}
        >
          <Icon type="ChevronDown" width={12} height={32} />
        </div>
      </div>
      {showDropdown && (
        <Callout
          target={`#${dropdownId}`}
          css={(theme) => ({
            border: '1px solid #AFB1B6',
            boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.2)',
            borderRadius: 4,
            background: theme.colors.primaryWhite,
            width: `${dropdownRef.current.offsetWidth}px`,
            padding: '8px'
          })}
          isBeakVisible={false}
          onDismiss={() => setShowDropdown(false)}
        >
          <div
            css={{
              padding: '4px 0px',
              width: '100%',
              display: 'flex',
              gridColumnGap: 8
            }}
          >
            <div
              css={(theme) => ({
                color: theme.colors.tertiaryBlue1,
                cursor: 'pointer'
              })}
              onClick={onSelectAll}
            >
              Select All
            </div>
            {' | '}
            <div
              css={(theme) => ({
                color: theme.colors.tertiaryBlue1,
                cursor: 'pointer'
              })}
              onClick={onDeselectAll}
            >
              Deselect All
            </div>
          </div>
          {options.map((x, key) => {
            const isSelected = selectedOptions.includes(x)
            return (
              <div
                key={key}
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingBottom: '5px',
                  width: '100%',
                  cursor: 'pointer',
                  ':hover': {
                    background: '#eaeaea'
                  }
                }}
                onClick={() => {
                  isSelected ? onDeselect(x) : onSelect(x)
                }}
              >
                <IndeterminateCheckbox
                  checked={isSelected}
                  label={''}
                  readOnly={true}
                />
                <div css={{ paddingLeft: '10px' }}>{x.text}</div>
              </div>
            )
          })}
          <div
            css={{
              display: 'flex',
              paddingTop: '8px',
              paddingBottom: '4px',
              justifyContent: 'end',
              gridColumnGap: 5
            }}
          >
            <button
              css={buttonStyles.primary}
              onClick={onApply}
              disabled={selectedOptions.length === 0}
            >
              Apply
            </button>
            <button css={buttonStyles.secondary} onClick={onDismiss}>
              Cancel
            </button>
          </div>
        </Callout>
      )}
    </>
  )
}
