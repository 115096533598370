import {
  DirectionalHint,
  FontIcon,
  HoverCard,
  HoverCardType,
  IPlainCardProps,
  Link,
  makeStyles
} from '@fluentui/react'
import React, { useMemo } from 'react'
import { chartColors } from 'shared/services/theme'
import tinycolor from 'tinycolor2'

const blueLight = chartColors[1]
const blueDarkHex = tinycolor(blueLight).darken(25).toHex()
const useClasses = makeStyles((theme) => ({
  info: {
    color: theme.palette.themeDark
  },
  hover: {
    backgroundColor: `${theme.palette.neutralLight}cc !important`
  },
  hover1: {
    backgroundColor: `#${blueDarkHex} !important`,
    cursor: 'pointer',
    color: theme.palette.white,
    fontWeight: 'bold'
  }
}))

export const PaymentFrequencyInfoHoverCard: React.FC<{
  paymentFrequency?: string
}> = ({ paymentFrequency }) => {
  const classes = useClasses()
  const plainCardProps = useMemo(
    (): IPlainCardProps => ({
      onRenderPlainCard: () => (
        <div
          style={{
            padding: '10px 15px',
            maxWidth: '300px',
            color: '#3a3a3a',
            border: '1px solid #B49D77',
            fontSize: 12
          }}
        >
          <strong>Payment Frequency:</strong> {paymentFrequency}
        </div>
      ),
      directionalHint: DirectionalHint.bottomRightEdge,
      calloutProps: {
        isBeakVisible: true
      }
    }),
    [paymentFrequency]
  )
  return (
    <HoverCard
      plainCardProps={plainCardProps}
      instantOpenOnClick={true}
      type={HoverCardType.plain}
    >
      <Link>
        <FontIcon iconName={'info'} className={classes.info} />
      </Link>
    </HoverCard>
  )
}
