import { createReducer } from 'typesafe-actions'
import {
  createUpdateScheduleActionTypes,
  getScheduleListActions,
  relatedPartiesDetailsActionTypes,
  ScheduleReportActionType
} from './actions'
import {
  IRelatedParty,
  IScheduleReport,
  IScheduleReportResponse
} from './types'

export interface IScheduleReportsState {
  loading: boolean
  error?: Error
  scheduleList: IScheduleReport[]
  relatedLoginDetails?: {
    relatedPartiesDetails?: IRelatedParty[]
    isRelatedPartyLoading: boolean
    error?: Error
  }
  createUpdateScheduleDetails?: {
    createUpdateresponse?: IScheduleReportResponse
    isLoading: boolean
    error?: Error
  }
}
const initialState: IScheduleReportsState = {
  loading: false,
  error: undefined,
  scheduleList: [],
  relatedLoginDetails: undefined,
  createUpdateScheduleDetails: undefined
}
export const scheduleReportsReducer = createReducer<
  IScheduleReportsState,
  ScheduleReportActionType
>(initialState)
  .handleAction(getScheduleListActions.request, (state) => ({
    ...state,
    loading: true,
    error: undefined,
    createUpdateScheduleDetails: undefined
  }))
  .handleAction(getScheduleListActions.success, (state, action) => ({
    ...state,
    loading: false,
    error: undefined,
    scheduleList: action.payload,
    createUpdateScheduleDetails: undefined
  }))
  .handleAction(getScheduleListActions.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
    createUpdateScheduleDetails: undefined
  }))
  .handleAction(relatedPartiesDetailsActionTypes.request, (state) => ({
    ...state,
    loading: false,
    error: undefined,
    relatedLoginDetails: {
      ...state,
      isRelatedPartyLoading: true,
      error: undefined,
      relatedPartiesDetails: []
    },
    createUpdateScheduleDetails: undefined
  }))
  .handleAction(relatedPartiesDetailsActionTypes.success, (state, action) => ({
    ...state,
    loading: false,
    error: undefined,
    relatedLoginDetails: {
      ...state,
      isRelatedPartyLoading: false,
      error: undefined,
      relatedPartiesDetails: action.payload
    },
    createUpdateScheduleDetails: undefined
  }))
  .handleAction(relatedPartiesDetailsActionTypes.failure, (state, action) => ({
    ...state,
    loading: false,
    error: undefined,
    relatedLoginDetails: {
      ...state,
      isRelatedPartyLoading: false,
      error: action.payload,
      relatedPartiesDetails: []
    },
    createUpdateScheduleDetails: undefined
  }))
  .handleAction(createUpdateScheduleActionTypes.request, (state) => ({
    ...state,
    loading: false,
    error: undefined,
    relatedLoginDetails: {
      ...state,
      isRelatedPartyLoading: false,
      error: undefined
    },
    createUpdateScheduleDetails: {
      ...state.createUpdateScheduleDetails,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(createUpdateScheduleActionTypes.success, (state, action) => ({
    ...state,
    loading: false,
    error: undefined,
    relatedLoginDetails: {
      ...state,
      isRelatedPartyLoading: false,
      error: undefined
    },
    createUpdateScheduleDetails: {
      ...state.createUpdateScheduleDetails,
      isLoading: false,
      error: undefined,
      createUpdateresponse: action.payload
    }
  }))
  .handleAction(createUpdateScheduleActionTypes.failure, (state, action) => ({
    ...state,
    loading: false,
    error: undefined,
    relatedLoginDetails: {
      ...state,
      isRelatedPartyLoading: false,
      error: action.payload
    },
    createUpdateScheduleDetails: {
      ...state.createUpdateScheduleDetails,
      isLoading: false,
      error: action.payload,
      createUpdateresponse: undefined
    }
  }))
