import { ActionType, createAction } from 'typesafe-actions'
import {
  IPendingManagementReviewCount,
  ITrustDashboardInfo,
  ITrustOfficerReviewCount
} from './types'

export const FETCH_TRUST_DASHBOARD_INFO =
  '@modules/@trusts/FETCH_TRUST_DASHBOARD_INFO'
export const FETCH_TRUST_DASHBOARD_INFO_SUCCESS =
  '@modules/@trusts/FETCH_TRUST_DASHBOARD_INFO_SUCCESS'
export const FETCH_TRUST_DASHBOARD_INFO_FAILURE =
  '@modules/@trusts/FETCH_TRUST_DASHBOARD_INFO_FAILURE'
export const SET_ACTIVE_TRUST = '@modules/@trusts/SET_ACTIVE_TRUST'
export const NAVIGATE_TO_PAYMENTS = '@modules/@trusts/NAVIGATE_TO_PAYMENTS'
export const NAVIGATE_TO_TRUSTINFO = '@modules/@trusts/NAVIGATE_TO_TRUSTINFO'
export const NAVIGATE_TO_TRUSTEES = '@modules/@trusts/NAVIGATE_TO_TRUSTEES'
export const NAVIGATE_TO_BENEFICIARIES =
  '@modules/@trusts/NAVIGATE_TO_BENEFICIARIES'
export const NAVIGATE_TO_ACCOUNTS = '@modules/@trusts/NAVIGATE_TO_ACCOUNTS'
export const NAVIGATE_TO_GRANTORS = '@modules/@trusts/NAVIGATE_TO_GRANTORS'

export const FETCH_PEND_MGMT_REV_COUNT =
  '@modules/@trusts/FETCH_PEND_MGMT_REV_COUNT'
export const FETCH_PEND_MGMT_REV_COUNT_SUCCESS =
  '@modules/@trusts/FETCH_PEND_MGMT_REV_COUNT_SUCCESS'
export const FETCH_PEND_MGMT_REV_COUNT_FAILURE =
  '@modules/@trusts/FETCH_PEND_MGMT_REV_COUNT_FAILURE'

export const FETCH_TRUST_OFFICER_REV_COUNT =
  '@modules/@trusts/FETCH_TRUST_OFFICER_REV_COUNT'
export const FETCH_TRUST_OFFICER_REV_COUNT_SUCCESS =
  '@modules/@trusts/FETCH_TRUST_OFFICER_REV_COUNT_SUCCESS'
export const FETCH_TRUST_OFFICER_REV_COUNT_FAILURE =
  '@modules/@trusts/FETCH_TRUST_OFFICER_REV_COUNT_FAILURE'
export const trustDashboardNavigationActions = {
  navigateToPayments: createAction(NAVIGATE_TO_PAYMENTS)(),
  navigateToTrustInfo: createAction(NAVIGATE_TO_TRUSTINFO)(),
  navigateToTrustees: createAction(NAVIGATE_TO_TRUSTEES)(),
  naviagteToBeneficiaries: createAction(NAVIGATE_TO_BENEFICIARIES)(),
  naviagteToAccounts: createAction(NAVIGATE_TO_ACCOUNTS)(),
  naviagteToGrantors: createAction(NAVIGATE_TO_GRANTORS)()
}
export const trustDashboardInfoDataActions = {
  request: createAction(FETCH_TRUST_DASHBOARD_INFO)(),
  success: createAction(FETCH_TRUST_DASHBOARD_INFO_SUCCESS)<
    ITrustDashboardInfo[]
  >(),
  failure: createAction(FETCH_TRUST_DASHBOARD_INFO_FAILURE)<any>()
}

export const pendingMgmtReviewCountActions = {
  request: createAction(FETCH_PEND_MGMT_REV_COUNT)(),
  success: createAction(FETCH_PEND_MGMT_REV_COUNT_SUCCESS)<
    IPendingManagementReviewCount | undefined
  >(),
  failure: createAction(FETCH_PEND_MGMT_REV_COUNT_FAILURE)<any>()
}

export const trustOfficerReviewCountActions = {
  request: createAction(FETCH_TRUST_OFFICER_REV_COUNT)(),
  success: createAction(FETCH_TRUST_OFFICER_REV_COUNT_SUCCESS)<
    ITrustOfficerReviewCount | undefined
  >(),
  failure: createAction(FETCH_TRUST_OFFICER_REV_COUNT_FAILURE)<any>()
}

export type TrustDashboardNavigationActions = ActionType<
  typeof trustDashboardNavigationActions
>

export type TrustDashboardInfoDataActions = ActionType<
  typeof trustDashboardInfoDataActions
>

export type PendingMgmtReviewCountActions = ActionType<
  typeof pendingMgmtReviewCountActions
>
export type TrustOfficerReviewCountActions = ActionType<
  typeof trustOfficerReviewCountActions
>

export type TrustDashboardInfoActionType =
  | TrustDashboardNavigationActions
  | TrustDashboardInfoDataActions
  | PendingMgmtReviewCountActions
  | TrustOfficerReviewCountActions
