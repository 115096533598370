import { combineReducers, Reducer } from 'redux'
import { StateType } from 'typesafe-actions'
import { manifestsReducer } from '../modules/Manifests/store/reducers'
import { statementListReducer } from '../modules/StatementList/store/reducers'

export interface IStatementModuleState {
  manifests: StateType<typeof manifestsReducer>
  statementList: StateType<typeof statementListReducer>
}

export const statementsReducer: Reducer<IStatementModuleState> =
  combineReducers({
    manifests: manifestsReducer,
    statementList: statementListReducer
  })
