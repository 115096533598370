import { DefaultButton, Icon, IIconProps, Stack } from '@fluentui/react'
import React, { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { getTheme } from '../../../../../../store/ui/selectors'

export interface IListsFilterStatusButtonProps {
  iconProps?: IIconProps
  primary?: boolean
  onClick?: (ev: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onRemove?: () => void
}

export const ListsFilterStatusButton: React.FC<
  PropsWithChildren<IListsFilterStatusButtonProps>
> = ({ children, iconProps, primary, onClick, onRemove }) => {
  const onRemoveClicked = (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
    ev.stopPropagation()
    onRemove?.()
  }

  const theme = useSelector(getTheme)

  return (
    <DefaultButton
      onClick={onClick}
      primary={primary}
      iconProps={iconProps}
      styles={{
        root: {
          padding: 0,
          paddingRight: 10,
          paddingLeft: 10,
          height: '28px',
          borderRadius: '20px'
        }
      }}
    >
      <Stack
        horizontal={true}
        horizontalAlign="space-between"
        verticalAlign="center"
        tokens={{ childrenGap: 10 }}
      >
        {children}
        {onRemove && (
          <Icon
            className="cancel-icon"
            iconName="Cancel"
            title="Remove filter"
            style={{ padding: '3px', fontSize: '12px', fontWeight: 'bold' }}
            styles={{
              root: {
                display: 'block',
                backgroundColor: theme.palette.neutralLight,
                borderRadius: '15px',
                selectors: {
                  ':hover': {
                    backgroundColor: theme.palette.neutralQuaternaryAlt,
                    color: theme.palette.black
                  }
                }
              }
            }}
            onClick={onRemoveClicked}
          />
        )}
      </Stack>
    </DefaultButton>
  )
}
