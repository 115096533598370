export * from './HomeOfficeContainer'
import { lazy } from 'react'
import { combineReducers } from 'redux'
import { IModuleDefinition } from '../../../types'
import { homeOfficeModulesReducer, homeOfficeModulesSagas } from './modules'

const HomeOfficeContainer = lazy(() => import('./HomeOfficeContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/homeoffice/*',
    private: true,
    component: HomeOfficeContainer
  },
  name: 'Home Office'
}

export default moduleDefinition

export const homeOfficeModuleReducer = combineReducers({
  modules: homeOfficeModulesReducer
})

export const homeOfficeModuleSagas = [...homeOfficeModulesSagas]
