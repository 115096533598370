import { IndeterminateProgressIndicator } from 'modules/Advisory/modules/Rdot360/components/shared'
import { SnackBar } from 'modules/Advisory/modules/Rdot360/components/shared/Snackbar'
import { useIncomeFeatureStore } from 'modules/Advisory/modules/Rdot360/features/Income'
import { useMemo } from 'react'
import { HorizontalScrollContainer } from 'shared/components/HorizontalScrollContainer'
import { getClasses } from '../AccountView/AccountView'
import { IncomeSummaryChartContainer } from './IncomeSummaryChartContainer'
import { ProjectedIncomeDetailSummaryTable } from './SummaryTable'
import { useIncomeSummaryContext } from './useIncomeSummaryContext.'

export const SummaryView = () => {
  const classes = useMemo(() => getClasses(), [])
  const { includeOptionPremiums } = useIncomeFeatureStore()

  const { summaryData, isFetching, error, refetch, dates } =
    useIncomeSummaryContext()

  return (
    <>
      <IncomeSummaryChartContainer />
      <div css={classes.tableContainer}>
        {isFetching && <IndeterminateProgressIndicator />}
        <HorizontalScrollContainer>
          <ProjectedIncomeDetailSummaryTable
            data={summaryData}
            dates={dates}
            includeOptionPremiums={includeOptionPremiums}
          />
        </HorizontalScrollContainer>
        {!!error && (
          <div css={{ paddingBottom: '5px' }}>
            <SnackBar
              message={
                (error as Error)?.message || 'An unknown error occurred.'
              }
              type="Failure"
              actionButtonLabel="click to retry"
              onActionButtonClicked={refetch}
            />
          </div>
        )}
      </div>
    </>
  )
}
