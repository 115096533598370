import { accountListHookDefinition } from 'features/Lists/accounts'
import { clientListHookDefinition } from 'features/Lists/clients'
import { IColumnDefinition } from 'features/Lists/core/contracts/IColumnDefinition'
import { IListsActions } from 'features/Lists/core/store/actions'
import { IListsSelectors } from 'features/Lists/core/store/selectors'
import { householdListHookDefinition } from 'features/Lists/households'
import { orderListHookDefinition } from 'features/Lists/orders'
import { positionListHookDefinition } from 'features/Lists/positions'
import { keyBy } from 'lodash'
import { ListTypes } from './types'

export interface IUseListHookDefinition<T extends string = string> {
  listType: T
  columnDefinitions: Record<string, IColumnDefinition>
  actions: IListsActions<any>
  selectors: IListsSelectors<any>
}

export const hookDefinitions = keyBy(
  [
    accountListHookDefinition,
    clientListHookDefinition,
    householdListHookDefinition,
    positionListHookDefinition,
    orderListHookDefinition
  ],
  ({ listType }) => listType
) as Record<ListTypes, IUseListHookDefinition<ListTypes>>
