import { CellContext } from '@tanstack/react-table'
import { CDMv2Account } from 'api/nickname.types'
import { FC, useCallback } from 'react'

export const InputTableCell: FC<{
  context: CellContext<CDMv2Account, unknown>
  setUpdatedNickname: (accountId: string, nickname: string | null) => void
  updatedNicknames?: Record<string, string | null | undefined>
}> = ({ context: { row, getValue }, setUpdatedNickname, updatedNicknames }) => {
  const accountId = row.original.accountID || ''
  const initialValue = getValue<string>()
  const updatedNickname = updatedNicknames?.[accountId]
  const value = updatedNickname === undefined ? initialValue : updatedNickname

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!accountId) {
        return
      }
      const value = e.target.value || null
      setUpdatedNickname(accountId, value)
    },
    [accountId, setUpdatedNickname]
  )

  return (
    <input
      value={value || ''}
      onChange={onChange}
      maxLength={100}
      css={{
        borderRadius: 4,
        border: '1px solid #AFB1B6',
        background: '#FFF',
        height: 34,
        padding: '8px 12px',
        fontSize: 14,
        width: '100%'
      }}
      placeholder="Nickname not available"
      type="search"
    />
  )
}
