import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  Overlay,
  Panel,
  PrimaryButton,
  Stack,
  TextField
} from '@fluentui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingComponent } from '../../../../shared/components/Loading'
import {
  editHouseholdNamePanelActions,
  getEditHouseholdNamePanelHousehold,
  getIsEditHouseholdNamePanelOpen,
  getIsUpdateNameLoading,
  getUpdateNameError,
  updateHouseholdNameActions
} from './store'

export const EditHouseholdNamePanel: React.FC = () => {
  const dispatch = useDispatch()
  const isPanelOpen = useSelector(getIsEditHouseholdNamePanelOpen)
  const household = useSelector(getEditHouseholdNamePanelHousehold)
  const isLoading = useSelector(getIsUpdateNameLoading)
  const error = useSelector(getUpdateNameError)
  const closePanel = useCallback(() => {
    dispatch(editHouseholdNamePanelActions.closePanel())
  }, [dispatch])

  const [householdName, setHouseholdName] = useState<string>()
  useEffect(() => {
    setHouseholdName(household?.name)
  }, [household])

  const onSubmit = useCallback(() => {
    if (!household?.id || !householdName) {
      return
    }

    dispatch(
      updateHouseholdNameActions.request({
        householdId: household.id,
        name: householdName
      })
    )
  }, [dispatch, household, householdName])

  const onRenderFooterContent = useCallback(() => {
    const canSubmit = householdName && household?.name !== householdName
    return (
      <Stack tokens={{ childrenGap: 10 }}>
        {error && (
          <MessageBar messageBarType={MessageBarType.error}>
            An error occurred while submitting the request
            {error.message ? `: ${error.message}` : ''}
          </MessageBar>
        )}
        <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
          <PrimaryButton onClick={onSubmit} disabled={!canSubmit}>
            Submit
          </PrimaryButton>

          <DefaultButton onClick={closePanel}>Cancel</DefaultButton>
        </Stack>
      </Stack>
    )
  }, [closePanel, error, household, householdName, onSubmit])

  const onHouseholdNameChange = useCallback((_: unknown, newValue?: string) => {
    setHouseholdName(newValue)
  }, [])

  return (
    <Panel
      headerText="Edit Household Name"
      isOpen={isPanelOpen}
      onDismiss={closePanel}
      closeButtonAriaLabel="Close"
      onRenderFooterContent={onRenderFooterContent}
    >
      {isLoading && (
        <Overlay styles={{ root: { zIndex: 1 } }}>
          <LoadingComponent />
        </Overlay>
      )}
      {isPanelOpen && (
        <div style={{ marginTop: '20px' }}>
          <TextField
            value={householdName}
            onChange={onHouseholdNameChange}
            autoComplete="off"
          />
        </div>
      )}
    </Panel>
  )
}
