import { call, put, takeLatest } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../../../store/shared/sagas'
import {
  fetchClientFinanceDetailsSvc,
  fetchTrustInfoListSvc
} from '../api/trustInfoService'
import { trustFinancialDataActions, trustInfoDataActions } from './actions'

function* handleGetTrustsList(
  action: ReturnType<typeof trustInfoDataActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchTrustInfoListSvc(action.payload, options)
    )
    yield put(trustInfoDataActions.success(data))
    if (data?.rcm_rockcodbpartyid) {
      yield put(trustFinancialDataActions.request(data?.rcm_rockcodbpartyid))
    }
  } catch (e) {
    yield put(trustInfoDataActions.failure(e as Error))
  }
}

function* handleGetTrustFinanceData(
  action: ReturnType<typeof trustFinancialDataActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchClientFinanceDetailsSvc(action.payload, options)
    )
    yield put(trustFinancialDataActions.success(data?.value?.[0]))
  } catch (e) {
    yield put(trustFinancialDataActions.failure(e))
  }
}

export const sagas = [
  () => takeLatest(trustInfoDataActions.request, handleGetTrustsList),
  () => takeLatest(trustFinancialDataActions.request, handleGetTrustFinanceData)
]
