import createCache from '@emotion/cache'
import { ThemeProvider, CacheProvider } from '@emotion/react'
import { createRoot } from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { Store, AnyAction } from 'redux'
import { whenIdle } from 'shared/async'
import { theme } from 'shared/theme'
import { PrintHTML } from './PrintHTML'

const generateHTML = async (
  masked: boolean,
  hideHousehold: boolean,
  store: Store<unknown, AnyAction>
) => {
  // Emotion renders the style tags in this div with dynamic classnames for each css prop
  const emotionContainer = document.createElement('div')
  const cache = createCache({
    key: 'print',
    speedy: false,
    container: emotionContainer
  })
  const container = document.createElement('div')
  const root = createRoot(container)
  root.render(
    <Provider store={store}>
      <IntlProvider locale="en">
        <ThemeProvider theme={theme}>
          <CacheProvider value={cache}>
            <PrintHTML masked={masked} hideHousehold={hideHousehold} />
          </CacheProvider>
        </ThemeProvider>
      </IntlProvider>
    </Provider>
  )
  await whenIdle()
  container.appendChild(emotionContainer)
  const html = container.innerHTML
  root.unmount()

  return html
}

export const openBalancesPrintTab = async (
  masked: boolean,
  hideHousehold: boolean,
  store: Store<unknown, AnyAction>
) => {
  const innerHtml = await generateHTML(masked, hideHousehold, store)
  const blob = new Blob([innerHtml], { type: 'text/html' })
  const url = URL.createObjectURL(blob)
  open(url, '_blank', 'noreferrer')
  setTimeout(() => URL.revokeObjectURL(url), 5000)
}
