import { Label, MessageBar, Stack } from '@fluentui/react'
import { Separator } from '../../shared/components/Separator'
import { ExistingClient3rdPartySearch } from './ExistingClient3rdPartySearch'
import { DelinkingList } from './features/DelinkingList/DelinkingList'

export const AccountDelinking: React.FC<{
  addAccount: (account: string) => void
  removeAccount: (account: string) => void
  delinkingAccounts?: string[]
}> = ({ addAccount, removeAccount, delinkingAccounts }) => {
  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Separator />

      <ExistingClient3rdPartySearch />

      <Separator />

      <div>
        <Label required>3. Delink Accounts</Label>
        <MessageBar isMultiline={true} dismissButtonAriaLabel="Close">
          Select the accounts in the list below by clicking the checkbox to the
          left of each account that you would like to delink from the Client or
          3rd Party. Once you have selected the accounts, click the{' '}
          <b>Submit</b> button to submit the request.
        </MessageBar>
      </div>
      <DelinkingList
        addAccount={addAccount}
        removeAccount={removeAccount}
        delinkingAccounts={delinkingAccounts}
      />
    </Stack>
  )
}
