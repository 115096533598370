import { SearchBox } from '@fluentui/react'
import React, { useCallback } from 'react'
import { IListsSearchFilter } from '../../../contracts/IListsFilter'
import { IListsFilterEditProps } from '../contracts/IListsFilterEditProps'

export interface IListsSearchFilterProps extends IListsFilterEditProps {
  filter: IListsSearchFilter
}

export const ListsSearchFilter: React.FC<IListsSearchFilterProps> = ({
  onChange,
  filter
}) => {
  const onSearchChange = useCallback(
    (_: any, newValue?: string) => {
      const newFilter: IListsSearchFilter = {
        ...filter,
        type: 'search',
        value: newValue,
        hasValue: !!newValue
      }
      onChange(newFilter)
    },
    [filter, onChange]
  )

  const { value } = filter

  return (
    <SearchBox
      value={value || ''}
      onChange={onSearchChange}
      autoComplete="off"
      autoFocus={true}
    />
  )
}
