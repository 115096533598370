import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { IClient } from 'api/client.types'
import { ISearchResult } from 'api/common.types'
import { IAnnuityPPLIContract } from 'api/datahub'
import { stringify } from 'query-string'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { isNotNullOrEmpty, isNotNullOrUndefined } from 'shared/guards'
import { constructQuery, datahubApi } from 'store/api/datahub'
import { AxiosBaseArgs, arrayCommaParamsSerializer } from 'store/api/shared'

type RepIdPropsType = {
  registeredRepIds?: string[]
  contractTypeFilter?: string
}

type Aggregation = {
  TotalCount?: number
  TotalPremium?: number
  TotalCashValue?: number
  TotalDeathBenefit?: number
}

type queryProps = RepIdPropsType & {
  contractStatus?: string[]
  contractType?: string
  ytd?: true
}

type SummaryQueryProps = RepIdPropsType & {
  contractStatus?: string[]
}

const date = new Date()
const lastYear = date.getFullYear() - 1

const paramsSerializer = (params: any) =>
  stringify(params, { arrayFormat: 'comma' })

const api = datahubApi.injectEndpoints({
  endpoints: (builder) => ({
    getAnnuityAggregation: builder.query<Aggregation[] | undefined, queryProps>(
      {
        query: (props) => {
          const { contractStatus, contractType, registeredRepIds, ytd } = props

          const filtersAndAggregations = [
            contractType && `filter(contractType in ('${contractType}'))`,
            contractStatus &&
              `filter(contractStatusDesc in ('${contractStatus?.join(
                "', '"
              )}'))`,
            registeredRepIds &&
              `filter(registeredRep in ('${registeredRepIds
                ?.filter(isNotNullOrEmpty)
                .join("', '")}'))`,
            ytd && `filter(year(issueDate) gt ${lastYear})`
          ]

          const totals = [
            '$count as TotalCount',
            ytd && `premiumPaidSinceInception with sum as TotalPremium`,
            ytd || contractType !== 'Insurance'
              ? undefined
              : `deathBenefitAmt with sum as TotalDeathBenefit`,
            ytd ? undefined : `cashValue with sum as TotalCashValue`
          ]

          const totalsJoined = totals.filter(isNotNullOrUndefined).join(', ')

          const aggregatedProperties = `aggregate(${totalsJoined})`

          filtersAndAggregations.push(aggregatedProperties)

          return {
            url: 'AnnuityPPLIContracts',
            params: {
              $apply: filtersAndAggregations
                .filter(isNotNullOrUndefined)
                .join('/')
            },
            paramsSerializer
          }
        },
        keepUnusedDataFor: 60 * 60 * 2,
        transformResponse: (x: IOdataResult<Aggregation>) => x.value || []
      }
    ),

    getAnnuitySummary: builder.query<IAnnuityPPLIContract[], SummaryQueryProps>(
      {
        query: ({ registeredRepIds, contractStatus }) => {
          const repIdFilter =
            registeredRepIds &&
            `registeredRep in ('${registeredRepIds
              ?.filter(isNotNullOrEmpty)
              .join("', '")}')`

          const allFilters = [
            contractStatus &&
              `contractStatusDesc in ('${contractStatus.join("', '")}')`,
            repIdFilter
          ]

          return {
            url: 'AnnuityPPLIContracts',
            params: {
              $filter: allFilters.filter(isNotNullOrUndefined).join(' and ')
            },
            paramsSerializer
          }
        },
        keepUnusedDataFor: 60 * 60 * 2,
        transformResponse: (x: IOdataResult<IAnnuityPPLIContract>) =>
          x.value || []
      }
    ),

    getClientSearch: builder.query<ISearchResult<IClient> | undefined, string>({
      queryFn: async (search, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<ISearchResult<IClient>, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: 'search/clients',
          paramsSerializer: arrayCommaParamsSerializer
        }

        const searchText = constructQuery(search)
        const baseApiParams = {
          search: searchText,
          $count: true,
          select: [
            'LegalEntityName',
            'LegalEntityID',
            'householdName',
            'legalEntityType',
            'contactdetails'
          ],
          queryType: 'full',
          searchFields: ['LegalEntityName']
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          params: { ...baseApiParams }
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data
        }
      },
      keepUnusedDataFor: 60 * 60 * 2
    }),

    updateInsuranceAccountLink: builder.mutation<
      undefined,
      { id: string; request: { LegalEntityID?: string } }
    >({
      query: ({ id, request }) => ({
        url: `InsuranceManualAccount/${id}`,
        method: 'PATCH',
        data: request
      })
    })
  })
})

export const {
  useGetAnnuityAggregationQuery,
  useGetAnnuitySummaryQuery,
  useGetClientSearchQuery,
  useUpdateInsuranceAccountLinkMutation
} = api
