import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel
} from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { detailTable } from '../../../components/shared/DataTable/DetailTable'
import { constants } from '../../../shared/theme'
import { IRetrieveDocumentListDocumentItem } from '../../../store/documentsApi/IRetrieveDocumentListResponse'
import { useDocumentModuleStore } from '../DocumentFilter/store'
import { constructColumns } from './StatementsTableColumns'

const StatementsDetailsTable: React.FC<{
  items?: IRetrieveDocumentListDocumentItem[]
}> = ({ items }) => {
  const columns = useMemo(() => constructColumns(), [])
  const [sorting, setSorting] = useState<SortingState>([])
  const data = useMemo(() => items || [], [items])
  const { rowSelection, updateSelection } = useDocumentModuleStore()

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection
    },
    onSortingChange: setSorting,
    onRowSelectionChange: updateSelection,
    getRowId: (originalRow) =>
      `${originalRow.PrimaryValue}:${originalRow.ObjectId}`,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  })

  return (
    <div>
      <table css={[detailTable.detailTable, { minWidth: '950px' }]}>
        <thead>
          <tr
            css={{
              position: 'sticky',
              top: constants.headerOffsetPx,
              zIndex: 1
            }}
          >
            {table.getFlatHeaders().map((header) => {
              return (
                <th
                  key={header.id}
                  style={{
                    width: header.getSize(),
                    maxWidth: header.column.columnDef.maxSize,
                    minWidth: header.column.columnDef.minSize
                  }}
                >
                  <div
                    onClick={header.column.getToggleSortingHandler()}
                    css={{
                      cursor: 'pointer',
                      height: '16px'
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} style={{ height: '50px' }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default StatementsDetailsTable
