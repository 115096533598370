import { cloneDeep, flow, keyBy, orderBy } from 'lodash'
import { createSelector } from 'reselect'
import { put, select, takeLatest } from 'typed-redux-saga'
import { ActionType, createReducer } from 'typesafe-actions'
import { IFacetResult } from '../../api/common.types'
import {
  IListsFacetFilter,
  IListsFilter
} from '../Lists/core/contracts/IListsFilter'
import { IDataTableSortBy } from './common/types'
import { IDataListActions } from './contracts/IDataListActions'
import { IDataListColumnDefinition } from './contracts/IDataListColumnDefinition'
import { IDataListSelectors } from './contracts/IDataListSelectors'
import { IDataListState } from './contracts/IDataListState'
import {
  createActionWithPrefix,
  createEmptyActionWithPrefix,
  evaluateFacets,
  evaluateFilter
} from './service'

export interface ICreateDataListStoreOptions<T, U> {
  prefix: string
  initialState: IDataListState<T>
  itemsSelector: (state: U) => T[] | undefined
  rootSelector: (state: U) => IDataListState<T>
}

export const createDataListStore = <T, U>({
  initialState,
  prefix,
  itemsSelector,
  rootSelector
}: ICreateDataListStoreOptions<T, U>) => {
  const { filters, columns } = initialState
  const initialFilters = cloneDeep(filters)

  const UPDATE_FILTER = '@features/@dataList/UPDATE_FILTER'
  const RESET_FILTER = '@features/@dataList/RESET_FILTER'
  const UPDATE_SORT = '@features/@dataList/UPDATE_SORT'
  const UPDATE_FACET = '@features/@dataList/UPDATE_FACET'
  const UPDATE_COLUMNS = '@features/@dataList/UPDATE_COLUMNS'

  const actions: IDataListActions<T> = {
    updateFilters: createActionWithPrefix(prefix, UPDATE_FILTER)<
      Record<string, IListsFilter>
    >(),
    resetFilters: createEmptyActionWithPrefix(prefix, RESET_FILTER)(),
    updateSort: createActionWithPrefix(prefix, UPDATE_SORT)<IDataTableSortBy>(),
    updateFacet: createActionWithPrefix(prefix, UPDATE_FACET)<string>(),
    updateColumns: createActionWithPrefix(prefix, UPDATE_COLUMNS)<
      IDataListColumnDefinition<T>[]
    >()
  }

  const reducer = createReducer<
    IDataListState<T>,
    ActionType<IDataListActions<T>>
  >(initialState)
    .handleAction(actions.updateFilters, (state, action) => ({
      ...state,
      filters: { ...state.filters, ...action.payload }
    }))
    .handleAction(actions.updateSort, (state, action) => ({
      ...state,
      sortBy: action.payload
    }))
    .handleAction(actions.resetFilters, (state) => ({
      ...state,
      filters: initialFilters
    }))
    .handleAction(actions.updateColumns, (state, action) => ({
      ...state,
      columns: action.payload
    }))

  const getSortBy = flow(rootSelector, ({ sortBy }) => sortBy)
  const getFilters = flow(rootSelector, ({ filters }) => filters)
  const getItems = itemsSelector
  const getColumns = flow(rootSelector, ({ columns }) => columns)

  const valueFns = columns.reduce(
    (a, x) => ({ ...a, [x.name]: x.getValue }),
    {} as Record<string, IDataListColumnDefinition<T>['getValue']>
  )

  const getFilteredAndSortedItems = createSelector(
    [getItems, getSortBy, getFilters, getColumns],
    (items, sortBy, filters, columns) => {
      const filteredItems = filters
        ? items?.filter((item) => evaluateFilter(item, valueFns, filters))
        : items
      const columnLookup = keyBy(columns, ({ name }) => name)
      return sortBy
        ? orderBy(
            filteredItems,
            columnLookup[sortBy.name].getValue,
            sortBy.direction
          )
        : filteredItems
    }
  )

  const getItemsCount = createSelector([getItems], (items) => items?.length)

  const selectors: IDataListSelectors<T, U> = {
    getItems,
    getItemsCount,
    getFilters,
    getColumns,
    getSortBy,
    getFilteredAndSortedItems
  }

  const sagas = [
    () =>
      takeLatest(
        actions.updateFacet,
        function* (action: ReturnType<typeof actions.updateFacet>) {
          const filters = yield* select(selectors.getFilters)
          const filterLookup = keyBy(filters, (x) => x.name)

          const items = yield* select(selectors.getItems)
          const filteredItems = items?.filter((x) =>
            evaluateFilter(x, valueFns, {
              ...filters,
              [action.payload]: { hasValue: false } as any
            })
          )
          const columns = yield* select(selectors.getColumns)
          const columnLookup = keyBy(columns, ({ name }) => name)
          const facets = evaluateFacets(filteredItems || [], [
            columnLookup[action.payload]
          ])
          const filter = filterLookup[action.payload]
          const columnFacet = Object.entries(facets[action.payload] || {})
            .map(([value, count]): IFacetResult => ({ value, count }))
            .sort((a, b) => b.count - a.count)

          yield put(
            actions.updateFilters({
              [action.payload]: {
                ...filter,
                facets: columnFacet
              } as IListsFacetFilter
            })
          )
        }
      )
  ]

  return {
    actions,
    reducer,
    selectors,
    sagas
  }
}
