import { css } from '@emotion/react'
import { FC, useMemo } from 'react'
import TileHeading, {
  BaseHeaderProps,
  ViewType
} from '../../../components/TileHeading'
import { useIncomeFeatureStore } from '../../../features/Income'

import { useHandleViewTypeChange } from '../../../hooks/useHandleViewTypeChange'
import { ClientDashboardTiles } from '../../../shared/types'
import { IncomeWidgetChartHistorical } from './IncomeWidgetChartHistorical'
import { IncomeWidgetChartProjected } from './IncomeWidgetChartProjected'
import { IncomeWidgetTable } from './IncomeWidgetTable'

const tileName = ClientDashboardTiles.incomeTile

export const getClasses = () => ({
  container: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  })
})

export const IncomeWidgetContainer: FC<BaseHeaderProps> = ({ onExpand }) => {
  const classes = useMemo(() => getClasses(), [])
  const { incomeMode } = useIncomeFeatureStore()
  const { handleViewTypeChange, viewType } = useHandleViewTypeChange(tileName)

  return (
    <div css={classes.container}>
      <TileHeading
        viewType={viewType}
        onViewtypeChange={handleViewTypeChange}
        graphIconType="chart"
        onExpand={onExpand}
      >
        <div>Income</div>
      </TileHeading>

      {viewType === ViewType.graph && incomeMode === 'historical' && (
        <IncomeWidgetChartHistorical />
      )}

      {viewType === ViewType.graph && incomeMode === 'projected' && (
        <IncomeWidgetChartProjected />
      )}

      {viewType === ViewType.table && <IncomeWidgetTable />}
    </div>
  )
}
