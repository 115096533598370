import { css } from '@emotion/react'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { parseDateISOStringInLocalTimezone } from 'shared'
import DatePicker from '../../../components/shared/DatePicker'

const parseDate = (date?: string) =>
  date ? parseDateISOStringInLocalTimezone(date) : undefined

export const getClasses = () => ({
  dateRangeContainer: css({
    display: 'flex',
    flexDirection: 'column',
    rowGap: 3
  }),
  datePickerContainer: css({
    fontSize: '14px',
    lineHeight: '1.5',
    width: '125px'
  }),
  datePickerLabel: css({
    fontSize: '14px',
    fontWeight: 500
  })
})

interface fromToFilter {
  dateLabel: string
  minDate: string | undefined
  maxDate: string | undefined
  date: string | undefined
  setDate: (startDate?: string) => void
}

const DateFromToFilter: React.FC<fromToFilter> = ({
  dateLabel,
  minDate,
  maxDate,
  date,
  setDate
}) => {
  const classes = useMemo(() => getClasses(), [])

  return (
    <div css={classes.dateRangeContainer}>
      <div css={classes.datePickerLabel}>{dateLabel}</div>
      <div css={classes.datePickerContainer}>
        <DatePicker
          value={date ? parseDate(date) : undefined}
          onSelectDate={(date) => date && setDate(format(date, 'yyyy-MM-dd'))}
          formatDate={(date) => (date ? format(date, 'yyyy-MM-dd') : '')}
          minDate={minDate ? parseDate(minDate) : undefined}
          maxDate={maxDate ? parseDate(maxDate) : new Date()}
          placeholder="YYYY-MM-DD"
          style={{ width: '135px' }}
        />
      </div>
    </div>
  )
}

export default DateFromToFilter
