import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useGetAnnuitySummaryQuery } from '../api/apiContracts'
import { useSelectedReps } from './useSelectedReps'

export const useGetSummaryDetails = () => {
  const { selectedReps, shouldMakeApiCall } = useSelectedReps()

  const queryProps = {
    registeredRepIds: selectedReps,
    contractStatus: [
      'Active',
      'Active Annuity Payout',
      'In Force',
      'Paid Up',
      'Lapse Pending'
    ]
  }

  const { data, isFetching: isSummaryFetching } = useGetAnnuitySummaryQuery(
    shouldMakeApiCall ? queryProps : skipToken
  )

  return { data, isSummaryFetching }
}
