import { GroupingState } from '@tanstack/react-table'
import { partial } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import {
  useEnsurePreferencesContainer,
  useLazyPreferencesContainer,
  usePreferencesContainer
} from 'store/preferences'
import { createPreferencesContainerSelector } from 'store/preferences/preferencesApi'
import { ViewType } from '../components/TileHeading'
import type { ActivityTableType } from '../modules/Dashboard/Activity/AccountActivity'
import { ClientDashboardTiles } from '../shared/types'
import { IncomeApiMode, PositionCategoryType } from '../store/holdingsApi'

const clientDashboardContainerId =
  'client_dashboard/client_dashboard_preferences'

interface ITileDetailsBase {
  viewType?: ViewType
  viewByKey?: string
}

interface IHoldingsTileDetails extends ITileDetailsBase {
  excludeUnclassifiedSectors?: boolean
}

interface IIncomeTileDetails extends ITileDetailsBase {
  includeOptionPremiums?: boolean
  incomeMode?: IncomeApiMode
}

interface IActivityTileDetails extends ITileDetailsBase {
  activityTableType?: ActivityTableType
}

interface IAssetsTileDetails extends ITileDetailsBase {
  positionCategoryType?: PositionCategoryType
}

interface IClientDashboardCommonPreferences {
  grouping?: GroupingState
  includeClosedAccounts?: boolean
  isSidePanelOpen?: boolean
  tilesOrder?: string[]
  preferredNickName?: string
}

interface IClientDashboardPreferences {
  commonPreferences?: IClientDashboardCommonPreferences
  [ClientDashboardTiles.activityTile]?: IActivityTileDetails
  [ClientDashboardTiles.balanceTile]?: ITileDetailsBase
  [ClientDashboardTiles.incomeTile]?: IIncomeTileDetails
  [ClientDashboardTiles.holdingsTile]?: IHoldingsTileDetails
  [ClientDashboardTiles.assetsTile]?: IAssetsTileDetails
}

const selectClientDashboardPreferencesApiResult =
  createPreferencesContainerSelector(clientDashboardContainerId)

export const selectClientDashboardPreferences = createSelector(
  [selectClientDashboardPreferencesApiResult],
  (preferences) =>
    preferences?.data?.value as IClientDashboardPreferences | undefined
)

export const selectPreferredNickNamePreference = createSelector(
  [selectClientDashboardPreferences],
  (preferences) => preferences?.commonPreferences?.preferredNickName
)

export const selectIncludeClosedAccountsPreference = createSelector(
  [selectClientDashboardPreferences],
  (preferences) => preferences?.commonPreferences?.includeClosedAccounts
)

export const selectGroupingPreference = createSelector(
  [selectClientDashboardPreferences],
  (preferences) => preferences?.commonPreferences?.grouping
)

const selectCommonPreferences = createSelector(
  [selectClientDashboardPreferences],
  (preferences) => preferences?.commonPreferences
)

const selectIsLoaded = createSelector(
  [selectClientDashboardPreferencesApiResult],
  (preferences) => preferences.isError || preferences.isSuccess
)

const useLazyClientDashboardPreferences = () => {
  const {
    setPreferences: apiSetPreferences,
    getPreferences: apiGetPreferences
  } = useLazyPreferencesContainer<IClientDashboardPreferences>()

  const [getPreferences, setPreferences] = useMemo(
    () => [
      partial(apiGetPreferences, clientDashboardContainerId),
      partial(apiSetPreferences, clientDashboardContainerId)
    ],
    [apiGetPreferences, apiSetPreferences]
  )

  return { setPreferences, getPreferences }
}

const useClientDashboardPreferences = () => {
  const {
    preferences: apiPreferences,
    setPreferences: setApiPreferences,
    isFetchingComplete: isApiPreferencesLoaded
  } = usePreferencesContainer<IClientDashboardPreferences>(
    clientDashboardContainerId
  )

  const [preferences, setPreferences, isLoaded] = [
    apiPreferences,
    setApiPreferences,
    isApiPreferencesLoaded
  ]

  return { preferences, setPreferences, isLoaded }
}

export const useClientDashboardTilePreferences = <
  T extends ClientDashboardTiles
>(
  tileName?: T
) => {
  const { isLoaded, preferences, setPreferences } =
    useClientDashboardPreferences()

  const tilePreferences = tileName && preferences?.[tileName]

  const setTilePreferences = useCallback(
    (newTilePreferences: IClientDashboardPreferences[T]) => {
      setPreferences({
        [tileName as string]: {
          ...tilePreferences,
          ...newTilePreferences
        }
      })
    },
    [tilePreferences, setPreferences, tileName]
  )

  return {
    isLoaded,
    tilePreferences,
    setTilePreferences
  }
}

export const useLazyClientDashboardCommonPreferences = () => {
  const { getPreferences: cdGetPreferences, setPreferences: cdSetPreferences } =
    useLazyClientDashboardPreferences()

  const setPreferences = useCallback(
    async (updatedCommonPreferences: IClientDashboardCommonPreferences) => {
      const preferences = await cdGetPreferences()
      cdSetPreferences({
        commonPreferences: {
          ...preferences?.commonPreferences,
          ...updatedCommonPreferences
        }
      })
    },
    [cdGetPreferences, cdSetPreferences]
  )

  const getPreferences = useCallback(async () => {
    const preferences = await cdGetPreferences()
    return preferences.commonPreferences
  }, [cdGetPreferences])

  return { setPreferences, getPreferences }
}

export const useClientDashboardCommonPreferences = () => {
  useEnsurePreferencesContainer(clientDashboardContainerId)
  const { setPreferences: setCommonPreferences } =
    useLazyClientDashboardCommonPreferences()

  const isLoaded = useSelector(selectIsLoaded)
  const preferences = useSelector(selectCommonPreferences)

  const setPreferences = useCallback(
    (updatedCommonPreferences: IClientDashboardCommonPreferences) => {
      setCommonPreferences(updatedCommonPreferences)
    },
    [setCommonPreferences]
  )

  return { isLoaded, preferences, setPreferences }
}
