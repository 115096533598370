import { CellContext, HeaderContext } from '@tanstack/react-table'
import { IAnnuityPPLIAssets } from 'api/datahub'
import { sum } from 'lodash'
import { FormattedDate, FormattedNumber } from 'react-intl'
import { USD } from 'shared/components/Formatting'
import { isNotNullOrEmpty, isNotNullOrUndefined } from 'shared/guards'
import { AnnuityPPLICombined } from '../../shared/types/annuityPPLICombined'

export const getFormattedDate = (
  value: string | number
): React.ReactElement | string => {
  const date = new Date(value)
  const isValidDate = value !== null && date.toString() !== 'Invalid Date'
  const formattedDate = isValidDate ? (
    <FormattedDate day="2-digit" month="2-digit" year="numeric" value={date} />
  ) : (
    '--'
  )

  return formattedDate
}

export const DateCell: React.FC<CellContext<AnnuityPPLICombined, unknown>> = ({
  getValue
}) => {
  const value = getValue<string | number>()

  if (typeof value === 'string' && value.includes('Multiple')) {
    return <>{value}</>
  }

  return getFormattedDate(value)
}

export const AmountCellUSD: React.FC<
  CellContext<AnnuityPPLICombined, unknown>
> = ({ getValue }) => {
  const value = getValue<number>()
  return value ? (
    <USD value={value} fractionDigits={0} currencySign={'standard'} />
  ) : (
    <>--</>
  )
}

export const PercentCell: React.FC<
  CellContext<IAnnuityPPLIAssets, unknown>
> = ({ getValue }) => {
  const value = getValue<number>()
  return value ? (
    <>
      <FormattedNumber
        value={value}
        minimumFractionDigits={1}
        maximumFractionDigits={1}
      />
      %
    </>
  ) : (
    <>--</>
  )
}

export const SumAmountFooter: React.FC<
  HeaderContext<AnnuityPPLICombined, unknown>
> = ({ table, column }) => {
  const tableRows = table.getRowModel().rows

  if (tableRows.length === 0) {
    return <>0</>
  }

  const tableRowsFiltered = table.getCanSomeRowsExpand()
    ? tableRows.filter((row) => row.getIsGrouped())
    : tableRows

  const columnNumberValues = tableRowsFiltered
    .map((row) => row.getValue<number>(column.id))
    .filter(isNotNullOrUndefined)

  const total = sum(columnNumberValues)

  return (
    <FormattedNumber
      value={total}
      style="currency"
      currency="USD"
      maximumFractionDigits={0}
      minimumFractionDigits={0}
    />
  )
}

export const AggregatedUniqueTotal: React.FC<
  CellContext<AnnuityPPLICombined, unknown>
> = ({ getValue }) => {
  const value = getValue<string[]>()
  const uniqTotal = value.filter(isNotNullOrEmpty).length

  return value ? (
    <FormattedNumber
      value={uniqTotal}
      maximumFractionDigits={0}
      minimumFractionDigits={0}
    />
  ) : (
    <>--</>
  )
}

export const AggregatedUnique: React.FC<
  CellContext<AnnuityPPLICombined, unknown>
> = ({ getValue }) => {
  const value = getValue<string[]>()
  const uniqValues = value.filter(isNotNullOrEmpty)

  switch (uniqValues.length) {
    case 0:
      return <></>
    case 1:
      return <>{uniqValues.join('')}</>
    default:
      return <>Multiple ({uniqValues.length})</>
  }
}
