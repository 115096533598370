import { MessageBarType } from '@fluentui/react'
import axios, { Method } from 'axios'
import { difference, groupBy, keyBy, uniq } from 'lodash'
import { flow } from 'lodash/fp'
import { combineReducers, Reducer } from 'redux'
import { createSelector } from 'reselect'
import { saga_getCurrentSystemuser } from 'store/user/dynamicsUser'
import { call, put, takeEvery } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { IWithDate } from '../../../../../api/common.types'
import {
  executeCdsBatchRequest,
  ICdsBatchRequestItem,
  IDynamicsBdaDepartmentAllowance
} from '../../../../../api/dynamics'
import { IDataListColumnDefinition } from '../../../../../features/DataList/contracts/IDataListColumnDefinition'
import { IDataListState } from '../../../../../features/DataList/contracts/IDataListState'
import { convertColumnTypeToFilterType } from '../../../../../features/DataList/service'
import { createDataListStore } from '../../../../../features/DataList/store'
import { IListsFilter } from '../../../../../features/Lists/core/contracts/IListsFilter'
import { pushNotification } from '../../../../../features/Notifications'
import { isNotNullOrEmpty } from '../../../../../shared/guards'
import { AppState } from '../../../../../store'
import { getDynamicsApiOptions } from '../../../../../store/shared/sagas'
import {
  BdaSummaryPayrollExpense,
  BdaSummaryTransaction
} from '../common/types'
import { bdaContainerActions } from './bdaContainer'
import { getBdaDepartmentAllowancesItems } from './bdaDepartmentAllowances'
import { getBdaPayrollExpensesItems } from './bdaPayrollExpenses'
import { getBdaTransactionsItems } from './bdaTransactions'
import { getIsBdaLoading } from './selectors'

export type BDAAdminPublishListColumnName =
  | 'Department'
  | 'Owner'
  | 'Unpublished Transactions'
  | 'Unpublished Payroll Expenses'
  | 'Total Items'
  | 'Allowance Type'
  | 'Allowance Disclaimer'

interface IBDAAdminPublishListColumnDefinition
  extends IDataListColumnDefinition<BDAAdminPublishListItem> {
  name: BDAAdminPublishListColumnName
}

const defaultColumn: Partial<IBDAAdminPublishListColumnDefinition> = {
  filterable: true,
  sortable: true
}

const bdaAdminPublishListColumns: IBDAAdminPublishListColumnDefinition[] = [
  {
    ...defaultColumn,
    name: 'Department',
    type: 'string',
    getValue: ({ department }) =>
      `${department?.rcm_Department?.cdm_name} ${department?.rcm_Department?.cdm_departmentnumber}`,
    width: 200
  },
  {
    ...defaultColumn,
    name: 'Owner',
    type: 'string',
    width: 200,
    getValue: ({ department }) =>
      department.owninguser?.fullname || department.owningteam?.name
  },
  {
    ...defaultColumn,
    name: 'Unpublished Transactions',
    type: 'number',
    width: 100,
    getValue: ({ transactions }) => transactions.unpublished.length
  },
  {
    ...defaultColumn,
    name: 'Unpublished Payroll Expenses',
    type: 'number',
    width: 100,
    getValue: ({ payroll }) => payroll.unpublished.length
  },
  {
    ...defaultColumn,
    name: 'Total Items',
    type: 'number',
    width: 100,
    getValue: ({ payroll, transactions }) =>
      payroll.items.length + transactions.items.length
  },
  {
    ...defaultColumn,
    name: 'Allowance Type',
    type: 'string',
    width: 200,
    facetable: true,
    getValue: ({ department }) =>
      department.rcm_allowancetype === 798960000
        ? 'Standard'
        : 'Support Staff Only'
  },
  {
    ...defaultColumn,
    name: 'Allowance Disclaimer',
    type: 'string',
    width: 200,
    facetable: true,
    getValue: ({ department }) => department.rcm_allowancedisclaimer || ''
  }
]

export const getMissingDepartments = createSelector(
  [
    getIsBdaLoading,
    getBdaDepartmentAllowancesItems,
    getBdaPayrollExpensesItems,
    getBdaTransactionsItems
  ],
  (loading, departments, payroll, transactions) => {
    if (loading || !departments) {
      return []
    }

    const payrollDepartmentGroups = groupBy(
      payroll,
      ({ rcm_Department }) => rcm_Department?.cdm_departmentnumber
    )

    const transactionDepartmentGroups = groupBy(
      transactions,
      ({ rcm_Department }) => rcm_Department?.cdm_departmentnumber
    )

    const departmentAllowanceDepartments = departments
      .map(({ rcm_Department }) => rcm_Department?.cdm_departmentnumber)
      .filter(isNotNullOrEmpty)

    const entityDepartments = uniq([
      ...Object.entries(payrollDepartmentGroups).map(([key]) => key),
      ...Object.entries(transactionDepartmentGroups).map(([key]) => key)
    ])

    const missingDepartments = difference(
      entityDepartments,
      departmentAllowanceDepartments
    )

    return missingDepartments
  }
)

export interface BDAAdminPublishListItem {
  department: IDynamicsBdaDepartmentAllowance
  payroll: {
    items: BdaSummaryPayrollExpense[]
    published: BdaSummaryPayrollExpense[]
    unpublished: BdaSummaryPayrollExpense[]
  }
  transactions: {
    items: BdaSummaryTransaction[]
    published: BdaSummaryTransaction[]
    unpublished: BdaSummaryTransaction[]
  }
}

const PUBLISH_ALL =
  '@modules/@advisory/@modules/@bda/@bdaAdminPublishList/PUBLISH_ALL'
const PUBLISH_ALL_SUCCESS =
  '@modules/@advisory/@modules/@bda/@bdaAdminPublishList/PUBLISH_ALL_SUCCESS'
const PUBLISH_ALL_ERROR =
  '@modules/@advisory/@modules/@bda/@bdaAdminPublishList/PUBLISH_ALL_ERROR'
const UNPUBLISH_ALL =
  '@modules/@advisory/@modules/@bda/@bdaAdminPublishList/UNPUBLISH_ALL'
const UNPUBLISH_ALL_SUCCESS =
  '@modules/@advisory/@modules/@bda/@bdaAdminPublishList/UNPUBLISH_ALL_SUCCESS'
const UNPUBLISH_ALL_ERROR =
  '@modules/@advisory/@modules/@bda/@bdaAdminPublishList/UNPUBLISH_ALL_ERROR'
const UPDATE_PUBLISH_MONTH =
  '@modules/@advisory/@modules/@bda/@bdaAdminPublishList/UPDATE_PUBLISH_MONTH'

export const bdaAdminPublishListPublishActions = {
  publish: createAction(PUBLISH_ALL)<BDAAdminPublishListItem[]>(),
  publishSuccess: createAction(PUBLISH_ALL_SUCCESS)(),
  publishError: createAction(PUBLISH_ALL_ERROR)<Error>(),
  unpublish: createAction(UNPUBLISH_ALL)<BDAAdminPublishListItem[]>(),
  unpublishSuccess: createAction(UNPUBLISH_ALL_SUCCESS)(),
  unpublishError: createAction(UNPUBLISH_ALL_ERROR)<Error>(),
  updatePublishMonth: createAction(UPDATE_PUBLISH_MONTH)<BDAPublishMonth>()
}

export type BdaAdminPublishListActionTypes = ActionType<
  typeof bdaAdminPublishListPublishActions
>

export type BDAPublishMonth =
  | 'all'
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'

export interface IBdaAdminPublishListState {
  loading?: boolean
  month: BDAPublishMonth
}

const initialState: IBdaAdminPublishListState = {
  month: 'all'
}

export const bdaAdminPublishListPublishReducer = createReducer<
  IBdaAdminPublishListState,
  BdaAdminPublishListActionTypes
>(initialState)
  .handleAction(bdaAdminPublishListPublishActions.publish, (state) => ({
    ...state,
    loading: true
  }))
  .handleAction(bdaAdminPublishListPublishActions.publishSuccess, (state) => ({
    ...state,
    loading: false
  }))
  .handleAction(bdaAdminPublishListPublishActions.publishError, (state) => ({
    ...state,
    loading: false
  }))
  .handleAction(bdaAdminPublishListPublishActions.unpublish, (state) => ({
    ...state,
    loading: true
  }))
  .handleAction(
    bdaAdminPublishListPublishActions.unpublishSuccess,
    (state) => ({
      ...state,
      loading: false
    })
  )
  .handleAction(bdaAdminPublishListPublishActions.unpublishError, (state) => ({
    ...state,
    loading: false
  }))
  .handleAction(
    bdaAdminPublishListPublishActions.updatePublishMonth,
    (state, action) => ({
      ...state,
      month: action.payload
    })
  )

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.bda.bdaAdminPublishList

export const getBdaAdminPublishListPublishState = flow(
  rootSelector,
  ({ publish }) => publish
)
export const getIsBdaAdminPublishListPublishLoading = flow(
  getBdaAdminPublishListPublishState,
  ({ loading }) => loading
)

export const getBdaAdminPublishListPublishMonth = flow(
  getBdaAdminPublishListPublishState,
  ({ month }) => month
)

export const getPublishListItems = createSelector(
  [
    getBdaDepartmentAllowancesItems,
    getBdaPayrollExpensesItems,
    getBdaTransactionsItems,
    getBdaAdminPublishListPublishMonth
  ],
  (departments, payroll, transactions, month): BDAAdminPublishListItem[] => {
    if (!departments) {
      return []
    }

    const payrollDepartmentGroups = groupBy(
      payroll,
      ({ rcm_Department }) => rcm_Department?.cdm_departmentnumber
    )

    const transactionDepartmentGroups = groupBy(
      transactions,
      ({ rcm_Department }) => rcm_Department?.cdm_departmentnumber
    )

    return departments.map((department) => {
      const { rcm_Department, ownerid } = department
      const departmentnumber = rcm_Department?.cdm_departmentnumber || ''
      const filterForMonth = ({ date }: IWithDate) =>
        month === 'all' || date?.getMonth() === parseInt(month)
      const departmentPayroll = (
        payrollDepartmentGroups[departmentnumber] || []
      ).filter(filterForMonth)
      const departmentTransactions = (
        transactionDepartmentGroups[departmentnumber] || []
      ).filter(filterForMonth)

      return {
        department,
        payroll: {
          items: departmentPayroll,
          published: departmentPayroll?.filter(
            (x) => x._ownerid_value === ownerid?.ownerid
          ),
          unpublished: departmentPayroll?.filter(
            (x) => x._ownerid_value !== ownerid?.ownerid
          )
        },
        transactions: {
          items: departmentTransactions,
          published: departmentTransactions?.filter(
            (x) => x._ownerid_value === ownerid?.ownerid
          ),
          unpublished: departmentTransactions?.filter(
            (x) => x._ownerid_value !== ownerid?.ownerid
          )
        }
      }
    })
  }
)

const filters = keyBy(
  bdaAdminPublishListColumns
    .filter((x) => x.filterable)
    .map(
      (column): IListsFilter => ({
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        hasValue: false
      })
    ),
  ({ id }) => id
)

const {
  actions: bdaAdminPublishListActions,
  reducer: dataListReducer,
  sagas,
  selectors: bdaAdminPublishListSelectors
} = createDataListStore({
  initialState: { columns: bdaAdminPublishListColumns, filters },
  itemsSelector: getPublishListItems,
  prefix: '@modules/@advisory/@modules/@bda/@bdaAdminPublishList',
  rootSelector: flow(rootSelector, ({ dataList }) => dataList)
})

export { bdaAdminPublishListActions, bdaAdminPublishListSelectors }

export const bdaAdminPublishListReducer: Reducer<{
  publish: ReturnType<typeof bdaAdminPublishListPublishReducer>
  dataList: IDataListState<BDAAdminPublishListItem>
}> = combineReducers({
  publish: bdaAdminPublishListPublishReducer,
  dataList: dataListReducer
})

export const bdaAdminPublishListSagas = [
  ...sagas,
  () =>
    takeEvery(
      bdaAdminPublishListPublishActions.publish,
      function* (
        action: ReturnType<typeof bdaAdminPublishListPublishActions.publish>
      ) {
        // eslint-disable-next-line import/no-named-as-default-member
        const source = axios.CancelToken.source()

        try {
          const apiOptions = yield* call(getDynamicsApiOptions, source.token)
          const requests = action.payload.flatMap(
            ({ department, payroll, transactions }) => {
              const ownerid = department.ownerid?.ownerid
              const ownerPath = department.owningteam ? 'teams' : 'systemusers'
              const departmentRequests: ICdsBatchRequestItem[] = [
                ...(payroll?.unpublished || []).map((item) => ({
                  method: 'PATCH' as Method,
                  url: `rcm_bdapayrollexpenseses(${item.rcm_bdapayrollexpensesid})`,
                  payload: { 'ownerid@odata.bind': `/${ownerPath}(${ownerid})` }
                })),
                ...(transactions?.unpublished || []).map((item) => ({
                  method: 'PATCH' as Method,
                  url: `rcm_businessdevelopmentaccounts(${item.rcm_businessdevelopmentaccountid})`,
                  payload: { 'ownerid@odata.bind': `/${ownerPath}(${ownerid})` }
                }))
              ]
              return departmentRequests
            }
          )

          if (requests.length) {
            yield call(executeCdsBatchRequest, apiOptions, requests)
          }

          yield put(bdaAdminPublishListPublishActions.publishSuccess())
        } catch (e: any) {
          yield put(bdaAdminPublishListPublishActions.publishError(e))
        }
      }
    ),
  () =>
    takeEvery(
      bdaAdminPublishListPublishActions.unpublish,
      function* (
        action: ReturnType<typeof bdaAdminPublishListPublishActions.unpublish>
      ) {
        // eslint-disable-next-line import/no-named-as-default-member
        const source = axios.CancelToken.source()
        const currentUser = yield* call(saga_getCurrentSystemuser)

        try {
          if (!currentUser?.systemuserid) {
            throw new Error('failed to load current user details')
          }
          const apiOptions = yield* call(getDynamicsApiOptions, source.token)
          const requests = action.payload.flatMap(
            ({ payroll, transactions }) => {
              const departmentRequests: ICdsBatchRequestItem[] = [
                ...(payroll?.published || []).map((item) => ({
                  method: 'PATCH' as Method,
                  url: `rcm_bdapayrollexpenseses(${item.rcm_bdapayrollexpensesid})`,
                  payload: {
                    'ownerid@odata.bind': `/systemusers(${currentUser?.systemuserid})`
                  }
                })),
                ...(transactions?.published || []).map((item) => ({
                  method: 'PATCH' as Method,
                  url: `rcm_businessdevelopmentaccounts(${item.rcm_businessdevelopmentaccountid})`,
                  payload: {
                    'ownerid@odata.bind': `/systemusers(${currentUser?.systemuserid})`
                  }
                }))
              ]
              return departmentRequests
            }
          )

          if (requests.length) {
            yield call(executeCdsBatchRequest, apiOptions, requests)
          }

          yield put(bdaAdminPublishListPublishActions.unpublishSuccess())
        } catch (e: any) {
          yield put(bdaAdminPublishListPublishActions.unpublishError(e))
        }
      }
    ),
  () =>
    takeEvery(
      [
        bdaAdminPublishListPublishActions.publishSuccess,
        bdaAdminPublishListPublishActions.publishError,
        bdaAdminPublishListPublishActions.unpublishSuccess,
        bdaAdminPublishListPublishActions.unpublishError
      ],
      function* () {
        yield put(bdaContainerActions.refresh())
      }
    ),
  () =>
    takeEvery(
      bdaAdminPublishListPublishActions.publishError,
      function* (
        action: ReturnType<
          typeof bdaAdminPublishListPublishActions.publishError
        >
      ) {
        yield call(pushNotification, {
          message: `Failed to publish: ${
            action.payload?.message || 'Unknown error during publish'
          }`, // `
          type: MessageBarType.error
        })
      }
    ),
  () =>
    takeEvery(
      bdaAdminPublishListPublishActions.unpublishError,
      function* (
        action: ReturnType<
          typeof bdaAdminPublishListPublishActions.unpublishError
        >
      ) {
        yield call(pushNotification, {
          message: `Failed to unpublish: ${
            action.payload?.message || 'Unknown error during unpublish'
          }`, // `
          type: MessageBarType.error
        })
      }
    )
]
