import { endOfDay, startOfDay } from 'date-fns'
import { includes, lowerCase, trim } from 'lodash'
import { flow } from 'lodash/fp'
import {
  createAction,
  EmptyActionCreator,
  PayloadActionCreator
} from 'typesafe-actions'
import { convertDateRangeToDates, DateRanges } from '../../shared'
import { isNotNullOrEmpty } from '../../shared/guards'
import {
  IListsDateRangeFilter,
  IListsFacetFilter,
  IListsFilter,
  IListsNumberRangeFilter,
  IListsSearchFilter
} from '../Lists/core/contracts/IListsFilter'
import { ListsFilterType } from '../Lists/core/contracts/IListsFilterDefinition'
import { IDataTableColumnDefinition } from './common/types'
import { IDataListColumnDefinition } from './contracts/IDataListColumnDefinition'

const lowerCaseTrim = flow(lowerCase, trim)

export const convertColumnTypeToFilterType = ({
  type,
  facetable
}: IDataTableColumnDefinition): ListsFilterType => {
  switch (type) {
    case 'date':
    case 'date-only':
      return 'date'
    case 'number':
      return 'number'
    case 'string':
      return facetable ? 'facet' : 'search'
    case 'boolean':
      return 'boolean'
  }
}

export const evaluateFacets = <T>(
  items: T[],
  columns: IDataListColumnDefinition<T>[]
) => {
  const facetValueFns = columns
    .filter((column) => column.facetable)
    .reduce(
      (a, x) => ({ ...a, [x.name]: x.getValue }),
      {} as Record<string, IDataListColumnDefinition<T>['getValue']>
    )

  if (!Object.entries(facetValueFns)) {
    return {}
  }

  return items.reduce((facets, item) => {
    const valueMap = Object.entries(facetValueFns).reduce(
      (map, [key, valueFn]) => ({ ...map, [key]: valueFn(item) }),
      {} as Record<string, unknown>
    )

    Object.entries(valueMap).forEach(([key, value]) => {
      facets[key] = facets[key] || {}
      facets[key][value as string] = (facets[key][value as string] || 0) + 1
    })

    return facets
  }, {} as Record<string, Record<string, number>>)
}

export const evaluateFilter = <T, U extends string>(
  item: T,
  valueFns: Record<U, IDataListColumnDefinition<T>['getValue']>,
  filters: Record<U, IListsFilter>
) => {
  return Object.entries<IListsFilter>(filters)
    .filter(([, filter]) => filter.hasValue)
    .every(([id, filter]) => {
      const itemValue = valueFns?.[id as U]?.(item)
      const { type, blankIndicator } = filter
      if (blankIndicator) {
        return (
          (blankIndicator === 'exclude') ===
          isNotNullOrEmpty((itemValue as any)?.toString() || '')
        )
      }
      switch (type) {
        case 'date': {
          const { from, to, range } = filter as IListsDateRangeFilter
          if (!range) {
            throw new Error('Range is undefined')
          }
          const [a, b] =
            range === DateRanges.Custom
              ? [from && startOfDay(from), to && endOfDay(to)]
              : convertDateRangeToDates(range)
          const itemValueAsDate = itemValue as Date
          return (
            !!itemValueAsDate &&
            (!a || itemValueAsDate >= a) &&
            (!b || itemValueAsDate <= b)
          )
        }
        case 'facet': {
          const { values } = filter as IListsFacetFilter
          const itemValueAsString = lowerCaseTrim(itemValue as string)
          const lowerCaseValues = values?.map(lowerCaseTrim)
          return (
            !!lowerCaseValues && includes(lowerCaseValues, itemValueAsString)
          )
        }
        case 'number': {
          const {
            min,
            max,
            filterType = 'range',
            value
          } = filter as IListsNumberRangeFilter
          const itemValueAsNumber = itemValue as number
          switch (filterType) {
            case 'eq': {
              return value === itemValueAsNumber
            }
            case 'ne': {
              return value !== itemValueAsNumber
            }
            default:
            case 'range': {
              return (
                (min == null || itemValueAsNumber >= min) &&
                (max == null || itemValueAsNumber <= max)
              )
            }
          }
        }
        case 'search': {
          const { value } = filter as IListsSearchFilter
          return includes(
            lowerCaseTrim(itemValue as string),
            lowerCaseTrim(value)
          )
        }
      }
    })
}

export const createActionWithPrefix =
  <TType extends string>(prefix: string, type: TType) =>
  <TPayload>(): PayloadActionCreator<TType, TPayload> =>
    createAction<TType, TPayload, string>(
      `${prefix}/${type}` as TType,
      (payload: TPayload) => payload,
      () => prefix
    )<TPayload, string>()

export const createEmptyActionWithPrefix =
  <TType extends string>(prefix: string, type: TType) =>
  (): EmptyActionCreator<TType> =>
    createAction<TType, string>(
      `${prefix}/${type}` as TType,
      () => prefix
    )<string>()
