import axios, { AxiosResponse } from 'axios'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import {
  anticipatedBalancePiDetailsRequest,
  anticipatedBalanceRequest,
  IAnticipatedBalance,
  PIDetails
} from '../store/types'

export const fetchAnticipatedBalance = (
  anticipatedBalanceReqObj: anticipatedBalanceRequest,
  options: IApiOptions
): Promise<AxiosResponse<IAnticipatedBalance | undefined>> => {
  const url = `${options.apiRoot}/trustapi/api/Balance/GetAnticipatedCashBalance`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post(url, anticipatedBalanceReqObj, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
export const fetchAnticipatedPIBalance = (
  anticipatedBalancePIReqObj: anticipatedBalancePiDetailsRequest,
  options: IApiOptions
): Promise<AxiosResponse<PIDetails | undefined>> => {
  const url = `${options.apiRoot}/balance/odata/balancedetails?AccountList=${anticipatedBalancePIReqObj.account}&Retrieve=PI`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
