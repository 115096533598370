import { IOverrides } from 'api/overrides.types'
import { updateOverrides } from 'api/security'
import axios from 'axios'
import { AppState } from 'store'
import {
  createAsyncReducer,
  createAsyncSelectors,
  getRockefellerApiOptions
} from 'store/shared'
import { call, cancelled, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { OverridesFetchActions } from './OverridesFetch'

export const OverridesUpdateActions = createAsyncAction(
  '@modules/@security/@overrides/UPDATE_REQUEST',
  '@modules/@security/@overrides/UPDATE_SUCCESS',
  '@modules/@security/@overrides/UPDATE_FAILURE'
)<IOverrides, number | undefined, Error>()

export const overridesUpdateReducer = createAsyncReducer(OverridesUpdateActions)

const rootSelector = (state: AppState) =>
  state.modules.overrides.overridesUpdate

export const {
  getError: UpdateOverridesError,
  getIsLoading: UpdateOverridesLoading,
  getResult: UpdateOverridesResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof OverridesUpdateActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)
  try {
    const result = yield* call(updateOverrides, apiOptions, action.payload)
    yield put(OverridesUpdateActions.success(result.errorCode))
    yield put(OverridesFetchActions.request(''))
  } catch (e: any) {
    yield put(OverridesUpdateActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const overridesUpdateSagas = [
  () => takeLatest(OverridesUpdateActions.request, onRequest)
]
