import { Pivot, PivotItem } from '@fluentui/react'
import { useCallback, useState } from 'react'
import { NotFound } from 'shared/components/NotFound'
import DetailsNavigator from '../../components/shared/DetailsNavigator/DetailsNavigator'
import { CollaborationContainer } from '../Collaboration/CollaborationContainer'
import { SecureMessageContainer } from '../SecureMessages/SecureMessageContainer'

const collaboration = 'collaboration'
const secureMessage = 'securemessage'

const tabs = [
  { name: 'Collaboration', key: collaboration },
  { name: 'Secure Message', key: secureMessage }
]

export const CommunicationsContainer: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>(collaboration)

  const onSelectedTabChanged = useCallback((item?: PivotItem) => {
    const newSelectedTab = item?.props?.itemKey
    if (!newSelectedTab) {
      return
    }
    setSelectedTab(newSelectedTab)
  }, [])

  const SelectedTabComponent = useCallback(() => {
    if (selectedTab === collaboration) {
      return <CollaborationContainer hideDetailsNavigator={true} />
    }

    if (selectedTab === secureMessage) {
      return <SecureMessageContainer />
    }

    return <NotFound />
  }, [selectedTab])

  return (
    <div>
      <div
        css={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          gap: 20
        }}
      >
        <DetailsNavigator />
        <Pivot selectedKey={selectedTab} onLinkClick={onSelectedTabChanged}>
          {tabs.map(({ name, key }) => (
            <PivotItem key={key} headerText={name} itemKey={key} />
          ))}
        </Pivot>
      </div>
      <SelectedTabComponent />
    </div>
  )
}
