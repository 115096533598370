export interface IBalanceDetailResponseValue {
  accountbalances: IBalanceDetailResponseValueItem[]
}

export interface IBalanceDetailResponseValueItem {
  // "customername": null,
  // "collateralaccounts": null,
  // "loanpurpose": null,
  // "loanaccount": null,
  // "totalcredit": null,
  // "availablecredit": null,
  // "grosscollateralvalue": null,
  // "interestrate": null,
  // "rateindex": null,
  // "spread": null,
  // "billedamount": null,
  // "dayofmonthpaymentdue": null,
  // "nextpaymentdate": null,
  // "nextpayment": null,
  // "paymentmethod": null,
  // "interestpaidamountytd": null,
  // "nextratechangedate": null,
  // "loanopendate": null,
  // "relationshipmanager": null,
  // "servicingphone": null,
  accountaumber?: string
  isnote?: boolean
  availablewithdraw?: number
  marginintrate?: number
  marginaccruedinterest?: number
  cashmarginavailabletowithdraw?: number
  cashbuyingpower?: number
  openordercashbuyingpower?: number
  cashmarginbuyingpower?: number
  nonmarginbuyingpower?: number
  key?: string
  asofdate?: string
  totalaccountvalue?: number
  pricedinvestments?: number
  cashmoneyaccounts?: number
  outstandingbalance?: number
  annuity?: number
  moneyaccountvalue?: number
  cashbalance?: number
  marginbalance?: number
  pendingactivity?: number
  fixedincomeaccruedinterest?: number
  availablemarginbalance?: number
  othercreditdebit?: number
  othercreditdebitchange?: number
  marginandoutstandingbalance?: number
  margindebitcoveredbycash?: number
  margindebitoutstanding?: number
  totalaccountvaluechange?: number
  pricedinvestmentschange?: number
  cashmoneyaccountschange?: number
  moneyaccountvaluechange?: number
  cashbalancechange?: number
  marginbalancechange?: number
  fixedincomeaccruedinterestchange?: number
  availablemarginbalancechange?: number
  outstandingbalancechange?: number
  annuitychange?: number
  totalaccountvaluepercentchange?: number
  pricedinvestmentspercentchange?: number
  todayschange?: IBalanceTodaysChange
  cashmoneyaccountspercentchange?: number
  moneyaccountvaluepercentchange?: number
  cashbalancepercentchange?: number
  fixedincomeaccruedinterestpercentchange?: number
  availablemarginbalancepercentchange?: number
  outstandingbalancepercentchange?: number
  annuitypercentchange?: number
  piasofdate?: string
  cashprincipal?: number
  cashincome?: number
  notes?: unknown[]
  status?: IBalanceDetailResponseValueItemStatus
  totalassetaccountvalue?: number
  totalassetaccountvaluechange?: number
  totalotherassetsvaluechange?: number
  totalexternalaccountvaluechange?: number
  dividendinterest_type9?: number
  totalNonNfsValueChange?: number
  holdingAccruDividends?: number | null
  holdingAccruIncome?: number | null
  holdingAccruInterest?: number | null
}

export interface IBalanceTodaysChange {
  RCM?: IBalanceTodaysChangeItem[]
  External?: unknown[]
  Other?: unknown[]
}

export interface IBalanceTodaysChangeItem {
  category: string
  amount: number
  sortOrder: number
  Activity: IBalanceTodaysChangeItemActivity[]
}

export interface IBalanceTodaysChangeItemActivity {
  description: TodaysChangeKeys
  todaysChangeAmount: number
}

export const todaysChangeFields = {
  gainOrLoss: 'Gain/Loss',
  accruedIncome: 'Accrued Income (Div/Int)',
  deposits: 'Deposits',
  interestOrDividends: 'Interest/Dividends',
  withdrawals: 'Withdrawals',
  other: 'Other'
} as const

export const todaysChangeFieldsDisplay = {
  [todaysChangeFields.gainOrLoss]: todaysChangeFields.gainOrLoss,
  [todaysChangeFields.accruedIncome]: todaysChangeFields.accruedIncome,
  [todaysChangeFields.deposits]: todaysChangeFields.deposits,
  [todaysChangeFields.interestOrDividends]: 'Dividends / Interest Paid',
  [todaysChangeFields.withdrawals]: todaysChangeFields.withdrawals,
  [todaysChangeFields.other]: todaysChangeFields.other
} as const

export type TodaysChangeKeys =
  (typeof todaysChangeFields)[keyof typeof todaysChangeFields]

export interface IBalanceDetailResponseValueItemStatus {
  Severity: unknown
  Code: string
  Text: unknown
  Errors: unknown[]
}
