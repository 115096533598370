import { skipToken } from '@reduxjs/toolkit/dist/query'
import { constructAndMergeWithDashboardAnalyticsCategories } from 'modules/Teams/store/selectors'
import { useCallback, useMemo } from 'react'
import { useSessionStateContainer } from 'store/session'
import { useGetPeerGroupAnalyticsByTeamNameQuery } from './peerReviewAnalyticsApi'

interface IPeerReviewSessionState {
  selectedTeam?: string
}

const usePeerReviewSessionState = () => {
  const { updateContainer, value } =
    useSessionStateContainer<IPeerReviewSessionState>('peerReview')
  const { selectedTeam } = value
  const setSelectedTeam = useCallback(
    (teamName?: string) => updateContainer({ selectedTeam: teamName }),
    [updateContainer]
  )

  return { selectedTeam, setSelectedTeam }
}

export const usePeerReviewAnalytics = () => {
  const { selectedTeam, setSelectedTeam } = usePeerReviewSessionState()
  const queryState = useGetPeerGroupAnalyticsByTeamNameQuery(
    selectedTeam ? { teamName: selectedTeam } : skipToken
  )

  const { currentData: data, isFetching, error } = queryState

  const team = useMemo(
    () =>
      data?.teamWithRank &&
      constructAndMergeWithDashboardAnalyticsCategories(data?.teamWithRank),
    [data?.teamWithRank]
  )

  const pwm = useMemo(
    () =>
      data?.pwm &&
      constructAndMergeWithDashboardAnalyticsCategories({
        team: data?.pwm
      }),
    [data?.pwm]
  )

  const division = useMemo(
    () =>
      data?.division &&
      constructAndMergeWithDashboardAnalyticsCategories({
        team: data?.division
      }),
    [data?.division]
  )

  const peers = useMemo(
    () =>
      data?.peers &&
      constructAndMergeWithDashboardAnalyticsCategories({
        team: data?.peers
      }),
    [data?.peers]
  )

  return {
    selectedTeam,
    setSelectedTeam,
    queryState,
    isFetching,
    error,
    enhancedData: team && {
      teamName: data?.teamWithRank?.team?.teamName,
      asOfDate: data?.meta?.asOfDate,
      meta: data?.meta,
      peerGroupDefinition: data?.peerGroupDefinition,
      team,
      pwm,
      division,
      peers
    }
  }
}
