import { IDropdownOption, MessageBarType } from '@fluentui/react'
import { createReducer } from 'typesafe-actions'

import { IAdvisoryBusiness, ITPMFirmPDF } from '../api/types/templateTypes'
import { IExternalManagerResp } from '../api/types/types'
import {
  ThirdPartyManagerFirmsActionTypes,
  downloadTPMPDFActions,
  exportTPMToExcelActions,
  fetchAdvisoryBusinessActions,
  fetchTPMFirmPDFActions,
  generateTPMPDFActions,
  setSelectedMonthYearActions
} from './actions'

export interface IExternalManagerModuleState {
  selectedMonthYear?: string
}
export interface IThirdPartyManagerState {
  selectedPeriod?: string
  selectedMonthYear?: string
  selectedFirmCode?: string
  listOfFirmCodes?: IDropdownOption[]
  selectedFirmRepCodes?: string[]
  selectedPdfFirmCode?: string
  repCodes?: string
  fetchAdvisoryBusinessState?: {
    inProgress: boolean
    error?: Error
    data?: IAdvisoryBusiness
  }
  exportTPMDashboard?: {
    inProgress: boolean
    error?: Error
  }
  TPMDownload?: {
    inProgress: boolean
    error?: Error
    messageType?: MessageBarType
  }
  generatePDFState?: {
    inProgress: boolean
    error?: Error
    messageType?: MessageBarType
    data?: IExternalManagerResp
    msg?: string
  }
  fetchTPMFirmPDFState?: {
    inProgress: boolean
    error?: Error
    messageType?: MessageBarType
    data?: ITPMFirmPDF[]
  }
}

const initialState: IThirdPartyManagerState = {}

export const thirdPartyManagerReducer = createReducer<
  IThirdPartyManagerState,
  ThirdPartyManagerFirmsActionTypes
>(initialState)
  .handleAction(
    setSelectedMonthYearActions?.selectedMonthYear,
    (state, action) => ({
      ...state,
      selectedMonthYear: action.payload || undefined,
      closeMonthState: undefined
    })
  )
  .handleAction(
    setSelectedMonthYearActions?.selectedFirmCode,
    (state, action) => ({
      ...state,
      selectedFirmCode: action.payload || undefined
    })
  )
  .handleAction(
    setSelectedMonthYearActions?.listOfFirmCodes,
    (state, action) => ({
      ...state,
      listOfFirmCodes: action.payload || undefined
    })
  )
  .handleAction(
    setSelectedMonthYearActions?.selectedFirmRepCodes,
    (state, action) => ({
      ...state,
      selectedFirmRepCodes: action.payload || undefined
    })
  )
  .handleAction(
    setSelectedMonthYearActions?.selectedPdfFirmCode,
    (state, action) => ({
      ...state,
      selectedPdfFirmCode: action.payload || undefined
    })
  )
  .handleAction(fetchAdvisoryBusinessActions.request, (state) => ({
    ...state,
    fetchAdvisoryBusinessState: {
      ...state.fetchAdvisoryBusinessState,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(fetchAdvisoryBusinessActions.success, (state, action) => ({
    ...state,
    fetchAdvisoryBusinessState: {
      ...state.fetchAdvisoryBusinessState,
      inProgress: false,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchAdvisoryBusinessActions.failure, (state, action) => ({
    ...state,
    fetchAdvisoryBusinessState: {
      ...state.fetchAdvisoryBusinessState,
      inProgress: false,
      error: action.payload,
      data: undefined
    }
  }))
  .handleAction(exportTPMToExcelActions.request, (state) => ({
    ...state,
    exportTPMDashboard: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(exportTPMToExcelActions.success, (state) => ({
    ...state,
    exportTPMDashboard: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(exportTPMToExcelActions.failure, (state, action) => ({
    ...state,
    exportTPMDashboard: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(generateTPMPDFActions.request, (state) => ({
    ...state,
    generatePDFState: {
      ...state.generatePDFState,
      inProgress: true,
      error: undefined,
      messageType: MessageBarType.info,
      msg: 'Generating the PDF'
    }
  }))
  .handleAction(generateTPMPDFActions.success, (state, action) => ({
    ...state,
    generatePDFState: {
      inProgress: false,
      error: undefined,
      messageType: MessageBarType.success,
      msg: 'Successfully generated PDF',
      data: action.payload
    }
  }))
  .handleAction(generateTPMPDFActions.failure, (state, action) => ({
    ...state,
    generatePDFState: {
      ...state.generatePDFState,
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(downloadTPMPDFActions.request, (state) => ({
    ...state,
    TPMDownload: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(downloadTPMPDFActions.success, (state) => ({
    ...state,
    TPMDownload: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(downloadTPMPDFActions.failure, (state, action) => ({
    ...state,
    TPMDownload: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(fetchTPMFirmPDFActions.request, (state) => ({
    ...state,
    fetchTPMFirmPDFState: {
      ...state.fetchTPMFirmPDFState,
      inProgress: true,
      error: undefined,
      messageType: undefined
    }
  }))
  .handleAction(fetchTPMFirmPDFActions.success, (state, action) => ({
    ...state,
    fetchTPMFirmPDFState: {
      ...state.fetchTPMFirmPDFState,
      inProgress: false,
      error: undefined,
      messageType: MessageBarType.success,
      data: action.payload
    }
  }))
  .handleAction(fetchTPMFirmPDFActions.failure, (state, action) => ({
    ...state,
    fetchTPMFirmPDFState: {
      ...state.fetchTPMFirmPDFState,
      inProgress: false,
      error: action.payload,
      messageType: MessageBarType.error,
      data: undefined
    }
  }))
