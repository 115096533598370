import { lazy } from 'react'
import { combineReducers } from 'redux'
import { IModuleDefinition } from '../../../types'
import { aiModulesReducer, aiModulesSagas } from './modules'

const AiContainer = lazy(() => import('./AIContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/ai/*',
    private: true,
    component: AiContainer
  },
  name: 'AI Dashboard'
}

export default moduleDefinition

export const aiModuleReducer = combineReducers({
  modules: aiModulesReducer
})
export const aiModuleSagas = [...aiModulesSagas]
