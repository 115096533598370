import { FC } from 'react'
import { USD } from 'shared/components/Formatting'

export const IncomeTotalLabel: FC<{
  financialYearLabel: string
  incomeTotal: number
}> = ({ financialYearLabel, incomeTotal }) => {
  return (
    <div
      css={{
        display: 'flex',
        marginTop: '4px',
        columnGap: '4px'
      }}
    >
      {`${financialYearLabel} income:`}
      <div css={{ fontWeight: 'bold' }}>
        <USD value={incomeTotal} currencySign="standard" fractionDigits={0} />
      </div>
    </div>
  )
}
