import { useCallback } from 'react'
import {
  IPreferencesContainer,
  UnknownPreferences,
  useLazyGetPreferencesContainerQuery,
  useSetPreferencesInContainerMutation,
  useDeleteContainerMutation
} from './preferencesApi'

export const useLazyPreferencesContainer = <T = UnknownPreferences>() => {
  const [triggerGet] = useLazyGetPreferencesContainerQuery()
  const [triggerSet] = useSetPreferencesInContainerMutation()
  const [triggerDelete] = useDeleteContainerMutation()

  const getPreferences = useCallback(
    async (id: string) => {
      const result = triggerGet({ containerId: id }, true)
      result.unsubscribe()
      const { data } = await result
      return data?.value as T
    },
    [triggerGet]
  )

  const setPreferences = useCallback(
    async (id: string, value: Partial<T>) => {
      const result = triggerSet({ containerId: id, value })
      const updated = await result.unwrap()
      return updated as IPreferencesContainer<T>
    },
    [triggerSet]
  )

  const deletePreferences = useCallback(
    async (id: string) => {
      const result = triggerDelete({ containerId: id })
      const deleted = await result.unwrap()
      return deleted as IPreferencesContainer<T>
    },
    [triggerDelete]
  )

  return { getPreferences, setPreferences, deletePreferences }
}
