import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
  PrimaryButton,
  Stack
} from '@fluentui/react'
import { useCallback, useMemo, useState } from 'react'
import { LoadingComponent } from '../../../../shared/components/Loading'
import { useDomainStore } from '../../store/domain'
import { AccountRepTree } from './AccountRepTree'
import { AdvisorRepTree } from './AdvisorRepTree'
import { useAccountRepTreeStore } from './store/accountRepTree'
import { useAdvisorRepTreeStore } from './store/advisorRepTree'
import { useRepSelectorPanelStore } from './store/repSelectorPanel'
import { UsersAndTeams } from './UsersAndTeams'

export const RepSelectorPanel = () => {
  const { setSelectedDomain, isFetching } = useDomainStore()
  const { isOpen, close, isAdvisorRepTreeSelectionValid } =
    useRepSelectorPanelStore()

  const { filteredAccountReps, selectedLookup: accountRepSelectedLookup } =
    useAccountRepTreeStore()

  const { filteredAdvisorReps, selectedLookup: advisorRepSelectedLookup } =
    useAdvisorRepTreeStore()

  const filteredSelectedAccountReps = useMemo(
    () =>
      filteredAccountReps
        .map(({ id }) => id)
        .filter((id) => accountRepSelectedLookup[id]),
    [accountRepSelectedLookup, filteredAccountReps]
  )

  const onApply = useCallback(() => {
    const advisorReps = filteredAdvisorReps
      .map(({ id }) => id)
      .filter((id) => advisorRepSelectedLookup[id])

    setSelectedDomain({
      accountReps: filteredSelectedAccountReps,
      advisorReps
    })
    close()
  }, [
    advisorRepSelectedLookup,
    close,
    filteredAdvisorReps,
    filteredSelectedAccountReps,
    setSelectedDomain
  ])

  const { selectAllAccountReps, selectRepsForTeam, selectRepsForUser } =
    useDomainStore()
  const onTeamClicked = useCallback(
    (businessunitid?: string) => {
      selectRepsForTeam(businessunitid)
      close()
    },
    [close, selectRepsForTeam]
  )
  const onAdvisorClicked = useCallback(
    (domainname?: string) => {
      selectRepsForUser(domainname)
      close()
    },
    [close, selectRepsForUser]
  )
  const onSelectAll = useCallback(() => {
    selectAllAccountReps()
    close()
  }, [selectAllAccountReps, close])

  const UsersAndTeamsWithEvents = useCallback(
    () => (
      <UsersAndTeams
        onAdvisorClicked={onAdvisorClicked}
        onTeamClicked={onTeamClicked}
        onSelectAll={onSelectAll}
      />
    ),
    [onAdvisorClicked, onSelectAll, onTeamClicked]
  )

  const [selectedPivot, setSelectedPivot] = useState('home')
  const onPivotChange = useCallback((item?: PivotItem) => {
    setSelectedPivot(item?.props.itemKey || 'home')
  }, [])

  const TreeComponent = useMemo(() => {
    switch (selectedPivot) {
      default:
      case 'home':
        return UsersAndTeamsWithEvents
      case 'reps':
        return AccountRepTree
      case 'advisors':
        return AdvisorRepTree
    }
  }, [UsersAndTeamsWithEvents, selectedPivot])

  const onRenderHeader = useCallback(() => {
    return (
      <div
        style={{
          alignSelf: 'flex-start',
          flexGrow: 1,
          padding: '0 0 5px 24px'
        }}
      >
        <Stack tokens={{ childrenGap: 20 }}>
          <Stack horizontal={true}>
            <Pivot
              headersOnly={true}
              selectedKey={selectedPivot}
              onLinkClick={onPivotChange}
            >
              <PivotItem headerText="Advisors / Teams" itemKey="home" />
              <PivotItem headerText="Pool / Rep IDs" itemKey="reps" />
              <PivotItem headerText="Participants" itemKey="advisors" />
            </Pivot>
          </Stack>
        </Stack>
      </div>
    )
  }, [onPivotChange, selectedPivot])

  const onRenderFooterContent = useCallback(() => {
    const someAreSelected = !!filteredSelectedAccountReps.length

    return (
      <Stack tokens={{ childrenGap: 5 }}>
        {!isAdvisorRepTreeSelectionValid && (
          <MessageBar messageBarType={MessageBarType.warning}>
            Advisor rep selection is invalid and will be reset
          </MessageBar>
        )}
        {selectedPivot !== 'home' && (
          <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
            <PrimaryButton disabled={!someAreSelected} onClick={onApply}>
              Apply
            </PrimaryButton>
            <Stack.Item grow={1}>
              <DefaultButton onClick={close}>Cancel</DefaultButton>
            </Stack.Item>
          </Stack>
        )}
      </Stack>
    )
  }, [
    close,
    filteredSelectedAccountReps.length,
    isAdvisorRepTreeSelectionValid,
    onApply,
    selectedPivot
  ])

  return (
    <Panel
      isFooterAtBottom={true}
      onRenderHeader={onRenderHeader}
      onRenderFooterContent={onRenderFooterContent}
      isOpen={isOpen}
      onDismiss={close}
      isLightDismiss={true}
      closeButtonAriaLabel="Close"
      type={PanelType.custom}
      customWidth="450px"
      styles={{ content: { flexGrow: 1 }, commands: { paddingBottom: '15px' } }}
    >
      {isFetching && <LoadingComponent />}
      {<TreeComponent />}
    </Panel>
  )
}
