import { lazy } from 'react'
import { IModuleDefinition } from '../../../types'

const PerformanceContainer = lazy(() => import('./PerformanceContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/operations/performance/*',
    private: true,
    component: PerformanceContainer
  },
  name: 'Performance Report Admin'
}

export default moduleDefinition
