import axios from 'axios'
import { IApiOptions } from 'shared/contracts/IApiOptions'

const apiName = 'RelatedParties'
const apiPath = 'datahub'

export interface IRelatedParty {
  email?: string
  loginDate?: string
  loginId?: string
  partyId?: string
  partyName?: string
  partyType?: string
  portalId?: string
  role?: string
}

const getRelatedLogins = (url: string, options: IApiOptions) => {
  return axios
    .get<IRelatedParty[]>(`${url}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}

export const getRelatedLoginsByAccountId = (
  accountId: string,
  options: IApiOptions
): Promise<IRelatedParty[]> => {
  const url = `${options.apiRoot}/${apiPath}/${apiName}/Account/${accountId}`
  return getRelatedLogins(url, options)
}

export const getRelatedLoginsByPartyId = (
  partyId: string,
  options: IApiOptions
): Promise<IRelatedParty[]> => {
  const url = `${options.apiRoot}/${apiPath}/${apiName}/Party/${partyId}`
  return getRelatedLogins(url, options)
}
