import { css } from '@emotion/react'
import { Header, Row, flexRender } from '@tanstack/react-table'
import { sum } from 'lodash'
import { ITableHeaderColumnSize } from 'modules/Advisory/modules/Rdot360/shared/ITableHeaderColumnSize'
import { ICategoryPosition } from 'modules/Advisory/modules/Rdot360/store/holdingsApi/ICategoryPositionResponse'
import React, { useCallback, useMemo, useRef } from 'react'
import { useWidthObserver } from 'shared/hooks/useResizeObserver'
import {
  InvestmentsTableBody,
  InvestmentsTableGroups
} from '../InvestmentsTable/InvestmentsTable'
import { investmentsTableColumnNames as colNames } from '../InvestmentsTable/shared'
import { cellStyles } from '../InvestmentsTable/styles'

const getClasses = () => ({
  printTableContainer: css({
    breakAfter: 'page',
    marginTop: 20,
    ['& .investments-table > tbody > tr > td:first-of-type span']: {
      overflow: 'visible',
      textOverflow: 'unset',
      whiteSpace: 'normal'
    },
    ['& .investments-table > tbody > tr > td:first-of-type div']: {
      overflow: 'visible',
      textOverflow: 'unset',
      whiteSpace: 'normal'
    },
    ['& td svg']: {
      display: 'none'
    },
    ['& .investments-table *']: {
      textDecoration: 'none'
    }
  }),
  printTable: css({
    width: '100%',
    borderCollapse: 'collapse',
    tableLayout: 'fixed'
  }),
  colWidth: css({
    width: '100%'
  }),
  printTableHeader: css({
    marginTop: '120px',
    backgroundColor: '#EFF3F7',
    width: '100%',
    th: css({
      padding: '10px 8px'
    }),
    'th:first-of-type': css({
      paddingLeft: 25
    }),
    'th:last-of-type': css({
      paddingRight: 25
    })
  }),
  printTableTotals: css({
    fontSize: 13,
    fontWeight: 'bold',
    backgroundColor: '#EFF3F7',
    td: css({
      padding: '10px 8px'
    }),
    '& td:first-of-type': {
      paddingLeft: 57
    },
    '& td:last-of-type': {
      paddingRight: 28
    }
  }),
  headerPadding: css({
    padding: '20px 0'
  })
})

interface IPrintTableProps {
  currentTable: Record<string, boolean>
  headers: Header<ICategoryPosition, unknown>[]
  rows: Row<ICategoryPosition>[]
  includeAltInvestmentDetails: boolean
}

export const PrintTable: React.FC<IPrintTableProps> = ({
  currentTable,
  headers,
  rows
  // includeAltInvestmentDetails // DEBUG: This will get passed to the Investment components
}) => {
  const { secid } = colNames

  const tableRefContainer = useRef<HTMLDivElement>(null)
  const containerWidth = useWidthObserver(tableRefContainer)
  const tableWidth = Math.max(1450, containerWidth || 0)
  const classes = useMemo(() => getClasses(), [])

  const getAdjustedHeaderSizes = (
    sizes: ITableHeaderColumnSize[],
    tableWidth: number,
    depth = 0,
    firstColumnOffset = 9,
    lastColumnOffset = 9
  ) => {
    const totalSize = sum(sizes.map(({ width }) => width))
    const ratio = tableWidth ? tableWidth / totalSize : 1

    return sizes.map(({ width, id }, i) => {
      const isFirst = i === 0
      const len = sizes?.length ?? 0
      const isLast = i === len - 1
      const depthAdjustment =
        isFirst || isLast
          ? depth * (isFirst ? firstColumnOffset : lastColumnOffset)
          : 0

      return { id, width: width * ratio - depthAdjustment }
    })
  }

  const getHeaderSizes = useCallback(
    (depth?: number) => {
      const sizes = getAdjustedHeaderSizes(
        headers.map((x) => ({ id: x.id || '', width: x.getSize() || 0 })),
        // -2 for the border around the table
        tableWidth - 2,
        depth
      )
      return sizes
    },
    [headers, tableWidth]
  )

  const sizes = getHeaderSizes()
  const fullSpan = sizes?.length ?? 0

  return (
    <div css={classes.printTableContainer} ref={tableRefContainer}>
      <table css={classes.printTable}>
        <colgroup css={classes.colWidth}>
          <ColWidth sizes={sizes} />
        </colgroup>
        <thead>
          <tr>
            <td colSpan={fullSpan}>
              <div css={classes.headerPadding}>&nbsp;</div>
            </td>
          </tr>
          <tr css={classes.printTableHeader}>
            <TableHeader headers={headers} />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={fullSpan} css={{ paddingBottom: 0 }}>
              {currentTable[secid] ? (
                <InvestmentsTableBody
                  rows={rows}
                  getHeaderSizes={getHeaderSizes}
                  depth={0}
                />
              ) : (
                <InvestmentsTableGroups
                  rows={rows}
                  depth={0}
                  getHeaderSizes={getHeaderSizes}
                />
              )}
            </td>
          </tr>
          <tr css={classes.printTableTotals}>
            <TableTotals headers={headers} />
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const ColWidth: React.FC<{
  sizes: ITableHeaderColumnSize[]
}> = ({ sizes }) => {
  return (
    <>
      {sizes.map(({ width, id }) => {
        return (
          <col
            key={id}
            style={{
              width,
              maxWidth: width
            }}
          />
        )
      })}
    </>
  )
}

const TableHeader: React.FC<{
  headers: Header<ICategoryPosition, unknown>[]
}> = ({ headers }) => {
  return (
    <>
      {headers.map((header) => {
        return (
          <th key={header.id} css={[cellStyles[header.id]]}>
            {flexRender(header.column.columnDef.header, header.getContext())}
          </th>
        )
      })}
    </>
  )
}

const TableTotals: React.FC<{
  headers: Header<ICategoryPosition, unknown>[]
}> = ({ headers }) => {
  return (
    <>
      {headers.map((header) => (
        <td key={header.id} css={[cellStyles[header.column.id]]}>
          {header.column.columnDef.footer
            ? flexRender(header.column.columnDef.footer, header.getContext())
            : null}
        </td>
      ))}
    </>
  )
}
