import { createReducer } from 'typesafe-actions'
import {
  subAccountDataActions,
  SubAccountActionTypes,
  addEditSubAccountDataActions,
  categoryDataActions,
  aBADataActions,
  getNinthwaveConfig,
  linkAccountActions,
  unLinkAccountActions,
  fetchGLMainAccountsActions
} from './actions'

import {
  ISubAccountResponse,
  ICategoryResponse,
  IABAResponse,
  IAddEditSubAcctResponse,
  INinthWaveConfig,
  UnlinkAccountResponseStatus,
  IMainAccount,
  ISubAccountRequest
} from './types'

export interface ISubAccountState {
  SubAccountRes: {
    subAccountsRes?: ISubAccountResponse
    isLoading: boolean
    error?: Error
    pkid?: ISubAccountRequest
  }
  AddEditSubAccount?: {
    isLoading: boolean
    error?: Error
    subAccountRes?: IAddEditSubAcctResponse
  }
  CategoryResponse?: {
    isLoading: boolean
    error?: Error
    categoryResponse?: ICategoryResponse
  }
  ABAResponse?: {
    isLoading: boolean
    error?: Error
    aBAResponse?: IABAResponse
  }
  NinthWaveConfigResponse?: {
    configs?: INinthWaveConfig
    isLoading: boolean
    error?: Error
  }
  LinkAccountResponse?: {
    linkaccount?: string
    isLoading: boolean
    error?: Error
  }
  unLinkAccountResponse?: {
    unLinkAccount?: UnlinkAccountResponseStatus
    isLoading: boolean
    error?: Error
  }
  mainAccountState?: {
    isLoading: boolean
    error?: Error
    accounts?: IMainAccount[]
  }
}

const initialState: ISubAccountState = {
  SubAccountRes: {
    isLoading: false,
    subAccountsRes: undefined
  }
}

//create reducer for Sub Account

export const subAccountReducer = createReducer<
  ISubAccountState,
  SubAccountActionTypes
>(initialState)
  .handleAction(subAccountDataActions.request, (state, action) => ({
    ...state,
    SubAccountRes: {
      isLoading: true,
      error: undefined,
      SubAccountRes: [],
      pkid: action.payload
    }
  }))
  .handleAction(subAccountDataActions.success, (state, action) => ({
    ...state,
    SubAccountRes: {
      isLoading: false,
      error: undefined,
      subAccountsRes: action.payload
    }
  }))
  .handleAction(subAccountDataActions.failure, (state, action) => ({
    ...state,
    SubAccountRes: {
      isLoading: false,
      subAccountsRes: undefined,
      error: action.payload
    }
  }))
  .handleAction(addEditSubAccountDataActions.request, (state) => ({
    ...state,
    AddEditSubAccount: {
      isLoading: true,
      error: undefined,
      subAccountRes: undefined
    }
  }))
  .handleAction(addEditSubAccountDataActions.success, (state, action) => ({
    ...state,
    AddEditSubAccount: {
      isLoading: false,
      error: undefined,
      subAccountRes: action.payload
    }
  }))
  .handleAction(addEditSubAccountDataActions.failure, (state, action) => ({
    ...state,
    AddEditSubAccount: {
      isLoading: false,
      subAccountRes: undefined,
      error: action.payload
    }
  }))
  .handleAction(categoryDataActions.request, (state) => ({
    ...state,
    CategoryResponse: {
      isLoading: true,
      error: undefined,
      categoryResponse: undefined
    }
  }))
  .handleAction(categoryDataActions.success, (state, action) => ({
    ...state,
    CategoryResponse: {
      isLoading: false,
      error: undefined,
      categoryResponse: action.payload
    }
  }))
  .handleAction(categoryDataActions.failure, (state, action) => ({
    ...state,
    CategoryResponse: {
      isLoading: false,
      error: action.payload,
      categoryResponse: undefined
    }
  }))
  .handleAction(aBADataActions.request, (state) => ({
    ...state,
    ABAResponse: {
      isLoading: true,
      error: undefined,
      aBAResponse: undefined
    }
  }))
  .handleAction(aBADataActions.success, (state, action) => ({
    ...state,
    ABAResponse: {
      isLoading: false,
      error: undefined,
      aBAResponse: action.payload
    }
  }))
  .handleAction(aBADataActions.failure, (state, action) => ({
    ...state,
    ABAResponse: {
      isLoading: false,
      error: action.payload,
      aBAResponse: undefined
    }
  }))
  .handleAction(getNinthwaveConfig.request, (state) => ({
    ...state,
    NinthWaveConfigResponse: {
      ...state.NinthWaveConfigResponse,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(getNinthwaveConfig.success, (state, action) => ({
    ...state,
    NinthWaveConfigResponse: {
      isLoading: false,
      configs: action.payload,
      error: undefined
    }
  }))
  .handleAction(getNinthwaveConfig.failure, (state, action) => ({
    ...state,
    NinthWaveConfigResponse: {
      isLoading: false,
      configs: undefined,
      error: action.payload
    }
  }))
  .handleAction(linkAccountActions.request, (state) => ({
    ...state,
    LinkAccountResponse: {
      isLoading: true,
      linkaccount: undefined,
      error: undefined
    }
  }))
  .handleAction(linkAccountActions.success, (state) => ({
    ...state,
    LinkAccountResponse: {
      isLoading: false,
      error: undefined,
      linkaccount: undefined
    }
  }))
  .handleAction(linkAccountActions.failure, (state, action) => ({
    ...state,
    LinkAccountResponse: {
      ...state.LinkAccountResponse,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(unLinkAccountActions.request, (state) => ({
    ...state,
    unLinkAccountResponse: {
      ...state.unLinkAccountResponse,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(unLinkAccountActions.success, (state, action) => ({
    ...state,
    unLinkAccountResponse: {
      unLinkAccount: action.payload,
      isLoading: false,
      error: undefined
    }
  }))
  .handleAction(unLinkAccountActions.failure, (state, action) => ({
    ...state,
    unLinkAccountResponse: {
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(fetchGLMainAccountsActions.request, (state) => ({
    ...state,
    mainAccountState: {
      isLoading: true,
      error: undefined,
      accounts: undefined
    }
  }))
  .handleAction(fetchGLMainAccountsActions.success, (state, action) => ({
    ...state,
    mainAccountState: {
      isLoading: false,
      error: undefined,
      accounts: action.payload
    }
  }))
  .handleAction(fetchGLMainAccountsActions.failure, (state, action) => ({
    ...state,
    mainAccountState: {
      accounts: undefined,
      isLoading: false,
      error: action.payload
    }
  }))
