import { Theme, css, useTheme } from '@emotion/react'
import { addMonths, startOfYear } from 'date-fns'
import type { PlotColumnOptions } from 'highcharts'
import { range, unionWith } from 'lodash'
import { IndeterminateProgressIndicator } from 'modules/Advisory/modules/Rdot360/components/shared'
import {
  useIncomeFeatureStore,
  IncomeChart
} from 'modules/Advisory/modules/Rdot360/features/Income'
import { IIncomeSummaryChartResponseSubTotal } from 'modules/Advisory/modules/Rdot360/store/holdingsApi/IIncomeSummaryResponse'
import { useGetProjectedIncomeChartQueryForSelectedAccounts } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useMemo } from 'react'

export const getClasses = (theme: Theme) => ({
  chartContainer: css({
    backgroundColor: theme.colors.primaryWhite,
    border: `solid 1px ${theme.colors.extraGray1}`,
    paddingTop: '20px',
    paddingLeft: 234,
    paddingRight: 100,
    overflow: 'auto',
    height: 280
  })
})

export const IncomeSummaryChartProjected: React.FC<{
  columnOptions?: PlotColumnOptions
}> = ({ columnOptions }) => {
  const { financialYear } = useIncomeFeatureStore()

  const { data, isLoading, isFetching } =
    useGetProjectedIncomeChartQueryForSelectedAccounts(financialYear)

  const dataWith12Months = useMemo(() => {
    const now = new Date()
    const start = financialYear === 2 ? addMonths(now, 1) : startOfYear(now)
    const empty = range(0, 12)
      .map((i) => addMonths(start, i))
      .map(
        (x): IIncomeSummaryChartResponseSubTotal => ({
          monSeq: x.getMonth() + 1,
          year: x.getFullYear()
        })
      )
    return unionWith(
      data?.incomeSubTotal || [],
      empty,
      (a, b) =>
        (a.monSeq || a.monseq) === (b.monSeq || b.monseq) && a.year === b.year
    )
  }, [data?.incomeSubTotal, financialYear])

  const theme = useTheme()
  const classes = useMemo(() => getClasses(theme), [theme])

  return (
    <>
      <div css={classes.chartContainer}>
        <IncomeChart
          data={dataWith12Months}
          includeOptionPremiums={false}
          columnOptions={columnOptions}
        />
      </div>
      {(isLoading || isFetching) && <IndeterminateProgressIndicator />}
    </>
  )
}
