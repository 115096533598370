import {
  IComboBoxOption,
  Label,
  MessageBar,
  MessageBarType,
  ProgressIndicator,
  SelectableOptionMenuItemType
} from '@fluentui/react'
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  SortingState,
  getSortedRowModel,
  Row,
  getFilteredRowModel
} from '@tanstack/react-table'
import { useWindowVirtualizer } from '@tanstack/react-virtual'
import { format, isAfter, isBefore } from 'date-fns'
import { uniq } from 'lodash'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { FormattedNumber } from 'react-intl'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { useDebounce } from 'shared/hooks/useDebounce'
import { Dropdown } from '../../components/shared'
import { detailTable } from '../../components/shared/DataTable/DetailTable'
import { Searchbox } from '../../components/shared/DetailTables/Searchbox'
import {
  useRevenueDetailFilterStore,
  IFilters
} from '../../features/Revenue/store'
import { useGetRevenueDetailTableData } from '../../features/Revenue/useRevenueContext'
import { constants } from '../../shared/theme'
import { IRevenueDetailTableItem } from '../../store/datahub'
import { constructColumns, IFormattedData } from './RevenueDetailListColumns'
import { RevenueDetailListDatePicker } from './RevenueDetailListDatePicker'

function formatData(data?: IRevenueDetailTableItem[]) {
  const result: IFormattedData[] | undefined = data?.map((asset) => {
    return {
      periodDate: parseDateISOStringInLocalTimezone(
        asset.event.periodTimestamp
      ),
      date: parseDateISOStringInLocalTimezone(asset.event.tradeOrRevenueDate),
      accountNumber: asset.event.accountNumber,
      contact: asset.event.contact,
      description: asset.event.description,
      allocatedAmount: asset.event.allocatedAmount,
      revenueCategory: asset.event.revenueCategoryLvl1,
      revenueCategoryDetail: asset.event.revenueCategoryLvl2
    }
  })
  return result
}

function filterData(formattedData?: IFormattedData[], filters?: IFilters) {
  const result = formattedData?.filter((asset) => {
    if (filters) {
      return (
        (format(asset.periodDate, "MMM'' yy") ===
          format(filters.periodStart, "MMM'' yy") ||
          (isAfter(new Date(asset.periodDate), filters.periodStart) &&
            isBefore(new Date(asset.periodDate), filters.periodEnd))) &&
        (filters?.subCategory || filters?.category
          ? asset.revenueCategoryDetail === filters?.subCategory ||
            asset.revenueCategory === filters?.category
          : true)
      )
    }
  })
  return result
}

const columns = constructColumns()

export const RevenueDetailList: React.FC<{
  revenueTableStartDate: Date
  revenueTableEndDate: Date
}> = ({ revenueTableStartDate, revenueTableEndDate }) => {
  const { filters, setFilters } = useRevenueDetailFilterStore()

  const { data, isFetching, error } = useGetRevenueDetailTableData(
    format(revenueTableStartDate, 'yyyy-MM-dd'),
    format(revenueTableEndDate, 'yyyy-MM-dd')
  )
  const formattedData = useMemo(() => {
    return formatData(data) ?? []
  }, [data])

  const categories = useMemo(() => {
    return uniq(formattedData.map((asset) => asset.revenueCategory))
  }, [formattedData])
  const subCategories = useMemo(() => {
    return uniq(formattedData.map((asset) => asset.revenueCategoryDetail))
  }, [formattedData])
  const categoryOptions = useMemo(() => {
    return categories.map((category) => {
      return {
        key: `Category-${category}`,
        text: category,
        data: 'category'
      }
    })
  }, [categories])
  const subCategoryOptions = useMemo(() => {
    return subCategories.map((subCategory) => {
      return {
        key: `SubCategory-${subCategory}`,
        text: subCategory,
        data: 'subCategory'
      }
    })
  }, [subCategories])
  const options: IComboBoxOption[] = useMemo(() => {
    return [
      {
        key: 'all',
        text: 'All'
      },
      {
        key: 'revCatHeader',
        text: 'Revenue Category',
        itemType: SelectableOptionMenuItemType.Header
      },
      ...categoryOptions,
      {
        key: 'revCatDetailHeader',
        text: 'Revenue Category Detail',
        itemType: SelectableOptionMenuItemType.Header
      },
      ...subCategoryOptions
    ]
  }, [categoryOptions, subCategoryOptions])

  const [selectedOption, setSelectedOption] = useState(options[0])
  const onFilterChange = useCallback(
    (ev: any, option?: IComboBoxOption) => {
      if (!option) {
        return
      }
      setSelectedOption(option)
      if (option.key === 'all') {
        setFilters({
          periodStart: filters?.periodStart ?? revenueTableStartDate,
          periodEnd: filters?.periodEnd ?? revenueTableEndDate,
          category: undefined,
          subCategory: undefined
        })
      } else if (option.data === 'category') {
        setFilters({
          periodStart: filters?.periodStart ?? revenueTableStartDate,
          periodEnd: filters?.periodEnd ?? revenueTableEndDate,
          category: option.text,
          subCategory: undefined
        })
      } else {
        setFilters({
          periodStart: filters?.periodStart ?? revenueTableStartDate,
          periodEnd: filters?.periodEnd ?? revenueTableEndDate,
          category: undefined,
          subCategory: option.text
        })
      }
    },
    [
      filters?.periodEnd,
      filters?.periodStart,
      revenueTableEndDate,
      revenueTableStartDate,
      setFilters
    ]
  )
  useEffect(() => {
    if (filters === undefined) {
      setFilters({
        periodStart: revenueTableStartDate,
        periodEnd: revenueTableEndDate,
        category: undefined,
        subCategory: undefined
      })
    } else if (!filters?.category && !filters?.subCategory) {
      setSelectedOption({ key: 'all', text: 'All' })
    } else if (filters?.category) {
      setSelectedOption({
        key: `Category-${filters?.category}`,
        text: filters?.category,
        data: 'category'
      })
    } else if (filters?.subCategory) {
      setSelectedOption({
        key: `SubCategory-${filters?.subCategory}`,
        text: filters?.subCategory,
        data: 'subCategory'
      })
    }
  }, [filters, revenueTableEndDate, revenueTableStartDate, setFilters])

  const [searchText, setSearchText] = useState<string | undefined>('')
  const debouncedSearchText = useDebounce(searchText, 100)?.toLowerCase().trim()

  const searchData = useMemo(() => {
    const filteredData = filterData(formattedData, filters)
    const result = filteredData?.filter((asset) => {
      return debouncedSearchText
        ? [
            asset.revenueCategory,
            asset.revenueCategoryDetail,
            asset.description,
            asset.accountNumber
          ]
            .filter(Boolean)
            .some((x) => `${x}`.toLowerCase().indexOf(debouncedSearchText) >= 0)
        : asset
    })
    return result
  }, [debouncedSearchText, filters, formattedData])

  const getTotal = useMemo(() => {
    return searchData?.length
  }, [searchData?.length])

  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data: searchData ?? [],
    columns: columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    manualPagination: true,
    autoResetExpanded: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel()
  })
  const headers = table.getFlatHeaders()
  const { rows } = table.getRowModel()

  const tableContainerRef = useRef<HTMLDivElement | null>(null)
  const tableContainerOffsetRef = useRef(0)
  useLayoutEffect(() => {
    tableContainerOffsetRef.current = tableContainerRef.current?.offsetTop || 0
  }, [tableContainerRef.current?.offsetTop])

  const virtualizer = useWindowVirtualizer({
    count: rows.length,
    estimateSize: () => 55,
    scrollMargin: tableContainerOffsetRef.current
  })
  const items = virtualizer.getVirtualItems()

  return (
    <div style={{ width: '100%' }}>
      <div
        css={{
          marginBottom: '20px',
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'end'
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'start',
            width: '800px',
            justifyContent: 'space-between'
          }}
        >
          <div>
            <Label>Search</Label>
            <Searchbox
              searchText={searchText}
              onChange={(x) => setSearchText(x)}
            />
          </div>
          <RevenueDetailListDatePicker
            defaultStartDate={filters?.periodStart ?? revenueTableStartDate}
            defaultEndDate={filters?.periodEnd ?? revenueTableEndDate}
            minDate={revenueTableStartDate}
            maxDate={revenueTableEndDate}
          />
          <div css={{ width: '200px' }}>
            <Dropdown
              placeholder="Category"
              label="Category"
              options={options}
              selectedKey={selectedOption?.key}
              onChange={onFilterChange}
            />
          </div>
        </div>

        <div css={{ fontWeight: 'bold' }}>
          <FormattedNumber value={getTotal ?? 0} style="decimal" /> Results
        </div>
      </div>

      <ProgressIndicator
        progressHidden={!isFetching}
        styles={{
          itemProgress: { padding: 0, margin: 0 }
        }}
      />
      <table
        css={[
          detailTable.detailTable,
          detailTable.leftRightPadding,
          {
            minWidth: '975px',
            position: 'sticky',
            top: constants.headerOffset,
            zIndex: 1
          }
        ]}
      >
        <thead>
          <tr>
            {table.getFlatHeaders().map((header) => {
              return (
                <th
                  key={header.id}
                  style={{
                    width: header.getSize(),
                    height: 0,
                    padding: 0,
                    margin: 0
                  }}
                />
              )
            })}
          </tr>
          <tr>
            {table.getFlatHeaders().map((header) => {
              return (
                <th key={header.id}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        onClick: header.column.getToggleSortingHandler()
                      }}
                      css={{
                        cursor: 'pointer'
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                  )}
                </th>
              )
            })}
          </tr>
        </thead>
      </table>
      {items?.length === 0 ? (
        <MessageBar
          messageBarType={error ? MessageBarType.error : MessageBarType.info}
        >
          {error
            ? (error as Error)?.message || 'An unknown error occurred'
            : 'No Data Available'}
        </MessageBar>
      ) : (
        <div
          ref={tableContainerRef}
          style={{
            position: 'relative',
            height: virtualizer.getTotalSize(),
            width: '100%'
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              transform: `translateY(${
                items?.[0]?.start - virtualizer.options.scrollMargin
              }px)`,
              width: '100%'
            }}
          >
            <table
              css={[
                detailTable.detailTable,
                detailTable.leftRightPadding,
                {
                  minWidth: '975px'
                }
              ]}
            >
              <thead>
                <tr>
                  {headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          width: header.getSize(),
                          height: 0,
                          padding: 0,
                          margin: 0
                        }}
                      />
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {items.map((virtualRow) => {
                  const row = rows[virtualRow.index] as Row<
                    | {
                        periodDate: string
                        date: string
                        accountNumber: string
                        contact: string
                        description: string
                        allocatedAmount: string
                        revenueCategory: string
                        revenueCategoryDetail: string
                      }
                    | undefined
                  >
                  return (
                    <tr
                      key={virtualRow.key}
                      data-index={virtualRow.index}
                      ref={virtualizer.measureElement}
                      css={{ height: '55px' }}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}
