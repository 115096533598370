import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { AppState } from '..'
import { IEnvironmentConfiguration } from '../../shared/services/environment/IEnvironmentConfiguration'

interface ISystemSliceState {
  environment?: IEnvironmentConfiguration
}

export const { actions: systemActions, reducer: systemReducer } = createSlice({
  name: 'system',
  initialState: {} as ISystemSliceState,
  reducers: {
    setEnvironment: (
      state,
      action: PayloadAction<IEnvironmentConfiguration>
    ) => ({ ...state, environment: action.payload })
  }
})

const getEnvironmentState = (state: AppState) => state.system.environment
export const getIsEnvironmentLoaded = flow(
  getEnvironmentState,
  (x) => x != null
)
const getEnvironmentApiState = flow(getEnvironmentState, (x) => x?.apis)
export const getRockefellerApiConfig = flow(
  getEnvironmentApiState,
  (x) => x?.rockefeller
)
export const getMicrosoftGraphApiConfig = flow(
  getEnvironmentApiState,
  (x) => x?.microsoftgraph
)
export const getDynamicsCrmApiConfig = flow(
  getEnvironmentApiState,
  (x) => x?.['dynamics-crm']
)
export const getClientOnlineApiConfig = flow(
  getEnvironmentApiState,
  (x) => x?.clientonline
)
export const getEnvironmentConfigSites = flow(
  getEnvironmentState,
  (x) => x?.sites
)
export const getEnvironmentConfigApps = flow(
  getEnvironmentState,
  (x) => x?.apps
)
export const getEnvironmentName = flow(getEnvironmentState, (x) => x?.name)
export const getIsEnvironmentProduction = flow(
  getEnvironmentName,
  (x) => x === 'prod'
)
