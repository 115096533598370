import { css } from '@emotion/react'
import { range } from 'lodash'
import { IRevenueSummary } from '../../../store/datahub'
import { dateFormat } from './RevenueSummary'

const classes = {
  percentage: css({
    position: 'relative',
    height: '20px',
    borderRadius: '30px 30px 30px 30px'
  })
}

const Percentage: React.FC<{ value?: number }> = ({ value }) => {
  const percentage = Math.round((value ?? 0) * 100)
  return (
    <div
      css={[
        classes.percentage,
        {
          width: `${percentage > 100 ? '120px' : '113px'}`,
          background: `${percentage > 100 ? '#3E8DDD' : '#E2EDF5'}`
        }
      ]}
    >
      <InnerPercentage value={percentage} />
    </div>
  )
}
const InnerPercentage: React.FC<{ value?: number }> = ({ value }) => {
  return (
    <div
      css={[
        classes.percentage,
        {
          width: `${value && value > 100 ? (113 / 120) * 100 : value}%`,
          background: `${value && value > 100 ? '#57C8E7' : '#3E8DDD'}`
        }
      ]}
    />
  )
}
interface PeriodAnnualizedProps {
  date?: string
  percentage?: number
}
const PeriodAnnualizedFormat: React.FC<PeriodAnnualizedProps> = ({
  date,
  percentage
}) => {
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '15px',
        justifyContent: percentage || date ? 'space-between' : 'center'
      }}
    >
      <div
        css={{
          fontWeight: '400',
          fontSize: '14px'
        }}
      >
        {date}
      </div>
      <div
        css={{
          fontWeight: '500',
          fontSize: '20px'
        }}
      >
        {percentage ? `${Math.round(percentage * 100)}%` : '--'}
      </div>
    </div>
  )
}
export const PeriodAnnualized: React.FC<{
  data?: IRevenueSummary
  isAnnual: boolean
}> = ({ data, isAnnual }) => {
  const prior3Months = (data?.prior3Months || []).map((value, i) => {
    const num = (isAnnual ? value.annualizedRevenue : value.periodRevenue) || 0
    const den =
      (isAnnual
        ? data?.priorYear.ytdRevenue
        : data?.priorYearPrior3Months?.[i]?.periodRevenue) || 0

    return { date: value.period, percentage: den && num / den }
  })

  return (
    <div
      css={{
        width: '100%',
        height: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
      }}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          paddingLeft: '5%',
          paddingRight: '5%'
        }}
      >
        {!!prior3Months?.length &&
          prior3Months.map(({ date, percentage }) => {
            return (
              <div key={date}>
                <PeriodAnnualizedFormat
                  date={dateFormat(date)}
                  percentage={percentage}
                />
                <Percentage value={percentage} />
              </div>
            )
          })}
        {!prior3Months?.length &&
          range(0, 3).map((key) => (
            <div key={key}>
              <PeriodAnnualizedFormat />
              <Percentage />
            </div>
          ))}
      </div>
    </div>
  )
}
