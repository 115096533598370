export const investmentsTableColumnNames = {
  accountNumber: 'Account Number',
  nickname: 'Nickname',
  assetClass: 'Asset Class',
  sectorClass: 'Sector Class',
  productType: 'Product Type',
  secid: 'Secid',
  symbol: 'Symbol',
  desc: 'Description',
  description: 'Symbol / Description',
  quantity: 'Quantity',
  price: 'Price',
  priceChange: 'Price Change',
  value: 'Value',
  dayChange: 'Day Gains/Losses',
  unrealized: 'Unrealized Gain/Loss',
  unitCost: 'Unit Cost',
  costBasis: 'Cost Basis',
  yield: 'Yield',
  accruedIncome: 'Accrued Income',
  asOf: 'As Of'
}

export const getViewByKey = (type: string) => {
  switch (type) {
    case 'P':
      return investmentsTableColumnNames.productType
    case 'S':
      return investmentsTableColumnNames.sectorClass
    default:
      return investmentsTableColumnNames.assetClass
  }
}
