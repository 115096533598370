import { MessageBarType } from '@fluentui/react'
import { delay, flow } from 'lodash/fp'
import { combineReducers } from 'redux'
import { call, put, takeEvery, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { IDatahubHousehold } from '../../../../api/households'
import { AppState } from '../../../../store'
import { pushNotification } from '../../../Notifications'
import { updateHouseholdFromApi } from '../../store/sagas'

const OPEN_PANEL = '@features/@households/@editHouseholdNamePanel/OPEN_PANEL'
const CLOSE_PANEL = '@features/@households/@editHouseholdNamePanel/CLOSE_PANEL'

export const editHouseholdNamePanelActions = {
  openPanel: createAction(OPEN_PANEL)<IDatahubHousehold>(),
  closePanel: createAction(CLOSE_PANEL)()
}

export interface IUpdateHouseholdNameActionPayload {
  householdId: string
  name: string
}

const REQUEST_UPDATE_HOUSEHOLD_NAME =
  '@features/@households/@editHouseholdNamePanel/REQUEST_UPDATE_HOUSEHOLD_NAME'
const UPDATE_HOUSEHOLD_NAME_SUCCESS =
  '@features/@households/@editHouseholdNamePanel/UPDATE_HOUSEHOLD_NAME_SUCCESS'
const UPDATE_HOUSEHOLD_NAME_FAILURE =
  '@features/@households/@editHouseholdNamePanel/UPDATE_HOUSEHOLD_NAME_FAILURE'

export const updateHouseholdNameActions = {
  request: createAction(
    REQUEST_UPDATE_HOUSEHOLD_NAME
  )<IUpdateHouseholdNameActionPayload>(),
  success: createAction(
    UPDATE_HOUSEHOLD_NAME_SUCCESS
  )<IUpdateHouseholdNameActionPayload>(),
  failure: createAction(UPDATE_HOUSEHOLD_NAME_FAILURE)<Error>()
}

export type EditHouseholdNamePanelActionTypes =
  | ActionType<typeof editHouseholdNamePanelActions>
  | ActionType<typeof updateHouseholdNameActions>

export interface IEditHouseholdNamePanelState {
  isPanelOpen: boolean
  household?: IDatahubHousehold
}

const initialPanelState: IEditHouseholdNamePanelState = {
  isPanelOpen: false
}

const editHouseholdNamePanelUiReducer = createReducer<
  IEditHouseholdNamePanelState,
  EditHouseholdNamePanelActionTypes
>(initialPanelState)
  .handleAction(editHouseholdNamePanelActions.openPanel, (state, action) => ({
    ...state,
    isPanelOpen: true,
    household: action.payload
  }))
  .handleAction(editHouseholdNamePanelActions.closePanel, (state) => ({
    ...state,
    isPanelOpen: false,
    household: undefined
  }))

export const getEditHouseholdNamePanelUiState = (state: AppState) =>
  state.features.households.features.editHouseholdName.editHouseholdNamePanel
export const getIsEditHouseholdNamePanelOpen = flow(
  getEditHouseholdNamePanelUiState,
  (x) => x.isPanelOpen
)
export const getEditHouseholdNamePanelHousehold = flow(
  getEditHouseholdNamePanelUiState,
  (x) => x.household
)

export interface IUpdateHouseholdNameState {
  item?: IUpdateHouseholdNameActionPayload
  success?: boolean
  loading: boolean
  error?: Error
}

const initialUpdateNameState = {
  loading: false
}

const updateNameReducer = createReducer<
  IUpdateHouseholdNameState,
  EditHouseholdNamePanelActionTypes
>(initialUpdateNameState)
  .handleAction(updateHouseholdNameActions.request, (state, action) => ({
    ...state,
    item: action.payload,
    success: undefined,
    error: undefined,
    loading: true
  }))
  .handleAction(updateHouseholdNameActions.success, (state) => ({
    ...state,
    success: true,
    loading: false
  }))
  .handleAction(updateHouseholdNameActions.failure, (state, action) => ({
    ...state,
    success: false,
    error: action.payload,
    loading: false
  }))
  .handleAction(editHouseholdNamePanelActions.closePanel, () => ({
    ...initialUpdateNameState
  }))

export const editHouseholdNameReducer = combineReducers({
  editHouseholdNamePanel: editHouseholdNamePanelUiReducer,
  updateNamePatch: updateNameReducer
})

export const getUpdateNameState = (state: AppState) =>
  state.features.households.features.editHouseholdName.updateNamePatch
export const getIsUpdateNameLoading = flow(getUpdateNameState, (x) => x.loading)
export const getUpdateNameError = flow(getUpdateNameState, (x) => x.error)

const updateName = function* (
  action: ReturnType<typeof updateHouseholdNameActions.request>
) {
  yield delay(300)
  const { householdId, name } = action.payload
  try {
    yield call(updateHouseholdFromApi, {
      id: householdId,
      name
    })

    yield put(updateHouseholdNameActions.success(action.payload))
  } catch (e: any) {
    yield put(updateHouseholdNameActions.failure(e))
  }
}

export const editHouseholdNameSagas = [
  () => takeLatest(updateHouseholdNameActions.request, updateName),
  () =>
    takeEvery(updateHouseholdNameActions.success, function* () {
      yield put(editHouseholdNamePanelActions.closePanel())

      yield call(pushNotification, {
        message: 'Name updated successfully.',
        type: MessageBarType.success
      })
    })
]
