import { IColumn } from '@fluentui/react'
import { add, format, sub } from 'date-fns'
//import { isEmpty } from 'lodash'
import { numberToCurrency } from 'modules/Fees/utilities'
import { _copyAndSort } from 'modules/shared/utilities'
import { IRecurringRequst } from 'modules/Transfers/features/MoneyMovement/store/types'
import { getLocalDateTime } from 'modules/Transfers/features/MoneyMovement/utilities/Utilities'
import {
  getFreqencyType,
  getPeriodicPaymentDataForEdit
} from 'modules/Transfers/features/MoneyMovement/utilities/UtilitiesPeriodicPmt'
import { saveBlobAsFile } from 'shared/downloads'
import { getDefaultColumns } from '../Constants'
import { ColumnSetting, PaymentMM } from '../store/types'

const formatDate = (date: Date | undefined | null, formatStr = 'MM/dd/yyyy') =>
  date ? format(date, formatStr) : ''
export function getDateMinMax(key: string) {
  const now = new Date()

  let from = ''
  let to = ''
  switch (key) {
    case 'last7':
      from = formatDate(sub(now, { days: 7 }), 'yyyy/MM/dd')
      to = formatDate(now, 'yyyy/MM/dd')
      break
    case 'last30':
      from = formatDate(sub(now, { days: 30 }), 'yyyy/MM/dd')
      to = formatDate(now, 'yyyy/MM/dd')
      break
    case 'last90':
      from = formatDate(sub(now, { days: 90 }), 'yyyy/MM/dd')
      to = formatDate(now, 'yyyy/MM/dd')
      break
    case 'today':
      from = formatDate(now, 'yyyy/MM/dd')
      to = formatDate(now, 'yyyy/MM/dd')
      break
    case 'future':
      from = formatDate(add(now, { days: 1 }), 'yyyy/MM/dd')
      break
  }
  return { min: from, max: to }
}

export const getSchedulesOccurences = (item?: PaymentMM): IRecurringRequst => {
  const getPeriodicPaymentInfo = getPeriodicPaymentDataForEdit(item)
  const request = {
    ...getPeriodicPaymentInfo.frequencyInfo,
    ...getPeriodicPaymentInfo.dateInfo,
    StartDate: item?.apprDetail?.recurringReq?.startDate
      ? `${item?.apprDetail?.recurringReq?.startDate}`
      : undefined,
    EndDate: item?.apprDetail?.recurringReq?.endDate
      ? `${item?.apprDetail?.recurringReq?.endDate}`
      : undefined,
    RecurringPlanTranId: item?.tranInfo?.origRecurringPlanTranId ?? undefined
  }
  return request
}

export function columnsToString<T>(data: T[]): string[] {
  if (!data) {
    return []
  }

  const column = Object.keys(data[0] ?? [])
  return column
}

export function getColumnsToString(data: any[]): string[] {
  if (!data) {
    return []
  }

  const columns: (string | string[])[] = []
  for (const key in data[0]) {
    if (isJson(data[0][key])) {
      const parsedValue = JSON.parse(data[0][key], (x, y) =>
        typeof x === 'number' ? undefined : y
      )
      if (Array.isArray(parsedValue)) {
        columns.push(...getColumnsToString(parsedValue))
      } else if (typeof parsedValue === 'object') {
        columns.push(...getColumnsToString([parsedValue]))
      } else {
        columns.push(key)
      }
    } else {
      columns.push(key)
    }
  }
  // })
  return columns.flat()
}

export const isJson = (value: any) => {
  try {
    if (value === null) {
      return
    }

    if (value) {
      JSON.parse(value, function (key, val) {
        if (typeof val === 'undefined') {
          throw new Error('invalid json')
        }
        return true
      })
      return true
    }
  } catch (e) {
    return false
  }
}

export function dataToString(data?: any[]) {
  //convert a number to a string
  const dataString: string[] = []
  data &&
    Object.entries(data)?.map(([, data]) => {
      const updatedObject = data

      for (const key in data) {
        if (typeof data[key] === 'number') {
          data[key] = JSON.stringify(data[key])
        }
      }
      dataString.push(updatedObject)
    })
  return dataString
}

export const updateDataColumns = (data?: string[], ...args: any[]) => {
  let currencyType: any = undefined
  let amount: any = undefined
  data &&
    Object.entries(data).forEach(([, value]: any) => {
      for (const d in value) {
        args.forEach((x: any) => {
          if (x === d && x === 'transactionAmount') {
            amount = value[d]
            currencyType = value['currency'] ?? ''

            value[d] =
              (currencyType &&
                amount &&
                numberToCurrency(amount, currencyType)) ??
              value['transactionAmount']
          }
          if (x === d && x === 'recurringFrequency') {
            value[d] = getFreqencyType(value[d]).replaceAll('--', '')
          }
          if (
            x === d &&
            (x === 'createdOn' ||
              x === 'reportDate' ||
              x === 'dateEntered' ||
              x === 'timeEntered')
          ) {
            value[d] = value[d]
              ? format(
                  new Date(getLocalDateTime(value[d])),
                  'MM/dd/yyyy hh:mm:ss aa'
                )
              : ''
          }
        })
      }
    })

  return data ?? []
}

export const createWorkbookPayment = async (
  responseData: any[],
  columns: any[],
  fileSheetName: string,
  workSheeName: string
) => {
  if (responseData.length === 0) {
    return []
  }
  //This is a large library, so we are using dynamic import to reduce the bundle size
  const exceljs = await import('exceljs')
  const workbook = new exceljs.Workbook()

  const sheet = workbook.addWorksheet(workSheeName, {
    pageSetup: {
      fitToPage: true,
      fitToHeight: 5,
      fitToWidth: 7,
      orientation: 'landscape'
    },
    views: [{ state: 'frozen', ySplit: 1 }]
  })

  sheet.properties.defaultRowHeight = 18
  sheet.properties.defaultColWidth = 20

  sheet.columns = columns

  sheet.addRow(1).values = columns.map((keys) => keys.Header)
  sheet.getRow(1).font = {
    size: 12,
    bold: true
  }

  sheet.autoFilter = {
    from: 'A2',
    to: {
      row: sheet.rowCount,
      column: sheet.columnCount
    }
  }
  sheet.getRow(1).border = {
    bottom: { style: 'thin' }
  }

  Object.entries(responseData)?.map(([, data]) => {
    const row = {}
    for (const key in data) {
      const newRow = { [key]: data[key] }
      Object.assign(row, newRow)
    }

    sheet.addRow(row)
  })

  workbook.xlsx.writeBuffer().then()
  const base64 = await workbook.xlsx.writeBuffer({
    base64: true
  } as any)

  const blob = new Blob([base64], { type: 'application/octet-stream' })
  saveBlobAsFile(blob, fileSheetName)
}

export const isPreviewOccurences = (item?: PaymentMM) => {
  if (
    item?.tranInfo?.origRecurringPlanTranId &&
    item?.mmStatusDescription === 'Live'
  ) {
    return 'N'
  } else if (item?.tranInfo?.origRecurringPlanTranId) {
    return 'Y'
  } else {
    return undefined
  }
}

export const formatDateWithoutTime = (date?: string) => {
  if (!date) {
    return ''
  }
  const year = date.split('-')[0]
  const month = date.split('-')[1]
  const day = date.split('-')[2].split('T')[0]
  return `${month}/${day}/${year}`
}

export const SortColumn = (
  column: IColumn,
  items: any,
  columns: any,
  isSortedDescending?: boolean
): { items: any; columns: any } => {
  const newColumns: IColumn[] = columns?.slice()
  const currColumn: IColumn = newColumns?.filter(
    (currCol) => column.key === currCol.key
  )[0]
  newColumns?.forEach((newCol: IColumn) => {
    if (newCol === currColumn) {
      currColumn.isSortedDescending = isSortedDescending
      currColumn.isSorted = true
    } else {
      newCol.isSorted = false
      newCol.isSortedDescending = true
    }
  })

  if (!currColumn?.fieldName) {
    throw new Error('fieldName is undefined')
  }

  const newItems = _copyAndSort(
    items,
    currColumn?.fieldName,
    !currColumn?.isSortedDescending
  )

  return {
    items: newItems,
    columns: newColumns
  }
}

export const updatePreferenceBasedOnDefaultColumn = (
  isRecurringPayment?: boolean,
  preference?: ColumnSetting[]
): ColumnSetting[] => {
  // this is to remove any deleted columns, old column names that are in preference
  // preference should be in synch with default column list
  const DefaultColumnList = getDefaultColumns(isRecurringPayment)
  const updatedPref = preference
    ?.map((pref) => {
      const defaultColumn = DefaultColumnList?.find(
        (item) => item?.key === pref?.key
      )
      if (!defaultColumn) {
        return {}
      }
      return {
        key: defaultColumn?.key,
        name: defaultColumn?.name,
        isVisible: pref?.isVisible
      } as ColumnSetting
    })
    ?.filter((x) => x?.key)

  return updatedPref || []
}
