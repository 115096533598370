export const saveBlobAsFile = (blob: Blob, filename: string) => {
  if ((window.navigator as any)?.msSaveOrOpenBlob) {
    const navAsAny = window.navigator as any
    navAsAny.msSaveOrOpenBlob(blob, filename)
  } else {
    const url = window.URL.createObjectURL(blob)
    downloadUrlAsFile(url, filename)
  }
}

export const downloadUrlAsFile = (url: string, filename: string) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.href = url
  a.download = filename
  a.click()
  setTimeout(() => {
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }, 0)
}
