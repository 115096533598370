import { IModuleDefinition } from 'modules/types'
import { lazy } from 'react'

const AlertsContainer = lazy(() => import('./AlertsContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/alerts/*',
    private: true,
    component: AlertsContainer
  },
  name: 'Alerts Dashboard'
}

export default moduleDefinition
