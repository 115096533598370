import { createReducer } from 'typesafe-actions'
import { VendorActionsType, VendorListActions } from './actions'
import { IVendorItem } from './type'

export interface IVendorState {
  vendorlist: IVendorItem[]
  error?: Error
  isLoading: boolean
}

const initialState: IVendorState = {
  vendorlist: [],
  isLoading: false
}

export const vendorReducer = createReducer<IVendorState, VendorActionsType>(
  initialState
)
  .handleAction(VendorListActions.request, (state) => ({
    ...state,
    isLoading: true,
    vendorlist: []
  }))
  .handleAction(VendorListActions.success, (state, action) => ({
    ...state,
    isLoading: false,
    vendorlist: action.payload
  }))
  .handleAction(VendorListActions.failure, (state, action) => ({
    ...state,
    isLoading: false,
    vendorlist: [],
    error: action.payload
  }))
