import { Checkbox, FontIcon, Stack } from '@fluentui/react'
import { groupBy, keyBy, mapValues } from 'lodash'
import { useCallback, useMemo } from 'react'
import { isNotNullOrUndefined } from '../../../../shared/guards'

export interface ISelectableTreeProps {
  leafs?: ITreeLeaf[]
  selected?: string[]
  nodes?: ITreeNode[]
  collapsed?: string[]
  TreeNodeComponent: React.FC<{ id: string }>
  onCollapsedChanged: (collapsed: string[]) => void
  onSelectedChanged: (selected: string[]) => void
  disabled?: boolean
}

export interface ITreeNode {
  id: string
  children?: ITreeNode[]
}

export interface ITreeLeaf {
  id: string
  parentId?: string
}

const flatten = <T extends { children?: T[] }, U>(
  node: T,
  map: (node: T, level: number, parent?: T, index?: number) => U | undefined,
  level = 0,
  parent?: T,
  index = 0
): U[] => {
  const childLevel = level + 1
  const newNode = map(node, level, parent, index)
  if (!newNode) {
    return []
  }
  const children = (
    node?.children?.map((x, i) => flatten(x, map, childLevel, node, i)) || []
  )
    .flat()
    .filter(isNotNullOrUndefined)
  return [newNode, ...children]
}

export const SelectableTree: React.FC<ISelectableTreeProps> = ({
  leafs = [],
  selected = [],
  nodes = [],
  collapsed = [],
  TreeNodeComponent,
  onCollapsedChanged,
  onSelectedChanged,
  disabled
}) => {
  const leafLookupByParent = useMemo(
    () => groupBy(leafs, ({ parentId = '' }) => parentId),
    [leafs]
  )

  const selectedLookup = useMemo(() => keyBy(selected), [selected])
  const collapsedLookup = useMemo(() => keyBy(collapsed), [collapsed])

  const flatNodes = useMemo(
    () =>
      nodes
        .map((node) =>
          flatten(node, (node, level, parent) => {
            const { id, children } = node
            const hasChildren =
              !!children?.length || leafLookupByParent[id]?.length

            if ((parent && collapsedLookup[parent.id]) || !hasChildren) {
              return
            }

            const leafs = flatten(node, (x) => x)
              .map(({ id }) => leafLookupByParent[id] || [])
              .flat()

            const hasLeafs = !!leafs.length
            if (!hasLeafs) {
              return
            }

            const checked =
              hasLeafs && leafs.every(({ id }) => selectedLookup[id])
            const indeterminate =
              !checked && hasLeafs && leafs.some(({ id }) => selectedLookup[id])
            return {
              id,
              level,
              hasChildren,
              checked,
              indeterminate
            }
          })
        )
        .flat(),
    [collapsedLookup, leafLookupByParent, nodes, selectedLookup]
  )

  const onNodeCollapsedToggle = useCallback(
    (id: string) => {
      const newLookup = { ...collapsedLookup, [id]: !collapsedLookup[id] }
      onCollapsedChanged(
        Object.entries(newLookup)
          .map(([key]) => key)
          .filter((key) => newLookup[key])
      )
    },
    [collapsedLookup, onCollapsedChanged]
  )

  const onCheckboxChanged = useCallback(
    (id: string, checked?: boolean) => {
      const leaf = leafs.find((leaf) => leaf.id === id)

      if (leaf) {
        onSelectedChanged(
          Object.entries({ ...selectedLookup, [id]: checked })
            .filter(([, val]) => val)
            .map(([key]) => key)
        )
        return
      }

      const node = nodes
        .map((node) => flatten(node, (x) => x))
        .flat()
        .find((node) => node.id === id)

      if (!node) {
        return
      }

      const nodeLeafs = flatten(
        node,
        ({ id }) => leafLookupByParent[id] || []
      ).flat()

      onSelectedChanged(
        Object.entries({
          ...selectedLookup,
          ...mapValues(
            keyBy(nodeLeafs, ({ id }) => id),
            () => checked
          )
        })
          .filter(([, checked]) => checked)
          .map(([key]) => key)
      )
    },
    [leafLookupByParent, leafs, nodes, onSelectedChanged, selectedLookup]
  )

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      {flatNodes
        .flatMap(({ id, level, ...rest }) => [
          { id, level, ...rest },
          ...(!collapsedLookup[id]
            ? (leafLookupByParent[id] || []).map(({ id }) => ({
                id,
                level: level + 1,
                hasChildren: false,
                checked: !!selectedLookup[id],
                indeterminate: false
              }))
            : [])
        ])
        .map(({ id, level, hasChildren, checked, indeterminate }) => {
          const marginLeft = `${level * 30 + (hasChildren ? 0 : 10)}px`
          const collapsed = !!collapsedLookup[id]
          return (
            <Stack
              key={id}
              horizontal={true}
              verticalAlign="center"
              tokens={{ childrenGap: 5 }}
              styles={{ root: { marginLeft } }}
            >
              <Stack.Item>
                {!!hasChildren && (
                  <FontIcon
                    iconName={
                      collapsed ? 'ChevronRightSmall' : 'ChevronDownSmall'
                    }
                    style={{ fontSize: '11px', cursor: 'pointer' }}
                    onClick={() => onNodeCollapsedToggle(id)}
                  />
                )}
              </Stack.Item>

              <Stack.Item>
                <Checkbox
                  indeterminate={indeterminate}
                  checked={checked}
                  onChange={(ev, checked) => onCheckboxChanged(id, checked)}
                  disabled={disabled}
                />
              </Stack.Item>

              <Stack.Item grow={1}>
                <TreeNodeComponent id={id} />
              </Stack.Item>
            </Stack>
          )
        })}
    </Stack>
  )
}
