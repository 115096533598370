import { Stack } from '@fluentui/react'
import { flexRender, Header, SortingState } from '@tanstack/react-table'
import { SortIndicator } from 'modules/Advisory/modules/Rdot360/shared/SortIndicator'
import {
  rdot360TableStyles,
  useRdot360ThemedTableStyles
} from 'modules/Advisory/modules/Rdot360/shared/tableStyles'
import { IPerformanceReport } from 'modules/Advisory/modules/Rdot360/store/types'
import { FC } from 'react'
import { reportCellStyles } from '../../styles'
import { colNames } from './columnDefinitions'

const { reportName, portfolioName } = colNames

export const TableHead: FC<{
  headers: Header<IPerformanceReport, unknown>[]
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>
}> = ({ headers, setSorting }) => {
  const themedStyles = useRdot360ThemedTableStyles()
  return (
    <thead
      css={[themedStyles.headerContainer, rdot360TableStyles.headerContainer]}
    >
      <tr css={[rdot360TableStyles.headerRow]}>
        <th css={{ width: 38 }} />
        {headers.map((header, i) => {
          const isFirstHeader = !i
          const isSortingEnabled = header.column.getCanSort()
          const headerText = flexRender(
            header.column.columnDef.header,
            header.getContext()
          )
          const headerTitle = isFirstHeader
            ? `${portfolioName} / ${headerText}`
            : headerText
          const headerStyles = reportCellStyles[header.column.id]

          const firstHeaderSortingHandler = () => {
            const sortState = headers[0].column.getIsSorted()
            const asc = sortState === false || sortState === 'desc'
            const newSorting = [
              {
                id: portfolioName,
                desc: !asc
              },
              {
                id: reportName,
                desc: !asc
              }
            ]
            setSorting(newSorting)
          }
          const sortingHandler = isFirstHeader
            ? firstHeaderSortingHandler
            : header.column.getToggleSortingHandler()

          return isSortingEnabled ? (
            <th
              key={header.id}
              css={[headerStyles, { cursor: 'pointer' }]}
              onClick={sortingHandler}
            >
              <Stack
                horizontal
                tokens={{ childrenGap: 8 }}
                verticalAlign="center"
              >
                <span>{headerTitle}</span>
                <SortIndicator direction={header.column.getIsSorted()} />
              </Stack>
            </th>
          ) : (
            <th key={header.id} css={[headerStyles]}>
              {headerTitle}
            </th>
          )
        })}
        <th css={{ width: 50 }} />
      </tr>
    </thead>
  )
}
