import { combineReducers, Reducer } from 'redux'
import { createReducer } from 'typesafe-actions'
import {
  IFacetResult,
  ISearchResult,
  SearchResponseType
} from '../../../../api/common.types'
import { IListsFilter } from '../contracts/IListsFilter'
import { IListsUiState } from '../contracts/IListsUIState'
import {
  IListsActions,
  IListsDataActions,
  ListsDataActionTypes,
  ListsExportActions,
  ListsExportActionTypes,
  ListsFacetActions,
  ListsFacetActionTypes,
  ListsUiActions,
  ListsUiActionTypes
} from './actions'

export interface IListsDataState<T extends SearchResponseType> {
  loading?: boolean
  progress?: number
  chunks?: ISearchResult<T>[]
  totalCount?: number
  error?: Error
}

export const createListsDataReducer = <T extends SearchResponseType>(
  actions: IListsDataActions<T>
) => {
  const initialState: IListsDataState<T> = {}

  return createReducer<IListsDataState<T>, ListsDataActionTypes<T>>(
    initialState
  )
    .handleAction(actions.request, (state) => ({
      ...state,
      progress: 0,
      loading: true,
      error: undefined
    }))
    .handleAction(actions.loadMore, (state) => ({
      ...state,
      loading: true,
      error: undefined
    }))
    .handleAction(actions.complete, (state) => ({
      ...state,
      loading: false
    }))
    .handleAction(actions.chunk, (state, action) => {
      const isFirstChunk = action.payload.index === 0
      const chunks = [...(isFirstChunk ? [] : state.chunks || [])]
      chunks[action.payload.index] = action.payload.result
      const totalCount = isFirstChunk
        ? action.payload.result['@odata.count']
        : state.totalCount

      return {
        ...state,
        chunks,
        totalCount,
        progress: chunks
          .filter((x) => x)
          .map((x) => x.value.length)
          .reduce((a, x) => a + x, 0)
      }
    })
    .handleAction(actions.error, (state, action) => ({
      ...initialState,
      error: action.payload
    }))
}

export const createListsUiReducer = (
  actions: ListsUiActions,
  initialState?: IListsUiState
) => {
  return createReducer<IListsUiState, ListsUiActionTypes>(initialState || {})
    .handleAction(actions.setFilter, (state, action) => ({
      ...state,
      filters: {
        ...state.filters,
        [action.payload.id]: action.payload
      }
    }))
    .handleAction(actions.removeFilters, (state, action) => {
      const newFilters: Record<string, IListsFilter> = { ...state.filters }
      action.payload.forEach((id) => {
        delete newFilters[id]
      })

      return {
        ...state,
        filters: newFilters
      }
    })
    .handleAction(actions.updateSearchText, (state, action) => ({
      ...state,
      searchText: action.payload
    }))
    .handleAction(actions.sort, (state, action) => ({
      ...state,
      orderBy: action.payload
    }))
    .handleAction(actions.updateColumnState, (state, action) => ({
      ...state,
      columnState: action.payload
    }))
    .handleAction(actions.update, (state, action) => ({
      ...state,
      ...action.payload
    }))
    .handleAction(actions.setFilters, (state, action) => ({
      ...state,
      filters: { ...state.filters, ...action.payload }
    }))
    .handleAction(actions.setConfig, (state, action) => ({
      ...state,
      ...action.payload
    }))
}

export interface IListsExportState {
  loading?: boolean
  progress?: number
  totalCount?: number
  error?: Error
}

export const createListsExportReducer = (actions: ListsExportActions) => {
  const initialState: IListsExportState = {}

  return createReducer<IListsExportState, ListsExportActionTypes>(initialState)
    .handleAction(actions.request, (state) => ({
      ...state,
      progress: 0,
      loading: true,
      error: undefined
    }))
    .handleAction(actions.complete, (state) => ({
      ...state,
      loading: false
    }))
    .handleAction(actions.error, (state, action) => ({
      ...initialState,
      error: action.payload
    }))
}

export interface IListsFacetState {
  loading?: boolean
  error?: Error
  items?: Record<string, IFacetResult[]>
}

export const createListsFacetReducer = (actions: ListsFacetActions) => {
  const initialState: IListsFacetState = {}

  return createReducer<IListsFacetState, ListsFacetActionTypes>(initialState)
    .handleAction(actions.request, (state, action) => {
      const { id } = action.payload
      const newState = { ...state, loading: true, error: undefined }
      if (id && newState.items) {
        delete newState.items[id]
      }
      return newState
    })
    .handleAction(actions.complete, (state, action) => ({
      ...state,
      loading: false,
      items: { ...state.items, ...action.payload }
    }))
    .handleAction(actions.error, (state, action) => ({
      ...state,
      loading: false,
      error: action.payload
    }))
    .handleAction(actions.reset, (state) => ({
      ...state,
      items: undefined
    }))
}

export interface IListsState<T extends SearchResponseType> {
  ui: IListsUiState
  data: IListsDataState<T>
  export: IListsExportState
  facet: IListsFacetState
}

export const createListsReducer = <T extends SearchResponseType>(
  actions: IListsActions<T>,
  initialUiState?: IListsUiState
): Reducer<IListsState<T>> => {
  const { uiActions, dataActions, exportActions, facetActions } = actions
  return combineReducers({
    ui: createListsUiReducer(uiActions, initialUiState),
    data: createListsDataReducer(dataActions),
    export: createListsExportReducer(exportActions),
    facet: createListsFacetReducer(facetActions)
  })
}
