import { CellContext, ColumnDef } from '@tanstack/react-table'
import { format } from 'date-fns'
import { IOpenTaxLotDetail } from 'modules/Advisory/modules/Rdot360/store/tasApi/IOpenTaxLotResponse'
import { FormattedNumber } from 'react-intl'
import { USD } from 'shared/components/Formatting'
import { ChangeCell } from '../../shared/ChangeCell'

export const openLotTableColumnNames = {
  acquisitionDate: 'Acquired',
  term: 'Term',
  quantity: 'Quantity',
  price: 'Price',
  value: 'Value',
  unrealized: 'Unrealized Gain/Loss',
  unitCost: 'Unit Cost',
  costBasis: 'Cost Basis',
  priceChange: 'priceChange',
  dayChange: 'dayChange'
}

export type OpenLotTableColumnNames = keyof typeof openLotTableColumnNames

const USDCell: React.FC<CellContext<IOpenTaxLotDetail, unknown>> = ({
  getValue
}) => {
  const value = getValue<number>()
  return <USD fractionDigits={2} value={value} currencySign="standard" />
}

const FormattedNumberCell: React.FC<
  CellContext<IOpenTaxLotDetail, unknown>
> = ({ getValue }) => {
  const value = getValue<number>()
  return (
    <FormattedNumber
      maximumFractionDigits={2}
      minimumFractionDigits={2}
      value={value}
    />
  )
}

export const getOpenLotsTableColumns = (): ColumnDef<IOpenTaxLotDetail>[] => [
  {
    id: openLotTableColumnNames.acquisitionDate,
    header: openLotTableColumnNames.acquisitionDate,
    accessorFn: (item) => item.AcquisitionDate,
    cell: ({ getValue }) => {
      const value = getValue<string>()
      return value ? format(new Date(value), 'yyy-MM-dd') : ''
    }
  },
  {
    id: openLotTableColumnNames.term,
    header: openLotTableColumnNames.term,
    accessorFn: (item) => (item.LongShort === 'L' ? 'Long term' : 'Short term'),
    cell: ({ getValue }) => getValue<string>()
  },
  {
    id: openLotTableColumnNames.quantity,
    header: openLotTableColumnNames.quantity,
    accessorFn: (item) => item.Units,
    cell: FormattedNumberCell
  },
  {
    id: openLotTableColumnNames.price,
    header: openLotTableColumnNames.price,
    accessorFn: (item) => item.RecentPrice,
    cell: USDCell
  },
  {
    id: openLotTableColumnNames.priceChange,
    enableSorting: false,
    header: ''
  },
  {
    id: openLotTableColumnNames.value,
    header: openLotTableColumnNames.value,
    accessorFn: (item) => item.RecentMktVal,
    cell: USDCell
  },
  {
    id: openLotTableColumnNames.dayChange,
    enableSorting: false,
    header: ''
  },
  {
    id: openLotTableColumnNames.unrealized,
    header: openLotTableColumnNames.unrealized,
    accessorFn: (item) => item.UnrealizedGainLossAmt,
    cell: ChangeCell
  },
  {
    id: openLotTableColumnNames.unitCost,
    header: openLotTableColumnNames.unitCost,
    accessorFn: (item) => item.AvgUnitCost,
    cell: USDCell
  },
  {
    id: openLotTableColumnNames.costBasis,
    header: openLotTableColumnNames.costBasis,
    accessorFn: (item) => item.CostBasis,
    cell: USDCell
  }
]
