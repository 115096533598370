import { ActionType, createAction } from 'typesafe-actions'
import { IPayees, IPayeesRequest } from './types'

export const FETCH_PAYEES = '@modules/@payments/@payee/FETCH_PAYEES_PAYMENTS'
export const FETCH_PAYEES_SUCCESS =
  '@modules/@payments/@payee/FETCH_PAYEES_SUCCESS'
export const FETCH_PAYEES_FAILURE =
  '@modules/@payments/@payee/FETCH_PAYEES_FAILURE'

export const ADD_EDIT_PAYEES = '@modules/@payments/@payee/ADD_EDIT_PAYEES'
export const ADD_EDIT_PAYEES_SUCCESS =
  '@modules/@payments/@payee/ADD_EDIT_PAYEES_SUCCESS'
export const ADD_EDIT_PAYEES_FAILURE =
  '@modules/@payments/@payee/ADD_EDIT_PAYEES_FAILURE'

export const payeesDataActions = {
  request: createAction(FETCH_PAYEES)<IPayeesRequest>(),
  success: createAction(FETCH_PAYEES_SUCCESS)<IPayees[]>(),
  failure: createAction(FETCH_PAYEES_FAILURE)<Error>()
}

export const addEditPayeesDataActions = {
  request: createAction(ADD_EDIT_PAYEES)<IPayees | undefined>(),
  success: createAction(ADD_EDIT_PAYEES_SUCCESS)<IPayees>(),
  failure: createAction(ADD_EDIT_PAYEES_FAILURE)<Error>()
}

export type PayeesDataActionTypes = ActionType<typeof payeesDataActions>
export type AddEditActionTypes = ActionType<typeof addEditPayeesDataActions>

export type PayeesActionTypes = PayeesDataActionTypes | AddEditActionTypes
