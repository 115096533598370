import { combineReducers } from 'redux'
import { hurdleEditFormReducer } from './hurdleEditForm'
import { hurdleEditPanelReducer, hurdleEditPanelSagas } from './hurdleEditPanel'
import { hurdleFetchReducer, hurdleFetchSagas } from './hurdleFetch'

export const hurdleEditReducer = combineReducers({
  hurdleFetch: hurdleFetchReducer,
  hurdleEditPanel: hurdleEditPanelReducer,
  hurdleEditForm: hurdleEditFormReducer
})

export const hurdleEditSagas = [...hurdleFetchSagas, ...hurdleEditPanelSagas]
