import {
  groupBy,
  sum,
  sumBy,
  uniq,
  keyBy,
  intersectionWith,
  orderBy
} from 'lodash'
import { flow } from 'lodash/fp'
import { createSelector } from 'reselect'
import { isNotNullOrEmpty, isNotNullOrUndefined } from '../../../shared/guards'
import {
  accountTypes,
  AnnualizedCategoriesEnum,
  AnnualizedVelocityCategoriesEnum,
  AUMCategoryEnum,
  brokerageproducts,
  feebasedproducts,
  T12CategoriesEnum,
  T12VelocityCategoriesEnum,
  IWithAnalyticsDashboardCategories
} from '../shared/types'
import { getTeamsModuleState } from './shared'
import { IAumCategoryItem, ITeamAnalyticsWithRank } from './teamAnalyticsApi'
import { getTeamAnalyticsDashboardState } from './teamAnalyticsDashboard'
import {
  getTeamAnalyticsTeams,
  getTeamAnalyticsTotal
} from './teamAnalyticsFetch'

export const getFilter = flow(getTeamAnalyticsDashboardState, (x) => x?.filter)
export const getDivisionFilter = flow(
  getTeamAnalyticsDashboardState,
  (x) => x?.divisionFilter
)
export const getDivisionFilterSearch = flow(
  getTeamAnalyticsDashboardState,
  (x) => x?.divisionFilterSearch
)
export const getTeamsFilter = flow(
  getTeamAnalyticsDashboardState,
  (x) => x?.teamsFilter || []
)
export const getSelectedLengthService = flow(
  getTeamAnalyticsDashboardState,
  (x) => x?.selectedLengthOfService
)
export const getLengthService = flow(
  getTeamAnalyticsDashboardState,
  (x) => x && { min: x.minDate, max: x.maxDate }
)
export const getAsOfDate = flow(
  getTeamAnalyticsDashboardState,
  (x) => x?.asOfDate
)
export const getPriorFirmFilter = flow(
  getTeamAnalyticsDashboardState,
  (x) => x?.priorFirm
)

export const getVintage = flow(
  getTeamsModuleState,
  (x) => x?.teamAnalyticsDashboard.vintage
)

const getTeamName = (item: ITeamAnalyticsWithRank) => {
  return item.team?.teamName?.trim()
}

const getOnboardingDate = (item: ITeamAnalyticsWithRank) => {
  return item.team?.onBoardingDate
    ? new Date(item.team?.onBoardingDate)
    : undefined
}
const getPriorFirm = (item: ITeamAnalyticsWithRank) => {
  return item.team?.priorFirm
}

export const getTeamAnalyticsWithDasbhoardCategories = createSelector(
  [getTeamAnalyticsTeams],
  (values) => values?.map(constructAndMergeWithDashboardAnalyticsCategories)
)

export const getFilteredTeamAnalyticsWithDasbhoardCategories = createSelector(
  [
    getTeamAnalyticsWithDasbhoardCategories,
    getFilter,
    getTeamsFilter,
    getLengthService,
    getVintage,
    getPriorFirmFilter
  ],
  (items, filter, teamsFilter, lengthOfService, vintage, priorFirmFilter) => {
    const filtered = items?.filter((item) => {
      const teamName = getTeamName(item)?.toLowerCase() || ''
      const onboardingDate = getOnboardingDate(item) || new Date()
      const onboardingYear = onboardingDate?.getFullYear()
      const priorFirm = getPriorFirm(item) || ''
      return [
        !isNotNullOrEmpty(filter) || teamName?.includes(filter.toLowerCase()),
        !teamsFilter?.length ||
          teamsFilter.map((x) => x.trim().toLowerCase()).includes(teamName),
        !lengthOfService?.min || onboardingDate >= lengthOfService.min,
        !lengthOfService?.max || onboardingDate <= lengthOfService.max,
        !(vintage && vintage === 2019) || [2018, 2019].includes(onboardingYear),
        !(vintage && vintage !== 2019) || onboardingYear === vintage,
        priorFirmFilter === 'all' || priorFirmFilter === priorFirm
      ].every(Boolean)
    })

    return orderBy(filtered, (item) =>
      item.team?.teamName === 'Ryan Group' ? '' : item.team?.onBoardingDate
    )
  }
)

export const constructAndMergeWithDashboardAnalyticsCategories = (
  item: ITeamAnalyticsWithRank
): ITeamAnalyticsWithRank & IWithAnalyticsDashboardCategories => {
  const { team } = item
  const aumGroupedByCategory = groupBy(
    team?.aumDetHistory,
    (item) =>
      aummap[
        [item.productType, item.accountType]
          .filter(isNotNullOrEmpty)
          .map((x) => x.toLowerCase())
          .join('')
      ]?.category || 'Other'
  )
  const aumCategoryTotals = Object.entries(aumGroupedByCategory).map(
    ([key, values]) => ({
      key: key,
      value: sumBy(values, (x) => x.aum || 0)
    })
  )

  const t12GroupedByAssetType = groupBy(
    team?.t12RevenueDetHistory,
    (item) => item.assetType
  )
  const t12CategoryTotals = Object.entries(t12GroupedByAssetType).map(
    ([key, values]) => ({
      key: key,
      value: sumBy(values, (x) => x.revenue || 0)
    })
  )

  const annualizedGroupedByAssetType = groupBy(
    team?.annualizedRevenueDetHistory,
    (item) => item.assetType
  )
  const annualizedCategoryTotals = Object.entries(
    annualizedGroupedByAssetType
  ).map(([key, values]) => ({
    key: key,
    value: sumBy(values, (x) => x.revenue || 0)
  }))

  const aumTotal = sumBy(
    aumCategoryTotals.filter((x) => x.key !== 'Other'),
    ({ value }) => value
  )
  const t12Total = sumBy(t12CategoryTotals, ({ value }) => value)
  const annualizedTotal = sumBy(annualizedCategoryTotals, ({ value }) => value)

  if (
    (item.aumRank && Math.abs(aumTotal - (item.aumRank?.total || 0)) > 1) ||
    (item.t12RevenueRank &&
      Math.abs(t12Total - (item.t12RevenueRank?.total || 0)) > 1) ||
    (item.annualizedRevenueRank &&
      Math.abs(annualizedTotal - (item.annualizedRevenueRank?.total || 0)) > 1)
  ) {
    console.error(`Api totals don't match category totals`, item, {
      aumTotal,
      t12Total,
      annualizedTotal
    })
  }

  const aumCategories = keyBy(
    aumCategoryTotals.map(({ key, value = 0 }) => ({
      key,
      total: value,
      average: value / aumTotal
    })),
    ({ key }) => key
  )
  const t12Categories = keyBy(
    t12CategoryTotals.map(({ key, value = 0 }) => ({
      key,
      total: value,
      average: value / t12Total
    })),
    ({ key }) => key
  )
  const annualizedCategories = keyBy(
    annualizedCategoryTotals.map(({ key, value = 0 }) => ({
      key,
      total: value,
      average: value / annualizedTotal
    })),
    ({ key }) => key
  )

  const t12VelocityCategories = Object.entries(T12VelocityDefinitions)
    .map(([key, value]) => {
      const aumTotal = sum(value.aum.map((x) => aumCategories?.[x]?.total || 0))
      const revTotal = sum(
        value.revenue.map((x) => t12Categories?.[x]?.total || 0)
      )
      return { key: key, value: revTotal / aumTotal }
    })
    .reduce(
      (a, { key, value }) => ((a[key] = value), a),
      {} as Record<string, number>
    )
  const annualizedVelocityCategories = Object.entries(
    AnnualizedVelocityDefinitions
  )
    .map(([key, value]) => {
      const aumTotal = sum(value.aum.map((x) => aumCategories?.[x]?.total || 0))
      const revTotal = sum(
        value.revenue.map((x) => annualizedCategories?.[x]?.total || 0)
      )
      return { key: key, value: revTotal / aumTotal }
    })
    .reduce(
      (a, { key, value }) => ((a[key] = value), a),
      {} as Record<string, number>
    )

  const dashboardCategories: IWithAnalyticsDashboardCategories = {
    aumCategories,
    t12Categories,
    annualizedCategories,
    t12VelocityCategories,
    annualizedVelocityCategories
  }

  return {
    ...item,
    ...dashboardCategories
  }
}

const getIsApplicableAUMItem = (item?: IAumCategoryItem) =>
  (item?.accountType && ['Managed', 'Brokerage'].includes(item.accountType)) ||
  (!item?.accountType && item?.productType === 'Other')

export const getTeamAnalyticsDasbhoardTotal = createSelector(
  [getTeamAnalyticsTotal],
  (total) =>
    total &&
    constructAndMergeWithDashboardAnalyticsCategories({
      team: { ...total, teamName: 'PWM Average' },
      aumRank: {
        total: sumBy(
          total.aumDetHistory?.filter(getIsApplicableAUMItem),
          (x) => x.aum || 0
        )
      },
      t12RevenueRank: {
        total: sumBy(total.t12RevenueDetHistory, (x) => x.revenue || 0)
      },
      annualizedRevenueRank: {
        total: sumBy(total.annualizedRevenueDetHistory, (x) => x.revenue || 0)
      }
    })
)

export const getFilteredAveragesAndTotals = createSelector(
  [getFilteredTeamAnalyticsWithDasbhoardCategories],
  (values) => {
    const aumDetHistory = values
      ?.flatMap((x) => x.team?.aumDetHistory)
      .filter(isNotNullOrUndefined)
      .filter(getIsApplicableAUMItem)
    const t12RevenueDetHistory = values
      ?.flatMap((x) => x.team?.t12RevenueDetHistory)
      .filter(isNotNullOrUndefined)
    const annualizedRevenueDetHistory = values
      ?.flatMap((x) => x.team?.annualizedRevenueDetHistory)
      .filter(isNotNullOrUndefined)

    return constructAndMergeWithDashboardAnalyticsCategories({
      team: {
        teamName: 'Filtered Average',
        aumDetHistory,
        t12RevenueDetHistory,
        annualizedRevenueDetHistory
      },
      aumRank: {
        total: sumBy(
          aumDetHistory?.filter(getIsApplicableAUMItem),
          (x) => x.aum || 0
        )
      },
      t12RevenueRank: {
        total: sumBy(t12RevenueDetHistory, (x) => x.revenue || 0)
      },
      annualizedRevenueRank: {
        total: sumBy(annualizedRevenueDetHistory, (x) => x.revenue || 0)
      }
    })
  }
)

export const getTeamAnalyticsDivisionAveragesAndTotals = createSelector(
  [getTeamAnalyticsTeams],
  (items) => {
    const teamGroups = groupBy(items, (x) => x.team?.regionName || 'Other')
    return Object.entries(teamGroups).map(([division, teams]) => {
      const aumDetHistory = teams
        ?.flatMap((x) => x.team?.aumDetHistory)
        .filter(isNotNullOrUndefined)
      const t12RevenueDetHistory = teams
        ?.flatMap((x) => x.team?.t12RevenueDetHistory)
        .filter(isNotNullOrUndefined)
      const annualizedRevenueDetHistory = teams
        ?.flatMap((x) => x.team?.annualizedRevenueDetHistory)
        .filter(isNotNullOrUndefined)
      return constructAndMergeWithDashboardAnalyticsCategories({
        team: {
          teamName: `${division} Average`,
          regionName: division,
          aumDetHistory,
          t12RevenueDetHistory,
          annualizedRevenueDetHistory
        },
        aumRank: {
          total: sumBy(
            aumDetHistory?.filter(getIsApplicableAUMItem),
            (x) => x.aum || 0
          )
        },
        t12RevenueRank: {
          total: sumBy(t12RevenueDetHistory, (x) => x.revenue || 0)
        },
        annualizedRevenueRank: {
          total: sumBy(annualizedRevenueDetHistory, (x) => x.revenue || 0)
        }
      })
    })
  }
)

export interface IDivision {
  name: string
  teams: string[]
}

export const getFilteredDivisionsWithTeams = createSelector(
  [getTeamAnalyticsTeams, getDivisionFilterSearch],
  (items, search) => {
    const lowerCaseSearch = search?.trim().toLowerCase()
    const filteredItems = lowerCaseSearch
      ? items?.filter((x) =>
          x.team?.teamName?.trim().toLowerCase().includes(lowerCaseSearch)
        )
      : items
    const divisionGroups = groupBy(
      filteredItems,
      (x) => x.team?.regionName || 'Other'
    )
    return Object.entries(divisionGroups).map(
      ([division, items]): IDivision => ({
        name: division,
        teams: items.map((x) => x.team?.teamName).filter(isNotNullOrEmpty)
      })
    )
  }
)

export const getFilteredTeamAnalyticsDivisionsAveragesAndTotals =
  createSelector(
    [
      getTeamAnalyticsDivisionAveragesAndTotals,
      getFilteredTeamAnalyticsWithDasbhoardCategories
    ],
    (divisions, filteredTeams) => {
      const filteredDivisions = Object.keys(
        groupBy(filteredTeams, (x) => x.team?.regionName)
      )
      return intersectionWith(
        divisions,
        filteredDivisions,
        (a, b) => a.team?.regionName === b
      )
    }
  )

export const getTeamNames = createSelector([getTeamAnalyticsTeams], (items) => {
  return uniq(items?.map((x) => x.team?.teamName).filter(isNotNullOrEmpty))
})

export const getPriorFirms = createSelector(
  [getTeamAnalyticsTeams],
  (items) => {
    return uniq(items?.map((x) => x?.team?.priorFirm).filter(isNotNullOrEmpty))
  }
)

const aummap = keyBy(
  [
    ...feebasedproducts.map((x) => ({
      type: [x, 'managed'],
      category: AUMCategoryEnum.FeeBased
    })),
    ...brokerageproducts.map((x) => ({
      type: [x, 'brokerage'],
      category: AUMCategoryEnum.Brokerage
    })),
    ...accountTypes.map((x) => ({
      type: ['structured products', x],
      category: AUMCategoryEnum.StructuredProducts
    })),
    ...accountTypes.map((x) => ({
      type: ['ai', x],
      category: AUMCategoryEnum.Alternatives
    })),
    {
      type: ['cash & equivalents', 'brokerage'],
      category: AUMCategoryEnum.BrokerageCash
    },
    ...accountTypes.map((x) => ({
      type: ['lending asset', x],
      category: AUMCategoryEnum.Lending
    })),
    ...accountTypes.map((x) => ({
      type: ['insurance & annuities', x],
      category: AUMCategoryEnum.InsuranceAndAnnuity
    })),
    {
      type: ['other'],
      category: AUMCategoryEnum.AssetsHeldAway
    },
    {
      type: ['advised only'],
      category: AUMCategoryEnum.Other
    }
  ],
  (x) => x.type.join('')
)

const T12VelocityDefinitions = {
  [T12VelocityCategoriesEnum.VelocityBPS]: {
    aum: [
      AUMCategoryEnum.FeeBased,
      AUMCategoryEnum.Brokerage,
      AUMCategoryEnum.StructuredProducts,
      AUMCategoryEnum.Alternatives,
      AUMCategoryEnum.Lending,
      AUMCategoryEnum.InsuranceAndAnnuity
    ],
    revenue: [
      T12CategoriesEnum.Brokerage,
      T12CategoriesEnum.StructuredProducts,
      T12CategoriesEnum.Alternatives,
      T12CategoriesEnum.FeeBased,
      T12CategoriesEnum.Lending,
      T12CategoriesEnum.Other
    ]
  },
  [T12VelocityCategoriesEnum.FeeBased]: {
    aum: [AUMCategoryEnum.FeeBased],
    revenue: ['Fee Based']
  },
  [T12VelocityCategoriesEnum.NonFeeBasedBSA]: {
    aum: [
      AUMCategoryEnum.Brokerage,
      AUMCategoryEnum.StructuredProducts,
      AUMCategoryEnum.Alternatives
    ],
    revenue: [
      T12CategoriesEnum.Brokerage,
      T12CategoriesEnum.StructuredProducts,
      T12CategoriesEnum.Alternatives
    ]
  },
  [T12VelocityCategoriesEnum.NonFeeBasedBS]: {
    aum: [AUMCategoryEnum.Brokerage, AUMCategoryEnum.StructuredProducts],
    revenue: [T12CategoriesEnum.Brokerage, T12CategoriesEnum.StructuredProducts]
  },
  [T12VelocityCategoriesEnum.NonFeeBasedB]: {
    aum: [AUMCategoryEnum.Brokerage],
    revenue: [T12CategoriesEnum.Brokerage]
  }
}

const AnnualizedVelocityDefinitions = {
  [AnnualizedVelocityCategoriesEnum.VelocityBPS]: {
    aum: [
      AUMCategoryEnum.FeeBased,
      AUMCategoryEnum.Brokerage,
      AUMCategoryEnum.StructuredProducts,
      AUMCategoryEnum.Alternatives,
      AUMCategoryEnum.Lending,
      AUMCategoryEnum.InsuranceAndAnnuity
    ],
    revenue: [
      AnnualizedCategoriesEnum.Brokerage,
      AnnualizedCategoriesEnum.StructuredProducts,
      AnnualizedCategoriesEnum.Alternatives,
      AnnualizedCategoriesEnum.FeeBased,
      AnnualizedCategoriesEnum.Lending,
      AnnualizedCategoriesEnum.Other
    ]
  },
  [AnnualizedVelocityCategoriesEnum.FeeBased]: {
    aum: [AUMCategoryEnum.FeeBased],
    revenue: [AnnualizedCategoriesEnum.FeeBased]
  },
  [AnnualizedVelocityCategoriesEnum.NonFeeBasedBSA]: {
    aum: [
      AUMCategoryEnum.Brokerage,
      AUMCategoryEnum.StructuredProducts,
      AUMCategoryEnum.Alternatives
    ],
    revenue: [
      AnnualizedCategoriesEnum.Brokerage,
      AnnualizedCategoriesEnum.StructuredProducts,
      AnnualizedCategoriesEnum.Alternatives
    ]
  },
  [AnnualizedVelocityCategoriesEnum.NonFeeBasedBS]: {
    aum: [AUMCategoryEnum.Brokerage, AUMCategoryEnum.StructuredProducts],
    revenue: [
      AnnualizedCategoriesEnum.Brokerage,
      AnnualizedCategoriesEnum.StructuredProducts
    ]
  },
  [AnnualizedVelocityCategoriesEnum.NonFeeBasedB]: {
    aum: [AUMCategoryEnum.Brokerage],
    revenue: [AnnualizedCategoriesEnum.Brokerage]
  }
}
