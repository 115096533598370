import {
  ContextualMenuItemType,
  ICommandBarItemProps,
  mergeStyleSets
} from '@fluentui/react'
import {
  ModalManageViews,
  trimNumber
} from 'features/Lists/core/components/ModalManageViews'
import { ModalSaveView } from 'features/Lists/core/components/ModalSaveView'
import { ListsComponents } from 'features/Lists/core/containers/service'
import { IListMetaPreferenceDetails } from 'features/Lists/hooks/useListPreferences'
import { useCallback, useMemo, useState } from 'react'
import { isNotNullOrFalse } from 'shared/guards'
import { useAiPositionListWithPreferences } from '../modules/Dashboard/features/AIPositionList/preferences'
import { AiPositionListTabNamesEnum } from '../modules/Dashboard/features/AIPositionList/types'

const classNames = mergeStyleSets({
  menuOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 300
  }
})

const defaultLabel = '(Default)'

export const ListCommandBarWithPreferences: React.FC<{
  firstItems?: ICommandBarItemProps[]
  lastItems?: ICommandBarItemProps[]
  listType: AiPositionListTabNamesEnum
  ListsCommandBar: ListsComponents['ListsCommandBar']
}> = ({ firstItems, lastItems, ListsCommandBar, listType }) => {
  const [saveModalProps, setSaveModalProps] =
    useState<IListMetaPreferenceDetails>()
  const [showManageViewsModal, setShowManageViewsModal] = useState(false)
  const { listPreferences } = useAiPositionListWithPreferences(listType)

  const {
    selectedViewId,
    applyView,
    defaultViewId,
    deleteView,
    isDefaultViewSelected,
    isSystemViewSelected,
    saveCurrentView,
    saveCurrentViewAs,
    savedViews,
    systemViews,
    selectedViewMetadata,
    setMetaPreferences,
    metaPreferences,
    isLoadedMetaPreferences
  } = listPreferences

  const closeSaveViewModal = useCallback(() => {
    setSaveModalProps(undefined)
  }, [])

  const closeManageViewsModal = useCallback(() => {
    setShowManageViewsModal(false)
  }, [])

  const onClickSaveChanges = useCallback(() => {
    saveCurrentView()
  }, [saveCurrentView])

  const showManageViews = useMemo(
    () => (systemViews?.length || 0) > 1 || (savedViews?.length || 0) > 0,
    [savedViews?.length, systemViews?.length]
  )

  const viewMenuTop = useMemo(() => {
    const menuTopItems = [
      !isSystemViewSelected && {
        key: 'viewSave',
        text: 'Save', // hidden if no pending changes or if system view
        iconProps: { iconName: 'Save' },
        onClick: () => onClickSaveChanges()
      },
      {
        key: 'viewSaveAs',
        text: 'Save As',
        iconProps: { iconName: 'SaveAll' },
        onClick: () => setSaveModalProps({ label: selectedViewMetadata?.label })
      },
      showManageViews && {
        key: 'manageViews',
        text: 'Manage Views',
        iconProps: { iconName: 'CustomList' },
        onClick: () => setShowManageViewsModal(true)
      },
      !isSystemViewSelected && {
        key: 'viewDividerFunctions',
        itemType: ContextualMenuItemType.Divider
      }
    ]
    return menuTopItems.filter(isNotNullOrFalse) as ICommandBarItemProps[]
  }, [
    isSystemViewSelected,
    showManageViews,
    onClickSaveChanges,
    selectedViewMetadata?.label
  ])

  const viewMenuBottom = useMemo(
    () => [
      { key: 'div1', itemType: ContextualMenuItemType.Divider },
      {
        key: 'div2',
        itemType: ContextualMenuItemType.Section,
        sectionProps: {
          title: 'System Views',
          items: systemViews?.map(({ key, label }) => ({
            key,
            text: `${label}${defaultViewId === key ? ` ${defaultLabel}` : ''}`,
            title: `${label}${defaultViewId === key ? ` ${defaultLabel}` : ''}`,
            onClick: () => applyView(key)
          }))
        }
      }
    ],
    [systemViews, defaultViewId, applyView]
  )

  const customViews = useMemo(() => {
    return savedViews?.map(({ key, label }) => {
      const text = key === defaultViewId ? `${label} ${defaultLabel}` : label
      return {
        key,
        text,
        onClick: () => applyView(key),
        className: classNames.menuOverflow,
        title: text
      }
    })
  }, [applyView, defaultViewId, savedViews])

  const menuName = useMemo(
    () =>
      selectedViewMetadata &&
      `${selectedViewMetadata.label || ''}${
        isDefaultViewSelected ? ` ${defaultLabel}` : ' '
      }`,
    [selectedViewMetadata, isDefaultViewSelected]
  )

  const myViews = useMemo(
    () => ({
      key: 'myViews',
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        title: 'My Views',
        items: [...(customViews || [])]
      }
    }),
    [customViews]
  )

  const viewsDropdown = useMemo(
    () =>
      ({
        key: 'menu',
        name: isLoadedMetaPreferences
          ? menuName || 'Default View'
          : '...Loading',
        items: [],
        buttonStyles: {
          label: {
            lineHeight: 'normal',
            overflow: 'hidden',
            textAlign: 'left',
            textOverflow: 'ellipsis',
            width: 265
          }
        },
        title: menuName,
        subMenuProps: {
          items: [
            ...viewMenuTop,
            ...(savedViews ? [myViews] : []),
            ...viewMenuBottom
          ]
        }
      } as ICommandBarItemProps),
    [
      isLoadedMetaPreferences,
      menuName,
      myViews,
      savedViews,
      viewMenuBottom,
      viewMenuTop
    ]
  )

  const first = useMemo(
    () => [viewsDropdown, ...(firstItems || [])],
    [firstItems, viewsDropdown]
  )

  // Used for View Save As and Rename
  const onClickSetNewViewName = useCallback(
    (item: IListMetaPreferenceDetails) => {
      let label = item.label || ''
      let count = 0
      // while the name is a duplicate, add a number to the end
      while (savedViews?.some((x) => x.label === label)) {
        label = trimNumber(label)
        label = `${label} (${++count})`
      }
      saveCurrentViewAs(label, item.isDefault || false)
    },
    [saveCurrentViewAs, savedViews]
  )

  const onClickSaveManageViews = useCallback(
    async (
      defaultViewId: string,
      customViews: IListMetaPreferenceDetails[]
    ) => {
      const originalCustomViews = structuredClone(savedViews) || []
      const savedContainerMeta = structuredClone(customViews) || []

      const toDelete = originalCustomViews.filter(
        ({ key: currentKey }) =>
          !customViews.find(({ key: newKey }) => currentKey === newKey)
      )

      const selectedViewIsDeleted = toDelete.some(
        ({ key }) => key === selectedViewId
      )

      if (selectedViewIsDeleted) {
        applyView(defaultViewId)
      }

      await Promise.all([
        setMetaPreferences({ savedContainerMeta, defaultViewId }),
        ...toDelete.map(({ key }) => deleteView(key || ''))
      ])
    },
    [applyView, deleteView, savedViews, selectedViewId, setMetaPreferences]
  )

  return (
    <>
      <ListsCommandBar firstItems={first} lastItems={lastItems} />

      {saveModalProps && (
        <ModalSaveView
          item={saveModalProps}
          onClose={closeSaveViewModal}
          onSubmit={onClickSetNewViewName}
        />
      )}

      {showManageViewsModal && (
        <ModalManageViews
          isOpen={showManageViewsModal}
          onClose={closeManageViewsModal}
          onSubmit={onClickSaveManageViews}
          metaPreferences={metaPreferences}
          systemViews={systemViews}
        />
      )}
    </>
  )
}
