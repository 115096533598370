import { ActionType, createAction } from 'typesafe-actions'
import {
  IFetchPaymentTransactionsRequest,
  IFetchPaymentTransactionsResponse,
  IMainAccount,
  IPayee,
  ISubAccount
} from './types'

export const FETCH_PYMTTRANS_LIST =
  '@modules/@payments/@paymentTransactions/list/FETCH_PYMTTRANS_LIST'
export const FETCH_PYMTTRANS_LIST_SUCCESS =
  '@modules/@payments/@paymentTransactions/list/FETCH_PYMTTRANS_LIST_SUCCESS'
export const FETCH_PYMTTRANS_LIST_FAILURE =
  '@modules/@payments/@paymentTransactions/list/FETCH_PYMTTRANS_LIST_FAILURE'
export const FETCH_PYMTTRANS_LIST_CLEAR =
  '@modules/@payments/@paymentTransactions/list/FETCH_PYMTTRANS_LIST_CLEAR'

export const FETCH_GL_MAIN_ACCOUNTS =
  '@modules/@payments/@paymentTransactions/list/FETCH_GL_MAIN_ACCOUNTS'
export const FETCH_GL_MAIN_ACCOUNTS_SUCCESS =
  '@modules/@payments/@paymentTransactions/list/FETCH_GL_MAIN_ACCOUNTS_SUCCESS'
export const FETCH_GL_MAIN_ACCOUNTS_FAILURE =
  '@modules/@payments/@paymentTransactions/list/FETCH_GL_MAIN_ACCOUNTS_FAILURE'

export const FETCH_SUB_ACCOUNTS =
  '@modules/@payments/@paymentTransactions/list/FETCH_SUB_ACCOUNTS'
export const FETCH_SUB_ACCOUNTS_SUCCESS =
  '@modules/@payments/@paymentTransactions/list/FETCH_SUB_ACCOUNTS_SUCCESS'
export const FETCH_SUB_ACCOUNTS_FAILURE =
  '@modules/@payments/@paymentTransactions/list/FETCH_SUB_ACCOUNTS_FAILURE'

export const FETCH_PAYEES =
  '@modules/@payments/@paymentTransactions/list/FETCH_PAYEES'
export const FETCH_PAYEES_SUCCESS =
  '@modules/@payments/@paymentTransactions/list/FETCH_PAYEES_SUCCESS'
export const FETCH_PAYEES_FAILURE =
  '@modules/@payments/@paymentTransactions/list/FETCH_PAYEES_FAILURE'

export const fetchPaymentTransactionsListActions = {
  request:
    createAction(FETCH_PYMTTRANS_LIST)<IFetchPaymentTransactionsRequest>(),
  success: createAction(FETCH_PYMTTRANS_LIST_SUCCESS)<
    IFetchPaymentTransactionsResponse[]
  >(),
  failure: createAction(FETCH_PYMTTRANS_LIST_FAILURE)<Error>(),
  clear: createAction(FETCH_PYMTTRANS_LIST_CLEAR)()
}

export const fetchGLMainAccountsActions = {
  request: createAction(FETCH_GL_MAIN_ACCOUNTS)(),
  success: createAction(FETCH_GL_MAIN_ACCOUNTS_SUCCESS)<IMainAccount[]>(),
  failure: createAction(FETCH_GL_MAIN_ACCOUNTS_FAILURE)<Error>()
}

export const fetchSubAccountsActions = {
  request: createAction(FETCH_SUB_ACCOUNTS)(),
  success: createAction(FETCH_SUB_ACCOUNTS_SUCCESS)<ISubAccount[]>(),
  failure: createAction(FETCH_SUB_ACCOUNTS_FAILURE)<Error>()
}

export const fetchPayeesActions = {
  request: createAction(FETCH_PAYEES)(),
  success: createAction(FETCH_PAYEES_SUCCESS)<IPayee[]>(),
  failure: createAction(FETCH_PAYEES_FAILURE)<Error>()
}

export type PaymentTransactionsListActionTypes =
  | ActionType<typeof fetchPaymentTransactionsListActions>
  | ActionType<typeof fetchGLMainAccountsActions>
  | ActionType<typeof fetchSubAccountsActions>
  | ActionType<typeof fetchPayeesActions>
