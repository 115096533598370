import { endOfDay, startOfDay } from 'date-fns'
import { IDataTableColumnDefinition } from 'features/DataList/common/types'
import {
  IOdataCollectionFilter,
  IOdataPropertyFilter,
  OdataFilterCollectionOperatorEnum,
  OdataFilterOperatorEnum,
  OdataPropertyFilterGroup
} from '../../../api/odata'
import { convertDateRangeToDates, DateRanges } from '../../../shared'
import { isNotNullOrFalse } from '../../../shared/guards'
import {
  IListsDateRangeFilter,
  IListsFacetFilter,
  IListsFilter,
  IListsNumberRangeFilter,
  IListsSearchFilter
} from '../../Lists/core/contracts/IListsFilter'
import { ListsFilterType } from '../../Lists/core/contracts/IListsFilterDefinition'

export const convertColumnTypeToFilterType = ({
  type,
  facetable
}: IDataTableColumnDefinition): ListsFilterType => {
  switch (type) {
    case 'date':
      return 'date'
    case 'date-only':
      return 'date-only'
    case 'number':
      return 'number'
    case 'string':
      return facetable ? 'facet' : 'search'
    case 'boolean':
      return 'boolean'
  }
}

const getUtcDateStartOfDay = (date: Date) =>
  new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
  )

export const convertToOdataFilter = (filter: IListsFilter) => {
  const { collectionPath, hasValue } = filter
  if (!hasValue) {
    return undefined
  }
  const propertyFilter = convertToOdataPropertyFilter(filter)
  if (!collectionPath) {
    return propertyFilter
  }

  const collectionFilter: IOdataCollectionFilter = {
    filter: propertyFilter,
    operator: OdataFilterCollectionOperatorEnum.any,
    path: collectionPath
  }

  return collectionFilter
}

export const convertToOdataPropertyFilter = (
  filter: IListsFilter
): OdataPropertyFilterGroup => {
  const { type, blankIndicator, dataPath = '' } = filter
  if (blankIndicator) {
    return {
      and: [
        {
          operator:
            blankIndicator === 'exclude'
              ? OdataFilterOperatorEnum.ne
              : OdataFilterOperatorEnum.eq,
          path: dataPath,
          type:
            type === 'date' || type === 'date-only' || type === 'number'
              ? 'number'
              : 'string',
          value: null
        }
      ]
    }
  }
  switch (type) {
    case 'date':
    case 'date-only': {
      const { from, to, range } = filter as IListsDateRangeFilter
      if (!range) {
        throw new Error('Range is undefined')
      }
      const [a, b] = (
        range === DateRanges.Custom
          ? [from && startOfDay(from), to && endOfDay(to)]
          : convertDateRangeToDates(range)
      ).map((x) => x && (type === 'date-only' ? getUtcDateStartOfDay(x) : x))

      const base: IOdataPropertyFilter = {
        operator: OdataFilterOperatorEnum.ge,
        type: 'datetime',
        path: dataPath
      }

      return {
        and: [
          a && {
            ...base,
            operator: OdataFilterOperatorEnum.ge,
            value: a
          },
          b && { ...base, operator: OdataFilterOperatorEnum.le, value: b }
        ].filter(isNotNullOrFalse)
      }
    }
    case 'boolean':
    case 'facet': {
      const { values, type } = filter as IListsFacetFilter
      return {
        and: [
          {
            operator: OdataFilterOperatorEnum.in,
            path: dataPath,
            type: type === 'boolean' ? 'boolean' : 'string',
            value: values
          }
        ]
      }
    }
    case 'number': {
      const {
        min,
        max,
        filterType = 'range',
        value
      } = filter as IListsNumberRangeFilter
      const base: IOdataPropertyFilter = {
        operator: OdataFilterOperatorEnum.ge,
        type: 'number',
        path: dataPath
      }

      return filterType === 'range'
        ? {
            and: [
              min != null && {
                ...base,
                operator: OdataFilterOperatorEnum.ge,
                value: min
              },
              max != null && {
                ...base,
                operator: OdataFilterOperatorEnum.le,
                value: max
              }
            ].filter(Boolean) as IOdataPropertyFilter[]
          }
        : {
            and: [
              {
                ...base,
                operator: filterType as OdataFilterOperatorEnum,
                value
              }
            ]
          }
    }
    case 'search': {
      const { value, filterType } = filter as IListsSearchFilter
      return filterType === 'eq'
        ? {
            and: [
              {
                operator: OdataFilterOperatorEnum.eq,
                path: dataPath,
                type: 'string',
                value
              }
            ]
          }
        : {
            and: [
              {
                operator: OdataFilterOperatorEnum.contains,
                path: dataPath,
                type: 'string',
                value
              }
            ]
          }
    }
  }
}
