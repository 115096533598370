import { css } from '@emotion/react'
import { DefaultButton, PrimaryButton } from '@fluentui/react'
import { FC, useMemo } from 'react'

const getClasses = () => ({
  footer: css({
    display: 'flex',
    justifyContent: 'right',
    gap: 20,
    marginTop: '20px'
  })
})

interface IButtonFooterProps {
  defaultText?: string
  defaultAction?: () => void
  primaryText: string
  primaryAction: () => void
  primaryDisabled?: boolean
  label?: string
}

export const ButtonFooter: FC<IButtonFooterProps> = ({
  defaultText = 'Cancel',
  defaultAction,
  primaryText,
  primaryAction,
  primaryDisabled,
  label
}) => {
  const classes = useMemo(() => getClasses(), [])
  return (
    <>
      <div
        style={{
          fontWeight: 800,
          fontSize: 14,
          visibility: label ? undefined : 'hidden'
        }}
      >
        {label || '_'}
      </div>
      <div css={classes.footer}>
        {defaultAction && (
          <DefaultButton
            styles={{ root: { borderRadius: 5 } }}
            onClick={defaultAction}
            text={defaultText}
          />
        )}
        <PrimaryButton
          styles={{ root: { borderRadius: 5 } }}
          onClick={primaryAction}
          text={primaryText}
          disabled={primaryDisabled}
        />
      </div>
    </>
  )
}
