import axios from 'axios'
import { flow } from 'lodash/fp'
import { createSelector } from 'reselect'
import { call, cancelled, put, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import {
  getBdaPayrollExpenses,
  IDynamicsApiResult,
  IDynamicsBdaPayrollExpense
} from '../../../../../api/dynamics'
import { parseDateISOStringInLocalTimezone } from '../../../../../shared'
import { isNotNullOrUndefined } from '../../../../../shared/guards'
import { AppState } from '../../../../../store'
import { getDynamicsApiOptions } from '../../../../../store/shared/sagas'
import { BdaSummaryPayrollExpense } from '../common/types'

const REQUEST = '@modules/@advisory/@modules/@bda/@bdaPayrollExpenses/REQUEST'
const SUCCESS = '@modules/@advisory/@modules/@bda/@bdaPayrollExpenses/SUCCESS'
const FAILURE = '@modules/@advisory/@modules/@bda/@bdaPayrollExpenses/FAILURE'

export const bdaPayrollExpensesActions = {
  request: createAction(REQUEST)<number>(),
  success: createAction(SUCCESS)<IDynamicsBdaPayrollExpense[]>(),
  failure: createAction(FAILURE)<Error>()
}

export type BdaPayrollExpensesActionTypes = ActionType<
  typeof bdaPayrollExpensesActions
>

export interface IBdaPayrollExpensesState {
  items?: IDynamicsBdaPayrollExpense[]
  loading?: boolean
  error?: Error
}

const initialState: IBdaPayrollExpensesState = {
  loading: false
}

export const bdaPayrollExpensesReducer = createReducer<
  IBdaPayrollExpensesState,
  BdaPayrollExpensesActionTypes
>(initialState)
  .handleAction(bdaPayrollExpensesActions.request, () => ({
    ...initialState,
    loading: true
  }))
  .handleAction(bdaPayrollExpensesActions.success, (state, action) => ({
    ...state,
    items: action.payload,
    loading: false
  }))
  .handleAction(bdaPayrollExpensesActions.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }))

export const getBdaPayrollExpensesState = (state: AppState) =>
  state.modules.advisory.modules.bda.payrollExpenses
const getBdaPayrollExpensesItemsInternal = flow(
  getBdaPayrollExpensesState,
  (x) => x.items
)
export const mapToSummaryPayrollExpense = (
  payroll: IDynamicsBdaPayrollExpense
): BdaSummaryPayrollExpense => ({
  ...payroll,
  category: bdaPayrollCategoryLookup[payroll.rcm_recordcode || ''] || 'Unknown',
  date: payroll.rcm_payrolldate
    ? parseDateISOStringInLocalTimezone(payroll.rcm_payrolldate)
    : undefined,
  value: payroll.rcm_currentearnings,
  rcm_Department:
    payroll.rcm_Department?.cdm_departmentnumber === '4418'
      ? {
          cdm_departmentid: '',
          cdm_departmentnumber: '4419',
          cdm_name: 'Merlin Wealth Management'
        }
      : payroll.rcm_Department
})

export const getBdaPayrollExpensesItems = createSelector(
  [getBdaPayrollExpensesItemsInternal],
  (items) => {
    return items?.map(mapToSummaryPayrollExpense)
  }
)

export const getIsBdaPayrollExpensesLoading = flow(
  getBdaPayrollExpensesState,
  (x) => x.loading
)
export const getBdaPayrollExpensesError = flow(
  getBdaPayrollExpensesState,
  (x) => x.error
)

export type BDAPayrollCategory = keyof typeof bdaPayrollCategories
export const bdaPayrollCategories = {
  'Regular Pay': [
    'Holiday',
    'PTO',
    'Regular',
    'RegularEarnings',
    'RetroactivePay',
    'Sick Pay',
    'Vacation',
    'Regular-Current',
    'Sev Lump Sum',
    'House Split Adjustment'
  ],
  OT: ['OT'],
  Bonus: ['PWA Monthly Bonus', 'BDA Bonus', 'BDA One Time', 'BDA Monthly'],
  Premium: ['Premium'],
  Unknown: ['Unknown']
}

export type SupplementalCompCategory = keyof typeof supplementalCompCategories
export const supplementalCompCategories = {
  'Fixed Dollar Bonus': [
    'PWA Fixed Dollar Bonus',
    'Supplemental Fixed Bonus',
    'Fixed Bonus'
  ],
  'Quarter End Bonus': ['PWA Quarter End Bonus', 'Quarter Bonus'],
  'Variable Bonus': [
    'PWA Variable Bonus',
    'Variable Bonus',
    'Supplemental Variable Bonus'
  ]
}

const allPayrollCategories = {
  ...bdaPayrollCategories,
  ...supplementalCompCategories
}

export const bdaPayrollCategoryLookup: Record<string, string> = [
  ...Object.entries(allPayrollCategories)
]
  .flatMap(([key, item]) => item.map((value) => [value, key]))
  .reduce((a, [key, value]) => ({ ...a, [key]: value }), {})

export const fetchBdaPayrollExpenses = function* (
  action: ReturnType<typeof bdaPayrollExpensesActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getDynamicsApiOptions, source.token)
  const pageSize = 500

  try {
    const bdaPayrollExpenses = yield* call(
      getBdaPayrollExpenses,
      apiOptions,
      new Date(Date.UTC(action.payload, 0, 1, 0, 0, 0, 0)),
      Object.entries(bdaPayrollCategories).flatMap(([, value]) => value),
      Object.entries(supplementalCompCategories).flatMap(([, value]) => value),
      pageSize
    )

    const pages = [bdaPayrollExpenses]
    let nextLink = bdaPayrollExpenses['@odata.nextLink']

    const fetchNext = (nextLink: string) =>
      axios
        .get<IDynamicsApiResult<IDynamicsBdaPayrollExpense>>(nextLink, {
          headers: {
            Authorization: `Bearer ${apiOptions.accessToken}`,
            Prefer: [
              `odata.maxpagesize=${pageSize}`,
              `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
            ].join(', ')
          }
        })
        .then((x) => x.data)

    while (nextLink) {
      const next = yield* call(fetchNext, nextLink)
      pages.push(next)
      nextLink = next['@odata.nextLink']
    }

    const results =
      pages
        .map(({ value }) => value)
        .filter(isNotNullOrUndefined)
        .flat() || []

    yield put(bdaPayrollExpensesActions.success(results))
  } catch (e: any) {
    yield put(bdaPayrollExpensesActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const bdaPayrollExpensesSagas = [
  () => takeLatest(bdaPayrollExpensesActions.request, fetchBdaPayrollExpenses)
]
