import {
  DirectionalHint,
  HoverCard,
  HoverCardType,
  IPlainCardProps,
  IStyle,
  makeStyles,
  Text
} from '@fluentui/react'
import React, { memo, PropsWithChildren, useMemo } from 'react'
import { USD } from '../../../../../../../../shared/components/Formatting'
import { ConnectedMasked } from '../../../../../../../../shared/components/MaskedText'
import { ICreditEventRecipient } from './CreditEvent'

interface IRecipientTableProps {
  recipients?: ICreditEventRecipient[]
  type: RecipientHoverCardType
  selectedLookup?: Record<string, string>
}

const useRecipientTableStyles = makeStyles((theme) => {
  const recipientTable: IStyle = {
    tableLayout: 'fixed',
    '& thead th, & tbody td': {
      ...theme.fonts.small,
      textAlign: 'left',
      padding: '1px 3px'
    },
    '& thead th': {
      fontWeight: 'bold'
    },
    '& th:nth-child(2), & td:nth-child(2)': {
      textAlign: 'right'
    }
  }

  return {
    recipientTable
  }
})
const RecipientTable: React.FC<IRecipientTableProps> = memo(
  ({ recipients, type, selectedLookup }) => {
    const classes = useRecipientTableStyles()

    return (
      <div>
        <table className={classes.recipientTable}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {recipients?.map((recipient, index) => (
              <tr key={index}>
                <td
                  style={
                    selectedLookup?.[recipient.recipientId || '']
                      ? { fontWeight: 'bold' }
                      : {}
                  }
                >
                  <ConnectedMasked text={recipient.recipientId} /> -{' '}
                  <ConnectedMasked text={recipient.recipient} />
                </td>
                <td
                  style={
                    selectedLookup?.[recipient.recipientId || '']
                      ? { fontWeight: 'bold' }
                      : {}
                  }
                >
                  <USD
                    value={
                      (type === 'allocatedAmount'
                        ? recipient.allocatedAmount
                        : recipient.grossPayout) || 0
                    }
                    currencySign="standard"
                    fractionDigits={2}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
)

export type RecipientHoverCardType = 'allocatedAmount' | 'grossPayout'
export const RecipientHoverCard: React.FC<
  PropsWithChildren<{
    recipients: ICreditEventRecipient[] | undefined
    type: RecipientHoverCardType
    selectedLookup?: Record<string, string>
  }>
> = ({ recipients, type, selectedLookup, children }) => {
  const plainCardProps = useMemo(
    (): IPlainCardProps => ({
      onRenderPlainCard: () => (
        <div style={{ padding: '10px' }}>
          <RecipientTable
            recipients={recipients}
            type={type}
            selectedLookup={selectedLookup}
          />
        </div>
      ),
      directionalHint: DirectionalHint.leftCenter,
      calloutProps: {
        isBeakVisible: true
      }
    }),
    [recipients, selectedLookup, type]
  )

  return recipients ? (
    <HoverCard
      plainCardProps={plainCardProps}
      instantOpenOnClick={true}
      type={HoverCardType.plain}
    >
      {children}
    </HoverCard>
  ) : (
    <Text nowrap={true}>{children}</Text>
  )
}
