import {
  FontSizes,
  IStyle,
  makeStyles,
  Stack,
  TooltipHost
} from '@fluentui/react'
import { IKPI } from 'api/common.types'
import { groupBy, orderBy, partition, sum, sumBy } from 'lodash'
import { Fragment, memo, useMemo } from 'react'
import { FormattedNumber } from 'react-intl'
import { USD } from 'shared/components/Formatting'
import { isNotNullOrUndefined } from 'shared/guards'
import { useNextGenCouncil } from './store'

export const USDInM: React.FC<{
  value?: number
}> = ({ value }) => {
  return (
    <>
      {value == null ? (
        <>--</>
      ) : (
        <>
          <FormattedNumber
            value={value / 1000000}
            style="currency"
            currency="USD"
            currencySign="accounting"
            maximumFractionDigits={1}
            minimumFractionDigits={1}
          />
          mm
        </>
      )}
    </>
  )
}

const useTableStyles = makeStyles((theme) => {
  const table: IStyle = {
    tableLayout: 'fixed',
    width: '100%',
    borderSpacing: '0',
    'th, td': {
      padding: '3px 8px',
      textAlign: 'right',
      width: '100px',
      borderRight: `solid 1px ${theme.palette.neutralLight}`
    },
    'tr.row:nth-child(even)': {
      backgroundColor: theme.palette.neutralLighterAlt
    },
    'tr th:first-child, tr td:first-child': {
      fontWeight: 'bold',
      textAlign: 'left',
      width: '180px'
    },
    'tr th:nth-child(2), tr td:nth-child(2)': {
      width: '180px',
      textAlign: 'left'
    },
    'tr.row td:first-child': {
      paddingLeft: '25px'
    },
    'tr th:last-child, tr td:last-child': {
      borderRight: 'none'
    },
    'tr:first-child th': {
      borderRight: 'none'
    },
    'tr:last-child td': {
      borderBottom: 'none'
    },
    'tfoot td': {
      borderTop: `solid 3px ${theme.semanticColors.bodyBackground}`,
      paddingTop: '5px',
      paddingBottom: '5px',
      fontWeight: 'bold'
    },
    'thead th': {
      borderBottom: `solid 3px ${theme.palette.neutralDark}`,
      paddingTop: '5px',
      paddingBottom: '5px',
      fontWeight: 'bold'
    },
    'tr:hover td': {
      backgroundColor: theme.palette.neutralLight
    }
  }

  return {
    table
  }
})
const getNumberFromMMString = (string?: string) => {
  const numberString = string?.replace(/\D+/g, '') || '0'
  return parseFloat(numberString) * 1000000
}

export const NextGenCouncilTable: React.FC = () => {
  const classes = useTableStyles()
  const { nextGenCouncilConfig, accountsByMember, householdsByMember } =
    useNextGenCouncil()

  const individualAssetsTotal = useMemo(() => {
    return sum(
      Object.values(accountsByMember || {}).flatMap((x) =>
        x?.entityAccounts.map((account) => account.AccountKPIs?.AccountTotal)
      )
    )
  }, [accountsByMember])
  const familyManagedTotal = useMemo(() => {
    return sum(
      Object.values(householdsByMember || {}).flatMap((x) =>
        x.map((x) => x?.householdKPI?.AumManaged)
      )
    )
  }, [householdsByMember])
  const familyAssetsTotal = useMemo(() => {
    return sum(
      Object.values(householdsByMember || {}).flatMap((x) =>
        x.map((x) => x?.householdKPI?.AumTotal)
      )
    )
  }, [householdsByMember])
  const householdsByMemberRevenue = useMemo(() => {
    return sum(
      Object.values(householdsByMember || {}).flatMap((x) =>
        x.map((x) => x?.revenue?.ttmrevenue)
      )
    )
  }, [householdsByMember])
  const nextGenCouncilConfigRevenue = useMemo(() => {
    return sum(nextGenCouncilConfig?.value?.map((x) => x.rcm_familyt12revenue))
  }, [nextGenCouncilConfig?.value])
  const totalRevenue = useMemo(() => {
    return (householdsByMemberRevenue || 0) + (nextGenCouncilConfigRevenue || 0)
  }, [householdsByMemberRevenue, nextGenCouncilConfigRevenue])
  const individualNetWorthTotal = useMemo(() => {
    return sum(
      nextGenCouncilConfig?.value?.map((x) =>
        getNumberFromMMString(x.rcm_individualnetworth)
      )
    )
  }, [nextGenCouncilConfig?.value])
  const familyNetWorthTotal = useMemo(() => {
    return sum(
      nextGenCouncilConfig?.value?.map((x) =>
        getNumberFromMMString(x.rcm_familynetworth)
      )
    )
  }, [nextGenCouncilConfig?.value])

  const [emeritus, active] = useMemo(
    () =>
      partition(
        nextGenCouncilConfig?.value,
        ({ rcm_memberstatus }) => rcm_memberstatus
      ),
    [nextGenCouncilConfig?.value]
  )

  const orderedActive = useMemo(
    () => orderBy(active, ({ rcm_dashboardorder }) => rcm_dashboardorder),
    [active]
  )

  return (
    <div>
      <div>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>Council Member</th>
              <th>Advisor</th>
              <th>Individual Assets (RCM)</th>
              <th>Family Managed Assets (RCM)</th>
              <th>Family Assets (RCM)</th>
              <th>Family T12 Revenue</th>
              <th>Individual Net Worth</th>
              <th>Family Net Worth</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={8}>ACTIVE</td>
            </tr>
            {[orderedActive, emeritus]?.flat()?.map((x, i) => {
              const accounts = x.rcm_nextgencouncilmemberid
                ? accountsByMember?.[x.rcm_nextgencouncilmemberid]
                : undefined

              const households =
                (x.rcm_nextgencouncilmemberid &&
                  householdsByMember?.[x.rcm_nextgencouncilmemberid]) ||
                []
              const entityTotal = sumBy(
                accounts?.entityAccounts,
                ({ AccountKPIs }) => AccountKPIs?.AccountTotal || 0
              )
              const familyTotal =
                sumBy(
                  households,
                  ({ householdKPI }) => householdKPI?.AumTotal || 0
                ) + (x.rcm_additionalfamilyassets || 0)

              const familyManaged = sumBy(
                households,
                ({ householdKPI }) => householdKPI?.AumManaged || 0
              )

              const familyT12Revenue = sumBy(
                households,
                ({ revenue }) => revenue?.ttmrevenue || 0
              )

              const totalFamilyRevenue =
                familyT12Revenue + (x?.rcm_familyt12revenue || 0)

              const entityTotalsByRepCode = orderBy(
                Object.values(
                  groupBy(
                    accounts?.entityAccounts,
                    ({ ClientAdvisorID }) => ClientAdvisorID
                  )
                ).map((accountGroup) => ({
                  advisor: accountGroup?.[0]?.ClientAdvisor,
                  total: sumBy(
                    accountGroup,
                    ({ AccountKPIs }) => AccountKPIs?.AccountTotal || 0
                  )
                })),
                ({ total }) => total || 0,
                'desc'
              )
              const entityAdvisor = entityTotalsByRepCode?.[0]?.advisor

              const householdTotalsByRepCode = orderBy(
                Object.values(
                  groupBy(
                    accounts?.householdAccounts,
                    ({ ClientAdvisorID }) => ClientAdvisorID
                  )
                ).map((accountGroup) => ({
                  advisor: accountGroup?.[0]?.ClientAdvisor,
                  total: sumBy(
                    accountGroup,
                    ({ AccountKPIs }) => AccountKPIs?.AccountTotal || 0
                  )
                })),
                ({ total }) => total || 0,
                'desc'
              )
              const householdAdvisor = householdTotalsByRepCode?.[0]?.advisor
              const advisor = entityAdvisor
                ? entityAdvisor
                : householdAdvisor
                ? householdAdvisor
                : x.rcm_Advisor?.fullname

              return (
                <Fragment key={x.rcm_name}>
                  {i === active.length && (
                    <tr>
                      <td colSpan={8}>EMERITUS</td>
                    </tr>
                  )}
                  <tr className="row">
                    <td>{x.rcm_name}</td>
                    <td title={advisor}>
                      <div
                        style={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden'
                        }}
                      >
                        {advisor}
                      </div>
                    </td>
                    <td>
                      {entityTotal ? (
                        <TooltipHost
                          content={
                            <AUSBreakdown
                              kpis={accounts?.entityAccounts
                                .map((x) => x.AccountKPIs)
                                .filter(isNotNullOrUndefined)}
                            />
                          }
                          tooltipProps={{ maxWidth: '600px' }}
                        >
                          <USDInM value={entityTotal} />
                        </TooltipHost>
                      ) : (
                        <>-</>
                      )}
                    </td>
                    <td>
                      {familyManaged ? (
                        <USDInM value={familyManaged} />
                      ) : (
                        <>-</>
                      )}
                    </td>
                    <td>
                      {familyTotal ? (
                        <TooltipHost
                          content={
                            <AUSBreakdown
                              kpis={households
                                .map((x) => x.householdKPI)
                                .filter(isNotNullOrUndefined)}
                              additionalAssets={x.rcm_additionalfamilyassets}
                            />
                          }
                          tooltipProps={{ maxWidth: '600px' }}
                        >
                          <USDInM value={familyTotal} />
                        </TooltipHost>
                      ) : (
                        <>-</>
                      )}
                    </td>
                    <td>
                      {totalFamilyRevenue ? (
                        <TooltipHost
                          content={
                            <RevenueBreakdown
                              rdot={familyT12Revenue}
                              manual={x.rcm_familyt12revenue}
                            />
                          }
                        >
                          <USD value={totalFamilyRevenue} fractionDigits={0} />
                        </TooltipHost>
                      ) : (
                        <>--</>
                      )}
                    </td>
                    <td>{x.rcm_individualnetworth}</td>
                    <td>{x.rcm_familynetworth}</td>
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
          <tfoot>
            <tr>
              <td>Total</td>
              <td />
              <td>
                <USDInM value={individualAssetsTotal} />
              </td>
              <td>
                <USDInM value={familyManagedTotal} />
              </td>
              <td>
                <USDInM value={familyAssetsTotal} />
              </td>
              <td>
                <USDInM value={totalRevenue} />
              </td>
              <td>
                <USDInM value={individualNetWorthTotal} />
              </td>
              <td>
                <USDInM value={familyNetWorthTotal} />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div style={{ fontSize: FontSizes.small, marginTop: '20px' }}>
        <div>
          * Columns with (RCM) indicate that the data resides in a Rockefeller
          owned database
        </div>
        <div>
          {' '}
          ** The Individual Net Worth and Family Net Worth columns are manually
          entered
        </div>
      </div>
    </div>
  )
}

const RevenueBreakdown: React.FC<{ rdot?: number; manual?: number }> = ({
  rdot,
  manual
}) => {
  return (
    <Stack>
      <LabelWithValue label="On Platform" value={rdot} showInMm={false} />
      <LabelWithValue
        label="Estimated Additional"
        value={manual}
        showInMm={false}
      />
    </Stack>
  )
}

const AUSBreakdown: React.FC<{
  kpis?: IKPI[]
  additionalAssets?: number
}> = memo(({ kpis, additionalAssets }) => {
  const managed = sumBy(kpis, ({ AumManaged }) => AumManaged || 0)
  const brokerage = sumBy(kpis, ({ AumBrokerage }) => AumBrokerage || 0)
  const adminReporting = sumBy(
    kpis,
    ({ AdminReportingAssets }) => AdminReportingAssets || 0
  )
  const loansOutstandingNfs = sumBy(
    kpis,
    ({ LoanOutstandingNfs }) => LoanOutstandingNfs || 0
  )
  const loanOutstandingOutsideNfs = sumBy(
    kpis,
    ({ LoanOutstandingOutsideNfs }) => LoanOutstandingOutsideNfs || 0
  )
  const annuity = sumBy(kpis, ({ annuity }) => annuity || 0)
  const advisedOnly = sumBy(kpis, ({ AdvisedOnly }) => AdvisedOnly || 0)

  return (
    <Stack>
      <LabelWithValue label="Managed Assets" value={managed} />
      <LabelWithValue label="Brokerage Assets" value={brokerage} />
      <LabelWithValue label="Admin Reporting" value={adminReporting} />
      <LabelWithValue
        label="Loans Outstanding NFS"
        value={loansOutstandingNfs}
      />
      <LabelWithValue
        label="Loans Outstanding Outside NFS"
        value={loanOutstandingOutsideNfs}
      />
      <LabelWithValue label="Annuities & Insurance" value={annuity} />
      <LabelWithValue label="Additional Assets" value={additionalAssets} />
      <LabelWithValue label="Advised Only" value={advisedOnly} />
    </Stack>
  )
})

const LabelWithValue: React.FC<{
  label: string
  value?: number
  showInMm?: boolean
}> = ({ label, value, showInMm = true }) => (
  <Stack horizontal={true} grow={1}>
    <div style={{ width: '150px', textAlign: 'right', marginRight: '10px' }}>
      <b>{label}:</b>
    </div>
    <div style={{ minWidth: 0, textAlign: 'right', flexGrow: 1 }}>
      {showInMm ? (
        <USDInM value={value} />
      ) : value ? (
        <USD value={value} fractionDigits={0} />
      ) : (
        <>--</>
      )}
    </div>
  </Stack>
)
