import { stringify } from 'query-string'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { datahubApi } from 'store/api/datahub'

export interface IHouseholdTag {
  householdId: string
  tagId: number
}

export interface IHouseholdTagName {
  entityId?: string | null
  tagId: number
  tagName: string
}

export interface IUpdateHouseholdTags {
  id: string
  status: number
  headers: Headers
}

const paramsSerializer = (params: any) => {
  return stringify(params, { arrayFormat: 'comma' })
}

const datahubWithHouseholdTags = datahubApi.enhanceEndpoints({
  addTagTypes: ['householdTags', 'householdTagNames']
})

const editHouseholdTagsApi = datahubWithHouseholdTags.injectEndpoints({
  endpoints: (builder) => ({
    addHouseholdTagName: builder.mutation<void, string>({
      query: (tagName) => ({
        url: 'HouseholdTagNames',
        method: 'POST',
        data: { tagName }
      })
    }),
    editHouseholdTagName: builder.mutation<void, IHouseholdTagName>({
      query: ({ tagId, tagName }) => ({
        url: `HouseholdTagNames(${tagId})`,
        method: 'PATCH',
        data: { tagName }
      })
    }),
    getHouseholdTagNames: builder.query<IHouseholdTagName[] | undefined, void>({
      query: () => ({
        url: 'HouseholdTagNames',
        params: {
          $select: ['entityId', 'tagId', 'tagName'],
          $orderby: 'tagName'
        },
        paramsSerializer
      }),
      providesTags: ['householdTagNames'],
      transformResponse: (x: IOdataResult<IHouseholdTagName>) => x?.value
    }),
    getHouseholdTags: builder.query<IHouseholdTag[] | undefined, string>({
      query: (householdId) => ({
        url: 'HouseholdTags',
        params: {
          $select: ['householdId', 'tagId'],
          $filter: `householdId eq '${householdId}'`
        },
        paramsSerializer
      }),
      providesTags: ['householdTags'],
      transformResponse: (x: IOdataResult<IHouseholdTag>) => x?.value
    }),
    updateHouseholdTags: builder.mutation<
      { responses: IUpdateHouseholdTags[] | undefined },
      { householdId: string; tagsAdded: number[]; tagsRemoved: number[] }
    >({
      query: ({ householdId, tagsAdded, tagsRemoved }) => {
        const requests = [
          ...tagsAdded.map((tagId) => ({
            url: 'HouseholdTags',
            method: 'POST',
            headers: {
              'content-type': 'application/json',
              'OData-Version': '4.0'
            },
            body: { householdId, tagId }
          })),
          ...tagsRemoved.map((tagId) => ({
            url: `HouseholdTags(HouseholdId='${householdId}',TagId=${tagId})`,
            method: 'DELETE'
          }))
        ].map((x, i) => ({ ...x, id: (i + 1).toString() }))

        return {
          url: '$batch',
          method: 'POST',
          data: { requests }
        }
      },
      invalidatesTags: ['householdTags']
    })
  })
})

export const {
  useAddHouseholdTagNameMutation,
  useEditHouseholdTagNameMutation,
  useGetHouseholdTagNamesQuery,
  useGetHouseholdTagsQuery,
  useUpdateHouseholdTagsMutation
} = editHouseholdTagsApi
