import {
  Callout,
  DirectionalHint,
  Dropdown,
  IDropdownOption,
  Stack,
  Text
} from '@fluentui/react'
import { IListsFilter } from 'features/Lists/core/contracts/IListsFilter'
import {
  IListsFilterEditWithApplyCancel,
  ListsFilterEditWithApplyCancel
} from 'features/Lists/core/features/filter/components/ListsFilterEditWithApplyCancel'
import { ListsFilterStatus } from 'features/Lists/core/features/filter/components/ListsFilterStatus'
import { ListsFilterStatusButton } from 'features/Lists/core/features/filter/components/ListsFilterStatusButton'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { isNotNullOrUndefined } from 'shared/guards'

export interface IListsFilterStatusListProps {
  filters: Record<string, IListsFilter>
  onRemove: (filter: IListsFilter) => void
  onFilterChange: (filter: IListsFilter) => void
  onFilterClick?: (filter: IListsFilter) => void
  customListsFilterEditWithApplyCancel?: React.FC<IListsFilterEditWithApplyCancel>
}

export const ListsFilterStatusList: React.FC<IListsFilterStatusListProps> = ({
  filters,
  onRemove,
  onFilterChange,
  onFilterClick,
  customListsFilterEditWithApplyCancel
}) => {
  const CustomListsFilterEditWithApplyCancel =
    customListsFilterEditWithApplyCancel
  const [selectedFilterId, setSelectedFilterId] = useState<string>()

  const selectedFilter = useMemo(
    () => selectedFilterId && filters[selectedFilterId],
    [filters, selectedFilterId]
  )

  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false)
  const editFilterRef = useRef<HTMLElement | null>(null)

  const filterOptions = useMemo(() => {
    return Object.entries(filters || {})
      .map(([key, filter]) => {
        if (filter.hasValue) {
          return
        }
        const options: IDropdownOption = {
          key,
          text: filter.name
        }
        return options
      })
      .filter(isNotNullOrUndefined)
      .filter((x) => !x.hidden)
      .sort((a, b) => ('' + a?.text).localeCompare(b?.text || ''))
  }, [filters]) as IDropdownOption[]

  const onNewFilterChange = useCallback(
    (_: any, option?: IDropdownOption) => {
      if (!option) {
        return
      }

      const filter = filters[option.key]

      if (!filter) {
        return
      }

      setSelectedFilterId(filter.id)
      onFilterClick?.(filters[filter.id])
    },
    [filters, onFilterClick]
  )

  const onApply = useCallback(
    (filter: IListsFilter) => {
      onFilterChange(filter)
      setSelectedFilterId(undefined)
    },
    [onFilterChange]
  )

  return (
    <>
      <Stack horizontal={true} tokens={{ childrenGap: 10 }} wrap={true}>
        {Object.entries(filters || {})
          .filter(([, { hasValue, hidden }]) => hasValue && !hidden)
          .map(([key, filter]) => {
            const onClick = (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
              if (filter.disableModifications) {
                return
              }
              editFilterRef.current = ev.target as HTMLElement
              setShowFilterDropdown(false)
              setSelectedFilterId(filter.id)
              onFilterClick?.(filter)
            }
            return (
              <Stack.Item key={key}>
                <ListsFilterStatusButton
                  onClick={onClick}
                  onRemove={() => onRemove(filter)}
                >
                  <Text>
                    {filter.name}:{' '}
                    <b>
                      <ListsFilterStatus
                        filter={filter}
                        shouldMask={filter.mask}
                      />
                    </b>
                  </Text>
                </ListsFilterStatusButton>
              </Stack.Item>
            )
          })}
      </Stack>
      {selectedFilter && (
        <Callout
          styles={{ root: { zIndex: 3 } }}
          target={editFilterRef}
          isBeakVisible={true}
          directionalHint={DirectionalHint.bottomAutoEdge}
          onDismiss={() => setSelectedFilterId(undefined)}
        >
          <Stack
            styles={{
              root: { minWidth: '200px', padding: '10px' }
            }}
            tokens={{ childrenGap: 10 }}
          >
            {showFilterDropdown && (
              <Dropdown
                styles={{ dropdown: { width: '100%' } }}
                selectedKey={selectedFilter.id}
                onChange={onNewFilterChange}
                placeholder="Select a column or facet to filter"
                options={filterOptions}
              />
            )}
            {selectedFilter &&
              (CustomListsFilterEditWithApplyCancel ? (
                <CustomListsFilterEditWithApplyCancel
                  filter={selectedFilter}
                  onApply={onApply}
                  onCancel={() => setSelectedFilterId(undefined)}
                />
              ) : (
                <ListsFilterEditWithApplyCancel
                  filter={selectedFilter}
                  onApply={onApply}
                  onCancel={() => setSelectedFilterId(undefined)}
                />
              ))}
          </Stack>
        </Callout>
      )}
    </>
  )
}
