import { Icon, Link, Stack, Text } from '@fluentui/react'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingComponent } from '../../shared/components/Loading'
import { errorPageActions, getIsReportErrorEmailLoading } from './store'

const error404Styles = {
  mainPage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10%'
  },
  h2Header: {
    fontSize: '18vw',
    lineHeight: '1em'
  },
  h4Header: {
    fontSize: '1.5em',
    marginBottom: '20px',
    marginTop: '2px'
  }
}

export const ErrorPage: React.FC<{
  error?: Error
  errorInfo?: React.ErrorInfo
}> = ({ error, errorInfo }) => {
  const dispatch = useDispatch()
  const [showMore, setShowMore] = useState(false)

  const isErrorReportLoading = useSelector(getIsReportErrorEmailLoading)
  const onReport = useCallback(() => {
    dispatch(errorPageActions.reportError({ error, errorInfo }))
  }, [dispatch, error, errorInfo])

  const onGoHome = useCallback(() => {
    window.location.href = window.location.origin
  }, [])

  const onRefresh = useCallback(() => {
    window.location.reload()
  }, [])

  return (
    <Stack
      style={{
        ...error404Styles.mainPage,
        position: 'inherit'
      }}
      tokens={{ childrenGap: 5 }}
      verticalAlign="center"
    >
      <div style={{ ...error404Styles.h2Header, position: 'relative' }}>
        500
      </div>
      <h4 style={{ ...error404Styles.h4Header, position: 'relative' }}>
        An error has occurred, please try one of the following:
      </h4>
      <ul>
        <li>
          <Link onClick={onRefresh}>Refresh the Page</Link>
        </li>
        <li>
          <Link onClick={onGoHome}>Go Home</Link>
        </li>
      </ul>

      <Stack verticalAlign="center" style={{ alignItems: 'center' }}>
        <Stack
          horizontal={true}
          verticalAlign="center"
          tokens={{ childrenGap: 5 }}
        >
          {isErrorReportLoading ? (
            <LoadingComponent />
          ) : (
            <Link onClick={onReport}>Report this error</Link>
          )}
          <div>|</div>
          <Stack
            horizontal={true}
            verticalAlign="center"
            tokens={{ childrenGap: 5 }}
            onClick={() => setShowMore(!showMore)}
            styles={{
              root: {
                cursor: 'pointer'
              }
            }}
          >
            <Text style={{ textAlign: 'center' }}>More Details</Text>
            {showMore ? (
              <Icon iconName="ChevronUp" title="Show Less" />
            ) : (
              <Icon iconName="ChevronDown" title="Show More" />
            )}
          </Stack>
        </Stack>
        {showMore && <Text>{error?.message}</Text>}
      </Stack>
    </Stack>
  )
}
