import { combineReducers } from 'redux'
import {
  aiDashboardFeaturesReducer,
  aiDashboardFeaturesSagas
} from '../features'
import { aiDashboardReducer } from './aiDashboard'

export const aiDashboardModuleReducer = combineReducers({
  features: aiDashboardFeaturesReducer,
  dashboard: aiDashboardReducer
})

export const aiDashboardModuleSagas = [...aiDashboardFeaturesSagas]
