import React from 'react'
import { FormattedNumber } from 'react-intl'

export type Notation = 'compact' | 'standard' | 'scientific' | 'engineering'

export const USD: React.FC<{
  value: number
  notation?: Notation
  currencySign?: 'standard' | 'accounting'
  fractionDigits?: number
}> = ({ value, notation, fractionDigits, currencySign = 'accounting' }) => {
  const decimals = fractionDigits != null ? fractionDigits : value === 0 ? 0 : 2
  return (
    <FormattedNumber
      value={value}
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      currency="USD"
      currencySign={currencySign}
      notation={notation}
      maximumFractionDigits={decimals}
      minimumFractionDigits={decimals}
    />
  )
}
