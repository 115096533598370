import { push } from 'connected-react-router'
import { call, put, takeLatest } from 'typed-redux-saga'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import { getRockefellerApiOptions } from '../../../../../store/shared/sagas'
import { fetchLookUpValue } from '../api'
import {
  ILookUpValueResponse,
  IAbaResponse,
  IWirePaymentResponse,
  ICreateWireInstructionResponse
} from '../api/types'
import {
  createWireInstructions,
  createWireInstructionsForSecurityService,
  fetchAbaDetails,
  fetchEligibleAccounts,
  fetchWireInstruction,
  fetchWirePayments
} from '../api/WireService'
import { formatWireInstructionResponse } from '../utilities/Utilities'
import {
  LookUpValueActions,
  CreateWireInstructionActions,
  WireInstructionNavigationActions,
  WireInstructionNavigationActionType,
  FetchAbaDetailActions,
  FetchEligibleAcctsActions,
  FetchWireInstructionActions,
  FetchWirePaymentActions,
  CreateWireInstructionForSecurityActions
} from './actions'

function* handleLookUpValueRequest(
  action: ReturnType<typeof LookUpValueActions.request>
) {
  try {
    const orgid = action.payload as string
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: ILookUpValueResponse[] = yield call(() =>
      fetchLookUpValue('PAYTERM', orgid, options)
    )
    yield put(LookUpValueActions.success(data))
  } catch (e: any) {
    yield put(LookUpValueActions.failure(e))
  }
}

function* handleCreateWireInstruction(
  action: ReturnType<typeof CreateWireInstructionActions.request>
) {
  try {
    const instruction = action.payload
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    yield call(() => createWireInstructions(instruction, options))
    yield put(
      WireInstructionNavigationActions.navigateToVendor(
        instruction?.OrgId ? instruction.OrgId : ''
      )
    )
  } catch (e: any) {
    yield put(CreateWireInstructionActions.failure(e))
  }
}

function* handleCreateWireInstructionForSecurity(
  action: ReturnType<typeof CreateWireInstructionForSecurityActions.request>
) {
  try {
    const instruction = action.payload
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: ICreateWireInstructionResponse = yield call(() =>
      createWireInstructionsForSecurityService(instruction, options)
    )

    if (data?.value?.[0]?.status?.Code === '0') {
      yield put(CreateWireInstructionForSecurityActions.success())
    } else {
      yield put(
        CreateWireInstructionForSecurityActions.failure(
          new Error('Api has sent non zero code')
        )
      )
    }
  } catch (e: any) {
    yield put(CreateWireInstructionForSecurityActions.failure(e))
  }
}

function* handleNavigateToVendor(action: WireInstructionNavigationActionType) {
  const orgid = action.payload as string
  yield put(push(`/payments/billpay/dashboard/vendors/${orgid}`))
}

function* handleFetchAbaDetails(
  action: ReturnType<typeof FetchAbaDetailActions.request>
) {
  try {
    const abaNumber = action.payload.routingNumber as string
    const country = action.payload.country
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response: IAbaResponse | undefined = yield call(() =>
      fetchAbaDetails(abaNumber, country, options)
    )
    if (response?.status?.Code === '000000') {
      yield put(FetchAbaDetailActions.success(response.ABANumbers?.[0]))
    } else {
      throw new Error('Failed to fetch ABA Number details')
    }
  } catch (e: any) {
    yield put(FetchAbaDetailActions.failure(e))
  }
}

function* handleFetchEligibleAccts(
  action: ReturnType<typeof FetchEligibleAcctsActions.request>
) {
  try {
    const orgid = action.payload
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(fetchEligibleAccounts, orgid, options)
    yield put(FetchEligibleAcctsActions.success(data))
  } catch (e: any) {
    yield put(FetchEligibleAcctsActions.failure(e))
  }
}

function* handleFetchWireInstruction(
  action: ReturnType<typeof FetchWireInstructionActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)

    //Fetch eligible account
    try {
      const eligibleAcct = yield* call(
        fetchEligibleAccounts,
        action.payload.orgid,
        options
      )

      yield put(FetchEligibleAcctsActions.success(eligibleAcct))
    } catch (e: any) {
      yield put(FetchEligibleAcctsActions.failure(e))
    }

    //Fetch Lookup values
    try {
      const lookupRes: ILookUpValueResponse[] = yield call(() =>
        fetchLookUpValue('PAYTERM', action.payload.orgid, options)
      )
      yield put(LookUpValueActions.success(lookupRes))
    } catch (e: any) {
      yield put(LookUpValueActions.failure(e))
    }

    const data = yield* call(
      fetchWireInstruction,
      action.payload.id,
      action.payload.orgid,
      options
    )

    yield put(
      FetchWireInstructionActions.success(
        formatWireInstructionResponse(data?.[0], action.payload.orgid)
      )
    )
  } catch (e: any) {
    yield put(FetchWireInstructionActions.failure(e))
  }
}

function* handleFetchWirePayments(
  action: ReturnType<typeof FetchWirePaymentActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IWirePaymentResponse = yield call(() =>
      fetchWirePayments(action.payload.fromDate, action.payload.toDate, options)
    )

    if (data?.response_status === 0) {
      yield put(
        FetchWirePaymentActions.success(
          data?.response_data ? data.response_data : []
        )
      )
    } else {
      yield put(
        FetchWirePaymentActions.failure(
          new Error('Unable to fetch wire payments')
        )
      )
    }
  } catch (e: any) {
    yield put(FetchWirePaymentActions.failure(e))
  }
}

export const sagas = [
  () => takeLatest(LookUpValueActions.request, handleLookUpValueRequest),
  () =>
    takeLatest(
      CreateWireInstructionActions.request,
      handleCreateWireInstruction
    ),
  () =>
    takeLatest(
      WireInstructionNavigationActions.navigateToVendor,
      handleNavigateToVendor
    ),
  () => takeLatest(FetchAbaDetailActions.request, handleFetchAbaDetails),
  () => takeLatest(FetchEligibleAcctsActions.request, handleFetchEligibleAccts),
  () =>
    takeLatest(FetchWireInstructionActions.request, handleFetchWireInstruction),
  () => takeLatest(FetchWirePaymentActions.request, handleFetchWirePayments),
  () =>
    takeLatest(
      CreateWireInstructionForSecurityActions.request,
      handleCreateWireInstructionForSecurity
    )
]
