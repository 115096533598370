import { PrimaryButton, Stack, Text } from '@fluentui/react'
import { useGetCurrentSystemuserQuery } from 'store/api/dynamics'
import { useDomainStore } from '../../store/domain'
import { RepSelectorPanel } from './RepSelectorPanel'
import { useRepSelectorPanelStore } from './store/repSelectorPanel'

export const RepSelectorButton: React.FC = () => {
  const { open } = useRepSelectorPanelStore()
  const { domain, selectedAccountReps, isFullDomainSelected } = useDomainStore()
  const { data: systemuser } = useGetCurrentSystemuserQuery()
  return (
    <>
      <PrimaryButton
        onClick={open}
        styles={{
          root: {
            width: 'auto',
            padding: 0,
            paddingLeft: 5,
            paddingRight: 5
          }
        }}
      >
        <Stack
          verticalFill={true}
          horizontal={true}
          tokens={{ childrenGap: 5 }}
        >
          <Stack.Item align="center">
            <Stack>
              <Stack.Item align="end">
                <Text block={true} variant="small" nowrap={true}>
                  {isFullDomainSelected ? (
                    <>All Reps Selected</>
                  ) : (
                    <>
                      {selectedAccountReps?.length || 0} of{' '}
                      {domain?.length || 0} Reps Selected
                    </>
                  )}
                </Text>
              </Stack.Item>
              <Stack.Item align="end">
                <Text
                  block={true}
                  variant="xSmall"
                  nowrap={true}
                  styles={{ root: { textTransform: 'uppercase' } }}
                >
                  {systemuser?.businessunitid?.name}
                </Text>
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </PrimaryButton>
      <RepSelectorPanel />
    </>
  )
}
