import { DefaultButton, PrimaryButton, Stack } from '@fluentui/react'
import React, { useEffect, useCallback, useState } from 'react'
import { IListsFilter } from '../../../contracts/IListsFilter'
import { IListsFilterEditProps } from '../contracts/IListsFilterEditProps'
import { ListsFilterEdit } from './ListsFilterEdit'

export interface IListsFilterEditWithApplyCancel {
  filter: IListsFilter
  onApply: (newFilter: IListsFilter) => void
  onCancel: () => void
  customListFilterEdit?: React.FC<IListsFilterEditProps>
}

export const ListsFilterEditWithApplyCancel: React.FC<
  IListsFilterEditWithApplyCancel
> = ({ filter, onApply, onCancel, customListFilterEdit }) => {
  const CustomListFilterEdit = customListFilterEdit
  const [modifiedFilter, setModifiedFilter] = useState<
    IListsFilter | undefined
  >()

  useEffect(() => {
    setModifiedFilter({ ...filter })
  }, [filter])

  const onChangeLocal = useCallback((newFilter?: IListsFilter) => {
    setModifiedFilter(newFilter ? { ...newFilter } : undefined)
  }, [])

  const onApplyClicked = useCallback(
    () => modifiedFilter?.hasValue && onApply(modifiedFilter),
    [modifiedFilter, onApply]
  )

  const onKeyPress = useCallback(
    (event?: React.KeyboardEvent<HTMLElement>) => {
      if (event?.key !== 'Enter' || !modifiedFilter?.hasValue) {
        return
      }

      onApply(modifiedFilter)
    },
    [modifiedFilter, onApply]
  )

  if (!modifiedFilter) {
    return null
  }

  return (
    <Stack tokens={{ childrenGap: 20 }} onKeyPress={onKeyPress}>
      <Stack.Item>
        {CustomListFilterEdit ? (
          <CustomListFilterEdit
            filter={modifiedFilter}
            onChange={onChangeLocal}
          />
        ) : (
          <ListsFilterEdit filter={modifiedFilter} onChange={onChangeLocal} />
        )}
      </Stack.Item>
      <Stack
        horizontal={true}
        tokens={{ childrenGap: 5 }}
        horizontalAlign="end"
      >
        <PrimaryButton
          onClick={onApplyClicked}
          disabled={!modifiedFilter.hasValue}
        >
          Apply
        </PrimaryButton>
        <DefaultButton onClick={onCancel}>Cancel</DefaultButton>
      </Stack>
    </Stack>
  )
}
