import { IOdataRequest } from 'api/odata.types'
import { escapeAndEncodeQuery, tokenizeQuery } from 'api/search'
import { cloneDeep, flow, trim } from 'lodash/fp'
import { combineReducers } from 'redux'
import { StrictEffect } from 'redux-saga/effects'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { isNotNullOrEmpty, isNotNullOrUndefined } from 'shared/guards'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { IOdataFacetResult } from '../../../store/alerts.types'
import {
  IOdataPropertyFilter,
  OdataFilterLogicalOperatorEnum,
  OdataPropertyFilterGroup,
  constructFilterQuery
} from '../../../store/odata'
import { IOdataListDataState } from '../common/IOdataListDataState'
import { IOdataListUiState } from '../common/IOdataListUiState'
import { convertToOdataFilter } from '../common/service'
import { createOdataListDataStore } from './odataListDataStore'
import {
  IOdataListFacetState,
  createOdataListFacetStore
} from './odataListFacetStore'
import { createOdataListUiStore } from './odataListUiStore'

export interface IODataListState<T> {
  data: IOdataListDataState<T>
  ui: IOdataListUiState
  facets: IOdataListFacetState
}

export type IOdataListFacetResult<T> = { [K in keyof T]?: string } & {
  count: number
}

export interface ICreateOdataListWithFacetsStoreOptions<T, U> {
  prefix: string
  initialState: IODataListState<T>
  rootSelector: (state: U) => IODataListState<T> | undefined
  getOdataResults: (
    request: IOdataRequest,
    chunks?: IOdataResult<T>[]
  ) => Generator<StrictEffect, IOdataResult<T>, unknown>
}

export const createOdataListWithFacetsStore = <T, U>(
  options: ICreateOdataListWithFacetsStoreOptions<T, U>
) => {
  const { prefix, initialState, rootSelector, getOdataResults } = options

  const dataStore = createOdataListDataStore(
    prefix,
    getOdataResults,
    flow(rootSelector, (x) => x?.data)
  )

  const uiStore = createOdataListUiStore({
    prefix,
    initialState: initialState.ui,
    rootSelector: flow(rootSelector, (x) => x?.ui),
    dataStore
  })

  const facetStore = createOdataListFacetStore({
    prefix,
    rootSelector: flow(rootSelector, (x) => x?.facets)
  })

  const {
    reducer: dataReducer,
    selectors: dataSelectors,
    actions: dataActions,
    sagas: dataSagas
  } = dataStore

  const {
    reducer: uiReducer,
    selectors: uiSelectors,
    actions: uiActions,
    sagas: uiSagas
  } = uiStore

  const {
    reducer: facetReducer,
    selectors: facetSelectors,
    actions: facetActions
  } = facetStore

  const actions = { dataActions, uiActions, facetActions }

  const reducer = combineReducers({
    data: dataReducer,
    ui: uiReducer,
    facets: facetReducer
  })

  const selectors = { dataSelectors, uiSelectors, facetSelectors }

  const onFacetRequest = function* (
    action: ReturnType<typeof actions.facetActions.request>
  ) {
    const filters = yield* select(uiSelectors.getFilters)
    const columns = yield* select(uiSelectors.getColumns)
    const filtersClone = cloneDeep(filters)
    filtersClone && delete filtersClone[action.payload.id]

    const searchableFilters = Object.values(filtersClone ?? {}).filter(
      (filter) => filter.type.includes('search') && filter.hasValue
    )

    const searchableColumns = (columns ?? [])
      .filter((column) => column.searchable)
      .map((x) => x.dataPath)

    const odataFilters = Object.values(filtersClone ?? {})
      .filter(({ hasValue, range }) => hasValue || !!range)
      .map((filter) => {
        return convertToOdataFilter(filter)
      })
      .filter(isNotNullOrUndefined)

    const nonSearchableOdataFilters = odataFilters.filter((filter) => {
      return !(
        (filter as OdataPropertyFilterGroup)[
          OdataFilterLogicalOperatorEnum.and
        ] as IOdataPropertyFilter[]
      )[0].operator.includes('contains')
    })

    const filter = constructFilterQuery(nonSearchableOdataFilters)
    const searchText = yield* select(uiSelectors.getSearchText)

    const requestedProperty = columns?.find(
      (x) => x.name === action.payload.id
    )?.dataPath
    try {
      if (!requestedProperty) {
        throw new Error('Property not found')
      }

      const result = yield* call(getOdataResults, {
        filters: filter.length ? [filter] : undefined,
        search:
          searchText && searchableColumns.length
            ? searchableColumns
                .map((x) => {
                  const searchString = tokenizeQuery(searchText)
                    .map(escapeAndEncodeQuery)
                    .map(trim)
                    .map((x) => `${x}*`)
                    .join(' AND ')

                  return `${x}:(${searchString})`
                })
                .join(' OR ')
            : '',
        searchFields: searchableFilters
          .filter((x) => isNotNullOrEmpty(x.value as string))
          .map((x) => {
            const searchString =
              x.filterType === 'search'
                ? tokenizeQuery(x.value as string)
                    .map(escapeAndEncodeQuery)
                    .map(trim)
                    .map((x) => `${x}*`)
                    .join(' AND ')
                : (x.value as string)

            return `${x.id}:(${searchString})`
          }),
        top: 0,
        facets: [`${action.payload.id},count:999`]
      })
      if (!result?.value) {
        throw new Error('No facets returned from service')
      }

      const facetResults =
        (result as IOdataFacetResult)?.['@search.facets'] ?? {}

      yield put(facetActions.complete(facetResults))
    } catch (e: any) {
      yield put(facetActions.error(e))
    }
  }

  const sagas = [
    ...dataSagas,
    ...uiSagas,
    () => takeLatest(facetActions.request, onFacetRequest),
    () =>
      takeLatest(
        [
          uiActions.updateFilters,
          uiActions.updateSearchText,
          uiActions.resetFilters
        ],
        function* () {
          yield put(facetActions.reset())
        }
      )
  ]

  return { actions, reducer, selectors, sagas }
}
