import { combineReducers } from 'redux'
import {
  editHouseholdNameReducer,
  editHouseholdNameSagas
} from './EditHouseholdName'
import { editHouseholdTagsReducer } from './EditHouseholdTags'
import {
  changeRequestEditReducer,
  changeRequestEditSagas
} from './HouseholdChangeRequest'
import { householdDetailReducer, householdDetailSagas } from './HouseholdDetail'
import {
  householdFeeFeatureReducer,
  householdFeeFeatureSagas
} from './HouseholdFee'

export const householdFeatureFeaturesReducer = combineReducers({
  householdFee: householdFeeFeatureReducer,
  editHouseholdName: editHouseholdNameReducer,
  editHouseholdTags: editHouseholdTagsReducer,
  householdDetail: householdDetailReducer,
  changeRequestEdit: changeRequestEditReducer
})

export const householdFeatureFeaturesSagas = [
  ...householdFeeFeatureSagas,
  ...editHouseholdNameSagas,
  ...householdDetailSagas,
  ...changeRequestEditSagas
]
