import { Row } from '@tanstack/react-table'
import { AnnuityPPLICombined } from '../../shared/types/annuityPPLICombined'
import { TableRow } from './TableRow'

export const TableBody: React.FC<{
  rows: Row<AnnuityPPLICombined>[]
  groupingLength: number
}> = ({ rows, groupingLength }) => {
  return (
    <tbody>
      {rows.map((row) => (
        <TableRow key={row.id} row={row} groupingLength={groupingLength} />
      ))}
    </tbody>
  )
}
