import { MessageBarType } from '@fluentui/react'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import { pushNotification } from 'features/Notifications'
import { downloadUrlAsFile } from 'shared/downloads'
import { isNotNullOrUndefined } from 'shared/guards'
import {
  AppState,
  createAsyncReducer,
  createAsyncSelectors
} from 'store/shared'
import { getAllPagedOdataApiResults } from 'store/shared/sagas/odata'
import { call, select, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { fetchTransfers, selectors as transfersSelectors } from '../transfers'
import { TransfersExportWorker } from './worker'

export const transfersExportActions = createAsyncAction(
  '@modules/@advisory/@modules/@ai/@modules/@Dashboard/@features/@AiTransfers/EXPORT_REQUEST',
  '@modules/@advisory/@modules/@ai/@modules/@Dashboard/@features/@AiTransfers/EXPORT_SUCCESS',
  '@modules/@advisory/@modules/@ai/@modules/@Dashboard/@features/@AiTransfers/EXPORT_FAILURE'
)<undefined, undefined, Error>()

export const transfersExportReducer = createAsyncReducer(transfersExportActions)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.ai.modules.dashboard.features.aiTransfersExport

export const {
  getError: getTransfersError,
  getIsLoading: getIsTransfersExportLoading
} = createAsyncSelectors(rootSelector)

const onExport = function* () {
  try {
    const request = yield* select(
      transfersSelectors.uiSelectors.getOdataRequest
    )

    const results = yield* call(() =>
      getAllPagedOdataApiResults(request, fetchTransfers)
    )

    const resultsMappedFiltered = results
      ?.flatMap((x) => x.result.value)
      ?.filter(isNotNullOrUndefined)

    const columnNames = yield* select(
      transfersSelectors.uiSelectors.getSelectedColumns
    )

    const worker = new Worker(new URL('./worker.ts', import.meta.url))

    const { createAndDownloadTransfersExport } =
      wrap<TransfersExportWorker>(worker)

    const result = yield* call(
      createAndDownloadTransfersExport,
      resultsMappedFiltered,
      columnNames || []
    )

    const filename = `Transfers ${format(new Date(), 'MM-dd-yyyy')}.xlsx`
    downloadUrlAsFile(result, filename)
    yield put(transfersExportActions.success())
  } catch (e: unknown) {
    yield put(transfersExportActions.failure(e as Error))
  }
}

const onFailure = function* (
  action: ReturnType<typeof transfersExportActions.failure>
) {
  yield call(pushNotification, {
    message: `Export failed: ${action.payload.message}`,
    type: MessageBarType.error
  })
}

export const transfersExportSagas = [
  () => takeLatest(transfersExportActions.request, onExport),
  () => takeLatest(transfersExportActions.failure, onFailure)
]
