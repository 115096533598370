import { DefaultButton, Stack, Text } from '@fluentui/react'
import { push } from 'connected-react-router'
import { FeedbackButtonContainer } from 'features/Header/containers/FeedbackButton'
import { SettingsButtonContainer } from 'features/Header/containers/SettingsButton'
import { UserButtonContainer } from 'features/Header/containers/UserButton'
import { SearchContainer } from 'features/Search'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getThemeType } from 'store/ui/selectors'
import blackLogo from '../../../../images/RockefellerLogoBlackBG.svg'
import whiteLogo from '../../../../images/RockefellerLogowhitBG.svg'
import { HeaderWrapper } from './HeaderWrapper'

export interface IHeaderContainerProps {
  title?: string
  onGoHome?: () => void
}

export const HeaderContainer: React.FC<IHeaderContainerProps> = ({
  title,
  onGoHome
}) => {
  const dispatch = useDispatch()
  const onHomeClicked = useCallback(
    () => (onGoHome ? onGoHome() : dispatch(push('/'))),
    [dispatch, onGoHome]
  )
  const themeType = useSelector(getThemeType)

  return (
    <HeaderWrapper>
      <Stack
        horizontal={true}
        horizontalAlign="space-between"
        verticalFill={true}
        verticalAlign="center"
        tokens={{ childrenGap: 20 }}
      >
        <Stack.Item align="center">
          <Stack horizontal={true}>
            <img
              style={{ width: '324px', height: '40px', marginLeft: '15px' }}
              src={themeType === 'light' ? whiteLogo : blackLogo}
            />
            <div
              style={{
                position: 'relative',
                border: '.5px solid #E6E6E6',
                margin: '5px 10px',
                opacity: themeType === 'light' ? '1' : '0.4'
              }}
            />
            <DefaultButton
              onClick={onHomeClicked}
              styles={{
                root: {
                  padding: 5,
                  border: 0,
                  backgroundColor: 'transparent'
                }
              }}
            >
              <Text nowrap={true}>
                <b>{title || 'BOOK OF BUSINESS'}</b>
              </Text>
            </DefaultButton>
          </Stack>
        </Stack.Item>

        <Stack.Item grow={1} align="center">
          <SearchContainer showHouseholds={true} />
        </Stack.Item>

        <Stack.Item>
          <Stack horizontal={true}>
            <FeedbackButtonContainer />
            <SettingsButtonContainer />
            <UserButtonContainer />
          </Stack>
        </Stack.Item>
      </Stack>
    </HeaderWrapper>
  )
}
