import { combineReducers } from 'redux'
import { IAccountsModuleDefinition } from '../shared/IAccountsModuleDefinition'
import MarginRateRequests, {
  marginRateRequestsModuleReducer,
  marginRateRequestsModuleSagas
} from './MarginRateRequests'

export default [MarginRateRequests] as IAccountsModuleDefinition[]

export const accountsModulesReducer = combineReducers({
  marginRateRequests: marginRateRequestsModuleReducer
})

export const accountsModulesSagas = [...marginRateRequestsModuleSagas]
