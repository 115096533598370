import { combineReducers } from 'redux'
import {
  boardMemberAccountsFetchReducer,
  boardMemberAccountsFetchSagas
} from './accountsFetch'

export const boardMemberAccountModuleReducer = combineReducers({
  accountsFetch: boardMemberAccountsFetchReducer
})

export const boardMemberAccountModuleSagas = [...boardMemberAccountsFetchSagas]
