import { FC, useCallback, useMemo } from 'react'
import { useClientDashboardTilePreferences } from '../../hooks/useClientDashboardPreferences'
import { useHandleViewTypeChange } from '../../hooks/useHandleViewTypeChange'
import { useInvestmentsTableStore } from '../../modules/Investments/features/InvestmentsTable'
import { ClientDashboardTiles } from '../../shared/types'
import { PositionCategoryType } from '../../store/holdingsApi'
import { useRdot360Context } from '../../store/rdot360Context'
import { useGetPositionByCategoryQueryForSelectedAccounts } from '../../store/rdot360Context/useRdot360HoldingsContext'
import { IRadioButtonGroupOption, RadioButtonGroup } from '../shared'
import { SnackBar } from '../shared/Snackbar'
import TileHeading, { BaseHeaderProps, ViewType } from '../TileHeading'
import { TileLoadingOverlay } from '../TileLoadingOverlay'
import { AssetSummaryTable } from './AssetAllocationSummaryTable'
import { AssetsAllocationChart } from './AssetsAllocationChart'

const assetTypes: IRadioButtonGroupOption[] = [
  { key: 'L1', text: 'Asset Class' },
  { key: 'S', text: 'Sector Class' },
  { key: 'P', text: 'Product Type' }
]

const tileName = ClientDashboardTiles.assetsTile

const AssetsAllocation: FC<BaseHeaderProps> = ({
  onExpand,
  isCobSupported
}) => {
  const { asOfDate } = useRdot360Context()
  const { handleViewTypeChange, viewType } = useHandleViewTypeChange(tileName)
  const { setTilePreferences, tilePreferences } =
    useClientDashboardTilePreferences(tileName)
  const { setInvestmentsViewByCategory } = useInvestmentsTableStore()

  const type = useMemo<PositionCategoryType>(
    () => tilePreferences?.positionCategoryType ?? 'L1',
    [tilePreferences]
  )

  const {
    data: assetAllocationResponseData,
    isUninitialized: isAssetUninitialized,
    isFetching: isAssetFetching,
    isLoading: isAssetLoading,
    error: isAssetError
  } = useGetPositionByCategoryQueryForSelectedAccounts(type)

  const data = useMemo(
    () =>
      isAssetFetching || isAssetError || !assetAllocationResponseData
        ? undefined
        : assetAllocationResponseData,
    [isAssetFetching, isAssetError, assetAllocationResponseData]
  )

  const handleExpand = useCallback(() => {
    setInvestmentsViewByCategory(type)
    onExpand?.()
  }, [onExpand, setInvestmentsViewByCategory, type])

  const onAssetTypeChange = useCallback(
    (newValue?: string) => {
      const selectedType = newValue || 'L1'
      if (selectedType === 'P') {
        setTilePreferences({ positionCategoryType: 'P' })
      } else if (selectedType === 'S') {
        setTilePreferences({ positionCategoryType: 'S' })
      } else {
        setTilePreferences({ positionCategoryType: 'L1' })
      }
    },
    [setTilePreferences]
  )

  const isError = useMemo(() => !!isAssetError, [isAssetError])
  const errorMessage =
    (isAssetError as Error)?.message || 'An unknown Error Occurred'
  const sortedData = useMemo(() => {
    const sortedData = data
      ?.slice()
      .sort((a: any, b: any) => b?.mktval - a?.mktval)
    return sortedData
  }, [data])

  return (
    <div css={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div>
        <TileHeading
          title="Asset Allocation"
          viewType={viewType}
          onExpand={handleExpand}
          onViewtypeChange={handleViewTypeChange}
          graphIconType="pieIcon"
          isCobSupported={isCobSupported}
        />
      </div>
      {isError && (
        <div css={{ paddingBottom: '5px' }}>
          <SnackBar message={errorMessage} type="Failure" />
        </div>
      )}
      <div
        css={{
          fontSize: '14px',
          lineHeight: '19px',
          marginTop: '10px',
          marginBottom: '5px'
        }}
      >
        <RadioButtonGroup
          name="assetType"
          align="horizontal"
          options={assetTypes}
          value={type}
          onChange={onAssetTypeChange}
        />
      </div>

      <div style={{ flexGrow: 1, minHeight: 0 }}>
        {viewType === ViewType.pie && (
          <AssetsAllocationChart data={data} selectedType={type} />
        )}
        {viewType === ViewType.table && (
          <AssetSummaryTable
            data={sortedData}
            selectedType={type}
            isLoading={isAssetFetching}
            isUninitialized={isAssetUninitialized}
          />
        )}
      </div>
      {asOfDate && (
        <div
          css={{
            fontSize: '10px',
            fontWeight: 300,
            marginTop: 6
          }}
        >
          Please note the displayed asset mix for a past close of business
          report date might be based on asset classification that may have been
          revised.
        </div>
      )}
      {(isAssetLoading || isAssetFetching) && <TileLoadingOverlay />}
    </div>
  )
}

export default AssetsAllocation
