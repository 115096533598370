import { lazy } from 'react'
import { combineReducers } from 'redux'
import { IModuleDefinition } from '../../../types'
import { accountsModulesReducer, accountsModulesSagas } from './modules'
const AccountsContainer = lazy(() => import('./AccountsContainer'))
const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/accounts/*',
    private: true,
    component: AccountsContainer
  },
  name: 'Accounts'
}

export default moduleDefinition

export const accountsModuleReducer = combineReducers({
  modules: accountsModulesReducer
})

export const accountsModuleSagas = [...accountsModulesSagas]
