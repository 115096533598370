import { combineReducers } from 'redux'
import {
  householdTotalFetchReducer,
  householdTotalFetchSagas
} from './HouseholdTotalFetch'

export const dashboardFeaturesReducer = combineReducers({
  householdTotalFetch: householdTotalFetchReducer
})

export const dashboardFeaturesSagas = [...householdTotalFetchSagas]
