import { css } from '@emotion/react'
import { addMonths, endOfMonth, setDate } from 'date-fns'
import { FC, useCallback, useMemo, useState } from 'react'
import TileHeading, {
  BaseHeaderProps,
  ViewType
} from '../../../components/TileHeading'
import { ChartDropDown } from '../../../features/Revenue/ChartDropDown'
import { useRevenueChartDateStore } from '../../../features/Revenue/revenueChartDateStore'
import { useRdot360Preferences } from '../../../hooks'
import { Summary } from './RevenueSummary'
import { RevenueSummaryChart } from './RevenueSummaryChart'
import { RevenueTable } from './RevenueTable'

const classes = {
  tabs: css({
    padding: '5px 0 5px 0',

    border: 'none',
    backgroundColor: 'white',
    cursor: 'pointer'
  })
}

type ActivityTableType = 'annual' | 'monthly' | 'category'

function getDates(months: number) {
  const today = new Date()
  const startDate = setDate(addMonths(today, -months), 1)
  return { startDate: startDate, endDate: today }
}
export const Revenue: FC<BaseHeaderProps> = ({ onExpand }) => {
  const { customDateRange, dateRange: dateRangeStore } =
    useRevenueChartDateStore()

  const [chartStartDate, chartEndDate] = useMemo(() => {
    if (dateRangeStore === 'Prior 12 Months') {
      return [getDates(11).startDate, new Date()]
    } else if (
      dateRangeStore === 'Custom' &&
      customDateRange !== undefined &&
      customDateRange[0] !== undefined &&
      customDateRange[1] !== undefined
    ) {
      return [
        setDate(customDateRange[0], 1),
        setDate(customDateRange[1], endOfMonth(customDateRange[1]).getDate())
      ]
    } else if (
      dateRangeStore !== 'Custom' &&
      dateRangeStore !== 'Prior 12 Months'
    ) {
      return [
        new Date(`${dateRangeStore}/01/01`),
        new Date(`${dateRangeStore}/12/31`)
      ]
    } else {
      return [getDates(12).startDate, getDates(12).endDate]
    }
  }, [customDateRange, dateRangeStore])

  const viewTypePreference = 'revenueTile'
  const { getPreference, setPreference } = useRdot360Preferences<{
    [key: string]: ViewType
  }>(viewTypePreference, ViewType.table)
  const handleViewTypeChange = useCallback(
    (value: ViewType) => {
      setPreference({ [viewTypePreference]: value })
      if (value === ViewType.table) {
        setActivityTableType('category')
      }
    },
    [setPreference]
  )
  const viewType = getPreference(viewTypePreference)
  const [activityTableType, setActivityTableType] =
    useState<ActivityTableType>('category')

  return (
    <div
      css={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <TileHeading
        title={'Revenue'}
        viewType={viewType}
        onViewtypeChange={handleViewTypeChange}
        graphIconType="chart"
        onExpand={onExpand}
      />
      <div
        css={{
          paddingBottom: '10px',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'end',
          rowGap: '5px'
        }}
      >
        {viewType === ViewType.table ? (
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'start',
              minHeight: viewType === ViewType.table ? '34px' : '0px'
            }}
          >
            <button
              css={[
                classes.tabs,
                {
                  borderBottomStyle:
                    activityTableType === 'category' ? 'solid' : 'none',
                  width: '125px'
                }
              ]}
              onClick={() => setActivityTableType('category')}
            >
              Revenue Category
            </button>
            <button
              css={[
                classes.tabs,
                {
                  borderBottomStyle:
                    activityTableType === 'annual' ? 'solid' : 'none',
                  width: '115px'
                }
              ]}
              onClick={() => setActivityTableType('annual')}
            >
              Annual Summary
            </button>
            <button
              css={[
                classes.tabs,
                {
                  borderBottomStyle:
                    activityTableType === 'monthly' ? 'solid' : 'none',
                  width: '120px'
                }
              ]}
              onClick={() => setActivityTableType('monthly')}
            >
              Monthly Summary
            </button>
          </div>
        ) : (
          <div />
        )}
        <div>
          {activityTableType === 'category' && viewType === ViewType.table ? (
            <div
              css={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <ChartDropDown showLabel={false} />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {activityTableType === 'annual' && viewType === ViewType.table && (
        <Summary isAnnual={true} />
      )}
      {activityTableType === 'monthly' && viewType === ViewType.table && (
        <Summary isAnnual={false} />
      )}
      {activityTableType === 'category' && viewType === ViewType.table && (
        <div css={{ flexGrow: 1, minHeight: 0 }}>
          <RevenueTable
            startDate={chartStartDate ?? getDates(12).startDate}
            endDate={chartEndDate ?? new Date()}
          />
        </div>
      )}

      {viewType === ViewType.graph && (
        <>
          <div
            css={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row-reverse',
              paddingBottom: '10px'
            }}
          >
            <div
              css={{
                width: 'min-content'
              }}
            >
              <ChartDropDown showLabel={false} />
            </div>
          </div>
          <div style={{ flexGrow: 1 }}>
            <RevenueSummaryChart
              startDate={chartStartDate ?? getDates(12).startDate}
              endDate={chartEndDate ?? new Date()}
            />
          </div>
        </>
      )}
      <div
        css={{
          fontSize: 10,
          fontWeight: 300,
          display: 'flex',
          marginTop: 4
        }}
      >
        Rockefeller Accounts at NFS only.
      </div>
    </div>
  )
}
