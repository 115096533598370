import { combineReducers } from 'redux'
import { createEditInteractionPanelReducer } from './createEditInteractionPanel'
import { emailPhonePanelReducer } from './emailPhonePanel'
import { interactionSettingsCalloutReducer } from './interactionSettingsCallout'
import { interactionsPanelReducer } from './interactionsPanel'

export const interactionsReducer = combineReducers({
  interactionsPanel: interactionsPanelReducer,
  createEditInteractionPanel: createEditInteractionPanelReducer,
  emailPhonePanel: emailPhonePanelReducer,
  interactionSettingsCallout: interactionSettingsCalloutReducer
})
