import { MessageBarType } from '@fluentui/react'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import { pushNotification } from 'features/Notifications'
import { downloadUrlAsFile } from 'shared/downloads'
import { isNotNullOrUndefined } from 'shared/guards'
import {
  AppState,
  createAsyncReducer,
  createAsyncSelectors
} from 'store/shared'
import { getAllPagedOdataApiResults } from 'store/shared/sagas/odata'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { RetirementExportWorker } from '../../export/worker'
import { fetchRetirementAssets } from '../../shared/retirementService'
import { selectors } from './store'

export const retirementHoldingsExportActions = createAsyncAction(
  '@modules/@advisory/@modules/@retirement/@holdings/EXPORT_REQUEST',
  '@modules/@advisory/@modules/@retirement/@holdings/EXPORT_SUCCESS',
  '@modules/@advisory/@modules/@retirement/@holdings/EXPORT_FAILURE'
)<undefined, undefined, Error>()

export const retirementHoldingsExportReducer = createAsyncReducer(
  retirementHoldingsExportActions
)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.retirement.holdingsExport

export const retirementHoldingsExportSelectors =
  createAsyncSelectors(rootSelector)

const onExport = function* () {
  try {
    const request = yield* select(selectors.uiSelectors.getOdataRequest)
    const results = yield* call(() =>
      getAllPagedOdataApiResults(request, fetchRetirementAssets)
    )
    const resultsMappedFiltered = results
      ?.flatMap((x) => x.result.value)
      ?.filter(isNotNullOrUndefined)
    const columnNames = yield* select(selectors.uiSelectors.getSelectedColumns)

    const worker = new Worker(
      new URL('../../export/worker.ts', import.meta.url)
    )
    const { createRetirementHoldingsExport } =
      wrap<RetirementExportWorker>(worker)

    const result = yield* call(
      createRetirementHoldingsExport,
      resultsMappedFiltered,
      columnNames || []
    )
    const filename = `Corporate Retirement Underlying Holdings ${format(
      new Date(),
      'MM-dd-yyyy'
    )}.xlsx`

    downloadUrlAsFile(result, filename)

    yield put(retirementHoldingsExportActions.success())
  } catch (e: unknown) {
    yield put(retirementHoldingsExportActions.failure(e as Error))
  }
}

const onFailure = function* (
  action: ReturnType<typeof retirementHoldingsExportActions.failure>
) {
  yield call(pushNotification, {
    message: `Underlying Holdings export failed: ${action.payload.message}`,
    type: MessageBarType.error
  })
}

export const retirementHoldingsExportSagas = [
  () => takeLatest(retirementHoldingsExportActions.request, onExport),
  () => takeLatest(retirementHoldingsExportActions.failure, onFailure)
]
