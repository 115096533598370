import {
  DirectionalHint,
  HoverCard,
  HoverCardType,
  IPlainCardProps,
  Stack,
  Text,
  Separator
} from '@fluentui/react'
import { PropsWithChildren, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingComponent } from '../../shared/components/Loading'
import { ConnectedMaskedText } from '../../shared/components/MaskedText'
import { USDCell } from '../Lists/core/components/USDCell'
import {
  alternateFeesActions,
  getAlternateFeesResult,
  getIsAlternateFeesLoading
} from './store/alternateFees'

export const PendingDebitHoverCard: React.FC<
  PropsWithChildren<{
    id: string
  }>
> = ({ id, children }) => {
  const dispatch = useDispatch()
  const details = useSelector(getAlternateFeesResult)
  const loading = useSelector(getIsAlternateFeesLoading)
  const plainCardProps = useMemo(
    (): IPlainCardProps => ({
      onRenderPlainCard: () => (
        <Stack tokens={{ childrenGap: 5, padding: 20 }}>
          {!loading && (
            <Stack.Item>
              <div style={{ textAlign: 'center' }}>
                <Text styles={{ root: { fontWeight: 'bold' } }}>
                  Alternate Debit Account Summary
                </Text>
              </div>
              <Separator styles={{ root: { padding: 0 } }} />
            </Stack.Item>
          )}
          {loading && <LoadingComponent />}
          {!loading && (
            <table
              style={{
                tableLayout: 'fixed',
                width: '450px',
                textAlign: 'right'
              }}
            >
              <thead>
                <tr>
                  <th style={{ textAlign: 'left' }}>Account</th>
                  <th>Managed Assets</th>
                  <th>Client Fee %</th>
                  <th>Client Fee</th>
                </tr>
              </thead>
              <tbody>
                {details?.map((account, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: 'left' }}>
                      <ConnectedMaskedText text={account.id} />
                    </td>
                    <td>
                      <USDCell value={account.AccountKPIs?.AumManaged} />
                    </td>
                    <td>{account.feeSummary?.clientFeePercent}%</td>
                    <td>
                      <USDCell value={account.feeSummary?.clientFee} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Stack>
      ),
      directionalHint: DirectionalHint.rightCenter,
      calloutProps: {
        isBeakVisible: true
      }
    }),
    [details, loading]
  )

  const onCardVisible = useCallback(() => {
    dispatch(alternateFeesActions.request(id))
  }, [dispatch, id])

  return (
    <HoverCard
      plainCardProps={plainCardProps}
      instantOpenOnClick={true}
      type={HoverCardType.plain}
      onCardVisible={onCardVisible}
    >
      {children}
    </HoverCard>
  )
}
