import { css } from '@emotion/react'
import {
  CellContext,
  HeaderContext,
  createColumnHelper
} from '@tanstack/react-table'
import { sum } from 'lodash'
import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { USD } from 'shared/components/Formatting'
import { WidgetTable } from '../../features/WidgetTable/WidgetTable'
import { GetGainLossesIncomeSummaryResponse } from '../../store/types'
import { USDCellWithColor } from '../USDCellWithColor'
import { mapGainLossIncome } from './dataMapper'

export interface GainLossTableTypeList {
  description: string
  longTerm: number
  shortTerm: number
  total: number
}

const classes = {
  right: css({
    textAlign: 'right',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  })
}

export const GainLossSummarytable: FC<{
  data?: GetGainLossesIncomeSummaryResponse
  financialYear: number
  isLoading: boolean
}> = ({ data, financialYear, isLoading }) => {
  const gainLossData = useMemo(() => {
    return mapGainLossIncome(data)
  }, [data])
  const navigate = useNavigate()

  const AmountCellColor = (
    props: CellContext<GainLossTableTypeList, number>
  ) => {
    return (
      <div css={[classes.right]}>
        {props.getValue() != null ? (
          <USDCellWithColor value={props.getValue()} fractionDigit={0} />
        ) : (
          '--'
        )}
      </div>
    )
  }

  const SumFooter: React.FC<HeaderContext<GainLossTableTypeList, unknown>> = ({
    table,
    column
  }) => {
    const total = sum(
      table
        .getPreExpandedRowModel()
        .rows.map(({ getValue }) => getValue<number>(column.id))
    )

    return (
      <div css={[classes.right, { fontWeight: '600' }]}>
        {total != null ? (
          <USD value={total} fractionDigits={0} currencySign={'standard'} />
        ) : (
          '--'
        )}
      </div>
    )
  }

  const columnHelper = createColumnHelper<GainLossTableTypeList>()
  const columns = useMemo(
    () => [
      columnHelper.accessor(({ longTerm }) => longTerm, {
        id: 'longTerm',
        cell: AmountCellColor,
        header: () => <div css={{ textAlign: 'right' }}>Long Term</div>,
        footer: SumFooter,
        size: 75
      }),
      columnHelper.accessor(({ shortTerm }) => shortTerm, {
        id: 'shortTerm',
        cell: AmountCellColor,
        header: () => <div css={{ textAlign: 'right' }}>Short Term</div>,
        footer: SumFooter,
        size: 75
      }),
      columnHelper.accessor(({ total }) => total, {
        id: 'total',
        cell: AmountCellColor,
        header: () => <div css={{ textAlign: 'right' }}>Total</div>,
        footer: SumFooter,
        size: 75
      })
    ],
    [columnHelper]
  )
  const realizedTableColumns = useMemo(
    () => [
      columnHelper.accessor(({ description }) => description, {
        id: 'description',
        cell: (props) => (
          <div
            css={{ color: '#1D679D', cursor: 'pointer' }}
            onClick={() => navigate('../gains-and-losses')}
          >
            {props.getValue()}
          </div>
        ),
        header: () => <div css={{ textAlign: 'left' }}>Description</div>,
        footer: () => (
          <div css={{ fontWeight: '600' }}>{`Net Realized ${
            financialYear === new Date().getFullYear()
              ? '(current year)'
              : `(${financialYear})`
          }`}</div>
        ),
        size: 150
      }),
      ...columns
    ],
    [columnHelper, columns, financialYear, navigate]
  )
  const unRealizedTableColumns = useMemo(
    () => [
      columnHelper.accessor(({ description }) => description, {
        id: 'description',
        cell: (props) => (
          <div
            css={{ color: '#1D679D', cursor: 'pointer' }}
            onClick={() => navigate('../investments')}
          >
            {props.getValue()}
          </div>
        ),
        header: () => <div css={{ textAlign: 'left' }}>Description</div>,
        footer: () => (
          <div css={{ fontWeight: '600' }}>Net Unrealized (cumulative)</div>
        ),
        size: 150
      }),
      ...columns
    ],
    [columnHelper, columns, navigate]
  )
  return (
    <>
      <div
        css={{
          borderBottom: '2px solid',
          borderBottomColor: '#000000'
        }}
      >
        <WidgetTable
          data={gainLossData?.realizedGainTableTypeList || []}
          columns={realizedTableColumns}
          isLoading={isLoading}
          isFooter={true}
        />
      </div>
      <div css={{ marginTop: '15px', borderTop: '2px solid #f0f0f0' }}>
        <WidgetTable
          data={gainLossData?.unrealizedGainTableTypeList || []}
          columns={unRealizedTableColumns}
          isLoading={isLoading}
          hideHeader={true}
          isFooter={true}
        />
      </div>
    </>
  )
}
