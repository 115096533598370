import { css, Theme, useTheme } from '@emotion/react'
import {
  IContextualMenuItem,
  IContextualMenuProps,
  IconButton
} from '@fluentui/react'
import { FC, useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Icon } from '../../../features/Icons/Icon'
import { useRdot360Context } from '../../../store/rdot360Context'
import { CobPill } from '../../CobPill'

export const getClasses = (theme: Theme) => ({
  flexContainer: css(`
    display: flex;
    margin-right: 10px; 
  `),
  alignColumn: css(`
    flex-direction: column
  `),
  navigatorWrapper: css(`
    min-width: 204px;    
    margin-top: 6px;
  `),
  backButton: css(`
    cursor: pointer;
    margin-right: 18px;
    margin-top: 8px;
  `),
  textOverflow: css(`
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    white-space: nowrap;
  `),
  title: css(`
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 20px;
    letter-spacing: 0.166667px;
    font-style: normal;
  `),
  dropdownButton: css(`
    flex-grow: 1;
    .ms-Button {
      float: right;
    }
    .ms-Button-flexContainer {
      .ms-Icon {
        margin: 0;
      }
    }
  `),
  dropdownArrow: css(`
    background-color: transparent;
    padding: 0;
    color: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), ${theme.colors.extraBlue3};
  `),
  separator: css(`
    background-color: gray;
    width: 1px;
    height: 48px;
    margin: 13px 0px 0px 21px;
  `)
})

const cobItems = ['Activity', 'Balances', 'Investments']

const DetailsNavigator: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const classes = useMemo(() => getClasses(theme), [theme])
  const menuItems = useMemo(
    (): IContextualMenuItem[] => [
      {
        key: 'balance',
        text: 'Balances',
        onClick: () => {
          navigate('../balances')
        }
      },
      {
        key: 'investments',
        text: 'Investments',
        onClick: () => {
          navigate('../investments')
        }
      },
      {
        key: 'activity',
        text: 'Activity',
        onClick: () => {
          navigate('../activity')
        }
      },
      {
        key: 'income',
        text: 'Income',
        onClick: () => {
          navigate('../income')
        }
      },
      {
        key: 'gains-and-losses',
        text: 'Realized Gains/Losses',
        onClick: () => {
          navigate('../gains-and-losses')
        }
      },

      {
        key: 'performance',
        text: 'Performance',
        onClick: () => {
          navigate('../performance')
        }
      },
      {
        key: 'revenue',
        text: 'Revenue',
        onClick: () => {
          navigate('../revenue')
        }
      },
      {
        key: 'documents',
        text: 'Documents'
      },
      {
        key: 'collaboration',
        text: 'Collaboration'
      },
      {
        key: 'communications',
        text: 'Communications Center'
      }
    ],
    [navigate]
  )

  const onBackButtonClick = useCallback(() => {
    navigate('..')
  }, [navigate])

  const filteredMenuItems = useMemo(
    () => menuItems.filter((item) => item.onClick),
    [menuItems]
  )
  const filteredMenuProps: IContextualMenuProps = useMemo(
    () => ({
      shouldFocusOnMount: true,
      items: filteredMenuItems
    }),
    [filteredMenuItems]
  )

  const title = useMemo(
    () => menuItems.find((item) => location.pathname.includes(item.key))?.text,
    [location.pathname, menuItems]
  )

  const isCobPage = useMemo(() => cobItems.includes(title || ''), [title])
  const { asOfDate } = useRdot360Context()

  return (
    <div css={classes.flexContainer}>
      <div css={[classes.flexContainer, classes.navigatorWrapper]}>
        <div css={classes.backButton} onClick={onBackButtonClick}>
          <Icon type="ArrowLeft" height={12} width={12} />
        </div>
        <div>
          <div css={[classes.flexContainer, classes.alignColumn]}>
            <div css={classes.textOverflow}>Overview / {title}</div>
            <div css={classes.title}>{title}</div>
          </div>
          {asOfDate && isCobPage && (
            <div
              css={{
                paddingTop: '10px',
                paddingLeft: '5px'
              }}
            >
              <CobPill />
            </div>
          )}
        </div>
        <div css={classes.dropdownButton}>
          <IconButton
            menuProps={filteredMenuProps}
            css={classes.dropdownArrow}
          />
        </div>
      </div>
      <div>
        <hr css={classes.separator} />
      </div>
    </div>
  )
}

export default DetailsNavigator
