import { flow } from 'lodash/fp'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createReducer,
  ActionType,
  PayloadActionCreator,
  EmptyActionCreator,
  PayloadAction
} from 'typesafe-actions'

export interface IAsyncReducerState<TReq, TRes> {
  request?: TReq
  result?: TRes
  error?: Error
  loading?: boolean
}

type StringConstant = string
export interface IAsyncAction<
  T1 extends StringConstant,
  T2 extends StringConstant,
  T3 extends StringConstant,
  TReq,
  TRes
> {
  request: PayloadActionCreator<T1, TReq> | EmptyActionCreator<T1>
  success: PayloadActionCreator<T2, TRes> | EmptyActionCreator<T2>
  failure: PayloadActionCreator<T3, Error>
}

export const createAsyncReducer = <
  T1 extends StringConstant,
  T2 extends StringConstant,
  T3 extends StringConstant,
  TReq,
  TRes
>(
  asyncAction: IAsyncAction<T1, T2, T3, TReq, TRes>,
  initialState?: IAsyncReducerState<TReq, TRes>
) =>
  createReducer<IAsyncReducerState<TReq, TRes>, ActionType<typeof asyncAction>>(
    initialState || {}
  )
    .handleAction<any, ReturnType<typeof asyncAction.request>, any>(
      asyncAction.request,
      (state, action) => ({
        ...state,
        request: (action as PayloadAction<T1, TReq>)?.payload,
        error: undefined,
        loading: true
      })
    )
    .handleAction<any, ReturnType<typeof asyncAction.success>, any>(
      asyncAction.success,
      (state, action) => ({
        ...state,
        result: (action as PayloadAction<T2, TRes>)?.payload,
        loading: false
      })
    )
    .handleAction<any, ReturnType<typeof asyncAction.failure>, any>(
      asyncAction.failure,
      (state, action) => ({
        ...state,
        result: undefined,
        error: action.payload,
        loading: false
      })
    )

export interface IAsyncSelectors<TReq, TRes, TState> {
  getIsLoading: (state: TState) => boolean | undefined
  getRequest: (state: TState) => TReq | undefined
  getResult: (state: TState) => TRes | undefined
  getError: (state: TState) => Error | undefined
}

export const createAsyncSelectors = <TReq, TRes, TState>(
  getRootState: (state: TState) => IAsyncReducerState<TReq, TRes> | undefined
): IAsyncSelectors<TReq, TRes, TState> => ({
  getIsLoading: flow(getRootState, (x) => x?.loading),
  getRequest: flow(getRootState, (x) => x?.request),
  getResult: flow(getRootState, (x) => x?.result),
  getError: flow(getRootState, (x) => x?.error)
})

export interface IAsyncHookResult<TReq, TRes>
  extends IAsyncReducerState<TReq, TRes> {
  sendRequest: (req?: TReq) => void
}

export type AsyncHook<TRes, TReq = undefined> = (
  autoRequest?: boolean,
  req?: TReq
) => IAsyncHookResult<TReq, TRes>

export const createAsyncHook = <TRes, TReq = undefined, TState = unknown>(
  requestAction:
    | PayloadActionCreator<string, TReq>
    | EmptyActionCreator<string>,
  selectors: IAsyncSelectors<TReq, TRes, TState>
) => {
  return (autoRequest = false, req?: TReq): IAsyncHookResult<TReq, TRes> => {
    const dispatch = useDispatch()
    const loading = useSelector(selectors.getIsLoading)
    const result = useSelector(selectors.getResult)
    const error = useSelector(selectors.getError)
    const request = useSelector(selectors.getRequest)

    const sendRequest = useCallback(
      (req?: TReq) => {
        dispatch(requestAction(req as any))
      },
      [dispatch]
    )

    useEffect(() => {
      if (!autoRequest) {
        return
      }

      sendRequest(req)
    }, [autoRequest, req, sendRequest])

    return { loading, result, error, request, sendRequest }
  }
}
