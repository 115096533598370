import axios from 'axios'
import { flow } from 'lodash/fp'
import { call, cancelled, delay, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { IHousehold } from '../../../api/household.types'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../store/shared/asyncStore'
import { search } from '../../../store/shared/sagas'
import { getMarginRateAdjustmentFeatureState } from './shared'
export const marginRateHouseholdSearchFetchActions = createAsyncAction(
  '@features/@marginRateAdjustment/@householdSearch/REQUEST',
  '@features/@marginRateAdjustment/@householdSearch/SUCCESS',
  '@features/@marginRateAdjustment/@householdSearch/FAILURE'
)<string | undefined, IHousehold[], Error>()

export const householdSearchFetchReducer = createAsyncReducer(
  marginRateHouseholdSearchFetchActions
)

const rootSelector = flow(
  getMarginRateAdjustmentFeatureState,
  (x) => x?.householdSearchFetch
)

export const {
  getError: getMarginRateHouseholdSearchFetchError,
  getIsLoading: getMarginRateHouseholdSearchFetchLoading,
  getResult: getMarginRateHouseholdSearchFetchResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof marginRateHouseholdSearchFetchActions.request>
) {
  if (!action.payload || !action.payload.length) {
    yield put(marginRateHouseholdSearchFetchActions.success([]))
    return
  }
  yield delay(500)

  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  try {
    const result = yield* call(search, 'household' as const, {
      top: 10,
      select: [
        'householdName',
        'id',
        'householdKPI',
        'householdId',
        'Parties',
        'Account'
      ],
      query: action.payload,
      searchFields: ['householdName', 'Account', 'Parties/LegalEntityName'],
      orderBy: [{ dataPath: 'id', direction: 'asc' as const }]
    })

    if (!result || !result.value) {
      throw new Error(
        'An error occurred while fetching the account information'
      )
    }
    yield put(
      marginRateHouseholdSearchFetchActions.success(
        (result.value as IHousehold[]) || []
      )
    )
  } catch (e: any) {
    yield put(marginRateHouseholdSearchFetchActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const householdSearchFetchSagas = [
  () => takeLatest(marginRateHouseholdSearchFetchActions.request, onRequest)
]
