import { Text } from '@fluentui/react'
import React, { PropsWithChildren } from 'react'

export interface ITextCellProps extends PropsWithChildren {
  title?: string
}

export const TextCell: React.FC<ITextCellProps> = ({ children, title }) =>
  children ? (
    <Text
      styles={{ root: { textOverflow: 'ellipsis', overflow: 'hidden' } }}
      title={title}
    >
      {children}
    </Text>
  ) : null
