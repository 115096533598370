import { combineReducers, Reducer } from 'redux'
import {
  IPaymentTransactionsListState,
  paymentTransactionsListReducer
} from '../modules/Transactions/store/reducers'

export interface IPaymentTransactionsModuleState {
  list: IPaymentTransactionsListState
}

export const paymentTransactionsReducer: Reducer<IPaymentTransactionsModuleState> =
  combineReducers({
    list: paymentTransactionsListReducer
  })
