import { lazy } from 'react'
import { IModuleDefinition } from '../types'

const TransfersContainer = lazy(() => import('./TransfersContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/transfers/*',
    private: true,
    component: TransfersContainer
  },
  name: 'Transfers'
}

export default moduleDefinition
