import axios from 'axios'
import { IApiOptions } from '../../../../../../../shared/contracts/IApiOptions'
import { IBeneficiary } from '../store/types'

export const fetchBeneficiariesListSvc = (
  activityPartiesIds: string[],
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/trusts/parties`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IBeneficiary[]>(url, activityPartiesIds, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
