import { createReducer } from 'typesafe-actions'
import { InvoiceActionType, UploadInvoiceActions } from './actions'

export interface IInvoiceState {
  uploadInvoice?: {
    error?: Error
  }
}

export const initialState: IInvoiceState = {
  uploadInvoice: {}
}

export const invoiceReducer = createReducer<IInvoiceState, InvoiceActionType>(
  initialState
)
  .handleAction(UploadInvoiceActions.request, (state) => ({
    ...state,
    uploadInvoice: {
      ...state.uploadInvoice,
      error: undefined
    }
  }))
  .handleAction(UploadInvoiceActions.failure, (state, action) => ({
    ...state,
    uploadInvoice: {
      ...state.uploadInvoice,
      error: action.payload
    }
  }))
