import { MessageBarType } from '@fluentui/react'
import { flow, uniq, uniqBy } from 'lodash'
import { call, put, race, select, take, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import {
  AccountLinkingRequestStatusEnum,
  getHierarchyForReps,
  IAccountLinkingRequest
} from '../../../api/dynamics'
import { IGraphApiSendMailRequest, sendMail } from '../../../api/graph'
import {
  isNotNullOrEmpty,
  isNotNullOrFalse,
  isNotNullOrUndefined
} from '../../../shared/guards'
import { AppState } from '../../../store'
import {
  getDynamicsApiOptions,
  getMicrosoftGraphApiOptions
} from '../../../store/shared/sagas'
import { getEnvironmentName } from '../../../store/system'
import { getRdotUsername } from '../../../store/user/selectors'
import { pushNotification } from '../../Notifications'
import { generateAccountDelinkingEmail } from '../AccountDelinkingEmail'
import { generateAccountLinkingEmail } from '../AccountLinkingEmail'
import { accountLinkingPostActions } from './accountLinkingPost'
import {
  getAccountLinkingValidationValidatedAccountsChecksum,
  getAccountLinkingValidationSelectedClients,
  getAccountLinkingValidationValidatedAccountDetails,
  accountLinkingValidationActions,
  getAccountLinkingValidations,
  getAccountLinkingValidationVerification,
  getAccountLinkingValidationSubject,
  getAccountLinkingValidationRequestType,
  IAccountLinkingVerification
} from './accountLinkingValidation'

const OPEN = '@features/@accountLinking/@accountLinkingPanel/OPEN'
const CLOSE = '@features/@accountLinking/@accountLinkingPanel/CLOSE'
const SUBMIT = '@features/@accountLinking/@accountLinkingPanel/SUBMIT'
const FAILURE = '@features/@accountLinking/@accountLinkingPanel/FAILURE'

export const fidelityCharitable = 'Fidelity Charitable/AEF'

export const accountLinkingPanelActions = {
  open: createAction(OPEN)(),
  close: createAction(CLOSE)(),
  submit: createAction(SUBMIT)<string[] | undefined>(),
  failure: createAction(FAILURE)<Error>()
}

export type AccountLinkingPanelActionTypes = ActionType<
  typeof accountLinkingPanelActions
>

export interface IAccountLinkingPanelState {
  isOpen?: boolean
  loading?: boolean
  error?: Error
}

const initialState: IAccountLinkingPanelState = {}

export const accountLinkingPanelReducer = createReducer<
  IAccountLinkingPanelState,
  AccountLinkingPanelActionTypes
>(initialState)
  .handleAction(accountLinkingPanelActions.open, () => ({
    ...initialState,
    isOpen: true
  }))
  .handleAction(accountLinkingPanelActions.close, () => ({
    ...initialState,
    isOpen: false
  }))
  .handleAction(accountLinkingPanelActions.submit, (state) => ({
    ...state,
    error: undefined,
    loading: true
  }))
  .handleAction(accountLinkingPanelActions.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }))

const rootSelector = (state: AppState) =>
  state.features.accountLinking.accountLinkingPanel

export const getIsAccountLinkingPanelOpen = flow(
  rootSelector,
  ({ isOpen }) => isOpen
)

export const getIsAccountLinkingPanelLoading = flow(
  rootSelector,
  ({ loading }) => loading
)

export const getAccountLinkingPanelError = flow(
  rootSelector,
  ({ error }) => error
)

const onSubmit = function* (
  action: ReturnType<typeof accountLinkingPanelActions.submit>
) {
  const validatedAccounts = yield* select(
    getAccountLinkingValidationValidatedAccountDetails
  )
  const selectedClients = yield* select(
    getAccountLinkingValidationSelectedClients
  )

  const checksum = yield* select(
    getAccountLinkingValidationValidatedAccountsChecksum
  )
  const validations = yield* select(getAccountLinkingValidations)
  const formVerification = yield* select(
    getAccountLinkingValidationVerification
  )
  const verification: IAccountLinkingVerification =
    formVerification?.method !== fidelityCharitable
      ? {
          ...formVerification,
          client: `${formVerification?.clientFirstName} ${formVerification?.clientLastName}`
        }
      : { method: formVerification?.method }
  const requestType = yield* select(getAccountLinkingValidationRequestType)

  try {
    if (!selectedClients?.length) {
      throw new Error('No selected clients')
    }
    const environment = yield* select(getEnvironmentName)
    const currentUsername = yield* select(getRdotUsername)
    const isProd = environment === 'prod'
    const subject = yield* select(getAccountLinkingValidationSubject)
    const graphApiOptions = yield* call(getMicrosoftGraphApiOptions)
    const origin = window.origin

    if (!currentUsername) {
      throw new Error('Unable to discover the logged in user')
    }
    if (requestType === 'delink') {
      if (!action.payload?.length) {
        throw new Error('Unable to determine accounts to delink')
      }
      const requests = selectedClients?.map(
        (x): IAccountLinkingRequest => ({
          rcm_cliendid: x?.wsportaluserid,
          rcm_accountstoadd: action.payload?.join(','),
          rcm_verificationdate: verification?.date?.toISOString(),
          rcm_verificationtime: verification?.time,
          rcm_client: `${verification?.clientFirstName} ${verification?.clientLastName}`,
          rcm_clientmethod: verification?.method,
          rcm_requesttype: requestType,
          rcm_newclient: x.fullname,
          rcm_newclientemailid: x.loginid,
          rcm_newclientrole: x.role,
          rcm_mfaphone: x.mfaPhones,
          rcm_status: AccountLinkingRequestStatusEnum.APPROVED
        })
      )

      yield put(accountLinkingPostActions.request(requests))
      const { success, failure } = yield* race({
        success: take(accountLinkingPostActions.success),
        failure: take(accountLinkingPostActions.failure)
      })
      if (failure || !success) {
        yield put(
          accountLinkingPanelActions.failure(
            failure?.payload || new Error('An unknown error occurred.')
          )
        )
        return
      }
      const body = yield* call(generateAccountDelinkingEmail, {
        selectedClients,
        requestType,
        origin,
        id: success.payload.rcm_accountlinkingrequestid,
        delinkAccounts: action.payload
      })

      const emailRequest: IGraphApiSendMailRequest = {
        message: {
          toRecipients: [{ emailAddress: { address: currentUsername } }],
          ccRecipients: isProd
            ? [{ emailAddress: { address: 'RCM-Onboarding@rockco.com' } }]
            : [{ emailAddress: { address: currentUsername } }],
          body: {
            contentType: 'HTML',
            content: body
          },
          subject
        }
      }

      yield* call(sendMail, graphApiOptions, emailRequest)
    } else {
      const dynamicsApiOptions = yield* call(getDynamicsApiOptions)
      const repsForAccounts = uniq(
        validatedAccounts?.map(({ ClientAdvisorID }) => ClientAdvisorID)
      ).filter(isNotNullOrEmpty)

      if (!repsForAccounts.length) {
        throw new Error('Rep ownership for accounts could not be determined')
      }
      const repsWithHierarchy = yield* call(
        getHierarchyForReps,
        dynamicsApiOptions,
        repsForAccounts
      )

      const [firstRep] = repsWithHierarchy || []
      if (!firstRep || !firstRep.rcm_OwningTeam?.businessunitid) {
        throw new Error('Reps not configured in cdm.rcm_advisorreps')
      }

      const firstRepBusinessUnit = firstRep.rcm_OwningTeam?.businessunitid

      const businessUnit = [
        firstRepBusinessUnit.parentbusinessunitid,
        firstRepBusinessUnit.parentbusinessunitid?.parentbusinessunitid
      ]
        .filter(isNotNullOrUndefined)
        .filter((x) => x?.emailaddress)

      const businessUnitEmails = [
        firstRepBusinessUnit.parentbusinessunitid?.emailaddress,
        firstRepBusinessUnit.parentbusinessunitid?.parentbusinessunitid
          ?.emailaddress
      ].filter(isNotNullOrEmpty)

      if (!businessUnitEmails.length) {
        throw new Error('Unable to determine business unit emails')
      }

      const uniqueOffices = uniqBy(
        repsWithHierarchy
          ?.map(
            ({ rcm_OwningTeam }) =>
              rcm_OwningTeam?.businessunitid?.parentbusinessunitid
          )
          .filter(isNotNullOrUndefined),
        ({ businessunitid }) => businessunitid
      )

      const requests = selectedClients?.map(
        (x): IAccountLinkingRequest => ({
          rcm_cliendid: x?.wsportaluserid,
          rcm_accountstoadd: validatedAccounts
            ?.map(({ accountkey }) => accountkey)
            .join(','),
          rcm_verificationdate: verification?.date?.toISOString(),
          rcm_verificationtime: verification?.time,
          rcm_client: `${verification?.clientFirstName} ${verification?.clientLastName}`,
          rcm_clientmethod: verification?.method,
          rcm_requesttype: requestType,
          rcm_newclient: x.fullname,
          rcm_newclientemailid: x.loginid,
          rcm_newclientrole: x.role,
          rcm_mfaphone: x.mfaPhones,
          rcm_partyid: x.partyid
        })
      )

      yield put(accountLinkingPostActions.request(requests))
      const { success, failure } = yield* race({
        success: take(accountLinkingPostActions.success),
        failure: take(accountLinkingPostActions.failure)
      })

      if (failure || !success) {
        yield put(
          accountLinkingPanelActions.failure(
            failure?.payload || new Error('An unknown error occurred.')
          )
        )
        return
      }

      const body = yield* call(generateAccountLinkingEmail, {
        selectedClients,
        validatedAccounts,
        validations: [
          ...validations,
          uniqueOffices.length > 1 &&
            `Reps span multiple offices: ${uniqueOffices
              .map(({ name }) => name)
              .join(', ')}`
        ].filter(isNotNullOrFalse),
        verification,
        checksum,
        approvers: businessUnit,
        requestType,
        origin,
        id: success.payload.rcm_accountlinkingrequestid
      })

      const emailRequest: IGraphApiSendMailRequest = {
        message: {
          toRecipients: isProd
            ? businessUnitEmails.map((domainname) => ({
                emailAddress: { address: domainname }
              }))
            : [{ emailAddress: { address: currentUsername } }],
          ccRecipients: isProd
            ? [
                { emailAddress: { address: currentUsername } },
                { emailAddress: { address: 'RCM-Onboarding@rockco.com' } }
              ]
            : [{ emailAddress: { address: currentUsername } }],
          body: {
            contentType: 'HTML',
            content: body
          },
          subject
        }
      }

      yield* call(sendMail, graphApiOptions, emailRequest)
    }
  } catch (e: any) {
    yield put(accountLinkingPanelActions.failure(e))
    return
  }

  yield call(pushNotification, {
    message: 'Successfully submitted request.',
    type: MessageBarType.success
  })
  yield put(accountLinkingPanelActions.close())
}

const onClose = function* () {
  yield put(accountLinkingValidationActions.reset())
}

export const accountLinkingPanelSagas = [
  () => takeLatest(accountLinkingPanelActions.close, onClose),
  () => takeLatest(accountLinkingPanelActions.submit, onSubmit)
]
