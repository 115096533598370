import { createReducer } from 'typesafe-actions'
import { trusteesDataActions, TrusteesActionType } from './actions'
import { ITrustee } from './types'

export interface ITrusteesState {
  trusteesData: ITrustee[]
  isLoading: boolean
  error?: Error
}

const initialState: ITrusteesState = {
  trusteesData: [],
  isLoading: true,
  error: undefined
}

export const trusteesReducer = createReducer<
  ITrusteesState,
  TrusteesActionType
>(initialState)
  .handleAction(trusteesDataActions.request, (state) => ({
    ...state,
    isLoading: true,
    error: undefined
  }))
  .handleAction(trusteesDataActions.success, (state, action) => ({
    ...state,
    trusteesData: action.payload,
    isLoading: false,
    error: undefined
  }))
  .handleAction(trusteesDataActions.failure, (state, action) => ({
    ...state,
    error: action.payload,
    isLoading: false
  }))
