import { lazy } from 'react'
import { IModuleDefinition } from '../../../../../types'
export * from './store'

const PeerReview = lazy(() => import('./PeerReview'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/PeerReview/*',
    private: true,
    component: PeerReview
  },
  name: 'PeerReview'
}

export default moduleDefinition
