import { datahubApi } from 'store/api/datahub'

export interface IHurdleRepCodeResponse {
  pools?: IRepCode[]
  individuals?: IRepCode[]
}
export interface IRepCode {
  repCode?: string
  name?: string
}

const hurdlesDatahubApi = datahubApi.injectEndpoints({
  endpoints: (builder) => ({
    getRepCodesForTeam: builder.query<IHurdleRepCodeResponse, string>({
      query: (teamId) => ({
        url: `advisorrep/team/${teamId}`
      })
    }),
    getRepCodesForIndividual: builder.query<IHurdleRepCodeResponse, string>({
      query: (individualId) => ({
        url: `advisorrep/individual/${individualId}`
      })
    })
  })
})

export const { useGetRepCodesForTeamQuery, useGetRepCodesForIndividualQuery } =
  hurdlesDatahubApi
