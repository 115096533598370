import React from 'react'
import { USD } from 'shared/components/Formatting'
import { ValueColorWrapper } from './ValueColorWrapper'

export const USDCellWithColor: React.FC<{
  className?: string
  fractionDigit?: number
  value?: number
}> = ({ className, fractionDigit, value }) => {
  const decimal = value === 0 ? 0 : fractionDigit
  return (
    <ValueColorWrapper value={value} className={className}>
      {!!value && value > 0 && '+'}
      {value != null && (
        <USD value={value} fractionDigits={decimal} currencySign="standard" />
      )}
    </ValueColorWrapper>
  )
}
