import { createReducer } from 'typesafe-actions'
import {
  beneficiaryInfoDataActions,
  BeneficiaryInfoActionType
} from './actions'
import { IBeneficiary } from './types'

export interface IBeneficiaryState {
  beneficiaryData: IBeneficiary[]
  isLoading: boolean
  error?: Error
}

const initialState: IBeneficiaryState = {
  beneficiaryData: [],
  isLoading: true,
  error: undefined
}

export const beneficiaryReducer = createReducer<
  IBeneficiaryState,
  BeneficiaryInfoActionType
>(initialState)
  .handleAction(beneficiaryInfoDataActions.request, (state) => ({
    ...state,
    beneficiaryData: [],
    isLoading: true,
    error: undefined
  }))
  .handleAction(beneficiaryInfoDataActions.success, (state, action) => ({
    ...state,
    beneficiaryData: action.payload,
    isLoading: false,
    error: undefined
  }))
  .handleAction(beneficiaryInfoDataActions.failure, (state, action) => ({
    ...state,
    beneficiaryData: [],
    error: action.payload,
    isLoading: false
  }))
