import { useTheme } from '@emotion/react'
import { useMemo } from 'react'

export const useActivityMappings = () => {
  const theme = useTheme()
  const colorLookup: Record<string, string> = useMemo(
    () => ({
      Buys: theme.colors.tertiaryBlue1,
      Sells: theme.colors.secondaryGreen2,
      'Dividends & Interest': theme.colors.tertiaryBlue2,
      'Exchanges / Transfers': theme.colors.secondaryYellow1,
      'Fees & Interest Charged': theme.colors.secondaryGreen1,
      'Deposits / Contributions': theme.colors.tertiaryBlue3,
      Withdrawals: theme.colors.secondaryTurquoise1,
      'Journal Entries': theme.colors.secondaryRed2,
      'Corporate Actions': theme.colors.secondaryViolet1,
      Other: theme.colors.secondaryGreen3
    }),
    [
      theme.colors.secondaryGreen1,
      theme.colors.secondaryGreen2,
      theme.colors.secondaryGreen3,
      theme.colors.secondaryRed2,
      theme.colors.secondaryTurquoise1,
      theme.colors.secondaryViolet1,
      theme.colors.secondaryYellow1,
      theme.colors.tertiaryBlue1,
      theme.colors.tertiaryBlue2,
      theme.colors.tertiaryBlue3
    ]
  )
  const categoryLookup: Record<string, string> = useMemo(
    () => ({
      Buys: 'Buys',
      Sells: 'Sells',
      'Dividends & Interest': 'Dividends & Interest',
      'Fees & Interest Charged': 'Fees & Interest Charged',
      'Deposits / Contributions': 'Deposits / Contributions',
      Withdrawals: 'Withdrawals',
      'Journal Entries': 'Journal Entries',
      'Corporate Actions': 'Corporate Actions',
      'Exchanges / Transfers': 'Exchanges / Transfers',
      Other: 'Other'
    }),
    []
  )
  const categoryCodeLookup: Record<string, string[]> = useMemo(
    () => ({
      Buys: ['BY'],
      Sells: ['SL'],
      'Dividends & Interest': ['DV'],
      'Fees & Interest Charged': ['FE'],
      'Deposits / Contributions': ['DP'],
      Withdrawals: ['WD'],
      'Journal Entries': ['JN'],
      'Corporate Actions': ['CA'],
      'Exchanges / Transfers': ['EX'],
      Other: ['ZZ']
    }),
    []
  )
  const orderLookup: Record<string, number> = useMemo(
    () => ({
      Buys: 1,
      Sells: 2,
      'Dividends & Interest': 3,
      'Fees & Interest Charged': 4,
      'Deposits / Contributions': 5,
      Withdrawals: 6,
      'Journal Entries': 7,
      'Corporate Actions': 8,
      'Exchanges / Transfers': 9,
      Other: 10
    }),
    []
  )
  return {
    colorLookup,
    categoryLookup,
    categoryCodeLookup,
    orderLookup
  }
}
