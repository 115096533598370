import { combineReducers } from 'redux'
import { noteCategoryCalloutReducer } from './noteCategoryCallout'
import { noteFilterCalloutReducer } from './noteFilterCallout'
import { noteFormReducer } from './noteForm'
import { noteSettingsCalloutReducer } from './noteSettingsCallout'
import { notesPanelReducer } from './notesPanel'

export const notesReducer = combineReducers({
  notesPanel: notesPanelReducer,
  noteCategoryCallout: noteCategoryCalloutReducer,
  noteSettingsCallout: noteSettingsCalloutReducer,
  noteFilterCallout: noteFilterCalloutReducer,
  noteForm: noteFormReducer
})
