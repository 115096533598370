import { combineReducers } from 'redux'
import { entitySearchReducer, entitySearchSagas } from './EntitySearch'
import {
  hurdleDefinitionListReducer,
  hurdleDefinitionListSagas
} from './HurdleDefinitionList'
import { hurdleEditReducer, hurdleEditSagas } from './HurdleEdit'
import {
  hurdleProgressManagementListReducer,
  hurdleProgressManagementListSagas
} from './HurdleProgressManagementList'
import {
  potentialPayoutsReducer,
  potentialPayoutsSagas
} from './PotentialPayouts'

export const hurdlesFeaturesReducer = combineReducers({
  hurdleDefinitionList: hurdleDefinitionListReducer,
  hurdleEdit: hurdleEditReducer,
  entitySearch: entitySearchReducer,
  hurdleProgressManagementList: hurdleProgressManagementListReducer,
  potentialPayouts: potentialPayoutsReducer
})

export const hurdlesFeaturesSagas = [
  ...hurdleDefinitionListSagas,
  ...hurdleEditSagas,
  ...entitySearchSagas,
  ...hurdleProgressManagementListSagas,
  ...potentialPayoutsSagas
]
