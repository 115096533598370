import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { getTradeDetailOrder } from './store'
import { TradeDetail } from './TradeDetail'

const MemoTradeDetail = memo(TradeDetail)

export const TradeDetailContainer: React.FC = () => {
  const orderWithTrades = useSelector(getTradeDetailOrder)

  if (!orderWithTrades) {
    return null
  }

  return <MemoTradeDetail orderWithTrades={orderWithTrades} />
}
