import { ActionType, createAction } from 'typesafe-actions'
import {
  IRelatedParty,
  IStatement,
  IStatementRequest,
  IUploadReportRequest,
  IUploadReportResponse
} from './types'

export const FETCH_STATEMENTS_REQUEST = `@mouldes/@trust/@Statements/@statements/FETCH_STATEMENTS_REQUEST`
export const FETCH_STATEMENTS_REQUEST_SUCCESS = `@mouldes/@trust/@Statements/@statements/FETCH_STATEMENTS_SUCCESS`
export const FETCH_STATEMENTS_REQUEST_FAILURE = `@mouldes/@trust/@Statements/@statements/FETCH_STATEMENTS_FAILURE`
export const FETCH_STATEMENTS_CLEAR = `@modules/@trust/@statemensts/@statements/FETCG_STATEMENTS_CLEAR`
export const fetchStatementsActions = {
  request: createAction(FETCH_STATEMENTS_REQUEST)<IStatementRequest>(),
  success: createAction(FETCH_STATEMENTS_REQUEST_SUCCESS)<IStatement[]>(),
  failure: createAction(FETCH_STATEMENTS_REQUEST_FAILURE)<Error>(),
  clear: createAction(FETCH_STATEMENTS_CLEAR)()
}

export const FETCH_RELATED_PARTIES_INFO =
  '@mouldes/@trust/@Statements/@statements/FETCH_RELATED_PARTIES_INFO'
export const FETCH_RELATED_PARTIES_SUCCESS =
  '@mouldes/@trust/@Statements/@statements/FETCH_RELATED_PARTIES_SUCCESS'
export const FETCH_RELATED_PARTIES_FAILURE =
  '@mouldes/@trust/@Statements/@statements/FETCH_RELATED_PARTIES_FAILURE'

export const fetchRelatedPartieActions = {
  request: createAction(FETCH_RELATED_PARTIES_INFO)<{ partyId: string }>(),
  success: createAction(FETCH_RELATED_PARTIES_SUCCESS)<IRelatedParty[]>(),
  failure: createAction(FETCH_RELATED_PARTIES_FAILURE)<Error>()
}
export const UPLOAD_REPORT =
  '@mouldes/@trust/@Statements/@statements/UPLOAD_REPORT'
export const UPLOAD_REPORT_SUCCESS =
  '@mouldes/@trust/@Statements/@statements/UPLOAD_REPORT_SUCCESS'
export const UPLOAD_REPORT_FAILURE =
  '@mouldes/@trust/@Statements/@statements/UPLOAD_REPORT_FAILURE'
export const UPLOAD_REPORT_CLEAR =
  '@mouldes/@trust/@Statements/@statements/UPLOAD_REPORT_CLEAR'

export const uploadReportActions = {
  request: createAction(UPLOAD_REPORT)<IUploadReportRequest>(),
  success: createAction(UPLOAD_REPORT_SUCCESS)<IUploadReportResponse>(),
  failure: createAction(UPLOAD_REPORT_FAILURE)<Error>(),
  clear: createAction(UPLOAD_REPORT_CLEAR)()
}

export const DOWNLOAD_STATEMENT_FILE_REQUEST = `@mouldes/@trust/@Statements/@Statements/DOWNLOAD_STATEMENT_FILE_REQUEST`
export const DOWNLOAD_STATEMENT_FILE_SUCCESS = `@mouldes/@trust/@Statements/@Statements/DOWNLOAD_STATEMENT_FILE_SUCCESS`
export const DOWNLOAD_STATEMENT_FILE_FAILURE = `@mouldes/@trust/@Statements/@Statements/DOWNLOAD_STATEMENT_FILE_FAILURE`

export const downloadStatementActions = {
  request: createAction(DOWNLOAD_STATEMENT_FILE_REQUEST)<string>(),
  success: createAction(DOWNLOAD_STATEMENT_FILE_SUCCESS)(),
  failure: createAction(DOWNLOAD_STATEMENT_FILE_FAILURE)<Error>()
}

export type StatementListActions =
  | ActionType<typeof fetchStatementsActions>
  | ActionType<typeof fetchRelatedPartieActions>
  | ActionType<typeof uploadReportActions>
  | ActionType<typeof downloadStatementActions>
