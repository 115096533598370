import axios from 'axios'
import { IApiOptions } from '../shared/contracts/IApiOptions'
import { IOverrides } from './overrides.types'

const apiPath = 'cdm/SecurityOverride'

export const getOverrides = (
  options: IApiOptions,
  searchParameter?: string
) => {
  let params = ''
  if (searchParameter) {
    params = `?searchText=${searchParameter}`
  }
  const apiName = 'GetSecurityOverrides'

  const url = `${options.apiRoot}/${apiPath}/${apiName}${params}`

  return axios
    .get(`${url}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}
export const getSecurityReferences = (options: IApiOptions, input: string) => {
  const apiName = `GetSecurityReferenceData/${input}`

  const url = `${options.apiRoot}/${apiPath}/${apiName}`

  return axios
    .get(`${url}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}

export const updateOverrides = (options: IApiOptions, payload?: IOverrides) => {
  const apiName = 'UpdateSecurityOverrides'
  // let params = ''
  // if (payload) {
  //   params = '?' + stringify(payload)
  // }

  const url = `${options.apiRoot}/${apiPath}/${apiName}`

  return axios
    .post(`${url}`, payload, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => x.data)
}
