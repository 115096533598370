export const SpecialCharacterPattern = (FieldName: string) => {
  if (FieldName === FieldNamesPattern.BankAccount) {
    return 'a-z A-Z 0-9 ( ) : + \\- '
  } else if (FieldName === FieldNamesPattern.NameonAccount) {
    return "a-z A-Z 0-9 ? : , ' \\- . ( ) + "
  } else if (FieldName === FieldNamesPattern.DFDisclosure) {
    return '<>&\\\\'
  } else if (FieldName === FieldNamesPattern.Amount) {
    return '0-9.'
  } else {
    return "a-z A-Z 0-9 ? : , ' \\- . ( ) + "
  }
}

export const FieldNamesPattern = {
  ReceivingAccount: 'ReceivingAccount',
  BankAccount: 'Bank Account',
  NameonAccount: 'NameonAccount',
  DFDisclosure: 'DFDisclosure',
  Amount: 'Amount'
}
