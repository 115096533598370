import { ActionType, createAction } from 'typesafe-actions'
import {
  ColumnSetting,
  IPaymentExcelExport,
  DashboardPreference,
  PaymentMM,
  PaymentRow,
  RefreshByRole,
  SearchSortFilter,
  Statistics,
  IAuthzLinkRsp
} from './types'

export const FETCH_PAYMENT_LIST =
  '@modules/@transfers/@dashboard/FETCH_PAYMENT_LIST'
export const FETCH_PAYMENT_LIST_SUCCESS =
  '@modules/@transfers/@dashboard/FETCH_PAYMENT_LIST_SUCCESS'
export const FETCH_PAYMENT_LIST_FAILURE =
  '@modules/@transfers/@dashboard/FETCH_PAYMENT_LIST_FAILURE'

export const FETCH_PAYMENT_BY_ID =
  '@modules/@transfers/@dashboard/FETCH_PAYMENT_BY_ID'
export const FETCH_PAYMENT_BY_ID_SUCCESS =
  '@modules/@transfers/@dashboard/FETCH_PAYMENT_BY_ID_SUCCESS'
export const FETCH_PAYMENT_BY_ID_FAILURE =
  '@modules/@transfers/@dashboard/FETCH_PAYMENT_BY_ID_FAILURE'
export const FETCH_PAYMENT_BY_ID_CLEAR =
  '@modules/@transfers/@dashboard/FETCH_PAYMENT_BY_ID_CLEAR'

export const SET_SELECTED_USER_ROLE =
  '@modules/@transfers/@dashboard/SET_SELECTED_USER_ROLE'

export const SET_SELECTED_PAYMENTITEM =
  '@modules/@transfers/@dashboard/SET_SELECTED_PAYMENTITEM'

export const NAVIGATE_TO_PAYMENT_EDIT =
  '@modules/@transfers/@dashboard/NAVIGATE_TO_PAYMENT_EDIT'
export const NAVIGATE_TO_PAYMENT_REVIEW =
  '@modules/@transfers/@dashboard/NAVIGATE_TO_PAYMENT_REVIEW'
export const NAVIGATE_TO_PAYMENT_VIEW =
  '@modules/@transfers/@dashboard/NAVIGATE_TO_PAYMENT_VIEW'
export const NAVIGATE_TO_PAYMENT_UPDATE_FED_REF =
  '@modules/@transfers/@dashboard/NAVIGATE_TO_PAYMENT_UPDATE_FED_REF'
export const NAVIGATE_TO_PAYMENT_UPDATE_DFD =
  '@modules/@transfers/@dashboard/NAVIGATE_TO_PAYMENT_UPDATE_DFD'
export const NAVIGATE_TO_PAYMENT_UPDATE_DFACK =
  '@modules/@transfers/@dashboard/NAVIGATE_TO_PAYMENT_UPDATE_DFACK'

export const SET_PAYMENT_SEARCH_SORT_FILTER =
  '@modules/@transfers/@dashboard/SET_PAYMENT_SEARCH_SORT_FILTER'
export const SET_PAYMENT_SEARCH_SORT_FILTER_PERIODIC_PLAN =
  '@modules/@transfers/@dashboard/SET_PAYMENT_SEARCH_SORT_FILTER_PERIODIC_PLAN'
export const SET_PAYMENT_AUTOREFRESH_FLAG =
  '@modules/@transfers/@dashboard/SET_PAYMENT_AUTOREFRESH_FLAG'
export const SET_COLUMN_PREFERENCE =
  '@modules/@transfers/@dashboard/SET_COLUMN_PREFERENCE'

export const SET_PREFERENCE = '@modules/@transfers/@dashboard/SET_PREFERENCE'
export const GET_PREFERENCE = '@modules/@transfers/@dashboard/GET_PREFERENCE'

export const fetchPaymentListDataActions = {
  request: createAction(FETCH_PAYMENT_LIST)<{
    role: string
    fromDate?: string
    toDate?: string
    useTrnDate?: string
    recurringPlan?: string
  }>(),
  success: createAction(FETCH_PAYMENT_LIST_SUCCESS)<{
    paymentList: PaymentRow[]
    statistics: Statistics[]
  }>(),
  failure: createAction(FETCH_PAYMENT_LIST_FAILURE)<Error>()
}

export const fetchPaymentByIDActions = {
  request: createAction(FETCH_PAYMENT_BY_ID)<{ ID: string; reset?: boolean }>(),
  success: createAction(FETCH_PAYMENT_BY_ID_SUCCESS)<{
    paymentDetails?: PaymentMM
  }>(),
  failure: createAction(FETCH_PAYMENT_BY_ID_FAILURE)<Error>(),
  clear: createAction(FETCH_PAYMENT_BY_ID_CLEAR)()
}

export const setUserRoleActions = {
  setrole: createAction(SET_SELECTED_USER_ROLE)<string>()
}

export const setPaymentSearchSortFilterActions = {
  setSearchSortFilter: createAction(
    SET_PAYMENT_SEARCH_SORT_FILTER
  )<SearchSortFilter>()
}

export const setPeriodicPlanSearchSortFilterActions = {
  setSearchSortFilter: createAction(
    SET_PAYMENT_SEARCH_SORT_FILTER_PERIODIC_PLAN
  )<SearchSortFilter>()
}
export const setPaymentAutoRefreshFlagActions = {
  setAutoRefreshFlag: createAction(SET_PAYMENT_AUTOREFRESH_FLAG)<
    RefreshByRole[]
  >()
}

export const setSelectedPaymentItemActions = {
  setPaymentItem: createAction(SET_SELECTED_PAYMENTITEM)<PaymentMM>()
}

export const paymentDashboardNavigationActions = {
  navigateToEdit: createAction(NAVIGATE_TO_PAYMENT_EDIT)<string | undefined>(),
  navigateToReview: createAction(NAVIGATE_TO_PAYMENT_REVIEW)(),
  navigateToView: createAction(NAVIGATE_TO_PAYMENT_VIEW)<string | undefined>(),
  navigateToUpdateFedRef: createAction(NAVIGATE_TO_PAYMENT_UPDATE_FED_REF)(),
  navigateToUpdateDFDisclosure: createAction(
    <string>NAVIGATE_TO_PAYMENT_UPDATE_DFD
  )(),
  navigateToUpdateDFAcknowledge: createAction(
    NAVIGATE_TO_PAYMENT_UPDATE_DFACK
  )()
}

export const dashboardPreferenceActions = {
  setColumnPreference: createAction(SET_COLUMN_PREFERENCE)<ColumnSetting[]>(),
  setPreference: createAction(SET_PREFERENCE)<DashboardPreference>(),
  getPreference_request: createAction(GET_PREFERENCE)(),
  getPreference_success: createAction(SET_PREFERENCE)<DashboardPreference>()
}

export type FetchPaymentListActionType = ActionType<
  typeof fetchPaymentListDataActions
>
export type setSelectedPaymentItemActionType = ActionType<
  typeof setSelectedPaymentItemActions
>
export type SetUserRoleActionType = ActionType<typeof setUserRoleActions>
export type setPaymentSearchSortFilterActionType = ActionType<
  typeof setPaymentSearchSortFilterActions
>
export const EXPORT_PAYMENT_TRANSACTIONS = `@modules/@dashboard/EXPORT_PAYMENT_TRANSACTIONS`
export const EXPORT_PAYMENTS_TRANSACTIONS_SUCCESS = `@modules/@dashboard/EXPORT_PAYMENTS_TRANSACTIONS_SUCCESS`
export const EXPORT_PAYMENTS_TRANSACTIONS_FAILURE = `@modules/@dashboard/EXPORT_PAYMENTS_TRANSACTIONS_FAILURE`

export const exportPaymentsToExcelActions = {
  request: createAction(EXPORT_PAYMENT_TRANSACTIONS)<IPaymentExcelExport>(),
  success: createAction(EXPORT_PAYMENTS_TRANSACTIONS_SUCCESS)(),
  failure: createAction(EXPORT_PAYMENTS_TRANSACTIONS_FAILURE)<Error>()
}
export const RESEND_SMS_LINK = `@modules/@dashboard/RESEND_SMS_LINK`
export const RESEND_SMS_LINK_SUCCESS = `@modules/@dashboard/RESEND_SMS_LINK_SUCCESS`
export const RESEND_SMS_LINK_FAILURE = `@modules/@dashboard/RESEND_SMS_LINK_FAILURE`
export const RESEND_SMS_LINK_CLEAR = `@modules/@dashboard/RESEND_SMS_LINK_CLEAR`
export const resendSMSLinkActions = {
  request: createAction(RESEND_SMS_LINK)<string>(),
  success: createAction(RESEND_SMS_LINK_SUCCESS)<IAuthzLinkRsp>(),
  failure: createAction(RESEND_SMS_LINK_FAILURE)<Error>(),
  clear: createAction(RESEND_SMS_LINK_CLEAR)()
}

export type DashboardActionTypes =
  | FetchPaymentListActionType
  | SetUserRoleActionType
  | setPaymentSearchSortFilterActionType
  | setSelectedPaymentItemActionType
  | ActionType<typeof setPaymentAutoRefreshFlagActions>
  | ActionType<typeof paymentDashboardNavigationActions>
  | ActionType<typeof fetchPaymentByIDActions>
  | ActionType<typeof dashboardPreferenceActions>
  | ActionType<typeof exportPaymentsToExcelActions>
  | ActionType<typeof resendSMSLinkActions>
