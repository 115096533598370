import { createReducer } from 'typesafe-actions'
import { IRelatedParty } from '../../ScheduleReport/store/types'
import {
  ShareReportActionType,
  relatedPartiesDetailsActionTypes,
  uploadReportActions
} from './action'
import { IUploadReportResponse } from './types'

export interface IShareReportState {
  relatedPartiesDetails: IRelatedParty[]
  isRelatedPartyLoading: boolean
  error?: Error
  uploadReportDetails?: {
    loading: boolean
    error?: Error
    reportStatus?: IUploadReportResponse
  }
}

const initialState: IShareReportState = {
  relatedPartiesDetails: [],
  isRelatedPartyLoading: false,
  error: undefined,
  uploadReportDetails: undefined
}

export const shareReportReducer = createReducer<
  IShareReportState,
  ShareReportActionType
>(initialState)
  .handleAction(relatedPartiesDetailsActionTypes.request, (state) => ({
    ...state,
    isRelatedPartyLoading: true,
    error: undefined,
    relatedPartiesDetails: [],
    uploadReportDetails: undefined
  }))
  .handleAction(relatedPartiesDetailsActionTypes.success, (state, action) => ({
    ...state,
    relatedPartiesDetails: action.payload,
    isRelatedPartyLoading: false,
    error: undefined,
    uploadReportDetails: undefined
  }))
  .handleAction(relatedPartiesDetailsActionTypes.failure, (state, action) => ({
    ...state,
    error: action.payload,
    isRelatedPartyLoading: false,
    relatedPartiesDetails: [],
    uploadReportDetails: undefined
  }))
  .handleAction(uploadReportActions.request, (state) => ({
    ...state,
    isRelatedPartyLoading: false,
    error: undefined,
    uploadReportDetails: {
      ...state.uploadReportDetails,
      loading: true,
      error: undefined
    }
  }))
  .handleAction(uploadReportActions.success, (state, action) => ({
    ...state,
    isRelatedPartyLoading: false,
    error: undefined,
    uploadReportDetails: {
      ...state.uploadReportDetails,
      loading: false,
      error: undefined,
      reportStatus: action.payload
    }
  }))
  .handleAction(uploadReportActions.failure, (state, action) => ({
    ...state,
    isRelatedPartyLoading: false,
    error: undefined,
    uploadReportDetails: {
      ...state.uploadReportDetails,
      loading: false,
      error: action.payload
    }
  }))
