import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useMemo } from 'react'
import { useGetPrivatePlacementSummaryQuery } from '../api/apiAssets'
import { useSelectedReps } from './useSelectedReps'

export const useGetPrivatePlacementDetails = () => {
  const { selectedReps, shouldMakeApiCall } = useSelectedReps()

  const queryProps = useMemo(
    () => ({
      registeredRepIds: selectedReps,
      contractStatus: ['Active'],
      contractType: 'Private Placement'
    }),
    [selectedReps]
  )

  const { data, isFetching: isSummaryFetching } =
    useGetPrivatePlacementSummaryQuery(
      shouldMakeApiCall ? queryProps : skipToken
    )

  return { data, isSummaryFetching }
}
