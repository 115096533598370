import type { IAnnuityPPLIAssets } from 'api/datahub'
import { PercentCell } from 'features/Lists/core/components/PercentCell'
import { TextCell } from 'features/Lists/core/components/TextCell'
import { USDCell } from 'features/Lists/core/components/USDCell'
import type { IOdataDataListCellRenderProps } from 'features/OdataList/containers/OdataList'
import type { AnnuityPPLIAssetsColumnName } from './columnNameTypes'

export const CellComponents: Record<
  AnnuityPPLIAssetsColumnName,
  React.FC<IOdataDataListCellRenderProps<IAnnuityPPLIAssets>>
> = {
  'Contract Type': ({ item }) => <TextCell>{item.contractType}</TextCell>,

  'Household Name': ({ item }) => <TextCell>{item.householdName}</TextCell>,

  'Account Number': ({ item }) => (
    <TextCell>{item.accountNumber || 'Account Not Linked'}</TextCell>
  ),

  'Legal Entity Name': ({ item }) => (
    <TextCell>{item.legalEntityName}</TextCell>
  ),

  'Contract Number': ({ item }) => <TextCell>{item.contractNumber}</TextCell>,

  Description: ({ item }) => <TextCell>{item.description}</TextCell>,

  'Product Type': ({ item }) => <TextCell>{item.productType}</TextCell>,

  Carrier: ({ item }) => <TextCell>{item.carrierName}</TextCell>,

  'Clean Carrier Name': ({ item }) => (
    <TextCell>{item.cleanCarrierName}</TextCell>
  ),

  'Underlying Security CUSIP': ({ item }) => (
    <TextCell>{item.assetCusip}</TextCell>
  ),

  'Underlying Security Description': ({ item }) => (
    <TextCell>{item.assetDescription}</TextCell>
  ),

  'Asset Class Level-1': ({ item }) => <TextCell>{item.assetClassL1}</TextCell>,

  'Asset Type': ({ item }) => <TextCell>{item.assetType}</TextCell>,

  'Asset Sub-Type': ({ item }) => <TextCell>{item.assetSubType}</TextCell>,

  'Market Value': ({ item }) => (
    <USDCell value={item.marketValue} fractionDigits={2} />
  ),

  'Holding %': ({ item }) => <PercentCell value={item.fundPercent} />,

  'Asset Class Level-2': ({ item }) => <TextCell>{item.assetClassL2}</TextCell>,

  'Asset Class Level-3': ({ item }) => <TextCell>{item.assetClassL3}</TextCell>,

  'Asset Class Level-4': ({ item }) => <TextCell>{item.assetClassL4}</TextCell>,

  'Team Name': ({ item }) => <TextCell>{item.teamName}</TextCell>,

  'Division Name': ({ item }) => <TextCell>{item.regionName}</TextCell>,

  'Advisor Id': ({ item }) => <TextCell>{item.registeredRep}</TextCell>,

  'Advisor Name': ({ item }) => <TextCell>{item.registeredRepName}</TextCell>,

  'Underlying Asset Class Level-1': ({ item }) => (
    <TextCell>{item.assetClassL1}</TextCell>
  ),

  'Underlying Asset Class Level-2': ({ item }) => (
    <TextCell>{item.assetClassL2}</TextCell>
  ),

  'Underlying Asset Class Level-3': ({ item }) => (
    <TextCell>{item.assetClassL3}</TextCell>
  ),

  'Underlying Asset Class Level-4': ({ item }) => (
    <TextCell>{item.assetClassL4}</TextCell>
  ),
  'Underlying Asset Type': ({ item }) => <TextCell>{item.assetType}</TextCell>,
  'Underlying Asset Sub Type': ({ item }) => (
    <TextCell>{item.assetSubType}</TextCell>
  )
}
