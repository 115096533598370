import { combineReducers } from 'redux'
import {
  hurdleSummaryFetchReducer,
  hurdleSummaryFetchSagas
} from './hurdleSummaryFetch'
import {
  revenueSummaryFetchReducer,
  revenueSummaryFetchSagas
} from './revenueSummaryFetch'

export const revenueTilesReducer = combineReducers({
  revenueSummaryFetch: revenueSummaryFetchReducer,
  hurdleSummaryFetch: hurdleSummaryFetchReducer
})

export const revenueTilesSagas = [
  ...revenueSummaryFetchSagas,
  ...hurdleSummaryFetchSagas
]
