import { css } from '@emotion/react'
import { InsightButton } from './InsightButton'

const classes = {
  center: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    height: '100%',
    textAlign: 'center'
  }),
  title: css({
    color: '#002A3A',
    fontSize: '16px'
  })
}

export const InsightEmpty: React.FC = () => {
  return (
    <div css={classes.center}>
      <p css={classes.title}>
        <b>There are currently no Insights for this household.</b>
      </p>
      <p>
        Insights is a tool that provides data-driven discoveries about your book
        of business. Insights can provide unique observations and highlight
        opportunities to better serve your clients. While we are in the early
        phases of developing Insights, you may find households with no insights,
        but we expect this to happen less frequently when we introduce future
        insights.
      </p>
      <p>
        <InsightButton>View Insights Dashboard</InsightButton>
      </p>
    </div>
  )
}
