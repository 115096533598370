import { useMemo } from 'react'
import { isNotNullOrUndefined } from 'shared/guards'
import { useRdot360AccountContext } from '../../store/rdot360Context'
import { useRdot360BalancesContext } from '../../store/rdot360Context/useRdot360BalancesContext'
import { useRdot360CagsContext } from '../../store/rdot360Context/useRdot360CagsContext'
import { BalanceItem } from './BalanceSummaryTableColumns'

export const useBalancesData = (skipCag: boolean) => {
  const { selectedAccountBalances } = useRdot360BalancesContext()
  const { accountLookupByAccountIdOrKey } = useRdot360AccountContext()

  const dataWithCustodian = useMemo(
    () =>
      selectedAccountBalances.map(
        (x): BalanceItem => ({
          ...x,
          account: x.key ? accountLookupByAccountIdOrKey[x.key] : undefined
        })
      ),
    [accountLookupByAccountIdOrKey, selectedAccountBalances]
  )

  const { cagsWithUngrouped } = useRdot360CagsContext(skipCag)

  const cagData = useMemo(() => {
    if (skipCag) {
      return []
    }
    return cagsWithUngrouped
      ?.map((cag) =>
        cag?.rcm_cag_rcm_financialaccount?.map(
          (account) =>
            dataWithCustodian.find(
              (x) => x.account?.accountId === account?.rcm_cdmaccountid
            ) &&
            ({
              ...dataWithCustodian.find(
                (x) => x.account?.accountId === account?.rcm_cdmaccountid
              ),
              cag: cag?.rcm_name
            } as BalanceItem)
        )
      )
      ?.flat()
      ?.filter(isNotNullOrUndefined)
  }, [cagsWithUngrouped, dataWithCustodian, skipCag])

  return {
    dataWithCustodian,
    cagData
  }
}
