import { Panel, PanelType } from '@fluentui/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HouseholdDetail } from './HouseholdDetail'
import { HouseholdHeader } from './HouseholdHeader'
import {
  getIsHouseholdDetailPanelOpen,
  householdDetailUiActions
} from './store'

export const HouseholdDetailPanel: React.FC = () => {
  const dispatch = useDispatch()
  const onDismiss = () => dispatch(householdDetailUiActions.closePanel())
  const isPanelOpen = useSelector(getIsHouseholdDetailPanelOpen)

  if (!isPanelOpen) {
    return null
  }

  return (
    <Panel
      onRenderHeader={renderHeader}
      onOuterClick={() => {
        return
      }}
      isOpen={isPanelOpen}
      onDismiss={onDismiss}
      isBlocking={true}
      isLightDismiss={true}
      closeButtonAriaLabel="Close"
      type={PanelType.custom}
      customWidth="800px"
    >
      <HouseholdDetail />
    </Panel>
  )
}

const renderHeader = (): JSX.Element | null => {
  return (
    <div
      style={{
        alignSelf: 'flex-start',
        flexGrow: 1,
        padding: '0 0 5px 24px'
      }}
    >
      <HouseholdHeader />
    </div>
  )
}
