import axios, { CancelTokenSource } from 'axios'
import { parseISO } from 'date-fns'
import { keyBy } from 'lodash'
import { flow } from 'lodash/fp'
import { combineReducers, Reducer } from 'redux'
import { createSelector } from 'reselect'
import { call, cancelled, put, select, takeEvery } from 'typed-redux-saga'
import { createAction } from 'typesafe-actions'
import { getHurdles, IHurdle } from '../../../../../../../../api/datahub'
import {
  OdataFilterOperatorEnum,
  OdataPropertyFilterGroup
} from '../../../../../../../../api/odata'
import { IOdataRequest } from '../../../../../../../../api/odata.types'
import { convertColumnTypeToFilterType } from '../../../../../../../../features/DataList/service'
import {
  IListsFacetFilter,
  IListsFilter
} from '../../../../../../../../features/Lists/core/contracts/IListsFilter'
import { IOdataListChunkPayload } from '../../../../../../../../features/OdataList/common/IOdataListDataActions'
import { IOdataListDataState } from '../../../../../../../../features/OdataList/common/IOdataListDataState'
import { IOdataListUiState } from '../../../../../../../../features/OdataList/common/IOdataListUiState'
import {
  IOdataListColumnDefinition,
  IWithGetValue
} from '../../../../../../../../features/OdataList/common/types'
import { createOdataListDataStore } from '../../../../../../../../features/OdataList/store/odataListDataStore'
import { createOdataListUiStore } from '../../../../../../../../features/OdataList/store/odataListUiStore'
import { IApiOptions } from '../../../../../../../../shared/contracts/IApiOptions'
import { AppState } from '../../../../../../../../store'
import { getRockefellerApiOptions } from '../../../../../../../../store/shared/sagas'
import { getHurdleDeleteLoading } from '../../store/hurdleDelete'
import { getHurdlePostLoading } from '../../store/hurdlePost'
import {
  hurdleDefinitionExportReducer,
  hurdleDefinitionExportSagas
} from './export'

export type HurdleDefinitionListColumnName =
  | 'Hurdle Name'
  | 'Team / Individual'
  | 'Advertised Production'
  | 'Description'
  | 'Created'
  | 'Probability'

export interface IHurdleDefinitionListColumnDefinition
  extends IOdataListColumnDefinition,
    IWithGetValue<IHurdle> {
  name: HurdleDefinitionListColumnName
}

const defaultColumn: Partial<IHurdleDefinitionListColumnDefinition> = {
  filterable: true,
  sortable: true
}

export const hurdleDefinitionListColumns: IHurdleDefinitionListColumnDefinition[] =
  [
    {
      ...defaultColumn,
      name: 'Hurdle Name',
      select: [
        'name',
        'completionType',
        'hurdleId',
        'notes',
        'accrualStartDate',
        'repCodes',
        'originalId'
      ],
      dataPath: 'name',
      type: 'string',
      width: 200,
      searchFields: ['name'],
      getValue: ({ name }) => name
    },
    {
      ...defaultColumn,
      name: 'Team / Individual',
      select: [
        'entityName',
        'entityId',
        'entityType',
        'entityStartDate',
        'term'
      ],
      dataPath: 'entityName',
      type: 'string',
      width: 200,
      searchFields: ['entityName'],
      getValue: ({ entityName }) => entityName
    },
    {
      ...defaultColumn,
      name: 'Advertised Production',
      dataPath: 'advertisedT12Revenue',
      type: 'number',
      width: 150,
      getValue: ({ advertisedT12Revenue }) => advertisedT12Revenue
    },
    {
      ...defaultColumn,
      name: 'Description',
      type: 'string',
      width: 400,
      filterable: false,
      sortable: false,
      getValue: () => ''
    },
    {
      ...defaultColumn,
      name: 'Probability',
      dataPath: 'probability',
      type: 'number',
      width: 100,
      getValue: ({ probability }) => probability
    },
    {
      ...defaultColumn,
      name: 'Created',
      select: ['createdDate', 'createdBy'],
      dataPath: 'createdDate',
      type: 'date',
      width: 150,
      getValue: ({ createdDate }) => createdDate && parseISO(createdDate)
    }
  ]

const getDatahubApiOptions = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)
  return [apiOptions, source] as [IApiOptions, CancelTokenSource]
}
export const fetchHurdles = function* (request: IOdataRequest) {
  const [apiOptions, cancelTokenSource] = yield* call(getDatahubApiOptions)
  try {
    const result = yield* call(getHurdles, apiOptions, {
      ...request,
      expand: request?.expand?.length
        ? request?.expand
        : ['measurements($expand=metrics($expand=payouts))']
    })
    return result
  } finally {
    if (yield* cancelled()) {
      cancelTokenSource.cancel()
    }
  }
}

const rootListSelector = (state: AppState) =>
  state.modules.advisory.modules.teams.modules.hurdles.features
    .hurdleDefinitionList

const dataStore = createOdataListDataStore(
  '@modules/@teams/@hurdleDefinitionList',
  fetchHurdles,
  flow(rootListSelector, ({ data }) => data)
)
export const {
  actions: hurdleDefinitionListDataActions,
  selectors: hurdleDefinitionListDataSelectors
} = dataStore
const uiFilters = keyBy(
  hurdleDefinitionListColumns
    .filter((x) => x.filterable)
    .map((column): IListsFilter => {
      const base = {
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        dataPath: column.dataPath,
        hasValue: false
      }

      return base
    }),
  ({ id }) => id
)
const convertRequestTypeColumnFilterToOdataFilter = (
  filter: IListsFilter
): OdataPropertyFilterGroup | undefined => {
  const { dataPath, values } = filter as IListsFacetFilter
  const hasValue = values && values.length > 0
  if (!hasValue || !dataPath) {
    return
  }

  return {
    and: [
      {
        operator: OdataFilterOperatorEnum.eq,
        value: values?.[0],
        path: dataPath,
        type: 'string'
      }
    ]
  }
}

const uiStore = createOdataListUiStore({
  prefix: '@modules/@teams/@hurdleDefinitionList',
  initialState: {
    columns: hurdleDefinitionListColumns,
    filters: uiFilters,
    sortBy: { direction: 'desc', name: 'Created' }
  },
  rootSelector: flow(rootListSelector, (x) => x.ui),
  dataStore,
  onConvertToOdataFilter: (filter: IListsFilter) => {
    return convertRequestTypeColumnFilterToOdataFilter(filter)
  }
})

export const {
  selectors: hurdleDefinitionListUiSelectors,
  actions: hurdleDefinitionListUiActions
} = uiStore
export const hurdleDefinitionListReducer: Reducer<{
  data: IOdataListDataState<IHurdle>
  ui: IOdataListUiState
  export: any
}> = combineReducers({
  data: dataStore.reducer,
  ui: uiStore.reducer,
  export: hurdleDefinitionExportReducer
})

export const getIsHurdlesLoading = createSelector(
  [getHurdlePostLoading, getHurdleDeleteLoading],
  (postLoading, deleteLoading) => {
    return postLoading || deleteLoading
  }
)

const ADD = '@modules/@teams/@hurdleDefinitionList/ADD'
const DELETE = '@modules/@teams/@hurdleDefinitionList/DELETE'
export const hurdleDefinitionListUpdateActions = {
  addOrUpdate: createAction(ADD)<IHurdle>(),
  delete: createAction(DELETE)<number>()
}

const hurdleDefinitionListUpdateSagas = [
  () =>
    takeEvery(
      hurdleDefinitionListUpdateActions.addOrUpdate,
      function* (
        action: ReturnType<typeof hurdleDefinitionListUpdateActions.addOrUpdate>
      ) {
        const newItem = action.payload
        const chunks = yield* select(
          hurdleDefinitionListDataSelectors.getChunks
        )
        if (!chunks?.length) {
          return
        }
        const [firstChunk] = chunks
        const chunk =
          chunks.find((x) =>
            x?.value?.some((x) => x.hurdleId === newItem.hurdleId)
          ) || firstChunk

        if (!chunk?.value?.length) {
          return
        }

        const chunkValueCopy = [...chunk.value]
        const index = chunk?.value?.findIndex(
          (x) => x.hurdleId === newItem.hurdleId
        )

        chunkValueCopy.splice(
          index >= 0 ? index : 0,
          index >= 0 ? 1 : 0,
          newItem
        )

        const updateChunkPayload = {
          index: chunks.indexOf(chunk),
          result: {
            ...chunk,
            value: chunkValueCopy
          }
        }

        if (!updateChunkPayload) {
          return
        }

        yield put(
          hurdleDefinitionListDataActions.updateChunk(updateChunkPayload)
        )
      }
    ),
  () =>
    takeEvery(
      hurdleDefinitionListUpdateActions.delete,
      function* (
        action: ReturnType<typeof hurdleDefinitionListUpdateActions.delete>
      ) {
        const chunks = yield* select(
          hurdleDefinitionListDataSelectors.getChunks
        )
        if (!chunks) {
          return
        }

        let updateChunkPayload: IOdataListChunkPayload<IHurdle> | undefined
        chunks?.some((x, i) => {
          if (!x.value) {
            return
          }

          const itemIndex = x.value.findIndex(
            (x) => x.hurdleId === action.payload
          )

          if (itemIndex < 0) {
            return
          }

          const itemsCopy = [...x.value]
          itemsCopy.splice(itemIndex, 1)
          updateChunkPayload = {
            index: i,
            result: { ...x, value: itemsCopy }
          }
        })

        if (!updateChunkPayload) {
          return
        }

        yield put(
          hurdleDefinitionListDataActions.updateChunk(updateChunkPayload)
        )
      }
    )
]

export const hurdleDefinitionListSagas = [
  ...dataStore.sagas,
  ...uiStore.sagas,
  ...hurdleDefinitionListUpdateSagas,
  ...hurdleDefinitionExportSagas
]
