import { useEffect, useMemo, useState } from 'react'
import {
  getHighcharts,
  highcharts,
  ensureOrganizationModule,
  HighchartsImport
} from 'shared/highcharts/service'

export const useHighcharts = () => {
  const [, setIsFetching] = useState<boolean>(!!highcharts)
  useEffect(() => {
    if (highcharts) {
      return
    }

    setIsFetching(true)
    const init = async () => {
      await getHighcharts()
      setIsFetching(false)
    }

    init()
  }, [])

  return highcharts
}

export const useHighchartsWithOrgAndSankeyModule = () => {
  const highcharts = useHighcharts()
  const [isFetching, setIsFetching] = useState<boolean>(true)
  useEffect(() => {
    if (!highcharts) {
      return
    }

    const init = async () => {
      await ensureOrganizationModule(highcharts)
      setIsFetching(false)
    }

    init()
  }, [highcharts])

  return isFetching ? undefined : highcharts
}

export const useHighchartsOptions = (
  callback: (highcharts: HighchartsImport) => Highcharts.Options
) => {
  const highcharts = useHighcharts()
  const options = useMemo(
    () => highcharts && callback(highcharts),
    [callback, highcharts]
  )
  return options
}
