import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { IHurdle } from '../../../../../../../../api/datahub'
import { hurdleDeleteActions } from '../../store/hurdleDelete'
import { hurdleEditFormActions } from '../HurdleEdit/store/hurdleEditForm'
import { hurdleEditPanelActions } from '../HurdleEdit/store/hurdleEditPanel'
import { HurdleMenu } from './HurdleMenu'

export interface IHurdleMenuContainerProps {
  hurdle: IHurdle
}

export const HurdleMenuContainer: React.FC<IHurdleMenuContainerProps> = ({
  hurdle
}) => {
  const dispatch = useDispatch()

  const onEditHurdle = useCallback(
    async (hurdle: IHurdle) => {
      dispatch(hurdleEditPanelActions.open())
      dispatch(hurdleEditFormActions.updateHurdle(hurdle))
    },
    [dispatch]
  )
  const onCopyHurdle = useCallback(
    async (hurdle: IHurdle) => {
      dispatch(hurdleEditPanelActions.open())
      dispatch(hurdleEditFormActions.updateHurdle(hurdle))
    },
    [dispatch]
  )
  const onDeleteHurdle = useCallback(
    (hurdle: IHurdle) => {
      dispatch(hurdleDeleteActions.request(hurdle.hurdleId))
    },
    [dispatch]
  )
  return (
    <HurdleMenu
      hurdle={hurdle}
      onEditHurdle={onEditHurdle}
      onCopyHurdle={onCopyHurdle}
      onDeleteHurdle={onDeleteHurdle}
    />
  )
}
