import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { UIInteraction } from '../InteractionsPanel'

const { actions, reducer } = createSlice({
  name: 'createEditInteractionPanel',
  initialState: {} as ICreateEditInteractionPanelState,
  reducers: {
    open: (state, action: PayloadAction<UIInteraction | undefined>) => ({
      isOpen: true,
      selectedInteraction: action.payload
    }),
    close: () => ({
      isOpen: false,
      error: undefined,
      selectedInteraction: undefined
    }),
    failure: (state, action: PayloadAction<Error>) => ({
      ...state,
      error: action.payload
    })
  }
})

export { reducer as createEditInteractionPanelReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.secondaryHeader.interactions
    .createEditInteractionPanel

const getIsPanelOpen = flow(rootSelector, (x) => x.isOpen)
const getPanelError = flow(rootSelector, (x) => x.error)
const getSelectedInteractionData = flow(
  rootSelector,
  (x) => x.selectedInteraction
)
export interface ICreateEditInteractionPanelState {
  isOpen?: boolean
  error?: Error
  selectedInteraction?: UIInteraction
}

export const useCreateEditInteractionPanel = () => {
  const dispatch = useDispatch()

  const close = useCallback(() => {
    dispatch(actions.close())
  }, [dispatch])

  const open = useCallback(
    (selectedInteraction?: UIInteraction) => {
      dispatch(actions.open(selectedInteraction))
    },
    [dispatch]
  )

  const setError = useCallback(
    (e: Error) => {
      dispatch(actions.failure(e))
    },
    [dispatch]
  )

  const isOpen = useSelector(getIsPanelOpen)
  const selectedInteractionData = useSelector(getSelectedInteractionData)
  const error = useSelector(getPanelError)

  return {
    close,
    open,
    setError,
    isOpen,
    error,
    selectedInteractionData
  }
}
