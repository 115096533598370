import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { useClientDashboardTilePreferences } from '../../hooks/useClientDashboardPreferences'
import { ClientDashboardTiles } from '../../shared/types'
import { IncomeApiMode } from '../../store/holdingsApi'

const tileName = ClientDashboardTiles.incomeTile

interface IIncomeFeatureState {
  financialYear?: number
}

const incomeFeatureSlice = createSlice({
  name: 'incomeFeatureState',
  initialState: {} as IIncomeFeatureState,
  reducers: {
    setFinancialYear: (state, action: PayloadAction<number>) => {
      state.financialYear = action.payload
    }
  }
})

export const { actions: incomeFeatureActions, reducer: incomeFeatureReducer } =
  incomeFeatureSlice

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.features.income

const getFinancialYear = (state: AppState) => rootSelector(state).financialYear

export const useIncomeFeatureStore = () => {
  const dispatch = useDispatch()

  const financialYear = useSelector(getFinancialYear)

  const { setTilePreferences, tilePreferences } =
    useClientDashboardTilePreferences(tileName)
  const {
    includeOptionPremiums = false,
    incomeMode = 'historical' as IncomeApiMode
  } = tilePreferences ?? {}

  const setIncludeOptionPremiums = useCallback(
    (includeOptionPremiums: boolean) => {
      setTilePreferences({
        includeOptionPremiums
      })
    },
    [setTilePreferences]
  )

  const setIncomeMode = useCallback(
    (newIncomeMode: IncomeApiMode) => {
      setTilePreferences({
        incomeMode: newIncomeMode
      })
    },
    [setTilePreferences]
  )

  const setFinancialYear = useCallback(
    (year: number) => {
      dispatch(incomeFeatureActions.setFinancialYear(year))
    },
    [dispatch]
  )

  return {
    includeOptionPremiums,
    setIncludeOptionPremiums,
    incomeMode,
    setIncomeMode,
    financialYear,
    setFinancialYear
  }
}
