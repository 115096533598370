import { advisoryModuleStyles } from 'modules/Advisory/shared/styles'
import { Icon } from './Icon'

type ISnackBarType = 'Success' | 'Warning' | 'Failure' | 'Info'

export const SnackBar: React.FC<{
  type: ISnackBarType
  message: string
  actionButtonLabel?: string
  onActionButtonClicked?: () => void
  onDismissClicked?: () => void
}> = ({
  type,
  message,
  actionButtonLabel,
  onActionButtonClicked,
  onDismissClicked
}) => {
  return (
    <div
      css={(theme) => ({
        background: `${
          type === 'Success'
            ? theme.colors.extraGreen1
            : type === 'Warning'
            ? theme.colors.secondaryYellow2
            : type === 'Failure'
            ? theme.colors.secondaryRed1
            : theme.colors.tertiaryBlue2
        }77`,
        padding: '6px 8px',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'space-between'
      })}
    >
      <div css={{ display: 'flex', gridColumnGap: 10, alignContent: 'center' }}>
        <Icon type={type} height={20} width={20} />
        <div
          css={() => [
            advisoryModuleStyles.fancyScroll,
            {
              lineHeight: '20px',
              maxHeight: '100px'
            }
          ]}
        >
          {message}
        </div>
      </div>
      <div css={{ display: 'flex', gridColumnGap: 10 }}>
        <div
          css={() => ({
            cursor: 'pointer',
            lineHeight: '20px'
          })}
          onClick={onActionButtonClicked}
        >
          {actionButtonLabel}
        </div>
        {onDismissClicked && (
          <Icon
            type="CancelClose"
            width={18}
            height={18}
            onClick={onDismissClicked}
          />
        )}
      </div>
    </div>
  )
}
