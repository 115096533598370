import { combineReducers } from 'redux'
import {
  hurdlesWithProgressFetchReducer,
  hurdlesWithProgressFetchSagas
} from './hurdlesWithProgressFetch'

export const advisorHurdleTableReducer = combineReducers({
  hurdlesWithProgressFetch: hurdlesWithProgressFetchReducer
})

export const advisorHurdleTableSagas = [...hurdlesWithProgressFetchSagas]
