import { createReducer } from 'typesafe-actions'
import {
  PostingRulesActions,
  postingRulesDataActions,
  institutionsDataActions,
  transactionsDataActions,
  payeesDataActions,
  mainAccountsDataActions,
  subAccountsDataActions,
  addEditRuleDataActions,
  projectDataActions,
  personDataActions
} from './actions'
import {
  IPostingRules,
  IInstitution,
  ITransaction,
  IPayee,
  IMainAccount,
  ISubAccount,
  IAddEditResponse,
  IProject,
  IPerson
} from './types'

export interface IPostingRulesState {
  postingRules: {
    rules?: IPostingRules[]
    isLoading: boolean
    error?: Error
  }
  institutions?: {
    institutionList?: IInstitution[]
    isLoading: boolean
    error?: Error
  }
  transactions?: {
    transactionList?: ITransaction[]
    isLoading: boolean
    error?: Error
  }
  payees?: {
    payeeList?: IPayee[]
    isLoading: boolean
    error?: Error
  }
  mainAccounts?: {
    mainAccountList?: IMainAccount[]
    isLoading: boolean
    error?: Error
  }
  subAccounts?: {
    subAccountList?: ISubAccount[]
    isLoading: boolean
    error?: Error
  }
  addEditRule?: {
    isLoading: boolean
    error?: Error
    response?: IAddEditResponse
  }
  projectList: {
    project?: IProject[]
    isLoading: boolean
    error?: Error
  }
  PersonList: {
    person?: IPerson[]
    isLoading: boolean
    error?: Error
  }
}

const initialState: IPostingRulesState = {
  postingRules: {
    isLoading: false
  },
  institutions: { isLoading: false },
  transactions: { isLoading: false },
  payees: { isLoading: false },
  mainAccounts: { isLoading: false },
  addEditRule: undefined,
  projectList: {
    isLoading: false
  },
  PersonList: {
    isLoading: false
  }
}

export const postingRulesReducer = createReducer<
  IPostingRulesState,
  PostingRulesActions
>(initialState)
  .handleAction(postingRulesDataActions.request, (state) => ({
    ...state,
    postingRules: {
      isLoading: true,
      error: undefined
    },
    addEditRule: undefined
  }))
  .handleAction(postingRulesDataActions.success, (state, action) => ({
    ...state,
    postingRules: {
      isLoading: false,
      error: undefined,
      rules: action.payload
    }
  }))
  .handleAction(postingRulesDataActions.failure, (state, action) => ({
    ...state,
    postingRules: {
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(institutionsDataActions.request, (state) => ({
    ...state,
    institutions: {
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(institutionsDataActions.success, (state, action) => ({
    ...state,
    institutions: {
      isLoading: false,
      error: undefined,
      institutionList: action.payload
    }
  }))
  .handleAction(institutionsDataActions.failure, (state, action) => ({
    ...state,
    institutions: {
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(transactionsDataActions.request, (state) => ({
    ...state,
    transactions: {
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(transactionsDataActions.success, (state, action) => ({
    ...state,
    transactions: {
      isLoading: false,
      error: undefined,
      transactionList: action.payload
    }
  }))
  .handleAction(transactionsDataActions.failure, (state, action) => ({
    ...state,
    transactions: {
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(payeesDataActions.request, (state) => ({
    ...state,
    payees: {
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(payeesDataActions.success, (state, action) => ({
    ...state,
    payees: {
      isLoading: false,
      error: undefined,
      payeeList: action.payload
    }
  }))
  .handleAction(payeesDataActions.failure, (state, action) => ({
    ...state,
    payees: {
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(mainAccountsDataActions.request, (state) => ({
    ...state,
    mainAccounts: {
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(mainAccountsDataActions.success, (state, action) => ({
    ...state,
    mainAccounts: {
      isLoading: false,
      error: undefined,
      mainAccountList: action.payload
    }
  }))
  .handleAction(mainAccountsDataActions.failure, (state, action) => ({
    ...state,
    mainAccounts: {
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(subAccountsDataActions.request, (state) => ({
    ...state,
    subAccounts: {
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(subAccountsDataActions.success, (state, action) => ({
    ...state,
    subAccounts: {
      isLoading: false,
      error: undefined,
      subAccountList: action.payload
    }
  }))
  .handleAction(subAccountsDataActions.failure, (state, action) => ({
    ...state,
    subAccounts: {
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(addEditRuleDataActions.request, (state) => ({
    ...state,
    addEditRule: {
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(addEditRuleDataActions.success, (state, action) => ({
    ...state,
    addEditRule: {
      isLoading: false,
      error: undefined,
      response: action.payload
    }
  }))
  .handleAction(addEditRuleDataActions.failure, (state, action) => ({
    ...state,
    addEditRule: {
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(projectDataActions.request, (state) => ({
    ...state,
    projectList: {
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(projectDataActions.success, (state, action) => ({
    ...state,
    projectList: {
      isLoading: false,
      error: undefined,
      project: action.payload
    }
  }))
  .handleAction(projectDataActions.failure, (state, action) => ({
    ...state,
    projectList: {
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(personDataActions.request, (state) => ({
    ...state,
    PersonList: {
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(personDataActions.success, (state, action) => ({
    ...state,
    PersonList: {
      isLoading: false,
      error: undefined,
      person: action.payload
    }
  }))
  .handleAction(personDataActions.failure, (state, action) => ({
    ...state,
    PersonList: {
      isLoading: false,
      error: action.payload
    }
  }))
