import { range, uniqBy } from 'lodash'
import { AllEffect, StrictEffect } from 'redux-saga/effects'
import { all, call } from 'typed-redux-saga'
import { ISearchParams, SearchIndices } from '../../../api/common.types'
import { IOdataRequest } from '../../../api/odata.types'
import { IOdataResult } from '../../../shared/contracts/IOdataResult'
import { search } from '.'

export const throttle = function* <T>(
  actions: (() => Generator<StrictEffect, T, unknown>)[],
  limit: number
): Generator<StrictEffect | AllEffect<any>, T[], unknown> {
  const copy = [...actions]
  const doNextAction = function* (): Generator<StrictEffect, T[], unknown> {
    const action = copy.shift()
    if (!action) {
      return []
    }
    const current = yield* call(action)
    const next = yield* call(doNextAction)
    return [current, ...next]
  }

  const threads = range(0, limit).map(doNextAction)

  const results = yield* all(threads)

  return results.flat()
}

export interface IPagedOdataApiResult<T> {
  index: number
  result: IOdataResult<T>
}

export const getAllPagedOdataApiResults = function* <T, U>(
  request: U,
  getOdataResults: (
    request: U & Pick<IOdataRequest, 'top' | 'skip' | 'count'>
  ) => Generator<StrictEffect, IOdataResult<T>, unknown>
) {
  const chunkSize = 200
  const initialChunkSize = 50

  const peekResults = yield* call(getOdataResults, {
    ...request,
    top: initialChunkSize,
    count: true
  })

  const first = { index: 0, result: peekResults } as IPagedOdataApiResult<T>

  const totalCount = peekResults['@odata.count'] || 0

  if (totalCount <= initialChunkSize) {
    return [first]
  }

  const iterations = Math.ceil((totalCount - initialChunkSize) / chunkSize)
  const action = (i: number) =>
    function* () {
      const skip = i * chunkSize + initialChunkSize
      let top = chunkSize

      if (skip + top > totalCount) {
        top = totalCount - skip
      }

      const chunk = yield* call(getOdataResults, {
        ...request,
        top,
        skip
      })

      return { index: i + 1, result: chunk } as IPagedOdataApiResult<T>
    }

  const actions = Array(iterations)
    .fill(1)
    .map((_, i) => i)
    .map(action)

  const remaining = yield* throttle(actions, 5)
  const results = [first, ...remaining]
  return results
}

export const getSearchApiResults = <T>(index: SearchIndices) =>
  function* (request: ISearchParams) {
    const { orderBy = [] } = request

    const params: ISearchParams = {
      ...request,
      orderBy: uniqBy(
        [...orderBy, { dataPath: 'id', direction: 'desc' }],
        ({ dataPath }) => dataPath
      )
    }

    const results = yield* call(search, index, params)

    return results as IOdataResult<T>
  }
