import {
  Calendar,
  CommandBar,
  CommandBarButton,
  ContextualMenu,
  DateRangeType,
  ICommandBarItemProps,
  IStyle,
  makeStyles,
  ProgressIndicator,
  Stack,
  Text,
  TooltipHost,
  ITooltipProps,
  DetailsListLayoutMode
} from '@fluentui/react'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { IDataTableSortBy } from 'features/DataList/common/types'
import { constants } from 'modules/Advisory/shared/theme'
import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { FormattedNumber } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { IHurdleProgressMeasurement } from '../../../../../../../../api/datahub'
import { HurdleStatusIndicator } from '../../../../../../../../features/Hurdles/HurdleStatusIndicator'
import { IListsDateRangeFilter } from '../../../../../../../../features/Lists/core/contracts/IListsFilter'
import {
  IOdataDataListCellRenderProps,
  OdataList
} from '../../../../../../../../features/OdataList/containers/OdataList'
import { parseDateISOStringInLocalTimezone } from '../../../../../../../../shared'
import { USD } from '../../../../../../../../shared/components/Formatting'
import { PaletteComponent } from '../../../../../../../../shared/components/Palette'
import { chartColors } from '../../../../../../../../shared/services/theme'
import { getIsHurdleAdmin } from '../../store/selectors'
import {
  getIsHurdleManagementViewFormattedExportLoading,
  hurdleManagementViewExportFormattedActions
} from './store/exportFormatted'
import {
  getIsHurdleManagementViewRawExportLoading,
  hurdleManagementViewExportRawActions
} from './store/exportRaw'
import {
  hurdleProgressManagementListUiActions,
  HurdleProgressManagementListColumnName,
  hurdleProgressManagementListUiSelectors,
  getUpdatedProgressManagementFacetFilter
} from './store/hurdleProgressManagementList'
import {
  getRerunMeasurementsLoading,
  rerunMeasurementsActions
} from './store/rerunMeasurements'

export const useActiveHurdleStyles = makeStyles((theme) => {
  const activeHurdleTable: IStyle = {
    tableLayout: 'fixed',
    width: '100%',
    borderSpacing: '0',
    'th, td': {
      padding: '2px 8px',
      textAlign: 'right',
      width: '75px',
      borderRight: `solid 2px ${theme.semanticColors.bodyBackground}`,
      borderLeft: `solid 2px ${theme.semanticColors.bodyBackground}`,
      borderTop: `solid 5px ${theme.semanticColors.bodyBackground}`,
      backgroundColor: `${theme.palette.neutralLighter}cc`
    },
    'tbody td.individual:first-child': {
      paddingLeft: '35px',
      width: '200px'
    },
    'tbody td.individual': {
      backgroundColor: `${theme.palette.neutralLighter}cc`,
      borderTop: '0px'
    },
    'tr th:first-child, tr td:first-child': {
      textAlign: 'left'
    },
    'thead th': {
      borderBottom: `solid 3px ${theme.semanticColors.bodyBackground}`,
      paddingTop: '5px',
      paddingBottom: '5px',
      fontWeight: 'bold'
    }
  }

  return {
    activeHurdleTable
  }
})

const CellComponents: Record<
  HurdleProgressManagementListColumnName,
  React.FC<IOdataDataListCellRenderProps<IHurdleProgressMeasurement>>
> = {
  Status: ({ item }) => <StatusCell item={item} />,
  'Hurdle Name': ({ item }) => (
    <Stack styles={{ root: { minWidth: 0 } }}>
      <Text nowrap={true} block={true}>
        {item.name}
      </Text>
      <Text
        variant="small"
        nowrap={true}
        block={true}
        styles={{ root: { fontWeight: 'bold' } }}
      >
        {item.entityName}
      </Text>
    </Stack>
  ),
  'Measurement Period Date': ({ item }) => (
    <Stack styles={{ root: { minWidth: 0 } }}>
      <Text nowrap={true} block={true}>
        {formatDate(item.currentPeriodDate)}
      </Text>
      <Text
        nowrap={true}
        block={true}
        variant="small"
        styles={{ root: { fontWeight: 'bold' } }}
      >
        {item.glMonthClose}
      </Text>
    </Stack>
  ),
  'Target Date': ({ item }) => (
    <Stack styles={{ root: { minWidth: 0 } }}>
      <Text nowrap={true} block={true}>
        {formatDate(item.hurdleDate)}
      </Text>
      <Text
        variant="small"
        nowrap={true}
        block={true}
        styles={{ root: { fontWeight: 'bold' } }}
      >
        {item.intervalofMeasurement}
      </Text>
    </Stack>
  ),
  'Target Metric Value': ({ item }) => <TargetMetric hurdle={item} />,
  'Current Month Annualized': ({ item }) =>
    item.metricType === 'AUS' ? (
      <>--</>
    ) : (
      <Stack style={{ flexGrow: 1, minWidth: 0 }}>
        <Text block={true} nowrap={true}>
          <USD value={item?.currentMonthAnnualized || 0} fractionDigits={0} />
        </Text>
        {!!item.currentMonthAnnualized && !!item.targetMetricValue && (
          <Text style={{ fontWeight: 'bold' }}>
            <FormattedNumber
              value={
                (item.currentMonthAnnualized / item.targetMetricValue) * 100
              }
              maximumFractionDigits={0}
              minimumFractionDigits={0}
            />
            %
          </Text>
        )}
      </Stack>
    ),
  'Trailing 12': ({ item }) =>
    item.metricType === 'AUS' ? (
      <>--</>
    ) : (
      <Stack style={{ flexGrow: 1, minWidth: 0 }}>
        <Text block={true} nowrap={true}>
          <USD value={item.currentT12 || 0} fractionDigits={0} />
        </Text>
        {!!item.currentT12 && !!item.targetMetricValue && (
          <Text style={{ fontWeight: 'bold' }}>
            <FormattedNumber
              value={(item.currentT12 / item.targetMetricValue) * 100}
              maximumFractionDigits={0}
              minimumFractionDigits={0}
            />
            %
          </Text>
        )}
      </Stack>
    ),
  'Measurement Period to Date': ({ item }) => (
    <Stack style={{ flexGrow: 1 }}>
      <USD value={item.metricMeasurementValue || 0} fractionDigits={0} />
      {!!item.metricProgress && (
        <ProgressBar
          progress={item.metricProgress}
          isEndOfMeasurementPeriod={item.isEndOfMeasurementPeriod || false}
          measuredMonthsRatio={
            (item.measuredMonths || 0) /
            (item.metricType === '3 Months Annualized' ? 3 : 12)
          }
        />
      )}
    </Stack>
  ),
  'Months In': ({ item }) => (
    <Stack horizontal={true}>
      <Text nowrap={true} block={true}>
        {item.numberofMonthsinMeasurementPeriod}
      </Text>
    </Stack>
  ),
  'Measured Months': ({ item }) => (
    <Stack styles={{ root: { minWidth: 0 } }}>
      <Text nowrap={true} block={true} style={{ textAlign: 'right' }}>
        {item.measuredMonths || 0}/
        {item.metricType === '3 Months Annualized' ? 3 : 12}
      </Text>
      <Text
        variant="small"
        nowrap={true}
        block={true}
        styles={{ root: { fontWeight: 'bold' } }}
      >
        {item.metricType}
      </Text>
    </Stack>
  ),
  Division: ({ item }) => (
    <Text nowrap={true} block={true}>
      {item.divisionName}
    </Text>
  )
}

const formatDate = (date: string | undefined) => {
  return date ? format(parseDateISOStringInLocalTimezone(date), `MMM yyyy`) : ''
}

const CellComponent: React.FC<
  IOdataDataListCellRenderProps<IHurdleProgressMeasurement>
> = memo(({ item, column }) => {
  const Component =
    CellComponents[column.name as HurdleProgressManagementListColumnName]
  return Component ? <Component item={item} column={column} /> : null
})

export const HurdleProgressManagementList: React.FC = () => {
  const dispatch = useDispatch()
  const onRawExport = useCallback(() => {
    dispatch(hurdleManagementViewExportRawActions.request())
  }, [dispatch])
  const onFormattedExport = useCallback(() => {
    dispatch(hurdleManagementViewExportFormattedActions.request())
  }, [dispatch])
  const filters = useSelector(
    hurdleProgressManagementListUiSelectors.getFilters
  )
  useEffect(() => {
    if (
      filters &&
      Object.entries(filters).some(([, filter]) => filter.hasValue)
    ) {
      return
    }
    dispatch(
      hurdleProgressManagementListUiActions.updateFilters({
        'Measurement Period Status': getUpdatedProgressManagementFacetFilter(
          'Measurement Period Status',
          ['Most Recent Closed']
        ),
        'Is Measurement Tracked': getUpdatedProgressManagementFacetFilter(
          'Is Measurement Tracked',
          ['Yes']
        ),
        Status: getUpdatedProgressManagementFacetFilter('Status', [
          'Pending',
          'Achieved',
          'Missed',
          ''
        ])
      })
    )
    dispatch(
      hurdleProgressManagementListUiActions.updateSort({
        name: 'Target Date',
        direction: 'asc'
      } as IDataTableSortBy)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])
  const isReRunLoading = useSelector(getRerunMeasurementsLoading)
  const isFetchLoading = useSelector(
    hurdleProgressManagementListUiSelectors.getIsLoading
  )
  const isLoading = useMemo(
    () => isReRunLoading || isFetchLoading,
    [isFetchLoading, isReRunLoading]
  )

  const isFormattedExportLoading = useSelector(
    getIsHurdleManagementViewFormattedExportLoading
  )
  const isRawExportLoading = useSelector(
    getIsHurdleManagementViewRawExportLoading
  )

  const isExportLoading = isFormattedExportLoading || isRawExportLoading
  const onMeasurementPeriodChange = useCallback(
    (date?: Date) => {
      if (!date) {
        return
      }

      const filterId = 'Measurement Period Date'
      dispatch(
        hurdleProgressManagementListUiActions.updateFilters({
          [filterId]: {
            ...filters?.[filterId],
            range: 'Custom',
            from: startOfMonth(date),
            to: endOfMonth(date),
            hasValue: true
          } as IListsDateRangeFilter
        })
      )
    },
    [dispatch, filters]
  )
  const isHurdleAdmin = useSelector(getIsHurdleAdmin)

  const header = useMemo(() => {
    const items: ICommandBarItemProps[] = [
      {
        key: 'Export',
        text: isExportLoading ? 'Exporting' : 'Export To Excel',
        iconProps: { iconName: 'ExcelDocument' },
        splitButtonAriaLabel: 'More Upload options',
        disabled: isExportLoading,
        subMenuProps: {
          items: [
            {
              key: 'exportRaw',
              text: 'Raw',
              onClick: onRawExport
            },
            {
              key: 'exportformatted',
              text: 'Formatted',
              onClick: onFormattedExport
            }
          ]
        }
      },
      ...(isHurdleAdmin
        ? [
            {
              key: 'Rerun',
              text: 'Rerun Measurements',
              iconProps: { iconName: 'Refresh' },
              disabled: isReRunLoading,
              onClick: () => {
                dispatch(rerunMeasurementsActions.request())
              }
            }
          ]
        : []),
      {
        key: 'measurementPeriod',
        text: 'Select Measurement Period',
        iconProps: { iconName: 'Calendar' },
        commandBarButtonAs: (props: any) => (
          <CommandBarButton
            {...props}
            menuAs={(menuProps) => (
              <ContextualMenu
                {...(menuProps || {})}
                items={menuProps?.items || []}
                onRenderMenuList={() => (
                  <Calendar
                    onSelectDate={onMeasurementPeriodChange}
                    dateRangeType={DateRangeType.Month}
                    isDayPickerVisible={false}
                    minDate={new Date(2019, 0)}
                    maxDate={new Date()}
                  />
                )}
              />
            )}
          />
        ),
        subMenuProps: {
          items: [{ key: 'filler' }]
        }
      },
      {
        key: 'Select a Preset',
        text: 'Select a Preset',
        subMenuProps: {
          items: [
            {
              key: 'endingSoonest',
              text: 'Ending Soonest',
              onClick: () => {
                dispatch(
                  hurdleProgressManagementListUiActions.resetFilters(undefined)
                )
                dispatch(
                  hurdleProgressManagementListUiActions.updateFilters({
                    'Measurement Period Status':
                      getUpdatedProgressManagementFacetFilter(
                        'Measurement Period Status',
                        ['Open']
                      )
                  })
                )
                dispatch(
                  hurdleProgressManagementListUiActions.updateSort({
                    name: 'Months In',
                    direction: 'desc'
                  } as IDataTableSortBy)
                )
              }
            },
            {
              key: 'recentlyClosed',
              text: 'Recently Closed',
              onClick: () => {
                dispatch(
                  hurdleProgressManagementListUiActions.resetFilters(undefined)
                )
                dispatch(
                  hurdleProgressManagementListUiActions.updateFilters({
                    'Measurement Period Status':
                      getUpdatedProgressManagementFacetFilter(
                        'Measurement Period Status',
                        ['Most Recent Closed']
                      )
                  })
                )
                dispatch(
                  hurdleProgressManagementListUiActions.updateFilters({
                    'Is Measurement Tracked':
                      getUpdatedProgressManagementFacetFilter(
                        'Is Measurement Tracked',
                        ['Yes']
                      )
                  })
                )
                dispatch(
                  hurdleProgressManagementListUiActions.updateSort({
                    name: 'Target Date',
                    direction: 'asc'
                  } as IDataTableSortBy)
                )
              }
            },
            {
              key: 'currentPeriod',
              text: 'Current Period',
              onClick: () => {
                dispatch(
                  hurdleProgressManagementListUiActions.resetFilters(undefined)
                )
                dispatch(
                  hurdleProgressManagementListUiActions.updateFilters({
                    'Is End Of Measurement Period':
                      getUpdatedProgressManagementFacetFilter(
                        'Is End Of Measurement Period',
                        ['Yes']
                      ),
                    'Measurement Period Status':
                      getUpdatedProgressManagementFacetFilter(
                        'Measurement Period Status',
                        ['Most Recent Closed']
                      ),
                    Status: getUpdatedProgressManagementFacetFilter('Status', [
                      'Pending',
                      'Achieved',
                      'Missed'
                    ])
                  })
                )
                dispatch(
                  hurdleProgressManagementListUiActions.updateSort({
                    name: 'Measurement Period to Date',
                    direction: 'desc'
                  } as IDataTableSortBy)
                )
              }
            },
            {
              key: 'todoItems',
              text: 'TODO Items',
              onClick: () => {
                dispatch(
                  hurdleProgressManagementListUiActions.resetFilters(undefined)
                )
                dispatch(
                  hurdleProgressManagementListUiActions.updateFilters({
                    'Is End Of Measurement Period':
                      getUpdatedProgressManagementFacetFilter(
                        'Is End Of Measurement Period',
                        ['Yes']
                      ),
                    'Measurement Period Status':
                      getUpdatedProgressManagementFacetFilter(
                        'Measurement Period Status',
                        ['Most Recent Closed']
                      ),
                    Status: getUpdatedProgressManagementFacetFilter('Status', [
                      'Pending'
                    ])
                  })
                )
                dispatch(
                  hurdleProgressManagementListUiActions.updateSort({
                    name: 'Measurement Period to Date',
                    direction: 'desc'
                  } as IDataTableSortBy)
                )
              }
            }
          ]
        }
      }
    ]
    return (
      <Stack>
        <CommandBar items={items} styles={{ root: { paddingLeft: '0px' } }} />
        <ProgressIndicator
          progressHidden={!isLoading}
          styles={{
            itemProgress: { padding: 0, margin: 0 }
          }}
        />
      </Stack>
    )
  }, [
    isExportLoading,
    onRawExport,
    onFormattedExport,
    isHurdleAdmin,
    isReRunLoading,
    isLoading,
    dispatch,
    onMeasurementPeriodChange
  ])

  return (
    <div>
      <PaletteComponent>
        <OdataList
          actions={hurdleProgressManagementListUiActions}
          selectors={hurdleProgressManagementListUiSelectors}
          onRenderCell={CellComponent}
          stickyHeaderOffset={constants.headerHeight}
          secondaryHeader={header}
          layoutMode={DetailsListLayoutMode.fixedColumns}
        />
      </PaletteComponent>
    </div>
  )
}

const TargetMetric: React.FC<{ hurdle: IHurdleProgressMeasurement }> = ({
  hurdle
}) => {
  const tooltipProps: ITooltipProps = {
    onRenderContent: () => (
      <Stack>
        <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
          <Text>Advertised T12:</Text>
          <Text style={{ fontWeight: 'bold' }}>
            <USD value={hurdle.advertisedT12Revenue || 0} fractionDigits={0} />
          </Text>
        </Stack>
      </Stack>
    )
  }
  return hurdle.metricType === 'AUS' ? (
    <Text nowrap={true} block={true}>
      <USD value={hurdle.targetMetricValue || 0} fractionDigits={0} />
    </Text>
  ) : (
    <TooltipHost tooltipProps={tooltipProps}>
      <Stack styles={{ root: { minWidth: 0 } }}>
        <Text nowrap={true} block={true}>
          <USD value={hurdle.targetMetricValue || 0} fractionDigits={0} />
        </Text>
        <Text
          variant="small"
          nowrap={true}
          block={true}
          styles={{ root: { fontWeight: 'bold' } }}
        >
          {`${hurdle.perNumber}%`}
        </Text>
      </Stack>
    </TooltipHost>
  )
}

export const ProgressBar: React.FC<{
  progress: number
  isEndOfMeasurementPeriod: boolean
  measuredMonthsRatio: number
}> = ({ progress, isEndOfMeasurementPeriod, measuredMonthsRatio }) => {
  const backgroundColor = useMemo(() => {
    return isEndOfMeasurementPeriod
      ? progress < 1
        ? `${chartColors[4]}55`
        : `${chartColors[3]}aa`
      : progress <= 0.5
      ? `${chartColors[4]}55`
      : progress <= 0.8
      ? `${chartColors[9]}55`
      : `${chartColors[3]}aa`
  }, [isEndOfMeasurementPeriod, progress])
  return (
    <div
      style={{
        position: 'relative',
        height: '25px',
        maxHeight: '20px',
        width: '100%',
        display: 'flex'
      }}
    >
      <div
        style={{
          position: 'absolute',
          right: '5px',
          top: '0',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          zIndex: 2
        }}
      >
        <Text styles={{ root: { fontWeight: 'bold' } }}>
          <FormattedNumber
            value={progress && progress > 0 ? progress * 100 : 0}
            maximumFractionDigits={1}
            minimumFractionDigits={1}
          />
        </Text>
        %
      </div>
      <div
        style={{
          height: '100%',
          width: `${progress ? (progress < 1 ? progress * 100 : 100) : 0}%`,
          backgroundColor,
          opacity: isEndOfMeasurementPeriod
            ? '1'
            : `${measuredMonthsRatio * 0.4 + 0.1}`
        }}
      />
    </div>
  )
}

const StatusCell: React.FC<{
  item: IHurdleProgressMeasurement
}> = ({ item }) => {
  const isHurdleAdmin = useSelector(getIsHurdleAdmin)
  return (
    <div style={{ textAlign: 'center', flexGrow: 1 }}>
      {item.isEndOfMeasurementPeriod ? (
        <HurdleStatusIndicator
          disableEdit={!isHurdleAdmin}
          id={item.hurdleRevenueMeasurementsId}
          status={item.status}
        />
      ) : (
        <Text>--</Text>
      )}
    </div>
  )
}
