import {
  IProcessedStyleSet,
  ITheme,
  mergeStyleSets,
  useTheme
} from '@fluentui/react'
import { flow } from 'lodash/fp'
import { useMemo } from 'react'

type ObjectOnly<TArg> = TArg extends object ? TArg : object
export const useClasses = <TStyleSet>(
  getThemedStyleSet: (theme: ITheme) => TStyleSet
): IProcessedStyleSet<ObjectOnly<TStyleSet>> => {
  const theme = useTheme()
  const result = useMemo(
    () => flow(getThemedStyleSet, mergeStyleSets)(theme),
    [theme, getThemedStyleSet]
  )
  return result as any
}
