import { constructOdataQuery } from 'api/odata'
import { IOdataRequest } from 'api/odata.types'
import axios, { CancelTokenSource } from 'axios'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { getRockefellerApiOptions } from 'store/shared'
import { call } from 'typed-redux-saga'

export const getDatahubApiOptions = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)
  return [apiOptions, source] as [IApiOptions, CancelTokenSource]
}

export const fetchOdataEndpoint = <T>(
  options: IApiOptions & { path: string },
  request: IOdataRequest
) => {
  const { apiRoot, cancelToken, accessToken, path } = options

  return axios
    .get<IOdataResult<T>>(
      `${apiRoot}/${path}?${constructOdataQuery(request)}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        cancelToken: cancelToken
      }
    )
    .then((x) => x.data)
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }

      throw e
    })
}
