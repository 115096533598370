import { Link } from '@fluentui/react'
import { useRdot360Context } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { ConnectedMaskedText } from 'shared/components/MaskedText'

export const HouseholdNameCell: React.FC<{
  householdName?: string
  householdId?: string
}> = ({ householdName, householdId }) => {
  const { setHousehold } = useRdot360Context()
  const navigate = useNavigate()
  const isRockHousehold = householdId?.startsWith('ROCK')
  const navigateToRdot360 = useCallback(
    (householdId?: string) => {
      if (!householdId) {
        return
      }
      setHousehold({ householdId })
      navigate('/advisory/client')
    },
    [navigate, setHousehold]
  )
  return householdName && isRockHousehold ? (
    <Link
      styles={{ root: { minWidth: 0 } }}
      onClick={() => navigateToRdot360(householdId)}
    >
      <ConnectedMaskedText nowrap text={householdName} />
    </Link>
  ) : (
    <ConnectedMaskedText nowrap text={householdName} />
  )
}
