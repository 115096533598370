import { PaletteComponent } from '../../../../../../shared/components/Palette'
import { ManagedAccountFeeHistoryList } from './ManagedAccountFeeHistoryList'

export const ManagedAccountFeeHistoryContainer: React.FC = () => (
  <div>
    <PaletteComponent>
      <ManagedAccountFeeHistoryList />
    </PaletteComponent>
  </div>
)
