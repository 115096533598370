import { useTheme } from '@emotion/react'
import { Stack } from '@fluentui/react'
import { Searchbox } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/Searchbox'
import { Icon } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { useCallback } from 'react'
import { useDatahubApiUtil } from '../../../store/datahub'
import DateRangeFilter from './DateRangeFilter'
import ReportCategoryFilter from './ReportCategoryFilter'
import { usePerformanceReportsUiState } from './reportsUiState'

const ReportFilters: React.FC = () => {
  const { searchText, setSearchText } = usePerformanceReportsUiState()

  const { invalidateTags } = useDatahubApiUtil()
  const invalidateCache = useCallback(
    () => invalidateTags(['performanceReports']),
    [invalidateTags]
  )
  const theme = useTheme()

  const FindRefresh: React.FC = () => {
    return (
      <Stack wrap={false} horizontal={true} css={{ paddingTop: 30 }}>
        <Stack.Item align="end">
          <Searchbox searchText={searchText} onChange={setSearchText} />
        </Stack.Item>
        <Stack.Item align="end">
          <div css={{ paddingBottom: 5, paddingLeft: 15 }}>
            <Icon
              type="Refresh"
              color={theme.colors.extraBlue2}
              onClick={invalidateCache}
              width={24}
            />
          </div>
        </Stack.Item>
      </Stack>
    )
  }

  return (
    <>
      <Stack
        horizontal
        wrap
        tokens={{ childrenGap: 15 }}
        css={{ flexGrow: 1 }}
        verticalAlign="center"
      >
        <Stack.Item>
          <ReportCategoryFilter />
        </Stack.Item>
        <Stack.Item grow disableShrink>
          <DateRangeFilter />
        </Stack.Item>
      </Stack>
      <Stack.Item>
        <FindRefresh />
      </Stack.Item>
    </>
  )
}

export default ReportFilters
