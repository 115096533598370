import { addMonths, format, startOfMonth } from 'date-fns'
import type { SeriesAreaOptions, SeriesColumnOptions } from 'highcharts'
import { groupBy, range, sumBy } from 'lodash'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { HighchartsImport, useHighchartsOptions } from 'shared/highcharts'
import { HighchartsComponent } from 'shared/highcharts/HighchartsComponent'
import { parseDateISOStringInLocalTimezone } from '../../../../../../../../shared'
import { getMostRecentClosedMonthFetchResult } from './store/mostRecentClosedMonthFetch'
import { getGroupByType } from './store/payoutsDashboardUi'

import { getNext18MonthsPayouts } from './store/potentialPayouts'

const getOptions = (highcharts: HighchartsImport): Highcharts.Options => ({
  title: {
    text: ''
  },
  yAxis: {
    title: {
      text: ''
    },
    softMin: 0
  },
  legend: {
    enabled: false
  },
  tooltip: {
    formatter: function formatTooltip(
      this: Highcharts.TooltipFormatterContextObject
    ) {
      return `<b>${this.x}</b><br/>${
        this.series.name
      }: $${highcharts.numberFormat(this.y || 0, 0, '.', ',')}
      <br/>TOTAL: $${highcharts.numberFormat(this.total || 0, 0, '.', ',')}`
    }
  },
  plotOptions: {
    column: {
      borderWidth: 0,
      pointPadding: 0,
      groupPadding: 0.1,
      shadow: false,
      stacking: 'normal',
      dataLabels: {
        enabled: false
      }
    },
    area: {
      shadow: false,
      stacking: 'normal',
      dataLabels: {
        enabled: false
      }
    }
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 400
        }
      }
    ]
  }
})

const formatStringDate = (date?: string) => {
  if (!date) {
    return
  }

  const parsed = parseDateISOStringInLocalTimezone(date)
  return formatDate(parsed)
}

const formatDate = (parsed: Date) => format(parsed, `MMM ''yy`)

export const PayoutChart: React.FC = () => {
  const mostRecentClosed = useSelector(getMostRecentClosedMonthFetchResult)
  const hurdles = useSelector(getNext18MonthsPayouts)
  const groupByType = useSelector(getGroupByType)

  const dates = useMemo(() => {
    if (!mostRecentClosed) {
      return
    }

    const start = startOfMonth(
      parseDateISOStringInLocalTimezone(mostRecentClosed)
    )
    return range(0, 18).map((x) => addMonths(start, x))
  }, [mostRecentClosed])

  const options = useHighchartsOptions(getOptions)

  const optionsWithSeries = useMemo(() => {
    if (!dates || !options) {
      return
    }

    const unknownCategory = 'Uncategorized'
    const pivotGroups =
      groupByType === 'hurdle'
        ? groupBy(hurdles, ({ name }) => name || unknownCategory)
        : groupBy(hurdles, ({ entityName }) => entityName || unknownCategory)
    const pivotKeys = Object.keys(pivotGroups)
    const series = pivotKeys.map((pivotKey) => {
      const pivotGroup = pivotGroups[pivotKey]
      const pivotDateGroups = groupBy(pivotGroup, (x) =>
        formatStringDate(x.date)
      )
      const dateData = dates.map((date) => {
        const items = pivotDateGroups[formatDate(date)] || []
        const value = sumBy(items, ({ amount }) => amount || 0)
        return {
          category: formatDate(date),
          value
        }
      })

      return {
        data: dateData,
        name: pivotKey
      }
    })

    const weightedSeries = series.map((componentSeries) => {
      const { name, data } = componentSeries

      const highChartsSeries: SeriesColumnOptions | SeriesAreaOptions = {
        name,
        data: data.map((item) => [item?.category, item.value]),
        type: 'column'
      }

      return {
        series: highChartsSeries,
        total: data.reduce((a, x) => a + (x?.value || 0), 0)
      }
    })

    return {
      ...options,
      xAxis: {
        categories: dates.map((x) => formatDate(x))
      },
      series: weightedSeries
        .sort((a, b) => a.total - b.total)
        .map((x) => x.series)
    }
  }, [dates, groupByType, hurdles, options])

  return (
    <HighchartsComponent
      options={optionsWithSeries}
      containerProps={{
        style: { height: '100%', width: '100%' }
      }}
    />
  )
}
