import { FormattedDate, FormattedTime } from 'react-intl'

export const PrintFooter: React.FC = () => {
  const today = new Date()
  return (
    <footer>
      <p>
        Note: Aggregated data is provided for your convenience and may contain
        investments held at other institutions outside of Rockefeller Financial
        LLC. To aggregate those accounts, system limitations may require
        transaction-data adjustments, and some values might vary from your
        external account statements. However, your statements always are the
        true and official record of your positions and transactions.
      </p>
      <p>(*) Indicates a pending activity.</p>
      <p>
        Generated on:{' '}
        <FormattedDate
          value={today}
          day="numeric"
          month="numeric"
          year="numeric"
        />{' '}
        <FormattedTime value={today} />
      </p>
    </footer>
  )
}
