import { getActivity } from 'api/datahub'
import { IOdataRequest } from 'api/odata.types'
import axios, { CancelTokenSource } from 'axios'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { getRockefellerApiOptions } from 'store/shared'
import { call, cancelled } from 'typed-redux-saga'

const getDatahubApiOptions = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)
  return [apiOptions, source] as [IApiOptions, CancelTokenSource]
}

export const fetchActivity = function* (request: IOdataRequest) {
  const [apiOptions, cancelTokenSource] = yield* call(getDatahubApiOptions)
  try {
    return yield* call(getActivity, apiOptions, request)
  } finally {
    if (yield* cancelled()) {
      cancelTokenSource.cancel()
    }
  }
}
