import { css } from '@emotion/react'
import { useMemo } from 'react'
import { Dropdown } from '../../../components/shared'
import { buttonStyles } from '../../../components/shared/Buttons'
import DateFromToFilter from './DateFromToFilter'
import { usePerformanceReportsUiState } from './reportsUiState'

const getClasses = () => ({
  dateRangeContainer: css({
    display: 'flex',
    alignItems: 'flex-end'
  }),
  dateRangeFilter: css({
    '& label': {
      paddingBottom: 7,
      display: 'block'
    }
  }),
  customRange: css({
    display: 'flex',
    alignItems: 'flex-end',
    paddingLeft: 10
  }),
  applyButton: css({
    width: '80px',
    marginTop: 20
  }),
  padRight: css({
    paddingRight: 20
  })
})

export type DurationOptions =
  | 'Last 60 Days'
  | 'Prior Month'
  | 'Prior 3 Months'
  | 'Prior 6 Months'
  | 'Prior 12 Months'
  | 'Prior Year'
  | 'Current Year'
  | 'Custom Range'

type SelectableOption = { key: DurationOptions; text: DurationOptions }

const durationDropdownOptions: SelectableOption[] = [
  { key: 'Last 60 Days', text: 'Last 60 Days' },
  { key: 'Prior Month', text: 'Prior Month' },
  { key: 'Prior 3 Months', text: 'Prior 3 Months' },
  { key: 'Prior 6 Months', text: 'Prior 6 Months' },
  { key: 'Prior 12 Months', text: 'Prior 12 Months' },
  { key: 'Prior Year', text: 'Prior Year' },
  { key: 'Current Year', text: 'Current Year' },
  { key: 'Custom Range', text: 'Custom Range' }
]

const DateRangeFilter: React.FC = () => {
  const classes = useMemo(() => getClasses(), [])

  const {
    dateSelection,
    setDateSelection,
    endDate,
    startDate,
    setStartDate,
    setEndDate,
    setCustomRange
  } = usePerformanceReportsUiState()

  return (
    <div css={classes.dateRangeContainer}>
      <Dropdown
        label="Date Range"
        options={durationDropdownOptions}
        selectedKey={dateSelection}
        onChange={(_, option) => {
          setDateSelection(option?.key as DurationOptions)
        }}
        css={{ minWidth: 150 }}
      />
      {dateSelection === 'Custom Range' && (
        <div css={classes.customRange}>
          <div css={classes.padRight}>
            <DateFromToFilter
              dateLabel="From Date"
              minDate={undefined}
              maxDate={endDate}
              date={startDate}
              setDate={setStartDate}
            />
          </div>
          <div css={classes.padRight}>
            <DateFromToFilter
              dateLabel="To Date"
              minDate={startDate}
              maxDate={undefined}
              date={endDate}
              setDate={setEndDate}
            />
          </div>
          <button
            css={[buttonStyles.primary, classes.applyButton]}
            onClick={() => setCustomRange(startDate, endDate)}
            disabled={!startDate || !endDate}
          >
            Apply
          </button>
        </div>
      )}
    </div>
  )
}

export default DateRangeFilter
