import { call, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { rerunMeasurements } from '../../../../../../../../../api/datahub'
import { AppState } from '../../../../../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../../../../../../../../store/shared/sagas'

export const rerunMeasurementsActions = createAsyncAction(
  '@modules/@teams/@rerunMeasurements/REQUEST',
  '@modules/@teams/@rerunMeasurements/SUCCESS',
  '@modules/@teams/@rerunMeasurements/FAILURE'
)<undefined, undefined, Error>()

export const rerunMeasurementsReducer = createAsyncReducer(
  rerunMeasurementsActions
)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.teams.modules.hurdles.features
    .hurdleProgressManagementList.rerunMeasurements

export const {
  getError: getRerunMeasurementsError,
  getIsLoading: getRerunMeasurementsLoading,
  getResult: getRerunMeasurementsResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* () {
  try {
    const apiOptions = yield* call(getRockefellerApiOptions)
    yield* call(rerunMeasurements, apiOptions)

    yield put(rerunMeasurementsActions.success())
  } catch (e: any) {
    yield put(rerunMeasurementsActions.failure(e))
  }
}

export const rerunMeasurementsSagas = [
  () => takeLatest(rerunMeasurementsActions.request, onRequest)
]
