import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

export type RevenueCategoryMode = 'L1' | 'L2'

const { actions, reducer } = createSlice({
  name: 'revenueDetailUIState',
  initialState: {
    categoryMode: 'L1'
  } as IRevenueDetailUiState,
  reducers: {
    setStartDate: (state, action: PayloadAction<string | undefined>) => {
      state.startDate = action.payload
    },
    setEndDate: (state, action: PayloadAction<string | undefined>) => {
      state.endDate = action.payload
    },
    setCategoryMode: (state, action: PayloadAction<RevenueCategoryMode>) => {
      state.categoryMode = action.payload
    },
    setCustomRange: (state, action: PayloadAction<string[]>) => {
      state.tradeReps = action.payload
    },
    setSearchText: (state, action: PayloadAction<string[]>) => {
      state.recipientReps = action.payload
    }
  }
})

export { reducer as revenueDetailUiStateReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.modules.revenue.revenueDetailUI

export const getStartDate = flow(rootSelector, (x) => x.startDate)
export const getEndDate = flow(rootSelector, (x) => x.endDate)
export const getCategoryMode = flow(rootSelector, (x) => x.categoryMode)
export const getTradeReps = flow(rootSelector, (x) => x.tradeReps)
export const getRecipientReps = flow(rootSelector, (x) => x.recipientReps)

export interface IRevenueDetailUiState {
  startDate?: string
  endDate?: string
  categoryMode: RevenueCategoryMode
  tradeReps?: string[]
  recipientReps?: string[]
}

export const useRevenueDetailUi = () => {
  const dispatch = useDispatch()
  const setStartDate = useCallback(
    (startDate?: string) => {
      dispatch(actions.setStartDate(startDate))
    },
    [dispatch]
  )

  const setEndDate = useCallback(
    (endDate?: string) => {
      dispatch(actions.setEndDate(endDate))
    },
    [dispatch]
  )

  const setCategoryMode = useCallback(
    (categoryMode?: RevenueCategoryMode) => {
      if (!categoryMode) {
        return
      }
      dispatch(actions.setCategoryMode(categoryMode))
    },
    [dispatch]
  )

  const startDate = useSelector(getStartDate)

  const endDate = useSelector(getEndDate)

  const categoryMode = useSelector(getCategoryMode)

  return {
    setStartDate,
    setEndDate,
    setCategoryMode,
    startDate,
    endDate,
    categoryMode
  }
}
