import { IUseListHookDefinition } from 'features/Lists/hooks/useList'
import { keyBy } from 'lodash'
import { CreditEventColumnDefinitions } from '../ColumnDefinitions'
import { creditEventListStore } from '../store'
import { CreditEvent, CreditEventListTypesEnum } from '../types'

const { actions, selectors } = creditEventListStore
const tabNames = [CreditEvent]

const tabNamesMappedToHookDefinitions = tabNames.map(
  (listType): IUseListHookDefinition<CreditEventListTypesEnum> => ({
    actions,
    selectors,
    columnDefinitions: CreditEventColumnDefinitions,
    listType
  })
)

export const creditEventHookDefinitions = keyBy(
  tabNamesMappedToHookDefinitions,
  ({ listType }) => listType
) as Record<
  CreditEventListTypesEnum,
  IUseListHookDefinition<CreditEventListTypesEnum>
>
