import { CellContext } from '@tanstack/react-table'
import { groupBy, sum, sumBy } from 'lodash'
import { ICategoryPosition } from 'modules/Advisory/modules/Rdot360/store/holdingsApi/ICategoryPositionResponse'
import { investmentsTableColumnNames } from './shared'
import { TodaysChange } from './TodaysChange'

export const TodaysChangeAggregatedCell: React.FC<
  CellContext<ICategoryPosition, unknown>
> = ({ getValue, row }) => {
  const todaysGainLoss = getValue<number>()
  const isGrouped = row.getIsGrouped()
  const isGroupedBySecId =
    row.groupingColumnId === investmentsTableColumnNames.secid
  const leafRows = isGrouped
    ? row.getLeafRows().filter((leafRow) => !leafRow.getIsGrouped())
    : [row]

  const shouldShowValue = leafRows.some(
    (item) => item.original.mktvals?.todaysGainLoss != null
  )
  if (!shouldShowValue) {
    return <>--</>
  }

  const rcntmktvalMapped = leafRows.map(
    (item) => item.original.mktvals?.rcntmktval || 0
  )
  const clsmktvalMapped = leafRows.map(
    (item) => item.original.mktvals?.clsmktval || 0
  )
  const todaysGainLossActivitiesMapped = leafRows.flatMap(
    (item) => item.original.mktvals?.todaysGainLossActivities || []
  )
  const todaysGainLossActivitiesTypes = groupBy(
    todaysGainLossActivitiesMapped,
    ({ activity }) => activity
  )
  const todaysGainLossActivitiesEntries = Object.entries(
    todaysGainLossActivitiesTypes
  )
  const todaysGainLossActivityTotals = todaysGainLossActivitiesEntries.map(
    ([key, value]) => {
      const total = sumBy(value, ({ amount }) => amount || 0)
      return { activity: key, amount: total }
    }
  )
  const todaysChangeProps = {
    todaysGainLoss,
    rcntmktval: sum(rcntmktvalMapped),
    clsmktval: sum(clsmktvalMapped),
    todaysGainLossActivities:
      !isGrouped || isGroupedBySecId ? todaysGainLossActivityTotals : []
  }

  return <TodaysChange {...todaysChangeProps} />
}
