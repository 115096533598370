import { combineReducers } from 'redux'
import { alternateFeesReducer, alternateFeesSagas } from './alternateFees'
import { feeDetailsReducer, feeDetailsSagas } from './feeDetails'

export const accountFeatureReducer = combineReducers({
  feeDetails: feeDetailsReducer,
  alternateFees: alternateFeesReducer
})

export const accountFeatureSagas = [...feeDetailsSagas, ...alternateFeesSagas]
