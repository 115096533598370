import { IColumnDefinition } from 'features/Lists/core/contracts/IColumnDefinition'

enum ColumnIdsEnum {
  id = 'id',
  portfolioId = 'portfolioId',
  portfolioType = 'portfolioType',
  portfolioName = 'portfolioName',
  reportId = 'reportId',
  reportCategory = 'reportCategory',
  reportDate = 'reportDate',
  reportName = 'reportName',
  sourceSystemCd = 'sourceSystemCd',
  repcodes = 'repcodes',
  advisors = 'advisors',
  createdBy = 'createdBy',
  fileId = 'fileId',
  contentType = 'contentType',
  accountList = 'accountList'
}

export const PerformanceColumnIds = { ...ColumnIdsEnum }
export type PerformanceColumnIds = typeof PerformanceColumnIds

export const PerformanceColumnDefinitions: Record<string, IColumnDefinition> = {
  [PerformanceColumnIds.portfolioId]: {
    id: PerformanceColumnIds.portfolioId,
    name: 'PORTFOLIO ID',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'portfolioId'
  },
  [PerformanceColumnIds.portfolioType]: {
    id: PerformanceColumnIds.portfolioType,
    name: 'PORTFOLIO TYPE',
    type: 'string',
    width: 110,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'portfolioType'
  },
  [PerformanceColumnIds.portfolioName]: {
    id: PerformanceColumnIds.portfolioName,
    name: 'PORTFOLIO NAME',
    type: 'string',
    width: 140,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'portfolioName'
  },
  [PerformanceColumnIds.reportId]: {
    id: PerformanceColumnIds.reportId,
    name: 'REPORT ID',
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'reportId'
  },
  [PerformanceColumnIds.reportCategory]: {
    id: PerformanceColumnIds.reportCategory,
    name: 'REPORT CATEGORY',
    type: 'string',
    width: 150,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'reportCategory'
  },
  [PerformanceColumnIds.reportDate]: {
    id: PerformanceColumnIds.reportDate,
    name: 'REPORT DATE',
    type: 'date-only',
    width: 150,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'reportDate'
  },
  [PerformanceColumnIds.reportName]: {
    id: PerformanceColumnIds.reportName,
    name: 'REPORT NAME',
    type: 'string',
    width: 250,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'reportName'
  },
  [PerformanceColumnIds.sourceSystemCd]: {
    id: PerformanceColumnIds.sourceSystemCd,
    name: 'SOURCE',
    type: 'string',
    width: 150,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'sourceSystemCd'
  },
  [PerformanceColumnIds.repcodes]: {
    id: PerformanceColumnIds.repcodes,
    name: 'REP CODE',
    type: 'custom',
    width: 150,
    filterable: false,
    sortable: false,
    searchable: false,
    facetable: false,
    dataPath: 'repcodes'
  },
  [PerformanceColumnIds.advisors]: {
    id: PerformanceColumnIds.advisors,
    name: 'ADVISOR',
    type: 'string',
    width: 150,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: true,
    dataPath: 'advisors'
  },
  [PerformanceColumnIds.createdBy]: {
    id: PerformanceColumnIds.createdBy,
    name: 'GENERATED BY',
    type: 'string',
    width: 150,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'createdBy'
  },
  [PerformanceColumnIds.fileId]: {
    id: PerformanceColumnIds.fileId,
    name: 'FILE ID',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'fileId'
  },
  [PerformanceColumnIds.accountList]: {
    id: PerformanceColumnIds.accountList,
    name: 'Account No',
    type: 'custom',
    width: 130,
    filterable: false,
    sortable: false,
    searchable: false,
    facetable: false,
    dataPath: 'accountList'
  },
  [PerformanceColumnIds.contentType]: {
    id: PerformanceColumnIds.contentType,
    name: '',
    type: 'string',
    width: 120,
    filterable: false,
    sortable: false,
    searchable: false,
    facetable: false,
    dataPath: 'id'
  }
}
