import { css } from '@emotion/react'
import { PropsWithChildren, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEnvironmentConfigApps } from 'store/system'
import { navigationActions } from 'store/ui/actions'

const classes = {
  button: css({
    border: 'solid 1px #D9CFBB',
    backgroundColor: '#FBFBF9',
    color: '#007680',
    cursor: 'pointer',
    padding: '8px 12px'
  })
}

export interface IInsightButtonProps extends PropsWithChildren {
  urlPart?: string
}

export const InsightButton: React.FC<IInsightButtonProps> = ({
  children,
  urlPart
}) => {
  const dispatch = useDispatch()
  const appsConfig = useSelector(getEnvironmentConfigApps)

  const handleClick = useCallback(() => {
    if (!appsConfig) {
      return
    }
    dispatch(
      navigationActions.launchUrl({
        url: `${appsConfig.insightsDashboard?.url}${urlPart || ''}`
      })
    )
  }, [appsConfig, dispatch, urlPart])

  return (
    <button css={classes.button} onClick={handleClick} type="button">
      {children}
    </button>
  )
}
