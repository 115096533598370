import { SearchIndices, SearchResponseType } from '../../../../api/common.types'
import { AppState } from '../../../../store'
import { IExportConfiguration } from '../contracts/IExportDefinition'
import { IListsUiState } from '../contracts/IListsUIState'
import { createListsActionsWithPrefix } from './actions'
import { createListsReducer, IListsState } from './reducers'
import { createListsSagas } from './sagas'
import { createListsSelectors } from './selectors'

export const createListsStore = <T extends SearchResponseType>(
  index: SearchIndices,
  actionPrefix: string,
  initialUiState: IListsUiState,
  rootSelector: (state: AppState) => IListsState<T>,
  exportConfigurations?: Record<string, IExportConfiguration<T>>
) => {
  const actions = createListsActionsWithPrefix<T>(actionPrefix)
  const selectors = createListsSelectors<T>(rootSelector)

  const reducer = createListsReducer<T>(actions, initialUiState)
  const sagas = createListsSagas<T>(
    index,
    actions,
    selectors,
    exportConfigurations
  )

  return {
    reducer,
    sagas,
    actions,
    selectors
  }
}
