import { ActionType, createAction } from 'typesafe-actions'
import {
  IABAResponse,
  IAddEditSubAcctResponse,
  ICategoryResponse,
  ILinkAccountRequest,
  INinthWaveConfig,
  ISubAccount,
  ISubAccountRequest,
  ISubAccountResponse,
  IUnlinkAccountRequest,
  UnlinkAccountResponseStatus,
  IMainAccount
} from './types'

export const FETCH_SUBACCOUNT =
  '@modules/@payments/@subAccount/FETCH_SUBACCOUNT_PAYMENTS'
export const FETCH_SUBACCOUNT_SUCCESS =
  '@modules/@payments/@subAccount/FETCH_SUBACCOUNT_SUCCESS'
export const FETCH_SUBACCOUNT_FAILURE =
  '@modules/@payments/@subAccount/FETCH_SUBACCOUNT_FAILURE'

export const FETCH_SUBACCOUNT_CATEGORIES =
  '@modules/@payments/@subAccount/FETCH_SUBACCOUNT_CATEGORIES'
export const FETCH_SUBACCOUNT_CATEGORIES_SUCCESS =
  '@modules/@payments/@subAccount/FETCH_SUBACCOUNT_CATEGORIES_SUCCESS'
export const FETCH_SUBACCOUNT_CATEGORIES_FAILURE =
  '@modules/@payments/@subAccount/FETCH_SUBACCOUNT_CATEGORIES_FAILURE'

export const GET_ABA = '@modules/@payments/@subAccount/GET_ABA'
export const GET_ABA_SUCCESS = '@modules/@payments/@subAccount/GET_ABA_SUCCESS'
export const GET_ABA_FAILURE = '@modules/@payments/@subAccount/GET_ABA_FAILURE'

export const ADD_EDIT_SUBACCOUNT =
  '@modules/@payments/@subAccount/ADD_EDIT_SUBACCOUNT'
export const ADD_EDIT_SUBACCOUNT_SUCCESS =
  '@modules/@payments/@subAccount/ADD_EDIT_SUBACCOUNT_SUCCESS'
export const ADD_EDIT_SUBACCOUNT_FAILURE =
  '@modules/@payments/@subAccount/ADD_EDIT_SUBACCOUNT_FAILURE'

export const GET_NINTHWAVE_CONFIG_DETAILS =
  '@modules/@payments/@subAccount/GET_NINTHWAVE_CONFIG_DETAILS'
export const GET_NINTHWAVE_CONFIG_DETAILS_SUCCESS =
  '@modules/@payments/@subAccount/GET_NINTHWAVE_CONFIG_DETAILS_SUCCESS'
export const GET_NINTHWAVE_CONFIG_DETAILS_FAILURE =
  '@modules/@payments/@subAccount/GET_NINTHWAVE_CONFIG_DETAILS_FAILURE'

export const LINK_ACCOUNT = '@modules/@payments/@subAccount/LINK_ACCOUNT'
export const LINK_ACCOUNT_SUCCESS =
  '@modules/@payments/@subAccount/LINK_ACCOUNT_SUCCESS'
export const LINK_ACCOUNT_FAILURE =
  '@modules/@payments/@subAccount/LINK_ACCOUNT_FAILURE'

export const UNLINK_ACCOUNT = '@modules/@payments/@subAccount/UNLINK_ACCOUNT'
export const UNLINK_ACCOUNT_SUCCESS =
  '@modules/@payments/@subAccount/UNLINK_ACCOUNT_SUCCESS'
export const UNLINK_ACCOUNT_FAILURE =
  '@modules/@payments/@subAccount/UNLINK_ACCOUNT_FAILURE'

export const FETCH_GL_MAIN_ACCOUNTS =
  '@modules/@payments/@subAccount/list/FETCH_GL_MAIN_ACCOUNTS'
export const FETCH_GL_MAIN_ACCOUNTS_SUCCESS =
  '@modules/@payments/@subAccount/list/FETCH_GL_MAIN_ACCOUNTS_SUCCESS'
export const FETCH_GL_MAIN_ACCOUNTS_FAILURE =
  '@modules/@payments/@subAccount/list/FETCH_GL_MAIN_ACCOUNTS_FAILURE'

export const subAccountDataActions = {
  request: createAction(FETCH_SUBACCOUNT)<ISubAccountRequest>(),
  success: createAction(FETCH_SUBACCOUNT_SUCCESS)<ISubAccountResponse>(),
  failure: createAction(FETCH_SUBACCOUNT_FAILURE)<Error>()
}

export const addEditSubAccountDataActions = {
  request: createAction(ADD_EDIT_SUBACCOUNT)<ISubAccount | undefined>(),
  success: createAction(ADD_EDIT_SUBACCOUNT_SUCCESS)<IAddEditSubAcctResponse>(),
  failure: createAction(ADD_EDIT_SUBACCOUNT_FAILURE)<Error>()
}
export const aBADataActions = {
  request: createAction(GET_ABA)<ISubAccount | undefined>(),
  success: createAction(GET_ABA_SUCCESS)<IABAResponse>(),
  failure: createAction(GET_ABA_FAILURE)<Error>()
}

export const getNinthwaveConfig = {
  request: createAction(GET_NINTHWAVE_CONFIG_DETAILS)(),
  success: createAction(
    GET_NINTHWAVE_CONFIG_DETAILS_SUCCESS
  )<INinthWaveConfig>(),
  failure: createAction(GET_NINTHWAVE_CONFIG_DETAILS_FAILURE)<Error>()
}
export const categoryDataActions = {
  request: createAction(FETCH_SUBACCOUNT_CATEGORIES)(),
  success: createAction(
    FETCH_SUBACCOUNT_CATEGORIES_SUCCESS
  )<ICategoryResponse>(),
  failure: createAction(FETCH_SUBACCOUNT_CATEGORIES_FAILURE)<Error>()
}
export const linkAccountActions = {
  request: createAction(LINK_ACCOUNT)<ILinkAccountRequest>(),
  success: createAction(LINK_ACCOUNT_SUCCESS)(),
  failure: createAction(LINK_ACCOUNT_FAILURE)<Error>()
}

export const unLinkAccountActions = {
  request: createAction(UNLINK_ACCOUNT)<IUnlinkAccountRequest>(),
  success: createAction(UNLINK_ACCOUNT_SUCCESS)<UnlinkAccountResponseStatus>(),
  failure: createAction(UNLINK_ACCOUNT_FAILURE)<Error>()
}

export const fetchGLMainAccountsActions = {
  request: createAction(FETCH_GL_MAIN_ACCOUNTS)(),
  success: createAction(FETCH_GL_MAIN_ACCOUNTS_SUCCESS)<IMainAccount[]>(),
  failure: createAction(FETCH_GL_MAIN_ACCOUNTS_FAILURE)<Error>()
}

export type SubAccountDataActionTypes = ActionType<typeof subAccountDataActions>
export type SubAccountAddEditActionTypes = ActionType<
  typeof addEditSubAccountDataActions
>
export type SubAccountCategoriesTypes = ActionType<typeof categoryDataActions>
export type ABADataActionTypes = ActionType<typeof aBADataActions>
export type NinthWaveConfigActionTypes = ActionType<typeof getNinthwaveConfig>
export type LinkAccountActionTypes = ActionType<typeof linkAccountActions>

export type UnlinkAccountActionTypes = ActionType<typeof unLinkAccountActions>

export type FetchGLMainAccountsActionTypes = ActionType<
  typeof fetchGLMainAccountsActions
>
export type SubAccountActionTypes =
  | SubAccountDataActionTypes
  | SubAccountAddEditActionTypes
  | SubAccountCategoriesTypes
  | ABADataActionTypes
  | NinthWaveConfigActionTypes
  | LinkAccountActionTypes
  | UnlinkAccountActionTypes
  | FetchGLMainAccountsActionTypes
