import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

const { actions, reducer } = createSlice({
  name: 'interactionSettingsCallout',
  initialState: { linesToDisplay: 4 } as IInteractionSettingsCalloutState,
  reducers: {
    show: (state) => ({
      ...state,
      showCallout: true
    }),
    hide: (state) => ({
      ...state,
      showCallout: false,
      error: undefined
    }),
    failure: (state, action: PayloadAction<Error>) => ({
      ...state,
      error: action.payload
    }),
    setLinesToDisplay: (state, action: PayloadAction<number | undefined>) => ({
      ...state,
      linesToDisplay: action.payload
    })
  }
})

export { reducer as interactionSettingsCalloutReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.secondaryHeader.interactions
    .interactionSettingsCallout

const getShouldShowCallout = flow(rootSelector, (x) => x.showCallout)
const getPanelError = flow(rootSelector, (x) => x.error)
const getLinesToDisplay = flow(rootSelector, (x) => x.linesToDisplay)

export interface IInteractionSettingsCalloutState {
  showCallout?: boolean
  error?: Error
  linesToDisplay?: number
}

export const useInteractionSettingsCallout = () => {
  const dispatch = useDispatch()

  const hide = useCallback(() => {
    dispatch(actions.hide())
  }, [dispatch])

  const show = useCallback(() => {
    dispatch(actions.show())
  }, [dispatch])

  const setError = useCallback(
    (e: Error) => {
      dispatch(actions.failure(e))
    },
    [dispatch]
  )

  const setLinesToDisplay = useCallback(
    (lines?: number) => {
      dispatch(actions.setLinesToDisplay(lines))
    },
    [dispatch]
  )

  const showCallout = useSelector(getShouldShowCallout)

  const error = useSelector(getPanelError)

  const linesToDisplay = useSelector(getLinesToDisplay)

  return {
    hide,
    show,
    setError,
    setLinesToDisplay,
    showCallout,
    error,
    linesToDisplay
  }
}
