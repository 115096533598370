import { createReducer } from 'typesafe-actions'
import {
  deletePaymentApproverActions,
  fetchPaymentApproversActions,
  fetchUserActions,
  PaymentApproversActions,
  setSelectedApproverActions,
  submitPaymentApproverActions
} from './actions'
import { IPaymentApprover } from './types'

export interface IPaymentApproverState {
  users?: {
    isLoading: boolean
    error?: Error
    list?: IPaymentApprover[]
  }
  approvers?: {
    isLoading: boolean
    error?: Error
    list?: IPaymentApprover[]
  }
  selectedApprover?: IPaymentApprover
  deleteApprover?: {
    inProgress: boolean
    error?: Error
  }
  submitApprover?: {
    inProgress: boolean
    error?: Error
  }
}

export const paymentApproversReducer = createReducer<
  IPaymentApproverState,
  PaymentApproversActions
>({})
  .handleAction(fetchUserActions.request, (state) => ({
    ...state,
    users: {
      isLoading: true,
      error: undefined,
      list: undefined
    }
  }))
  .handleAction(fetchUserActions.success, (state, action) => ({
    ...state,
    users: {
      isLoading: false,
      error: undefined,
      list: action.payload
    }
  }))
  .handleAction(fetchUserActions.failure, (state, action) => ({
    ...state,
    users: {
      ...state.users,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(fetchPaymentApproversActions.request, (state) => ({
    ...state,
    approvers: {
      isLoading: true,
      error: undefined,
      list: undefined
    }
  }))
  .handleAction(fetchPaymentApproversActions.success, (state, action) => ({
    ...state,
    approvers: {
      isLoading: false,
      error: undefined,
      list: action.payload
    }
  }))
  .handleAction(fetchPaymentApproversActions.failure, (state, action) => ({
    ...state,
    approvers: {
      ...state.approvers,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(deletePaymentApproverActions.request, (state) => ({
    ...state,
    deleteApprover: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(deletePaymentApproverActions.success, (state) => ({
    ...state,
    deleteApprover: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(deletePaymentApproverActions.failure, (state, action) => ({
    ...state,
    deleteApprover: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(setSelectedApproverActions.setApprover, (state, action) => ({
    ...state,
    selectedApprover: action.payload
  }))
  .handleAction(submitPaymentApproverActions.request, (state) => ({
    ...state,
    submitApprover: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(submitPaymentApproverActions.success, (state) => ({
    ...state,
    submitApprover: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(submitPaymentApproverActions.failure, (state, action) => ({
    ...state,
    submitApprover: {
      inProgress: false,
      error: action.payload
    }
  }))
