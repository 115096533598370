import { IRockDigitalInvitationRequest } from 'api/mail'
import { datahubApi } from 'store/api/datahub'

interface InvitePayload {
  invitation: IRockDigitalInvitationRequest
  email: string
}

export const clientInviteDatahubApi = datahubApi.injectEndpoints({
  endpoints: (builder) => ({
    clientInvite_inviteToRockDigital: builder.mutation<
      void,
      { id: string; invite: InvitePayload }
    >({
      query: ({ id, invite }) => ({
        url: `/nfsProfiles/${id}/inviteToRockDigital`,
        method: 'post',
        data: { request: invite }
      }),
      invalidatesTags: (result, _, { id }) =>
        result ? [{ type: 'profile', id }] : []
    }),
    clientInvite_resendRockDigitalInvitation: builder.mutation<
      void,
      { id: string; invite: InvitePayload }
    >({
      query: ({ id, invite }) => ({
        url: `/nfsProfiles/${id}/resendRockDigitalInvitation`,
        method: 'post',
        data: { request: invite }
      }),
      invalidatesTags: (result, _, { id }) =>
        result ? [{ type: 'profile', id }] : []
    }),
    getNumberOfAccountsForPortalId: builder.query<number | undefined, string>({
      query: (portalId) => ({
        method: 'POST',
        url: `/nfsProfiles/GetNumberOfAccountsForPortalId`,
        data: {
          request: {
            portalId: portalId
          }
        },
        headers: { 'api-version': '1.0' }
      }),
      transformResponse: (response: { value?: number }) => response?.value
    })
  })
})

export const {
  useClientInvite_inviteToRockDigitalMutation,
  useClientInvite_resendRockDigitalInvitationMutation,
  useGetNumberOfAccountsForPortalIdQuery
} = clientInviteDatahubApi
