import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow, fromPairs, pickBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import {
  rdot360ContextActions,
  useRdot360AccountContext
} from '../../store/rdot360Context'

export interface IAccountNicknamesUiState {
  advisorNicknames?: Record<string, string | null>
  clientNicknames?: Record<string, string | null>
  searchText: string
  checkedAccounts: Record<string, boolean>
}

export interface ISetNicknamePayload {
  accountId: string
  nickname?: string | null
}

export function isNotUndefined<T>(input: undefined | T): input is T {
  return input !== undefined
}

export const defaultNickname = 'accountNicknames.advisorAddedNickName'

const { actions, reducer } = createSlice({
  name: 'AccountNicknamesUiState',
  initialState: {
    advisorNicknames: undefined,
    clientNicknames: undefined,
    searchText: '',
    checkedAccounts: {}
  } as IAccountNicknamesUiState,
  reducers: {
    setAdvisorNickname: (state, action: PayloadAction<ISetNicknamePayload>) => {
      const { accountId, nickname } = action.payload
      const updatedNicknames = {
        ...(state.advisorNicknames || {}),
        [accountId]: nickname
      }
      state.advisorNicknames = pickBy(updatedNicknames, isNotUndefined)
    },
    setClientNickname: (state, action: PayloadAction<ISetNicknamePayload>) => {
      const { accountId, nickname } = action.payload
      const updatedNicknames = {
        ...(state.clientNicknames || {}),
        [accountId]: nickname
      }
      state.clientNicknames = pickBy(updatedNicknames, isNotUndefined)
    },
    setCheckedAccounts: (
      state,
      action: PayloadAction<{ accountId: string; checked: boolean }>
    ) => {
      state.checkedAccounts[action.payload.accountId] = action.payload.checked
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    },
    reset: (state) => {
      state.advisorNicknames = undefined
      state.clientNicknames = undefined
      state.checkedAccounts = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rdot360ContextActions.setSelectedHouseholdId, (state) => {
      state.searchText = ''
    })
  }
})

export { reducer as manageAccountNicknamesFeatureReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.features.manageAccountNicknames

const getAdvisorNicknames = createSelector(
  rootSelector,
  (x) => x.advisorNicknames
)
const getClientNicknames = createSelector(
  rootSelector,
  (x) => x.clientNicknames
)
const getCheckedAccounts = createSelector(
  rootSelector,
  (x) => x.checkedAccounts
)

const getSearchText = flow(rootSelector, (x) => x.searchText)

export const useManageAccountNicknamesStore = () => {
  const dispatch = useDispatch()
  const { cdmv2HouseholdAccounts: nicknameAccounts } =
    useRdot360AccountContext()

  const originalNicknamesLookup = useMemo(
    () =>
      fromPairs(
        nicknameAccounts?.map(({ accountID, accountNicknames }) => [
          accountID,
          {
            advisor: accountNicknames?.advisorAddedNickName || null,
            client: accountNicknames?.clientAddedNickName || null
          }
        ])
      ),
    [nicknameAccounts]
  )
  const initialCheckedAccounts = useMemo(() => {
    return fromPairs(
      nicknameAccounts?.map((x) => [
        x.accountID,
        x.accountNicknames?.sendToPerformance === 'T'
      ])
    )
  }, [nicknameAccounts])

  const advisorNicknames = useSelector(getAdvisorNicknames)
  const clientNicknames = useSelector(getClientNicknames)
  const checkedAccounts = useSelector(getCheckedAccounts)

  const hasChangedClientNickname = useMemo(() => {
    for (const accountId in clientNicknames) {
      const originalNickname = originalNicknamesLookup[accountId]?.client
      if (
        !!originalNickname &&
        clientNicknames[accountId] !== originalNickname
      ) {
        return true
      }
    }
    return false
  }, [clientNicknames, originalNicknamesLookup])

  const setUpdatedNickname = useCallback(
    (
      accountId: string,
      nickname: string | null = null,
      nicknameType: 'advisor' | 'client'
    ) => {
      const originalNickname =
        originalNicknamesLookup[accountId]?.[nicknameType]
      if (originalNickname === undefined) {
        return
      }

      const nicknameToUpdate = nickname || null
      const nicknameIsChanged = originalNickname !== nicknameToUpdate
      const nicknameAction =
        nicknameType === 'advisor'
          ? actions.setAdvisorNickname
          : actions.setClientNickname
      dispatch(
        nicknameAction({
          accountId,
          nickname: nicknameIsChanged ? nicknameToUpdate : undefined
        })
      )
    },
    [dispatch, originalNicknamesLookup]
  )

  const setIsChecked = useCallback(
    (payload: { accountId: string; checked: boolean }) => {
      dispatch(actions.setCheckedAccounts(payload))
    },
    [dispatch]
  )

  const setSearchText = useCallback(
    (search?: string) => {
      dispatch(actions.setSearchText(search || ''))
    },
    [dispatch]
  )

  const searchText = useSelector(getSearchText)

  const reset = useCallback(() => {
    dispatch(actions.reset())
  }, [dispatch])

  return {
    advisorNicknames,
    clientNicknames,
    hasChangedClientNickname,
    nicknameAccounts,
    setUpdatedNickname,
    initialCheckedAccounts,
    checkedAccounts,
    setIsChecked,
    searchText,
    setSearchText,
    reset
  }
}
