import { Dropdown, IDropdownOption, SearchBox } from '@fluentui/react'
import React, { useCallback } from 'react'
import {
  IListsFilter,
  IListsSearchFilter,
  SearchFilterTypes,
  SearchFilterTypesEnum
} from '../../../contracts/IListsFilter'
import { IListsFilterEditProps } from '../contracts/IListsFilterEditProps'

export interface IListsSearchFilterProps extends IListsFilterEditProps {
  filter: IListsSearchFilter
}

const mapToComboBoxOptions = <T extends { [key: string]: string }>(
  options: T
): IDropdownOption[] =>
  Object.entries(options).map(
    ([key, text]): IDropdownOption => ({
      key,
      text
    })
  )

const FILTER_TYPE_OPTIONS = mapToComboBoxOptions(SearchFilterTypes)

export const ListsSearchFilterV2: React.FC<IListsSearchFilterProps> = ({
  onChange,
  filter
}) => {
  const { value, filterType } = filter
  const selectedFilterType = filterType || 'search'

  const onSearchChange = useCallback(
    (_: any, newValue?: string) => {
      const newFilter: IListsSearchFilter = {
        ...filter,
        type: 'search',
        filterType: selectedFilterType,
        value: newValue,
        hasValue: !!newValue
      }
      onChange(newFilter)
    },
    [filter, onChange, selectedFilterType]
  )

  const onFilterTypeChange = (_: any, option?: IDropdownOption) => {
    if (!option) {
      return
    }
    fireOnChange({
      ...filter,
      filterType: option.key as keyof typeof SearchFilterTypesEnum
    })
  }
  const fireOnChange = (newNumberRangeFilter: IListsSearchFilter) => {
    const newListsFilter: IListsFilter = newNumberRangeFilter
    const type = filterType || 'search'

    onChange({
      ...newListsFilter,
      hasValue: type === 'search' || newNumberRangeFilter.value != null
    })
  }

  return (
    <>
      <Dropdown
        title="Filter Type"
        styles={{ root: { height: '35px' } }}
        selectedKey={selectedFilterType}
        options={FILTER_TYPE_OPTIONS}
        onChange={onFilterTypeChange}
      />
      <SearchBox
        value={value || ''}
        onChange={onSearchChange}
        autoComplete="off"
        autoFocus={true}
      />
    </>
  )
}
