import { Panel } from '@fluentui/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContactsContainer } from './ContactsContainer'
import {
  contactPanelActions,
  getContactPanelTitle,
  getIsContactPanelOpen
} from './store'

export const ContactsPanelContainer: React.FC = () => {
  const dispatch = useDispatch()
  const onDismiss = () => dispatch(contactPanelActions.close())
  const isPanelOpen = useSelector(getIsContactPanelOpen)
  const title = useSelector(getContactPanelTitle)

  if (!isPanelOpen) {
    return null
  }

  return (
    <Panel
      headerText={title}
      isOpen={isPanelOpen}
      onDismiss={onDismiss}
      isLightDismiss={true}
      closeButtonAriaLabel="Close"
    >
      <ContactsContainer />
    </Panel>
  )
}
