import axios from 'axios'
import { IDragDropFile } from '../../../../../shared/components/DragDrop'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'

export const uploadInvoice = (
  file: IDragDropFile,
  orgid: string,
  email: string,
  options: IApiOptions,
  onUploadProgress?: (
    progressEvent: ProgressEvent<EventTarget>,
    file: IDragDropFile
  ) => void
) => {
  const url = `${options.apiRoot}/payment/api/billmgmt/attachmentUpload`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  const data = new FormData()
  data.append('fileContent', file.file)
  data.append('fileName', file.file.name)
  data.append('orgid', orgid)
  data.append('id', email)

  return axios
    .post(url, data, {
      headers,
      cancelToken: options.cancelToken,
      onUploadProgress: onUploadProgress
        ? (progressEvent) => {
            onUploadProgress(progressEvent, file)
          }
        : undefined
    })
    .then((res) => res.data)
}
