import { TagDescription } from '@reduxjs/toolkit/dist/query/react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { rdot360Api, Rdot360ApiTagType } from 'store/api/rdot360'
import { apiConstants } from '../apis'
import { IRetrieveDocumentListRequest } from './IRetrieveDocumentListRequest'
import {
  IRetrieveDocumentListResponse,
  IRetrieveDocumentListResponseItem
} from './IRetrieveDocumentListResponse'
import {
  IDocumentApiPdfDocument,
  IDocumentApiRetrieveDocumentRequest,
  IDocumentApiRetrieveDocumentResponse
} from './types'

export interface IDocumentsApiBaseRequest<T = unknown> {
  accounts: string[]
  contextId: string
  payload?: T
}

export interface IShareReportWithUserPayload {
  shareWithUser?: string
  documentPath?: string
  documentName?: string
  documentType?: string
  accountNumbers?: string[]
  type?: string
  asOfDate?: string
  validateAccount?: boolean
  auditMetadata?: string
  sourceSystemId?: string
}

const { cacheTime } = apiConstants

type DocumentsApiTagType =
  | 'retrieveDocumentList'
  | 'retrieveDocument'
  | 'combinedRetrieveDocument'
  | 'individualRetrieveDocument'
const balancesApiTags: DocumentsApiTagType[] = [
  'retrieveDocumentList',
  'retrieveDocument',
  'combinedRetrieveDocument',
  'individualRetrieveDocument'
]
const balancesApiWithTags = rdot360Api.enhanceEndpoints({
  addTagTypes: balancesApiTags
})

const getDefaultTags = (
  type: DocumentsApiTagType,
  req: IDocumentsApiBaseRequest
): TagDescription<DocumentsApiTagType | Rdot360ApiTagType>[] => [
  'rdot360',
  { type: 'rdot360', id: req.contextId },
  type,
  { type, id: req.contextId }
]

const providesTags =
  (type: DocumentsApiTagType) =>
  (_: unknown, _1: unknown, req: IDocumentsApiBaseRequest) =>
    getDefaultTags(type, req)

const documentsApi = balancesApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    retrieveDocumentList: builder.query<
      IRetrieveDocumentListResponseItem | undefined,
      IDocumentsApiBaseRequest<IRetrieveDocumentListRequest>
    >({
      query: ({ payload }) => ({
        url: `documentsapi/odata/document/RetrieveDocumentList`,
        method: 'POST',
        data: payload
      }),
      transformResponse: (response: IRetrieveDocumentListResponse) =>
        response?.value?.[0],
      providesTags: providesTags('retrieveDocumentList'),
      keepUnusedDataFor: cacheTime
    }),
    retrieveDocument: builder.query<
      IDocumentApiPdfDocument | undefined,
      IDocumentsApiBaseRequest<IDocumentApiRetrieveDocumentRequest>
    >({
      query: ({ payload }) => ({
        url: `documentsapi/odata/document/RetrieveDocument`,
        method: 'POST',
        data: payload
      }),
      transformResponse: (response: IDocumentApiRetrieveDocumentResponse) =>
        response?.value?.[0],
      providesTags: providesTags('retrieveDocument'),
      keepUnusedDataFor: cacheTime
    }),
    combinedRetrieveDocument: builder.query<
      IDocumentApiPdfDocument | undefined,
      IDocumentsApiBaseRequest<IDocumentApiRetrieveDocumentRequest>
    >({
      query: ({ payload }) => ({
        url: `documentsapi/odata/document/RetrieveStichedPdfDocument`,
        method: 'POST',
        data: payload
      }),
      transformResponse: (response: IDocumentApiRetrieveDocumentResponse) =>
        response?.value?.[0],
      providesTags: providesTags('combinedRetrieveDocument'),
      keepUnusedDataFor: cacheTime
    }),
    individualRetrieveDocument: builder.query<
      string | undefined,
      IDocumentsApiBaseRequest<IDocumentApiRetrieveDocumentRequest>
    >({
      query: ({ payload }) => ({
        url: `documentsapi/odata/document/DownloadMultipleFiles`,
        method: 'POST',
        data: payload
      }),
      transformResponse: (response?: IDocumentApiPdfDocument) =>
        response?.value,
      providesTags: providesTags('individualRetrieveDocument'),
      keepUnusedDataFor: cacheTime
    }),
    shareReportWithUserV2: builder.mutation<
      unknown,
      IShareReportWithUserPayload
    >({
      query: (payload: IShareReportWithUserPayload) => ({
        method: 'post',
        url: 'documentsapi/api/reports/ShareReportWithUserV2',
        data: payload
      })
    })
  })
})

export const {
  useRetrieveDocumentListQuery,
  useLazyRetrieveDocumentQuery,
  useLazyCombinedRetrieveDocumentQuery,
  useLazyIndividualRetrieveDocumentQuery,
  useShareReportWithUserV2Mutation
} = documentsApi

export const useDocumentsApiUtil = () => {
  const dispatch = useDispatch()
  const invalidateTags = useCallback(
    (tags: TagDescription<DocumentsApiTagType>[]) =>
      dispatch(documentsApi.util.invalidateTags(tags)),
    [dispatch]
  )

  return {
    invalidateTags
  }
}
