import { call, delay, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import {
  getHouseholdRelatedDepartments,
  IDatahubDepartment
} from '../../../api/households'
import { AppState } from '../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../../store/shared/sagas'

export const householdDepartmentFetchActions = createAsyncAction(
  '@features/@households/@features/@householdFee/@householdDepartmentFetch/REQUEST',
  '@features/@households/@features/@householdFee/@householdDepartmentFetch/SUCCESS',
  '@features/@households/@features/@householdFee/@householdDepartmentFetch/FAILURE'
)<string, IDatahubDepartment[] | undefined, Error>()

export const householdDepartmentsFetchReducer = createAsyncReducer(
  householdDepartmentFetchActions
)

const rootSelector = (state: AppState) =>
  state.features.households.householdDepartmentsFetch

export const {
  getError: getHouseholdDepartmentFetchError,
  getIsLoading: getIsHouseholdDepartmentFetchLoading,
  getResult: getHouseholdDepartmentFetchResult,
  getRequest: getHouseholdDepartmentFetchRequest
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof householdDepartmentFetchActions.request>
) {
  if (!action.payload) {
    yield put(householdDepartmentFetchActions.success(undefined))
    return
  }
  yield delay(100)
  try {
    const apiOptions = yield* call(getRockefellerApiOptions)
    const result = yield* call(
      getHouseholdRelatedDepartments,
      apiOptions,
      action.payload
    )
    if (!result?.value) {
      throw new Error('No result returned from datahub API')
    }
    yield put(householdDepartmentFetchActions.success(result.value))
  } catch (e: any) {
    yield put(householdDepartmentFetchActions.failure(e))
  }
}

export const householdDepartmentsFetchSagas = [
  () => takeLatest(householdDepartmentFetchActions.request, onRequest)
]
