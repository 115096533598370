import axios from 'axios'
import { call, cancelled, delay, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import {
  getSystemUsers,
  ISystemUser
} from '../../../../../../../../../api/dynamics'
import { IOdataRequest } from '../../../../../../../../../api/odata.types'
import { AppState } from '../../../../../../../../../store'
import {
  createAsyncHook,
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../../../store/shared/asyncStore'
import { getDynamicsApiOptions } from '../../../../../../../../../store/shared/sagas'

export const payoutUserSearchFetchActions = createAsyncAction(
  '@modules/@teams/@payoutUserSearchFetch/REQUEST',
  '@modules/@teams/@payoutUserSearchFetch/SUCCESS',
  '@modules/@teams/@payoutUserSearchFetch/FAILURE'
)<string | undefined, ISystemUser[], Error>()

export const payoutUserSearchFetchReducer = createAsyncReducer(
  payoutUserSearchFetchActions
)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.teams.modules.hurdles.features.entitySearch
    .payoutUserSearchFetch

const selectors = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof payoutUserSearchFetchActions.request>
) {
  if (!action.payload || !action.payload.length) {
    yield put(payoutUserSearchFetchActions.success([]))
    return
  }
  yield delay(500)

  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  try {
    const apiOptions = yield* call(getDynamicsApiOptions)
    const result = yield* call(getSystemUsers, apiOptions, {
      search: action.payload,
      searchFields: ['fullname'],
      select: ['fullname', 'domainname'],
      filters: ['rcm_AdvisorRep_PersonalRepFor_SystemUser/any()'],
      top: 20
    } as IOdataRequest)

    if (!result) {
      throw new Error('An error occurred while fetching teams')
    }
    yield put(payoutUserSearchFetchActions.success(result.value || []))
  } catch (e: any) {
    yield put(payoutUserSearchFetchActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const usePayoutUserSearchFetch = createAsyncHook(
  payoutUserSearchFetchActions.request,
  selectors
)

export const payoutUserSearchFetchSagas = [
  () => takeLatest(payoutUserSearchFetchActions.request, onRequest)
]
