import { combineReducers } from 'redux'
import {
  hurdlesDashboardFeaturesReducer,
  hurdlesDashboardFeaturesSagas
} from '../features'

export const hurdlesDashboardReducer = combineReducers({
  features: hurdlesDashboardFeaturesReducer
})

export const hurdlesDashboardSagas = [...hurdlesDashboardFeaturesSagas]
