import { keyBy } from 'lodash'
import { IDataListColumnDefinition } from '../../../../../../../../../features/DataList/contracts/IDataListColumnDefinition'
import { convertColumnTypeToFilterType } from '../../../../../../../../../features/DataList/service'
import { createDataListStore } from '../../../../../../../../../features/DataList/store'
import { IListsFilter } from '../../../../../../../../../features/Lists/core/contracts/IListsFilter'
import { parseDateISOStringInLocalTimezone } from '../../../../../../../../../shared'
import { AppState } from '../../../../../../../../../store'
import { getPossiblePayouts, PossiblePayout } from './potentialPayouts'

export type PayoutsListColumnName =
  | 'Status'
  | 'Hurdle Name'
  | 'Date'
  | 'Person'
  | 'Amount'
  | 'Type'
  | 'Team / Individual'

interface IPayoutsListColumnDefinition
  extends IDataListColumnDefinition<PossiblePayout> {
  name: PayoutsListColumnName
}

const defaultColumn: Partial<IPayoutsListColumnDefinition> = {
  filterable: true,
  sortable: true
}

const payoutsListColumns: IPayoutsListColumnDefinition[] = [
  {
    ...defaultColumn,
    name: 'Status',
    type: 'string',
    getValue: (payout) => payout?.status,
    width: 40,
    facetable: true
  },
  {
    ...defaultColumn,
    name: 'Hurdle Name',
    type: 'string',
    getValue: (payout) => payout?.name,
    width: 400
  },
  {
    ...defaultColumn,
    name: 'Date',
    type: 'date',
    getValue: (payout) =>
      payout.date && parseDateISOStringInLocalTimezone(payout.date),
    width: 200
  },
  {
    ...defaultColumn,
    name: 'Person',
    type: 'string',
    getValue: (payout) => payout?.person,
    width: 200
  },
  {
    ...defaultColumn,
    name: 'Amount',
    type: 'number',
    getValue: (payout) => payout?.amount,
    width: 200
  },
  {
    ...defaultColumn,
    name: 'Type',
    type: 'string',
    getValue: (payout) => payout?.type,
    width: 200,
    facetable: true
  },
  {
    ...defaultColumn,
    name: 'Team / Individual',
    type: 'string',
    getValue: (payout) => payout?.entityName,
    width: 200,
    hidden: true
  }
]

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.teams.modules.hurdles.features.potentialPayouts
    .payoutsList

const filters = keyBy(
  payoutsListColumns
    .filter((x) => x.filterable)
    .map(
      (column): IListsFilter => ({
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        hasValue: false
      })
    ),
  ({ id }) => id
)

const {
  actions: payoutsListActions,
  reducer: payoutsListReducer,
  selectors: payoutsListSelectors,
  sagas: payoutsListSagas
} = createDataListStore({
  initialState: {
    columns: payoutsListColumns,
    filters,
    sortBy: { name: 'Date', direction: 'desc' }
  },
  itemsSelector: getPossiblePayouts,
  prefix: '@modules/@teams/@potentialPayout',
  rootSelector
})

export {
  payoutsListActions,
  payoutsListSelectors,
  payoutsListReducer,
  payoutsListSagas
}
