import { useCallback } from 'react'
import {
  useLazyPreferencesContainer,
  usePreferencesContainer
} from 'store/preferences'
import { IListMetaPreferences, IListPreferences } from './IListPreferences'

export const useListMetaPreferenceContainer = (containerId: string) => {
  return usePreferencesContainer<IListMetaPreferences>(containerId)
}

export const useLazyListPreferenceContainer = (containerId: string) => {
  const {
    getPreferences: getPreferencesInternal,
    setPreferences: setPreferencesInternal,
    deletePreferences: deletePreferencesInternal
  } = useLazyPreferencesContainer<IListPreferences>()

  const getPreferences = useCallback(
    (listId: string) => {
      return getPreferencesInternal(`${containerId}/${listId}`)
    },
    [containerId, getPreferencesInternal]
  )

  const setPreferences = useCallback(
    (listId: string, value: Partial<IListPreferences>) => {
      return setPreferencesInternal(`${containerId}/${listId}`, value)
    },
    [containerId, setPreferencesInternal]
  )

  const deletePreferences = useCallback(
    (listId: string) => {
      return deletePreferencesInternal(`${containerId}/${listId}`)
    },
    [containerId, deletePreferencesInternal]
  )

  return { getPreferences, setPreferences, deletePreferences }
}
