import axios from 'axios'
import { IApiOptions } from '../../../../../../../shared/contracts/IApiOptions'
import { IPayees, IPayeesRequest } from '../store/types'

export const fetchPayeeList = (
  options: IApiOptions,
  payeeReq: IPayeesRequest
) => {
  const url = `${options.apiRoot}/payment/api/d365/getUIVendorPayee?entityId=${payeeReq.entityId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPayees[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const addEditPayee = (
  options: IApiOptions,
  payeeReq: IPayees | undefined
) => {
  const url = `${options.apiRoot}/payment/api/d365/updUIVendorPayee`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IPayees>(url, payeeReq, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
