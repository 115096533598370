import { ActionType, createAction } from 'typesafe-actions'
import {
  IManifest,
  IStatementDetail,
  IUploadFileRequest,
  IUploadResponse
} from './types'

export const FETCH_MANIFESTS_REQUEST = `@mouldes/@trust/@Statements/@manifests/FETCH_MANIFESTS_REQUEST`
export const FETCH_MANIFESTS_REQUEST_SUCCESS = `@mouldes/@trust/@Statements/@manifests/FETCH_MANIFESTS_SUCCESS`
export const FETCH_MANIFESTS_REQUEST_FAILURE = `@mouldes/@trust/@Statements/@manifests/FETCH_MANIFESTS_FAILURE`

export const fetchManifestsActions = {
  request: createAction(FETCH_MANIFESTS_REQUEST)(),
  success: createAction(FETCH_MANIFESTS_REQUEST_SUCCESS)<IManifest[]>(),
  failure: createAction(FETCH_MANIFESTS_REQUEST_FAILURE)<Error>()
}

export const UPLOAD_MANIFEST_FILE_REQUEST = `@mouldes/@trust/@Statements/@manifests/UPLOAD_MANIFEST_FILE_REQUEST`
export const UPLOAD_MANIFEST_FILE_SUCCESS = `@mouldes/@trust/@Statements/@manifests/UPLOAD_MANIFEST_FILE_SUCCESS`
export const UPLOAD_MANIFEST_FILE_FAILURE = `@mouldes/@trust/@Statements/@manifests/UPLOAD_MANIFEST_FILE_FAILURE`

export const uploadManifestFileActions = {
  request: createAction(UPLOAD_MANIFEST_FILE_REQUEST)<IUploadFileRequest>(),
  success: createAction(UPLOAD_MANIFEST_FILE_SUCCESS)<IUploadResponse>(),
  failure: createAction(UPLOAD_MANIFEST_FILE_FAILURE)<Error>()
}

export const DOWNLOAD_MANIFEST_FILE_REQUEST = `@mouldes/@trust/@Statements/@manifests/DOWNLOAD_MANIFEST_FILE_REQUEST`
export const DOWNLOAD_MANIFEST_FILE_SUCCESS = `@mouldes/@trust/@Statements/@manifests/DOWNLOAD_MANIFEST_FILE_SUCCESS`
export const DOWNLOAD_MANIFEST_FILE_FAILURE = `@mouldes/@trust/@Statements/@manifests/DOWNLOAD_MANIFEST_FILE_FAILURE`

export const downloadManifestFileActions = {
  request: createAction(DOWNLOAD_MANIFEST_FILE_REQUEST)<string>(),
  success: createAction(DOWNLOAD_MANIFEST_FILE_SUCCESS)(),
  failure: createAction(DOWNLOAD_MANIFEST_FILE_FAILURE)<Error>()
}

export const DOWNLOAD_MANIFEST_STATEMENT_FILE_REQUEST = `@mouldes/@trust/@Statements/@manifests/DOWNLOAD_MANIFEST_STATEMENT_FILE_REQUEST`
export const DOWNLOAD_MANIFEST_STATEMENT_FILE_SUCCESS = `@mouldes/@trust/@Statements/@manifests/DOWNLOAD_MANIFEST_STATEMENT_FILE_SUCCESS`
export const DOWNLOAD_MANIFEST_STATEMENT_FILE_FAILURE = `@mouldes/@trust/@Statements/@manifests/DOWNLOAD_MANIFEST_STATEMENT_FILE_FAILURE`

export const downloadManifestStatementActions = {
  request: createAction(DOWNLOAD_MANIFEST_STATEMENT_FILE_REQUEST)<string>(),
  success: createAction(DOWNLOAD_MANIFEST_STATEMENT_FILE_SUCCESS)(),
  failure: createAction(DOWNLOAD_MANIFEST_STATEMENT_FILE_FAILURE)<Error>()
}

export const FETCH_STATEMENT_DETAIL_REQUEST = `@mouldes/@trust/@Statements/@manifests/FETCH_STATEMENT_DETAIL_REQUEST`
export const FETCH_STATEMENT_DETAIL_SUCCESS = `@mouldes/@trust/@Statements/@manifests/FETCH_STATEMENT_DETAIL_SUCCESS`
export const FETCH_STATEMENT_DETAIL_FAILURE = `@mouldes/@trust/@Statements/@manifests/FETCH_STATEMENT_DETAIL_FAILURE`
export const FETCH_STATEMENT_DETAIL_CLEAR = `@mouldes/@trust/@Statements/@manifests/FETCH_STATEMENT_DETAIL_CLEAR`

export const fetchStatementDetailActions = {
  request: createAction(FETCH_STATEMENT_DETAIL_REQUEST)<string>(),
  success: createAction(FETCH_STATEMENT_DETAIL_SUCCESS)<IStatementDetail[]>(),
  failure: createAction(FETCH_STATEMENT_DETAIL_FAILURE)<Error>(),
  clear: createAction(FETCH_STATEMENT_DETAIL_CLEAR)()
}

export type ManifestsActions =
  | ActionType<typeof fetchManifestsActions>
  | ActionType<typeof uploadManifestFileActions>
  | ActionType<typeof downloadManifestFileActions>
  | ActionType<typeof downloadManifestStatementActions>
  | ActionType<typeof fetchStatementDetailActions>
