import { useTheme } from '@emotion/react'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import { fromPairs } from 'lodash'
import { Icon } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { useIncomeFeatureStore } from 'modules/Advisory/modules/Rdot360/features/Income'
import { useGetIncomeTaxSummaryQueryForSelectedAccounts } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useCallback, useMemo } from 'react'
import { downloadUrlAsFile } from 'shared/downloads'
import { useIncomeDetailsUiState } from '../../store'
import type { IncomeAccountExportWorker } from './export'
import { getColumns } from './IncomeColumns'

export const AccountExport = () => {
  const { incomeMode, includeOptionPremiums, financialYear } =
    useIncomeFeatureStore()
  const { data } = useGetIncomeTaxSummaryQueryForSelectedAccounts(
    incomeMode,
    financialYear
  )
  const theme = useTheme()
  const { searchText } = useIncomeDetailsUiState()

  const accountData = useMemo(
    () => data?.IncomeSubTotal?.flatMap((val) => val.IncomeDetail || []) || [],
    [data]
  )

  const exportToExcelAccount = useCallback(async () => {
    if (!accountData?.length) {
      return
    }
    const isHistorical = incomeMode === 'historical'
    const columns = getColumns(searchText, isHistorical, includeOptionPremiums)

    const columnDef = columns?.map((x) => ({
      id: x?.id || '',
      header: x?.id
    }))

    const mappedExportData = accountData?.map((x, i) =>
      fromPairs<any>(
        columns?.map((column) => [column.id || '', column?.accessorFn(x, i)])
      )
    )

    const worker = new Worker(new URL('./export.ts', import.meta.url))
    const { exportIncomeAccount } = wrap<IncomeAccountExportWorker>(worker)
    const result = await exportIncomeAccount(
      mappedExportData,
      isHistorical,
      includeOptionPremiums,
      columnDef
    )
    const filename = `Income Account ${format(new Date(), 'MM-dd-yyyy')}.xlsx`
    downloadUrlAsFile(result, filename)
  }, [accountData, includeOptionPremiums, incomeMode, searchText])

  return (
    <>
      <Icon
        type="Download"
        width={24}
        height={24}
        onClick={exportToExcelAccount}
        color={theme.colors.extraBlue2}
      />
    </>
  )
}
