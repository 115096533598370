import { isDefined } from 'shared/guards'
import { call, put, select, takeEvery } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../../../store/shared/sagas'
import { getActiveTrust } from '../../TrustInfo/store/selectors'
import { fetchGrantorsListSvc } from '../api/grantorService'
import { grantorsInfoDataActions } from './actions'

function* handleGetGrantorList() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const activeTrust = yield* select(getActiveTrust)
    const activityIds = activeTrust?.account_rcm_grantors
      ?.map((x) => x.activityid || undefined)
      ?.filter(isDefined)
    const grantors = yield* call(() =>
      fetchGrantorsListSvc(activityIds || [], options)
    )

    yield put(grantorsInfoDataActions.success(grantors || []))
  } catch (e) {
    yield put(grantorsInfoDataActions.failure(e))
  }
}

export const sagas = [
  () => takeEvery(grantorsInfoDataActions.request, handleGetGrantorList)
]
