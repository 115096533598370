import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IReportSearchRequest } from 'api/datahub'
import { flow } from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import { optionsIncomeAndExpenseReportFeaturesReducer } from './features'
import { getOptionsIncomeAndExpenseReportRootState } from './shared'

export type OptionsIncomeAndExpenseReportCriteria = IReportSearchRequest & {
  householdName?: string
}
interface IOptionsIncomeAndExpenseReportState {
  criteria?: OptionsIncomeAndExpenseReportCriteria
}

const { reducer, actions } = createSlice({
  name: '@modules/@advisory/@modules/@reports/@options-income-and-expense-report/@optionsIncomeAndExpenseReport',
  initialState: {} as IOptionsIncomeAndExpenseReportState,
  reducers: {
    setCriteria: (
      state,
      action: PayloadAction<OptionsIncomeAndExpenseReportCriteria | undefined>
    ) => ({
      ...state,
      criteria: action.payload
    })
  }
})

const rootSelector = flow(
  getOptionsIncomeAndExpenseReportRootState,
  (x) => x.report
)
const getOptionsAndExpenseReportCriteria = createSelector(
  rootSelector,
  (x) => x.criteria
)

export const optionsIncomeAndExpenseReportReducer = combineReducers({
  features: optionsIncomeAndExpenseReportFeaturesReducer,
  report: reducer
})

export const useOptionsIncomeAndExpenseReport = () => {
  const dispatch = useDispatch()
  const criteria = useSelector(getOptionsAndExpenseReportCriteria)
  const setCriteria = useCallback(
    (criteria: OptionsIncomeAndExpenseReportCriteria) =>
      dispatch(actions.setCriteria(criteria)),
    [dispatch]
  )
  const reset = useCallback(() => dispatch(actions.setCriteria()), [dispatch])

  return { criteria, setCriteria, reset }
}
