import { flow, groupBy, uniqBy } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { getDynamicsUserBusinessunitId } from 'store/user/dynamicsUser'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { IAdvisorRep, ISystemUser } from '../../../../../api/dynamics'
import {
  isNotNullOrEmpty,
  isNotNullOrUndefined
} from '../../../../../shared/guards'
import { AppState } from '../../../../../store'
import {
  selectAccountRepsWithSplitVisibility,
  selectAdvisorRepsForUser
} from '../../../store/domain'

const SET_FILTER =
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@usersAndTeams/SET_FILTER'
const RESET =
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@usersAndTeams/RESET'

export const usersAndTeamsActions = {
  setFilter: createAction(SET_FILTER)<string | undefined>(),
  reset: createAction(RESET)()
}

export interface IUsersAndTeamsState {
  filter?: string
}

const initialState: IUsersAndTeamsState = {}

export const usersAndTeamsReducer = createReducer<
  IUsersAndTeamsState,
  ActionType<typeof usersAndTeamsActions>
>(initialState)
  .handleAction(usersAndTeamsActions.setFilter, (state, action) => ({
    ...state,
    filter: action.payload
  }))
  .handleAction(usersAndTeamsActions.reset, () => ({
    ...initialState
  }))

const rootSelector = (state: AppState) =>
  state.features.domain.features.repSelector.usersAndTeams

const getUsersAndTeamsFilter = flow(rootSelector, ({ filter }) => filter)

const getAllBusinessUnits = createSelector(
  [selectAccountRepsWithSplitVisibility],
  (advisorReps) =>
    uniqBy(
      advisorReps
        .map((x) => x?.rep?.rcm_OwningTeam?.businessunitid)
        .filter(isNotNullOrUndefined),
      ({ businessunitid }) => businessunitid
    )
)

export interface IUsersAndTeamsAdvisorWithPersonalReps {
  advisor?: ISystemUser
  reps?: IAdvisorRep[]
}

const getUserAdvisorInfo = createSelector(
  [selectAdvisorRepsForUser],
  (reps): IUsersAndTeamsAdvisorWithPersonalReps | undefined => {
    if (!reps?.length) {
      return
    }

    return {
      advisor: reps[0].rcm_PersonalRepFor,
      reps
    }
  }
)

const getAllAdvisors = createSelector(
  [selectAccountRepsWithSplitVisibility],
  (reps) => {
    const groupedByUser = groupBy(
      reps
        .filter((x) => x.userHasAccessToSplits && x.rep.rcm_PersonalRepFor)
        .map(({ rep }) => rep),
      (x) => x?.rcm_PersonalRepFor?.domainname
    )
    return Object.entries(groupedByUser).map(
      ([, reps]): IUsersAndTeamsAdvisorWithPersonalReps => {
        const firstRep = reps?.[0]
        return {
          advisor: firstRep?.rcm_PersonalRepFor,
          reps
        }
      }
    )
  }
)

const getUserBusinessUnit = createSelector(
  [getAllBusinessUnits, getDynamicsUserBusinessunitId],
  (businessUnits, userBusinessunitId) =>
    businessUnits?.find((x) => x.businessunitid === userBusinessunitId)
)

const getFilteredAdvisors = createSelector(
  [getAllAdvisors, getUsersAndTeamsFilter],
  (advisors, filter) => {
    const lowerCaseSearchText = filter?.toLocaleLowerCase()
    if (!lowerCaseSearchText) {
      return advisors
    }

    return advisors?.filter((x) => {
      const businessunit = x.reps?.[0]?.rcm_OwningTeam?.businessunitid
      return [
        x.advisor?.fullname,
        ...(x?.reps?.map(({ rcm_name }) => rcm_name) || []),
        businessunit?.name,
        businessunit?.parentbusinessunitid?.name,
        businessunit?.parentbusinessunitid?.parentbusinessunitid?.name
      ]
        .filter(isNotNullOrEmpty)
        .some((x) => x.toLowerCase().indexOf(lowerCaseSearchText) >= 0)
    })
  }
)

const getFilteredBusinessUnits = createSelector(
  [getAllBusinessUnits, getUsersAndTeamsFilter],
  (businessunits, filter) => {
    const lowerCaseSearchText = filter?.toLocaleLowerCase()
    if (!lowerCaseSearchText) {
      return businessunits
    }

    return businessunits?.filter((x) =>
      [
        x.name,
        x.parentbusinessunitid?.name,
        x.parentbusinessunitid?.parentbusinessunitid?.name
      ]
        .filter(isNotNullOrEmpty)
        .some((x) => x.toLowerCase().indexOf(lowerCaseSearchText) >= 0)
    )
  }
)

export const useUsersAndTeamsStore = () => {
  const dispatch = useDispatch()
  const filteredAdvisors = useSelector(getFilteredAdvisors)
  const filteredTeams = useSelector(getFilteredBusinessUnits)
  const filter = useSelector(getUsersAndTeamsFilter)
  const userAdvisorInfo = useSelector(getUserAdvisorInfo)
  const userTeam = useSelector(getUserBusinessUnit)

  const updateFilter = useCallback(
    (text?: string) => {
      dispatch(usersAndTeamsActions.setFilter(text))
    },
    [dispatch]
  )

  return {
    filteredAdvisors,
    filteredTeams,
    filter,
    userAdvisorInfo,
    userTeam,
    updateFilter
  }
}
