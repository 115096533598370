import { MessageBar, Stack } from '@fluentui/react'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import React from 'react'
import { useSelector } from 'react-redux'
import { useFetchEnhancedProfileByWealthscapeIdQuery } from 'store/api/datahub'
import { getAccountLinkingValidationSelectedClients } from '../../store/accountLinkingValidation'
import { DelinkingListItem } from './DelinkingListItem'

export const DelinkingList: React.FC<{
  addAccount: (account: string) => void
  removeAccount: (account: string) => void
  delinkingAccounts?: string[]
}> = ({ addAccount, removeAccount, delinkingAccounts }) => {
  const [firstClient] =
    useSelector(getAccountLinkingValidationSelectedClients) || []
  const { currentData } = useFetchEnhancedProfileByWealthscapeIdQuery(
    firstClient?.wsportaluserid || skipToken
  )
  const linkedAccounts = currentData?.relatedAccounts?.filter(
    (x) => x.isInProfile
  )

  return linkedAccounts?.length ? (
    <Stack tokens={{ childrenGap: 15 }}>
      {linkedAccounts?.map((x, index) => (
        <DelinkingListItem
          account={x}
          addAccount={addAccount}
          removeAccount={removeAccount}
          isChecked={delinkingAccounts?.includes(x.accountKey) || false}
          key={index}
        />
      ))}
    </Stack>
  ) : (
    <MessageBar>Select Client to View Linked Accounts</MessageBar>
  )
}
