import axios from 'axios'
import { IApiOptions } from '../../../../../../../shared/contracts/IApiOptions'
import {
  IPostingRules,
  IPostingRulesRequest,
  IInstitution,
  ITransaction,
  IPayee,
  IMainAccount,
  ISubAccount,
  IAddEditResponse,
  IAddEditRulesRequest,
  IProject,
  IPerson
} from '../store/types'

export const fetchPostingRulesList = (
  options: IApiOptions,
  postingRulesRequest: IPostingRulesRequest
) => {
  const url = `${options.apiRoot}/trustapi/api/VendorPayeeRule/GetRules?clientid=${postingRulesRequest.clientId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPostingRules[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
export const fetchInstitutionList = (
  options: IApiOptions,
  institutionRequest: IPostingRulesRequest
) => {
  const url = `${options.apiRoot}/trustapi/api/VendorPayeeRule/GetInstitutions?clientid=${institutionRequest.clientId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IInstitution[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
export const fetchTransactionList = (options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/api/VendorPayeeRule/GetTransactions`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<ITransaction[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
export const fetchPayeeList = (
  options: IApiOptions,
  payeeRequest: IPostingRulesRequest
) => {
  const url = `${options.apiRoot}/payment/api/d365/getUIVendorPayee?entityId=${payeeRequest.clientId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPayee[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
export const fetchMainAccountList = (options: IApiOptions) => {
  const url = `${options.apiRoot}/trustapi/api/VendorPayeeRule/GetMainAccounts`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IMainAccount[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
export const fetchSubAccountList = (
  options: IApiOptions,
  subAccountRequest: IPostingRulesRequest
) => {
  const url = `${options.apiRoot}/trustapi/api/VendorPayeeRule/GetSubAccounts?clientid=${subAccountRequest.clientId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<ISubAccount[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
export const addEditRule = (
  options: IApiOptions,
  ruleReq: IAddEditRulesRequest | undefined
) => {
  let url = `${options.apiRoot}/trustapi/api/VendorPayeeRule/AddRule`
  if (ruleReq?.mode === 'edit') {
    url = `${options.apiRoot}/trustapi/api/VendorPayeeRule/UpdateRule`
  }
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IAddEditResponse>(url, ruleReq?.rule, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchProjectList = (
  options: IApiOptions,
  projectReq: IPostingRulesRequest
) => {
  const url = `${options.apiRoot}/trustapi/api/VendorPayeeRule/GetDimensionValues?dimname=project&clientid=${projectReq.clientId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IProject[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchPersonList = (
  options: IApiOptions,
  personReq: IPostingRulesRequest
) => {
  const url = `${options.apiRoot}/trustapi/api/VendorPayeeRule/GetDimensionValues?dimname=person&clientid=${personReq.clientId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IPerson[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
