import { flow, orderBy, sum } from 'lodash'
import { createSelector } from 'reselect'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { IFacetResult } from '../../../../../api/common.types'
import { IDiscountShares } from '../../../../../api/datahub'
import { IAdvisorRep } from '../../../../../api/dynamics'
import { parseDateISOStringInLocalTimezone } from '../../../../../shared'
import { AppState } from '../../../../../store'
import { getDiscountSharingFetchResult } from './discountSharing'
import { getDiscountSplitsFetchResult, IPools, ISplits } from './discountSplits'

const SET_DIVISION_FILTER =
  '@modules/@advisory/@modules/@bda/@discountSplitUI/SET_DIVISION_FILTER'
const SET_HUB_FILTER =
  '@modules/@advisory/@modules/@bda/@discountSplitUI/SET_HUB_FILTER'
const RESET = '@modules/@advisory/@modules/@bda/@discountSplitUI/RESET'

export const discountSplitUIActions = {
  setDivisionFilter: createAction(SET_DIVISION_FILTER)<string[]>(),
  setHubFilter: createAction(SET_HUB_FILTER)<string[]>(),
  reset: createAction(RESET)()
}

export type DiscountSplitUIActionTypes = ActionType<
  typeof discountSplitUIActions
>

export interface IDiscountSplitUIState {
  divisionFilter?: string[]
  hubFilter?: string[]
}

const initialState: IDiscountSplitUIState = {
  divisionFilter: [],
  hubFilter: []
}

export const discountSplitUIReducer = createReducer<
  IDiscountSplitUIState,
  DiscountSplitUIActionTypes
>(initialState)
  .handleAction(discountSplitUIActions.setDivisionFilter, (state, action) => ({
    ...state,
    divisionFilter: action.payload
  }))
  .handleAction(discountSplitUIActions.setHubFilter, (state, action) => ({
    ...state,
    hubFilter: action.payload
  }))
  .handleAction(discountSplitUIActions.reset, () => ({
    ...initialState
  }))

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.bda.discountSplitUI

export const divisionFilter = flow(
  rootSelector,
  ({ divisionFilter }) => divisionFilter
)

export const hubFilter = flow(rootSelector, ({ hubFilter }) => hubFilter)

export const getPools = createSelector(
  [getDiscountSharingFetchResult, getDiscountSplitsFetchResult],
  (items, splits) => {
    if (!items?.length || !splits?.length) {
      return [] as IPools[]
    }
    return createPools(items, splits)
  }
)

const createPools = (items: IDiscountShares[], splits: IAdvisorRep[]) => {
  let pools: IPools[] = splits.map((split) => {
    const pool: IPools = {
      rowlabel: split.rcm_repid || '',
      poolname: split.rcm_OwningTeam?.name || '',
      splits: createSplits(split),
      isPool: !split.rcm_PersonalRepFor,
      repcodes: [split.rcm_repid || ''],
      monthlyDiscounts: new Array(12).fill(0),
      hub: split.rcm_OwningTeam?.businessunitid?.parentbusinessunitid?.name,
      division:
        split.rcm_OwningTeam?.businessunitid?.parentbusinessunitid
          ?.parentbusinessunitid?.name
    }
    return pool
  })
  items.forEach((discount) => {
    const index = pools.map((x) => x.rowlabel).indexOf(discount.rcm_rowlabels)
    const month = parseDateISOStringInLocalTimezone(
      discount.rcm_tradedate
    ).getMonth()
    pools[index]?.splits?.forEach((split) => {
      split.monthlyDiscounts[month] +=
        (discount.rcm_discountsharing * split.percentage) / 100
    })
  })
  const individuals = pools.filter((pool) => !pool.isPool)
  pools = pools.filter((pool) => pool.isPool)
  const groupedPools: IPools[] = []
  pools.forEach((pool) => {
    const index = groupedPools.map((x) => x.poolname).indexOf(pool.poolname)
    if (index === -1) {
      groupedPools.push(pool)
    } else {
      groupedPools[index].repcodes = groupedPools[index].repcodes.concat(
        pool.repcodes
      )
      pool.splits.forEach((split) => {
        const splitIndex = groupedPools[index].splits
          .map((x) => x.repcode)
          .indexOf(split.repcode)
        if (splitIndex === -1) {
          groupedPools[index].splits.push(split)
        } else {
          groupedPools[index].splits[splitIndex].monthlyDiscounts =
            groupedPools[index].splits[splitIndex].monthlyDiscounts.map(
              (discount, i) => discount + split.monthlyDiscounts[i]
            )
        }
      })
    }
  })
  pools = groupedPools
  pools.forEach((pool) => {
    pool.splits.forEach((split) => {
      const index = individuals.map((x) => x.rowlabel).indexOf(split.repcode)
      if (index !== -1) {
        split.name = individuals[index].splits[0].name
      }
    })
  })
  individuals.forEach((individual) => {
    const index = pools.map((x) => x.poolname).indexOf(individual.poolname)
    if (index === -1) {
      pools.push(individual)
    } else {
      if (!pools[index].hub) {
        pools[index].hub = individual.hub
      }
      if (!pools[index].division) {
        pools[index].division = individual.division
      }
      pools[index].repcodes.push(individual.rowlabel)
      const splitIndex = pools[index].splits
        .map((x) => x.repcode)
        .indexOf(individual.rowlabel)
      if (splitIndex === -1) {
        pools[index].splits.push(individual.splits[0])
      } else {
        pools[index].splits[splitIndex].monthlyDiscounts = pools[index].splits[
          splitIndex
        ].monthlyDiscounts.map(
          (discount, i) => discount + individual.splits[0].monthlyDiscounts[i]
        )
      }
    }
  })
  pools.forEach((pool) => {
    pool.splits.forEach((split) => {
      pool.monthlyDiscounts = pool.monthlyDiscounts.map(
        (discount, i) => discount + split.monthlyDiscounts[i]
      )
    })
  })
  pools.forEach(
    (pool) => (pool.splits = pool.splits.filter((split) => split.name))
  )
  pools = pools.filter((pool) => sum(pool.monthlyDiscounts) !== 0)
  pools.map((pool) => {
    pool.splits = pool.splits.filter(
      (split) => sum(split.monthlyDiscounts) !== 0
    )
  })
  return pools
}

const createSplits = (split: IAdvisorRep) => {
  const splits: ISplits[] = []
  split.rcm_PersonalRepFor
    ? splits.push({
        repcode: split.rcm_repid,
        monthlyDiscounts: new Array(12).fill(0),
        percentage: 100,
        name: split.rcm_PersonalRepFor.fullname
      } as ISplits)
    : split.rcm_AdvisorRepPoolSplits_PoolRep_rcm_Advi?.forEach((rep) => {
        splits.push({
          repcode: rep.rcm_calc_personalrepid,
          monthlyDiscounts: new Array(12).fill(0),
          percentage: rep.rcm_percentage,
          poolrep: rep.rcm_calc_poolrepid
        } as ISplits)
      })
  return splits
}

export const getFilteredPools = createSelector(
  [getPools, divisionFilter, hubFilter],
  (pools, divisions, hubs) => {
    if (divisions && divisions.length > 0) {
      pools = pools.filter((pool) => {
        return divisions?.includes(pool.division || '')
      })
    }
    if (hubs && hubs.length > 0) {
      pools = pools.filter((pool) => {
        return hubs?.includes(pool.hub || '')
      })
    }
    pools = orderBy(pools, ({ poolname = '' }) => poolname, 'asc')
    return pools
  }
)

export const getHubs = createSelector([getPools], (pools) => {
  const hubs: IFacetResult[] = []
  pools.forEach((pool) => {
    if (pool.hub) {
      const index = hubs.map((x) => x.value).indexOf(pool.hub)
      if (index === -1) {
        hubs.push({ count: 1, value: pool.hub })
      } else {
        hubs[index].count += 1
      }
    }
  })
  return hubs
})

export const getDivisions = createSelector([getPools], (pools) => {
  const divisions: IFacetResult[] = []
  pools.forEach((pool) => {
    if (pool.division) {
      const index = divisions.map((x) => x.value).indexOf(pool.division)
      if (index === -1) {
        divisions.push({ count: 1, value: pool.division })
      } else {
        divisions[index].count += 1
      }
    }
  })
  return divisions
})

export const getMonthlyTotals = createSelector([getFilteredPools], (pools) => {
  return getTotals(pools)
})

const getTotals = (pools: IPools[]) => {
  const totals: number[] = new Array(12).fill(0)
  pools.forEach((pool) => {
    pool.monthlyDiscounts.map((discount, index) => (totals[index] += discount))
  })
  return totals
}

export const getInvalidSplits = createSelector(
  [getDiscountSplitsFetchResult],
  (splits) => {
    const invalid: string[] = []
    splits?.map((split) => {
      if (
        split.rcm_AdvisorRepPoolSplits_PoolRep_rcm_Advi &&
        split.rcm_AdvisorRepPoolSplits_PoolRep_rcm_Advi.length
      ) {
        const totalPercent = sum(
          split.rcm_AdvisorRepPoolSplits_PoolRep_rcm_Advi.map(
            (x) => x.rcm_percentage
          )
        )
        if (Math.abs(totalPercent - 100) > 0.00001) {
          invalid.push(split.rcm_repid)
        }
      }
    })
    return invalid
  }
)

export const accountLinkingValidationSagas = []
