import { AppState } from '../../../../../store'

export const getAllCashReceiptState = (state: AppState) =>
  state.modules.products.cashReceipts.cashReceiptsState

export const getAllProductTypesState = (state: AppState) =>
  state.modules.products.cashReceipts.productTypesState

export const updateAllCashReceiptState = (state: AppState) =>
  state.modules.products.cashReceipts.updateAllCashReceiptState

export const selectedMonthYearForCashReceiptSetting = (state: AppState) =>
  state.modules.products.cashReceipts.selectedMonthYear

export const getUploadedFilesState = (state: AppState) =>
  state.modules.products.cashReceipts.uploadedFilesRes

export const getDeleteFilesRes = (state: AppState) =>
  state.modules.products.cashReceipts.deleteDocumentRes

export const getSelectedCashReceipt = (state: AppState) =>
  state.modules.products.cashReceipts.selectedCashReceipt

export const getUpdateCashReceiptBYProviderState = (state: AppState) =>
  state.modules.products.cashReceipts.updateCashReceiptByProviderState

export const getUpdatedCashReceiptFlag = (state: AppState) =>
  state.modules.products.cashReceipts.updatedCashReceiptFlag

export const getAllocatedAmount = (state: AppState) =>
  state.modules.products.cashReceipts.fetchAllocatedAmountState
