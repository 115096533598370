export interface IOfacScreenRecord {
  ofactransactionid?: string
  transourcetypecd?: string
  accountno?: string
  sourcesystemid?: string
  tranreferenceno?: string
  trancategory?: string
  trantype?: string
  tranentrydate?: Date
  tranamount?: number
  beneentityname?: string
  benefirstname?: string
  benemiddlename?: string
  benelastname?: string
  benenametitle?: string
  benenamesuffix?: string
  beneaddressline1?: string
  beneaddressline2?: string
  beneaddressline3?: string
  beneaddressline4?: string
  bankcode?: string
  bankcodetypecd?: string
  bankname?: string
  scandate?: string
  scandstate?: string
  isreviewneeded?: boolean
  scanresults?: string
  scandata?: string
  accountownername?: string
}

export interface Name {
  First: string
  Full: string
  Generation: string
  Last: string
  Middle: string
  Title: string
}

export interface History {
  Date: Date
  Event: string
  Note: string
  User: string
}

export interface MatchState {
  MatchID: any
  Type: number
}

export interface RecordState {
  AddedToAcceptList: boolean
  AlertState: number
  AssignedTo: string[]
  AssignmentType: number
  Division: string
  History: History[]
  MatchStates: MatchState[]
  Status: string
}

export interface RecordDetails {
  AcceptListID: number
  AccountAmount: string
  AccountGroupID: string
  AccountOtherData: string
  AccountProviderID: string
  AccountType: string
  AddedToAcceptList: boolean
  Division: string
  DPPA: number
  EFTContext: string
  EFTType: number
  EntityType: number
  Gender: number
  GLB: number
  LastUpdatedDate: Date
  Name: Name
  RecordState: RecordState
  SearchDate: Date
  Text: string
}

export interface Conflicts {
  AddressConflict: boolean
  CitizenshipConflict: boolean
  CountryConflict: boolean
  DOBConflict: boolean
  EntityTypeConflict: boolean
  GenderConflict: boolean
  IDConflict: boolean
  PhoneConflict: boolean
}

export interface AdditionalInfo {
  ID: number
  Type: number
  TypeName: string
  Value: string
  Comments: string
}

export interface AKA {
  Category: number
  ID: number
  Name: Name
  Type: number
  TypeName: string
  Comments: string
}
export interface Address {
  Country: string
  ID: number
  Type: number
  TypeName: string
  City: string
  PostalCode: string
  StateProvinceDistrict: string
  Street1: string
}

export interface ID {
  ID: number
  Number: string
  Type: number
}

export interface EntityDetails {
  AdditionalInfo: AdditionalInfo[]
  AKAs: AKA[]
  Comments: string
  EntityType: number
  ListReferenceNumber: string
  Name: Name
  ReasonListed: string
  Addresses: Address[]
  DateListed: string
  Gender: string
  IDs: ID[]
}

export interface File {
  Build: Date
  Custom: boolean
  ID: number
  Name: string
  Published: Date
  Type: number
}

export interface Match {
  AcceptListID: number
  AddedToAcceptList: boolean
  AddressName: boolean
  AutoFalsePositive: boolean
  BestAddressIsPartial: boolean
  BestCountry: string
  BestCountryScore: number
  BestCountryType: number
  BestDOBIsPartial: boolean
  BestName: string
  BestNameScore: number
  CheckSum: number
  Conflicts: Conflicts
  EntityDetails: EntityDetails
  EntityName: string
  EntityScore: number
  EntityUniqueID: string
  FalsePositive: boolean
  File: File
  GatewayOFACScreeningIndicatorMatch: boolean
  ID: any
  MatchReAlert: boolean
  PreviousResultID: number
  ReasonListed: string
  ResultDate: Date
  SecondaryOFACScreeningIndicatorMatch: boolean
  TrueMatch: boolean
}

export interface Watchlist {
  Matches: Match[]
  Status: number
}

export interface Record {
  Record: number
  RecordDetails: RecordDetails
  ResultID: number
  RunID: number
  Watchlist: Watchlist
}

export interface SearchResult {
  ClientReference: string
  Records: Record[]
  SearchEngineVersion: string
}

export interface Body {
  SearchResult: SearchResult
}

export interface IScanResult {
  Body: Body
}

export enum ScanStateEnum {
  Closed = 'UCLOS',
  Good = 'UIGO',
  NotGood = 'UNIGO',
  Completed = 'SCMPL',
  Pending = 'SPEND',
  InProgress = 'SINPR',
  Failed = 'SFAIL',
  NeedReview = 'UREVW',
  Skipped = 'SSKIP'
}

export enum ResultTypeEnum {
  NeedReview = 'Need Review'
}

export function getEnumKeyByEnumValue(
  enumeration: any,
  enumValue: number | string
): string {
  const keys = Object.keys(enumeration).filter(
    (x) => enumeration[x] === enumValue
  )
  return keys.length > 0 ? keys[0] : 'Other'
}

export interface IOfacTransactionStatusUpdateReq {
  OFACTransactionId: string
  ScanState: string
  UserComments?: string
}

export interface IFilterDateRange {
  FromDate: Date
  ToDate: Date
}

export interface IOfacTransactionStatusUpdate {
  updateRequest: IOfacTransactionStatusUpdateReq
  filterDateRange: IFilterDateRange
}

export interface IScanData {
  OFACTransactionId?: string
  BeneEntityName?: string
  BeneFirstName?: string
  BeneLastName?: string
  BankCode?: string
  BankCodeTypeCd?: string
  TranReferenceNo?: string
  BeneAddressLine1?: string
  BeneAddressLine2?: string
  BeneAddressLine3?: string
  BeneAddressLine4?: string
  BankFC1?: string
  BankFC2?: string
}
