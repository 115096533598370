import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { IInteractionType } from 'modules/Advisory/modules/Rdot360/store/dynamics'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

const { actions, reducer } = createSlice({
  name: 'interactionsPanel',
  initialState: {} as IInteractionsPanelState,
  reducers: {
    open: () => ({
      isOpen: true
    }),
    close: () => ({
      isOpen: false,
      error: undefined
    }),
    failure: (state, action: PayloadAction<Error>) => ({
      ...state,
      error: action.payload
    }),
    setType: (state, action: PayloadAction<IInteractionType | undefined>) => ({
      ...state,
      interactionType: action.payload
    })
  }
})

export { reducer as interactionsPanelReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.secondaryHeader.interactions
    .interactionsPanel

const getIsPanelOpen = flow(rootSelector, (x) => x.isOpen)
const getPanelError = flow(rootSelector, (x) => x.error)
const getInteractionType = flow(rootSelector, (x) => x.interactionType)

export interface IInteractionsPanelState {
  isOpen?: boolean
  error?: Error
  interactionType?: IInteractionType
}

export const useInteractionsPanel = () => {
  const dispatch = useDispatch()

  const close = useCallback(() => {
    dispatch(actions.close())
    dispatch(actions.setType(undefined))
  }, [dispatch])

  const open = useCallback(
    (type?: IInteractionType) => {
      dispatch(actions.open())
      if (type) {
        dispatch(actions.setType(type))
      }
    },
    [dispatch]
  )

  const setError = useCallback(
    (e: Error) => {
      dispatch(actions.failure(e))
    },
    [dispatch]
  )

  const setInteractionType = useCallback(
    (type?: IInteractionType) => {
      dispatch(actions.setType(type))
    },
    [dispatch]
  )

  const isOpen = useSelector(getIsPanelOpen)

  const error = useSelector(getPanelError)

  const interactionType = useSelector(getInteractionType)

  return {
    close,
    open,
    setError,
    setInteractionType,
    isOpen,
    error,
    interactionType
  }
}
