import {
  Checkbox,
  FontIcon,
  IconButton,
  SearchBox,
  Stack
} from '@fluentui/react'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { downloadUrlAsFile } from 'shared/downloads'
import { useAccountRepTreeStore } from './store/accountRepTree'
import { AccountPrepsExportWorker } from './store/export'

export const AccountRepTreeHeader: React.FC = () => {
  const {
    filteredAccountReps,
    selectedLookup: accountRepSelectedLookup,
    selectAllVisible,
    clearAllSelected,
    collapseAllVisible,
    expandAll,
    updateFilter,
    filter,
    showSelectedOnly,
    toggleShowSelectedOnly
  } = useAccountRepTreeStore()

  const exportToExcel = useCallback(async () => {
    const worker = new Worker(new URL('./store/export.ts', import.meta.url))

    const { createAndDownloadAccountPrepsExport } =
      wrap<AccountPrepsExportWorker>(worker)

    const result = await createAndDownloadAccountPrepsExport(
      filteredAccountReps
    )

    const filename = `Reps Summary ${format(new Date(), 'MM-dd-yyyy')}.xlsx`

    downloadUrlAsFile(result, filename)
  }, [filteredAccountReps])

  const allAreSelected = useMemo(
    () =>
      !!filteredAccountReps.length &&
      filteredAccountReps.every(({ id }) => accountRepSelectedLookup[id]),
    [accountRepSelectedLookup, filteredAccountReps]
  )

  const someAreSelected = useMemo(
    () =>
      !allAreSelected &&
      filteredAccountReps.some(({ id }) => accountRepSelectedLookup[id]),
    [accountRepSelectedLookup, allAreSelected, filteredAccountReps]
  )

  const onSearchChange = useCallback(
    (_: any, newValue?: string) => {
      updateFilter(newValue)
      expandAll()
    },
    [expandAll, updateFilter]
  )

  const debouncedOnSearchChange = useMemo(
    () => debounce(onSearchChange, 100),
    [onSearchChange]
  )

  const [headerCollapseToggleState, setHeaderCollapseToggleState] =
    useState<boolean>()
  const toggleHeaderCollapsedState = useCallback(() => {
    setHeaderCollapseToggleState(!headerCollapseToggleState)
  }, [headerCollapseToggleState])

  useEffect(() => {
    if (headerCollapseToggleState === undefined) {
      return
    }

    headerCollapseToggleState ? collapseAllVisible() : expandAll()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerCollapseToggleState])

  return (
    <Stack.Item grow={1}>
      <Stack
        horizontal={true}
        verticalAlign="center"
        tokens={{ childrenGap: 5 }}
      >
        <FontIcon
          iconName={
            headerCollapseToggleState ? 'ChevronRightSmall' : 'ChevronDownSmall'
          }
          onClick={toggleHeaderCollapsedState}
          style={{ fontSize: '11px', cursor: 'pointer' }}
        />

        <Checkbox
          indeterminate={someAreSelected}
          checked={allAreSelected}
          onChange={(_, checked) =>
            checked ? selectAllVisible() : clearAllSelected()
          }
        />
        <Stack.Item grow={1}>
          <SearchBox
            placeholder="Filter Account Reps"
            defaultValue={filter || ''}
            onChange={debouncedOnSearchChange}
            autoComplete="off"
          />
        </Stack.Item>
        <IconButton
          iconProps={{ iconName: 'CheckList' }}
          title="Show Selected Items Only"
          checked={showSelectedOnly}
          onClick={toggleShowSelectedOnly}
        />
        <IconButton
          disabled={filteredAccountReps.length === 0}
          iconProps={{
            iconName: 'ExcelLogo',
            ariaLabel: 'Export To Excel',
            title: 'Export To Excel'
          }}
          onClick={exportToExcel}
        />
      </Stack>
    </Stack.Item>
  )
}
