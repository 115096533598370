import { combineReducers, Reducer } from 'redux'
import { dashboardReducer } from '../features/Dashboard/store/reducer'

import {
  ISIState,
  SIReducer
} from '../features/MoneyMovement/store/EFT/reducer'
import {
  IJournalState,
  journalReducer
} from '../features/MoneyMovement/store/Journal/reducer'
import {
  IPeriodicPaymentState,
  periodicPaymentReducer
} from '../features/MoneyMovement/store/PeriodicPayment/reducer'
import {
  IWireState,
  wireReducer
} from '../features/MoneyMovement/store/reducer'

export interface ITransferModuleState {
  dashboard: ReturnType<typeof dashboardReducer>
  wire: IWireState
  journal: IJournalState
  periodicPayment: IPeriodicPaymentState
  standingInstruction: ISIState
}

export const transferReducer: Reducer<ITransferModuleState> = combineReducers({
  dashboard: dashboardReducer,
  wire: wireReducer,
  journal: journalReducer,
  periodicPayment: periodicPaymentReducer,
  standingInstruction: SIReducer
})
