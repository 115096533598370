import { flexRender } from '@tanstack/react-table'
import { Row } from '@tanstack/table-core'
import { useMemo } from 'react'
import { IActivity } from '../../../../store/types'
import { activityDetailColumns } from '../../shared'
import { SymbolCusipPrintCell } from './SymbolCusipPrintCell'

export const PrintTableBody: React.FC<{
  rows: Row<IActivity>[]
}> = ({ rows }) => {
  const lookUp = useMemo(
    () => ({
      [activityDetailColumns.symbolCusip]: SymbolCusipPrintCell
    }),
    []
  )

  const printCellLookUp = (columnId?: string) => {
    return columnId ? lookUp[columnId] : undefined
  }

  return (
    <tbody>
      {rows.map((row) => {
        const visibleCells = row.getVisibleCells()
        return (
          <tr key={row.id}>
            {visibleCells.map((cell) => {
              const isPlaceholder = cell.getIsPlaceholder()
              const isAggregated = cell.getIsAggregated()
              const printCell = printCellLookUp(cell.column.columnDef.id)
              return (
                <td key={cell.id}>
                  {!isPlaceholder &&
                    !isAggregated &&
                    flexRender(
                      printCell ?? cell.column.columnDef.cell,
                      cell.getContext()
                    )}
                  {!isPlaceholder &&
                    isAggregated &&
                    flexRender(
                      cell.column.columnDef.aggregatedCell ??
                        cell.column.columnDef.cell,
                      cell.getContext()
                    )}
                </td>
              )
            })}
          </tr>
        )
      })}
    </tbody>
  )
}
