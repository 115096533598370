import { createReducer } from 'typesafe-actions'
import {
  performanceDashboardActions,
  ReportDataActionsTypes,
  runReportActions,
  setSelectedMonthYearActions
} from './actions'
import { IReportSummaryListEntity, IRunJob } from './types'
export interface IReportState {
  performanceReport: {
    isLoading: boolean
    reports?: IReportSummaryListEntity[]
    error?: Error
  }
  runJob: {
    isLoading: boolean
    performanceReportResponse?: IRunJob
    error?: Error
  }
  selectedMonthYear?: string
}

const initialState: IReportState = {
  performanceReport: {
    isLoading: true,
    reports: []
  },
  runJob: {
    isLoading: false
  }
}

export const performanceReducer = createReducer<
  IReportState,
  ReportDataActionsTypes
>(initialState)
  .handleAction(performanceDashboardActions.request, (state) => ({
    ...state,
    performanceReport: {
      ...state.performanceReport,
      error: undefined,
      isLoading: true
    }
  }))
  .handleAction(performanceDashboardActions.success, (state, action) => ({
    ...state,
    performanceReport: {
      ...state.performanceReport,
      reports: action.payload || [],
      error: undefined,
      isLoading: false
    }
  }))
  .handleAction(performanceDashboardActions.failure, (state, action) => ({
    ...state,
    dashboardFees: {
      ...state.performanceReport,
      error: action.payload,
      isLoading: false
    }
  }))
  .handleAction(runReportActions.request, (state) => ({
    ...state,
    runJob: {
      ...state.runJob,
      error: undefined,
      isLoading: true
    }
  }))
  .handleAction(runReportActions.reset, (state) => ({
    ...state,
    runJob: {
      ...state.runJob,
      error: undefined,
      isLoading: false,
      performanceReportResponse: undefined
    }
  }))
  .handleAction(runReportActions.success, (state, action) => ({
    ...state,
    runJob: {
      ...state.runJob,
      performanceReportResponse: action.payload || '',
      error: undefined,
      isLoading: false
    }
  }))
  .handleAction(runReportActions.failure, (state, action) => ({
    ...state,
    runJob: {
      ...state.runJob,
      error: action.payload,
      isLoading: false
    }
  }))
  .handleAction(
    setSelectedMonthYearActions.selectedMonthYear,
    (state, action) => ({
      ...state,
      selectedMonthYear: action.payload || undefined,
      closeMonthState: undefined
    })
  )
