import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import { FormattedNumber } from 'react-intl'
import { AccountNumberCell } from '../../components/shared/DetailTables/AccountNumberCell'
import { HeaderContainer } from '../../components/shared/DetailTables/HeaderContainer'
import { TextCell } from '../../features/WidgetTable/WidgetTable'

export interface IFormattedData {
  periodDate: Date
  date: Date
  accountNumber: string
  contact: string
  description: string
  allocatedAmount: number
  revenueCategory: string
  revenueCategoryDetail: string
}

export const constructColumns = () => {
  const columnHelper = createColumnHelper<IFormattedData | undefined>()
  const columns = [
    columnHelper.accessor((asset) => asset?.periodDate, {
      id: 'payrollPeriodDate',
      cell: (asset) => (
        <div css={{ paddingLeft: '10px' }}>
          {format(asset.getValue() || new Date(), "MMM'' yy")}
        </div>
      ),
      header: (props) => (
        <div css={{ paddingLeft: '10px' }}>
          <HeaderContainer
            title="Payroll Period Date"
            isSorted={props.column.getIsSorted()}
          />
        </div>
      ),
      size: 100
    }),
    columnHelper.accessor((asset) => asset?.date, {
      id: 'tradeOrRevenueDate',
      cell: (asset) => (
        <div css={{ textAlign: 'right' }}>
          {format(asset.getValue() ?? new Date(), 'yyyy-MM-dd')}
        </div>
      ),
      header: (props) => (
        <HeaderContainer
          title="Trade/ Revenue Date"
          isSorted={props.column.getIsSorted()}
          rightAlign={true}
        />
      ),
      size: 100
    }),
    columnHelper.accessor((asset) => asset?.accountNumber, {
      id: 'accountNumber',
      cell: (asset) => (
        <div>
          <AccountNumberCell accountIdOrKey={asset.getValue()} />
        </div>
      ),
      header: (props) => (
        <HeaderContainer
          title="Account Number"
          isSorted={props.column.getIsSorted()}
        />
      ),
      size: 150
    }),
    columnHelper.accessor((asset) => asset?.description, {
      id: 'description',
      cell: (asset) => <TextCell value={asset.getValue()} />,
      header: (props) => (
        <HeaderContainer
          title="Description"
          isSorted={props.column.getIsSorted()}
        />
      ),
      size: 200
    }),
    columnHelper.accessor((asset) => asset?.allocatedAmount, {
      id: 'allocatedAmount',
      cell: (asset) => (
        <div css={{ textAlign: 'right' }}>
          <FormattedNumber
            value={asset.getValue() ?? 0}
            style="currency"
            currency="USD"
          />
        </div>
      ),
      header: (props) => (
        <HeaderContainer
          title="Allocated Amount"
          isSorted={props.column.getIsSorted()}
          rightAlign={true}
        />
      ),
      size: 120
    }),
    columnHelper.accessor((asset) => asset?.revenueCategory, {
      id: 'Revenue Category',
      cell: (asset) => <TextCell value={asset.getValue()} />,
      header: (props) => (
        <HeaderContainer
          title="Revenue Category"
          isSorted={props.column.getIsSorted()}
        />
      ),
      size: 150
    }),
    columnHelper.accessor((asset) => asset?.revenueCategoryDetail, {
      id: 'revenueCategoryDetail',
      cell: (asset) => (
        <div css={{ paddingRight: '10px' }}>{asset.getValue()}</div>
      ),
      header: (props) => (
        <div css={{ paddingRight: '10px' }}>
          <HeaderContainer
            title="Revenue Category Detail"
            isSorted={props.column.getIsSorted()}
          />
        </div>
      ),
      size: 150
    })
  ]
  return columns
}
