import { IAccount } from 'api/account.types'
import { ISearchResult } from 'api/common.types'
import { createAction, ActionType } from 'typesafe-actions'
import { IPHubTypeCode } from '../../api/types'
import { IWireInitiationForm } from '../types'
import { IJournalInitiationForm, IgetGiftResponse } from './types'

export const ACCOUNT_SEARCH_REQUEST = `@modules/@transfers/@moneymovement/@journal/ACCOUNT_SEARCH_REQUEST`
export const ACCOUNT_SEARCH_SETDETAILS = `@modules/@transfers/@moneymovement/@journal/ACCOUNT_SEARCH_SETDETAILS`
export const ACCOUNT_SEARCH_SUCCESS = `@modules/@transfers/@moneymovement/@journal/ACCOUNT_SEARCH_SUCCESS`
export const ACCOUNT_SEARCH_FAILURE = `@modules/@transfers/@moneymovement/@journal/ACCOUNT_SEARCH_FAILURE`
export const ACCOUNT_SEARCH_CLEAR = `@modules/@transfers/@moneymovement/@journal/ACCOUNT_SEARCH_CLEAR`

export const toAccountSearchActions = {
  request: createAction(ACCOUNT_SEARCH_REQUEST)<string | undefined>(),
  setAccountDetails: createAction(ACCOUNT_SEARCH_SETDETAILS)<{
    account: string
    wireData: IWireInitiationForm | IJournalInitiationForm
    isToAccount?: boolean
    updateIsWithinAccount?: boolean
  }>(),
  success: createAction(ACCOUNT_SEARCH_SUCCESS)<ISearchResult<IAccount>>(),
  failure: createAction(ACCOUNT_SEARCH_FAILURE)<Error>(),
  clear: createAction(ACCOUNT_SEARCH_CLEAR)()
}

export const HOUSEHOLD_ACCOUNT_SEARCH_REQUEST = `@modules/@transfers/@moneymovement/@journal/HOUSEHOLD_ACCOUNT_SEARCH_REQUEST`
export const HOUSEHOLD_ACCOUNT_SEARCH_SUCCESS = `@modules/@transfers/@moneymovement/@journal/HOUSEHOLD_ACCOUNT_SEARCH_SUCCESS`
export const HOUSEHOLD_ACCOUNT_SEARCH_FAILURE = `@modules/@transfers/@moneymovement/@journal/HOUSEHOLD_ACCOUNT_SEARCH_FAILURE`
export const HOUSEHOLD_ACCOUNT_SEARCH_CLEAR = `@modules/@transfers/@moneymovement/@journal/HOUSEHOLD_ACCOUNT_SEARCH_CLEAR`

export const toHouseholdAccountSearchActions = {
  request: createAction(HOUSEHOLD_ACCOUNT_SEARCH_REQUEST)<string | undefined>(),
  success: createAction(HOUSEHOLD_ACCOUNT_SEARCH_SUCCESS)<
    ISearchResult<IAccount>
  >(),
  failure: createAction(HOUSEHOLD_ACCOUNT_SEARCH_FAILURE)<Error>(),
  clear: createAction(HOUSEHOLD_ACCOUNT_SEARCH_CLEAR)()
}
export const FETCH_PHUB_CODES_REQUEST = `@modules/@transfers/@moneymovement/@journal/FETCH_PHUB_CODES_REQUEST`
export const FETCH_PHUB_CODES_SUCCESS = `@modules/@transfers/@moneymovement/@journal/FETCH_PHUB_CODES_SUCCESS`
export const FETCH_PHUB_CODES_FAILURE = `@modules/@transfers/@moneymovement/@journal/FETCH_PHUB_CODES_FAILURE`

export const fetchPhubCodes = {
  request: createAction(FETCH_PHUB_CODES_REQUEST)(),
  success: createAction(FETCH_PHUB_CODES_SUCCESS)<IPHubTypeCode[]>(),
  failure: createAction(FETCH_PHUB_CODES_FAILURE)<Error>()
}

export const GET_DEFAULT_GIFT_CODE_REQUEST = `@modules/@transfers/@moneymovement/@journal/GET_DEFAULT_GIFT_CODE_REQUEST`
export const GET_DEFAULT_GIFT_CODE_SUCCESS = `@modules/@transfers/@moneymovement/@journal/GET_DEFAULT_GIFT_CODE_SUCCESS`
export const GET_DEFAULT_GIFT_CODE_FAILURE = `@modules/@transfers/@moneymovement/@journal/GET_DEFAULT_GIFT_CODE_FAILURE`
export const GET_DEFAULT_GIFT_CODE_CLEAR = `@modules/@transfers/@moneymovement/@journal/GET_DEFAULT_GIFT_CODE_CLEAR`

export const getDefaultGiftCodeActions = {
  request: createAction(GET_DEFAULT_GIFT_CODE_REQUEST)<{
    fromAccount: string
    toAccount: string
  }>(),
  success: createAction(GET_DEFAULT_GIFT_CODE_SUCCESS)<IgetGiftResponse>(),
  failure: createAction(GET_DEFAULT_GIFT_CODE_FAILURE)<Error>(),
  clear: createAction(GET_DEFAULT_GIFT_CODE_CLEAR)()
}

export type JournalActions =
  | ActionType<typeof toAccountSearchActions>
  | ActionType<typeof toHouseholdAccountSearchActions>
  | ActionType<typeof getDefaultGiftCodeActions>
  | ActionType<typeof fetchPhubCodes>
