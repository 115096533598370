import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { IAlert } from 'modules/Advisory/modules/Alerts/store/alerts.types'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

export interface IDialogState {
  isArchiveAlertOpen?: boolean
  errorAlertDetails?: Error
  selectedAlert?: IAlert
  currentArchiveAlertIndex?: number
  isSuccess?: boolean
  errorArchiveAlertDialog?: Error
}

const { actions, reducer } = createSlice({
  name: 'archiveAlert',
  initialState: {
    isSuccess: false
  } as IDialogState,
  reducers: {
    openArchiveAlertDialog: (
      state,
      action: PayloadAction<{
        selectedAlert?: IAlert
        currentArchiveAlertIndex?: number
      }>
    ) => ({
      isArchiveAlertOpen: true,
      selectedAlert: action.payload.selectedAlert,
      currentArchiveAlertIndex: action.payload.currentArchiveAlertIndex || 0
    }),
    closeArchiveAlertDialog: () => ({
      isArchiveAlertOpen: false,
      errorAlertDetails: undefined,
      selectedAlert: undefined,
      currentArchiveAlertIndex: undefined
    }),
    failure: (state, action: PayloadAction<Error>) => ({
      ...state,
      errorArchiveAlertDialog: action.payload
    }),
    success: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isSuccess: action.payload
    })
  }
})

export { reducer as archiveAlertReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.alerts.archiveAlert

const getIsDialogOpen = flow(rootSelector, (x) => x.isArchiveAlertOpen)
const getDialogError = flow(rootSelector, (x) => x.errorArchiveAlertDialog)
const getSelectedAlert = flow(rootSelector, (x) => x.selectedAlert)
const getCurrentIndex = flow(rootSelector, (x) => x.currentArchiveAlertIndex)
export const getIsSuccess = flow(rootSelector, (x) => x.isSuccess)

export const useArchiveAlertDialog = () => {
  const dispatch = useDispatch()

  const closeArchiveAlert = useCallback(() => {
    dispatch(actions.closeArchiveAlertDialog())
  }, [dispatch])

  const openArchiveAlertDialog = useCallback(
    ({
      selectedAlert,
      currentArchiveAlertIndex
    }: {
      selectedAlert?: IAlert
      currentArchiveAlertIndex?: number
    }) => {
      dispatch(
        actions.openArchiveAlertDialog({
          selectedAlert,
          currentArchiveAlertIndex
        })
      )
    },
    [dispatch]
  )

  const setErrorArchiveAlert = useCallback(
    (e: Error) => {
      dispatch(actions.failure(e))
    },
    [dispatch]
  )

  const setSuccess = useCallback(
    (success: boolean) => {
      dispatch(actions.success(success))
    },
    [dispatch]
  )

  const isArchiveAlertOpen = useSelector(getIsDialogOpen)
  const selectedAlertData = useSelector(getSelectedAlert)
  const errorArchiveAlert = useSelector(getDialogError)
  const currentArchiveAlertIndex = useSelector(getCurrentIndex)
  const isSuccess = useSelector(getIsSuccess)

  return {
    closeArchiveAlert,
    openArchiveAlertDialog,
    setErrorArchiveAlert,
    setSuccess,
    isArchiveAlertOpen,
    errorArchiveAlert,
    selectedAlertData,
    currentArchiveAlertIndex,
    isSuccess
  }
}
