import { MessageBarType } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { IAnnuityPPLIContract } from 'api/datahub'
import { usePushNotification } from 'features/Notifications'
import { useEffect, useState } from 'react'
import { AccountLinkContent } from './AccountLinkContent'
import { AccountStatus } from './AccountLinkStatus'

export const AccountLink: React.FC<{
  props: Partial<IAnnuityPPLIContract>
}> = ({ props }) => {
  const {
    contractNumber,
    contractType,
    manuallyLinkedPartyId,
    clientName,
    insuredAnnuitants
  } = props

  const [isPanelOpen, { setTrue: openPanel, setFalse: closePanel }] =
    useBoolean(false)

  const [isAccountLinkSuccess, setIsAccountLinkSuccess] = useState(false)

  const { pushNotification } = usePushNotification()

  useEffect(() => {
    if (isAccountLinkSuccess) {
      closePanel()
      pushNotification({
        message: 'Account linking request has been submitted.',
        type: MessageBarType.success
      })
    }
  }, [closePanel, isAccountLinkSuccess, pushNotification])

  return (
    <>
      <AccountStatus
        contractType={contractType}
        manuallyLinkedPartyId={manuallyLinkedPartyId}
        openPanel={openPanel}
        isInProgress={isAccountLinkSuccess}
      />
      {isPanelOpen && (
        <AccountLinkContent
          contractProps={{
            contractType,
            contractNumber,
            clientName,
            insuredAnnuitants
          }}
          closePanel={closePanel}
          setIsSuccess={setIsAccountLinkSuccess}
        />
      )}
    </>
  )
}
