import { useCallback, useMemo } from 'react'
import { useSessionStateContainer } from 'store/session'
import { IListPreferences } from '../useListPreferences/IListPreferences'

export interface IListSessionState {
  listPreferences?: IListPreferences
}

export const useListSessionState = (sessionKey: string) => {
  const { updateContainer, value = {} } =
    useSessionStateContainer<IListSessionState>(
      `${sessionKey}/useListSessionState`
    )

  const { listPreferences } = value
  const setListPreferences = useCallback(
    (newPreferences: IListPreferences) =>
      updateContainer({
        listPreferences: newPreferences
      }),
    [updateContainer]
  )

  const clearListPreferences = useCallback(
    () =>
      updateContainer({
        listPreferences: undefined
      }),
    [updateContainer]
  )

  const api = useMemo(
    () => ({
      hasListPreferences: !!listPreferences,
      listPreferences,
      setListPreferences,
      clearListPreferences
    }),
    [listPreferences, setListPreferences, clearListPreferences]
  )

  return api
}
