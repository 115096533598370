import { FormattedNumber } from 'react-intl'
import { useRdot360AccountContext } from '../../../../store/rdot360Context'

interface IPrintHeaderAccountProps {
  account: string
  maskedAccount: string
  netAmount: number
}

export const PrintHeaderAccount: React.FC<IPrintHeaderAccountProps> = ({
  account,
  maskedAccount,
  netAmount
}) => {
  const { accountLookupByAccountIdOrKey } = useRdot360AccountContext()
  const accountInfo = accountLookupByAccountIdOrKey[account] ?? {}
  const accountName = accountInfo.preferredNickname || ''
  return (
    <header className="header-account header-columns top-margin">
      <div className="header-column-half">
        {accountName !== '' && (
          <>
            <strong>{accountName}</strong> |{' '}
          </>
        )}
        Account: {maskedAccount ? maskedAccount : account}
      </div>
      <div className="header-column-half header-right">
        Net Amount:{' '}
        <strong>
          {' '}
          <FormattedNumber
            value={netAmount || 0}
            style="currency"
            currency="USD"
            maximumFractionDigits={2}
            minimumFractionDigits={2}
          />
        </strong>
      </div>
    </header>
  )
}
