import {
  IDataTableColumnDefinition,
  IDataTableSortBy
} from 'features/DataList/common/types'
import { keyBy, orderBy } from 'lodash'
import { flow } from 'lodash/fp'
import { createSelector } from 'reselect'
import { put, select, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { IFacetResult } from '../../../../../api/common.types'
import {
  IListsDateRangeFilter,
  IListsFacetFilter,
  IListsFilter
} from '../../../../../features/Lists/core/contracts/IListsFilter'
import { convertColumnTypeToFilterType } from '../../../../../features/OdataList/common/service'
import { DateRanges } from '../../../../../shared'
import { AppState } from '../../../../../store'
import { evaluateFacets, evaluateFilter } from '../common/service'
import { BdaSummaryPayrollExpense } from '../common/types'
import {
  bdaContainerActions,
  getBdaPayrollExpensesWithPremium
} from './bdaContainer'
import { getBdaDepartmentAllowancesItems } from './bdaDepartmentAllowances'

export type BDAPayrollListColumnName =
  | 'Date'
  | 'Department'
  | 'Record Code'
  | 'Category'
  | 'Employee'
  | 'Earnings'
  | 'Published'
  | 'Owner'

export interface IBDAPayrollColumnDefinition
  extends IDataTableColumnDefinition {
  getValue: (
    item: BdaSummaryPayrollExpenseListItem
  ) => number | Date | string | undefined
  name: BDAPayrollListColumnName
}

const defaultColumn: Partial<IBDAPayrollColumnDefinition> = {
  filterable: true,
  sortable: true
}

export const bdaPayrollListColumns: IBDAPayrollColumnDefinition[] = [
  {
    ...defaultColumn,
    name: 'Date',
    type: 'date',
    width: 100,
    getValue: ({ date }) => date
  },
  {
    ...defaultColumn,
    name: 'Department',
    type: 'string',
    facetable: true,
    getValue: ({ rcm_Department }) => rcm_Department?.cdm_name,
    width: 200
  },
  {
    ...defaultColumn,
    name: 'Record Code',
    type: 'string',
    facetable: true,
    getValue: ({ rcm_recordcode }) => rcm_recordcode,
    width: 130
  },
  {
    ...defaultColumn,
    name: 'Category',
    type: 'string',
    facetable: true,
    getValue: ({ category }) => category,
    width: 130
  },
  {
    ...defaultColumn,
    name: 'Employee',
    type: 'string',
    width: 300,
    getValue: ({ rcm_employeename }) => rcm_employeename
  },
  {
    ...defaultColumn,
    name: 'Earnings',
    type: 'number',
    width: 200,
    getValue: ({ rcm_currentearnings }) => rcm_currentearnings
  },
  {
    ...defaultColumn,
    name: 'Published',
    type: 'string',
    width: 100,
    facetable: true,
    getValue: ({ isPublished }) => (isPublished ? 'Yes' : 'No')
  },
  {
    ...defaultColumn,
    name: 'Owner',
    type: 'string',
    width: 100,
    getValue: ({
      '_ownerid_value@OData.Community.Display.V1.FormattedValue': ownername
    }) => ownername
  }
]

const UPDATE_FILTER =
  '@modules/@advisory/@modules/@bda/@bdaPayrollList/UPDATE_FILTER'
const RESET_FILTER =
  '@modules/@advisory/@modules/@bda/@bdaPayrollList/RESET_FILTER'
const UPDATE_SORT =
  '@modules/@advisory/@modules/@bda/@bdaPayrollList/UPDATE_SORT'
const SET_DATE_RANGE =
  '@modules/@advisory/@modules/@bda/@bdaPayrollList/SET_DATE_RANGE'
const SET_CATEGORIES =
  '@modules/@advisory/@modules/@bda/@bdaPayrollList/SET_CATEGORIES'
const SET_DEPARTMENT =
  '@modules/@advisory/@modules/@bda/@bdaPayrollList/SET_DEPARTMENT'
const UPDATE_FACET =
  '@modules/@advisory/@modules/@bda/@bdaPayrollList/UPDATE_FACET'

export const bdaPayrollListActions = {
  setDateRange: createAction(SET_DATE_RANGE)<
    { from: Date; to: Date } | undefined
  >(),
  setCategories: createAction(SET_CATEGORIES)<string[] | undefined>(),
  setDepartment: createAction(SET_DEPARTMENT)<string | undefined>(),
  updateFilters: createAction(UPDATE_FILTER)<Record<string, IListsFilter>>(),
  resetFilters: createAction(RESET_FILTER)(),
  updateSort: createAction(UPDATE_SORT)<IDataTableSortBy>(),
  updateFacet: createAction(UPDATE_FACET)<BDAPayrollListColumnName>()
}

export type bdaPayrollListActionTypes = ActionType<typeof bdaPayrollListActions>

export interface IBdaPayrollListState {
  filters: Record<BDAPayrollListColumnName, IListsFilter>
  sortBy: IDataTableSortBy
}

const defaultFilters = () =>
  keyBy(
    bdaPayrollListColumns
      .filter((x) => x.filterable)
      .map((column) => ({
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        hasValue: false
      })),
    ({ id }) => id
  ) as Record<BDAPayrollListColumnName, IListsFilter>

const initialState: IBdaPayrollListState = {
  filters: defaultFilters(),
  sortBy: {
    name: 'Date',
    direction: 'desc'
  }
}

export const bdaPayrollListReducer = createReducer<
  IBdaPayrollListState,
  bdaPayrollListActionTypes
>(initialState)
  .handleAction(bdaPayrollListActions.updateFilters, (state, action) => ({
    ...state,
    filters: { ...state.filters, ...action.payload }
  }))
  .handleAction(bdaPayrollListActions.updateSort, (state, action) => ({
    ...state,
    sortBy: action.payload
  }))
  .handleAction(bdaPayrollListActions.resetFilters, (state) => ({
    ...state,
    filters: defaultFilters()
  }))

export const getBdaPayrollListState = (state: AppState) =>
  state.modules.advisory.modules.bda.bdaPayrollList
export const getBdaPayrollListSortBy = flow(
  getBdaPayrollListState,
  ({ sortBy }) => sortBy
)
export const getBdaPayrollListFilters = flow(
  getBdaPayrollListState,
  ({ filters }) => filters
)

// TODO - this reevaluates when clicking on the column header
const valueFns = bdaPayrollListColumns.reduce(
  (a, x) => ({ ...a, [x.name]: x.getValue }),
  {} as Record<
    BDAPayrollListColumnName,
    IBDAPayrollColumnDefinition['getValue']
  >
)

export type BdaSummaryPayrollExpenseListItem = BdaSummaryPayrollExpense & {
  isPublished?: boolean
}
export const getBdaPayrollListItems = createSelector(
  [getBdaPayrollExpensesWithPremium, getBdaDepartmentAllowancesItems],
  (items, departments) => {
    if (!departments || !items) {
      return
    }
    const departmentLookup = keyBy(
      departments,
      ({ rcm_Department }) => rcm_Department?.cdm_departmentnumber || ''
    )

    return items.map((item): BdaSummaryPayrollExpenseListItem => {
      const departmentNumber = item?.rcm_Department?.cdm_departmentnumber
      const department = departmentNumber && departmentLookup[departmentNumber]
      return {
        ...item,
        isPublished:
          !!department &&
          !!item?._ownerid_value &&
          department?.ownerid?.ownerid === item?._ownerid_value
      }
    })
  }
)

export const getFilteredAndSortedPayrollItems = createSelector(
  [getBdaPayrollListItems, getBdaPayrollListSortBy, getBdaPayrollListFilters],
  (items, sortBy, filters) => {
    const columnLookup = keyBy(bdaPayrollListColumns, ({ name }) => name)
    const filteredItems = items?.filter((item) =>
      evaluateFilter(item, valueFns, filters)
    )

    return sortBy
      ? orderBy(
          filteredItems,
          columnLookup[sortBy.name].getValue,
          sortBy.direction
        )
      : filteredItems
  }
)

export const bdaPayrollListSagas = [
  () =>
    takeLatest(bdaContainerActions.setSelectedDepartments, function* () {
      yield put(bdaPayrollListActions.resetFilters())
    }),
  () =>
    takeLatest(
      bdaPayrollListActions.setDateRange,
      function* (
        action: ReturnType<typeof bdaPayrollListActions.setDateRange>
      ) {
        const filters: Record<BDAPayrollListColumnName, IListsFilter> =
          yield select(getBdaPayrollListFilters)
        const { from, to } = action.payload || {}
        yield put(
          bdaPayrollListActions.updateFilters({
            Date: {
              ...filters.Date,
              range: action.payload && DateRanges.Custom,
              from,
              to,
              hasValue: !!action.payload
            } as IListsDateRangeFilter
          })
        )
      }
    ),
  () =>
    takeLatest(
      bdaPayrollListActions.setCategories,
      function* (
        action: ReturnType<typeof bdaPayrollListActions.setCategories>
      ) {
        const filters: Record<BDAPayrollListColumnName, IListsFilter> =
          yield select(getBdaPayrollListFilters)

        yield put(
          bdaPayrollListActions.updateFilters({
            Category: {
              ...filters.Category,
              values: action.payload,
              hasValue: !!action.payload
            } as IListsFacetFilter
          })
        )
      }
    ),
  () =>
    takeLatest(
      bdaPayrollListActions.setDepartment,
      function* (
        action: ReturnType<typeof bdaPayrollListActions.setDepartment>
      ) {
        const filters: Record<BDAPayrollListColumnName, IListsFilter> =
          yield select(getBdaPayrollListFilters)

        yield put(
          bdaPayrollListActions.updateFilters({
            Department: {
              ...filters.Department,
              values: [action.payload],
              hasValue: !!action.payload
            } as IListsFacetFilter
          })
        )
      }
    ),
  () =>
    takeLatest(
      bdaPayrollListActions.updateFacet,
      function* (action: ReturnType<typeof bdaPayrollListActions.updateFacet>) {
        const columnLookup = keyBy(bdaPayrollListColumns, (x) => x.name)
        const filters = yield* select(getBdaPayrollListFilters)
        const filterLookup = keyBy(filters, (x) => x.name)
        const items = yield* select(getBdaPayrollListItems)
        const filteredItems = items?.filter((x) =>
          evaluateFilter(x, valueFns, {
            ...filters,
            [action.payload]: { hasValue: false }
          })
        )

        const facets = evaluateFacets(filteredItems || [], [
          columnLookup[action.payload]
        ])
        const filter = filterLookup[action.payload]
        const columnFacet = Object.entries(facets[action.payload] || {})
          .map(([value, count]): IFacetResult => ({ value, count }))
          .sort((a, b) => b.count - a.count)

        yield put(
          bdaPayrollListActions.updateFilters({
            [action.payload]: {
              ...filter,
              facets: columnFacet
            } as IListsFacetFilter
          })
        )
      }
    )
]
