import { ActionType, createAction } from 'typesafe-actions'
import {
  IClientSettings,
  IClientSettingsDocReq,
  IPaymentClient,
  IUpdateAccount,
  IUpdateAccounts,
  IUpdateClientSettingsReq,
  IUpdatePaymentAccountReq,
  IUploadLoaDocRequest
} from './types'

export const FETCH_CLIENT_SETTINGS_LIST =
  '@modules/@payments/@admin/@clientsettings/FETCH_CLIENT_SETTINGS_LIST'
export const FETCH_CLIENT_SETTINGS_LIST_SUCCESS =
  '@modules/@payments/@admin/@clientsettings/FETCH_CLIENT_SETTINGS_LIST_SUCCESS'
export const FETCH_CLIENT_SETTINGS_LIST_FAILURE =
  '@modules/@payments/@admin/@clientsettings/FETCH_CLIENT_SETTINGS_LIST_FAILURE'

export const SET_SELECTED_CLIENT_SETTING =
  '@modules/@payments/@admin/@clientsettings/SET_SELECTED_CLIENT_SETTING'

export const FETCH_CLIENT_SETTINGS_DOC =
  '@modules/@payments/@admin/@clientsettings/FETCH_CLIENT_SETTINGS_DOC'
export const FETCH_CLIENT_SETTINGS_DOC_SUCCESS =
  '@modules/@payments/@admin/@clientsettings/FETCH_CLIENT_SETTINGS_DOC_SUCCESS'
export const FETCH_CLIENT_SETTINGS_DOC_FAILURE =
  '@modules/@payments/@admin/@clientsettings/FETCH_CLIENT_SETTINGS_DOC_FAILURE'
export const DOWNLOAD_CLIENT_SETTINGS_DOC =
  '@modules/@payments/@admin/@clientsettings/DOWNLOAD_CLIENT_SETTINGS_DOC'

export const FETCH_CLIENT_LIST =
  '@modules/@payments/@admin/@clientsettings/FETCH_CLIENT_LIST'
export const FETCH_CLIENT_LIST_SUCCESS =
  '@modules/@payments/@admin/@clientsettings/FETCH_CLIENT_LIST_SUCCESS'
export const FETCH_CLIENT_LIST_FAILURE =
  '@modules/@payments/@admin/@clientsettings/FETCH_CLIENT_LIST_FAILURE'

export const UPDATE_PAYMENT_ACCOUNT =
  '@modules/@payments/@admin/@clientsettings/UPDATE_PAYMENT_ACCOUNT'
export const UPDATE_PAYMENT_ACCOUNT_SUCCESS =
  '@modules/@payments/@admin/@clientsettings/UPDATE_PAYMENT_ACCOUNT_SUCCESS'
export const UPDATE_PAYMENT_ACCOUNT_FAILURE =
  '@modules/@payments/@admin/@clientsettings/UPDATE_PAYMENT_ACCOUNT_FAILURE'

export const UPDATE_CLIENT_SETTINGS =
  '@modules/@payments/@admin/@clientsettings/UPDATE_CLIENT_SETTINGS'
export const UPDATE_CLIENT_SETTINGS_SUCCESS =
  '@modules/@payments/@admin/@clientsettings/UPDATE_CLIENT_SETTINGS_SUCCESS'
export const UPDATE_CLIENT_SETTINGS_FAILURE =
  '@modules/@payments/@admin/@clientsettings/UPDATE_CLIENT_SETTINGS_FAILURE'
export const UPDATE_CLIENT_SETTINGS_CLEAR =
  '@modules/@payments/@admin/@clientsettings/UPDATE_CLIENT_SETTINGS_CLEAR'

export const UPDATE_ACCOUNTS =
  '@modules/@payments/@admin/@clientsettings/UPDATE_ACCOUNTS'
export const UPDATE_ACCOUNTS_SUCCESS =
  '@modules/@payments/@admin/@clientsettings/UPDATE_ACCOUNTS_SUCCESS'
export const UPDATE_ACCOUNTS_INFO =
  '@modules/@payments/@admin/@clientsettings/UPDATE_ACCOUNTS_INFO'
export const UPDATE_ACCOUNTS_FAILURE =
  '@modules/@payments/@admin/@clientsettings/UPDATE_ACCOUNTS_FAILURE'
export const UPDATE_ACCOUNTS_CLEAR =
  '@modules/@payments/@admin/@clientsettings/UPDATE_ACCOUNTS_CLEAR'

export const UPLOAD_LOA_DOC =
  '@modules/@payments/@admin/@clientsettings/UPLOAD_LOA_DOC'
export const UPLOAD_LOA_DOC_SUCCESS =
  '@modules/@payments/@admin/@clientsettings/UPLOAD_LOA_DOC_SUCCESS'
export const UPLOAD_LOA_DOC_FAILURE =
  '@modules/@payments/@admin/@clientsettings/UPLOAD_LOA_DOC_FAILURE'

export const fetchClientSettingsListActions = {
  request: createAction(FETCH_CLIENT_SETTINGS_LIST)(),
  success: createAction(FETCH_CLIENT_SETTINGS_LIST_SUCCESS)<
    IClientSettings[]
  >(),
  failure: createAction(FETCH_CLIENT_SETTINGS_LIST_FAILURE)<Error>()
}

export const setSelectedClientSettingActions = {
  set: createAction(SET_SELECTED_CLIENT_SETTING)<IClientSettings>()
}

export const fetchClientSettingsDocActions = {
  request: createAction(FETCH_CLIENT_SETTINGS_DOC)<IClientSettingsDocReq>(),
  success: createAction(FETCH_CLIENT_SETTINGS_DOC_SUCCESS)<string>(),
  failure: createAction(FETCH_CLIENT_SETTINGS_DOC_FAILURE)<Error>()
}

export const downloadClientSettingsDocActions = {
  download: createAction(DOWNLOAD_CLIENT_SETTINGS_DOC)<IClientSettingsDocReq>()
}

export const fetchClientListActions = {
  request: createAction(FETCH_CLIENT_LIST)<IClientSettings>(),
  success: createAction(FETCH_CLIENT_LIST_SUCCESS)<IPaymentClient[]>(),
  failure: createAction(FETCH_CLIENT_LIST_FAILURE)<Error>()
}

export const updatePaymentAccountActions = {
  request: createAction(UPDATE_PAYMENT_ACCOUNT)<IUpdatePaymentAccountReq>(),
  success: createAction(UPDATE_PAYMENT_ACCOUNT_SUCCESS)(),
  failure: createAction(UPDATE_PAYMENT_ACCOUNT_FAILURE)<Error>()
}

export const updateClientSettingsActions = {
  request: createAction(UPDATE_CLIENT_SETTINGS)<IUpdateClientSettingsReq>(),
  success: createAction(UPDATE_CLIENT_SETTINGS_SUCCESS)(),
  failure: createAction(UPDATE_CLIENT_SETTINGS_FAILURE)<Error>(),
  clear: createAction(UPDATE_CLIENT_SETTINGS_CLEAR)()
}
export const updateAccountsActions = {
  request: createAction(UPDATE_ACCOUNTS)<IUpdateAccounts>(),
  success: createAction(UPDATE_ACCOUNTS_SUCCESS)<IUpdateAccount[]>(),
  setInfo: createAction(UPDATE_ACCOUNTS_INFO)<string>(),
  failure: createAction(UPDATE_ACCOUNTS_FAILURE)<string>(),
  clear: createAction(UPDATE_ACCOUNTS_CLEAR)()
}

export const uploadLoaDocActions = {
  request: createAction(UPLOAD_LOA_DOC)<IUploadLoaDocRequest>(),
  success: createAction(UPLOAD_LOA_DOC_SUCCESS)(),
  failure: createAction(UPLOAD_LOA_DOC_FAILURE)<Error>()
}

export type FetchClientSettingsListActionType = ActionType<
  typeof fetchClientSettingsListActions
>

export type SetSelectedClientSettingsActionType = ActionType<
  typeof setSelectedClientSettingActions
>

export type FetchClientSettingsDocActionType = ActionType<
  typeof fetchClientSettingsDocActions
>

export type DownloadClientSettingsDocActionType = ActionType<
  typeof downloadClientSettingsDocActions
>

export type FetchClientListActionType = ActionType<
  typeof fetchClientListActions
>

export type UpdatePaymentAccountActionType = ActionType<
  typeof updatePaymentAccountActions
>

export type UpdateClientSettingsActionType = ActionType<
  typeof updateClientSettingsActions
>

export type UpdateAccountsActionType = ActionType<typeof updateAccountsActions>

export type UploadLoaDocActionType = ActionType<typeof uploadLoaDocActions>

export type ClientSettingsActions =
  | FetchClientSettingsListActionType
  | SetSelectedClientSettingsActionType
  | FetchClientSettingsDocActionType
  | DownloadClientSettingsDocActionType
  | FetchClientListActionType
  | UpdatePaymentAccountActionType
  | UploadLoaDocActionType
  | UpdateClientSettingsActionType
  | UpdateAccountsActionType
