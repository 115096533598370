import { combineReducers, Reducer } from 'redux'
import { StateType } from 'typesafe-actions'
import { clientSettingsReducer } from '../modules/ClientSettings/store/reducer'
import { clientVisibilityReducer } from '../modules/ClientVisibility/store/reducer'
import { paymentApproversReducer } from '../modules/PaymentApprovers/store/reducer'

export interface IAdminModuleState {
  clientVisibility: StateType<typeof clientVisibilityReducer>
  clientSettings: StateType<typeof clientSettingsReducer>
  paymentApprovers: StateType<typeof paymentApproversReducer>
}

export const adminReducer: Reducer<IAdminModuleState> = combineReducers({
  clientVisibility: clientVisibilityReducer,
  clientSettings: clientSettingsReducer,
  paymentApprovers: paymentApproversReducer
})
