// eslint-disable-next-line import/no-named-as-default
import HighchartsReact from 'highcharts-react-official'
import { forwardRef, memo } from 'react'
import {
  useHighcharts,
  useHighchartsWithOrgAndSankeyModule
} from 'shared/highcharts/useHighcharts'

export type HighchartProps = Omit<HighchartsReact.Props, 'highcharts'>
export type HighchartRef = HighchartsReact.RefObject

const HighchartsComponentInternal = forwardRef<
  HighchartsReact.RefObject,
  HighchartProps
>((props, ref) => {
  const highcharts = useHighcharts()
  return highcharts ? (
    <HighchartsReact ref={ref} {...props} highcharts={highcharts} />
  ) : (
    <div />
  )
})

export const HighchartsComponent = memo(HighchartsComponentInternal)

export const HighchartsWithOrgAndSankeyComponent: React.FC<HighchartProps> = (
  props
) => {
  const highcharts = useHighchartsWithOrgAndSankeyModule()
  return highcharts ? (
    <HighchartsReact {...props} highcharts={highcharts} />
  ) : (
    <div />
  )
}
