import { IHurdle } from 'api/datahub'
import { datahubApi } from 'store/api/datahub'

export const hurdlesDatahubApi = datahubApi.injectEndpoints({
  endpoints: (builder) => ({
    checkForHurdle: builder.query<boolean, number>({
      query: (hurdleId) => ({
        url: `/hurdles/${hurdleId}`
      }),
      transformResponse: (x: IHurdle) => !!x
    })
  })
})

export const { useLazyCheckForHurdleQuery } = hurdlesDatahubApi
