export interface IWireInstruction {
  BankAcctFromAcctId?: string
  BankAcctFromAcctType?: string
  BankAcctFromBankId?: string
  BankAcctFromBankIdType?: string
  BankAcctFromName?: string
  BeneficiaryAcctId?: string
  BeneficiaryAcctType?: string
  BeneficiaryBankName?: string
  BeneficiaryBankRoutingId?: string
  BeneficiaryBankRoutingType?: string
  BeneficiaryName?: string
  BeneficiaryNameAddrLine1?: string
  BeneficiaryNameAddrLine2?: string
  BeneficiaryNameAddrLine3?: string
  BeneficiaryNameAddrLine4?: string
  DestinationCountry?: string
  DisplayName?: string
  FurtherCredit1_AccountNumber?: string
  FurtherCredit1_NameLine1?: string
  FurtherCredit1_NameLine2?: string
  FurtherCredit1_NameLine3?: string
  FurtherCredit1_NameLine4?: string
  FurtherCredit2_AccountNumber?: string
  FurtherCredit2_NameLine1?: string
  FurtherCredit2_NameLine2?: string
  FurtherCredit2_NameLine3?: string
  FurtherCredit2_NameLine4?: string
  IsThirdParty?: boolean
  WireDisbursementDetail1?: string
  WireDisbursementDetail2?: string
  WireDisbursementDetail3?: string
  WireDisbursementDetail4?: string
}

export interface IInstruction {
  AccNumber?: string
  AccountType?: string
  Id?: string
  Mode?: InstructionModeType
  Name?: string
  OrgId?: string
  PaymentTermId?: string
  wireInstruction?: IWireInstruction
}

export enum InstructionModeType {
  'ADD' = 'ADD',
  'UPDATE' = 'UPDATE'
}

export interface IWireInstructionForSecurity {
  seqid?: number
  WireRecepientKey?: string
  iswire: boolean
  isrecepientonly: boolean
  isthirdparty?: boolean
  RoutingNo?: string
  RoutingNoTypeCode?: string
  bankname?: string
  bankaccountnumber?: string
  bankaccountname?: string
  bankaccounttype?: string
  beneficiarynameline1?: string
  beneficiarynameline2?: string
  beneficiarynameline3?: string
  beneficiarynameline4?: string
  beneficiaryaccountnumber?: string
  destinationcountry?: string
  FurtherCredit1_AccountNumber?: string
  FurtherCredit1_NameLine1?: string
  FurtherCredit1_NameLine2?: string
  FurtherCredit1_NameLine3?: string
  FurtherCredit1_NameLine4?: string
  SourceSystemRefNum: string
  SourceSystemCd: string
}
