import {
  Icon,
  ITooltipProps,
  Link,
  MessageBar,
  MessageBarType,
  Text,
  TooltipHost
} from '@fluentui/react'
import React from 'react'

export const PreviewDisclaimer: React.FC = () => (
  <MessageBar
    messageBarType={MessageBarType.warning}
    messageBarIconProps={{ iconName: 'Warning' }}
    isMultiline={true}
  >
    This is a{' '}
    <Link
      title="The information displayed here may not be
    completely accurate."
    >
      preview
    </Link>{' '}
    feature.
  </MessageBar>
)

const tooltipProps: ITooltipProps = {
  onRenderContent: () => (
    <Text>
      This is a <b>preview</b> feature. The information displayed here may not
      be completely accurate.
    </Text>
  )
}

export const IconPreviewDisclaimer: React.FC = () => (
  <TooltipHost tooltipProps={tooltipProps}>
    <Icon iconName="Info" />
  </TooltipHost>
)

export const PreviewWarning: React.FC = () => (
  <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
    This is a preview feature. You must be enrolled in the preview in order to
    use it.
  </MessageBar>
)
