import { createReducer } from 'typesafe-actions'
import { clientSummaryDataActions, ClientSummaryActionTypes } from './actions'
import { IClientSummary } from './types'

export interface IClientSummaryState {
  ClientsData?: IClientSummary[]
  isLoading: boolean
  error?: Error
}

const initialState: IClientSummaryState = {
  isLoading: true,
  error: undefined
}

export const clientsummaryReducer = createReducer<
  IClientSummaryState,
  ClientSummaryActionTypes
>(initialState)
  .handleAction(clientSummaryDataActions.request, (state) => ({
    ...state,
    isLoading: true,
    error: undefined
  }))
  .handleAction(clientSummaryDataActions.success, (state, action) => ({
    ...state,
    ClientsData: action.payload,
    isLoading: false,
    error: undefined
  }))
  .handleAction(clientSummaryDataActions.failure, (state, action) => ({
    ...state,
    error: action.payload,
    isLoading: false,
    ClientsData: undefined
  }))
