import { IDropdownOption } from '@fluentui/react'
import { range } from 'lodash'

export const FundingOptions = {
  Core: 'Core',
  // CashPosition: 'Cash Position',
  DividendsInterest: 'Dividends/Interest',
  CoreDividendsInterest: 'Core/Dividends/Interest'
  //Investment: 'Investment'   //PHASE 2
}

export const FrequencyOptions = {
  W: 'Weekly',
  M: 'Monthly',
  Q: 'Quarterly',
  Y: 'Yearly'
}

export const QuarterlyOptions = {
  '1/4/7/10': 'Jan/Apr/Jul/Oct',
  '2/5/8/11': 'Feb/May/Aug/Nov',
  '3/6/9/12': 'Mar/Jun/Sep/Dec'
}

export const DayOptions = {
  2: 'M',
  3: 'T',
  4: 'W',
  5: 'Th',
  6: 'F'
}

export const MonthOptions = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec'
}

export const WeekendHolidayProcessOptions = {
  P: 'Prior Business Day',
  N: 'Next Business Day',
  S: 'Skip'
}

export const EligibleSecuritiesConstants = {
  FixedAmount: 'Fixed Amount',
  Proportional: 'Proportional',
  FixedPercentage: 'Fixed Percentage'
}
export const InvestToOptions: IDropdownOption[] = [
  {
    key: 'Cash',
    text: 'Cash'
  }
]

export const DefaultSelectedDays = range(2, 7)
export const DefaultSelectedMonths = range(1, 13)
// export const WeekendHolidayProcessOptions: IDropdownOption[] = [
//   { key: 'P', text: 'Prior Business Day' },
//   { key: 'N', text: 'Next Business Day' },
//   { key: 'S', text: 'Skip' }
// ]
