import {
  Icon,
  ISearchBox,
  makeStyles,
  Stack,
  Text,
  TextField,
  useTheme
} from '@fluentui/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { AutoComplete } from '../../../../../../../../shared/components/AutoComplete'
import { AsyncHook } from '../../../../../../../../store/shared/asyncStore'

export interface IEntitySelectorProps<T> {
  selectedText?: string
  onSelectedEntityChanged: (entity?: T) => void
  errorMessage?: string
  disabled?: boolean
}

const useClasses = makeStyles((theme) => ({
  selectedItem: {
    border: `solid 1px ${theme.palette.neutralLight}`,
    backgroundColor: theme.palette.neutralLighterAlt,
    padding: '6px 10px'
  },
  errorBorder: {
    borderColor: theme.semanticColors.errorIcon
  }
}))

export const createEntitySelector =
  <T,>(
    useAsyncEntity: AsyncHook<T[], string | undefined>,
    AutoCompleteComponent: React.FC<{ item?: T }>
  ) =>
  ({
    selectedText,
    onSelectedEntityChanged,
    errorMessage,
    disabled
  }: IEntitySelectorProps<T>): React.ReactElement<IEntitySelectorProps<T>> => {
    const { sendRequest, loading, result } = useAsyncEntity()
    const [searchText, setSearchText] = useState<string>()
    const autoCompleteRef = useRef<ISearchBox>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const classes = useClasses()
    const theme = useTheme()

    const focusInput = useCallback(() => {
      autoCompleteRef?.current?.focus()
    }, [])

    const focusContainer = useCallback(() => {
      containerRef?.current?.focus()
    }, [])

    useEffect(() => {
      !selectedText && sendRequest(searchText)
    }, [searchText, selectedText, sendRequest])

    const onEntitySelected = useCallback(
      (entity?: T) => {
        if (!entity) {
          return
        }

        onSelectedEntityChanged(entity)
        setSearchText('')
        focusContainer()
      },
      [focusContainer, onSelectedEntityChanged]
    )

    const onEditClick = useCallback(() => {
      onSelectedEntityChanged()
      setTimeout(focusInput)
    }, [focusInput, onSelectedEntityChanged])

    const onBlur = useCallback(
      (item?: T) => {
        onEntitySelected(item)
      },
      [onEntitySelected]
    )

    const onClick = useCallback(() => {
      if (selectedText) {
        onEditClick()
        return
      }
    }, [onEditClick, selectedText])

    const onKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Tab') {
          return
        }
        selectedText && onEditClick()
      },
      [onEditClick, selectedText]
    )

    return disabled ? (
      <TextField disabled={true} value={selectedText} />
    ) : (
      <div
        tabIndex={0}
        ref={containerRef}
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        {selectedText ? (
          <Stack
            horizontal={true}
            verticalAlign="center"
            horizontalAlign="space-between"
            className={classes.selectedItem}
            styles={{ root: { minWidth: 0 } }}
          >
            <Text nowrap>{selectedText}</Text>
            <Icon
              iconName="Edit"
              onClick={onEditClick}
              styles={{ root: { cursor: 'pointer' } }}
            />
          </Stack>
        ) : (
          <Stack tokens={{ childrenGap: 5 }}>
            <AutoComplete<T>
              value={searchText}
              onSearchTextChanged={setSearchText}
              items={result || []}
              onItemSelected={onEntitySelected}
              itemComponent={AutoCompleteComponent}
              showLoadingIndicator={loading}
              onBlur={onBlur}
              searchBoxRef={autoCompleteRef}
              className={errorMessage && classes.errorBorder}
            />
            {errorMessage && (
              <Text
                variant="smallPlus"
                styles={{ root: { color: theme.semanticColors.errorText } }}
              >
                {errorMessage}
              </Text>
            )}
          </Stack>
        )}
      </div>
    )
  }
