import { sum, keyBy } from 'lodash'
import { partial } from 'lodash/fp'
import { select } from 'typed-redux-saga'
import {
  selectSelectedAdvisorRepIds,
  selectUserHasAccessToPayout
} from '../../../../../../../../features/Domain/store/domain'
import { createListsComponents } from '../../../../../../../../features/Lists/core/containers/service'
import { IColumnDefinition } from '../../../../../../../../features/Lists/core/contracts/IColumnDefinition'
import { createListsStore } from '../../../../../../../../features/Lists/core/store'
import {
  createDefaultColumnState,
  createFilter,
  mapColumnsToFilterDefinitions
} from '../../../../../../../../features/Lists/shared/lists'
import { AppState } from '../../../../../../../../store'
import {
  CreditEventColumnDefinitions,
  CreditEventColumnIds
} from './ColumnDefinitions'
import { IEnrichedCreditEvent } from './CreditEvent'
import { CreditEventCell } from './CreditEventCell'

export const constructInitialCreditEventListColumnState = partial(
  createDefaultColumnState,
  [CreditEventColumnDefinitions]
)

export const creditEventListFilterDefinitions = mapColumnsToFilterDefinitions(
  CreditEventColumnDefinitions
)

const getColumnValue = function* (
  columnDefinition: IColumnDefinition,
  item: IEnrichedCreditEvent
) {
  if (
    columnDefinition.id === CreditEventColumnIds.grossPayout ||
    columnDefinition.id === CreditEventColumnIds.allocatedAmount
  ) {
    const userHasAccessToPayout = yield* select(selectUserHasAccessToPayout)
    if (
      columnDefinition.id === CreditEventColumnIds.grossPayout &&
      !userHasAccessToPayout
    ) {
      return ''
    }

    const selectedAdvisors = yield* select(selectSelectedAdvisorRepIds)
    const recipients = selectedAdvisors?.length
      ? item.recipients?.filter((recipient) =>
          selectedAdvisors?.includes(recipient.recipientId || '')
        )
      : item.recipients
    const total = sum(
      recipients?.map((recipient) =>
        columnDefinition.id === CreditEventColumnIds.allocatedAmount
          ? recipient.allocatedAmount
          : recipient.grossPayout
      )
    )

    return +(total?.toFixed(2) || 0)
  }
}

export const creditEventListStore = createListsStore<IEnrichedCreditEvent>(
  'creditevent',
  '@features/@lists/@creditevents',
  {
    columnDefinitions: CreditEventColumnDefinitions,
    filterDefinitions: creditEventListFilterDefinitions,
    isReady: false
  },
  (state: AppState) =>
    state.modules.advisory.modules.revenue.modules.dashboard.features
      .creditEventsList,
  keyBy(
    [CreditEventColumnIds.grossPayout, CreditEventColumnIds.allocatedAmount]
      .map((id) => CreditEventColumnDefinitions[id])
      .map((column) => ({
        columnId: column.id,
        getExportColumns: (_, columnDefinition) => [
          {
            columnId: columnDefinition.id,
            columnName: columnDefinition.name,
            getValueGenerator: (item) => getColumnValue(columnDefinition, item)
          }
        ]
      })),
    (x) => x.columnId
  )
)

export const { reducer, sagas, actions, selectors } = creditEventListStore

export const createCreditEventListFilter = partial(createFilter, [
  CreditEventColumnDefinitions
])

export const CreditEventListComponents = createListsComponents(
  actions,
  selectors,
  CreditEventCell
)
