import { IListsFacetFilter } from 'features/Lists/core/contracts/IListsFilter'
import { IOdataListUiActions } from 'features/OdataList/common/IOdataListUiActions'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

export const useContractTypeFilter = (props: {
  uiActions: IOdataListUiActions
  contractType: 'Annuity' | 'Private Placement' | 'Insurance'
  hidden?: boolean
}) => {
  const { uiActions, contractType, hidden = false } = props
  const dispatch = useDispatch()

  useEffect(() => {
    const contractTypeFilterId = 'Contract Type'

    dispatch(
      uiActions.updateFilters({
        [contractTypeFilterId]: {
          id: contractTypeFilterId,
          name: contractTypeFilterId,
          type: 'facet',
          dataPath: 'contractType',
          hasValue: true,
          values: [contractType],
          hidden
        } as IListsFacetFilter
      })
    )
  }, [contractType, dispatch, hidden, uiActions])
}
