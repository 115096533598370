import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { useGetHouseholdFromIdQuery } from '../datahub'
import { useGetAdvisorTeamQuery } from '../dynamics'
import { useRdot360Context } from './rdot360Context'

export const useRdot360HouseholdContext = () => {
  const { selectedHouseholdId } = useRdot360Context()
  const skip = !selectedHouseholdId

  const {
    data: householdData,
    isFetching: isHouseholdFetching,
    isError: isHouseholdError,
    refetch: refetchHousehold
  } = useGetHouseholdFromIdQuery(skip ? skipToken : selectedHouseholdId)

  const {
    data: dynamicsHouseholdData,
    isFetching: isDynamicsHouseholdFetching,
    isError: isDynamicsHouseholdError
  } = useGetAdvisorTeamQuery(skip ? skipToken : selectedHouseholdId)

  return {
    household: skip || isHouseholdError ? undefined : householdData,
    isHouseholdFetching,
    dynamicsHousehold:
      skip || isDynamicsHouseholdError ? undefined : dynamicsHouseholdData,
    isDynamicsHouseholdFetching,
    refetchHousehold
  }
}
