import { DetailsListLayoutMode, Stack, Text } from '@fluentui/react'
import { constants } from 'modules/Advisory/shared/theme'
import React from 'react'
import { ListsComponents } from '../../../../../features/Lists/core/containers/service'

export const createDashboardList = (
  listComponents: ListsComponents,
  layoutMode: DetailsListLayoutMode = DetailsListLayoutMode.fixedColumns
) => {
  const DashboardList: React.FC = () => {
    return (
      <Stack tokens={{ childrenGap: 5 }}>
        <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
          <listComponents.ListsSearch />
          <Stack.Item grow={1}>
            <listComponents.ListsFilterStatusList />
          </Stack.Item>

          <Text variant="medium" styles={{ root: { fontWeight: 'bold' } }}>
            <listComponents.ListsResultsCount /> Results
          </Text>
        </Stack>
        <Stack.Item>
          <Stack>
            <listComponents.ListsCommandBar />
            <listComponents.ListsProgress />
            <listComponents.ListsInfiniteScrollDataTable
              layoutMode={layoutMode}
              stickyHeaderOffset={constants.headerHeight}
            />
          </Stack>
        </Stack.Item>
      </Stack>
    )
  }
  return DashboardList
}
