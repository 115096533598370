import { Link, Stack, Text } from '@fluentui/react'
import { format } from 'date-fns'
import { FormattedDate } from 'react-intl'
import { IListsCellComponent } from '../../../../../../../../features/Lists/core/components/ListsDataTable'
import { NumberCell } from '../../../../../../../../features/Lists/core/components/NumberCell'
import { TextCell } from '../../../../../../../../features/Lists/core/components/TextCell'
import { USDCell } from '../../../../../../../../features/Lists/core/components/USDCell'
import { AccountNumberCell } from '../../../../../../../../features/Lists/shared/AccountNumberCell'
import { parseDateISOStringInLocalTimezone } from '../../../../../../../../shared'
import { ConnectedMaskedText } from '../../../../../../../../shared/components/MaskedText'
import { CreditEventColumnIds } from './ColumnDefinitions'
import { IEnrichedCreditEvent } from './CreditEvent'
import { RecipientHoverCard } from './RecipientHoverCard'

export const createCreditEventCell = (cellComponents: {
  [key: string]: React.FC<{ creditEvent: IEnrichedCreditEvent }>
}) => {
  const CreditEventCellComponent: IListsCellComponent<IEnrichedCreditEvent> = ({
    columnDefinition,
    item
  }) => {
    const Cell = cellComponents[columnDefinition.id]
    return Cell && <Cell creditEvent={item} />
  }

  return CreditEventCellComponent
}

export const CreditEventCellComponents: {
  [key: string]: React.FC<{ creditEvent: IEnrichedCreditEvent }>
} = {
  [CreditEventColumnIds.id]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.event?.id}</TextCell>
  ),
  [CreditEventColumnIds.eventType]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.event?.eventType}</TextCell>
  ),
  [CreditEventColumnIds.tradeOrRevenueDate]: ({ creditEvent }) => {
    return (
      <Text nowrap={true}>
        {creditEvent?.event?.tradeOrRevenueDate ? (
          <FormattedDate
            day="2-digit"
            month="2-digit"
            year="numeric"
            value={parseDateISOStringInLocalTimezone(
              creditEvent?.event?.tradeOrRevenueDate
            )}
          />
        ) : (
          <>--</>
        )}{' '}
      </Text>
    )
  },
  [CreditEventColumnIds.accountNumber]: ({ creditEvent }) => (
    <AccountNumberCell
      menu={null}
      account={{
        CustodyAccount: creditEvent?.event?.accountNumber,
        Shortname: creditEvent?.account?.shortName,
        registrationtype: creditEvent?.account?.registrationType
      }}
    />
  ),
  [CreditEventColumnIds.productType]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.event?.productType}</TextCell>
  ),
  [CreditEventColumnIds.productCategory]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.event?.productCategory}</TextCell>
  ),
  [CreditEventColumnIds.product]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.event?.product}</TextCell>
  ),
  [CreditEventColumnIds.repCompany]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.event?.repCompany}</TextCell>
  ),
  [CreditEventColumnIds.description]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.event?.description}</TextCell>
  ),
  [CreditEventColumnIds.cusip]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.event?.cusip}</TextCell>
  ),
  [CreditEventColumnIds.symbol]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.event?.symbol}</TextCell>
  ),
  [CreditEventColumnIds.tranQuantity]: ({ creditEvent }) => (
    <NumberCell value={creditEvent?.event?.tranQuantity} />
  ),
  [CreditEventColumnIds.price]: ({ creditEvent }) => (
    <USDCell value={creditEvent?.event?.price} />
  ),
  [CreditEventColumnIds.faceAmount]: ({ creditEvent }) => (
    <USDCell value={creditEvent?.event?.faceAmount} />
  ),
  [CreditEventColumnIds.grossCommission]: ({ creditEvent }) => (
    <USDCell value={creditEvent?.event?.grossCommission} />
  ),
  [CreditEventColumnIds.feeAmount]: ({ creditEvent }) => (
    <USDCell value={creditEvent?.event?.feeAmount} />
  ),
  [CreditEventColumnIds.feeAndCommission]: ({ creditEvent }) => (
    <USDCell value={creditEvent?.event?.feeAndCommission} />
  ),
  [CreditEventColumnIds.managedMoney]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.event?.managedMoney}</TextCell>
  ),
  [CreditEventColumnIds.tradeIsOnHold]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.event?.tradeIsOnHold}</TextCell>
  ),
  [CreditEventColumnIds.allocatedAmount]: ({ creditEvent }) => (
    <RecipientHoverCard
      recipients={creditEvent?.recipients}
      type={'allocatedAmount'}
    >
      <Link>
        <USDCell value={creditEvent?.event?.allocatedAmount} />
      </Link>
    </RecipientHoverCard>
  ),
  [CreditEventColumnIds.grossPayout]: ({ creditEvent }) => (
    <RecipientHoverCard
      recipients={creditEvent?.recipients}
      type={'grossPayout'}
    >
      <Link>
        <USDCell value={creditEvent?.event?.grossPayout} />
      </Link>
    </RecipientHoverCard>
  ),
  [CreditEventColumnIds.payrollPeriod]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.event?.payrollPeriod}</TextCell>
  ),
  [CreditEventColumnIds.periodYear]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.event?.periodYear}</TextCell>
  ),
  [CreditEventColumnIds.periodMonth]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.event?.periodMonth}</TextCell>
  ),
  [CreditEventColumnIds.periodTimestamp]: ({ creditEvent }) => (
    <Text nowrap={true}>
      {creditEvent?.event?.periodTimestamp
        ? format(
            parseDateISOStringInLocalTimezone(
              creditEvent?.event?.periodTimestamp
            ),
            `MMM ''yy`
          )
        : '--'}
    </Text>
  ),
  [CreditEventColumnIds.revenueCategoryLvl1]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.event?.revenueCategoryLvl1}</TextCell>
  ),
  [CreditEventColumnIds.revenueCategoryLvl2]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.event?.revenueCategoryLvl2}</TextCell>
  ),
  [CreditEventColumnIds.accountRegistrationType]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.account?.registrationType}</TextCell>
  ),
  [CreditEventColumnIds.accountShortName]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.account?.shortName}</TextCell>
  ),
  [CreditEventColumnIds.accountType]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.account?.accountType}</TextCell>
  ),
  [CreditEventColumnIds.householdId]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.household?.householdId}</TextCell>
  ),
  [CreditEventColumnIds.householdName]: ({ creditEvent }) => (
    <ConnectedMaskedText
      text={creditEvent?.household?.householdName}
      nowrap={true}
    />
  ),
  [CreditEventColumnIds.partyName]: ({ creditEvent }) => (
    <Stack styles={{ root: { minWidth: 0 } }}>
      <ConnectedMaskedText text={creditEvent?.event?.contact} nowrap={true} />
      <Text nowrap={true} variant="small">
        {creditEvent.party?.partyType}
      </Text>
    </Stack>
  ),
  [CreditEventColumnIds.partyType]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.party?.partyType}</TextCell>
  ),
  [CreditEventColumnIds.repId]: ({ creditEvent }) => (
    <Stack styles={{ root: { minWidth: 0 } }}>
      <ConnectedMaskedText text={creditEvent?.rep?.repId} nowrap={true} />
      <ConnectedMaskedText
        text={creditEvent?.rep?.name}
        variant="small"
        nowrap={true}
      />
    </Stack>
  ),
  [CreditEventColumnIds.repName]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.rep?.name}</TextCell>
  ),
  [CreditEventColumnIds.team]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.rep?.team}</TextCell>
  ),
  [CreditEventColumnIds.office]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.rep?.office}</TextCell>
  ),
  [CreditEventColumnIds.division]: ({ creditEvent }) => (
    <TextCell>{creditEvent?.rep?.division}</TextCell>
  )
}

export const CreditEventCell = createCreditEventCell(CreditEventCellComponents)
