import { css, useTheme } from '@emotion/react'
import {
  DirectionalHint,
  HoverCard,
  HoverCardType,
  IPlainCardProps
} from '@fluentui/react'
import {
  ExpandedState,
  HeaderContext,
  Row,
  createColumnHelper
} from '@tanstack/react-table'
import { PerformanceData } from 'api/performance.types'
import { format } from 'date-fns'
import { sum, round } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { PercentCellWithColor } from '../../../components/PercentCellWithColor'
import { detailTable } from '../../../components/shared/DataTable/DetailTable'
import { HeaderContainer } from '../../../components/shared/DetailTables/HeaderContainer'
import { SnackBar } from '../../../components/shared/Snackbar'
import { USDCellWithColor } from '../../../components/USDCellWithColor'
import { Icon } from '../../../features/Icons/Icon'
import AccountToggle from '../../../features/Performance/AccountToggle'
import IncomeReturnTypeToggle from '../../../features/Performance/IncomeReturnTypeToggle'
import { InvalidPerformanceCell } from '../../../features/Performance/InvalidPerformanceCell'
import PerformanceDateRangeFilter from '../../../features/Performance/PerformanceDateRangeFilter'
import {
  getIsPartialPeriod,
  getIsValidPerformance,
  usePerformanceState
} from '../../../features/Performance/store'
import { USDCell, WidgetTable } from '../../../features/WidgetTable/WidgetTable'
import {
  IRdot360Account,
  useRdot360AccountContext
} from '../../../store/rdot360Context'
import { useRdot360PerformanceContext } from '../../../store/rdot360Context/useRdot360PerformanceContext'
import { performanceDetailTableColumnNames } from '../../Performance/PerformanceTableColumns'

export const getClasses = () => ({
  container: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  }),
  hyperLinkLabel: css({
    color: '#1D679D',
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  })
})

const helperClasses = {
  rightEllipsis: css({
    textAlign: 'right',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  })
}

const SumFooter: React.FC<HeaderContext<PerformanceData, unknown>> = ({
  table,
  column
}) => {
  const total = sum(
    table
      .getRowModel()
      .rows.map(({ getValue }) => getValue<number>(column.id) || 0)
  )
  return <USDCell value={total} defaultValue="--" />
}

const AccountNumberCell: React.FC<{
  account?: IRdot360Account
  itd?: string
}> = ({ account, itd }) => {
  const accountNumber = account?.CustodyAccount ?? ''
  const title = `${account?.preferredNickname || ''}\n${accountNumber} ${
    account?.registrationtype ? `| ${account?.registrationtype}` : ''
  }\nInception Date: ${itd}`

  return (
    <div
      css={(theme) => [
        detailTable.ellipsis,
        { color: theme.colors.tertiaryBlue1 }
      ]}
      title={title}
    >
      {account?.preferredNickname || accountNumber}
    </div>
  )
}

const PerformanceTable: React.FC = () => {
  const classes = useMemo(() => getClasses(), [])

  const { assetType, dateRange, setDefaultExpanded, setSearchText } =
    usePerformanceState()

  const {
    performanceData,
    isUninitialized,
    isFetching,
    isError,
    error,
    isGroupDataAvailable,
    selectedEntityTypeIsSingleAccount
  } = useRdot360PerformanceContext()

  const columnHelper = createColumnHelper<PerformanceData>()
  const navigate = useNavigate()

  const onClick = useCallback(
    (index: number, value?: string) => {
      if (!selectedEntityTypeIsSingleAccount) {
        const parameter: ExpandedState = {
          [index]: true
        }
        setDefaultExpanded(parameter)
      } else {
        setSearchText(value)
      }
      navigate('../performance')
    },
    [
      navigate,
      selectedEntityTypeIsSingleAccount,
      setDefaultExpanded,
      setSearchText
    ]
  )

  const { accountLookupByAccountIdOrKey } = useRdot360AccountContext()

  const getAccountByIdOrKey = useCallback(
    (accountIdOrKey?: string) => {
      return accountIdOrKey
        ? accountLookupByAccountIdOrKey[accountIdOrKey] ?? {}
        : {}
    },
    [accountLookupByAccountIdOrKey]
  )

  const getSortingFn =
    (dateRange: string, assetType: string) =>
    (a: Row<PerformanceData>, b: Row<PerformanceData>) => {
      const x = a.original[`${dateRange}CPerformance`]?.[assetType]
      const y = b.original[`${dateRange}CPerformance`]?.[assetType]

      if (x == null) {
        return -1
      }
      if (y == null) {
        return 1
      }
      if (Math.abs(x) > 0.99) {
        return -1
      }
      return (x || 0) > (y || 0) ? 1 : (x || 0) < (y || 0) ? -1 : 0
    }

  const columns = useMemo(
    () => [
      columnHelper.accessor(({ Name }) => Name, {
        id: performanceDetailTableColumnNames.group,
        cell: (props) =>
          selectedEntityTypeIsSingleAccount ? (
            <div
              css={classes.hyperLinkLabel}
              onClick={() => onClick(props.row.index, props.getValue())}
            >
              <AccountNumberCell
                account={getAccountByIdOrKey(props.row.original.Account?.[0])}
                itd={props.row.original.ITDCPerformance?.StartDate}
              />
            </div>
          ) : (
            <div
              title={`${props.getValue()} \nInception Date: ${
                props.row.original.ITDCPerformance?.StartDate
              }`}
            >
              <div
                css={classes.hyperLinkLabel}
                onClick={() => onClick(props.row.index, props.getValue())}
              >
                {props.getValue()}
              </div>
            </div>
          ),
        header: (props) => (
          <HeaderContainer
            title={selectedEntityTypeIsSingleAccount ? 'Account' : 'Group'}
            isSorted={props.column.getIsSorted()}
            rightAlign={false}
          />
        ),
        footer: 'Total',
        size: 110
      }),
      columnHelper.accessor(
        (row) => row[`${dateRange}CPerformance`]?.[assetType],
        {
          id: assetType,
          sortingFn: getSortingFn(dateRange, assetType),
          cell: ({ row }) => {
            const parent = row.getParentRow()
            const value =
              row.original?.[`${dateRange}CPerformance`]?.[assetType]

            const isPartialPeriod = getIsPartialPeriod(
              dateRange,
              row?.original,
              parent?.original
            )

            const isValidPerformance = getIsValidPerformance(value)
            const hasValue = value != null

            return (
              <div css={helperClasses.rightEllipsis}>
                {hasValue && isPartialPeriod && isValidPerformance && '*'}
                {!isValidPerformance && !row.original?.override99 && (
                  <InvalidPerformanceCell />
                )}
                {hasValue &&
                  (isValidPerformance || row.original?.override99) && (
                    <PercentCellWithColor
                      value={round(value, 4) * 100}
                      showTwoDecimalPrecisionForZero
                    />
                  )}
                {!hasValue && '--'}
              </div>
            )
          },
          header: (props) => (
            <HeaderContainer
              title={{ NetTWR: 'Net TWR', NetIrr: 'Net IRR' }[assetType] || ''}
              isSorted={props.column.getIsSorted()}
              rightAlign={true}
            />
          ),
          size: 72
        }
      ),
      columnHelper.accessor(
        (row) => row?.[`${dateRange}CPerformance`]?.StartValue,
        {
          id: `${performanceDetailTableColumnNames.startValue} ${dateRange}`,
          cell: (props) => (
            <USDCell
              value={props.row.original[`${dateRange}CPerformance`]?.StartValue}
              defaultValue="--"
            />
          ),
          header: (props) => (
            <HeaderContainer
              title={performanceDetailTableColumnNames.startValue}
              isSorted={props.column.getIsSorted()}
              rightAlign={true}
            />
          ),
          footer: SumFooter,
          size: 90
        }
      ),
      columnHelper.accessor(
        (row) => row?.[`${dateRange}CPerformance`]?.Netflows,
        {
          id: `${performanceDetailTableColumnNames.netFlows} ${dateRange}`,
          cell: (props) => (
            <div css={helperClasses.rightEllipsis}>
              {props.row?.original?.[`${dateRange}CPerformance`]?.Netflows !==
              undefined ? (
                <USDCellWithColor
                  value={
                    props.row?.original?.[`${dateRange}CPerformance`]?.Netflows
                  }
                  fractionDigit={0}
                />
              ) : (
                '--'
              )}
            </div>
          ),
          header: (props) => (
            <HeaderContainer
              title={performanceDetailTableColumnNames.netFlows}
              isSorted={props.column.getIsSorted()}
              rightAlign={true}
            />
          ),
          footer: SumFooter,
          size: 90
        }
      ),
      columnHelper.accessor(
        (row) => row?.[`${dateRange}CPerformance`]?.NetInvestmentGain,
        {
          id: `${performanceDetailTableColumnNames.investmentEarnings} ${dateRange}`,
          cell: (props) => (
            <div css={helperClasses.rightEllipsis}>
              {props.row.original[`${dateRange}CPerformance`]
                ?.NetInvestmentGain !== undefined ? (
                <USDCellWithColor
                  value={
                    props.row.original[`${dateRange}CPerformance`]
                      ?.NetInvestmentGain
                  }
                  fractionDigit={0}
                />
              ) : (
                '--'
              )}
            </div>
          ),
          header: (props) => (
            <HeaderContainer
              title={performanceDetailTableColumnNames.investmentEarnings}
              isSorted={props.column.getIsSorted()}
              rightAlign={true}
            />
          ),
          footer: SumFooter,
          size: 90
        }
      ),
      columnHelper.accessor(
        (row) => row?.[`${dateRange}CPerformance`]?.EndValue,
        {
          id: `${performanceDetailTableColumnNames.endingValue} ${dateRange}`,
          cell: (props) => (
            <USDCell
              value={props.row.original[`${dateRange}CPerformance`]?.EndValue}
              defaultValue="--"
            />
          ),
          header: (props) => (
            <HeaderContainer
              title={performanceDetailTableColumnNames.endingValue}
              isSorted={props.column.getIsSorted()}
              rightAlign={true}
            />
          ),
          footer: SumFooter,
          size: 90
        }
      )
    ],
    [
      assetType,
      classes.hyperLinkLabel,
      columnHelper,
      dateRange,
      getAccountByIdOrKey,
      onClick,
      selectedEntityTypeIsSingleAccount
    ]
  )

  const theme = useTheme()
  const plainCardProps = useMemo(
    (): IPlainCardProps => ({
      onRenderPlainCard: () => (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 5,
            fontSize: 10,
            maxWidth: '350px',
            padding: '20px'
          }}
        >
          <span>
            The information presented is based on groups/accounts imported from
            external sources and are not filtered based upon the selection in
            the account selector. Calculations and/or values may be different
            than other areas in the Client Dashboard or custodial statements.
          </span>
          <span>Partial period performance is marked with an asterisk (*)</span>
        </div>
      ),
      directionalHint: DirectionalHint.rightCenter,
      calloutProps: {
        isBeakVisible: true,
        gapSpace: 5
      }
    }),
    []
  )
  const errorMessage = (error as Error)?.message || 'An unknown Error Occurred'

  return (
    <>
      {isError && (
        <div css={{ paddingBottom: '5px' }}>
          <SnackBar message={errorMessage} type="Failure" />
        </div>
      )}
      <div css={classes.container}>
        <IncomeReturnTypeToggle />
        {isGroupDataAvailable && <AccountToggle />}
        <PerformanceDateRangeFilter />
      </div>
      <WidgetTable
        data={performanceData}
        emptyRowCount={6}
        sort={[
          {
            id: `${performanceDetailTableColumnNames.endingValue} ${dateRange}`,
            desc: true
          }
        ]}
        columns={columns}
        isUninitialized={isUninitialized}
        isLoading={isFetching}
        isFooter={selectedEntityTypeIsSingleAccount}
      />
      <div
        css={{
          fontSize: 10,
          fontWeight: 300,
          display: 'flex',
          columnGap: 4,
          alignItems: 'center'
        }}
      >
        <div style={{ flexGrow: 1, minWidth: 0 }}>
          <div css={{ display: 'flex', flexDirection: 'column' }}>
            NFS and Non-NFS Accounts fed through Addepar / Tamarac Only.
            {performanceData?.[0]?.LastUpdatedAt && (
              <span>
                Values are as of{' '}
                {format(
                  parseDateISOStringInLocalTimezone(
                    performanceData?.[0]?.LastUpdatedAt
                  ),
                  'yyyy-MM-dd'
                )}
              </span>
            )}
          </div>
        </div>
        <HoverCard
          plainCardProps={plainCardProps}
          type={HoverCardType.plain}
          cardDismissDelay={400}
          instantOpenOnClick={true}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon
              type="Info"
              width={10}
              height={10}
              color={theme.colors.tertiaryBlue1}
            />
            <span
              css={{
                fontSize: 10,
                padding: '0 3px',
                color: '#2A769D',
                textDecoration: 'underline',
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
                fontWeight: '500'
              }}
            >
              More info
            </span>
          </div>
        </HoverCard>
      </div>
    </>
  )
}

export default PerformanceTable
