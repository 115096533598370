import { createReducer } from 'typesafe-actions'
import {
  fetchManifestsActions,
  fetchStatementDetailActions,
  ManifestsActions,
  uploadManifestFileActions
} from './actions'
import { IManifest, IStatementDetail } from './types'

export interface IManifestListState {
  error?: Error
  isLoading?: boolean
  manifests?: IManifest[]
}

export interface IUploadManifestFileState {
  error?: Error
  isLoading?: boolean
  isUploaded?: boolean
}
export interface IStatementDetailsState {
  error?: Error
  isLoading?: boolean
  statementDetails?: IStatementDetail[]
  manifestFileName?: string
}

export interface IManifestsState {
  manifestListState?: IManifestListState
  uploadManifestFileState?: IUploadManifestFileState
  statementDetailsState?: IStatementDetailsState
}
export const initialState: IManifestsState = {}

export const manifestsReducer = createReducer<
  IManifestsState,
  ManifestsActions
>(initialState)
  .handleAction(fetchManifestsActions.request, (state) => ({
    ...state,
    manifestListState: {
      isLoading: true,
      error: undefined,
      manifests: undefined
    }
  }))
  .handleAction(fetchManifestsActions.success, (state, action) => ({
    ...state,
    manifestListState: {
      isLoading: false,
      error: undefined,
      manifests: action.payload
    }
  }))
  .handleAction(fetchManifestsActions.failure, (state, action) => ({
    ...state,
    manifestListState: {
      isLoading: false,
      error: action.payload,
      manifests: undefined
    }
  }))
  .handleAction(uploadManifestFileActions.request, (state) => ({
    ...state,
    uploadManifestFileState: {
      isLoading: true,
      error: undefined,
      isUploaded: undefined
    }
  }))
  .handleAction(uploadManifestFileActions.success, (state, action) => ({
    ...state,
    uploadManifestFileState: {
      isLoading: false,
      error: undefined,
      isUploaded: action.payload?.isSuccess
    }
  }))
  .handleAction(uploadManifestFileActions.failure, (state, action) => ({
    ...state,
    uploadManifestFileState: {
      isLoading: false,
      error: action.payload,
      isUploaded: undefined
    }
  }))
  .handleAction(fetchStatementDetailActions.request, (state, action) => ({
    ...state,
    statementDetailsState: {
      isLoading: true,
      error: undefined,
      statementDetails: undefined,
      manifestFileName: action.payload
    }
  }))
  .handleAction(fetchStatementDetailActions.success, (state, action) => ({
    ...state,
    statementDetailsState: {
      ...state.statementDetailsState,
      isLoading: false,
      error: undefined,
      statementDetails: action.payload
    }
  }))
  .handleAction(fetchStatementDetailActions.failure, (state, action) => ({
    ...state,
    statementDetailsState: {
      ...state.statementDetailsState,
      isLoading: false,
      error: action.payload,
      statementDetails: undefined
    }
  }))
  .handleAction(fetchStatementDetailActions.clear, (state) => ({
    ...state,
    statementDetailsState: undefined
  }))
