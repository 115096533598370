import { combineReducers } from 'redux'
import {
  accountLinkingRequestViewFeatureReducer,
  accountLinkingRequestViewFeatureSagas
} from './AccountLinkingRequestView'

export const accountLinkingFeaturesReducer = combineReducers({
  accountLinkingRequestView: accountLinkingRequestViewFeatureReducer
})

export const accountLinkingFeaturesSagas = [
  ...accountLinkingRequestViewFeatureSagas
]
