import { createAction } from 'typesafe-actions'
import { IFeeForm } from '../features/Schedule/FeeFormPanel/ScheduleFeeForm'
import {
  MasterData,
  IFeeScheduleForm,
  IAgencyFeeTypeModel,
  IDepositAccountModel,
  IGLAccountModel,
  IGLAccountResponseModel,
  IFetchAgencyFeeTypeModel
} from './types'

// New Fee UI Actions
export const FETCH_FEE_CATEGORIES = `@module/@finance/@schedulefee/FETCH_FEE_CATEGORIES`
export const FETCH_FEE_CATEGORIES_SUCCESS = `@module/@finance/@schedulefee/FETCH_FEE_CATEGORIES_SUCCESS`
export const FETCH_FEE_CATEGORIES_FAILURE = `@module/@finance/@schedulefee/FETCH_FEE_CATEGORIES_FAILURE`

export const fetchFeeCategoriesActions = {
  request: createAction(FETCH_FEE_CATEGORIES)(),
  success: createAction(FETCH_FEE_CATEGORIES_SUCCESS)<MasterData[]>(),
  failure: createAction(FETCH_FEE_CATEGORIES_FAILURE)<Error>()
}

export const FETCH_FEE_SERVICE_TYPE = `@module/@finance/@schedulefee/FETCH_FEE_SERVICE_TYPE`
export const FETCH_FEE_SERVICE_TYPE_SUCCESS = `@module/@finance/@schedulefee/FETCH_FEE_SERVICE_TYPE_SUCCESS`
export const FETCH_FEE_SERVICE_TYPE_FAILURE = `@module/@finance/@schedulefee/FETCH_FEE_SERVICE_TYPE_FAILURE`

export const fetchFeeServiceTypeActions = {
  request: createAction(FETCH_FEE_SERVICE_TYPE)<string>(),
  success: createAction(FETCH_FEE_SERVICE_TYPE_SUCCESS)<MasterData[]>(),
  failure: createAction(FETCH_FEE_SERVICE_TYPE_FAILURE)<Error>()
}

export const FETCH_INVOICE_FEE_TYPE = `@module/@finance/@schedulefee/FETCH_INVOICE_FEE_TYPE`
export const FETCH_INVOICE_FEE_TYPE_SUCCESS = `@module/@finance/@schedulefee/FETCH_INVOICE_FEE_TYPE_SUCCESS`
export const FETCH_INVOICE_FEE_TYPE_FAILURE = `@module/@finance/@schedulefee/FETCH_INVOICE_FEE_TYPE_FAILURE`

export const fetchInvoiceFeeTypeActions = {
  request: createAction(FETCH_INVOICE_FEE_TYPE)<string>(),
  success: createAction(FETCH_INVOICE_FEE_TYPE_SUCCESS)<MasterData[]>(),
  failure: createAction(FETCH_INVOICE_FEE_TYPE_FAILURE)<Error>()
}

export const FETCH_FEE_COLLECTION_TYPE = `@module/@finance/@schedulefee/FETCH_FEE_COLLECTION_TYPE`
export const FETCH_FEE_COLLECTION_TYPE_SUCCESS = `@module/@finance/@schedulefee/FETCH_FEE_COLLECTION_TYPE_SUCCESS`
export const FETCH_FEE_COLLECTION_TYPE_FAILURE = `@module/@finance/@schedulefee/FETCH_FEE_COLLECTION_TYPE_FAILURE`

export const fetchFeeCollectionTypeActions = {
  request: createAction(FETCH_FEE_COLLECTION_TYPE)(),
  success: createAction(FETCH_FEE_COLLECTION_TYPE_SUCCESS)<MasterData[]>(),
  failure: createAction(FETCH_FEE_COLLECTION_TYPE_FAILURE)<Error>()
}

export const FETCH_AGENCY_FEE_TYPE = `@module/@finance/@schedulefee/FETCH_AGENCY_FEE_TYPE`
export const FETCH_AGENCY_FEE_TYPE_SUCCESS = `@module/@finance/@schedulefee/FETCH_AGENCY_FEE_TYPE_SUCCESS`
export const FETCH_AGENCY_FEE_TYPE_FAILURE = `@module/@finance/@schedulefee/FETCH_AGENCY_FEE_TYPE_FAILURE`

export const fetchAgencyFeeTypeActions = {
  request: createAction(FETCH_AGENCY_FEE_TYPE)<IFetchAgencyFeeTypeModel>(),
  success: createAction(FETCH_AGENCY_FEE_TYPE_SUCCESS)<IAgencyFeeTypeModel[]>(),
  failure: createAction(FETCH_AGENCY_FEE_TYPE_FAILURE)<Error>()
}

export const FETCH_GLACCOUNT = `@module/@finance/@schedulefee/FETCH_GLACCOUNT`
export const FETCH_GLACCOUNT_SUCCESS = `@module/@finance/@schedulefee/FETCH_GLACCOUNT_SUCCESS`
export const FETCH_GLACCOUNT_FAILURE = `@module/@finance/@schedulefee/FETCH_GLACCOUNT_FAILURE`

export const fetchGLAccountActions = {
  request: createAction(FETCH_GLACCOUNT)<IGLAccountModel>(),
  success: createAction(FETCH_GLACCOUNT_SUCCESS)<IGLAccountResponseModel[]>(),
  failure: createAction(FETCH_GLACCOUNT_FAILURE)<Error>()
}

export const FETCH_DEPOSIT_ACCOUNT = `@module/@finance/@schedulefee/FETCH_DEPOSIT_ACCOUNT`
export const FETCH_DEPOSIT_ACCOUNT_SUCCESS = `@module/@finance/@schedulefee/FETCH_DEPOSIT_ACCOUNT_SUCCESS`
export const FETCH_DEPOSIT_ACCOUNT_FAILURE = `@module/@finance/@schedulefee/FETCH_DEPOSIT_ACCOUNT_FAILURE`

export const fetchDepositAccountActions = {
  request: createAction(FETCH_DEPOSIT_ACCOUNT)(),
  success: createAction(FETCH_DEPOSIT_ACCOUNT_SUCCESS)<
    IDepositAccountModel[]
  >(),
  failure: createAction(FETCH_DEPOSIT_ACCOUNT_FAILURE)<Error>()
}

export const FETCH_FEE_FREQUENCY_TYPE = `@module/@finance/@schedulefee/FETCH_FEE_FREQUENCY_TYPE`
export const FETCH_FEE_FREQUENCY_TYPE_SUCCESS = `@module/@finance/@schedulefee/FETCH_FEE_FREQUENCY_TYPE_SUCCESS`
export const FETCH_FEE_FREQUENCY_TYPE_FAILURE = `@module/@finance/@schedulefee/FETCH_FEE_FREQUENCY_TYPE_FAILURE`

export const fetchFeeFrequencyTypeActions = {
  request: createAction(FETCH_FEE_FREQUENCY_TYPE)(),
  success: createAction(FETCH_FEE_FREQUENCY_TYPE_SUCCESS)<MasterData[]>(),
  failure: createAction(FETCH_FEE_FREQUENCY_TYPE_FAILURE)<Error>()
}

export const FETCH_FEE_BUSINESS_SEGMENT_TYPE = `@module/@finance/@schedulefee/FETCH_FEE_BUSINESS_SEGMENT_TYPE`
export const FETCH_FEE_BUSINESS_SEGMENT_TYPE_SUCCESS = `@module/@finance/@schedulefee/FETCH_FEE_BUSINESS_SEGMENT_TYPE_SUCCESS`
export const FETCH_FEE_BUSINESS_SEGMENT_TYPE_FAILURE = `@module/@finance/@schedulefee/FETCH_FEE_BUSINESS_SEGMENT_TYPE_FAILURE`

export const fetchFeeBusinessSegmentTypeActions = {
  request: createAction(FETCH_FEE_BUSINESS_SEGMENT_TYPE)(),
  success: createAction(FETCH_FEE_BUSINESS_SEGMENT_TYPE_SUCCESS)<
    MasterData[]
  >(),
  failure: createAction(FETCH_FEE_BUSINESS_SEGMENT_TYPE_FAILURE)<Error>()
}

export const FETCH_FEE_ADVISORS = `@module/@finance/@schedulefee/FETCH_FEE_ADVISORS`
export const FETCH_FEE_ADVISORS_SUCCESS = `@module/@finance/@schedulefee/FETCH_FEE_ADVISORS_SUCCESS`
export const FETCH_FEE_ADVISORS_FAILURE = `@module/@finance/@schedulefee/FETCH_FEE_ADVISORS_FAILURE`

export const fetchFeeAdvisorActions = {
  request: createAction(FETCH_FEE_ADVISORS)(),
  success: createAction(FETCH_FEE_ADVISORS_SUCCESS)<MasterData[]>(),
  failure: createAction(FETCH_FEE_ADVISORS_FAILURE)<Error>()
}

export const FETCH_FEE_LEGEL_ENTITIES = `@module/@finance/@schedulefee/FETCH_FEE_LEGEL_ENTITIES`
export const FETCH_FEE_LEGEL_ENTITIES_SUCCESS = `@module/@finance/@schedulefee/FETCH_FEE_LEGEL_ENTITIES_SUCCESS`
export const FETCH_FEE_LEGEL_ENTITIES_FAILURE = `@module/@finance/@schedulefee/FETCH_FEE_LEGEL_ENTITIES_FAILURE`

export const fetchFeeLegalEntitiesActions = {
  request: createAction(FETCH_FEE_LEGEL_ENTITIES)<string>(),
  success: createAction(FETCH_FEE_LEGEL_ENTITIES_SUCCESS)<MasterData[]>(),
  failure: createAction(FETCH_FEE_LEGEL_ENTITIES_FAILURE)<Error>()
}

export const CREATE_NEW_FEE = `@module/@finance/@schedulefee/CREATE_NEW_FEE`
export const CREATE_NEW_FEE_SUCCESS = `@module/@finance/@schedulefee/CREATE_NEW_FEE_SUCCESS`
export const CREATE_NEW_FEE_FAILURE = `@module/@finance/@schedulefee/CREATE_NEW_FEE_FAILURE`
export const CREATE_NEW_FEE_CLEAR = `@module/@finance/@schedulefee/CREATE_NEW_FEE_CLEAR`

export const createNewFeeActions = {
  request: createAction(CREATE_NEW_FEE)<IFeeForm>(),
  success: createAction(CREATE_NEW_FEE_SUCCESS)(),
  failure: createAction(CREATE_NEW_FEE_FAILURE)<Error>(),
  clear: createAction(CREATE_NEW_FEE_CLEAR)()
}

export const UPDATE_NEW_FEE = `@module/@finance/@schedulefee/UPDATE_NEW_FEE`
export const UPDATE_NEW_FEE_SUCCESS = `@module/@finance/@schedulefee/UPDATE_NEW_FEE_SUCCESS`
export const UPDATE_NEW_FEE_FAILURE = `@module/@finance/@schedulefee/UPDATE_NEW_FEE_FAILURE`
export const UPDATE_NEW_FEE_CLEAR = `@module/@finance/@schedulefee/UPDATE_NEW_FEE_CLEAR`

export const updateNewFeeActions = {
  request: createAction(UPDATE_NEW_FEE)<IFeeForm>(),
  success: createAction(UPDATE_NEW_FEE_SUCCESS)(),
  failure: createAction(UPDATE_NEW_FEE_FAILURE)<Error>(),
  clear: createAction(UPDATE_NEW_FEE_CLEAR)()
}
export const UPDATE_NEW_FEE_SCHEDULE = `@module/@finance/@feeschedule/UPDATE_NEW_SCHEDULE_FEE`
export const UPDATE_NEW_FEE_SCHEDULE_SUCCESS = `@module/@finance/@feeschedule/UPDATE_NEW_FEE_SCHEDULE_SUCCESS`
export const UPDATE_NEW_FEE_SCHEDULE_FAILURE = `@module/@finance/@feeschedule/UPDATE_NEW_FEE_SCHEDULE_FAILURE`
// export const UPDATE_NEW_FEE_CLEAR = `@module/@finance/@schedulefee/UPDATE_NEW_FEE_CLEAR`

export const updateNewFeeScheduleActions = {
  request: createAction(UPDATE_NEW_FEE_SCHEDULE)<IFeeScheduleForm>(),
  success: createAction(UPDATE_NEW_FEE_SCHEDULE_SUCCESS)(),
  failure: createAction(UPDATE_NEW_FEE_SCHEDULE_FAILURE)<Error>()
  // clear: createAction(UPDATE_NEW_FEE_CLEAR)()
}
