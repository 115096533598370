import { flow } from 'lodash'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { AppState } from '../../../../../../../../../store'

const SET_CHART_OR_TABLE =
  '@modules/@teams/@payoutsDashboardUi/SET_CHART_OR_TABLE'
const SET_GROUP_BY_TYPE =
  '@modules/@teams/@payoutsDashboardUi/SET_GROUP_BY_TYPE'

export type ChartorTable = 'Chart' | 'Table'
export type GroupByTypes = 'hurdle' | 'team'

export const payoutsDashboardUiActions = {
  setChartOrTable: createAction(SET_CHART_OR_TABLE)<ChartorTable>(),
  setGroupByType: createAction(SET_GROUP_BY_TYPE)<GroupByTypes>()
}

export type PayoutsDashboardUiActionTypes = ActionType<
  typeof payoutsDashboardUiActions
>

export interface IPayoutsDashboardUiFormState {
  chartOrTable: ChartorTable
  groupByType: GroupByTypes
}

const initialState: IPayoutsDashboardUiFormState = {
  chartOrTable: 'Table',
  groupByType: 'team'
}

export const payoutsDashboardUiReducer = createReducer<
  IPayoutsDashboardUiFormState,
  PayoutsDashboardUiActionTypes
>(initialState)
  .handleAction(payoutsDashboardUiActions.setChartOrTable, (state, action) => ({
    ...state,
    chartOrTable: action.payload
  }))
  .handleAction(payoutsDashboardUiActions.setGroupByType, (state, action) => ({
    ...state,
    groupByType: action.payload
  }))

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.teams.modules.hurdles.features.potentialPayouts
    .potentialPayoutsUi

export const getChartOrTable = flow(
  rootSelector,
  ({ chartOrTable }) => chartOrTable
)
export const getGroupByType = flow(
  rootSelector,
  ({ groupByType }) => groupByType
)

export const payoutsDashboardUiSagas = []
