import { ActionType, createAction } from 'typesafe-actions'
import { IPerson, IPersonRequest } from './types'

export const FETCH_PERSON = '@modules/@payments/@person/FETCH_PERSON'
export const FETCH_PERSON_SUCCESS =
  '@modules/@payments/@person/FETCH_PERSON_SUCCESS'
export const FETCH_PERSON_FAILURE =
  '@modules/@payments/@person/FETCH_PERSON_FAILURE'

export const ADD_EDIT_PERSON = '@modules/@payments/@person/ADD_EDIT_PERSON'
export const ADD_EDIT_PERSON_SUCCESS =
  '@modules/@payments/@person/ADD_EDIT_PERSON_SUCCESS'
export const ADD_EDIT_PERSON_FAILURE =
  '@modules/@payments/@person/ADD_EDIT_PERSON_FAILURE'

export const personDataActions = {
  request: createAction(FETCH_PERSON)<IPersonRequest>(),
  success: createAction(FETCH_PERSON_SUCCESS)<IPerson[]>(),
  failure: createAction(FETCH_PERSON_FAILURE)<Error>()
}

export const addEditPersonDataActions = {
  request: createAction(ADD_EDIT_PERSON)<IPerson | undefined>(),
  success: createAction(ADD_EDIT_PERSON_SUCCESS)<IPerson>(),
  failure: createAction(ADD_EDIT_PERSON_FAILURE)<Error>()
}

export type PersonDataActionTypes = ActionType<typeof personDataActions>
export type AddEditActionTypes = ActionType<typeof addEditPersonDataActions>

export type PersonActionTypes = PersonDataActionTypes | AddEditActionTypes
