import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { IClient } from 'api/client.types'
import { ISearchResult } from 'api/common.types'
import axios from 'axios'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { datahubApi } from 'store/api/datahub'
import { AxiosBaseArgs, arrayCommaParamsSerializer } from 'store/api/shared'
import {
  AlertsBatchResponse,
  IAlert,
  IBulkPayload,
  IOdataFacetResult,
  IOdataRequest
} from './alerts.types'
import {
  CacheringFileResponse,
  CacheringSummary
} from './cacheringSummary.types'
import { constructOdataQuery } from './odata'

type DatahubApiTagType =
  | 'alerts,count:999'
  | 'source,count:999'
  | 'status,count:999'
  | 'facets'
  | 'uploadFile'
  | 'cacheringSummary'
  | 'cacheringSummaryFile'
  | 'rejectedrecords'
  | 'generateAlerts'
  | 'cancelAlerts'
const datahubApiTags: DatahubApiTagType[] = [
  'alerts,count:999',
  'source,count:999',
  'status,count:999',
  'facets',
  'uploadFile',
  'cacheringSummary',
  'cacheringSummaryFile',
  'rejectedrecords',
  'generateAlerts',
  'cancelAlerts'
]

const datahubWithAlertsTags = datahubApi.enhanceEndpoints({
  addTagTypes: datahubApiTags
})

export const alertsDatahubApi = datahubWithAlertsTags.injectEndpoints({
  endpoints: (builder) => ({
    alerts_facets: builder.query<
      IOdataFacetResult | undefined,
      object | undefined
    >({
      queryFn: async (filter, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<IOdataFacetResult, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: `/search/alerts`,
          method: 'post',
          data: filter,
          paramsSerializer: arrayCommaParamsSerializer
        }
        const result = (await baseQuery({
          ...baseApiArgs
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return result
      },
      providesTags: ['alerts,count:999', 'facets']
    }),
    alerts_source: builder.query<IOdataFacetResult | undefined, void>({
      queryFn: async (payload, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<IOdataFacetResult, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: '/search/alerts',
          method: 'post',
          paramsSerializer: arrayCommaParamsSerializer
        }
        const baseApiParams = {
          top: 0,
          facets: ['source,count:999'],
          filter: 'isDeleted ne true'
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          data: { ...baseApiParams }
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return result
      },
      providesTags: ['alerts,count:999', 'source,count:999']
    }),
    alerts_status: builder.query<
      IOdataFacetResult | undefined,
      { sources: string[]; enabledArchiveFilter: boolean }
    >({
      queryFn: async (payload, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<IOdataFacetResult, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: '/search/alerts',
          method: 'post',
          paramsSerializer: arrayCommaParamsSerializer
        }
        const baseApiParams = {
          top: 0,
          facets: ['status,count:999'],
          filter: `${
            payload.sources.length > 0
              ? "search.in(source, '" +
                payload.sources.join('|') +
                "', '|') and "
              : ''
          }isArchived ${
            payload.enabledArchiveFilter ? 'eq true' : 'ne true'
          } and isDeleted ne true`
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          data: { ...baseApiParams }
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return result
      },
      providesTags: ['alerts,count:999', 'status,count:999']
    }),
    alerts_types: builder.query<
      IOdataFacetResult | undefined,
      { sources: string[]; enabledArchiveFilter: boolean }
    >({
      queryFn: async (payload, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<IOdataFacetResult, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: '/search/alerts',
          method: 'post',
          paramsSerializer: arrayCommaParamsSerializer
        }
        const baseApiParams = {
          top: 0,
          facets: ['alertType,count:999'],
          filter: `${
            payload.sources.length > 0
              ? "search.in(source, '" +
                payload.sources.join('|') +
                "', '|') and "
              : ''
          }isArchived ${
            payload.enabledArchiveFilter ? 'eq true' : 'ne true'
          } and isDeleted ne true`
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          data: { ...baseApiParams }
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return result
      },
      providesTags: ['alerts,count:999', 'status,count:999']
    }),
    getIndividualsFromLegalEntityID: builder.query<
      IClient[] | undefined,
      string
    >({
      queryFn: async (id, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<ISearchResult<IClient>, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: 'search/clients',
          paramsSerializer: arrayCommaParamsSerializer
        }

        const baseApiParams = {
          $filter: `LegalEntityID eq '${id}'`,
          $count: true,
          select: [
            'LegalEntityName',
            'householdId',
            'householdList',
            'contactdetails/emailid',
            'legalEntityType',
            'Account',
            'LegalEntityID',
            'LegalEntityName',
            'loginDetails'
          ],
          queryType: 'full'
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          params: { ...baseApiParams }
        })) as Response

        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data?.value
        }
      }
    }),
    updateAlertStatus: builder.query<
      any | undefined,
      {
        options?: IApiOptions
        id?: string
        receivedWeek?: string
        isArchived?: boolean
        changedBy?: string
      }
    >({
      query: ({ options, id, receivedWeek, isArchived, changedBy }) => ({
        url: `${options?.apiRoot}/func-rdot/updatedocument/${id}/${receivedWeek}`,
        method: 'post',
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${options?.accessToken}`
        },
        data: { isArchived: isArchived, changedBy: changedBy }
      }),
      providesTags: ['alerts,count:999', 'facets'],
      transformResponse: (x: IOdataResult<IAlert[]>) => x
    }),
    updateOwner: builder.query<
      any | undefined,
      {
        options?: IApiOptions
        id?: string
        receivedWeek?: string
        owner?: string | null
        changedBy?: string
      }
    >({
      query: ({ options, id, receivedWeek, owner, changedBy }) => ({
        url: `${options?.apiRoot}/func-rdot/updatedocument/${id}/${receivedWeek}`,
        method: 'post',
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${options?.accessToken}`
        },
        data: { owner: owner, changedBy: changedBy }
      }),
      providesTags: ['alerts,count:999']
    }),
    bulkUpdate: builder.query<
      IBulkPayload[] | undefined,
      {
        options?: IApiOptions
        selectedAlertData?: IBulkPayload[]
      }
    >({
      query: ({ options, selectedAlertData }) => ({
        url: `${options?.apiRoot}/func-rdot/CAAlertsbulkupdate`,
        method: 'post',
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${options?.accessToken}`
        },
        data: selectedAlertData
      }),
      providesTags: ['alerts,count:999']
    }),
    uploadFile: builder.mutation<
      any,
      {
        options?: IApiOptions
        file: File
        importedby: string
        onProgress?: (progress: number) => void
      }
    >({
      query: ({ options, file, importedby, onProgress }) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('importedby', importedby)

        return {
          url: `${options?.apiRoot}/func-rdot/CAAlert_Cachering`,
          method: 'post',
          headers: {
            ...defaultHeaders,
            Authorization: `Bearer ${options?.accessToken}`,
            'Content-Type': 'multipart/form-data',
            'Content-Length': file.size
          },
          data: formData,
          onUploadProgress: (progressEvent: ProgressEvent) => {
            if (progressEvent.total) {
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
              onProgress?.(progress)
            }
          }
        }
      }
    }),
    getCacheringSummary: builder.query<CacheringSummary, IApiOptions>({
      query: (options) => {
        return {
          url: `${options?.apiRoot}/func-rdot/cacheringSummary`,
          method: 'GET'
        }
      },
      providesTags: ['cacheringSummary']
    }),
    getCacheringSummaryFile: builder.query<
      CacheringFileResponse,
      { fileName: string; options: IApiOptions }
    >({
      query: ({ fileName, options }) => {
        return {
          url: `${options?.apiRoot}/func-rdot/download/${fileName}`,
          method: 'GET'
        }
      },
      providesTags: ['cacheringSummaryFile']
    }),
    getRejectedrecords: builder.query<
      { [key: string]: string },
      { fileName: string; options: IApiOptions }
    >({
      query: ({ fileName, options }) => {
        return {
          url: `${options?.apiRoot}/func-rdot/rejectedrecords/${fileName}`,
          method: 'GET'
        }
      },
      providesTags: ['rejectedrecords']
    }),
    generateAlerts: builder.query<
      { [key: string]: string },
      { fileName: string; options: IApiOptions; fileId: string }
    >({
      query: ({ fileName, options, fileId }) => {
        return {
          url: `${options?.apiRoot}/func-rdot/generateAlerts/${fileName}/confirmed/${fileId}`,
          method: 'GET'
        }
      },
      providesTags: ['generateAlerts']
    }),
    cancelAlerts: builder.query<
      { [key: string]: string },
      { fileName: string; options: IApiOptions; fileId: string }
    >({
      query: ({ fileName, options, fileId }) => {
        return {
          url: `${options?.apiRoot}/func-rdot/generateAlerts/${fileName}/CANCELLED/${fileId}`,
          method: 'GET'
        }
      },
      providesTags: ['cancelAlerts']
    })
  })
})

export const {
  useAlerts_facetsQuery,
  useAlerts_sourceQuery,
  useAlerts_statusQuery,
  useAlerts_typesQuery,
  useLazyUpdateOwnerQuery,
  useLazyBulkUpdateQuery,
  useGetIndividualsFromLegalEntityIDQuery,
  useLazyUpdateAlertStatusQuery,
  useUploadFileMutation,
  useGetCacheringSummaryQuery,
  useGetCacheringSummaryFileQuery,
  useGetRejectedrecordsQuery,
  useGenerateAlertsQuery,
  useCancelAlertsQuery
} = alertsDatahubApi

const apiVersion = '1.0'
const defaultHeaders = {
  'api-version': apiVersion
}

export const getAlerts = (options: IApiOptions, request: IOdataRequest) => {
  const { apiRoot, cancelToken, accessToken } = options
  const query = constructOdataQuery(request)
  const url = `${apiRoot}/datahub/search/alerts`

  return axios
    .post<ISearchResult<IAlert>>(url, query, {
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${accessToken}`
      },
      cancelToken: cancelToken
    })
    .then((x) => x.data)
    .then((x) => {
      return x
    })
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      throw e
    })
}

export const getAllAlerts = (
  options: IApiOptions,
  request: IOdataRequest,
  count: number
) => {
  const { apiRoot, cancelToken, accessToken } = options
  const baseQuery = constructOdataQuery(request)
  const url = `/search/alerts`
  const batchUrl = `${apiRoot}/datahub/$batch`
  let id = 1
  let skip = 0
  const top = count
  const baseRequest = {
    id,
    method: 'POST',
    url,
    headers: {
      'Content-Type': 'application/json'
    },
    body: { ...baseQuery }
  }

  const requests = []

  while (count > 0 && id <= 100) {
    const request = {
      ...baseRequest,
      id: id.toString(),
      body: { ...baseQuery, skip, top }
    }
    requests.push(request)
    id = id + 1
    skip += 1000
    count -= 1000
  }

  return axios
    .post<AlertsBatchResponse>(
      batchUrl,
      {
        requests
      },
      {
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${accessToken}`
        },
        cancelToken: cancelToken
      }
    )
    .then((x) =>
      x.data.responses
        .filter(({ status }) => status === 200)
        .map(({ body: { value } }) => value)
    )
    .then((x) => {
      return x
    })
    .catch((e) => {
      if (e?.response?.data?.error) {
        throw e.response.data.error
      }
      throw e
    })
}
