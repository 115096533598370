import type { Borders, Fill, Font } from 'exceljs'
import tinycolor from 'tinycolor2'

const chartColors = [
  '#13A5B0',
  '#B3E3F4',
  '#7BC46C',
  '#C4E1B2',
  '#B71F3A',
  '#EF4264',
  '#653C93',
  '#B02C91',
  '#F89938',
  '#F4B93F',
  '#45bda9'
]

const blueLight = chartColors[1]
const blueLighterHex = tinycolor(blueLight).lighten(10).toHex()
const blueDarkHex = tinycolor(blueLight).darken(50).toHex()
const yellowLight = chartColors[9]
const yellowLigherHex = tinycolor(yellowLight).lighten(30).toHex()
const green = chartColors[3]
const greenLighHex = tinycolor(green).lighten(10).toHex()

export const fillWhite: Fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'ffffff' },
  bgColor: { argb: 'ffffff' }
}

export const fillLightGreen: Fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: greenLighHex },
  bgColor: { argb: greenLighHex }
}

export const fillLightBlue: Fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: blueLighterHex },
  bgColor: { argb: blueLighterHex }
}

export const fillDarkBlue: Fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: blueDarkHex },
  bgColor: { argb: blueDarkHex }
}

export const fillLighterYellow: Fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: yellowLigherHex },
  bgColor: { argb: yellowLigherHex }
}

export const thinBorder: Partial<Borders> = {
  top: { style: 'thin', color: { argb: 'cccccc' } },
  left: { style: 'thin', color: { argb: 'cccccc' } },
  bottom: { style: 'thin', color: { argb: 'cccccc' } },
  right: { style: 'thin', color: { argb: 'cccccc' } }
}

export const totalBorder: Partial<Borders> = {
  top: { style: 'thick', color: { argb: '999999' } },
  left: { style: 'thin', color: { argb: 'cccccc' } },
  bottom: { style: 'thin', color: { argb: 'cccccc' } },
  right: { style: 'thin', color: { argb: 'cccccc' } }
}

export const totalRowBorder: Partial<Borders> = {
  top: { style: 'thin', color: { argb: '4F81BD' } },
  bottom: { style: 'double', color: { argb: '4F81BD' } }
}

export const normalDarkFont: Partial<Font> = {
  name: 'Calibri',
  color: { argb: '333333' },
  size: 11
}

export const boldDarkFont: Partial<Font> = {
  ...normalDarkFont,
  bold: true
}

export const normalLightFont: Partial<Font> = {
  name: 'Calibri',
  color: { argb: 'FFFFFFFF' },
  size: 11
}

export const boldLightFont: Partial<Font> = {
  ...normalLightFont,
  bold: true
}
