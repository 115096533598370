import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { IDriveChild, useGraphApiUtil } from '../../store/graph'

interface Folder {
  name?: string
  id?: string
}

const { actions, reducer } = createSlice({
  name: 'activityDetailsUiState',
  initialState: {
    showCreateFolder: false,
    showDeleteWarning: false,
    showUpload: false,
    folderHierarchy: [{ name: 'Documents', id: undefined }]
  } as ICollaborationUiState,
  reducers: {
    setSelectedCollaborator: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.selectedCollaborator = action.payload
      state.folderHierarchy = [{ name: 'Documents', id: undefined }]
    },
    setShowCreateFolder: (state, action: PayloadAction<boolean>) => {
      state.showCreateFolder = action.payload
    },
    setShowDeleteWarning: (state, action: PayloadAction<boolean>) => {
      state.showDeleteWarning = action.payload
    },
    setShowUpload: (state, action: PayloadAction<boolean>) => {
      state.showUpload = action.payload
    },
    setMenuTarget: (state, action: PayloadAction<IDriveChild | undefined>) => {
      state.menuItem = action.payload
    },
    setItemToRename: (state, action: PayloadAction<string | undefined>) => {
      state.itemToRename = action.payload
    },
    setFolderHierarchy: (state, action: PayloadAction<Folder[]>) => {
      state.folderHierarchy = action.payload
    },
    setDriveId: (state, action: PayloadAction<string | undefined>) => {
      state.driveId = action.payload
    }
  }
})

export { reducer as collaborationUiStateReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.modules.collaboration
    .collaborationUiState

const getShowCreateFolder = flow(rootSelector, (x) => x.showCreateFolder)
const getShowDeleteWarning = flow(rootSelector, (x) => x.showDeleteWarning)
const getShowUpload = flow(rootSelector, (x) => x.showUpload)
const getMenuTarget = flow(rootSelector, (x) => x.menuItem)
const getItemToRename = flow(rootSelector, (x) => x.itemToRename)
const getSelectedCollaborator = flow(
  rootSelector,
  (x) => x.selectedCollaborator
)
const getFolderHierarchy = flow(rootSelector, (x) => x.folderHierarchy)
const getDriveId = flow(rootSelector, (x) => x.driveId)

export interface ICollaborationUiState {
  showCreateFolder: boolean
  showDeleteWarning: boolean
  showUpload: boolean
  menuItem?: IDriveChild
  itemToRename?: string
  selectedCollaborator?: string
  folderHierarchy: Folder[]
  driveId?: string
}

export const useCollaborationUiState = () => {
  const dispatch = useDispatch()

  const { invalidateTags } = useGraphApiUtil()

  const setShowCreateFolder = useCallback(
    (show: boolean) => {
      dispatch(actions.setShowCreateFolder(show))
    },
    [dispatch]
  )
  const showCreateFolder = useSelector(getShowCreateFolder)

  const setShowDeleteWarning = useCallback(
    (show: boolean) => {
      dispatch(actions.setShowDeleteWarning(show))
    },
    [dispatch]
  )
  const showDeleteWarning = useSelector(getShowDeleteWarning)

  const setShowUpload = useCallback(
    (show: boolean) => {
      dispatch(actions.setShowUpload(show))
    },
    [dispatch]
  )
  const showUpload = useSelector(getShowUpload)

  const setMenuItem = useCallback(
    (item?: IDriveChild) => {
      dispatch(actions.setMenuTarget(item))
    },
    [dispatch]
  )
  const menuItem = useSelector(getMenuTarget)

  const setItemToRename = useCallback(
    (item?: string) => {
      dispatch(actions.setItemToRename(item))
    },
    [dispatch]
  )
  const itemToRename = useSelector(getItemToRename)

  const setSelectedCollaborator = useCallback(
    (collaborator?: string) => {
      dispatch(actions.setSelectedCollaborator(collaborator))
      invalidateTags(['Collaboration'])
    },
    [dispatch, invalidateTags]
  )
  const selectedCollaborator = useSelector(getSelectedCollaborator)

  const folderHierarchy = useSelector(getFolderHierarchy)
  const onHierarchyClicked = useCallback(
    (index: number) => {
      if (index === 0) {
        dispatch(actions.setFolderHierarchy([folderHierarchy[0]]))
        return
      }
      const hierarchyCopy = [...folderHierarchy]
      hierarchyCopy.splice(index + 1)
      dispatch(actions.setFolderHierarchy(hierarchyCopy))
    },
    [dispatch, folderHierarchy]
  )
  const onFolderClicked = useCallback(
    (folder: IDriveChild) => {
      if (folderHierarchy[folderHierarchy.length - 1] === folder?.id) {
        return
      }
      dispatch(
        actions.setFolderHierarchy([
          ...folderHierarchy,
          { name: folder?.name, id: folder?.id }
        ])
      )
    },
    [dispatch, folderHierarchy]
  )

  const setDriveId = useCallback(
    (id?: string) => {
      dispatch(actions.setDriveId(id))
    },
    [dispatch]
  )
  const driveId = useSelector(getDriveId)

  return {
    setShowCreateFolder,
    showCreateFolder,
    setMenuItem,
    menuItem,
    setShowDeleteWarning,
    showDeleteWarning,
    setItemToRename,
    itemToRename,
    setShowUpload,
    showUpload,
    setSelectedCollaborator,
    selectedCollaborator,
    folderHierarchy,
    onHierarchyClicked,
    onFolderClicked,
    setDriveId,
    driveId
  }
}
