import { createReducer } from 'typesafe-actions'
import {
  editedGlTransactionDataActions,
  glTransactionDataActions,
  gwesTransCodesDataActions,
  LedgerPaymentsActionType,
  postGlPaymentsActions,
  reverseTransactionActions,
  updateTransactionActions
} from './actions'
import { GLTransactionRquest, IGLTransaction, IGWESTransCodes } from './types'

export interface ILedgerPaymentState {
  selectedInputs?: GLTransactionRquest
  glTransactionState: {
    payments?: IGLTransaction[]
    isLoading: boolean
    error?: Error
  }
  reverseTransactionState?: {
    inProgress: boolean
    error?: Error
    successMsg?: string
  }
  updateTransactionState?: {
    inProgress: boolean
    error?: Error
    successMsg?: string
  }
  gwesTransCodesState?: {
    transcodes?: IGWESTransCodes[]
    isLoading: boolean
    error?: Error
  }
  postGLTransactionState?: {
    inProgress: boolean
    error?: Error
    successMsg?: string
  }
  editedGLTransactionState?: {
    payments?: IGLTransaction[]
    isLoading: boolean
    error?: Error
  }
}

const initialState: ILedgerPaymentState = {
  glTransactionState: {
    isLoading: true
  }
}

export const ledgerPaymentReducer = createReducer<
  ILedgerPaymentState,
  LedgerPaymentsActionType
>(initialState)
  .handleAction(glTransactionDataActions.request, (state) => ({
    ...state,
    glTransactionState: {
      isLoading: true,
      error: undefined,
      payments: undefined
    }
  }))
  .handleAction(glTransactionDataActions.success, (state, action) => ({
    ...state,
    glTransactionState: {
      isLoading: false,
      error: undefined,
      payments: action.payload
    }
  }))
  .handleAction(glTransactionDataActions.failure, (state, action) => ({
    ...state,
    glTransactionState: {
      ...state.glTransactionState.payments,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(reverseTransactionActions.request, (state) => ({
    ...state,
    reverseTransactionState: {
      inProgress: true,
      successMsg: undefined,
      error: undefined
    }
  }))
  .handleAction(reverseTransactionActions.success, (state) => ({
    ...state,
    reverseTransactionState: {
      inProgress: false,
      successMsg: 'Transaction has been successfully reversed.',
      error: undefined
    }
  }))
  .handleAction(reverseTransactionActions.failure, (state, action) => ({
    ...state,
    reverseTransactionState: {
      inProgress: false,
      error: action.payload,
      successMsg: undefined
    }
  }))
  .handleAction(reverseTransactionActions.clear, (state) => ({
    ...state,
    reverseTransactionState: undefined
  }))
  .handleAction(updateTransactionActions.request, (state) => ({
    ...state,
    updateTransactionState: {
      inProgress: true,
      successMsg: undefined,
      error: undefined
    }
  }))
  .handleAction(updateTransactionActions.success, (state) => ({
    ...state,
    updateTransactionState: {
      inProgress: false,
      successMsg: 'Transaction has been successfully updated.',
      error: undefined
    }
  }))
  .handleAction(updateTransactionActions.failure, (state, action) => ({
    ...state,
    updateTransactionState: {
      inProgress: false,
      error: action.payload,
      successMsg: undefined
    }
  }))
  .handleAction(updateTransactionActions.clear, (state) => ({
    ...state,
    updateTransactionState: undefined
  }))
  .handleAction(gwesTransCodesDataActions.request, (state) => ({
    ...state,
    gwesTransCodesState: {
      isLoading: true,
      error: undefined,
      transcodes: undefined
    }
  }))
  .handleAction(gwesTransCodesDataActions.success, (state, action) => ({
    ...state,
    gwesTransCodesState: {
      isLoading: false,
      error: undefined,
      transcodes: action.payload
    }
  }))
  .handleAction(gwesTransCodesDataActions.failure, (state, action) => ({
    ...state,
    gwesTransCodesState: {
      ...state.gwesTransCodesState?.transcodes,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(postGlPaymentsActions.request, (state) => ({
    ...state,
    postGLTransactionState: {
      inProgress: true,
      successMsg: undefined,
      error: undefined
    }
  }))
  .handleAction(postGlPaymentsActions.success, (state) => ({
    ...state,
    postGLTransactionState: {
      inProgress: false,
      successMsg: 'Transactions has been successfully posted.',
      error: undefined
    }
  }))
  .handleAction(postGlPaymentsActions.failure, (state, action) => ({
    ...state,
    postGLTransactionState: {
      inProgress: false,
      error: action.payload,
      successMsg: undefined
    }
  }))
  .handleAction(postGlPaymentsActions.clear, (state) => ({
    ...state,
    postGLTransactionState: undefined
  }))
  .handleAction(editedGlTransactionDataActions.request, (state) => ({
    ...state,
    editedGLTransactionState: {
      isLoading: true,
      error: undefined,
      payments: undefined
    }
  }))
  .handleAction(editedGlTransactionDataActions.success, (state, action) => ({
    ...state,
    editedGLTransactionState: {
      isLoading: false,
      error: undefined,
      payments: action.payload
    }
  }))
  .handleAction(editedGlTransactionDataActions.failure, (state, action) => ({
    ...state,
    editedGLTransactionState: {
      ...state.editedGLTransactionState?.payments,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(editedGlTransactionDataActions.setInputs, (state, action) => ({
    ...state,
    selectedInputs: action.payload
  }))
