import { wrap } from 'comlink'
import { format } from 'date-fns'
import { downloadUrlAsFile } from 'shared/downloads'
import {
  AnnuityPPLIApiType,
  ExcelColumnConfigType,
  ExcelExportWorker,
  IExcelWorksheetProps
} from './worker'

export const exportToExcel = async (
  props: IExcelWorksheetProps<AnnuityPPLIApiType, ExcelColumnConfigType>[],
  exportLoading: () => void,
  exportDoneLoading: () => void,
  fileTitle: string
) => {
  exportLoading()
  try {
    const worker = new Worker(new URL('./worker.ts', import.meta.url))
    const { createAndDownloadExcelWorksheet } = wrap<ExcelExportWorker>(worker)

    const result = await createAndDownloadExcelWorksheet(props)

    const filename = `${fileTitle} ${format(new Date(), 'MM-dd-yyyy')}.xlsx`

    downloadUrlAsFile(result, filename)
  } catch (e: unknown) {
    console.error(e)
  }
  exportDoneLoading()
}
