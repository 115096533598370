export * from './Summary/Summary'
export * from './Annuities/Annuities'
export * from './Insurance/Insurance'
export * from './Holdings/Holdings'
export * from './PrivatePlacement/PrivatePlacement'

import type { IAnnuityPPLIContract, IAnnuityPPLIAssets } from 'api/datahub'
import type { IODataListState } from 'features/OdataList/store/odataListWithFacetsStore'
import { Reducer, combineReducers } from 'redux'
import type { IAsyncReducerState } from 'store/shared'

import {
  annuitiesExcelExportReducer,
  annuitiesExcelExportSagas
} from './Annuities/excelExportActionsAndSagas'
import {
  reducer as annuitiesReducer,
  sagas as annuitiesSagas
} from './Annuities/store'
import {
  holdingsExcelExportReducer,
  holdingsExcelExportSagas
} from './Holdings/excelExportActionsAndSagas'
import {
  reducer as underlyingHoldingsReducer,
  sagas as underlyingHoldingsSagas
} from './Holdings/store'
import {
  insuranceExcelExportReducer,
  insuranceExcelExportSagas
} from './Insurance/excelExportActionsAndSagas'
import {
  reducer as insuranceReducer,
  sagas as insuranceSagas
} from './Insurance/store'

interface IAnnuitiesAndInsuranceDashboardFeaturesState {
  features: {
    annuities: IODataListState<IAnnuityPPLIContract>
    annuitiesExcelExport: IAsyncReducerState<unknown, unknown>
    insurance: IODataListState<IAnnuityPPLIContract>
    insuranceExcelExport: IAsyncReducerState<unknown, unknown>
    holdings: IODataListState<IAnnuityPPLIAssets>
    holdingsExcelExport: IAsyncReducerState<unknown, unknown>
  }
}

const combinedAnnuitiesAndInsuranceReducers = combineReducers({
  annuities: annuitiesReducer,
  annuitiesExcelExport: annuitiesExcelExportReducer,
  insurance: insuranceReducer,
  insuranceExcelExport: insuranceExcelExportReducer,
  holdings: underlyingHoldingsReducer,
  holdingsExcelExport: holdingsExcelExportReducer
})

const annuitiesAndInsuranceDashboardFeaturesReducer: Reducer<IAnnuitiesAndInsuranceDashboardFeaturesState> =
  combineReducers({
    features: combinedAnnuitiesAndInsuranceReducers
  })

const annuitiesAndInsuranceDashboardFeaturesSagas = [
  ...annuitiesSagas,
  ...annuitiesExcelExportSagas,
  ...insuranceSagas,
  ...insuranceExcelExportSagas,
  ...underlyingHoldingsSagas,
  ...holdingsExcelExportSagas
]

export const annuitiesAndInsuranceModulesReducer = combineReducers({
  dashboard: annuitiesAndInsuranceDashboardFeaturesReducer
})

export const annuitiesAndInsuranceModulesSagas = [
  ...annuitiesAndInsuranceDashboardFeaturesSagas
]
