import { select } from 'redux-saga/effects'
import { call, put, takeLatest } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../../../store/shared/sagas'
import { getActiveClient } from '../../../../Dashboard/store/selectors'
import { IMatrixInfo } from '../../../../Dashboard/store/types'
import {
  fetchMainAccountListService,
  fetchPayeeListService,
  fetchPaymentTransactionsListService,
  fetchSubAccountListService
} from '../api/TransactionsListService'
import {
  fetchGLMainAccountsActions,
  fetchPayeesActions,
  fetchPaymentTransactionsListActions,
  fetchSubAccountsActions
} from './actions'

function* handleFetchPaymentTransactionsList(
  action: ReturnType<typeof fetchPaymentTransactionsListActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const paymentTransactionRequestPayload = action.payload
    const data = yield* call(
      fetchPaymentTransactionsListService,
      paymentTransactionRequestPayload,
      options
    )
    yield put(fetchPaymentTransactionsListActions.success(data))
  } catch (e: any) {
    yield put(fetchPaymentTransactionsListActions.failure(e))
  }
}

function* handleFetchPayeesList() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const activeClient: IMatrixInfo = yield select(getActiveClient)
    const data = yield* call(fetchPayeeListService, options, activeClient)
    yield put(fetchPayeesActions.success(data))
  } catch (e: any) {
    yield put(fetchPayeesActions.failure(e))
  }
}

function* handleFetchGLMainAccountsList() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(fetchMainAccountListService, options)
    yield put(fetchGLMainAccountsActions.success(data))
  } catch (e: any) {
    yield put(fetchGLMainAccountsActions.failure(e))
  }
}

function* handleFetchSubAccountsList() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const activeClient: IMatrixInfo = yield select(getActiveClient)
    const data = yield* call(fetchSubAccountListService, options, activeClient)
    yield put(fetchSubAccountsActions.success(data))
  } catch (e: any) {
    yield put(fetchSubAccountsActions.failure(e))
  }
}

export const sagas = [
  () =>
    takeLatest(
      fetchPaymentTransactionsListActions.request,
      handleFetchPaymentTransactionsList
    ),
  () =>
    takeLatest(
      fetchGLMainAccountsActions.request,
      handleFetchGLMainAccountsList
    ),
  () => takeLatest(fetchSubAccountsActions.request, handleFetchSubAccountsList),
  () => takeLatest(fetchPayeesActions.request, handleFetchPayeesList)
]
