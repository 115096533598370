import { IModuleDefinition } from 'modules/types'
import { combineReducers } from 'redux'
import { BalancesContainer } from './BalancesContainer'
import { balanceSummaryDetailsUiStateReducer } from './balanceSummaryDetailsUIState'

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/balances/*',
    private: true,
    component: BalancesContainer
  },
  name: 'Balances'
}

export const rdot360BalanceReducer = combineReducers({
  balanceSummaryDetailsUiState: balanceSummaryDetailsUiStateReducer
})

export default moduleDefinition
