import { Dialog } from '@fluentui/react'
import { useEffect, useState, useCallback } from 'react'
import { buttonStyles } from '../../components/shared/Buttons'
import { inputClasses } from '../../components/shared/Inputs'
import { SnackBar } from '../../components/shared/Snackbar'
import { useRenameItemMutation } from '../../store/graph'
import { useCollaborationUiState } from './CollaborationUIState'

export const ItemRenameDialog: React.FC<{
  initialValue?: string
}> = ({ initialValue }) => {
  const { itemToRename, setItemToRename, driveId } = useCollaborationUiState()
  const [name, setName] = useState(initialValue)
  const [rename, renameResult] = useRenameItemMutation()
  const { error, isSuccess } = renameResult
  useEffect(() => {
    if (isSuccess) {
      setItemToRename(undefined)
    }
  }, [isSuccess, setItemToRename])

  const onApply = useCallback(() => {
    rename({ driveId, itemId: itemToRename, name })
  }, [rename, driveId, itemToRename, name])

  return (
    <Dialog
      hidden={!itemToRename}
      modalProps={{
        isBlocking: true
      }}
      dialogContentProps={{
        title: 'Create a New Secure Folder',
        styles: {
          title: {
            fontSize: '18px',
            fontWeight: 700,
            color: '#13272B',
            padding: '18px 30px'
          },
          header: {
            borderBottom: '1px solid rgba(0,0,0,0.1)'
          },
          content: { width: '550px' }
        }
      }}
      minWidth={'550px'}
      onDismiss={() => setItemToRename(undefined)}
    >
      <div css={{ padding: '17px 0' }}>
        <label css={inputClasses.label}>Name</label>
        <input
          css={[inputClasses.input, { width: '490px' }]}
          placeholder="Enter name"
          value={name}
          onChange={(ev) => setName(ev.target.value)}
        />
      </div>
      {error ? (
        <SnackBar
          type="Failure"
          message={(error as Error)?.message || 'An unknown error occurred'}
        />
      ) : null}
      <div
        css={{
          padding: '40px 0 20px',
          display: 'flex',
          justifyContent: 'center',
          gridColumnGap: 5
        }}
      >
        <button
          onClick={() => setItemToRename(undefined)}
          css={[buttonStyles.secondary, { width: '100px' }]}
        >
          Cancel
        </button>
        <button
          onClick={onApply}
          css={[
            buttonStyles.primary,
            {
              width: '100px',
              cursor: !name ? 'default' : 'pointer'
            }
          ]}
          disabled={!name}
        >
          Update
        </button>
      </div>
    </Dialog>
  )
}
