import { ITheme } from '@fluentui/react'
import { constants } from 'modules/Advisory/shared/theme'

export const getThemedClasses = (theme: ITheme) => ({
  summaryTable: {
    tableLayout: 'auto',
    minWidth: 0,
    width: '100%',
    boxSizing: 'border-box',
    borderSpacing: '0',
    '@media print': {
      breakInside: 'auto',
      'tr, th, td': {
        breakInside: 'avoid',
        breakAfter: 'auto'
      },
      thead: {
        display: 'table-header-group'
      }
    },
    th: {
      textAlign: 'left'
    },
    'th.right, td.right': {
      textAlign: 'right'
    },
    '.row > td, th': {
      padding: '3px 8px',
      borderRight: `solid 1px ${theme.palette.neutralLight}`,
      backgroundColor: theme.palette.white
    },
    '.row > td': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    '.row > td:nth-child(2), .row > th:nth-child(2)': {
      textAlign: 'left',
      position: 'sticky',
      left: '126px',
      zIndex: 3
    },
    '.row > td:first-child, .row > th:first-child': {
      textAlign: 'left',
      position: 'sticky',
      left: '94px',
      zIndex: 2
    },
    '.row:nth-child(even) td': {
      backgroundColor: theme.palette.neutralLighterAlt
    },
    '.row:nth-child(odd) td': {
      backgroundColor: theme.palette.white
    },
    '.row td:last-child': {
      borderRight: 'none'
    },
    '.row:last-child td': {
      borderBottom: 'none'
    },
    '.row:hover td': {
      backgroundColor: theme.palette.neutralLighter
    },
    '.row.group-row td': {
      padding: '8px 8px',
      backgroundColor: theme.palette.neutralLight,
      fontWeight: '600',
      borderTop: `solid 2px ${theme.palette.white}`
    },
    '.row .assets': {
      boxShadow: '0 0 20px rgba(70, 50, 20, .15) inset'
    }
  },
  tableHeader: {
    'tr:first-child th': {
      borderRight: 'none'
    },
    th: {
      verticalAlign: 'bottom'
    },
    thead: {
      '@media screen': {
        zIndex: 4,
        position: 'sticky',
        left: 0,
        top: constants.headerHeightPx
      }
    },
    'thead .row th': {
      borderBottom: `solid 3px ${theme.palette.neutralDark}`,
      paddingTop: '5px',
      paddingBottom: '5px',
      fontWeight: 'bold',
      whiteSpace: 'nowrap'
    }
  },
  tableFooter: {
    tfoot: {
      '@media screen': {
        zIndex: 4,
        backgroundColor: theme.palette.white,
        position: 'sticky',
        bottom: 0
      },
      '.row > td': {
        borderTop: `solid 1px ${theme.palette.neutralDark}`,
        fontWeight: 'bold',
        borderRight: 'none',
        paddingBottom: '16px'
      },
      '.row:hover > td': {
        backgroundColor: 'unset'
      }
    }
  }
})
