import { IListsUiState } from 'features/Lists/core/contracts/IListsUIState'
import { ISystemViewDefinition } from 'features/Lists/hooks/useListPreferences'
import { constructInitialPositionListColumnState } from 'features/Lists/positions'
import { PositionColumnIds } from 'features/Lists/positions/ColumnDefinitions'
import { getAiDashboardDefaultViewFilters } from '../getListFilters'

const initialColumns: (keyof PositionColumnIds)[] = [
  'householdName',
  'accountNumber',
  'partyname',
  'symbolCusipDesc',
  'securitySubtype',
  'assetTypeDetail',
  'vintageYear',
  'capitalCommitment',
  'CapitalCall',
  'Unfunded_Value',
  'Distributions',
  'ai_lastValuationValue',
  'ValuationDate',
  'ai_valuationReceivedDate',
  'AdjustedMarketValue',
  'AdjustedTotalValue',
  'AdjustedTVPI',
  'NetIRRAnnualized',
  'NetIRRCumulative'
]

const defaultUiState: IListsUiState = {
  searchText: '',
  columnState: constructInitialPositionListColumnState(initialColumns),
  filters: getAiDashboardDefaultViewFilters(),
  orderBy: { columnId: PositionColumnIds.capitalCommitment, direction: 'desc' }
}

export const drawdownSystemViews = [
  {
    key: 'DRAWDOWN_DEFAULT_SYSTEM_VIEW',
    label: 'Default System View',
    config: defaultUiState
  }
] as ISystemViewDefinition[]
