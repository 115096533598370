import { combineReducers } from 'redux'
import { feeDashboardModuleReducer, feeDashboardModuleSagas } from './Dashboard'
import {
  managedAccountFeeHistoryModuleReducer,
  managedAccountFeeHistoryModuleSagas
} from './ManagedAccountFeeHistory'
import {
  platformFeeHistoryModuleReducer,
  platformFeeHistoryModuleSagas
} from './PlatformFeeHistory/store'

export const feesModulesReducer = combineReducers({
  managedAccountFeeHistory: managedAccountFeeHistoryModuleReducer,
  dashboard: feeDashboardModuleReducer,
  platformFeeHistory: platformFeeHistoryModuleReducer
})

export const feesModulesSagas = [
  ...managedAccountFeeHistoryModuleSagas,
  ...feeDashboardModuleSagas,
  ...platformFeeHistoryModuleSagas
]
