import { SearchResponseType } from '../../../../api/common.types'
import { IListsCellComponent } from '../components/ListsDataTable'
import { createConnectedListsFilterStatusList } from '../features/filter/containers/ListsFilterStatusList'
import { IListsActions } from '../store/actions'
import { IListsSelectors } from '../store/selectors'
import { createConnectedListsCommandBar } from './ListsCommandBar'
import { createConnectedListsInfiniteScrollDataTable } from './ListsInfiniteScrollDataTable'
import { createConnectedListsProgess } from './ListsProgress'
import { createConnectedListsResultsCount } from './ListsResultsCount'
import { createConnectedListsSearchComponent } from './ListsSearch'

export type ListsComponents = ReturnType<typeof createListsComponents>
export const createListsComponents = <T extends SearchResponseType>(
  listActions: IListsActions<T>,
  listSelectors: IListsSelectors<T>,
  cell: IListsCellComponent<T>
) => {
  const ListsFilterStatusList = createConnectedListsFilterStatusList(
    listActions,
    listSelectors
  )

  const ListsSearch = createConnectedListsSearchComponent(
    listActions,
    listSelectors
  )

  const ListsProgress = createConnectedListsProgess(listSelectors)

  const ListsResultsCount = createConnectedListsResultsCount(listSelectors)

  const ListsCommandBar = createConnectedListsCommandBar(
    listActions,
    listSelectors
  )

  const ListsInfiniteScrollDataTable =
    createConnectedListsInfiniteScrollDataTable(
      listActions,
      listSelectors,
      cell
    )

  return {
    ListsFilterStatusList,
    ListsSearch,
    ListsProgress,
    ListsResultsCount,
    ListsCommandBar,
    ListsInfiniteScrollDataTable
  }
}
