import { IColumn } from '@fluentui/react'
import React, { useMemo } from 'react'
import { IAccount } from '../../api/account.types'
import { DataTable } from '../../shared/components/DataTable'
import { TextCell } from '../Lists/core/components/TextCell'
import { USDCell } from '../Lists/core/components/USDCell'
import { AccountNumberCell } from '../Lists/shared/AccountNumberCell'

const defaultColumn: IColumn = {
  key: '',
  name: '',
  isResizable: true,
  minWidth: 80,
  maxWidth: 130
}

export const SmallAccountList: React.FC<{
  accounts?: IAccount[]
  loading?: boolean
}> = ({ accounts = [], loading = false }) => {
  const columns: IColumn[] = useMemo(
    () => [
      {
        ...defaultColumn,
        name: 'Account',
        key: 'account',
        maxWidth: 175,
        onRender: (account: IAccount) => <AccountNumberCell account={account} />
      },
      {
        ...defaultColumn,
        key: 'name',
        name: 'Legal Entity Name',
        maxWidth: 220,
        onRender: ({ LegalEntityName }: IAccount) => (
          <TextCell>{LegalEntityName}</TextCell>
        )
      },
      {
        ...defaultColumn,
        key: 'rev',
        className: 'align-right',
        headerClassName: 'align-right',
        maxWidth: 110,
        name: 'T12 Revenue',
        onRender: ({ revenueDet }: IAccount) => (
          <USDCell value={revenueDet?.ttmrevenue} fractionDigits={0} />
        )
      },
      {
        ...defaultColumn,
        key: 'aus',
        className: 'align-right',
        headerClassName: 'align-right',
        name: 'AUS',
        onRender: ({ AccountKPIs }: IAccount) => (
          <USDCell value={AccountKPIs?.AccountTotal} fractionDigits={0} />
        )
      }
    ],
    []
  )
  return (
    <DataTable
      columns={columns}
      items={accounts}
      enableShimmer={loading}
      shimmerLines={5}
    />
  )
}
