import { ITheme, mergeStyleSets, Stack, Text } from '@fluentui/react'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../store'
import { getTheme } from '../../store/ui/selectors'

export interface ILoadingComponentOwnProps {
  isLoading?: boolean
  style?: React.CSSProperties
  status?: string
}

interface IConnectedProps {
  theme: ITheme
}

type props = ILoadingComponentOwnProps & IConnectedProps

const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    loadingDot: {
      backgroundColor: theme.palette.themeDark
    }
  })
}

export class Component extends PureComponent<props> {
  public render() {
    const { isLoading, theme, status } = this.props
    const classNames = getClassNames(theme)
    const shouldShowLoading = isLoading == null || isLoading === true
    if (!shouldShowLoading) {
      return null
    }

    const dotClassName = ['loading-dots--dot', classNames.loadingDot].join(' ')
    return (
      <Stack
        verticalFill={true}
        verticalAlign="center"
        horizontalAlign="center"
      >
        <div className="loading-dots">
          <div className={dotClassName} />
          <div className={dotClassName} />
          <div className={dotClassName} />
        </div>
        {status && (
          <Text styles={{ root: { fontWeight: 'bold' } }}>{status}</Text>
        )}
      </Stack>
    )
  }
}

export const LoadingComponent = connect<
  IConnectedProps,
  void,
  ILoadingComponentOwnProps,
  AppState
>((state: AppState, ownProps: ILoadingComponentOwnProps): props => {
  return {
    ...ownProps,
    theme: getTheme(state)
  }
})(Component as any)
