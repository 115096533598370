import { get } from 'lodash'
import { b64toBlob } from 'modules/Products/features/store/utility'
import { exportDataToExcel } from 'shared/xlsx'
import { call, delay, put, takeLatest } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../store/shared/sagas'
import {
  downloadTPMDocService,
  fetchAdvisoryBusinessService,
  fetchTPMFirmpdfService,
  generateTPMPDFService
} from '../api/thirdPartyManagerService'
import { IExportSheets } from '../api/types/types'
import {
  downloadTPMPDFActions,
  exportTPMToExcelActions,
  fetchAdvisoryBusinessActions,
  fetchTPMFirmPDFActions,
  generateTPMPDFActions
} from './actions'

function* handleFetchAdvisoryBusinessService(
  action: ReturnType<typeof fetchAdvisoryBusinessActions.request>
) {
  try {
    yield delay(300)
    const options = yield* call(getRockefellerApiOptions)
    const response = yield* call(() =>
      fetchAdvisoryBusinessService(action.payload, options)
    )
    if (response?.success) {
      const data = response?.data && response?.data[0]
      yield put(fetchAdvisoryBusinessActions.success(data))
    }
  } catch (e: any) {
    yield put(fetchAdvisoryBusinessActions.failure(e))
  }
}

function* handleExportTPMToExcel(
  action: ReturnType<typeof exportTPMToExcelActions.request>
) {
  try {
    const { sheetsData, filename } = action.payload
    const sheets: IExportSheets[] = sheetsData?.map((item) => {
      const wsName = item.wsName
      const data = item.sheetData?.map((plan) => {
        const excelPlan = {
          ...plan
        }
        return item.columns?.map((column): unknown =>
          column.fieldName ? get(excelPlan, column.fieldName) : ''
        )
      })
      data?.unshift(
        item.columns?.filter((x) => x.key !== 'details')?.map((x) => x.name)
      )
      return {
        name: wsName,
        data: data
      } as IExportSheets
    })

    yield call(() =>
      exportDataToExcel(
        {
          sheets: sheets
        },
        filename
      )
    )
    yield put(exportTPMToExcelActions.success())
  } catch (e: any) {
    yield put(exportTPMToExcelActions.failure(e))
  }
}

function* handleGeneratePDF(
  action: ReturnType<typeof generateTPMPDFActions.request>
) {
  try {
    yield delay(300)
    const options = yield* call(getRockefellerApiOptions)
    const response = yield* call(() =>
      generateTPMPDFService(action.payload, options)
    )
    if (response.success) {
      yield put(generateTPMPDFActions.success(response.data[0]))
    } else {
      yield put(
        generateTPMPDFActions.failure(new Error('Unable to generate PDF'))
      )
    }
  } catch (e: any) {
    yield put(generateTPMPDFActions.failure(e))
  }
}

function* handleDownloadPDF(
  action: ReturnType<typeof downloadTPMPDFActions.request>
) {
  const options = yield* call(getRockefellerApiOptions)

  try {
    const data = yield* call(() =>
      downloadTPMDocService(action.payload, options)
    )
    if (data.success) {
      yield put(downloadTPMPDFActions.success())
      if (data?.data) {
        const blob = b64toBlob(data.data[0])
        const fileURL = URL.createObjectURL(blob)
        window.open(fileURL, 'DocumentWindow')
      }
    }
  } catch (e: any) {
    yield put(downloadTPMPDFActions.failure(e))
  }
}

function* handleFetchTPMFirmPDFService(
  action: ReturnType<typeof fetchTPMFirmPDFActions.request>
) {
  try {
    yield delay(300)
    const options = yield* call(getRockefellerApiOptions)
    const response = yield* call(() =>
      fetchTPMFirmpdfService(action.payload, options)
    )
    if (response?.success) {
      yield put(fetchTPMFirmPDFActions.success(response?.data))
    } else {
      yield put(
        fetchTPMFirmPDFActions.failure(new Error(response.responseMessage))
      )
    }
  } catch (e: any) {
    yield put(fetchTPMFirmPDFActions.failure(e))
  }
}

export const sagas = [
  () =>
    takeLatest(
      fetchAdvisoryBusinessActions.request,
      handleFetchAdvisoryBusinessService
    ),
  () => takeLatest(exportTPMToExcelActions.request, handleExportTPMToExcel),
  () => takeLatest(generateTPMPDFActions.request, handleGeneratePDF),
  () => takeLatest(downloadTPMPDFActions.request, handleDownloadPDF),
  () => takeLatest(fetchTPMFirmPDFActions.request, handleFetchTPMFirmPDFService)
]
