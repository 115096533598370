import { css } from '@emotion/react'
import { Header, Row } from '@tanstack/react-table'
import { Icon } from 'modules/Advisory/modules/Rdot360/features/Icons/Icon'
import { constants } from 'modules/Advisory/modules/Rdot360/shared/theme'
import {
  IClosedLotExtended,
  IClosedLotSummaryItem
} from 'modules/Advisory/modules/Rdot360/store/types'
import { FC, Fragment } from 'react'
import { FooterCell, HeaderCell, TableCell } from './cells'

const classes = {
  tableBase: css({
    tableLayout: 'fixed',
    width: '100%',
    boxSizing: 'border-box',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    marginBottom: 8,
    'th, td': {
      ':first-of-type': {
        padding: 10,
        width: 36
      },
      ':last-of-type': {
        paddingRight: 8
      },
      ':nth-of-type(2)': {
        width: 240
      }
    }
  }),
  tableHeader: css({
    backgroundColor: '#dfe8ee',
    minWidth: 1400,
    position: 'sticky',
    top: constants.blueBarHeight + constants.headerHeight,
    th: {
      verticalAlign: 'top',
      padding: '10px 0',
      textAlign: 'right'
    }
  }),
  tableContent: css({
    tbody: {
      border: '1px solid #E6E6E6',
      tr: {
        ':first-of-type': {
          backgroundColor: '#EBF6FC',
          fontWeight: 700
        },
        '&.headerRow': {
          borderTop: '1px solid #E6E6E6',
          fontWeight: 600
        }
      },
      td: {
        color: '#080808',
        verticalAlign: 'top',
        padding: '8px 0',
        textAlign: 'right',
        ':nth-of-type(2)': {
          textAlign: 'left'
        },
        '&:not(:nth-of-type(2))': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      }
    }
  }),
  tableFooter: css({
    backgroundColor: '#cde2f2',
    position: 'sticky',
    bottom: 0,
    td: {
      fontWeight: 'bold',
      padding: '20px 0',
      textAlign: 'right',
      ':nth-of-type(2)': {
        textAlign: 'left'
      }
    }
  })
}

interface IGainLossHeadingProp {
  headers: Header<IClosedLotExtended, unknown>[]
}

export const GainLossHeading: FC<IGainLossHeadingProp> = ({ headers }) => (
  <table css={[classes.tableBase, classes.tableHeader]}>
    <thead>
      <tr>
        <th />
        {headers?.map((header, i) =>
          !i ? null : (
            <th key={i}>
              {i === 1 && <HeaderCell header={headers[0]} />}
              <HeaderCell header={header} cellIndex={i} />
            </th>
          )
        )}
      </tr>
    </thead>
  </table>
)

export const GainLossFooter: FC<IGainLossHeadingProp> = ({ headers }) => (
  <table css={[classes.tableBase, classes.tableFooter]}>
    <tfoot>
      <tr>
        <td />
        <td>Total</td>
        {headers?.map((header, i) =>
          i < 2 ? null : <FooterCell key={i} header={header} />
        )}
      </tr>
    </tfoot>
  </table>
)

interface IGainLossBodyProp {
  rows: Row<IClosedLotSummaryItem>[]
}

export const GainLossBody: FC<IGainLossBodyProp> = ({ rows }) =>
  rows.map((row) => {
    const visibleCells = row.getVisibleCells()
    const canRowExpand = row.getCanExpand()
    const isExpanded = row.getIsExpanded()
    const rowDepth = row.depth
    const iconName = isExpanded ? 'Subtract' : 'Add'

    return rowDepth !== 0 ? null : (
      <table key={row.id} css={[classes.tableBase, classes.tableContent]}>
        <tbody>
          <tr>
            <td>
              {canRowExpand && (
                <Icon
                  type={iconName}
                  width={14}
                  height={14}
                  color="black"
                  onClick={row.getToggleExpandedHandler()}
                />
              )}
            </td>
            {visibleCells.map((cell) => {
              if (cell.getIsPlaceholder()) {
                return null
              }
              return <TableCell key={cell.id} cell={cell} />
            })}
          </tr>

          {isExpanded &&
            row.subRows.map((subRow, i) => {
              const subVisibleCells = subRow.getVisibleCells()
              const isSubRowExpanded = subRow.getIsExpanded()
              const canSubRowExpand = subRow.getCanExpand()
              const iconName = isSubRowExpanded ? 'Subtract' : 'Add'
              const isEven = i % 2 === 0
              const subRowStyles = css({
                backgroundColor: isEven ? '#FFFFFF' : '#FAFAFA'
              })

              return (
                <Fragment key={subRow.id}>
                  <tr className="headerRow" css={subRowStyles}>
                    <td>
                      {canSubRowExpand && (
                        <Icon
                          type={iconName}
                          width={12}
                          height={12}
                          color="gray"
                          onClick={subRow.getToggleExpandedHandler()}
                        />
                      )}
                    </td>
                    {subVisibleCells.map((cell) => {
                      return cell.getIsPlaceholder() ? null : (
                        <TableCell key={cell.id} cell={cell} />
                      )
                    })}
                  </tr>

                  {isSubRowExpanded &&
                    subRow.subRows.map((subSubRow) => {
                      const subSubVisibleCells = subSubRow.getVisibleCells()

                      return (
                        <tr key={subSubRow.id} css={subRowStyles}>
                          <td />
                          <td />
                          {subSubVisibleCells.map((cell) => {
                            return cell.getIsPlaceholder() ? null : (
                              <TableCell key={cell.id} cell={cell} />
                            )
                          })}
                        </tr>
                      )
                    })}
                </Fragment>
              )
            })}
        </tbody>
      </table>
    )
  })
