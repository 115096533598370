import { call, put, takeLatest } from 'typed-redux-saga'
import {
  getMicrosoftGraphApiOptions,
  getRockefellerApiOptions
} from '../../../../../../../store/shared/sagas'
import {
  deletePaymentApproversService,
  fetchPaymentApproversService,
  fetchUsersService,
  submitPaymentApproverService
} from '../api/PaymentApproversService'
import {
  deletePaymentApproverActions,
  fetchPaymentApproversActions,
  fetchUserActions,
  submitPaymentApproverActions
} from './actions'
import {
  IPaymentApprover,
  IPaymentApproverResponse,
  ISearchUserResponse
} from './types'

function* handleFetchUsers(
  action: ReturnType<typeof fetchUserActions.request>
) {
  try {
    const options = yield* call(getMicrosoftGraphApiOptions)
    const data: ISearchUserResponse = yield call(() =>
      fetchUsersService(action.payload, options)
    )
    yield put(fetchUserActions.success(data?.value || []))
  } catch (e: any) {
    yield put(fetchUserActions.failure(e))
  }
}

function* handleFetchPaymentApprovers() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: IPaymentApproverResponse[] = yield call(() =>
      fetchPaymentApproversService(options)
    )
    yield put(
      fetchPaymentApproversActions.success(
        data?.map(
          (x) =>
            ({
              givenName: x?.firstname,
              surname: x?.lastname,
              wsportaluserid: x?.wsportaluserid,
              mail: x?.emailid,
              id: x?.approverid
            } as IPaymentApprover)
        ) || []
      )
    )
  } catch (e: any) {
    yield put(fetchPaymentApproversActions.failure(e))
  }
}

function* handleDeletePaymentApprover(
  action: ReturnType<typeof deletePaymentApproverActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    yield call(() => deletePaymentApproversService(action.payload, options))
    yield put(deletePaymentApproverActions.success())
    yield put(fetchPaymentApproversActions.request())
  } catch (e: any) {
    yield put(deletePaymentApproverActions.failure(e))
  }
}

function* handleSubmitApprover(
  action: ReturnType<typeof submitPaymentApproverActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    yield call(() => submitPaymentApproverService(action.payload, options))
    yield put(submitPaymentApproverActions.success())
    yield put(fetchPaymentApproversActions.request())
  } catch (e: any) {
    yield put(submitPaymentApproverActions.failure(e))
  }
}

export const sagas = [
  () => takeLatest(fetchUserActions.request, handleFetchUsers),
  () =>
    takeLatest(
      fetchPaymentApproversActions.request,
      handleFetchPaymentApprovers
    ),
  () =>
    takeLatest(
      deletePaymentApproverActions.request,
      handleDeletePaymentApprover
    ),
  () => takeLatest(submitPaymentApproverActions.request, handleSubmitApprover)
]
