import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { useClientDashboardCommonPreferences } from '../../hooks/useClientDashboardPreferences'

interface ISidePanelState {
  showManageAccountsModal?: boolean
}

const initialState = {
  showManageAccountsModal: false
} as ISidePanelState

const sidePanelFeatureSlice = createSlice({
  name: '@modules/@rdot360/@features/@accountSelector',
  initialState,
  reducers: {
    setShowManageAccountsModal: (state, action: PayloadAction<boolean>) => ({
      ...state,
      showManageAccountsModal: action.payload
    })
  }
})

export const { reducer: sidePanelFeatureReducer } = sidePanelFeatureSlice
const { actions } = sidePanelFeatureSlice

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.features.sidePanel

export const useSidePanelStore = () => {
  const dispatch = useDispatch()
  const { showManageAccountsModal } = useSelector(rootSelector)
  const { preferences, setPreferences } = useClientDashboardCommonPreferences()

  const isOpen = useMemo(
    () => preferences?.isSidePanelOpen ?? true,
    [preferences]
  )

  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      setPreferences({ isSidePanelOpen: isOpen })
    },
    [setPreferences]
  )

  const setShowManageAccountsModal = useCallback(
    (isOpen: boolean) => {
      dispatch(actions.setShowManageAccountsModal(isOpen))
    },
    [dispatch]
  )

  return {
    isOpen,
    setIsOpen,
    showManageAccountsModal,
    setShowManageAccountsModal
  }
}
