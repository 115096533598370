import { Dialog } from '@fluentui/react'
import { useCallback, useMemo, useState } from 'react'
import { buttonStyles } from '../../components/shared/Buttons'
import { inputClasses } from '../../components/shared/Inputs'
import { SnackBar } from '../../components/shared/Snackbar'
import { useAddFolderMutation } from '../../store/graph'
import { useCollaborationUiState } from './CollaborationUIState'

export const CreateFolderDialog: React.FC<{
  driveId?: string
  folderId?: string
  groupId?: string
}> = ({ driveId, folderId, groupId }) => {
  const [addFolder, addFolderResult] = useAddFolderMutation()
  const { isSuccess, error } = addFolderResult
  const [folderName, setFolderName] = useState('')
  const { showCreateFolder, setShowCreateFolder } = useCollaborationUiState()
  useMemo(() => {
    if (isSuccess) {
      setFolderName('')
      setShowCreateFolder(false)
    }
  }, [setShowCreateFolder, isSuccess])

  const onAddFolder = useCallback(() => {
    addFolder({
      driveId,
      folderId,
      groupId,
      name: folderName
    })
  }, [addFolder, driveId, folderName, folderId, groupId])

  return (
    <Dialog
      hidden={!showCreateFolder}
      modalProps={{
        isBlocking: true
      }}
      dialogContentProps={{
        title: 'Create a New Folder',
        styles: {
          title: {
            fontSize: '18px',
            fontWeight: 700,
            color: '#13272B',
            padding: '18px 30px'
          },
          header: {
            borderBottom: '1px solid rgba(0,0,0,0.1)'
          },
          content: { width: '550px' }
        }
      }}
      minWidth={'550px'}
      onDismiss={() => setShowCreateFolder(false)}
    >
      <div css={{ padding: '17px 0' }}>
        <label css={inputClasses.label}>Folder Name</label>
        <input
          css={[inputClasses.input, { width: '490px' }]}
          placeholder="Enter your folder name"
          value={folderName}
          onChange={(ev) => setFolderName(ev.target.value)}
        />
      </div>
      {error ? (
        <SnackBar
          type="Failure"
          message={(error as Error)?.message || 'An unknown error occurred'}
        />
      ) : null}
      <div
        css={{
          padding: '40px 0 20px',
          display: 'flex',
          justifyContent: 'center',
          gridColumnGap: 5
        }}
      >
        <button
          onClick={() => setShowCreateFolder(false)}
          css={[buttonStyles.secondary, { width: '100px' }]}
        >
          Cancel
        </button>
        <button
          onClick={onAddFolder}
          css={[
            buttonStyles.primary,
            {
              width: '100px',
              cursor: !folderName ? 'default' : 'pointer'
            }
          ]}
          disabled={!folderName}
        >
          Create
        </button>
      </div>
    </Dialog>
  )
}
