import { ColumnDef, Row } from '@tanstack/react-table'
import { CellValue } from 'exceljs'
import { uniq } from 'lodash'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { isNotNullOrUndefined } from 'shared/guards'
import { IClosedLotExtended } from '../../../../store/types'
import { GainLossDetailColumnNames } from '../../shared'
import {
  GainLossDateCell,
  GainLossQuantityCell,
  GainLossUSDCellWithColor,
  GainLossUSDCell,
  GainLossAccountNumberCell,
  SymbolDescriptionCell,
  GainLossAggregatedDateCell,
  TotalValueFooter,
  GainLossAggregatedUniqueUSDCell,
  GainLossAggregatedUSDCellWithColor
} from './cells'

type ColumnDefExtended<TData extends object> = ColumnDef<TData> & {
  getExportValue?: (item: IClosedLotExtended) => Partial<CellValue>
}

const getUniqueValue = (
  columnId: string,
  leafRows: Row<IClosedLotExtended>[]
) => {
  const uniqueValues = uniq(
    leafRows
      .map((x) => x.getValue<number>(columnId))
      .filter(isNotNullOrUndefined)
  )

  return uniqueValues.length === 1 ? uniqueValues[0] : undefined
}

export const getColumnDefinition = (
  isAccountL1?: boolean,
  isMasked?: boolean
): ColumnDefExtended<IClosedLotExtended>[] => {
  const columns: ColumnDefExtended<IClosedLotExtended>[] = [
    {
      header: GainLossDetailColumnNames.quantity,
      accessorFn: ({ NUMSHRS }) => NUMSHRS,
      getExportValue: ({ NUMSHRS }) => NUMSHRS,
      cell: GainLossQuantityCell,
      aggregationFn: 'sum',
      aggregatedCell: undefined
    },
    {
      header: GainLossDetailColumnNames.symbolDescription,
      accessorFn: ({ SECNAME }) => SECNAME,
      getExportValue: ({ SECNAME }) => SECNAME,
      cell: SymbolDescriptionCell
    },
    {
      header: GainLossDetailColumnNames.account,
      accessorFn: ({ ACCTKEY }) => ACCTKEY,
      getExportValue: ({ ACCTKEY }) => ACCTKEY,
      cell: (props) => (
        <GainLossAccountNumberCell {...props} masked={isMasked} />
      )
    },
    {
      header: GainLossDetailColumnNames.totalGainLoss,
      accessorFn: ({ PROFIT }) => PROFIT,
      getExportValue: ({ PROFIT }) => PROFIT,
      cell: GainLossUSDCellWithColor,
      aggregationFn: 'sum',
      aggregatedCell: (props) =>
        GainLossAggregatedUSDCellWithColor(props, {
          addPercentageChangeCOSTBASIS: true
        }),
      footer: (props) => TotalValueFooter(props, { isUsdWithColor: true })
    },
    {
      header: GainLossDetailColumnNames.shortTermGainLoss,
      accessorFn: ({ SHRTGAINLOSS }) => SHRTGAINLOSS,
      getExportValue: ({ SHRTGAINLOSS }) => SHRTGAINLOSS,
      cell: GainLossUSDCellWithColor,
      aggregationFn: 'sum',
      aggregatedCell: GainLossAggregatedUSDCellWithColor,
      footer: (props) => TotalValueFooter(props, { isUsdWithColor: true })
    },
    {
      header: GainLossDetailColumnNames.longTermGainLoss,
      accessorFn: ({ LNGGAINLOSS }) => LNGGAINLOSS,
      getExportValue: ({ LNGGAINLOSS }) => LNGGAINLOSS,
      cell: GainLossUSDCellWithColor,
      aggregationFn: 'sum',
      aggregatedCell: undefined,
      footer: (props) => TotalValueFooter(props, { isUsdWithColor: true })
    },
    {
      header: GainLossDetailColumnNames.acquisitionDate,
      accessorFn: ({ DTAQD }) => DTAQD,
      getExportValue: ({ DTAQD }) =>
        DTAQD && parseDateISOStringInLocalTimezone(DTAQD),
      cell: GainLossDateCell,
      aggregatedCell: GainLossAggregatedDateCell,
      aggregationFn: 'unique',
      sortingFn: 'datetime'
    },
    {
      header: GainLossDetailColumnNames.acquisitionPrice,
      accessorFn: ({ COSTBASIS, NUMSHRS }) => {
        const isValid = COSTBASIS != null && NUMSHRS != null
        return isValid ? COSTBASIS / NUMSHRS : null
      },
      getExportValue: ({ COSTBASIS, NUMSHRS }) => {
        const isValid = COSTBASIS != null && NUMSHRS != null
        const value = isValid ? COSTBASIS / NUMSHRS : null
        return (value && isNaN(value)) || value === Infinity ? null : value
      },
      cell: GainLossUSDCell,
      aggregationFn: getUniqueValue,
      aggregatedCell: (props) =>
        GainLossAggregatedUniqueUSDCell(props, { isAccountNotApplicable: true })
    },
    {
      header: GainLossDetailColumnNames.acquisitionCost,
      accessorFn: ({ COSTBASIS }) => COSTBASIS,
      getExportValue: ({ COSTBASIS }) => COSTBASIS,
      cell: GainLossUSDCell,
      aggregatedCell: undefined
    },
    {
      header: GainLossDetailColumnNames.liquidationDate,
      accessorFn: ({ DTSALE }) => DTSALE,
      getExportValue: ({ DTSALE }) =>
        DTSALE && parseDateISOStringInLocalTimezone(DTSALE),
      cell: GainLossDateCell,
      aggregatedCell: GainLossAggregatedDateCell,
      aggregationFn: 'unique',
      sortingFn: 'datetime'
    },
    {
      header: GainLossDetailColumnNames.liquidationPrice,
      accessorFn: ({ LIQUIDATIONPRICE }) => LIQUIDATIONPRICE,
      getExportValue: ({ LIQUIDATIONPRICE }) => LIQUIDATIONPRICE,
      cell: GainLossUSDCell,
      aggregationFn: getUniqueValue,
      aggregatedCell: (props) =>
        GainLossAggregatedUniqueUSDCell(props, { isAccountNotApplicable: true })
    },
    {
      header: GainLossDetailColumnNames.liquidationAmount,
      accessorFn: ({ STKBNDAMT }) => STKBNDAMT,
      getExportValue: ({ STKBNDAMT }) => STKBNDAMT,
      cell: GainLossUSDCell,
      aggregatedCell: undefined,
      footer: TotalValueFooter
    },
    {
      header: GainLossDetailColumnNames.symbol,
      getExportValue: ({ SECNAME }) => SECNAME
    },
    {
      header: GainLossDetailColumnNames.description,
      getExportValue: ({ SECDESCRIPTION }) => SECDESCRIPTION
    }
  ]

  if (isAccountL1) {
    const accountColumn = columns.splice(2, 1)[0]
    columns.splice(1, 0, accountColumn)
  }

  return columns
}
