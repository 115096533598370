import { css } from '@emotion/react'

export const WidgetTableStyles = {
  ellipsis: css({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  })
}

export const USDCell: React.FC<{ value?: number; defaultValue?: string }> = ({
  value,
  defaultValue = '-'
}) => {
  const totalAmount =
    value !== undefined && value !== null
      ? value?.toLocaleString('en-US', {
          style: 'currency',
          currencySign: 'standard',
          maximumFractionDigits: 2,
          currency: 'USD'
        })
      : defaultValue

  const totalAmountTitle = value?.toLocaleString('en-US', {
    style: 'currency',
    currencySign: 'standard',
    maximumFractionDigits: 2,
    currency: 'USD'
  })
  return (
    <div
      css={[
        WidgetTableStyles.ellipsis,
        {
          textAlign: 'right'
        }
      ]}
      title={totalAmountTitle}
    >
      {totalAmount}
    </div>
  )
}
