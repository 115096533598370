import { flow } from 'lodash'
import { AppState } from '../../../../store'

export const rootSelector = (state: AppState) =>
  state.modules.products.productCommon

export const getPlansToDistributeCashState = flow(
  rootSelector,
  (x) => x.plansToDistributeCash
)
export const getSelectedMonthYear = flow(
  rootSelector,
  (x) => x.selectedMonthYear
)
export const getSelectedPlan = flow(rootSelector, (x) => x.selectedPlan)
export const getProductPlanTransactionsState = flow(
  rootSelector,
  (x) => x.productTransactions
)
export const getFetchProductPlanState = flow(
  rootSelector,
  (x) => x.productPlans
)
export const getFetchPlanProvidersState = flow(
  rootSelector,
  (x) => x.planProviders
)
export const getFetchProviderForExcelState = flow(
  rootSelector,
  (x) => x.planProvidersForExcelState
)
export const getPlansByProviderState = flow(
  rootSelector,
  (x) => x.plansByProviderState
)
export const getFeeTypes = flow(
  rootSelector,
  (x) => x.masterLookup?.masterLookup?.feetype
)
export const getUploadDocTypes = flow(
  rootSelector,
  (x) => x.masterLookup?.masterLookup?.lookup
)
export const getSelectedFeeType = flow(rootSelector, (x) => x.selectedFeeType)
export const getProjectedAmountByPlanState = flow(
  rootSelector,
  (x) => x.projectAmountByPlan
)
export const getUpdateprojectAmountState = flow(
  rootSelector,
  (x) => x.updateByPlanId
)
export const getProductPlanAllocationsState = flow(
  rootSelector,
  (x) => x.planAllocations
)
export const getCloseMonthState = flow(rootSelector, (x) => x.closeMonthState)

export const getUpdatePlanByProviderState = flow(
  rootSelector,
  (x) => x.updatePlanByProviderState
)

export const getUpdatePlanProviderState = flow(
  rootSelector,
  (x) => x.updatePlanProvider
)

export const getClassificationTypesState = flow(
  rootSelector,
  (x) => x.classificationTypesState
)
export const getFetchDocTypeState = flow(rootSelector, (x) => x.uploadDocTypes)

export const getFetchDocListState = flow(rootSelector, (x) => x.uploadDocList)
export const getUploadFundDocState = flow(rootSelector, (x) => x.FunDocUpload)
export const getDeleteFundDocState = flow(rootSelector, (x) => x.FunDocDelete)
export const getDownloaddFundDocState = flow(
  rootSelector,
  (x) => x.FunDocDownload
)
export const getPlanProvidersDocsState = flow(
  rootSelector,
  (x) => x.planProviderDocsState
)

export const getDeleteStatementState = flow(
  rootSelector,
  (x) => x.deleteStatementState
)

export const getUpdatePlanProviderDocState = flow(
  rootSelector,
  (x) => x.updatePlanProviderDoc
)

export const getFetchAccrualReportState = flow(
  rootSelector,
  (x) => x.accrualReport
)
export const getFetchOpenReceivablesReportState = flow(
  rootSelector,
  (x) => x.openReceivablesReport
)
export const getCashReceiptTrendReportState = flow(
  rootSelector,
  (x) => x.cashReceiptTrendReport
)

export const getAccrualReportDrilldownState = flow(
  rootSelector,
  (x) => x.accrualReportDrilldownState
)

export const getExistingSponsorNamesState = flow(
  rootSelector,
  (x) => x.existingSponsorNamesState
)

export const getTrendReportDrilldownExcelEXportState = flow(
  rootSelector,
  (x) => x.trendReportDrilldownExcelExportState
)

export const getAIApprovalDashboardState = flow(
  rootSelector,
  (x) => x.aiFundsProvider
)

export const getAIApproverActionResponse = flow(
  rootSelector,
  (x) => x.approverActionResponse.status
)

export const getPlanChangeDetailsActionResponse = flow(
  rootSelector,
  (x) => x.comparisonData
)

export const getWorkflowHistoryState = flow(
  rootSelector,
  (x) => x.workflowHistoryData
)

export const getViewCommittedFundState = flow(
  rootSelector,
  (x) => x.committedFundData
)
export const getManualUploadState = flow(
  rootSelector,
  (x) => x.manualUploadState
)
export const getUploadManualAccrualState = flow(
  rootSelector,
  (x) => x.ManualAccrualUploadState
)

export const getFundPlanDataState = flow(rootSelector, (x) => x.fundPlandData)
