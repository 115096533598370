import { uniq } from 'lodash'
import { createReducer } from 'typesafe-actions'
import { dashboardActions, DashboardActionTypes } from './actions'
import { AllocationType } from './types'

interface IDashboardState {
  allocationType: AllocationType
  selectedProductAllocations?: string[]
  selectedAssetAllocations?: string[]
  selectedSectorAllocations?: string[]
}

const initialState: IDashboardState = {
  allocationType: 'asset'
}

export const dashboardReducer = createReducer<
  IDashboardState,
  DashboardActionTypes
>(initialState)
  .handleAction(
    dashboardActions.setSelectedProductAllocations,
    (state, action) => ({
      ...state,
      selectedProductAllocations: uniq(action.payload)
    })
  )
  .handleAction(
    dashboardActions.setSelectedAssetAllocations,
    (state, action) => ({
      ...state,
      selectedAssetAllocations: uniq(action.payload)
    })
  )
  .handleAction(
    dashboardActions.setSelectedSectorAllocations,
    (state, action) => ({
      ...state,
      selectedSectorAllocations: uniq(action.payload)
    })
  )
  .handleAction(dashboardActions.setAllocationType, (state, action) => ({
    ...state,
    allocationType: action.payload
  }))
