import {
  DatePicker,
  DateRangeType,
  DefaultButton,
  IButtonStyles,
  IContextualMenuItem,
  IContextualMenuListProps,
  IContextualMenuProps,
  IRenderFunction,
  Label,
  Stack
} from '@fluentui/react'
import { format } from 'date-fns'
import { range } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { buttonStyles } from '../../components/shared/Buttons'
import { inputClasses } from '../../components/shared/Inputs'
import { useRevenueChartDateStore } from './revenueChartDateStore'

export interface IRevenueWidgetDateRangeOption {
  key: string
  value?: string
  type?: 'YEAR' | 'MONTHS'
  label: string
}
export const DateSelector = () => {
  const dateRangeOptions = useMemo(() => getRevenueWidgetDateRangeOptions(), [])
  const { customDateRange, dateRange, setCustomDateRange, setDateRange } =
    useRevenueChartDateStore()
  const [startDate, setStartDate] = useState<Date | undefined>(
    customDateRange ? customDateRange[0] : undefined
  )
  const [endDate, setEndDate] = useState<Date | undefined>(
    customDateRange ? customDateRange[1] : undefined
  )
  const dateRangeMenuItems = useMemo(
    () =>
      dateRangeOptions.map(
        ({ key, label, type }): IContextualMenuItem => ({
          key,
          text: label,
          type: type
        })
      ),
    [dateRangeOptions]
  )
  const onItemClick = (ev: any, item: IContextualMenuItem | undefined) => {
    setDateRange(item?.key as string)
  }
  const onCustomSubmit = useCallback(() => {
    if (!startDate || !endDate) {
      return
    }
    setDateRange('Custom')
    setShowMenu(false)
    setCustomDateRange([startDate, endDate])
  }, [endDate, setCustomDateRange, setDateRange, startDate])

  const formatDate = (date: Date | undefined) => {
    return date ? format(date, `MMM yyyy`) : ''
  }
  const onStartDateChange = useCallback((date?: Date | null) => {
    if (!date) {
      return
    }
    setStartDate(date)
  }, [])
  const onEndDateChange = useCallback((date?: Date | null) => {
    if (!date) {
      return
    }
    setEndDate(date)
  }, [])
  const [showMenu, setShowMenu] = useState<boolean>(true)

  const onRenderMenuList: IRenderFunction<IContextualMenuListProps> = (
    menuListProps?: IContextualMenuListProps,
    defaultRender?: IRenderFunction<IContextualMenuListProps>
  ) => {
    return (
      <>
        {defaultRender?.(menuListProps)}
        <div style={{ padding: '10px' }}>
          <Stack tokens={{ childrenGap: '5px' }}>
            <Label>Custom Range</Label>
            <Stack horizontal={true} tokens={{ childrenGap: '5px' }}>
              <DatePicker
                placeholder="Start Date"
                value={startDate}
                onSelectDate={onStartDateChange}
                calendarProps={{
                  dateRangeType: DateRangeType.Month,
                  isDayPickerVisible: false
                }}
                formatDate={formatDate}
                minDate={new Date(2019, 0, 1)}
                maxDate={endDate ?? new Date()}
                style={{ width: '117px' }}
              />
              <DatePicker
                placeholder="End Date"
                value={endDate}
                onSelectDate={onEndDateChange}
                calendarProps={{
                  dateRangeType: DateRangeType.Month,
                  isDayPickerVisible: false
                }}
                formatDate={formatDate}
                minDate={startDate ?? new Date(2019, 0, 1)}
                maxDate={new Date()}
                style={{ width: '110px' }}
              />
            </Stack>
            <Stack
              horizontal={true}
              horizontalAlign="end"
              tokens={{ childrenGap: 5 }}
            >
              <button
                css={[buttonStyles.primary]}
                disabled={!startDate || !endDate}
                onClick={onCustomSubmit}
              >
                Apply
              </button>
              <button
                css={[buttonStyles.secondary]}
                onClick={() => setShowMenu(false)}
              >
                Cancel
              </button>
            </Stack>
          </Stack>
        </div>
      </>
    )
  }

  const menuProps: IContextualMenuProps = {
    items: dateRangeMenuItems,
    directionalHintFixed: true,
    onRenderMenuList,
    onItemClick
  }

  const styles: IButtonStyles = {
    label: [
      {
        fontWeight: 'normal',
        textAlign: 'left'
      }
    ],
    root: [
      {
        padding: '5px'
      }
    ],
    rootExpanded: [{ background: 'none' }]
  }

  return (
    <DefaultButton
      text={
        dateRange === 'Custom'
          ? `${formatDate(startDate)} - ${formatDate(endDate)}`
          : dateRange
      }
      style={{ width: '200px', background: 'white' }}
      onClick={() => setShowMenu(true)}
      menuProps={showMenu ? menuProps : undefined}
      styles={styles}
      menuIconProps={{ iconName: 'ChevronDown' }}
      css={[inputClasses.input]}
    />
  )
}
export const getRevenueWidgetDateRangeOptions = () => {
  const currentYear = new Date().getFullYear()
  const options: IRevenueWidgetDateRangeOption[] = [
    {
      key: 'Prior 12 Months',
      label: 'Prior 12 Months',
      type: 'MONTHS'
    },
    ...range(currentYear, 2018, -1).map(
      (x): IRevenueWidgetDateRangeOption => ({
        key: x.toString(),
        type: 'YEAR',
        value: x.toString(),
        label: x.toString()
      })
    )
  ]

  return options
}
