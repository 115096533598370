import { initializeIcons, makeStyles, ThemeProvider } from '@fluentui/react'
import { ActivateProfilePanel } from 'features/ActivateProfile/ActivateProfilePanel'
import '@fontsource-variable/inter'
import { ClientInvitePanel } from 'features/ClientInvite/ClientInvitePanel'
import { EditHouseholdTagsPanel } from 'features/Households/features/EditHouseholdTags'
import React, {
  PropsWithChildren,
  StrictMode,
  Suspense,
  useEffect
} from 'react'
import { createRoot } from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import { Provider, useSelector } from 'react-redux'
import {
  unstable_HistoryRouter as HistoryRouter,
  Navigate,
  Route,
  Routes,
  useLocation
} from 'react-router-dom'
import { Store } from 'redux'
import { registerInterceptors } from 'shared/services/axios'
import { AccountLinkingValidationPanel } from './features/AccountLinking/AccountLinkingValidationPanel'
import { ContactsPanelContainer } from './features/Contact/ContactsPanelContainer'
import { EditHouseholdNamePanel } from './features/Households/features/EditHouseholdName/EditHouseholdNamePanel'
import { HouseholdChangeRequestEditPanel } from './features/Households/features/HouseholdChangeRequest'
import { HouseholdDetailPanel } from './features/Households/features/HouseholdDetail'
import { HouseholdFeePanel } from './features/Households/features/HouseholdFee/HouseholdFeePanel'
import { MarginRequestRatePanel } from './features/MarginRateAdjustment/MarginRequestRatePanel'
import { NotificationBanner } from './features/Notifications'
import { PrivateRoute } from './features/PrivateRoute'
import { RelatedPartyPanelContainer } from './features/RelatedPartyPanel'
import modules from './modules'
import * as serviceWorker from './serviceWorker'
import { ErrorBoundary } from './shared/components/ErrorBoundary'
import { NotFound } from './shared/components/NotFound'
import { ready } from './shared/services/dom'
import { getEnvironment } from './shared/services/environment'
import { history } from './shared/services/history'
import { store } from './store'
import { getIsEnvironmentLoaded, systemActions } from './store/system'
import { getTheme } from './store/ui/selectors'
import './styles/index.css'

const AllRoutes: React.FC = () => {
  const isEnvironmentReady = useSelector(getIsEnvironmentLoaded)

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  if (!isEnvironmentReady) {
    return <div className="rdot-login-splash" />
  }

  return (
    <Routes>
      {modules.map(({ routeProps, name }) => (
        <Route
          path={routeProps.path}
          element={<PrivateRoute routeProps={routeProps} />}
          key={name}
        />
      ))}
      {['households', 'clients', 'accounts', 'positions', 'orders'].map(
        (item) => (
          <Route
            key={item}
            path={`/lists/${item}`}
            element={
              <Navigate
                to={{
                  pathname: `/advisory/lists/${item}`,
                  search: window.location.search
                }}
                replace
              />
            }
          />
        )
      )}
      <Route path="/*" element={<NotFound />} />
    </Routes>
  )
}

const useBodyStyles = makeStyles((theme) => {
  return {
    body: {
      fontFamily: "'Inter Variable', sans-serif",
      background: theme.palette.neutralLighter,
      color: theme.semanticColors.bodyText,
      ...theme.fonts.medium,
      '@media print': {
        backgroundColor: theme.palette.white
      }
    }
  }
})
// fix JSON serialization of BigInt
;(BigInt.prototype as any).toJSON = function () {
  return (JSON as any).rawJSON(this.toString())
}

const ConnectedThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const theme = useSelector(getTheme)
  const classes = useBodyStyles({ theme })

  useEffect(() => {
    const body: HTMLBodyElement = document.getElementsByTagName('body')[0]
    const doc = document.documentElement

    body.className = classes.body
    doc.className = classes.body
  }, [classes])

  return (
    <ThemeProvider theme={theme} applyTo="none">
      {children}
    </ThemeProvider>
  )
}
const Panels: React.FC = () => {
  const isEnvironmentReady = useSelector(getIsEnvironmentLoaded)
  return isEnvironmentReady ? (
    <>
      <RelatedPartyPanelContainer />
      <ContactsPanelContainer />
      <HouseholdChangeRequestEditPanel />
      <HouseholdDetailPanel />
      <EditHouseholdNamePanel />
      <EditHouseholdTagsPanel />
      <AccountLinkingValidationPanel />
      <MarginRequestRatePanel />
      <HouseholdFeePanel />
      <ClientInvitePanel />
      <ActivateProfilePanel />
    </>
  ) : null
}

const Root: React.FC<{ store: Store }> = ({ store }) => {
  return (
    <StrictMode>
      <Provider store={store}>
        <ConnectedThemeProvider>
          <HistoryRouter history={history}>
            <ErrorBoundary>
              <IntlProvider locale="en">
                <Suspense fallback={<div />}>
                  <AllRoutes />
                </Suspense>
                <Panels />
              </IntlProvider>
            </ErrorBoundary>
            <NotificationBanner />
          </HistoryRouter>
        </ConnectedThemeProvider>
      </Provider>
    </StrictMode>
  )
}

const enableMocking = async () => {
  if (process.env.NODE_ENV !== 'development') {
    return
  }

  await ready()
  const { startMockApi } = await import('./mocks')
  const registration = await startMockApi()
  return registration
}

const init = async () => {
  registerInterceptors()

  getEnvironment().then((config) =>
    store.dispatch(systemActions.setEnvironment(config))
  )

  const container = document.getElementById('root')
  if (!container) {
    throw new Error('Failed to get reference to container element')
  }
  const root = createRoot(container)

  await enableMocking()
  ready().then(() => root.render(<Root store={store} />))

  initializeIcons()
}

init()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
