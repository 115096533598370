import { createListsStore } from 'features/Lists/core/store'
import { mapColumnsToFilterDefinitions } from 'features/Lists/shared/lists'
import { partial } from 'lodash/fp'
import { AppState } from 'store'
import { IAlert } from './alert.types'
import { createDefaultColumnState } from './alertsConfig'
import { AlertColumnDefinitions } from './ColumnDefinitions'

export const constructInitialAlertListColumnState = partial(
  createDefaultColumnState,
  [AlertColumnDefinitions]
)

const filterDefinitions = mapColumnsToFilterDefinitions(AlertColumnDefinitions)

export const alertListStore = createListsStore<IAlert>(
  'alert',
  '@modules/@advisory/@modules/@alerts/@features/@lists/@alerts',
  {
    columnDefinitions: AlertColumnDefinitions,
    filterDefinitions
  },
  (state: AppState) => state.modules.advisory.modules.alerts.list
)

export const { reducer, sagas, actions, selectors } = alertListStore
