import { FC, useCallback } from 'react'
import { useClientDashboardCommonPreferences } from '../../hooks/useClientDashboardPreferences'
import { Icon } from '../Icons/Icon'
import { defaultNickname } from './store'

export const HeaderWithRadioButton: FC<{
  fieldName: string
  title: string
  title2?: string
  radioLabel?: string
  tooltiptxt?: string
}> = ({
  fieldName,
  title,
  title2,
  radioLabel = 'Use these names in Client Dashboard',
  tooltiptxt
}) => {
  const { preferences, setPreferences } = useClientDashboardCommonPreferences()
  const preferredNickName = preferences?.preferredNickName || defaultNickname

  const handleChangePreferredNickname = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setPreferences({ preferredNickName: fieldName })
      }
    },
    [fieldName, setPreferences]
  )

  return (
    <div>
      <div css={{ display: 'flex' }}>
        <span css={{ fontSize: 14 }}>{title}</span>
        {title2 && (
          <span css={{ fontSize: 12, fontWeight: 'normal', marginLeft: 4 }}>
            {title2}
          </span>
        )}
        <div title={tooltiptxt} css={{ padding: '0 4px' }}>
          <Icon type="Info" width={14} height={14} color="#005CB8" />
        </div>
      </div>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 4
        }}
      >
        <label css={{ fontWeight: 'normal', fontSize: 14, cursor: 'pointer' }}>
          <input
            name="preferredNickName"
            type="radio"
            checked={fieldName === preferredNickName}
            css={{ height: 11, marginLeft: 0 }}
            onChange={handleChangePreferredNickname}
          />
          {radioLabel}
        </label>
      </div>
    </div>
  )
}
