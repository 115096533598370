import { stringify } from 'query-string'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { datahubApi } from 'store/api/datahub'
import { IAdvisorRep } from '../../../api/dynamics'

const paramsSerializer = (params: any) => {
  return stringify(params, { arrayFormat: 'comma' })
}

const domainApi = datahubApi.injectEndpoints({
  endpoints: (builder) => ({
    getDomainReps: builder.query<IAdvisorRep[] | undefined, void>({
      query: () => ({
        url: 'D365Proxy/rcm_advisorreps',
        params: {
          select: ['rcm_advisorrepid', 'rcm_repid,rcm_name'],
          $expand: [
            'rcm_OwningTeam($select=name;$expand=businessunitid($select=name;$expand=parentbusinessunitid($select=name;$expand=parentbusinessunitid($select=name;$expand=parentbusinessunitid($select=name)))))',
            'rcm_PersonalRepFor($select=domainname,fullname,_businessunitid_value,businessunitid)',
            'rcm_AdvisorRepPoolSplits_PoolRep_rcm_Advi($select=rcm_percentage,rcm_calc_poolrepname,rcm_calc_personalrepname,rcm_calc_poolrepid,rcm_calc_personalrepid;$filter=statecode eq 0)'
          ]
        },
        paramsSerializer
      }),
      keepUnusedDataFor: 60 * 60 * 2,
      transformResponse: (x: IOdataResult<IAdvisorRep>) => x.value
    })
  })
})

export const { useGetDomainRepsQuery } = domainApi
export const selectDomainRepsQueryResult =
  domainApi.endpoints.getDomainReps.select()
