import axios from 'axios'
import { flow } from 'lodash/fp'
import { call, cancelled, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import {
  createMarginRateRequest,
  IMarginRateRequest
} from '../../../api/dynamics'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../store/shared/asyncStore'
import { getDynamicsApiOptions } from '../../../store/shared/sagas'
import { getMarginRateAdjustmentFeatureState } from './shared'

export const marginRateRequestPostActions = createAsyncAction(
  '@features/@marginRate/@marginRatePost/REQUEST',
  '@features/@marginRate/@marginRatePost/SUCCESS',
  '@features/@marginRate/@marginRatePost/FAILURE'
)<IMarginRateRequest, IMarginRateRequest, Error>()

export const marginRateRequestPostReducer = createAsyncReducer(
  marginRateRequestPostActions
)

const rootSelector = flow(
  getMarginRateAdjustmentFeatureState,
  (x) => x?.marginRateRequestPost
)

export const {
  getError: getMarginRateRequestPostError,
  getIsLoading: getIsMarginRateRequestPostLoading,
  getResult: getMarginRateRequestPostResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof marginRateRequestPostActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  try {
    const apiOptions = yield* call(getDynamicsApiOptions)
    const newItem = yield* call(
      createMarginRateRequest,
      apiOptions,
      action.payload
    )

    yield put(marginRateRequestPostActions.success(newItem))
  } catch (e: unknown) {
    yield put(marginRateRequestPostActions.failure(e as Error))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const marginRateRequestPostAccountsUserSagas = [
  () => takeLatest(marginRateRequestPostActions.request, onRequest)
]
