import {
  DefaultButton,
  IButtonStyles,
  IconButton,
  ITheme,
  mergeStyleSets,
  Stack
} from '@fluentui/react'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../store'
import { getTheme } from '../../store/ui/selectors'
import { IconPreviewDisclaimer } from './PreviewDisclaimer'

export interface IPaletteComponentOwnProps {
  title?: string
  onClick?: () => void
  isButton?: boolean
  isSelected?: boolean
  children?: React.ReactNode
  className?: string
  scrollable?: boolean
  onNavigate?: () => void
  showPreviewDisclaimer?: boolean
}

interface IConnectedProps {
  theme: ITheme
}

type props = IPaletteComponentOwnProps & IConnectedProps

const getClassNames = (theme: ITheme) => {
  return mergeStyleSets({
    paletteOuter: {
      backgroundColor: theme.semanticColors.bodyBackground,
      boxShadow: theme.effects.elevation8,
      width: '100%',
      height: '100%'
    },
    paletteInner: {
      width: '100%',
      height: '100%',
      padding: '15px',
      position: 'relative'
    },
    goIcon: {
      position: 'absolute',
      top: 0,
      right: 0
    },
    disclaimerIcon: {
      position: 'absolute',
      top: 5,
      left: 5
    }
  })
}

const getComponentStyles = (): { buttonWrapper: IButtonStyles } => {
  return {
    buttonWrapper: {
      root: {
        padding: 0,
        border: 0,
        height: '100%',
        width: '100%',
        display: 'block'
      },
      flexContainer: {
        display: 'block'
      }
    }
  }
}

const preventDefault = (e: React.MouseEvent<HTMLAnchorElement>) =>
  e.preventDefault()

export class Component extends PureComponent<props> {
  public render() {
    const componentStyles = getComponentStyles()
    const {
      title,
      children,
      isButton,
      onClick,
      className,
      theme,
      isSelected,
      scrollable,
      onNavigate,
      showPreviewDisclaimer
    } = this.props
    const classes = getClassNames(theme)
    const component = (
      <div
        className={[classes.paletteInner, 'palette', className]
          .filter((x) => x)
          .join(' ')}
      >
        {' '}
        {showPreviewDisclaimer && (
          <div className={classes.disclaimerIcon}>
            <IconPreviewDisclaimer />
          </div>
        )}
        {onNavigate ? (
          <IconButton
            title="View Detail"
            iconProps={{
              iconName: 'Go'
            }}
            styles={{
              root: {
                width: '25px',
                height: '25px',
                selectors: {
                  '& i[data-icon-name]': {
                    fontSize: '11px',
                    fontWeight: 'bold'
                  }
                }
              }
            }}
            className={classes.goIcon}
            onClick={this.onNavigateClick}
          />
        ) : null}
        {title ? (
          <Stack verticalFill={true} tokens={{ childrenGap: 20 }}>
            <Stack.Item>
              <h3>{title}</h3>
            </Stack.Item>
            <Stack.Item
              verticalFill={true}
              grow={1}
              styles={{ root: { overflow: scrollable ? 'auto' : 'visible' } }}
            >
              {children}
            </Stack.Item>
          </Stack>
        ) : (
          children
        )}
      </div>
    )

    return (
      <div className={classes.paletteOuter} onClick={onClick}>
        {isButton ? (
          <DefaultButton
            // render this as a link because of a button nesting issue
            href="#"
            onClick={preventDefault}
            styles={componentStyles.buttonWrapper}
            checked={isSelected}
            primary={false}
          >
            {component}
          </DefaultButton>
        ) : (
          component
        )}
      </div>
    )
  }

  private onNavigateClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    if (this.props.onNavigate) {
      this.props.onNavigate()
    }
  }
}

export const PaletteComponent = connect<
  IConnectedProps,
  void,
  IPaletteComponentOwnProps,
  AppState
>((state: AppState, ownProps: IPaletteComponentOwnProps): props => {
  return {
    ...ownProps,
    theme: getTheme(state)
  }
})(Component)
