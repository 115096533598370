import { toLower } from 'lodash'
import { useMatch, useResolvedPath } from 'react-router-dom'
import { isNotNullOrEmpty } from 'shared/guards'

export const useRouteMatch = () => {
  const { pathname } = useResolvedPath('.')
  const match1 = useMatch({
    path: `${pathname}/:segment1/*`,
    caseSensitive: false,
    end: false
  })
  const match2 = useMatch({
    path: `${pathname}/:segment1/:segment2/*`,
    caseSensitive: false,
    end: false
  })
  const match3 = useMatch({
    path: `${pathname}/:segment1/:segment2/:segment3/*`,
    caseSensitive: false,
    end: false
  })
  const { segment1 } = match1?.params || {}
  const { segment2 } = match2?.params || {}
  const { segment3 } = match3?.params || {}

  return {
    pathname,
    subSegments: [segment1, segment2, segment3]
      .filter(isNotNullOrEmpty)
      .map(toLower),
    firstSubSegment: toLower(segment1)
  }
}
