import { AppState } from '../../../../../store'

export const getFetchRetirementProductPlanState = (state: AppState) =>
  state.modules.products.retirement.retirementPlans

export const getRetirementProductPlanTransactionsState = (state: AppState) =>
  state.modules.products.retirement.retirementTransactions

export const getSelectedRetirementPlan = (state: AppState) =>
  state.modules.products.retirement.selectedPlan

export const getSelectedMonthYear = (state: AppState) =>
  state.modules.products.retirement.selectedMonthYear

export const getSelectedRetirementPlanType = (state: AppState) =>
  state.modules.products.retirement.selectedRetirementPlanType

export const getProjectedAmountByPlanState = (state: AppState) =>
  state.modules.products.retirement.projectAmountByPlan

export const getUpdateprojectAmountState = (state: AppState) =>
  state.modules.products.retirement.updateByPlanId

export const getFetchPlanProvidersState = (state: AppState) =>
  state.modules.products.retirement.planProviders

export const getUpdatePlanProviderState = (state: AppState) =>
  state.modules.products.retirement.updatePlanProvider

export const getSelectedPeriod = (state: AppState) =>
  state.modules.products.retirement.selectedPeriod

export const getPlansToDistributeCashState = (state: AppState) =>
  state.modules.products.retirement.plansToDistributeCash

export const getUpdateCashReceiptToPlanState = (state: AppState) =>
  state.modules.products.retirement.updateCashReceiptToPlan

export const getSelectedAccrualPeriod = (state: AppState) =>
  state.modules.products.retirement.selectedAccrualPeriod

export const getPlansByProviderState = (state: AppState) =>
  state.modules.products.retirement.plansByProviderState

export const getCloseMonthState = (state: AppState) =>
  state.modules.products.retirement.closeMonthState

export const getRetirementPlanReportState = (state: AppState) =>
  state.modules.products.retirement.retirementPlanRpt

export const getUpdatePlanByProviderState = (state: AppState) =>
  state.modules.products.retirement.updatePlanByProviderState

export const getRetirementPlanAllocationsState = (state: AppState) =>
  state.modules.products.retirement.planAllocations

export const getLookUpFieldsState = (state: AppState) =>
  state.modules.products.retirement.lookupFields
