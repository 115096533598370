import {
  ActionButton,
  Checkbox,
  IIconProps,
  Panel,
  Stack
} from '@fluentui/react'
import { Column, VisibilityState } from '@tanstack/react-table'
import { useCallback } from 'react'
import { AnnuityPPLICombined } from '../../shared/types/annuityPPLICombined'

interface IColumnEditSummaryPanel {
  isPanelOpen: boolean
  closePanel: React.Dispatch<React.SetStateAction<boolean>>
  columns: Column<AnnuityPPLICombined, unknown>[]
  columnVisibility: VisibilityState
  setColumnVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>
  isAllColumnsVisible: boolean
  toggleAllColumnsVisibilityHandler: (event: unknown) => void
}

export const ColumnEditSummaryPanel: React.FC<IColumnEditSummaryPanel> = ({
  isPanelOpen,
  closePanel,
  columns,
  columnVisibility,
  setColumnVisibility,
  isAllColumnsVisible,
  toggleAllColumnsVisibilityHandler
}) => {
  const onDismiss = useCallback(() => closePanel(false), [closePanel])

  const onRenderFooterContent = useCallback(() => {
    const pinnedIcon: IIconProps = { iconName: 'Pinned' }
    return (
      <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
        <ActionButton
          iconProps={pinnedIcon}
          onClick={onDismiss}
          allowDisabledFocus
        >
          {'Looks good! Close.'}
        </ActionButton>
      </Stack>
    )
  }, [onDismiss])

  return (
    <Panel
      headerText={'Edit Columns'}
      isOpen={isPanelOpen}
      onDismiss={onDismiss}
      isLightDismiss={true}
      closeButtonAriaLabel="Close"
      onRenderFooterContent={onRenderFooterContent}
    >
      <div style={{ marginBottom: '20px' }}>
        <Checkbox
          checked={isAllColumnsVisible}
          onChange={toggleAllColumnsVisibilityHandler}
          label="Toggle All"
        />
      </div>
      <Stack tokens={{ childrenGap: 10 }}>
        {columns.map((column) => {
          return (
            <Checkbox
              key={column.id}
              checked={column.getIsVisible()}
              label={column.id}
              onChange={(_ev, checked) => {
                if (checked !== undefined) {
                  setColumnVisibility({
                    ...columnVisibility,
                    [column.id]: checked
                  })
                }
              }}
            />
          )
        })}
      </Stack>
    </Panel>
  )
}
