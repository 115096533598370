import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import { AppState } from 'store'
import {
  createProfileApiContextStore,
  createProfileApiContextSlice
} from '../profileApi'

const selectedAccountsApiContextSlice = createProfileApiContextSlice('selected')

export const apiContextReducer = combineReducers({
  selected: selectedAccountsApiContextSlice.reducer
})

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.apiContext

const selectedSelector = createSelector(
  rootSelector,
  ({ selected }) => selected
)
const {
  useProfileApiContext: useRdot360SelectedAccountsApiContext,
  saga_watchContext: saga_watchSelectedContext,
  selectContextId,
  selectProfileId,
  selectAsOfDate
} = createProfileApiContextStore(
  selectedSelector,
  selectedAccountsApiContextSlice.actions
)

const apiContextSagas = [saga_watchSelectedContext]

export {
  useRdot360SelectedAccountsApiContext,
  apiContextSagas,
  selectContextId,
  selectProfileId,
  selectAsOfDate
}
