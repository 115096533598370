import { IModuleDefinition } from 'modules/types'
import { CommunicationsContainer } from './CommunicationsContainer'

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/communications/*',
    private: true,
    component: CommunicationsContainer
  },
  name: 'Communications Center'
}

export default moduleDefinition
