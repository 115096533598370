import {
  Calendar,
  CommandBar,
  CommandBarButton,
  ContextualMenu,
  DateRangeType,
  DetailsListLayoutMode,
  ICommandBarItemProps,
  makeStyles,
  ProgressIndicator
} from '@fluentui/react'
import { endOfMonth, startOfMonth } from 'date-fns'
import { IDataTableColumnDefinition } from 'features/DataList/common/types'
import { DateOnlyCell } from 'features/Lists/core/components/DateCell'
import { constants } from 'modules/Advisory/shared/theme'
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IManagedAccountFeeHistory } from '../../../../../../api/datahub'
import { useDomainStore } from '../../../../../../features/Domain/store/domain'
import { PercentCell } from '../../../../../../features/Lists/core/components/PercentCell'
import { TextCell } from '../../../../../../features/Lists/core/components/TextCell'
import { USDCell } from '../../../../../../features/Lists/core/components/USDCell'
import {
  IListsDateRangeFilter,
  IListsFacetFilter
} from '../../../../../../features/Lists/core/contracts/IListsFilter'
import {
  IOdataDataListCellRenderProps,
  OdataList
} from '../../../../../../features/OdataList/containers/OdataList'
import { ConnectedMasked } from '../../../../../../shared/components/MaskedText'
import { useManagedAccountFeeHistoryBillTypes } from './store/billTypesFetch'
import {
  getIsManagedAccountFeeHistoryExportLoading,
  managedAccountFeeHistoryExportActions
} from './store/managedAccountFeeHistoryExport'
import {
  ManagedAccountFeeHistoryListColumnName,
  managedAccountFeeHistoryListUiActions,
  managedAccountFeeHistoryListUiSelectors
} from './store/managedAccountFeeHistoryList'

const CellComponents: Record<
  ManagedAccountFeeHistoryListColumnName,
  React.FC<IOdataDataListCellRenderProps<IManagedAccountFeeHistory>>
> = {
  Account: ({ item }) => (
    <TextCell>
      <ConnectedMasked text={item.account} />
    </TextCell>
  ),
  Client: ({ item }) => (
    <TextCell>
      <ConnectedMasked text={item.client} />
    </TextCell>
  ),
  'Bill Date': ({ item }) => <DateOnlyCell value={item.billDate} />,
  'Bill Period Start': ({ item }) => (
    <DateOnlyCell value={item.billPeriodStart} />
  ),
  'Bill Period End': ({ item }) => <DateOnlyCell value={item.billPeriodEnd} />,
  Days: ({ item }) => <TextCell>{item.days}</TextCell>,
  'Bill Type': ({ item }) => <TextCell>{item.billType}</TextCell>,
  'Billable Value': ({ item }) => <USDCell value={item.billableValue} />,
  'Manager Fee': ({ item }) => <USDCell value={item.managerFee} />,
  'Manager Fee Percent': ({ item }) => (
    <PercentCell value={item.managerFeePercent} />
  ),
  'Rockefeller Fee': ({ item }) => <USDCell value={item.rockefellerFee} />,
  'Rockefeller Fee Percent': ({ item }) => (
    <PercentCell value={item.rockefellerFeePercent} />
  ),
  'Client Fee': ({ item }) => <USDCell value={item.clientFee} />,
  'Client Fee Percent': ({ item }) => (
    <PercentCell value={item.clientFeePercent} />
  ),
  'Advisor Name': ({ item }) => (
    <TextCell>
      <ConnectedMasked text={item.advisorName} />
    </TextCell>
  ),
  'Product Type': ({ item }) => <TextCell>{item.productType}</TextCell>,
  'Rep Code': ({ item }) => (
    <TextCell>
      <ConnectedMasked text={item.repCode} />
    </TextCell>
  ),
  'Product Name': ({ item }) => <TextCell>{item.productName}</TextCell>,
  'Debit Account': ({ item }) => (
    <TextCell>
      <ConnectedMasked text={item.debitAccount} />
    </TextCell>
  ),
  Branch: ({ item }) => <TextCell>{item.branch}</TextCell>,
  'Billing Group Name': ({ item }) => (
    <TextCell>{item.billingGroupName}</TextCell>
  ),
  'Billable Household Value': ({ item }) => (
    <USDCell value={item.billableHouseholdValue} />
  )
}

const ManagedAccountFeeHistoryListCell: React.FC<
  IOdataDataListCellRenderProps<IManagedAccountFeeHistory>
> = memo(({ item, column }) => {
  const Component =
    CellComponents[column.name as ManagedAccountFeeHistoryListColumnName]
  return Component ? <Component item={item} column={column} /> : null
})

const useClasses = makeStyles((theme) => ({
  commandBar: {
    borderBottom: `solid 2px ${theme.palette.neutralLight}`
  }
}))

export const ManagedAccountFeeHistoryList: React.FC = () => {
  const dispatch = useDispatch()
  const onExport = useCallback(() => {
    dispatch(managedAccountFeeHistoryExportActions.request())
  }, [dispatch])

  const containerRef = useRef<HTMLDivElement>(null)
  const [offsetLeft, setOffsetLeft] = useState(0)
  useEffect(() => {
    const offset = containerRef.current?.getBoundingClientRect()
    setOffsetLeft(offset?.left || 0)
  }, [])

  const { selectedAccountRepIds, isFullDomainSelected, domain } =
    useDomainStore()
  const filters = useSelector(
    managedAccountFeeHistoryListUiSelectors.getFilters
  )

  useEffect(() => {
    if (selectedAccountRepIds == null) {
      return
    }
    const filterId = 'Rep Code'
    const hasValue = !isFullDomainSelected && !!selectedAccountRepIds?.length
    dispatch(
      managedAccountFeeHistoryListUiActions.updateFilters({
        [filterId]: {
          ...filters?.[filterId],
          values: hasValue ? selectedAccountRepIds : undefined,
          type: 'facet',
          facets: domain?.map(({ rcm_repid }) => ({ value: rcm_repid })),
          hasValue
        } as IListsFacetFilter
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountRepIds])

  const isExportLoading = useSelector(
    getIsManagedAccountFeeHistoryExportLoading
  )

  const onBillPeriodChange = useCallback(
    (date?: Date) => {
      if (!date) {
        return
      }

      const filterId = 'Bill Date'
      dispatch(
        managedAccountFeeHistoryListUiActions.updateFilters({
          [filterId]: {
            ...filters?.[filterId],
            range: 'Custom',
            from: startOfMonth(date),
            to: endOfMonth(date),
            hasValue: true
          } as IListsDateRangeFilter
        })
      )
    },
    [dispatch, filters]
  )

  const commandBarItems = useMemo(() => {
    const items: ICommandBarItemProps[] = [
      {
        key: 'billing-period',
        text: 'Select Bill Date',
        iconProps: { iconName: 'Calendar' },
        commandBarButtonAs: (props: any) => (
          <CommandBarButton
            {...props}
            menuAs={(menuProps) => (
              <ContextualMenu
                {...(menuProps || {})}
                items={menuProps?.items || []}
                onRenderMenuList={() => (
                  <Calendar
                    onSelectDate={onBillPeriodChange}
                    dateRangeType={DateRangeType.Month}
                    isDayPickerVisible={false}
                    minDate={new Date(2019, 0, 1)}
                    maxDate={new Date()}
                  />
                )}
              />
            )}
          />
        ),
        subMenuProps: {
          items: [{ key: 'filler' }]
        }
      },
      {
        key: 'export',
        name: isExportLoading ? 'Exporting...' : 'Export to Excel',
        iconProps: { iconName: 'ExcelLogo' },
        onClick: onExport,
        disabled: isExportLoading
      }
    ]
    return items
  }, [isExportLoading, onExport, onBillPeriodChange])

  const classes = useClasses()
  const secondaryHeader = useMemo(
    () => (
      <>
        <CommandBar
          className={classes.commandBar}
          items={commandBarItems}
          styles={{ root: { padding: 0, margin: 0 } }}
        />
        <div style={{ height: '2px' }}>
          <ProgressIndicator
            progressHidden={!isExportLoading}
            styles={{
              itemProgress: { padding: 0, margin: 0 }
            }}
          />
        </div>
      </>
    ),
    [classes.commandBar, commandBarItems, isExportLoading]
  )

  const { billTypes, fetchBillTypes } = useManagedAccountFeeHistoryBillTypes()

  const onColumnClick = useCallback(
    (column?: IDataTableColumnDefinition) => {
      if (column?.name !== 'Bill Type') {
        return
      }

      fetchBillTypes()
    },
    [fetchBillTypes]
  )

  useEffect(() => {
    if (!billTypes?.length) {
      return
    }
    dispatch(
      managedAccountFeeHistoryListUiActions.updateFacets({
        'Bill Type': billTypes.map((x) => ({ value: x, count: 0 }))
      })
    )
  }, [billTypes, dispatch])

  return (
    <div ref={containerRef}>
      <OdataList
        actions={managedAccountFeeHistoryListUiActions}
        selectors={managedAccountFeeHistoryListUiSelectors}
        onRenderCell={ManagedAccountFeeHistoryListCell}
        stickyHeaderOffset={constants.headerHeight}
        stickyColumnOffset={offsetLeft}
        secondaryHeader={secondaryHeader}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        numStickyColumns={2}
        autoLoad={false}
        onColumnClick={onColumnClick}
      />
    </div>
  )
}
