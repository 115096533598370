import { call, delay, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { IHousehold } from '../../../api/household.types'
import {
  IOdataCollectionFilter,
  OdataFilterCollectionOperatorEnum,
  OdataFilterOperatorEnum,
  OdataPropertyFilterGroup
} from '../../../api/odata'
import { AppState } from '../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../store/shared/asyncStore'
import { search } from '../../../store/shared/sagas'

export const accountLinkingClientHouseholdFetchActions = createAsyncAction(
  '@features/@accountLinking/@clientHousehold/REQUEST',
  '@features/@accountLinking/@clientHousehold/SUCCESS',
  '@features/@accountLinking/@clientHousehold/FAILURE'
)<string[] | undefined, IHousehold[], Error>()

export const accountLinkingClientHouseholdFetchReducer = createAsyncReducer(
  accountLinkingClientHouseholdFetchActions
)

const rootSelector = (state: AppState) =>
  state.features.accountLinking.clientHouseholdFetch

export const {
  getError: getAccountLinkingClientHouseholdFetchError,
  getIsLoading: getAccountLinkingClientHouseholdFetchLoading,
  getResult: getAccountLinkingClientHouseholdFetchResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof accountLinkingClientHouseholdFetchActions.request>
) {
  if (!action.payload || !action.payload.length) {
    yield put(accountLinkingClientHouseholdFetchActions.success([]))
    return
  }

  yield delay(300)

  const filter: OdataPropertyFilterGroup = {
    and: [
      {
        operator: OdataFilterOperatorEnum.searchin,
        type: 'string',
        value: action.payload
      }
    ]
  }

  const collectionFilter: IOdataCollectionFilter = {
    filter,
    operator: OdataFilterCollectionOperatorEnum.any,
    path: 'Account'
  }

  try {
    const result = yield* call(search, 'household' as const, {
      filters: [collectionFilter],
      select: ['householdName', 'householdId'],
      orderBy: [{ dataPath: 'id', direction: 'asc' as const }]
    })

    if (!result) {
      throw new Error(
        'An error occurred while fetching the household information'
      )
    }

    yield put(
      accountLinkingClientHouseholdFetchActions.success(
        (result.value as IHousehold[]) || []
      )
    )
  } catch (e: any) {
    yield put(accountLinkingClientHouseholdFetchActions.failure(e))
  }
}

export const accountLinkingClientHouseholdFetchSagas = [
  () => takeLatest(accountLinkingClientHouseholdFetchActions.request, onRequest)
]
