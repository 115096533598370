import { Stack, Label } from '@fluentui/react'
import { DateSelector } from './DateSelector'

export const ChartDropDown: React.FC<{
  showLabel?: boolean
}> = ({ showLabel }) => {
  return (
    <Stack>
      {showLabel && <Label>Date Range</Label>}
      <DateSelector />
    </Stack>
  )
}
