import { ActionType, createAction } from 'typesafe-actions'
import {
  anticipatedBalancePiDetailsRequest,
  anticipatedBalanceRequest,
  IAnticipatedBalance,
  PIDetails
} from './types'

export const FETCH_ANTICIPATED_BALANCE_INFO =
  '@modules/@payments/FETCH_ANTICIPATED_BALANCE_INFO'
export const FETCH_ANTICIPATED_BALANCE_SUCCESS =
  '@modules/@payments/FETCH_ANTICIPATED_BALANCE_SUCCESS'
export const FETCH_ANTICIPATED_BALANCE_FAILURE =
  '@modules/@payments/FETCH_ANTICIPATED_BALANCE_FAILURE'

export const anticipatedBalanceDetailsActionTypes = {
  request: createAction(
    FETCH_ANTICIPATED_BALANCE_INFO
  )<anticipatedBalanceRequest>(),
  success: createAction(
    FETCH_ANTICIPATED_BALANCE_SUCCESS
  )<IAnticipatedBalance>(),
  failure: createAction(FETCH_ANTICIPATED_BALANCE_FAILURE)<Error>()
}
export const FETCH_ANTICIPATED_BALANCE_PI_INFO =
  '@modules/@payments/FETCH_ANTICIPATED_BALANCE_PI_INFO'
export const FETCH_ANTICIPATED_BALANCE_PI_SUCCESS =
  '@modules/@payments/FETCH_ANTICIPATED_BALANCE_PI_SUCCESS'
export const FETCH_ANTICIPATED_BALANCE_PI_FAILURE =
  '@modules/@payments/FETCH_ANTICIPATED_BALANCE_PI_FAILURE'

export const anticipatedBalancePIDataActionTypes = {
  request: createAction(
    FETCH_ANTICIPATED_BALANCE_PI_INFO
  )<anticipatedBalancePiDetailsRequest>(),
  success: createAction(FETCH_ANTICIPATED_BALANCE_PI_SUCCESS)<PIDetails>(),
  failure: createAction(FETCH_ANTICIPATED_BALANCE_PI_FAILURE)<Error>()
}
export type AnticipatedBalanceDataActionTypes = ActionType<
  typeof anticipatedBalanceDetailsActionTypes
>
export type AnticipatedBalancePIDataActionTypes = ActionType<
  typeof anticipatedBalancePIDataActionTypes
>

export type AnticipatedBalanceActionType =
  | AnticipatedBalanceDataActionTypes
  | AnticipatedBalancePIDataActionTypes
