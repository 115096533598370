import axios from 'axios'
import { call, cancelled, delay, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import {
  getDynamicsTeams,
  IDynamicsTeam
} from '../../../../../../../../../api/dynamics'
import { AppState } from '../../../../../../../../../store'
import {
  createAsyncHook,
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../../../store/shared/asyncStore'
import { getDynamicsApiOptions } from '../../../../../../../../../store/shared/sagas'

export const teamSearchFetchActions = createAsyncAction(
  '@modules/@teams/@teamSearchFetch/REQUEST',
  '@modules/@teams/@teamSearchFetch/SUCCESS',
  '@modules/@teams/@teamSearchFetch/FAILURE'
)<string | undefined, IDynamicsTeam[], Error>()

export const teamSearchFetchReducer = createAsyncReducer(teamSearchFetchActions)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.teams.modules.hurdles.features.entitySearch
    .teamSearchFetch

const selectors = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof teamSearchFetchActions.request>
) {
  if (!action.payload || !action.payload.length) {
    yield put(teamSearchFetchActions.success([]))
    return
  }
  yield delay(500)

  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  try {
    const apiOptions = yield* call(getDynamicsApiOptions)
    const result = yield* call(getDynamicsTeams, apiOptions, action.payload)

    if (!result) {
      throw new Error('An error occurred while fetching teams')
    }
    yield put(teamSearchFetchActions.success(result || []))
  } catch (e: any) {
    yield put(teamSearchFetchActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const useTeamSearchFetch = createAsyncHook(
  teamSearchFetchActions.request,
  selectors
)

export const teamSearchFetchSagas = [
  () => takeLatest(teamSearchFetchActions.request, onRequest)
]
