import {
  AutoScrollOptions,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  Modifiers,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { PropsWithChildren } from 'react'

export const BasicDndContext: React.FC<
  PropsWithChildren<{
    autoScroll?: boolean | AutoScrollOptions
    onDragEnd?(event: DragEndEvent): void
    modifiers?: Modifiers
  }>
> = ({ autoScroll, children, onDragEnd, modifiers }) => {
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 8 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  return (
    <DndContext
      autoScroll={autoScroll}
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
      modifiers={modifiers}
    >
      {children}
    </DndContext>
  )
}
