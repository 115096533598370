import { MessageBarType } from '@fluentui/react'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import { isNotNullOrUndefined } from 'shared/guards'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { pushNotification } from '../../../../../../../../../features/Notifications'
import { downloadUrlAsFile } from '../../../../../../../../../shared/downloads'
import { AppState } from '../../../../../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../../../store/shared/asyncStore'
import { getAllPagedOdataApiResults } from '../../../../../../../../../store/shared/sagas/odata'
import {
  fetchUpcomingCapitalCalls,
  selectors as upcomingCapitalCallsSelectors
} from '../store/upcomingCapitalCalls'
import type { CapitalCallsExportWorker } from './worker'

export const upcomingCapitalCallsExportActions = createAsyncAction(
  '@modules/@advisory/@modules/@ai/@modules/@Dashboard/@features/@AiUpcomingCapitalCalls/EXPORT_REQUEST',
  '@modules/@advisory/@modules/@ai/@modules/@Dashboard/@features/@AiUpcomingCapitalCalls/EXPORT_SUCCESS',
  '@modules/@advisory/@modules/@ai/@modules/@Dashboard/@features/@AiUpcomingCapitalCalls/EXPORT_FAILURE'
)<undefined, undefined, Error>()

export const upcomingCapitalCallsExportReducer = createAsyncReducer(
  upcomingCapitalCallsExportActions
)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.ai.modules.dashboard.features
    .aiUpcomingCapitalCallsExport

export const {
  getError: getUpcomingCapitalCallsError,
  getIsLoading: getIsUpcomingCapitalCallsExportLoading
} = createAsyncSelectors(rootSelector)

const onExport = function* () {
  try {
    const request = yield* select(
      upcomingCapitalCallsSelectors.uiSelectors.getOdataRequest
    )

    const results = yield* call(() =>
      getAllPagedOdataApiResults(request, fetchUpcomingCapitalCalls)
    )

    const resultsMappedFiltered = results
      ?.flatMap((x) => x.result.value)
      ?.filter(isNotNullOrUndefined)

    const columnNames = yield* select(
      upcomingCapitalCallsSelectors.uiSelectors.getSelectedColumns
    )

    const worker = new Worker(new URL('./worker.ts', import.meta.url))

    const { createAndDownloadCapitalCallsExport } =
      wrap<CapitalCallsExportWorker>(worker)

    const result = yield* call(
      createAndDownloadCapitalCallsExport,
      resultsMappedFiltered,
      columnNames || []
    )

    const filename = `Upcoming Capital Calls ${format(
      new Date(),
      'MM-dd-yyyy'
    )}.xlsx`
    downloadUrlAsFile(result, filename)
    yield put(upcomingCapitalCallsExportActions.success())
  } catch (e: unknown) {
    yield put(upcomingCapitalCallsExportActions.failure(e as Error))
  }
}

const onFailure = function* (
  action: ReturnType<typeof upcomingCapitalCallsExportActions.failure>
) {
  yield call(pushNotification, {
    message: `Export failed: ${action.payload.message}`,
    type: MessageBarType.error
  })
}

export const upcomingCapitalCallsExportSagas = [
  () => takeLatest(upcomingCapitalCallsExportActions.request, onExport),
  () => takeLatest(upcomingCapitalCallsExportActions.failure, onFailure)
]
