export type BalancesTableColumnName =
  keyof typeof balancesDetailTableColumnNames

export const balancesDetailTableGroupingColumnNames = {
  clientDashboardCategory: 'Default',
  custodianName: 'Institution / Custodian',
  taxable: 'Taxable / Tax-deferred',
  legalEntityId: 'Entity',
  accountRegistration: 'Account Type',
  ausClass: 'Managed / Brokerage',
  cag: 'Custom Account Groups'
}
export const balancesDetailTableColumnNames = {
  accountNumber: 'Account',
  cashmoneyaccounts: 'Cash',
  pricedinvestments: 'Priced Investments',
  annuity: 'Annuities and Insurance',
  other: 'Other',
  accruedIncome: 'Accrued Income',
  loans: 'Loans',
  netBalance: 'Total',
  todaysChange: `Day's Change`,
  availableToWithdraw: 'Available to Withdraw',
  availableToInvest: 'Available to Invest',
  clientDashboardCategoryCode: 'clientDashboardCategoryCode',
  ...balancesDetailTableGroupingColumnNames
}
