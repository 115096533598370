import { push } from 'connected-react-router'
import {
  call,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest
  // tslint:disable-next-line: no-submodule-imports
} from 'typed-redux-saga'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import { getRockefellerApiOptions } from '../../../../../store/shared/sagas'
import { getRdotUsername } from '../../../../../store/user/selectors'
import { pilotFetaureActions } from '../../../../Payments/features/Dashboard/store/actions'
import {
  fetchPendingDistributionService,
  fetchCompletedDistributionService,
  fetchDocumentService,
  deleteDistributionService
} from './../api/distributionService'
import {
  pendingDistributionDataActions,
  completedDistributionDataActions,
  discretionaryDistributionNavigationActions,
  documentDataActions,
  deleteDistributionActions
} from './actions'
// import { getStatelocation } from './selectors'
import { IDistributionInfo } from './types'

/**
 * Generator function saga to fetch distribution info and call api
 */

function* handleFetchPendingDistributionInfo() {
  // Call fetch matrix info api here....
  console.log('Pending Distribution list Saga generator')
  try {
    const options = yield* call(getRockefellerApiOptions)
    const email = yield* select(getRdotUsername)

    if (!email) {
      throw new Error('Invalid State: email is undefined')
    }

    yield put(pilotFetaureActions.request())
    const { pilotFeatureSuccess } = yield* race({
      pilotFeatureSuccess: take(pilotFetaureActions.success),
      pilotFeatureFailure: take(pilotFetaureActions.failure)
    })

    const showAllTxn =
      (pilotFeatureSuccess &&
        pilotFeatureSuccess?.payload?.pilotFeatures?.filter(
          (x) => x.featureName === 'DISCRETIONARY_DIST_ALLTXN'
        )?.[0]?.isCalculated) ||
      'N'

    const data: IDistributionInfo[] = yield call(() =>
      fetchPendingDistributionService(email, showAllTxn, options)
    )

    const result = data.map((d) => {
      return {
        ...d,
        requestDate: new Date(d.requestDate).toISOString()
      }
    })
    yield put(pendingDistributionDataActions.success(result))
  } catch (e: any) {
    yield put(pendingDistributionDataActions.failure(e))
  }
}

function* handleFetchCompletedDistributionInfo() {
  // Call fetch matrix info api here....
  console.log('Completed Distribution list Saga generator')
  try {
    const options = yield* call(getRockefellerApiOptions)
    const email = yield* select(getRdotUsername)

    if (!email) {
      throw new Error('Invalid State: email is undefined')
    }

    yield put(pilotFetaureActions.request())
    const { pilotFeatureSuccess } = yield* race({
      pilotFeatureSuccess: take(pilotFetaureActions.success),
      pilotFeatureFailure: take(pilotFetaureActions.failure)
    })

    const showAllTxn =
      (pilotFeatureSuccess &&
        pilotFeatureSuccess?.payload?.pilotFeatures?.filter(
          (x) => x.featureName === 'DISCRETIONARY_DIST_ALLTXN'
        )?.[0]?.isCalculated) ||
      'N'

    const data = yield* call(
      fetchCompletedDistributionService,
      email,
      showAllTxn,
      options
    )
    const result = data.map((d) => {
      return {
        ...d,
        requestDate: new Date(d.requestDate).toISOString()
      }
    })

    yield put(completedDistributionDataActions.success(result))
  } catch (e: any) {
    yield put(completedDistributionDataActions.failure(e))
  }
}

function* handleNavigateToNewDistribution(
  action: ReturnType<
    typeof discretionaryDistributionNavigationActions.naviagteToNewDistribution
  >
) {
  const id = action?.payload ? action.payload : ''
  yield put(push(`/trusts/distribution?distributionid=${id}`))
}

function* handleNaviagteToDistributions() {
  yield put(push('/trusts'))
}

function* handleFetchDocument(
  action: ReturnType<typeof documentDataActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: string = yield call(() =>
      fetchDocumentService(action.payload, options)
    )
    yield put(documentDataActions.success(data))
  } catch (e: any) {
    yield put(documentDataActions.failure(e))
  }
}

function* handleDeleteDistribution(
  action: ReturnType<typeof deleteDistributionActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const email = yield* select(getRdotUsername)

    if (!email) {
      throw new Error('Invalid State: email is undefined')
    }

    yield call(deleteDistributionService, action.payload, email, options)
    yield put(deleteDistributionActions.success(action.payload))
  } catch (e: any) {
    yield put(deleteDistributionActions.failure(e))
  }
}

export const sagas = [
  () =>
    takeEvery(
      pendingDistributionDataActions.request,
      handleFetchPendingDistributionInfo
    ),
  () =>
    takeEvery(
      completedDistributionDataActions.request,
      handleFetchCompletedDistributionInfo
    ),
  () =>
    takeLatest(
      discretionaryDistributionNavigationActions.naviagteToNewDistribution,
      handleNavigateToNewDistribution
    ),
  () => takeLatest(documentDataActions.request, handleFetchDocument),
  () => takeEvery(deleteDistributionActions.request, handleDeleteDistribution),
  () =>
    takeEvery(
      discretionaryDistributionNavigationActions.naviagteToDistribution,
      handleNaviagteToDistributions
    )
]
