import { Stack, Text } from '@fluentui/react'
import React from 'react'
import { IOrder } from '../../../api/order.types'
import { DateCell } from '../../../features/Lists/core/components/DateCell'
import { IListsCellComponent } from '../../../features/Lists/core/components/ListsDataTable'
import { NumberCell } from '../../../features/Lists/core/components/NumberCell'
import { TextCell } from '../../../features/Lists/core/components/TextCell'
import { USDCell } from '../../../features/Lists/core/components/USDCell'
import { ConnectedMasked } from '../../../shared/components/MaskedText'
import { OrderColumnIds } from './ColumnDefinitions'
import { RefNoCellContainer } from './RefNoCellContainer'

export const OrderCell: IListsCellComponent<IOrder> = ({
  columnDefinition,
  item
}) => {
  const Cell = CellComponents[columnDefinition.id]
  return Cell && <Cell order={item} />
}

const CellComponents: {
  [key: string]: React.FC<{ order: IOrder }>
} = {
  [OrderColumnIds.accountNumber]: ({ order }) => (
    <TextCell>
      <ConnectedMasked text={order.accountNumber} />
    </TextCell>
  ),
  [OrderColumnIds.symbol]: ({ order }) => <TextCell>{order.Symbol}</TextCell>,
  [OrderColumnIds.tradeDate]: ({ order }) => (
    <DateCell value={order.tradeDate?.slice(0, -1)} />
  ),
  [OrderColumnIds.settleDate]: ({ order }) => (
    <DateCell value={order.SettleDate?.slice(0, -1)} />
  ),
  [OrderColumnIds.orderEntryDate]: ({ order }) => (
    <DateCell value={order.orderEntryDate} showTime={true} />
  ),
  [OrderColumnIds.action]: ({ order }) => <TextCell>{order.Action}</TextCell>,
  [OrderColumnIds.description]: ({ order }) => (
    <TextCell>{order.description}</TextCell>
  ),
  [OrderColumnIds.quantity]: ({ order }) => (
    <NumberCell value={order.Quantity} />
  ),
  [OrderColumnIds.executedQuantity]: ({ order }) => (
    <NumberCell value={order.ExecQuantity} />
  ),
  [OrderColumnIds.cusip]: ({ order }) => <TextCell>{order.CUSIP}</TextCell>,
  [OrderColumnIds.timeInForce]: ({ order }) => (
    <TextCell>{order.TimeinForce}</TextCell>
  ),
  [OrderColumnIds.orderRefNo]: ({ order }) => (
    <RefNoCellContainer order={order} />
  ),
  [OrderColumnIds.orderRefNoAndOrderEntryDate]: ({ order }) => (
    <Stack styles={{ root: { minWidth: 0 } }}>
      <RefNoCellContainer order={order} />
      <DateCell value={order.orderEntryDate} showTime={true} size="small" />
    </Stack>
  ),
  [OrderColumnIds.accountNoAndLegalEntity]: ({ order }) => (
    <Stack styles={{ root: { minWidth: 0 } }}>
      <TextCell>
        <ConnectedMasked text={order.accountNumber} />
      </TextCell>
      <Text variant="small" nowrap={true}>
        {order.LegalEntityName}
      </Text>
    </Stack>
  ),
  [OrderColumnIds.symbolCusipDesc]: ({ order }) => (
    <Stack styles={{ root: { minWidth: 0 } }}>
      <Stack horizontal={true} tokens={{ childrenGap: 8 }}>
        <Text styles={{ root: { fontWeight: 'bold' } }} nowrap={true}>
          {order.Symbol}
        </Text>
        <Text variant="small" nowrap={true}>
          {order.CUSIP}
        </Text>
      </Stack>
      <Text variant="small" nowrap={true}>
        {order.description}
      </Text>
    </Stack>
  ),
  [OrderColumnIds.productType]: ({ order }) => (
    <TextCell>{order.AssetType}</TextCell>
  ),
  [OrderColumnIds.assetType]: ({ order }) => (
    <TextCell>{order.assetClassLevel1}</TextCell>
  ),
  [OrderColumnIds.assetTypeDetail]: ({ order }) => (
    <TextCell>{order.assetClassLevel3}</TextCell>
  ),
  [OrderColumnIds.productSubType]: ({ order }) => (
    <TextCell>{order.AssetSubType}</TextCell>
  ),
  [OrderColumnIds.priceType]: ({ order }) => (
    <TextCell>{order.PriceType}</TextCell>
  ),
  [OrderColumnIds.price]: ({ order }) => (
    <USDCell value={order.ExecutionPrice} fractionDigits={4} />
  ),
  [OrderColumnIds.orderValue]: ({ order }) => (
    <USDCell value={order.orderValue} />
  ),
  [OrderColumnIds.tradeCount]: ({ order }) => (
    <NumberCell value={order.tradecount} fractionDigits={0} />
  ),
  [OrderColumnIds.legalEntityName]: ({ order }) => (
    <TextCell>{order.LegalEntityName}</TextCell>
  ),
  [OrderColumnIds.executionStatCode]: ({ order }) => (
    <TextCell>{order.ExecutionStatCode}</TextCell>
  ),
  [OrderColumnIds.orderCancelCode]: ({ order }) => (
    <TextCell>{order.OrderCancelCode}</TextCell>
  ),
  [OrderColumnIds.solicitedInd]: ({ order }) => (
    <TextCell>{order.solicitedInd}</TextCell>
  ),
  [OrderColumnIds.commisionAmount]: ({ order }) => (
    <USDCell value={order.commisionAmount} />
  ),
  [OrderColumnIds.concessionAmount]: ({ order }) => (
    <USDCell value={order.concessionAmount} />
  ),
  [OrderColumnIds.advisorID]: ({ order }) => (
    <TextCell>{order.AdvisorID}</TextCell>
  ),
  [OrderColumnIds.managedaccInd]: ({ order }) => (
    <TextCell>{order.managedaccInd}</TextCell>
  )
}
