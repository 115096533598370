export const ELIGIBLE_ACCOUNT_TYPES = [
  'CP',
  'I',
  'IC',
  'COMP',
  'TIC',
  'TIE',
  'UF',
  'J',
  'LLC',
  'NP',
  'PT',
  'SP',
  'TODI',
  'TODJ',
  'TODE',
  'TRUA',
  'TRUW',
  'UA'
]
