import { IModuleDefinition } from '../types'
import { HomeContainer } from './Home'

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/',
    private: true,
    component: HomeContainer
  },
  name: 'Home'
}

export default moduleDefinition
