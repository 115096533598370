import { lazy } from 'react'
import { IModuleDefinition } from '../types'

const ProductsContainer = lazy(() => import('./ProductsContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/products/*',
    private: true,
    component: ProductsContainer
  },
  name: 'Products'
}

export default moduleDefinition
