import { css } from '@emotion/react'
import { HeaderContext, Row, createColumnHelper } from '@tanstack/react-table'
import {
  EntityType,
  IncomeReturnType,
  PerformanceData,
  PerformanceDateRange
} from 'api/performance.types'
import { sum, round } from 'lodash'
import { AccountNumberCell } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/AccountNumberCell'
import { HighlightSearchText } from 'modules/Advisory/modules/Rdot360/components/shared/DetailTables/HighlightSearchText'
import { PercentCellWithColor } from '../../components/PercentCellWithColor'
import { HeaderContainer } from '../../components/shared/DetailTables/HeaderContainer'
import { USDCellWithColor } from '../../components/USDCellWithColor'
import { InvalidPerformanceCell } from '../../features/Performance/InvalidPerformanceCell'
import {
  PerfDateRange,
  getIsPartialPeriod,
  getIsValidPerformance
} from '../../features/Performance/store'
import { DateCell, USDCell } from '../../features/WidgetTable/WidgetTable'

interface IPerformanceDetailTableColumnNames {
  [key: string]: string
}

export const performanceDetailTableColumnNames: IPerformanceDetailTableColumnNames =
  {
    group: 'Group',
    account: 'Account',
    inceptionDate: 'Inception Date',
    startValue: 'Start Value',
    netFlows: 'Net Flows',
    investmentEarnings: 'Investment Earnings',
    endingValue: 'Ending Value',
    NetTWRMTD: 'Net TWR MTD',
    NetTWRQTD: 'Net TWR QTD',
    NetTWRYTD: 'Net TWR YTD',
    NetTWRITD: 'Net TWR ITD',
    NetIrrMTD: 'Net IRR MTD',
    NetIrrQTD: 'Net IRR QTD',
    NetIrrYTD: 'Net IRR YTD',
    NetIrrITD: 'Net IRR ITD'
  }

const helperClasses = {
  rightEllipsis: css({
    textAlign: 'right',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  })
}

const getOrderedColumns = (
  assetType: IncomeReturnType,
  dateRange: PerfDateRange,
  performanceType: string
) => {
  const getNetColumn = (columnDateRange: PerfDateRange) => {
    const accessorId = `${assetType}${columnDateRange}`
    const columnId = performanceDetailTableColumnNames[accessorId]

    const column = columnHelper.accessor(
      (row) => {
        const performanceData =
          row?.[`${columnDateRange}${performanceType}Performance`]
        return performanceData?.[assetType]
      },
      {
        id: columnId,
        sortingFn: getSortingFn(columnDateRange, performanceType, assetType),
        cell: ({ row }) => {
          const value =
            row.original?.[`${columnDateRange}${performanceType}Performance`]?.[
              assetType
            ]

          const isPartialPeriod = getIsPartialPeriod(
            columnDateRange,
            row.original,
            row.getParentRow()?.original
          )

          const isValidPerformance = getIsValidPerformance(value)
          const hasValue = value != null

          return (
            <>
              {hasValue && isPartialPeriod && isValidPerformance && '*'}
              {!isValidPerformance && !row.original?.override99 && (
                <InvalidPerformanceCell />
              )}
              {hasValue && (isValidPerformance || row.original?.override99) && (
                <PercentCellWithColor
                  value={round(value, 4) * 100}
                  showTwoDecimalPrecisionForZero
                />
              )}
              {!hasValue && '--'}
            </>
          )
        },
        header: (props) => (
          <HeaderContainer
            title={performanceDetailTableColumnNames[accessorId]}
            isSorted={props.column.getIsSorted()}
            rightAlign={true}
          />
        ),
        size: 100,
        enableGlobalFilter: false
      }
    )

    return column
  }

  switch (dateRange) {
    case PerformanceDateRange.monthToDate: {
      return [
        getNetColumn(PerformanceDateRange.monthToDate),
        getNetColumn(PerformanceDateRange.quarterToDate),
        getNetColumn(PerformanceDateRange.yearToDate),
        getNetColumn(PerformanceDateRange.inceptionToDate)
      ]
    }
    case PerformanceDateRange.quarterToDate: {
      return [
        getNetColumn(PerformanceDateRange.quarterToDate),
        getNetColumn(PerformanceDateRange.monthToDate),
        getNetColumn(PerformanceDateRange.yearToDate),
        getNetColumn(PerformanceDateRange.inceptionToDate)
      ]
    }
    case PerformanceDateRange.yearToDate: {
      return [
        getNetColumn(PerformanceDateRange.yearToDate),
        getNetColumn(PerformanceDateRange.monthToDate),
        getNetColumn(PerformanceDateRange.quarterToDate),
        getNetColumn(PerformanceDateRange.inceptionToDate)
      ]
    }
    case PerformanceDateRange.inceptionToDate: {
      return [
        getNetColumn(PerformanceDateRange.inceptionToDate),
        getNetColumn(PerformanceDateRange.monthToDate),
        getNetColumn(PerformanceDateRange.quarterToDate),
        getNetColumn(PerformanceDateRange.yearToDate)
      ]
    }
    default:
      return []
  }
}

const columnHelper = createColumnHelper<PerformanceData>()

const SumFooter: React.FC<HeaderContext<PerformanceData, unknown>> = ({
  table,
  column
}) => {
  const total = sum(
    table
      .getPreGroupedRowModel()
      .rows.map(({ getValue }) => getValue<number>(column.id) || 0)
  )

  return <USDCell value={total} defaultValue="--" />
}

const getSortingFn =
  (dateRange: string, performanceType: string, sortField: string) =>
  (a: Row<PerformanceData>, b: Row<PerformanceData>) => {
    const x =
      a.original?.[`${dateRange}${performanceType}Performance`]?.[sortField]
    const y =
      b.original?.[`${dateRange}${performanceType}Performance`]?.[sortField]

    if (x == null) {
      return -1
    }
    if (y == null) {
      return 1
    }
    if (Math.abs(x) > 0.99) {
      return -1
    }
    return (x || 0) > (y || 0) ? 1 : (x || 0) < (y || 0) ? -1 : 0
  }

const getPerformanceTableColumns = (
  search: string,
  assetType: IncomeReturnType,
  dateRange: PerfDateRange,
  performanceType: string,
  entityType: EntityType
) => [
  columnHelper.accessor(
    (row) =>
      row?.EntityType === EntityType.group ? row?.Name : row?.Account?.[0],
    {
      id: performanceDetailTableColumnNames.group,
      cell: function Name(props) {
        const value = props.row?.original
        const isGroup = props.row?.original?.EntityType === EntityType.group

        return isGroup ? (
          <div title={value?.Name}>
            <HighlightSearchText text={value?.Name} search={search} />
          </div>
        ) : (
          <AccountNumberCell
            accountIdOrKey={value?.Account?.[0]}
            search={search}
            fallbackValue={value?.Name}
          />
        )
      },
      header: (props) => (
        <HeaderContainer
          title={entityType === EntityType.group ? 'Group' : 'Account'}
          isSorted={props.column.getIsSorted()}
        />
      ),
      minSize: 228,
      enableGlobalFilter: true,
      footer: 'Total'
    }
  ),
  columnHelper.accessor((row) => row?.ITDCPerformance?.StartDate, {
    id: performanceDetailTableColumnNames.inceptionDate,
    sortUndefined: -1,
    cell: (props) => (
      <DateCell
        defaultValue="--"
        value={props.row.original?.ITDCPerformance?.StartDate}
      />
    ),
    header: (props) => (
      <HeaderContainer
        title={performanceDetailTableColumnNames.inceptionDate}
        isSorted={props.column.getIsSorted()}
        rightAlign={true}
      />
    ),
    size: 90,
    enableGlobalFilter: false
  }),
  columnHelper.accessor(
    (row) => row?.[`${dateRange}${performanceType}Performance`]?.StartValue,
    {
      id: `${performanceDetailTableColumnNames.startValue} ${dateRange} ${performanceType}`,
      sortUndefined: -1,
      cell: (props) => (
        <USDCell
          defaultValue="--"
          value={
            props.row.original?.[`${dateRange}${performanceType}Performance`]
              ?.StartValue
          }
        />
      ),
      header: (props) => (
        <HeaderContainer
          title={performanceDetailTableColumnNames.startValue}
          isSorted={props.column.getIsSorted()}
          rightAlign={true}
        />
      ),
      minSize: 90,
      footer: SumFooter,
      enableGlobalFilter: false
    }
  ),
  columnHelper.accessor(
    (row) => row?.[`${dateRange}${performanceType}Performance`]?.Netflows,
    {
      id: `${performanceDetailTableColumnNames.netFlows} ${dateRange} ${performanceType}`,
      sortUndefined: -1,
      cell: (props) => (
        <div css={helperClasses.rightEllipsis}>
          {props.row.original?.[`${dateRange}${performanceType}Performance`]
            ?.Netflows !== undefined ? (
            <USDCellWithColor
              fractionDigit={0}
              value={
                props.row.original?.[
                  `${dateRange}${performanceType}Performance`
                ]?.Netflows
              }
            />
          ) : (
            '--'
          )}
        </div>
      ),
      header: (props) => (
        <HeaderContainer
          title={performanceDetailTableColumnNames.netFlows}
          isSorted={props.column.getIsSorted()}
          rightAlign={true}
        />
      ),
      minSize: 90,
      footer: SumFooter,
      enableGlobalFilter: false
    }
  ),
  columnHelper.accessor(
    (row) =>
      row?.[`${dateRange}${performanceType}Performance`]?.NetInvestmentGain,
    {
      id: `${performanceDetailTableColumnNames.investmentEarnings} ${dateRange} ${performanceType}`,
      sortUndefined: -1,
      cell: (props) => (
        <div css={helperClasses.rightEllipsis}>
          {props.row.original?.[`${dateRange}${performanceType}Performance`]
            ?.NetInvestmentGain !== undefined ? (
            <USDCellWithColor
              fractionDigit={0}
              value={
                props.row.original?.[
                  `${dateRange}${performanceType}Performance`
                ]?.NetInvestmentGain
              }
            />
          ) : (
            '--'
          )}
        </div>
      ),
      header: (props) => (
        <HeaderContainer
          title={performanceDetailTableColumnNames.investmentEarnings}
          isSorted={props.column.getIsSorted()}
          rightAlign={true}
        />
      ),
      footer: SumFooter,
      minSize: 90
    }
  ),
  columnHelper.accessor(
    (row) => row?.[`${dateRange}${performanceType}Performance`]?.EndValue,
    {
      id: `${performanceDetailTableColumnNames.endingValue} ${dateRange} ${performanceType}`,
      sortUndefined: -1,
      cell: (props) => (
        <USDCell
          defaultValue="--"
          value={
            props.row.original?.[`${dateRange}${performanceType}Performance`]
              ?.EndValue
          }
        />
      ),
      header: (props) => (
        <HeaderContainer
          title={performanceDetailTableColumnNames.endingValue}
          isSorted={props.column.getIsSorted()}
          rightAlign={true}
        />
      ),
      minSize: 90,
      footer: SumFooter,
      enableGlobalFilter: false
    }
  ),
  ...getOrderedColumns(assetType, dateRange, performanceType)
]

export default getPerformanceTableColumns
