import { css } from '@emotion/react'

export const getGainLossesIncomeClasses = () => ({
  container: css({
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    height: '100%'
  }),
  dropdownContainer: css({
    fontSize: '14px',
    lineHeight: '19px',
    width: '160px',
    marginTop: '5px',
    marginBottom: '10px'
  }),
  note: css({
    fontSize: 10,
    fontWeight: 300,
    bottom: 0,
    position: 'absolute',
    marginTop: '5px'
  })
})
