import { combineReducers } from 'redux'
import { revenueModulesReducer, revenueModulesSagas } from '../modules'
import {
  mostRecentClosedMonthFetchReducer,
  mostRecentClosedMonthFetchSagas
} from './mostRecentClosedDateFetch'

export const revenueModuleReducer = combineReducers({
  modules: revenueModulesReducer,
  mostRecentClosedMonthFetch: mostRecentClosedMonthFetchReducer
})

export const revenueModuleSagas = [
  ...revenueModulesSagas,
  ...mostRecentClosedMonthFetchSagas
]
