import { lazy } from 'react'
import { combineReducers } from 'redux'
import { IModuleDefinition } from '../../../types'
import { excelReportsReducer, excelReportsSagas } from './excel-reports'
import { optionsIncomeAndExpenseReportReducer } from './options-income-and-expense-report'

const ReportsContainer = lazy(() => import('./ReportsContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/reports/*',
    private: true,
    component: ReportsContainer
  },
  name: 'Reports'
}

export const advisoryReportsReducer = combineReducers({
  excelReports: excelReportsReducer,
  optionsAndIncomeExpense: optionsIncomeAndExpenseReportReducer
})

export const advisoryReportsSagas = [...excelReportsSagas]

export default moduleDefinition
