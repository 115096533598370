import { AnnuityPPLIAssetsColumnName } from '../../shared/annuityPPLIAssets/columnNameTypes'
import { AnnuityPPLIName } from '../../shared/annuityPPLIContracts'

type AnnuityPPLINameExtended = AnnuityPPLIName | AnnuityPPLIAssetsColumnName
type ColumnName = Record<any, AnnuityPPLINameExtended>

export const columnNames: ColumnName = {
  accountNumber: 'Account Number',
  address: 'Address',
  advisorId: 'Advisor Id',
  advisorName: 'Advisor Name',
  advisorTeam: 'Advisor Team',
  regionName: 'Division Name',
  agents: 'Agents',
  assetClassL1: 'Asset Class Level-1',
  assetClassL2: 'Asset Class Level-2',
  assetClassL3: 'Asset Class Level-3',
  assetClassL4: 'Asset Class Level-4',
  assetSubType: 'Asset Sub Type',
  assetType: 'Asset Type',
  beneficiaryName: 'Beneficiary Name',
  carrier: 'Carrier',
  cashValue: 'Cash Value',
  cleanCarrierName: 'Clean Carrier Name',
  contractNumber: 'Contract Number',
  contractType: 'Contract Type',
  cusip: 'CUSIP',
  deathBenefit: 'Death Benefit',
  description: 'Description',
  feeType: 'Fee Type',
  highBenefitName: 'High Benefit Name',
  householdName: 'Household Name',
  incomeBenefitGMIB: 'Income Benefit (GMIB)',
  insuredAnnuitants: 'Insured / Annuitants',
  issueDate: 'Issue Date',
  lastUpdated: 'Last Updated',
  legalEntityName: 'Legal Entity Name',
  maturityDate: 'Maturity Date',
  maxBenefit: 'Max Benefit',
  ownerClient: 'Owner / Client',
  policyLoanAmount: 'Policy Loan amount',
  policyStatus: 'Policy Status',
  premium: 'Premium',
  productName: 'Product Name',
  productType: 'Product Type',
  rmds: 'RMDs',
  state: 'State',
  surrenderCharge: 'Surrender Charge',
  surrenderChargeExpiration: 'Surrender Charge Expiration',
  surrenderValue: 'Surrender Value',
  tbfPercentage: 'TBF Percentage',
  underlyingAssetClassL1: 'Underlying Asset Class Level-1',
  underlyingAssetClassL2: 'Underlying Asset Class Level-2',
  underlyingAssetClassL3: 'Underlying Asset Class Level-3',
  underlyingAssetClassL4: 'Underlying Asset Class Level-4',
  holdingPercent: 'Holding %',
  marketValue: 'Market Value',
  underlyingAssetSubType: 'Underlying Asset Sub Type',
  underlyingAssetType: 'Underlying Asset Type',
  underlyingSecurityCUSIP: 'Underlying Security CUSIP',
  underlyingSecurityDescription: 'Underlying Security Description'
} as const
