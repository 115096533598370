import { DetailsListLayoutMode } from '@fluentui/react'
import { PropsWithChildren } from 'react'
import { HorizontalScrollContainer } from 'shared/components/HorizontalScrollContainer'

export const HorizontalScrollContainerWrapper: React.FC<
  PropsWithChildren<{ layoutMode?: DetailsListLayoutMode }>
> = ({ children, layoutMode }) =>
  layoutMode === DetailsListLayoutMode.fixedColumns ? (
    <HorizontalScrollContainer>{children}</HorizontalScrollContainer>
  ) : (
    <>{children}</>
  )
