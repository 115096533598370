import { DefaultButton, ProgressIndicator } from '@fluentui/react'
import { groupBy, keyBy } from 'lodash'
import { useCallback } from 'react'
import { PaletteComponent } from 'shared/components/Palette'
import { exportDataToExcel } from 'shared/xlsx'
import { NextGenCouncilTable } from './NextGenCouncilTable'
import { useNextGenCouncil } from './store'

export const NextGenCouncilDashboard: React.FC = () => {
  const {
    nextGenCouncilConfig,
    accountsByMember,
    householdsByMember,
    isFetching
  } = useNextGenCouncil()

  const exportToExcel = useCallback(() => {
    if (
      !householdsByMember ||
      !accountsByMember ||
      !nextGenCouncilConfig?.value
    ) {
      return
    }

    const memberLookup = keyBy(
      nextGenCouncilConfig.value,
      ({ rcm_nextgencouncilmemberid }) => rcm_nextgencouncilmemberid || ''
    )

    const households = Object.entries(householdsByMember).flatMap(
      ([key, households]) => {
        const member = memberLookup[key]
        return households.map((household) => [
          member.rcm_name,
          household.householdName,
          household.householdId,
          household.householdKPI?.AumTotal
        ])
      }
    )

    households.unshift([
      'Next Gen Member',
      'Household Name',
      'Household Id',
      'AUS'
    ])

    const accounts = Object.entries(accountsByMember).flatMap(
      ([key, accountResult]) => {
        const member = memberLookup[key]
        return (
          accountResult?.householdAccounts.map((account) => [
            member.rcm_name,
            account.CustodyAccount,
            account.LegalEntityName,
            account.householdName,
            account.ClientAdvisor,
            account.ClientAdvisorID,
            account.AccountKPIs?.AccountTotal,
            account.LegalEntityID,
            account.householdId
          ]) || []
        )
      }
    )

    accounts.unshift([
      'Next Gen Member',
      'Account',
      'Legal Entity Name',
      'Household Name',
      'Advisor',
      'Advisor ID',
      'AUS',
      'Legal Entity ID',
      'Household Id'
    ])

    const memberToAccount = Object.entries(accountsByMember).flatMap(
      ([key, accountResult]) => {
        const member = memberLookup[key]
        const groups = groupBy(
          accountResult?.householdAccounts,
          ({ CustodyAccount }) => CustodyAccount?.split('/')?.[0] || ''
        )
        return Object.entries(groups).map(([key]) => [member.rcm_name, key])
      }
    )

    memberToAccount.unshift(['Next Gen Member', 'Account / Client Number'])

    const filename = 'Next Gen Council.xlsx'

    exportDataToExcel(
      {
        sheets: [
          { name: 'Households', data: households },
          { name: 'Accounts', data: accounts },
          { name: 'Associations', data: memberToAccount }
        ]
      },
      filename
    )
  }, [accountsByMember, householdsByMember, nextGenCouncilConfig])

  return (
    <div
      style={{ maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}
    >
      <DefaultButton
        styles={{ root: { alignSelf: 'flex-end' } }}
        onClick={exportToExcel}
      >
        Export to Excel
      </DefaultButton>
      <ProgressIndicator
        progressHidden={!isFetching}
        styles={{
          itemProgress: { padding: 0, margin: 0, height: '2px' },
          root: { height: '3px' }
        }}
      />
      <PaletteComponent>
        <NextGenCouncilTable />
      </PaletteComponent>
    </div>
  )
}

export default NextGenCouncilDashboard
