import {
  DatePicker,
  Stack,
  Label,
  Text,
  MessageBar,
  makeStyles
} from '@fluentui/react'
import { skipToken } from '@reduxjs/toolkit/query/react'
import { IHousehold } from 'api/household.types'
import React, { useCallback, useMemo, useState } from 'react'
import { AutoComplete } from 'shared/components/AutoComplete'
import { Separator } from 'shared/components/Separator'
import { useDebounce } from 'shared/hooks/useDebounce'
import { chartColors } from 'shared/services/theme'
import { useSearchHouseholdsQuery } from '../../api/datahub'
import { OptionsIncomeAndExpenseReportAccountList } from './OptionsIncomeAndExpenseReportAccountList'
import {
  useReportCriteriaEditCriteria,
  useReportCriteriaEditSelectedHousehold
} from './store'

const useClasses = makeStyles((theme) => ({
  selectedAccountContainer: {
    backgroundColor: theme.palette.neutralLighterAlt,
    border: `solid 1px ${theme.palette.neutralLighter}`,
    padding: '5px'
  },
  householdNameContainer: {
    backgroundColor: theme.palette.neutralLighterAlt,
    border: `solid 1px ${theme.palette.neutralLighter}`,
    padding: '5px',
    borderBottom: `solid 3px ${chartColors[1]}`
  }
}))

const maxDate = new Date()
maxDate.setDate(maxDate.getDate() - 1)

export const OptionsIncomeAndExpenseReportCriteria: React.FC<{
  selectedHouseholdId?: string
}> = () => {
  const classes = useClasses()

  const {
    fromDate,
    selectedAccounts,
    setFromDate,
    setSelectedAccounts,
    setSelectedHouseholdId,
    setToDate,
    toDate
  } = useReportCriteriaEditCriteria()
  const [householdSearchText, setHouseholdSearchText] = useState<string>()
  const debouncedSearchText = useDebounce(householdSearchText, 500)
  const isDebounced = useMemo(
    () => householdSearchText !== debouncedSearchText,
    [debouncedSearchText, householdSearchText]
  )

  const { data: searchHouseholds, isLoading: isSearchHouseholdsLoading } =
    useSearchHouseholdsQuery(debouncedSearchText ?? skipToken)

  const onHouseholdSearchItemSelected = useCallback(
    (item?: IHousehold) => {
      setSelectedHouseholdId(item?.id)
      setHouseholdSearchText('')
    },
    [setSelectedHouseholdId]
  )

  const onFromDateChanged = useCallback(
    (date?: Date | null) => setFromDate(date || undefined),
    [setFromDate]
  )
  const onToDateChanged = useCallback(
    (date?: Date | null) => setToDate(date || undefined),
    [setToDate]
  )
  const onHouseholdSearchTextChanged = useCallback(
    (text?: string) => setHouseholdSearchText(text),
    []
  )

  const onSelectedAccountsChanged = useCallback(
    (accounts?: string[]) => setSelectedAccounts(accounts),
    [setSelectedAccounts]
  )

  const {
    household: { data: selectedHousehold },
    accounts: { isLoading: isAccountsLoading, data: accounts }
  } = useReportCriteriaEditSelectedHousehold()

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Label required>1. Search for Household, Client, or Account</Label>
      <Stack>
        <Stack>
          <AutoComplete<IHousehold>
            value={householdSearchText}
            onSearchTextChanged={onHouseholdSearchTextChanged}
            items={searchHouseholds || []}
            onItemSelected={onHouseholdSearchItemSelected}
            itemComponent={HouseholdSearchAutoCompleteItem}
            showLoadingIndicator={isDebounced || isSearchHouseholdsLoading}
          />
        </Stack>
        <Stack.Item
          styles={{ root: { paddingTop: '8px' } }}
          className={classes.selectedAccountContainer}
        >
          {!!selectedHousehold && (
            <HouseholdSearchAutoCompleteItem item={selectedHousehold} />
          )}
          {!selectedHousehold && (
            <MessageBar>No Household selected.</MessageBar>
          )}
        </Stack.Item>
      </Stack>
      <Separator />
      <Stack>
        <Label required>2. Select Date Range</Label>
        <Stack horizontal={true} tokens={{ childrenGap: '16px' }}>
          <DatePicker
            placeholder="From Date"
            maxDate={maxDate}
            value={fromDate}
            onSelectDate={onFromDateChanged}
            style={{ width: '45%' }}
          />
          <DatePicker
            placeholder="To Date"
            minDate={fromDate}
            maxDate={maxDate}
            value={toDate}
            onSelectDate={onToDateChanged}
            style={{ width: '45%' }}
          />
        </Stack>
      </Stack>
      <Separator />
      <Label required>3. Select Accounts</Label>
      <Stack>
        <OptionsIncomeAndExpenseReportAccountList
          accounts={accounts}
          selectedAccounts={selectedAccounts}
          loading={isAccountsLoading}
          onSelectionChanged={onSelectedAccountsChanged}
        />
        {!accounts && <MessageBar>No Accounts found.</MessageBar>}
      </Stack>
      <Separator />
    </Stack>
  )
}

const HouseholdSearchAutoCompleteItem: React.FC<{
  item?: IHousehold
}> = ({ item }) => {
  const { householdName, Parties, Account, householdKPI } = item || {}
  const numClients = Parties?.length || 0
  const numAccounts = Account?.length || 0
  return (
    <Stack styles={{ root: { padding: '5px 10px', minWidth: 0 } }}>
      <Stack horizontal={true} horizontalAlign="space-between">
        <Stack>
          <Text nowrap={true} block={true} title={householdName}>
            {householdName || '(No Name)'}
          </Text>
          <Stack
            horizontal={true}
            tokens={{ childrenGap: 3 }}
            verticalAlign="center"
          >
            <Text variant="small" nowrap={true} block={true}>
              {numClients} client{numClients !== 1 && <>s</>}
            </Text>
            <Text variant="small" nowrap={true} block={true}>
              |
            </Text>
            <Text variant="small" nowrap={true} block={true}>
              {numAccounts} account{numAccounts !== 1 && <>s</>}
            </Text>
          </Stack>
        </Stack>
        <Text>${householdKPI?.AumTotal?.toLocaleString()}</Text>
      </Stack>
    </Stack>
  )
}
