import {
  OdataFilterCollectionOperatorEnum,
  OdataFilterOperatorEnum
} from 'api/odata'
import { intersectionWith } from 'lodash'
import { flow } from 'lodash/fp'
import { createSelector } from 'reselect'
import { call, put, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { AppState } from '..'
import { getRandomCapitalLetter, getRandomName } from '../../api/random'
import { search } from '../shared/sagas'
import { rdotUserActions } from '../user/rdotUser'
import { getEnableDataMaskingPreference } from '../user/selectors'

export interface IDomainItem {
  id?: string
  ClientAdvisorID?: string
  ClientAdvisor?: string
  ClientAdvisorTeam?: string
  AdvisorKPI?: IAdvisorKPI
  BusinessSegment?: string
}

export interface IAdvisorKPI {
  poolMemberKPI?: IPoolMemberKPI[]
}

export interface IPoolMemberKPI {
  ClientAdvisorKPI?: IClientAdvisorKPI[]
  Custodian?: string
  CustodianNo?: string
  RCMEmail?: string
}

export interface IClientAdvisorKPI {
  RepNoPercentage?: string
  AlternateInvestment?: number
  AumBrokerage?: number
  AumManaged?: number
  AumTotal?: number
  LoanOutstanding?: number
  NetNewMoney?: number
  TOA?: number
  TradeBrokerageCount?: number
  TradeCommision?: number
  TradeManagedCount?: number
  cashAvlToTrade?: number
  cashInFlow?: number
  cashOutFlow?: number
}

const DOMAIN_REQUEST = '@context/@domain/DOMAIN_REQUEST'
const DOMAIN_SUCCESS = '@context/@domain/DOMAIN_SUCCESS'
const DOMAIN_ERROR = '@context/@domain/DOMAIN_ERROR'

const domainContextRequestActions = {
  request: createAction(DOMAIN_REQUEST)(),
  success: createAction(DOMAIN_SUCCESS)<IDomainItem[]>(),
  failure: createAction(DOMAIN_ERROR)<Error>()
}

const SET_SELECTED_DOMAIN = '@context/@domain/SET_SELECTED_DOMAIN'
export const domainContextActions = {
  setSelectedDomain: createAction(SET_SELECTED_DOMAIN)<string[]>(),
  setSelectedDomainReps: createAction(SET_SELECTED_DOMAIN)<string[]>()
}

type DomainContextActionTypes =
  | ActionType<typeof domainContextRequestActions>
  | ActionType<typeof domainContextActions>

interface IDomainContextState {
  items?: IDomainItem[]
  loading?: boolean
  error?: Error
  selected: string[]
}

const initialState: IDomainContextState = {
  loading: true,
  selected: []
}

export const domainContextReducer = createReducer<
  IDomainContextState,
  DomainContextActionTypes
>(initialState)
  .handleAction(domainContextRequestActions.request, () => ({
    ...initialState,
    loading: true
  }))
  .handleAction(domainContextRequestActions.success, (state, action) => ({
    ...state,
    items: action.payload,
    loading: false
  }))
  .handleAction(domainContextRequestActions.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false
  }))
  .handleAction(domainContextActions.setSelectedDomain, (state, action) => ({
    ...state,
    selected: action.payload
  }))

const getDomainContextState = (state: AppState) => state.context.domain
export const getIsDomainContextLoading = flow(
  getDomainContextState,
  (x) => x.loading
)
export const getDomainContextError = flow(getDomainContextState, (x) => x.error)
const getDomainContextItemsInternal = flow(
  getDomainContextState,
  (x) => x.items
)
export const getDomainContextItems = createSelector(
  [getDomainContextItemsInternal, getEnableDataMaskingPreference],
  (items, shouldMask) => {
    if (!shouldMask) {
      return items
    }
    const masked = items?.map((x) => {
      const advisorId = getRandomCapitalLetter() + x.ClientAdvisorID?.slice(-2)
      return {
        ...x,
        ClientAdvisor: getRandomName(),
        ClientAdvisorID: advisorId,
        ClientAdvisorTeam: getRandomName() + ' Team'
      }
    })

    return masked
  }
)
export const getSelectedDomainContextItems = createSelector(
  getDomainContextState,
  ({ selected, items }) =>
    intersectionWith(items, selected, (item, id) => item?.id === id)
)
export const getSelectedDomainContextRepCodes = createSelector(
  [getDomainContextState],
  ({ selected }) => selected
)
export const getIsAllDomainContextItemsSelected = createSelector(
  [getDomainContextState],
  ({ selected, items }) => selected.length === items?.length
)

const fetchDomain = function* () {
  try {
    const result = yield* call(search, 'advisor' as const, {
      top: 1000,
      select: [
        'ClientAdvisorID',
        'ClientAdvisor',
        'id',
        'ClientAdvisorTeam',
        'AdvisorKPI/poolMemberKPI',
        'BusinessSegment',
        'RegionName',
        'HubName'
      ],
      filters: [
        {
          or: [
            {
              operator: OdataFilterOperatorEnum.gt,
              value: 0,
              path: 'total/noofaccounts',
              type: 'number' as const
            },
            {
              operator: OdataFilterCollectionOperatorEnum.any,
              path: 'revenueDetHistory',
              filter: null
            }
          ]
        }
      ],
      orderBy: [
        {
          dataPath: 'total/noofaccounts',
          direction: 'desc' as const
        }
      ]
    })

    yield put(domainContextRequestActions.success(result.value))
  } catch (e: any) {
    yield put(domainContextRequestActions.failure(e))
  }
}

export const domainSagas = [
  () => takeLatest(domainContextRequestActions.request, fetchDomain),
  () =>
    takeLatest(rdotUserActions.loginSuccess, function* () {
      yield put(domainContextRequestActions.request())
    })
]
