import { skipToken } from '@reduxjs/toolkit/dist/query'
import {
  format,
  eachDayOfInterval,
  eachMonthOfInterval,
  differenceInDays,
  subDays,
  subMonths,
  startOfMonth,
  endOfMonth,
  startOfYear,
  subYears,
  endOfYear,
  min,
  max,
  startOfDay
} from 'date-fns'
import { groupBy, keys, sumBy, Dictionary, map } from 'lodash'
import { useMemo } from 'react'
import { parseDateISOStringInLocalTimezone } from 'shared'
import {
  HistoricalValueDateRangeType,
  useBalanceModuleStore
} from '../../modules/Dashboard/BalanceSummary/store'
import {
  IBalancesAssetAllocationApiRequest,
  useGetBalancesAssetallocationQuery
} from '../../store/balancesApi'
import {
  useRdot360AccountContext,
  useRdot360SelectedAccountsApiContext
} from '../../store/rdot360Context'

export interface BalanceSummaryChartData {
  categories: string[]
  totalAmounts: Array<number | null>
}

type HistoricalBalanceGain = 'Day' | 'Month'
const getGrain = (fromDate?: Date, toDate?: Date): HistoricalBalanceGain => {
  if (!fromDate || !toDate) {
    return 'Day'
  }

  const daysDiff = differenceInDays(toDate, fromDate)
  return daysDiff <= 60 ? 'Day' : 'Month'
}

const getDateRangeDates = (type: HistoricalValueDateRangeType) => {
  const today = startOfDay(new Date())

  switch (type) {
    case 'mostRecent':
      return [subDays(today, 29), today]
    case 'priorMonth': {
      const startOfCurrentMonth = startOfMonth(today)
      const startOfPriorMonth = subMonths(startOfCurrentMonth, 1)
      return [startOfPriorMonth, endOfMonth(startOfPriorMonth)]
    }
    case 'prior6Months':
      return [subMonths(today, 6), today]
    case 'prior12Months':
      return [subMonths(today, 12), today]
    case 'priorYear': {
      const startOfPriorYear = startOfYear(subYears(today, 1))
      return [startOfPriorYear, endOfYear(startOfPriorYear)]
    }
    case 'currentYear':
      return [startOfYear(today), today]
    default:
      return undefined
  }
}

export const useBalanceSummaryChart = () => {
  const { selectedAccountKeys } = useRdot360AccountContext()
  const { selectedDateRange, customFromDate, customToDate } =
    useBalanceModuleStore()
  const { contextId, apiContextAccounts } =
    useRdot360SelectedAccountsApiContext()
  const { cdmv2SelectedAccounts } = useRdot360AccountContext()

  const firstEstablishedDate = useMemo(() => {
    if (!cdmv2SelectedAccounts?.length) {
      return undefined
    }

    const establishDateArr =
      cdmv2SelectedAccounts
        ?.filter(({ establishDate }) => establishDate)
        .map(({ establishDate = '' }) =>
          parseDateISOStringInLocalTimezone(establishDate)
        ) ?? []

    const minEstablishDate = min([...establishDateArr, new Date()])
    return max([minEstablishDate, new Date('2019/01/01')])
  }, [cdmv2SelectedAccounts])

  const [fromDate, toDate] = useMemo(() => {
    if (selectedDateRange === 'customRange') {
      return [customFromDate, customToDate]
    }

    if (selectedDateRange === 'inceptionToDate') {
      return [firstEstablishedDate, new Date()]
    }

    return getDateRangeDates(selectedDateRange) || []
  }, [customFromDate, customToDate, firstEstablishedDate, selectedDateRange])

  const grain = useMemo(() => getGrain(fromDate, toDate), [fromDate, toDate])

  const payload = useMemo(() => {
    if (!fromDate || !toDate) {
      return undefined
    }

    const [formattedFromDate, formattedToDate] = [fromDate, toDate]
      .map(startOfDay)
      .map((x) => format(x, 'yyyy-MM-dd'))

    return {
      contextId,
      contextAccounts: apiContextAccounts,
      payload: {
        fromdate: formattedFromDate,
        todate: formattedToDate,
        grain: grain
      }
    } as IBalancesAssetAllocationApiRequest
  }, [apiContextAccounts, contextId, fromDate, grain, toDate])

  const skip = !apiContextAccounts?.length || !payload

  const {
    currentData: balancesResponseData,
    isFetching,
    refetch,
    error,
    isError,
    isUninitialized
  } = useGetBalancesAssetallocationQuery(skip ? skipToken : payload)

  const balanceSummaryChartData: BalanceSummaryChartData | undefined =
    useMemo(() => {
      if (!fromDate || !toDate || !balancesResponseData) {
        return
      }
      const selectedAccountsBalanceSummary = balancesResponseData?.filter((x) =>
        selectedAccountKeys?.includes(x?.accountKey)
      )
      const chartData = groupBy(
        selectedAccountsBalanceSummary,
        ({ asOfDate }) => asOfDate
      )
      const dateFormat = grain === 'Day' ? 'MM/dd' : 'MMM yyyy'

      const lookupData: Dictionary<number | undefined> = keys(chartData).reduce(
        (acc, item) => {
          const date = format(new Date(item), dateFormat)
          const total = sumBy(chartData[item], (x) => x.marketValue)
          return { ...acc, [date]: total }
        },
        {}
      )

      const dateValues =
        grain === 'Day'
          ? eachDayOfInterval({ start: fromDate, end: toDate })
          : eachMonthOfInterval({ start: fromDate, end: toDate })

      const categories = dateValues.map((item) => {
        return format(item, dateFormat)
      })

      const totalAmounts = map(categories, (date) => lookupData[date] ?? null)

      return {
        categories,
        totalAmounts
      }
    }, [balancesResponseData, fromDate, grain, selectedAccountKeys, toDate])

  return {
    isBalanceSummarychartFetching: isFetching,
    isBalanceSummaryChartError: !isUninitialized && isError,
    balanceSummaryChartRefetch: refetch,
    balanceSummaryChartData,
    error
  }
}
