import { IListsUiState } from 'features/Lists/core/contracts/IListsUIState'
import { useCallback } from 'react'
import { ListTypes, useList } from '../useList'
import {
  IUseListHookDefinition,
  hookDefinitions as coreHookDefinitions
} from '../useList/hookDefinitions'
import {
  IListPreferences,
  ISystemViewDefinition,
  useLazyListPreferences,
  useListPreferences
} from '../useListPreferences'

export interface IListWithPreferencesHookArgs {
  preferenceKey: string
  systemViews?: ISystemViewDefinition[]
  onBeforeApply?: (
    newState: Partial<IListsUiState>,
    currentState: Partial<IListsUiState>
  ) => Partial<IListsUiState>
  onBeforeSave?: (preferences: IListPreferences) => IListPreferences
}

const useOnApply = (
  definition: IUseListHookDefinition<string>,
  args: IListWithPreferencesHookArgs
) => {
  const { update, listState } = useList(definition)
  const { onBeforeApply } = args

  const onApply = useCallback(
    (newState: Partial<IListsUiState>) => {
      const newStateWithoutSearch = {
        ...newState,
        searchText: newState.searchText || ''
      } as Partial<IListsUiState>
      const { columnState, filters, searchText, orderBy } = listState
      const currentState = structuredClone({
        columnState,
        filters,
        searchText,
        orderBy
      }) as Partial<IListsUiState>
      const updated =
        onBeforeApply?.(newStateWithoutSearch, currentState) ||
        newStateWithoutSearch
      update(updated)
    },
    [listState, onBeforeApply, update]
  )

  return { onApply }
}

export const useListWithPreferences = (
  definition: IUseListHookDefinition<string>,
  args: IListWithPreferencesHookArgs
) => {
  const { listType } = definition
  const { listState, columnDefinitions } = useList(definition)
  const { preferenceKey, systemViews, onBeforeSave } = args
  const { onApply } = useOnApply(definition, args)

  const listPreferences = useListPreferences({
    preferenceKey: `${preferenceKey}/${listType}`,
    listState: listState as IListsUiState,
    systemViews,
    onApply,
    onBeforeSave
  })

  return { listState, listPreferences, columnDefinitions, systemViews, onApply }
}

const useLazyListWithPreferences = (
  definition: IUseListHookDefinition<string>,
  args: IListWithPreferencesHookArgs
) => {
  const { listType } = definition
  const { preferenceKey, systemViews } = args
  const { listState, columnDefinitions } = useList(definition)
  const { onApply } = useOnApply(definition, args)

  const lazyListPreferences = useLazyListPreferences({
    preferenceKey: `${preferenceKey}/${listType}`,
    columnDefinitions,
    systemViews,
    onApply
  })

  return { listState, lazyListPreferences, columnDefinitions, systemViews }
}

export const useCoreListWithPreferences = (
  type: ListTypes,
  args: IListWithPreferencesHookArgs
) => {
  const definition = coreHookDefinitions[type]
  return useListWithPreferences(definition, args)
}

export const useLazyCoreListWithPreferences = (
  type: ListTypes,
  args: IListWithPreferencesHookArgs
) => {
  const definition = coreHookDefinitions[type]
  return useLazyListWithPreferences(definition, args)
}
