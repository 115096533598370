import { createReducer } from 'typesafe-actions'
import {
  personDataActions,
  PersonActionTypes,
  addEditPersonDataActions
} from './actions'

import { IPerson, IPersonRequest } from './types'

export interface IPersonState {
  PersonList?: {
    person?: IPerson[]
    isLoading: boolean
    error?: Error
    pkId?: IPersonRequest
  }
  AddEditVendor?: {
    isLoading: boolean
    error?: Error
    person?: IPerson
  }
}

const initialState: IPersonState = {
  PersonList: {
    isLoading: true
  }
}

export const personReducer = createReducer<IPersonState, PersonActionTypes>(
  initialState
)
  .handleAction(personDataActions.request, (state, action) => ({
    ...state,
    PersonList: {
      person: undefined,
      isLoading: true,
      error: undefined,
      pkId: action.payload
    }
  }))
  .handleAction(personDataActions.success, (state, action) => ({
    ...state,
    PersonList: {
      isLoading: false,
      error: undefined,
      person: action.payload
    }
  }))
  .handleAction(personDataActions.failure, (state, action) => ({
    ...state,
    PersonList: {
      person: undefined,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(addEditPersonDataActions.request, (state) => ({
    ...state,
    AddEditVendor: {
      person: undefined,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(addEditPersonDataActions.success, (state, action) => ({
    ...state,
    AddEditVendor: {
      isLoading: false,
      error: undefined,
      person: action.payload
    }
  }))
  .handleAction(addEditPersonDataActions.failure, (state, action) => ({
    ...state,
    AddEditVendor: {
      isLoading: false,
      error: action.payload
    }
  }))
