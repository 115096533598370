import {
  DefaultButton,
  Persona,
  PersonaInitialsColor,
  PersonaSize,
  Stack,
  Text
} from '@fluentui/react'
import { PureComponent } from 'react'

export interface IUserButtonComponentProps {
  username: string
  onClick: () => void
}

export class UserButtonComponent extends PureComponent<IUserButtonComponentProps> {
  public render() {
    const { username, onClick } = this.props

    return (
      <DefaultButton
        onClick={onClick}
        styles={{
          root: {
            padding: 0,
            paddingLeft: 15,
            paddingRight: 5,
            border: 0,
            backgroundColor: 'transparent'
          }
        }}
      >
        <Stack
          verticalFill={true}
          horizontal={true}
          tokens={{ childrenGap: 5 }}
        >
          <Stack.Item align="center">
            <Stack>
              <Stack.Item align="end">
                <Text block={true} variant="small" nowrap={true}>
                  {username}
                </Text>
              </Stack.Item>
              <Stack.Item align="end">
                <Text block={true} variant="xSmall" nowrap={true}>
                  ROCKEFELLER CAPITAL MANAGEMENT
                </Text>
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item align="center">
            <Persona
              size={PersonaSize.size28}
              initialsColor={PersonaInitialsColor.coolGray}
              hidePersonaDetails={true}
            />
          </Stack.Item>
        </Stack>
      </DefaultButton>
    )
  }
}
