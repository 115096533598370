import { IOdataRequest } from 'api/odata.types'
import { orderBy, range } from 'lodash'
import pLimit from 'p-limit'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { tryAcquireAccessToken } from 'shared/services/auth'
import { getRockefellerApiConfig } from 'store/system'

export const useDatahubApi = () => {
  const config = useSelector(getRockefellerApiConfig)
  const acquireToken = useCallback(async () => {
    const scopes = config?.scopes

    if (!scopes) {
      throw new Error('No scopes provided for datahub api')
    }

    return tryAcquireAccessToken(scopes)
  }, [config?.scopes])

  return { acquireToken, config }
}

export const fetchAllPagedOdataResults = async <T>(
  baseRequest: IOdataRequest,
  odataRequestHandler: (req: IOdataRequest) => Promise<IOdataResult<T>>
) => {
  const peekTop = 50
  const chunkTop = 500

  const peek = await odataRequestHandler({
    ...baseRequest,
    top: peekTop,
    count: true
  })

  const count = peek?.['@odata.count'] || 0

  const numChunks = Math.ceil(Math.max(count - peekTop, 0) / chunkTop)
  const requests = range(0, numChunks).map((i): [number, IOdataRequest] => {
    const skip = peekTop + i * chunkTop
    return [
      i,
      {
        ...baseRequest,
        top: Math.min(chunkTop, count - skip),
        skip
      }
    ]
  })

  const limit = pLimit(7)
  const results = await Promise.all(
    requests.map(([i, x]) =>
      limit(async (): Promise<[number, IOdataResult<T> | undefined]> => {
        try {
          const result = await odataRequestHandler(x)
          return [i, result]
        } catch (e) {
          limit.clearQueue()
          throw e
        }
      })
    )
  )

  return [
    peek?.value || [],
    ...orderBy(results, ([index]) => index, 'asc').map(
      ([, result]) => result?.value || []
    )
  ].flat()
}
