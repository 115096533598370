import { Checkbox } from '@fluentui/react'
import { CellContext } from '@tanstack/react-table'
import { CDMv2Account } from 'api/nickname.types'
import { FC, useCallback } from 'react'
import { useRdot360AccountContext } from '../../store/rdot360Context'
import { useManageAccountNicknamesStore } from './store'

export const InputCheckboxTableCell: FC<{
  context: CellContext<CDMv2Account, unknown>
}> = ({ context: { row } }) => {
  const accountId = row.original.accountID || ''

  const { accountLookupByAccountIdOrKey } = useRdot360AccountContext()
  const { initialCheckedAccounts, checkedAccounts, setIsChecked } =
    useManageAccountNicknamesStore()
  const updatedValue = checkedAccounts[accountId]
  const value =
    updatedValue !== undefined
      ? updatedValue
      : initialCheckedAccounts[accountId] || false

  const accountDetails = accountLookupByAccountIdOrKey[accountId]

  const onChange = useCallback(
    (ev?: any, checked?: boolean): void => {
      setIsChecked({ accountId, checked: checked || false })
    },
    [accountId, setIsChecked]
  )

  return (
    <div css={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      {accountDetails?.RDOTAccountCategoryCode === '03' && (
        <Checkbox checked={value} onChange={onChange} />
      )}
      {accountDetails?.RDOTAccountCategoryCode !== '03' && (
        <Checkbox disabled />
      )}
    </div>
  )
}
