import {
  DefaultButton,
  FontIcon,
  FontWeights,
  IButtonStyles,
  IIconProps,
  IconButton,
  MessageBar,
  MessageBarType,
  Modal,
  PrimaryButton,
  TextField,
  mergeStyleSets,
  useTheme
} from '@fluentui/react'
import {
  IListMetaPreferenceDetails,
  IListMetaPreferences
} from 'features/Lists/hooks/useListPreferences'
import { useCallback, useState } from 'react'
import { stringTrim } from 'shared/strings'

const useTableStyles = () => {
  const theme = useTheme()
  const contentStyles = mergeStyleSets({
    container: {
      minWidth: 400,
      maxWidth: 800,
      border: '2px solid lightgray',
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      padding: '10px 20px 20px 20px'
    },
    header: [
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '0 0 5px 0'
      }
    ],
    body: {
      flex: '4 4 auto',
      padding: 0,
      overflowY: 'auto',
      maxHeight: '60vh',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 }
      }
    },
    footer: {
      padding: 0
    },
    input: {
      paddingBottom: 10
    },
    error: {
      marginBottom: 10
    },
    cancel: {
      marginRight: 20
    },
    pointer: {
      cursor: 'pointer'
    },
    viewTable: {
      borderSpacing: 0,
      width: '100%',
      tr: {
        height: 40,
        'td:first-child': {
          width: 30
        }
      },
      'tr:nth-child(odd)': {
        backgroundColor: theme.palette.neutralLighterAlt
      },
      'tr:nth-child(even)': {
        backgroundColor: theme.palette.white
      },
      'tr:hover': {
        backgroundColor: theme.palette.neutralLighter
      }
    },
    iconCol: {
      width: 30
    },
    overflowWrap: {
      wordBreak: 'break-all',
      cursor: 'pointer'
    },
    instructions: {
      margin: '0 0 10px 0',
      display: 'flex',
      'span:first-child': {
        marginRight: 10
      }
    },
    countHeading: {
      backgroundColor: theme.palette.themeDark,
      color: theme.palette.neutralLighter,
      margin: '0 0 10px 0',
      padding: 10,
      fontWeight: FontWeights.semibold
    },
    errorRow: {
      padding: 0,
      margin: '5px 0 10px 0',
      height: 30
    }
  })

  const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: 4,
      marginRight: 2
    },
    rootHovered: {
      color: theme.palette.neutralDark
    }
  }

  return { contentStyles, iconButtonStyles }
}

const cancelIcon: IIconProps = { iconName: 'Cancel' }

export const trimNumber = (name: string) => {
  let label = name
  const first = label?.lastIndexOf('(')
  if (first !== -1) {
    const last = label?.lastIndexOf(')')
    if (first < last && last === label?.length - 1) {
      const number = label?.substring(first + 1, last).trim()
      if (!isNaN(Number(number))) {
        label = label?.substring(0, first).trim()
      }
    }
  }
  return label
}

export const ModalManageViews: React.FC<{
  isOpen: boolean
  onClose: () => void
  onSubmit: (
    defaultViewId: string,
    customViews: IListMetaPreferenceDetails[]
  ) => void
  metaPreferences?: IListMetaPreferences
  systemViews?: IListMetaPreferenceDetails[]
}> = ({ isOpen, onClose, onSubmit, metaPreferences, systemViews }) => {
  const [disableSaveButton, setDisableSaveButton] = useState(true)
  const [previousSaveButton, setPreviousSaveButton] = useState(true)
  const [showError, setShowError] = useState('')
  const [customViews, setCustomViews] = useState([
    ...(metaPreferences?.savedContainerMeta || [])
  ])
  const defaultSystemViewId = systemViews ? systemViews[0].key : ''
  const [defaultViewId, setDefaultViewId] = useState(
    metaPreferences?.defaultViewId
      ? metaPreferences.defaultViewId
      : defaultSystemViewId
  )
  const [errorId, setErrorId] = useState('')
  const [renameId, setRenameId] = useState('')
  const [renameName, setRenameName] = useState('')
  const [originalName, setOriginalName] = useState('')

  const { contentStyles, iconButtonStyles } = useTableStyles()

  const preventDuplicateViewName = useCallback(
    (key: string, name: string) => {
      let count = 0
      let label = name
      // while the name is a duplicate, add a number to the end
      while (customViews?.some((x) => x.label === label && x.key !== key)) {
        label = trimNumber(label)
        label = `${label} (${++count})`
      }
      return label
    },
    [customViews]
  )

  const updateCustomViews = useCallback(
    (key: string, label: string) => {
      const views = customViews.map((view) => {
        if (view.key === key) {
          return { ...view, label }
        }
        return { ...view }
      })
      setCustomViews([...views])
    },
    [customViews]
  )

  const onSaveChanges = useCallback(() => {
    setDisableSaveButton(true)
    onClose()
    onSubmit(defaultViewId || '', customViews)
  }, [customViews, defaultViewId, onClose, onSubmit])

  const hideError = useCallback(() => {
    setShowError('')
    setErrorId('')
  }, [setErrorId, setShowError])

  const undoRename = useCallback(() => {
    updateCustomViews(renameId, originalName)
    setRenameId('')
    setRenameName('')
    hideError()
    setDisableSaveButton(previousSaveButton) // restore previous Save button disabled state
  }, [updateCustomViews, renameId, originalName, hideError, previousSaveButton])

  const onRenameView = useCallback(
    (key?: string, label?: string) => {
      if (showError) {
        undoRename()
      }
      setRenameId(key || '')
      setRenameName(label || '')
      setOriginalName(label || '')
      setPreviousSaveButton(disableSaveButton) // remember if Save is disabled
    },
    [showError, disableSaveButton, undoRename]
  )

  const onDeleteView = useCallback(
    (key?: string) => {
      if (key === defaultViewId && systemViews?.length) {
        setDefaultViewId(systemViews[0].key)
      }
      if (key === errorId) {
        hideError()
      }
      setCustomViews(customViews.filter((view) => view.key !== key))
      setDisableSaveButton(false)
    },
    [customViews, defaultViewId, errorId, hideError, systemViews]
  )

  const onChangeDefaultId = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDefaultViewId(event.target.value)
      if (showError) {
        return
      }
      setDisableSaveButton(false)
    },
    [setDefaultViewId, showError, setDisableSaveButton]
  )

  const onNameChange = useCallback(
    (ev: any, text?: string) => {
      updateCustomViews(renameId, text || '')
      setRenameName(text || '')
      const textTrimmed = stringTrim(text || '')
      const isWrongLength =
        textTrimmed && (textTrimmed.length < 3 || textTrimmed.length > 250)
      const isEmpty = textTrimmed === ''
      if (isWrongLength || isEmpty) {
        setDisableSaveButton(true)
        setShowError('Name must be between 3 and 250 characters')
        setErrorId(renameId)
        return
      }
      setDisableSaveButton(false)
      hideError()
    },
    [hideError, renameId, updateCustomViews]
  )

  return (
    <Modal
      isOpen={isOpen}
      isBlocking={false}
      containerClassName={contentStyles.container}
    >
      <div className={contentStyles.header}>
        <h3>Manage Views</h3>
        <IconButton
          styles={iconButtonStyles}
          iconProps={cancelIcon}
          onClick={() => {
            onClose()
            setDisableSaveButton(true)
          }}
        />
      </div>
      <div className={contentStyles.instructions}>
        <span>
          <FontIcon iconName="info" />
        </span>
        <span>
          You may Rename custom views, Delete custom views or change the Default
          View to a custom or system view. Changes will only be saved when you
          click Save Changes. Click Cancel to ignore all changes. Your current
          view will be the Default View when you login.
        </span>
      </div>
      <div className={contentStyles.countHeading}>
        Available Views ({customViews.length + (systemViews?.length || 0)})
      </div>
      <div className={contentStyles.body}>
        {customViews.length !== 0 && (
          <>
            <h3>Custom Views</h3>
            <table className={contentStyles.viewTable}>
              <tbody>
                {customViews?.map((view) => (
                  <tr key={view.key}>
                    <td>
                      <input
                        type="radio"
                        name="defaultView"
                        value={view.key}
                        checked={view.key === defaultViewId}
                        onChange={(e) => onChangeDefaultId(e)}
                        className={contentStyles.pointer}
                      />
                    </td>
                    <td
                      className={contentStyles.overflowWrap}
                      onClick={() => {
                        onRenameView(view.key, view.label)
                      }}
                    >
                      {view.key !== renameId && view.label}
                      {view.key === renameId && (
                        <TextField
                          value={view.label}
                          className={contentStyles.input}
                          onChange={onNameChange}
                          autoFocus={true}
                          css={{ padding: 0 }}
                          onBlur={(e) => {
                            if (showError) {
                              undoRename()
                              return
                            }
                            const trimmedName = stringTrim(e.target.value)
                            const uniqueName = preventDuplicateViewName(
                              view.key || '',
                              trimmedName
                            )
                            updateCustomViews(renameId, uniqueName)
                            setRenameId('')
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Escape') {
                              undoRename()
                              return
                            }
                            if (e.key === 'Enter') {
                              if (showError) {
                                return
                              }
                              const trimmedName = stringTrim(renameName)
                              const uniqueName = preventDuplicateViewName(
                                view.key || '',
                                trimmedName
                              )
                              updateCustomViews(renameId, uniqueName)
                              setRenameId('')
                            }
                          }}
                        />
                      )}
                    </td>
                    <td className={contentStyles.iconCol}>
                      <IconButton
                        iconProps={{ iconName: 'Edit' }}
                        title="Rename view"
                        onClick={() => {
                          onRenameView(view.key, view.label)
                        }}
                      />
                    </td>
                    <td className={contentStyles.iconCol}>
                      <IconButton
                        iconProps={{ iconName: 'Delete' }}
                        title="Delete view"
                        onClick={() => {
                          onDeleteView(view.key)
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        <h3>System Views</h3>
        <table className={contentStyles.viewTable}>
          <tbody>
            {systemViews?.map((view) => (
              <tr key={view.key}>
                <td>
                  <input
                    type="radio"
                    name="defaultView"
                    value={view.key}
                    checked={view.key === defaultViewId}
                    className={contentStyles.pointer}
                    onChange={(e) => {
                      onChangeDefaultId(e)
                    }}
                  />
                </td>
                <td>{view.label}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={contentStyles.errorRow}>
        {showError && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
            dismissButtonAriaLabel="Close"
            className={contentStyles.error}
          >
            {showError}
          </MessageBar>
        )}
      </div>
      <div className={contentStyles.footer}>
        <DefaultButton
          text="Cancel"
          onClick={onClose}
          className={contentStyles.cancel}
        />
        <PrimaryButton
          text="Save Changes"
          onClick={() => onSaveChanges()}
          disabled={disableSaveButton}
          allowDisabledFocus
        />
      </div>
    </Modal>
  )
}
