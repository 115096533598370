import { ReactComponent as Logo } from 'modules/Advisory/shared/images/bannerRockefellerGlobalFamilyOffice.svg'

export const PrintHeaderSmall = () => {
  return (
    <table>
      <thead>
        <tr>
          <th className="logo-col" colSpan={100}>
            <div className="top-margin-table" />
            <div className="header-columns logo-row">
              <div className="header-column-half">
                <h1>Activity</h1>
              </div>
              <div className="header-column-half header-right">
                <Logo style={{ width: '252px', height: '37px' }} />
              </div>
            </div>
          </th>
        </tr>
      </thead>
    </table>
  )
}
