import { useEditHouseholdTagsPanel } from '../hooks/useEditHouseholdTagsPanel'
import { EditHouseholdTags } from './EditHouseholdTags'

export const EditHouseholdTagsPanel: React.FC = () => {
  const { isPanelOpen } = useEditHouseholdTagsPanel()

  if (!isPanelOpen) {
    return null
  }

  return <EditHouseholdTags />
}
