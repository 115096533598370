import { Dictionary } from 'lodash'
import {
  IDataFilter,
  NoData
} from 'shared/components/DataTableFilters/DataTableFilters'

export const filterDashboardByFilterData = (
  airevenuelist: any,
  filterBy: Dictionary<IDataFilter>
) => {
  let filteredData: any = [...airevenuelist]
  if (filterBy) {
    Object.entries(filterBy)?.map(([key, data]) => {
      filteredData = filteredData.filter(
        (x: { [x: string]: string | number }) => {
          if (data?.value && key in x) {
            return (data.value as (string | number)[]).includes(
              (x[key as keyof typeof x] as string | number) || NoData
            )
          } else {
            return true
          }
        }
      )
    })
  }
  return filteredData
}

export const filterDashboardBySearchtext = (items: any, searchText: string) => {
  let filtered: any = items || []
  if (items && searchText && Array.isArray(items)) {
    const lowerCaseText = searchText.toLowerCase()
    filtered = items.filter((p) => {
      if (
        p?.planName?.toLowerCase()?.includes(lowerCaseText) ||
        p?.cusip?.toLowerCase()?.includes(lowerCaseText) ||
        p?.planTotal?.toFixed(2)?.toString()?.startsWith(searchText)
      ) {
        return true
      } else {
        return false
      }
    })
  }
  return filtered
}
