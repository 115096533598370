import { call, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import {
  getDashboardRevenueSummary,
  IGetDasbhoardRevenueSummaryRequest,
  IRevenueDashboardRevenueSummary
} from '../../../../../../../../../api/datahub'
import { AppState } from '../../../../../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors,
  createAsyncHook
} from '../../../../../../../../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../../../../../../../../store/shared/sagas'

export const revenueSummaryFetchActions = createAsyncAction(
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@revenueSummary/REQUEST',
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@revenueSummary/SUCCESS',
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@revenueSummary/FAILURE'
)<
  IGetDasbhoardRevenueSummaryRequest,
  IRevenueDashboardRevenueSummary | undefined,
  Error
>()

export const revenueSummaryFetchReducer = createAsyncReducer(
  revenueSummaryFetchActions
)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.revenue.modules.dashboard.features.revenueTiles
    .revenueSummaryFetch

const selectors = createAsyncSelectors(rootSelector)

export const useRevenueSummaryFetch = createAsyncHook(
  revenueSummaryFetchActions.request,
  selectors
)

const onRequest = function* (
  action: ReturnType<typeof revenueSummaryFetchActions.request>
) {
  try {
    const apiOptions = yield* call(getRockefellerApiOptions)
    const result = yield* call(
      getDashboardRevenueSummary,
      apiOptions,
      action.payload
    )
    if (!result) {
      throw new Error('No result returned from datahub API')
    }
    yield put(revenueSummaryFetchActions.success(result))
  } catch (e: any) {
    yield put(revenueSummaryFetchActions.failure(e))
  }
}

export const revenueSummaryFetchSagas = [
  () => takeLatest(revenueSummaryFetchActions.request, onRequest)
]
