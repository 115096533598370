import { Header, flexRender } from '@tanstack/react-table'
import { AnnuityPPLICombined } from '../../shared/types/annuityPPLICombined'

export const TableFooter: React.FC<{
  headers: Header<AnnuityPPLICombined, unknown>[]
  groupingLength: number
}> = ({ headers, groupingLength }) => {
  return (
    <tfoot>
      <tr className="row">
        {groupingLength > 0 && (
          <>
            <td />
            <td />
          </>
        )}
        {headers.map((header) => {
          return header.column.getIsVisible() &&
            !header.column.getIsGrouped() ? (
            <td key={header.id} className="right">
              {header.column.columnDef.footer
                ? flexRender(
                    header.column.columnDef.footer,
                    header.getContext()
                  )
                : null}
            </td>
          ) : null
        })}
      </tr>
    </tfoot>
  )
}
