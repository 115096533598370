import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMount } from 'react-use'
import { AppState } from 'store/shared'
import { select, takeLatest } from 'typed-redux-saga'

export type UnknownSessionState = Record<string, unknown>
export interface ISessionStateContainer<T = UnknownSessionState> {
  value?: Partial<T>
  lastUpdated?: number
  persistInSessionStorage?: boolean
}

const getContainerNameWithPrefix = (containerName: string) =>
  `rcm/${containerName}`

const getSessionContainer = <T = UnknownSessionState>(
  containerName: string
) => {
  return flow(
    getContainerNameWithPrefix,
    (id) => sessionStorage.getItem(id),
    safeParseJson
  )(containerName) as ISessionStateContainer<T> | undefined
}

const setSessionContainer = <T = UnknownSessionState>(
  containerName: string,
  container: ISessionStateContainer<T>
) => {
  sessionStorage.setItem(
    getContainerNameWithPrefix(containerName),
    JSON.stringify(container)
  )

  return container
}

const deleteSessionContainer = (containerName: string) => {
  sessionStorage.removeItem(getContainerNameWithPrefix(containerName))
}

const safeParseJson = (json?: string | null) => {
  if (!json) {
    return undefined
  }

  let parsed: Record<string, unknown> = {}

  try {
    parsed = JSON.parse(json)
  } finally {
    /* empty */
  }

  return parsed
}

const initialState: Record<string, ISessionStateContainer> = {}

const sessionStoreSlice = createSlice({
  name: 'sessionStore',
  initialState,
  reducers: {
    initializeContainer: (
      state,
      action: PayloadAction<{ id: string; container: ISessionStateContainer }>
    ) => {
      const { id, container } = action.payload
      state[id] = container
    },
    upsertContainer: (
      state,
      action: PayloadAction<{ id: string; value: UnknownSessionState }>
    ) => {
      const { id, value } = action.payload
      const newValue = {
        ...(state[id]?.value || {}),
        ...value
      }

      state[id] = {
        ...(state[id] || {}),
        value: newValue,
        lastUpdated: Date.now()
      }
    },
    deleteContainer: (state, action: PayloadAction<string>) => {
      delete state[action.payload]
    }
  }
})

const rootSelector = (state: AppState) => state.session
export const createSessionContainerSelector =
  (id: string) => (state: AppState) =>
    rootSelector(state)[id]

export const { reducer: sessionStoreReducer } = sessionStoreSlice

export interface IUseSessionStateContainerHookArgs<T = UnknownSessionState> {
  defaults?: Partial<T>
  persistInSessionStorage?: boolean
}

const getSessionContainerOrDefault = <T = UnknownSessionState>(
  containerName: string,
  {
    defaults = {},
    persistInSessionStorage = true
  }: IUseSessionStateContainerHookArgs
) => {
  return (
    getSessionContainer<T>(containerName) || {
      lastUpdated: Date.now(),
      value: defaults,
      persistInSessionStorage
    }
  )
}

export const useSessionStateContainer = <T = UnknownSessionState>(
  id: string,
  args: IUseSessionStateContainerHookArgs<T> = {}
) => {
  const dispatch = useDispatch()
  const selectSessionContainer = useMemo(
    () => createSessionContainerSelector(id),
    [id]
  )
  const storeValue = useSelector(selectSessionContainer) as
    | ISessionStateContainer<T>
    | undefined

  const returnValue = storeValue || getSessionContainerOrDefault<T>(id, args)

  useMount(() => {
    if (storeValue) {
      return
    }

    dispatch(
      sessionStoreSlice.actions.initializeContainer({
        id,
        container: returnValue
      })
    )
  })

  const updateContainer = useCallback(
    (value: Partial<T>) => {
      dispatch(
        sessionStoreSlice.actions.upsertContainer({
          id,
          value
        })
      )
    },
    [dispatch, id]
  )

  const deleteContainer = useCallback(() => {
    deleteSessionContainer(id)
  }, [id])

  const { lastUpdated, value } = returnValue

  return {
    value: value as Partial<T>,
    lastUpdated,
    updateContainer,
    deleteContainer
  }
}

export const sessionSagas = [
  () =>
    takeLatest(sessionStoreSlice.actions.upsertContainer, function* (action) {
      const selector = createSessionContainerSelector(action.payload.id)
      const container = yield* select(selector)
      if (!container.persistInSessionStorage) {
        return
      }
      setSessionContainer(action.payload.id, container)
    })
]
