import { Dialog, Overlay } from '@fluentui/react'
import { useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { IndeterminateProgressIndicator } from '../../components/shared'
import { buttonStyles } from '../../components/shared/Buttons'
import { inputClasses } from '../../components/shared/Inputs'
import { SnackBar } from '../../components/shared/Snackbar'
import { Icon } from '../../features/Icons/Icon'
import { useUploadFileMutation } from '../../store/graph'
import { useCollaborationUiState } from './CollaborationUIState'

export const UploadItemDialog: React.FC<{
  driveId?: string
  folderId?: string
  groupId?: string
}> = ({ driveId, folderId, groupId }) => {
  const methods = useForm<{ files?: FileList }>()
  const { register, handleSubmit, reset, formState } = methods
  const { errors } = formState
  const [uploadFile, uploadResult] = useUploadFileMutation()
  const onSubmit = useCallback(
    async (data?: { files?: FileList }) => {
      const files = [...(data?.files || [])]
      if (!files || !files?.length) {
        return
      }
      files?.map((x) =>
        uploadFile({
          driveId: driveId,
          folderId: folderId,
          groupId: groupId,
          file: x
        })
      )
    },
    [uploadFile, driveId, folderId, groupId]
  )
  const { showUpload, setShowUpload } = useCollaborationUiState()
  useEffect(() => {
    if (uploadResult?.status === 'fulfilled') {
      setShowUpload(false)
      reset()
    }
  }, [reset, setShowUpload, uploadResult?.status])
  const validateFileSizes = (fileList?: FileList) => {
    const files = [...(fileList || [])]
    return files?.every((x) => x?.size < 104857600)
  }

  return (
    <Dialog
      hidden={!showUpload}
      modalProps={{
        isBlocking: true
      }}
      dialogContentProps={{
        title: 'Upload File',
        styles: {
          title: {
            fontSize: '18px',
            fontWeight: 700,
            color: '#13272B',
            padding: '18px 30px'
          },
          header: {
            borderBottom: '1px solid rgba(0,0,0,0.1)'
          },
          content: { width: '550px' }
        }
      }}
      minWidth={'550px'}
      onDismiss={() => setShowUpload(false)}
    >
      {uploadResult?.isLoading && (
        <Overlay>
          <IndeterminateProgressIndicator />
        </Overlay>
      )}
      <FormProvider {...methods}>
        <form id="uploadFilesForm" onSubmit={handleSubmit(onSubmit)}>
          <div css={{ padding: '17px 0' }}>
            {((uploadResult?.isError && !uploadResult?.isLoading) ||
              errors.files) && (
              <div
                css={{
                  paddingBottom: '5px'
                }}
              >
                <SnackBar
                  type="Failure"
                  message={
                    errors.files
                      ? 'Upload exceeds size limit'
                      : (uploadResult?.error as Error)?.message ||
                        'An unknown error occurred'
                  }
                />
              </div>
            )}
            <div
              css={(theme) => ({
                backgroundColor: '#F6FBFF',
                border: `${theme.colors.tertiaryGray4} 1px dashed`,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '35px 55px',
                gridRowGap: 15,
                marginBottom: '20px'
              })}
            >
              <Icon type="FileUpload" height={40} width={40} />
              <div>
                Only .pdf, .xls, .xlsx, .csv, .doc, .docx, .png, .jpg, .tif,
                .pages, .txt, .log, .ppt, .pptx, .numbers, .heic files with max
                size of 100 MB.
              </div>
            </div>
            <div>
              <input
                css={[inputClasses.input]}
                type="file"
                multiple={true}
                accept=".pdf,.xls,.xlsx,.csv,.doc,.docx,.png,.jpg,.tif,.pages,.txt,.log,.ppt,.pptx,.numbers,.heic"
                {...register('files', {
                  validate: {
                    validateFileSizes: (x) => validateFileSizes(x)
                  }
                })}
              />
            </div>
          </div>
          <div
            css={{
              padding: '0 0 20px',
              display: 'flex',
              justifyContent: 'center',
              gridColumnGap: 5
            }}
          >
            <button
              onClick={() => setShowUpload(false)}
              css={[buttonStyles.secondary, { width: '100px' }]}
            >
              Cancel
            </button>
            <button
              type="submit"
              form="uploadFilesForm"
              css={[buttonStyles.primary, { width: '100px' }]}
            >
              {uploadResult?.isError ? 'Try Again' : 'Done'}
            </button>
          </div>
        </form>
      </FormProvider>
    </Dialog>
  )
}
