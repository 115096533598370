import {
  CommandBar,
  ICommandBarItemProps,
  ProgressIndicator,
  Stack,
  Text
} from '@fluentui/react'
import { format } from 'date-fns'
import { constants } from 'modules/Advisory/shared/theme'
import React, { memo, useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IDataListColumnDefinition } from '../../../../../../../../features/DataList/contracts/IDataListColumnDefinition'
import { DataList } from '../../../../../../../../features/DataList/DataList'
import { HurdleStatusIndicator } from '../../../../../../../../features/Hurdles/HurdleStatusIndicator'
import { parseDateISOStringInLocalTimezone } from '../../../../../../../../shared'
import { USD } from '../../../../../../../../shared/components/Formatting'
import {
  getIsPotentialPayoutExportLoading,
  potentialPayoutExportActions
} from './store/export'

import {
  getIsHurdlesFetchLoading,
  hurdlesFetchActions
} from './store/hurdleFetch'
import {
  payoutsListActions,
  PayoutsListColumnName,
  payoutsListSelectors
} from './store/payoutsList'
import { PossiblePayout } from './store/potentialPayouts'

const CellComponents: Record<
  PayoutsListColumnName,
  React.FC<{
    item: PossiblePayout
    column: IDataListColumnDefinition<PossiblePayout>
  }>
> = {
  Status: ({ item }) => (
    <div style={{ textAlign: 'center', flexGrow: 1 }}>
      <HurdleStatusIndicator status={item.status} disableEdit={true} />
    </div>
  ),
  'Hurdle Name': ({ item }) => (
    <Text nowrap={true} block={true}>
      {item.name}
    </Text>
  ),
  Date: ({ item }) => (
    <Text nowrap={true} block={true}>
      {item.date
        ? format(parseDateISOStringInLocalTimezone(item.date), `MMM yyyy`)
        : ''}
    </Text>
  ),
  Person: ({ item }) => (
    <Text nowrap={true} block={true}>
      {item.person}
    </Text>
  ),
  Amount: ({ item }) => (
    <Text nowrap={true} block={true}>
      <USD value={item.amount || 0} fractionDigits={0} />
    </Text>
  ),
  Type: ({ item }) => (
    <Text nowrap={true} block={true}>
      {item.type}
    </Text>
  ),
  'Team / Individual': ({ item }) => (
    <Text nowrap={true} block={true}>
      {item.entityName}
    </Text>
  )
}

const CellComponent: React.FC<{
  item: PossiblePayout
  column: IDataListColumnDefinition<PossiblePayout>
}> = memo(({ item, column }) => {
  const Component = CellComponents[column.name as PayoutsListColumnName]
  return Component ? <Component item={item} column={column} /> : null
})

export const PayoutList: React.FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(hurdlesFetchActions.request())
  }, [dispatch])
  const isLoading = useSelector(getIsHurdlesFetchLoading)
  const onExport = useCallback(() => {
    dispatch(potentialPayoutExportActions.request())
  }, [dispatch])
  const isExportLoading = useSelector(getIsPotentialPayoutExportLoading)
  const header = useMemo(() => {
    const items: ICommandBarItemProps[] = [
      {
        key: 'Export',
        text: isExportLoading ? 'Exporting' : 'Export To Excel',
        iconProps: { iconName: 'ExcelDocument' },
        onClick: onExport,
        disabled: isExportLoading
      }
    ]
    return (
      <Stack>
        <CommandBar items={items} styles={{ root: { paddingLeft: '0px' } }} />
        <ProgressIndicator
          progressHidden={!isLoading}
          styles={{
            itemProgress: { padding: 0, margin: 0 }
          }}
        />
      </Stack>
    )
  }, [isExportLoading, isLoading, onExport])

  return (
    <>
      <DataList
        actions={payoutsListActions}
        selectors={payoutsListSelectors}
        enableShimmer={isLoading}
        stickyHeaderOffset={constants.headerHeight}
        cell={CellComponent}
        secondaryHeader={header}
      />
    </>
  )
}
