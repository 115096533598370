import { FC } from 'react'
import { IndeterminateProgressIndicator } from '../../../components/shared'
import { useRdot360AccountContext } from '../../../store/rdot360Context/useRdot360AccountContext'

const LoaderContainer: FC<{
  isLoading: boolean
  isFetching: boolean
  isDownloading: boolean
  dataListCount: number
}> = ({ isLoading, isFetching, isDownloading, dataListCount }) => {
  const { accounts, selectedAccountIds } = useRdot360AccountContext()
  return (
    <>
      <span css={{ padding: '0px 5px 5px 5px' }}>
        {`${selectedAccountIds?.length || 0} / ${
          accounts?.length || 0
        } Accounts Selected`}
      </span>
      <span css={{ padding: '0px 5px 5px 15px' }}> | </span>
      <span css={{ padding: '0px 5px 5px 15px' }}>{dataListCount} results</span>
      {isLoading || isFetching ? <IndeterminateProgressIndicator /> : ''}
      {isDownloading ? <IndeterminateProgressIndicator /> : ''}
    </>
  )
}

export default LoaderContainer
