import { lazy } from 'react'
import { combineReducers } from 'redux'
import { IModuleDefinition } from '../../../types'
import {
  annuitiesAndInsuranceModulesReducer,
  annuitiesAndInsuranceModulesSagas
} from './modules/index'

const AnnuitiesAndInsuranceContainer = lazy(
  () => import('./AnnuitiesAndInsuranceContainer')
)

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/AnnuitiesAndInsurance/*',
    private: true,
    component: AnnuitiesAndInsuranceContainer
  },
  name: 'Annuities and Insurance'
}

export default moduleDefinition

export const annuitiesAndInsuranceModuleReducer = combineReducers({
  modules: annuitiesAndInsuranceModulesReducer
})
export const annuitiesAndInsuranceModuleSagas = [
  ...annuitiesAndInsuranceModulesSagas
]
