import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import { fromPairs } from 'lodash'
import { AppState } from 'store'
import { select, takeEvery } from 'typed-redux-saga'

const preferencePrefix = 'rdot360Preferences-'
const safeParseJson = (json?: string | null) => {
  let parsed: Record<string, unknown> = {}
  if (!json) {
    return parsed
  }

  try {
    parsed = JSON.parse(json)
  } finally {
    /* empty */
  }

  return parsed
}

const preferencesSlice = createSlice({
  name: '@features/@rdot360/@preferencesSlice',
  initialState: (): Record<string, Record<string, unknown>> =>
    fromPairs(
      Object.keys(localStorage)
        .filter((key) => key.startsWith(preferencePrefix))
        .map((key) => [
          key.replace(preferencePrefix, ''),
          safeParseJson(localStorage.getItem(key))
        ])
    ),
  reducers: {
    setPreferenceInContainer: (
      state,
      action: PayloadAction<{
        containerName: string
        keyName: string
        value: unknown
      }>
    ) => {
      const { containerName, keyName, value } = action.payload
      state[containerName] = {
        ...(state[containerName] || {}),
        [keyName]: value
      }
    }
  }
})

export const { reducer: preferencesReducer, actions: preferencesActions } =
  preferencesSlice

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.preferences

export const selectPreferencesByContainerName = createSelector(
  [rootSelector, (_, containerName: string) => containerName],
  (preferences, containerName) => preferences?.[containerName]
)

const syncPreferencesWithLocalStorage = function* (
  action: ReturnType<typeof preferencesActions.setPreferenceInContainer>
) {
  const { containerName } = action.payload
  const containerValue = yield* select((state) =>
    selectPreferencesByContainerName(state, containerName)
  )
  localStorage.setItem(
    `${preferencePrefix}${containerName}`,
    JSON.stringify(containerValue)
  )
}

export const preferencesSagas = [
  () =>
    takeEvery(
      preferencesActions.setPreferenceInContainer,
      syncPreferencesWithLocalStorage
    )
]
