import { MessageBarType } from '@fluentui/react'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { pushNotification } from '../../../../../../../../../features/Notifications'
import { downloadUrlAsFile } from '../../../../../../../../../shared/downloads'
import { AppState } from '../../../../../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../../../store/shared/asyncStore'
import { getAllPagedOdataApiResults } from '../../../../../../../../../store/shared/sagas/odata'
import type { HurdleManagementViewRawExportWorker } from './exportRaw.worker'
import {
  fetchHurdleProgressMeasurements,
  hurdleProgressManagementListUiSelectors
} from './hurdleProgressManagementList'

export const hurdleManagementViewExportRawActions = createAsyncAction(
  '@modules/@advisory/@modules/@teams/@modules/@hurdles/@features/@hurdleProgressManagement/@exportRaw/REQUEST',
  '@modules/@advisory/@modules/@teams/@modules/@hurdles/@features/@hurdleProgressManagement/@exportRaw/SUCCESS',
  '@modules/@advisory/@modules/@teams/@modules/@hurdles/@features/@hurdleProgressManagement/@exportRaw/FAILURE'
)<undefined, undefined, Error>()

export const hurdleManagementViewExportRawReducer = createAsyncReducer(
  hurdleManagementViewExportRawActions
)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.teams.modules.hurdles.features
    .hurdleProgressManagementList.hurdleManagementViewExportRaw
export const {
  getError: getHurdleManagementViewExportError,
  getIsLoading: getIsHurdleManagementViewRawExportLoading
} = createAsyncSelectors(rootSelector)

const onExport = function* () {
  try {
    const request = yield* select(
      hurdleProgressManagementListUiSelectors.getOdataRequest
    )
    const results = yield* call(() =>
      getAllPagedOdataApiResults(request, fetchHurdleProgressMeasurements)
    )

    const worker = new Worker(new URL('./exportRaw.worker.ts', import.meta.url))
    const { createAndDownloadHurdleManagementViewRawExport } =
      wrap<HurdleManagementViewRawExportWorker>(worker)

    const result = yield* call(
      createAndDownloadHurdleManagementViewRawExport,
      results
    )

    const filename = `Hurdles Management View Raw ${format(
      new Date(),
      'MM-dd-yyyy'
    )}.xlsx`
    downloadUrlAsFile(result, filename)
    yield put(hurdleManagementViewExportRawActions.success())
  } catch (e: unknown) {
    yield put(hurdleManagementViewExportRawActions.failure(e as Error))
  }
}

const onFailure = function* (
  action: ReturnType<typeof hurdleManagementViewExportRawActions.failure>
) {
  yield call(pushNotification, {
    message: `Export failed: ${action.payload.message}`,
    type: MessageBarType.error
  })
}

export const hurdleManagementViewExportRawSagas = [
  () => takeLatest(hurdleManagementViewExportRawActions.request, onExport),
  () => takeLatest(hurdleManagementViewExportRawActions.failure, onFailure)
]
