import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INfsProfile } from 'api/datahub'
import { IRockDigitalInvitationRequest } from 'api/mail'
import { flow } from 'lodash/fp'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import {
  useClientInvite_inviteToRockDigitalMutation,
  useClientInvite_resendRockDigitalInvitationMutation
} from '../api/datahub'
import { IClientInviteForm } from '../InviteContent'

export type IClientInvitePanelType =
  | 'invite'
  | 'reinvite'
  | 'resend'
  | 'changeEmail'

const thirdPartyRoles = ['3rdParty', '3rdPartyMM']

const { actions, reducer } = createSlice({
  name: 'clientInvitePanel',
  initialState: {} as IClientInvitePanelState,
  reducers: {
    open: (state, action: PayloadAction<string>) => ({
      ...state,
      isOpen: true,
      selectedWealthscapeId: action.payload
    }),
    close: () => ({
      isOpen: false,
      selectedWealthscapeId: undefined,
      type: undefined,
      error: undefined,
      showValidationError: false
    }),
    failure: (state, action: PayloadAction<Error>) => ({
      ...state,
      error: action.payload
    }),
    setType: (state, action: PayloadAction<IClientInvitePanelType>) => ({
      ...state,
      type: action.payload
    }),
    setShowValidationError: (state, action: PayloadAction<boolean>) => ({
      ...state,
      showValidationError: action.payload
    }),
    setInviteId: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      selectedWealthscapeId: action.payload,
      type: 'invite'
    })
  }
})

export { reducer as clientInvitePanelReducer }

const rootSelector = (state: AppState) =>
  state.features.clientInvite.clientInvitePanel

const getIsClientInvitePanelOpen = flow(rootSelector, (x) => x.isOpen)
const getClientInvitePanelError = flow(rootSelector, (x) => x.error)
const getSelectedWealthscapeId = flow(
  rootSelector,
  (x) => x.selectedWealthscapeId
)
const getPanelType = flow(rootSelector, (x) => x.type)
const getShowValidationError = flow(rootSelector, (x) => x.showValidationError)

export interface IClientInvitePanelState {
  isOpen?: boolean
  error?: Error
  selectedWealthscapeId?: string
  type?: IClientInvitePanelType
  showValidationError?: boolean
}

export const useClientInvitePanel = () => {
  const dispatch = useDispatch()

  const close = useCallback(() => {
    dispatch(actions.close())
  }, [dispatch])

  const open = useCallback(
    (id: string, type: IClientInvitePanelType) => {
      dispatch(actions.setType(type))
      dispatch(actions.open(id))
    },
    [dispatch]
  )

  const setError = useCallback(
    (e: Error) => {
      dispatch(actions.failure(e))
    },
    [dispatch]
  )

  const setShowValidationError = useCallback(
    (show: boolean) => {
      dispatch(actions.setShowValidationError(show))
    },
    [dispatch]
  )

  const setInviteId = useCallback(
    (id?: string) => {
      dispatch(actions.setInviteId(id))
    },
    [dispatch]
  )

  const selectedWealthscapeId = useSelector(getSelectedWealthscapeId)

  const isOpen = useSelector(getIsClientInvitePanelOpen)

  const error = useSelector(getClientInvitePanelError)

  const panelType = useSelector(getPanelType)

  const showValidationError = useSelector(getShowValidationError)

  const [inviteProfile, inviteResult] =
    useClientInvite_inviteToRockDigitalMutation()
  const [resendInvite, resendResult] =
    useClientInvite_resendRockDigitalInvitationMutation()
  const { isLoading: isResendLoading } = resendResult
  const { isLoading: isInviteLoading } = inviteResult

  const isSendInviteLoading = useMemo(
    () => isResendLoading || isInviteLoading,
    [isInviteLoading, isResendLoading]
  )

  const sendInvite = useCallback(
    async (
      onSuccess: () => void,
      form?: IClientInviteForm,
      nfsProfile?: INfsProfile,
      isThirdParty?: boolean
    ) => {
      if (!form || !nfsProfile?.id) {
        return
      }

      const { email, cc, from, invitedBy, invitee, onBehalfOf } = form.invite
      const invitation: IRockDigitalInvitationRequest = {
        profileid: nfsProfile?.id.toString(),
        ccRecipients: cc,
        client: {
          fullname: isThirdParty ? onBehalfOf : invitee,
          pronoun: 'their'
        },
        thirdparty: isThirdParty ? { fullname: invitee } : undefined,
        requestor: { email: from, fullname: invitedBy }
      }
      try {
        if (panelType === 'invite' || panelType === 'changeEmail') {
          await inviteProfile({
            id: nfsProfile?.id.toString(),
            invite: { invitation, email: email || '' }
          }).unwrap()
        }
        if (panelType === 'resend') {
          await resendInvite({
            id: nfsProfile?.id.toString(),
            invite: { invitation, email: email || '' }
          }).unwrap()
        }
      } catch (e) {
        setError(e as Error)
        return
      }

      onSuccess()
    },
    [inviteProfile, panelType, resendInvite, setError]
  )

  const getIsThirdParty = (role?: string) =>
    !!role && thirdPartyRoles?.includes(role)

  return {
    close,
    open,
    selectedWealthscapeId,
    setError,
    isOpen,
    error,
    panelType,
    sendInvite,
    isSendInviteLoading,
    getIsThirdParty,
    showValidationError,
    setShowValidationError,
    setInviteId
  }
}
