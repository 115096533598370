import { call, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import {
  IOdataCollectionFilter,
  OdataFilterCollectionOperatorEnum,
  OdataFilterOperatorEnum
  //OdataPropertyFilterGroup
} from '../../../../../api/odata'
import { AppState } from '../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../store/shared/asyncStore'
import { search } from '../../../../../store/shared/sagas'

export const householdTotalFetchActions = createAsyncAction(
  '@modules/@advisory/@modules/@dashboard/@features/@householdTotalFetch/REQUEST',
  '@modules/@advisory/@modules/@dashboard/@features/@householdTotalFetch/SUCCESS',
  '@modules/@advisory/@modules/@dashboard/@features/@householdTotalFetch/FAILURE'
)<string[] | undefined, number | undefined, Error>()

export const householdTotalFetchReducer = createAsyncReducer(
  householdTotalFetchActions
)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.dashboardFeatures.householdTotalFetch

export const {
  getError: getHouseholdTotalFetchError,
  getIsLoading: getHouseholdTotalFetchLoading,
  getResult: getHouseholdTotalFetchResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof householdTotalFetchActions.request>
) {
  const collectionFilter: IOdataCollectionFilter = {
    filter: {
      and: [
        {
          operator: OdataFilterOperatorEnum.searchin,
          value: action.payload,
          path: 'ClientAdvisorID',
          type: 'string'
        }
      ]
    },
    operator: OdataFilterCollectionOperatorEnum.any,
    path: 'Advisors'
  }
  try {
    const result = yield* call(search, 'household' as const, {
      top: 0,
      filters: [
        collectionFilter,
        {
          and: [
            {
              operator: OdataFilterOperatorEnum.eq,
              value: 'True',
              path: 'ActiveFlag',
              type: 'string' as const
            }
          ]
        }
      ],
      count: true
    })

    if (!result) {
      throw new Error('No result returned from datahub api')
    }
    yield put(householdTotalFetchActions.success(result['@odata.count']))
  } catch (e) {
    yield put(householdTotalFetchActions.failure(e as Error))
  }
}

export const householdTotalFetchSagas = [
  () => takeLatest(householdTotalFetchActions.request, onRequest)
]
