import { addMonths, IDropdownOption, INavLinkGroup } from '@fluentui/react'
import { format } from 'date-fns'
import { Dictionary, sumBy } from 'lodash'
import {
  IDataFilter,
  NoData
} from 'shared/components/DataTableFilters/DataTableFilters'
import { RdotUserRoleEnum } from '../../../../../store/user/rdotUser'
import {
  IAUM,
  ICashAllocation,
  IFetchPlanToDistributeCash,
  IPlanByProvider,
  IRetirementProductCashReceipt,
  IRetirementProductPlan
} from './types'

export const getMonthYearList = (): IDropdownOption[] => {
  const today = new Date()
  const last6Months = []
  const next3Months = []

  for (let i = 0; i < 13; i++) {
    const monthDate = addMonths(today, i * -1)
    last6Months.push({
      key: format(monthDate, 'MM-yyyy'),
      text: format(monthDate, 'LLLL-yyyy')
    })
  }

  for (let i = 3; i > 0; i--) {
    const monthDate = addMonths(today, i)
    next3Months.push({
      key: format(monthDate, 'MM-yyyy'),
      text: format(monthDate, 'LLLL-yyyy')
    })
  }
  return [...next3Months, ...last6Months]
}

export const getCurrentMonthYearKey = (): string => {
  const today = new Date()
  return format(today, 'MM-yyyy')
}

export const getNextMonthYearKey = (selectedMonthYear: string): string => {
  const splitString = selectedMonthYear?.split('-')
  const currentMonthDate = new Date(
    `${splitString?.[0]}/01/${splitString?.[1]}`
  )
  const date = addMonths(currentMonthDate, 1)
  return format(date, 'MM-yyyy')
}

export const getLast12MonthYearOptions = (
  selectedMonthYear: string
): IDropdownOption[] => {
  const last12Months = []
  const splitString = selectedMonthYear?.split('-')
  const currentMonthDate = new Date(
    `${splitString?.[0]}/01/${splitString?.[1]}`
  )

  for (let i = 0; i < 12; i++) {
    const monthDate = addMonths(currentMonthDate, i * -1)
    last12Months.push({
      key: format(monthDate, 'MM-yyyy'),
      text: format(monthDate, 'LLLL-yyyy')
    })
  }

  return last12Months
}

export const getOriginalPendingCashReceipt = (
  receipt: IRetirementProductCashReceipt,
  receipts: IRetirementProductCashReceipt[]
) => {
  let original: IRetirementProductCashReceipt | undefined = undefined
  for (let i = 0; i < receipts?.length; i++) {
    if (receipts[i].cashreceiptid === receipt.cashreceiptid) {
      original = receipts[i]
      break
    }
  }

  return original
}

export const getProjectionAmountMonthYear = () => {
  const today = new Date()
  const currentYear = format(today, 'yyyy')
  const next12Months = []
  const prevMonthsOfYear = []

  for (let i = today.getMonth(); i > 0; i--) {
    const monthDate = addMonths(today, i * -1)
    const prevMonthYear = format(monthDate, 'yyyy')
    if (prevMonthYear !== currentYear) {
      break
    }
    prevMonthsOfYear.push({
      key: format(monthDate, 'MM-yyyy'),
      text: format(monthDate, 'LLLL-yyyy')
    })
  }

  for (let i = 0; i < 12; i++) {
    const monthDate = addMonths(today, i)
    next12Months.push({
      key: format(monthDate, 'MM-yyyy'),
      text: format(monthDate, 'LLLL-yyyy')
    })
  }

  return [...prevMonthsOfYear, ...next12Months]
}

export const filterRetirementPlanBySearchtext = (
  items: IRetirementProductPlan[],
  searchText: string
) => {
  let filtered: IRetirementProductPlan[] = items || []
  if (items && searchText && Array.isArray(items)) {
    const lowerCaseText = searchText.toLowerCase()
    filtered = items.filter((p) => {
      if (
        p?.planprovider?.toLowerCase()?.includes(lowerCaseText) ||
        p?.planname?.toLowerCase()?.includes(lowerCaseText) ||
        p?.clientname?.toLowerCase()?.includes(lowerCaseText) ||
        p?.teamid?.toLowerCase()?.includes(lowerCaseText) ||
        p?.teamname?.toLowerCase()?.includes(lowerCaseText) ||
        p?.plantype?.toLowerCase()?.includes(lowerCaseText) ||
        p?.feepaymentsource?.toLowerCase()?.includes(lowerCaseText) ||
        p?.plansponser?.state?.toLowerCase()?.includes(lowerCaseText) ||
        formatDateString(p?.asof)?.startsWith(searchText) ||
        p?.aum?.startsWith(searchText) ||
        p?.projectedamount?.toFixed(2)?.toString()?.startsWith(searchText) ||
        p?.receivedamount?.toFixed(2)?.toString()?.startsWith(searchText) ||
        p?.entity?.toLowerCase()?.includes(lowerCaseText) ||
        p?.segment?.toLowerCase()?.includes(lowerCaseText)
      ) {
        return true
      } else {
        return false
      }
    })
  }

  return filtered
}

export const filterRetirementPlanByFilterData = (
  items: IRetirementProductPlan[],
  filterBy: Dictionary<IDataFilter>
) => {
  let filteredData: IRetirementProductPlan[] = items || []
  if (filterBy) {
    Object.entries(filterBy)?.map(([key, data]) => {
      filteredData = filteredData.filter((x) => {
        if (data?.value) {
          return (data.value as (string | number)[]).includes(
            (x[key as keyof IRetirementProductPlan] as string | number) ||
              NoData
          )
        } else {
          return true
        }
      })
    })
  }
  return filteredData
}

export const getProductSubNavLinks = (
  roles: RdotUserRoleEnum[] | undefined,
  isProductUser: boolean
): INavLinkGroup[] => {
  const isRetirementProductUser = roles?.includes(
    RdotUserRoleEnum.Products_Retirements
  )
  const isAlternateInvestmentUser = roles?.includes(
    RdotUserRoleEnum.Products_AltInvestments
  )
  return [
    {
      links: [
        ...(isRetirementProductUser
          ? [
              {
                name: 'Retirement',
                url: '',
                title: '',
                links: [
                  {
                    name: 'Dashboard',
                    url: '',
                    key: 'retirement'
                  },
                  {
                    name: 'Plan Providers',
                    url: '',
                    key: 'retirement/planproviders'
                  },
                  {
                    name: 'Reports',
                    url: '',
                    links: [
                      {
                        name: 'Trueup Report',
                        url: '',
                        key: 'retirement/report/trueup'
                      },
                      {
                        name: 'Comp Report',
                        url: '',
                        key: 'retirement/report/comp'
                      },
                      {
                        name: 'Aging Report',
                        url: '',
                        key: 'retirement/report/aging'
                      }
                    ],
                    isExpanded: true
                  }
                ],
                isExpanded: true
              }
            ]
          : []),
        ...(isAlternateInvestmentUser
          ? [
              {
                name: 'Alternative Investments',
                url: '',
                title: '',
                links: [
                  {
                    name: 'Dashboard',
                    url: '',
                    key: 'alts'
                  },
                  {
                    name: 'Fund Providers',
                    url: '',
                    key: 'alts/planproviders'
                  },
                  {
                    name: 'Approval Dashboard',
                    url: '',
                    key: 'alts/AIWorkflow'
                  },
                  {
                    name: 'Manual Upload',
                    url: '',
                    key: 'alts/upload'
                  },
                  {
                    name: 'Reports',
                    url: '',
                    links: [
                      {
                        name: 'Trueup Report',
                        url: '',
                        key: 'alts/report/trueup'
                      },
                      {
                        name: 'Comp Report',
                        url: '',
                        key: 'alts/report/comp'
                      },
                      {
                        name: 'Accrual Trend Report',
                        url: '',
                        key: 'alts/report/accrual'
                      },
                      {
                        name: 'Open Receivables Report',
                        url: '',
                        key: 'alts/report/openreceivables'
                      },
                      {
                        name: 'Cash Receipt Trend Report',
                        url: '',
                        key: 'alts/report/cashreceipttrend'
                      },
                      {
                        name: 'Aging Report',
                        url: '',
                        key: 'alts/report/aging'
                      },
                      {
                        name: 'Deferred Report',
                        url: '',
                        key: 'alts/report/deferred'
                      }
                    ],
                    isExpanded: true
                  }
                ],
                isExpanded: true
              }
            ]
          : []),
        ...(isProductUser
          ? [
              {
                name: 'Cash Receipt Setting',
                url: '',
                key: 'cashreceiptsetting'
              }
            ]
          : [])
      ]
    }
  ]
}

export const allowedUplaodFileTypes: string[] = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
]

export const allowedUploadedCheckFileTypes: string[] = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf'
]

export const allowedUploadedAllocationTypes: string[] = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/csv',
  'application/vnd.ms-excel',
  'text/csv'
]
export const showPeriodFromDate = (date: string): string => {
  if (date) {
    try {
      const inputDate = new Date(date)
      return format(inputDate, 'MMM-yyyy')
    } catch (e) {
      return '-'
    }
  }

  return '-'
}
export const showMonthFromDate = (date: string): string => {
  if (date) {
    try {
      const inputDate = new Date(date)
      return format(inputDate, 'MMM')
    } catch (e) {
      return '-'
    }
  }

  return '-'
}
export const showYearFromDate = (date: string): string => {
  if (date) {
    try {
      const inputDate = new Date(date)
      return format(inputDate, 'yyyy')
    } catch (e) {
      return '-'
    }
  }

  return '-'
}
export const showNumericPeriodFromDate = (date: string): string => {
  if (date) {
    try {
      const inputDate = new Date(date)
      return format(inputDate, 'MM-yyyy')
    } catch (e) {
      return '-'
    }
  }

  return '-'
}

export const convertToUSD = (data: number | undefined): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  return data ? formatter.format(data) : '0'
}

export const getChangedCashAllocations = (
  newCashAllocation: ICashAllocation[],
  originalCashAllocation: ICashAllocation[]
): ICashAllocation[] => {
  return newCashAllocation.filter(
    (x) =>
      !originalCashAllocation?.some(
        (y) =>
          y.cashallocatedamt === x.cashallocatedamt &&
          y.noncompensablecashcashallocatedamt ===
            x.noncompensablecashcashallocatedamt &&
          y.uniqueid === x.uniqueid
      )
  )
}

export const getMonthYearStringFromSelectedMonth = (
  selectedMonthYear: string
): string => {
  try {
    const splitString = selectedMonthYear?.split('-')
    const currentMonthDate = new Date(
      `${splitString?.[0]}/01/${splitString?.[1]}`
    )
    return format(new Date(currentMonthDate), 'MMM-yyyy')
  } catch {
    return ''
  }
}

export const getRemainingAmount = (
  localPlans: IFetchPlanToDistributeCash[] | undefined,
  cashReceipt: IRetirementProductCashReceipt | undefined
): number => {
  const allocatedAmt = sumBy(
    localPlans?.map((x) => ({
      totalCash: sumBy(x.cashallocations || [], (y) => y.cashallocatedamt || 0)
    })),
    (z) => z.totalCash || 0
  )

  const remainingAmt = (cashReceipt?.amount || 0) - allocatedAmt

  return parseFloat(remainingAmt.toFixed(2))
}

export const formatDateString = (date: string | undefined): string => {
  const formattedString = ''
  try {
    if (date) {
      return format(new Date(date || ''), 'MM/dd/yyyy')
    }
  } catch {
    return formattedString
  }
  return formattedString
}

export const getLast12MonthYearAums = (
  plan: IPlanByProvider | null | undefined
): IAUM[] => {
  const last12Months = []
  const currentMonthDate = new Date()

  for (let i = 0; i < 12; i++) {
    const monthDate = addMonths(currentMonthDate, i * -1)
    const key = format(monthDate, 'MM-yyyy')
    const matchedAum = plan?.aum?.filter((x) => x.aumasofdate === key)?.[0]
    last12Months.push({
      aumasofdate: key,
      planaum: matchedAum?.planaum || 0,
      asofdate: matchedAum?.asofdate,
      retirementtranid: matchedAum?.retirementtranid || 0,
      isclosedmonth: matchedAum?.isclosedmonth
    } as IAUM)
  }

  return last12Months
}

export const addMonthsInSelectedMonthYear = (
  selectedMonthYear: string,
  value: number
): string => {
  try {
    const splitString = selectedMonthYear?.split('-')
    const currentMonthDate = new Date(
      `${splitString?.[0]}/01/${splitString?.[1]}`
    )
    const updatedMonthYear = addMonths(currentMonthDate, value)
    return format(updatedMonthYear, 'MM-yyyy')
  } catch {
    return ''
  }
}
