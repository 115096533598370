import { partial } from 'lodash/fp'
import { createListsComponents } from '../core/containers/service'
import { IUseListHookDefinition } from '../hooks/useList/hookDefinitions'
import { createFilter } from '../shared/lists'
import { OrderColumnDefinitions } from './ColumnDefinitions'
import { OrderCell } from './OrderCell'
import { actions, selectors } from './store'

export * from './store'

export const createOrderListFilter = partial(createFilter, [
  OrderColumnDefinitions
])

export const OrderListComponents = createListsComponents(
  actions,
  selectors,
  OrderCell
)

export const orderListHookDefinition: IUseListHookDefinition = {
  actions,
  selectors,
  columnDefinitions: OrderColumnDefinitions,
  listType: 'order'
}
