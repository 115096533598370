import { flow } from 'lodash/fp'
import { call, put, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { ISearchResult } from '../../api/common.types'
import { OdataFilterOperatorEnum } from '../../api/odata'
import { IOrder } from '../../api/order.types'
import { AppState } from '../../store'
import { search } from '../../store/shared/sagas'

const REQUEST_TRADE_DETAIL = '@features/@tradeDetail/REQUEST_TRADE_DETAIL'
const FETCH_TRADE_DETAIL_SUCCESS =
  '@features/@tradeDetail/FETCH_TRADE_DETAIL_SUCCESS'
const FETCH_TRADE_DETAIL_FAILURE =
  '@features/@tradeDetail/FETCH_TRADE_DETAIL_FAILURE'

export const tradeDetailDataActions = {
  request: createAction(REQUEST_TRADE_DETAIL)<string>(),
  success: createAction(FETCH_TRADE_DETAIL_SUCCESS)<IOrder>(),
  failure: createAction(FETCH_TRADE_DETAIL_FAILURE)<Error>()
}

export type TradeDetailActionTypes = ActionType<typeof tradeDetailDataActions>

export interface ITradeDetailState {
  isLoading: boolean
  order?: IOrder
  error?: Error
}

const initialState: ITradeDetailState = {
  isLoading: false
}

export const tradeDetailReducer = createReducer<
  ITradeDetailState,
  TradeDetailActionTypes
>(initialState)
  .handleAction(tradeDetailDataActions.request, (state) => ({
    ...state,
    isLoading: true,
    order: undefined,
    error: undefined
  }))
  .handleAction(tradeDetailDataActions.success, (state, action) => ({
    ...state,
    isLoading: false,
    order: action.payload
  }))
  .handleAction(tradeDetailDataActions.failure, (state, action) => ({
    ...state,
    isLoading: false,
    order: undefined,
    error: action.payload
  }))

export const sagas = [
  () =>
    takeLatest(
      tradeDetailDataActions.request,
      function* (action: ReturnType<typeof tradeDetailDataActions.request>) {
        try {
          const searchResult: ISearchResult<IOrder> = yield call(
            search,
            'order' as const,
            {
              filters: [
                {
                  and: [
                    {
                      operator: OdataFilterOperatorEnum.eq,
                      value: action.payload,
                      path: 'id',
                      type: 'string' as const
                    }
                  ]
                }
              ],
              top: 1
            }
          )

          yield put(tradeDetailDataActions.success(searchResult.value[0]))
        } catch (e: any) {
          tradeDetailDataActions.failure(e)
        }
      }
    )
]

const getRootState = (state: AppState) => state.features.tradeDetail

export const getTradeDetailOrder = flow(getRootState, (x) => x.order)
export const getIsTradeDetailOrderLoading = flow(
  getRootState,
  (x) => x.isLoading
)
