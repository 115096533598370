import axios from 'axios'
import { IApiOptions } from '../../../../shared/contracts/IApiOptions'
import {
  IAdvisoryBusinessReq,
  IAdvisoryBusinessResp,
  ITPMFirmPDFResp,
  ITPMPDFResp
} from './types/templateTypes'
import { IExternalManagerResp, IGenerateTPMPDFReq } from './types/types'

const fetchAdvisoryBusinessService = (
  req: IAdvisoryBusinessReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/firm/GetAdvisoryBusiness?flowYear=${req.flowYear}&flowMonth=${req.flowMonth}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IAdvisoryBusinessResp | undefined>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => {
      return res.data
    })
}

const downloadTPMDocService = (
  req: IGenerateTPMPDFReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/firm/DownloadFirmPDF?MonthYear=${req.monthYear}&firmCode=${req.firmCode}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<ITPMPDFResp>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const generateTPMPDFService = (
  req: IGenerateTPMPDFReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/firm/generatefirmpdf?MonthYear=${req.monthYear}&firmCode=${req.firmCode}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IExternalManagerResp>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchTPMFirmpdfService = (MonthYear: string, options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/firm/tpmfirmpdfs?MonthYear=${MonthYear}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<ITPMFirmPDFResp>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => {
      return res.data
    })
}

export {
  fetchAdvisoryBusinessService,
  downloadTPMDocService,
  generateTPMPDFService,
  fetchTPMFirmpdfService
}
