import { useRdot360Context } from '../store/rdot360Context'

export const CobPill: React.FC = () => {
  const { asOfDate } = useRdot360Context()
  return (
    <div
      css={{
        padding: '1px 10px',
        background: '#ffffd0',
        fontSize: '11px',
        borderRadius: '11.5px',
        border: '1px solid #DDDDDD',
        textTransform: 'none'
      }}
    >
      As of COB: {asOfDate || '--'}
    </div>
  )
}
