import axios, { AxiosResponse } from 'axios'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import { IInstruction, IWireInstructionForSecurity } from '../store/types'
import {
  ILookUpValueResponse,
  IAbaResponse,
  IEligibleAccount,
  IWirePaymentResponse,
  IFetchWireInstructionResponse,
  ICreateWireInstructionResponse
} from './types'

export const fetchLookUpValue = (
  groupCode: string,
  orgid: string,
  options: IApiOptions
): Promise<AxiosResponse<ILookUpValueResponse[] | undefined>> => {
  const url = `${options.apiRoot}/payment/api/payment/getLookUpValues?groupCode=${groupCode}&orgId=${orgid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchEligibleAccounts = (orgid: string, options: IApiOptions) => {
  const url = `${options.apiRoot}/payment/api/billmgmt/retrieveFromAcctList?orgid=${orgid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IEligibleAccount[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const createWireInstructions = (
  instruction: IInstruction,
  options: IApiOptions
): Promise<AxiosResponse> => {
  const url = `${options.apiRoot}/payment/api/payment/manageVendorPaymentInstructionForWire`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .post(url, instruction, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchAbaDetails = (
  aba: string,
  country: string | undefined,
  options: IApiOptions
): Promise<AxiosResponse<IAbaResponse | undefined>> => {
  const str = country && country !== 'US' ? 'WIRES' : 'ATOCH'
  const url = `${options.apiRoot}/cashmanagement/mm/odata/GetABA('${str},${aba}')`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data?.value?.[0])
}

export const fetchWireInstruction = (
  id: string,
  orgid: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/payment/searchVendorList?vendorId=${id}&orgId=${orgid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IFetchWireInstructionResponse[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchWirePayments = (
  fromDate: string,
  toDate: string,
  options: IApiOptions
): Promise<AxiosResponse<IWirePaymentResponse | undefined>> => {
  const url = `${options.apiRoot}/payment/api/enterprise/getBillInfo?fromDate=${fromDate}&toDate=${toDate}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const createWireInstructionsForSecurityService = (
  instruction: IWireInstructionForSecurity,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/mm/odata/MMPaymentInstructionSubmit`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .post<ICreateWireInstructionResponse>(url, instruction, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
