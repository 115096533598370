import { ISystemUser, ISystemUserRole } from 'api/dynamics'
import { createSelector } from 'reselect'
import { useGetCurrentSystemuserQuery } from 'store/api/dynamics'
import { DynamicsUserRolesEnum } from '../../../../../store/user/dynamicsUser'
import {
  getBdaDepartmentAllowancesItems,
  getBdaDepartmentAllowancesLoading
} from './bdaDepartmentAllowances'
import { getIsBdaPayrollExpensesLoading } from './bdaPayrollExpenses'
import { getIsBdaTransactionsLoading } from './bdaTransactions'

export const useBdaRoles = () => {
  return useGetCurrentSystemuserQuery(undefined, {
    selectFromResult: (result) => ({
      isBdaAdmin: selectIsBdaAdmin(result),
      isEntitledForTransactions: selectIsEntitledForTransactions(result),
      isEntitledForPayroll: selectIsEntitledForPayroll(result),
      isEntitledForDiscountSharing: selectIsEntitledForDiscountSharing(result)
    })
  })
}

export const getIsEntitledForPayroll = (roles?: ISystemUserRole[]) =>
  roles?.some(({ name }) =>
    [
      DynamicsUserRolesEnum.SystemAdministrator,
      DynamicsUserRolesEnum.BDAAdmin,
      DynamicsUserRolesEnum.BDAOwner
    ].includes(name as DynamicsUserRolesEnum)
  )

const rolesResultSelector = (result?: { data?: ISystemUser }) =>
  result?.data?.systemuserroles_association

const selectIsBdaAdmin = createSelector(rolesResultSelector, (roles) =>
  roles?.some(({ name }) =>
    [
      DynamicsUserRolesEnum.SystemAdministrator,
      DynamicsUserRolesEnum.BDAAdmin
    ].includes(name as DynamicsUserRolesEnum)
  )
)

const selectIsEntitledForTransactions = createSelector(
  rolesResultSelector,
  (roles) =>
    roles?.some(({ name }) =>
      [
        DynamicsUserRolesEnum.SystemAdministrator,
        DynamicsUserRolesEnum.BDAAdmin,
        DynamicsUserRolesEnum.BDAOwner,
        DynamicsUserRolesEnum.BDATransactionReviewer
      ].includes(name as DynamicsUserRolesEnum)
    )
)

const selectIsEntitledForPayroll = createSelector(
  rolesResultSelector,
  getIsEntitledForPayroll
)

const selectIsEntitledForDiscountSharing = createSelector(
  rolesResultSelector,
  (roles) =>
    roles?.some(({ name }) =>
      [
        DynamicsUserRolesEnum.SystemAdministrator,
        DynamicsUserRolesEnum.BDAAdmin,
        DynamicsUserRolesEnum.BDAOwner,
        DynamicsUserRolesEnum.BDADiscountSharingReviewer
      ].includes(name as DynamicsUserRolesEnum)
    )
)

export const hasAccessToMultipleDepartments = createSelector(
  [getBdaDepartmentAllowancesItems],
  (items) => items && items.length > 1
)

export const getIsBdaLoading = createSelector(
  [
    getIsBdaPayrollExpensesLoading,
    getIsBdaTransactionsLoading,
    getBdaDepartmentAllowancesLoading
  ],
  (payrollLoading, transactions, departments) =>
    [payrollLoading, transactions, departments].some(Boolean)
)
