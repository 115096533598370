import axios from 'axios'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import {
  IQBOReportActivity,
  IQBOReportHoldings,
  IQBOReportJournal,
  IQBOReportMarkToMarket,
  IQBOReportRequest
} from '../store/types'

const formatParam = (name: string, value?: string) =>
  value ? `${name}=${value}&` : ''

const formatListParam = (name: string, list?: string[]) =>
  list && list?.length > 0 ? `&${name}=${list?.join(',')}` : ''

export const fetchQBOReportJournalActivity = (
  request: IQBOReportRequest,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/QBO/journals?${formatParam(
    'clientid',
    request?.id
  )}period=${request.period}${formatListParam('Accounts', request?.accounts)}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<{ Journals?: IQBOReportJournal[] }>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data?.Journals || [])
}
export const fetchQBOReportActivity = (
  request: IQBOReportRequest,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/QBO/activities?${formatParam(
    'clientid',
    request?.id
  )}period=${request.period}${formatListParam('Accounts', request?.accounts)}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<{ Activities?: IQBOReportActivity[] }>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data?.Activities || [])
}
export const fetchQBOReportHoldings = (
  request: IQBOReportRequest,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/QBO/holdings?${formatParam(
    'clientid',
    request?.id
  )}period=${request.period}${formatListParam('Accounts', request?.accounts)}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<{ Holdings: IQBOReportHoldings[] }>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data?.Holdings || [])
}

export const fetchQBOReportMTM = (
  request: IQBOReportRequest,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/QBO/MTM?${formatParam(
    'clientid',
    request?.id
  )}period=${request.period}${formatListParam('Accounts', request?.accounts)}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<{ MTM: IQBOReportMarkToMarket[] }>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data?.MTM || [])
}
