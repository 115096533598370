import { IFontStyles, Text } from '@fluentui/react'
import React from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'
import { parseDateISOStringInLocalTimezone } from 'shared'

export const DateCell: React.FC<{
  value?: string
  showTime?: boolean
  size?: keyof IFontStyles
}> = ({ value, showTime, size }) =>
  value ? (
    <Text nowrap variant={size}>
      <FormattedDate
        day="2-digit"
        month="2-digit"
        year="numeric"
        value={new Date(value)}
      />
      {showTime && (
        <span>
          {' '}
          <FormattedTime value={new Date(value)} />
        </span>
      )}
    </Text>
  ) : (
    <Text variant={size}>--</Text>
  )

export const DateOnlyCell: React.FC<{
  value?: string
  size?: keyof IFontStyles
}> = ({ value, size }) => {
  return value ? (
    <Text nowrap={true} variant={size}>
      <FormattedDate
        day="2-digit"
        month="2-digit"
        year="numeric"
        value={parseDateISOStringInLocalTimezone(value)}
      />
    </Text>
  ) : (
    <Text variant={size}>--</Text>
  )
}
