import { PositionColumnIds } from 'features/Lists/positions/ColumnDefinitions'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useUpdateEffect } from 'react-use'
import { selectSelectedHouseholds } from '../../../store/aiDashboard'
import { createAiPositionListFilter } from '../store'
import { AiPositionListTabNamesEnum } from '../types'
import { useAiDashboardList } from './useList'

const householdIdFilterName = PositionColumnIds.householdId

export const useAiDashboardHouseholdIdFilter = () => {
  const selectedHouseholds = useSelector(selectSelectedHouseholds)
  const householdIdFilter = useMemo(() => {
    if (!selectedHouseholds?.length) {
      return undefined
    }
    return createAiPositionListFilter(householdIdFilterName, {
      type: 'facet',
      values: selectedHouseholds,
      hidden: true
    })
  }, [selectedHouseholds])
  return { householdIdFilter }
}

export const useSyncAiDashboardListWithHousehold = (
  listType: AiPositionListTabNamesEnum
) => {
  const { setFilters, removeFilters } = useAiDashboardList(listType)
  const { householdIdFilter } = useAiDashboardHouseholdIdFilter()

  useUpdateEffect(() => {
    householdIdFilter
      ? setFilters?.({ [householdIdFilterName]: householdIdFilter })
      : removeFilters?.([householdIdFilterName])
  }, [householdIdFilter, removeFilters, setFilters])
}
