import { Dropdown, IDropdownOption, Stack, TextField } from '@fluentui/react'
import React from 'react'
import {
  IListsFilter,
  IListsNumberRangeFilter,
  NumberFilterTypes,
  NumberFilterTypesEnum
} from '../../../contracts/IListsFilter'
import { IListsFilterEditProps } from '../contracts/IListsFilterEditProps'

export interface IListsNumberRangeFilterComponentProps
  extends IListsFilterEditProps {
  filter: IListsNumberRangeFilter
}

const mapToComboBoxOptions = <T extends { [key: string]: string }>(
  options: T
): IDropdownOption[] =>
  Object.entries(options).map(
    ([key, text]): IDropdownOption => ({
      key,
      text
    })
  )

const FILTER_TYPE_OPTIONS = mapToComboBoxOptions(NumberFilterTypes)

export const ListsNumberRangeFilterComponent: React.FC<
  IListsNumberRangeFilterComponentProps
> = ({ filter, onChange }) => {
  const { min, max, value, filterType } = filter

  const fireOnChange = (newNumberRangeFilter: IListsNumberRangeFilter) => {
    const newListsFilter: IListsFilter = newNumberRangeFilter
    const type = filterType || 'range'

    onChange({
      ...newListsFilter,
      hasValue:
        (type === 'range' &&
          (newNumberRangeFilter.min != null ||
            newNumberRangeFilter.max != null)) ||
        newNumberRangeFilter.value != null
    })
  }

  const getValue = (val?: string) => {
    const float = parseFloat(val || '')
    return isNaN(float) ? undefined : float
  }

  const onMinValueChange = (_: any, newValue?: string) =>
    fireOnChange({
      ...filter,
      min: getValue(newValue)
    })
  const onMaxValueChange = (_: any, newValue?: string) =>
    fireOnChange({
      ...filter,
      max: getValue(newValue)
    })
  const onValueChange = (_: any, newValue?: string) =>
    fireOnChange({
      ...filter,
      value: getValue(newValue)
    })

  const onFilterTypeChange = (_: any, option?: IDropdownOption) => {
    if (!option) {
      return
    }
    fireOnChange({
      ...filter,
      filterType: option.key as keyof typeof NumberFilterTypesEnum
    })
  }

  const selectedFilterType = filterType || 'range'

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Dropdown
        title="Filter Type"
        styles={{ root: { height: '35px' } }}
        selectedKey={selectedFilterType}
        options={FILTER_TYPE_OPTIONS}
        onChange={onFilterTypeChange}
      />
      <Stack
        tokens={{ childrenGap: 10 }}
        horizontal={true}
        verticalAlign="center"
      >
        {selectedFilterType === 'range' && (
          <>
            <Stack.Item grow={1}>
              <TextField
                styles={{ field: { minWidth: 0, width: '100px' } }}
                type="number"
                step="0.01"
                placeholder="Min"
                autoComplete="Off"
                value={(min as any) != null ? (min as any) : ''}
                onChange={onMinValueChange}
                autoFocus={true}
              />
            </Stack.Item>
            <Stack.Item align="center">
              <b>to</b>
            </Stack.Item>
            <Stack.Item grow={1}>
              <TextField
                styles={{ field: { minWidth: 0, width: '100px' } }}
                type="number"
                step="0.01"
                placeholder="Max"
                autoComplete="Off"
                value={(max as any) != null ? (max as any) : ''}
                onChange={onMaxValueChange}
              />
            </Stack.Item>
          </>
        )}
        {selectedFilterType !== 'range' && (
          <TextField
            styles={{ root: { width: '100%' } }}
            type="number"
            placeholder="Value"
            autoComplete="Off"
            value={(value as any) ?? ''}
            onChange={onValueChange}
            autoFocus={true}
          />
        )}
      </Stack>
    </Stack>
  )
}
