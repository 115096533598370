import {
  Callout,
  DefaultButton,
  DirectionalHint,
  ITheme,
  Link,
  Persona,
  PersonaInitialsColor,
  PersonaSize,
  Stack,
  Text
} from '@fluentui/react'
import React, { useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useClasses } from '../../../shared/hooks/useClasses'
import { rdotUserActions } from '../../../store/user/rdotUser'
import {
  getRdotUserDisplayName,
  getRdotUsername
} from '../../../store/user/selectors'
import { UserButtonComponent } from '../components/UserButton'

const getThemedClasses = (theme: ITheme) => {
  return {
    userCallout: {
      boxShadow: theme.effects.elevation16
    },
    userCalloutContent: {
      padding: '10px',
      minWidth: '200px'
    }
  }
}

const UserDisplayName: React.FC<{ userDisplayName?: string }> = ({
  userDisplayName
}) => (
  <Text variant="large" nowrap={true}>
    {userDisplayName}
  </Text>
)

const ViewAccountLink: React.FC<{ username?: string }> = ({ username }) => {
  return (
    <Link
      target="_blank"
      href={`https://account.activedirectory.windowsazure.com/profile?ref=MeControl&login_hint=${username}`}
    >
      View Account
    </Link>
  )
}

export const UserButtonContainer = () => {
  const dispatch = useDispatch()
  const [showUserCallout, setShowUserCallout] = useState(false)
  const userButton = useRef<HTMLDivElement>(null)
  const toggleUserCallout = useCallback(
    () => setShowUserCallout(!showUserCallout),
    [showUserCallout]
  )
  const closeUserCallout = useCallback(() => setShowUserCallout(false), [])
  const onLogout = useCallback(
    () => dispatch(rdotUserActions.logoutRequested()),
    [dispatch]
  )
  const onSwitchAccount = useCallback(
    () => dispatch(rdotUserActions.switchAccount()),
    [dispatch]
  )

  const username = useSelector(getRdotUsername)
  const userDisplayName = useSelector(getRdotUserDisplayName)

  const onRenderTertiaryText = useCallback(
    () => <ViewAccountLink username={username} />,
    [username]
  )
  const onRenderPrimaryText = useCallback(
    () => <UserDisplayName userDisplayName={userDisplayName} />,
    [userDisplayName]
  )

  const classes = useClasses(getThemedClasses)
  return (
    <>
      <span ref={userButton}>
        <UserButtonComponent
          username={username || ''}
          onClick={toggleUserCallout}
        />
      </span>
      {showUserCallout ? (
        <Callout
          className={classes.userCallout}
          styles={{ root: { zIndex: 3 } }}
          target={userButton}
          isBeakVisible={false}
          directionalHint={DirectionalHint.bottomRightEdge}
          doNotLayer={false}
          onDismiss={closeUserCallout}
        >
          <Stack
            className={classes.userCalloutContent}
            tokens={{ childrenGap: 20 }}
          >
            <Stack
              horizontal={true}
              horizontalAlign="space-between"
              verticalAlign="center"
              tokens={{ childrenGap: 20 }}
            >
              <Text nowrap={true} variant="small">
                Rockefeller Capital Management
              </Text>
              <DefaultButton
                styles={{ root: { border: 0 } }}
                onClick={onLogout}
              >
                <Text variant="small">Sign Out</Text>
              </DefaultButton>
            </Stack>
            <Persona
              initialsColor={PersonaInitialsColor.coolGray}
              onRenderPrimaryText={onRenderPrimaryText}
              secondaryText={username}
              onRenderTertiaryText={onRenderTertiaryText}
              size={PersonaSize.size72}
            />
            <DefaultButton
              styles={{ root: { border: 0 } }}
              onClick={onSwitchAccount}
            >
              Switch Account
            </DefaultButton>
          </Stack>
        </Callout>
      ) : null}
    </>
  )
}
