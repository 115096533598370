import { lazy } from 'react'
import { IModuleDefinition } from '../../../../../types'
export * from './store'

const Hurdles = lazy(() => import('./HurdlesContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/Hurdles/*',
    private: true,
    component: Hurdles
  },
  name: 'Hurdles'
}

export default moduleDefinition
