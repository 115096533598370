import { Stack } from '@fluentui/react'
import { IOdataListFacetActions } from 'features/OdataList/common/IODataListFacetActions'
import { IOdataListFacetSelectors } from 'features/OdataList/common/IODataListFacetSelectors'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingComponent } from '../../../shared/components/Loading'
import { getEnableDataMaskingPreference } from '../../../store/user/selectors'
import { IListsFacetFilter } from '../../Lists/core/contracts/IListsFilter'
import { ListsFacetFilterComponent } from '../../Lists/core/features/filter/components/ListsFacetFilter'
import { IListsFilterEditProps } from '../../Lists/core/features/filter/contracts/IListsFilterEditProps'

export interface IListsFacetFilterProps extends IListsFilterEditProps {
  filter: IListsFacetFilter
}

export const createConnectedODataListFacetFilter = <T,>(
  facetActions: IOdataListFacetActions,
  facetSelectors: IOdataListFacetSelectors<T>
) => {
  const ListsStringFilterContainer: React.FC<IListsFacetFilterProps> = ({
    onChange,
    filter
  }) => {
    const onFilterChange = useCallback(
      (selectedValues: string[]) => {
        const newFilter: IListsFacetFilter = {
          ...filter,
          values: selectedValues,
          hasValue: selectedValues.length > 0
        }
        onChange(newFilter)
      },
      [filter, onChange]
    )

    const { id, values, mask } = filter

    const facets = useSelector(facetSelectors.getItems)
    const isFacetLoading = useSelector(facetSelectors.getIsLoading)
    const shouldMask = useSelector(getEnableDataMaskingPreference)
    const facet = facets?.[id]
    const dispatch = useDispatch()
    const mappedFacets = Object.values(facet || {})?.flatMap((x) => x)

    useEffect(() => {
      if (!facet) {
        dispatch(
          facetActions.request({
            id
          })
        )
      }
    }, [facet, dispatch, id])

    return (
      <Stack styles={{ root: { minHeight: '150px' } }}>
        {isFacetLoading ? (
          <LoadingComponent />
        ) : (
          <ListsFacetFilterComponent
            facets={mappedFacets}
            selectedValues={values}
            onChange={onFilterChange}
            maskValues={shouldMask && mask}
          />
        )}
      </Stack>
    )
  }
  return ListsStringFilterContainer
}
