import { ReactNode } from 'react'
import { FormattedNumber } from 'react-intl'

export const amountFormatter = (
  value?: number,
  fractionDigits = 0
): ReactNode => {
  return value ? (
    <>
      <FormattedNumber
        value={value}
        style="currency"
        currency="USD"
        maximumFractionDigits={fractionDigits}
        minimumFractionDigits={fractionDigits}
      />
    </>
  ) : (
    '--'
  )
}
