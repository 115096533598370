import { lazy } from 'react'
import { combineReducers } from 'redux'
import { IModuleDefinition } from '../../../types'
import { clientsModulesReducer, clientsModulesSagas } from './modules'

const ClientsContainer = lazy(() => import('./ClientsContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/clients/*',
    private: true,
    component: ClientsContainer
  },
  name: 'Clients'
}

export default moduleDefinition

export const clientsModuleReducer = combineReducers({
  modules: clientsModulesReducer
})

export const clientsModuleSagas = [...clientsModulesSagas]
