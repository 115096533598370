import { createSelector, createSlice } from '@reduxjs/toolkit'
import { AppState } from 'store'

export interface IActivitiesListUiState {
  initialized: boolean
}

const initialState: IActivitiesListUiState = {
  initialized: false
}

export const {
  actions: activitiesListUiActions,
  reducer: activitiesListUiReducer
} = createSlice({
  name: '@features/@lists/@activities/@ui',
  initialState,
  reducers: {
    initialize: (state) => {
      state.initialized = true
    }
  }
})

const rootSelector = (state: AppState) => state.features.lists.activity.ui

export const selectActivitiesInitialized = createSelector(
  rootSelector,
  ({ initialized }) => initialized
)
