import {
  Callout,
  DefaultButton,
  FontSizes,
  Icon,
  IconButton,
  makeStyles,
  Stack,
  Text
} from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { useActivateProfilePanel } from 'features/ActivateProfile/store/activateProfilePanel'
import { useClientInvitePanel } from 'features/ClientInvite/store/clientInvitePanel'
import { TextCell } from 'features/Lists/core/components/TextCell'
import { RelatedPartyMenu } from 'features/RelatedPartyPanel/RelatedPartyMenu'
import React, { useCallback, useRef } from 'react'
import { FormattedDate } from 'react-intl'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { isNotNullOrFalse } from 'shared/guards'
import { IClient, ILoginDetails } from '../../api/client.types'
import { getMostRecentLoginDetails } from '../../shared/client'

const useClasses = makeStyles((theme) => ({
  container: {
    width: '100%'
  },
  loginidButton: {
    border: 'solid 1px transparent',
    width: 'calc(100% + 20px)',
    height: '41px',
    margin: '-7px -10px',
    padding: '7px 10px'
  },
  loginidButtonEffects: {
    cursor: 'pointer',
    '&:hover, &.active': {
      backgroundColor: theme.palette.white,
      border: `solid 1px ${theme.palette.themeLighter}`,
      boxShadow: theme.effects.elevation4
    },
    '&:hover button, &.active button': {
      display: 'block'
    }
  }
}))

export const InvitedEmailCell: React.FC<{
  client: IClient
}> = ({ client }) => {
  const classes = useClasses()
  const mostRecentLogin = getMostRecentLoginDetails(
    client?.loginDetails?.filter((x) => x.loginid)
  )
  const { loginid, WealthscapeID } =
    mostRecentLogin ||
    client?.loginDetails?.find((x) => x.loginid) ||
    client?.loginDetails?.[0] ||
    {}
  const [
    isCalloutVisible,
    { toggle: toggleIsCalloutVisible, setFalse: hideCallout }
  ] = useBoolean(false)
  const { open } = useClientInvitePanel()

  const onInviteClicked = useCallback(() => {
    open(WealthscapeID || '', 'invite')
  }, [WealthscapeID, open])

  const { open: openAccountLinkingPanel } = useActivateProfilePanel()

  const onActivateClicked = useCallback(() => {
    openAccountLinkingPanel(client?.LegalEntityID, client?.LegalEntityName)
  }, [client?.LegalEntityID, client?.LegalEntityName, openAccountLinkingPanel])

  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <div className={classes.container}>
      {loginid && (
        <div
          onClick={toggleIsCalloutVisible}
          ref={containerRef}
          className={[
            classes.loginidButton,
            classes.loginidButtonEffects,
            isCalloutVisible && 'active'
          ]
            .filter(isNotNullOrFalse)
            .join(' ')}
        >
          <Stack
            styles={{ root: { minWidth: 0, height: '100%' } }}
            horizontal={true}
            verticalAlign="center"
            tokens={{ childrenGap: 5 }}
            horizontalAlign="space-between"
          >
            <Stack styles={{ root: { minWidth: 0 } }}>
              <TextCell>{loginid}</TextCell>

              {mostRecentLogin && (
                <LoginMfaEauthSummary loginDetails={mostRecentLogin} />
              )}
            </Stack>
            <IconButton
              iconProps={{ iconName: 'ChevronDown' }}
              checked={isCalloutVisible}
            />
          </Stack>
        </div>
      )}

      {client.legalEntityType === 'Individual' &&
        !loginid &&
        !!WealthscapeID && (
          <DefaultButton
            styles={{
              root: {
                padding: '3px 2px',
                height: 'auto',
                minWidth: 'auto'
              },
              label: { fontWeight: 'normal', fontSize: FontSizes.small }
            }}
            onClick={onInviteClicked}
          >
            Invite Now
          </DefaultButton>
        )}

      {client.legalEntityType === 'Individual' &&
        !loginid &&
        !WealthscapeID && (
          <DefaultButton
            styles={{
              root: {
                padding: '3px 2px',
                height: 'auto',
                minWidth: 'auto'
              },
              label: { fontWeight: 'normal', fontSize: FontSizes.small }
            }}
            onClick={onActivateClicked}
          >
            Activate Profile
          </DefaultButton>
        )}

      {isCalloutVisible && (
        <Callout
          onDismiss={toggleIsCalloutVisible}
          target={containerRef}
          isBeakVisible={false}
        >
          <RelatedPartyMenu
            loginid={loginid}
            wsportaluserid={WealthscapeID}
            displayName={client.LegalEntityName}
            partyId={client.LegalEntityID}
            onItemClicked={hideCallout}
          />
        </Callout>
      )}
    </div>
  )
}

const LoginMfaEauthSummary: React.FC<{ loginDetails: ILoginDetails }> = ({
  loginDetails
}) => {
  const { lastLogin, isEnrolledInEauth, isEnrolledInMFA } = loginDetails
  return (
    <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
      <Stack.Item styles={{ root: { minWidth: '0px' } }}>
        <Text variant="small" nowrap={true} block={true}>
          {lastLogin && (
            <FormattedDate
              day="2-digit"
              month="2-digit"
              year="numeric"
              value={parseDateISOStringInLocalTimezone(lastLogin)}
            />
          )}
        </Text>
      </Stack.Item>
      <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
        <Icon
          title={'Enrolled in eAuth'}
          id="eAuthnotEnrolled"
          iconName={isEnrolledInEauth === 'Yes' ? 'SkypeCircleCheck' : ''}
          styles={{ root: { cursor: 'default' } }}
        />
        <Icon
          title={'Enrolled in MFA'}
          id="MFAenrolled"
          iconName={isEnrolledInMFA === 'Yes' ? 'AuthenticatorApp' : ''}
          styles={{ root: { cursor: 'default' } }}
        />
      </Stack>
    </Stack>
  )
}
