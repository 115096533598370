import { intersection } from 'lodash'
import { flow } from 'lodash/fp'
import { createSelector } from 'reselect'
import { call, delay, put, select, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { AppState } from '..'
import { IAccount } from '../../api/account.types'
import { ISearchResult } from '../../api/common.types'
import {
  OdataFilterOperatorEnum,
  OdataPropertyFilterGroup
} from '../../api/odata'
import { notNullOrEmpty, stringArraysAreEqual } from '../../shared'
import { search } from '../shared/sagas'

const REQUEST = '@context/@account/REQUEST'
const START = '@context/@account/START'
const UPDATE = '@context/@account/UPDATE'
const ERROR = '@context/@account/ERROR'
const COMPLETE = '@context/@account/COMPLETE'

export const accountContextUpdateActions = {
  request: createAction(REQUEST)<string[] | undefined>(),
  start: createAction(START)<string[]>(),
  success: createAction(UPDATE)<IAccount[] | undefined>(),
  failure: createAction(ERROR)<Error>(),
  complete: createAction(COMPLETE)()
}

const UPDATE_ACCOUNT_SELECTION = '@context/@account/UPDATE_ACCOUNT_SELECTION'

export const accountContextSelectionActions = {
  update: createAction(UPDATE_ACCOUNT_SELECTION)<string[] | undefined>()
}

export type AccountContextActionTypes =
  | ActionType<typeof accountContextUpdateActions>
  | ActionType<typeof accountContextSelectionActions>

export interface IAccountContextState {
  items?: IAccount[]
  loading?: boolean
  error?: Error
  selectedItemIds?: string[]
}

const initialState: IAccountContextState = {
  loading: false
}

export const accountContextReducer = createReducer<
  IAccountContextState,
  AccountContextActionTypes
>(initialState)
  .handleAction(accountContextUpdateActions.start, (state) => ({
    ...initialState,
    selectedItemIds: state.selectedItemIds,
    loading: true
  }))
  .handleAction(accountContextUpdateActions.success, (state, action) => ({
    ...state,
    items: action.payload
  }))
  .handleAction(accountContextUpdateActions.failure, (state, action) => ({
    ...state,
    error: action.payload
  }))
  .handleAction(accountContextUpdateActions.complete, (state) => ({
    ...state,
    loading: false
  }))
  .handleAction(accountContextSelectionActions.update, (state, action) => ({
    ...state,
    selectedItemIds: action.payload
  }))

export const getAccountContext = (state: AppState) => state.context.account
export const getAccountContextItems = flow(getAccountContext, (x) => x.items)
export const getIsAccountContextLoading = flow(
  getAccountContext,
  (x) => x.loading
)
export const getAccountContextError = flow(getAccountContext, (x) => x.error)
export const getAccountContextIds = createSelector(
  [getAccountContextItems],
  (items) => items?.map((x) => x.id)
)

export const getAccountContextSelectedAccountIds = flow(
  getAccountContext,
  (x) => x.selectedItemIds
)

const requestAccounts = function* (
  action: ReturnType<typeof accountContextUpdateActions.request>
) {
  yield delay(25)

  console.debug('account context update requested', action.payload)

  if (!action.payload || !action.payload.length) {
    yield put(accountContextUpdateActions.success(undefined))
    yield put(accountContextUpdateActions.complete())
    return
  }

  const currentAccountIds: string[] | undefined = yield select(
    getAccountContextIds
  )
  if (
    currentAccountIds &&
    stringArraysAreEqual(currentAccountIds, action.payload)
  ) {
    return
  }

  yield put(accountContextUpdateActions.start(action.payload))
}

function* fetchAccounts(
  action: ReturnType<typeof accountContextUpdateActions.start>
) {
  const filter: OdataPropertyFilterGroup = {
    and: [
      {
        operator: OdataFilterOperatorEnum.searchin,
        path: 'id',
        type: 'string',
        value: action.payload
      }
    ]
  }

  try {
    const results: ISearchResult<IAccount> = yield call(
      search,
      'account' as const,
      {
        filters: [filter]
      }
    )

    if (!results?.value) {
      throw new Error(`Account context is undefined for: ${action.payload}`)
    }

    yield put(accountContextUpdateActions.success(results.value))
  } catch (e: any) {
    yield put(accountContextUpdateActions.failure(e))
  }

  yield put(accountContextUpdateActions.complete())
}

export const accountSagas = [
  () => takeLatest(accountContextUpdateActions.request, requestAccounts),
  () => takeLatest(accountContextUpdateActions.start, fetchAccounts),
  () =>
    takeLatest(accountContextUpdateActions.success, function* () {
      const selectedAccountIds: string[] | undefined = yield select(
        getAccountContextSelectedAccountIds
      )
      const allAccounts: IAccount[] | undefined = yield select(
        getAccountContextItems
      )

      if (!selectedAccountIds?.length || !allAccounts?.length) {
        return
      }

      const allAccountIds = allAccounts?.map((x) => x.id) || []
      const newSelection = intersection(
        selectedAccountIds,
        allAccountIds
      ).filter(notNullOrEmpty)

      if (stringArraysAreEqual(newSelection, selectedAccountIds)) {
        return
      }

      yield put(
        accountContextSelectionActions.update(
          newSelection.length ? newSelection : undefined
        )
      )
    }),
  () =>
    takeLatest(accountContextSelectionActions.update, function* () {
      const selectedAccountIds = yield* select(
        getAccountContextSelectedAccountIds
      )
      console.debug(
        'account context selected accounts updated',
        selectedAccountIds
      )
    })
]
