import { call } from 'typed-redux-saga'
import {
  getApplicationById,
  getClientCollaborationGroupsForUser,
  getTeamDetail,
  getUserWithEmail
} from '../../../api/graph'
import { getMicrosoftGraphApiOptions } from './auth'

export const getTeamURL = function* (email?: string) {
  const onFailure = () => {
    throw new Error('Failed to acquire url for team')
  }

  if (!email) {
    return onFailure()
  }

  const apiOptions = yield* call(getMicrosoftGraphApiOptions)

  const user = yield* call(getUserWithEmail, email, apiOptions)
  const userId = user?.id
  if (!userId) {
    return onFailure()
  }

  const groups = yield* call(
    getClientCollaborationGroupsForUser,
    userId,
    apiOptions
  )
  if (!groups || !groups.length) {
    return onFailure()
  }

  const groupForTeam = groups.filter(
    (x) => x.classification === 'Client Collaboration'
  )[0]
  if (!groupForTeam) {
    return onFailure()
  }

  const team = yield* call(getTeamDetail, groupForTeam.id, apiOptions)
  if (team && team.webUrl) {
    return team.webUrl
  }

  return onFailure()
}

export const getApplication = function* (id: string) {
  const apiOptions = yield* call(getMicrosoftGraphApiOptions)
  const app = yield* call(getApplicationById, id, apiOptions)
  return app
}
