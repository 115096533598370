import { Checkbox, Stack, Text } from '@fluentui/react'
import React, { useCallback } from 'react'
import { IEnhancedClientProfileAccount } from 'store/api/datahub'

export const DelinkingListItem: React.FC<{
  account: IEnhancedClientProfileAccount
  addAccount: (account: string) => void
  removeAccount: (account: string) => void
  isChecked: boolean
}> = ({ account, addAccount, removeAccount, isChecked }) => {
  const onChange = useCallback(
    (ev?: any, checked?: boolean): void => {
      if (checked) {
        addAccount(account.accountKey)
      } else {
        removeAccount(account.accountKey)
      }
    },
    [account, addAccount, removeAccount]
  )
  return (
    <Stack horizontal={true} tokens={{ childrenGap: 5 }} verticalAlign="center">
      <Checkbox onChange={onChange} checked={isChecked} />
      <Stack>
        <Text variant="small" styles={{ root: { fontWeight: 'bold' } }}>
          {account.nickname}
        </Text>
        <Text>{account.accountNumber}</Text>
      </Stack>
    </Stack>
  )
}
