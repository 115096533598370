import { css } from '@emotion/react'
import { SnackBar } from 'modules/Advisory/modules/Rdot360/components/shared/Snackbar'
import { useIncomeFeatureStore } from 'modules/Advisory/modules/Rdot360/features/Income'
import { useGetIncomeTaxSummaryQueryForSelectedAccounts } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useMemo } from 'react'

import { AccountDataTable } from './AccountDataTable'

export const getClasses = () => ({
  progressIndicatorContainer: css({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  }),
  tableContainer: css({
    marginTop: '34px'
  })
})

export const AccountView = () => {
  const classes = useMemo(() => getClasses(), [])
  const { financialYear, incomeMode } = useIncomeFeatureStore()

  const { data, isFetching, error, isUninitialized, refetch } =
    useGetIncomeTaxSummaryQueryForSelectedAccounts(incomeMode, financialYear)

  return (
    <div css={classes.tableContainer}>
      {!!error && (
        <div css={{ paddingBottom: '5px' }}>
          <SnackBar
            message={(error as Error)?.message}
            type="Failure"
            actionButtonLabel="click to retry"
            onActionButtonClicked={refetch}
          />
        </div>
      )}

      <AccountDataTable
        isUninitialized={isUninitialized}
        isLoading={isFetching}
        data={data?.IncomeSubTotal}
      />
    </div>
  )
}
