import { DetailsListLayoutMode } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import type { IAnnuityPPLIAssets } from 'api/datahub'
import {
  IOdataDataListCellRenderProps,
  OdataList
} from 'features/OdataList/containers/OdataList'
import { constants } from 'modules/Advisory/shared/theme'
import { memo, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ColumnEditPanel, SecondaryHeader } from '../../features'
import { CellComponents } from '../../shared/annuityPPLIAssets/Cells'
import type { AnnuityPPLIAssetsColumnName } from '../../shared/annuityPPLIAssets/columnNameTypes'
import {
  getHoldingsExcelExportError,
  getHoldingsExcelExportIsLoading
} from '../../shared/export/rootSelectors'
import { useAdvisorIdFilter } from '../../shared/hooks/useAdvisorIdFilter'
import { useContractTypeFilter } from '../../shared/hooks/useContractTypeFilter'
import { holdingsExcelExportActions } from './excelExportActionsAndSagas'
import {
  actions as underlyingHoldingsActions,
  selectors as underlyingHoldingsSelectors
} from './store'

const AnnuitiesAndInsuranceCell: React.FC<
  IOdataDataListCellRenderProps<IAnnuityPPLIAssets>
> = memo(({ item, column }) => {
  const Component = CellComponents[column.name as AnnuityPPLIAssetsColumnName]
  return Component ? <Component item={item} column={column} /> : null
})

export const Holdings: React.FC = () => {
  const { facetActions, uiActions } = underlyingHoldingsActions
  const { facetSelectors, uiSelectors } = underlyingHoldingsSelectors

  const [isPanelOpen, { setTrue: openPanel, setFalse: closePanel }] =
    useBoolean(false)

  const dispatch = useDispatch()

  const isExportLoading = useSelector(getHoldingsExcelExportIsLoading)

  const isExportError = useSelector(getHoldingsExcelExportError)

  const exportToExcel = useCallback(() => {
    dispatch(holdingsExcelExportActions.request())
  }, [dispatch])

  const secondaryHeader = useMemo(
    () => (
      <SecondaryHeader
        exportToExcel={exportToExcel}
        isExportError={isExportError}
        isExportLoading={isExportLoading}
        openPanel={openPanel}
      />
    ),
    [exportToExcel, isExportError, isExportLoading, openPanel]
  )

  useAdvisorIdFilter(uiActions, uiSelectors)

  useContractTypeFilter({
    uiActions,
    contractType: 'Private Placement'
  })

  return (
    <>
      <h2 css={{ marginTop: '0' }}>Underlying Holdings</h2>
      <OdataList
        actions={uiActions}
        selectors={uiSelectors}
        facetActions={facetActions}
        facetSelectors={facetSelectors}
        onRenderCell={AnnuitiesAndInsuranceCell}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        secondaryHeader={secondaryHeader}
        stickyHeaderOffset={constants.headerHeight}
      />
      {isPanelOpen && (
        <ColumnEditPanel
          isPanelOpen={isPanelOpen}
          closePanel={closePanel}
          actions={uiActions}
          selectors={uiSelectors}
        />
      )}
    </>
  )
}
