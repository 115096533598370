import { combineReducers } from 'redux'
import { overridesFetchReducer, overridesFetchSagas } from './OverridesFetch'
import {
  overridePreferenceReducer,
  overridePreferenceSagas
} from './OverridesPreference'
import { overridesUpdateReducer, overridesUpdateSagas } from './OverridesUpdate'
import {
  securityReferenceFetchReducer,
  securityReferenceFetchSagas
} from './SecurityReferenceFetch'
import {
  securityToOverrideFetchReducer,
  securityToOverrideFetchSagas
} from './SecurityToOverride'

export const overridesReducer = combineReducers({
  overridesFetch: overridesFetchReducer,
  overridesUpdate: overridesUpdateReducer,
  securityToOverride: securityToOverrideFetchReducer,
  securityReferenceFetch: securityReferenceFetchReducer,
  overridePreference: overridePreferenceReducer
})

export const overridesSagas = [
  ...overridesFetchSagas,
  ...overridesUpdateSagas,
  ...securityToOverrideFetchSagas,
  ...securityReferenceFetchSagas,
  ...overridePreferenceSagas
]
