import { createReducer } from 'typesafe-actions'
import {
  projectDataActions,
  ProjectActionTypes,
  addEditProjectDataActions
} from './actions'

import { IProject, IProjectRequest } from './types'

export interface IProjectState {
  ProjectList?: {
    project?: IProject[]
    isLoading: boolean
    error?: Error
    pkid?: IProjectRequest
  }
  AddEditVendor?: {
    isLoading: boolean
    error?: Error
    project?: IProject
  }
}

const initialState: IProjectState = {
  ProjectList: {
    isLoading: true
  }
}

//create reducer for failed payment

export const projectReducer = createReducer<IProjectState, ProjectActionTypes>(
  initialState
)
  .handleAction(projectDataActions.request, (state, action) => ({
    ...state,
    ProjectList: {
      project: undefined,
      isLoading: true,
      error: undefined,
      pkid: action.payload
    }
  }))
  .handleAction(projectDataActions.success, (state, action) => ({
    ...state,
    ProjectList: {
      isLoading: false,
      error: undefined,
      project: action.payload
    }
  }))
  .handleAction(projectDataActions.failure, (state, action) => ({
    ...state,
    ProjectList: {
      project: undefined,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(addEditProjectDataActions.request, (state) => ({
    ...state,
    AddEditVendor: {
      project: undefined,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(addEditProjectDataActions.success, (state, action) => ({
    ...state,
    AddEditVendor: {
      isLoading: false,
      error: undefined,
      project: action.payload
    }
  }))
  .handleAction(addEditProjectDataActions.failure, (state, action) => ({
    ...state,
    AddEditVendor: {
      isLoading: false,
      error: action.payload
    }
  }))
