import { IListsFacetFilter } from 'features/Lists/core/contracts/IListsFilter'
import { IOdataListUiActions } from 'features/OdataList/common/IOdataListUiActions'
import { IOdataListUiSelectors } from 'features/OdataList/common/IOdataListUiSelectors'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const usePolicyStatusFilter = <T, U>(
  uiActions: IOdataListUiActions,
  uiSelectors: IOdataListUiSelectors<T, U>,
  policyStatusFilters?: string[]
) => {
  const dispatch = useDispatch()

  const filters = useSelector(uiSelectors.getFilters)

  useEffect(() => {
    const policyStatusFilterId = 'Policy Status'
    dispatch(
      uiActions.updateFilters({
        [policyStatusFilterId]: {
          ...filters?.[policyStatusFilterId],
          hasValue: true,
          values: policyStatusFilters
        } as IListsFacetFilter
      })
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, uiActions])
}
