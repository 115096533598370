import { DefaultButton, PrimaryButton, Stack } from '@fluentui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { IListsFilter } from '../../../contracts/IListsFilter'
import { ListsFacetActions } from '../../../store/actions'
import { ListsFacetSelectors } from '../../../store/selectors'
import { createListsFilterEditContainer } from './ListsFilterEdit'

export interface IListsFilterEditStandalone {
  filter: IListsFilter
  onApply: (newFilter: IListsFilter) => void
  onCancel: () => void
}

export const createListsFilterEditStandaloneContainer = (
  facetSelectors: ListsFacetSelectors,
  facetActions: ListsFacetActions
) => {
  const FilterEdit = createListsFilterEditContainer(
    facetSelectors,
    facetActions
  )
  const FilterEditStandalone: React.FC<IListsFilterEditStandalone> = ({
    filter,
    onApply,
    onCancel
  }) => {
    useEffect(() => {
      setModifiedFilter({ ...filter })
    }, [filter])

    const [modifiedFilter, setModifiedFilter] = useState<
      IListsFilter | undefined
    >()

    const onChange = useCallback((newFilter?: IListsFilter) => {
      setModifiedFilter(newFilter ? { ...newFilter } : undefined)
    }, [])

    const onApplyClicked = useCallback(
      () => modifiedFilter?.hasValue && onApply(modifiedFilter),
      [modifiedFilter, onApply]
    )

    const onKeyPress = useCallback(
      (event?: React.KeyboardEvent<HTMLElement>) => {
        if (event?.key !== 'Enter' || !modifiedFilter?.hasValue) {
          return
        }

        onApply(modifiedFilter)
      },
      [modifiedFilter, onApply]
    )

    if (!modifiedFilter) {
      return null
    }

    return (
      <Stack tokens={{ childrenGap: 20 }} onKeyPress={onKeyPress}>
        <Stack.Item>
          <FilterEdit filter={modifiedFilter} onChange={onChange} />
        </Stack.Item>
        <Stack
          horizontal={true}
          tokens={{ childrenGap: 5 }}
          horizontalAlign="end"
        >
          <PrimaryButton
            onClick={onApplyClicked}
            disabled={!modifiedFilter.hasValue}
          >
            Apply
          </PrimaryButton>
          <DefaultButton onClick={onCancel}>Cancel</DefaultButton>
        </Stack>
      </Stack>
    )
  }
  return FilterEditStandalone
}
