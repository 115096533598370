import { css, useTheme } from '@emotion/react'
import { IDropdownOption } from '@fluentui/react'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import { fromPairs } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useStore } from 'react-redux'
import { downloadUrlAsFile } from 'shared/downloads'
import { Icon } from '../../features/Icons/Icon'
import { useClientDashboardTilePreferences } from '../../hooks/useClientDashboardPreferences'
import { useBalanceSummaryDetailsUiState } from '../../modules/Balances/balanceSummaryDetailsUIState'
import { PrintDropdownMenu } from '../../shared/PrintDropdownMenu'
import { ClientDashboardTiles } from '../../shared/types'
import { useRdot360BalancesContext } from '../../store/rdot360Context/useRdot360BalancesContext'
import { Dropdown, Error } from '../shared'
import DetailsNavigator from '../shared/DetailsNavigator/DetailsNavigator'
import { Searchbox } from '../shared/DetailTables/Searchbox'
import { BalanceSummaryChartContainer } from './BalanceSummaryChartContainer'
import { BalanceSummaryTable } from './BalanceSummaryTable'
import { getBalancesDetailTableColumns } from './BalanceSummaryTableColumns'
import type { BalancesExportWorker } from './export'
import { openBalancesPrintTab } from './features/PrintView/PrintView'
import {
  balancesDetailTableColumnNames,
  balancesDetailTableGroupingColumnNames
} from './shared'
import { useBalancesData } from './useBalancesData'

const tileName = ClientDashboardTiles.balanceTile

const options = Object.entries(balancesDetailTableGroupingColumnNames).map(
  ([, value]) => ({ key: value, text: value })
)

const useBalanceSummaryClasses = () => ({
  headerContainer: css({
    display: 'flex',
    alignItems: 'flex-end',
    flexFlow: 'row wrap',
    gap: 20,
    marginBottom: 24
  }),
  viewByContainer: css({
    display: 'flex',
    flexDirection: 'column'
  }),
  viewByLabel: css({
    fontSize: '14px',
    fontWeight: 500
  }),
  viewByDropdown: css({
    fontSize: '14px',
    width: 220,
    marginTop: '8px'
  }),
  searchBox: css({
    maxWidth: '246px',
    marginLeft: 'auto'
  }),
  refreshIcon: css({
    width: 24,
    height: 24,
    marginBottom: 6,
    marginRight: 20,
    cursor: 'pointer',
    marginLeft: 10
  }),
  printIcon: css({
    marginBottom: 6
  }),
  subHeaderContainer: css({
    display: 'flex',
    flexGrow: 1,
    alignItems: 'flex-end'
  }),
  iconStyle: css({
    width: 24,
    height: 24,
    cursor: 'pointer',
    marginBottom: 5,
    marginLeft: 20
  }),
  iconStyleDisable: css({
    width: 24,
    height: 24,
    marginBottom: 5,
    opacity: '0.5',
    marginLeft: 20
  })
})

export const BalanceSummaryDetailView = () => {
  const {
    selectedAccountBalances,
    isFetching,
    error,
    refetch: refetchBalanceDetailsData,
    invalidateCache
  } = useRdot360BalancesContext()
  const { searchText, setSearchText } = useBalanceSummaryDetailsUiState()
  const { setTilePreferences, tilePreferences } =
    useClientDashboardTilePreferences(tileName)

  const viewByKey = useMemo(
    () => tilePreferences?.viewByKey || (options[0].key as string),
    [tilePreferences]
  )

  const onViewByFilterChange = useCallback(
    (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option) {
        setTilePreferences({
          viewByKey: option.key as string
        })
      }
    },
    [setTilePreferences]
  )
  const columns = useMemo(getBalancesDetailTableColumns, [])
  const skipCag = viewByKey !== balancesDetailTableColumnNames.cag
  const { dataWithCustodian } = useBalancesData(skipCag)
  const exportToExcel = useCallback(async () => {
    if (!dataWithCustodian) {
      return
    }
    const columnDef = columns
      .filter(({ id }) => id)
      .map((x) => ({
        id: x.id || '',
        header: x.id
      }))
    const mappedExportData = dataWithCustodian?.map((x, i) =>
      fromPairs<any>(
        columns.map((column) => [column.id || '', column.accessorFn(x, i)])
      )
    )

    const worker = new Worker(new URL('./export.ts', import.meta.url))
    const { exportBalances } = wrap<BalancesExportWorker>(worker)
    const result = await exportBalances(mappedExportData, columnDef)
    const filename = `Balance Summary ${format(new Date(), 'MM-dd-yyyy')}.xlsx`
    downloadUrlAsFile(result, filename)
  }, [columns, dataWithCustodian])

  const classes = useBalanceSummaryClasses()
  const theme = useTheme()
  const store = useStore()
  const print = useCallback(
    (masked = true, hideHousehold = true) => {
      openBalancesPrintTab(masked, hideHousehold, store)
    },
    [store]
  )

  const isData = selectedAccountBalances && selectedAccountBalances?.length > 0
  const isDisabled = isFetching || !isData

  return (
    <>
      <div css={classes.headerContainer}>
        <DetailsNavigator />
        <div css={classes.subHeaderContainer}>
          <div css={classes.viewByContainer}>
            <div css={classes.viewByLabel}>View By</div>
            <div css={classes.viewByDropdown}>
              <Dropdown
                options={options}
                selectedKey={viewByKey}
                onChange={onViewByFilterChange}
              />
            </div>
          </div>
          <div css={classes.searchBox}>
            <Searchbox searchText={searchText} onChange={setSearchText} />
          </div>
          <div css={classes.refreshIcon}>
            <Icon
              type="Refresh"
              color={theme.colors.extraBlue2}
              onClick={invalidateCache}
            />
          </div>
          <div css={classes.printIcon}>
            <PrintDropdownMenu
              print={print}
              displayDisclaimer={false}
              isDisabled={isDisabled}
            />
          </div>
          <div css={isDisabled ? classes.iconStyleDisable : classes.iconStyle}>
            <Icon
              type="Download"
              width={24}
              height={24}
              onClick={isDisabled ? undefined : exportToExcel}
              color={theme.colors.extraBlue2}
              isDisabled={isDisabled}
            />
          </div>
        </div>
      </div>
      <BalanceSummaryChartContainer />
      <div css={{ marginTop: '34px' }}>
        <BalanceSummaryTable
          viewType={viewByKey}
          dataWithCustodian={dataWithCustodian}
          isFetching={isFetching}
        />
        {!!error && <Error refetch={refetchBalanceDetailsData} />}
      </div>
    </>
  )
}
