// import { MessageBarType } from '@fluentui/react'
import { IAccount } from 'api/account.types'
import { ISearchResult } from 'api/common.types'
import { createReducer } from 'typesafe-actions'
import { IPHubTypeCode } from '../../api/types'
import {
  JournalActions,
  fetchPhubCodes,
  getDefaultGiftCodeActions,
  toAccountSearchActions,
  toHouseholdAccountSearchActions
} from './actions'
import { IMMConfig } from './types'

export interface IJournalState {
  toAccountSearchState?: IToAccountSearchState
  toHouseholdAccountSearchState?: IToHouseholdAccountSearchState
  giftCodeState?: IGiftCodeState
  pHubCodeState?: IPHubState
  mmConfigState?: IMMConfigState
}

export interface IToAccountSearchState {
  isLoading: boolean
  toAccountSearchList?: ISearchResult<IAccount>
  error?: Error
}

export interface IToHouseholdAccountSearchState {
  isLoading: boolean
  toHouseholdAccountSearchList?: ISearchResult<IAccount>
  error?: Error
}
export interface IGiftCodeState {
  isLoading: boolean
  giftCode?: string
  error?: Error
}
export interface IPHubState {
  isLoading: boolean
  pHubCodes?: IPHubTypeCode[]
  error?: Error
}
export interface IMMConfigState {
  isLoading: boolean
  mmConfig?: IMMConfig[]
  error?: Error
}

const initialState: IJournalState = {}

export const journalReducer = createReducer<IJournalState, JournalActions>(
  initialState
)
  .handleAction(toAccountSearchActions.request, (state) => ({
    ...state,
    toAccountSearchState: {
      ...state.toAccountSearchState,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(toAccountSearchActions.success, (state, action) => ({
    ...state,
    toAccountSearchState: {
      isLoading: false,
      toAccountSearchList: action.payload,
      error: undefined
    }
  }))
  .handleAction(toAccountSearchActions.failure, (state, action) => ({
    ...state,
    toAccountSearchState: {
      isLoading: false,
      toAccountSearchList: undefined,
      error: action.payload
    }
  }))
  .handleAction(toAccountSearchActions.clear, (state) => ({
    ...state,
    toAccountSearchState: undefined
  }))

  .handleAction(toHouseholdAccountSearchActions.request, (state) => ({
    ...state,
    toHouseholdAccountSearchState: {
      toHouseholdAccountSearchList: undefined,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(toHouseholdAccountSearchActions.success, (state, action) => ({
    ...state,
    toHouseholdAccountSearchState: {
      isLoading: false,
      toHouseholdAccountSearchList: action.payload,
      error: undefined
    }
  }))
  .handleAction(toHouseholdAccountSearchActions.failure, (state, action) => ({
    ...state,
    toHouseholdAccountSearchState: {
      isLoading: false,
      toHouseholdAccountSearchList: undefined,
      error: action.payload
    }
  }))
  .handleAction(toHouseholdAccountSearchActions.clear, (state) => ({
    ...state,
    toHouseholdAccountSearchState: undefined
  }))

  .handleAction(getDefaultGiftCodeActions.request, (state) => ({
    ...state,
    giftCodeState: {
      ...state.giftCodeState,
      isLoading: true,
      error: undefined
    }
  }))
  .handleAction(getDefaultGiftCodeActions.success, (state, action) => ({
    ...state,
    giftCodeState: {
      isLoading: false,
      giftCode: action.payload.giftCode,
      error: undefined
    }
  }))
  .handleAction(getDefaultGiftCodeActions.failure, (state, action) => ({
    ...state,
    giftCodeState: {
      isLoading: false,
      giftCode: undefined,
      error: action.payload
    }
  }))
  .handleAction(getDefaultGiftCodeActions.clear, (state) => ({
    ...state,
    giftCodeState: undefined
  }))
  .handleAction(fetchPhubCodes.request, (state) => ({
    ...state,
    pHubCodeState: {
      isLoading: true,
      pHubCodes: undefined,
      error: undefined
    }
  }))
  .handleAction(fetchPhubCodes.success, (state, action) => ({
    ...state,
    pHubCodeState: {
      isLoading: false,
      pHubCodes: action.payload,
      error: undefined
    }
  }))
  .handleAction(fetchPhubCodes.failure, (state, action) => ({
    ...state,
    pHubCodeState: {
      isLoading: false,
      pHubCodes: undefined,
      error: action.payload
    }
  }))
