import { keyBy } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { IDiscountShares } from '../../../../../api/datahub'
import { IDataListColumnDefinition } from '../../../../../features/DataList/contracts/IDataListColumnDefinition'
import { convertColumnTypeToFilterType } from '../../../../../features/DataList/service'
import { createDataListStore } from '../../../../../features/DataList/store'
import { IListsFilter } from '../../../../../features/Lists/core/contracts/IListsFilter'
import { parseDateISOStringInLocalTimezone } from '../../../../../shared'
import { AppState } from '../../../../../store'
import {
  getDiscountSharingFetchLoading,
  getDiscountSharingFetchResult
} from './discountSharing'
export {}

export type DiscountSharingListColumnName =
  | 'Row Labels'
  | 'Team'
  | 'Client Name'
  | 'Account Number'
  | 'FBSI Short Name'
  | 'Trade Date'
  | 'Action'
  | 'Symbol'
  | 'CUSIP'
  | 'Description'
  | 'Security Type'
  | 'Fees / Commissions'
  | 'Min'
  | 'Allocated Amount'
  | 'Product Name'
  | 'Billing Group Name'
  | 'HH AUM'
  | 'Rockefeller Fee %'
  | 'Support Indicator'
  | 'Floor Indicator'
  | 'Discount'
  | 'Asset Class'
  | 'Discount Sharing'

interface IDiscountSharingListColumnDefinition
  extends IDataListColumnDefinition<IDiscountShares> {
  name: DiscountSharingListColumnName
}

const defaultColumn: Partial<IDiscountSharingListColumnDefinition> = {
  filterable: true,
  sortable: true
}

const activityAndNotesListColumns: IDiscountSharingListColumnDefinition[] = [
  {
    ...defaultColumn,
    name: 'Row Labels',
    type: 'string',
    getValue: (discountShare) => discountShare.rcm_rowlabels,
    width: 45,
    facetable: true
  },
  {
    ...defaultColumn,
    name: 'Team',
    type: 'string',
    getValue: (discountShare) => discountShare.rcm_team,
    width: 150,
    facetable: true
  },
  {
    ...defaultColumn,
    name: 'Client Name',
    type: 'string',
    getValue: (discountShare) => discountShare.rcm_clientname,
    width: 150
  },
  {
    ...defaultColumn,
    name: 'Account Number',
    type: 'string',
    getValue: (discountShare) => discountShare.rcm_accountnumber,
    width: 85
  },
  {
    ...defaultColumn,
    name: 'FBSI Short Name',
    type: 'string',
    getValue: (discountShare) => discountShare.rcm_fbsishortname,
    width: 80
  },
  {
    ...defaultColumn,
    name: 'Trade Date',
    type: 'date',
    getValue: (discountShare) =>
      parseDateISOStringInLocalTimezone(discountShare.rcm_tradedate),
    width: 75
  },
  {
    ...defaultColumn,
    name: 'Action',
    type: 'string',
    getValue: (discountShare) => discountShare.rcm_action,
    width: 50,
    facetable: true
  },
  {
    ...defaultColumn,
    name: 'Symbol',
    type: 'string',
    getValue: (discountShare) => discountShare.rcm_symbol,
    width: 50
  },
  {
    ...defaultColumn,
    name: 'CUSIP',
    type: 'string',
    getValue: (discountShare) => discountShare.rcm_cusip,
    width: 80
  },
  {
    ...defaultColumn,
    name: 'Description',
    type: 'string',
    getValue: (discountShare) => discountShare.rcm_description,
    width: 200
  },
  {
    ...defaultColumn,
    name: 'Security Type',
    type: 'string',
    getValue: (discountShare) => discountShare.rcm_securitytype,
    width: 60,
    facetable: true
  },
  {
    ...defaultColumn,
    name: 'Fees / Commissions',
    type: 'number',
    getValue: (discountShare) => discountShare.rcm_commissionconcession,
    width: 65
  },
  {
    ...defaultColumn,
    name: 'Min',
    type: 'number',
    getValue: (discountShare) => discountShare.rcm_min,
    width: 50
  },
  {
    ...defaultColumn,
    name: 'Allocated Amount',
    type: 'number',
    getValue: (discountShare) => discountShare.rcm_allocatedamount,
    width: 65
  },
  {
    ...defaultColumn,
    name: 'Product Name',
    type: 'string',
    getValue: (discountShare) => discountShare.rcm_productname,
    width: 125
  },
  {
    ...defaultColumn,
    name: 'Billing Group Name',
    type: 'string',
    getValue: (discountShare) => discountShare.rcm_billinggroupname,
    width: 125
  },
  {
    ...defaultColumn,
    name: 'HH AUM',
    type: 'number',
    getValue: (discountShare) => discountShare.rcm_hhaum,
    width: 85
  },
  {
    ...defaultColumn,
    name: 'Rockefeller Fee %',
    type: 'number',
    getValue: (discountShare) => discountShare.rcm_rockefellerfee,
    width: 80
  },
  {
    ...defaultColumn,
    name: 'Support Indicator',
    type: 'number',
    getValue: (discountShare) => discountShare.rcm_supportindicator,
    width: 65
  },
  {
    ...defaultColumn,
    name: 'Floor Indicator',
    type: 'number',
    getValue: (discountShare) => discountShare.rcm_floorindicator,
    width: 65
  },
  {
    ...defaultColumn,
    name: 'Discount',
    type: 'number',
    getValue: (discountShare) => discountShare.rcm_discount,
    width: 65
  },
  {
    ...defaultColumn,
    name: 'Asset Class',
    type: 'string',
    getValue: (discountShare) => discountShare.rcm_assetclass,
    width: 125
  },
  {
    ...defaultColumn,
    name: 'Discount Sharing',
    type: 'number',
    getValue: (discountShare) => discountShare.rcm_discountsharing,
    width: 85
  }
]

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.bda.discountSharingList

export const getDiscountSharingListItems = createSelector(
  [getDiscountSharingFetchResult, getDiscountSharingFetchLoading],
  (discountSharing, loading) => {
    if (!discountSharing || loading) {
      return []
    }
    return discountSharing
  }
)

const filters = keyBy(
  activityAndNotesListColumns
    .filter((x) => x.filterable)
    .map(
      (column): IListsFilter => ({
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        hasValue: false
      })
    ),
  ({ id }) => id
)

const {
  actions: discountSharingListActions,
  reducer: discountSharingListReducer,
  selectors: discountSharingListSelectors,
  sagas: discountSharingListSagas
} = createDataListStore({
  initialState: {
    columns: activityAndNotesListColumns,
    filters
  },
  itemsSelector: getDiscountSharingListItems,
  prefix: '@modules/@advisory/@modules/@bda/@discountSharingList',
  rootSelector
})

export {
  discountSharingListActions,
  discountSharingListSelectors,
  discountSharingListReducer,
  discountSharingListSagas
}

export const useDiscountSharingList = () => {
  const dispatch = useDispatch()
  const columns = useSelector(discountSharingListSelectors.getColumns)
  const setVisibleColumns = useCallback(
    (visibleColumns: DiscountSharingListColumnName[]) => {
      const updatedColumns = columns?.map((column) => ({
        ...column,
        hidden: !visibleColumns.includes(
          column.name as DiscountSharingListColumnName
        )
      }))
      dispatch(discountSharingListActions.updateColumns(updatedColumns || []))
    },
    [columns, dispatch]
  )
  return { setVisibleColumns }
}
