import { IColumnDefinition } from '../../../../../../../../features/Lists/core/contracts/IColumnDefinition'

enum ColumnIdsEnum {
  id = 'id',
  eventType = 'eventType',
  tradeOrRevenueDate = 'tradeOrRevenueDate',
  accountNumber = 'accountNumber',
  productType = 'productType',
  productCategory = 'productCategory',
  product = 'product',
  repCompany = 'repCompany',
  description = 'description',
  cusip = 'cusip',
  symbol = 'symbol',
  tranQuantity = 'tranQuantity',
  price = 'price',
  faceAmount = 'faceAmount',
  grossCommission = 'grossCommission',
  feeAmount = 'feeAmount',
  feeAndCommission = 'feeAndCommission',
  managedMoney = 'managedMoney',
  tradeIsOnHold = 'tradeIsOnHold',
  allocatedAmount = 'allocatedAmount',
  grossPayout = 'grossPayout',
  payrollPeriod = 'payrollPeriod',
  periodYear = 'periodYear',
  periodMonth = 'periodMonth',
  periodTimestamp = 'periodTimestamp',
  revenueCategoryLvl1 = 'revenueCategoryLvl1',
  revenueCategoryLvl2 = 'revenueCategoryLvl2',

  accountRegisteredRep = 'accountRegisteredRep',
  accountRegistrationType = 'accountRegistrationType',
  accountShortName = 'accountShortName',
  accountType = 'accountType',

  householdId = 'householdId',
  householdName = 'householdName',

  partyName = 'partyName',
  partyFirstName = 'partyFirstName',
  partyLastName = 'partyLastName',
  partyType = 'partyType',

  repId = 'repId',
  repName = 'repName',
  team = 'team',
  office = 'office',
  division = 'division'
}

export const CreditEventColumnIds = { ...ColumnIdsEnum }
export type CreditEventColumnIds = typeof CreditEventColumnIds

export const CreditEventColumnDefinitions: Record<string, IColumnDefinition> = {
  [CreditEventColumnIds.id]: {
    id: CreditEventColumnIds.id,
    name: 'Transaction Id',
    type: 'string',
    width: 150,
    dataPath: 'event/id',
    sortable: true,
    filterable: true
  },
  [CreditEventColumnIds.eventType]: {
    id: CreditEventColumnIds.eventType,
    name: 'Event Type',
    type: 'string',
    width: 150,
    dataPath: 'event/eventType',
    sortable: true,
    filterable: true,
    facetable: true
  },
  [CreditEventColumnIds.tradeOrRevenueDate]: {
    id: CreditEventColumnIds.tradeOrRevenueDate,
    name: 'Trade / Revenue Date',
    type: 'date-only',
    width: 100,
    dataPath: 'event/tradeOrRevenueDate',
    sortable: true,
    filterable: true
  },
  [CreditEventColumnIds.accountNumber]: {
    id: CreditEventColumnIds.accountNumber,
    name: 'Account Number',
    select: [
      'event/accountNumber',
      'account/registrationType',
      'account/shortName'
    ],
    type: 'string',
    width: 150,
    dataPath: 'event/accountNumber',
    sortable: true,
    filterable: true,
    searchable: true
  },
  [CreditEventColumnIds.productType]: {
    id: CreditEventColumnIds.productType,
    name: 'Product Type',
    type: 'string',
    width: 150,
    dataPath: 'event/productType',
    sortable: true,
    filterable: true,
    facetable: true
  },
  [CreditEventColumnIds.productCategory]: {
    id: CreditEventColumnIds.productCategory,
    name: 'Product Category',
    type: 'string',
    width: 150,
    dataPath: 'event/productCategory',
    sortable: true,
    filterable: true,
    facetable: true
  },
  [CreditEventColumnIds.product]: {
    id: CreditEventColumnIds.product,
    name: 'Product',
    type: 'string',
    width: 150,
    dataPath: 'event/product',
    sortable: true,
    filterable: true,
    searchable: true
  },
  [CreditEventColumnIds.repCompany]: {
    id: CreditEventColumnIds.repCompany,
    name: 'Rep. Company',
    type: 'string',
    width: 150,
    dataPath: 'event/repCompany',
    sortable: true,
    filterable: true,
    searchable: true
  },
  [CreditEventColumnIds.description]: {
    id: CreditEventColumnIds.description,
    name: 'Description',
    type: 'string',
    width: 180,
    dataPath: 'event/description',
    sortable: true,
    filterable: true,
    searchable: true
  },
  [CreditEventColumnIds.cusip]: {
    id: CreditEventColumnIds.cusip,
    name: 'CUSIP',
    type: 'string',
    width: 110,
    dataPath: 'event/cusip',
    sortable: true,
    filterable: true,
    searchable: true
  },
  [CreditEventColumnIds.symbol]: {
    id: CreditEventColumnIds.symbol,
    name: 'Symbol',
    type: 'string',
    width: 110,
    dataPath: 'event/symbol',
    sortable: true,
    filterable: true,
    searchable: true
  },
  [CreditEventColumnIds.tranQuantity]: {
    id: CreditEventColumnIds.tranQuantity,
    name: 'Transaction Quantity',
    type: 'number',
    width: 100,
    dataPath: 'event/tranQuantity',
    sortable: true,
    filterable: true
  },
  [CreditEventColumnIds.price]: {
    id: CreditEventColumnIds.price,
    name: 'Price',
    type: 'number',
    width: 100,
    dataPath: 'event/price',
    sortable: true,
    filterable: true
  },
  [CreditEventColumnIds.faceAmount]: {
    id: CreditEventColumnIds.faceAmount,
    name: 'Face Amount',
    type: 'number',
    width: 100,
    dataPath: 'event/faceAmount',
    sortable: true,
    filterable: true
  },
  [CreditEventColumnIds.grossCommission]: {
    id: CreditEventColumnIds.grossCommission,
    name: 'Gross Commission',
    type: 'number',
    width: 100,
    dataPath: 'event/grossCommission',
    sortable: true,
    filterable: true
  },
  [CreditEventColumnIds.feeAmount]: {
    id: CreditEventColumnIds.feeAmount,
    name: 'Fee Amount',
    type: 'number',
    width: 110,
    dataPath: 'event/feeAmount',
    sortable: true,
    filterable: true
  },
  [CreditEventColumnIds.feeAndCommission]: {
    id: CreditEventColumnIds.feeAndCommission,
    name: 'Fee / Commission',
    type: 'number',
    width: 100,
    dataPath: 'event/feeAndCommission',
    sortable: true,
    filterable: true
  },
  [CreditEventColumnIds.managedMoney]: {
    id: CreditEventColumnIds.managedMoney,
    name: 'Managed Money',
    type: 'string',
    width: 80,
    dataPath: 'event/managedMoney',
    sortable: true,
    filterable: true,
    facetable: true
  },
  [CreditEventColumnIds.tradeIsOnHold]: {
    id: CreditEventColumnIds.tradeIsOnHold,
    name: 'Trade Is On Hold',
    type: 'string',
    width: 80,
    dataPath: 'event/tradeIsOnHold',
    sortable: true,
    filterable: true,
    facetable: true
  },
  [CreditEventColumnIds.allocatedAmount]: {
    id: CreditEventColumnIds.allocatedAmount,
    select: ['event/allocatedAmount', 'recipients', 'rep/repId'],
    name: 'Allocated Amount',
    type: 'number',
    width: 100,
    dataPath: 'event/allocatedAmount',
    sortable: false,
    filterable: false
  },
  [CreditEventColumnIds.grossPayout]: {
    id: CreditEventColumnIds.grossPayout,
    select: ['event/grossPayout', 'recipients', 'rep/repId'],
    name: 'Payout',
    type: 'number',
    width: 100,
    dataPath: 'event/grossPayout',
    sortable: false,
    filterable: false
  },
  [CreditEventColumnIds.payrollPeriod]: {
    id: CreditEventColumnIds.payrollPeriod,
    name: 'Payroll Period',
    type: 'string',
    width: 90,
    dataPath: 'event/payrollPeriod',
    sortable: true,
    filterable: true,
    facetable: true
  },
  [CreditEventColumnIds.periodYear]: {
    id: CreditEventColumnIds.periodYear,
    name: 'Payroll Period Year',
    type: 'number',
    width: 80,
    dataPath: 'event/periodYear',
    sortable: true,
    filterable: true
  },
  [CreditEventColumnIds.periodMonth]: {
    id: CreditEventColumnIds.periodMonth,
    name: 'Payroll Period Month',
    type: 'number',
    width: 80,
    dataPath: 'event/periodMonth',
    sortable: true,
    filterable: true
  },
  [CreditEventColumnIds.periodTimestamp]: {
    id: CreditEventColumnIds.periodTimestamp,
    name: 'Payroll Period Date',
    type: 'date-only',
    width: 80,
    dataPath: 'event/periodTimestamp',
    sortable: true,
    filterable: true
  },
  [CreditEventColumnIds.revenueCategoryLvl1]: {
    id: CreditEventColumnIds.revenueCategoryLvl1,
    name: 'Revenue Category',
    type: 'string',
    width: 150,
    dataPath: 'event/revenueCategoryLvl1',
    sortable: true,
    filterable: true,
    facetable: true
  },
  [CreditEventColumnIds.revenueCategoryLvl2]: {
    id: CreditEventColumnIds.revenueCategoryLvl2,
    name: 'Revenue Category Detail',
    type: 'string',
    width: 150,
    dataPath: 'event/revenueCategoryLvl2',
    sortable: true,
    filterable: true,
    facetable: true
  },
  [CreditEventColumnIds.accountRegistrationType]: {
    id: CreditEventColumnIds.accountRegistrationType,
    name: 'Account Registration Type',
    type: 'string',
    width: 50,
    dataPath: 'account/registrationType',
    sortable: true,
    filterable: true,
    facetable: true
  },
  [CreditEventColumnIds.accountShortName]: {
    id: CreditEventColumnIds.accountShortName,
    name: 'Account Short Name',
    type: 'string',
    width: 90,
    dataPath: 'account/shortName',
    sortable: true,
    filterable: true,
    searchable: true
  },
  [CreditEventColumnIds.accountType]: {
    id: CreditEventColumnIds.accountType,
    name: 'Account AUS Class',
    type: 'string',
    width: 80,
    dataPath: 'account/accountType',
    sortable: true,
    filterable: true,
    facetable: true
  },
  [CreditEventColumnIds.householdId]: {
    id: CreditEventColumnIds.householdId,
    name: 'Household Id',
    type: 'string',
    width: 120,
    dataPath: 'household/householdId',
    sortable: true,
    filterable: true,
    searchable: true
  },
  [CreditEventColumnIds.householdName]: {
    id: CreditEventColumnIds.householdName,
    name: 'Household Name',
    type: 'string',
    width: 180,
    dataPath: 'household/householdName',
    sortable: true,
    filterable: true,
    searchable: true
  },
  [CreditEventColumnIds.partyName]: {
    id: CreditEventColumnIds.partyName,
    name: 'Contact / Legal Entity Name',
    select: ['event/contact', 'party/partyType'],
    type: 'string',
    width: 200,
    dataPath: 'event/contact',
    sortable: true,
    filterable: true,
    searchable: true
  },
  [CreditEventColumnIds.partyType]: {
    id: CreditEventColumnIds.partyType,
    name: 'Legal Entity Type',
    type: 'string',
    width: 120,
    dataPath: 'party/partyType',
    sortable: true,
    filterable: true,
    facetable: true
  },
  [CreditEventColumnIds.repId]: {
    id: CreditEventColumnIds.repId,
    name: 'Trade Rep Id',
    select: ['rep/repId', 'rep/name'],
    type: 'string',
    width: 90,
    dataPath: 'rep/repId',
    sortable: true,
    filterable: true,
    facetable: true
  },
  [CreditEventColumnIds.repName]: {
    id: CreditEventColumnIds.repName,
    name: 'Trade Rep',
    type: 'string',
    width: 90,
    dataPath: 'rep/name',
    sortable: true,
    filterable: true,
    facetable: true
  },
  [CreditEventColumnIds.team]: {
    id: CreditEventColumnIds.team,
    name: 'Team',
    type: 'string',
    width: 90,
    dataPath: 'rep/team',
    sortable: true,
    filterable: true,
    facetable: true
  },
  [CreditEventColumnIds.office]: {
    id: CreditEventColumnIds.office,
    name: 'Office Location',
    type: 'string',
    width: 90,
    dataPath: 'rep/office',
    sortable: true,
    filterable: true,
    facetable: true
  },
  [CreditEventColumnIds.division]: {
    id: CreditEventColumnIds.division,
    name: 'Division',
    type: 'string',
    width: 90,
    dataPath: 'rep/division',
    sortable: true,
    filterable: true,
    facetable: true
  }
}
