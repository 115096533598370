import { format } from 'date-fns'
import { saveBlobAsFile } from '../../../../shared/downloads'
import { IParamValues, IReportInfo, IReportParams } from './store/types'
const base64toBlob = (base64Data: string, contentType: string) => {
  contentType = contentType || ''
  const sliceSize = 1024
  const byteCharacters = atob(base64Data)
  const bytesLength = byteCharacters.length
  const slicesCount = Math.ceil(bytesLength / sliceSize)
  const byteArrays = new Array(slicesCount)
  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize
    const end = Math.min(begin + sliceSize, bytesLength)

    const bytes = new Array(end - begin)
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0)
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes)
  }
  return new Blob(byteArrays, { type: contentType })
}

const downloadFile = (
  fileContent: string,
  reportName: string,
  reportFormat: string
) => {
  const blob = new Blob(
    [
      base64toBlob(
        fileContent,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    ],
    {}
  )
  saveBlobAsFile(blob, reportName + '.' + reportFormat.toLowerCase())
}
export { base64toBlob, downloadFile }

export const setReportsDefaultParameters = (
  selectedReport?: IReportInfo
): IReportParams | undefined => {
  selectedReport?.reportParams?.paginatedReportConfiguration?.parameterValues?.map(
    (params: IParamValues) => {
      if (params?.metadata?.type === 'date') {
        params.value = format(new Date(), 'yyyy-MM-dd')
      } else if (params?.metadata?.type === 'option') {
        params.value =
          params?.metadata?.options?.[0]?.key.toString() || params.value
      } else if (params?.metadata?.type === 'multioption') {
        params.value = ''
      }
    }
  )
  return selectedReport?.reportParams
}
