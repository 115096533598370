import axios, { CancelTokenSource } from 'axios'
import { cloneDeep, flow, keyBy } from 'lodash'
import { combineReducers, Reducer } from 'redux'
import { call, cancelled, put, select, takeEvery } from 'typed-redux-saga'
import {
  getHurdleProgressMeasurements,
  IHurdleProgressMeasurement
} from '../../../../../../../../../api/datahub'
import {
  OdataFilterOperatorEnum,
  OdataPropertyFilterGroup
} from '../../../../../../../../../api/odata'
import { IOdataRequest } from '../../../../../../../../../api/odata.types'
import { updateStatusActions } from '../../../../../../../../../features/Hurdles'
import {
  IListsFacetFilter,
  IListsFilter
} from '../../../../../../../../../features/Lists/core/contracts/IListsFilter'
import { IOdataListChunkPayload } from '../../../../../../../../../features/OdataList/common/IOdataListDataActions'
import { IOdataListDataState } from '../../../../../../../../../features/OdataList/common/IOdataListDataState'
import { IOdataListUiState } from '../../../../../../../../../features/OdataList/common/IOdataListUiState'
import { convertColumnTypeToFilterType } from '../../../../../../../../../features/OdataList/common/service'
import {
  IOdataListColumnDefinition,
  IWithGetValue
} from '../../../../../../../../../features/OdataList/common/types'
import { createOdataListDataStore } from '../../../../../../../../../features/OdataList/store/odataListDataStore'
import { createOdataListUiStore } from '../../../../../../../../../features/OdataList/store/odataListUiStore'
import { parseDateISOStringInLocalTimezone } from '../../../../../../../../../shared'
import { IApiOptions } from '../../../../../../../../../shared/contracts/IApiOptions'
import { AppState } from '../../../../../../../../../store'
import { getRockefellerApiOptions } from '../../../../../../../../../store/shared/sagas'

export interface IHurdleReport {
  hub?: string
  hurdleName?: string
  teamName?: string
  advertisedT12?: number
  hurdleDate?: Date
  hurdlePercent?: number
  hurdleAmount?: number
  currentMonthAnnualized?: number
  annualizedPercentToTarget?: number
  T12?: number
  measurementPeriodToDate?: number
  monthsInPeriod?: number
  measurementStartDate?: Date
  active?: boolean
  measurementFrequency?: string
  months?: number
  total?: number
  type?: string
}

export type HurdleProgressManagementListColumnName =
  | 'Hurdle Name'
  | 'Status'
  | 'Target Date'
  | 'Target Metric Value'
  | 'Current Month Annualized'
  | 'Trailing 12'
  | 'Measurement Period to Date'
  | 'Months In'
  | 'Measured Months'
  | 'Measurement Period Date'
  | 'Division'

export interface IHurdleProgressManagementListColumnDefinition
  extends IOdataListColumnDefinition,
    IWithGetValue<IHurdleProgressMeasurement> {
  name: HurdleProgressManagementListColumnName
}

const defaultColumn: Partial<IHurdleProgressManagementListColumnDefinition> = {
  filterable: true,
  sortable: true
}

export const hurdleProgressManagementListColumns: IHurdleProgressManagementListColumnDefinition[] =
  [
    {
      ...defaultColumn,
      name: 'Division',
      dataPath: 'divisionName',
      type: 'string',
      getValue: (hurdle) => hurdle.divisionName,
      width: 150
    },
    {
      ...defaultColumn,
      name: 'Status',
      select: [
        'hurdleRevenueMeasurementsId',
        'status',
        'isEndOfMeasurementPeriod'
      ],
      dataPath: 'status',
      type: 'string',
      facetable: true,
      getValue: (hurdle) => hurdle.status,
      width: 45
    },

    {
      ...defaultColumn,
      name: 'Hurdle Name',
      searchFields: ['name', 'entityName'],
      select: ['name', 'entityName'],
      type: 'string',
      dataPath: 'name',
      getValue: (hurdle) => hurdle.name,
      width: 250
    },
    {
      ...defaultColumn,
      name: 'Measurement Period Date',
      type: 'date-only',
      select: ['currentPeriodDate', 'glMonthClose'],
      dataPath: 'currentPeriodDate',
      getValue: (hurdle) =>
        hurdle.currentPeriodDate &&
        parseDateISOStringInLocalTimezone(hurdle.currentPeriodDate),
      width: 150
    },
    {
      ...defaultColumn,
      name: 'Target Date',
      type: 'date-only',
      select: ['hurdleDate', 'intervalofMeasurement'],
      dataPath: 'hurdleDate',
      getValue: (hurdle) =>
        hurdle.hurdleDate &&
        parseDateISOStringInLocalTimezone(hurdle.hurdleDate),
      width: 100
    },
    {
      ...defaultColumn,
      name: 'Target Metric Value',
      type: 'number',
      select: ['targetMetricValue', 'perNumber', 'advertisedT12Revenue'],
      dataPath: 'targetMetricValue',
      getValue: (hurdle) => hurdle.targetMetricValue || 0,
      width: 125
    },
    {
      ...defaultColumn,
      name: 'Months In',
      type: 'number',
      dataPath: 'numberofMonthsinMeasurementPeriod',
      getValue: (hurdle) => hurdle.numberofMonthsinMeasurementPeriod,
      width: 90
    },
    {
      ...defaultColumn,
      name: 'Measured Months',
      type: 'number',
      select: ['measuredMonths', 'metricType'],
      dataPath: 'measuredMonths',
      getValue: (hurdle) => hurdle.measuredMonths,
      width: 100
    },
    {
      ...defaultColumn,
      name: 'Current Month Annualized',
      type: 'number',
      dataPath: 'currentMonthAnnualized',
      getValue: (hurdle) => hurdle.currentMonthAnnualized,
      width: 120
    },
    {
      ...defaultColumn,
      name: 'Trailing 12',
      type: 'number',
      dataPath: 'currentT12',
      getValue: (hurdle) => hurdle.currentT12,
      width: 120
    },
    {
      ...defaultColumn,
      name: 'Measurement Period to Date',
      type: 'number',
      select: ['metricMeasurementValue', 'metricProgress'],
      dataPath: 'metricProgress',
      getValue: (hurdle) => hurdle.metricProgress,
      width: 120
    }
  ]

const getDatahubApiOptions = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)
  return [apiOptions, source] as [IApiOptions, CancelTokenSource]
}
export const fetchHurdleProgressMeasurements = function* (
  request: IOdataRequest
) {
  const [apiOptions, cancelTokenSource] = yield* call(getDatahubApiOptions)
  try {
    const result = yield* call(
      getHurdleProgressMeasurements,
      apiOptions,
      request
    )
    return result
  } finally {
    if (yield* cancelled()) {
      cancelTokenSource.cancel()
    }
  }
}

const rootListSelector = (state: AppState) =>
  state.modules.advisory.modules.teams.modules.hurdles.features
    .hurdleProgressManagementList.list

const dataStore = createOdataListDataStore(
  '@modules/@teams/@hurdleProgressManagementList',
  fetchHurdleProgressMeasurements,
  flow(rootListSelector, ({ data }) => data)
)
export const {
  actions: hurdleProgressManagementListDataActions,
  selectors: hurdleProgressManagementListDataSelectors
} = dataStore
const uiFilters = keyBy(
  hurdleProgressManagementListColumns
    .filter((x) => x.filterable)
    .map((column): IListsFilter => {
      const base = {
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        dataPath: column.dataPath,
        hasValue: false
      }

      return base
    }),
  ({ id }) => id
)
uiFilters['Team / Individual'] = {
  id: 'Team / Individual',
  name: 'Team / Individual',
  type: 'search',
  dataPath: 'entityName',
  hasValue: false
}
uiFilters['Measurement Period Status'] = {
  id: 'Measurement Period Status',
  name: 'Measurement Period Status',
  type: 'facet',
  dataPath: 'glMonthClose',
  hasValue: false,
  facets: [
    { value: 'Open' },
    { value: 'Closed' },
    { value: 'Most Recent Closed' }
  ]
} as IListsFacetFilter
uiFilters['Is End Of Measurement Period'] = {
  id: 'Is End Of Measurement Period',
  name: 'Is End Of Measurement Period',
  type: 'facet',
  dataPath: 'isEndOfMeasurementPeriod',
  hasValue: false,
  facets: [{ value: 'Yes' }, { value: 'No' }]
} as IListsFacetFilter
uiFilters['Status'] = {
  id: 'Status',
  name: 'Status',
  type: 'facet',
  dataPath: 'status',
  hasValue: false,
  facets: [
    { value: 'Pending' },
    { value: 'Achieved' },
    { value: 'Missed' },
    { value: 'Not Applicable' },
    { value: null }
  ]
} as IListsFacetFilter
uiFilters['Advertised Production'] = {
  id: 'Advertised Production',
  name: 'Advertised Production',
  type: 'number',
  dataPath: 'advertisedT12Revenue',
  hasValue: false
}
uiFilters['Is Hurdle Achieved'] = {
  id: 'Is Hurdle Achieved',
  name: 'Is Hurdle Achieved',
  type: 'facet',
  dataPath: 'isHurdleAchieved',
  hasValue: false,
  facets: [{ value: 'Yes' }, { value: 'No' }]
} as IListsFacetFilter
uiFilters['Is Measurement Tracked'] = {
  id: 'Is Measurement Tracked',
  name: 'Is Measurement Tracked',
  type: 'facet',
  dataPath: 'isMeasurementTracked',
  hasValue: false,
  facets: [{ value: 'Yes' }, { value: 'No' }]
} as IListsFacetFilter
uiFilters['Metric Type'] = {
  id: 'Metric Type',
  name: 'Metric Type',
  type: 'facet',
  dataPath: 'metricType',
  hasValue: false,
  facets: [
    { value: 'T-12 From Hurdle' },
    { value: '3 Months Annualized' },
    { value: 'AUS' }
  ]
} as IListsFacetFilter

export const getUpdatedProgressManagementFacetFilter = (
  id: string,
  values: string[]
) => {
  return {
    ...uiFilters[id],
    hasValue: true,
    values: values.map((val) => (val === '' ? null : val))
  } as IListsFacetFilter
}

const convertMeasurementFilterToOdataFilter = (
  filter: IListsFilter
): OdataPropertyFilterGroup | undefined => {
  const { dataPath, values } = filter as IListsFacetFilter
  const hasValue = values && values.length > 0

  if (!hasValue || !dataPath) {
    return
  }

  return {
    or: values?.map((value) => ({
      operator: OdataFilterOperatorEnum.eq,
      value: value,
      path: dataPath,
      type: 'string'
    }))
  }
}
const convertMetricTypeFilterToOdataFilter = (
  filter: IListsFilter
): OdataPropertyFilterGroup | undefined => {
  const { dataPath, values } = filter as IListsFacetFilter
  const hasValue = values && values.length > 0

  if (!hasValue || !dataPath) {
    return
  }

  return {
    or: values?.map((value) => ({
      operator: OdataFilterOperatorEnum.eq,
      value: value,
      path: dataPath,
      type: 'string'
    }))
  }
}
const convertYesNoFilterToOdataFilter = (
  filter: IListsFilter
): OdataPropertyFilterGroup | undefined => {
  const { dataPath, values } = filter as IListsFacetFilter
  const hasValue = values && values.length > 0

  if (!hasValue || !dataPath) {
    return
  }

  return {
    or: values?.map((value) => ({
      operator: OdataFilterOperatorEnum.eq,
      value: value === 'Yes' ? true : false,
      path: dataPath,
      type: 'boolean'
    }))
  }
}

const uiStore = createOdataListUiStore({
  prefix: '@modules/@teams/@hurdleProgressManagementList',
  initialState: {
    columns: hurdleProgressManagementListColumns,
    filters: uiFilters,
    sortBy: { direction: 'desc', name: 'Months In' }
  },
  rootSelector: flow(rootListSelector, ({ ui }) => ui),
  dataStore,
  onConvertToOdataFilter: (filter: IListsFilter) => {
    if (filter.name === 'Measurement Period Status') {
      return convertMeasurementFilterToOdataFilter(filter)
    }
    if (filter.name === 'Metric Type') {
      return convertMetricTypeFilterToOdataFilter(filter)
    }
    if (
      filter.name === 'Is End Of Measurement Period' ||
      filter.name === 'Is Measurement Tracked' ||
      filter.name === 'Is Hurdle Achieved'
    ) {
      return convertYesNoFilterToOdataFilter(filter)
    }
  }
})

export const {
  selectors: hurdleProgressManagementListUiSelectors,
  actions: hurdleProgressManagementListUiActions
} = uiStore
export const hurdleProgressManagementListReducer: Reducer<{
  data: IOdataListDataState<IHurdleProgressMeasurement>
  ui: IOdataListUiState
}> = combineReducers({
  data: dataStore.reducer,
  ui: uiStore.reducer
})

const hurdleProgressManagementListUpdateSagas = [
  () =>
    takeEvery(
      updateStatusActions.success,
      function* (action: ReturnType<typeof updateStatusActions.success>) {
        const chunks = yield* select(
          hurdleProgressManagementListDataSelectors.getChunks
        )
        if (!chunks) {
          return
        }
        let updateChunkPayload:
          | IOdataListChunkPayload<IHurdleProgressMeasurement>
          | undefined
        chunks?.some((x, i) => {
          if (!x.value) {
            return
          }

          const itemIndex = x.value.findIndex(
            (x) => x.hurdleRevenueMeasurementsId === action.payload.id
          )

          if (itemIndex < 0) {
            return
          }

          const itemsCopy = [...x.value]
          const newItem = cloneDeep(itemsCopy[itemIndex])
          newItem.status = action.payload.status
          itemsCopy.splice(itemIndex, 1, newItem)
          updateChunkPayload = {
            index: i,
            result: { ...x, value: itemsCopy }
          }
        })

        if (!updateChunkPayload) {
          return
        }

        yield put(
          hurdleProgressManagementListDataActions.updateChunk(
            updateChunkPayload
          )
        )
      }
    )
]

export const hurdleProgressManagementListSagas = [
  ...dataStore.sagas,
  ...uiStore.sagas,
  ...hurdleProgressManagementListUpdateSagas
]
