import { Link, MessageBar, MessageBarType, Stack, Text } from '@fluentui/react'
import React from 'react'
import { FormattedDate } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingComponent } from '../../shared/components/Loading'
import {
  contactNavigationActions,
  getContactPanelContacts,
  getIsContactPanelLoading
} from './store'

export const ContactsContainer: React.FC = () => {
  const contacts = useSelector(getContactPanelContacts)
  const isLoading = useSelector(getIsContactPanelLoading)
  const dispatch = useDispatch()
  const onContactSelected = (contactid: string) =>
    dispatch(contactNavigationActions.openContactInDynamics(contactid))

  if (isLoading) {
    return <LoadingComponent />
  }

  if (!contacts || contacts?.length === 0) {
    return (
      <MessageBar
        messageBarType={MessageBarType.info}
        isMultiline={true}
        dismissButtonAriaLabel="Close"
      >
        No Contacts were found.
      </MessageBar>
    )
  }

  return (
    <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
      <li style={{ textAlign: 'right' }}>
        <Text>
          <b>Last Modified</b>
        </Text>
      </li>
      {contacts.map(
        (
          {
            contactid,
            emailaddress1,
            firstname,
            lastname,
            fullname,
            modifiedon
          },
          i
        ) => {
          const onLinkClicked = (e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault()

            contactid && onContactSelected(contactid)
          }

          const name = fullname ? fullname : `${firstname} ${lastname}`

          return (
            <li key={i} style={{ padding: '5px' }}>
              <Stack>
                <Stack horizontal={true} horizontalAlign="space-between">
                  <Text nowrap={true} styles={{ root: { minWidth: 0 } }}>
                    <Link
                      href={`/contacts/${contactid}`}
                      onClick={onLinkClicked}
                    >
                      {name}
                    </Link>
                  </Text>

                  <Text>
                    {modifiedon && (
                      <FormattedDate
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        value={new Date(modifiedon)}
                      />
                    )}
                  </Text>
                </Stack>
                {emailaddress1 && (
                  <Text
                    nowrap={true}
                    variant="small"
                    styles={{ root: { maxWidth: '100%' } }}
                  >
                    {emailaddress1}
                  </Text>
                )}
              </Stack>
            </li>
          )
        }
      )}
    </ul>
  )
}
