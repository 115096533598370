import { IColumnDefinition } from 'features/Lists/core/contracts/IColumnDefinition'

enum ColumnIdsEnum {
  id = 'id',
  year = 'year',
  month = 'month',
  qtr = 'qtr',
  rep_code = 'rep_code',
  product_id = 'product_id',
  ticker = 'ticker',
  cusip = 'cusip',
  l1code = 'l1code',
  l1description = 'l1description',
  l2code = 'l2code',
  l2description = 'l2description',
  l3code = 'l3code',
  l3description = 'l3description',
  l4code = 'l4code',
  l4description = 'l4description',
  product_name = 'product_name',
  product_type = 'product_type',
  firm = 'firm',
  firm_code = 'firm_code',
  is_strategic_partner = 'is_strategic_partner',
  asset_value = 'asset_value',
  inFlowAmnt = 'inFlowAmnt',
  outFlowAmnt = 'outFlowAmnt',
  netFlowAmnt = 'netFlowAmnt',
  qtrOutFlowAmnt = 'qtrOutFlowAmnt',
  qtrInFlowAmnt = 'qtrInFlowAmnt',
  qtrNetFlowAmnt = 'qtrNetFlowAmnt'
}

export const ThirdPartyProductColumnIds = { ...ColumnIdsEnum }
export type ThirdPartyProductColumnIds = typeof ThirdPartyProductColumnIds

export const ThirdPartyProductColumnDefinitions: Record<
  string,
  IColumnDefinition
> = {
  [ThirdPartyProductColumnIds.year]: {
    id: ThirdPartyProductColumnIds.year,
    name: 'Year',
    type: 'string',
    width: 70,
    filterable: false,
    sortable: false,
    searchable: true,
    facetable: false,
    dataPath: 'year'
  },
  [ThirdPartyProductColumnIds.month]: {
    id: ThirdPartyProductColumnIds.month,
    name: 'Month',
    type: 'string',
    width: 70,
    filterable: false,
    sortable: false,
    searchable: true,
    facetable: false,
    dataPath: 'month'
  },
  [ThirdPartyProductColumnIds.qtr]: {
    id: ThirdPartyProductColumnIds.qtr,
    name: 'Quarter',
    type: 'string',
    width: 70,
    filterable: false,
    sortable: false,
    searchable: true,
    facetable: false,
    dataPath: 'qtr'
  },
  [ThirdPartyProductColumnIds.rep_code]: {
    id: ThirdPartyProductColumnIds.rep_code,
    name: 'Rep Code',
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'rep_code'
  },
  [ThirdPartyProductColumnIds.product_type]: {
    id: ThirdPartyProductColumnIds.product_type,
    name: 'Product Type',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'product_type'
  },
  [ThirdPartyProductColumnIds.product_id]: {
    id: ThirdPartyProductColumnIds.product_id,
    name: 'Product ID',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'product_id'
  },
  [ThirdPartyProductColumnIds.product_name]: {
    id: ThirdPartyProductColumnIds.product_name,
    name: 'Product Name',
    type: 'string',
    width: 130,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'product_name'
  },

  [ThirdPartyProductColumnIds.ticker]: {
    id: ThirdPartyProductColumnIds.ticker,
    name: 'Ticker',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'ticker'
  },
  [ThirdPartyProductColumnIds.firm_code]: {
    id: ThirdPartyProductColumnIds.firm_code,
    name: 'Firm Code',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'firm_code'
  },
  [ThirdPartyProductColumnIds.firm]: {
    id: ThirdPartyProductColumnIds.firm,
    name: 'Firm',
    type: 'string',
    width: 140,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'firm'
  },
  [ThirdPartyProductColumnIds.asset_value]: {
    id: ThirdPartyProductColumnIds.asset_value,
    name: 'Asset Value',
    type: 'string',
    width: 100,
    filterable: false,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'asset_value'
  },
  [ThirdPartyProductColumnIds.inFlowAmnt]: {
    id: ThirdPartyProductColumnIds.inFlowAmnt,
    name: 'InFlow Amount',
    type: 'string',
    width: 110,
    filterable: false,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'inFlowAmnt'
  },
  [ThirdPartyProductColumnIds.outFlowAmnt]: {
    id: ThirdPartyProductColumnIds.outFlowAmnt,
    name: 'OutFlow Amount',
    type: 'string',
    width: 110,
    filterable: false,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'outFlowAmnt'
  },
  [ThirdPartyProductColumnIds.netFlowAmnt]: {
    id: ThirdPartyProductColumnIds.netFlowAmnt,
    name: 'NetFlow Amount',
    type: 'string',
    width: 110,
    filterable: false,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'netFlowAmnt'
  },
  [ThirdPartyProductColumnIds.qtrInFlowAmnt]: {
    id: ThirdPartyProductColumnIds.qtrInFlowAmnt,
    name: 'Qtr InFlow Amount',
    type: 'string',
    width: 110,
    filterable: false,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'qtrInFlowAmnt'
  },
  [ThirdPartyProductColumnIds.qtrOutFlowAmnt]: {
    id: ThirdPartyProductColumnIds.qtrOutFlowAmnt,
    name: 'Qtr OutFlow Amount',
    type: 'string',
    width: 110,
    filterable: false,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'qtrOutFlowAmnt'
  },
  [ThirdPartyProductColumnIds.qtrNetFlowAmnt]: {
    id: ThirdPartyProductColumnIds.qtrNetFlowAmnt,
    name: 'Qtr NetFlow Amount',
    type: 'string',
    width: 110,
    filterable: false,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'qtrNetFlowAmnt'
  },
  [ThirdPartyProductColumnIds.l1description]: {
    id: ThirdPartyProductColumnIds.l1description,
    name: 'L1 Description',
    type: 'string',
    width: 110,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'l1description'
  },
  [ThirdPartyProductColumnIds.l2description]: {
    id: ThirdPartyProductColumnIds.l2description,
    name: 'L2 Description',
    type: 'string',
    width: 110,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'l2description'
  },
  [ThirdPartyProductColumnIds.l3description]: {
    id: ThirdPartyProductColumnIds.l3description,
    name: 'L3 Description',
    type: 'string',
    width: 110,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'l3description'
  },
  [ThirdPartyProductColumnIds.l4description]: {
    id: ThirdPartyProductColumnIds.l4description,
    name: 'L4 Description',
    type: 'string',
    width: 110,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'l4description'
  },
  [ThirdPartyProductColumnIds.is_strategic_partner]: {
    id: ThirdPartyProductColumnIds.is_strategic_partner,
    name: 'Is Strategic Partner',
    type: 'string',
    width: 110,
    filterable: false,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'is_strategic_partner'
  }
}
