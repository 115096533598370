import { createReducer } from 'typesafe-actions'
import {
  fetchGLMainAccountsActions,
  fetchPayeesActions,
  fetchPaymentTransactionsListActions,
  fetchSubAccountsActions,
  PaymentTransactionsListActionTypes
} from './actions'
import {
  IFetchPaymentTransactionsResponse,
  IMainAccount,
  IPayee,
  ISubAccount
} from './types'

export interface IPaymentTransactionsListState {
  listState?: {
    transactions?: IFetchPaymentTransactionsResponse[]
    isLoading: boolean
    error?: Error
  }
  mainAccountState?: {
    isLoading: boolean
    error?: Error
    accounts?: IMainAccount[]
  }
  subAccountState?: {
    isLoading: boolean
    error?: Error
    accounts?: ISubAccount[]
  }
  payeesState?: {
    isLoading: boolean
    error?: Error
    payees?: IPayee[]
  }
}

const initialState: IPaymentTransactionsListState = {
  listState: undefined
}

export const paymentTransactionsListReducer = createReducer<
  IPaymentTransactionsListState,
  PaymentTransactionsListActionTypes
>(initialState)
  .handleAction(fetchPaymentTransactionsListActions.request, (state) => ({
    ...state,
    listState: {
      isLoading: true,
      error: undefined,
      transactions: undefined
    }
  }))
  .handleAction(
    fetchPaymentTransactionsListActions.success,
    (state, action) => ({
      ...state,
      listState: {
        isLoading: false,
        error: undefined,
        transactions: action.payload
      }
    })
  )
  .handleAction(
    fetchPaymentTransactionsListActions.failure,
    (state, action) => ({
      ...state,
      listState: {
        ...state.listState?.transactions,
        isLoading: false,
        error: action.payload
      }
    })
  )
  .handleAction(fetchPaymentTransactionsListActions.clear, (state) => ({
    ...state,
    listState: undefined
  }))
  .handleAction(fetchGLMainAccountsActions.request, (state) => ({
    ...state,
    mainAccountState: {
      isLoading: true,
      error: undefined,
      accounts: undefined
    }
  }))
  .handleAction(fetchGLMainAccountsActions.success, (state, action) => ({
    ...state,
    mainAccountState: {
      isLoading: false,
      error: undefined,
      accounts: action.payload
    }
  }))
  .handleAction(fetchGLMainAccountsActions.failure, (state, action) => ({
    ...state,
    mainAccountState: {
      accounts: undefined,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(fetchSubAccountsActions.request, (state) => ({
    ...state,
    subAccountState: {
      isLoading: true,
      error: undefined,
      accounts: undefined
    }
  }))
  .handleAction(fetchSubAccountsActions.success, (state, action) => ({
    ...state,
    subAccountState: {
      isLoading: false,
      error: undefined,
      accounts: action.payload
    }
  }))
  .handleAction(fetchSubAccountsActions.failure, (state, action) => ({
    ...state,
    subAccountState: {
      accounts: undefined,
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(fetchPayeesActions.request, (state) => ({
    ...state,
    payeesState: {
      isLoading: true,
      error: undefined,
      payees: undefined
    }
  }))
  .handleAction(fetchPayeesActions.success, (state, action) => ({
    ...state,
    payeesState: {
      isLoading: false,
      error: undefined,
      payees: action.payload
    }
  }))
  .handleAction(fetchPayeesActions.failure, (state, action) => ({
    ...state,
    payeesState: {
      payees: undefined,
      isLoading: false,
      error: action.payload
    }
  }))
