export enum ColumnIdsEnum {
  householdName = 'Household Name',
  accountNumber = 'Account Number',
  legalEntityName = 'Legal Entity Name',
  contractNumber = 'Contract Number',
  cusipNumber = 'CUSIP',
  productType = 'Product Type',
  carrierName = 'Carrier',
  securityDescription = 'Description',
  contractStatusDesc = 'Policy Status',
  issueDate = 'Issue Date',
  premiumPaidSinceInception = 'Premium',
  valuationDate = 'Last Updated date',
  cashValue = 'Cash Value',
  surrenderValue = 'Surrender Value',
  surrenderCharge = 'Surrender Charge',
  surrenderDate = 'Surrender Charge Expiration',
  deathBenefitAmt = 'Death Benefit',
  rmdAmount = 'RMDs',
  loanAmount = 'Policy Loan amount',
  maturityDate = 'Maturity Date',
  insuredAnnuitants = 'Insured / Annuitants',
  productName = 'Product Name',
  address = 'Address',
  partyState = 'State',
  highBenefitName = 'High Benefit Name',
  maxBenefit = 'Max Benefit',
  incomeBenefitAmt = 'Income Benefit (GMIB)',
  feeType = 'Fee Type',
  tbfPercentAmt = 'TBF Percentage',
  agents = 'Agents',
  clientName = 'Owner / Client',
  teamName = 'Advisor Team',
  regionName = 'Division Name',
  assetClassL1 = 'Asset Class Level-1',
  assetClassL2 = 'Asset Class Level-2',
  assetClassL3 = 'Asset Class Level-3',
  assetClassL4 = 'Asset Class Level-4',
  assetType = 'Asset Type',
  assetSubType = 'Asset Sub Type',
  registeredRep = 'Advisor Id',
  registeredRepName = 'Advisor Name',
  cleanCarrierName = 'Clean Carrier Name'
}
