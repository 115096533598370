import { CellContext } from '@tanstack/react-table'
import { IActivity } from '../../../../store/types'

export const SymbolCusipPrintCell: React.FC<CellContext<IActivity, unknown>> = (
  props
) => {
  const {
    original: { cusipnumber: cusip, secid: symbol }
  } = props.row
  return (
    <div>
      {symbol && <div className="left-align">{symbol}</div>}
      {cusip && <div className="left-align">{cusip}</div>}
    </div>
  )
}
