import { endOfMonth, getMonth, getYear } from 'date-fns'
import { findLast, groupBy, keyBy, orderBy, range, sum, sumBy } from 'lodash'
import { flow } from 'lodash/fp'
import { createSelector } from 'reselect'
import { saga_getCurrentSystemuser } from 'store/user/dynamicsUser'
import { all, call, put, select, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import {
  IDynamicsBdaDepartmentAllowance,
  IDynamicsDepartment,
  payrollOnlyCode
} from '../../../../../api/dynamics'
import { isNotNullOrUndefined } from '../../../../../shared/guards'
import { AppState } from '../../../../../store'
import {
  ICombinedBdaMonthlySummaryWithOveragesAndProjections,
  IBdaCategorySummary,
  IBdaMonthlyCategorySummary,
  IWithCategory,
  IWithDate,
  IWithValue,
  IBdaYearlySummary,
  BdaSummaryPayrollExpense,
  BdaSummaryTransaction
} from '../common/types'
import {
  bdaDepartmentAllowancesActions,
  getBdaDepartmentAllowancesError,
  getBdaDepartmentAllowancesItems
} from './bdaDepartmentAllowances'
import {
  bdaPayrollCategories,
  BDAPayrollCategory,
  bdaPayrollExpensesActions,
  getBdaPayrollExpensesError,
  getBdaPayrollExpensesItems,
  mapToSummaryPayrollExpense,
  supplementalCompCategories,
  SupplementalCompCategory
} from './bdaPayrollExpenses'
import {
  bdaTransactionActions,
  getBdaTransactionsError,
  getBdaTransactionsItems
} from './bdaTransactions'
import { getLastYearBdaDepartmentAllowancesItems } from './lastYearDepartmentAllowances'
import { getIsEntitledForPayroll } from './selectors'

const SET_SELECTED_DEPARTMENTS =
  '@modules/@advisory/@modules/@bda/@bdaContainer/SET_SELECTED_DEPARTMENTS'
const SET_SUMMARY_YEAR =
  '@modules/@advisory/@modules/@bda/@bdaContainer/SET_SUMMARY_YEAR'
const SET_LAST_FETCHED_YEAR =
  '@modules/@advisory/@modules/@bda/@bdaContainer/SET_LAST_FETCHED_YEAR'
const REFRESH = '@modules/@advisory/@modules/@bda/@bdaContainer/REFRESH'

export const bdaContainerActions = {
  setSelectedDepartments: createAction(SET_SELECTED_DEPARTMENTS)<
    IDynamicsDepartment[]
  >(),
  setSummaryYear: createAction(SET_SUMMARY_YEAR)<number>(),
  setLastFetchedYear: createAction(SET_LAST_FETCHED_YEAR)<number>(),
  refresh: createAction(REFRESH)()
}

export type BdaContainerActionTypes = ActionType<typeof bdaContainerActions>

export interface IBdaContainerState {
  selectedDepartments?: IDynamicsDepartment[]
  summaryYear?: number
  lastFetchedYear?: number
}

const initialState: IBdaContainerState = {}

export const bdaContainerReducer = createReducer<
  IBdaContainerState,
  BdaContainerActionTypes
>(initialState)
  .handleAction(
    bdaContainerActions.setSelectedDepartments,
    (state, { payload }) => ({
      ...state,
      selectedDepartments: payload
    })
  )
  .handleAction(bdaContainerActions.setSummaryYear, (state, { payload }) => ({
    ...state,
    summaryYear: payload
  }))
  .handleAction(
    bdaContainerActions.setLastFetchedYear,
    (state, { payload }) => ({
      ...state,
      lastFetchedYear: payload
    })
  )

export const getBdaContainerState = (state: AppState) =>
  state.modules.advisory.modules.bda.bdaContainer
export const getBdaSelectedDepartments = flow(
  getBdaContainerState,
  (x) => x.selectedDepartments
)
export const getBdaSummaryYear = flow(
  getBdaContainerState,
  (x) => x.summaryYear
)

const getLastFetchedYear = flow(getBdaContainerState, (x) => x.lastFetchedYear)

const mapToBdaCategorySummaryCollection = <
  T extends IWithCategory & IWithValue
>(
  items: T[] = []
): IBdaCategorySummary<T>[] =>
  Object.entries(groupBy(items, (x) => x.category)).map(([key, category]) => {
    return {
      category: key,
      items: category,
      total: sumBy(category, (x) => x.value || 0)
    }
  })

const mapToBdaMonthlyCategorySummaryCollection = <
  T extends IWithDate & IWithCategory & IWithValue
>(
  items: T[] = [],
  eligibleAllowanceCategories?: Record<string, boolean>
): IBdaMonthlyCategorySummary<T>[] =>
  Object.entries(
    groupBy(items, ({ date }) =>
      date ? `${getMonth(date)}-${getYear(date)}` : ''
    )
  ).map(([, month]) =>
    mapToBdaMonthlyCategorySummary(month, eligibleAllowanceCategories)
  )

const mapToBdaMonthlyCategorySummary = <
  T extends IWithDate & IWithCategory & IWithValue
>(
  items: T[] = [],
  eligibleAllowanceCategories?: Record<string, boolean>
): IBdaMonthlyCategorySummary<T> => {
  const [first] = items
  const total = sumBy(items, ({ value }) => value || 0)
  return {
    date: first && first.date ? endOfMonth(first.date) : undefined,
    items: items,
    categories: mapToBdaCategorySummaryCollection(items),
    total: total,
    allowanceTotal: eligibleAllowanceCategories
      ? sumBy(
          items.filter(
            ({ category }) => eligibleAllowanceCategories[category || '']
          ),
          ({ value }) => value || 0
        )
      : total
  }
}

const mapMonthlySummaryToOrderedMonthArray = <T extends IWithDate>(
  items: T[]
): T[] => {
  return items.reduce((a, x) => {
    if (x.date) {
      a[getMonth(x.date)] = x
    }
    return a
  }, [] as T[])
}

export const categoryIsSalary = ({ category }: BdaSummaryPayrollExpense) =>
  category === ('Regular Pay' as BDAPayrollCategory)

const regularPayLookup = keyBy(bdaPayrollCategories['Regular Pay'])

export const mapToBdaSummary = (
  transactions: BdaSummaryTransaction[] = [],
  allPayroll: BdaSummaryPayrollExpense[] = [],
  departmentAllowance: IDynamicsBdaDepartmentAllowance = {},
  lastYearDepartmentAllowance: IDynamicsBdaDepartmentAllowance = {},
  selectedYear?: number
) => {
  if (!selectedYear) {
    return
  }
  const {
    rcm_allowance = 0,
    rcm_allowancetype,
    rcm_lastyearremainingallowance = 0
  } = departmentAllowance

  const { rcm_allowancetype: lastYearAllowanceType } =
    lastYearDepartmentAllowance
  const payroll = allPayroll.filter(
    ({ category }) => bdaPayrollCategories[category as BDAPayrollCategory]
  )
  const supplemental = allPayroll.filter(
    ({ category }) =>
      supplementalCompCategories[category as SupplementalCompCategory]
  )

  const isPayrollOnly = rcm_allowancetype === payrollOnlyCode

  const isLastYearPayrollOnly = lastYearAllowanceType === payrollOnlyCode

  const selectedYearTransactions = transactions.filter(
    (transaction) => transaction.date?.getFullYear() === selectedYear
  )

  const transactionSummary = flow(
    (transactions: BdaSummaryTransaction[]) =>
      mapToBdaMonthlyCategorySummaryCollection(
        transactions,
        isPayrollOnly ? {} : undefined
      ),
    mapMonthlySummaryToOrderedMonthArray
  )(selectedYearTransactions)

  const previousYearLastMonthTransactions = sum(
    transactions
      .filter(
        (transaction) => transaction.date?.getFullYear() === selectedYear - 1
      )
      .map((transaction) => transaction.value)
  )

  const payrollSummary = flow(
    mapToBdaMonthlyCategorySummaryCollection,
    mapMonthlySummaryToOrderedMonthArray
  )(payroll)

  const supplementalSummary = flow(
    mapToBdaMonthlyCategorySummaryCollection,
    mapMonthlySummaryToOrderedMonthArray
  )(supplemental)

  const lastPayrollMonthSummary = findLast(payrollSummary, isNotNullOrUndefined)
  const months = range(12)
  const shouldProjectPayroll = true
  const firstProjectionMonth = lastPayrollMonthSummary?.date
    ? getMonth(lastPayrollMonthSummary.date) + 1
    : 0

  const januaryDeductions = isLastYearPayrollOnly
    ? previousYearLastMonthTransactions
    : previousYearLastMonthTransactions > rcm_lastyearremainingallowance
    ? previousYearLastMonthTransactions - rcm_lastyearremainingallowance
    : 0

  const combinedMonthlySummary = months.map((month) => {
    const monthTransactionsSummary = transactionSummary[month]
    const monthPayrollSummary = payrollSummary[month]
    const monthSupplementalSummary = supplementalSummary[month]
    const lastMonthTransactionSummary = transactionSummary[month - 1]
    const previousMonthTransactions =
      month === 0 ? 0 : lastMonthTransactionSummary?.total || 0

    return {
      transactions: monthTransactionsSummary,
      payroll: monthPayrollSummary,
      supplemental: monthSupplementalSummary,
      total:
        (monthTransactionsSummary?.total || 0) +
        (monthPayrollSummary?.total || 0),
      allowanceTotal:
        (monthTransactionsSummary?.allowanceTotal || 0) +
        (monthPayrollSummary?.allowanceTotal || 0),
      operatingExpense:
        (monthPayrollSummary?.total || 0) + previousMonthTransactions,
      allowanceOperatingExpenses:
        (monthPayrollSummary?.allowanceTotal || 0) +
        (lastMonthTransactionSummary?.allowanceTotal || 0),
      deductions:
        month === 0
          ? januaryDeductions
          : isPayrollOnly
          ? previousMonthTransactions
          : 0
    }
  })

  const combinedMonthlySummaryWithOveragesAndProjections =
    combinedMonthlySummary.map(
      (
        summary,
        month
      ): ICombinedBdaMonthlySummaryWithOveragesAndProjections => {
        const shouldAddProjected =
          shouldProjectPayroll &&
          !summary.payroll &&
          !summary.transactions &&
          month >= firstProjectionMonth - 1

        const lastMonthSummary = combinedMonthlySummary?.[month - 1]

        if (shouldAddProjected) {
          summary.payroll = {
            ...mapToBdaMonthlyCategorySummary(
              lastPayrollMonthSummary?.items?.filter(categoryIsSalary)
            ),
            isProjection: true
          }

          summary.total =
            (summary?.payroll?.total || 0) + (summary?.transactions?.total || 0)

          summary.allowanceTotal =
            (summary?.payroll?.allowanceTotal || 0) +
            (summary?.transactions?.allowanceTotal || 0)

          summary.operatingExpense =
            (summary?.payroll?.total || 0) +
            (lastMonthSummary?.transactions?.total || 0)
          summary.allowanceOperatingExpenses =
            (summary?.payroll?.allowanceTotal || 0) +
            (lastMonthSummary?.transactions?.allowanceTotal || 0)
        }

        const totalForOverageTest =
          sumBy(
            combinedMonthlySummary.slice(0, month + 1),
            (x) => x?.payroll?.allowanceTotal || 0
          ) +
          sumBy(
            combinedMonthlySummary.slice(0, month),
            (x) => x?.transactions?.allowanceTotal || 0
          )

        const newTotalForOverageTest = sumBy(
          combinedMonthlySummary.slice(0, month + 1),
          (x) => x?.allowanceOperatingExpenses
        )

        const overageTestDifferenceToAllowance =
          rcm_allowance - totalForOverageTest

        const newOverageTest = rcm_allowance - newTotalForOverageTest
        const newMonthHasOverage = newOverageTest < 0

        const monthHasOverage = overageTestDifferenceToAllowance < 0

        if (monthHasOverage && summary?.payroll?.items?.length) {
          const premiumRecordCode = bdaPayrollCategories.Premium[0]
          const premiumEligibleItems =
            summary?.payroll?.items?.filter(({ rcm_recordcode }) =>
              selectedYear >= 2024
                ? regularPayLookup[rcm_recordcode || '']
                : rcm_recordcode !== premiumRecordCode
            ) || []
          const overage = Math.abs(overageTestDifferenceToAllowance)
          const total = summary?.payroll?.allowanceTotal || 0
          const rate = overage < total ? (overage / total) * 0.3 : 0.3

          summary.payroll = {
            ...summary.payroll,
            ...mapToBdaMonthlyCategorySummary([
              ...(summary?.payroll?.items || []),
              ...premiumEligibleItems
                .map((item) => ({
                  ...item,
                  rcm_trackercategory: `${premiumRecordCode} - ${item.rcm_recordcode}`,
                  rcm_recordcode: premiumRecordCode,
                  category: premiumRecordCode,
                  rcm_currentearnings: (item?.rcm_currentearnings || 0) * rate
                }))
                .map(mapToSummaryPayrollExpense)
            ])
          }

          summary.total =
            (summary?.payroll?.total || 0) + (summary?.transactions?.total || 0)

          summary.allowanceTotal =
            (summary?.payroll?.allowanceTotal || 0) +
            (summary?.transactions?.allowanceTotal || 0)

          summary.operatingExpense =
            month === 0
              ? (summary?.payroll?.total || 0) +
                previousYearLastMonthTransactions
              : (summary?.payroll?.total || 0) +
                (lastMonthSummary?.transactions?.total || 0)
          summary.allowanceOperatingExpenses =
            (summary?.payroll?.allowanceTotal || 0) +
            (lastMonthSummary?.transactions?.allowanceTotal || 0)
        }

        const operatingExpenses = summary.operatingExpense

        const runningTotal = sumBy(
          combinedMonthlySummary.slice(0, month + 1),
          (x) => x.total
        )

        const runningAllowanceTotal = sumBy(
          combinedMonthlySummary.slice(0, month + 1),
          (x) => x?.allowanceTotal || 0
        )

        const newRunningAllowanceTotal = sumBy(
          combinedMonthlySummary.slice(0, month + 1),
          (x) => x?.allowanceOperatingExpenses || 0
        )

        const runningDifferenceToAllowance =
          rcm_allowance - runningAllowanceTotal
        const newRunningDifferenceToAllowance =
          rcm_allowance - newRunningAllowanceTotal

        const allowanceOverage = monthHasOverage
          ? Math.abs(runningDifferenceToAllowance) < summary.allowanceTotal
            ? Math.abs(runningDifferenceToAllowance)
            : summary.allowanceTotal
          : 0

        const newAllowanceOverage = newMonthHasOverage
          ? Math.abs(newRunningDifferenceToAllowance) <
            summary.allowanceOperatingExpenses
            ? Math.abs(newRunningDifferenceToAllowance)
            : summary.allowanceOperatingExpenses
          : 0

        const deductions =
          allowanceOverage +
          (isPayrollOnly ? summary?.transactions?.total || 0 : 0)

        const newDeductions = newAllowanceOverage + (summary.deductions || 0)

        return {
          ...summary,
          isProjection: shouldAddProjected,
          allowanceOverage,
          deductions,
          runningTotal,
          runningAllowanceTotal,
          runningDifferenceToAllowance,
          operatingExpenses,
          newRunningDifferenceToAllowance,
          newDeductions,
          allowanceOperatingExpenses: summary.allowanceOperatingExpenses
        }
      }
    )

  const ytdTotal = sumBy(
    combinedMonthlySummaryWithOveragesAndProjections.filter(
      ({ isProjection }) => !isProjection
    ),
    ({ total }) => total || 0
  )

  const ytdAllowanceTotal = sumBy(
    combinedMonthlySummaryWithOveragesAndProjections.filter(
      ({ isProjection }) => !isProjection
    ),
    ({ allowanceTotal }) => allowanceTotal || 0
  )

  const newYtdAllowanceTotal = sumBy(
    combinedMonthlySummaryWithOveragesAndProjections.filter(
      ({ isProjection }) => !isProjection
    ),
    ({ allowanceOperatingExpenses }) => allowanceOperatingExpenses || 0
  )

  const ytdPayroll = sumBy(
    combinedMonthlySummaryWithOveragesAndProjections.filter(
      ({ isProjection }) => !isProjection
    ),
    ({ payroll: { total = 0 } = {} }) => total
  )

  const ytdTransactions = sumBy(
    combinedMonthlySummaryWithOveragesAndProjections.filter(
      ({ isProjection }) => !isProjection
    ),
    ({ transactions: { total = 0 } = {} }) => total
  )

  const projection = sumBy(
    combinedMonthlySummaryWithOveragesAndProjections,
    ({ allowanceTotal }) => allowanceTotal || 0
  )
  const newProjection = sumBy(
    combinedMonthlySummaryWithOveragesAndProjections,
    ({ allowanceOperatingExpenses }) => allowanceOperatingExpenses || 0
  )

  const projectionDifferenceToAllowance = rcm_allowance - projection
  const newProjectionDifferenceToAllowance = rcm_allowance - newProjection
  const ytdTotalDifferenceToAllowance = rcm_allowance - ytdAllowanceTotal
  const newYtdTotalDifferenceToAllowance = rcm_allowance - newYtdAllowanceTotal
  const overage =
    ytdTotalDifferenceToAllowance < 0
      ? Math.abs(ytdTotalDifferenceToAllowance)
      : 0
  const allowanceProgress = rcm_allowance
    ? ytdAllowanceTotal / rcm_allowance
    : undefined

  return {
    allowance: rcm_allowance,
    departmentAllowance,
    allowanceProgress,
    overage,
    ytdTotal,
    ytdAllowanceTotal,
    ytdPayroll,
    ytdTransactions,
    ytdTotalDifferenceToAllowance,
    projection,
    projectionDifferenceToAllowance,
    months: combinedMonthlySummaryWithOveragesAndProjections,
    priorYearAllowanceRemaining: rcm_lastyearremainingallowance,
    previousYearLastMonthTransactions,
    newYtdTotalDifferenceToAllowance,
    newProjectionDifferenceToAllowance,
    isLastYearPayrollOnly
  }
}

export const getIsBdaYearCurrentYear = createSelector(
  [getBdaSummaryYear],
  (year) => getYear(Date.now()) === year
)

export const getMostRecentPayrollMonth = createSelector(
  [getBdaPayrollExpensesItems],
  (payroll) => {
    const sortedPayroll = orderBy(
      payroll?.filter((x) => x.rcm_payrolldate),
      (x) => (x.rcm_payrolldate ? new Date(x.rcm_payrolldate) : undefined),
      'desc'
    )

    const [mostRecent] = sortedPayroll || []
    return mostRecent?.rcm_payrolldate
      ? getMonth(new Date(mostRecent.rcm_payrolldate))
      : undefined
  }
)

export const getBdaSummaryForAllDepartments = createSelector(
  [
    getBdaTransactionsItems,
    getBdaPayrollExpensesItems,
    getBdaDepartmentAllowancesItems,
    getLastYearBdaDepartmentAllowancesItems,
    getBdaSummaryYear
  ],
  (transactions, payroll, departments, lastYearDepartments, selectedYear) => {
    if (!transactions || !payroll) {
      return
    }

    const transactionsByDepartment = groupBy(
      transactions,
      ({ rcm_Department }) => rcm_Department?.cdm_departmentnumber
    )

    const payrollByDepartment = groupBy(
      payroll,
      ({ rcm_Department }) => rcm_Department?.cdm_departmentnumber
    )

    const lastYearAllowanceByDepartment = keyBy(
      lastYearDepartments,
      (x) => x?.rcm_Department?.cdm_departmentnumber || ''
    )

    return departments?.map((departmentAllowance): IBdaYearlySummary => {
      const { rcm_Department } = departmentAllowance
      const departmentTransactions =
        transactionsByDepartment[rcm_Department?.cdm_departmentnumber || '']
      const departmentPayroll =
        payrollByDepartment[rcm_Department?.cdm_departmentnumber || '']

      const lastYearDepartmentAllowance =
        lastYearAllowanceByDepartment[
          rcm_Department?.cdm_departmentnumber || ''
        ]

      return {
        department: rcm_Department,
        ...mapToBdaSummary(
          departmentTransactions,
          departmentPayroll,
          departmentAllowance,
          lastYearDepartmentAllowance,
          selectedYear
        )
      }
    })
  }
)

export const getBdaPayrollExpensesWithPremium = createSelector(
  [getBdaSummaryForAllDepartments],
  (expenses) => {
    return expenses?.flatMap((x) =>
      (x.months || [])
        .flatMap((x) => [x.payroll, x.supplemental])
        .filter((x) => x && !x.isProjection)
        .flatMap((x) => x?.items || [])
    )
  }
)

export const getCombinedBdaSummaryForSelectedDepartments = createSelector(
  [
    getBdaTransactionsItems,
    getBdaPayrollExpensesItems,
    getBdaDepartmentAllowancesItems,
    getLastYearBdaDepartmentAllowancesItems,
    getBdaSummaryYear,
    getBdaSelectedDepartments
  ],
  (
    transactions,
    payroll,
    departments,
    lastYearDepartments,
    summaryYear,
    selectedDepartments
  ) => {
    if (!transactions || !payroll) {
      return
    }

    if (!selectedDepartments?.length) {
      return
    }

    const transactionsByDepartment = groupBy(
      transactions,
      ({ rcm_Department }) => rcm_Department?.cdm_departmentnumber
    )

    const payrollByDepartment = groupBy(
      payroll,
      ({ rcm_Department }) => rcm_Department?.cdm_departmentnumber
    )

    const allowanceByDepartment = keyBy(
      departments,
      (x) => x?.rcm_Department?.cdm_departmentnumber || ''
    )
    const lastYearAllowanceByDepartment = keyBy(
      lastYearDepartments,
      (x) => x?.rcm_Department?.cdm_departmentnumber || ''
    )

    const selected = selectedDepartments?.map(({ cdm_departmentnumber }) => ({
      transactions: transactionsByDepartment[cdm_departmentnumber || ''],
      payroll: payrollByDepartment[cdm_departmentnumber || ''],
      allowance: allowanceByDepartment[cdm_departmentnumber || ''],
      lastYearAllowance:
        lastYearAllowanceByDepartment[cdm_departmentnumber || '']
    }))

    return mapToBdaSummary(
      selectedDepartments?.length
        ? selected?.flatMap((x) => x.transactions || [])
        : transactions,
      selectedDepartments?.length
        ? selected?.flatMap((x) => x.payroll || [])
        : payroll,
      selected?.[0]?.allowance,
      selected?.[0]?.lastYearAllowance,
      summaryYear
    )
  }
)

export const getBdaDashboardError = createSelector(
  [
    getBdaDepartmentAllowancesError,
    getBdaPayrollExpensesError,
    getBdaTransactionsError
  ],
  (allowances, expenses, transactions) => allowances || expenses || transactions
)

export const bdaContainerSagas = [
  () =>
    takeLatest(bdaContainerActions.refresh, function* () {
      const lastFetchedYear = yield* select(getLastFetchedYear)
      const summaryYear = yield* select(getBdaSummaryYear)
      if (summaryYear === lastFetchedYear || !summaryYear) {
        return
      }
      const systemuser = yield* call(saga_getCurrentSystemuser)
      const loadPayroll = yield* call(
        getIsEntitledForPayroll,
        systemuser?.systemuserroles_association
      )
      yield all([
        put(bdaTransactionActions.request(summaryYear)),
        loadPayroll && put(bdaPayrollExpensesActions.request(summaryYear)),
        !loadPayroll && put(bdaPayrollExpensesActions.success([])),
        put(bdaDepartmentAllowancesActions.request(summaryYear))
      ])
      yield put(bdaContainerActions.setLastFetchedYear(summaryYear))
    })
]
