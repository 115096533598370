import { combineReducers, Reducer } from 'redux'
import { IActivityAndNotes } from '../../../api/dynamics'
import { IDataListState } from '../../DataList/contracts/IDataListState'
import {
  activityAndNotesListReducer,
  activityAndNotesListSagas
} from './activityAndNotesList'
import {
  clientActivityFetchReducer,
  clientActivityFetchSagas
} from './clientActivityFetch'
export const clientsFeatureReducer: Reducer<{
  clientActivityFetch: ReturnType<typeof clientActivityFetchReducer>
  activityAndNotesList: IDataListState<IActivityAndNotes>
}> = combineReducers({
  clientActivityFetch: clientActivityFetchReducer,
  activityAndNotesList: activityAndNotesListReducer
})

export const clientsFeatureSagas = [
  ...clientActivityFetchSagas,
  ...activityAndNotesListSagas
]
