import { MessageBarType } from '@fluentui/react'
import { flow } from 'lodash/fp'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import {
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest
} from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { IOdataBatchResponseItem } from '../../../api/datahub'
import {
  HouseholdChangeRequestStatus,
  IHouseholdChangeRequest
} from '../../../api/households'
import { IOrderBy } from '../../../api/odata.types'
import { pushNotification } from '../../../features/Notifications'
import { IOdataResult } from '../../../shared/contracts/IOdataResult'
import { AppState } from '../../../store'
import {
  deleteHouseholdChangeRequestsFromApi,
  getHouseholdChangeRequestsFromApi,
  getRequestParametersForHouseholdsChangeRequest,
  updateEditHouseholdRequestStatusFromApi
} from './sagas'

const SEARCH_REQUEST =
  '@modules/@households/@householdsChangeRequestList/SEARCH_REQUEST'
const SEARCH_CHUNK =
  '@modules/@households/@householdsChangeRequestList/SEARCH_CHUNK'
const SEARCH_LOAD_MORE =
  '@modules/@households/@householdsChangeRequestList/SEARCH_LOAD_MORE'
const SEARCH_FAILURE =
  '@modules/@households/@householdsChangeRequestList/SEARCH_FAILURE'

interface IChunkPayload {
  page: number
  chunk: IOdataResult<IHouseholdChangeRequest>
}

export const changeRequestSearchActions = {
  request: createAction(SEARCH_REQUEST)(),
  loadMore: createAction(SEARCH_LOAD_MORE)(),
  chunk: createAction(SEARCH_CHUNK)<IChunkPayload>(),
  failure: createAction(SEARCH_FAILURE)<Error>()
}

const STATUS_CHANGE_REQUEST =
  '@modules/@households/@changeRequestsList/STATUS_CHANGE_REQUEST'
const STATUS_CHANGE_SUCCESS =
  '@modules/@households/@changeRequestsList/STATUS_CHANGE_SUCCESS'
const STATUS_CHANGE_FAILURE =
  '@modules/@households/@changeRequestsList/STATUS_CHANGE_FAILURE'

export interface IChangeRequestStatusUpdateActionPayload {
  requestId: number
  status: HouseholdChangeRequestStatus
}
export const changeRequestStatusUpdateActions = {
  request: createAction(STATUS_CHANGE_REQUEST)<
    IChangeRequestStatusUpdateActionPayload[]
  >(),
  success: createAction(STATUS_CHANGE_SUCCESS)(),
  failure: createAction(STATUS_CHANGE_FAILURE)<Error>()
}

const UPDATE_SEARCH_TEXT =
  '@modules/@households/@changeRequestsList/UPDATE_SEARCH_TEXT'
const SORT_COLUMN = '@modules/@households/@changeRequestsList/SORT_COLUMN'
const FILTER = '@modules/@households/@changeRequestsList/FILTER'

export const changeRequestsListUiActions = {
  updateSearchText: createAction(UPDATE_SEARCH_TEXT)<string | undefined>(),
  sort: createAction(SORT_COLUMN)<IOrderBy>(),
  filter: createAction(FILTER)<IChangeRequestsListFilter>()
}

const DELETE_REQUEST = '@modules/@households/@changeRequestsList/DELETE_REQUEST'
const DELETE_SUCCESS = '@modules/@households/@changeRequestsList/DELETE_SUCCESS'
const DELETE_FAILURE = '@modules/@households/@changeRequestsList/DELETE_FAILURE'

export const changeRequestDeleteActions = {
  request: createAction(DELETE_REQUEST)<number[] | undefined>(),
  success: createAction(DELETE_SUCCESS)(),
  failure: createAction(DELETE_FAILURE)<Error>()
}

export type HouseholdActionTypes =
  | ActionType<typeof changeRequestSearchActions>
  | ActionType<typeof changeRequestStatusUpdateActions>
  | ActionType<typeof changeRequestsListUiActions>
  | ActionType<typeof changeRequestDeleteActions>

export interface IHouseholdChangeRequestSearchState {
  chunks?: IOdataResult<IHouseholdChangeRequest>[]
  loading?: boolean
  error?: Error
}

const initialHouseholdChangeRequestSearchState: IHouseholdChangeRequestSearchState =
  {
    loading: false
  }

export const householdsChangeRequestSearchReducer = createReducer<
  IHouseholdChangeRequestSearchState,
  HouseholdActionTypes
>(initialHouseholdChangeRequestSearchState)
  .handleAction(changeRequestSearchActions.request, (state) => ({
    ...state,
    error: undefined,
    loading: true
  }))
  .handleAction(changeRequestSearchActions.loadMore, (state) => ({
    ...state,
    error: undefined,
    loading: true
  }))
  .handleAction(changeRequestSearchActions.chunk, (state, action) => {
    const { page, chunk } = action.payload
    const isFirstPage = page === 0
    const newChunks = isFirstPage ? [chunk] : [...(state.chunks || [])]
    if (!isFirstPage) {
      newChunks[page] = chunk
    }
    return {
      ...state,
      error: undefined,
      chunks: newChunks,
      loading: false
    }
  })
  .handleAction(changeRequestSearchActions.failure, (state, action) => ({
    ...state,
    chunks: [],
    error: action.payload,
    loading: false
  }))

export const getHouseholdsChangeRequestListState = (state: AppState) =>
  state.modules.households.householdsChangeRequestList
export const getHouseholdsChangeRequestSearchState = flow(
  getHouseholdsChangeRequestListState,
  (x) => x.search
)
export const getIsHouseholdChangeRequestSearchLoading = flow(
  getHouseholdsChangeRequestSearchState,
  (x) => x.loading
)
export const getHouseholdsChangeRequestSearchError = flow(
  getHouseholdsChangeRequestSearchState,
  (x) => x.error
)
export const getHouseholdsChangeRequestSearchChunks = flow(
  getHouseholdsChangeRequestSearchState,
  (x) => x.chunks
)
export const getHouseholdsChangeRequestSearchCount = createSelector(
  [getHouseholdsChangeRequestSearchChunks],
  (chunks) => {
    return chunks?.[0]?.['@odata.count'] as number | undefined
  }
)
export const getHouseholdsChangeRequestSearchItems = createSelector(
  [getHouseholdsChangeRequestSearchChunks],
  (chunks) => {
    return chunks?.flatMap((x) => x.value || [])
  }
)

export interface IChangeRequestStatusUpdateState {
  items?: IChangeRequestStatusUpdateActionPayload[]
  success?: boolean
  loading?: boolean
  error?: Error
}

const initialChangeRequestStatusUpdateState: IChangeRequestStatusUpdateState = {
  loading: false
}

export const householdsChangeRequestStatusUpdateReducer = createReducer<
  IChangeRequestStatusUpdateState,
  HouseholdActionTypes
>(initialChangeRequestStatusUpdateState)
  .handleAction(changeRequestStatusUpdateActions.request, (state, action) => ({
    ...state,
    items: action.payload,
    success: undefined,
    error: undefined,
    loading: true
  }))
  .handleAction(changeRequestStatusUpdateActions.success, (state) => ({
    ...state,
    success: true,
    loading: false
  }))
  .handleAction(changeRequestStatusUpdateActions.failure, (state, action) => ({
    ...state,
    error: action.payload,
    success: false,
    loading: false
  }))

export const getChangeRequestStatusUpdateState = (state: AppState) =>
  state.modules.households.householdsChangeRequestList.statusUpdate
export const getChangeRequestStatusUpdateItems = flow(
  getChangeRequestStatusUpdateState,
  (x) => x.items
)
export const getIsChangeRequestStatusUpdateLoading = flow(
  getChangeRequestStatusUpdateState,
  (x) => x.loading
)

export interface IChangeRequestsListFilter {
  status?: HouseholdChangeRequestStatus[]
}

export interface IChangeRequestsListUiState {
  searchText?: string
  orderBy?: IOrderBy
  filters?: IChangeRequestsListFilter
}

const initialChangeRequestEditListUiState: IChangeRequestsListUiState = {
  orderBy: {
    dataPath: 'modifiedOn',
    direction: 'desc'
  }
}

export const changeRequestsListUiReducer = createReducer<
  IChangeRequestsListUiState,
  HouseholdActionTypes
>(initialChangeRequestEditListUiState)
  .handleAction(
    changeRequestsListUiActions.updateSearchText,
    (state, action) => ({
      ...state,
      searchText: action.payload
    })
  )
  .handleAction(changeRequestsListUiActions.sort, (state, action) => ({
    ...state,
    orderBy: action.payload
  }))
  .handleAction(changeRequestsListUiActions.filter, (state, action) => ({
    ...state,
    filters: { ...state?.filters, ...action.payload }
  }))

export const getChangeRequestEditListUiSearchText = flow(
  getHouseholdsChangeRequestListState,
  (x) => x.ui.searchText
)
export const getChangeRequestEditListUiOrderBy = flow(
  getHouseholdsChangeRequestListState,
  (x) => x.ui.orderBy
)
export const getChangeRequestEditListUiFilters = flow(
  getHouseholdsChangeRequestListState,
  (x) => x.ui.filters
)

export interface IHouseholdChangeRequestDeleteState {
  items?: number[]
  success?: boolean
  loading?: boolean
  error?: Error
}

const initialHouseholdChangeRequestDeleteState: IHouseholdChangeRequestDeleteState =
  {
    loading: false
  }

export const householdsChangeRequestDeleteReducer = createReducer<
  IHouseholdChangeRequestDeleteState,
  HouseholdActionTypes
>(initialHouseholdChangeRequestDeleteState)
  .handleAction(changeRequestDeleteActions.request, (state, action) => ({
    ...state,
    items: action.payload,
    success: undefined,
    error: undefined,
    loading: true
  }))
  .handleAction(changeRequestDeleteActions.success, (state) => ({
    ...state,
    success: true,
    loading: false
  }))
  .handleAction(changeRequestDeleteActions.failure, (state, action) => ({
    ...state,
    error: action.payload,
    success: false,
    loading: false
  }))

export const getChangeRequestDeleteState = (state: AppState) =>
  state.modules.households.householdsChangeRequestList.delete
export const getIsChangeRequestDeleteLoading = flow(
  getChangeRequestDeleteState,
  (x) => x.loading
)

export const householdsChangeRequestListReducer = combineReducers({
  search: householdsChangeRequestSearchReducer,
  statusUpdate: householdsChangeRequestStatusUpdateReducer,
  delete: householdsChangeRequestDeleteReducer,
  ui: changeRequestsListUiReducer
})

const fetchHouseholdChangeRequestSearchResults = function* () {
  yield delay(300)
  try {
    const changeRequests: IOdataResult<IHouseholdChangeRequest> = yield call(
      getHouseholdChangeRequestsFromApi
    )

    yield put(
      changeRequestSearchActions.chunk({
        page: 0,
        chunk: changeRequests
      })
    )
  } catch (e: any) {
    yield put(changeRequestSearchActions.failure(e))
  }
}

const loadMore = function* () {
  yield delay(300)

  try {
    const request = yield* call(getRequestParametersForHouseholdsChangeRequest)
    const { top } = request

    const chunks: IOdataResult<IHouseholdChangeRequest>[] | undefined =
      yield select(getHouseholdsChangeRequestSearchChunks)
    const totalChunks = chunks?.length || 0
    const max = chunks?.[0]?.['@odata.count'] || 0
    const skip = totalChunks * (top || 0)
    if (!totalChunks || max <= skip) {
      return
    }

    const changeRequests: IOdataResult<IHouseholdChangeRequest> = yield call(
      getHouseholdChangeRequestsFromApi,
      { ...request, skip }
    )

    yield put(
      changeRequestSearchActions.chunk({
        page: totalChunks,
        chunk: changeRequests
      })
    )
  } catch (e: any) {
    yield put(changeRequestSearchActions.failure(e))
  }
}

const updateStatusForHouseholdChangeRequests = function* (
  action: ReturnType<typeof changeRequestStatusUpdateActions.request>
) {
  yield delay(300)
  try {
    const responses: IOdataBatchResponseItem[] = yield call(
      updateEditHouseholdRequestStatusFromApi,
      action.payload
    )

    yield put(changeRequestStatusUpdateActions.success())
    const listResults = responses.pop()
      ?.body as IOdataResult<IHouseholdChangeRequest>

    if (listResults?.value) {
      yield put(
        changeRequestSearchActions.chunk({
          page: 0,
          chunk: listResults
        })
      )
    }
  } catch (e: any) {
    yield put(changeRequestStatusUpdateActions.failure(e))
  }
}

const deleteHouseholdChangeRequests = function* (
  action: ReturnType<typeof changeRequestDeleteActions.request>
) {
  try {
    if (!action.payload?.length) {
      throw new Error('At least 1 request id is required')
    }

    const responses: IOdataBatchResponseItem[] = yield call(
      deleteHouseholdChangeRequestsFromApi,
      action.payload
    )

    yield put(changeRequestDeleteActions.success())
    const listResults = responses.pop()
      ?.body as IOdataResult<IHouseholdChangeRequest>

    if (listResults?.value) {
      yield put(
        changeRequestSearchActions.chunk({
          page: 0,
          chunk: listResults
        })
      )
    }
  } catch (e: any) {
    yield put(changeRequestDeleteActions.failure(e))
  }
}

export const householdsChangeRequestListSagas = [
  () =>
    takeLatest(
      [
        changeRequestsListUiActions.updateSearchText,
        changeRequestsListUiActions.sort,
        changeRequestsListUiActions.filter
      ],
      function* () {
        yield put(changeRequestSearchActions.request())
      }
    ),
  () =>
    takeLatest(
      changeRequestSearchActions.request,
      fetchHouseholdChangeRequestSearchResults
    ),
  () => takeLatest(changeRequestSearchActions.loadMore, loadMore),
  () =>
    takeLatest(
      changeRequestStatusUpdateActions.request,
      updateStatusForHouseholdChangeRequests
    ),
  () =>
    takeLatest(
      changeRequestDeleteActions.request,
      deleteHouseholdChangeRequests
    ),
  () =>
    takeEvery(changeRequestDeleteActions.success, function* () {
      yield call(pushNotification, {
        message: 'Successfully deleted request(s).',
        type: MessageBarType.success
      })
    }),
  () =>
    takeEvery(changeRequestDeleteActions.failure, function* () {
      yield call(pushNotification, {
        message: 'Failed to delete request(s).',
        type: MessageBarType.error
      })
    }),
  () =>
    takeEvery(changeRequestStatusUpdateActions.success, function* () {
      yield call(pushNotification, {
        message: 'Status updated.',
        type: MessageBarType.success
      })
    }),
  () =>
    takeEvery(changeRequestStatusUpdateActions.failure, function* () {
      yield call(pushNotification, {
        message: 'Failed to update status.',
        type: MessageBarType.error
      })
    })
]
