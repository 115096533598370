import { IClient } from 'api/client.types'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { getMostRecentLoginDetails } from 'shared/client'
import { isNotNullOrUndefined } from 'shared/guards'
import { useLazyGetNFSProfileQuery } from '../store/datahub'
import { useRdot360Context } from '../store/rdot360Context'

export const useRdot360Navigation = () => {
  const { setHousehold } = useRdot360Context()
  const [requestNFSProfile] = useLazyGetNFSProfileQuery()
  const navigate = useNavigate()

  const handleClientClicked = useCallback(
    async (client?: IClient) => {
      const { householdId, LegalEntityID: legalEntityId } = client || {}
      if (!householdId || !legalEntityId) {
        alert('An error occurred while trying to navigate to Client Dashboard.')
        return
      }

      navigate('/advisory/client')

      const wealthscapeId = getMostRecentLoginDetails(
        client?.loginDetails
      )?.WealthscapeID

      let profileAccounts: string[] = []
      if (wealthscapeId) {
        const profile = await requestNFSProfile(wealthscapeId)
        profileAccounts =
          profile.data?.accounts
            ?.map(({ accountKey }) => accountKey)
            ?.filter(isNotNullOrUndefined) || []
      }

      setHousehold({
        householdId,
        selectionType: profileAccounts.length ? 'account' : 'client',
        selectionIds: profileAccounts.length ? profileAccounts : [legalEntityId]
      })
    },
    [navigate, requestNFSProfile, setHousehold]
  )

  const handleHouseholdClicked = useCallback(
    (householdId?: string) => {
      if (!householdId) {
        alert('An error occurred while trying to navigate to Client Dashboard.')
        return
      }
      setHousehold({
        householdId
      })

      navigate('/advisory/client')
    },
    [navigate, setHousehold]
  )

  return {
    handleClientClicked,
    handleHouseholdClicked
  }
}
