import { isDefined } from '@nevware21/ts-utils'
import axios, { AxiosResponse } from 'axios'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { IFirm, IFirmInsertUpdateRes } from './types'

const fetchFirmService = (
  req: string | undefined,
  options: IApiOptions
): Promise<AxiosResponse<IFirm[] | undefined>> => {
  const url = `${options.apiRoot}/cdm/Firm/${
    isDefined(req) ? `GetByFirmCode/${req}` : `GetAllFirms`
  }`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, { headers, cancelToken: options.cancelToken })
    .then((res) => res.data?.data)
}

const inserUpdateFirmService = (
  payload: IFirm | undefined,
  options: IApiOptions
): Promise<AxiosResponse<IFirmInsertUpdateRes | undefined>> => {
  const url = `${options.apiRoot}/cdm/Firm/${
    payload?.insertUpdateDelete === 'I'
      ? `createFirm`
      : payload?.insertUpdateDelete === 'U'
      ? `updateFirm/${payload?.firmCode}`
      : `deleteFirm/${payload?.firmCode}`
  }`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  if (payload?.insertUpdateDelete === 'U') {
    return axios
      .put(url, payload, { headers, cancelToken: options.cancelToken })
      .then((res) => res.data)
  } else if (payload?.insertUpdateDelete === 'I') {
    return axios
      .post(url, payload, { headers, cancelToken: options.cancelToken })
      .then((res) => res.data)
  } else {
    return axios
      .delete(url, { headers, cancelToken: options.cancelToken })
      .then((res) => res.data)
  }
}

export { fetchFirmService, inserUpdateFirmService }
