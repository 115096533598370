import {
  DatePicker,
  Dropdown,
  IDropdownOption,
  SelectableOptionMenuItemType,
  Stack
} from '@fluentui/react'
import { endOfDay, format, startOfDay } from 'date-fns'
import React from 'react'
import {
  CustomDateRanges,
  CustomDateRangesEnum,
  DateRanges,
  PastDateRanges,
  PresentDateRanges
} from '../../../../../../shared'
import { IListsDateRangeFilter } from '../../../contracts/IListsFilter'
import { IListsFilterEditProps } from '../contracts/IListsFilterEditProps'

export interface IListsDateRangeFilterComponentProps
  extends IListsFilterEditProps {
  filter: IListsDateRangeFilter
}

const mapToComboBoxOptions = <T extends { [key: string]: string }>(
  options: T
): IDropdownOption[] =>
  Object.keys(options).map(
    (x): IDropdownOption => ({
      key: options[x],
      text: options[x]
    })
  )

const DATE_RANGE_OPTIONS: IDropdownOption[] = [
  { key: 'past', text: 'Past', itemType: SelectableOptionMenuItemType.Header },
  ...mapToComboBoxOptions(PastDateRanges),
  {
    key: 'past-divider',
    text: '-',
    itemType: SelectableOptionMenuItemType.Divider
  },
  {
    key: 'present',
    text: 'Present',
    itemType: SelectableOptionMenuItemType.Header
  },
  ...mapToComboBoxOptions(PresentDateRanges),
  {
    key: 'custom-divider',
    text: '-',
    itemType: SelectableOptionMenuItemType.Divider
  },
  {
    key: 'custom',
    text: 'Custom',
    itemType: SelectableOptionMenuItemType.Header
  },
  ...mapToComboBoxOptions(CustomDateRanges)
]

const onFormatDate = (date?: Date): string =>
  date ? format(date, 'MM/dd/yyyy') : ''

export const ListsDateRangeFilterComponent: React.FC<
  IListsDateRangeFilterComponentProps
> = ({ filter, onChange }) => {
  const { range, from, to } = filter

  const fireOnChange = (newListsDateRangeFilter: IListsDateRangeFilter) => {
    const {
      range: newRange,
      from: newFrom,
      to: newTo
    } = newListsDateRangeFilter
    onChange({
      ...newListsDateRangeFilter,
      hasValue:
        (newRange === CustomDateRangesEnum.Custom && (!!newFrom || !!newTo)) ||
        (newRange !== CustomDateRangesEnum.Custom && newRange != null)
    })
  }

  const onComboBoxChange = (_: any, option?: IDropdownOption) => {
    if (!option) {
      return
    }

    fireOnChange({
      ...filter,
      range: option.key as DateRanges
    })
  }

  const onFromDateChanged = (date?: Date | null) => {
    if (!range || !date) {
      return
    }

    fireOnChange({
      ...filter,
      from: startOfDay(date)
    })
  }

  const onToDateChanged = (date?: Date | null) => {
    if (!range || !date) {
      return
    }

    fireOnChange({
      ...filter,
      to: endOfDay(date)
    })
  }

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <Stack.Item>
        <Dropdown
          title="Date Ranges"
          styles={{ root: { height: '35px' } }}
          placeholder="Select a Date Range"
          selectedKey={range}
          options={DATE_RANGE_OPTIONS}
          onChange={onComboBoxChange}
        />
      </Stack.Item>
      {range === DateRanges.Custom && (
        <Stack
          horizontal={true}
          tokens={{ childrenGap: 10 }}
          verticalAlign="center"
        >
          <DatePicker
            styles={{ textField: { width: 125 } }}
            placeholder="Start Date"
            ariaLabel="Start Date"
            value={from}
            onSelectDate={onFromDateChanged}
            formatDate={onFormatDate}
          />
          <b>to</b>
          <DatePicker
            styles={{ textField: { width: 125 } }}
            placeholder="End Date"
            ariaLabel="End Date"
            value={to}
            onSelectDate={onToDateChanged}
            formatDate={onFormatDate}
          />
        </Stack>
      )}
    </Stack>
  )
}
