import React, { Component, PropsWithChildren } from 'react'
import { ErrorPage } from '../../features/ErrorPage/ErrorPage'
import { trackException } from '../services/telemetry'

interface IErrorBoundaryState {
  hasError: boolean
  error?: Error
  errorInfo?: React.ErrorInfo
}

export class ErrorBoundary extends Component<
  PropsWithChildren<unknown>,
  IErrorBoundaryState
> {
  constructor(props: PropsWithChildren<unknown>) {
    super(props)
    this.state = { hasError: false }
  }

  public componentDidCatch?(error?: Error, errorInfo?: React.ErrorInfo) {
    // Display fallback UI
    this.setState({ hasError: true, error, errorInfo })
    // You can also log the error to an error reporting service
    this.logError(error, errorInfo)
    if (error?.name === 'ChunkLoadError') {
      window.location.reload()
    }
  }

  public render() {
    const { hasError, error, errorInfo } = this.state
    const { children } = this.props

    if (hasError && error?.name === 'ChunkLoadError') {
      return (
        <span>
          A new version of the application has been released. Refreshing the
          page to get the latest version.
        </span>
      )
    }

    return hasError ? (
      <ErrorPage error={error} errorInfo={errorInfo} />
    ) : (
      children
    )
  }

  private logError(error?: Error, errorInfo?: React.ErrorInfo) {
    trackException(error || new Error('unknown error occurred'), {
      source: 'error-boundary',
      componentStack: errorInfo?.componentStack
    })
    console.error('A fatal exception occurred', error, errorInfo)
  }
}
