import { ISystemViewDefinition } from 'features/Lists/hooks/useListPreferences'
import { AiPositionListTabNamesEnum } from '../../types'
import { combinedSystemViews } from './combined'
import { drawdownSystemViews } from './drawdown'
import { nonDrawdownSystemViews } from './nondrawdown'

export const systemViewsMap: Record<
  AiPositionListTabNamesEnum,
  ISystemViewDefinition[]
> = {
  drawdown: drawdownSystemViews,
  nondrawdown: nonDrawdownSystemViews,
  combined: combinedSystemViews
}
