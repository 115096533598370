import { partial } from 'lodash/fp'
import { IAccount } from '../../../api/account.types'
import { AppState } from '../../../store'
import { createListsStore } from '../core/store'
import { BaseExportConfigurations } from '../shared/BaseExportConfigurations'
import {
  createDefaultColumnState,
  mapColumnsToFilterDefinitions
} from '../shared/lists'
import { AccountColumnDefinitions } from './ColumnDefinitions'

export const constructInitialAccountListColumnState = partial(
  createDefaultColumnState,
  [AccountColumnDefinitions]
)

const filterDefinitions = mapColumnsToFilterDefinitions(
  AccountColumnDefinitions
)

export const accountListStore = createListsStore<IAccount>(
  'account',
  '@features/@lists/@accounts',
  {
    columnDefinitions: AccountColumnDefinitions,
    filterDefinitions
  },
  (state: AppState) => state.features.lists.account,
  BaseExportConfigurations
)

export const { reducer, sagas, actions, selectors } = accountListStore
