import { skipToken } from '@reduxjs/toolkit/dist/query'
import { uniq } from 'lodash'
import { useMemo } from 'react'
import { useGetRealizedGainLossPossibleYearsQuery } from '../../store/rdot360AnalyticsApi'

const currentYear = new Date().getFullYear()

export const useYearRangeOptions = (accounts: string[]) => {
  const skip = !accounts?.length
  const { data: possibleYears } = useGetRealizedGainLossPossibleYearsQuery(
    skip ? skipToken : { accounts }
  )
  const yearRangeOptions = useMemo(() => {
    const possibleYearsWithCurrentYear =
      possibleYears && Array.isArray(possibleYears)
        ? [...possibleYears, currentYear]
        : [currentYear]

    return uniq(possibleYearsWithCurrentYear)
      .filter((year) => typeof year === 'number')
      .sort()
      .reverse()
      .map((year) => ({
        key: year,
        text: `${year}`
      }))
  }, [possibleYears])

  return { yearRangeOptions }
}
