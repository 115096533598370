import { IPerformanceReport } from 'api/performancereport.types'
import { createListsStore } from 'features/Lists/core/store'
import {
  createDefaultColumnState,
  mapColumnsToFilterDefinitions
} from 'features/Lists/shared/lists'
import { partial } from 'lodash/fp'
import { AppState } from 'store/shared/contracts'
import { PerformanceColumnDefinitions } from '../ColumnDefinitions'

export const constructInitialPerformanceColumnState = partial(
  createDefaultColumnState,
  [PerformanceColumnDefinitions]
)

export const performanceListStore = createListsStore<IPerformanceReport>(
  'genreport',
  '@features/@lists/@performancereport',
  {
    columnDefinitions: PerformanceColumnDefinitions,
    filterDefinitions: mapColumnsToFilterDefinitions(
      PerformanceColumnDefinitions
    )
  },
  (state: AppState) => state.features.lists.performanceReport
)

export const { reducer, sagas, actions, selectors } = performanceListStore
