import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

const { actions, reducer } = createSlice({
  name: 'performanceTileCallout',
  initialState: {
    columns: [
      'category',
      'yearToDate',
      'prior12Months',
      'inceptionToDate',
      'filter'
    ]
  } as IPerformanceTileCalloutState,
  reducers: {
    show: (state) => ({
      ...state,
      showCallout: true
    }),
    hide: (state) => ({
      ...state,
      showCallout: false
    }),
    setColumns: (state, action: PayloadAction<string[]>) => ({
      ...state,
      columns: action.payload
    })
  }
})

export { reducer as performanceTileCalloutReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.modules.dashboard
    .performanceTileCallout

const getShouldShowCallout = flow(rootSelector, (x) => x.showCallout)
const getColumns = flow(rootSelector, (x) => x.columns)

export interface IPerformanceTileCalloutState {
  showCallout?: boolean
  columns: string[]
}

export const usePerformanceTileCallout = () => {
  const dispatch = useDispatch()

  const hide = useCallback(() => {
    dispatch(actions.hide())
  }, [dispatch])

  const show = useCallback(() => {
    dispatch(actions.show())
  }, [dispatch])

  const setColumns = useCallback(
    (columns: string[]) => {
      dispatch(actions.setColumns(columns))
    },
    [dispatch]
  )

  const showCallout = useSelector(getShouldShowCallout)

  const selectedColumns = useSelector(getColumns)

  const addColumn = useCallback(
    (column: string) => {
      dispatch(actions.setColumns([...selectedColumns, column]))
    },
    [dispatch, selectedColumns]
  )

  const removeColumn = useCallback(
    (column: string) => {
      dispatch(actions.setColumns(selectedColumns.filter((x) => x !== column)))
    },
    [dispatch, selectedColumns]
  )

  return {
    hide,
    show,
    setColumns,
    showCallout,
    selectedColumns,
    addColumn,
    removeColumn
  }
}
