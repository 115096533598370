import axios from 'axios'
import { call, cancelled, delay, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { getNfsProfiles, INfsProfile } from '../../../api/datahub'
import { AppState } from '../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../../store/shared/sagas'

export const accountLinkingClientSearchFetchActions = createAsyncAction(
  '@features/@accountLinking/@clientSearch/REQUEST',
  '@features/@accountLinking/@clientSearch/SUCCESS',
  '@features/@accountLinking/@clientSearch/FAILURE'
)<string | undefined, INfsProfile[], Error>()

export const accountLinkingClientSearchFetchReducer = createAsyncReducer(
  accountLinkingClientSearchFetchActions
)

const rootSelector = (state: AppState) =>
  state.features.accountLinking.clientSearchFetch

export const {
  getError: getAccountLinkingClientSearchFetchError,
  getIsLoading: getAccountLinkingClientSearchFetchLoading,
  getResult: getAccountLinkingClientSearchFetchResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof accountLinkingClientSearchFetchActions.request>
) {
  if (!action.payload || !action.payload.length) {
    yield put(accountLinkingClientSearchFetchActions.success([]))
    return
  }

  yield delay(500)

  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)

  try {
    const result = yield* call(getNfsProfiles, apiOptions, {
      top: 10,
      search: action.payload,
      searchFields: ['fullname', 'loginid', 'emailprimary'],
      filters: [`fullname ne '' and fullname ne null and role ne 'Prospect'`],
      select: [
        'fullname',
        'loginid',
        'emailprimary',
        'role',
        'wsportaluserid',
        'id',
        'profilejson',
        'repcode',
        'partyid'
      ]
    })

    if (!result || !result.value) {
      throw new Error(
        'An error occurred while fetching the account information'
      )
    }

    yield put(accountLinkingClientSearchFetchActions.success(result.value))
  } catch (e: any) {
    yield put(accountLinkingClientSearchFetchActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const accountLinkingClientSearchFetchSagas = [
  () => takeLatest(accountLinkingClientSearchFetchActions.request, onRequest)
]
