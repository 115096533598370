import { RdotAccountCategoryCode } from 'api/account.types'
import { IRdot360Account } from '../../store/rdot360Context'
import { EnhancedBalanceDetailResponseValue } from '../../store/rdot360Context/useRdot360BalancesContext'

export type ClientDashboardAccountCategory =
  | 'Rockefeller Accounts at NFS'
  | 'External Accounts'
  | 'Other Assets and Liabilities'
  | 'Rockefeller Accounts at Other Custodians'

export const rdotCategoryToClientDashboardCategoryMap: Record<
  RdotAccountCategoryCode,
  ClientDashboardAccountCategory
> = {
  '01': 'Rockefeller Accounts at NFS',
  '02': 'Rockefeller Accounts at Other Custodians',
  '03': 'External Accounts',
  '04': 'Other Assets and Liabilities'
}

export interface IAccountSelectorAccount {
  id: string
  clientDashboardCategoryCode?: RdotAccountCategoryCode
  placeholder?: boolean
  account?: IRdot360Account
  balance?: EnhancedBalanceDetailResponseValue
}
