import { lazy } from 'react'
import { combineReducers } from 'redux'
import { IModuleDefinition } from '../../../types'
import { teamsModulesReducer, teamsModulesSagas } from './modules'

const TeamsContainer = lazy(() => import('./TeamsContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/teams/*',
    private: true,
    component: TeamsContainer
  },
  name: 'Teams'
}

export default moduleDefinition

export const teamsModuleReducer = combineReducers({
  modules: teamsModulesReducer
})
export const teamsModuleSagas = [...teamsModulesSagas]
