import { createAction, ActionType } from 'typesafe-actions'
import {
  IAuditRequestByRcmSecIdApiRes,
  IGetDueDiligence,
  IUpdateDueDiligence
} from '../api/types'

export const FETCH_DUE_DILIGENCE_REQUEST = `@modules/@security/@duediligences/FETCH_DUE_DILIGENCE_REQUEST`
export const FETCH_DUE_DILIGENCE_SUCCESS = `@modules/@security/@duediligences/FETCH_DUE_DILIGENCE_SUCCESS`
export const FETCH_DUE_DILIGENCE_FAILURE = `@modules/@security/@duediligences/FETCH_DUE_DILIGENCE_FAILURE`
export const FETCH_DUE_DILIGENCE_CLEAR = `@modules/@security/@duediligences/FETCH_DUE_DILIGENCE_CLEAR`

export const dueDiligenceFetchActions = {
  request: createAction(FETCH_DUE_DILIGENCE_REQUEST)<string | undefined>(),
  success: createAction(FETCH_DUE_DILIGENCE_SUCCESS)<IGetDueDiligence[]>(),
  failure: createAction(FETCH_DUE_DILIGENCE_FAILURE)<Error>(),
  clear: createAction(FETCH_DUE_DILIGENCE_CLEAR)()
}
export const UPDATE_DUE_DILIGENCE_REQUEST = `@modules/@security/@duediligences/UPDATE_DUE_DILIGENCE_REQUEST`
export const UPDATE_DUE_DILIGENCE_SUCCESS = `@modules/@security/@duediligences/UPDATE_DUE_DILIGENCE_SUCCESS`
export const UPDATE_DUE_DILIGENCE_FAILURE = `@modules/@security/@duediligences/UPDATE_DUE_DILIGENCE_FAILURE`
export const UPDATE_DUE_DILIGENCE_CLEAR = `@modules/@security/@duediligences/UPDATE_DUE_DILIGENCE_CLEAR`

export const updateDueDiligenceActions = {
  request: createAction(UPDATE_DUE_DILIGENCE_REQUEST)<IUpdateDueDiligence>(),
  success: createAction(UPDATE_DUE_DILIGENCE_SUCCESS)<any>(),
  failure: createAction(UPDATE_DUE_DILIGENCE_FAILURE)<Error>(),
  clear: createAction(UPDATE_DUE_DILIGENCE_CLEAR)()
}

export const FETCH_APPROVAL_STATUS_REQUEST = `@modules/@security/@duediligences/FETCH_APPROVAL_STATUS_REQUEST`
export const FETCH_APPROVAL_STATUS_SUCCESS = `@modules/@security/@duediligences/FETCH_APPROVAL_STATUS_SUCCESS`
export const FETCH_APPROVAL_STATUS_FAILURE = `@modules/@security/@duediligences/FETCH_APPROVAL_STATUS_FAILURE`
export const FETCH_APPROVAL_STATUS_CLEAR = `@modules/@security/@duediligences/FETCH_APPROVAL_STATUS_CLEAR`

export const fetchApprovalStatusActions = {
  request: createAction(FETCH_APPROVAL_STATUS_REQUEST)<any>(),
  success: createAction(FETCH_APPROVAL_STATUS_SUCCESS)<any>(),
  failure: createAction(FETCH_APPROVAL_STATUS_FAILURE)<Error>(),
  clear: createAction(FETCH_APPROVAL_STATUS_CLEAR)()
}
export const FETCH_AUDIT_REQUEST_REQUEST = `@modules/@security/@duediligences/FETCH_AUDIT_REQUEST_REQUEST`
export const FETCH_AUDIT_REQUEST_SUCCESS = `@modules/@security/@duediligences/FETCH_AUDIT_REQUEST_SUCCESS`
export const FETCH_AUDIT_REQUEST_FAILURE = `@modules/@security/@duediligences/FETCH_AUDIT_REQUEST_FAILURE`
export const FETCH_AUDIT_REQUEST_CLEAR = `@modules/@security/@duediligences/FETCH_AUDIT_REQUEST_CLEAR`

export const getAuditRequestActions = {
  request: createAction(FETCH_AUDIT_REQUEST_REQUEST)<string>(),
  success: createAction(
    FETCH_AUDIT_REQUEST_SUCCESS
  )<IAuditRequestByRcmSecIdApiRes>(),
  failure: createAction(FETCH_AUDIT_REQUEST_FAILURE)<Error>(),
  clear: createAction(FETCH_AUDIT_REQUEST_CLEAR)()
}

export type GetDueDiligenceActionType = ActionType<
  typeof dueDiligenceFetchActions
>
export type UpdateDueDiligenceActionType = ActionType<
  typeof updateDueDiligenceActions
>
export type FetchApprovalStatusActionType = ActionType<
  typeof fetchApprovalStatusActions
>
export type GetAuditRequestActionsType = ActionType<
  typeof getAuditRequestActions
>

export type DueDiligencesActionsTypes =
  | GetDueDiligenceActionType
  | UpdateDueDiligenceActionType
  | FetchApprovalStatusActionType
  | GetAuditRequestActionsType
