import { getRockefellerApiOptions } from 'store/shared'
import { call, put, takeLatest } from 'typed-redux-saga'
import {
  fetchQBOReportActivity,
  fetchQBOReportHoldings,
  fetchQBOReportJournalActivity,
  fetchQBOReportMTM
} from '../api/QBOReportServices'
// import { getAccountsList } from '../Utilities'
import {
  fetchQBOReportActivityActions,
  fetchQBOReportAllActions,
  fetchQBOReportHoldingsActions,
  fetchQBOReportJournalActivityActions,
  fetchQBOReportJournalMTMActions,
  fetchQBOReportMTMActions
} from './actions'
import { IQBOReportActivity, IQBOReportJournal } from './types'

function* handleFetchAllQBOReport(
  action: ReturnType<typeof fetchQBOReportAllActions.request>
) {
  yield put(fetchQBOReportActivityActions.request(action.payload))
  yield put(fetchQBOReportHoldingsActions.request(action.payload))
  yield put(fetchQBOReportJournalActivityActions.request(action.payload))
  yield put(fetchQBOReportMTMActions.request(action.payload))
}
function* handleFetchQBOReportActivity(
  action: ReturnType<typeof fetchQBOReportActivityActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: IQBOReportActivity[] = yield call(() =>
      fetchQBOReportActivity(action.payload, options)
    )
    if (data) {
      yield put(fetchQBOReportActivityActions.success(data))
    } else {
      yield put(
        fetchQBOReportActivityActions.failure(new Error('No data found'))
      )
    }
  } catch (e: any) {
    yield put(fetchQBOReportActivityActions.failure(e))
  }
}

function* handleFetchQBOReportHoldings(
  action: ReturnType<typeof fetchQBOReportHoldingsActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: IQBOReportActivity[] = yield* call(() =>
      fetchQBOReportHoldings(action.payload, options)
    )
    if (data) {
      yield put(fetchQBOReportHoldingsActions.success(data))
    } else {
      yield put(
        fetchQBOReportHoldingsActions.failure(new Error('No data found'))
      )
    }
  } catch (e: any) {
    yield put(fetchQBOReportHoldingsActions.failure(e))
  }
}

function* handleFetchQBOReportMTM(
  action: ReturnType<typeof fetchQBOReportMTMActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: IQBOReportActivity[] = yield call(() =>
      fetchQBOReportMTM(action.payload, options)
    )
    if (data) {
      yield put(fetchQBOReportMTMActions.success(data))
    }
  } catch (e: any) {
    yield put(fetchQBOReportMTMActions.failure(e))
  }
}

function* handleFetchQBOReportJournalsActivity(
  action: ReturnType<typeof fetchQBOReportJournalActivityActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    let data: IQBOReportJournal[] = yield call(() =>
      fetchQBOReportJournalActivity(action.payload, options)
    )
    if (data) {
      data = data.map((x) => {
        return {
          ...x,
          Credits: x.Credits ? parseFloat(x.Credits?.toString()) : undefined,
          Debits: x.Debits ? parseFloat(x.Debits?.toString()) : undefined
        }
      })
      const journalMTM = data.filter((x) => x.JournalType === 'MTM')
      const journalActivity = data.filter((x) => x.JournalType === 'ACTIVITY')
      yield put(fetchQBOReportJournalActivityActions.success(journalActivity))
      yield put(fetchQBOReportJournalMTMActions.success(journalMTM))
    }
  } catch (e: any) {
    yield put(fetchQBOReportJournalActivityActions.failure(e))
    yield put(fetchQBOReportJournalMTMActions.failure(e))
  }
}

export const sagas = [
  () =>
    takeLatest(
      fetchQBOReportActivityActions.request,
      handleFetchQBOReportActivity
    ),
  () =>
    takeLatest(
      fetchQBOReportHoldingsActions.request,
      handleFetchQBOReportHoldings
    ),
  () => takeLatest(fetchQBOReportMTMActions.request, handleFetchQBOReportMTM),
  () =>
    takeLatest(
      fetchQBOReportJournalActivityActions.request,
      handleFetchQBOReportJournalsActivity
    ),
  () => takeLatest(fetchQBOReportAllActions.request, handleFetchAllQBOReport)
]
