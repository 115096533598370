import { ActionType, createAction } from 'typesafe-actions'
import { IFailedPayment, IRetryFailedPaymentRequest } from './types'

export const FETCH_FAILED_PAYMENTS =
  '@modules/@payments/@paymentforaction/FETCH_FAILED_PAYMENTS'
export const FETCH_FAILED_PAYMENTS_SUCCESS =
  '@modules/@payments/@paymentforaction/FETCH_FAILED_PAYMENTS_SUCCESS'
export const FETCH_FAILED_PAYMENTS_FAILURE =
  '@modules/@payments/@paymentforaction/FETCH_FAILED_PAYMENTS_FAILURE'

export const RETRY_FAILED_PAYMENTS_REQUEST = `@modules/@payments/@paymentforaction/RETRY_FAILED_PAYMENTS_REQUEST`
export const RETRY_FAILED_PAYMENTS_SUCCESS = `@modules/@payments/@paymentforaction/RETRY_FAILED_PAYMENTS_SUCCESS`
export const RETRY_FAILED_PAYMENTS_FAILURE = `@modules/@payments/@paymentforaction/RETRY_FAILED_PAYMENTS_FAILURE`

export const failedPaymentsDataActions = {
  request: createAction(FETCH_FAILED_PAYMENTS)(),
  success: createAction(FETCH_FAILED_PAYMENTS_SUCCESS)<IFailedPayment[]>(),
  failure: createAction(FETCH_FAILED_PAYMENTS_FAILURE)<Error>()
}

export const retryFailedPaymentsActions = {
  request: createAction(RETRY_FAILED_PAYMENTS_REQUEST)<
    IRetryFailedPaymentRequest | undefined
  >(),
  success: createAction(RETRY_FAILED_PAYMENTS_SUCCESS)<IFailedPayment>(),
  failure: createAction(RETRY_FAILED_PAYMENTS_FAILURE)<Error>()
}

export type FailedPaymentsDataActionTypes = ActionType<
  typeof failedPaymentsDataActions
>

export type RetryFailedPaymentsActionTypes = ActionType<
  typeof retryFailedPaymentsActions
>

export type FaiedPaymentActionType =
  | FailedPaymentsDataActionTypes
  | RetryFailedPaymentsActionTypes
