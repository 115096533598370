import { b64toBlob } from 'modules/Products/features/store/utility'
import {
  call,
  put,
  select,
  takeEvery,
  delay
  // tslint:disable-next-line: no-submodule-imports
} from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../store/shared/sagas'
import { getRdotUsername } from '../../../../../store/user/selectors'
import { getActiveClient } from '../../../../Payments/features/Dashboard/store/selectors'
import {
  GenerateReportService,
  fetchReportListService
} from '../../../api/index'
import {
  deleteUploadReportService,
  fetchClientListService,
  fetchUploadReportService,
  RefreshReportService,
  ShareReportService,
  uploadReport,
  ViewReportService,
  viewUploadReportService
} from '../../../api/reportService'
import {
  deleteUploadReportActions,
  fetchUploadReportActions,
  generateReportsActions,
  getClientListActions,
  getReportListActions,
  refreshReportActions,
  shareReportActions,
  uploadReportActions,
  viewReportActions,
  viewUploadReportActions
} from './actions'
import { getReportList } from './selectors'
import {
  IReportInfo,
  IShareReportResponse,
  IUploadReportList,
  IUploadReportRequest,
  IUploadReportResponse
} from './types'

function* handleGenerateReport(
  action: ReturnType<typeof generateReportsActions.request>
) {
  try {
    yield* delay(500)
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(GenerateReportService, options, action.payload)
    yield put(generateReportsActions.success(data))

    //Refresh grid row here
    const activeClient = yield* select(getActiveClient)
    if (activeClient) {
      const reportlist = yield* call(() =>
        fetchReportListService(options, {
          client: activeClient.dynamicsLegalEntity,
          reportType: 'Powerbi'
        })
      )

      const updatedRow = reportlist?.filter(
        (x) => x.reportKey === action.payload.reportKey
      )?.[0]

      const existingReportList = yield* select(getReportList)

      if (updatedRow && existingReportList) {
        //get existing report list
        const copyShallowLocal = existingReportList
          ? [...existingReportList]
          : []
        const matchedItemIdx = copyShallowLocal?.findIndex(
          (x) => x.reportKey === action.payload.reportKey
        )
        const newItem: IReportInfo = {
          ...updatedRow,
          loading: false,
          error: undefined
        }
        copyShallowLocal.splice(matchedItemIdx, 1, newItem)
        yield put(getReportListActions.success(copyShallowLocal))
      }
    }
  } catch (e: any) {
    yield put(
      generateReportsActions.failure({ error: e, request: action.payload })
    )
  }
}
function* handleRefreshReport(
  action: ReturnType<typeof refreshReportActions.request>
) {
  try {
    yield* delay(500)
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(RefreshReportService, options, action.payload)
    yield put(refreshReportActions.success(data))

    //Refresh grid row here
    const activeClient = yield* select(getActiveClient)
    if (activeClient) {
      const reportlist = yield* call(() =>
        fetchReportListService(options, {
          client: activeClient.dynamicsLegalEntity,
          reportType: 'Powerbi'
        })
      )

      const updatedRow = reportlist?.filter(
        (x) => x.reportKey === action.payload.reportKey
      )?.[0]

      const existingReportList = yield* select(getReportList)

      if (updatedRow && existingReportList) {
        //get existing report list
        const copyShallowLocal = existingReportList
          ? [...existingReportList]
          : []
        const matchedItemIdx = copyShallowLocal?.findIndex(
          (x) => x.reportKey === action.payload.reportKey
        )
        const newItem: IReportInfo = {
          ...updatedRow,
          loading: false,
          error: undefined,
          loadingMsg: undefined
        }
        copyShallowLocal.splice(matchedItemIdx, 1, newItem)
        yield put(getReportListActions.success(copyShallowLocal))
      }
    }
  } catch (e: any) {
    yield put(
      refreshReportActions.failure({ error: e, request: action.payload })
    )
  }
}
function* handleViewReport(
  action: ReturnType<typeof viewReportActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(ViewReportService, options, action.payload)
    yield put(viewReportActions.success(data as string))
  } catch (e: any) {
    yield put(viewReportActions.failure(e))
  }
}
function* handleShareReport(
  action: ReturnType<typeof shareReportActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: IShareReportResponse = yield* call(
      ShareReportService,
      options,
      action.payload
    )
    yield put(shareReportActions.success(data))
  } catch (e: any) {
    yield put(shareReportActions.failure(e))
  }
}
function* handleGetReportList(
  action: ReturnType<typeof getReportListActions.request>
) {
  // Call fetch report list info api here....

  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchReportListService(options, action.payload)
    )
    yield put(getReportListActions.success(data))
  } catch (e: any) {
    yield put(getReportListActions.failure(e))
  }
}

function* handleGetClientList() {
  // Call fetch report list info api here....

  try {
    const options = yield* call(getRockefellerApiOptions)
    const email = yield* select(getRdotUsername)
    const data = yield* call(() =>
      fetchClientListService(options, email ? email : '')
    )
    yield put(getClientListActions.success(data))
  } catch (e: any) {
    yield put(getClientListActions.failure(e))
  }
}

function* handleUploadReport(
  action: ReturnType<typeof uploadReportActions.request>
) {
  const {
    file,
    onUploadSuccess,
    onUploadFail,
    onUploadProgress,
    asofdate,
    sharewithuser,
    reportkey
  } = action.payload as IUploadReportRequest
  try {
    const options = yield* call(getRockefellerApiOptions)
    const email = yield* select(getRdotUsername)

    if (!email) {
      throw new Error('Invalid State: email is undefined')
    }
    const data: IUploadReportResponse = yield call(() =>
      uploadReport(
        options,
        reportkey,
        file,
        asofdate,
        sharewithuser,
        onUploadProgress
      )
    )

    onUploadSuccess(file)
    yield put(
      fetchUploadReportActions.request({
        client: action.payload?.sharewithuser || ''
      })
    )
    yield put(uploadReportActions.success(data))
  } catch (e: any) {
    onUploadFail(file)
    yield put(uploadReportActions.failure(e))
  }
}

function* handleFetchUploadReport(
  action: ReturnType<typeof fetchUploadReportActions.request>
) {
  // Call fetch report list info api here....

  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: IUploadReportList[] = yield* call(() =>
      fetchUploadReportService(options, action.payload.client)
    )
    yield put(fetchUploadReportActions.success(data))
  } catch (e: any) {
    yield put(fetchUploadReportActions.failure(e))
  }
}

function* handleViewUploadReport(
  action: ReturnType<typeof viewUploadReportActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: any = yield* call(() =>
      viewUploadReportService(action.payload, options)
    )
    if (data.status !== 'Failed') {
      yield put(viewUploadReportActions.success())
      const blob = b64toBlob(data.filecontents)
      const fileURL = URL.createObjectURL(blob)
      window.open(fileURL, 'statementWindow')
    } else {
      yield put(viewUploadReportActions.failure(data?.message))
    }
  } catch (e: any) {
    yield put(viewUploadReportActions.failure(e))
  }
}

function* handleDeleteUploadReport(
  action: ReturnType<typeof deleteUploadReportActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)

    yield* call(() => deleteUploadReportService(action.payload, options))
    yield put(deleteUploadReportActions.success())
    yield put(
      fetchUploadReportActions.request({
        client: action.payload.client
      })
    )
  } catch (e: any) {
    yield put(deleteUploadReportActions.failure(e))
  }
}

export const sagas = [
  () => takeEvery(getReportListActions.request, handleGetReportList),
  () => takeEvery(generateReportsActions.request, handleGenerateReport),
  () => takeEvery(refreshReportActions.request, handleRefreshReport),
  () => takeEvery(viewReportActions.request, handleViewReport),
  () => takeEvery(getClientListActions.request, handleGetClientList),
  () => takeEvery(shareReportActions.request, handleShareReport),
  () => takeEvery(uploadReportActions.request, handleUploadReport),
  () => takeEvery(fetchUploadReportActions.request, handleFetchUploadReport),
  () => takeEvery(viewUploadReportActions.request, handleViewUploadReport),
  () => takeEvery(deleteUploadReportActions.request, handleDeleteUploadReport)
]
