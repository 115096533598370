import { call, put, select, takeEvery } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../../../store/shared/sagas'
import { getActiveTrust } from '../../TrustInfo/store/selectors'
import { fetchAccountsList } from '../api/accountsService'
import { accountsDataActions } from './actions'

function* handleGetAccountsList() {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const activeTrust = yield* select(getActiveTrust)
    const data = yield* call(() =>
      fetchAccountsList(activeTrust?.accountid || '', options)
    )

    yield put(accountsDataActions.success(data || []))
  } catch (e) {
    yield put(accountsDataActions.failure(e))
  }
}

export const sagas = [
  () => takeEvery(accountsDataActions.request, handleGetAccountsList)
]
