import { createReducer } from 'typesafe-actions'
import { IClient } from '../../../../../../../api/client.types'
import { ITrustDashboardInfo } from '../../DashBoard/store/types'
import {
  trustInfoDataActions,
  TrustInfoActionType,
  setActiveTrustAction,
  trustFinancialDataActions
} from './actions'
import { ITrustInfo } from './types'

export interface ITrustInfoState {
  trustInfoData?: ITrustInfo
  isLoading: boolean
  error?: Error
  activeTrust?: ITrustDashboardInfo
  trustFinancialInfoState?: {
    isLoading: boolean
    error?: Error
    financeData?: IClient
  }
}

const initialState: ITrustInfoState = {
  isLoading: false
}

export const trustInfoReducer = createReducer<
  ITrustInfoState,
  TrustInfoActionType
>(initialState)
  .handleAction(trustInfoDataActions.request, (state) => ({
    ...state,
    isLoading: true,
    error: undefined,
    trustInfoData: undefined,
    trustFinancialInfoState: undefined
  }))
  .handleAction(trustInfoDataActions.success, (state, action) => ({
    ...state,
    trustInfoData: action.payload,
    isLoading: false,
    error: undefined
  }))
  .handleAction(trustInfoDataActions.failure, (state, action) => ({
    ...state,
    error: action.payload,
    trustInfoData: undefined,
    isLoading: false
  }))
  .handleAction(setActiveTrustAction.setActiveTrust, (state, action) => ({
    ...state,
    activeTrust: action.payload
  }))
  .handleAction(trustFinancialDataActions.request, (state) => ({
    ...state,
    trustFinancialInfoState: {
      isLoading: true,
      error: undefined,
      financeData: undefined
    }
  }))
  .handleAction(trustFinancialDataActions.success, (state, action) => ({
    ...state,
    trustFinancialInfoState: {
      isLoading: false,
      error: undefined,
      financeData: action.payload
    }
  }))
  .handleAction(trustFinancialDataActions.failure, (state, action) => ({
    ...state,
    trustFinancialInfoState: {
      isLoading: false,
      error: action.payload,
      financeData: undefined
    }
  }))
