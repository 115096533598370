import axios from 'axios'
import { IDragDropFile } from 'shared/components/DragDrop'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import {
  IRelatedParty,
  IStatement,
  IStatementRequest,
  IUploadReportResponse
} from '../store/types'

export const fetchStatementListService = (
  options: IApiOptions,
  request: IStatementRequest
) => {
  const url = `${options.apiRoot}/documentsapi/api/Reports/GetReportsV2?reportType=Trust&startDate=${request.startDate}&endDate=${request.endDate}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
    contextjson: `${request.key}`,
    profilejson: `${request.key}`,
    Version: `${request.version ?? 'v2'}`
  }
  return axios
    .get<IStatement[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
export const setContextService = (
  options: IApiOptions,
  key: string,
  profileId: string
) => {
  const url = `${options.apiRoot}/profileapi/odata/context?key=${key}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  const context = [{ profile: { __id: profileId, profileid: profileId } }]
  return axios
    .post(url, context, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const downloadStatementService = (
  options: IApiOptions,

  docId: string
) => {
  const url = `${options.apiRoot}/documentsapi/api/Reports/DownloadReport?fromrdot=true&storagedocid=${docId}`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const uploadReportService = (
  options: IApiOptions,
  reportkey: string,
  file: IDragDropFile,
  asofdate?: string,
  sharewithuser?: string,
  onUploadProgress?: (
    progressEvent: ProgressEvent<EventTarget>,
    file: IDragDropFile
  ) => void
) => {
  const url = `${options.apiRoot}/documentsapi/api/Reports/UploadShareReportWithUser`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  const data = new FormData()
  data.append('filecontents', file.file)
  data.append('asofdate', asofdate ?? '')
  data.append('sharewithuser', sharewithuser ?? '')
  data.append('reportkey', reportkey)

  return axios
    .post<IUploadReportResponse>(url, data, {
      headers,
      cancelToken: options.cancelToken,
      onUploadProgress: onUploadProgress
        ? (progressEvent) => {
            onUploadProgress(progressEvent, file)
          }
        : undefined
    })
    .then((res) => res.data)
}

export const fetchRelatedLoginsService = (
  options: IApiOptions,
  partyId: string
) => {
  const url = `${options.apiRoot}/documentsapi/api/party/GetPartyIDLogins?partyid=${partyId}`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
    accept: 'application/json'
  }
  return axios
    .get<IRelatedParty[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
