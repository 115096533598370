import { combineReducers } from 'redux'
import {
  householdFeePanelReducer,
  householdFeePanelSagas
} from './householdFeePanel'

export const householdFeeFeatureReducer = combineReducers({
  householdFeePanel: householdFeePanelReducer
})

export const householdFeeFeatureSagas = [...householdFeePanelSagas]
