import { DetailsListLayoutMode } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { OdataList } from 'features/OdataList/containers/OdataList'
import { constants } from 'modules/Advisory/shared/theme'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ColumnEditPanel, SecondaryHeader } from '../../features'
import {
  getInsuranceExcelExportError,
  getInsuranceExcelExportIsLoading
} from '../../shared/export/rootSelectors'
import {
  useAdvisorIdFilter,
  usePolicyStatusFilter,
  useContractTypeFilter
} from '../../shared/hooks'
import { AnnuitiesAndInsuranceCell } from '../Annuities/Annuities'
import { insuranceExcelExportActions } from './excelExportActionsAndSagas'
import {
  actions as insuranceActions,
  selectors as insuranceSelectors
} from './store'

export const Insurance: React.FC = () => {
  const { facetActions, uiActions } = insuranceActions
  const { facetSelectors, uiSelectors } = insuranceSelectors

  const [isPanelOpen, { setTrue: openPanel, setFalse: closePanel }] =
    useBoolean(false)

  const dispatch = useDispatch()

  const isExportLoading = useSelector(getInsuranceExcelExportIsLoading)

  const isExportError = useSelector(getInsuranceExcelExportError)

  const exportToExcel = useCallback(() => {
    dispatch(insuranceExcelExportActions.request())
  }, [dispatch])

  const secondaryHeader = useMemo(
    () => (
      <SecondaryHeader
        exportToExcel={exportToExcel}
        isExportError={isExportError}
        isExportLoading={isExportLoading}
        openPanel={openPanel}
      />
    ),
    [exportToExcel, isExportError, isExportLoading, openPanel]
  )

  useAdvisorIdFilter(uiActions, uiSelectors)

  usePolicyStatusFilter(uiActions, uiSelectors, [
    'In Force',
    'Paid Up',
    'Lapse Pending'
  ])

  useContractTypeFilter({
    uiActions,
    contractType: 'Insurance',
    hidden: true
  })

  return (
    <>
      <h2 css={{ marginTop: '0' }}>Insurance</h2>
      <OdataList
        actions={uiActions}
        selectors={uiSelectors}
        facetActions={facetActions}
        facetSelectors={facetSelectors}
        onRenderCell={AnnuitiesAndInsuranceCell}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        secondaryHeader={secondaryHeader}
        stickyHeaderOffset={constants.headerHeight}
      />
      {isPanelOpen && (
        <ColumnEditPanel
          isPanelOpen={isPanelOpen}
          closePanel={closePanel}
          actions={uiActions}
          selectors={uiSelectors}
        />
      )}
    </>
  )
}
