import { IModuleDefinition } from 'modules/types'
import { combineReducers } from 'redux'
import { ActivityContainer } from './ActivityContainer'
import { activityDetailUiStateReducer } from './activityDetailsUiState'

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/activity/*',
    private: true,
    component: ActivityContainer
  },
  name: 'Activity'
}

export const rdot360ActivitydReducer = combineReducers({
  activityDetailUiState: activityDetailUiStateReducer
})

export default moduleDefinition
