import { IModuleDefinition } from 'modules/types'
import { memo } from 'react'
import { combineReducers } from 'redux'
import { CollaborationContainer } from './CollaborationContainer'
import { collaborationUiStateReducer } from './CollaborationUIState'

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/collaboration/*',
    private: true,
    component: memo(CollaborationContainer)
  },
  name: 'Documents'
}

export const rdot360CollaborationReducer = combineReducers({
  collaborationUiState: collaborationUiStateReducer
})

export default moduleDefinition
