import { InvestmentsChartContainer } from './features/InvestmentsChart/InvestmentsChartContainer'
import { InvestmentsMenu } from './features/InvestmentsMenu'
import { InvestmentsTable } from './features/InvestmentsTable'

export const InvestmentsContainer: React.FC = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '15px' }}>
      <InvestmentsMenu />
      <InvestmentsChartContainer />
      <InvestmentsTable />
    </div>
  )
}
