import { IconButton, Link, Modal, Spinner, Text } from '@fluentui/react'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IOrder } from '../../../api/order.types'
import { TextCell } from '../../../features/Lists/core/components/TextCell'
import {
  getIsTradeDetailOrderLoading,
  TradeDetailContainer,
  tradeDetailDataActions
} from '../../../features/TradeDetail'

export interface IRefNoCellContainerProps {
  order: IOrder
}
export const RefNoCellContainer: React.FC<IRefNoCellContainerProps> = ({
  order
}) => {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const onDismissModal = useCallback(() => {
    setShowModal(false)
  }, [])
  const onLinkClicked = useCallback(() => {
    const { id } = order
    if (!id) {
      return
    }
    setShowModal(true)

    dispatch(tradeDetailDataActions.request(id))
  }, [dispatch, order])

  const isTradeDetailOrderLoading = useSelector(getIsTradeDetailOrderLoading)

  if (!order.tradecount) {
    return <TextCell>{order.orderRefNo}</TextCell>
  }

  return (
    <>
      <Link
        onClick={onLinkClicked}
        styles={{ root: { minWidth: 0, display: 'block' } }}
      >
        <Text nowrap={true} block={true} title={order.orderRefNo}>
          {order.orderRefNo}
        </Text>
      </Link>
      {showModal && (
        <Modal
          isOpen={showModal}
          onDismiss={onDismissModal}
          styles={{ main: { display: 'flex' } }}
        >
          <IconButton
            styles={{ root: { position: 'absolute', right: 20, top: 20 } }}
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel="Close popup modal"
            onClick={onDismissModal}
          />
          {isTradeDetailOrderLoading ? (
            <Spinner
              styles={{ root: { height: '100%', padding: '20px' } }}
              label={`Loading Trade Detail for Order Ref #${order.orderRefNo}...`}
            />
          ) : (
            <TradeDetailContainer />
          )}
        </Modal>
      )}
    </>
  )
}
