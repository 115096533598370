import {
  call,
  put,
  takeEvery,
  takeLatest
  // tslint:disable-next-line: no-submodule-imports
} from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../store/shared/sagas'
import { checkReprintScreenHandler, fetchCheckList } from '../api/checksService'
import { checkReprintScreenActions, checksDataActions } from './actions'

function* handleFetchCheckList(
  action: ReturnType<typeof checksDataActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)

    const data = yield* call(() => fetchCheckList(action.payload, options))
    yield put(checksDataActions.success(data))
  } catch (e: any) {
    yield put(checksDataActions.failure(e))
  }
}

function* handleCheckReprintScreenAction(
  action: ReturnType<typeof checkReprintScreenActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)

    const data = yield* call(() =>
      checkReprintScreenHandler(action.payload, options)
    )
    yield put(checkReprintScreenActions.success(data))
  } catch (e: any) {
    yield put(checkReprintScreenActions.failure(e))
  }
}

export const sagas = [
  () => takeLatest(checksDataActions.request, handleFetchCheckList),
  () =>
    takeEvery(checkReprintScreenActions.request, handleCheckReprintScreenAction)
]
