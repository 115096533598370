import { lazy } from 'react'
import { combineReducers } from 'redux'
import { IModuleDefinition } from '../../../../../types'
import {
  accountLinkingRequestsFeaturesReducer,
  accountLinkingRequestsFeaturesSagas
} from './features'

const AccountLinkingRequestsContainer = lazy(
  () => import('./AccountLinkingRequestsContainer')
)

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/AccountLinkingRequests',
    private: true,
    component: AccountLinkingRequestsContainer
  },
  name: 'Account Linking Requests'
}

export default moduleDefinition

export const accountLinkingRequestsModuleReducer = combineReducers({
  features: accountLinkingRequestsFeaturesReducer
})

export const accountLinkingRequestsModuleSagas = [
  ...accountLinkingRequestsFeaturesSagas
]
