import {
  IAssetAllocationItem,
  IProductAllocationItem,
  ISectorAllocationItem
} from 'api/common.types'
import { uniq, sum, groupBy } from 'lodash'
import { isNotNullOrEmpty } from 'shared/guards'
import {
  IExportConfiguration,
  IExportDefinition
} from '../core/contracts/IExportDefinition'
import { CommonColumnIdsEnum } from './CommonColumnIdsEnum'

export interface IWithAllocation {
  sectorAllocation?: ISectorAllocationItem[]
  productAllocation?: IProductAllocationItem[]
  assetAllocationLvl1?: IAssetAllocationItem[]
}

const defaultSector = 'Unassigned'

export const BaseExportConfigurations: Record<
  string,
  IExportConfiguration<IWithAllocation>
> = {
  [CommonColumnIdsEnum.sectorAllocation]: {
    columnId: CommonColumnIdsEnum.sectorAllocation,
    getExportColumns: (data) => {
      const types = uniq(
        data.flatMap((x) =>
          x.sectorAllocation?.map((x) => x.Sector || defaultSector)
        )
      ).filter(isNotNullOrEmpty)

      return types.map(
        (type): IExportDefinition<IWithAllocation> => ({
          columnId: `sector_${type}`,
          columnName: `Sector Allocation - ${type}`,
          getValue: (item) => {
            const sectors = groupBy(
              item.sectorAllocation,
              ({ Sector }) => Sector || defaultSector
            )
            return sum(sectors[type]?.map((x) => x.value || 0))
          }
        })
      )
    }
  },
  [CommonColumnIdsEnum.productAllocation]: {
    columnId: CommonColumnIdsEnum.productAllocation,
    getExportColumns: (data) => {
      const types = uniq(
        data.flatMap((x) => x.productAllocation?.map((x) => x.productType))
      ).filter(isNotNullOrEmpty)

      return types.map(
        (type): IExportDefinition<IWithAllocation> => ({
          columnId: `product_${type}`,
          columnName: `Product Allocation - ${type}`,
          getValue: (item) => {
            return sum(
              item.productAllocation
                ?.filter((x) => x.productType === type)
                .map((x) => x.value || 0)
            )
          }
        })
      )
    }
  },
  [CommonColumnIdsEnum.assetAllocation]: {
    columnId: CommonColumnIdsEnum.assetAllocation,
    getExportColumns: (data) => {
      const types = uniq(
        data.flatMap((x) => x.assetAllocationLvl1?.map((x) => x.assetType))
      ).filter(isNotNullOrEmpty)

      return types.map(
        (type): IExportDefinition<IWithAllocation> => ({
          columnId: `asset_${type}`,
          columnName: `Asset Allocation - ${type}`,
          getValue: (item) => {
            return sum(
              item.assetAllocationLvl1
                ?.filter((x) => x.assetType === type)
                .map((x) => x.value || 0)
            )
          }
        })
      )
    }
  }
}
