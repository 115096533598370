import { DetailsListLayoutMode, Stack, Text } from '@fluentui/react'
import { createListsComponents } from 'features/Lists/core/containers/service'
import { PositionCell } from 'features/Lists/positions/PositionCell'
import { ListCommandBarWithPreferences } from 'modules/Advisory/modules/AI/components/ListCommandBarWithPreferences'
import { AiPositionListTabNamesEnum } from 'modules/Advisory/modules/AI/modules/Dashboard/features/AIPositionList/types'
import { constants } from 'modules/Advisory/shared/theme'
import { HorizontalScrollContainer } from 'shared/components/HorizontalScrollContainer'
import { AiDashboardHouseholdFilter } from '../AiDashboardFilters/AiDashboardHouseholdFilter'
import { useInitializeAiPositionList } from './preferences'
import { useSyncAiDashboardWithDomainContext } from './preferences/useAdvisorIdFilter'
import { useSyncAiDashboardListWithHousehold } from './preferences/useHouseholdFilter'
import { actions, selectors } from './store'

const AIPositionListComponents = createListsComponents(
  actions,
  selectors,
  PositionCell
)

export const AIPositionList: React.FC<{
  selectedTab: AiPositionListTabNamesEnum
}> = ({ selectedTab }) => {
  useSyncAiDashboardListWithHousehold(selectedTab)
  useSyncAiDashboardWithDomainContext(selectedTab)
  useInitializeAiPositionList(selectedTab)

  return (
    <Stack tokens={{ childrenGap: 5 }}>
      <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
        <AIPositionListComponents.ListsSearch />
        <AiDashboardHouseholdFilter />
        <Stack.Item grow={1}>
          <AIPositionListComponents.ListsFilterStatusList />
        </Stack.Item>

        <Text variant="medium" styles={{ root: { fontWeight: 'bold' } }}>
          <AIPositionListComponents.ListsResultsCount /> Results
        </Text>
      </Stack>
      <Stack.Item>
        <Stack>
          <ListCommandBarWithPreferences
            listType={selectedTab}
            ListsCommandBar={AIPositionListComponents.ListsCommandBar}
          />
          <AIPositionListComponents.ListsProgress />
          <HorizontalScrollContainer>
            <AIPositionListComponents.ListsInfiniteScrollDataTable
              layoutMode={DetailsListLayoutMode.fixedColumns}
              stickyHeaderOffset={constants.headerHeight}
            />
          </HorizontalScrollContainer>
        </Stack>
      </Stack.Item>
    </Stack>
  )
}
