import { useDomainStore } from 'features/Domain/store/domain'
import { useMemo } from 'react'
import { useUpdateEffect } from 'react-use'
import { CreditEventColumnIds } from '../ColumnDefinitions'
import { createCreditEventListFilter } from '../store'
import { CreditEventListTypesEnum } from '../types'
import { useRevenueDashboardList } from './useList'

const advisorIdFilterName = CreditEventColumnIds.repId

export const useAdvisorIdFilter = () => {
  const { selectedAccountRepIds, isFullDomainSelected } = useDomainStore()

  const advisorIdFilter = useMemo(() => {
    if (isFullDomainSelected || !selectedAccountRepIds?.length) {
      return undefined
    }

    return createCreditEventListFilter(advisorIdFilterName, {
      type: 'facet',
      values: selectedAccountRepIds,
      hidden: true
    })
  }, [isFullDomainSelected, selectedAccountRepIds])

  return { advisorIdFilter }
}

export const useSyncRevenueDashboardWithDomainContext = (
  listType: CreditEventListTypesEnum
) => {
  const { setFilters, removeFilters } = useRevenueDashboardList(listType)
  const { advisorIdFilter } = useAdvisorIdFilter()

  useUpdateEffect(() => {
    advisorIdFilter
      ? setFilters?.({ [advisorIdFilterName]: advisorIdFilter })
      : removeFilters?.([advisorIdFilterName])
  }, [advisorIdFilter, removeFilters, setFilters])
}
