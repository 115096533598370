import { Link } from '@fluentui/react'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  getAttachment,
  getNoteAttachment,
  IAttachment
} from '../../api/dynamics'
import { base64toBlob } from '../../modules/Reports/features/Dashboard/Utilities'
import { IApiOptions } from '../../shared/contracts/IApiOptions'
import { saveBlobAsFile } from '../../shared/downloads'
import { tryAcquireAccessToken } from '../../shared/services/auth'
import { getDynamicsCrmApiConfig } from '../../store/system'

export interface IAttachmentComponentProps {
  filename: string
  id: string
  type: string
}

export const AttachmentComponent: React.FC<IAttachmentComponentProps> = ({
  filename,
  id,
  type
}) => {
  const dynamicsApiConfig = useSelector(getDynamicsCrmApiConfig)
  const downloadAttachment = async () => {
    if (!dynamicsApiConfig) {
      return
    }
    const token = await tryAcquireAccessToken(dynamicsApiConfig.scopes)
    const options: IApiOptions = {
      apiRoot: dynamicsApiConfig.root,
      accessToken: token
    }
    let attachment: IAttachment | undefined
    let blob: Blob
    if (type === 'Email') {
      attachment = await getAttachment(options, id)
      if (!attachment) {
        throw Error('Attachment not Found')
      }
      blob = base64toBlob(attachment.body, attachment.mimetype)
    } else if (type === 'Note') {
      attachment = await getNoteAttachment(options, id)
      if (!attachment) {
        throw Error('Attachment not Found')
      }
      blob = base64toBlob(attachment.documentbody, attachment.mimetype)
    } else {
      throw Error('Invalid attachment type')
    }
    saveBlobAsFile(blob, attachment.filename)
  }
  return (
    <Link onClick={(e) => (e.preventDefault(), downloadAttachment())}>
      {filename}
    </Link>
  )
}
