import { takeEvery, call, select } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../store/shared/sagas'
import { getRdotUsername } from '../../../../../store/user/selectors'
import { uploadInvoice } from '../api/invoiceService'
import { UploadInvoiceActions, UploadInvoiceActionType } from './actions'
import { IUploadInvoiceRequest } from './types'

function* handleUploadInvoice(action: UploadInvoiceActionType) {
  const { orgid, file, onUploadSuccess, onUploadFail, onUploadProgress } =
    action.payload as IUploadInvoiceRequest
  try {
    const options = yield* call(getRockefellerApiOptions)
    const email = yield* select(getRdotUsername)

    if (!email) {
      throw new Error('Invalid State: email is undefined')
    }

    yield call(uploadInvoice, file, orgid, email, options, onUploadProgress)
    onUploadSuccess(file)
  } catch (e) {
    onUploadFail(file)
  }
}

export const sagas = [
  () => takeEvery(UploadInvoiceActions.request, handleUploadInvoice)
]
