import { modulesSagas as adminSagas } from '../features/Admin/store/sagas'
import { sagas as AnticipatedBalanceSagas } from '../features/AnticipatedBalance/store/sagas'
import { sagas as checksSagas } from '../features/CheckPrint/store/sagas'
import { sagas as dashboardSagas } from '../features/Dashboard/store/sagas'
import { sagas as invoiceSagas } from '../features/Invoices/store/sagas'
import { sagas as payeeSagas } from '../features/Ledger/modules/Payee/store/sagas'
import { sagas as personSagas } from '../features/Ledger/modules/Person/store/sagas'
import { sagas as postingRulesSaga } from '../features/Ledger/modules/PostingRules/store/sagas'
import { sagas as projectSagas } from '../features/Ledger/modules/Project/store/sagas'
import { sagas as SubAccountSagas } from '../features/Ledger/modules/SubAccount/store/sagas'
import { sagas as ledgerPaymentSagas } from '../features/LedgerPayments/store/sagas'
import { sagas as failedPaymentSagas } from '../features/PaymentsForAction/store/sagas'
import { sagas as paymentTransactionModuleSagas } from '../features/PaymentTransactions/store/sagas'
import { sagas as QBOReportSagas } from '../features/QBOReport/store/sagas'
import { sagas as vendorSagas } from '../features/Vendors/store/sagas'
import { sagas as wireSagas } from '../features/Wire/store/sagas'

export const sagas = [
  ...dashboardSagas,
  ...vendorSagas,
  ...wireSagas,
  ...invoiceSagas,
  ...failedPaymentSagas,
  ...AnticipatedBalanceSagas,
  ...payeeSagas,
  ...personSagas,
  ...projectSagas,
  ...SubAccountSagas,
  ...postingRulesSaga,
  ...adminSagas,
  ...ledgerPaymentSagas,
  ...paymentTransactionModuleSagas,
  ...checksSagas,
  ...QBOReportSagas
]
