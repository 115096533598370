import axios from 'axios'
import { IApiOptions } from '../../../../../../../shared/contracts/IApiOptions'
import { IDynamicsApiResult } from '../../DashBoard/store/types'
import { IAccount } from '../store/types'

export const fetchAccountsList = (
  trustAccount: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/trusts/${trustAccount}/accounts`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IDynamicsApiResult<IAccount>>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data.value)
}
