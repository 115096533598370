import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { IDynamicsApiResult, INotes } from 'api/dynamics'
import { subDays } from 'date-fns'
import { chunk, orderBy } from 'lodash'
import { isNotNullOrUndefined } from 'shared/guards'
import { dynamicsApi } from 'store/api/dynamics'
import { AxiosBaseArgs, arrayCommaParamsSerializer } from 'store/api/shared'

export interface IAddNotePayload {
  subject: string
  notetext: string
  'objectid_contact@odata.bind'?: string
  'objectid_account@odata.bind'?: string
  mimetype?: string
  filename?: string
  documentbody?: string
  isdocument?: boolean
}

export interface INoteCategory {
  categoryName?: string
  color?: string
}

export interface INotePinPayload {
  msdyn_formrecordid: string
  msdyn_timelinecontrolid: string
  msdyn_pinnedrecordid: string
  msdyn_pinnedrecordlogicalname: 'annotation'
  partitionid: string
  msdyn_timelinepinid?: string
}

export interface ITimelinePin {
  msdyn_pinnedrecordid?: string
  msdyn_pinnedrecordlogicalname?: string
  msdyn_timelinepinid?: string
  partitionid?: string
  versionnumber?: number
  'versionnumber@Microsoft.Dynamics.CRM.ETag'?: string
}

const cacheTime = 60 * 20 // 20 minutes

type DynamicsApiTagType = 'Notes' | 'rdot360'

const datahubApiTags: DynamicsApiTagType[] = ['Notes', 'rdot360']

const dynamicsApiWithTags = dynamicsApi.enhanceEndpoints({
  addTagTypes: datahubApiTags
})

export const notesDynamicsApi = dynamicsApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    notes_getNotesByContacts: builder.query<
      INotes[] | undefined,
      {
        contactIds: string[]
        order?: 'asc' | 'desc'
        searchText?: string
        last30Days?: boolean
      }
    >({
      queryFn: async (
        { contactIds, order, searchText, last30Days },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const chunkedIds = chunk(contactIds, 20)
        const requests = chunkedIds.map((contactIds) => ({
          method: 'GET',
          url: [
            `/annotations?$filter=Microsoft.Dynamics.CRM.In(PropertyName='objectid',PropertyValues=[${contactIds
              .map((x) => `'${x}'`)
              .join(',')}]) ${
              searchText
                ? `and (contains(notetext, '${searchText}') or contains(subject, '${searchText}'))`
                : ''
            } ${
              last30Days
                ? `and modifiedon ge '${subDays(new Date(), 29).toISOString()}'`
                : ''
            } &$expand=objectid_contact($select=fullname),objectid_account($select=name)&$orderby=modifiedon ${
              order || 'desc'
            }
          &$select=${[
            'filename',
            'mimetype',
            'annotationid',
            'notetext',
            'objectid_contact',
            '_ownerid_value',
            'modifiedon',
            '_modifiedby_value',
            'objectid_contact',
            'subject',
            '_createdby_value',
            'createdon'
          ].join(',')}
          &$top=500`
          ]
            .filter(Boolean)
            .join(''),
          headers: {
            Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`,
            Accept: 'application/json, text/plain, */*'
          }
        }))
        const responses = await Promise.all(
          requests.map(
            async (x) =>
              baseQuery(x) as Promise<
                QueryReturnValue<IDynamicsApiResult<INotes>>
              >
          )
        )
        const orderedResponses = orderBy(
          responses
            .flatMap((x) => x.data?.value || [])
            ?.filter(isNotNullOrUndefined),
          'modifiedon',
          order || 'desc'
        )
        return { data: orderedResponses }
      },
      providesTags: ['Notes', 'rdot360'],
      keepUnusedDataFor: cacheTime
    }),
    notes_getNoteAttachment: builder.query<INotes | undefined, string>({
      queryFn: async (id, _api, _extraOptions, baseQuery) => {
        type Response = QueryReturnValue<INotes, Error>

        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: `annotations(${id})`,
          paramsSerializer: arrayCommaParamsSerializer
        }

        const baseApiParams = {
          $select: ['filename', 'mimetype', 'documentbody']
        }

        const result = (await baseQuery({
          ...baseApiArgs,
          params: { ...baseApiParams }
        })) as Response
        const error = result.error
        if (error) {
          return { error }
        }

        return {
          data: result?.data
        }
      },
      keepUnusedDataFor: cacheTime
    }),
    notes_addNote: builder.mutation<
      INotes,
      {
        notePayload: IAddNotePayload
        contacts: string[]
        order?: 'asc' | 'desc'
        searchText?: string
        last30Days?: boolean
      }
    >({
      query: ({ notePayload }) => ({
        url: `/annotations`,
        method: 'POST',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
        },
        data: notePayload
      }),
      invalidatesTags: ['Notes']
    }),
    notes_editNote: builder.mutation<
      INotes,
      {
        notePayload: IAddNotePayload
        contacts: string[]
        id: string
        order?: 'asc' | 'desc'
        searchText?: string
        last30Days?: boolean
      }
    >({
      query: ({ notePayload, id }) => ({
        url: `/annotations(${id})`,
        method: 'PATCH',
        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue",return=representation`
        },
        data: notePayload
      }),
      invalidatesTags: ['Notes']
    }),
    notes_getNoteCategories: builder.query<
      INoteCategory[] | undefined,
      undefined
    >({
      query: () => ({
        url: `/noteCategories`,

        headers: {
          Prefer: `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
        }
      }),
      transformResponse: (x: IDynamicsApiResult<INoteCategory>) => x.value,
      providesTags: ['rdot360'],
      keepUnusedDataFor: cacheTime
    }),
    notes_addPin: builder.mutation<null, INotePinPayload>({
      query: (payload) => ({
        url: `/msdyn_timelinepins`,
        method: 'POST',
        data: payload
      }),
      invalidatesTags: ['Notes']
    }),
    notes_getPins: builder.query<ITimelinePin[] | undefined, string[]>({
      query: (ids) => ({
        url: 'msdyn_timelinepins',
        params: {
          $filter: `Microsoft.Dynamics.CRM.In(PropertyName='msdyn_formrecordid',PropertyValues=[${ids
            .map((x) => `'${x}'`)
            .join(',')}])`
        }
      }),
      transformResponse: (x: IDynamicsApiResult<ITimelinePin>) => x.value,
      providesTags: ['rdot360', 'Notes'],
      keepUnusedDataFor: cacheTime
    }),
    notes_RemovePin: builder.mutation<
      null,
      { id: string; partitionId: string; payload: INotePinPayload }
    >({
      query: ({ id, partitionId, payload }) => ({
        url: `/msdyn_timelinepins(${id})`,
        params: { partitionId },
        method: 'DELETE',
        data: payload
      }),
      invalidatesTags: ['Notes']
    })
  })
})

export const {
  useNotes_addNoteMutation,
  useNotes_editNoteMutation,
  useNotes_getNotesByContactsQuery,
  useLazyNotes_getNoteAttachmentQuery,
  useNotes_getNoteCategoriesQuery,
  useNotes_addPinMutation,
  useNotes_getPinsQuery,
  useNotes_RemovePinMutation
} = notesDynamicsApi
