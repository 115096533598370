import { createReducer } from 'typesafe-actions'
import {
  pendingDistributionDataActions,
  completedDistributionDataActions,
  DistributionActionType,
  documentDataActions,
  deleteDistributionActions
} from './actions'
import { IDistributionInfo } from './types'
export interface IDistributionsState {
  pendingDistribution?: IDistributionListState
  completedDistribution?: IDistributionListState
  document?: IDocumentState
  deleteDoc?: IDeleteDocumentState
}

export interface IDocumentState {
  isLoaded: boolean
  docString?: string
  error?: Error
}

export interface IDistributionListState {
  isLoaded: boolean
  List: IDistributionInfo[]
  error?: Error
}

export interface IDeleteDocumentState {
  inProgress: boolean
  error?: Error
}

const initialState: IDistributionsState = {
  pendingDistribution: {
    isLoaded: false,
    List: []
  },
  completedDistribution: {
    isLoaded: false,
    List: []
  }
}

export const distributionReducer = createReducer<
  IDistributionsState,
  DistributionActionType
>(initialState)
  .handleAction(pendingDistributionDataActions.request, (state) => ({
    ...state,
    pendingDistribution: {
      ...state.pendingDistribution,
      isLoaded: false,
      List: [],
      error: undefined
    }
  }))
  .handleAction(pendingDistributionDataActions.success, (state, action) => ({
    ...state,
    pendingDistribution: {
      ...state.pendingDistribution,
      isLoaded: true,
      List: action.payload,
      error: undefined
    }
  }))
  .handleAction(pendingDistributionDataActions.failure, (state, action) => ({
    ...state,
    pendingDistribution: {
      ...state.pendingDistribution,
      isLoaded: true,
      List: [],
      error: action.payload
    }
  }))
  .handleAction(completedDistributionDataActions.request, (state) => ({
    ...state,
    completedDistribution: {
      ...state.completedDistribution,
      isLoaded: false,
      List: [],
      error: undefined
    }
  }))
  .handleAction(completedDistributionDataActions.success, (state, action) => ({
    ...state,
    completedDistribution: {
      ...state.completedDistribution,
      isLoaded: true,
      List: action.payload,
      error: undefined
    }
  }))
  .handleAction(completedDistributionDataActions.failure, (state, action) => ({
    ...state,
    completedDistribution: {
      ...state.completedDistribution,
      isLoaded: true,
      List: [],
      error: action.payload
    }
  }))
  .handleAction(documentDataActions.request, (state) => ({
    ...state,
    document: {
      ...state.document,
      isLoaded: false,
      error: undefined,
      docString: undefined
    }
  }))
  .handleAction(documentDataActions.success, (state, action) => ({
    ...state,
    document: {
      ...state.document,
      isLoaded: false,
      error: undefined,
      docString: action.payload
    }
  }))
  .handleAction(documentDataActions.failure, (state, action) => ({
    ...state,
    document: {
      ...state.document,
      isLoaded: false,
      error: action.payload,
      docString: undefined
    }
  }))
  .handleAction(deleteDistributionActions.request, (state) => ({
    ...state,
    deleteDoc: {
      ...state.deleteDoc,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(deleteDistributionActions.success, (state, action) => ({
    ...state,
    deleteDoc: {
      ...state.deleteDoc,
      inProgress: false,
      error: undefined
    },
    pendingDistribution: {
      ...state.pendingDistribution,
      isLoaded: true,
      List: state.pendingDistribution?.List
        ? state.pendingDistribution?.List.filter(
            (dis) => dis.distributionID !== action.payload
          )
        : []
    }
  }))
  .handleAction(deleteDistributionActions.failure, (state, action) => ({
    ...state,
    deleteDoc: {
      ...state.deleteDoc,
      inProgress: false,
      error: action.payload
    }
  }))
