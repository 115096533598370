import { combineReducers, Reducer } from 'redux'
import { IDataListState } from '../../../../../../../../../features/DataList/contracts/IDataListState'
import {
  potentialPayoutExportReducer,
  potentialPayoutExportSagas
} from './export'
import { hurdlesFetchReducer, hurdlesFetchSagas } from './hurdleFetch'
import {
  mostRecentClosedMonthFetchReducer,
  mostRecentClosedMonthFetchSagas
} from './mostRecentClosedMonthFetch'
import {
  payoutsDashboardUiReducer,
  payoutsDashboardUiSagas
} from './payoutsDashboardUi'
import { payoutsListReducer, payoutsListSagas } from './payoutsList'
import { PossiblePayout } from './potentialPayouts'

export const potentialPayoutsReducer: Reducer<{
  hurdlesFetch: ReturnType<typeof hurdlesFetchReducer>
  payoutsList: IDataListState<PossiblePayout>
  potentialPayoutsUi: ReturnType<typeof payoutsDashboardUiReducer>
  mostRecentClosedMonthFetch: ReturnType<
    typeof mostRecentClosedMonthFetchReducer
  >
  potentialPayoutExport: ReturnType<typeof potentialPayoutExportReducer>
}> = combineReducers({
  hurdlesFetch: hurdlesFetchReducer,
  payoutsList: payoutsListReducer,
  potentialPayoutsUi: payoutsDashboardUiReducer,
  mostRecentClosedMonthFetch: mostRecentClosedMonthFetchReducer,
  potentialPayoutExport: potentialPayoutExportReducer
})

export const potentialPayoutsSagas = [
  ...hurdlesFetchSagas,
  ...payoutsListSagas,
  ...payoutsDashboardUiSagas,
  ...mostRecentClosedMonthFetchSagas,
  ...potentialPayoutExportSagas
]
