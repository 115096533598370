import { combineReducers } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import {
  hurdleManagementViewExportFormattedReducer,
  hurdleManagementViewExportFormattedSagas
} from './exportFormatted'
import {
  hurdleManagementViewExportRawReducer,
  hurdleManagementViewExportRawSagas
} from './exportRaw'
import {
  hurdleProgressManagementListReducer as listReducer,
  hurdleProgressManagementListSagas as listSagas,
  hurdleProgressManagementListUiActions
} from './hurdleProgressManagementList'
import {
  rerunMeasurementsActions,
  rerunMeasurementsReducer,
  rerunMeasurementsSagas
} from './rerunMeasurements'

export const hurdleProgressManagementListReducer = combineReducers({
  list: listReducer,
  rerunMeasurements: rerunMeasurementsReducer,
  hurdleManagementViewExportRaw: hurdleManagementViewExportRawReducer,
  hurdleManagementViewExportFormatted:
    hurdleManagementViewExportFormattedReducer
})

const onRerunSuccess = function* () {
  yield put(hurdleProgressManagementListUiActions.reload(undefined))
}

export const hurdleProgressManagementListSagas = [
  ...listSagas,
  ...rerunMeasurementsSagas,
  ...hurdleManagementViewExportRawSagas,
  ...hurdleManagementViewExportFormattedSagas,
  () => takeLatest(rerunMeasurementsActions.success, onRerunSuccess)
]
