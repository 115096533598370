import { useCallback } from 'react'
import { useActivityDetailUiState } from '../../Activity/activityDetailsUiState'

export const useSharedActivityStore = () => {
  const store = useActivityDetailUiState()
  const {
    setCategories,
    setDateSelection,
    setRangeForDay,
    setStartDate,
    setEndDate,
    setCustomRange
  } = store
  const setCustomDateRange = useCallback(
    (startDate: string, endDate: string) => {
      setDateSelection('Custom Range')
      setStartDate(startDate)
      setEndDate(endDate)
      setCustomRange(startDate, endDate)
    },
    [setCustomRange, setDateSelection, setEndDate, setStartDate]
  )

  return { setCategories, setDateSelection, setRangeForDay, setCustomDateRange }
}
