import { debounce } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

export const useWidthObserver = (ref: React.RefObject<HTMLElement>) => {
  const [width, setWidth] = useState<number>()
  const onResize = useCallback(() => {
    const newWidth = ref.current?.clientWidth

    if (newWidth === width) {
      return
    }
    setWidth(newWidth)
  }, [ref, width])

  const debouncedOnResize = useMemo(() => debounce(onResize, 200), [onResize])

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const observer = new ResizeObserver(debouncedOnResize)
    observer.observe(ref.current)

    return () => observer.disconnect()
  }, [debouncedOnResize, ref])

  return width
}
