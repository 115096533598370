import { IThirdPartyProduct } from 'api/thirdpartyproduct.type'
import { createListsStore } from 'features/Lists/core/store'
import {
  createDefaultColumnState,
  mapColumnsToFilterDefinitions
} from 'features/Lists/shared/lists'
import { partial } from 'lodash/fp'
import { AppState } from 'store/shared/contracts'
import { ThirdPartyProductColumnDefinitions } from '../ColumnDefinitions'

export const constructInitialThirdPartyProductsColumnState = partial(
  createDefaultColumnState,
  [ThirdPartyProductColumnDefinitions]
)

export const thirdPartyProductListStore = createListsStore<IThirdPartyProduct>(
  'thirdpartyproduct',
  '@features/@lists/@thirdpartyproduct',
  {
    columnDefinitions: ThirdPartyProductColumnDefinitions,
    filterDefinitions: mapColumnsToFilterDefinitions(
      ThirdPartyProductColumnDefinitions
    )
  },
  (state: AppState) => state.features.lists.thirdPartyProduct
)

export const { reducer, sagas, actions, selectors } = thirdPartyProductListStore
