import { Text } from '@fluentui/react'
import { createRoot } from 'react-dom/client'
import { whenIdle } from 'shared/async'
import {
  AccountLinkingEmailClientsSection,
  IAccountLinkingEmailProps
} from './AccountLinkingEmail'

export const AccountDelinkingEmail: React.FC<IAccountLinkingEmailProps> = ({
  selectedClients,
  requestType,
  origin,
  id,
  delinkAccounts
}) => {
  return (
    <div>
      <div>
        <div>
          To view the status of your request, see{' '}
          <a
            href={`${origin}/advisory/requests/AccountLinkingRequests${
              id ? `?id=${id}` : ''
            }`}
          >
            Account Linking Requests
          </a>{' '}
        </div>
      </div>

      <AccountLinkingEmailClientsSection
        selectedClients={selectedClients}
        requestType={requestType}
      />

      <div style={{ marginTop: '10px' }}>
        <b>Account Numbers</b>
        <br />
        <Text>{delinkAccounts?.join(', ')}</Text>
      </div>
    </div>
  )
}

export const generateAccountDelinkingEmail = async (
  props: IAccountLinkingEmailProps
) => {
  const container = document.createElement('div')
  const root = createRoot(container)

  root.render(<AccountDelinkingEmail {...props} />)
  await whenIdle()
  const html = container.innerHTML
  root.unmount()
  return html
}
