import { format } from 'date-fns'
import {
  Dictionary,
  IDataFilter,
  NoData
} from '../../../../../shared/components/DataTableFilters/DataTableFilters'

import { IReportSummaryListEntity } from './types'

export const getCurrentMonthYearKey = (): string => {
  const today = new Date()
  return format(today, 'MM-yyyy')
}
export const filterFeePlanBySearchtext = (
  items: IReportSummaryListEntity[],
  searchText: string
) => {
  let filtered: IReportSummaryListEntity[] = items || []
  if (items && searchText && Array.isArray(items)) {
    const lowerCaseText = searchText.toLowerCase()
    filtered = items.filter((p) => {
      if (
        p?.reportCategory?.toLowerCase()?.includes(lowerCaseText) ||
        p?.reportName?.toString()?.startsWith(searchText) ||
        p?.portfolioName?.toLowerCase().includes(lowerCaseText)
      ) {
        return true
      } else {
        return false
      }
    })
  }
  return filtered
}

export const filterPlansByFilterData = (
  feePlans: IReportSummaryListEntity[],
  filterBy: Dictionary<IDataFilter>
) => {
  let filteredData = [...feePlans]
  if (filterBy) {
    Object.entries(filterBy)?.map(([key, data]) => {
      filteredData = filteredData.filter((x) => {
        if (data?.value) {
          return (data.value as (string | number)[]).includes(
            (x[key as keyof IReportSummaryListEntity] as string | number) ||
              NoData
          )
        } else {
          return true
        }
      })
    })
  }

  return filteredData
}

export const getFeeCategoryDisplayValue = (feeCategory: string) => {
  if (feeCategory.toLowerCase() === 'flat_fee') {
    return 'Flat Fee'
  }
  return feeCategory
}

export const base64toBlob = (data: string) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length)

  const bytes = atob(base64WithoutPrefix)
  let length = bytes.length
  const out = new Uint8Array(length)

  while (length--) {
    out[length] = bytes.charCodeAt(length)
  }

  return new Blob([out], { type: 'application/pdf' })
}
