import { createRoot } from 'react-dom/client'
import { whenIdle } from 'shared/async'
import { IAccount } from '../../api/account.types'
import { IHousehold } from '../../api/household.types'
import { MarginRateRequestType } from './store/householdAccountsDetails'
import { getSuggestedRate } from './store/marginRatePanel'

export interface IMarginRateEmailProps {
  selectedHousehold?: IHousehold
  accounts?: IAccount[]
  rate?: number
  justification?: string
  requestType?: MarginRateRequestType
}

export const MarginRateEmail: React.FC<IMarginRateEmailProps> = ({
  selectedHousehold,
  accounts,
  rate,
  justification,
  requestType
}) => {
  return (
    <div>
      <div>
        <div>
          To view the status of your request, see{' '}
          <a href={`${origin}/advisory/requests/MarginRateRequests`}>
            Margin Rate Requests
          </a>{' '}
        </div>
      </div>
      <div style={{ marginTop: '10px' }}>
        <div>
          <b>Household Name</b>: {selectedHousehold?.householdName}
        </div>
        <div>
          <b>AUM Total</b>: $
          {selectedHousehold?.householdKPI?.AumTotal?.toLocaleString()}
        </div>
        <div>
          <b>HH T12 Revenue</b>: $
          {selectedHousehold?.revenue?.ttmrevenue?.toLocaleString()}
        </div>
        <div>
          <b>Suggested Rate</b>: OBFR +{' '}
          {getSuggestedRate(selectedHousehold?.householdKPI?.AumTotal)?.toFixed(
            2
          )}
          %
        </div>
      </div>

      <div style={{ marginTop: '10px' }}>
        <div>
          <b>Rate Type</b>: {requestType}
        </div>
        <div>
          <b>Requested Rate</b>: OBFR + {rate?.toFixed(2)}%
        </div>
        {requestType === 'Suggested' && (
          <div>
            <b>Employee Discount Applied</b>: {selectedHousehold?.employeeCode}
          </div>
        )}
        {requestType === 'Custom' && (
          <div>
            <b>Justification</b>: {justification}
          </div>
        )}
      </div>

      <div style={{ marginTop: '10px' }}>
        <b>Selected Accounts</b>
        <table>
          <thead>
            <tr>
              {[
                'Account Number',
                'Registration Type',
                'Nickname',
                'Margin Agreement',
                'Current Margin Rate',
                'Employee Indicator',
                'Loan Outstanding'
              ].map((x, i) => (
                <th
                  style={{ padding: '0 5px', margin: 0, textAlign: 'left' }}
                  key={i}
                >
                  {x}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {accounts?.map(
              (
                {
                  CustodyAccount,
                  registrationtype,
                  AdvisorAddedNickName,
                  marginAgreement,
                  marginInterestRate,
                  employeeCode,
                  AccountKPIs
                },
                i
              ) => (
                <tr key={i}>
                  {[
                    CustodyAccount,
                    registrationtype,
                    AdvisorAddedNickName,
                    marginAgreement,
                    marginInterestRate
                      ? `OBFR + ${marginInterestRate.toFixed(2)}%`
                      : '--',
                    employeeCode,
                    `$${AccountKPIs?.LoanOutstanding?.toLocaleString()}`
                  ].map((value, i) => (
                    <td style={{ padding: '0 5px' }} key={i}>
                      {value || '--'}
                    </td>
                  ))}
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export const generateMarginRateEmail = async (props: IMarginRateEmailProps) => {
  const container = document.createElement('div')
  const root = createRoot(container)

  root.render(<MarginRateEmail {...props} />)
  await whenIdle()

  const html = container.innerHTML
  root.unmount()
  return html
}
