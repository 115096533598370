import { combineReducers } from 'redux'
import {
  householdFeatureFeaturesReducer,
  householdFeatureFeaturesSagas
} from '../features'
import {
  householdAccountsFetchReducer,
  householdAccountsFetchSagas
} from './householdAccountsFetch'
import {
  householdClientsFetchReducer,
  householdClientsFetchSagas
} from './householdClientsFetch'
import {
  householdDepartmentsFetchReducer,
  householdDepartmentsFetchSagas
} from './householdDepartmentsFetch'
import {
  householdFeeFetchReducer,
  householdFeesFetchSagas
} from './householdFeeFetch'
import { householdFetchReducer, householdFetchSags } from './householdFetch'

export const householdFeatureReducer = combineReducers({
  features: householdFeatureFeaturesReducer,
  householdAccountsFetch: householdAccountsFetchReducer,
  householdClientsFetch: householdClientsFetchReducer,
  householdFeeFetch: householdFeeFetchReducer,
  householdFetch: householdFetchReducer,
  householdDepartmentsFetch: householdDepartmentsFetchReducer
})

export const householdFeatureSagas = [
  ...householdFeatureFeaturesSagas,
  ...householdAccountsFetchSagas,
  ...householdClientsFetchSagas,
  ...householdFeesFetchSagas,
  ...householdFetchSags,
  ...householdDepartmentsFetchSagas
]
