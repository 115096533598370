import { IEnvironmentAppConfigurations } from 'shared/services/environment/IEnvironmentConfiguration'
import { ActionType, createAction } from 'typesafe-actions'
import { IServicePrincipal } from '../../api/graph'
import { ThemeType } from './types'

const UPDATE_THEME = '@ui/UPDATE_THEME'

export const uiActions = {
  updateTheme: createAction(UPDATE_THEME)<ThemeType>()
}

export interface ILaunchConfigApplicationPayload {
  id: keyof IEnvironmentAppConfigurations
  postLoginRedirectPath?: string
  relayState?: string
}

const LAUNCH_TEAMS = '@ui/LAUNCH_TEAMS'
const LAUNCH_CLIENT_ONLINE_WEALTHSCAPE_ID =
  '@ui/LAUNCH_CLIENT_ONLINE_WEALTHSCAPE_ID'
const LAUNCH_WEALTHSCAPE = '@ui/LAUNCH_WEALTHSCAPE'
const LAUNCH_SIMON = '@ui/LAUNCH_SIMON'
const LAUNCH_APPLICATION = '@ui/LAUNCH_APPLICATION'
const LAUNCH_CONFIG_APPLICATION = '@ui/LAUNCH_CONFIG_APPLICATION'

// Actions for payments
const LAUNCH_BILL_DOT_COM = '@ui/LAUNCH_BILL_DOT_COM'
const LAUNCH_ACCOUNTING365 = '@ui/LAUNCH_ACCOUNTING365'
const LAUNCH_ACCOUTING_SUBITEM = '@ui/LAUNCH_ACCOUNTING_SUBITEM'
const LAUNCH_PAYMENTS_REPORTING = '@ui/LAUNCH_PAYMENTS_REPORTING'

const LAUNCH_LEXIS_NEXIS = '@ui/LAUNCH_LEXIS_NEXIS'
const LAUNCH_RETIREMENT_REPORT = '@ui/LAUNCH_RETIREMENT_REPORT'
const LAUNCH_PRODUCT_REPORT = '@ui/LAUNCH_PRODUCT_REPORT'
const LAUNCH_SHAREPOINT_CHECKS = `@ui/LAUNCH_SHAREPOINT_CHECKS`
const LAUNCH_URL = '@ui/LAUNCH_URL'
const LAUNCH_RETIREMENT_TRUE_REPORT = '@ui/LAUNCH_RETIREMENT_TRUE_REPORT'
const LAUNCH_PRODUCTS_COMP_REPORT = '@ui/LAUNCH_PRODUCTS_COMP_REPORT'
const LAUNCH_RETIREMENT_AGING_REPORT = '@ui/LAUNCH_RETIREMENT_AGING_REPORT'
const LAUNCH_PRODUCTS_AGING_REPORT = '@ui/LAUNCH_PRODUCTS_AGING_REPORT'
const LAUNCH_PRODUCTS_DEFERRED_REPORT = '@ui/LAUNCH_PRODUCTS_DEFERRED_REPORT'
const LAUNCH_FEES_COMP_REPORT = '@ui/LAUNCH_FEES_COMP_REPORT'
const LAUNCH_FEES_BILLING_REPORT = '@ui/LAUNCH_FEES_BILLING_REPORT'
const LAUNCH_FEES_NON_BILLING_REPORT = '@ui/LAUNCH_FEES_NON_BILLING_REPORT'

export interface ILaunchClientOnlineActionPayload {
  wealthscapeId?: string
  partyId?: string
  path?: string
  useRockDigital?: boolean
  additionalParams?: Record<string, string>
}

export const navigationActions = {
  launchTeams: createAction(LAUNCH_TEAMS)<string>(),
  launchClientOnline: createAction(
    LAUNCH_CLIENT_ONLINE_WEALTHSCAPE_ID
  )<ILaunchClientOnlineActionPayload>(),
  launchWealthscape: createAction(LAUNCH_WEALTHSCAPE)<string>(),
  launchSimon: createAction(LAUNCH_SIMON)<string>(),
  launchApplication: createAction(LAUNCH_APPLICATION)<
    IServicePrincipal | string
  >(),
  launchConfigApplication: createAction(
    LAUNCH_CONFIG_APPLICATION
  )<ILaunchConfigApplicationPayload>(),
  launchBillDotCom: createAction(LAUNCH_BILL_DOT_COM)<string>(),
  launchAccounting365: createAction(LAUNCH_ACCOUNTING365)(),
  launchAccountingSubItem: createAction(LAUNCH_ACCOUTING_SUBITEM)<string>(),
  launchPaymentsReporting: createAction(LAUNCH_PAYMENTS_REPORTING)(),
  launchLexisNexis: createAction(LAUNCH_LEXIS_NEXIS)(),
  launchRetirementReport: createAction(LAUNCH_RETIREMENT_REPORT)(),
  launchProductReport: createAction(LAUNCH_PRODUCT_REPORT)<string>(),
  launchSharePointChecks: createAction(LAUNCH_SHAREPOINT_CHECKS)(),
  launchUrl: createAction(LAUNCH_URL)<{ url?: string; target?: string }>(),
  launchRetirementTrueUpReport: createAction(LAUNCH_RETIREMENT_TRUE_REPORT)(),
  launchRetirementAgingReport: createAction(LAUNCH_RETIREMENT_AGING_REPORT)(),
  launchCompReport: createAction(LAUNCH_PRODUCTS_COMP_REPORT)<string>(),
  launchAgingReport: createAction(LAUNCH_PRODUCTS_AGING_REPORT)<string>(),
  launchDeferredReport: createAction(LAUNCH_PRODUCTS_DEFERRED_REPORT)<string>(),
  launchFeesCompReport: createAction(LAUNCH_FEES_COMP_REPORT)<string>(),
  launchFeesBillingReport: createAction(LAUNCH_FEES_BILLING_REPORT)<string>(),
  launchFeesNonBillingReport: createAction(
    LAUNCH_FEES_NON_BILLING_REPORT
  )<string>()
}

export type UIActionTypes =
  | ActionType<typeof uiActions>
  | ActionType<typeof navigationActions>
