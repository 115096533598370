import axios from 'axios'
import { endOfMonth } from 'date-fns'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { parseDateISOStringInLocalTimezone } from 'shared'
import { call, cancelled, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { getMostRecentClosedMonth } from '../../../../../api/datahub'
import { AppState } from '../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../../../../store/shared/sagas'

const actions = createAsyncAction(
  '@modules/@revenue/@hurdles/mostRecentClosedMonthFetch/REQUEST',
  '@modules/@revenue/@hurdles/mostRecentClosedMonthFetch/SUCCESS',
  '@modules/@revenue/@hurdles/mostRecentClosedMonthFetch/FAILURE'
)<undefined, string | undefined, Error>()

export const mostRecentClosedMonthFetchReducer = createAsyncReducer(actions)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.revenue.mostRecentClosedMonthFetch

const { getError, getIsLoading, getResult } = createAsyncSelectors(rootSelector)

const onRequest = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  try {
    const apiOptions = yield* call(getRockefellerApiOptions)
    const date = yield* call(getMostRecentClosedMonth, apiOptions)
    if (!date) {
      throw new Error(
        'An error occurred while fetching the most recent revenue closed date'
      )
    }
    yield put(actions.success(date))
  } catch (e: any) {
    yield put(actions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const mostRecentClosedMonthFetchSagas = [
  () => takeLatest(actions.request, onRequest)
]

export const useGetMostRecentClosedRevenueDate = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsLoading)
  const error = useSelector(getError)
  const result = useSelector(getResult)
  useEffect(() => {
    if (isLoading || result || error) {
      return
    }

    dispatch(actions.request())
  })
  const mostRecentClosedDate =
    result && endOfMonth(parseDateISOStringInLocalTimezone(result))

  return { isLoading, error, mostRecentClosedDate }
}
