import axios, { AxiosResponse } from 'axios'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import { IFailedPayment, INextAction } from '../store/types'

export const fetchFailedPaymentList = (options: IApiOptions) => {
  const url = `${options.apiRoot}/payment/api/billmgmt/getBillStatus?pkid=1`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IFailedPayment[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const retryFailedPayment = (
  payment: IFailedPayment | undefined,
  action: INextAction | undefined,
  options: IApiOptions
): Promise<AxiosResponse<IFailedPayment | undefined>> => {
  const url = `${options.apiRoot}/payment/api/billmgmt/updBillStatus?action=${action?.actionCode}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  const data = {
    clientId: payment?.clientId,
    id: payment?.id,
    StatusCode: payment?.statusCode,
    StatusDesc: payment?.statusDesc,
    Processor: payment?.processor,
    ConfirmationType: payment?.confirmationType,
    ConfirmationId: payment?.confirmationId,
    CreatedOn: payment?.createdOn,
    UpdatedOn: payment?.updatedOn,
    NextActions: action
      ? [
          {
            StatusCode: action?.statusCode,
            StatusName: action?.statusName,
            ActionCode: action?.actionCode,
            ActionName: action?.actionName
          }
        ]
      : undefined
  }
  return axios
    .post(url, data, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
