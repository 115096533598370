import axios, { CancelTokenSource } from 'axios'
import { orderBy } from 'lodash'
import { call, cancelled, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { getHurdles, IHurdle } from '../../../../../../../../../api/datahub'
import { IOdataRequest } from '../../../../../../../../../api/odata.types'
import { IApiOptions } from '../../../../../../../../../shared/contracts/IApiOptions'
import { isNotNullOrUndefined } from '../../../../../../../../../shared/guards'
import { AppState } from '../../../../../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../../../../../../../../store/shared/sagas'
import { getAllPagedOdataApiResults } from '../../../../../../../../../store/shared/sagas/odata'

export const hurdlesFetchActions = createAsyncAction(
  '@modules/@teams/@potentialPayout/REQUEST',
  '@modules/@teams/@potentialPayout/SUCCESS',
  '@modules/@teams/@potentialPayout/FAILURE'
)<undefined, IHurdle[], Error>()

export const hurdlesFetchReducer = createAsyncReducer(hurdlesFetchActions)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.teams.modules.hurdles.features.potentialPayouts
    .hurdlesFetch

export const {
  getError: getHurdlesFetchError,
  getIsLoading: getIsHurdlesFetchLoading,
  getResult: getHurdlesFetchResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()

  try {
    const pagedResults = yield* call(() =>
      getAllPagedOdataApiResults(
        {
          expand: [
            'measurements($expand=metrics($expand=payouts,progressMeasurementStatuses($select=status,currentPeriodDate)))'
          ]
        },
        fetchHurdles
      )
    )
    const result = orderBy(pagedResults, ({ index }) => index)
      .map(({ result }) => result?.value)
      .filter(isNotNullOrUndefined)
      .flat()

    if (!result) {
      throw new Error('An error occurred while fetching teams')
    }
    yield put(hurdlesFetchActions.success(result || []))
  } catch (e: any) {
    yield put(hurdlesFetchActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const hurdlesFetchSagas = [
  () => takeLatest(hurdlesFetchActions.request, onRequest)
]

const getDatahubApiOptions = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)
  return [apiOptions, source] as [IApiOptions, CancelTokenSource]
}

const fetchHurdles = function* (request: IOdataRequest) {
  const [apiOptions, cancelTokenSource] = yield* call(getDatahubApiOptions)
  try {
    const result = yield* call(getHurdles, apiOptions, request)
    return result
  } finally {
    if (yield* cancelled()) {
      cancelTokenSource.cancel()
    }
  }
}
