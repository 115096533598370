import { createReducer } from 'typesafe-actions'
import {
  ILookUpValueResponse,
  IAbaNumber,
  IEligibleAccount,
  IWirePayment
} from '../api/types'
import {
  WireActions,
  LookUpValueActions,
  CreateWireInstructionActions,
  FetchAbaDetailActions,
  FetchEligibleAcctsActions,
  FetchWireInstructionActions,
  FetchWirePaymentActions,
  CreateWireInstructionForSecurityActions
} from './actions'
import { IInstruction } from './types'

export interface IWireState {
  lookupvalues?: ILookUpValueState
  wireInstruction?: IWireInstructionState
  abaDetail?: IAbaNumberState
  eligibleAccts?: IEligibleAccountState
  wirePayments?: IWirePaymentState
  wireInstructionForSecurity?: IWireInstructionForSecurityState
}

export interface ILookUpValueState {
  isLoading: boolean
  values?: ILookUpValueResponse[]
  error?: Error
}

export interface IWireInstructionState {
  isLoading: boolean
  instruction?: IInstruction
  error?: Error
  validationError?: string
}

export interface IWireInstructionForSecurityState {
  isLoading: boolean
  error?: Error
  isWireCreated?: boolean
}

export interface IAbaNumberState {
  isLoading: boolean
  abaNumber?: IAbaNumber
  error?: Error
}

export interface IEligibleAccountState {
  isLoading: boolean
  accounts?: IEligibleAccount[]
  error?: Error
}

const initialState: IWireState = {
  lookupvalues: undefined
}

export interface IWirePaymentState {
  isLoading: boolean
  payments?: IWirePayment[]
  error?: Error
}

export const wireReducer = createReducer<IWireState, WireActions>(initialState)
  .handleAction(LookUpValueActions.request, (state) => ({
    ...state,
    lookupvalues: {
      isLoading: true,
      values: undefined,
      error: undefined
    }
  }))
  .handleAction(LookUpValueActions.success, (state, action) => ({
    ...state,
    lookupvalues: {
      isLoading: false,
      values: action.payload
    }
  }))
  .handleAction(LookUpValueActions.failure, (state, action) => ({
    ...state,
    lookupvalues: {
      isLoading: false,
      values: undefined,
      error: action.payload
    }
  }))
  .handleAction(LookUpValueActions.clear, (state) => ({
    ...state,
    lookupvalues: undefined
  }))
  .handleAction(CreateWireInstructionActions.request, (state, action) => ({
    ...state,
    wireInstruction: {
      isLoading: true,
      instruction: action.payload,
      error: undefined
    }
  }))
  .handleAction(CreateWireInstructionActions.success, (state) => ({
    ...state,
    wireInstruction: {
      isLoading: false
    }
  }))
  .handleAction(CreateWireInstructionActions.failure, (state, action) => ({
    ...state,
    wireInstruction: {
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(
    CreateWireInstructionActions.validationError,
    (state, action) => ({
      ...state,
      wireInstruction: {
        ...state.wireInstruction,
        isLoading: false,
        error: undefined,
        validationError: action.payload
      }
    })
  )
  .handleAction(FetchAbaDetailActions.request, (state) => ({
    ...state,
    abaDetail: {
      isLoading: true,
      error: undefined,
      abaNumber: undefined
    }
  }))
  .handleAction(FetchAbaDetailActions.success, (state, action) => ({
    ...state,
    abaDetail: {
      isLoading: false,
      error: undefined,
      abaNumber: action.payload
    }
  }))
  .handleAction(FetchAbaDetailActions.failure, (state, action) => ({
    ...state,
    abaDetail: {
      isLoading: false,
      error: action.payload,
      abaNumber: undefined
    }
  }))
  .handleAction(FetchAbaDetailActions.clear, (state) => ({
    ...state,
    abaDetail: undefined
  }))
  .handleAction(FetchEligibleAcctsActions.request, (state) => ({
    ...state,
    eligibleAccts: {
      isLoading: true,
      error: undefined,
      accounts: undefined
    }
  }))
  .handleAction(FetchEligibleAcctsActions.success, (state, action) => ({
    ...state,
    eligibleAccts: {
      isLoading: false,
      error: undefined,
      accounts: action.payload
    }
  }))
  .handleAction(FetchEligibleAcctsActions.failure, (state, action) => ({
    ...state,
    eligibleAccts: {
      isLoading: true,
      error: action.payload,
      accounts: undefined
    }
  }))
  .handleAction(FetchEligibleAcctsActions.clear, (state) => ({
    ...state,
    eligibleAccts: undefined
  }))
  .handleAction(FetchWireInstructionActions.request, (state) => ({
    ...state,
    wireInstruction: {
      ...state.wireInstruction,
      isLoading: true,
      error: undefined,
      validationError: undefined
    }
  }))
  .handleAction(FetchWireInstructionActions.success, (state, action) => ({
    ...state,
    wireInstruction: {
      ...state.wireInstruction,
      isLoading: false,
      error: undefined,
      validationError: undefined,
      instruction: action.payload
    }
  }))
  .handleAction(FetchWireInstructionActions.failure, (state, action) => ({
    ...state,
    wireInstruction: {
      ...state.wireInstruction,
      isLoading: false,
      error: action.payload,
      validationError: undefined,
      instruction: undefined
    }
  }))
  .handleAction(FetchWireInstructionActions.clear, (state) => ({
    ...state,
    wireInstruction: undefined
  }))
  .handleAction(FetchWirePaymentActions.request, (state) => ({
    ...state,
    wirePayments: {
      isLoading: true,
      error: undefined,
      payments: undefined
    }
  }))
  .handleAction(FetchWirePaymentActions.success, (state, action) => ({
    ...state,
    wirePayments: {
      isLoading: false,
      error: undefined,
      payments: action.payload
    }
  }))
  .handleAction(FetchWirePaymentActions.failure, (state, action) => ({
    ...state,
    wirePayments: {
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(CreateWireInstructionForSecurityActions.request, (state) => ({
    ...state,
    wireInstructionForSecurity: {
      isLoading: true,
      error: undefined,
      isWireCreated: false
    }
  }))
  .handleAction(CreateWireInstructionForSecurityActions.success, (state) => ({
    ...state,
    wireInstructionForSecurity: {
      isLoading: false,
      error: undefined,
      isWireCreated: true
    }
  }))
  .handleAction(
    CreateWireInstructionForSecurityActions.failure,
    (state, action) => ({
      ...state,
      wireInstructionForSecurity: {
        isLoading: false,
        error: action.payload,
        isWireCreated: false
      }
    })
  )
  .handleAction(CreateWireInstructionForSecurityActions.clear, (state) => ({
    ...state,
    wireInstructionForSecurity: undefined,
    abaDetail: undefined
  }))
