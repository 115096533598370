import { Row } from '@tanstack/react-table'
import { tokenizeQuery } from 'api/search'
import { IPerformanceReport } from 'modules/Advisory/modules/Rdot360/store/types'
import { colNames } from './columnDefinitions'

export const globalFilterFn = (
  row: Row<IPerformanceReport>,
  columnId: string,
  filterValue: string
): boolean => {
  const cols = [
    colNames.reportDate,
    colNames.reportName,
    colNames.portfolioName
  ]
  if (!cols.some((item) => item === columnId)) {
    return false
  }
  const filterLowerCase = String(filterValue).toLowerCase()
  const columnValue = row.getValue<string>(columnId)
  const tokens = tokenizeQuery(filterLowerCase)
  const lowerCaseValue = columnValue.toLowerCase()
  return tokens.every((token) => lowerCaseValue?.includes(token))
}
