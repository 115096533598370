import { CancelTokenSource } from 'axios'
import { call, cancelled, select } from 'typed-redux-saga'
import {
  batch,
  IOdataBatchRequest,
  IOdataBatchResponseItem
} from '../../../api/datahub'
import {
  constructHouseholdChangeRequestsQuery,
  getHouseholdChangeRequests,
  IHouseholdChangeRequest
} from '../../../api/households'
import { IOdataRequest } from '../../../api/odata.types'
import { getHouseholdApiOptions } from '../../../features/Households/store/sagas'
import {
  getChangeRequestEditListUiFilters,
  getChangeRequestEditListUiOrderBy,
  getChangeRequestEditListUiSearchText,
  IChangeRequestStatusUpdateActionPayload
} from '../../../modules/Households/store/changeRequestsList'
import { IApiOptions } from '../../../shared/contracts/IApiOptions'
import { IOdataResult } from '../../../shared/contracts/IOdataResult'
import { isNotNullOrFalse } from '../../../shared/guards'

export const getRequestParametersForHouseholdsChangeRequest = function* () {
  const searchText = yield* select(getChangeRequestEditListUiSearchText)
  const orderBy = yield* select(getChangeRequestEditListUiOrderBy)
  const filters = yield* select(getChangeRequestEditListUiFilters)

  const request: IOdataRequest = {
    search: searchText,
    orderby: orderBy ? [orderBy] : [],
    top: 50,
    filters: [
      !!filters?.status?.length &&
        `status in (${filters.status.map((x) => `'${x}'`).join(',')})`
    ].filter(isNotNullOrFalse)
  }

  return request
}

export const getHouseholdChangeRequestsFromApi = function* (
  request?: IOdataRequest
) {
  const [apiOptions, source]: [IApiOptions, CancelTokenSource] = yield call(
    getHouseholdApiOptions
  )

  request =
    request || (yield call(getRequestParametersForHouseholdsChangeRequest))

  try {
    const changeRequests: IOdataResult<IHouseholdChangeRequest> = yield call(
      getHouseholdChangeRequests,
      apiOptions,
      { ...request }
    )

    return changeRequests
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const updateEditHouseholdRequestStatusFromApi = function* (
  requests: IChangeRequestStatusUpdateActionPayload[]
) {
  const [apiOptions, source]: [IApiOptions, CancelTokenSource] = yield call(
    getHouseholdApiOptions
  )

  const listRequest: IOdataRequest = yield call(
    getRequestParametersForHouseholdsChangeRequest
  )

  const batchRequests: IOdataBatchRequest[] = [
    ...requests.map(
      ({ requestId, status }): IOdataBatchRequest => ({
        url: `householdchangerequests/${requestId}`,
        method: 'PATCH',
        body: { status }
      })
    ),
    {
      url: `householdchangerequests?${constructHouseholdChangeRequestsQuery(
        listRequest
      )}`,
      method: 'GET'
    } as IOdataBatchRequest
  ].filter(isNotNullOrFalse)

  try {
    const result = yield* call(batch, apiOptions, batchRequests)
    return result
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const deleteHouseholdChangeRequestsFromApi = function* (
  requests: number[]
) {
  const [apiOptions, source]: [IApiOptions, CancelTokenSource] = yield call(
    getHouseholdApiOptions
  )

  const listRequest: IOdataRequest = yield call(
    getRequestParametersForHouseholdsChangeRequest
  )

  const batchRequests: IOdataBatchRequest[] = [
    ...requests.map(
      (requestId): IOdataBatchRequest => ({
        url: `householdchangerequests/${requestId}`,
        method: 'DELETE'
      })
    ),
    {
      url: `householdchangerequests?${constructHouseholdChangeRequestsQuery(
        listRequest
      )}`,
      method: 'GET'
    } as IOdataBatchRequest
  ].filter(isNotNullOrFalse)

  try {
    const result: IOdataBatchResponseItem[] = yield call(
      batch,
      apiOptions,
      batchRequests
    )

    return result
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}
