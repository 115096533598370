import { base64toBlob } from 'modules/Reports/features/Dashboard/Utilities'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { getRockefellerApiOptions } from 'store/shared'
import { call, put, takeLatest } from 'typed-redux-saga'
import {
  downloadStatementService,
  fetchRelatedLoginsService,
  fetchStatementListService,
  setContextService,
  uploadReportService
} from '../api/StatementServices'
import {
  downloadStatementActions,
  fetchRelatedPartieActions,
  fetchStatementsActions,
  uploadReportActions
} from './actions'
import { IDownlaodResponse, IUploadReportResponse } from './types'

function* hanleFetchStatementList(
  action: ReturnType<typeof fetchStatementsActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)

    yield* call(() =>
      setContextService(options, action.payload.key, action.payload.profileId)
    )
    const data = yield* call(() =>
      fetchStatementListService(options, action.payload)
    )

    if (data) {
      yield put(fetchStatementsActions.success(data))
    } else {
      yield put(
        fetchStatementsActions.failure(
          new Error('Unable to fetch Statement List')
        )
      )
    }
  } catch (err: any) {
    yield put(fetchStatementsActions.failure(err))
  }
}

function* handleUploadReport(
  action: ReturnType<typeof uploadReportActions.request>
) {
  const {
    file,
    onUploadSuccess,
    onUploadFail,
    onUploadProgress,
    asofdate,
    sharewithuser,
    reportkey
  } = action.payload
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data: IUploadReportResponse = yield call(() =>
      uploadReportService(
        options,
        reportkey,
        file,
        asofdate,
        sharewithuser,
        onUploadProgress
      )
    )
    if (data.status?.toLowerCase() === 'success') {
      onUploadSuccess(file)
      yield put(uploadReportActions.success(data))
    } else {
      onUploadFail(file)
      yield put(
        uploadReportActions.failure(new Error('Unable to upload report'))
      )
    }
  } catch (e: any) {
    onUploadFail(file)
    yield put(uploadReportActions.failure(e))
  }
}

function* handleFetchRelatedParties(
  action: ReturnType<typeof fetchRelatedPartieActions.request>
) {
  // Call fetch related logins api here....
  try {
    const options = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchRelatedLoginsService(options, action.payload.partyId)
    )
    if (data && data.length > 0) {
      yield put(fetchRelatedPartieActions.success(data))
    } else {
      yield put(
        fetchRelatedPartieActions.failure(
          new Error('No logins are associated with this client')
        )
      )
    }
  } catch (e) {
    yield put(
      fetchRelatedPartieActions.failure(
        new Error(
          'Unable to fetch logins associated with this client. Please try again later.'
        )
      )
    )
  }
}

function* handleDownloadStatementFile(
  action: ReturnType<typeof downloadStatementActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IDownlaodResponse = yield* call(() =>
      downloadStatementService(options, action.payload)
    )
    if (data.status !== 'Failed') {
      yield put(downloadStatementActions.success())
      const blob = base64toBlob(data.base64contents, 'application/pdf')
      const url = URL.createObjectURL(blob)
      window.open(url)
    } else {
      yield put(downloadStatementActions.failure(new Error(data?.message)))
    }
  } catch (e: any) {
    yield put(downloadStatementActions.failure(e))
  }
}

export const sagas = [
  () => takeLatest(fetchStatementsActions.request, hanleFetchStatementList),
  () => takeLatest(uploadReportActions.request, handleUploadReport),
  () =>
    takeLatest(fetchRelatedPartieActions.request, handleFetchRelatedParties),
  () =>
    takeLatest(downloadStatementActions.request, handleDownloadStatementFile)
]
