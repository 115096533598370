import { ActionType, createAction } from 'typesafe-actions'
import {
  IPlanByProvider,
  FetchRetirementPlanTransactionType,
  IFetchPlanToDistributeCash,
  IPlanProvider,
  IPlansByProviderRequest,
  IProductPlan,
  IPlanApprove, ////approval dashboard
  IRetirementPlanTransactionsResponse,
  IRetirementProductCashReceipt,
  IGetMasterLookupResponse,
  IFetchPlansToDistributeCashRequest,
  IPlanExportReq,
  ICloseMonthRequest,
  IUploadProductDocumentRequest,
  IProjectedAmount,
  IProjectedAmountFetchRequest,
  IPlanAllocations,
  IFetchProductPlanTransactionTypeReq,
  INavigateByPlanRequest,
  IAddPlanProviderResponse,
  IFundDocsRequest,
  IFetchDocType,
  IFundDocTypeRequest,
  IUploadFundDocRequest,
  IPlanProviderDocs,
  IFetchPlanProviderDocsReq,
  IPlanProviderForExcel,
  IPlanExcelExportReq,
  IFetchProvidersforExcelReq,
  IUpdatePlanProviderDocReq,
  IAltsCashReceiptTrendReport,
  ICashReceiptTrendExportExcelReq,
  IAltsAccrualReport,
  IAccuralReportExportReq,
  IAccuralReportReportReq,
  ICashRecepitTrendReportReq,
  IOpenReceivablesReport,
  IOpenReceivablesReportReq,
  IOpenReceivablesExportReq,
  IAccuralReportDrilldownReq,
  IAccrualReportDrilldown,
  ITrendDrilldownExportReq,
  IClassification,
  IApproverAction,
  IApiResponse,
  IUploadManualRequest,
  IUploadFundres,
  IUploadManualAccrual
} from './../store/types'
import { RootObject } from './approvalDashboardComparisonTypes'
import { IworkflowHistory } from './workflowHistoryApprovalDashboardTypes'

export const SET_SELECTED_PLAN = `@modules/@products/SET_SELECTED_PLAN`
export const SET_SELECTED_FEETYPE = `@modules/@products/SET_SELECTED_FEETYPE`

export const FETCH_PLANS_TO_DISTRIBUTE_CASH = `@modules/@products/FETCH_PLANS_TO_DISTRIBUTE_CASH`
export const FETCH_PLANS_TO_DISTRIBUTE_CASH_SUCCESS = `@modules/@products/FETCH_PLANS_TO_DISTRIBUTE_CASH_SUCCESS`
export const FETCH_PLANS_TO_DISTRIBUTE_CASH_FAILURE = `@modules/@products/FETCH_PLANS_TO_DISTRIBUTE_CASH_FAILURE`
export const CLEAR_PLANS_TO_DISTRIBUTE_CASH = `@modules/@products/CLEAR_PLANS_TO_DISTRIBUTE_CASH`

export const SET_SELECTED_ACCRUAL_PERIOD = `@modules/@products/SET_SELECTED_ACCRUAL_PERIOD`

export const UPDATE_CASH_RECEIPT_TO_PLAN = `@modules/@products/UPDATE_CASH_RECEIPT_TO_PLAN`
export const UPDATE_CASH_RECEIPT_TO_PLAN_SUCCESS = `@modules/@products/UPDATE_CASH_RECEIPT_TO_PLAN_SUCCESS`
export const UPDATE_CASH_RECEIPT_TO_PLAN_FAILURE = `@modules/@products/UPDATE_CASH_RECEIPT_TO_PLAN_FAILURE`

export const SET_SELECTED_MONTH_YEAR = `@modules/@products/SET_SELECTED_MONTH_YEAR`

export const FETCH_RETIREMENT_PLAN_TRANSACTIONS =
  '@modules/@products/FETCH_RETIREMENT_PLAN_TRANSACTIONS'
export const FETCH_RETIREMENT_PLAN_TRANSACTIONS_SUCCESS =
  '@modules/@products/FETCH_RETIREMENT_PLAN_TRANSACTIONS_SUCCESS'
export const FETCH_RETIREMENT_PLAN_TRANSACTIONS_FAILURE =
  '@modules/@products/FETCH_RETIREMENT_PLAN_TRANSACTIONS_FAILURE'
export const FETCH_PLAN_PROVIDERS = `@modules/@products/@planproviders/FETCH_PLAN_PROVIDERS`
export const FETCH_PLAN_PROVIDERS_SUCCESS = `@modules//@products/@planproviders/FETCH_PLAN_PROVIDERS_SUCCESS`
export const FETCH_PLAN_PROVIDERS_FAILURE = `@modules//@products/@planproviders/FETCH_PLAN_PROVIDERS_FAILURE`
export const CLEAR_PLAN_PROVIDERS = `@modules/@products/@planproviders/CLEAR_PLAN_PROVIDERS`

export const FETCH_PLAN_PROVIDER_DOCS = `@modules/@products/@planproviders/FETCH_PLAN_PROVIDER_DOCS`
export const FETCH_PLAN_PROVIDER_DOCS_SUCCESS = `@modules//@products/@planproviders/FETCH_PLAN_PROVIDER_DOCS_SUCCESS`
export const FETCH_PLAN_PROVIDER_DOCS_FAILURE = `@modules//@products/@planproviders/FETCH_PLAN_PROVIDER_DOCS_FAILURE`
export const FETCH_FUND_PROVIDERS_EXCEL = `@modules/@products/@planproviders/FETCH_FUND_PROVIDERS_EXCEL`
export const FETCH_FUND_PROVIDERS_EXCEL_SUCCESS = `@modules//@products/@planproviders/FETCH_FUND_PROVIDERS_EXCEL_SUCCESS`
export const FETCH_FUND_PROVIDERS_EXCEL_FAILURE = `@modules//@products/@planproviders/FETCH_FUND_PROVIDERS_EXCEL_FAILURE`

export const UPDATE_PLAN_PROVIDER_DOC_REQUEST = `@modules/@products/@planproviders/UPDATE_PLAN_PROVIDER_DOC_REQUEST`
export const UPDATE_PLAN_PROVIDER_DOC_SUCCESS = `@modules//@products/@planproviders/UPDATE_PLAN_PROVIDER_DOC_SUCCESS`
export const UPDATE_PLAN_PROVIDER_DOC_FAILURE = `@modules//@products/@planproviders/UPDATE_PLAN_PROVIDER_DOC_FAILURE`

export const FETCH_FEE_TYPES = `@modules/@products/FETCH_FEE_TYPES`
export const FETCH_FEE_TYPES_SUCCESS = `@modules/@products/FETCH_FEE_TYPES_SUCCESS`
export const FETCH_FEE_TYPES_FAILURE = `@modules/@products/FETCH_FEE_TYPES_FAILURE`

export const FETCH_RETIREMENT_OPEN_RECEIVABLE_RPT =
  '@modules/@products/@retirement/FETCH_RETIREMENT_OPEN_RECEIVABLE_RPT'
export const FETCH_RETIREMENT_OPEN_RECEIVABLE_RPT_SUCCESS =
  '@modules/@products/@retirement/FETCH_RETIREMENT_OPEN_RECEIVABLE_RPT_SUCCESS'
export const FETCH_RETIREMENT_OPEN_RECEIVABLE_RPT_FAILURE =
  '@modules/@products/@retirement/FETCH_RETIREMENT_OPEN_RECEIVABLE_RPT_FAILURE'
export const EXPORT_OPEN_RECEIVABLE_RPT =
  '@modules/@products/@retirement/EXPORT_OPEN_RECEIVABLE_RPT'

export const fetchOpenReceivablesReportActions = {
  request: createAction(
    FETCH_RETIREMENT_OPEN_RECEIVABLE_RPT
  )<IOpenReceivablesReportReq>(),
  success: createAction(
    FETCH_RETIREMENT_OPEN_RECEIVABLE_RPT_SUCCESS
  )<IOpenReceivablesReport>(),
  failure: createAction(FETCH_RETIREMENT_OPEN_RECEIVABLE_RPT_FAILURE)<Error>()
}

export const exportOpenReceivablesReportActions = {
  export: createAction(EXPORT_OPEN_RECEIVABLE_RPT)<IOpenReceivablesExportReq>()
}
export const fetchPlanProvidersActions = {
  request: createAction(FETCH_PLAN_PROVIDERS)<string>(),
  success: createAction(FETCH_PLAN_PROVIDERS_SUCCESS)<IPlanProvider[]>(),
  failure: createAction(FETCH_PLAN_PROVIDERS_FAILURE)<Error>(),
  clear: createAction(CLEAR_PLAN_PROVIDERS)()
}
export const fetchProvidersforExcelActions = {
  request: createAction(
    FETCH_FUND_PROVIDERS_EXCEL
  )<IFetchProvidersforExcelReq>(),
  success: createAction(FETCH_FUND_PROVIDERS_EXCEL_SUCCESS)<
    IPlanProviderForExcel[]
  >(),
  failure: createAction(FETCH_FUND_PROVIDERS_EXCEL_FAILURE)<Error>()
}

export const CLEAR_RETIREMENT_PLAN_TRANSACTIONS =
  '@modules/@products/CLEAR_RETIREMENT_PLAN_TRANSACTIONS'

export const FETCH_PRODUCT_PLANS =
  '@modules/@products/@productCommon/FETCH_PRODUCT_PLANS'
export const FETCH_PRODUCT_PLANS_SUCCESS =
  '@modules/@products/@productCommon/FETCH_PRODUCT_PLANS_SUCCESS'
export const FETCH_PRODUCT_PLANS_FAILURE =
  '@modules/@products/@productCommon/FETCH_PRODUCT_PLANS_FAILURE'

export const NAVIGATE_TO_PLAN_TRANSACTIONS = `@modules/@products/@productCommon/NAVIGATE_TO_PLAN_TRANSACTIONS`
export const NAVIGATE_TO_PROJECTED_AMOUNT_BY_PLAN = `@modules/@products/@productCommon/NAVIGATE_TO_PROJECTED_AMOUNT_BY_PLAN`
export const NAVIGATE_TO_PLAN = `@modules/@products/@productCommon/NAVIAGTE_TO_PLAN`
export const NAVIGATE_TO_PLAN_TRANSACTIONS_BY_CLIENT = `@modules/@products/@productCommon/NAVIGATE_TO_PLAN_TRANSACTIONS_BY_CLIENT`

export const EXPORT_PLANS = `@modules/@products/@productCommon/EXPORT_PLANS`
export const EXPORT_PLANS_SUCCESS = `@modules/@products/@productCommon/EXPORT_PLANS_SUCCESS`
export const EXPORT_PLANS_FAILURE = `@modules/@products/@productCommon/EXPORT_PLANS_FAILURE`

export const EXPORT_FUND_PLANS = `@modules/@products/@productCommon/EXPORT_FUND_PLANS`
export const EXPORT_FUND_PLANS_SUCCESS = `@modules/@products/@productCommon/EXPORT_FUND_PLANS_SUCCESS`
export const EXPORT_FUND_PLANS_FAILURE = `@modules/@products/@productCommon/EXPORT_FUND_PLANS_FAILURE`

export const DOWNLOAD_D365_EXCEL =
  '@modules/@products/@productCommon/DOWNLOAD_D365_EXCEL'
export const DOWNLOAD_D365_EXCEL_SUCCESS =
  '@modules/@products/@productCommon/DOWNLOAD_D365_EXCEL_SUCCESS'
export const DOWNLOAD_D365_EXCEL_FAILURE =
  '@modules/@products/@productCommon/DOWNLOAD_D365_EXCEL_FAILURE'

export const CLOSE_MONTH = `@modules/@products/@productCommon/CLOSE_MONTH`
export const CLOSE_MONTH_SUCCESS = `@modules/@products/@productCommon/CLOSE_MONTH_SUCCESS`
export const CLOSE_MONTH_FAILURE = `@modules/@products/@productCommon/CLOSE_MONTH_SUCCESS`

export const VIEW_STATEMENT = `@modules/@products/@productCommon/VIEW_STATEMENT`
export const VIEW_STATEMENT_SUCCESS = `@modules/@products/@productCommon/VIEW_STATEMENT_SUCCESS`
export const VIEW_STATEMENT_FAILURE = `@modules/@products/@productCommon/VIEW_STATEMENT_FAILURE`

export const DELETE_STATEMENT = `@modules/@products/@productCommon/DELETE_STATEMENT`
export const DELETE_STATEMENT_SUCCESS = `@modules/@products/@productCommon/DELETE_STATEMENT_SUCCESS`
export const DELETE_STATEMENT_FAILURE = `@modules/@products/@productCommon/DELETE_STATEMENT_FAILURE`

export const UPLOAD_PLAN_DOCUMENTS = `@modules/@products/@productCommon/UPLOAD_PLAN_DOCUMENT`

export const UPLOAD_MANUAL_ACCRUAL = `@modules/@products/@productCommon/MANUAL_UPLOAD_REQUEST`

export const FETCH_PROJECTED_AMOUNT_BY_PLAN = `@modules/@products/@productCommon/FETCH_PROJECTED_AMOUNT_BY_PLAN`
export const FETCH_PROJECTED_AMOUNT_BY_PLAN_SUCCESS = `@modules/@products/@productCommon/FETCH_PROJECTED_AMOUNT_BY_PLAN_SUCCESS`
export const FETCH_PROJECTED_AMOUNT_BY_PLAN_FAILURE = `@modules/@products/@productCommon/FETCH_PROJECTED_AMOUNT_BY_PLAN_FAILURE`
export const CLEAR_PROJECTED_AMOUNT_BY_PLAN = `@modules/@products/@productCommon/CLEAR_PROJECTED_AMOUNT_BY_PLAN`

export const UPDATE_PROJECTED_AMOUNT_BY_PLAN = `@modules/@products/@productCommon/UPDATE_PROJECTED_AMOUNT_BY_PLAN`
export const UPDATE_PROJECTED_AMOUNT_BY_PLAN_SUCCESS = `@modules/@products/@productCommon/UPDATE_PROJECTED_AMOUNT_BY_PLAN_SUCCESS`
export const UPDATE_PROJECTED_AMOUNT_BY_PLAN_FAILURE = `@modules/@products/@productCommon/UPDATE_PROJECTED_AMOUNT_BY_PLAN_FAILURE`

export const FETCH_PRODUCT_PLAN_ALLOCATIONS = `@modules/@products/@productCommon/FETCH_PRODUCT_PLAN_ALLOCATIONS`
export const FETCH_PRODUCT_PLAN_ALLOCATIONS_SUCCESS = `@modules/@products/@productCommon/FETCH_PRODUCT_PLAN_ALLOCATIONS_SUCCESS`
export const FETCH_PRODUCT_PLAN_ALLOCATIONS_FAILURE = `@modules/@products/@productCommon/FETCH_PRODUCT_PLAN_ALLOCATIONS_FAILURE`
export const CLEAR_PRODUCT_PLAN_ALLOCATIONS = `@modules/@products/@productCommon/CLEAR_PRODUCT_PLAN_ALLOCATIONS`

export const FETCH_CASH_RECEIPT_TREND_REPORT =
  '@modules/@products/FETCH_CASH_RECEIPT_TREND_REPORT'
export const FETCH_CASH_RECEIPT_TREND_REPORT_SUCCESS =
  '@modules/@products/FETCH_CASH_RECEIPT_TREND_REPORT_SUCCESS'
export const FETCH_CASH_RECEIPT_TREND_REPORT_FAILURE =
  '@modules/@products/FETCH_CASH_RECEIPT_TREND_REPORT_FAILURE'

export const EXPORT_EXCEL_CASH_RECEIPT_TREND_REPORT = `@modules/@products/@productCommon/EXPORT_CASH_RECEIPT_TREND_REPORT`
export const EXPORT_EXCEL_CASH_RECEIPT_TREND_REPORT_SUCCESS = `@modules/@products/@productCommon/EXPORT_CASH_RECEIPT_TREND_REPORT_SUCCESS`
export const EXPORT_EXCEL_CASH_RECEIPT_TREND_REPORT_FAILURE = `@modules/@products/@productCommon/EXPORT_CASH_RECEIPT_TREND_REPORT_FAILURE`

export const setSelectedPlan = {
  selectedPlan: createAction(SET_SELECTED_PLAN)<
    IProductPlan | undefined | null
  >()
}
export const setSelectedFeeType = {
  selectedFeeType: createAction(SET_SELECTED_FEETYPE)<string>()
}
export const fetchPlansToDistributeCashActions = {
  request: createAction(
    FETCH_PLANS_TO_DISTRIBUTE_CASH
  )<IFetchPlansToDistributeCashRequest>(),
  success: createAction(FETCH_PLANS_TO_DISTRIBUTE_CASH_SUCCESS)<
    IFetchPlanToDistributeCash[]
  >(),
  failure: createAction(FETCH_PLANS_TO_DISTRIBUTE_CASH_FAILURE)<Error>(),
  clear: createAction(CLEAR_PLANS_TO_DISTRIBUTE_CASH)()
}
export const setSelectedAccrualPeriodActions = {
  setPeriod: createAction(SET_SELECTED_ACCRUAL_PERIOD)<string>()
}
export const updateCashReceiptToPlanActions = {
  request: createAction(UPDATE_CASH_RECEIPT_TO_PLAN)<{
    plans: IFetchPlanToDistributeCash[]
    cashReceipt: IRetirementProductCashReceipt
    productType: string
    feetype: string
  }>(),
  success: createAction(UPDATE_CASH_RECEIPT_TO_PLAN_SUCCESS)(),
  failure: createAction(UPDATE_CASH_RECEIPT_TO_PLAN_FAILURE)<Error>()
}
export const setSelectedMonthYearActions = {
  selectedMonthYear: createAction(SET_SELECTED_MONTH_YEAR)<string>()
}
export const fetchRetirementPlanTransactionsAction = {
  request: createAction(
    FETCH_RETIREMENT_PLAN_TRANSACTIONS
  )<FetchRetirementPlanTransactionType>(),
  success: createAction(
    FETCH_RETIREMENT_PLAN_TRANSACTIONS_SUCCESS
  )<IRetirementPlanTransactionsResponse>(),
  failure: createAction(FETCH_RETIREMENT_PLAN_TRANSACTIONS_FAILURE)<Error>(),
  clear: createAction(CLEAR_RETIREMENT_PLAN_TRANSACTIONS)()
}
export const FETCH_PLANS_BY_PROVIDER = `@modules/@products/FETCH_PLANS_BY_PROVIDER`
export const FETCH_PLANS_BY_PROVIDER_SUCCESS = `@modules/@products/FETCH_PLANS_BY_PROVIDER_SUCCESS`
export const FETCH_PLANS_BY_PROVIDER_FAILURE = `@modules/@products/FETCH_PLANS_BY_PROVIDER_FAILURE`

export const fetchPlansByProviderActions = {
  request: createAction(FETCH_PLANS_BY_PROVIDER)<IPlansByProviderRequest>(),
  success: createAction(FETCH_PLANS_BY_PROVIDER_SUCCESS)<{
    planproviderId: number
    plans: IPlanByProvider[]
  }>(),
  failure: createAction(FETCH_PLANS_BY_PROVIDER_FAILURE)<{
    planproviderId: number
    error: Error
  }>()
}

export const FETCH_DRAFT_PLANS_DETAILS = `@modules/@products/FETCH_DRAFT_PLANS_DETAILS`
export const FETCH_DRAFT_PLANS_RESET_DETAILS = `@modules/@products/FETCH_DRAFT_PLANS_RESET_DETAILS`
export const FETCH_DRAFT_PLANS_DETAILS_SUCCESS = `@modules/@products/FETCH_DRAFT_PLANS_DETAILS_SUCCESS`
export const FETCH_DRAFT_PLANS_DETAILS_FAILURE = `@modules/@products/FETCH_DRAFT_PLANS_DETAILS_FAILURE`

export const fetchDraftPlansActions = {
  reset: createAction(FETCH_DRAFT_PLANS_RESET_DETAILS)(),
  request: createAction(FETCH_DRAFT_PLANS_DETAILS)<{
    draftId?: number
    planproviderid?: number
  }>(),
  success: createAction(FETCH_DRAFT_PLANS_DETAILS_SUCCESS)<{
    planproviderId?: number
    plans: IPlanByProvider[]
  }>(),
  failure: createAction(FETCH_DRAFT_PLANS_DETAILS_FAILURE)<{
    planproviderId?: number
    error: Error
  }>()
}

export const FETCH_COMMITTED_ACTION = `@modules/@products/FETCH_COMMITTED_ACTION`
export const FETCH_COMMITTED_RESET_ACTION = `@modules/@products/FETCH_COMMITTED_RESET_ACTION`
export const FETCH_COMMITTED_ACTION_SUCCESS = `@modules/@products/FETCH_COMMITTED_ACTION_SUCCESS`
export const FETCH_COMMITTED_ACTION_FAILURE = `@modules/@products/FETCH_COMMITTED_ACTION_FAILURE`

export const fetchCommittedActions = {
  request: createAction(FETCH_COMMITTED_ACTION)<{
    planProviderId: number
    PlanId: number
    productType: string
  }>(),
  reset: createAction(FETCH_COMMITTED_RESET_ACTION)(),
  success: createAction(FETCH_COMMITTED_ACTION_SUCCESS)<{
    plans: IPlanByProvider[]
  }>(),
  failure: createAction(FETCH_COMMITTED_ACTION_FAILURE)<{
    error: Error
  }>()
}

////approval dashboard
export const FETCH_AI_PLANS_APPROVAL_DASHBOARD = `@modules/@products/FETCH_AI_PLANS_APPROVAL_DASHBOARD`
export const FETCH_AI_PLANS_APPROVAL_DASHBOARD_SUCCESS = `@modules/@products/FETCH_AI_PLANS_APPROVAL_DASHBOARD_SUCCESS`
export const FETCH_AI_PLANS_APPROVAL_DASHBOARD_FAILURE = `@modules/@products/FETCH_AI_PLANS_APPROVAL_DASHBOARD_FAILURE`
export const fetchAIPlansApproveDashboardActions = {
  request: createAction(FETCH_AI_PLANS_APPROVAL_DASHBOARD)(),
  success: createAction(FETCH_AI_PLANS_APPROVAL_DASHBOARD_SUCCESS)<{
    aiFunds: IPlanApprove[]
  }>(),
  failure: createAction(FETCH_AI_PLANS_APPROVAL_DASHBOARD_FAILURE)<{
    error: Error
  }>()
}

////approval dashboard
export const FETCH_WORKFLOW_PLAN_HISTORY_APPROVAL_DASHBOARD = `@modules/@products/FETCH_WORKFLOW_PLAN_HISTORY_APPROVAL_DASHBOARD`
export const FETCH_WORKFLOW_PLAN_HISTORY_APPROVAL_DASHBOARD_SUCCESS = `@modules/@products/FETCH_WORKFLOW_PLAN_HISTORY_APPROVAL_DASHBOARD_SUCCESS`
export const FETCH_WORKFLOW_PLAN_HISTORY_APPROVAL_DASHBOARD_FAILURE = `@modules/@products/FETCH_WORKFLOW_PLAN_HISTORY_APPROVAL_DASHBOARD_FAILURE`
export const fetchWorkflowPlanHistoryApproveDashboardActions = {
  request: createAction(FETCH_WORKFLOW_PLAN_HISTORY_APPROVAL_DASHBOARD)<{
    draftId: number
  }>(),
  success: createAction(
    FETCH_WORKFLOW_PLAN_HISTORY_APPROVAL_DASHBOARD_SUCCESS
  )<{
    workflowHistory: IworkflowHistory[]
  }>(),
  failure: createAction(
    FETCH_WORKFLOW_PLAN_HISTORY_APPROVAL_DASHBOARD_FAILURE
  )<{
    error: Error
  }>()
}

export const FETCH_AIDRAFT_PLANS_APPROVAL_DASHBOARD = `@modules/@products/FETCH_AIDRAFT_PLANS_APPROVAL_DASHBOARD`
export const FETCH_AIDRAFT_PLANS_APPROVAL_DASHBOARD_SUCCESS = `@modules/@products/FETCH_AIDRAFT_PLANS_APPROVAL_DASHBOARD_SUCCESS`
export const FETCH_AIDRAFT_PLANS_APPROVAL_DASHBOARD_FAILURE = `@modules/@products/FETCH_AIDRAFT_PLANS_APPROVAL_DASHBOARD_FAILURE`
export const fetchAIDRAFTPlansApproveDashboardActions = {
  request: createAction(FETCH_AIDRAFT_PLANS_APPROVAL_DASHBOARD)<{
    providerId?: number
    planId?: number
    draftId?: number
  }>(),
  success: createAction(
    FETCH_AIDRAFT_PLANS_APPROVAL_DASHBOARD_SUCCESS
  )<IPlanByProvider>(),
  failure: createAction(FETCH_AIDRAFT_PLANS_APPROVAL_DASHBOARD_FAILURE)<{
    error: Error
  }>()
}

export const FETCH_COMPARISON_DATA_APPROVAL_DASHBOARD = `@modules/@products/FETCH_COMPARISON_DATA_APPROVAL_DASHBOARD`
export const FETCH_COMPARISON_DATA_APPROVAL_DASHBOARD_SUCCESS = `@modules/@products/FETCH_COMPARISON_DATA_APPROVAL_DASHBOARD_SUCCESS`
export const FETCH_COMPARISON_DATA_APPROVAL_DASHBOARD_FAILURE = `@modules/@products/FETCH_COMPARISON_DATA_APPROVAL_DASHBOARD_FAILURE`
export const fetchComparisonApproveDashboardActions = {
  request: createAction(FETCH_COMPARISON_DATA_APPROVAL_DASHBOARD)<{
    providerId?: number
    planId?: number
    draftId?: number
  }>(),
  success: createAction(
    FETCH_COMPARISON_DATA_APPROVAL_DASHBOARD_SUCCESS
  )<RootObject>(),
  failure: createAction(FETCH_COMPARISON_DATA_APPROVAL_DASHBOARD_FAILURE)<{
    error: Error
  }>()
}

export const HANDLE_ACTION_APPROVAL_DASHBOARD = `HANDLE_ACTION_APPROVAL_DASHBOARD`
export const HANDLE_ACTION_APPROVAL_DASHBOARD_SUCCESS = `HANDLE_ACTION_APPROVAL_DASHBOARD_SUCCESS`
export const HANDLE_ACTION_APPROVAL_DASHBOARD_FAILURE = `HANDLE_ACTION_APPROVAL_DASHBOARD_FAILURE`
export const HANDLE_ACTION_APPROVAL_DASHBOARD_CLEAR = `HANDLE_ACTION_APPROVAL_DASHBOARD_CLEAR`
export const handleApproverActionApprovalDashboard = {
  request: createAction(HANDLE_ACTION_APPROVAL_DASHBOARD)<IApproverAction>(),
  success: createAction(
    HANDLE_ACTION_APPROVAL_DASHBOARD_SUCCESS
  )<IApiResponse>(),
  failure: createAction(HANDLE_ACTION_APPROVAL_DASHBOARD_FAILURE)<{
    error: Error
  }>(),
  clear: createAction(HANDLE_ACTION_APPROVAL_DASHBOARD_CLEAR)()
}

export const fetchProductPlansAction = {
  request: createAction(FETCH_PRODUCT_PLANS)<string>(),
  success: createAction(FETCH_PRODUCT_PLANS_SUCCESS)<IProductPlan[]>(),
  failure: createAction(FETCH_PRODUCT_PLANS_FAILURE)<Error>()
}
export const UPDATE_PLAN_PROVIDER = `@modules/@products/UPDATE_PLAN_PROVIDER`
export const UPDATE_PLAN_PROVIDER_SUCCESS = `@modules/@products/UPDATE_PLAN_PROVIDER_SUCCESS`
export const UPDATE_PLAN_PROVIDER_FAILURE = `@modules/@products/UPDATE_PLAN_PROVIDER_FAILURE`

export const updatePlanProviderActions = {
  request: createAction(UPDATE_PLAN_PROVIDER)<IPlansByProviderRequest>(),
  success: createAction(UPDATE_PLAN_PROVIDER_SUCCESS)(),
  failure: createAction(UPDATE_PLAN_PROVIDER_FAILURE)<Error>()
}
export const UPDATE_PLAN_BY_PROVIDER = `@modules/@products/UPDATE_PLAN_BY_PROVIDER`
export const UPDATE_PLAN_BY_PROVIDER_SUCCESS = `@modules/@products/UPDATE_PLAN_BY_PROVIDER_SUCCESS`
export const UPDATE_PLAN_BY_PROVIDER_FAILURE = `@modules/@products/UPDATE_PLAN_BY_PROVIDER_FAILURE`

export const updatePlanPlanByProviderActions = {
  request: createAction(UPDATE_PLAN_BY_PROVIDER)<{
    provider: IPlanProvider
    plan: IPlanByProvider
    source?: string
    selectedOption?: string
  }>(),
  success: createAction(
    UPDATE_PLAN_BY_PROVIDER_SUCCESS
  )<IAddPlanProviderResponse>(),
  failure: createAction(UPDATE_PLAN_BY_PROVIDER_FAILURE)<Error>()
}

export const fetchFeeTypesActions = {
  request: createAction(FETCH_FEE_TYPES)<{ type: string }>(),
  success: createAction(FETCH_FEE_TYPES_SUCCESS)<IGetMasterLookupResponse>(),
  failure: createAction(FETCH_FEE_TYPES_FAILURE)<Error>()
}

export const productNavigationActions = {
  navigatetoTransaction: createAction(
    NAVIGATE_TO_PLAN_TRANSACTIONS
  )<INavigateByPlanRequest>(),
  navigateToProjectedAmountByPlan: createAction(
    NAVIGATE_TO_PROJECTED_AMOUNT_BY_PLAN
  )<INavigateByPlanRequest>(),
  navigateToPlan: createAction(NAVIGATE_TO_PLAN)<string>(),
  naviagteToTransactionByClient: createAction(
    NAVIGATE_TO_PLAN_TRANSACTIONS_BY_CLIENT
  )<IProductPlan>()
}
export const exportPlansToExcelActions = {
  request: createAction(EXPORT_PLANS)<IPlanExportReq>(),
  success: createAction(EXPORT_PLANS_SUCCESS)(),
  failure: createAction(EXPORT_PLANS_FAILURE)<Error>()
}
export const exportToExcelActions = {
  request: createAction(EXPORT_FUND_PLANS)<IPlanExcelExportReq>(),
  success: createAction(EXPORT_FUND_PLANS_SUCCESS)(),
  failure: createAction(EXPORT_FUND_PLANS_FAILURE)<Error>()
}
export const downloadD365ExcelActions = {
  request: createAction(DOWNLOAD_D365_EXCEL)<string>(),
  success: createAction(DOWNLOAD_D365_EXCEL_SUCCESS)(),
  failure: createAction(DOWNLOAD_D365_EXCEL_FAILURE)<Error>()
}
export const closeMonthActions = {
  request: createAction(CLOSE_MONTH)<ICloseMonthRequest>(),
  success: createAction(CLOSE_MONTH_SUCCESS)<string>(),
  failure: createAction(CLOSE_MONTH_FAILURE)<Error>()
}
export const viewStatementActions = {
  request: createAction(VIEW_STATEMENT)<{
    planid: string
    period: string
    producttype: string
    plandocumentid?: number
  }>(),
  success: createAction(VIEW_STATEMENT_SUCCESS)(),
  failure: createAction(VIEW_STATEMENT_FAILURE)<Error>()
}
export const deleteStatementActions = {
  request: createAction(DELETE_STATEMENT)<{
    planproviderid?: string
    producttype: string
    plandocumentid?: number
    isPlanSpecific?: boolean
    planid?: number | null
  }>(),
  success: createAction(DELETE_STATEMENT_SUCCESS)(),
  failure: createAction(DELETE_STATEMENT_FAILURE)<Error>()
}
export const uploadProductDocumentActions = {
  uploadDoc: createAction(
    UPLOAD_PLAN_DOCUMENTS
  )<IUploadProductDocumentRequest>()
}

export const fetchProjectedAmountByPlansActions = {
  request: createAction(
    FETCH_PROJECTED_AMOUNT_BY_PLAN
  )<IProjectedAmountFetchRequest>(),
  success: createAction(FETCH_PROJECTED_AMOUNT_BY_PLAN_SUCCESS)<
    IProjectedAmount[]
  >(),
  failure: createAction(FETCH_PROJECTED_AMOUNT_BY_PLAN_FAILURE)<Error>(),
  clear: createAction(CLEAR_PROJECTED_AMOUNT_BY_PLAN)()
}

export const updateProjectedAmountByPlansActions = {
  request: createAction(UPDATE_PROJECTED_AMOUNT_BY_PLAN)<IProjectedAmount[]>(),
  success: createAction(UPDATE_PROJECTED_AMOUNT_BY_PLAN_SUCCESS)(),
  failure: createAction(UPDATE_PROJECTED_AMOUNT_BY_PLAN_FAILURE)<Error>()
}
export const UPLOAD_FUND_DOC = `@modules/@products/UPLOAD_FUND_DOC`
export const UPLOAD_FUND_DOC_SUCCESS = `@modules/@products/UPLOAD_FUND_DOC_SUCCESS`
export const UPLOAD_FUND_DOC_FAILURE = `@modules/@products/UPLOAD_FUND_DOC_FAILURE`

export const uploadFundDocActions = {
  request: createAction(UPLOAD_FUND_DOC)<IUploadFundDocRequest>(),
  success: createAction(UPLOAD_FUND_DOC_SUCCESS)<IUploadFundres>(),
  failure: createAction(UPLOAD_FUND_DOC_FAILURE)<Error>()
}

export const CLASSIFICATION_TYPES = `@modules/@products/CLASSIFICATION_TYPES`
export const CLASSIFICATION_TYPES_SUCCESS = `@modules/@products/CLASSIFICATION_TYPES_SUCCESS`
export const CLASSIFICATION_TYPES_FAILURE = `@modules/@products/CLASSIFICATION_TYPES_FAILURE`

export const fetchClassificationTypesActions = {
  request: createAction(CLASSIFICATION_TYPES)(),
  success: createAction(CLASSIFICATION_TYPES_SUCCESS)<IClassification[]>(),
  failure: createAction(CLASSIFICATION_TYPES_FAILURE)<Error>()
}

export const DRAFTPLANPROVIDER_TYPES = `@modules/@products/DRAFTPLANPROVIDER`
export const DRAFTPLANPROVIDER_TYPES_SUCCESS = `@modules/@products/DRAFTPLANPROVIDER_SUCCESS`
export const DRAFTPLANPROVIDER_TYPES_FAILURE = `@modules/@products/DRAFTPLANPROVIDER_FAILURE`

export const fetchDraftPlansByProviderServiceTypesActions = {
  request: createAction(DRAFTPLANPROVIDER_TYPES)<{
    draftId?: number
    planproviderid?: number
  }>(),
  success: createAction(DRAFTPLANPROVIDER_TYPES_SUCCESS)<IClassification[]>(),
  failure: createAction(DRAFTPLANPROVIDER_TYPES_FAILURE)<Error>()
}

export type UploadFundDocActionType = ActionType<typeof uploadFundDocActions>
export const DOWNLOAD_FUND_DOC = `@modules/@products/DOWNLOAD_FUND_DOC`
export const DOWNLOAD_FUND_DOC_SUCCESS = `@modules/@products/DOWNLOAD_FUND_DOC_SUCCESS`
export const DOWNLOAD_FUND_DOC_FAILURE = `@modules/@products/DOWNLOAD_FUND_DOC_FAILURE`

export const downloadFundDocActions = {
  request: createAction(DOWNLOAD_FUND_DOC)<{
    storagedocdraftid: string
    storagedocid: string
    draftid?: number
  }>(),
  success: createAction(DOWNLOAD_FUND_DOC_SUCCESS)(),
  failure: createAction(DOWNLOAD_FUND_DOC_FAILURE)<Error>()
}
export type DownloadFundDocActionType = ActionType<
  typeof downloadFundDocActions
>
export const fetchProductPlanAllocationActions = {
  request: createAction(
    FETCH_PRODUCT_PLAN_ALLOCATIONS
  )<IFetchProductPlanTransactionTypeReq>(),
  success: createAction(
    FETCH_PRODUCT_PLAN_ALLOCATIONS_SUCCESS
  )<IPlanAllocations>(),
  failure: createAction(FETCH_PRODUCT_PLAN_ALLOCATIONS_FAILURE)<Error>(),
  clear: createAction(CLEAR_PRODUCT_PLAN_ALLOCATIONS)()
}

export const FETCH_UPLOAD_DOC_TYPES = `@modules/@products/FETCH_UPLOAD_DOC_TYPES`
export const FETCH_UPLOAD_DOC_TYPES_SUCCESS = `@modules/@products/FETCH_UPLOAD_DOC_TYPES_SUCCESS`
export const FETCH_UPLOAD_DOC_TYPES_FAILURE = `@modules/@products/FETCH_UPLOAD_DOC_TYPES_FAILURE`

export const fetchUploadDocTypeActions = {
  request: createAction(FETCH_UPLOAD_DOC_TYPES)<IFundDocTypeRequest>(),
  success: createAction(FETCH_UPLOAD_DOC_TYPES_SUCCESS)<IFetchDocType[]>(),
  failure: createAction(FETCH_UPLOAD_DOC_TYPES_FAILURE)<Error>()
}
export type FetchUploadDocTypeActions = ActionType<
  typeof fetchUploadDocTypeActions
>
export const FETCH_UPLOAD_DOC_LIST = `@modules/@products/FETCH_UPLOAD_DOC_LIST`
export const FETCH_UPLOAD_DOC_LIST_SUCCESS = `@modules/@products/FETCH_UPLOAD_DOC_LIST_SUCCESS`
export const FETCH_UPLOAD_DOC_LIST_FAILURE = `@modules/@products/FETCH_UPLOAD_DOC_LIST_FAILURE`

export const fetchUploadDocListActions = {
  request: createAction(FETCH_UPLOAD_DOC_LIST)<IFundDocsRequest>(),
  success: createAction(FETCH_UPLOAD_DOC_LIST_SUCCESS)<IFetchDocType[]>(),
  failure: createAction(FETCH_UPLOAD_DOC_TYPES)<Error>()
}
export const LAUNCH_FUND_DOC = `@modules/@products/LAUNCH_FUND_DOC`
export const navigateactions = {
  launchFundDoc: createAction(LAUNCH_FUND_DOC)<string>()
}

export const DELETE_FUND_DOC = `@modules/@products/DELETE_FUND_DOC`
export const DELETE_FUND_DOC_SUCCESS = `@modules/@products/DELETE_FUND_DOC_SUCCESS`
export const DELETE_FUND_DOC_FAILURE = `@modules/@products/DELETE_FUND_DOC_FAILURE`

export const deleteFundDocActions = {
  request: createAction(DELETE_FUND_DOC)<{
    storagedocdraftid: string
    draftid?: number
  }>(),
  success: createAction(DELETE_FUND_DOC_SUCCESS)<IUploadFundres>(),
  failure: createAction(DELETE_FUND_DOC_FAILURE)<Error>()
}

export const fetchPlanProvidersDocsActions = {
  request: createAction(FETCH_PLAN_PROVIDER_DOCS)<IFetchPlanProviderDocsReq>(),
  success: createAction(FETCH_PLAN_PROVIDER_DOCS_SUCCESS)<
    IPlanProviderDocs[]
  >(),
  failure: createAction(FETCH_PLAN_PROVIDER_DOCS_FAILURE)<Error>()
}
export const updatePlanProviderDocActions = {
  request: createAction(
    UPDATE_PLAN_PROVIDER_DOC_REQUEST
  )<IUpdatePlanProviderDocReq>(),
  success: createAction(UPDATE_PLAN_PROVIDER_DOC_SUCCESS)(),
  failure: createAction(UPDATE_PLAN_PROVIDER_DOC_FAILURE)<Error>()
}

export const FETCH_ACCRUAL_REPORT = '@modules/@products/FETCH_ACCRUAL_REPORT'
export const FETCH_ACCRUAL_REPORT_SUCCESS =
  '@modules/@products/FETCH_ACCRUAL_REPORT_SUCCESS'
export const FETCH_ACCRUAL_REPORT_FAILURE =
  '@modules/@products/FETCH_ACCRUAL_REPORT_FAILURE'

export const fetchAccrualReport = {
  request: createAction(FETCH_ACCRUAL_REPORT)<IAccuralReportReportReq>(),
  success: createAction(FETCH_ACCRUAL_REPORT_SUCCESS)<IAltsAccrualReport>(),
  failure: createAction(FETCH_ACCRUAL_REPORT_FAILURE)<Error>()
}

export const EXPORT_ACCRUAL_REPORT = `@modules/@products/@productCommon/EXPORT_ACCRUAL_REPORT`
export const EXPORT_ACCRUAL_REPORT_SUCCESS = `@modules/@products/@productCommon/EXPORT_ACCRUAL_REPORT_SUCCESS`
export const EXPORT_ACCRUAL_REPORT_FAILURE = `@modules/@products/@productCommon/EXPORT_ACCRUAL_REPORT_FAILURE`

export const exportAccrualReportToExcelActions = {
  request: createAction(EXPORT_ACCRUAL_REPORT)<IAccuralReportExportReq>(),
  success: createAction(EXPORT_ACCRUAL_REPORT_SUCCESS)(),
  failure: createAction(EXPORT_ACCRUAL_REPORT_FAILURE)<Error>()
}

export const fetchCashReceiptTrendReportActions = {
  request: createAction(
    FETCH_CASH_RECEIPT_TREND_REPORT
  )<ICashRecepitTrendReportReq>(),
  success: createAction(
    FETCH_CASH_RECEIPT_TREND_REPORT_SUCCESS
  )<IAltsCashReceiptTrendReport>(),
  failure: createAction(FETCH_CASH_RECEIPT_TREND_REPORT_FAILURE)<Error>()
}

export const exportExcelCashReceiptTrendReportActions = {
  request: createAction(
    EXPORT_EXCEL_CASH_RECEIPT_TREND_REPORT
  )<ICashReceiptTrendExportExcelReq>(),
  success: createAction(EXPORT_EXCEL_CASH_RECEIPT_TREND_REPORT_SUCCESS)(),
  failure: createAction(EXPORT_EXCEL_CASH_RECEIPT_TREND_REPORT_FAILURE)<Error>()
}

export const FETCH_ACCRUAL_REPORT_DRILLDOWN_LIST = `@modules/@products/FETCH_ACCRUAL_REPORT_DRILLDOWN_LIST`
export const FETCH_ACCRUAL_REPORT_DRILLDOWN_LIST_SUCCESS = `@modules/@products/FETCH_ACCRUAL_REPORT_DRILLDOWN_LIST_SUCCESS`
export const FETCH_ACCRUAL_REPORT_DRILLDOWN_LIST_FAILURE = `@modules/@products/FETCH_ACCRUAL_REPORT_DRILLDOWN_LIST_FAILURE`
export const CLEAR_FETCH_ACCRUAL_REPORT_DRILLDOWN_LIST = `@modules/@products/CLEAR_FETCH_ACCRUAL_REPORT_DRILLDOWN_LIST`

export const fetchAccrualReportDrilldownListActions = {
  request: createAction(
    FETCH_ACCRUAL_REPORT_DRILLDOWN_LIST
  )<IAccuralReportDrilldownReq>(),
  success: createAction(FETCH_ACCRUAL_REPORT_DRILLDOWN_LIST_SUCCESS)<
    IAccrualReportDrilldown[]
  >(),
  failure: createAction(FETCH_ACCRUAL_REPORT_DRILLDOWN_LIST_FAILURE)<Error>(),
  clear: createAction(CLEAR_FETCH_ACCRUAL_REPORT_DRILLDOWN_LIST)()
}

export const FETCH_EXISTING_SPONSORS_NAME = `@modules/@products/FETCH_EXISTING_SPONSORS_NAME`
export const FETCH_EXISTING_SPONSORS_NAME_SUCCESS = `@modules/@products/FETCH_EXISTING_SPONSORS_NAME_SUCCESS`
export const FETCH_EXISTING_SPONSORS_NAME_FAILURE = `@modules/@products/FETCH_EXISTING_SPONSORS_NAME_FAILURE`
export const CLEAR_FETCH_EXISTING_SPONSORS_NAME = `@modules/@products/CLEAR_FETCH_EXISTING_SPONSORS_NAME`

export const fetchExistingSponsorNamesActions = {
  request: createAction(FETCH_EXISTING_SPONSORS_NAME)<
    string | undefined | null
  >(),
  success: createAction(FETCH_EXISTING_SPONSORS_NAME_SUCCESS)<string[]>(),
  failure: createAction(FETCH_EXISTING_SPONSORS_NAME_FAILURE)<Error>(),
  clear: createAction(CLEAR_FETCH_EXISTING_SPONSORS_NAME)()
}

export const TREND_REPORT_DRILLDOWN_TO_EXCEL = `@modules/@products/TREND_REPORT_DRILLDOWN_TO_EXCEL`
export const TREND_REPORT_DRILLDOWN_TO_EXCEL_SUCCESS = `@modules/@products/TREND_REPORT_DRILLDOWN_TO_EXCEL_SUCCESS`
export const TREND_REPORT_DRILLDOWN_TO_EXCEL_FAILURE = `@modules/@products/TREND_REPORT_DRILLDOWN_TO_EXCEL_FAILURE`
export const CLEAR_TREND_REPORT_DRILLDOWN_EXPORT = `@modules/@products/CLEAR_TREND_REPORT_DRILLDOWN_EXPORT`

export const exportTrendReportDrilldownToExcelActions = {
  request: createAction(
    TREND_REPORT_DRILLDOWN_TO_EXCEL
  )<ITrendDrilldownExportReq>(),
  success: createAction(TREND_REPORT_DRILLDOWN_TO_EXCEL_SUCCESS)(),
  failure: createAction(TREND_REPORT_DRILLDOWN_TO_EXCEL_FAILURE)<Error>(),
  clear: createAction(CLEAR_TREND_REPORT_DRILLDOWN_EXPORT)()
}

export const uploadManualActions = {
  uploadDoc: createAction(UPLOAD_MANUAL_ACCRUAL)<IUploadManualRequest>()
}

export const UPLOAD_MANUAL_ACCRUAL_SUCCESS = `@modules/@products/UPLOAD_MANUAL_ACCRUAL_SUCCESS`
export const UPLOAD_MANUAL_ACCRUAL_FAILURE = `@modules/@products/UPLOAD_MANUAL_ACCRUAL_FAILURE`
export const UPLOAD_MANUAL_ACCRUAL_CLEAR = `@modules/@products/UPLOAD_MANUAL_ACCRUAL_CLEAR`

export const uploadManualAccrualActions = {
  success: createAction(UPLOAD_MANUAL_ACCRUAL_SUCCESS)<IUploadManualAccrual>(),
  failure: createAction(UPLOAD_MANUAL_ACCRUAL_FAILURE)<Error>()
}

export const FETCH_MANUAL_UPLOAD = `@modules/@products/FETCH_MANUAL_UPLOAD`
export const FETCH_MANUAL_UPLOAD_SUCCESS = `@modules/@products/FETCH_MANUAL_UPLOAD_SUCCESS`
export const FETCH_MANUAL_UPLOAD_FAILURE = `@modules/@products/FETCH_MANUAL_UPLOAD_FAILURE`
export const FETCH_MANUAL_UPLOAD_CLEAR = `@modules/@products/FETCH_MANUAL_UPLOAD_CLEAR`

export const fetchManualUploadActions = {
  request: createAction(FETCH_MANUAL_UPLOAD)<any>(),
  success: createAction(FETCH_MANUAL_UPLOAD_SUCCESS)<string[]>(),
  failure: createAction(FETCH_MANUAL_UPLOAD_FAILURE)<Error>(),
  clear: createAction(FETCH_MANUAL_UPLOAD_CLEAR)<undefined>()
}

export const DOWNLOAD_MANUAL_UPLOAD = `@modules/@products/DOWNLOAD_MANUAL_UPLOAD`
export const DOWNLOAD_MANUAL_UPLOAD_SUCCESS = `@modules/@products/DOWNLOAD_MANUAL_UPLOAD_SUCCESS`
export const DOWNLOAD_MANUAL_UPLOAD_FAILURE = `@modules/@products/DOWNLOAD_MANUAL_UPLOAD_FAILURE`

export const downloadManualAccrualActions = {
  request: createAction(DOWNLOAD_MANUAL_UPLOAD)<{
    attachmentsURL: string
    fileName: string
  }>(),
  success: createAction(DOWNLOAD_MANUAL_UPLOAD_SUCCESS)(),
  failure: createAction(DOWNLOAD_MANUAL_UPLOAD_FAILURE)<Error>()
}

export const DELETE_MANUAL_UPLOAD = `@modules/@products/DELETE_MANUAL_UPLOAD`
export const DELETE_MANUAL_UPLOAD_SUCCESS = `@modules/@products/DELETE_MANUAL_UPLOAD_SUCCESS`
export const DELETE_MANUAL_UPLOAD_FAILURE = `@modules/@products/DELETE_MANUAL_UPLOAD_FAILURE`
export const DELETE_MANUAL_UPLOAD_CLEAR = `@modules/@products/DELETE_MANUAL_UPLOAD_CLEAR`

export const deleteManualAccrualActions = {
  request: createAction(DELETE_MANUAL_UPLOAD)<{ fileName: string }>(),
  success: createAction(DELETE_MANUAL_UPLOAD_SUCCESS)(),
  failure: createAction(DELETE_MANUAL_UPLOAD_FAILURE)<Error>(),
  clear: createAction(DELETE_MANUAL_UPLOAD_CLEAR)<undefined>()
}

export type NavigationActions = ActionType<typeof navigateactions>
export type FetchUploadDocListActions = ActionType<
  typeof fetchUploadDocListActions
>
export type FetchUploadDocTypeListActions = ActionType<
  typeof fetchUploadDocTypeActions
>
export type UpdatePlanByProviderActionTypes = ActionType<
  typeof updatePlanPlanByProviderActions
>
export type SetSelectedPlanActionTypes = ActionType<typeof setSelectedPlan>
export type SetSelectedFeeTypeActionTypes = ActionType<
  typeof setSelectedFeeType
>
export type fetchPlansToDistributeCashActionTypes = ActionType<
  typeof fetchPlansToDistributeCashActions
>
export type setSelectedAccrualPeriodActionTypes = ActionType<
  typeof setSelectedAccrualPeriodActions
>
export type updateCashReceiptToPlanActionTypes = ActionType<
  typeof updateCashReceiptToPlanActions
>
export type SetSelectedMonthYearActionTypes = ActionType<
  typeof setSelectedMonthYearActions
>
export type RetirementProductPlanTransactionActionTypes = ActionType<
  typeof fetchRetirementPlanTransactionsAction
>
////approval dashboard
export type fetchAIPlansApproveDashboardActionTypes = ActionType<
  typeof fetchAIPlansApproveDashboardActions
>

export type fetchWorkflowPlanHistoryApproveDashboardActionTypes = ActionType<
  typeof fetchWorkflowPlanHistoryApproveDashboardActions
>

export type fetchComparisonApprovalDashboardActionType = ActionType<
  typeof fetchComparisonApproveDashboardActions
>
export type handleApproverActionApprovalDashboardActionType = ActionType<
  typeof handleApproverActionApprovalDashboard
>

export type fetchProductPlansActionTypes = ActionType<
  typeof fetchProductPlansAction
>
export type fetchProvidersforExcelActionTypes = ActionType<
  typeof fetchProvidersforExcelActions
>
export type fetchPlanProvidersActionTypes = ActionType<
  typeof fetchPlanProvidersActions
>

export type fetchPlansByProviderActionTypes = ActionType<
  typeof fetchPlansByProviderActions
>

export type fetchDraftPlansActionsTypes = ActionType<
  typeof fetchDraftPlansActions
>

export type fetchCommittedActionsTypes = ActionType<
  typeof fetchCommittedActions
>

export type updatePlanProviderActionTypes = ActionType<
  typeof updatePlanProviderActions
>
export type fetchFeeTypesActionTypes = ActionType<typeof fetchFeeTypesActions>
export type productNavigationActionTypes = ActionType<
  typeof productNavigationActions
>
export type exportPlansToExcelActionTypes = ActionType<
  typeof exportPlansToExcelActions
>
export type exportCashReceiptTrendReportToExcelActionTypes = ActionType<
  typeof exportExcelCashReceiptTrendReportActions
>
export type downloadD365ExcelActionTypes = ActionType<
  typeof downloadD365ExcelActions
>
export type CloseMonthActionTypes = ActionType<typeof closeMonthActions>
export type viewStatementActionTypes = ActionType<typeof viewStatementActions>
export type uploadProductDocumentsActionTypes = ActionType<
  typeof uploadProductDocumentActions
>
export type updateProjectedAmountByPlansActionTypes = ActionType<
  typeof updateProjectedAmountByPlansActions
>
export type fetchProjectedAmountByPlansActionTypes = ActionType<
  typeof fetchProjectedAmountByPlansActions
>
export type fetchProductPlanAllocationActionType = ActionType<
  typeof fetchProductPlanAllocationActions
>
export type fetchPlanProvidersDocsActionTypes = ActionType<
  typeof fetchPlanProvidersDocsActions
>
export type deleteStatementActionTypes = ActionType<
  typeof deleteStatementActions
>
export type updatePlanProviderDocActionTypes = ActionType<
  typeof updatePlanProviderDocActions
>
export type fetchCashReceiptTrendReportActionTypes = ActionType<
  typeof fetchCashReceiptTrendReportActions
>
export type fetchAccrualReportActionTypes = ActionType<
  typeof fetchAccrualReport
>
export type exportAccrualReportToExcelActionsTypes = ActionType<
  typeof exportAccrualReportToExcelActions
>
export type FetchOpenReceivablesReportActionTypes = ActionType<
  typeof fetchOpenReceivablesReportActions
>

export type ExportOpenReceivablesReportActionTypes = ActionType<
  typeof exportOpenReceivablesReportActions
>
export type uploadManualActionTypes = ActionType<typeof uploadManualActions>

export type fetchManualUploadTypeActions = ActionType<
  typeof fetchManualUploadActions
>

export type uploadManualAccrualActionsActions = ActionType<
  typeof uploadManualAccrualActions
>

export type downloadSPADocActionType = ActionType<
  typeof downloadManualAccrualActions
>
export type deleteSPADocActionType = ActionType<
  typeof deleteManualAccrualActions
>
export type fetchClassificationTypesActionsTypes = ActionType<
  typeof fetchClassificationTypesActions
>

export type deleteFundDocActionsTypes = ActionType<typeof deleteFundDocActions>

export type ProductActionTypes =
  | SetSelectedPlanActionTypes
  | fetchPlansToDistributeCashActionTypes
  | setSelectedAccrualPeriodActionTypes
  | updateCashReceiptToPlanActionTypes
  | SetSelectedMonthYearActionTypes
  | RetirementProductPlanTransactionActionTypes
  | fetchAIPlansApproveDashboardActionTypes ////approval dashboard
  | fetchWorkflowPlanHistoryApproveDashboardActionTypes
  | fetchComparisonApprovalDashboardActionType
  | handleApproverActionApprovalDashboardActionType
  | fetchProductPlansActionTypes
  | fetchProvidersforExcelActionTypes
  | fetchPlanProvidersActionTypes
  | fetchPlansByProviderActionTypes
  | fetchDraftPlansActionsTypes
  | fetchCommittedActionsTypes
  | updatePlanProviderActionTypes
  | UpdatePlanByProviderActionTypes
  | fetchFeeTypesActionTypes
  | SetSelectedFeeTypeActionTypes
  | exportPlansToExcelActionTypes
  | downloadD365ExcelActionTypes
  | CloseMonthActionTypes
  | viewStatementActionTypes
  | uploadProductDocumentsActionTypes
  | updateProjectedAmountByPlansActionTypes
  | fetchProjectedAmountByPlansActionTypes
  | fetchProductPlanAllocationActionType
  | FetchUploadDocTypeActions
  | FetchUploadDocListActions
  | NavigationActions
  | UploadFundDocActionType
  | DownloadFundDocActionType
  | fetchPlanProvidersDocsActionTypes
  | deleteStatementActionTypes
  | updatePlanProviderDocActionTypes
  | fetchCashReceiptTrendReportActionTypes
  | exportCashReceiptTrendReportToExcelActionTypes
  | fetchAccrualReportActionTypes
  | exportAccrualReportToExcelActionsTypes
  | FetchOpenReceivablesReportActionTypes
  | ExportOpenReceivablesReportActionTypes
  | ActionType<typeof fetchAccrualReportDrilldownListActions>
  | ActionType<typeof fetchExistingSponsorNamesActions>
  | ActionType<typeof exportTrendReportDrilldownToExcelActions>
  | fetchClassificationTypesActionsTypes
  | fetchManualUploadTypeActions
  | downloadSPADocActionType
  | deleteSPADocActionType
  | uploadManualAccrualActionsActions
  | deleteFundDocActionsTypes
