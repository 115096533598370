import {
  DirectionalHint,
  Stack,
  TooltipDelay,
  TooltipHost
} from '@fluentui/react'
import { keyBy } from 'lodash'
import React from 'react'
import { FormattedNumber } from 'react-intl'
import { IAssetAllocationItem } from '../../../api/common.types'
import assetTaxonomy from '../../../metadata/asset-taxonomy.json'

interface IAssetAllocationBarContainerProps {
  assetAllocations: IAssetAllocationItem[]
  selectedAssetAllocations?: string[]
}

export const AssetAllocationBarContainer: React.FC<
  IAssetAllocationBarContainerProps
> = ({ assetAllocations, selectedAssetAllocations }) => {
  const taxonomyLookup = keyBy(
    assetTaxonomy.filter(({ level }) => level === 1),
    (x) => x.description
  )

  const assetAllocation = (assetAllocations || []).filter(
    (x) => x.value && x.value > 0
  )
  const total = assetAllocation.reduce((a, x) => a + (x.value || 0), 0)
  const allRows = assetAllocation
    .map((x) => {
      return {
        name: x.assetType || '',
        ratio: ((x.value || 0) / total) * 100
      }
    })
    .sort((a, b) => {
      return (
        (taxonomyLookup[a.name]?.order || 999) -
        (taxonomyLookup[b.name]?.order || 999)
      )
    })

  const selectedMap = (selectedAssetAllocations || []).reduce(
    (a, allocationName) => {
      a[allocationName] = allocationName
      return a
    },
    {} as any
  )
  const isAnyAllocationSelected = (selectedAssetAllocations || []).length > 0
  const defaultColor = '666'

  return (
    <Stack
      horizontal={true}
      tokens={{ childrenGap: 1 }}
      styles={{ root: { width: '100%', height: '100%', maxHeight: '20px' } }}
    >
      {allRows.map((x, i) => {
        const backgroundColor =
          !isAnyAllocationSelected ||
          (isAnyAllocationSelected && selectedMap[x.name])
            ? `#${taxonomyLookup[x.name]?.color || defaultColor}`
            : `#${defaultColor}`
        return (
          <Stack
            key={x.name + i}
            style={{
              width: `${x.ratio}%`,
              backgroundColor
            }}
          >
            <TooltipHost
              styles={{ root: { height: '100%', width: '100%' } }}
              tooltipProps={{
                onRenderContent: () => {
                  return (
                    <div>
                      {x.name} -{' '}
                      <FormattedNumber
                        value={x.ratio}
                        maximumFractionDigits={2}
                        minimumFractionDigits={2}
                      />
                      %
                    </div>
                  )
                }
              }}
              delay={TooltipDelay.zero}
              directionalHint={DirectionalHint.bottomCenter}
            >
              <div style={{ height: '100%', width: '100%' }} />
            </TooltipHost>
          </Stack>
        )
      })}
    </Stack>
  )
}
