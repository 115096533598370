import { combineReducers, Reducer } from 'redux'
import {
  IRetirementProductState,
  retirementProductReducer
} from '../features/Retirement/store/reducer'
import {
  allCashReceiptsReducer,
  IAllCashReceiptState
} from '../features/Setting/store/reducer'
import {
  productCommonReducer,
  IProductCommonState
} from '../features/store/reducers'

export interface IProductModuleState {
  retirement: IRetirementProductState
  cashReceipts: IAllCashReceiptState
  productCommon: IProductCommonState
}
export const productReducer: Reducer<IProductModuleState> = combineReducers({
  retirement: retirementProductReducer,
  cashReceipts: allCashReceiptsReducer,
  productCommon: productCommonReducer
})
