import { USDCell } from 'features/Lists/core/components/USDCell'

export const TotalLabelValue = ({
  label,
  value
}: {
  label: string
  value: number | null | undefined
}) => (
  <div>
    <label
      css={{
        marginRight: '4px'
      }}
    >
      {label}:
    </label>
    <USDCell value={value ?? 0} fractionDigits={0} />
  </div>
)
