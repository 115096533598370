import { ContextualMenu, Icon, IContextualMenuItem } from '@fluentui/react'
import { has } from 'lodash'
import { SnackBar } from 'modules/Advisory/modules/Rdot360/components/shared/Snackbar'
import { base64toBlob } from 'modules/Advisory/modules/Rdot360/shared/utilities'
import { IPerformanceReport } from 'modules/Advisory/modules/Rdot360/store/types'
import { createRef, FC, useCallback, useEffect, useState } from 'react'
import { downloadUrlAsFile } from 'shared/downloads'
import { useGetReportBase64CodeMutation } from 'store/api/cdm'
import { usePerformanceReportsUiState } from '../reportsUiState'
import { ShareReportDialog } from './ShareModal/ShareReportDialog'

interface IShowActionsProps {
  report: IPerformanceReport
}

type EncodedReportResponse =
  | {
      data: string | undefined
    }
  | {
      error: unknown
    }

const getEncodedReportBlobUrl = (
  base64EncodedReport: EncodedReportResponse
): string | undefined => {
  if (!has(base64EncodedReport, 'data')) {
    return
  }
  try {
    const { data = '' } = base64EncodedReport
    const blob = base64toBlob('data:application/pdf;base64,' + data)
    return URL.createObjectURL(blob)
  } catch (error) {
    console.error('Error in getEncodedReportBlobUrl', error)
    return
  }
}

export const ShowActions: FC<IShowActionsProps> = ({ report }) => {
  const { id = '', reportName = '' } = report
  const [getEncodedReport, getEncodedReportStatus] =
    useGetReportBase64CodeMutation()
  const { isLoading } = getEncodedReportStatus
  const { setIsEncodedReportLoading } = usePerformanceReportsUiState()
  const [showContextualMenu, setShowContextualMenu] = useState(false)
  const [showShareReportDialog, setShowShareReportDialog] = useState(false)
  const [showBase64EncodedReportError, setShowBase64EncodedReportError] =
    useState(false)
  const onShowContextualMenu = useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      ev.preventDefault()
      setShowContextualMenu(true)
    },
    []
  )
  const onHideContextualMenu = useCallback(
    () => setShowContextualMenu(false),
    []
  )
  const targetMenu = createRef<HTMLDivElement>()

  const menuItems: IContextualMenuItem[] = [
    {
      key: 'ViewReport',
      text: 'View Report',
      onClick: async () => {
        const base64EncodedReport = await getEncodedReport(id)
        const blobUrl = getEncodedReportBlobUrl(base64EncodedReport)
        blobUrl ? window.open(blobUrl) : setShowBase64EncodedReportError(true)
      }
    },
    {
      key: 'DownloadPDF',
      text: 'Download PDF',
      onClick: async () => {
        const base64EncodedReport = await getEncodedReport(id)
        const blobUrl = getEncodedReportBlobUrl(base64EncodedReport)
        blobUrl
          ? reportName && downloadUrlAsFile(blobUrl, reportName)
          : setShowBase64EncodedReportError(true)
      }
    },
    {
      key: 'ShareReport',
      text: 'Share Report',
      onClick: () => setShowShareReportDialog(true)
    }
  ]

  useEffect(() => {
    setIsEncodedReportLoading(isLoading)
  }, [isLoading, setIsEncodedReportLoading])

  useEffect(() => {
    if (showBase64EncodedReportError) {
      setTimeout(() => setShowBase64EncodedReportError(false), 3000)
    }
  }, [showBase64EncodedReportError])

  return (
    <>
      <div
        ref={targetMenu}
        css={{ cursor: 'pointer' }}
        onClick={onShowContextualMenu}
      >
        <Icon iconName="MoreVertical" title="Choose Action" />
        {showContextualMenu && (
          <ContextualMenu
            items={menuItems}
            hidden={!showContextualMenu}
            target={targetMenu}
            onItemClick={onHideContextualMenu}
            onDismiss={onHideContextualMenu}
          />
        )}
        {showShareReportDialog && (
          <ShareReportDialog
            showShareReportDialog={showShareReportDialog}
            setShowShareReportDialog={setShowShareReportDialog}
            report={report}
          />
        )}
      </div>
      {showBase64EncodedReportError && (
        <div css={{ position: 'fixed', bottom: 20, right: 20, zIndex: 9999 }}>
          <SnackBar type="Failure" message="Report download failed!" />
        </div>
      )}
    </>
  )
}
