import { call, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { getHurdles, IHurdle } from '../../../../../../../../../api/datahub'
import { AppState } from '../../../../../../../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../../../../../../../store/shared/asyncStore'
import { getRockefellerApiOptions } from '../../../../../../../../../store/shared/sagas'

export const hurdleFetchActions = createAsyncAction(
  '@modules/@teams/@hurdleFetch/REQUEST',
  '@modules/@teams/@hurdleFetch/SUCCESS',
  '@modules/@teams/@hurdleFetch/FAILURE'
)<undefined, IHurdle[] | undefined, Error>()

export const hurdleFetchReducer = createAsyncReducer(hurdleFetchActions)

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.teams.modules.hurdles.features.hurdleEdit
    .hurdleFetch

export const {
  getError: getHurdleFetchError,
  getIsLoading: getHurdleFetchLoading,
  getResult: getHurdleFetchResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* () {
  try {
    const apiOptions = yield* call(getRockefellerApiOptions)
    const result = yield* call(getHurdles, apiOptions, {
      expand: ['measurements($expand=metrics($expand=payouts))']
    })

    if (!result) {
      throw new Error('No result returned from dynamics api')
    }

    yield put(hurdleFetchActions.success(result.value))
  } catch (e: any) {
    yield put(hurdleFetchActions.failure(e))
  }
}

export const hurdleFetchSagas = [
  () => takeLatest(hurdleFetchActions.request, onRequest)
]
