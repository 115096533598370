import { createAction, ActionType } from 'typesafe-actions'
import { IVendorItem } from './type'

export const VENDOR_LIST_REQUEST = `@modules/@payments/@vendor/VENDOR_LIST_REQUEST`
export const VENDOR_LIST_SUCCESS = `@modules/@payments/@vendor/VENDOR_LIST_SUCCESS`
export const VENDOR_LIST_FAILURE = `@modules/@payments/@vendor/VENDOR_LIST_FAILURE`

export const NAVIGATE_TO_WIRE_INSTRUCTION = `@modules/@payments/@vendor/NAVIGATE_TO_WIRE_INSTRUCTION`
export const NAVIGATE_TO_EDIT_WIRE_INSTRUCTION = `@modules/@payments/@vendor/NAVIGATE_TO_EDIT_WIRE_INSTRUCTION`
export const NAVIGATE_TO_PAYMENTS = `@modules/@payments/@vendor/NAVIGATE_TO_PAYMENTS`
export const NAVIGATE_TO_OTHER_PAYMENTS = `@modules/@payments/@vendor/NAVIGATE_TO_OTHER_PAYMENTS`

export const VendorListActions = {
  request: createAction(VENDOR_LIST_REQUEST)<string>(),
  success: createAction(VENDOR_LIST_SUCCESS)<IVendorItem[]>(),
  failure: createAction(VENDOR_LIST_FAILURE)<Error>()
}

export const VendorNavigationActions = {
  naviagateToWireInstruction: createAction(
    NAVIGATE_TO_WIRE_INSTRUCTION
  )<string>(),
  navigateToPayments: createAction(NAVIGATE_TO_PAYMENTS)<undefined | null>(),
  naviagteToEditWireInstruction: createAction(
    NAVIGATE_TO_EDIT_WIRE_INSTRUCTION
  )<{ orgid: string; id: string }>(),
  naviagteToOtherPayments: createAction(NAVIGATE_TO_OTHER_PAYMENTS)<
    undefined | null
  >()
}

export type VendorListActionType = ActionType<typeof VendorListActions>
export type VendorNavigationActionType = ActionType<
  typeof VendorNavigationActions
>

export type VendorActionsType =
  | VendorListActionType
  | VendorNavigationActionType
