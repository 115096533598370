import { push } from 'connected-react-router'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { getRockefellerApiOptions } from 'store/shared'
import { call, put, race, take, takeLatest } from 'typed-redux-saga'

import { SIValidateService } from '../../api/EFTJNLServices'
import { mmWireSaveAsDraftActions, setFormDataActions } from '../actions'
import { siValidatePaymentActions } from './action'
import { SIResponse } from './types'

function* handleSIValidateWire(
  action: ReturnType<typeof siValidatePaymentActions.request>
) {
  try {
    const { iseft } = action.payload.validateRequest
    const options: IApiOptions = yield call(getRockefellerApiOptions)

    const data: SIResponse = yield call(() =>
      SIValidateService(action.payload?.validateRequest, options)
    )
    if (data) {
      yield put(siValidatePaymentActions.success(data.value[0]))
      if (data?.value[0].status?.Code === '0') {
        yield put(
          setFormDataActions.setWireFormData({
            ...action.payload.wireData,
            EWSResult: data?.value[0].status?.Warnings?.[0].Code,
            isewsverified: data?.value[0]?.isewsverified
          })
        )
      }

      if (
        data?.value[0].status?.Code === '0' &&
        data?.value[0].status?.Warnings?.length &&
        data?.value[0].status?.Warnings[0].Code !== 'EWS-P' &&
        data?.value[0].status?.Warnings[0].Code !== 'EWS-F'
      ) {
        yield put(
          siValidatePaymentActions.failure({
            Error: new Error('Please select one of the options'),
            validateResponse: data.value[0]
          })
        )
      } else if (
        data?.value[0].status?.Code === '0' &&
        data?.value[0].status?.Warnings?.length &&
        data?.value[0].status?.Warnings[0].Code === 'EWS-F'
      ) {
        yield put(
          siValidatePaymentActions.failure({
            Error: new Error(
              `The Early Warning System failed the link between the client's account and the provided bank account details. Please ensure the client's account is selected is stenciled with the correct name and ssn. The client will be receiving a mailer explaining the situation.`
            ),
            validateResponse: data.value[0]
          })
        )
      } else if (data?.value[0].status?.Code === '0') {
        yield put(
          mmWireSaveAsDraftActions.request({
            paymentVerificationData: action.payload?.verificationData,
            wireData: {
              ...action.payload.wireData,
              isewsverified: data?.value[0]?.isewsverified,
              EWSResult: data?.value[0].status?.Warnings?.[0].Code
            },
            isWireInitiation: true,
            ismarginpaperworkrequired: undefined,
            paymentType: action.payload?.paymentType
          })
        )
        const { success } = yield* race({
          success: take(mmWireSaveAsDraftActions.success),
          failure: take(mmWireSaveAsDraftActions.failure)
        })

        if (success) {
          yield put(setFormDataActions.setWireFormData(action.payload.wireData))
          if (iseft) {
            yield put(push('/transfers/eft/review'))
          }
        } else {
          yield put(
            siValidatePaymentActions.failure({
              Error: new Error('Failed to save EFT as draft'),
              validateResponse: undefined
            })
          )
        }
      }
    } else {
      yield put(
        siValidatePaymentActions.failure({
          Error: new Error('Error fetching SIvalidate info'),
          validateResponse: undefined
        })
      )
    }
  } catch (e: any) {
    yield put(siValidatePaymentActions.failure(e))
  }
}
export const sagas = [
  () => takeLatest(siValidatePaymentActions.request, handleSIValidateWire)
]
