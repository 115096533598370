import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { keyBy, orderBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { isNotNullOrEmpty } from 'shared/guards'
import { trackException } from 'shared/services/telemetry'
import { AppState, useSelector } from 'store/shared'
import { getEnvironmentName } from 'store/system'
import { getRdotWsid } from 'store/user/selectors'
import { call, delay, put, select } from 'typed-redux-saga'
import { v4 as uuidv4 } from 'uuid'
import {
  IProfileApiContext,
  IProfileApiContextAccount
} from './IProfileApiContext'
import {
  profileApi,
  useInvalidateCacheMutation,
  useSetContextMutation
} from './profileApi'

export interface ISetContextPayload {
  profileId: string
  wsid: string
  accounts: IProfileApiContextAccount[]
  selectedAccountKeys: string[]
  includeExternal?: boolean
  asOfDate?: string
}

const createContextPayload = (req: ISetContextPayload) => {
  const {
    wsid,
    accounts,
    selectedAccountKeys,
    includeExternal = false,
    asOfDate
  } = req

  const validAccounts = accounts.filter((x) => {
    const { key, repcode, isInternal } = x
    if (!key || !repcode) {
      return false
    }

    if (!includeExternal && !isInternal) {
      return false
    }

    return true
  })

  const selectedLookup = keyBy(selectedAccountKeys)
  const externalAccounts = validAccounts.filter(
    ({ key, isInternal }) => key && selectedLookup[key] && !isInternal
  )
  const selectedAccounts = validAccounts.filter(
    ({ key, isInternal }) => key && selectedLookup[key] && isInternal
  )
  const hasExternalAccounts = validAccounts.some(
    ({ isInternal }) => !isInternal
  )

  const clientProfile: IProfileApiContext = {
    profile: {
      __id: req.profileId,
      wsportaluserid: wsid,
      loggedInUserWealthscapePortalId: wsid,
      accounts: selectedAccounts.length ? selectedAccounts : undefined,
      allaccounts: validAccounts.length ? validAccounts : undefined,
      showexternalaccounts: hasExternalAccounts,
      externalaccounts: externalAccounts.length ? externalAccounts : undefined,
      AsofDate: asOfDate,
      preferencejson: {
        pilotfeatures: [
          {
            componentname: 'BalSummaryV2',
            active: 'true'
          }
        ]
      }
    }
  }

  return clientProfile
}

interface IProfileApiContextState {
  id: string
  apiKey: string
  apiKeyCount: number
  apiKeys: string[]
  lastUpdated?: number
  requestedContext?: IProfileApiContext
  context?: IProfileApiContext
  error?: Error
  isLoading: boolean
}

const maxApiKeys = 4
const generateContextId = (id: string) => `cd_${id}_${uuidv4()}`

export const createProfileApiContextSlice = (id: string) => {
  const apiKeys = Array.from({ length: maxApiKeys }, () =>
    generateContextId(id)
  )

  const initialState: IProfileApiContextState = {
    id,
    apiKey: apiKeys[0],
    apiKeyCount: 1,
    apiKeys,
    isLoading: false
  }

  const slice = createSlice({
    name: `@features/@rdot360/@serverContextSlice/${id}`,
    initialState,
    reducers: {
      setRequestedContext: (
        state,
        action: PayloadAction<IProfileApiContext | undefined>
      ) => ({
        ...state,
        lastUpdated: Date.now(),
        requestedContext: action.payload,
        error: undefined,
        isLoading: true
      }),
      setContext: (
        state,
        action: PayloadAction<IProfileApiContext | undefined>
      ) => ({
        ...state,
        lastUpdated: Date.now(),
        context: action.payload,
        requestedContext: action.payload,
        error: undefined,
        isLoading: false
      }),
      setError: (state, action: PayloadAction<Error>) => ({
        ...state,
        error: action.payload,
        context: undefined,
        isLoading: false
      }),
      setIsLoading: (state, action: PayloadAction<boolean>) => ({
        ...state,
        isLoading: action.payload
      }),
      setLastUpdated: (state) => ({
        ...state,
        lastUpdated: Date.now()
      }),
      rotateApiKey: (state) => {
        const newCount = state.apiKeyCount + 1
        state.apiKeyCount = newCount
        state.apiKey = apiKeys[newCount % maxApiKeys]
      }
    }
  })

  return slice
}

const selectWsid = createSelector(
  [getEnvironmentName, getRdotWsid],
  (environment, claimsWsid) =>
    environment === 'preprod' || environment === 'prod'
      ? claimsWsid
      : '5511000024'
)

const contextTimeout = 30 * 60 * 1000
const contextCheckInterval = 7 * 60 * 1000
const empty: string[] = []
export const createProfileApiContextStore = (
  rootSelector: (state: AppState) => IProfileApiContextState,
  actions: ReturnType<typeof createProfileApiContextSlice>['actions']
) => {
  const selectApiContext = createSelector(
    rootSelector,
    ({ context }) => context
  )

  const selectApiContextSelectedAccounts = createSelector(
    selectApiContext,
    (context) => {
      const { accounts = [], externalaccounts = [] } = context?.profile || {}
      const selectedAccountKeys = orderBy(
        [...accounts, ...externalaccounts]
          .map(({ key }) => key)
          .filter(isNotNullOrEmpty)
      )

      return selectedAccountKeys?.length ? selectedAccountKeys : empty
    }
  )

  const selectApiContextAllAccounts = createSelector(
    selectApiContext,
    (context) => {
      const { allaccounts } = context?.profile || {}
      const allAccountKeys = orderBy(
        allaccounts?.map(({ key }) => key).filter(isNotNullOrEmpty)
      )

      return allAccountKeys?.length ? allAccountKeys : empty
    }
  )

  const selectLastUpdated = createSelector(
    rootSelector,
    ({ lastUpdated }) => lastUpdated
  )

  const selectContextId = createSelector(rootSelector, ({ id }) => id)
  const selectCurrentApiKey = createSelector(
    rootSelector,
    ({ apiKeys, apiKeyCount }) => apiKeys[apiKeyCount % maxApiKeys]
  )
  const selectNextApiKey = createSelector(
    rootSelector,
    ({ apiKeys, apiKeyCount }) => apiKeys[(apiKeyCount + 1) % maxApiKeys]
  )
  const selectProfileId = createSelector(
    selectApiContext,
    (context) => context?.profile?.__id
  )
  const selectAsOfDate = createSelector(
    selectApiContext,
    (context) => context?.profile?.AsofDate
  )

  const selectIsSetApiContextLoading = createSelector(
    rootSelector,
    ({ isLoading }) => isLoading || false
  )

  const saga_setContext = function* (key: string, context: IProfileApiContext) {
    const action = profileApi.endpoints.setContext.initiate({ key, context })
    const promise = yield* put(action)
    promise.unsubscribe()
    return yield* call(promise.unwrap)
  }

  const saga_refreshStaleContext = function* () {
    const lastUpdated = yield* select(selectLastUpdated)
    const apiKey = yield* select(selectCurrentApiKey)
    const context = yield* select(selectApiContext)
    const isLoading = yield* select(selectIsSetApiContextLoading)

    const isStale = lastUpdated && Date.now() - lastUpdated > contextTimeout

    if (isLoading || !isStale || !context) {
      return
    }

    console.info('api context is stale', apiKey)

    try {
      yield* call(saga_setContext, apiKey, context)
      yield* put(actions.setLastUpdated())
    } catch (e) {
      yield* call(trackException, e as Error)
    }
  }

  const saga_watchContext = function* () {
    while (true) {
      yield* call(delay, contextCheckInterval)
      yield* call(saga_refreshStaleContext)
    }
  }

  const useProfileApiContext = () => {
    const dispatch = useDispatch()
    const {
      context: currentContext,
      requestedContext,
      error,
      id
    } = useSelector(rootSelector)
    const [setApiContext] = useSetContextMutation()
    const wsid = useSelector(selectWsid)
    const currentApiKey = useSelector(selectCurrentApiKey)
    const nextApiKey = useSelector(selectNextApiKey)
    const currentProfileId = useSelector(selectProfileId)

    const rotateApiKey = useCallback(() => {
      dispatch(actions.rotateApiKey())
      return nextApiKey
    }, [dispatch, nextApiKey])

    const setError = useCallback(
      (error: Error) => {
        dispatch(actions.setError(error))
      },
      [dispatch]
    )

    const setStoreContext = useCallback(
      (context?: IProfileApiContext) => dispatch(actions.setContext(context)),
      [dispatch]
    )

    const setRequestedContext = useCallback(
      (context: IProfileApiContext) =>
        dispatch(actions.setRequestedContext(context)),
      [dispatch]
    )

    const [invalidateProfileApiCache] = useInvalidateCacheMutation()
    const invalidateServerCache = useCallback(async () => {
      try {
        const newKey = rotateApiKey()
        await invalidateProfileApiCache({ key: newKey }).unwrap()
        return newKey
      } catch (e) {
        setError(e as Error)
      }
    }, [invalidateProfileApiCache, rotateApiKey, setError])

    const setContextInternal = useCallback(
      async (context: IProfileApiContext, forceRefreshCache = false) => {
        setRequestedContext(context)
        const apiKey =
          context.profile.__id !== currentProfileId ||
          error ||
          forceRefreshCache
            ? await invalidateServerCache()
            : currentApiKey

        if (!apiKey) {
          return
        }

        try {
          await setApiContext({
            key: apiKey,
            context
          }).unwrap()
        } catch (e: unknown) {
          setError(
            (e as Error) ||
              new Error('Unknown error occurred while setting API Context')
          )
          return
        }
        setStoreContext(context)
      },
      [
        currentApiKey,
        currentProfileId,
        error,
        invalidateServerCache,
        setApiContext,
        setError,
        setRequestedContext,
        setStoreContext
      ]
    )

    const setContext = useCallback(
      async (
        profileId: string,
        accounts: IProfileApiContextAccount[],
        selectedAccountKeys: string[] = [],
        asOfDate?: string
      ) => {
        if (!wsid) {
          setError(new Error(`Unable to determine user's wsid`))
          return
        }
        const context = createContextPayload({
          profileId,
          wsid,
          accounts,
          selectedAccountKeys,
          includeExternal: true,
          asOfDate
        })
        setContextInternal(context)
      },
      [wsid, setContextInternal, setError]
    )

    const apiContextAccounts = useSelector(selectApiContextSelectedAccounts)
    const apiContextAllAccounts = useSelector(selectApiContextAllAccounts)
    const isLoading = useSelector(selectIsSetApiContextLoading)

    const refreshServerContext = useCallback(async () => {
      if (!requestedContext) {
        return
      }

      return await setContextInternal({ ...requestedContext }, true)
    }, [requestedContext, setContextInternal])

    const updateAsOf = useCallback(
      async (asOfDate?: string) => {
        if (!requestedContext) {
          return
        }
        const updatedContext: IProfileApiContext = {
          profile: {
            ...requestedContext.profile,
            AsofDate: asOfDate
          }
        }
        return await setContextInternal(updatedContext, true)
      },
      [requestedContext, setContextInternal]
    )

    const result = useMemo(
      () => ({
        profileId: currentContext?.profile?.__id,
        contextId: id,
        context: currentContext,
        apiContextAccounts,
        allAccountKeys: apiContextAllAccounts,
        isLoading,
        error,
        setContext,
        refreshServerContext,
        updateAsOf
      }),
      [
        apiContextAccounts,
        apiContextAllAccounts,
        currentContext,
        error,
        id,
        isLoading,
        refreshServerContext,
        setContext,
        updateAsOf
      ]
    )

    return result
  }

  return {
    useProfileApiContext,
    saga_watchContext,
    selectProfileId,
    selectContextId,
    selectAsOfDate
  }
}
