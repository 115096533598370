import { IModuleDefinition } from 'modules/types'
import DocumentsContainer from './DocumentsContainer'

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/documents/*',
    private: true,
    component: DocumentsContainer
  },
  name: 'Documents'
}

export default moduleDefinition
