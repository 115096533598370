import { IToggleProps, Toggle } from '@fluentui/react'
import { FC } from 'react'

interface IExtendedToggleProps extends IToggleProps {
  border?: string
}

const ToggleWrapper: FC<IExtendedToggleProps> = ({ border, ...props }) => {
  return (
    <div
      css={(theme) => ({
        margin: 'auto 0',
        '.ms-Toggle': {
          margin: 'auto 0',
          '.ms-Toggle-background': {
            backgroundColor: theme.colors.primaryLightBlue1,
            width: 36,
            height: 13,
            border: border || 0,
            padding: 0,
            '.ms-Toggle-thumb': {
              background: theme.colors.extraBlue2,
              height: 16,
              width: 16
            }
          }
        }
      })}
    >
      <Toggle {...props} />
    </div>
  )
}

export default ToggleWrapper
