import { ColumnDef } from '@tanstack/react-table'
import { CDMv2Account } from 'api/nickname.types'
import { AccountNumberCell } from '../../components/shared/DetailTables/AccountNumberCell'
import { AccountStencil } from '../../components/shared/DetailTables/AccountStencil'
import { HeaderContainer } from '../../components/shared/DetailTables/HeaderContainer'
import { HighlightSearchText } from '../../components/shared/DetailTables/HighlightSearchText'
import { AdvisorNicknameCell } from './AdvisorNicknameCell'
import { ClientNicknameCell } from './ClientNicknameCell'
import { HeaderWithRadioButton } from './HeaderWithRadioButton'
import { InputCheckboxTableCell } from './InputCheckboxTableCell'

export const nicknameTableColumnNames = {
  accountDetails: 'Account Details',
  accountStencil: 'Account Stencil',
  sendToTamarac: 'Send Account to Tamarac',
  rDotNickname: 'Rockefeller Connect Nickname',
  advisorTooltip:
    'This nickname will not overwrite any other nicknames and will only be seen by internal R.Dot Users.',
  maxCharacter: '(Max. 100 characters)',
  rockefellerDigitalNickname: 'Rockefeller Digital Nickname',
  accountNicknameTooltip: 'Nickname seen by the client on Client Online.',
  shortName: 'Short Name/Custodian Nickname',
  shortNameTooltip: 'Nickname provided by our Custodians.'
}
export const getNickNameTableColumns = (
  searchText: string
): ColumnDef<CDMv2Account>[] => [
  {
    id: 'accountNumber',
    header: (props) => (
      <HeaderContainer
        title={nicknameTableColumnNames.accountDetails}
        isSorted={props.column.getIsSorted()}
      />
    ),
    accessorFn: ({ accountNumber }) => accountNumber,
    cell: ({ row }) => {
      const { accountID, accountNumber } = row.original
      return (
        <AccountNumberCell
          accountIdOrKey={accountID}
          search={searchText}
          fallbackValue={accountNumber}
        />
      )
    },
    minSize: 200,
    enableGlobalFilter: true
  },
  {
    id: 'addressMappings',
    header: nicknameTableColumnNames.accountStencil,
    accessorFn: ({ addressMappings }) => addressMappings?.[0]?.address,
    cell: (props) => (
      <AccountStencil account={props.row.original} searchText={searchText} />
    ),
    minSize: 260,
    enableGlobalFilter: true,
    enableSorting: false
  },
  {
    id: 'sendToTamarac',
    header: () => (
      <div css={{ textAlign: 'center' }}>
        {nicknameTableColumnNames.sendToTamarac}
      </div>
    ),
    accessorFn: ({ accountNicknames }) => accountNicknames?.sendToPerformance,
    cell: (props) => <InputCheckboxTableCell context={props} />,
    size: 90,
    minSize: 70,
    maxSize: 140,
    enableGlobalFilter: true,
    enableSorting: false
  },
  {
    id: 'advisorAddedNickName',
    header: () => (
      <HeaderWithRadioButton
        fieldName="accountNicknames.advisorAddedNickName"
        title={nicknameTableColumnNames.rDotNickname}
        tooltiptxt={nicknameTableColumnNames.advisorTooltip}
      />
    ),
    accessorFn: ({ accountNicknames }) =>
      accountNicknames?.advisorAddedNickName,
    cell: (props) => <AdvisorNicknameCell context={props} />,
    minSize: 220,
    enableGlobalFilter: true,
    enableSorting: false
  },
  {
    id: 'clientAddedNickName',
    header: () => (
      <HeaderWithRadioButton
        fieldName="accountNicknames.clientAddedNickName"
        title={nicknameTableColumnNames.rockefellerDigitalNickname}
        tooltiptxt={nicknameTableColumnNames.accountNicknameTooltip}
      />
    ),
    accessorFn: ({ accountNicknames }) => accountNicknames?.clientAddedNickName,
    cell: (props) => <ClientNicknameCell context={props} />,
    minSize: 220,
    enableGlobalFilter: true,
    enableSorting: false
  },
  {
    id: 'shortName',
    header: () => (
      <HeaderWithRadioButton
        fieldName="shortName"
        title={nicknameTableColumnNames.shortName}
        tooltiptxt={nicknameTableColumnNames.shortNameTooltip}
      />
    ),
    accessorFn: ({ shortName }) => shortName,
    cell: (props) => (
      <HighlightSearchText
        text={props.getValue() as string}
        search={searchText}
      />
    ),
    minSize: 220,
    enableGlobalFilter: true,
    enableSorting: false
  }
]
