import axios from 'axios'
import { IApiOptions } from '../../../shared/contracts/IApiOptions'
import {
  MasterData,
  NewFeeRequest,
  UpdateFeeRequest,
  IGLStringReq,
  IGLString,
  IFeeScheduleForm,
  IInsertUpdateFeeScheduleApiRes,
  IAgencyFeeTypeModel,
  IDepositAccountModel,
  IGLAccountModel,
  IGLAccountResponseModel
} from '../store/types'

export const fetchFeeCategoryTypesService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/feeutil/feeCodes/FEE_CATEGORY`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<MasterData[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchFeeFrequenciesService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/feeutil/feeCodes/FEE_FREQUENCY`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<MasterData[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchAgencyFeeService = (options: IApiOptions, trustType = '') => {
  const url = `${options.apiRoot}/feeutil/feeCodes/AGENCY_FEE_TYPE?trustType=${trustType}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IAgencyFeeTypeModel[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchGLAccountService = (
  options: IApiOptions,
  payload: IGLAccountModel
) => {
  const url = `${options.apiRoot}/feeutil/fetchAumGLcode?feeType=${payload.feeType}&trustType=${payload.trustType}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IGLAccountResponseModel[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchDepositAccountService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/feeutil/feeCodes/DEPOSIT_ACCT`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IDepositAccountModel[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchFeeBusinessSegmentsService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/feeutil/feeCodes/FEE_BUSINESS_SEGMENT`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<MasterData[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchFeeCollectionTypesService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/feeutil/feeCodes/FEE_COLL_METHOD`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<MasterData[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchFeeRepCodesService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/feeutil/feeCodes/REP_CODE_DETAILS`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<MasterData[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchFeeServiceTypesService = (
  feeCategory: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/feeutil/feeCodes/FEE_TYPE_${feeCategory}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<MasterData[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchInvoiceFeeTypesService = (
  feeCategory: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/feeutil/feeCodes/FEE_TYPE_INV_${feeCategory}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<MasterData[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const createFeeService = (fee: NewFeeRequest, options: IApiOptions) => {
  const url = `${options.apiRoot}/feeutil/feeSchedule`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
    accept: 'application/json'
  }
  return axios
    .post<void>(url, fee, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const updateFeeScheduleService = (
  req: UpdateFeeRequest,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/feeutil/feeSchedule/${req.feeScheduleId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  delete req['feeScheduleId']
  return axios
    .put(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchFeeGLString = (req: IGLStringReq, options: IApiOptions) => {
  const url = `${options.apiRoot}/feeutil/glString`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IGLString>(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => {
      return res.data
    })
}

export const createFeeScheduleService = (
  fee: IFeeScheduleForm,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/Fee/InsertUpdateFeeStructureDetails`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
    accept: 'application/json'
  }
  return axios
    .post<IInsertUpdateFeeScheduleApiRes>(url, fee, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
