import { css } from '@emotion/react'

const colors = {
  background: '#fff',
  groupBackground: '#EFF6FB',
  groupLeftBorder: '#4C9DA8',
  itemBackground: '#EFF3F6',
  border: '#00000044',
  text: '#06262D',
  lightText: '#676767',
  green: '#008000'
}

export const accountSelectorStyles = {
  container: css({
    border: `solid 1px ${colors.border}`,
    marginBottom: '5px',
    '&:last-of-type': {
      marginBottom: 0
    }
  }),
  accountRowContainer: css({
    borderRight: `solid 1px ${colors.border}`,
    borderLeft: `solid 1px ${colors.border}`,
    padding: '1px 3px',
    '&:has(+ :not(&)), :last-child': {
      borderBottom: `solid 1px ${colors.border}`,
      paddingBottom: '2px'
    }
  }),
  groupRowInnerContainer: css({
    borderBottom: `solid 1px ${colors.border}`
  }),
  groupRowInnerContainerCollapsed: css({
    border: `solid 1px ${colors.border}`
  }),
  groupRowOuterContainer: css({
    marginTop: '4px',
    border: `solid 1px ${colors.border}`,
    borderBottom: 'none',
    paddingBottom: '2px'
  }),
  groupRowOuterContainerCollapsed: css({
    border: 'none'
  }),
  groupItem: css({
    backgroundColor: colors.groupBackground,
    padding: '10px 10px 5px 7px',
    borderLeft: `solid 4px ${colors.groupLeftBorder}`
  }),
  expandedGroupItem: {
    borderBottom: `solid 1px ${colors.border}`
  },
  itemsContainer: css({
    padding: '2px 5px'
  }),
  accountItem: css({
    backgroundColor: colors.itemBackground,
    padding: '6px',
    marginBottom: '2px',
    '&:last-of-type': {
      marginBottom: 0
    }
  }),
  checkbox: css({
    padding: 0,
    margin: 0,
    cursor: 'pointer'
  }),
  groupBalance: css({
    fontSize: '19px',
    fontWeight: 600,
    color: colors.text
  }),
  groupTitle: css({
    fontSize: '13px',
    fontWeight: 600,
    color: colors.lightText
  }),
  itemBalance: css({
    fontSize: '14px',
    fontWeight: 600,
    color: colors.text
  }),
  itemTitle: css({
    fontSize: '12px',
    fontWeight: 600,
    color: colors.lightText
  }),
  itemBalanceChange: css({
    fontSize: '12px',
    fontWeight: 600
  }),
  positiveBalanceChange: css({
    color: colors.green
  }),
  flexColumn: css({
    display: 'flex',
    flexDirection: 'column'
  }),
  flexRow: css({
    display: 'flex'
  }),
  chevron: css({
    border: `1px solid #B8D5ED`,
    height: '18px',
    width: '18px',
    borderRadius: '18px',
    cursor: 'pointer',
    backgroundColor: 'white'
  }),
  comingSoon: css({
    padding: '15px'
  })
}
