import { css } from '@emotion/react'
import {
  IStyle,
  ITooltipProps,
  makeStyles,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  TooltipHost
} from '@fluentui/react'
import { compareAsc, format } from 'date-fns'
import { range } from 'lodash'
import { constants } from 'modules/Advisory/shared/theme'
import { useEffect, useMemo } from 'react'
import { FormattedNumber } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import {
  HurdleMetricType,
  IHurdle,
  IHurdleStatus,
  IMetricProgress,
  IMetricWithProgress
} from '../../../../../../../../api/datahub'
import { useDomainStore } from '../../../../../../../../features/Domain/store/domain'
import { HurdleStatusIndicator } from '../../../../../../../../features/Hurdles/HurdleStatusIndicator'
import { parseDateISOStringInLocalTimezone } from '../../../../../../../../shared'
import { USD } from '../../../../../../../../shared/components/Formatting'
import { HorizontalScrollContainer } from '../../../../../../../../shared/components/HorizontalScrollContainer'
import {
  isNotNullOrFalse,
  isNotNullOrUndefined
} from '../../../../../../../../shared/guards'
import { chartColors } from '../../../../../../../../shared/services/theme'
import { useGetMostRecentClosedRevenueDate } from '../../../../store/mostRecentClosedDateFetch'
import {
  getHurdlesWithProgressFetchError,
  hurdlesWithProgressFetchActions
} from './store/hurdlesWithProgressFetch'
import { getHurdlesWithProgress, getTableDates } from './store/hurdleTableUI'

const useFixedHeaders = () => {
  const offset = 73
  const fixedHeaders = css({
    'tr th:first-child, tr td:first-child': {
      left: `${offset + 40}px`
    },
    'tr th:nth-child(2), tr td:nth-child(2)': {
      left: `${offset + 260}px`
    },
    'tr th:nth-child(3), tr td:nth-child(3)': {
      left: `${offset + 465}px`
    },
    'tr th:nth-child(4), tr td:nth-child(4)': {
      left: `${offset + 520}px`
    }
  })

  return {
    fixedHeaders
  }
}

const useHurdleTableStyles = makeStyles((theme) => {
  const hurdleTable: IStyle = {
    tableLayout: 'fixed',
    borderSpacing: '0',
    'th, td': {
      padding: '2px 8px',
      textAlign: 'right'
    },
    th: {
      fontWeight: 'bolder',
      position: 'sticky',
      top: constants.headerHeightPx,
      backgroundColor: theme.semanticColors.bodyBackground,
      zIndex: 1
    },
    'tr th:first-child, tr td:first-child': {
      textAlign: 'left',
      position: 'sticky',
      top: constants.headerHeightPx,
      zIndex: 1,
      backgroundColor: theme.semanticColors.bodyBackground,
      minWidth: '225px',
      maxWidth: '225px'
    },
    'tr th:nth-child(2), tr td:nth-child(2)': {
      textAlign: 'left',
      position: 'sticky',
      top: constants.headerHeightPx,
      zIndex: 1,
      backgroundColor: theme.semanticColors.bodyBackground,
      maxWidth: '205px',
      minWith: '205px'
    },
    'tr th:nth-child(3), tr td:nth-child(3)': {
      textAlign: 'left',
      position: 'sticky',
      top: constants.headerHeightPx,
      zIndex: 1,
      backgroundColor: theme.semanticColors.bodyBackground,
      maxWidth: '55px',
      minWidth: '55px'
    },
    'tr th:nth-child(4), tr td:nth-child(4)': {
      position: 'sticky',
      top: constants.headerHeightPx,
      zIndex: 1,
      maxWidth: '180px',
      minWidth: '180px',
      backgroundColor: theme.palette.neutralLighterAlt
    },
    'td.total': {
      fontWeight: 'bolder'
    },
    'td.measurement': {
      paddingLeft: '25px'
    },
    'thead th': {
      paddingTop: '5px',
      paddingBottom: '5px',
      fontWeight: 'bold'
    },
    'td.metric, th.metric': {
      textAlign: 'left'
    },

    'tr.hover:hover td': {
      backgroundColor: theme.palette.neutralLight
    },
    'tr.team td': {
      paddingTop: '5px'
    },
    'th.month': {
      minWidth: '180px',
      maxWidth: '180px'
    }
  }

  return {
    hurdleTable
  }
})

export const AdvisorHurdleTable: React.FC = () => {
  const classes = useHurdleTableStyles()
  const fixedClasses = useFixedHeaders()
  const { selectedAccountRepIds, selectedTeams, isFullDomainSelected } =
    useDomainStore()
  const dispatch = useDispatch()
  useEffect(() => {
    const filters = !isFullDomainSelected
      ? [...(selectedAccountRepIds || []), ...(selectedTeams || [])]
      : undefined
    if (!selectedAccountRepIds?.length) {
      return
    }
    dispatch(hurdlesWithProgressFetchActions.request(filters))
  }, [dispatch, isFullDomainSelected, selectedAccountRepIds, selectedTeams])

  const { mostRecentClosedDate } = useGetMostRecentClosedRevenueDate()

  const hurdles = useSelector(getHurdlesWithProgress)
  const monthHeaders = useMemo(() => getTableDates(), [])
  const error = useSelector(getHurdlesWithProgressFetchError)

  return (
    <HorizontalScrollContainer>
      <div>
        <table
          css={[fixedClasses.fixedHeaders]}
          className={classes.hurdleTable}
        >
          <thead>
            <tr>
              <th style={{ zIndex: 2 }}>Name / Target Date</th>
              <th style={{ zIndex: 2, minWidth: '205px' }} className={'metric'}>
                Metric
              </th>
              <th style={{ zIndex: 2 }}>Status</th>
              <th style={{ zIndex: 2 }}>Progress</th>
              {monthHeaders.map((month, index) => (
                <th key={index} className={'month'}>
                  <Stack>
                    <Text styles={{ root: { fontWeight: 'bold' } }}>
                      {format(month, `MMM ''yy`)}
                    </Text>
                    <Text variant="small">
                      {mostRecentClosedDate &&
                        (compareAsc(month, mostRecentClosedDate) <= 0
                          ? 'Closed'
                          : 'Open')}
                    </Text>
                  </Stack>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {hurdles?.map((hurdle, index) => (
              <HurdleGroup hurdle={hurdle} key={index} />
            ))}
          </tbody>
        </table>
        {hurdles && !hurdles?.length && (
          <MessageBar>No Results Found</MessageBar>
        )}
        {error && (
          <MessageBar messageBarType={MessageBarType.error}>
            {error.message}
          </MessageBar>
        )}
      </div>
    </HorizontalScrollContainer>
  )
}

const HurdleGroup: React.FC<{ hurdle: IHurdle }> = ({ hurdle }) => {
  return (
    <>
      <tr className={'team'}>
        <td>
          <Text
            styles={{ root: { fontWeight: 'bold' } }}
            block={true}
            nowrap={true}
            title={hurdle.name}
          >
            {hurdle.name}
          </Text>
        </td>
        {range(9).map((x) => (
          <td key={x} />
        ))}
      </tr>
      {hurdle?.measurements?.map((measurement) =>
        measurement?.metrics?.map((metric: IMetricWithProgress, index) => (
          <tr key={index} className={'hover'}>
            <td className="measurement">
              {index === 0
                ? [
                    measurement.targetDate
                      ? format(
                          parseDateISOStringInLocalTimezone(
                            measurement.targetDate
                          ),
                          `MMM ''yy`
                        )
                      : '',
                    measurement.intervalOfMeasurement !== 'Annual' &&
                      ` | ${measurement.intervalOfMeasurement}`
                  ]
                    .filter(isNotNullOrFalse)
                    .join(' ')
                : ''}
            </td>
            <td className={'metric'}>
              <MetricCell metric={metric} />
            </td>
            <td style={{ textAlign: 'center' }}>
              <HurdleStatusIndicator
                status={metric.status}
                disableEdit={true}
              />
            </td>
            <td>
              {metric.totalProgress?.progress &&
              metric.status !== 'Not Applicable' ? (
                <ProgressBar
                  metric={metric.totalProgress}
                  status={metric.status}
                  type={metric.metricType}
                />
              ) : (
                '--'
              )}
            </td>
            {metric.trailing6MonthProgress?.map((progress, index) => (
              <td key={index}>
                {progress.progress && metric.status !== 'Not Applicable' ? (
                  <ProgressBar
                    metric={progress || 0}
                    type={metric.metricType}
                  />
                ) : (
                  '--'
                )}
              </td>
            ))}
          </tr>
        ))
      )}
    </>
  )
}

const ProgressBar: React.FC<{
  metric: IMetricProgress
  status?: IHurdleStatus
  type?: HurdleMetricType
}> = ({ metric, status, type }) => {
  const { progress, total } = metric
  const backgroundColor = useMemo(() => {
    if (status === 'Missed') {
      return `${chartColors[4]}55`
    } else if (status === 'Achieved') {
      return `${chartColors[3]}aa`
    } else {
      return `${chartColors[9]}55`
    }
  }, [status])
  const tooltipProps: ITooltipProps = {
    onRenderContent: () => (
      <Stack>
        <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
          <Text>Period Date Range:</Text>
          <Text style={{ fontWeight: 'bold' }}>
            {metric.periodStartDate &&
              format(metric.periodStartDate, `MMM ''yy`)}{' '}
            - {metric.periodDate && format(metric.periodDate, `MMM ''yy`)}
          </Text>
        </Stack>
        {type !== 'AUS' && (
          <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
            <Text>Advertised T12:</Text>
            <Text style={{ fontWeight: 'bold' }}>
              <USD
                value={metric.advertisedT12Revenue || 0}
                fractionDigits={0}
              />
            </Text>
          </Stack>
        )}
        <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
          <Text>Metric Target:</Text>
          <Text style={{ fontWeight: 'bold' }}>
            <USD value={metric.metricTarget || 0} fractionDigits={0} />
          </Text>
        </Stack>
        {type !== 'AUS' && (
          <>
            <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
              <Text>Current Month Annualized:</Text>
              <Text style={{ fontWeight: 'bold' }}>
                <USD
                  value={metric.currentMonthAnnualized || 0}
                  fractionDigits={0}
                />
              </Text>
            </Stack>
            <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
              <Text>Current T12:</Text>
              <Text style={{ fontWeight: 'bold' }}>
                <USD value={metric.currentT12 || 0} fractionDigits={0} />
              </Text>
            </Stack>
          </>
        )}
      </Stack>
    )
  }
  return (
    <div
      style={{
        position: 'relative',
        width: '100%'
      }}
    >
      <TooltipHost tooltipProps={tooltipProps}>
        <div
          style={{
            position: 'absolute',
            right: '5px',
            top: '0',
            height: '100%',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Stack
            horizontal={true}
            verticalAlign="center"
            tokens={{ childrenGap: 4 }}
            styles={{ root: { padding: '2px 0x' } }}
          >
            <Text>
              <USD value={total || 0} fractionDigits={0} />
            </Text>
            <Text>|</Text>
            <Text style={{ fontWeight: 'bold' }}>
              <FormattedNumber
                value={progress && progress > 0 ? progress * 100 : 0}
                maximumFractionDigits={1}
                minimumFractionDigits={1}
              />
              {'%'}
            </Text>
          </Stack>
        </div>
        <div
          style={{
            height: '25px',
            padding: '2px',
            width: `${progress ? (progress < 1 ? progress * 100 : 100) : 0}%`,
            backgroundColor
          }}
        />
      </TooltipHost>
    </div>
  )
}

const MetricCell: React.FC<{
  metric: IMetricWithProgress
}> = ({ metric }) => {
  const tooltipProps: ITooltipProps = {
    onRenderContent: () => (
      <Stack>
        {metric.metricType !== 'AUS' && (
          <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
            <Text>Advertised T12:</Text>
            <Text style={{ fontWeight: 'bold' }}>
              <USD value={metric.advertisedT12 || 0} fractionDigits={0} />
            </Text>
          </Stack>
        )}
        <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
          <Text>
            {metric.metricType === 'AUS'
              ? 'Metric Target'
              : 'Metric Target Percent'}
            :
          </Text>
          {metric.metricType === 'AUS' ? (
            <Text style={{ fontWeight: 'bold' }}>
              ${metric.metricValue?.toLocaleString()}
            </Text>
          ) : (
            <Text style={{ fontWeight: 'bold' }}>
              <FormattedNumber
                value={metric.metricValue || 0}
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
              %
            </Text>
          )}
        </Stack>
      </Stack>
    )
  }
  return (
    <TooltipHost tooltipProps={tooltipProps}>
      <Text block={true} nowrap={true}>
        {[
          [
            metric.metricType === 'T-12 From Hurdle'
              ? 'T-12'
              : metric.metricType === '3 Months Annualized'
              ? '3 Mo Annu'
              : metric.metricType,
            metric.targetValue
              ? `$${metric.targetValue?.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                })}`
              : undefined
          ]
            .filter(isNotNullOrUndefined)
            .join(' | ')
        ]
          .filter(isNotNullOrUndefined)
          .join(' | ')}
      </Text>
    </TooltipHost>
  )
}
