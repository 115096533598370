import { combineReducers } from 'redux'
import { revenueDashboardReducer, revenueDashboardSagas } from './Dashboard'
import { hurdlesDashboardReducer, hurdlesDashboardSagas } from './Hurdles'

export const revenueModulesReducer = combineReducers({
  dashboard: revenueDashboardReducer,
  hurdles: hurdlesDashboardReducer
})

export const revenueModulesSagas = [
  ...revenueDashboardSagas,
  ...hurdlesDashboardSagas
]
