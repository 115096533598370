import { IFetchPaymentTransactionsResponse } from './types'

interface IFetchPaymentTransactionsResponseData {
  data: IFetchPaymentTransactionsResponse[]
}

export const fetchPaymentTransationsListMockResponse: IFetchPaymentTransactionsResponseData =
  {
    data: [
      {
        tranid: 1,
        account: 'John Doe',
        amount: 456.78,
        date: '03/17/2022',
        description: 'Direct Deposit - Bill payments',
        glaccount: 'Transfer From Other Acc (05541)',
        isclassified: true,
        payee: 'Test 1 Payee',
        subaccount: 'RJL000030 (TESTJT)',
        transtype: 'Deposit'
      },
      {
        tranid: 2,
        account: 'Jane Jill',
        amount: 20700.78,
        date: '03/17/2022',
        description: 'Direct Debit Standard Plumbing',
        glaccount: 'Transfer To Other Acc (09016)',
        isclassified: false,
        payee: 'Test 2 Payee',
        subaccount: 'Primary Home (P00012345)',
        transtype: 'Debit'
      },
      {
        tranid: 3,
        account: 'Jack Doe',
        amount: 5000.78,
        date: '03/22/2022',
        description: 'Direct Debit Standard Plumbing',
        glaccount: 'Transfer To Other Acc (09016)',
        isclassified: true,
        payee: 'Test 1 Payee',
        subaccount: '',
        transtype: 'Debit'
      }
    ]
  }
