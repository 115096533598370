import { format } from 'date-fns'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSharedActivityStore } from '../../modules/shared/activity'
import { todaysChangeFields } from '../../store/balancesApi/IBalanceDetailResponse'
import { useRdot360Context } from '../../store/rdot360Context'
import { useGetStaticConfig } from '../../store/templateApi/templateApi'

export const useNavigateTo = () => {
  const { setCategories } = useSharedActivityStore()
  const { setCustomDateRange } = useSharedActivityStore()

  const todayDate = format(new Date(), 'yyyy-MM-dd')
  const { currentBusinessDate } = useGetStaticConfig()
  const { asOfDate } = useRdot360Context()

  const navigate = useNavigate()

  const handleActivityNavigation = useCallback(
    (category: string) => {
      if (asOfDate) {
        setCustomDateRange(asOfDate, asOfDate)
      } else if (currentBusinessDate) {
        setCustomDateRange(currentBusinessDate, todayDate)
      } else {
        setCustomDateRange(todayDate, todayDate)
      }
      switch (category) {
        case todaysChangeFields.deposits:
          setCategories(['DP'])
          break
        case todaysChangeFields.withdrawals:
          setCategories(['WD'])
          break
        case todaysChangeFields.other:
          setCategories(['FE', 'JN', 'CA', 'EX', 'ZZ'])
          break
        case todaysChangeFields.interestOrDividends:
          setCategories(['DV'])
          break
        case 'Cash':
          setCategories(['DP', 'WD'])
          break
        default:
          setCategories(['DV', 'FE', 'DP', 'WD', 'JN', 'CA', 'EX', 'ZZ'])
          break
      }
      navigate('./activity')
    },
    [
      asOfDate,
      currentBusinessDate,
      navigate,
      setCategories,
      setCustomDateRange,
      todayDate
    ]
  )
  return { handleActivityNavigation }
}
