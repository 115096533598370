import axios from 'axios'
import { subMonths } from 'date-fns'
import { flow } from 'lodash/fp'
import { createSelector } from 'reselect'
import { call, cancelled, put, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import {
  getBdaTransactions,
  IDynamicsApiResult,
  IDynamicsBdaTransaction
} from '../../../../../api/dynamics'
import { parseDateISOStringInLocalTimezone } from '../../../../../shared'
import { isNotNullOrUndefined } from '../../../../../shared/guards'
import { AppState } from '../../../../../store'
import { getDynamicsApiOptions } from '../../../../../store/shared/sagas'
import { BdaSummaryTransaction } from '../common/types'

const REQUEST = '@modules/@advisory/@modules/@bda/@bdaTransactions/REQUEST'
const SUCCESS = '@modules/@advisory/@modules/@bda/@bdaTransactions/SUCCESS'
const FAILURE = '@modules/@advisory/@modules/@bda/@bdaTransactions/FAILURE'

export const bdaTransactionActions = {
  request: createAction(REQUEST)<number>(),
  success: createAction(SUCCESS)<IDynamicsBdaTransaction[]>(),
  failure: createAction(FAILURE)<Error>()
}

export type BdaTransactionActionTypes = ActionType<typeof bdaTransactionActions>

export interface IBdaTransactionsState {
  items?: IDynamicsBdaTransaction[]
  loading?: boolean
  error?: Error
}

const initialState: IBdaTransactionsState = {
  loading: false
}

export const bdaTransactionsReducer = createReducer<
  IBdaTransactionsState,
  BdaTransactionActionTypes
>(initialState)
  .handleAction(bdaTransactionActions.request, () => ({
    ...initialState,
    loading: true
  }))
  .handleAction(bdaTransactionActions.success, (state, action) => ({
    ...state,
    items: action.payload,
    loading: false
  }))
  .handleAction(bdaTransactionActions.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload
  }))

export const getBdaTransactionsState = (state: AppState) =>
  state.modules.advisory.modules.bda.transactions
const getBdaTransactionsItemsInternal = flow(
  getBdaTransactionsState,
  (x) => x.items
)

export type BDATransactionCategory = keyof typeof transactionCategories
const transactionCategories = {
  'T&E': ['T&E'],
  Technology: ['Technology'],
  'Fees Waived': ['Fees Waived'],
  Marketing: ['Marketing'],
  Other: ['Other'],
  Unknown: ['Unknown']
}
const transactionCategoryLookup: Record<string, string> = Object.entries(
  transactionCategories
)
  .flatMap(([key, values]) => values.map((value) => [value, key]))
  .reduce((a, [key, value]) => ({ ...a, [key]: value }), {})

export const getAllTransactionCategories = () => transactionCategories

const mapToSummaryTransaction = (
  transaction: IDynamicsBdaTransaction
): BdaSummaryTransaction => ({
  ...transaction,
  date: transaction.rcm_date
    ? parseDateISOStringInLocalTimezone(transaction.rcm_date)
    : undefined,
  value: transaction.rcm_totalamount,
  category:
    (transaction.rcm_trackercategory &&
      transactionCategoryLookup[transaction.rcm_trackercategory]) ||
    'Unknown'
})
export const getBdaTransactionsItems = createSelector(
  [getBdaTransactionsItemsInternal],
  (items) => items?.map(mapToSummaryTransaction)
)
export const getIsBdaTransactionsLoading = flow(
  getBdaTransactionsState,
  (x) => x.loading
)
export const getBdaTransactionsError = flow(
  getBdaTransactionsState,
  (x) => x.error
)

export const fetchBdaTransactions = function* (
  action: ReturnType<typeof bdaTransactionActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getDynamicsApiOptions, source.token)
  const pageSize = 500
  const start = new Date(Date.UTC(action.payload, 0, 1, 0, 0, 0, 0))

  try {
    const bdaTransactions = yield* call(
      getBdaTransactions,
      apiOptions,
      subMonths(start, 1),
      new Date(Date.UTC(start.getUTCFullYear(), 11, 31, 23, 59, 59, 999)),
      pageSize
    )

    const pages = [bdaTransactions]
    let nextLink = bdaTransactions['@odata.nextLink']

    const fetchNext = (nextLink: string) =>
      axios
        .get<IDynamicsApiResult<IDynamicsBdaTransaction>>(nextLink, {
          headers: {
            Authorization: `Bearer ${apiOptions.accessToken}`,
            Prefer: [
              `odata.maxpagesize=${pageSize}`,
              `odata.include-annotations="OData.Community.Display.V1.FormattedValue"`
            ].join(', ')
          }
        })
        .then((x) => x.data)

    while (nextLink) {
      const next = yield* call(fetchNext, nextLink)
      pages.push(next)
      nextLink = next['@odata.nextLink']
    }

    const results =
      pages
        .map(({ value }) => value)
        .filter(isNotNullOrUndefined)
        .flat() || []

    yield put(bdaTransactionActions.success(results))
  } catch (e: any) {
    yield put(bdaTransactionActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const bdaTransactionsSagas = [
  () => takeLatest(bdaTransactionActions.request, fetchBdaTransactions)
]
