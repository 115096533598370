import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

export interface IClientProfileCalloutState {
  showCallout?: boolean
  error?: Error
}

const { actions, reducer } = createSlice({
  name: 'useClientProfileCallout',
  initialState: {} as IClientProfileCalloutState,
  reducers: {
    show: () => ({
      showCallout: true
    }),
    hide: () => ({
      showCallout: false,
      error: undefined
    }),
    failure: (state, action: PayloadAction<Error>) => ({
      ...state,
      error: action.payload
    })
  }
})

const clientProfileRootSelector = (state: AppState) =>
  state.modules.advisory.modules.rdot360.createClientProfileCallout

const getShouldShowCallout = flow(
  clientProfileRootSelector,
  (x) => x.showCallout
)
const getPanelError = flow(clientProfileRootSelector, (x) => x.error)
export const useClientProfileCallout = () => {
  const dispatch = useDispatch()

  const hide = useCallback(() => {
    dispatch(actions.hide())
  }, [dispatch])

  const show = useCallback(() => {
    dispatch(actions.show())
  }, [dispatch])

  const setError = useCallback(
    (e: Error) => {
      dispatch(actions.failure(e))
    },
    [dispatch]
  )

  const showCallout = useSelector(getShouldShowCallout)

  const error = useSelector(getPanelError)

  return {
    hide,
    show,
    setError,
    showCallout,
    error
  }
}

export { reducer as createClientProfileCalloutReducer }
