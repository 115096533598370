import { ActionType, createAsyncAction } from 'typesafe-actions'
import { IFirm, IFirmInsertUpdateRes } from '../api/types'

export const firmFetchActions = createAsyncAction(
  '@modules/@firm/@firmlist/REQUEST',
  '@modules/@firm/@firmlist/SUCCESS',
  '@modules/@firm/@firmlist/FAILURE'
)<string | undefined, IFirm[] | undefined, Error>()

export const insertUpdateFirmActions = createAsyncAction(
  '@modules/@firm/@firmlist/INSERTUPDATE_REQUEST',
  '@modules/@firm/@firmlist/INSERTUPDATE_SUCCESS',
  '@modules/@firm/@firmlist/INSERTUPDATE_FAILURE',
  '@modules/@firm/@firmlist/INSERTUPDATE_CLEAR'
)<IFirm | undefined, IFirmInsertUpdateRes | undefined, Error, undefined>()

export type FirmActionsTypes =
  | ActionType<typeof firmFetchActions>
  | ActionType<typeof insertUpdateFirmActions>
