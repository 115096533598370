import {
  DirectionalHint,
  FontIcon,
  HoverCard,
  HoverCardType,
  IPlainCardProps,
  Link,
  makeStyles
} from '@fluentui/react'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getRevenueDashboardCategoryMode } from '../../store/dashboard'

const useClasses = makeStyles((theme) => ({
  info: {
    color: theme.palette.themeDark,
    paddingLeft: '5px'
  }
}))
const l1Mappings = [
  { category: 'Educational Savings', frequency: 'Monthly' },
  { category: 'Advisory Fees', frequency: 'Monthly' },
  { category: 'Banking Related', frequency: 'Monthly' },
  { category: 'Brokerage', frequency: 'T+2' },
  { category: 'Other', frequency: 'Monthly' },
  { category: 'Retirement', frequency: 'Monthly' },
  { category: 'Annuity & Insurance', frequency: 'Monthly' },
  { category: 'GFO Services', frequency: 'Monthly/Quarterly' },
  { category: 'Rockefeller Deals', frequency: 'Monthly' },
  { category: 'Structured Products', frequency: 'T+2' },
  { category: 'Alternative Investments', frequency: 'Monthly' }
]
const l2Mappings = [
  { category: '529', frequency: 'Monthly' },
  { category: 'Harvest', frequency: 'Monthly' },
  { category: 'Carrick Lane', frequency: 'Monthly' },
  { category: 'Envestnet', frequency: 'Monthly' },
  { category: 'BlackRock Held Away', frequency: 'Monthly' },
  { category: 'Margin Interest', frequency: 'Monthly' },
  { category: 'Sweep', frequency: 'Monthly' },
  { category: 'Cash', frequency: 'Monthly' },
  { category: 'Options', frequency: 'T+2' },
  { category: 'NFSC', frequency: 'Monthly' },
  { category: 'Other', frequency: 'Monthly' },
  { category: 'Foreign Exchange', frequency: 'Monthly' },
  { category: 'Units', frequency: 'T+2' },
  { category: 'Equities', frequency: 'T+2' },
  { category: 'Mutual Funds', frequency: 'T+2' },
  { category: 'Fixed Income', frequency: 'T+2' },
  { category: 'Retirement', frequency: 'Monthly' },
  { category: 'Annuity', frequency: 'Monthly' },
  { category: 'Insurance', frequency: 'Monthly' },
  { category: 'GFO 2020 Services', frequency: 'Monthly/Quarterly' },
  { category: 'GFO', frequency: 'Monthly/Quarterly' },
  { category: 'Titan AG', frequency: 'Monthly' },
  { category: 'Titan Birds Fund', frequency: 'Monthly' },
  { category: 'Titan Space Fund', frequency: 'Monthly' },
  { category: 'VantageRock', frequency: 'Monthly' },
  { category: 'TigerGlobal Access', frequency: 'Monthly' },
  { category: 'Lifted Truck', frequency: 'Monthly' },
  { category: 'Supreme Golf', frequency: 'Monthly' },
  { category: 'Structured Products', frequency: 'T+2' },
  { category: 'Alternative Investments', frequency: 'Monthly' }
]

export const InfoHoverCard: React.FC = () => {
  const classes = useClasses()
  const categoryMode = useSelector(getRevenueDashboardCategoryMode)
  const categoryMappings = categoryMode === 'L1' ? l1Mappings : l2Mappings
  const plainCardProps = useMemo(
    (): IPlainCardProps => ({
      onRenderPlainCard: () => (
        <div style={{ padding: '15px', maxWidth: '300px' }}>
          <table>
            <thead>
              <tr>
                <th style={{ textAlign: 'left' }}>Category</th>
                <th style={{ textAlign: 'right' }}>Frequency</th>
              </tr>
            </thead>
            <tbody>
              {categoryMappings.map((category, index) => (
                <tr key={index}>
                  <td style={{ textAlign: 'left' }}>{category.category}</td>
                  <td style={{ textAlign: 'right' }}>{category.frequency}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ),
      directionalHint: DirectionalHint.rightCenter,
      calloutProps: {
        isBeakVisible: true
      }
    }),
    [categoryMappings]
  )
  return (
    <HoverCard
      plainCardProps={plainCardProps}
      instantOpenOnClick={true}
      type={HoverCardType.plain}
    >
      <Link>
        <FontIcon iconName={'info'} className={classes.info} />
      </Link>
    </HoverCard>
  )
}
