import axios from 'axios'
import { IApiOptions } from '../../../../../../../shared/contracts/IApiOptions'
import {
  IAddClientReq,
  IClient,
  IManageClientReq,
  IManageClientRes,
  IUser
} from '../store/types'

export const fetchUserListService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/payment/api/enterprise/searchRcmUserList`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IUser[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const addClients = (req: IAddClientReq, options: IApiOptions) => {
  const url = `${options.apiRoot}/bill/api/clientsettings/createclient`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<void>(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const fetchClientListService = (
  userid: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/enterprise/searchRcmUserOrg?rcmUserId=${userid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IClient[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const manageClientOrgService = (
  req: IManageClientReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/payment/api/enterprise/manageRcmUserOrg?operationMode=${req.mode}&orgId=${req.orgId}&RcmUserId=${req.rcmUserId}&pkId=${req.pKid}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IManageClientRes>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
