import { call, delay, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { IAccount } from '../../../api/account.types'
import {
  OdataFilterOperatorEnum,
  OdataPropertyFilterGroup
} from '../../../api/odata'
import { AppState } from '../../../store'
import {
  createAsyncReducer,
  createAsyncSelectors
} from '../../../store/shared/asyncStore'
import { search } from '../../../store/shared/sagas'

export const accountLinkingValidationAccountsFetchActions = createAsyncAction(
  '@features/@accountLinking/@accounts/REQUEST',
  '@features/@accountLinking/@accounts/SUCCESS',
  '@features/@accountLinking/@accounts/FAILURE'
)<string[] | undefined, IAccount[], Error>()

export const accountLinkingValidationAccountsFetchReducer = createAsyncReducer(
  accountLinkingValidationAccountsFetchActions
)

const rootSelector = (state: AppState) =>
  state.features.accountLinking.validationAccountsFetch

export const {
  getError: getAccountLinkingValidationAccountsFetchError,
  getIsLoading: getAccountLinkingValidationAccountsFetchLoading,
  getResult: getAccountLinkingValidationAccountsFetchResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<
    typeof accountLinkingValidationAccountsFetchActions.request
  >
) {
  if (!action.payload || !action.payload.length) {
    yield put(accountLinkingValidationAccountsFetchActions.success([]))
    return
  }

  yield delay(500)

  const filters: OdataPropertyFilterGroup[] = [
    {
      and: [
        {
          operator: OdataFilterOperatorEnum.searchin,
          path: 'Accountsource',
          type: 'string',
          value: [
            'NFS',
            'Rockit',
            'FSA',
            'First Rate',
            'Addepar',
            'Tamarac',
            'SATool'
          ]
        }
      ]
    },
    {
      or: [
        {
          operator: OdataFilterOperatorEnum.searchin,
          path: 'CustodyAccount',
          type: 'string',
          value: action.payload
        },
        {
          operator: OdataFilterOperatorEnum.searchin,
          path: 'accountkey',
          type: 'string',
          value: action.payload
        }
      ]
    }
  ]

  try {
    const result = yield* call(search, 'account' as const, {
      filters,
      orderBy: [{ dataPath: 'id', direction: 'asc' as const }]
    })

    if (!result) {
      throw new Error(
        'An error occurred while fetching the account information'
      )
    }

    yield put(
      accountLinkingValidationAccountsFetchActions.success(
        (result.value as IAccount[]) || []
      )
    )
  } catch (e: any) {
    yield put(accountLinkingValidationAccountsFetchActions.failure(e))
  }
}

export const accountLinkingValidationAccountsFetchSagas = [
  () =>
    takeLatest(accountLinkingValidationAccountsFetchActions.request, onRequest)
]
