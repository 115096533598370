import axios from 'axios'
import { IApiOptions } from '../../../../../shared/contracts/IApiOptions'
import {
  IRelatedParty,
  IScheduleReport,
  IScheduleReportRequest,
  IScheduleReportResponse,
  relatedPartiesRequest
} from '../store/types'

const fetchScheduleReportListService = (
  options: IApiOptions,
  reportRequest: IScheduleReportRequest
) => {
  const url = `${
    options.apiRoot
  }/documentsapi/api/Reports/GetReportSchedules?partyId=${
    reportRequest.partyId
  }&reportId=${reportRequest.reportId ? reportRequest.reportId : ''}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IScheduleReport[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const createScheduleService = (
  options: IApiOptions,
  reportRequest: IScheduleReport
) => {
  const url = `${options.apiRoot}/documentsapi/api/Reports/InsertUpdateReportSchedule`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IScheduleReportResponse>(url, reportRequest, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const GetRelatedLoginsByPartyIdService = (
  options: IApiOptions,
  request: relatedPartiesRequest
) => {
  const url = `${options.apiRoot}/documentsapi/api/party/GetPartyIDLogins?partyid=${request.partyId}`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
    accept: 'application/json'
  }
  return axios
    .get<IRelatedParty[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
export {
  fetchScheduleReportListService,
  createScheduleService,
  GetRelatedLoginsByPartyIdService
}
