import { ActionType, createAction } from 'typesafe-actions'
import { IBeneficiary } from './types'
export const NAVIGATE_TO_TRUSTINFO = '@modules/@trusts/NAVIGATE_TO_TRUSTINFO'
export const NAVIGATE_TO_NEWTRUST = '@modules/@trusts/NAVIGATE_TO_NEWTRUST'
export const FETCH_BENEFICIARY_INFO = '@modules/@trusts/FETCH_BENEFICIARY_INFO'
export const FETCH_BENEFICIARY_INFO_SUCCESS =
  '@modules/@trusts/FETCH_BENEFICIARY_INFO_SUCCESS'
export const FETCH_BENEFICIARY_INFO_FAILURE =
  '@modules/@trusts/FETCH_BENEFICIARY_INFO_FAILURE'

export const trustNavigationActions = {
  navigateToTrustInfo: createAction(NAVIGATE_TO_TRUSTINFO)(),
  navigateToNewTrust: createAction(NAVIGATE_TO_NEWTRUST)()
}
export const beneficiaryInfoDataActions = {
  request: createAction(FETCH_BENEFICIARY_INFO)(),
  success: createAction(FETCH_BENEFICIARY_INFO_SUCCESS)<IBeneficiary[]>(),
  failure: createAction(FETCH_BENEFICIARY_INFO_FAILURE)<any>()
}
export type TrustNavigationActions = ActionType<typeof trustNavigationActions>

export type BeneficiaryInfoDataActions = ActionType<
  typeof beneficiaryInfoDataActions
>

export type BeneficiaryInfoActionType =
  | TrustNavigationActions
  | BeneficiaryInfoDataActions
