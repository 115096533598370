export const brokerageproducts = [
  'EQUITY',
  'COMMODITIES',
  'MUTUAL FUNDS',
  'FIXED INCOME',
  'OPTION',
  'UIT'
].map((x) => x.toLowerCase())

export const feebasedproducts = [
  'EQUITY',
  'COMMODITIES',
  'MUTUAL FUNDS',
  'FIXED INCOME',
  'OPTION',
  'UIT',
  'CASH & EQUIVALENTS'
].map((x) => x.toLowerCase())

export const accountTypes = ['Managed', 'Brokerage'].map((x) => x.toLowerCase())

export enum AUMCategoryEnum {
  FeeBased = 'Fee Based',
  Brokerage = 'Brokerage',
  StructuredProducts = 'Structured Products',
  Alternatives = 'Alternatives',
  BrokerageCash = 'Brokerage Cash',
  Lending = 'Lending',
  InsuranceAndAnnuity = 'Insurance and Annuity',
  AssetsHeldAway = 'Assets Held Away',
  Other = 'Other'
}
export const AUMCategories = Object.values(AUMCategoryEnum)

export enum T12CategoriesEnum {
  FeeBased = 'Fee Based',
  Brokerage = 'Brokerage',
  StructuredProducts = 'Structured Products',
  Alternatives = 'Alternatives',
  Lending = 'Lending',
  Other = 'Other'
}
export const T12Categories = Object.values(T12CategoriesEnum)

export enum T12VelocityCategoriesEnum {
  VelocityBPS = 'Velocity - bps (T-12 Revenue)',
  FeeBased = 'Fee Based',
  NonFeeBasedBSA = 'Non Fee Based (Brokerage, Structured, Alts)',
  NonFeeBasedBS = 'Non Fee Based (Brokerage, Structured)',
  NonFeeBasedB = 'Non Fee Based (Brokerage)'
}
export const T12VelocityCategories = Object.values(T12VelocityCategoriesEnum)

export enum AnnualizedCategoriesEnum {
  FeeBased = 'Fee Based',
  Brokerage = 'Brokerage',
  StructuredProducts = 'Structured Products',
  Alternatives = 'Alternatives',
  Lending = 'Lending',
  Other = 'Other'
}
export const AnnualizedCategories = Object.values(AnnualizedCategoriesEnum)

export enum AnnualizedVelocityCategoriesEnum {
  VelocityBPS = 'Velocity - bps (Annualized Revenue)',
  FeeBased = 'Fee Based',
  NonFeeBasedBSA = 'Non Fee Based (Brokerage, Structured, Alts)',
  NonFeeBasedBS = 'Non Fee Based (Brokerage, Structured)',
  NonFeeBasedB = 'Non Fee Based (Brokerage)'
}
export const AnnualizedVelocityCategories = Object.values(
  AnnualizedVelocityCategoriesEnum
)

export interface ITotalAndAverage {
  total?: number
  average?: number
}

export interface IWithAnalyticsDashboardCategories {
  aumCategories: Record<string, ITotalAndAverage>
  t12Categories: Record<string, ITotalAndAverage>
  annualizedCategories: Record<string, ITotalAndAverage>
  t12VelocityCategories: Record<string, number>
  annualizedVelocityCategories: Record<string, number>
}

export enum SummaryManagedBrokerageCategoriesEnum {
  'Common Stock' = 'COMMON',
  'ETFs' = 'ETF',
  'Preferred Equity' = 'Preferred Equity',
  'Rights/Warrants' = 'RIGHT/WARRANT',
  'Corps/Non-U.S. Debt' = 'CORPORATES & NON-US GOVT DEBT',
  "Govt' Agency & MBS" = 'GOVT AGENCY & MORTGAGE-BACKED',
  'Munis' = 'MUNIS',
  'Preferred FI' = 'Preferred FI',
  'Mutual Funds' = 'MUTUAL FUNDS',
  'Commodities' = 'COMMODITIES',
  'UITs' = 'UIT',
  'Structured Products' = 'STRUCTURED PRODUCTS',
  'Options' = 'OPTION',
  'Cash' = 'CASH & EQUIVALENTS'
}
export const SummayManagedBrokerageCategories = Object.keys(
  SummaryManagedBrokerageCategoriesEnum
)

export enum SummaryAICategoriesEnum {
  'Hedge Fund' = 'HEDGE FUNDS',
  'Private Equity' = 'PRIVATE EQUITY',
  'Private Credit' = 'PRIVATE CREDIT',
  'Real Estate' = 'REAL ESTATE',
  'Other AI' = 'OTHER AI'
}
export const SummaryAICategories = Object.keys(SummaryAICategoriesEnum)

export enum SummaryOtherCategoriesEnum {
  'Lending' = 'Lending Asset',
  'Insurance & Annuities' = 'Insurance & Annuities',
  'Assets Held Away' = 'Assets Held Away',
  'Other' = 'Other'
}
export const SummayOtherCategories = Object.keys(SummaryOtherCategoriesEnum)
