import {
  Checkbox,
  IColumn,
  ProgressIndicator,
  Stack,
  Text
} from '@fluentui/react'
import { CDMv2Account } from 'api/nickname.types'
import { TextCell } from 'features/Lists/core/components/TextCell'
import { useMemo } from 'react'
import { DataTable } from 'shared/components/DataTable'
import { ConnectedMaskedText } from 'shared/components/MaskedText'
import { useActivateProfilePanel } from './store/activateProfilePanel'

const defaultColumn: IColumn = {
  key: '',
  name: '',
  isResizable: true,
  minWidth: 80,
  maxWidth: 180
}

export const ActivateProfileAccountsList: React.FC<{
  accounts?: CDMv2Account[]
  selectedAccounts?: string[]
  onSelectionChanged: (key: string) => void
  isLoading?: boolean
}> = ({
  accounts = [],
  selectedAccounts = [],
  onSelectionChanged,
  isLoading
}) => {
  const { partyId } = useActivateProfilePanel()
  const columns: IColumn[] = useMemo(
    () => [
      {
        ...defaultColumn,
        isResizable: false,
        key: 'select',
        maxWidth: 20,
        minWidth: 20,
        onRender: (account: CDMv2Account) => {
          return (
            <Checkbox
              checked={selectedAccounts.includes(account.accountKey || '')}
              onChange={() =>
                account?.accountKey && onSelectionChanged(account?.accountKey)
              }
            />
          )
        }
      },
      {
        ...defaultColumn,
        name: 'Account',
        key: 'account',
        maxWidth: 150,
        onRender: (account: CDMv2Account) => (
          <Stack styles={{ root: { minWidth: 0, overflow: 'hidden' } }}>
            <Stack
              horizontal={true}
              tokens={{ childrenGap: 3 }}
              verticalAlign="center"
              styles={{ root: { minWidth: 0, overflow: 'hidden' } }}
            >
              <ConnectedMaskedText
                nowrap={true}
                text={account?.accountNumber}
              />
              {account?.registrationType && (
                <Text
                  variant="small"
                  nowrap={true}
                  styles={{ root: { fontWeight: 'bold' } }}
                >
                  ({account?.registrationType})
                </Text>
              )}
            </Stack>
            <Text variant="small">
              {account?.accountNicknames?.advisorAddedNickName ||
                account?.shortName}
            </Text>
          </Stack>
        )
      },
      {
        ...defaultColumn,
        key: 'name',
        name: 'Legal Entity Name',
        onRender: (account: CDMv2Account) => (
          <TextCell>
            {
              account?.stakeholders?.find((x) => x.partyID === partyId)?.party
                ?.partyName
            }
          </TextCell>
        )
      },
      {
        ...defaultColumn,
        key: 'household',
        name: 'Household',
        minWidth: 170,
        onRender: (account: CDMv2Account) => (
          <TextCell>{account?.household?.householdName}</TextCell>
        )
      },
      {
        ...defaultColumn,
        key: 'role',
        name: 'Role',
        minWidth: 170,
        onRender: (account: CDMv2Account) => {
          const stakeholder = account?.stakeholders?.find(
            (x) => x.partyID === partyId
          )
          const relationship =
            stakeholder?.accountRelationship ||
            stakeholder?.stakeholderType ||
            stakeholder?.stakeholderRelationship
          return (
            <Text
              nowrap={true}
              title={`${relationship?.name} (${relationship?.code})`}
            >
              {relationship?.name}{' '}
              {!!relationship?.code && `(${relationship?.code})`}
            </Text>
          )
        }
      },
      {
        ...defaultColumn,
        key: 'rep',
        name: 'Rep Code',
        maxWidth: 20,
        onRender: ({ registeredRep }: CDMv2Account) => (
          <TextCell>{registeredRep}</TextCell>
        )
      }
    ],
    [onSelectionChanged, partyId, selectedAccounts]
  )
  return (
    <>
      <div style={{ height: '3px' }}>{isLoading && <ProgressIndicator />}</div>
      <DataTable columns={columns} items={accounts} shimmerLines={5} />
    </>
  )
}
