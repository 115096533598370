import { lazy } from 'react'
import { IModuleDefinition } from '../types'

const AIRevenueContainer = lazy(() => import('./AIRevenueContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/airevenue/*',
    private: true,
    component: AIRevenueContainer
  },
  name: 'AIRevenue'
}

export default moduleDefinition
export * from './store'
