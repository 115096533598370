import { partial } from 'lodash/fp'
import { createListsComponents } from '../core/containers/service'
import { IUseListHookDefinition } from '../hooks/useList/hookDefinitions'
import { createFilter } from '../shared/lists'
import { AccountCell } from './AccountCell'
import { AccountColumnDefinitions } from './ColumnDefinitions'
import { actions, selectors } from './store'

export * from './store'

export const createAccountListFilter = partial(createFilter, [
  AccountColumnDefinitions
])

export const AccountListComponents = createListsComponents(
  actions,
  selectors,
  AccountCell
)

export const accountListHookDefinition: IUseListHookDefinition = {
  actions,
  selectors,
  columnDefinitions: AccountColumnDefinitions,
  listType: 'account'
}
