import { parseISO, startOfMonth } from 'date-fns/fp'
import { flow } from 'lodash/fp'
import { createSelector } from 'reselect'
import { all, put, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { IAUSHistoryItem } from '../../../../api/common.types'
import { AppState } from '../../../../store'
import { IRevenueHistory } from '../../../../store/context/advisor'
import {
  accountFetchActions,
  getAccountsError,
  getAccountsResult,
  getIsAccountsLoading
} from '../../store/householdAccountsFetch'
import {
  clientFetchActions,
  getClientsError,
  getClientsResult,
  getIsClientsLoading
} from '../../store/householdClientsFetch'
import { householdFeeFetchActions } from '../../store/householdFeeFetch'
import {
  getHouseholdError,
  getHouseholdResult,
  getIsHouseholdLoading,
  householdFetchActions
} from '../../store/householdFetch'

const SET_HOUSEHOLD_ID =
  '@features/@households/@householdDetail/@ui/SET_HOUSEHOLD_ID'
const OPEN_PANEL = '@features/@households/@householdDetail/@ui/OPEN_PANEL'
const CLOSE_PANEL = '@features/@households/@householdDetail/@ui/CLOSE_PANEL'
const UPDATE_FILTER = '@features/@households/@householdDetail/@ui/UPDATE_FILTER'

export const householdDetailUiActions = {
  setHouseholdId: createAction(SET_HOUSEHOLD_ID)<string>(),
  openPanel: createAction(OPEN_PANEL)<string | undefined>(),
  closePanel: createAction(CLOSE_PANEL)(),
  updateFilter: createAction(UPDATE_FILTER)<string>()
}
export interface IHouseholdDetailUiState {
  householdId?: string
  isPanelOpen?: boolean
  filterText?: string
}

const initialUiState: IHouseholdDetailUiState = { isPanelOpen: false }

export const householdDetailReducer = createReducer<
  IHouseholdDetailUiState,
  ActionType<typeof householdDetailUiActions>
>(initialUiState)
  .handleAction(householdDetailUiActions.setHouseholdId, (state, action) => ({
    ...state,
    householdId: action.payload
  }))
  .handleAction(householdDetailUiActions.openPanel, (state) => ({
    ...state,
    isPanelOpen: true
  }))
  .handleAction(householdDetailUiActions.closePanel, (state) => ({
    ...state,
    isPanelOpen: false,
    filterText: undefined
  }))
  .handleAction(householdDetailUiActions.updateFilter, (state, action) => ({
    ...state,
    filterText: action.payload || undefined
  }))

const rootSelector = (state: AppState) =>
  state.features.households.features.householdDetail

export const getIsHouseholdDetailPanelOpen = flow(
  rootSelector,
  ({ isPanelOpen }) => isPanelOpen
)
export const getHouseholdDetailUiHouseholdId = flow(
  rootSelector,
  ({ householdId }) => householdId
)
export const getHouseholdDetailUiHouseholdFilter = flow(
  rootSelector,
  ({ filterText }) => filterText
)

export const getCurrentHousehold = getHouseholdResult

export const getIsHouseholdDetailLoading = createSelector(
  [getIsClientsLoading, getIsAccountsLoading, getIsHouseholdLoading],
  (clients, accounts, household) => clients || accounts || household
)

export const getHouseholdDetail = createSelector(
  [
    getIsClientsLoading,
    getClientsResult,
    getAccountsResult,
    getHouseholdResult
  ],
  (loading, clients, accounts, household) => {
    if (loading) {
      return
    }

    return { clients, accounts, household }
  }
)

export const getHouseholdDetailError = createSelector(
  [getClientsError, getAccountsError, getHouseholdError],
  (clients, accounts, household): Error | undefined =>
    clients || accounts || household
)

export interface IAusHistoryWithDate extends IAUSHistoryItem {
  date: Date
  timestamp: number
}

export const getHouseholdSortedAusHistory = createSelector(
  [getHouseholdResult],
  (household): IAusHistoryWithDate[] => {
    return (
      household?.ausHistory
        ?.map((item) => {
          const date = flow(
            parseISO,
            startOfMonth,
            (x) => new Date(x.getFullYear(), x.getMonth(), x.getDate())
          )(item.DateAsOf || '')

          return {
            ...item,
            date,
            timestamp: date.getTime()
          }
        })
        .sort((a, b) => a.timestamp - b.timestamp) || []
    )
  }
)

export const getHouseholdSortedRevenueHistory = createSelector(
  [getHouseholdResult],
  (household) =>
    household?.revenueDetHistory
      ?.map((item): IRevenueHistory => {
        const date = flow(
          startOfMonth,
          (x) => new Date(x.getFullYear(), x.getMonth(), x.getDate())
        )(new Date(item.revYear || 0, item.revMonth ? item.revMonth - 1 : 0, 1))
        return {
          date,
          timestamp: date.getTime(),
          category: item.AssetType,
          revenue: item.revenueDet?.compRevenue
        }
      })
      .sort((a, b) => (a.timestamp || 0) - (b.timestamp || 0))
)

export const householdDetailSagas = [
  () =>
    takeLatest(
      householdDetailUiActions.openPanel,
      function* (
        action: ReturnType<typeof householdDetailUiActions.openPanel>
      ) {
        if (!action.payload) {
          return
        }

        yield put(householdDetailUiActions.setHouseholdId(action.payload))
      }
    ),
  () =>
    takeLatest(
      householdDetailUiActions.setHouseholdId,
      function* (
        action: ReturnType<typeof householdDetailUiActions.setHouseholdId>
      ) {
        yield all([
          put(householdFetchActions.request(action.payload)),
          put(accountFetchActions.request(action.payload)),
          put(clientFetchActions.request(action.payload)),
          put(householdFeeFetchActions.request(action.payload))
        ])
      }
    )
]
