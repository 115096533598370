import { format } from 'date-fns'
import type { SeriesAreaOptions, SeriesColumnOptions } from 'highcharts'
import { merge } from 'lodash'
import React, { useMemo } from 'react'
import {
  HighchartsComponent,
  HighchartsImport,
  useHighchartsOptions
} from 'shared/highcharts'

const getChartOptions = (highcharts: HighchartsImport): Highcharts.Options => ({
  title: {
    text: ''
  },
  yAxis: {
    title: {
      text: ''
    },
    softMin: 0,
    allowDecimals: false
  },
  legend: {
    enabled: true,
    layout: 'horizontal',
    symbolRadius: 0,
    align: 'center',
    verticalAlign: 'bottom',
    backgroundColor: 'transparent',
    floating: false,
    shadow: false
  },
  tooltip: {
    formatter: function formatTooltip(
      this: Highcharts.TooltipFormatterContextObject
    ) {
      return `<b>${format(this.x as number, `dd-MMM`)}</b><br/>${
        this.series.name
      }: $${highcharts.numberFormat(this.y as number, 0, '.', ',')}
      <br/>TOTAL: $${highcharts.numberFormat(this.total || 0, 0, '.', ',')}`
    }
  },
  plotOptions: {
    column: {
      borderWidth: 0,
      pointPadding: 0,
      maxPointWidth: 10,
      groupPadding: 0.1,
      shadow: false,
      stacking: 'normal',
      dataLabels: {
        enabled: false
      }
    },
    area: {
      shadow: false,
      stacking: 'normal',
      dataLabels: {
        enabled: false
      }
    }
  }
})

export interface ITimeSeriesChartSeries {
  name: string
  data: ITimeSeriesChartData[]
  stack?: number
  color?: string
}

export interface ITimeSeriesChartData {
  date?: Date
  value?: number
}

export interface ITimeSeriesChartProps {
  series: ITimeSeriesChartSeries[]
  chartType: ITimeSeriesChartType
  options?: Highcharts.Options
  desc?: boolean
  removeDailyTick?: boolean
}

export type ITimeSeriesChartType = 'area' | 'column'
const containerProps: React.HTMLAttributes<unknown> = {
  style: { height: '100%', width: '100%' }
}
export const DailyTimeSeriesChart: React.FC<ITimeSeriesChartProps> = ({
  series,
  chartType,
  options,
  desc,
  removeDailyTick
}) => {
  const weightedSeries = useMemo(
    () =>
      series.map((componentSeries) => {
        const { name, data, stack, color } = componentSeries

        const highChartsSeries: SeriesColumnOptions | SeriesAreaOptions = {
          name,
          data: data
            .filter(({ date }) => date != null)
            .map((item) => [item?.date?.getTime(), item.value])
            .sort((a, b) => (a[0] || 0) - (b[0] || 0)),
          stack: stack || 0,
          type: chartType || 'area',
          color
        }

        return {
          series: highChartsSeries,
          total: data.reduce((a, x) => a + (x?.value || 0), 0)
        }
      }),
    [chartType, series]
  )

  const data = useMemo(
    () =>
      desc
        ? weightedSeries.sort((a, b) => b.total - a.total).map((x) => x.series)
        : weightedSeries.sort((a, b) => a.total - b.total).map((x) => x.series),
    [desc, weightedSeries]
  )

  const seriesOptions: Highcharts.Options = useMemo(
    () => ({
      series: data?.length
        ? data
        : [{ data: [], type: 'column', name: 'Other' }],
      xAxis: {
        type: 'datetime',
        tickInterval: removeDailyTick ? undefined : 24 * 3600 * 1000,
        labels: {
          format: `{value:%e-%b}`
        }
      }
    }),
    [data, removeDailyTick]
  )
  const defaultChartOptions = useHighchartsOptions(getChartOptions)
  const mergedChartOptions = useMemo(
    () => merge({}, defaultChartOptions, options, seriesOptions),
    [defaultChartOptions, options, seriesOptions]
  )

  return (
    <HighchartsComponent
      options={mergedChartOptions}
      containerProps={containerProps}
    />
  )
}
