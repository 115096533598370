import { useCallback, useMemo } from 'react'
import { ViewType } from '../components/TileHeading'
import { ClientDashboardTiles } from '../shared/types'
import { useClientDashboardTilePreferences } from './useClientDashboardPreferences'

export const useHandleViewTypeChange = (tileName: ClientDashboardTiles) => {
  const { setTilePreferences, tilePreferences } =
    useClientDashboardTilePreferences(tileName)

  const viewType = useMemo<ViewType>(
    () => tilePreferences?.viewType || ViewType.table,
    [tilePreferences]
  )

  const handleViewTypeChange = useCallback(
    (viewType: ViewType) => {
      setTilePreferences({ viewType })
    },
    [setTilePreferences]
  )

  return {
    handleViewTypeChange,
    viewType
  }
}
