import { combineReducers } from 'redux'
import {
  payoutUserSearchFetchReducer,
  payoutUserSearchFetchSagas
} from './payoutUserSearchFetch'
import { repSearchFetchReducer, repSearchFetchSagas } from './repSearchFetch'
import { teamSearchFetchReducer, teamSearchFetchSagas } from './teamSearchFetch'

export const entitySearchReducer = combineReducers({
  payoutUserSearchFetch: payoutUserSearchFetchReducer,
  repSearchFetch: repSearchFetchReducer,
  teamSearchFetch: teamSearchFetchReducer
})

export const entitySearchSagas = [
  ...payoutUserSearchFetchSagas,
  ...repSearchFetchSagas,
  ...teamSearchFetchSagas
]
