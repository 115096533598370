import { lazy } from 'react'
import { IModuleDefinition } from '../../../types'

const RequestsContainer = lazy(() => import('./RequestsContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/requests/*',
    private: true,
    component: RequestsContainer
  },
  name: 'Requests'
}

export default moduleDefinition
