import { Theme } from '@emotion/react'

export const theme: Theme = {
  colors: {
    primaryDarkBlue: '#06262D',
    primaryLightBlue1: '#B0D6F1',
    primaryLightBlue2: '#C6E0F2',
    primaryLightBlue3: '#b8d5ed',
    primaryLightBlue4: '#eff6fb',
    primaryBlack: '#000000',
    primaryWhite: '#FFFFFF',
    primaryRed: '#a90505',
    secondaryWhite: '#f0f0f0',
    secondaryWhite2: '#eff3f6',
    secondaryWhite3: '#FAFAFA',
    secondaryBlue1: '#B3E3F4',
    secondaryBlue2: '#CBE0F0',
    secondaryTurquoise1: '#13A5B0',
    secondaryGreen1: '#C4E1B2',
    secondaryGreen2: '#7BC46C',
    secondaryGreen3: '#45BEAA',
    secondaryPurple1: '#B02C91',
    secondaryRed1: '#EF4264',
    secondaryRed2: '#B71F3A',
    secondaryViolet1: '#653C93',
    secondaryYellow1: '#F4B93F',
    secondaryYellow2: '#F89938',
    tertiaryBlue1: '#005CB8',
    tertiaryBlue2: '#3E8DDD',
    tertiaryBlue3: '#57C8E7',
    tertiaryBlue4: '#6fa3c5',
    tertiaryGray1: '#5C666F',
    tertiaryGray2: '#7C858C',
    tertiaryGray3: '#B2B3B2',
    tertiaryGray4: '#D1D3D3',
    tertiaryGray5: '#F7F7F7',
    extraBlue1: '#324B53',
    extraBlue2: '#2A769D',
    extraBlue3: '#13272B',
    extraBlue4: '#EAF6FC',
    extraBlue5: '#9AC1DB',
    extraGray: '#F5F5F5',
    extraGreen1: '#008000',
    extraGray1: '#D9D9D9',
    createNoteBackground: '#E2F1FB',
    panelCalloutBorder: '#AFB1B6'
  },
  fontWeights: {
    lighter: 'lighter',
    light: 300,
    regular: 400,
    medium: 500,
    demi: 700,
    bolder: 'bolder'
  },
  size: {
    xxs: '4px',
    xs: '8px',
    sm: '12px',
    md: '14px',
    lg: '16px',
    lg1: '18px',
    xl: '20px',
    xxl: '24px'
  }
}
