import { useCallback } from 'react'
import {
  UnknownPreferences,
  useGetPreferencesContainerQuery
} from './preferencesApi'
import { useLazyPreferencesContainer } from './useLazyPreferencesContainer'

const emptyResult = { static: 'useGetPreferencesContainerQuery' }
export const useEnsurePreferencesContainer = (containerName: string) => {
  useGetPreferencesContainerQuery(
    { containerId: containerName },
    { selectFromResult: () => emptyResult }
  )
}

export const usePreferencesContainer = <T = UnknownPreferences>(
  containerName: string
) => {
  const { data, isFetching, isSuccess, isError } =
    useGetPreferencesContainerQuery(
      { containerId: containerName },
      {
        selectFromResult: ({ data, isFetching, isSuccess, isError }) => ({
          data,
          isFetching,
          isSuccess,
          isError
        })
      }
    )

  const { setPreferences: setPreferencesLazy } =
    useLazyPreferencesContainer<T>()

  const setPreferences = useCallback(
    (value: Partial<T>) => {
      return setPreferencesLazy(containerName, value)
    },
    [containerName, setPreferencesLazy]
  )

  return {
    isFetchingComplete: isSuccess || isError,
    isSuccess,
    isError,
    isFetching,
    setPreferences,
    preferences: data?.value as T | undefined
  }
}
