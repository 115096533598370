import { useCallback } from 'react'
import { useSessionStateContainer } from 'store/session'
import { Drawdown, NonDrawdown, Combined } from '../types'

interface IDashboardSessionState {
  selectedTab: string
}

export const dashboardSessionStateKey = 'modules/advisory/aidashboard'

const dashBoardDefaults: IDashboardSessionState = {
  selectedTab: 'summary'
}

const validTabs = [
  'summary',
  Drawdown,
  NonDrawdown,
  Combined,
  'capitalcalls',
  'transfers'
] as string[]

const args = { defaults: dashBoardDefaults }

export const useAiPositionSessionState = () => {
  const { updateContainer, value = {} } = useSessionStateContainer(
    dashboardSessionStateKey,
    args
  )

  const { selectedTab = dashBoardDefaults.selectedTab } = value

  const setSelectedTab = useCallback(
    (newSelectedTab: string) => {
      if (!validTabs.includes(newSelectedTab)) {
        return
      }
      updateContainer({ selectedTab: newSelectedTab })
    },
    [updateContainer]
  )

  return { selectedTab, setSelectedTab }
}
