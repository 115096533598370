import { endOfDay, startOfDay } from 'date-fns'
import { trim, uniq, uniqBy } from 'lodash'
import { flow } from 'lodash/fp'
import {
  isNotNullOrEmpty,
  isNotNullOrFalse,
  isNotNullOrUndefined
} from '../shared/guards'
import { IOdataRequest } from './odata.types'
import { escapeAndEncodeQuery, operators, tokenizeQuery } from './search'

export type OdataFilterType =
  | 'string'
  | 'number'
  | 'date'
  | 'datetime'
  | 'boolean'

export enum OdataFilterOperatorEnum {
  eq = 'eq',
  ne = 'ne',
  gt = 'gt',
  ge = 'ge',
  lt = 'lt',
  le = 'le',
  in = 'in',

  contains = 'contains',
  search = 'search',
  searchin = 'search.in',
  startswith = 'startswith',

  dynamicsin = 'Microsoft.Dynamics.CRM.In'
}

export enum OdataFilterLogicalOperatorEnum {
  and = 'and',
  or = 'or',
  not = 'not'
}

export enum OdataFilterCollectionOperatorEnum {
  empty = 'empty',
  any = 'any'
}

export interface IOdataPropertyFilter {
  type: OdataFilterType
  operator: OdataFilterOperatorEnum
  value?: string | string[] | number | Date | null | boolean | boolean[]
  path?: string
}

export type OdataPropertyFilterGroup = {
  [key in keyof typeof OdataFilterLogicalOperatorEnum]?:
    | OdataPropertyFilterGroup
    | (IOdataPropertyFilter | IOdataCollectionFilter)[]
}

export interface IOdataCollectionFilter {
  operator: OdataFilterCollectionOperatorEnum
  path: string
  filter: OdataPropertyFilterGroup | null
}

const isCollectionFilter = (
  item: OdataPropertyFilterGroup | IOdataCollectionFilter | IOdataPropertyFilter
): item is IOdataCollectionFilter => {
  return (item as IOdataCollectionFilter).filter !== undefined
}

export const constructFilterQuery = (
  filters: (OdataPropertyFilterGroup | IOdataCollectionFilter)[]
) => {
  return filters
    .map((x) => {
      return isCollectionFilter(x)
        ? createCollectionFilterQuery(x)
        : processFilterGroup(x)
    })
    .join(` ${OdataFilterLogicalOperatorEnum.and} `)
}

export type YesNo = 'Yes' | 'No'
export const mapFacetStringToBoolean = (value: YesNo) => value === 'Yes'
export const mapBooleanToFacetString = (value: boolean): YesNo =>
  value ? 'Yes' : 'No'

const createCollectionFilterQuery = (
  collectionFilter: IOdataCollectionFilter
) => {
  const { filter, operator, path } = collectionFilter
  const subFilter =
    filter !== null ? `g: ${processFilterGroup(filter, 'g')}` : ''
  return `${path}/${operator}(${subFilter})`
}

export const escapeFilterString = (value: string) => value.replace(/'/gi, `''`)

const processFilter = (
  filter: IOdataPropertyFilter | IOdataCollectionFilter,
  collectionPrefix = ''
): string | undefined => {
  if (isCollectionFilter(filter)) {
    return createCollectionFilterQuery(filter)
  }

  const { value, operator, type } = filter
  let { path } = filter

  if (collectionPrefix) {
    path = `${collectionPrefix}${path && path !== '.' ? `/${path}` : ''}`
  }

  if (value === null || value === undefined || value === '') {
    return `(${[
      `${path} ${operator} null`,
      type === 'string' && `${path} ${operator} ''`
    ]
      .filter(Boolean)
      .join(
        operator === OdataFilterOperatorEnum.ne
          ? ` ${OdataFilterLogicalOperatorEnum.and} `
          : ` ${OdataFilterLogicalOperatorEnum.or} `
      )})`
  }

  if (type === 'string' && operator === OdataFilterOperatorEnum.searchin) {
    const values = value as string[]
    const strings = values.filter(Boolean)
    const nulls = values.filter((x) => !x)
    return `${[
      strings.length &&
        `search.in(${path}, '${strings
          .map(encodeURIComponent)
          .map(escapeFilterString)
          .join('|')}', '|')`,
      nulls.length && `${path} eq ''`,
      nulls.length && `${path} eq null`
    ]
      .filter(Boolean)
      .join(` ${OdataFilterLogicalOperatorEnum.or} `)}`
  }

  if (type === 'string' && operator === OdataFilterOperatorEnum.in) {
    const values = value as string[]
    const strings = values.filter(Boolean)
    const nulls = values.filter((x) => !x)
    return `${[
      strings.length &&
        `${path} in (${strings
          .map(encodeURIComponent)
          .map(escapeFilterString)
          .map((x) => `'${x}'`)
          .join(', ')})`,
      nulls.length && `${path} eq ''`,
      nulls.length && `${path} eq null`
    ]
      .filter(Boolean)
      .join(` ${OdataFilterLogicalOperatorEnum.or} `)}`
  }

  if (type === 'boolean' && operator === OdataFilterOperatorEnum.in) {
    const values = value as YesNo[]
    const booleans = values
      .filter(isNotNullOrEmpty)
      .map(mapFacetStringToBoolean)
    const nulls = values.filter((x) => !x)
    return `${[
      !!booleans.length && `${path} in (${booleans.join(', ')})`,
      !!nulls.length && `${path} eq null`
    ]
      .filter(isNotNullOrFalse)
      .join(` ${OdataFilterLogicalOperatorEnum.or} `)}`
  }

  if (
    (type === 'string' || type === 'number') &&
    operator === OdataFilterOperatorEnum.dynamicsin
  ) {
    const values = value as string[]
    const strings = values.filter(isNotNullOrEmpty)
    const nulls = values.filter((x) => !x)
    return `${[
      strings.length &&
        `Microsoft.Dynamics.CRM.In(PropertyName='${
          filter.path
        }', PropertyValues=[${strings
          .map(encodeURIComponent)
          .map(escapeFilterString)
          .map((x) => `'${x}'`)
          .join(',')}])`,
      type === 'string' && nulls.length && `${path} eq ''`,
      nulls.length && `${path} eq null`
    ]
      .filter(Boolean)
      .join(` ${OdataFilterLogicalOperatorEnum.or} `)}`
  }

  if (
    (type === 'string' && operator === OdataFilterOperatorEnum.contains) ||
    operator === OdataFilterOperatorEnum.startswith
  ) {
    return `${operator}(${path}, '${flow(
      encodeURIComponent,
      escapeFilterString
    )(value as string)}')`
  }

  if (type === 'string' && operator === OdataFilterOperatorEnum.search) {
    return `search.ismatch('${tokenizeQuery(value as string)
      .map(escapeFilterString)
      .map(escapeAndEncodeQuery)
      .filter(Boolean)
      .map((x) => `${x}*`)
      .join(operators.and)}', '${path}', 'full', 'all')`
  }

  if (type === 'string') {
    return `${path} ${operator} '${escapeFilterString(value as string)}'`
  }

  if (
    operator === OdataFilterOperatorEnum.in ||
    operator === OdataFilterOperatorEnum.search
  ) {
    throw new Error(
      'Invalid request, cannot use operators in or search unless the filter type is string'
    )
  }

  if (type === 'date') {
    const date = new Date(value as Date)
    const start = startOfDay(date)
    const end = endOfDay(date)

    switch (operator) {
      case 'gt':
      case 'le':
        return `${path} ${operator} ${end.toISOString()}`
      case 'ge':
      case 'lt':
        return `${path} ${operator} ${start.toISOString()}`
      case 'eq':
        return processFilterGroup({
          and: [
            {
              operator: OdataFilterOperatorEnum.ge,
              path,
              type,
              value: start
            },
            {
              operator: OdataFilterOperatorEnum.le,
              path,
              type,
              value: end
            }
          ]
        })
      default:
        return `${path} ${operator} ${date.toISOString()}`
    }
  }

  if (type === 'number') {
    return `${path} ${operator} ${value}`
  }

  if (type === 'boolean') {
    return `${path} ${operator} ${value ? 'true' : 'false'}`
  }

  if (type === 'datetime') {
    return `${path} ${operator} ${new Date(value as Date).toISOString()}`
  }
}

const isFilterList = (
  item:
    | OdataPropertyFilterGroup
    | (IOdataPropertyFilter | IOdataCollectionFilter)[]
): item is (IOdataPropertyFilter | IOdataCollectionFilter)[] => {
  return (
    (item as (IOdataPropertyFilter | IOdataCollectionFilter)[])?.length !==
    undefined
  )
}

const processFilterGroup = (
  filter: OdataPropertyFilterGroup,
  collectionPrefix = ''
): string | undefined => {
  const ops = Object.keys(
    filter
  ) as (keyof typeof OdataFilterLogicalOperatorEnum)[]
  if (ops.length !== 1) {
    throw new Error('There must be exactly 1 operator in a filter group')
  }

  const operator = ops[0]
  const item = filter[operator]

  if (!item) {
    return
  }

  if (isFilterList(item) && operator === 'not') {
    throw new Error('Cannot pass a filter list with operator not')
  }

  if (!isFilterList(item) && operator !== 'not') {
    throw new Error('Cannot pass a filter list with operator not')
  }

  if (isFilterList(item)) {
    return `(${item
      .map((x) => processFilter(x, collectionPrefix))
      .join(` ${operator} `)})`
  }

  if (!isFilterList(item)) {
    return `(${operator} ${processFilterGroup(item, collectionPrefix)})`
  }
}

export const constructOdataQuery = (
  request: IOdataRequest,
  defaults?: IOdataRequest,
  { $select }: { $select?: string } = {
    $select: 'select'
  }
) => {
  const {
    skip,
    top,
    filters,
    orderby,
    select,
    expand,
    search,
    searchFields,
    count,
    apply
  } = request

  const allFilters = [...(filters || [])]

  if (search && searchFields?.length) {
    const tokens = tokenizeQuery(search)
      .map(escapeFilterString)
      .map(escapeAndEncodeQuery)
      .map(trim)
      .filter(isNotNullOrEmpty)

    const searchFilters = search
      ? tokens.map(
          (token) =>
            `(${searchFields
              .map((field) => `contains(${field}, '${token}')`)
              .join(' or ')})`
        )
      : []

    allFilters.push(...searchFilters)
  }

  const orderbyWithDefault = uniqBy(
    [...(orderby || []), ...(defaults?.orderby || [])].filter(
      isNotNullOrUndefined
    ),
    (x) => x.dataPath
  )

  const selectWithDefault = uniq(
    [...(select || []), ...(defaults?.select || [])].filter(
      isNotNullOrUndefined
    )
  )

  const expandWithDefault = [
    ...(expand || []),
    ...(defaults?.expand || [])
  ].filter(isNotNullOrUndefined)

  return [
    top != null && `$top=${top}`,
    skip != null && `$skip=${skip}`,
    count && '$count=true',
    !!selectWithDefault?.length && `${$select}=${selectWithDefault.join(',')}`,
    !!expandWithDefault?.length && `$expand=${expandWithDefault.join(',')}`,
    !!orderbyWithDefault?.length &&
      `$orderby=${orderbyWithDefault
        .map(
          ({ dataPath, direction }) =>
            `${dataPath}${direction ? ` ${direction}` : ''}`
        )
        .join(',')}`,
    !!allFilters?.length && `$filter=${allFilters.join(' and ')}`,
    apply != null && `$apply=${apply}`
  ]
    .filter(isNotNullOrFalse)
    .join('&')
}
