import { lazy } from 'react'
import { combineReducers } from 'redux'
import { IModuleDefinition } from '../../../types'
import { feesModulesReducer, feesModulesSagas } from './modules'

const FeesContainer = lazy(() => import('./FeesContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/fees/*',
    private: true,
    component: FeesContainer
  },
  name: 'Fees'
}

export default moduleDefinition

export const feesModuleReducer = combineReducers({
  modules: feesModulesReducer
})

export const feesModuleSagas = [...feesModulesSagas]
