import { useDomainStore } from 'features/Domain/store/domain'
import { PositionColumnIds } from 'features/Lists/positions/ColumnDefinitions'
import { useMemo } from 'react'
import { useUpdateEffect } from 'react-use'
import { createAiPositionListFilter } from '../store'
import { AiPositionListTabNamesEnum } from '../types'
import { useAiDashboardList } from './useList'

const advisorIdFilterName = PositionColumnIds.registeredrep
export const useAiDashboardAdvisorIdFilter = () => {
  const { selectedAccountRepIds, isFullDomainSelected, isFetching } =
    useDomainStore()

  const advisorIdFilter = useMemo(() => {
    if (isFetching || isFullDomainSelected || !selectedAccountRepIds?.length) {
      return undefined
    }

    return createAiPositionListFilter(advisorIdFilterName, {
      type: 'facet',
      values: selectedAccountRepIds,
      hidden: true
    })
  }, [isFetching, isFullDomainSelected, selectedAccountRepIds])

  return { advisorIdFilter }
}

export const useSyncAiDashboardWithDomainContext = (
  listType: AiPositionListTabNamesEnum
) => {
  const { setFilters, removeFilters } = useAiDashboardList(listType)
  const { advisorIdFilter } = useAiDashboardAdvisorIdFilter()

  useUpdateEffect(() => {
    advisorIdFilter
      ? setFilters?.({ [advisorIdFilterName]: advisorIdFilter })
      : removeFilters?.([advisorIdFilterName])
  }, [advisorIdFilter, removeFilters, setFilters])
}
