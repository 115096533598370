import { FC } from 'react'
import TileHeading, { BaseHeaderProps } from '../../../components/TileHeading'
import { BalanceSummaryTable } from './BalanceSummaryTable'

const BalanceSummary: FC<BaseHeaderProps> = ({ onExpand, isCobSupported }) => {
  return (
    <div css={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <TileHeading onExpand={onExpand} isCobSupported={isCobSupported}>
        Balances
      </TileHeading>
      <BalanceSummaryTable />
    </div>
  )
}

export default BalanceSummary
