import { TagDescription } from '@reduxjs/toolkit/dist/query'
import { IBaseGraphValueResponse, IUserGroup } from 'api/graph'
import axios from 'axios'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { graph_v1 } from 'store/api/graph_v1'
import { AxiosBaseArgs } from 'store/api/shared'
import { apiConstants } from './apis'

export interface IDriveChild {
  id?: string
  name?: string
  folder?: IGraphFolder
  parentReference?: IParentReference
  file?: IGraphFile
  createdDateTime?: string
  createdBy?: ICreatedBy
  size?: number
  '@microsoft.graph.downloadUrl'?: string
  lastModifiedBy?: IModifiedBy
  webUrl?: string
}

interface ICreatedBy {
  user?: { displayName?: string }
}

interface IModifiedBy {
  user?: { displayName?: string }
}

export interface IGraphFolder {
  childCount?: number
}

export interface IGraphFile {
  mimeType?: string
  hashes?: { quickXorHash?: string }
}

export interface IParentReference {
  driveId?: string
  driveType?: string
  id?: string
  path?: string
}

export interface INewFolderPayload {
  '@microsoft.graph.conflictBehavior'?: string //rename
  folder?: any
  name?: string
}

export interface IUploadSessionResponse {
  expirationDateTime?: string
  nextExpectedRanges?: string[]
  uploadUrl?: string
}

type GraphApiTagType = 'Collaboration' | 'rdot360'

const graphApiTags: GraphApiTagType[] = ['Collaboration', 'rdot360']

const { cacheTime } = apiConstants

const graphApiWithRdot360Tags = graph_v1.enhanceEndpoints({
  addTagTypes: graphApiTags
})
export const rdot360GraphAPI = graphApiWithRdot360Tags.injectEndpoints({
  endpoints: (builder) => ({
    getCollaborationGroups: builder.query<IUserGroup[] | undefined, string>({
      query: (aaduseroid) => ({
        url: `/users/${aaduseroid}/memberOf/microsoft.graph.group?${[
          '$count=true',
          '$orderby=displayName',
          '$select=id,classification,displayName,description,resourceProvisioningOptions',
          `$filter=classification eq 'Client Collaboration'`
        ].join('&')}`,
        headers: {
          ConsistencyLevel: 'eventual'
        }
      }),
      transformResponse: (x: IBaseGraphValueResponse<IUserGroup[]>) => x?.value,
      providesTags: ['Collaboration', 'rdot360'],
      keepUnusedDataFor: cacheTime
    }),
    getGroupRootDrive: builder.query<IDriveChild[] | undefined, string>({
      query: (groupId) => ({
        url: `/groups/${groupId}/drive/root/children`,
        headers: {
          ConsistencyLevel: 'eventual'
        }
      }),
      transformResponse: (x: IBaseGraphValueResponse<IDriveChild[]>) =>
        x?.value,
      providesTags: ['Collaboration', 'rdot360'],
      keepUnusedDataFor: cacheTime
    }),
    getUserPhoto: builder.query<Blob, string | undefined>({
      query: (id) => ({
        url: `/users('${id}')/photo/$value`,
        responseType: 'blob'
      }),
      providesTags: ['rdot360'],
      keepUnusedDataFor: cacheTime
    }),
    getChildrenFromFolder: builder.query<
      IDriveChild[] | undefined,
      {
        driveId?: string
        folderId?: string
      }
    >({
      query: ({ driveId, folderId }) => ({
        url: `/drives/${driveId}/items/${folderId}/children`,
        headers: {
          ConsistencyLevel: 'eventual'
        }
      }),
      transformResponse: (x: IBaseGraphValueResponse<IDriveChild[]>) =>
        x?.value,
      providesTags: ['Collaboration', 'rdot360'],
      keepUnusedDataFor: cacheTime
    }),
    addFolder: builder.mutation<
      IDriveChild[] | undefined,
      {
        driveId?: string
        folderId?: string
        groupId?: string
        name?: string
      }
    >({
      query: ({ driveId, folderId, groupId, name }) => ({
        url: folderId
          ? `/drives/${driveId}/items/${folderId}/children`
          : `/groups/${groupId}/drive/root/children`,
        method: 'POST',
        headers: {
          ConsistencyLevel: 'eventual'
        },
        data: {
          '@microsoft.graph.conflictBehavior': 'rename',
          folder: {},
          name
        }
      }),
      transformResponse: (x: IBaseGraphValueResponse<IDriveChild[]>) =>
        x?.value,
      invalidatesTags: ['Collaboration']
    }),
    deleteItem: builder.mutation<
      undefined,
      {
        driveId?: string
        itemId?: string
      }
    >({
      query: ({ driveId, itemId }) => ({
        url: `/drives/${driveId}/items/${itemId}`,
        method: 'Delete'
      }),
      invalidatesTags: ['Collaboration']
    }),
    renameItem: builder.mutation<
      undefined,
      {
        driveId?: string
        itemId?: string
        name?: string
      }
    >({
      query: ({ driveId, itemId, name }) => ({
        url: `/drives/${driveId}/items/${itemId}`,
        method: 'Patch',
        headers: {
          ConsistencyLevel: 'eventual'
        },
        data: {
          '@microsoft.graph.conflictBehavior': 'rename',
          name
        }
      }),
      invalidatesTags: ['Collaboration']
    }),
    createUploadSession: builder.mutation<
      IUploadSessionResponse | undefined,
      {
        driveId?: string
        folderId?: string
        groupId?: string
        fileName?: string
      }
    >({
      query: ({ driveId, folderId, groupId, fileName }) => ({
        url: folderId
          ? `/drives/${driveId}/items/${folderId}:/${fileName}:/createUploadSession`
          : `/groups/${groupId}/drive/root:/${fileName}:/createUploadSession`,
        method: 'POST',
        data: {
          item: { '@microsoft.graph.conflictBehavior': 'rename' }
        }
      }),
      invalidatesTags: ['Collaboration']
    }),
    uploadFile: builder.mutation<
      any,
      {
        driveId?: string
        folderId?: string
        groupId?: string
        fileName?: string
        file?: File
      }
    >({
      queryFn: async (
        { driveId, folderId, groupId, file },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const baseApiArgs: Partial<AxiosBaseArgs> = {
          url: folderId
            ? `/drives/${driveId}/items/${folderId}:/${file?.name}:/createUploadSession`
            : `/groups/${groupId}/drive/root:/${file?.name}:/createUploadSession`,
          method: 'POST',
          data: {
            item: { '@microsoft.graph.conflictBehavior': 'rename' }
          }
        }

        const uploadSession = (await baseQuery({
          ...baseApiArgs
        })) as { data?: IUploadSessionResponse }

        const uploadURL = uploadSession?.data?.uploadUrl

        if (!uploadURL) {
          throw new Error('Failed to create upload session')
        }
        const stream = await file?.arrayBuffer()

        const uploadResponse = await axios
          .put(uploadURL, stream, {
            headers: {
              'Content-Range': `bytes 0-${(file?.size || 0) - 1}/${file?.size}`,
              'Content-Type': file?.type || ''
            }
          })
          .catch((error) => {
            throw error
          })

        return uploadResponse
      },
      invalidatesTags: ['Collaboration']
    })
  })
})

export const {
  useGetCollaborationGroupsQuery,
  useGetGroupRootDriveQuery,
  useGetChildrenFromFolderQuery,
  useGetUserPhotoQuery,
  useAddFolderMutation,
  useDeleteItemMutation,
  useRenameItemMutation,
  useCreateUploadSessionMutation,
  useUploadFileMutation
} = rdot360GraphAPI

export const useGraphApiUtil = () => {
  const dispatch = useDispatch()
  const invalidateTags = useCallback(
    (tags: TagDescription<GraphApiTagType>[]) =>
      dispatch(rdot360GraphAPI.util.invalidateTags(tags)),
    [dispatch]
  )

  return {
    invalidateTags
  }
}
