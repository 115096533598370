import { Icon, makeStyles, Stack } from '@fluentui/react'
import {
  Children,
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { isNotNullOrFalse } from '../../../../../../../../shared/guards'

const useClasses = makeStyles((theme) => ({
  accordionOuter: {
    border: `solid 1px ${theme.palette.neutralQuaternary}`,
    borderBottom: 'none',
    '&.expanded': {
      borderBottom: `solid 1px ${theme.palette.neutralDark}`,
      marginBottom: '10px'
    },
    '&:last-of-type': {
      borderBottom: `solid 1px ${theme.palette.neutralDark}`
    }
  },
  accordionHeader: {
    backgroundColor: theme.palette.neutralLighterAlt,
    cursor: 'pointer',
    padding: '10px',
    '&.expanded': {
      borderBottom: `solid 1px ${theme.palette.neutralLight}`
    },
    '&.invalid': {
      backgroundColor: theme.semanticColors.errorBackground
    }
  }
}))

export interface IAccordionContext {
  expanded?: boolean
  invalid?: boolean
  toggle?: () => void
}

export const AccordionContext = createContext<IAccordionContext>({})

export const Accordion: React.FC<
  PropsWithChildren<{
    defaultExpanded?: boolean
    expandAll?: boolean
    invalid?: boolean
  }>
> = ({ children, defaultExpanded = false, expandAll, invalid = false }) => {
  const classes = useClasses()
  const [summary, ...rest] = Children.toArray(children)

  const [expanded, setExpanded] = useState(defaultExpanded)
  const toggle = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded])

  const context = useMemo(
    () => ({
      expanded,
      invalid,
      toggle
    }),
    [expanded, invalid, toggle]
  )

  useEffect(() => {
    if (expandAll === undefined) {
      return
    }
    setExpanded(expandAll)
  }, [expandAll])

  return (
    <div
      className={[classes.accordionOuter, expanded && 'expanded']
        .filter(isNotNullOrFalse)
        .join(' ')}
    >
      <AccordionContext.Provider value={context}>
        {summary}
      </AccordionContext.Provider>
      <div
        style={
          expanded ? { padding: '15px' } : { height: 0, overflow: 'hidden' }
        }
      >
        {rest}
      </div>
    </div>
  )
}

export const AccordionSummary: React.FC<PropsWithChildren> = ({ children }) => {
  const classes = useClasses()
  const { expanded, invalid, toggle } = useContext(AccordionContext)
  return (
    <div
      className={[
        classes.accordionHeader,
        expanded && 'expanded',
        invalid && 'invalid'
      ]
        .filter(isNotNullOrFalse)
        .join(' ')}
      onClick={toggle}
    >
      <Stack
        horizontal={true}
        verticalAlign="center"
        tokens={{ childrenGap: 10 }}
        style={{ padding: '5px' }}
      >
        <Icon iconName={expanded ? 'ChevronUp' : 'ChevronDown'} />
        <Stack.Item grow={1}>{children}</Stack.Item>
      </Stack>
    </div>
  )
}
