import { Theme, css, useTheme } from '@emotion/react'
import {
  DirectionalHint,
  HoverCard,
  HoverCardType,
  IPlainCardProps
} from '@fluentui/react'
import React, { useMemo } from 'react'
import { USD } from 'shared/components/Formatting'
import { Icon } from '../../features/Icons/Icon'

const getClasses = (theme: Theme) => ({
  changeRow: css({
    display: 'flex',
    padding: '8px 8px',
    justifyContent: 'space-between'
  }),
  link: css({
    '& *:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  }),
  rowContainer: css({
    padding: '8px',
    minWidth: 300,
    '& > div:nth-of-type(odd)': {
      backgroundColor: '#F9FBFC'
    },
    '& > div:nth-of-type(even)': {
      backgroundColor: theme.colors.primaryWhite
    }
  })
})

export const AccruedIncome: React.FC<{
  dividends?: number | null
  income?: number
  interest?: number | null
  isTotalRow?: boolean
}> = ({ dividends, income, interest, isTotalRow }) => {
  const theme = useTheme()
  const classes = useMemo(() => getClasses(theme), [theme])
  const plainCardProps = useMemo(
    (): IPlainCardProps => ({
      onRenderPlainCard: () => (
        <div css={[classes.rowContainer]}>
          <div css={[classes.changeRow]}>
            <div>Accrued Interest</div>
            <USD
              value={interest ?? 0}
              fractionDigits={2}
              currencySign={'standard'}
            />
          </div>
          <div css={[classes.changeRow]}>
            <div>Accrued Dividends</div>
            <USD
              value={dividends ?? 0}
              fractionDigits={2}
              currencySign={'standard'}
            />
          </div>
        </div>
      ),
      directionalHint: DirectionalHint.bottomCenter,
      directionalHintFixed: false,
      calloutProps: {
        isBeakVisible: true
      }
    }),
    [dividends, interest, classes.changeRow, classes.rowContainer]
  )

  if (!income) {
    if (!isTotalRow) {
      return '--'
    }
    income = 0
  }

  if (!dividends && !interest) {
    return <USD value={income} fractionDigits={2} currencySign={'standard'} />
  }

  return (
    <HoverCard
      styles={{ host: { display: 'inline-flex' } }}
      plainCardProps={plainCardProps}
      type={HoverCardType.plain}
      cardDismissDelay={400}
      instantOpenOnClick={true}
    >
      <div
        css={[
          classes.link,
          {
            cursor: 'pointer',
            display: 'inline-flex',
            columnGap: '5px',
            alignItems: 'center',
            verticalAlign: 'center'
          }
        ]}
      >
        <Icon
          type="Info"
          width={12}
          height={12}
          color={theme.colors.tertiaryBlue1}
        />
        <USD value={income} fractionDigits={2} currencySign={'standard'} />
      </div>
    </HoverCard>
  )
}
