import axios, { AxiosResponse } from 'axios'
import { IApiOptions } from './../../../../../shared/contracts/IApiOptions'
import { IDistributionInfo } from './../../DiscretionaryDistribution/store/types'
import { IFetchDocumentRequest } from './types'

const fetchPendingDistributionService = (
  email: string,
  showAllTxn: string,
  options: IApiOptions
): Promise<AxiosResponse<IDistributionInfo[] | undefined>> => {
  const url = `${options.apiRoot}/trustapi/api/DiscritionaryDistribution/List?email=${email}&alltxn=${showAllTxn}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchCompletedDistributionService = (
  email: string,
  showAllTxn: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/DiscritionaryDistribution/CompletedList?email=${email}&alltxn=${showAllTxn}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IDistributionInfo[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchDocumentService = (
  docReq: IFetchDocumentRequest,
  options: IApiOptions
): Promise<AxiosResponse<string | undefined>> => {
  const url = `${
    options.apiRoot
  }/trustapi/api/DiscritionaryDistribution/Document?id=${docReq.id}&doctype=${
    docReq.docType
  }&docId=${docReq.docId ? docReq.docId : ''}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const deleteDistributionService = (
  id: string,
  email: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/trustapi/api/DiscritionaryDistribution/Delete`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  const data = {
    DistributionId: id,
    RequestorEmail: email
  }
  return axios
    .post(url, data, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export {
  fetchPendingDistributionService,
  fetchCompletedDistributionService,
  fetchDocumentService,
  deleteDistributionService
}
