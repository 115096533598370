import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import usePreferences from './usePreferences'

function useRdot360Preferences<T>(
  preferenceName: string,
  defaultPreferenceValue?: any
) {
  const { getPreference, setPreference } = usePreferences<T>('rdot360')

  useEffect(() => {
    if (!isEmpty(defaultPreferenceValue)) {
      const preferenceData = getPreference(preferenceName)

      if (isEmpty(preferenceData)) {
        setPreference({ [preferenceName]: defaultPreferenceValue } as T)
      }
    }
  }, [defaultPreferenceValue, getPreference, preferenceName, setPreference])

  return { getPreference, setPreference }
}

export default useRdot360Preferences
