import {
  Icon,
  ITheme,
  makeStyles,
  SearchBox,
  Stack,
  Text
} from '@fluentui/react'
import { debounce } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { USD } from '../../../../shared/components/Formatting'
import { LoadingComponent } from '../../../../shared/components/Loading'
import { ConnectedMaskedText } from '../../../../shared/components/MaskedText'
import { chartColors } from '../../../../shared/services/theme'
import {
  getHouseholdFeeFetchResult,
  getIsHouseholdFeeFetchLoading
} from '../../store/householdFeeFetch'
import { householdFeePanelActions } from '../HouseholdFee/store/householdFeePanel'
import { getCurrentHousehold, householdDetailUiActions } from './store'

const useClasses = makeStyles((theme: ITheme) => {
  const headerItem = {
    backgroundColor: theme.palette.neutralLighterAlt,
    border: `solid 1px ${theme.palette.neutralLighter}`,
    borderBottom: `solid 3px ${chartColors[1]}`,
    padding: '3px 5px'
  }

  const actionableHeaderItem = {
    ...headerItem,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.neutralLighter
    }
  }

  const linkButton = {
    color: theme.semanticColors.link,
    '&:hover': { color: theme.semanticColors.linkHovered }
  }

  return {
    headerItem,
    actionableHeaderItem,
    linkButton
  }
})

export const HouseholdHeader = () => {
  const household = useSelector(getCurrentHousehold)
  const feeDetails = useSelector(getHouseholdFeeFetchResult)
  const isFeeDetailLoading = useSelector(getIsHouseholdFeeFetchLoading)
  const classes = useClasses()
  const dispatch = useDispatch()
  const debouncedOnChange = useMemo(
    () =>
      debounce((_?: any, newValue?: string) => {
        dispatch(householdDetailUiActions.updateFilter(newValue || ''))
      }, 200),
    [dispatch]
  )
  const onFilterChanged = useCallback(debouncedOnChange, [debouncedOnChange])

  const openHouseholdFeeEditPanel = useCallback(() => {
    if (!household?.id) {
      return
    }
    dispatch(householdFeePanelActions.openPanel(household.id))
  }, [dispatch, household?.id])

  if (!household) {
    return null
  }

  return (
    <Stack tokens={{ childrenGap: 15 }}>
      <Stack horizontal={true} tokens={{ childrenGap: 10 }} wrap={true}>
        <Stack className={classes.headerItem}>
          <Text variant="small">Household Name</Text>
          <Text
            variant="mediumPlus"
            styles={{ root: { fontWeight: 'bold' } }}
            nowrap={true}
          >
            {household?.householdName}
          </Text>
        </Stack>

        <Stack className={classes.headerItem}>
          <Text variant="small">AUS</Text>
          <Text variant="mediumPlus" styles={{ root: { fontWeight: 'bold' } }}>
            <USD
              value={household?.householdKPI?.AumTotal || 0}
              fractionDigits={0}
            />
          </Text>
        </Stack>

        <Stack className={classes.headerItem}>
          <Text variant="small">T12 Revenue</Text>
          <Text variant="mediumPlus" styles={{ root: { fontWeight: 'bold' } }}>
            <USD
              value={household?.revenue?.ttmrevenue || 0}
              fractionDigits={0}
            />
          </Text>
        </Stack>

        <Stack className={classes.headerItem}>
          <Text variant="small">Fee Eligible</Text>
          <Text styles={{ root: { fontWeight: 'bold' } }} variant="mediumPlus">
            {household?.householdFeeIndicator}
          </Text>
        </Stack>

        <Stack
          className={classes.actionableHeaderItem}
          onClick={openHouseholdFeeEditPanel}
          verticalAlign="space-between"
        >
          <Text variant="small">Billing Methodology</Text>
          {isFeeDetailLoading && <LoadingComponent />}
          {!isFeeDetailLoading && (
            <Stack
              horizontal={true}
              horizontalAlign="space-between"
              tokens={{ childrenGap: 10 }}
              verticalAlign="center"
            >
              <Stack
                horizontal={true}
                tokens={{ childrenGap: 5 }}
                verticalAlign="end"
              >
                <Text
                  variant="mediumPlus"
                  styles={{ root: { fontWeight: 'bold' } }}
                >
                  {feeDetails?.chargeType || 'Production'}
                </Text>
                {feeDetails?.chargeType === 'Client' &&
                  feeDetails?.chargeAccount && (
                    <ConnectedMaskedText
                      variant="small"
                      text={feeDetails?.chargeAccount}
                    />
                  )}
                {feeDetails?.chargeType === 'BDA' &&
                  feeDetails?.primaryChargeToDept && (
                    <Text variant="small">
                      {feeDetails?.primaryChargeToDept}
                    </Text>
                  )}
                {feeDetails?.chargeType === 'Production' &&
                  feeDetails?.primaryChargeToRep && (
                    <Text variant="small">
                      {feeDetails?.primaryChargeToRep}
                    </Text>
                  )}
              </Stack>
              <Icon iconName="Edit" className={classes.linkButton} />
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack.Item>
        <SearchBox
          placeholder="Filter Clients and Accounts"
          onChange={onFilterChanged}
        />
      </Stack.Item>
    </Stack>
  )
}
