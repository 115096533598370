import React from 'react'
import { FormattedNumber } from 'react-intl'
import { useSelector } from 'react-redux'
import { SearchResponseType } from '../../../../api/common.types'
import { IListsSelectors } from '../store/selectors'

export const createConnectedListsResultsCount = <T extends SearchResponseType>(
  listSelectors: IListsSelectors<T>
) => {
  const ListsResultsCount: React.FC = () => {
    const totalCount = useSelector(listSelectors.dataSelectors.getTotalCount)
    return <FormattedNumber value={totalCount || 0} />
  }
  return ListsResultsCount
}
