import { Icon, Text } from '@fluentui/react'
import { IHousehold } from 'api/household.types'
import React, { memo } from 'react'
import { FormattedNumber } from 'react-intl'
import { useClasses } from 'shared/hooks/useClasses'
import { USD } from '../../../shared/components/Formatting'
import { getThemedClasses } from '../shared/styles'
import { ConnectedMaskedHighlight } from './Highlight'

export interface IHouseholdSearchResultsProps {
  className?: string
  households: IHousehold[]
  onHouseholdClick: (household: IHousehold) => void
  query?: string
}

export const HouseholdSearchResults: React.FC<IHouseholdSearchResultsProps> =
  memo(({ className, households, onHouseholdClick, query }) => {
    const classes = useClasses(getThemedClasses)
    return (
      <div
        className={className}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        {households.map((household) => {
          const {
            id,
            householdName,
            RockConnectAccBalance,
            Advisors,
            Account,
            householdKPI
          } = household
          const onClick = () => onHouseholdClick(household)
          const advisor = Advisors?.[0]
          return (
            <div key={id} className={classes.rowItem} onClick={onClick}>
              <div
                style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}
              >
                <div style={{ alignSelf: 'center' }}>
                  <Icon iconName="Home" />
                </div>
                <div
                  style={{
                    flexGrow: 1,
                    minWidth: 0,
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Text
                    nowrap={true}
                    block={true}
                    variant="medium"
                    title={householdName}
                  >
                    <ConnectedMaskedHighlight
                      text={householdName || 'No Name'}
                      highlight={query}
                    />
                  </Text>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text
                      styles={{
                        root: { fontSize: '11px', fontWeight: '300' }
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '3px'
                        }}
                      >
                        <div
                          style={{
                            maxWidth: '200px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}
                          title={advisor?.ClientAdvisor}
                        >
                          {advisor?.ClientAdvisorID || ''}
                        </div>
                        <>|</>
                        <div title="Number of accounts in the household">
                          <FormattedNumber value={Account?.length || 0} />{' '}
                          Account(s)
                        </div>
                        <>|</>
                        <div title="Account Balances">
                          <USD
                            value={
                              RockConnectAccBalance ||
                              householdKPI?.AumTotal ||
                              0
                            }
                            fractionDigits={0}
                          />
                        </div>
                      </div>
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  })
