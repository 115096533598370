import { ChangeEvent, FC, useCallback } from 'react'

export interface IRadioButtonGroupOption {
  key: string | number
  text: string
}

export interface IRadioButtonGroup {
  name: string
  value?: string | number
  options: IRadioButtonGroupOption[]
  align?: 'horizontal' | 'vertical'
  onChange?: (newValue?: string) => void
}

const RadioButtonGroup: FC<IRadioButtonGroup> = ({
  name,
  options,
  value,
  align = 'vertical',
  onChange
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.value)
    },
    [onChange]
  )

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: align === 'horizontal' ? 'row' : 'column'
      }}
    >
      {options.map((option: IRadioButtonGroupOption) => (
        <div
          key={option.key}
          css={{
            marginRight: '10px'
          }}
        >
          <label
            css={{
              cursor: 'pointer'
            }}
          >
            <input
              type="radio"
              name={name}
              value={option.key}
              checked={option.key === value}
              onChange={handleChange}
              css={{
                cursor: 'pointer'
              }}
            />
            {option.text}
          </label>
        </div>
      ))}
    </div>
  )
}

export default RadioButtonGroup
