import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IconButton,
  IContextualMenuItem,
  IContextualMenuProps,
  PrimaryButton
} from '@fluentui/react'
import { useBoolean, useId } from '@fluentui/react-hooks'
import { cloneDeep } from 'lodash'
import { memo } from 'react'
import { IHurdle } from '../../../../../../../../api/datahub'

interface IHurdleMenuProps {
  hurdle: IHurdle
  onEditHurdle: (hurdle: IHurdle) => void
  onCopyHurdle: (hurdle: IHurdle) => void
  onDeleteHurdle: (hurdle: IHurdle) => void
}

export const HurdleMenu: React.FC<IHurdleMenuProps> = memo(
  ({ hurdle, onEditHurdle, onDeleteHurdle }) => {
    const deleteButtonId = useId('deleteButton')
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)
    const menuItems: IContextualMenuItem[] = [
      {
        key: 'edit-hurdle',
        onClick: () => onEditHurdle(cloneDeep(hurdle)),
        text: 'Edit Hurdle'
      },
      {
        key: 'copy-hurdle',
        onClick: () =>
          onEditHurdle(cloneDeep({ ...hurdle, hurdleId: undefined, name: '' })),
        text: 'Copy Hurdle'
      },
      {
        key: 'delete',
        onClick: toggleHideDialog,
        text: 'Delete Hurdle'
      }
    ].filter((x) => x) as IContextualMenuItem[]

    const menuProps: IContextualMenuProps = {
      shouldFocusOnMount: true,
      items: menuItems
    }
    const dialogContentProps = {
      type: DialogType.largeHeader,
      title: 'Delete',
      subText: `Are you sure you want to delete ${hurdle.name}?`,
      closeButtonAriaLabel: 'Close'
    }

    return (
      <div>
        <IconButton
          title="Hurdle Menu"
          ariaLabel="Menu"
          disabled={false}
          checked={false}
          styles={{
            root: { visibility: menuItems.length > 1 ? 'visible' : 'hidden' }
          }}
          menuIconProps={{ iconName: 'MoreVertical' }}
          menuProps={menuProps}
          id={deleteButtonId}
        />
        <Dialog
          hidden={hideDialog}
          onDismiss={toggleHideDialog}
          dialogContentProps={dialogContentProps}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                onDeleteHurdle(hurdle)
                toggleHideDialog()
              }}
              text="Delete"
            />
            <DefaultButton onClick={toggleHideDialog} text="Cancel" />
          </DialogFooter>
        </Dialog>
      </div>
    )
  }
)
