import { css } from '@emotion/react'
import { useMemo } from 'react'
import { IHouseholdInsight } from 'store/api/insights'
import { InsightButton } from './InsightButton'

const classes = {
  buttonContainer: css({
    textAlign: 'right'
  })
}

export interface IInsightDetailProps {
  insight: IHouseholdInsight
}

export const InsightDetail: React.FC<IInsightDetailProps> = ({ insight }) => {
  const sortedStatements = useMemo(() => {
    const statements = insight.statements
    if (!statements) {
      return
    }
    return [...statements].sort((a, b) => a.order - b.order)
  }, [insight])

  return (
    <>
      <p>
        <b>{insight.ins_name}</b>
      </p>
      {sortedStatements?.map((s, index) => (
        <p
          key={index}
          dangerouslySetInnerHTML={{
            __html: s.statement
          }}
        />
      ))}
      {insight.ins_details_url && (
        <div css={classes.buttonContainer}>
          <InsightButton urlPart={insight.ins_details_url}>
            View Details
          </InsightButton>
        </div>
      )}
    </>
  )
}
