import { css } from '@emotion/react'
import { useIncomeFeatureStore } from 'modules/Advisory/modules/Rdot360/features/Income'
import { useGetProjectedIncomeChartQueryForSelectedAccounts } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useMemo } from 'react'
import { Divider } from './Divider'
import { TotalLabelValue } from './TotalLabelValue'

const getClasses = () => ({
  totalRow: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    margin: '10px 0'
  })
})

export const ProjectedIncomeTotals: React.FC = () => {
  const classes = useMemo(() => getClasses(), [])
  const { financialYear } = useIncomeFeatureStore()

  const { data: projectedChartData } =
    useGetProjectedIncomeChartQueryForSelectedAccounts(financialYear)

  return (
    <div css={classes.totalRow}>
      <TotalLabelValue
        label="Total Interest"
        value={projectedChartData?.interest}
      />
      <Divider />
      <TotalLabelValue
        label="Total Dividends"
        value={projectedChartData?.dividend}
      />
      <Divider />
      <TotalLabelValue label="Total Income" value={projectedChartData?.total} />
      <Divider />
      <TotalLabelValue
        label="Total Capital Gains Distribution"
        value={projectedChartData?.capGain}
      />
    </div>
  )
}
