import { call, put, takeLatest } from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../../../../store/shared/sagas'
import { fetchTrustsAumSummary } from '../api/aumSummaryService'
import { trustAumSummaryDataActions } from './actions'

function* handleGetTrustsAumSummaryList(
  action: ReturnType<typeof trustAumSummaryDataActions.request>
) {
  try {
    const apiOptions = yield* call(getRockefellerApiOptions)
    const data = yield* call(() =>
      fetchTrustsAumSummary(action.payload, apiOptions)
    )

    yield put(trustAumSummaryDataActions.success(data || []))
  } catch (e) {
    yield put(trustAumSummaryDataActions.failure(e))
  }
}

export const sagas = [
  () =>
    takeLatest(
      trustAumSummaryDataActions.request,
      handleGetTrustsAumSummaryList
    )
]
