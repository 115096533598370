import { ITextProps, Text } from '@fluentui/react'
import React from 'react'
import { useSelector } from 'react-redux'
import { getEnableDataMaskingPreference } from '../../store/user/selectors'

export interface IMaskedTextProps {
  text?: string
  unmaskedCount?: number
  maskCharacter?: string
  nowrap?: boolean
  shouldMask?: boolean
  variant?: ITextProps['variant']
}

export const mask: (args: IMaskedTextProps) => string = ({
  text = '',
  unmaskedCount = 2,
  maskCharacter = '*',
  shouldMask = true
}) => {
  if (!shouldMask) {
    return text
  }

  const safeText = text || ''

  if (unmaskedCount >= safeText.length) {
    return safeText
  }

  const unmasked = safeText.slice(-1 * unmaskedCount)
  const maskedCount = safeText.length - unmaskedCount
  const masked = new Array(maskedCount).fill(maskCharacter).join('')

  return masked + unmasked
}

export const Masked: React.FC<IMaskedTextProps> = (props) => <>{mask(props)}</>
export const MaskedText: React.FC<IMaskedTextProps> = (props) => (
  <Text nowrap={props.nowrap} variant={props.variant}>
    {mask(props)}
  </Text>
)

export const ConnectedMasked: React.FC<IMaskedTextProps> = (props) => {
  const shouldMask = useSelector(getEnableDataMaskingPreference)
  return shouldMask ? <Masked {...props} /> : <>{props?.text || ''}</>
}

export const ConnectedMaskedText: React.FC<IMaskedTextProps> = ({
  text,
  ...props
}) => {
  const shouldMask = useSelector(getEnableDataMaskingPreference)
  return shouldMask ? (
    <MaskedText text={text} {...props} />
  ) : (
    <Text nowrap={props.nowrap} variant={props.variant}>
      {text || ''}
    </Text>
  )
}
