import { format } from 'date-fns'
import { PaymentMM } from '../../Dashboard/modules/Dashboard/store/types'
import { AddrFieldsByFormat, CarrierName } from '../Check/Constants'
import { RecipientType } from '../EFT/Constants'
import {
  ICheckInitiationForm,
  ICheckPayeeDetails,
  ICheckRequest,
  IStubRow
} from '../store/Check/types'
import { IMMConfig } from '../store/Journal/types'
import {
  IMMSummarySIById,
  IMMValidateRequest,
  IPastPayment,
  ISenderFormDetails,
  ITransInfo,
  IVerificationDetails
} from '../store/types'
import {
  getAMAacknowledgementValue,
  getCommonValidateRequest,
  getExpirationDate,
  getRetirementDistribution,
  processDate
} from './Utilities'
import { getrecipient } from './UtilitiesJNL'

export const generateMMValidateRequestForCheck = (
  checkData: ICheckInitiationForm
): IMMValidateRequest => {
  const request: IMMValidateRequest = {
    ...getCommonValidateRequest(
      checkData?.senderDetails?.account,
      checkData?.executingCustodian
    ),
    ismodeone: false,
    ischk: true,
    OverrideAddr: checkData?.payeeDetails?.overrideAddr,
    AddrNameLine1: checkData?.payeeDetails?.addrLine1,
    AddrNameLine2: checkData?.payeeDetails?.addrLine2,
    AddrAttName: checkData?.payeeDetails?.addrLine3,
    AddrLine1: checkData?.payeeDetails?.addrLine4,
    AddrLine2: checkData?.payeeDetails?.addrLine5,
    City: checkData?.payeeDetails?.addrLine6_city,
    State: checkData?.payeeDetails?.addrLine7_stateProvince,
    Zip1: checkData?.payeeDetails?.addrLine8_zip1,
    Zip2: checkData?.payeeDetails?.addrLine8_zip2,
    destinationcountry: checkData?.payeeDetails?.addrLine9_country,
    addrFormat: checkData?.payeeDetails?.addressType,
    fromaccount: {
      accountnumber: checkData?.senderDetails?.account,
      isira: checkData?.senderDetails?.isRetirementAccount || false
    },
    toaccount: {
      accountnumber: undefined,
      accountname: checkData?.payeeDetails?.addrLine1,
      bankidtype: 'F',
      abanumber: undefined,
      swiftcode: undefined,
      bankname: undefined,
      isira: false,
      isthirdparty:
        checkData?.wireAmount?.recipientType === RecipientType.ThirdParty,
      isthirdpartycommon:
        checkData?.wireAmount?.recipientType === RecipientType.ThirdPartyCommon,
      // isexternal: true,
      siid: checkData?.wireAmount?.standingInstructionId
    },
    // amount: Number(checkData?.wireAmount?.amount) || 0,
    amount: checkData?.wireAmount?.accountCloseout
      ? checkData?.senderDetails?.availablecashtowithdraw
        ? checkData?.senderDetails?.availablecashtowithdraw
        : 1
      : Number(checkData?.wireAmount?.amount),
    closeout: checkData?.wireAmount?.accountCloseout ? 'Y' : undefined,

    transactiondate: processDate(checkData),
    isira: checkData?.senderDetails?.isRetirementAccount || false,
    overrideReason: checkData?.wireAmount?.hasInsufFund
      ? checkData?.wireAmount?.insufFundSolutionId
      : 'na',

    OvernightCheck:
      checkData?.overnightCheckDetails?.isOvernightCheck ?? undefined,
    Carrier: checkData?.overnightCheckDetails?.isOvernightCheck
      ? checkData?.overnightCheckDetails?.carrierName
      : undefined,
    CarrierBillingAccount: checkData?.overnightCheckDetails?.isOvernightCheck
      ? checkData?.overnightCheckDetails?.billingAccountNumber
      : undefined,
    CarrierAddress1:
      checkData?.overnightCheckDetails?.isOvernightCheck &&
      checkData?.overnightCheckDetails?.carrierName === CarrierName.UPS
        ? checkData?.overnightCheckDetails?.CarrierAddress1
        : undefined,
    CarrierAddress2:
      checkData?.overnightCheckDetails?.isOvernightCheck &&
      checkData?.overnightCheckDetails?.carrierName === CarrierName.UPS
        ? checkData?.overnightCheckDetails?.CarrierAddress2
        : undefined,
    CarrierAddress3:
      checkData?.overnightCheckDetails?.isOvernightCheck &&
      checkData?.overnightCheckDetails?.carrierName === CarrierName.UPS
        ? checkData?.overnightCheckDetails?.CarrierAddress3
        : undefined,
    StubAddrNameLine1: checkData?.bundleChecks?.StubAddrLine1,
    StubAddrNameLine2: checkData?.bundleChecks?.StubAddrNameLine2,
    StubAddrLine1: checkData?.bundleChecks?.StubAddrLine1,
    StubAddrLine2: checkData?.bundleChecks?.StubAddrLine2,
    StubCity: checkData?.bundleChecks?.StubCity,
    StubState: checkData?.bundleChecks?.StubState,
    StubZip1: checkData?.bundleChecks?.StubZip1,
    BundleOffice: checkData?.bundleChecks?.bundleFlag
      ? `${checkData?.bundleChecks?.bundleNo} of ${checkData?.bundleChecks?.totalBundles}`
      : undefined,
    stubAdditionLines: checkData?.stubAdditionLines?.map(
      (x) => x.description || ''
    ),
    checkMemo: checkData?.checkDetails?.checkMemo,
    memo: checkData?.checkDetails?.memo
  }

  if (checkData?.senderDetails?.isRetirementAccount) {
    request.retdistreason = getRetirementDistribution(checkData)
    request.AMAacknowledgement = getAMAacknowledgementValue(checkData)

    request.fedwithholdpct = Number(checkData?.taxWithholdings?.fedTaxRate)

    request.statewithholdpct = Number(checkData?.taxWithholdings?.stateTaxRate)
    request.istrnamtincludetaxwithhold = !(
      checkData?.taxWithholdings?.includeTax || false
    )

    request.fedwithholdmaxpct =
      checkData?.taxWithholdings?.fedwithholdmaxpct ?? 0

    request.fedwithholdminpct =
      checkData?.taxWithholdings?.fedwithholdminpct ?? 0

    request.statewithholdmaxpct =
      checkData?.taxWithholdings?.statewithholdmaxpct ?? 0

    request.statewithholdminpct =
      checkData?.taxWithholdings?.statewithholdminpct ?? 0
  }

  return request
}

type createCheckRequestProps = {
  checkInfo: ICheckInitiationForm
  approvalDetail?: IVerificationDetails
  status?: string
  ismarginpaperworkrequired?: boolean
}
export const createCheckRequest = ({
  checkInfo,
  approvalDetail = {},
  status = 'DRFT',
  ismarginpaperworkrequired = false
}: createCheckRequestProps) => {
  const request: ICheckRequest = {
    mmInstID: checkInfo?.wirePaymentId,
    ExecutingCustodian: checkInfo?.executingCustodian,
    tranInfo: {
      SrvTrnID: 0,
      ExecutingCustodian: checkInfo?.executingCustodian,
      isOFACOnly: checkInfo?.IsOfacOnly,
      isRecurringPaymentPlan:
        checkInfo?.periodicPayment?.isRecurringPaymentPlan,
      availablecashtowithdraw:
        checkInfo?.senderDetails?.availablecashtowithdraw,
      UseExistingSI: checkInfo?.wireAmount?.useExistingSI || false,
      StandingInstructionId: checkInfo.wireAmount?.standingInstructionId,
      IsThirdParty:
        checkInfo?.wireAmount?.recipientType === RecipientType.ThirdParty,
      isThirdPartyCommon:
        checkInfo?.wireAmount?.recipientType === RecipientType.ThirdPartyCommon,
      BankAcctFromAcctId: checkInfo?.senderDetails?.account || '',
      BankAcctFromName: checkInfo?.senderDetails?.name || '',

      BeneficiaryAcctId: '',
      BeneficiaryName: checkInfo?.payeeDetails?.addrLine1 || '',
      CreateNewSI: checkInfo?.createNewSI || false,
      Ismarginpaperworkrequired: ismarginpaperworkrequired,

      BankAcctFromAcctType: checkInfo?.senderDetails?.registrationtype,
      isfuturedated: false,
      isIRA: checkInfo?.senderDetails?.isRetirementAccount,

      // TrnAmt: parseFloat(checkInfo?.wireAmount?.amount || '0'),
      TrnAmt: !checkInfo?.wireAmount?.accountCloseout
        ? parseFloat(checkInfo?.wireAmount?.amount ?? '0')
        : undefined,
      AMAacknowledgement: getAMAacknowledgementValue(checkInfo),

      TrnDateTime: checkInfo?.wireAmount?.tranDate
        ? format(checkInfo?.wireAmount?.tranDate, 'MM/dd/yyyy')
        : '',
      UseType9Income: checkInfo?.wireAmount?.useType9Income,
      AccountCloseout: checkInfo?.wireAmount?.accountCloseout,

      overrideReason: checkInfo?.wireAmount?.hasInsufFund
        ? checkInfo?.wireAmount?.insufFundSolutionId
        : 'na',
      userEditedTransaction: checkInfo?.userEditedTransaction,
      OverrideAddr: checkInfo?.payeeDetails?.overrideAddr,
      AddrNameLine1: checkInfo?.payeeDetails?.addrLine1,
      AddrNameLine2: checkInfo?.payeeDetails?.addrLine2,
      AddrAttName: checkInfo?.payeeDetails?.addrLine3,
      AddrLine1: checkInfo?.payeeDetails?.addrLine4,
      AddrLine2: checkInfo?.payeeDetails?.addrLine5,
      City: checkInfo?.payeeDetails?.addrLine6_city,
      State: checkInfo?.payeeDetails?.addrLine7_stateProvince,
      Zip1: checkInfo?.payeeDetails?.addrLine8_zip1,
      Zip2: checkInfo?.payeeDetails?.addrLine8_zip2,
      DestinationCountry: checkInfo?.payeeDetails?.addrLine9_country,
      CheckAddressFormat: checkInfo?.payeeDetails?.addressType,
      //check overnightdetails
      OvernightCheck: checkInfo?.overnightCheckDetails?.isOvernightCheck,
      Carrier: checkInfo?.overnightCheckDetails?.isOvernightCheck
        ? checkInfo?.overnightCheckDetails?.carrierName
        : undefined,
      CarrierBillingAccount: checkInfo?.overnightCheckDetails?.isOvernightCheck
        ? checkInfo?.overnightCheckDetails?.billingAccountNumber
        : undefined,
      isHomeOffice: checkInfo?.overnightCheckDetails?.isOvernightCheck
        ? checkInfo?.overnightCheckDetails?.isHomeOfficeFlag === 'Y'
          ? true
          : false
        : undefined,
      CarrierAddress1:
        checkInfo?.overnightCheckDetails?.isOvernightCheck &&
        checkInfo?.overnightCheckDetails?.carrierName === CarrierName.UPS
          ? checkInfo?.overnightCheckDetails?.CarrierAddress1
          : undefined,
      CarrierAddress2:
        checkInfo?.overnightCheckDetails?.isOvernightCheck &&
        checkInfo?.overnightCheckDetails?.carrierName === CarrierName.UPS
          ? checkInfo?.overnightCheckDetails?.CarrierAddress2
          : undefined,
      CarrierAddress3:
        checkInfo?.overnightCheckDetails?.isOvernightCheck &&
        checkInfo?.overnightCheckDetails?.carrierName === CarrierName.UPS
          ? checkInfo?.overnightCheckDetails?.CarrierAddress3
          : undefined,
      BundleFlag: checkInfo?.bundleChecks?.bundleFlag
        ? checkInfo?.bundleChecks?.bundleFlag
          ? 'Y'
          : 'N'
        : undefined,
      BundleOffice: checkInfo?.bundleChecks?.bundleFlag
        ? `${checkInfo?.bundleChecks?.bundleNo} of ${checkInfo?.bundleChecks?.totalBundles}`
        : undefined,
      bundleNo: checkInfo?.bundleChecks?.bundleFlag
        ? checkInfo?.bundleChecks?.bundleNo
        : undefined,
      totalBundles: checkInfo?.bundleChecks?.bundleFlag
        ? checkInfo?.bundleChecks?.totalBundles
        : undefined,
      bundleofficeLoc: checkInfo?.bundleChecks?.bundleFlag
        ? checkInfo?.bundleChecks?.OfficeLocationName
        : undefined,
      selectedOfficeLoc: checkInfo?.bundleChecks?.bundleFlag
        ? checkInfo?.bundleChecks?.OfficeLocationName
        : undefined,
      StubAddrNameLine1: checkInfo?.bundleChecks?.bundleFlag
        ? checkInfo?.bundleChecks?.StubAddrNameLine1
        : undefined,
      StubAddrNameLine2: checkInfo?.bundleChecks?.bundleFlag
        ? checkInfo?.bundleChecks?.StubAddrNameLine2
        : undefined,
      StubAddrLine1: checkInfo?.bundleChecks?.bundleFlag
        ? checkInfo?.bundleChecks?.StubAddrLine1
        : undefined,
      StubAddrLine2: checkInfo?.bundleChecks?.bundleFlag
        ? checkInfo?.bundleChecks?.StubAddrLine2
        : undefined,
      StubCity: checkInfo?.bundleChecks?.bundleFlag
        ? checkInfo?.bundleChecks?.StubCity
        : undefined,
      StubState: checkInfo?.bundleChecks?.bundleFlag
        ? checkInfo?.bundleChecks?.StubState
        : undefined,
      StubZip1: checkInfo?.bundleChecks?.bundleFlag
        ? checkInfo?.bundleChecks?.StubZip1
        : undefined,

      stubAdditionLines:
        checkInfo?.stubAdditionLines?.map((x) => x?.description ?? '') ??
        undefined,
      SIstubAdditionLines:
        checkInfo?.SIstubAdditionLines?.map((x) => x?.description ?? '') ??
        undefined,
      checkmemo: checkInfo?.checkDetails?.checkMemo,
      Memo: checkInfo?.checkDetails?.memo
    },
    expirationDate: getExpirationDate(checkInfo),
    apprDetail: approvalDetail,
    intlMMinfo: {
      toCurrency: 'USD',
      USDAmount: parseFloat(checkInfo?.wireAmount?.amount || '0')
    },
    mmStatus: status,
    xferType: 'CD'
  }
  if (checkInfo?.senderDetails?.isRetirementAccount) {
    const taxWithholdings: ITransInfo = {
      canfedwithhold: checkInfo?.taxWithholdings?.fedTaxOption,
      canstatewithhold: checkInfo?.taxWithholdings?.stateTaxOption,
      fedwithholdpct: Number(checkInfo?.taxWithholdings?.fedTaxRate || 0),
      statewithholdpct: Number(checkInfo?.taxWithholdings?.stateTaxRate || 0),
      fedwithholdmaxpct: Number(
        checkInfo?.taxWithholdings?.fedwithholdmaxpct || 0
      ),
      fedwithholdminpct: Number(
        checkInfo?.taxWithholdings?.fedwithholdminpct || 0
      ),
      statewithholdmaxpct: Number(
        checkInfo?.taxWithholdings?.statewithholdmaxpct || 0
      ),
      statewithholdminpct: Number(
        checkInfo?.taxWithholdings?.statewithholdminpct || 0
      ),
      istrnamtincludetaxwithhold: !(
        checkInfo?.taxWithholdings?.includeTax || false
      ),
      totalamtincludetaxwithhold:
        checkInfo?.taxWithholdings?.totalamtincludetaxwithhold,
      statewithholdamt: checkInfo?.taxWithholdings?.statewithholdamt,
      fedwithholdamt: checkInfo?.taxWithholdings?.fedwithholdamt,
      reasoncode: checkInfo?.taxWithholdings?.retDistCode,
      reasondesc: checkInfo?.taxWithholdings?.retDistReason,
      reasonsubcode: checkInfo?.taxWithholdings?.retDistSubCode
    }
    request.tranInfo = { ...request.tranInfo, ...taxWithholdings }
  }

  return request
}

const getStubLines = (lines?: string[]): IStubRow[] => {
  return (
    lines?.map((x) => {
      return { description: x }
    }) ?? []
  )
}

export const prepareCheckDataForEdit = (
  paymentMM: PaymentMM | undefined
): ICheckInitiationForm => {
  const formData: ICheckInitiationForm = {
    wirePaymentId: paymentMM?.mmInstID,
    pmmId: paymentMM?.pmmID,
    paymentType: paymentMM?.tranType,
    IsOfacOnly: paymentMM?.tranInfo?.isOFACOnly,
    createNewSI: paymentMM?.tranInfo?.createNewSI,
    executingCustodian:
      paymentMM?.executingCustodian || paymentMM?.tranInfo?.executingCustodian,
    docInfo: paymentMM?.docInfo,
    mmStatusDescription: paymentMM?.mmStatusDescription,
    mmStatus: paymentMM?.mmStatus,
    hasRecurringPaymentToday: paymentMM?.hasRecurringPaymentToday,
    senderDetails: {
      account: paymentMM?.tranInfo?.bankAcctFromAcctId || '',
      name: paymentMM?.tranInfo?.bankAcctFromName,
      isRetirementAccount: paymentMM?.tranInfo?.isIRA,
      availablecashtowithdraw: paymentMM?.tranInfo?.availablecashtowithdraw,
      registrationtype: paymentMM?.tranInfo?.bankAcctFromAcctType || ''
    },
    wireAmount: {
      useExistingSI: paymentMM?.tranInfo?.useExistingSI,
      standingInstructionId: paymentMM?.tranInfo?.standingInstructionId,

      amount: paymentMM?.tranInfo?.trnAmt?.toString() || '',
      recipientType: getrecipient(
        paymentMM?.tranInfo?.isThirdPartyCommon,
        paymentMM?.tranInfo?.isThirdParty
      ),
      tranDate: paymentMM?.tranDate ? new Date(paymentMM?.tranDate) : undefined,
      expirationDate: paymentMM?.expirationDate
        ? new Date(paymentMM?.expirationDate)
        : undefined,
      useType9Income: paymentMM?.tranInfo?.useType9Income,
      accountCloseout: paymentMM?.tranInfo?.accountCloseout,
      insufFundSolutionId: paymentMM?.apprDetail?.insufFundSolution,
      insufFundComments: paymentMM?.apprDetail?.insufFund
    },
    taxWithholdings: {
      retDistCode: paymentMM?.tranInfo?.reasoncode,
      retDistReason: paymentMM?.tranInfo?.reasondesc,
      retDistSubCode: paymentMM?.tranInfo?.reasonsubcode,
      conReasonCode: paymentMM?.tranInfo?.conreasoncode,
      conReasonDesc: paymentMM?.tranInfo?.conreasondesc,
      conReasonSubcode: paymentMM?.tranInfo?.conreasonsubcode,
      conPromptYearInd: paymentMM?.tranInfo?.conpromptyearind,
      year: paymentMM?.tranInfo?.currentyear || paymentMM?.tranInfo?.prioryear,
      fedTaxOption: paymentMM?.tranInfo?.canfedwithhold,
      stateTaxOption: paymentMM?.tranInfo?.canstatewithhold,
      fedTaxPct: paymentMM?.tranInfo?.fedwithholdpct,
      stateTaxPct: paymentMM?.tranInfo?.statewithholdpct,
      fedTaxRate: paymentMM?.tranInfo?.fedwithholdpct,
      stateTaxRate: paymentMM?.tranInfo?.statewithholdpct,
      includeTax: !(paymentMM?.tranInfo?.istrnamtincludetaxwithhold || false),
      AMAacknowledgement: paymentMM?.tranInfo?.amAacknowledgement,
      fedwithholdmaxpct: paymentMM?.tranInfo?.fedwithholdmaxpct,
      fedwithholdminpct: paymentMM?.tranInfo?.fedwithholdminpct,
      statewithholdmaxpct: paymentMM?.tranInfo?.statewithholdmaxpct,
      statewithholdminpct: paymentMM?.tranInfo?.statewithholdminpct
    },
    payeeDetails: {
      overrideAddr: paymentMM?.tranInfo?.overrideAddr,
      addressType: paymentMM?.tranInfo?.checkAddressFormat,
      addrLine1: paymentMM?.tranInfo?.addrNameLine1,
      addrLine2: paymentMM?.tranInfo?.addrNameLine2,
      addrLine3: paymentMM?.tranInfo?.addrAttName,
      addrLine4: paymentMM?.tranInfo?.addrLine1,
      addrLine5: paymentMM?.tranInfo?.addrLine2,
      addrLine6_city: paymentMM?.tranInfo?.city,
      addrLine7_stateProvince: paymentMM?.tranInfo?.state,
      addrLine8_zip1: paymentMM?.tranInfo?.zip1,
      addrLine8_zip2: paymentMM?.tranInfo?.zip2,
      addrLine9_country: paymentMM?.tranInfo?.destinationCountry
    },
    overnightCheckDetails: {
      isOvernightCheck: paymentMM?.tranInfo?.overnightCheck,
      isHomeOfficeFlag: paymentMM?.tranInfo?.overnightCheck
        ? paymentMM?.tranInfo?.isHomeOffice
          ? 'Y'
          : 'N'
        : undefined,
      carrierName: paymentMM?.tranInfo?.carrier,
      billingAccountNumber: paymentMM?.tranInfo?.carrierBillingAccount,
      CarrierAddress1: paymentMM?.tranInfo?.carrierAddress1,
      CarrierAddress2: paymentMM?.tranInfo?.carrierAddress2,
      CarrierAddress3: paymentMM?.tranInfo?.carrierAddress3
    },
    checkDetails: {
      checkMemo: paymentMM?.tranInfo?.checkMemo,
      memo: paymentMM?.tranInfo?.memo
    },
    bundleChecks: {
      bundleFlag: paymentMM?.tranInfo?.bundleFlag === 'Y',
      bundleOffice: paymentMM?.tranInfo?.bundleOffice,
      bundleNo: paymentMM?.tranInfo?.bundleNo
        ? Number(paymentMM?.tranInfo?.bundleNo)
        : undefined,
      totalBundles: paymentMM?.tranInfo?.totalBundles
        ? Number(paymentMM?.tranInfo?.totalBundles)
        : undefined,
      OfficeLocationName: paymentMM?.tranInfo?.bundleOfficeLoc,
      StubAddrNameLine1: paymentMM?.tranInfo?.stubAddrNameLine1,
      StubAddrNameLine2: paymentMM?.tranInfo?.stubAddrNameLine2,
      StubAddrLine1: paymentMM?.tranInfo?.stubAddrLine1,
      StubAddrLine2: paymentMM?.tranInfo?.stubAddrLine2,
      StubCity: paymentMM?.tranInfo?.stubCity,
      StubState: paymentMM?.tranInfo?.stubState,
      StubZip1: paymentMM?.tranInfo?.stubZip1
    },
    stubAdditionLines: getStubLines(paymentMM?.tranInfo?.stubAdditionLines),
    SIstubAdditionLines: getStubLines(paymentMM?.tranInfo?.sIstubAdditionLines)
  }
  return formData
}

export const prepareCheckDataFromPastPaymnet = (
  item: IPastPayment,
  senderDetails?: ISenderFormDetails,
  config?: IMMConfig
): ICheckInitiationForm => {
  const recipientType = getrecipient(
    item?.tranInfo?.isThirdPartyCommon,
    item?.tranInfo?.isThirdParty
  )
  const payeeDetails =
    recipientType === RecipientType.FirstParty
      ? {
          overrideAddr: false,
          addressType: AddrFieldsByFormat.Domestic.key,
          addrLine1: senderDetails?.name
        }
      : {
          overrideAddr: false,
          addressType: AddrFieldsByFormat.NonStandard.key,
          addrLine1: item?.tranInfo?.beneficiaryNameAddrLine1,
          addrLine2: item?.tranInfo?.beneficiaryNameAddrLine2,
          addrLine3: item?.tranInfo?.beneficiaryNameAddrLine3,
          addrLine4: item?.tranInfo?.beneficiaryNameAddrLine4,
          addrLine5: item?.tranInfo?.beneficiaryNameAddrLine5,
          addrLine6_city: item?.tranInfo?.beneficiaryNameAddrLine6,
          addrLine7_stateProvince: item?.tranInfo?.state,
          addrLine8_zip1: item?.tranInfo?.zip1,
          addrLine8_zip2: item?.tranInfo?.zip2,
          addrLine9_country: item?.tranInfo?.destinationCountry
        }
  return {
    senderDetails: senderDetails,
    executingCustodian: config?.custodianID ?? item.executingCustodian,
    paymentType: 'CD',
    payeeDetails: payeeDetails,
    overnightCheckDetails: {
      isOvernightCheck: item?.tranInfo?.overnightCheck,
      isHomeOfficeFlag:
        item?.tranInfo?.overnightCheck && item?.tranInfo?.isHomeOffice
          ? 'Y'
          : 'N',
      carrierName: item?.tranInfo?.carrier,
      billingAccountNumber: item?.tranInfo?.carrierBillingAccount
    },
    checkDetails: {
      checkMemo: item?.tranInfo?.checkMemo,
      memo: item?.tranInfo?.memo
    },
    bundleChecks: {
      bundleFlag: item?.tranInfo?.bundleFlag === 'Y',
      bundleOffice: item?.tranInfo?.bundleOffice,
      bundleNo: item?.tranInfo?.bundleNo
        ? Number(item?.tranInfo?.bundleNo)
        : undefined,
      totalBundles: item?.tranInfo?.totalBundles
        ? Number(item?.tranInfo?.totalBundles)
        : undefined,
      OfficeLocationName: item?.tranInfo?.bundleOfficeLoc,
      StubAddrNameLine1: item?.tranInfo?.stubAddrNameLine1,
      StubAddrNameLine2: item?.tranInfo?.stubAddrNameLine2,
      StubAddrLine1: item?.tranInfo?.stubAddrLine1,
      StubAddrLine2: item?.tranInfo?.stubAddrLine2,
      StubCity: item?.tranInfo?.stubCity,
      StubState: item?.tranInfo?.stubState,
      StubZip1: item?.tranInfo?.stubZip1
    },
    stubAdditionLines: getStubLines(item?.tranInfo?.stubAdditionLines),
    wireAmount: {
      amount: '',
      currency: 'USD',
      recipientType: recipientType,
      useExistingPP: true,
      useExistingSI: false
    }
  }
}

export const prepareCheckDataFromSI = (
  item: IMMSummarySIById,
  senderDetails?: ISenderFormDetails,
  executingCustodian?: string,
  firstPartyPayeeDetails?: ICheckPayeeDetails
): ICheckInitiationForm => {
  const recipientType = getrecipient(
    item?.isthirdpartycommon,
    item?.isthirdparty
  )
  const payeeDetails =
    recipientType === RecipientType.FirstParty
      ? {
          ...firstPartyPayeeDetails,
          overrideAddr: false,
          addressType: AddrFieldsByFormat.Domestic.key
        }
      : {
          overrideAddr: false,
          addressType: item?.addrFormat || 'N',
          addrLine1: item?.addrNameLine1,
          addrLine2: item?.addrNameLine2,
          addrLine3: item?.addrAttName,
          addrLine4: item?.addrLine1,
          addrLine5: item?.addrLine2,
          addrLine6_city: item?.city,
          addrLine7_stateProvince: item?.state,
          addrLine8_zip1: item?.zip1,
          addrLine8_zip2: item?.zip2,
          addrLine9_country: item?.destinationcountry
        }
  return {
    senderDetails: senderDetails,
    executingCustodian: executingCustodian,
    paymentType: 'CD',
    payeeDetails: payeeDetails,
    wireAmount: {
      amount: '',
      currency: 'USD',
      useExistingSI: true,
      useExistingPP: false,
      standingInstructionId: item?.standinginstructionid,
      recipientType: recipientType
    },
    checkDetails: {
      checkMemo: item?.checkMemo,
      memo: item?.memo
    },
    SIstubAdditionLines: getStubLines(item?.stubAdditionLines),
    stubAdditionLines: []
  }
}
