import { lazy } from 'react'
import { IModuleDefinition } from '../types'

const PaymentsContainer = lazy(() => import('./PaymentsContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/payments/*',
    private: true,
    component: PaymentsContainer
  },
  name: 'Payments'
}

export default moduleDefinition
