import { useTheme } from '@fluentui/react'
import { Link } from 'react-router-dom'
import { Icon } from 'shared/uiSharedComponents/Icon'
import { useRdot360Context } from '../../../../store/rdot360Context'

const LinkIcon: React.FC<{ color?: string; iconSize?: number }> = ({
  color,
  iconSize = 16
}) => (
  <Icon
    type="OpenLink"
    width={iconSize}
    height={iconSize}
    color={color}
    aria-label="Open Link"
  />
)

export const NavigateToAIDashboard: React.FC<{
  linkText?: string
  iconSize?: number
}> = ({ linkText, iconSize }) => {
  const theme = useTheme()
  const { selectedHouseholdId } = useRdot360Context()
  const linkTo = `/advisory/ai?selectedTab=summary&householdId=${selectedHouseholdId}`

  return (
    <div css={{ display: 'inline-flex', gap: 3 }}>
      <Link to={linkTo}>{linkText}</Link>
      <LinkIcon color={theme.palette.themePrimary} iconSize={iconSize} />
    </div>
  )
}
