import { css } from '@emotion/react'
import {
  DirectionalHint,
  HoverCard,
  HoverCardType,
  IPlainCardProps
} from '@fluentui/react'
import { IKPI } from 'api/common.types'
import React, { useMemo } from 'react'
import { USDCell } from './USDCell'

interface IData {
  label?: string
  value?: number
}

const getClasses = () => ({
  container: css({
    padding: 10,
    fontSize: '12px',
    minWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '6px'
  }),
  label: css({
    fontWeight: 'bold'
  })
})

const AusHoverDetail: React.FC<{ data: IData[] }> = ({ data }) => {
  const classes = useMemo(() => getClasses(), [])
  return (
    <div css={classes.container}>
      {data.map((x) => (
        <div css={classes.label} key={x.label}>
          {x.label} : {<USDCell value={x?.value} fractionDigits={0} />}
        </div>
      ))}
    </div>
  )
}

export const AusCell: React.FC<{
  KPI?: IKPI
}> = ({ KPI }) => {
  const data: IData[] = useMemo(() => {
    return [
      { label: 'Managed Assets', value: KPI?.AumManaged },
      { label: 'Brokerage Assets', value: KPI?.AumBrokerage },
      {
        label: 'Loan Outstanding Nfs',
        value: KPI?.LoanOutstandingNfs
      },
      {
        label: 'Loan Outstanding Outside Nfs',
        value: KPI?.LoanOutstandingOutsideNfs
      },
      { label: 'Annuities and Insurance', value: KPI?.annuity },
      { label: 'Advised Only', value: KPI?.AdvisedOnly },
      {
        label: 'Admin Reporting Assets',
        value: KPI?.AdminReportingAssets
      }
    ]
  }, [KPI])

  const IPlainCardProps: IPlainCardProps = useMemo(
    () => ({
      onRenderPlainCard: () => <AusHoverDetail data={data} />,
      directionalHint: DirectionalHint.topCenter,
      calloutProps: {
        isBeakVisible: true
      }
    }),
    [data]
  )
  return (
    <>
      <HoverCard
        plainCardProps={IPlainCardProps}
        type={HoverCardType.plain}
        cardDismissDelay={500}
      >
        <div css={{ cursor: 'pointer' }}>
          <USDCell
            value={KPI?.AumTotal || KPI?.AccountTotal}
            fractionDigits={0}
          />
        </div>
      </HoverCard>
    </>
  )
}
