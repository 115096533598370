import { IUseListHookDefinition } from 'features/Lists/hooks/useList'
import { PositionColumnDefinitions } from 'features/Lists/positions/ColumnDefinitions'
import { keyBy } from 'lodash'
import { aiPositionListStore } from '../store'
import {
  Drawdown,
  NonDrawdown,
  Combined,
  AiPositionListTabNamesEnum
} from '../types'

const { actions, selectors } = aiPositionListStore
const aiPositionListTabNames = [Drawdown, NonDrawdown, Combined]
const tabNamesMappedToHookDefinitions = aiPositionListTabNames.map(
  (listType): IUseListHookDefinition<AiPositionListTabNamesEnum> => ({
    actions,
    selectors,
    columnDefinitions: PositionColumnDefinitions,
    listType
  })
)

export const aiPositionHookDefinitions = keyBy(
  tabNamesMappedToHookDefinitions,
  ({ listType }) => listType
) as Record<
  AiPositionListTabNamesEnum,
  IUseListHookDefinition<AiPositionListTabNamesEnum>
>
