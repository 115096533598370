import { push } from 'connected-react-router'
import { getLocation } from 'shared/services/dom'
import { IEnvironmentAppConfigurations } from 'shared/services/environment/IEnvironmentConfiguration'
import {
  call,
  put,
  select,
  takeEvery
  // tslint:disable-next-line: no-submodule-imports
} from 'typed-redux-saga'
import { getRockefellerApiOptions } from '../../../../../store/shared/sagas'
import { getEnvironmentConfigApps } from '../../../../../store/system'
import { getRdotUsername } from '../../../../../store/user/selectors'
import { fetchMatrixInfoService, fetchPaymentsMenuOptions } from '../../../api'
import {
  getPilotFeature,
  handleDynamicsExportService
} from '../../../api/dashboardService'
import {
  dynamicsExportActions,
  paymentsDataActions,
  paymentsMenuActions,
  paymentsNavigationActions,
  pilotFetaureActions,
  setActiveClientAction
} from './actions'
import {
  IMatrixInfo,
  IPaymentOption,
  IPilotFeatureRequest,
  IPilotFeaturesList
} from './types'

/**
 * Generator function saga to fetch matrix info and call api
 */

function* handleFetchMatrixInfo() {
  // Call fetch matrix info api here....

  try {
    const options = yield* call(getRockefellerApiOptions)
    const email = yield* select(getRdotUsername)

    if (!email) {
      throw new Error('Invalid State: email is undefined')
    }

    const data = yield* call(fetchMatrixInfoService, email, options)

    yield put(paymentsDataActions.success(data))
  } catch (e: any) {
    yield put(paymentsDataActions.failure(e))
  }
}

/**
 * This function will be used to naviagte to
 */
function* handleNavigateToFee() {
  const path = `/fees`
  yield put(push({ pathname: path }))
}

function* handleNavigateToPayments() {
  const path = `/payments`
  yield put(push({ pathname: path }))
}

function* handleNaviagteToWireInitiation() {
  const path = `/transfers/wire/new`
  yield put(push({ pathname: path }))
}

function* handleNavigateToVendor(
  action: ReturnType<typeof paymentsNavigationActions.navigateToVendors>
) {
  const payload = action.payload
  const { pathname } = yield* call(getLocation)
  const path = `${pathname}/vendors/${payload.orgId}`
  yield put(push({ pathname: path }))
}

function* handleNavigateToInvoice(
  action: ReturnType<typeof paymentsNavigationActions.naviagteToInvoice>
) {
  const payload = action.payload as IMatrixInfo
  const { pathname } = yield* call(getLocation)
  const path = `${pathname}/invoices/${payload.orgId}/${encodeURI(
    payload.name
  )}`
  yield put(push({ pathname: path }))
}

function* handleNavigateToReports(
  action: ReturnType<typeof paymentsNavigationActions.navigateToReports>
) {
  yield put(setActiveClientAction.clearActiveClient())
  const path = `/reports/dashboard`
  const payload = action.payload as IMatrixInfo
  if (payload?.partyId) {
    try {
      const options = yield* call(getRockefellerApiOptions)
      const email = yield* select(getRdotUsername)

      if (!email) {
        throw new Error('Invalid State: email is undefined')
      }
      const data = yield* call(fetchMatrixInfoService, email, options)
      yield put(paymentsDataActions.success(data))

      const matchedClient = data?.filter(
        (x) => x.partyId === payload.partyId
      )?.[0]

      if (matchedClient) {
        yield put(setActiveClientAction.setActiveClient(matchedClient))
      }
      yield put(push({ pathname: path }))
    } catch (e) {
      yield put(push({ pathname: path }))
    }
  } else {
    yield put(push({ pathname: path }))
  }
}

function* requestPaymentsMenuOptions() {
  const options = yield* call(getRockefellerApiOptions)
  const data = yield* call(fetchPaymentsMenuOptions, options)

  yield put(paymentsMenuActions.success(data as IPaymentOption[]))
}
function* handlePilotFeatures() {
  // Call fetch matrix info api here....

  try {
    const options = yield* call(getRockefellerApiOptions)
    const email = yield* select(getRdotUsername)

    if (!email) {
      throw new Error('Invalid State: email is undefined')
    }

    const req: IPilotFeatureRequest = {
      loginId: email,
      applicationName: 'PAYMENTHUB'
    }

    const data: IPilotFeaturesList = yield call(() =>
      getPilotFeature(req, options)
    )
    yield put(pilotFetaureActions.success(data))
  } catch (e: any) {
    yield put(pilotFetaureActions.failure(e))
  }
}

function* handleDynamicsExport() {
  // Call dynamics export info api here....

  try {
    const options = yield* call(getRockefellerApiOptions)
    const apps: IEnvironmentAppConfigurations = yield select(
      getEnvironmentConfigApps
    )
    const url = apps.dynamicsExport?.url
    const data: boolean = yield call(() =>
      handleDynamicsExportService(url || '', options)
    )
    yield put(dynamicsExportActions.success(data))
  } catch (e: any) {
    yield put(dynamicsExportActions.failure(e))
  }
}

function* handleNavigateToGlAdjustment(
  action: ReturnType<typeof paymentsNavigationActions.navigateToGl>
) {
  yield* put(setActiveClientAction.setActiveClient(action.payload))
  const path = `gl`
  yield put(push({ pathname: path }))
}

export const sagas = [
  () => takeEvery(paymentsDataActions.request, handleFetchMatrixInfo),
  () => takeEvery(paymentsNavigationActions.navigateToFee, handleNavigateToFee),
  () =>
    takeEvery(
      paymentsNavigationActions.navigateToVendors,
      handleNavigateToVendor
    ),
  () =>
    takeEvery(
      paymentsNavigationActions.naviagteToInvoice,
      handleNavigateToInvoice
    ),
  () => takeEvery(paymentsMenuActions.request, requestPaymentsMenuOptions),
  () => takeEvery(pilotFetaureActions.request, handlePilotFeatures),
  () => takeEvery(dynamicsExportActions.request, handleDynamicsExport),
  () =>
    takeEvery(
      paymentsNavigationActions.navigateToPayments,
      handleNavigateToPayments
    ),
  () =>
    takeEvery(
      paymentsNavigationActions.navigateToReports,
      handleNavigateToReports
    ),
  () =>
    takeEvery(
      paymentsNavigationActions.navigateToGl,
      handleNavigateToGlAdjustment
    ),
  () =>
    takeEvery(
      paymentsNavigationActions.naviagteToWireInitiation,
      handleNaviagteToWireInitiation
    )
]
