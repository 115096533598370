import { IndeterminateProgressIndicator } from './shared'

const baseStyles: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  pointerEvents: 'none'
}

export const TileLoadingOverlay: React.FC = () => {
  return (
    <div
      style={{
        ...baseStyles,
        top: '36px',
        zIndex: 2
      }}
      className="noprint"
    >
      <div
        style={{
          ...baseStyles,
          backgroundColor: 'rgba(255,255,255,0.5)',
          zIndex: 1
        }}
      />
      <div
        style={{
          ...baseStyles,
          zIndex: 2
        }}
      >
        <div
          css={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%'
          }}
        >
          <IndeterminateProgressIndicator />
        </div>
      </div>
    </div>
  )
}
