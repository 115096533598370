import { IAccount } from 'api/account.types'
import { IReportSearchRequest, IReportSearchResult } from 'api/datahub'
import { IHousehold } from 'api/household.types'
import { stringify } from 'query-string'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { constructQuery, datahubApi } from 'store/api/datahub'

const paramsSerializer = (params: any) => {
  return stringify(params, { arrayFormat: 'comma' })
}

export const optionsIncomeAndExpenseDatahubApi = datahubApi.injectEndpoints({
  endpoints: (builder) => ({
    optionsIncomeAndExpense_searchHouseholds: builder.query<
      IHousehold[],
      string
    >({
      query: (searchText) => ({
        url: 'search/households',
        params: {
          search: constructQuery(searchText),
          queryType: 'full',
          searchFields: ['householdName', 'Account', 'Parties/LegalEntityName'],
          select: [
            'id',
            'householdId',
            'householdName',
            'Parties',
            'Account',
            'householdKPI/AumTotal'
          ],
          $top: 20
        },
        paramsSerializer
      }),
      transformResponse: (response: IOdataResult<IHousehold>) =>
        response?.value || []
    }),
    optionsIncomeAndExpense_getHousehold: builder.query<
      IHousehold | undefined,
      string
    >({
      query: (householdId) => ({
        url: 'search/households',
        params: {
          $filter: [`householdId eq '${householdId}'`],
          $top: 1,
          select: [
            'id',
            'householdId',
            'householdName',
            'Parties',
            'Account',
            'householdKPI/AumTotal'
          ],
          $orderby: 'householdKPI/AumTotal'
        },
        paramsSerializer
      }),
      transformResponse: (response: IOdataResult<IHousehold>) =>
        response?.value?.[0]
    }),
    optionsIncomeAndExpense_getAccountsByHouseholdId: builder.query<
      IAccount[],
      string
    >({
      query: (householdId) => ({
        url: `search/accounts`,
        params: {
          $filter: [`householdId eq '${householdId}'`],
          $top: 1000,
          select: [
            'householdId',
            'CustodyAccount',
            'id',
            'AccountKPIs/AccountTotal',
            'LegalEntityName',
            'AdvisorAddedNickName',
            'registrationtype',
            'accountStatus',
            'Shortname'
          ]
        },
        paramsSerializer
      }),
      transformResponse: (response: IOdataResult<IAccount>) =>
        response?.value || []
    }),
    getOptionsIncomeAndExpenses: builder.query<
      IReportSearchResult,
      IReportSearchRequest
    >({
      query: ({ accounts, endDate, startDate }) => ({
        url: 'AccountIncomeAndExpenses/GetAccountIncomeExpenseSummary',
        method: 'POST',
        data: { request: { accounts, endDate, startDate } }
      })
    })
  })
})

export const {
  useOptionsIncomeAndExpense_searchHouseholdsQuery: useSearchHouseholdsQuery,
  useOptionsIncomeAndExpense_getHouseholdQuery: useGetHouseholdQuery,
  useOptionsIncomeAndExpense_getAccountsByHouseholdIdQuery:
    useGetAccountsByHouseholdIdQuery,
  useGetOptionsIncomeAndExpensesQuery
} = optionsIncomeAndExpenseDatahubApi
