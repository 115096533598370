import { Stack, Text } from '@fluentui/react'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import React, { useMemo } from 'react'
import { LoadingComponent } from 'shared/components/Loading'
import { SimpleOverlay } from 'shared/components/SimpleOverlay'
import { isNotNullOrEmpty } from 'shared/guards'
import { useFetchNfsProfileByIdQuery } from 'store/api/datahub'

export const PhonesMenuSection: React.FC<{
  WealthscapeId?: string
}> = ({ WealthscapeId }) => {
  const { currentData: data, isFetching } = useFetchNfsProfileByIdQuery(
    WealthscapeId || skipToken
  )
  const mfaPhones = useMemo(() => {
    try {
      const phones: string[] = JSON.parse(data?.mfaPhones || '[]')
      return phones
    } catch (e) {
      return []
    }
  }, [data?.mfaPhones])

  const nfsPhones = useMemo(() => {
    try {
      const phones: { Phone: string }[] = JSON.parse(data?.phones || `[]`)
      return phones.map((x) => x.Phone).filter(isNotNullOrEmpty)
    } catch (e) {
      return []
    }
  }, [data?.phones])

  return (
    <div style={{ position: 'relative' }}>
      {isFetching && (
        <SimpleOverlay>
          <LoadingComponent />
        </SimpleOverlay>
      )}

      <Stack
        tokens={{ childrenGap: 10 }}
        styles={{ root: { position: 'relative' } }}
      >
        <div>
          <Text variant="small" styles={{ root: { fontWeight: 'bold' } }}>
            MFA Phone(s)
          </Text>
          <Stack styles={{ root: { minHeight: '20px' } }}>
            {mfaPhones?.map((x, i) => (
              <Text key={i}> {x}</Text>
            ))}

            {!mfaPhones.length && !isFetching && <Text>--</Text>}
          </Stack>
        </div>
        <div>
          <Text variant="small" styles={{ root: { fontWeight: 'bold' } }}>
            NFS Phone(s)
          </Text>
          <Stack styles={{ root: { minHeight: '20px' } }}>
            {nfsPhones?.map((phone, i) => (
              <Text key={i}> {phone}</Text>
            ))}

            {!nfsPhones.length && !isFetching && <Text>--</Text>}
          </Stack>
        </div>
      </Stack>
    </div>
  )
}
