import {
  ListsFilterEdit,
  getFilerComponent
} from 'features/Lists/core/features/filter/components/ListsFilterEdit'
import { IListsFilterEditProps } from 'features/Lists/core/features/filter/contracts/IListsFilterEditProps'
import { IOdataListFacetActions } from 'features/OdataList/common/IODataListFacetActions'
import { IOdataListFacetSelectors } from 'features/OdataList/common/IODataListFacetSelectors'
import React, { PropsWithChildren, memo, useMemo } from 'react'
import { createConnectedODataListFacetFilter } from './ODataListDynamicFacetFilter'

export interface IOdataListsFilterEditProps<T> extends IListsFilterEditProps {
  facetActions?: IOdataListFacetActions
  facetSelectors?: IOdataListFacetSelectors<T>
}

export const ODataListsFilterEdit = <T,>({
  filter,
  facetActions,
  facetSelectors,
  ...rest
}: PropsWithChildren<
  IOdataListsFilterEditProps<T>
>): React.ReactElement | null => {
  const { type } = filter
  const FilterComponent: React.FC<IListsFilterEditProps> | null =
    useMemo(() => {
      if (
        facetActions &&
        facetSelectors &&
        (type === 'facet' || type === 'boolean')
      ) {
        return memo(
          createConnectedODataListFacetFilter(
            facetActions,
            facetSelectors
          ) as React.FC<IListsFilterEditProps>
        )
      }
      return getFilerComponent(type)
    }, [facetActions, facetSelectors, type])

  return (
    <ListsFilterEdit filter={filter} {...rest} customFilter={FilterComponent} />
  )
}
