import { IOdataRequest } from 'api/odata.types'
import { wrap } from 'comlink'
import { format } from 'date-fns'
import {
  fetchAllPagedOdataResults,
  useDatahubApi
} from 'modules/Advisory/shared/export/api'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { downloadUrlAsFile } from 'shared/downloads'
import {
  feeDashboardListUiSelectors,
  fetchPendingFees
} from '../store/dashboardList'
import { feeDashboardListColumns } from '../store/dashboardListColumns'
import { FeeDashboardExcelExportWorker } from './worker'

const fileName = 'Fee Dashboard'

export const useFeeDashboardExport = () => {
  const { acquireToken, config } = useDatahubApi()
  const odataRequest = useSelector(feeDashboardListUiSelectors.getOdataRequest)

  const [isLoading, setIsLoading] = useState(false)

  const fetchPendingFeesWithAuth = useCallback(
    async (req: IOdataRequest) => {
      if (!config?.root) {
        throw new Error('No api root provided for datahub api')
      }

      const accessToken = await acquireToken()
      const result = await fetchPendingFees(
        { accessToken, apiRoot: config.root },
        req
      )

      return result
    },
    [acquireToken, config?.root]
  )

  const fetchAllPendingFees = useCallback(async () => {
    return fetchAllPagedOdataResults(odataRequest, fetchPendingFeesWithAuth)
  }, [fetchPendingFeesWithAuth, odataRequest])

  const exportToExcel = useCallback(async () => {
    setIsLoading(true)
    try {
      const data = await fetchAllPendingFees()
      const worker = new Worker(new URL('./worker.ts', import.meta.url))
      const { createExcelWorksheetBlobUrlForFeeDashboard } =
        wrap<FeeDashboardExcelExportWorker>(worker)

      const blobUrl = await createExcelWorksheetBlobUrlForFeeDashboard(
        data,
        feeDashboardListColumns.map((x) => x.name),
        fileName
      )

      const filename = `${fileName} ${format(new Date(), 'MM-dd-yyyy')}.xlsx`

      downloadUrlAsFile(blobUrl, filename)
    } finally {
      setIsLoading(false)
    }
  }, [fetchAllPendingFees])

  return { exportToExcel, isLoading }
}
