import { useDomainStore } from 'features/Domain/store/domain'
import { IListsUiState } from 'features/Lists/core/contracts/IListsUIState'
import { useListSessionState } from 'features/Lists/hooks/useListSessionState'
import {
  IListWithPreferencesHookArgs,
  useListWithPreferences
} from 'features/Lists/hooks/useListWithPreferences'
import { useCallback, useLayoutEffect } from 'react'
import { useMount, useUpdateEffect } from 'react-use'
import { AiPositionListTabNamesEnum } from '../types'
import { aiPositionHookDefinitions } from './hookDefinitions'
import { systemViewsMap } from './systemViews'
import { useAiDashboardAdvisorIdFilter } from './useAdvisorIdFilter'
import { useHiddenFilters } from './useHiddenFilters'
import { useAiDashboardHouseholdIdFilter } from './useHouseholdFilter'
import { useAiDashboardList } from './useList'

export const useAiListWithPreferences = (
  type: AiPositionListTabNamesEnum,
  args: IListWithPreferencesHookArgs
) => {
  const definition = aiPositionHookDefinitions[type]
  return useListWithPreferences(definition, args)
}

const preferenceKey = 'ai_dashboard/lists'

const useAiPositionListConfig = (listType: AiPositionListTabNamesEnum) => {
  const { aiPositionListHiddenFilters } = useHiddenFilters(listType)
  const { advisorIdFilter } = useAiDashboardAdvisorIdFilter()
  const { householdIdFilter } = useAiDashboardHouseholdIdFilter()

  const onBeforeApply = useCallback(
    (state: Partial<IListsUiState>) => {
      return {
        ...state,
        filters: {
          ...(state.filters || {}),
          ...aiPositionListHiddenFilters,
          ...(advisorIdFilter?.id && { [advisorIdFilter.id]: advisorIdFilter }),
          ...(householdIdFilter && {
            [householdIdFilter.id]: householdIdFilter
          })
        }
      }
    },
    [advisorIdFilter, aiPositionListHiddenFilters, householdIdFilter]
  )

  const systemViews = systemViewsMap[listType]

  return {
    preferenceKey,
    listType,
    systemViews,
    onBeforeApply
  }
}

const useAiPositionListSessionPreferences = (
  listType: AiPositionListTabNamesEnum
) => {
  return useListSessionState(
    `${preferenceKey}/${listType}/useAiPositionSessionPreferences`
  )
}

export const useAiPositionListWithPreferences = (
  listType: AiPositionListTabNamesEnum
) => {
  const { preferenceKey, systemViews, onBeforeApply } =
    useAiPositionListConfig(listType)

  const listWithPreferences = useAiListWithPreferences(listType, {
    preferenceKey,
    systemViews,
    onBeforeApply
  })

  return listWithPreferences
}

export const useInitializeAiPositionList = (
  listType: AiPositionListTabNamesEnum
) => {
  const {
    listPreferences: sessionListPreferences,
    setListPreferences: setSessionListPreferences
  } = useAiPositionListSessionPreferences(listType)
  const { isFetching } = useDomainStore()
  const { setIsReady } = useAiDashboardList(listType)
  const { listPreferences } = useAiPositionListWithPreferences(listType)
  const {
    applyManualPreferences,
    defaultViewId,
    selectedViewId,
    applyView,
    preSaveListPreferences
  } = listPreferences

  useMount(() => {
    if (!sessionListPreferences) {
      return
    }

    applyManualPreferences(sessionListPreferences)
  })

  useUpdateEffect(() => {
    // if rep codes aren't ready, or we haven't updated the list state yet, don't set ready
    if (isFetching || !preSaveListPreferences) {
      return
    }
    setIsReady()
  }, [isFetching, preSaveListPreferences, setIsReady])

  // need useLayoutEffect here or we cause an infinite loop
  // https://github.com/reduxjs/react-redux/issues/1912
  // set the initial view
  useLayoutEffect(() => {
    if (!defaultViewId || selectedViewId) {
      return
    }

    applyView(defaultViewId)
  }, [applyView, defaultViewId, selectedViewId])

  useUpdateEffect(() => {
    if (!preSaveListPreferences) {
      return
    }

    setSessionListPreferences(preSaveListPreferences)
  }, [preSaveListPreferences, setSessionListPreferences])
}
