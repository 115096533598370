import axios from 'axios'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import {
  IPastStandingInstructionResponse,
  ISIValidateRequest
} from '../store/EFT/types'

export const getPastStandingInstructions = (
  options: IApiOptions,
  account?: string,
  By?: string
) => {
  const url = `${options.apiRoot}/mm/odata/MMSummary?AccountList=${account}&SearchBy=SI,${By}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .get<IPastStandingInstructionResponse>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const getAccountHolders = (account: string, options: IApiOptions) => {
  const url = `${options.apiRoot}/payment/api/payment/getAccountOwner?account=${account}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .get(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export const SIValidateService = (
  request: ISIValidateRequest,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/mm/odata/MMStandingInstructionValidate`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }

  return axios
    .post(url, request, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
