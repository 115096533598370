import { MessageBarType } from '@fluentui/react'
import { flow } from 'lodash'
import { createSelector } from 'reselect'
import { call, put, race, take, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import {
  deleteMarginRateRequest,
  MarginRateRequestStatusEnum
} from '../../../../../../../../../api/dynamics'
import { pushNotification } from '../../../../../../../../../features/Notifications'
import { AppState } from '../../../../../../../../../store'
import { getDynamicsApiOptions } from '../../../../../../../../../store/shared/sagas'
import { marginRateRequestListUpdateActions } from '../../MarginRateRequestList'
import {
  getAccountsError,
  getIsAccountsLoading,
  marginAccountFetchActions
} from './HouseholdAccountsFetch'
import {
  getHouseholdsError,
  getIsHouseholdsLoading,
  householdDetailsFetchActions
} from './HouseholdDetailsFetch'
import {
  getIsMarginRateRequestFetchLoading,
  getMarginRateRequestFetchError,
  marginRateRequestFetchActions
} from './MarginRateRequestFetch'
import { marginRateRequestUpdateActions } from './MarginRateRequestUpdate'

const OPEN =
  '@modules/@advisory/@modules/@marginRateRequest/@marginRateRequestViewPanel/OPEN'
const APPROVE =
  '@modules/@advisory/@modules/@marginRateRequest/@marginRateRequestViewPanel/APPROVE'
const REJECT =
  '@modules/@advisory/@modules/@marginRateRequest/@marginRateRequestViewPanel/REJECT'
const DELETE =
  '@modules/@advisory/@modules/@marginRateRequest/@marginRateRequestViewPanel/DELETE'
const UPDATE_STATUS =
  '@modules/@advisory/@modules/@marginRateRequest/@marginRateRequestViewPanel/UPDATE_STATUS'
const CLOSE =
  '@modules/@advisory/@modules/@marginRateRequest/@marginRateRequestViewPanel/CLOSE'
const FAILURE =
  '@modules/@advisory/@modules/@marginRateRequest/@marginRateRequestViewPanel/FAILURE'

export const marginRateRequestViewPanelActions = {
  open: createAction(OPEN)<string | undefined>(),
  updateStatus: createAction(UPDATE_STATUS)<string | undefined>(),
  close: createAction(CLOSE)(),
  approve: createAction(APPROVE)<string | undefined>(),
  reject: createAction(REJECT)<string | undefined>(),
  delete: createAction(DELETE)<string | undefined>(),
  failure: createAction(FAILURE)<Error>()
}
export type MarginRateRequestPanelActionTypes = ActionType<
  typeof marginRateRequestViewPanelActions
>
export interface IMarginRateRequestViewPanelState {
  isOpen?: boolean
  status?: string
  error?: Error
}

const initialState: IMarginRateRequestViewPanelState = {}

export const marginRateRequestViewPanelReducer = createReducer<
  IMarginRateRequestViewPanelState,
  MarginRateRequestPanelActionTypes
>(initialState)
  .handleAction(marginRateRequestViewPanelActions.open, () => ({
    ...initialState,
    isOpen: true
  }))
  .handleAction(marginRateRequestViewPanelActions.close, () => ({
    ...initialState,
    isOpen: false
  }))
  .handleAction(marginRateRequestViewPanelActions.approve, (state) => ({
    ...state,
    error: undefined
  }))
  .handleAction(marginRateRequestViewPanelActions.reject, (state) => ({
    ...state,
    error: undefined
  }))
  .handleAction(marginRateRequestViewPanelActions.delete, (state) => ({
    ...state,
    error: undefined
  }))
  .handleAction(
    marginRateRequestViewPanelActions.updateStatus,
    (state, action) => ({
      ...state,
      status: action.payload
    })
  )
  .handleAction(marginRateRequestViewPanelActions.failure, (state, action) => ({
    ...state,
    status: undefined,
    error: action.payload
  }))

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.accounts.modules.marginRateRequests.features
    .marginRateRequestViewPanel

export const getIsMarginRateRequestViewPanelOpen = flow(
  rootSelector,
  ({ isOpen }) => isOpen
)
export const getIsMarginRateRequestPanelProcessing = flow(
  rootSelector,
  ({ status }) => !!status
)

export const getMarginRateRequestViewPanelStatus = flow(
  rootSelector,
  ({ status }) => status
)
export const getIsMarginRequestFetchLoading = getIsMarginRateRequestFetchLoading
export const getIsMarginRequestHouseholdsFetchLoading = getIsHouseholdsLoading
export const getIsMarginRequestAccountsFetchLoading = getIsAccountsLoading

export const getIsMarginRateRequestViewPanelLoading = createSelector(
  [
    getIsMarginRequestFetchLoading,
    getIsMarginRequestHouseholdsFetchLoading,
    getIsMarginRequestAccountsFetchLoading
  ],
  (request, household, accounts) =>
    [request, household, accounts].some((x) => x)
)

export const getMarginRateRequestViewPanelError = createSelector(
  [
    flow(rootSelector, ({ error }) => error),
    getMarginRateRequestFetchError,
    getHouseholdsError,
    getAccountsError
  ],
  (panel, request, household, accounts) =>
    panel || request || household || accounts
)

const onOpenMarginRateRequestViewPanel = function* (
  action: ReturnType<typeof marginRateRequestViewPanelActions.open>
) {
  if (!action.payload) {
    return
  }
  yield put(marginAccountFetchActions.request(undefined))
  yield put(householdDetailsFetchActions.request(undefined))
  yield put(marginRateRequestFetchActions.request(action.payload))
}
export const marginRateRequestViewPanelSagas = [
  () =>
    takeLatest(
      marginRateRequestViewPanelActions.open,
      onOpenMarginRateRequestViewPanel
    ),
  () =>
    takeLatest(
      marginRateRequestViewPanelActions.approve,
      function* (
        action: ReturnType<typeof marginRateRequestViewPanelActions.approve>
      ) {
        yield put(
          marginRateRequestViewPanelActions.updateStatus(
            'Submitting for Approval'
          )
        )
        yield put(
          marginRateRequestUpdateActions.request({
            rcm_marginraterequestid: action.payload,
            rcm_status: MarginRateRequestStatusEnum.APPROVED
          })
        )

        const { success, failure } = yield* race({
          success: take(marginRateRequestUpdateActions.success),
          failure: take(marginRateRequestUpdateActions.failure)
        })

        if (failure || !success) {
          yield put(
            marginRateRequestViewPanelActions.failure(
              failure?.payload || new Error('An unknown error occurred')
            )
          )
        }

        yield put(marginRateRequestViewPanelActions.close())
        yield call(pushNotification, {
          message: 'Successfully approved request.',
          type: MessageBarType.success
        })

        yield put(marginRateRequestViewPanelActions.updateStatus(undefined))
      }
    ),
  () =>
    takeLatest(
      marginRateRequestViewPanelActions.reject,
      function* (
        action: ReturnType<typeof marginRateRequestViewPanelActions.reject>
      ) {
        yield put(
          marginRateRequestViewPanelActions.updateStatus(
            'Submitting for Rejection'
          )
        )
        yield put(
          marginRateRequestUpdateActions.request({
            rcm_marginraterequestid: action.payload,
            rcm_status: MarginRateRequestStatusEnum.REJECTED
          })
        )

        const { success, failure } = yield* race({
          success: take(marginRateRequestUpdateActions.success),
          failure: take(marginRateRequestUpdateActions.failure)
        })

        if (failure || !success) {
          yield put(
            marginRateRequestViewPanelActions.failure(
              failure?.payload || new Error('An unknown error occurred')
            )
          )
        }

        yield put(marginRateRequestViewPanelActions.close())
        yield call(pushNotification, {
          message: 'Successfully Rejected request.',
          type: MessageBarType.success
        })

        yield put(marginRateRequestViewPanelActions.updateStatus(undefined))
      }
    ),
  () =>
    takeLatest(
      marginRateRequestViewPanelActions.delete,
      function* (
        action: ReturnType<typeof marginRateRequestViewPanelActions.delete>
      ) {
        if (!action.payload) {
          return
        }

        yield put(
          marginRateRequestViewPanelActions.updateStatus('Deleting Request...')
        )

        const apiOptions = yield* call(getDynamicsApiOptions)
        yield* call(deleteMarginRateRequest, apiOptions, action.payload)
        yield put(marginRateRequestListUpdateActions.delete(action.payload))

        yield put(marginRateRequestViewPanelActions.close())
        yield call(pushNotification, {
          message: 'Successfully deleted the request.',
          type: MessageBarType.success
        })

        yield put(marginRateRequestViewPanelActions.updateStatus(undefined))
      }
    )
]
