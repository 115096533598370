import { combineReducers } from 'redux'
import {
  changeRequestDetailReducer,
  changeRequestDetailSagas
} from './changeRequestDetail'
import {
  householdsChangeRequestListReducer,
  householdsChangeRequestListSagas
} from './changeRequestsList'
import { householdsListReducer, householdsListSagas } from './householdsList'

export const householdsReducer = combineReducers({
  householdsList: householdsListReducer,
  householdsChangeRequestList: householdsChangeRequestListReducer,
  changeRequestDetail: changeRequestDetailReducer
})

export const householdSagas = [
  ...householdsListSagas,
  ...householdsChangeRequestListSagas,
  ...changeRequestDetailSagas
]
