import {
  Checkbox,
  FontIcon,
  IconButton,
  SearchBox,
  Stack
} from '@fluentui/react'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAdvisorRepTreeStore } from './store/advisorRepTree'

export const AdvisorRepTreeHeader: React.FC = () => {
  const {
    filteredAdvisorReps,
    selectedLookup: advisorRepSelectedLookup,
    areAllAdvisorRepsSelected,
    selectAllVisible,
    clearAllSelected,
    collapseAllVisible,
    expandAll,
    updateFilter,
    filter,
    showSelectedOnly,
    toggleShowSelectedOnly
  } = useAdvisorRepTreeStore()

  const someAreSelected = useMemo(
    () =>
      !areAllAdvisorRepsSelected &&
      filteredAdvisorReps.some(({ id }) => advisorRepSelectedLookup[id]),
    [advisorRepSelectedLookup, areAllAdvisorRepsSelected, filteredAdvisorReps]
  )

  const onSearchChange = useCallback(
    (_: any, newValue?: string) => {
      updateFilter(newValue)
      expandAll()
    },
    [expandAll, updateFilter]
  )

  const debouncedOnSearchChange = useMemo(
    () => debounce(onSearchChange, 100),
    [onSearchChange]
  )

  const [headerCollapseToggleState, setHeaderCollapseToggleState] =
    useState<boolean>()
  const toggleHeaderCollapsedState = useCallback(() => {
    setHeaderCollapseToggleState(!headerCollapseToggleState)
  }, [headerCollapseToggleState])

  useEffect(() => {
    if (headerCollapseToggleState === undefined) {
      return
    }

    headerCollapseToggleState ? collapseAllVisible() : expandAll()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerCollapseToggleState])

  return (
    <Stack.Item grow={1}>
      <Stack
        horizontal={true}
        verticalAlign="center"
        tokens={{ childrenGap: 5 }}
      >
        <FontIcon
          iconName={
            headerCollapseToggleState ? 'ChevronRightSmall' : 'ChevronDownSmall'
          }
          onClick={toggleHeaderCollapsedState}
          style={{ fontSize: '11px', cursor: 'pointer' }}
        />

        <Checkbox
          indeterminate={someAreSelected}
          checked={areAllAdvisorRepsSelected}
          onChange={(_, checked) =>
            checked ? selectAllVisible() : clearAllSelected()
          }
        />
        <Stack.Item grow={1}>
          <SearchBox
            placeholder="Filter Advisors"
            defaultValue={filter || ''}
            onChange={debouncedOnSearchChange}
            autoComplete="off"
          />
        </Stack.Item>
        <IconButton
          iconProps={{ iconName: 'CheckList' }}
          title="Show Selected Items Only"
          checked={showSelectedOnly}
          onClick={toggleShowSelectedOnly}
        />
      </Stack>
    </Stack.Item>
  )
}
