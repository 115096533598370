import { HTMLProps, useEffect, useRef } from 'react'

export const IndeterminateCheckbox: React.FC<
  { indeterminate?: boolean } & HTMLProps<HTMLInputElement>
> = ({ indeterminate, checked, ...rest }) => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!ref.current || typeof indeterminate !== 'boolean') {
      return
    }

    ref.current.indeterminate = !checked && indeterminate
  }, [ref, indeterminate, checked])

  return (
    <input
      style={{ cursor: 'pointer', padding: 0, margin: 0 }}
      type="checkbox"
      ref={ref}
      checked={checked}
      {...rest}
    />
  )
}
