import { useTheme } from '@emotion/react'
import { Callout } from '@fluentui/react'
import { useId } from '@fluentui/react-hooks'
import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import { useCallback, useState } from 'react'
import { FormattedNumber } from 'react-intl'
import { downloadUrlAsFile } from 'shared/downloads'
import { detailTable } from '../../../components/shared/DataTable/DetailTable'
import { HeaderContainer } from '../../../components/shared/DetailTables/HeaderContainer'
import { Icon } from '../../../features/Icons/Icon'
import { IDriveChild } from '../../../store/graph'
import { useCollaborationUiState } from '../CollaborationUIState'
import { ShareItemCallout } from '../ShareItemCallout'

const columnHelper = createColumnHelper<IDriveChild>()

const ItemNameCell: React.FC<{
  driveChild: IDriveChild
}> = ({ driveChild }) => {
  const { name } = driveChild
  const type = driveChild?.folder ? 'Folder' : 'File'
  const { onFolderClicked } = useCollaborationUiState()
  const onItemClicked = useCallback(() => {
    if (type === 'Folder') {
      onFolderClicked(driveChild)
    }
    if (!driveChild?.['@microsoft.graph.downloadUrl'] || !driveChild?.name) {
      return
    }
    downloadUrlAsFile(
      driveChild?.['@microsoft.graph.downloadUrl'],
      driveChild?.name
    )
  }, [driveChild, onFolderClicked, type])
  const itemId = useId('item')
  const [showCallout, setShowCallout] = useState(false)
  const theme = useTheme()
  return (
    <>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'center',
          ':hover .hiddenIcon': { visibility: 'visible' }
        }}
      >
        <div
          css={{ display: 'flex', gridColumnGap: 5, cursor: 'pointer' }}
          onClick={onItemClicked}
        >
          <Icon type={type} width={14} height={14} />
          <div>{name}</div>
        </div>
        <div
          id={itemId}
          className="hiddenIcon"
          css={{ visibility: showCallout ? 'visible' : 'hidden' }}
        >
          <Icon
            type="Share"
            width={14}
            height={14}
            onClick={() => setShowCallout(true)}
            title="Share item"
          />
        </div>
      </div>
      {showCallout && (
        <Callout
          target={`#${itemId}`}
          onDismiss={() => setShowCallout(false)}
          styles={{
            calloutMain: {
              borderRadius: '8px',
              background: 'white',
              boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.2)',
              padding: '20px'
            },
            root: {
              marginTop: '5px',
              borderRadius: '8px',
              border: `1px solid ${theme.colors.panelCalloutBorder}`
            },
            beakCurtain: {
              borderRadius: '8px',
              border: `1px solid ${theme.colors.panelCalloutBorder}`
            },
            beak: {
              border: `1px solid ${theme.colors.panelCalloutBorder}`,
              background: 'white',
              boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.2)'
            }
          }}
          isBeakVisible={true}
        >
          <ShareItemCallout
            targetId={itemId}
            link={driveChild?.webUrl}
            showCallout={showCallout}
          />
        </Callout>
      )}
    </>
  )
}

const CollaborationItemMenu: React.FC<{ item?: IDriveChild }> = ({ item }) => {
  const { setMenuItem } = useCollaborationUiState()
  const theme = useTheme()
  return (
    <div
      css={{ width: 16, height: 16, cursor: 'pointer' }}
      id={`id${item?.id}`}
      onClick={() => setMenuItem(item)}
    >
      <Icon type="Meatball" color={theme.colors.tertiaryGray3} />
    </div>
  )
}

export const constructColumns = () => [
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    cell: (props) => <ItemNameCell driveChild={props.row.original} />,
    header: (props) => (
      <HeaderContainer title="Name" isSorted={props.column.getIsSorted()} />
    ),
    size: 200,
    enableGlobalFilter: false
  }),
  columnHelper.accessor((row) => (row?.folder ? 0 : row?.size), {
    id: 'file',
    cell: (props) =>
      props.row.original.folder ? (
        ''
      ) : (
        <FormattedNumber
          notation="compact"
          unit="byte"
          unitDisplay="narrow"
          style="unit"
          value={props.row.original?.size || 0}
        />
      ),
    header: (props) => (
      <HeaderContainer title="File" isSorted={props.column.getIsSorted()} />
    ),
    size: 100
  }),
  columnHelper.accessor((row) => row.createdDateTime, {
    id: 'dateUpload',
    cell: (props) => format(new Date(props.getValue() || ''), 'yyyy-MM-dd p'),
    header: (props) => (
      <HeaderContainer
        title="Date Uploaded"
        isSorted={props.column.getIsSorted()}
      />
    ),
    size: 100
  }),
  columnHelper.accessor((row) => row.createdBy?.user?.displayName, {
    id: 'createdBy',
    cell: (props) => (
      <div css={detailTable.ellipsis} title={props.getValue()}>
        {props.getValue()}
      </div>
    ),
    header: (props) => (
      <HeaderContainer
        title="Created By"
        isSorted={props.column.getIsSorted()}
      />
    ),
    size: 100
  }),
  columnHelper.accessor((row) => row?.lastModifiedBy?.user?.displayName, {
    id: 'lastUpdateBy',
    cell: (props) => (
      <div css={detailTable.ellipsis} title={props.getValue()}>
        {props.getValue()}
      </div>
    ),
    header: (props) => (
      <HeaderContainer
        title="Last Update By"
        isSorted={props.column.getIsSorted()}
      />
    ),
    size: 100
  }),
  columnHelper.accessor(
    (row) => (row?.folder ? 'Folder' : row?.file?.mimeType),
    {
      id: 'fileType',
      cell: (props) => (
        <div css={detailTable.ellipsis} title={props.getValue()}>
          {props.getValue()}
        </div>
      ),
      header: (props) => (
        <HeaderContainer
          title="File Type"
          isSorted={props.column.getIsSorted()}
        />
      ),
      size: 100
    }
  ),
  columnHelper.accessor((row) => row, {
    id: 'menu',
    cell: (props) => <CollaborationItemMenu item={props.getValue()} />,
    header: () => <></>,
    enableGlobalFilter: false,
    maxSize: 1
  })
]
