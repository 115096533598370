import { getAnnuityPPLIAssets } from 'api/datahub'
import type { IOdataRequest } from 'api/odata.types'
import axios, { CancelTokenSource } from 'axios'
import type { IListsFilter } from 'features/Lists/core/contracts/IListsFilter'
import { convertColumnTypeToFilterType } from 'features/OdataList/common/service'
import { createOdataListWithFacetsStore } from 'features/OdataList/store/odataListWithFacetsStore'
import { keyBy } from 'lodash'
import type { IApiOptions } from 'shared/contracts/IApiOptions'
import { AppState, getRockefellerApiOptions } from 'store/shared'
import { call, cancelled } from 'typed-redux-saga'
import { annuityPPLIAssetsColumns } from '../../shared/annuityPPLIAssets/columnConfigOdataList'
import type { AnnuityPPLIAssetsColumnName } from '../../shared/annuityPPLIAssets/columnNameTypes'

export const defaultColumnsIds: AnnuityPPLIAssetsColumnName[] =
  annuityPPLIAssetsColumns.map((x) => x.name)

const getDatahubApiOptions = function* () {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)
  return [apiOptions, source] as [IApiOptions, CancelTokenSource]
}

export const fetchUnderlyingHoldings = function* (request: IOdataRequest) {
  const [apiOptions, cancelTokenSource] = yield* call(getDatahubApiOptions)
  try {
    return yield* call(getAnnuityPPLIAssets, apiOptions, request)
  } finally {
    if (yield* cancelled()) {
      cancelTokenSource.cancel()
    }
  }
}

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.annuitiesAndInsurance.modules.dashboard
    .features.holdings

const uiFilters = keyBy(
  annuityPPLIAssetsColumns
    .filter((x) => x.filterable)
    .map((column): IListsFilter => {
      const base = {
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        dataPath: column.dataPath,
        hasValue: false
      }

      return base
    }),
  ({ id }) => id
)

const selectedColumnIds = [...defaultColumnsIds].splice(0, 14)

const store = createOdataListWithFacetsStore({
  prefix:
    '@modules/@advisory/@modules/@annuitiesandinsurance/@modules/@dashboard/@features/@holdings',
  getOdataResults: fetchUnderlyingHoldings,
  initialState: {
    data: {},
    ui: {
      columns: annuityPPLIAssetsColumns,
      selectedColumns: selectedColumnIds,
      filters: uiFilters,
      sortBy: {
        direction: 'asc',
        name: 'Carrier'
      }
    },
    facets: {}
  },
  rootSelector
})

export const { actions, selectors, reducer, sagas } = store
