import { combineReducers, Reducer } from 'redux'
import {
  IOfacScreeningState,
  ofacScreeningReducer
} from '../features/Ofac/store/reducer'

export interface IComplianceModuleState {
  ofac: IOfacScreeningState
}

export const compliancereducer: Reducer<IComplianceModuleState> =
  combineReducers({
    ofac: ofacScreeningReducer
  })
