import { ITextField, ITextFieldProps, TextField } from '@fluentui/react'
import { flow } from 'lodash/fp'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  isNotNullOrEmpty,
  isNotNullOrFalse,
  isNotNullOrUndefined
} from '../guards'

const numberOnlyRegex = /[^-?\d*.?\d+$]/g

const formatAsParsableNumber = (notFormatted?: string) => {
  if (!isNotNullOrEmpty(notFormatted)) {
    return ''
  }

  const parseable = notFormatted.replace(numberOnlyRegex, '')
  const [first, ...rest] = parseable.split('.')
  return [first, !!rest?.length && rest.join('')]
    .filter(isNotNullOrFalse)
    .join('.')
}

const getCurrencyFormattedValue = (value?: string) => {
  const parseable = formatAsParsableNumber(value)
  if (!isNotNullOrEmpty(parseable)) {
    return ''
  }

  const [, fraction] = parseable.split('.')
  const endsWithPeriod = parseable.endsWith('.')

  return [
    parseFloat(parseable).toLocaleString(undefined, {
      minimumFractionDigits: fraction?.length || 0
    }),
    endsWithPeriod && '.'
  ]
    .filter(isNotNullOrFalse)
    .join('')
}

export const FormattedNumberField: React.FC<
  Omit<
    ITextFieldProps,
    'componentRef' | 'onChange' | 'value' | 'defaultValue'
  > & {
    onChange: (value?: number) => void
    value?: number
  }
> = (props) => {
  const componentRef = useRef<ITextField>(null)
  const { onChange: propsOnChange, value: propsValue, ...propsRest } = props
  const [internalValue, setInternalValue] = useState('')
  const [selection, setSelection] = useState<number>()
  const internalValueAsNumber = useMemo(
    () =>
      isNotNullOrEmpty(internalValue)
        ? flow(formatAsParsableNumber, parseFloat)(internalValue)
        : undefined,
    [internalValue]
  )

  useEffect(() => {
    if (!isNotNullOrUndefined(propsValue)) {
      setInternalValue('')
    }

    if (internalValueAsNumber === propsValue) {
      return
    }

    const formatted = getCurrencyFormattedValue(propsValue?.toString())
    setInternalValue(formatted)
  }, [internalValueAsNumber, propsValue])

  const onChange = useCallback(
    (_: any, newValue?: string) => {
      const { selectionStart } = componentRef.current || {}
      const formatted = getCurrencyFormattedValue(newValue)
      const parseable = formatAsParsableNumber(newValue)
      const parsed = isNotNullOrEmpty(parseable)
        ? parseFloat(parseable)
        : undefined
      const adjustment = formatted.length - (newValue?.length || 0)

      setSelection(
        isNotNullOrUndefined(selectionStart)
          ? Math.max(0, selectionStart + adjustment)
          : undefined
      )

      propsOnChange?.(parsed)
      setInternalValue(formatted)
    },
    [propsOnChange]
  )

  useEffect(() => {
    if (!isNotNullOrUndefined(selection)) {
      return
    }

    componentRef.current?.setSelectionRange(selection, selection)
  }, [selection, internalValue])

  return (
    <TextField
      {...propsRest}
      componentRef={componentRef}
      value={internalValue}
      onChange={onChange}
    />
  )
}
