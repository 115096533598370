import { combineReducers } from 'redux'
import {
  repSelectorFeatureReducer,
  repSelectorFeatureSagas
} from './RepSelector'

export const domainFeaturesReducer = combineReducers({
  repSelector: repSelectorFeatureReducer
})

export const domainFeaturesSagas = [...repSelectorFeatureSagas]
