import { IStyle, makeStyles, Text, TooltipHost } from '@fluentui/react'
import { endOfMonth, format, startOfMonth } from 'date-fns'
import { sum } from 'lodash'
import { constants } from 'modules/Advisory/shared/theme'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import tinycolor from 'tinycolor2'
import {
  IListsSearchFilter,
  IListsDateRangeFilter
} from '../../../../../../../../features/Lists/core/contracts/IListsFilter'
import { HorizontalScrollContainer } from '../../../../../../../../shared/components/HorizontalScrollContainer'
import { chartColors } from '../../../../../../../../shared/services/theme'
import { getGroupByType } from './store/payoutsDashboardUi'
import { payoutsListActions } from './store/payoutsList'
import {
  getPayoutDates,
  getPayoutRows,
  getPayoutTotals
} from './store/potentialPayouts'

const blueDarkHex = tinycolor(chartColors[1]).darken(25).toHex()

export const usePayoutsTableStyles = makeStyles((theme) => {
  const payoutTable: IStyle = {
    tableLayout: 'fixed',
    borderSpacing: '0',
    'th, td': {
      padding: '2px 8px',
      textAlign: 'right',
      width: '70px',
      minWidth: '70px'
    },
    'th:first-child, td:first-child': {
      textAlign: 'left',
      width: '250px',
      maxWidth: '250px'
    },
    th: {
      fontWeight: 'bolder',
      position: 'sticky',
      top: constants.headerHeightPx,
      backgroundColor: theme.semanticColors.bodyBackground
    },
    'td.total': {
      fontWeight: 'bolder'
    },
    'thead th': {
      paddingTop: '5px',
      paddingBottom: '5px',
      fontWeight: 'bold'
    },
    'tr:hover td': {
      backgroundColor: theme.palette.neutralLight
    },
    'tr:last-child': {
      fontWeight: 'bold'
    },
    'td.actionable:hover': {
      backgroundColor: `#${blueDarkHex}`,
      cursor: 'pointer',
      color: theme.palette.white,
      fontWeight: 'bold'
    }
  }

  return {
    payoutTable
  }
})

export const PayoutTable: React.FC = () => {
  const dispatch = useDispatch()
  const classes = usePayoutsTableStyles()
  const dates = useSelector(getPayoutDates)
  const payoutRows = useSelector(getPayoutRows)
  const payoutTotals = useSelector(getPayoutTotals)
  const groupByType = useSelector(getGroupByType)
  const onCellClicked = useCallback(
    (hurdleName?: string, hurdleDate?: Date) => {
      if (!hurdleDate || !hurdleName) {
        return
      }
      dispatch(payoutsListActions.resetFilters())
      dispatch(
        payoutsListActions.updateFilters(
          groupByType === 'hurdle'
            ? {
                'Hurdle Name': {
                  id: 'Hurdle Name',
                  type: 'search',
                  name: 'Hurdle Name',
                  hasValue: true,
                  displayValue: hurdleName,
                  filterType: 'eq',
                  value: hurdleName
                } as IListsSearchFilter,
                Date: {
                  id: 'Date',
                  name: 'Date',
                  type: 'date',
                  range: 'Custom',
                  from: startOfMonth(hurdleDate),
                  to: endOfMonth(hurdleDate),
                  hasValue: true
                } as IListsDateRangeFilter
              }
            : {
                'Team / Individual': {
                  id: 'Team / Individual',
                  type: 'search',
                  name: 'Team / Individual',
                  hasValue: true,
                  displayValue: hurdleName,
                  filterType: 'eq',
                  value: hurdleName
                } as IListsSearchFilter,
                Date: {
                  id: 'Date',
                  name: 'Date',
                  type: 'date',
                  range: 'Custom',
                  from: startOfMonth(hurdleDate),
                  to: endOfMonth(hurdleDate),
                  hasValue: true
                } as IListsDateRangeFilter
              }
        )
      )
    },
    [dispatch, groupByType]
  )
  const onMonthTotalCellClicked = useCallback(
    (hurdleDate: Date) => {
      dispatch(payoutsListActions.resetFilters())
      dispatch(
        payoutsListActions.updateFilters({
          Date: {
            id: 'Date',
            name: 'Date',
            type: 'date',
            range: 'Custom',
            from: startOfMonth(hurdleDate),
            to: endOfMonth(hurdleDate),
            hasValue: true
          } as IListsDateRangeFilter
        })
      )
    },
    [dispatch]
  )
  const onHurdleTotalCellClicked = useCallback(
    (hurdleName?: string) => {
      dispatch(payoutsListActions.resetFilters())
      dispatch(
        payoutsListActions.updateFilters(
          groupByType === 'hurdle'
            ? {
                'Hurdle Name': {
                  id: 'Hurdle Name',
                  type: 'search',
                  name: 'Hurdle Name',
                  hasValue: true,
                  displayValue: hurdleName,
                  filterType: 'eq',
                  value: hurdleName
                } as IListsSearchFilter
              }
            : {
                'Team / Individual': {
                  id: 'Team / Individual',
                  type: 'search',
                  name: 'Team / Individual',
                  hasValue: true,
                  displayValue: hurdleName,
                  filterType: 'eq',
                  value: hurdleName
                } as IListsSearchFilter
              }
        )
      )
    },
    [dispatch, groupByType]
  )
  const onTotalClicked = useCallback(() => {
    if (!dates.length) {
      return
    }
    dispatch(payoutsListActions.resetFilters())
    dispatch(
      payoutsListActions.updateFilters({
        Date: {
          id: 'Date',
          name: 'Date',
          type: 'date',
          range: 'Custom',
          from: startOfMonth(dates[0]),
          to: endOfMonth(dates[dates.length - 1]),
          hasValue: true
        } as IListsDateRangeFilter
      })
    )
  }, [dates, dispatch])

  return (
    <HorizontalScrollContainer>
      <div>
        <table className={classes.payoutTable}>
          <thead>
            <tr>
              <th />
              <th>Total</th>
              {dates.map((date, index) => (
                <th key={index}>{format(date, `MMM ''yy`)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {payoutRows?.map((payoutRow, index) => (
              <tr key={index}>
                <td>
                  <TooltipHost
                    content={payoutRow.group}
                    calloutProps={{ isBeakVisible: false }}
                  >
                    <Text nowrap={true} block={true}>
                      {payoutRow.group}
                    </Text>
                  </TooltipHost>
                </td>
                <td
                  className="total actionable"
                  onClick={() => onHurdleTotalCellClicked(payoutRow.group)}
                >
                  $
                  {Intl.NumberFormat('en-US', {
                    notation: 'compact',
                    maximumFractionDigits: 1
                  }).format(sum(payoutRow.amounts) || 0)}
                </td>
                {payoutRow.amounts.map((amount, index) => (
                  <td
                    key={index}
                    onClick={() =>
                      amount && onCellClicked(payoutRow.group, dates[index])
                    }
                    className={amount ? 'actionable' : ''}
                  >
                    {amount
                      ? `$${Intl.NumberFormat('en-US', {
                          notation: 'compact',
                          maximumFractionDigits: 1
                        }).format(amount || 0)}`
                      : '--'}
                  </td>
                ))}
              </tr>
            ))}
            <tr>
              <td>Total</td>
              <td onClick={onTotalClicked} className={'actionable'}>
                $
                {Intl.NumberFormat('en-US', {
                  notation: 'compact',
                  maximumFractionDigits: 1
                }).format(sum(payoutTotals))}
              </td>
              {payoutTotals.map((total, index) => (
                <td
                  key={index}
                  className="actionable"
                  onClick={() => onMonthTotalCellClicked(dates[index])}
                >
                  $
                  {Intl.NumberFormat('en-US', {
                    notation: 'compact',
                    maximumFractionDigits: 1
                  }).format(total)}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </HorizontalScrollContainer>
  )
}
