import { Row } from '@tanstack/react-table'
import { IAnnuityPPLIContract } from 'api/datahub'
import { mapValues, values } from 'lodash'

export const getGlobalFilterFn = (searchableColumnIds: string[]) => {
  const globalFilterFn = (
    row: Row<IAnnuityPPLIContract>,
    _columnId: string,
    filterValue: string[]
  ) => {
    const visibleColumnIds = mapValues(row.getVisibleCells(), 'column.id')
    const searchableValues = searchableColumnIds
      .filter((colId) => values(visibleColumnIds).includes(colId))
      .map((colId) => row.getValue<string>(colId)?.toLowerCase())

    return filterValue.every((x) =>
      searchableValues.find((item) => item?.includes(x))
    )
  }

  return globalFilterFn
}
