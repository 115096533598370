import highchartsDefaultOptions from '../services/charts'

export type HighchartsImport = typeof import('highcharts')
let highchartsPromise: Promise<HighchartsImport> | undefined
let highcharts: HighchartsImport | undefined

const loadHighcharts = async () => {
  highcharts = await import('highcharts')
  highcharts.setOptions(highchartsDefaultOptions)
  return highcharts
}

let organizationPromise: Promise<void> | undefined
const loadOrganization = async (module: HighchartsImport) => {
  const [sankey, org] = await Promise.all([
    import('highcharts/modules/sankey'),
    import('highcharts/modules/organization')
  ])

  sankey.default(module)
  org.default(module)
}

export const getHighcharts = async () => {
  if (!highchartsPromise) {
    highchartsPromise = loadHighcharts()
  }

  return highchartsPromise
}

export const ensureOrganizationModule = (module: HighchartsImport) => {
  if (!organizationPromise) {
    organizationPromise = loadOrganization(module)
  }

  return organizationPromise
}

export { highcharts }
