import { partial } from 'lodash/fp'
import { createListsComponents } from '../core/containers/service'
import { IUseListHookDefinition } from '../hooks/useList/hookDefinitions'
import { createFilter } from '../shared/lists'
import { ClientCell } from './ClientCell'
import { ClientColumnDefinitions } from './ColumnDefinitions'
import { actions, selectors } from './store'

export * from './store'

export const createClientListFilter = partial(createFilter, [
  ClientColumnDefinitions
])

export const ClientListComponents = createListsComponents(
  actions,
  selectors,
  ClientCell
)

export const clientListHookDefinition: IUseListHookDefinition = {
  actions,
  selectors,
  columnDefinitions: ClientColumnDefinitions,
  listType: 'client'
}
