import { combineReducers } from 'redux'
import {
  revenueDashboardFeaturesReducer,
  revenueDashboardFeaturesSagas
} from '../features'
import {
  revenueDashboardReducer as dashboard,
  revenueDashboardSharedSagas
} from './dashboard'

export const revenueDashboardReducer = combineReducers({
  features: revenueDashboardFeaturesReducer,
  dashboard
})

export const revenueDashboardSagas = [
  ...revenueDashboardFeaturesSagas,
  ...revenueDashboardSharedSagas
]
