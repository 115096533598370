import {
  differenceWith,
  flow,
  intersectionWith,
  keyBy,
  orderBy,
  uniqBy
} from 'lodash'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { ISystemUser } from '../../../../../api/dynamics'
import {
  isNotNullOrEmpty,
  isNotNullOrUndefined
} from '../../../../../shared/guards'
import { AppState } from '../../../../../store'
import {
  selectAccountRepsWithSplitVisibility,
  selectValidDomainReps
} from '../../../store/domain'
import {
  getAccountRepTreeFilteredSelectedReps,
  getUserHasAccessToAllAccountRepTreeSelectedAccountRepSplits
} from './accountRepTree'
import { IAdvisoryAccountRep, mapToContextBusinessUnit } from './service'

const SET_SELECTED =
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@advisorRepTree/SET_SELECTED'
const SET_COLLAPSED =
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@advisorRepTree/SET_COLLAPSED'
const SET_FILTER =
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@advisorRepTree/SET_FILTER'
const RESET =
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@advisorRepTree/RESET'
const TOGGLE_SELECTED_ONLY =
  '@modules/@advisory/@modules/@revenue/@modules/@dashboard/@features/@advisorRepTree/TOGGLE_SELECTED_ONLY'

export const advisorRepTreeActions = {
  setSelected: createAction(SET_SELECTED)<string[] | undefined>(),
  setCollapsed: createAction(SET_COLLAPSED)<string[] | undefined>(),
  setFilter: createAction(SET_FILTER)<string | undefined>(),
  toggleSelectedOnly: createAction(TOGGLE_SELECTED_ONLY)(),
  reset: createAction(RESET)()
}

export interface IAdvisorRepTreeState {
  selected?: string[]
  collapsed?: string[]
  filter?: string
  showSelectedOnly?: boolean
}

const initialState: IAdvisorRepTreeState = {}

export const advisorRepTreeReducer = createReducer<
  IAdvisorRepTreeState,
  ActionType<typeof advisorRepTreeActions>
>(initialState)
  .handleAction(advisorRepTreeActions.setSelected, (state, action) => ({
    ...state,
    selected: action.payload
  }))
  .handleAction(advisorRepTreeActions.setCollapsed, (state, action) => ({
    ...state,
    collapsed: action.payload
  }))
  .handleAction(advisorRepTreeActions.setFilter, (state, action) => ({
    ...state,
    filter: action.payload
  }))
  .handleAction(advisorRepTreeActions.toggleSelectedOnly, (state) => ({
    ...state,
    showSelectedOnly: !state.showSelectedOnly
  }))
  .handleAction(advisorRepTreeActions.reset, () => ({
    ...initialState
  }))

const rootSelector = (state: AppState) =>
  state.features.domain.features.repSelector.advisorRepTree

export const getAdvisorRepTreeSelectedReps = flow(
  rootSelector,
  ({ selected }) => selected
)

export const getAdvisorRepTreeCollapsedNodes = flow(
  rootSelector,
  ({ collapsed }) => collapsed
)

export const getAdvisorRepTreeFilter = flow(
  rootSelector,
  ({ filter }) => filter
)

export const getAdvisorRepTreeShowSelectedOnly = flow(
  rootSelector,
  ({ showSelectedOnly }) => showSelectedOnly
)

export interface IAdvisoryAdvisorRep extends IAdvisoryAccountRep {
  owner?: ISystemUser
}

const getAdvisoryAdvisorReps = createSelector(
  [selectValidDomainReps],
  (domain) => {
    return domain
      ?.filter(({ rcm_PersonalRepFor }) => rcm_PersonalRepFor)
      .map(
        ({
          rcm_name,
          rcm_repid,
          rcm_OwningTeam,
          rcm_PersonalRepFor
        }): IAdvisoryAdvisorRep => ({
          id: rcm_repid,
          name: rcm_name,
          owner: rcm_PersonalRepFor,
          team: mapToContextBusinessUnit(rcm_OwningTeam?.businessunitid),
          office: mapToContextBusinessUnit(
            rcm_OwningTeam?.businessunitid?.parentbusinessunitid
          ),
          division: mapToContextBusinessUnit(
            rcm_OwningTeam?.businessunitid?.parentbusinessunitid
              ?.parentbusinessunitid
          )
        })
      )
  }
)

const getOrderedAdvisorReps = createSelector([getAdvisoryAdvisorReps], (reps) =>
  orderBy(reps, ({ id }) => id)
)

const getApplicableAdvisorRepsBasedOnAccountRepSelection = createSelector(
  [getAccountRepTreeFilteredSelectedReps, selectAccountRepsWithSplitVisibility],
  (selected, splits) => {
    const selectedRepSplitVisibility = intersectionWith(
      splits,
      selected || [],
      (a, b) => a.rep.rcm_repid === b.id
    )

    const splitReps = selectedRepSplitVisibility.flatMap(({ splits }) => splits)
    const houseRep = splitReps.find((x) => x?.id === '002')

    return uniqBy(
      [...splitReps, houseRep && { ...houseRep, id: '000' }].filter(
        isNotNullOrUndefined
      ),
      ({ id }) => id
    )
  }
)

const getOtherNonVisibleAdvisorReps = createSelector(
  [getOrderedAdvisorReps, getApplicableAdvisorRepsBasedOnAccountRepSelection],
  (advisorReps, splitReps) => {
    return differenceWith(splitReps, advisorReps, (a, b) => a.id === b.id).map(
      ({ id, name }): IAdvisoryAdvisorRep => ({
        id,
        name
      })
    )
  }
)

const getAdvisorRepTreeSelectedAdvisorRepLookup = createSelector(
  [getAdvisorRepTreeSelectedReps],
  (seletedAdvisorReps) => keyBy(seletedAdvisorReps)
)

const getFilteredAdvisorReps = createSelector(
  [
    getAdvisorRepTreeFilter,
    getOrderedAdvisorReps,
    getOtherNonVisibleAdvisorReps,
    getApplicableAdvisorRepsBasedOnAccountRepSelection,
    getAdvisorRepTreeSelectedAdvisorRepLookup,
    getAdvisorRepTreeShowSelectedOnly
  ],
  (
    searchText,
    orderedAdvisorReps,
    otherAdvisorReps,
    applicableReps,
    selectedLookup,
    showSelectedOnly
  ) => {
    const applicableOrderedReps = intersectionWith(
      orderedAdvisorReps,
      applicableReps,
      (a, b) => a.id === b.id
    )

    const applicableAndOtherAdvisorReps = [
      ...applicableOrderedReps,
      ...otherAdvisorReps
    ]

    const lowerCaseSearchText = searchText?.toLowerCase()
    return applicableAndOtherAdvisorReps.filter(
      ({ id, name, team, office, division }) =>
        (!showSelectedOnly || selectedLookup[id]) &&
        (!lowerCaseSearchText ||
          (lowerCaseSearchText &&
            [id, name, team?.name, office?.name, division?.name]
              .filter(isNotNullOrEmpty)
              .some((x) => x.toLowerCase().indexOf(lowerCaseSearchText) >= 0)))
    )
  }
)

const getFilteredBusinessUnits = createSelector(
  [getFilteredAdvisorReps],
  (filteredContextReps) => {
    const filteredBus = orderBy(
      uniqBy(
        filteredContextReps?.flatMap(({ team, office, division }) =>
          [team, office, division].filter(isNotNullOrUndefined)
        ),
        ({ id }) => id
      ),
      ({ name }) => name
    )
    return filteredBus
  }
)

export const getAreAllAdvisorRepsSelected = createSelector(
  [getFilteredAdvisorReps, getAdvisorRepTreeSelectedAdvisorRepLookup],
  (filteredAdvisorReps, selectedAdvisorRepsLookup) =>
    !!filteredAdvisorReps.length &&
    filteredAdvisorReps.every(({ id }) => selectedAdvisorRepsLookup[id])
)

export const useAdvisorRepTreeStore = () => {
  const selected = useSelector(getAdvisorRepTreeSelectedReps)
  const collapsed = useSelector(getAdvisorRepTreeCollapsedNodes)
  const filter = useSelector(getAdvisorRepTreeFilter)
  const filteredBusinessUnits = useSelector(getFilteredBusinessUnits)
  const filteredAdvisorReps = useSelector(getFilteredAdvisorReps)
  const selectedLookup = useSelector(getAdvisorRepTreeSelectedAdvisorRepLookup)
  const enableSelection = useSelector(
    getUserHasAccessToAllAccountRepTreeSelectedAccountRepSplits
  )
  const showSelectedOnly = useSelector(getAdvisorRepTreeShowSelectedOnly)
  const areAllAdvisorRepsSelected = useSelector(getAreAllAdvisorRepsSelected)

  const dispatch = useDispatch()

  const updateSelected = useCallback(
    (selected?: string[]) => {
      dispatch(advisorRepTreeActions.setSelected(selected))
    },
    [dispatch]
  )

  const updateCollapsed = useCallback(
    (collapsed?: string[]) => {
      dispatch(advisorRepTreeActions.setCollapsed(collapsed))
    },
    [dispatch]
  )

  const updateFilter = useCallback(
    (text?: string) => {
      dispatch(advisorRepTreeActions.setFilter(text))
    },
    [dispatch]
  )

  const selectAllVisible = useCallback(
    () => updateSelected(filteredAdvisorReps.map(({ id }) => id)),
    [filteredAdvisorReps, updateSelected]
  )

  const clearAllSelected = useCallback(
    () => updateSelected([]),
    [updateSelected]
  )

  const collapseAllVisible = useCallback(
    () =>
      updateCollapsed([
        ...(filteredBusinessUnits?.map(({ id }) => id) || []),
        'OTHER'
      ]),
    [filteredBusinessUnits, updateCollapsed]
  )
  const expandAll = useCallback(() => updateCollapsed([]), [updateCollapsed])

  const toggleShowSelectedOnly = useCallback(() => {
    dispatch(advisorRepTreeActions.toggleSelectedOnly())
  }, [dispatch])

  return {
    selected,
    selectedLookup,
    areAllAdvisorRepsSelected,
    updateSelected,
    selectAllVisible,
    clearAllSelected,
    collapsed,
    updateCollapsed,
    collapseAllVisible,
    expandAll,
    filter,
    updateFilter,
    filteredBusinessUnits,
    filteredAdvisorReps,
    enableSelection,
    showSelectedOnly,
    toggleShowSelectedOnly
  }
}
