import { ISystemViewDefinition } from 'features/Lists/hooks/useListPreferences'
import { CreditEvent, CreditEventListTypesEnum } from '../../types'
import { creditEventSystemViews } from './creditEvent'

export const systemViewsMap: Record<
  CreditEventListTypesEnum,
  ISystemViewDefinition[]
> = {
  [CreditEvent]: creditEventSystemViews
}
