import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

const { actions, reducer } = createSlice({
  name: 'notesPanel',
  initialState: {} as INotesPanelState,
  reducers: {
    partyId: (state, action: PayloadAction<string | undefined>) => ({
      partyId: action.payload
    })
  }
})

export { reducer as notesPanelReducer }

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.alerts.notes

const getPartyId = flow(rootSelector, (x) => x.partyId)

export interface INotesPanelState {
  partyId?: string
}

export const useAlertsNotesPanel = () => {
  const dispatch = useDispatch()

  const setPartyId = useCallback(
    (newPartyId: string) => {
      dispatch(actions.partyId(newPartyId))
    },
    [dispatch]
  )

  const partyId = useSelector(getPartyId)

  return {
    partyId,
    setPartyId
  }
}
