import { orderBy, sumBy } from 'lodash'
import { currentYear } from 'modules/Advisory/modules/Rdot360/components/shared/filterOptions'
import {
  IncomeTableDataMonth,
  convertIncomeApiResponseToMonthArrays,
  getHistoricalIncomeDates,
  getProjectedIncomeDates,
  useIncomeFeatureStore
} from 'modules/Advisory/modules/Rdot360/features/Income'
import { useGetIncomeDetailBySecurityQueryForSelectedAccounts } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { useMemo } from 'react'
import { isNotNullOrEmpty } from 'shared/guards'
import { useIncomeDetailsUiState } from '../../store'

export const useIncomeSummaryContext = () => {
  const { incomeMode, financialYear, includeOptionPremiums } =
    useIncomeFeatureStore()

  const { data, isFetching, error, refetch } =
    useGetIncomeDetailBySecurityQueryForSelectedAccounts(
      incomeMode,
      financialYear
    )

  const monthlyData = useMemo(
    () => convertIncomeApiResponseToMonthArrays(data),
    [data]
  )
  const { searchText } = useIncomeDetailsUiState()

  const summaryData = useMemo(() => {
    if (monthlyData) {
      const search = searchText.toLowerCase()

      const getTotal = (item: IncomeTableDataMonth) =>
        (includeOptionPremiums || incomeMode === 'projected'
          ? item.total
          : item.totalWithoutOptions) || 0

      const projectedIncomeDetailSummaryTableData = monthlyData
        .filter(
          (item) =>
            (item.SECDESCRIPTION || '').toLocaleLowerCase().includes(search) ||
            (item.SECNAME || '').toLocaleLowerCase().includes(search)
        )
        .map((item) => {
          const [Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec] =
            orderBy(
              item?.months?.map((x) => ({
                month: x?.date ? new Date(x.date).getMonth() : undefined,
                total: getTotal(x)
              })),
              (x) => x.month
            )?.map((x) => x.total) || []
          return {
            Security: item.SECDESCRIPTION,
            SECNAME: item.SECNAME,
            Jan,
            Feb,
            Mar,
            Apr,
            May,
            Jun,
            Jul,
            Aug,
            Sep,
            Oct,
            Nov,
            Dec,
            Total: sumBy(item.months, getTotal)
          }
        })

      projectedIncomeDetailSummaryTableData.push({
        Security: 'Total',
        SECNAME: '',
        Jan: sumBy(projectedIncomeDetailSummaryTableData, ({ Jan }) => Jan),
        Feb: sumBy(projectedIncomeDetailSummaryTableData, ({ Feb }) => Feb),
        Mar: sumBy(projectedIncomeDetailSummaryTableData, ({ Mar }) => Mar),
        Apr: sumBy(projectedIncomeDetailSummaryTableData, ({ Apr }) => Apr),
        May: sumBy(projectedIncomeDetailSummaryTableData, ({ May }) => May),
        Jun: sumBy(projectedIncomeDetailSummaryTableData, ({ Jun }) => Jun),
        Jul: sumBy(projectedIncomeDetailSummaryTableData, ({ Jul }) => Jul),
        Aug: sumBy(projectedIncomeDetailSummaryTableData, ({ Aug }) => Aug),
        Sep: sumBy(projectedIncomeDetailSummaryTableData, ({ Sep }) => Sep),
        Oct: sumBy(projectedIncomeDetailSummaryTableData, ({ Oct }) => Oct),
        Nov: sumBy(projectedIncomeDetailSummaryTableData, ({ Nov }) => Nov),
        Dec: sumBy(projectedIncomeDetailSummaryTableData, ({ Dec }) => Dec),
        Total: sumBy(
          projectedIncomeDetailSummaryTableData,
          ({ Total }) => Total
        )
      })

      return projectedIncomeDetailSummaryTableData
    } else {
      return []
    }
  }, [includeOptionPremiums, incomeMode, monthlyData, searchText])

  const dates = useMemo(() => {
    if (incomeMode === 'historical') {
      return getHistoricalIncomeDates(
        financialYear,
        financialYear === currentYear
      )
    }

    const results = orderBy(
      monthlyData?.[0]?.months
        ?.map(({ date }) => date)
        ?.filter(isNotNullOrEmpty)
        .map((x) => new Date(x)),
      (x) => x,
      'asc'
    )

    return results?.length === 12
      ? results
      : getProjectedIncomeDates(financialYear === 1)
  }, [financialYear, incomeMode, monthlyData])

  return {
    summaryData,
    dates,
    isFetching,
    error,
    refetch
  }
}
