import { DragEndEvent } from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy
} from '@dnd-kit/sortable'
import { SerializedStyles, css } from '@emotion/react'
import { difference, intersection, keyBy } from 'lodash'
import { advisoryModuleStyles } from 'modules/Advisory/shared/styles'
import { memo, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMount } from 'react-use'
import { useRdotUser } from 'store/user/useRdotUser'
import { BasicDndContext } from '../../../../../../../shared/components/BasicDndContext'
import AssetsAllocation from '../../../components/AssetsAllocation/AssetsAllocation'
import ComingSoon from '../../../components/ComingSoon/ComingSoon'
import GainLossesIncome from '../../../components/GainLossesIncome/GainLossesIncome'
import Holdings from '../../../components/Holdings'
import { BaseHeaderProps } from '../../../components/TileHeading'
import { useClientDashboardCommonPreferences } from '../../../hooks/useClientDashboardPreferences'
import AccountActivity from '../Activity/AccountActivity'
import BalanceSummary from '../BalanceSummary'
import { HistoricalValueWidgetContainer } from '../HistoricalValue'
import { IncomeWidgetContainer } from '../Income'
import { Insights } from '../Insights/Insights'
import Performance from '../Performance/Performance'
import { Revenue } from '../Revenue/Revenue'
import { SortableItem } from './SortableItem'

const classes = {
  container: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(476px, 1fr))',
    gap: '10px'
  }),
  tileContainer: css({
    position: 'relative',
    backgroundColor: '#fff',
    height: '375px',
    overflow: 'hidden',
    ['&:hover']: {
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)'
    }
  }),
  tileContainerNew: css({
    backgroundColor: '#F3F1EC',
    borderTop: 'solid 4px #B49D77',
    color: '#3A3A3A'
  }),
  tile: css({
    overflow: 'auto',
    width: '100%',
    height: '100%',
    padding: 8,
    paddingTop: 15
  })
}

interface ITileDefinition {
  id: string
  isCobSupported?: boolean
  component: React.FC<BaseHeaderProps>
  detailPath: string
  disableExpand?: boolean
  css?: SerializedStyles
}

const getTiles = (
  isInsightsPilotUser?: boolean,
  isRevenuePilotUser?: boolean
) => {
  const tiles: ITileDefinition[] = [
    {
      id: 'Balances',
      isCobSupported: true,
      component: BalanceSummary,
      detailPath: 'balances'
    },
    {
      id: 'Investments',
      isCobSupported: true,
      component: Holdings,
      detailPath: 'investments'
    },
    {
      id: 'Asset Allocation',
      isCobSupported: true,
      component: AssetsAllocation,
      detailPath: 'investments'
    },
    {
      id: 'Activity',
      isCobSupported: true,
      component: AccountActivity,
      detailPath: 'activity'
    },
    {
      id: 'Income',
      component: IncomeWidgetContainer,
      detailPath: 'income'
    },
    {
      id: 'Gains & Losses',
      component: GainLossesIncome,
      detailPath: 'gains-and-losses'
    },
    {
      id: 'Performance',
      component: Performance,
      detailPath: 'performance'
    },
    {
      id: 'Revenue',
      component: isRevenuePilotUser
        ? Revenue
        : () => <ComingSoon title={'Revenue'} />,
      detailPath: 'revenue',
      disableExpand: !isRevenuePilotUser
    },
    {
      id: 'Loans',
      component: () => <ComingSoon title={'Loans'} />,
      detailPath: 'loans',
      disableExpand: true
    },
    {
      id: 'Historical Value',
      component: HistoricalValueWidgetContainer,
      detailPath: 'balances'
    }
  ]

  if (isInsightsPilotUser) {
    const insightsTile = {
      id: 'Insights',
      component: Insights,
      detailPath: 'insights',
      css: classes.tileContainerNew
    }
    tiles.unshift(insightsTile)
  }
  return tiles
}

export const Rdot360TileView: React.FC = memo(() => {
  const navigate = useNavigate()
  const {
    isClientDashboardInsightsPilotUser,
    isClientDashboardRevenuePilotUser
  } = useRdotUser()
  const [tileItems, setTileItems] = useState<ITileDefinition[]>([])

  const {
    preferences: tileOrderingPreference,
    setPreferences: setTileOrderingPreference
  } = useClientDashboardCommonPreferences()

  useMount(() => {
    const tileItemsPreferenceIds = tileOrderingPreference?.tilesOrder || []
    const tileItemsDefault = getTiles(
      isClientDashboardInsightsPilotUser,
      isClientDashboardRevenuePilotUser
    )
    const tileItemsDefaultIds = tileItemsDefault.map((tile) => tile.id)
    const tileLookupById = keyBy(tileItemsDefault, ({ id }) => id)

    const validPreferenceTileIds = intersection(
      tileItemsPreferenceIds,
      tileItemsDefaultIds
    )

    const missingTileIds = difference(
      tileItemsDefaultIds,
      validPreferenceTileIds
    )

    const orderedTileItems = [...validPreferenceTileIds, ...missingTileIds].map(
      (id) => tileLookupById[id]
    )

    setTileItems(orderedTileItems)
  })

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event

      if (!over) {
        return
      }

      if (active.id !== over?.id) {
        if (!tileItems) {
          return
        }

        const oldIndex = tileItems.findIndex(({ id }) => id === active.id)
        const newIndex = tileItems.findIndex(({ id }) => id === over.id)

        const newTileItems = arrayMove([...tileItems], oldIndex, newIndex)

        setTileItems(newTileItems)
        setTileOrderingPreference({
          tilesOrder: newTileItems.map((tile) => tile.id)
        })
      }
    },
    [setTileOrderingPreference, tileItems]
  )

  return (
    <div css={classes.container}>
      <BasicDndContext
        onDragEnd={handleDragEnd}
        modifiers={[restrictToParentElement]}
      >
        <SortableContext items={tileItems} strategy={rectSortingStrategy}>
          {tileItems?.map((tile) => (
            <SortableItem
              key={tile.id}
              id={tile.id}
              css={[classes.tileContainer, tile.css]}
            >
              <div css={[classes.tile, advisoryModuleStyles.fancyScroll]}>
                <tile.component
                  isCobSupported={tile.isCobSupported}
                  disableExpand={tile.disableExpand}
                  onExpand={() => navigate(tile.detailPath)}
                />
              </div>
            </SortableItem>
          ))}
        </SortableContext>
      </BasicDndContext>
    </div>
  )
})
