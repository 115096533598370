import { CancelToken } from 'axios'
import { call, select } from 'typed-redux-saga'
import { IApiOptions } from '../../../shared/contracts/IApiOptions'
import * as auth from '../../../shared/services/auth'
import {
  getDynamicsCrmApiConfig,
  getMicrosoftGraphApiConfig,
  getRockefellerApiConfig
} from '../../system'

export const tryAcquireAccessToken = function* (
  scopes: string[] | string,
  destinationAfterRedirect?: string,
  loginHint?: string,
  fallbackLoginType: auth.LoginTypes = 'popup'
) {
  return yield* call(
    auth.tryAcquireAccessToken,
    scopes,
    destinationAfterRedirect,
    loginHint,
    fallbackLoginType
  )
}

export function* getMicrosoftGraphApiOptions(cancelToken?: CancelToken) {
  const microsoftGraphApiConfig = yield* select(getMicrosoftGraphApiConfig)

  if (!microsoftGraphApiConfig) {
    throw new Error('Invalid state: Microsoft Graph api config is undefined')
  }

  const token: string = yield* call(
    tryAcquireAccessToken,
    microsoftGraphApiConfig.scopes
  )

  const options: IApiOptions = {
    apiRoot: microsoftGraphApiConfig.root,
    accessToken: token,
    cancelToken: cancelToken
  }

  return options
}

export function* getRockefellerApiOptions(cancelToken?: CancelToken) {
  const rockefellerApiConfig = yield* select(getRockefellerApiConfig)

  if (!rockefellerApiConfig) {
    throw new Error('Invalid state: Rockefeller api config is undefined')
  }

  const token: string = yield* call(
    tryAcquireAccessToken,
    rockefellerApiConfig.scopes
  )

  const options: IApiOptions = {
    apiRoot: rockefellerApiConfig.root,
    accessToken: token,
    cancelToken: cancelToken
  }

  return options
}

export function* getDynamicsApiOptions(cancelToken?: CancelToken) {
  const dynamicsApiConfig = yield* select(getDynamicsCrmApiConfig)

  if (!dynamicsApiConfig) {
    throw new Error('Invalid state: Dynamics 365 api config is undefined')
  }

  const token: string = yield* call(
    tryAcquireAccessToken,
    dynamicsApiConfig.scopes
  )

  const options: IApiOptions = {
    apiRoot: dynamicsApiConfig.root,
    accessToken: token,
    cancelToken: cancelToken
  }

  return options
}
