import { flow } from 'lodash'
import { createSelector } from 'reselect'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { getMarginRateAdjustmentFeatureState } from './shared'

const SET_MARGIN_RATE =
  '@features/@MarginRateAdjustment/@marginRateEmailContents/SET_MARGIN_RATE'
const SET_JUSTIFICATION =
  '@features/@MarginRateAdjustment/@marginRateEmailContents/SET_JUSTIFICATION'

export const marginRateEmailActions = {
  setMarginRate: createAction(SET_MARGIN_RATE)<number | undefined>(),
  setJustification: createAction(SET_JUSTIFICATION)<string | undefined>()
}

export type marginRateEmailActionTypes = ActionType<
  typeof marginRateEmailActions
>

export interface IMarginRateEmailState {
  marginRate?: number
  justification?: string
}

const initialState: IMarginRateEmailState = {
  marginRate: undefined
}

export const marginRateEmailReducer = createReducer<
  IMarginRateEmailState,
  marginRateEmailActionTypes
>(initialState)
  .handleAction(marginRateEmailActions.setMarginRate, (state, action) => ({
    ...state,
    marginRate: action.payload
  }))
  .handleAction(marginRateEmailActions.setJustification, (state, action) => ({
    ...state,
    justification: action.payload
  }))

const rootSelector = flow(
  getMarginRateAdjustmentFeatureState,
  (x) => x?.marginRateEmailContent
)

export const getMarginRateEmailContent = flow(
  rootSelector,
  (x) =>
    x && {
      rate: x?.marginRate,
      justification: x?.justification
    }
)

export const getRateJustification = flow(rootSelector, (x) => x?.justification)

export const getMarginRate = flow(rootSelector, (x) => x?.marginRate)

export const isInvalidMarginRate = createSelector(
  [getMarginRate],
  (rate) => !rate || isNaN(rate as number)
)
