import { Link, Stack, Text } from '@fluentui/react'
import { useRdot360Context } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IHousehold } from '../../api/household.types'
import { householdDetailUiActions } from './features/HouseholdDetail'
import { HouseholdMenuContainer } from './HouseholdMenuContainer'

export const HouseholdNameCell: React.FC<{ household: IHousehold }> = ({
  household
}) => {
  const dispatch = useDispatch()
  const numClients = household?.Parties?.length || 0
  const numAccounts = household?.Account?.length || 0
  const { setHousehold } = useRdot360Context()
  const navigate = useNavigate()
  const navigateToRdot360 = useCallback(
    (householdId?: string) => {
      if (!householdId) {
        return
      }

      const isRockHousehold = householdId?.startsWith('ROCK')
      if (isRockHousehold) {
        setHousehold({ householdId })
        navigate('/advisory/client')
      } else {
        dispatch(householdDetailUiActions.openPanel(householdId))
      }
    },
    [dispatch, navigate, setHousehold]
  )
  return (
    <Stack
      horizontal={true}
      tokens={{ childrenGap: 5 }}
      styles={{ root: { minWidth: 0 } }}
    >
      <HouseholdMenuContainer household={household} />
      <Stack styles={{ root: { minWidth: 0 } }}>
        <Link
          styles={{ root: { minWidth: 0 } }}
          onClick={() => navigateToRdot360(household.householdId)}
        >
          <Text nowrap={true} block={true} title={household?.householdName}>
            {household?.householdName || '(No Name)'}
          </Text>
        </Link>
        <Stack
          horizontal={true}
          tokens={{ childrenGap: 3 }}
          verticalAlign="center"
        >
          <Text variant="small" nowrap={true} block={true}>
            {numClients} client{numClients !== 1 && <>s</>}
          </Text>
          <Text variant="small" nowrap={true} block={true}>
            |
          </Text>
          <Text variant="small" nowrap={true} block={true}>
            {numAccounts} account{numAccounts !== 1 && <>s</>}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  )
}
