import { IIconProps, IconButton } from '@fluentui/react'
import { Row, flexRender } from '@tanstack/react-table'
import { isNotNullOrFalse } from 'shared/guards'
import { AnnuityPPLICombined } from '../../shared/types/annuityPPLICombined'

export const TableRow: React.FC<{
  row: Row<AnnuityPPLICombined>
  groupingLength: number
}> = ({ row, groupingLength }) => {
  const visibleSubCells = row.getVisibleCells()
  const isRowGrouped = row.getIsGrouped()
  const parentRow = row.getParentRow()
  const isExpanded = row.getIsExpanded()
  const parentRowId = parentRow?.id

  const iconProps: IIconProps = {
    iconName: isExpanded ? 'CaretSolidDown' : 'CaretSolidRight',
    styles: {
      root: {
        opacity: '.75',
        height: 'auto',
        lineHeight: '1em',
        margin: '0'
      }
    }
  }

  return (
    <tr
      key={row.id}
      className={['row', isRowGrouped && !parentRowId && 'group-row']
        .filter(isNotNullOrFalse)
        .join(' ')}
    >
      {groupingLength > 0 && (
        <td key="expand">
          {isRowGrouped && !parentRow?.id && (
            <IconButton
              iconProps={iconProps}
              title="Expand / Collapse"
              ariaLabel="Expand / Collapse"
              onClick={row.getToggleExpandedHandler()}
              styles={{
                root: {
                  display: 'block',
                  width: '16px',
                  height: '16px',
                  borderRadius: 0,
                  padding: 0
                },
                rootHovered: { backgroundColor: 'transparent' },
                rootPressed: { backgroundColor: 'transparent' }
              }}
            />
          )}
        </td>
      )}
      {visibleSubCells.map((cell) => {
        const isPlaceholder = cell.getIsPlaceholder()
        const isGrouped = cell.getIsGrouped()
        const isMultipleGrouping = groupingLength > 1
        const isAggregated = cell.getIsAggregated()

        if (isPlaceholder && isMultipleGrouping) {
          return null
        }

        const {
          column: {
            columnDef: {
              cell: regularCell,
              aggregatedCell,
              meta: { className: metaClassName, aggregatedClassName } = {}
            }
          }
        } = cell

        const cellToRender =
          isAggregated && aggregatedCell ? aggregatedCell : regularCell

        const className =
          isAggregated && aggregatedClassName
            ? aggregatedClassName
            : metaClassName

        return (
          <td key={cell.id} className={className}>
            {flexRender(cellToRender, cell.getContext())}
            {isGrouped && row.subRows.length > 1 && (
              <span
                style={{ opacity: '.5' }}
                title={`Total count - ${row.subRows.length}`}
              >{` (${row.subRows.length})`}</span>
            )}
          </td>
        )
      })}
    </tr>
  )
}
