import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { IGroupTask } from '../api/dynamics'

const { actions, reducer } = createSlice({
  name: 'createTaskPanel',
  initialState: {} as ICreateTaskPanelState,
  reducers: {
    open: (state, action: PayloadAction<IGroupTask | undefined>) => ({
      isOpen: true,
      task: action.payload
    }),
    close: () => ({
      isOpen: false,
      error: undefined,
      task: undefined
    }),
    failure: (state, action: PayloadAction<Error>) => ({
      ...state,
      error: action.payload
    }),
    setTask: (state, action: PayloadAction<IGroupTask | undefined>) => ({
      ...state,
      task: action.payload
    })
  }
})

export { reducer as createTaskPanelReducer }

const rootSelector = (state: AppState) => state.features.tasks.createTaskPanel

const getIsPanelOpen = flow(rootSelector, (x) => x.isOpen)
const getPanelError = flow(rootSelector, (x) => x.error)
const getTask = flow(rootSelector, (x) => x.task)

export interface ICreateTaskPanelState {
  isOpen?: boolean
  error?: Error
  task?: IGroupTask
}

export const useCreateEditTaskPanel = () => {
  const dispatch = useDispatch()

  const close = useCallback(() => {
    dispatch(actions.close())
  }, [dispatch])

  const open = useCallback(
    (task?: IGroupTask) => {
      dispatch(actions.open(task))
    },
    [dispatch]
  )

  const setError = useCallback(
    (e: Error) => {
      dispatch(actions.failure(e))
    },
    [dispatch]
  )

  const setTask = useCallback(
    (task?: IGroupTask) => {
      dispatch(actions.setTask(task))
    },
    [dispatch]
  )

  const isOpen = useSelector(getIsPanelOpen)

  const error = useSelector(getPanelError)

  const task = useSelector(getTask)

  return {
    close,
    open,
    setError,
    isOpen,
    error,
    task,
    setTask
  }
}
