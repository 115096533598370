import { tokenizeQuery } from 'api/search'
import { toLower } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useDebounce } from 'shared/hooks/useDebounce'

export const useSearchText = () => {
  const [searchText, setSearchText] = useState('')
  const debouncedSearchText = useDebounce(searchText, 500)
  const tokenizedGlobalFilter = useMemo(
    () => tokenizeQuery(debouncedSearchText).map(toLower),
    [debouncedSearchText]
  )
  const onSearchTextChanged = useCallback((_e: unknown, newValue?: string) => {
    setSearchText(newValue || '')
  }, [])

  return { searchText, onSearchTextChanged, tokenizedGlobalFilter }
}
