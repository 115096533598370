import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { SnackBar } from '../../../components/shared/Snackbar'
import { useRetrieveDocumentListQueryForSelectedAccounts } from '../../../store/rdot360Context/useRdot360DocumentsContext'
import LoaderContainer from '../DocumentFilter/LoaderContainer'
import {
  getSearchDateEnd,
  getSearchDateBegin,
  getIsDownloading,
  useDocumentModuleStore,
  getShowAllAccounts
} from '../DocumentFilter/store'
import CorrespondenceTable from './CorrespondenceTable'

const CorrespondenceTab = () => {
  const tab = 'Correspondence'
  const selectedSearchDateEnd = useSelector(getSearchDateEnd(tab))
  const selectedSearchDateBegin = useSelector(getSearchDateBegin(tab))
  const showAllAccounts = useSelector(getShowAllAccounts())
  const isDownloading = useSelector(getIsDownloading)
  const { data, isLoading, isFetching, isUninitialized, error } =
    useRetrieveDocumentListQueryForSelectedAccounts(
      !!(selectedSearchDateBegin && selectedSearchDateEnd) && {
        VirtualTable: 'NF_CORRESPONDENCE',
        SearchDateComparison: 'R',
        SearchDateBegin: selectedSearchDateBegin,
        SearchDateEnd: selectedSearchDateEnd,
        SearchDateEndSpecified: true,
        SearchAllVersions: true,
        IncludeRelationships: false,
        RenditionFilter: '*',
        DateRange: ''
      },
      showAllAccounts
    )

  const { clearSelection } = useDocumentModuleStore()
  useEffect(() => {
    clearSelection()
  }, [data, clearSelection])

  return (
    <>
      <LoaderContainer
        isLoading={isLoading}
        isFetching={isFetching}
        isDownloading={isDownloading}
        dataListCount={data?.DocumentList?.length || 0}
      />
      <CorrespondenceTable items={data?.DocumentList} />
      {data?.DocumentList?.length === 0 &&
        !isUninitialized &&
        !isFetching &&
        !error && <SnackBar type={'Info'} message={'No data available'} />}
      {error && (
        <SnackBar
          type={'Failure'}
          message={(error as Error)?.message || 'An unknown error occurred'}
        />
      )}
    </>
  )
}

export default CorrespondenceTab
