import { search } from 'api/search'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { getRockefellerApiOptions } from 'store/shared'
import { call, delay, put, takeEvery, takeLatest } from 'typed-redux-saga'
import {
  fetchGiftCode,
  fetchPaymentTypeCodeservice
} from '../../api/WireService'
import { setFormDataActions } from '../actions'
import {
  fetchPhubCodes,
  getDefaultGiftCodeActions,
  toAccountSearchActions,
  toHouseholdAccountSearchActions
} from './actions'
import { IJournalInitiationForm, IgetGiftResponse } from './types'

function accountSearch(
  account: string,
  options: IApiOptions,
  searchFields?: string[],
  fetchAll?: boolean
) {
  return search(
    'account',
    {
      query: account,
      fullQuery: true,
      searchFields: searchFields || [
        'CustodyAccount',
        'LegalEntityName',
        'AccountNickname',
        'Shortname',
        'gfoCustodyAccount'
      ],
      select: [
        'ClientAdvisorID',
        'ClientAdvisor',
        'ClientAdvisorTeam',
        'CustodyAccount',
        'LegalEntityName',
        'LegalEntityID',
        'AccountKPIs/AccountTotal',
        'AccountNickname',
        'Shortname',
        'CustodianType',
        'CustodianName',
        'registrationtype',
        'registrationDesc',
        'retirementAccount',
        'gfoCustodyAccount',
        'id',
        'householdId'
      ],
      count: true,
      top: fetchAll ? undefined : 20
    },
    options
  )
}
function* handleAccountSearch(
  action: ReturnType<typeof toAccountSearchActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      accountSearch(action.payload ?? '', options)
    )
    if (response && response?.value) {
      yield put(toAccountSearchActions.success(response))
    } else {
      yield put(
        toAccountSearchActions.failure(
          new Error('unable to fetch account list')
        )
      )
    }
  } catch (e: any) {
    yield put(toAccountSearchActions.failure(e))
  }
}

function* handleHouseholdAccountSearch(
  action: ReturnType<typeof toHouseholdAccountSearchActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      accountSearch(action.payload ?? '', options, ['householdId'], true)
    )
    if (response && response?.value) {
      yield put(toHouseholdAccountSearchActions.success(response))
    } else {
      yield put(
        toHouseholdAccountSearchActions.failure(
          new Error('unable to fetch household account list')
        )
      )
    }
  } catch (e: any) {
    yield put(toHouseholdAccountSearchActions.failure(e))
  }
}

function* handleFetchPHubCodes() {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() => fetchPaymentTypeCodeservice(options))
    if (response?.rspStatus?.statusCode === '0') {
      yield put(fetchPhubCodes.success(response?.phubTypeCodes || []))
    } else {
      yield put(fetchPhubCodes.failure(new Error(response?.rspStatus?.message)))
    }
  } catch (e: any) {
    yield put(fetchPhubCodes.failure(e))
  }
}

function* handleSetAccountDetails(
  action: ReturnType<typeof toAccountSearchActions.setAccountDetails>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      accountSearch(action.payload.account, options)
    )
    if (response?.value[0]) {
      const accountDetails = response?.value[0]
      const data: IJournalInitiationForm = action?.payload?.wireData
      if (action?.payload?.isToAccount && data?.journalDetails) {
        data.journalDetails.toAccountRegDesc = accountDetails?.registrationDesc
        data.journalDetails.toAccountCustodianName =
          accountDetails?.CustodianName
        data.journalDetails.isToRetirementAccount =
          accountDetails?.retirementAccount === 'Y'
        data.journalDetails.toName =
          data.journalDetails.toName ?? accountDetails?.LegalEntityName
        data.journalDetails.toAccount =
          accountDetails?.gfoCustodyAccount ||
          accountDetails?.CustodyAccount ||
          data.journalDetails.toAccount
      } else if (!action?.payload?.isToAccount && data?.senderDetails) {
        data.senderDetails.registrationDesc = accountDetails?.registrationDesc
        data.senderDetails.custodianName = accountDetails?.CustodianName
        data.senderDetails.householdId = accountDetails?.householdId
      }
      if (action?.payload?.updateIsWithinAccount && data.journalTransferType) {
        data.journalTransferType.withinAccount =
          data.senderDetails?.account === data.journalDetails?.toAccount
            ? 'Y'
            : 'N'
      }
      yield put(setFormDataActions.setWireFormData(data))
    }
  } catch (e: any) {
    yield put(toAccountSearchActions.failure(e))
  }
}

function* handleGetGiftCode(
  action: ReturnType<typeof getDefaultGiftCodeActions.request>
) {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const data: IgetGiftResponse = yield call(() =>
      fetchGiftCode(
        action?.payload.fromAccount,
        action.payload.toAccount,
        options
      )
    )
    if (data?.rspStatus?.statusCode === '0') {
      yield put(getDefaultGiftCodeActions.success(data || {}))
    } else {
      yield put(
        getDefaultGiftCodeActions.failure(
          new Error('unable to fetch gift code')
        )
      )
    }
  } catch (e: any) {
    yield put(getDefaultGiftCodeActions.failure(e))
  }
}
export const sagas = [
  () => takeLatest(toAccountSearchActions.request, handleAccountSearch),
  () =>
    takeLatest(
      toHouseholdAccountSearchActions.request,
      handleHouseholdAccountSearch
    ),
  () =>
    takeEvery(
      toAccountSearchActions.setAccountDetails,
      handleSetAccountDetails
    ),
  () => takeLatest(fetchPhubCodes.request, handleFetchPHubCodes),
  () => takeLatest(getDefaultGiftCodeActions.request, handleGetGiftCode)
]
