import { Text } from '@fluentui/react'
import React from 'react'
import { IAdvisorRep } from '../../../../../../../../api/dynamics'
import { createEntitySelector } from './EntitySelector'
import { useRepSearchFetch } from './store/repSearchFetch'

const RepSearchAutoCompleteItem: React.FC<{
  item?: IAdvisorRep
}> = ({ item }) => {
  return (
    <div style={{ padding: '6px 10px', minWidth: 0 }}>
      <Text>{item?.rcm_repid}</Text> - <Text>{item?.rcm_name}</Text>
    </div>
  )
}

export const RepEntitySelector = createEntitySelector(
  useRepSearchFetch,
  RepSearchAutoCompleteItem
)
