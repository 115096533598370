import { IOrderBy } from 'api/odata.types'
import { tokenizeQuery, operators } from 'api/search'
import { endOfDay, startOfDay } from 'date-fns'
import { uniq, uniqBy } from 'lodash'
import {
  isNotNullOrEmpty,
  isNotNullOrFalse,
  isNotNullOrUndefined
} from 'shared/guards'
import { IOdataRequest } from './alerts.types'

export type OdataFilterType =
  | 'string'
  | 'number'
  | 'date'
  | 'datetime'
  | 'boolean'

export enum OdataFilterOperatorEnum {
  eq = 'eq',
  ne = 'ne',
  gt = 'gt',
  ge = 'ge',
  lt = 'lt',
  le = 'le',
  in = 'in',

  contains = 'contains',
  search = 'search',
  searchin = 'search.in',
  startswith = 'startswith',

  dynamicsin = 'Microsoft.Dynamics.CRM.In'
}

export enum OdataFilterLogicalOperatorEnum {
  and = 'and',
  or = 'or',
  not = 'not'
}

export enum OdataFilterCollectionOperatorEnum {
  empty = 'empty',
  any = 'any'
}

export interface IOdataPropertyFilter {
  type: OdataFilterType
  operator: OdataFilterOperatorEnum
  value?: string | string[] | number | Date | null | boolean | boolean[]
  path?: string
}

export type OdataPropertyFilterGroup = {
  [key in keyof typeof OdataFilterLogicalOperatorEnum]?:
    | OdataPropertyFilterGroup
    | (IOdataPropertyFilter | IOdataCollectionFilter)[]
}

export interface IOdataCollectionFilter {
  operator: OdataFilterCollectionOperatorEnum
  path: string
  filter: OdataPropertyFilterGroup | null
}

const isCollectionFilter = (
  item: OdataPropertyFilterGroup | IOdataCollectionFilter | IOdataPropertyFilter
): item is IOdataCollectionFilter => {
  return (item as IOdataCollectionFilter).filter !== undefined
}

export const constructFilterQuery = (
  filters: (OdataPropertyFilterGroup | IOdataCollectionFilter)[]
) => {
  return filters
    .map((x) => {
      return isCollectionFilter(x)
        ? createCollectionFilterQuery(x)
        : processFilterGroup(x)
    })
    .join(` ${OdataFilterLogicalOperatorEnum.and} `)
}

export type YesNo = 'Yes' | 'No'
export const mapFacetStringToBoolean = (value: YesNo) => value === 'Yes'
export const mapBooleanToFacetString = (value: boolean): YesNo =>
  value ? 'Yes' : 'No'

const createCollectionFilterQuery = (
  collectionFilter: IOdataCollectionFilter
) => {
  const { filter, operator, path } = collectionFilter
  const subFilter =
    filter !== null ? `g: ${processFilterGroup(filter, 'g')}` : ''
  return `${path}/${operator}(${subFilter})`
}

const processFilter = (
  filter: IOdataPropertyFilter | IOdataCollectionFilter,
  collectionPrefix = ''
): string | undefined => {
  if (isCollectionFilter(filter)) {
    return createCollectionFilterQuery(filter)
  }

  const { value, operator, type } = filter
  let { path } = filter

  if (collectionPrefix) {
    path = `${collectionPrefix}${path && path !== '.' ? `/${path}` : ''}`
  }

  if (value === null || value === undefined || value === '') {
    return `(${[
      `${path} ${operator} null`,
      type === 'string' && `${path} ${operator} ''`
    ]
      .filter(Boolean)
      .join(
        operator === OdataFilterOperatorEnum.ne
          ? ` ${OdataFilterLogicalOperatorEnum.and} `
          : ` ${OdataFilterLogicalOperatorEnum.or} `
      )})`
  }

  if (type === 'string' && operator === OdataFilterOperatorEnum.searchin) {
    const values = value as string[]
    const strings = values.filter(Boolean)
    const nulls = values.filter((x) => !x)
    return `${[
      strings.length && `search.in(${path}, '${strings.join('|')}', '|')`,
      nulls.length && `${path} eq ''`,
      nulls.length && `${path} eq null`
    ]
      .filter(Boolean)
      .join(` ${OdataFilterLogicalOperatorEnum.or} `)}`
  }

  if (type === 'string' && operator === OdataFilterOperatorEnum.in) {
    const values = value as string[]
    const strings = values.filter(Boolean)
    const nulls = values.filter((x) => !x)
    return `${[
      strings.length &&
        `${path} in (${strings.map((x) => `'${x}'`).join(', ')})`,
      nulls.length && `${path} eq ''`,
      nulls.length && `${path} eq null`
    ]
      .filter(Boolean)
      .join(` ${OdataFilterLogicalOperatorEnum.or} `)}`
  }

  if (type === 'boolean' && operator === OdataFilterOperatorEnum.in) {
    const values = value as YesNo[]
    const booleans = values
      .filter(isNotNullOrEmpty)
      .map(mapFacetStringToBoolean)
    const nulls = values.filter((x) => !x)
    return `${[
      !!booleans.length && `${path} in (${booleans.join(', ')})`,
      !!nulls.length && `${path} eq null`
    ]
      .filter(isNotNullOrFalse)
      .join(` ${OdataFilterLogicalOperatorEnum.or} `)}`
  }

  if (
    (type === 'string' || type === 'number') &&
    operator === OdataFilterOperatorEnum.dynamicsin
  ) {
    const values = value as string[]
    const strings = values.filter(isNotNullOrEmpty)
    const nulls = values.filter((x) => !x)
    return `${[
      strings.length &&
        `Microsoft.Dynamics.CRM.In(PropertyName='${
          filter.path
        }', PropertyValues=[${strings.map((x) => `'${x}'`).join(',')}])`,
      type === 'string' && nulls.length && `${path} eq ''`,
      nulls.length && `${path} eq null`
    ]
      .filter(Boolean)
      .join(` ${OdataFilterLogicalOperatorEnum.or} `)}`
  }

  if (
    (type === 'string' && operator === OdataFilterOperatorEnum.contains) ||
    operator === OdataFilterOperatorEnum.startswith
  ) {
    return `${path}(${value}*)`
  }

  if (type === 'string' && operator === OdataFilterOperatorEnum.search) {
    return `search.ismatch('${tokenizeQuery(value as string)
      .filter(Boolean)
      .map((x) => `${x}*`)
      .join(operators.and)}', '${path}', 'full', 'all')`
  }

  if (type === 'string') {
    return `${path} ${operator} '${value}'`
  }

  if (
    operator === OdataFilterOperatorEnum.in ||
    operator === OdataFilterOperatorEnum.search
  ) {
    throw new Error(
      'Invalid request, cannot use operators in or search unless the filter type is string'
    )
  }

  if (type === 'date') {
    const date = new Date(value as Date)
    const start = startOfDay(date)
    const end = endOfDay(date)

    switch (operator) {
      case 'gt':
      case 'le':
        return `${path} ${operator} ${end.toISOString()}`
      case 'ge':
      case 'lt':
        return `${path} ${operator} ${start.toISOString()}`
      case 'eq':
        return processFilterGroup({
          and: [
            {
              operator: OdataFilterOperatorEnum.ge,
              path,
              type,
              value: start
            },
            {
              operator: OdataFilterOperatorEnum.le,
              path,
              type,
              value: end
            }
          ]
        })
      default:
        return `${path} ${operator} ${date.toISOString()}`
    }
  }

  if (type === 'number') {
    return `${path} ${operator} ${value}`
  }

  if (type === 'boolean') {
    return `${path} ${operator} ${value ? 'true' : 'false'}`
  }

  if (type === 'datetime') {
    return `${path} ${operator} ${new Date(value as Date).toISOString()}`
  }
}

const isFilterList = (
  item:
    | OdataPropertyFilterGroup
    | (IOdataPropertyFilter | IOdataCollectionFilter)[]
): item is (IOdataPropertyFilter | IOdataCollectionFilter)[] => {
  return (
    (item as (IOdataPropertyFilter | IOdataCollectionFilter)[])?.length !==
    undefined
  )
}

const processFilterGroup = (
  filter: OdataPropertyFilterGroup,
  collectionPrefix = ''
): string | undefined => {
  const ops = Object.keys(
    filter
  ) as (keyof typeof OdataFilterLogicalOperatorEnum)[]
  if (ops.length !== 1) {
    throw new Error('There must be exactly 1 operator in a filter group')
  }

  const operator = ops[0]
  const item = filter[operator]

  if (!item) {
    return
  }

  if (isFilterList(item) && operator === 'not') {
    throw new Error('Cannot pass a filter list with operator not')
  }

  if (!isFilterList(item) && operator !== 'not') {
    throw new Error('Cannot pass a filter list with operator not')
  }

  if (isFilterList(item)) {
    return `(${item
      .map((x) => processFilter(x, collectionPrefix))
      .join(` ${operator} `)})`
  }

  if (!isFilterList(item)) {
    return `(${operator} ${processFilterGroup(item, collectionPrefix)})`
  }
}

export const constructOdataQuery = (
  request: IOdataRequest,
  defaults?: IOdataRequest
): IOdataRequest => {
  const {
    skip,
    top,
    filters,
    orderby,
    select,
    expand,
    search,
    searchFields = [],
    count,
    apply,
    facets
  } = request

  const allFilters = [...(filters ?? [])]

  const orderbyWithDefault = uniqBy(
    ([...(orderby ?? []), ...(defaults?.orderby ?? [])] as IOrderBy[]).filter(
      isNotNullOrUndefined
    ),
    (x) => x.dataPath
  )

  const selectWithDefault = uniq(
    [...(select ?? []), ...(defaults?.select ?? [])].filter(
      isNotNullOrUndefined
    )
  )

  const expandWithDefault = [
    ...(expand ?? []),
    ...(defaults?.expand ?? [])
  ].filter(isNotNullOrUndefined)

  const searchFieldsPart = searchFields?.join(' AND ') ?? ''
  const searchPart = search ? `(${search})` : ''
  const searchFieldsAndSearch = searchFields?.length && search ? ' AND ' : ''
  const searchQuery = searchFields
    ? `${searchFieldsPart}${searchFieldsAndSearch}${searchPart}`
    : ''
  const data: IOdataRequest = {
    queryType: 'full',
    top
  }

  if (!facets?.length) {
    if (apply) {
      data.apply = apply
    }
    if (skip) {
      data.skip = skip
    }
    if (!facets?.length) {
      data.count = count
    }
    if (orderbyWithDefault) {
      data.orderby = orderbyWithDefault
        ?.map(({ dataPath, direction }) => {
          const directionPart = direction ? ' ' + direction : ''
          return `${dataPath}${directionPart}`
        })
        .join(',')
    }
    if (expandWithDefault?.length) {
      data.expand = expandWithDefault.join(',')
    }
    if (selectWithDefault?.length) {
      data.select = selectWithDefault.join(',')
    }
  }
  if (facets?.length) {
    data.facets = facets
  }
  if (searchQuery) {
    data.search = searchQuery
  }
  if (allFilters?.length) {
    data.filter = allFilters.join(' and ')
  }

  return data
}
