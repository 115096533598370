import { createReducer } from 'typesafe-actions'
import {
  OfacScreeningActionType,
  ofacScreeningDataActions,
  updateOfacStatusActions
} from './actions'
import { IOfacScreenRecord } from './types'

export interface IOfacScreeningState {
  ofacRecords: {
    records?: IOfacScreenRecord[]
    isLoading: boolean
    error?: Error
  }
  updateRecord?: {
    isLoading: boolean
    isSuccess?: boolean
    error?: Error
  }
}

const initialState: IOfacScreeningState = {
  ofacRecords: {
    isLoading: true
  },
  updateRecord: undefined
}

//create reducer for failed payment

export const ofacScreeningReducer = createReducer<
  IOfacScreeningState,
  OfacScreeningActionType
>(initialState)
  .handleAction(ofacScreeningDataActions.request, (state) => ({
    ...state,
    ofacRecords: {
      isLoading: true,
      error: undefined
    },
    updateRecord: undefined
  }))
  .handleAction(ofacScreeningDataActions.success, (state, action) => ({
    ...state,
    ofacRecords: {
      isLoading: false,
      error: undefined,
      records: action.payload
    }
  }))
  .handleAction(ofacScreeningDataActions.failure, (state, action) => ({
    ...state,
    ofacRecords: {
      isLoading: false,
      error: action.payload
    }
  }))
  .handleAction(updateOfacStatusActions.request, (state) => ({
    ...state,
    updateRecord: {
      isLoading: true,
      error: undefined,
      isSuccess: undefined
    }
  }))
  .handleAction(updateOfacStatusActions.success, (state) => ({
    ...state,
    updateRecord: {
      isLoading: false,
      error: undefined,
      isSuccess: true
    }
  }))
  .handleAction(updateOfacStatusActions.failure, (state, action) => ({
    ...state,
    updateRecord: {
      error: action.payload,
      isLoading: false,
      isSuccess: undefined
    }
  }))
  .handleAction(updateOfacStatusActions.reset, (state) => ({
    ...state,
    updateRecord: undefined
  }))
