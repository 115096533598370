import { partial } from 'lodash/fp'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { datahubApi } from 'store/api/datahub'
import { IAfterValuationHistory, IPosition } from '../../../api/position.types'
import { AppState } from '../../../store'
import { createListsStore } from '../core/store'
import {
  createDefaultColumnState,
  mapColumnsToFilterDefinitions
} from '../shared/lists'
import { PositionColumnDefinitions } from './ColumnDefinitions'
import { positionExportDefinitions } from './ExportDefinitions'

export const constructInitialPositionListColumnState = partial(
  createDefaultColumnState,
  [PositionColumnDefinitions]
)

export const positionsListStore = createListsStore<IPosition>(
  'position',
  '@features/@lists/@positions',
  {
    columnDefinitions: PositionColumnDefinitions,
    filterDefinitions: mapColumnsToFilterDefinitions(PositionColumnDefinitions)
  },
  (state: AppState) => state.features.lists.position,
  positionExportDefinitions
)

export const { reducer, sagas, actions, selectors } = positionsListStore

const positionsDatahubApi = datahubApi.injectEndpoints({
  endpoints: (builder) => ({
    getAfterValuationHistory: builder.query<
      IAfterValuationHistory[] | undefined,
      string
    >({
      query: (id) => ({
        url: 'search/positions',
        params: { $filter: `id eq '${id}'`, $select: 'AfterValuationHistory' }
      }),
      transformResponse: (x: IOdataResult<IPosition>) =>
        x.value?.[0]?.AfterValuationHistory
    })
  })
})

export const { useGetAfterValuationHistoryQuery } = positionsDatahubApi
