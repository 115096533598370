import { ISelectableOption, IDropdownOption } from '@fluentui/react'
import { PerformanceType } from 'api/performance.types'
import { useCallback } from 'react'
import { Dropdown } from '../../components/shared'
import { PerfDateRange, usePerformanceState } from './store'

const filterPerformanceTypeOptions: ISelectableOption[] = [
  { key: PerformanceType.combined, text: 'Total' },
  { key: PerformanceType.managed, text: 'Total minus Excluded' },
  { key: PerformanceType.unmanaged, text: 'Excluded only' }
]

const PerformanceTypeFilter = () => {
  const { performanceType, setPerformanceType } = usePerformanceState()

  const onPerformanceTypeChange = useCallback(
    (ev: any, option?: IDropdownOption) => {
      if (!option?.key) {
        return
      }
      setPerformanceType(option?.key as PerfDateRange)
    },
    [setPerformanceType]
  )

  return (
    <Dropdown
      options={filterPerformanceTypeOptions}
      selectedKey={performanceType}
      onChange={onPerformanceTypeChange}
    />
  )
}

export default PerformanceTypeFilter
