import { useMemo } from 'react'
import { Icon, IconType } from '../features/Icons/Icon'

export const SortIndicator: React.FC<{
  direction?: 'asc' | 'desc' | false
}> = ({ direction }) => {
  const iconType: IconType = useMemo(() => {
    switch (direction) {
      case 'asc':
        return 'SortColumnAsc'
      case 'desc':
        return 'SortColumnDesc'

      default:
        return 'SortColumn'
    }
  }, [direction])
  return (
    <div style={{ paddingLeft: '3px' }}>
      <Icon
        type={iconType}
        color="black"
        height={11}
        width={11}
        viewBox="0 0 12 12"
      />
    </div>
  )
}
