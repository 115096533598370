import { ActionType, createAction } from 'typesafe-actions'
import { IAccount } from './types'

export const FETCH_ACCOUNTS = '@modules/@payments/FETCH_ACCOUNTS'
export const FETCH_ACCOUNTS_SUCCESS =
  '@modules/@payments/FETCH_ACCOUNTS_SUCCESS'
export const FETCH_ACCOUNTS_FAILURE =
  '@modules/@payments/FETCH_ACCOUNTS_FAILURE'

export const accountsDataActions = {
  request: createAction(FETCH_ACCOUNTS)(),
  success: createAction(FETCH_ACCOUNTS_SUCCESS)<IAccount[]>(),
  failure: createAction(FETCH_ACCOUNTS_FAILURE)<any>()
}

export type AccountsDataActions = ActionType<typeof accountsDataActions>

export type AccountsActionType = AccountsDataActions
