import {
  CommandBar,
  DirectionalHint,
  ICommandBarItemProps,
  ITheme,
  Link,
  Stack,
  Text,
  TooltipDelay,
  TooltipHost
} from '@fluentui/react'
import { formatDistance } from 'date-fns'
import React, { memo, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  MarginRateRequestStatusEnum,
  IMarginRateRequest
} from '../../../../../../../../api/dynamics'
import { TextCell } from '../../../../../../../../features/Lists/core/components/TextCell'
import { marginRatePanelActions } from '../../../../../../../../features/MarginRateAdjustment/store/marginRatePanel'
import {
  IOdataDataListCellRenderProps,
  OdataList
} from '../../../../../../../../features/OdataList/containers/OdataList'
import { ConnectedMasked } from '../../../../../../../../shared/components/MaskedText'
import { useClasses } from '../../../../../../../../shared/hooks/useClasses'
import { marginRateRequestViewPanelActions } from '../MarginRateRequestView/store/MarginRateRequestViewPanel'
import {
  MarginRateRequestListColumnName,
  marginRateRequestListUiActions,
  marginRateRequestListUiSelectors
} from './store'

const getThemedClasses = (theme: ITheme) => ({
  commandBar: {
    borderBottom: `solid 2px ${theme.palette.neutralLight}`
  }
})

const HouseholdNameCell: React.FC<{ request: IMarginRateRequest }> = ({
  request
}) => {
  const dispatch = useDispatch()
  const onLinkClick = useCallback(() => {
    if (!request.rcm_marginraterequestid) {
      return
    }
    dispatch(
      marginRateRequestViewPanelActions.open(request.rcm_marginraterequestid)
    )
  }, [dispatch, request])

  return (
    <Link
      title="Account Info"
      onClick={onLinkClick}
      styles={{ root: { minWidth: 0 } }}
    >
      <Text nowrap={true} block={true}>
        {request.rcm_householdname || request.rcm_householdid}
      </Text>
    </Link>
  )
}

export const DateCell: React.FC<{ value?: string; showTime?: boolean }> = ({
  value
}) =>
  value ? (
    <Text nowrap={true}>
      {formatDistance(new Date(value), Date.now(), { includeSeconds: false })}{' '}
      ago
    </Text>
  ) : (
    <Text>--</Text>
  )

export const MarginRateRequestList: React.FC = () => {
  const CellComponents: Record<
    MarginRateRequestListColumnName,
    React.FC<IOdataDataListCellRenderProps<IMarginRateRequest>>
  > = {
    'Household Name': ({ item }) => (
      <Stack styles={{ root: { minWidth: 0 } }}>
        <HouseholdNameCell request={item} />
      </Stack>
    ),

    Status: ({ item }) => {
      const [[key]] = Object.entries(MarginRateRequestStatusEnum).filter(
        ([, value]) => value === item.rcm_status
      )

      return <TextCell>{key}</TextCell>
    },
    'Rate Type': ({ item }) => <TextCell>{item.rcm_ratetype}</TextCell>,
    Rate: ({ item }) => (
      <TextCell>
        {!!item.rcm_rate && `OBFR + ${item.rcm_rate?.toFixed(2)}%`}
      </TextCell>
    ),
    Accounts: ({ item }) => {
      const accounts = item?.rcm_accounts?.split(',') || []

      return (
        <>
          {!!accounts.length && (
            <TooltipHost
              tooltipProps={{
                onRenderContent: () => {
                  return (
                    <Stack tokens={{ childrenGap: 2 }}>
                      {accounts?.map((x, i) => (
                        <Text key={i} variant="small">
                          <ConnectedMasked text={x} />
                        </Text>
                      ))}
                    </Stack>
                  )
                }
              }}
              delay={TooltipDelay.zero}
              directionalHint={DirectionalHint.rightCenter}
            >
              <Link>
                <Text variant="smallPlus">{accounts.length} accounts</Text>
              </Link>
            </TooltipHost>
          )}
        </>
      )
    },
    Justification: ({ item }) => {
      const length = item?.rcm_justification?.length
      return (
        <>
          {!!length && (
            <TooltipHost
              styles={{ root: { minWidth: 0 } }}
              tooltipProps={{
                onRenderContent: () => {
                  return (
                    <Stack tokens={{ childrenGap: 2 }}>
                      <Text variant="small">
                        <ConnectedMasked text={item.rcm_justification} />
                      </Text>
                    </Stack>
                  )
                }
              }}
              delay={TooltipDelay.zero}
              directionalHint={DirectionalHint.rightCenter}
            >
              <Text nowrap={true} block={true}>
                {item.rcm_justification || '--'}
              </Text>
            </TooltipHost>
          )}
          {length === undefined && (
            <Text variant="small" nowrap={true}>
              {'--'}
            </Text>
          )}
        </>
      )
    },
    Created: ({ item }) => (
      <Stack styles={{ root: { minWidth: 0 } }}>
        <DateCell value={item.createdon} />
        <Text variant="small" nowrap={true}>
          {item['_createdby_value@OData.Community.Display.V1.FormattedValue']}
        </Text>
      </Stack>
    ),
    'Last Modified': ({ item }) => (
      <Stack styles={{ root: { minWidth: 0 } }}>
        <DateCell value={item.modifiedon} />
        <Text variant="small" nowrap={true}>
          {item[`_modifiedby_value@OData.Community.Display.V1.FormattedValue`]}
        </Text>
      </Stack>
    )
  }
  const MarginRateRequestListCell: React.FC<
    IOdataDataListCellRenderProps<IMarginRateRequest>
  > = memo(({ item, column }) => {
    const Component =
      CellComponents[column.name as MarginRateRequestListColumnName]
    return Component ? <Component item={item} column={column} /> : null
  })
  const dispatch = useDispatch()
  const commandBarItems = useMemo(() => {
    const items: ICommandBarItemProps[] = [
      {
        key: 'newRequest',
        name: 'New Request',
        iconProps: { iconName: 'Add' },
        onClick: () => {
          dispatch(marginRatePanelActions.open(undefined))
        }
      }
    ]
    return items
  }, [dispatch])
  const classes = useClasses(getThemedClasses)
  const header = useMemo(
    () => (
      <CommandBar
        className={classes.commandBar}
        items={commandBarItems}
        styles={{ root: { paddingLeft: '0px' } }}
      />
    ),
    [classes, commandBarItems]
  )

  return (
    <OdataList
      actions={marginRateRequestListUiActions}
      selectors={marginRateRequestListUiSelectors}
      onRenderCell={MarginRateRequestListCell}
      stickyHeaderOffset={0}
      secondaryHeader={header}
    />
  )
}
