import { MessageBarType, Overlay, Panel, PanelType } from '@fluentui/react'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { usePushNotification } from 'features/Notifications'
import React, { useCallback, useEffect, useMemo } from 'react'
import { LoadingComponent } from 'shared/components/Loading'
import { useFetchNfsProfileByIdQuery } from 'store/api/datahub'
import { IClientInviteForm, InviteContent } from './InviteContent'
import { InviteFooter } from './InviteFooter'
import { useClientInvitePanel } from './store/clientInvitePanel'

export const ClientInvitePanel: React.FC = () => {
  const {
    close,
    isOpen,
    error: panelError,
    panelType,
    sendInvite,
    isSendInviteLoading,
    getIsThirdParty,
    selectedWealthscapeId,
    setShowValidationError
  } = useClientInvitePanel()

  const {
    currentData: data,
    isFetching: isFetchLoading,
    error: fetchError
  } = useFetchNfsProfileByIdQuery(selectedWealthscapeId || skipToken)

  const { pushNotification } = usePushNotification()

  const onDismiss = useCallback(() => {
    close()
  }, [close])

  const onSuccess = useCallback(() => {
    onDismiss()
    const getMessage = () => {
      switch (panelType) {
        case 'invite':
          return 'Successfully Invited'
        case 'changeEmail':
          return 'Successfully Changed Email'
        default:
          return 'Successfully Reinvited'
      }
    }
    pushNotification({
      message: getMessage(),
      type: MessageBarType.success
    })
  }, [onDismiss, panelType, pushNotification])

  useEffect(() => {
    if (!isOpen) {
      return
    }
    setShowValidationError(false)
  }, [isOpen, setShowValidationError])

  const error = useMemo(
    () => (fetchError as Error | undefined) || panelError,
    [fetchError, panelError]
  )

  const isAlreadyInvited = useMemo(
    () => panelType === 'invite' && !!data?.loginid && !error,
    [data?.loginid, error, panelType]
  )
  const phones = useMemo(() => JSON.parse(data?.phones || '[]'), [data?.phones])
  const onRenderFooterContent = useCallback(() => {
    return (
      <InviteFooter
        isFetchLoading={isFetchLoading}
        isSendInviteLoading={isSendInviteLoading}
        error={error}
        isAlreadyInvited={isAlreadyInvited}
        panelType={panelType}
        data={data}
        hasPhones={phones?.length}
        onDismiss={onDismiss}
      />
    )
  }, [
    data,
    error,
    isAlreadyInvited,
    isFetchLoading,
    isSendInviteLoading,
    onDismiss,
    panelType,
    phones?.length
  ])

  const headerText = useMemo(() => {
    switch (panelType) {
      case 'invite':
        return 'Client Invite'
      case 'changeEmail':
        return 'Change Email'
      case 'resend':
        return 'Resend Invitation'
    }
  }, [panelType])

  const isThirdParty = useMemo(
    () => getIsThirdParty(data?.role),
    [data?.role, getIsThirdParty]
  )

  const onSubmit = useCallback(
    async (form?: IClientInviteForm) => {
      await sendInvite(onSuccess, form, data, isThirdParty)
    },
    [data, isThirdParty, onSuccess, sendInvite]
  )

  return (
    <Panel
      headerText={headerText}
      isOpen={isOpen}
      onDismiss={onDismiss}
      isLightDismiss={true}
      closeButtonAriaLabel="Close"
      type={PanelType.custom}
      customWidth="400px"
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom={true}
      styles={{ content: { flexGrow: 1 } }}
    >
      <>
        {(isFetchLoading || isSendInviteLoading) && (
          <Overlay styles={{ root: { zIndex: 1 } }}>
            <LoadingComponent />
          </Overlay>
        )}
        <InviteContent
          panelType={panelType}
          nfsProfile={data}
          isThirdParty={isThirdParty}
          onSubmit={onSubmit}
          error={error}
        />
      </>
    </Panel>
  )
}
